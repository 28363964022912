import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Gstr1Service } from 'src/app/core/services/gstr1.service';
import * as XLSX from 'xlsx';

interface IRange {
  value: Date[];
  label: string;

}

@Component({
  selector: 'app-gst-report3-b',
  templateUrl: './gst-report3-b.component.html',
  styleUrls: ['./gst-report3-b.component.scss']
})
export class GstReport3BComponent implements OnInit {
  public quarterStartDate: any;
  public quarterEndDate: any;
  public today: Date;
  public quarter;
  public maxDate: Date;
  public start = moment().subtract(29, 'days');
  public end = moment();
  public ranges: IRange[];
  public dateRange: Date[];
  fileName = 'gst3bReport.xlsx';
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))

  public TTaxValue: any;
  public IntTax: any;
  public tchess: any;
  public centralTax: any;
  public stateTax: any;

  public TTaxValueB: any;
  public IntTaxB: any;
  public tchessB: any;
  public centralTaxB: any;
  public stateTaxB: any;

  public TTaxValueD: any;
  public IntTaxD: any;
  public tchessD: any;
  public centralTaxD: any;
  public stateTaxD: any;

  public TTaxValueE: any;
  public IntTaxE: any;
  public tchessE: any;

  public URTTaxvalue: any;
  public URIntTax: any;
  public RTTaxvalue: any;
  public RIntTax: any;

  public interStateSupply: any;
  public intraStateSupplyNonGst: any;

  public inwordSuppliesByRcmintegrated_Tax: any;
  public inwordSuppliesByRcmCentral_Tax: any;
  public inwordSuppliesByRcmstate_UT_tax: any;

  //   (click)="printComponent('component1')"
  //   printComponent(cmpName) {
  //     let printContents = document.getElementById(cmpName).innerHTML;
  //     let originalContents = document.body.innerHTML;

  //     document.body.innerHTML = printContents;

  //     window.print();

  //     document.body.innerHTML = originalContents;

  // }
  constructor(private gstr1Service: Gstr1Service) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
    let quarter = moment(this.today).utc().quarter() //outputs 4
    this.getQuarterRange();
  }
  //isShown: boolean = false ;
  ngOnInit(): void {
    this.ranges = [

      {
        value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 7 Days'
      },
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 30 Days'
      },
      {
        value: [new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()), new Date(new Date(new Date().setDate(0)).toDateString())],
        label: 'Last Month'

      },
      {
        value: [this.quarterStartDate._d, this.quarterEndDate._d],
        label: ' Last Quarter'
      },
      {
        value: [new Date(new Date(new Date().getFullYear() - 1, 3, 1).toDateString()), new Date(new Date(new Date().getFullYear(), 2, 31).toDateString())],
        label: 'Last Financial Year'

      },
      {
        value: [new Date(new Date(new Date().getFullYear(), 3, 1).toDateString()), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'This Financial Year'

      },
      {
        value: [],
        label: 'Custom Range'

      }];
    this.dateRange = [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate()))]

    this.getGstr3BReport();
  }

  changeDate(_e: any) {
    this.getGstr3BReport();
  }

  getGstr3BReport() {
    this.gstr1Service.getgstr3BReport({}, this.currentUser.id).then((res: any) => {
      console.log("Res", res)
      this.TTaxValue = res.data.gst3_1.a.total_taxable_value;
      this.IntTax = res.data.gst3_1.a.integrated_Tax;
      this.tchess = res.data.gst3_1.a.totalCess;
      this.centralTax = res.data.gst3_1.a.central_Tax;
      this.stateTax = res.data.gst3_1.a.state_UT_tax;

      this.TTaxValueB = res.data.gst3_1.b.total_taxable_value;
      this.IntTaxB = res.data.gst3_1.b.integrated_Tax;
      console.log(this.IntTaxB)
      this.tchessB = res.data.gst3_1.b.totalCess;
      this.centralTaxB = res.data.gst3_1.b.central_Tax;
      this.stateTaxB = res.data.gst3_1.b.state_UT_tax;

      this.TTaxValueD = res.data.gst3_1.d.total_taxable_value;
      this.IntTaxD = res.data.gst3_1.d.integrated_Tax;
      console.log(this.IntTaxD)
      this.tchessD = res.data.gst3_1.d.totalCess;
      this.centralTaxD = res.data.gst3_1.d.central_Tax;
      this.stateTaxD = res.data.gst3_1.d.state_UT_tax;

      this.TTaxValueE = res.data.gst3_1.e.total_taxable_value;
      this.IntTaxE = res.data.gst3_1.e.integrated_tax;
      this.tchessE = res.data.gst3_1.e.totalCess;

      this.URTTaxvalue = res.data.gst3_2.unregisteredParty.total_taxable_value;
      this.URIntTax = res.data.gst3_2.unregisteredParty.integrated_tax;
      this.RTTaxvalue = res.data.gst3_2.registerParty.total_taxable_value;
      this.RIntTax = res.data.gst3_2.registerParty.integrated_tax;

      this.interStateSupply = res.data.gst3_4.a;
      this.intraStateSupplyNonGst = res.data.gst3_4.b;

      this.inwordSuppliesByRcmintegrated_Tax = res.data.gst3_3_3[0].integrated_Tax;
      this.inwordSuppliesByRcmCentral_Tax = res.data.gst3_3_3[0].central_Tax;
      this.inwordSuppliesByRcmstate_UT_tax = res.data.gst3_3_3[0].state_UT_tax;
    })
  }
  getQuarterRange() {
    this.quarterStartDate = moment(this.today).subtract(1, 'quarter').startOf('quarter');
    this.quarterEndDate = moment(this.today).subtract(1, 'quarter').endOf('quarter');
  }

  exportExcel() {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }
  onPrint() {
    const printContents = document.getElementById('excel-table').innerHTML;
    const originalContents = document.body.innerHTML;
    this.removeScrollbar();
    window.print();
    this.restoreScrollbar();
  }
  private removeScrollbar() {
    document.body.style.overflow = 'hidden';
  }

  private restoreScrollbar() {
    document.body.style.overflow = 'auto';
  }

}
