import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ExpenseServiceService } from 'src/app/core/services/expense-service.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { TitleCasePipe } from '@angular/common';
import { Router } from '@angular/router';


@Component({
  selector: 'app-expense-add',
  templateUrl: './expense-add.component.html',
  styleUrls: ['./expense-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpenseAddComponent implements OnInit {
  public expenseForm: any;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public todaysDate: any;
  public expenseCategoryData: any;
  public fieldArray: Array<any> = [];
  public newAttribute: any = {};
  public formArrayData;
  public submitted: boolean = false;
  public toatalAmount: any;
  public totalTableAmount: any;
  public isdisableCategory: boolean = false
  public totalExpense: boolean = false
  today: Date;
  public expensemodeFlag: boolean = false;
  expenseListType: string;
  public apiCallInProgress: boolean = false;


  constructor(private cdr: ChangeDetectorRef, private formBuilder: FormBuilder, public router: Router, private expenseservice: ExpenseServiceService, private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe, public dialogRef: MatDialogRef<ExpenseAddComponent>

  ) { }

  ngOnInit(): void {
    this.today = new Date();
    this.expenseForm = this.formBuilder.group({
      expenseCategory: ["", [Validators.required]],
      expenseType: ["", [Validators.required]],
      expenseDate: ["", [Validators.required]],
      paymentMode: [null, [Validators.required]],
      // expenseMode: [null],
      toatalAmount: ["",],
      expenseNote: [null],
      addtionalDetails: new FormArray([this.createaddtionalDetails()]),

    });
    this.additionalDetailsControls.valueChanges.subscribe(data => {
      this.toatalAmount = data.reduce((a, b) => a + +b.totalAmount, 0)
      this.expenseForm.patchValue({
        toatalAmount: this.toatalAmount
      })
    })
  }

  createaddtionalDetails(): FormGroup {
    try {
      return new FormGroup({
        item: new FormControl(""),
        quantity: new FormControl(""),
        pricePerUnit: new FormControl(""),
        totalAmount: new FormControl(""),
      });
    }
    catch (e) {

    }
  }

  //set value of total = price per unit * quantity
  setTotalValue(i) {
    try {
      let cur_obj = this.additionalDetailsControls.controls[i].value
      cur_obj.totalAmount = cur_obj.quantity * cur_obj.pricePerUnit
      this.additionalDetailsControls.controls[i].patchValue(cur_obj)
    }
    catch (e) {
    }

  }


  get additionalDetailsControls() {
    try {
      return this.expenseForm.get('addtionalDetails') as FormArray;
    }
    catch (e) {
    }
  }

  deleteaddAddtionalDetails(index): void {
    const data = this.expenseForm.get('addtionalDetails') as FormArray;
    if (data.length > 0) {
      data.removeAt(data.length - 1); // Remove the last element
    }
    this.cdr.detectChanges();
  }


  addAddtionalDetails(): void {
    try {
      const data = this.expenseForm.get('addtionalDetails') as FormArray;
      data.push(this.createaddtionalDetails());
      this.cdr.detectChanges();
    }
    catch (e) {
      console.log(e)

    }
  }
  get f() {
    return this.expenseForm.controls;
  }
  postexpenseDetails() {
    this.submitted = true;
   
    // Check if an API call is already in progress
    if (this.apiCallInProgress) {
      return;
    }

    // Disable the "Save" button to prevent multiple clicks
    this.apiCallInProgress = true;


    if (this.expenseListType === 'COGS') {
      this.formArrayData = this.additionalDetailsControls.value;
      if (this.expenseForm.invalid) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
        // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
        this.apiCallInProgress = false;
        return false;
      }
      else {

        if (this.formArrayData.length < 1) {
          this.toastService.toastMsg({
            title: "Error",
            content: "Please Add At Least One Expense Item To Proceed!!!",
          });
          // this.toastService.openErrorSnackBar(
          //   "Please Add At Least One Expense Item To Proceed!!!"

          // );
          this.apiCallInProgress = false;
          return false;
        }


        let body =
        {
          "expenseData": {
            "userId": this.currentUser.id,
            "date": this.expenseForm.controls.expenseDate.value,
            "expensecategoryId": this.expenseForm.controls.expenseCategory.value,
            "expenseType": this.expenseForm.controls.expenseType.value,
            "paymentMode": this.expenseForm.controls.paymentMode.value,
            "totalAmount": this.expenseForm.controls.toatalAmount.value,
            "note": this.expenseForm.controls.expenseNote.value,
          },
          "expenseItems": this.formArrayData,
        }
        this.expenseservice.saveExpense(body, this.currentUser.id).then((res) => {

          // this.toastService.openSnackBar("Expense Added Successfully!!!");
          this.toastService.toastMsg({
            title: "Success",
            content: "Expense Added Successfully!!!",
          });
          this.expenseservice.notifyPartyAdded()
          this.dialogRef.close();
        }
          , (err) => {
            this.apiCallInProgress = false;
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
              // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
              // this.toastService.openErrorSnackBar("Something Went Wrong.");
            }
          }
        )
      }

    }
    else {

      if (this.expenseForm.controls.expenseDate.value==""||this.expenseForm.controls.expenseCategory.value==""||this.expenseForm.controls.expenseType.value==""||this.expenseForm.controls.paymentMode.value=="") {
        // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
        this.apiCallInProgress = false;
        return false;
      }
      let body =
      {
        "expenseData": {
          "userId": this.currentUser.id,
          "date": this.expenseForm.controls.expenseDate.value,
          "expensecategoryId": this.expenseForm.controls.expenseCategory.value,
          "expenseType": this.expenseForm.controls.expenseType.value,
          "paymentMode": this.expenseForm.controls.paymentMode.value,
          "totalAmount": this.expenseForm.controls.toatalAmount.value,
          "note": this.expenseForm.controls.expenseNote.value,
        },
        "expenseItems": [],
      }
      this.expenseservice.saveExpense(body, this.currentUser.id).then((res) => {
        this.toastService.toastMsg({
          title: "Success",
          content: "Expense Added Successfully!!!",
        });
        // this.toastService.openSnackBar("Expense Added Successfully!!!");
        this.expenseservice.notifyPartyAdded()
        this.dialogRef.close();
      }
        , (err) => {
          this.apiCallInProgress = false;
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
            // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
            // this.toastService.openErrorSnackBar("Something Went Wrong.");
          }
        }
      )

    }



  }
  cancel() {
    this.expenseForm.reset();
    // this.submitted = false;
    this.dialogRef.close();
  }

  getExpensecategory(expeseType: string) {
    console.log(expeseType);
    this.expenseListType = expeseType;
    console.log("expense List TYPEEE", this.expenseListType)
    this.expenseservice.getExpenseCategory({}, this.expenseListType).then((res: any) => {
      this.expenseCategoryData = res.data.rows;
      console.log("expense Category data", this.expenseCategoryData)
    })
  }

  SetExpenseNAture(e) {
    const CategortType = this.expenseCategoryData.filter((res) => res.id === e).map(item => item.type);
    this.expensemodeFlag = true
    this.expenseForm.patchValue({
      expenseMode: CategortType[0]
    }
    )
  }
  openDatepicker() {
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.expenseForm.get('expenseDate').value;
    if (isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.expenseForm.get('expenseDate').setValue(new Date());
    }
  }

}
