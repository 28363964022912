import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import * as moment from 'moment';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardService } from 'src/app/core/services/dashboard.service';
HC_exporting(Highcharts);

@Component({
  selector: 'app-review-product',
  templateUrl: './review-product.component.html',
  styleUrls: ['./review-product.component.scss']
})
export class ReviewProductComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public reviewAi: any = [];
  totalProfit: number=80;
  totalLoss: number=80;
  grossProfit: number=80;
  grossLoss: number;
  products: any[] = [];
  constructor(public commonService: CommonService, public dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.getProfitLoss()
  }

  getProfitLoss(){
    this.dashboardService.reviewProductdash({}, this.currentUser.id).then((res: any) => {
       console.log("res reviewAi",res)
      this.products.push(res);
      if (this.products.length !== 0) {
      this.totalProfit =this.convertStringToNumber(this.products[0].sentiment_distribution.positive);
      this.totalLoss =  this.convertStringToNumber(this.products[0].sentiment_distribution.negative);
      this.grossProfit = this.convertStringToNumber(this.products[0].sentiment_distribution.neutral);
      }
      this.getPieChart()
    }, (error) => {
      console.log("this.products.length",this.products.length)
      this.getPieChart()
    })

    
}

  // getProfitLoss() {


  //   const newProduct = {
  //       "product_id": "0148355e-2d09-4685-8565-b4e98b5ae02a",
  //       "reviews": [
  //           {
  //               "predicted_sentiment": "Positive",
  //               "review": "good"
  //           }
  //       ],
  //       "sentiment_distribution": {
  //           "negative": "0%",
  //           "neutral": "0%",
  //           "positive": "100%"
  //       }
  //   };

  //   this.products.push(newProduct);
  //   // if (this.products.length !== 0) {
  //   // this.totalProfit =this.convertStringToNumber(this.products[0].sentiment_distribution.positive);
  //   // this.totalLoss =  this.convertStringToNumber(this.products[0].sentiment_distribution.negative);
  //   // this.grossProfit = this.convertStringToNumber(this.products[0].sentiment_distribution.neutral);
    
  //   // }
  //   this.getPieChart()
    
  // }

  convertStringToNumber(value: string): number {
    // Remove the percentage sign from the string
    const numericValue = parseFloat(value.replace('%', ''));
    // Check if the result is a valid number
    if (!isNaN(numericValue)) {
      return numericValue;
    } else {
      // Handle invalid input, return NaN or any default value
      return 0; // Or you can return NaN if preferred
    }
  }

  getPieChart() {
    this.reviewAi = Highcharts;

    if (this.products.length === 0) {
      // Display a "No data found" message
      const chartOptions = {
        chart: {
          type: 'pie',
          events: {
            load: function () {
              const chart = this;
              const centerX = chart.plotWidth / 2;
              const centerY = chart.plotHeight / 2;

              // Add the image element
              chart.noDataImage = chart.renderer
                .image('../../../assets/img/icons/spot-illustrations/notfound1.png', centerX - 90, centerY - 140, 200, 150)
                .add();
            }
          }
        },
        title: {
          // text: 'Gross Profit/Loss',
          text: ' ',
          style: {
            fontFamily: "'Poppins', sans-serif",
            fontWeight: '500',
            fontSize: '16px',
            color: '#737791'
          }
        },
        credits: {
          enabled: false
        }
      };
      this.reviewAi.chart('ai-details-chart', chartOptions);
      document.getElementById('ai-details-chart').style.height = '200px';

    } else {
      const chartOptions = {
        chart: {
          type: 'pie',
        },
        credits: {
          enabled: false
        },
        title: {
          // text: 'Gross Profit/Loss',
          text: ' ',
          style: {
            fontFamily: "'Poppins', sans-serif",
            fontWeight: '500',
            fontSize: '16px',
            color: '#737791'
          }
        },
        yAxis: {
          gridLineWidth: 0
        },
        colors: ['#110152'],
        series: [
          {
            name: 'Sentiment distribution',
            size: '100%',
            data: [
              // ['Gross Profit', this.grossProfit],
              {
                name: 'Postive',
                y: this.totalProfit,
                color: '#FF0000'
              },
              {
                name: 'Negative',
                y: this.totalLoss,
                color: '#00FF00'
              },
              {
                name: 'Neutral',
                y: this.grossProfit,
                color: '#0000FF'
              },
            ],
            // innerSize: '80%',
            // dataLabels: {
            //   enabled: true
            // }
          },
          // {
          //   name: 'Loss',
          //   size: '50%',
          //   data: [
          //     ['Gross Loss', this.grossLoss],
          //     {
          //       name: 'Total Loss',
          //       y: this.totalLoss,
          //       color: '#FC7643'
          //     }
          //   ],
          //   innerSize: '70%',
          //   dataLabels: {
          //     enabled: true
          //   }
          // }
        ]
      };
      this.reviewAi.chart('ai-details-chart', chartOptions);
      document.getElementById('ai-details-chart').style.height = '200px';
    }
  }

}
