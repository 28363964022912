<div class="container-fluid bg-white">
    <div class="card-body p-0 overflow-hidden">
        <div class="table-responsive">
            <table class="table table-striped table-bordered mt-2 bg-white">
                <thead >
                    <tr class="btn-reveal-trigger">
                        <th scope="col">#</th>
                        <th scope="col">Email</th>
                        <th scope="col">Mobile</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col" class="text-center">Delete</th>
                    </tr>
                </thead>
                <tbody *ngIf="employeeshop">
                    <tr class="text-capitalize" *ngFor="let empshop of employeeshop; let i = index">
                        <td>{{empshop.id}}</td>
                        <td>{{empshop.email}}</td>
                        <td>{{empshop.mobile}}</td>
                        <td>{{empshop.firstName}}</td>
                        <td>{{empshop.lastName}}</td>
                       
                        <td class="name align-middle text-center white-space-nowrap py-2">
                            <button class="btn  btnp" type="button" (click)="deleteEmployee(empshop.id)">
                                <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"></span>

                            </button>
                         
                        </td>
                    </tr>
                </tbody> 
            </table>
        </div>
    </div>
</div>
