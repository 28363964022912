import { Component, OnInit } from '@angular/core';
import { PayumoneyPaymentService } from 'src/app/core/services/payumoney-payment.service';

@Component({
  selector: 'app-pay-ment-info',
  templateUrl: './pay-ment-info.component.html',
  styleUrls: ['./pay-ment-info.component.scss']
})
export class PayMentInfoComponent implements OnInit {

  constructor(
    public paymentService: PayumoneyPaymentService,
  ) { }

  ngOnInit(): void {
  }

}
