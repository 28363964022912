<!-- <div class="modal-header row" id="Add-Party-modal">
  <div class="col-lg-6 col-sm-12 col-md-4">
    <h1 style="color: #EB8B3F; font-weight: bolder;" class="modal-title">Purchase Return</h1>
  </div>
  <div class="col-lg-4 col-sm-12 col-md-1"></div>
  <div class="col-lg-1 col-sm-12 col-md-3  mb-2">
    <button type="button" class="btn btn-cancel" (click)="cancel()">Cancel</button>
  </div>

  <div class="col-lg-1 col-sm-12 col-md-3 mb-2">
    <button type="button" class="btn btn-save" (click)="SavePurchase()">Save</button>
  </div>

</div> -->

<div class="modal-header pt-0" id="Add-Party-modal">
  <h1 style="color: #EB8B3F; font-weight: bolder;margin-left:5px" class="modal-title mb-0">Purchase Return</h1>

  <div class="addProductButtons">

    <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel"
      (click)="cancel()">Cancel</button>
    <button type="button" class="btn btn-save" (click)="SavePurchase()">Save</button>

  </div>

</div>

<div class="modal-body">
  <form [formGroup]="purchaseForm" *ngIf="!isArabic">

    <div class="row d-flex justify-content-between">
      <div class=""></div>
      <div class="col-sm-12 col-md-12 col-lg-12 my-2 mt-sm-0 justify-content-end">
        <div class="d-flex justify-content-end">
          <div>
            <label class="form-label" class="form-check-label me-2" [ngClass]="lblColor ? 'labelDark' : 'labelLight'"
              for="inlineCheckbox1" style="font-size: 14px !important;font-weight: 500;">Full Return</label>
          </div>
          <div class="form-check form-switch mb-0">
            <input class="form-check-input" id="inlineCheckbox1" formControlName="returnType"  type="checkbox" checked="checked"
              (click)="getRadioBtnValue($event.target.checked)" />
            <label class="form-check-label  me-2" for="inlineCheckbox1"
              style="font-size: 14px !important;font-weight: 500;">Partial Return</label>
          </div>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-4">
        <label class="form-label">Purchase Invoice #<span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline">
          <input type="text" placeholder="Purchase Invoice #." matInput autocomplete="off"
            (keyup.enter)="getInvoiceById($event.target.value)" formControlName="purchaseInvoiceno" id="inputEmail3">
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.purchaseInvoiceno.touched) && f.purchaseInvoiceno.errors?.required">
          Purchase Invoice # Is Required!
        </mat-error>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4">
        <label class="form-label">Purchase Return #<span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline">
          <input type="text" matInput placeholder="Purchase Return #" autocomplete="off"
            (keypress)="numberOnly($event)" formControlName="PurchaseReturnNo" id="inputEmail3">
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.PurchaseReturnNo.touched) && f.PurchaseReturnNo.errors?.required">
          Purchase Return # Is Required!
        </mat-error>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4">
        <label class="form-label" for="Date">Date</label>
        <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline">
          <input matInput required ngModel bsDatepicker placeholder="Receipt Date." [(ngModel)]="todaysDate"
            style="text-transform:uppercase!important;" [maxDate]="today" id="datePicker" [bsConfig]="{
                containerClass: 'theme-dark-blue',
                dateInputFormat: 'DD MMM YYYY',
                showWeekNumbers:false
              }" [ngModelOptions]="{ standalone: true }" (click)="openDatepicker()">
          <mat-icon class="iconcalender">date_range</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div [ngSwitch]="currentUser.userType"  class=" col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <div *ngSwitchCase="'CLIENT'" style="font-size: 14px">
          <label class="form-label">Warehouse<span class="error-asterisk">*</span></label>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse" (selectionChange)="onWarehouseSelectionChange($event)">
              <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                {{warehouse.firstName}}
              </mat-option>
              <mat-option value="clear">Clear Selection</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="(submitted || f.warehouse.touched)">
            <mat-error *ngIf="f.warehouse.errors?.required">
              Warehouse is required!
            </mat-error>
          </mat-error>
        </div>
        <div *ngSwitchCase="'SHOP'" style="font-size: 14px">
          <label class="form-label">Warehouse</label>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse" (selectionChange)="onWarehouseSelectionChange($event)">
              <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                {{warehouse.firstName}}
              </mat-option>
              <mat-option value="clear">Clear Selection</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="table-responsive mt-2  my-custom-scrollbar ">
      <table class="table table-bordered p-2">
        <thead class="inner-model-table-heading">
          <tr class="font_size font-weight-bold">
            <th style = "vertical-align: middle;" scope="col">#</th>
            <th scope="col" style="width: 10%;vertical-align: middle;">
              SKU<span class="text-danger"> *</span>
            </th>
            <th style = "vertical-align: middle;" class="text-nowrap">SKU Description</th>
            <th style = "vertical-align: middle;">Unit</th>

            <th style = "vertical-align: middle;">Quantity</th>
            <th style = "vertical-align: middle;" scope="col" class="text-nowrap" colspan="2">
              Base Price (In Rs.)<span class="text-danger"> *</span>
            </th>
            <th style = "vertical-align: middle;" scope="col" colspan="2">
              GST<span class="text-danger"> *</span>
            </th>
            <th style = "vertical-align: middle;" scope="col" colspan="2" class="text-nowrap" *ngIf="isShown">
              Additional CESS (In Rs.)<span class="text-danger"> *</span>
            </th>
            <th style = "vertical-align: middle;" class="text-nowrap" scope="col ">
              Total (In Rs.)<span class="text-danger text-nowrap"> *</span>
            </th>
            <th scope="col" class="text-nowrap" style="width:8%;vertical-align: middle;">
              Action<span class="text-danger"> *</span>
            </th>
          </tr>
        </thead>

        <tbody class="mod">
          <tr>
            <td></td>
            <td>
              <mat-form-field class="example-full-width" appearance="outline">
                <input type="text" id="inp12" placeholder="SKU" (input)="onSearchInputChange($event.target.value)"
                    matInput [formControl]="myControl1" [matAutocomplete]="productAuto">
                <mat-icon class="m-2" matSuffix>search</mat-icon>        
                <mat-autocomplete #productAuto="matAutocomplete">
                    <mat-option *ngFor="let option of productOptions | async" [value]="option.itemName"
                    (onSelectionChange)="getGoodsByBarcodeSelected(option.barcode,$event)">
                    <span style="font-size: 12px;">
                    {{option.itemName | titlecase}}/<b>{{ option.barcode}}</b>
                    </span>
                </mat-option>
                </mat-autocomplete>
            </mat-form-field>   
          </td>
            <td>
              <input type="text" matInput placeholder="SKU Description" required />
            </td>

            <td colspan="1">
              <input type="text" matInput readonly placeholder="Unit" required />
            </td>
            <td colspan="1">
              <input type="text" matInput placeholder="Quantity" style="width: 5rem;" required />
            </td>
            <td colspan="1">
              <input type="text" matInput readonly placeholder="Price / Unit" />
            </td>
            <td colspan="1">
              <input type="text" matInput readonly placeholder="Price" />
            </td>
            <td colspan="1">
              <input type="text" matInput readonly required placeholder="%" style="width: 3rem;" />
            </td>
            <td colspan="1">
              <input type="text" matInput readonly required placeholder="In Rs." style="width: 3rem;" />
            </td>
            <td colspan="1" *ngIf="isShown">
              <input type="text" matInput readonly placeholder="%" style="width: 5rem;" required />
            </td>
            <td colspan="1" *ngIf="isShown">
              <input type="text" matInput readonly placeholder="In Rs" style="width: 5rem;" required />
            </td>
            <td>
              <input type="text" matInput readonly placeholder="Amount" required />
            </td>
            <td></td>
          </tr>
          <tr *ngFor="let item of productData;let i=index">
            <td style="vertical-align: middle;">{{ i + 1 }}</td>

            <td>
              {{ item.barcode }}
            </td>
            <td>{{ item.itemName | titlecase}}</td>
            <td class="text-center">
              <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit"
                readonly />
            </td>
            <!-- <td class="">
                <p style="margin-top: 0px!important;">
                  <a class="border-white btn"  style="margin-top: 0px!important;padding: 0px!important;"(click)="changeQuantity('-',i)">-</a>
                  <span class="">{{ item.quantity }}</span>
                  <a class="border-white btn"  style="margin-top: 0px!important;padding: 0px!important;"(click)="changeQuantity('+',i)">+</a>
                </p>
              </td> -->
            <td style="display:flex; flex-direction: row; justify-content: center; align-items: center;">
              <!-- <input type="text" matinput [value]="item.quantity" style="width : 5 rem" /> -->
              <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                (click)="changeQuantity('-',i)">-</a>
              <input type="text" matInput [value]="item.quantity" placeholder="Quantity"
                (change)="changeQuantity('qty',i,$event.target.value)" style="width: 5rem;text-align: center;"
                required />
              <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                (click)="changeQuantity('+',i)">+</a>
            </td>
            <td colspan="1">
              <p class="">
                <!-- {{ (item.pricePerUnit/(1 + item.gst/100)) | currency:'INR':'symbol':'1.2-2'}} -->
                ₹&nbsp;{{ (item.pricePerUnit/(1 + item.gst/100)) | number:'1.2-2'}}
                <!-- {{ ((item.salePrice * item.quantity) - (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                    currency:'INR':'symbol':'1.2-2'}} -->
                <!-- {{ item.salePrice | currency:'INR':'symbol':'1.2-2'}} -->
              </p>
            </td>
            <td colspan="1">
              <p class="">
                <!-- {{ (item.pricePerUnit/(1 + item.gst/100) * item.quantity) | currency:'INR':'symbol':'1.2-2'}} -->
                ₹&nbsp;{{ (item.pricePerUnit/(1 + item.gst/100) * item.quantity) | number:'1.2-2'}}
                <!-- {{ ((item.salePrice * item.quantity) - (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                    currency:'INR':'symbol':'1.2-2'}} -->
                <!-- {{ item.salePrice * item.quantity |
                  currency:'INR':'symbol':'1.2-2'}} -->
              </p>
            </td>
            <td colspan="1">
              <p class="" style="width: 3rem;">
                {{ item.gst }}%
              </p>
            </td>
            <td colspan="1">
              <p class="" style="width: 5rem;">
                <!-- {{ item.gstAmount | currency:'INR':'symbol':'1.2-2' }} -->
                ₹&nbsp;{{ item.gstAmount | number:'1.2-2'}}
              </p>
            </td>
            <td colspan="1" *ngIf="isShown">
              <p class="" style="width: 3rem;">{{ item.additionalCess }}% </p>
            </td>
            <td colspan="1" *ngIf="isShown">
              <p class="" style="width: 5rem;">
                <!-- {{ (item.salePrice * (item.additionalCess / 100)) | currency:'INR':'symbol':'1.2-2'}} -->
                ₹&nbsp;{{ (item.salePrice * (item.additionalCess / 100)) | number:'1.2-2'}}
              </p>
            </td>
            <td>
              <p class="" style="width:100% !important;">
                <!-- {{ item.finalAmount | currency:'INR':'symbol':'1.2-2'}} -->
                ₹&nbsp;{{ item.finalAmount | number:'1.2-2'}}
                <!-- {{ ((item.salePrice * item.quantity) + (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                  currency:'INR':'symbol':'1.2-2'}} -->
              </p>
            </td>
            <td readonly>
              <button type="button" class="text-center btnb" style="width: 3rem!important;"
                (click)="changeQuantity('rm',i)">
                <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip" data-bs-placement="top"
                  title="Delete"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-responsive my-custom-scrollbar justify-content-start row">
      <div class="col-sm-12 col-md-6 col-lg-9"></div>
      <div class="col-sm-12 col-md-6 col-lg-3 table-bordered my-custom-scrollbar">
        <table class="table table-bordered p-5 my-custom-scrollbar">
          <tr>
            <td class="thWidth p-2" style="text-align:left!important">Subtotal</td>
            <td class="p-2" style="width: 50px;" style="text-align:right!important">
              <!-- {{ totalAmount -GstAmount | currency:'INR':'symbol':'1.2-2'}} -->
              ₹&nbsp;{{ totalAmount - GstAmount | number:'1.2-2'}}
            </td>
          </tr>
          <tr>
            <td class="thWidth p-2" style="text-align:left!important">CGST</td>
            <td class="p-2" style="width: 50px;" style="text-align:right!important">
              <!-- {{ GstAmount/2| currency:'INR':'symbol':'1.2-2'}} -->
              ₹&nbsp;{{ GstAmount/2 | number:'1.2-2'}}
            </td>
          </tr>
          <tr>
            <td class="thWidth p-2" style="text-align:left!important">SGST</td>
            <td class="p-2" style="width:50px;" style="text-align:right!important">
              <!-- {{ GstAmount/2 | currency:'INR':'symbol':'1.2-2'}} -->
              ₹&nbsp;{{ GstAmount/2 | number:'1.2-2'}}
            </td>
          </tr>
          <tr>
            <td class="thWidth p-2" style="text-align:left!important">Total</td>
            <td class="p-2" style="width: 50px;" style="text-align:right!important">
              <!-- {{totalAmount| currency:'INR':'symbol':'1.2-2'}} -->
              ₹&nbsp;{{ totalAmount | number:'1.2-2'}}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <!-- <div class="col-12 d-flex p-3 justify-content-end">

      <a class="btn btn-outline-primary" style="margin-left: 10px" type="submit" (click)="SavePurchase()">Save</a>
    </div> -->

  </form>

  <!-- UAE *ngIf="isArabic" -->
  <form [formGroup]="purchaseForm" *ngIf="isArabic">

    <div class="row d-flex justify-content-between">
      <div class=""></div>
      <div class="col-sm-12 col-md-12 col-lg-12 my-2 mt-sm-0 justify-content-end">
        <div class="d-flex justify-content-end">
          <div>
            <label class="form-label" class="form-check-label me-2" [ngClass]="lblColor ? 'labelDark' : 'labelLight'"
              for="inlineCheckbox1" style="font-size: 14px !important;font-weight: 500;">Full Return</label>
          </div>
          <div class="form-check form-switch mb-0">
            <input formControlName="returnType" class="form-check-input" id="inlineCheckbox1" type="checkbox" checked="checked"
              (click)="getRadioBtnValue($event.target.checked)" />
            <label class="form-check-label  me-2" for="inlineCheckbox1"
              style="font-size: 14px !important;font-weight: 500;">Partial Return</label>
          </div>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-4">
        <label class="form-label">Purchase Invoice #<span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline">
          <input type="text" placeholder="Purchase Invoice #" matInput autocomplete="off"
            (keyup.enter)="getInvoiceById($event.target.value)" formControlName="purchaseInvoiceno" id="inputEmail3">
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.purchaseInvoiceno.touched) && f.purchaseInvoiceno.errors?.required">
          Purchase Invoice # Is Required!
        </mat-error>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4">
        <label class="form-label">Purchase Return #<span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline">
          <input type="text" matInput placeholder="Purchase Return #" autocomplete="off"
            (keypress)="numberOnly($event)" formControlName="PurchaseReturnNo" id="inputEmail3">
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.PurchaseReturnNo.touched) && f.PurchaseReturnNo.errors?.required">
          Purchase Return # Is Required!
        </mat-error>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4">
        <label class="form-label" for="Date">Date</label>
        <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline">
          <input matInput required ngModel bsDatepicker placeholder="Receipt Date." [(ngModel)]="todaysDate"
            style="text-transform:uppercase!important;" [maxDate]="today" id="datePicker" [bsConfig]="{
                containerClass: 'theme-dark-blue',
                dateInputFormat: 'DD MMM YYYY',
                showWeekNumbers:false
              }" [ngModelOptions]="{ standalone: true }" (click)="openDatepicker()">
          <mat-icon class="iconcalender">date_range</mat-icon>
        </mat-form-field>
      </div>
    </div>

    <div class="table-responsive mt-2  my-custom-scrollbar ">
      <table class="table table-bordered p-2">
        <thead class="inner-model-table-heading">
          <tr class="font_size font-weight-bold">
            <th style = "vertical-align: middle;" scope="col">#</th>
            <th scope="col" style="width: 10%;vertical-align: middle;">
              SKU<span class="text-danger"> *</span>
            </th>
            <th style = "vertical-align: middle;" class="text-nowrap">SKU Description</th>
            <th style = "vertical-align: middle;">Unit</th>

            <th style = "vertical-align: middle;">Quantity</th>
            <th style = "vertical-align: middle;" scope="col" class="text-nowrap" colspan="2">
              Base Price (In AED)<span class="text-danger"> *</span>
            </th>
            <th style = "vertical-align: middle;" scope="col" colspan="2">
              {{ lang.GST }}<span class="text-danger"> *</span>
            </th>
            <th style = "vertical-align: middle;" scope="col" colspan="2" class="text-nowrap" *ngIf="isShown">
              Additional CESS (IN AED)<span class="text-danger"> *</span>
            </th>
            <th style = "vertical-align: middle;" class="text-nowrap" scope="col ">
              Total (In AED)<span class="text-danger text-nowrap"> *</span>
            </th>
            <th scope="col" class="text-nowrap" style="width:8%;vertical-align: middle;">
              Action<span class="text-danger"> *</span>
            </th>
          </tr>
        </thead>

        <tbody class="mod">
          <tr>
            <td></td>
            <td>
              <input type="text" matInput  placeholder="SKU" (change)="getGoodsByBarcode()"
                [(ngModel)]="barcode" [ngModelOptions]="{standalone: true}" required />
            </td>
            <td>
              <input type="text" matInput placeholder="SKU Description" required />
            </td>

            <td colspan="1">
              <input type="text" matInput readonly placeholder="Unit" required />
            </td>
            <td colspan="1">
              <input type="text" matInput placeholder="Quantity" style="width: 5rem;" required />
            </td>
            <td colspan="1">
              <input type="text" matInput readonly placeholder="Price / Unit" />
            </td>
            <td colspan="1">
              <input type="text" matInput readonly placeholder="Price" />
            </td>
            <td colspan="1">
              <input type="text" matInput readonly required placeholder="%" style="width: 3rem;" />
            </td>
            <td colspan="1">
              <input type="text" matInput readonly required placeholder="In AED" style="width: 3rem;" />
            </td>
            <!-- <td colspan="1" *ngIf="isShown">
              <input type="text" matInput readonly placeholder="%" style="width: 5rem;" required />
            </td>
            <td colspan="1" *ngIf="isShown">
              <input type="text" matInput readonly placeholder="In AED" style="width: 5rem;" required />
            </td> -->
            <td>
              <input type="text" matInput readonly placeholder="Amount" style="max-width: 150px;" required />
            </td>
            <td></td>
          </tr>
          <tr *ngFor="let item of productData;let i=index">
            <td style="vertical-align: middle;">{{ i + 1 }}</td>

            <td>
              {{ item.barcode }}
            </td>
            <td>{{ item.itemName | titlecase}}</td>
            <td class="text-center">
              <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit" readonly />
            </td>
            <!-- <td class="">
                <p style="margin-top: 0px!important;">
                  <a class="border-white btn"  style="margin-top: 0px!important;padding: 0px!important;"(click)="changeQuantity('-',i)">-</a>
                  <span class="">{{ item.quantity }}</span>
                  <a class="border-white btn"  style="margin-top: 0px!important;padding: 0px!important;"(click)="changeQuantity('+',i)">+</a>
                </p>
              </td> -->
            <td style="display:flex; flex-direction: row; justify-content: center; align-items: center;">
              <!-- <input type="text" matinput [value]="item.quantity" style="width : 5 rem" /> -->
              <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                (click)="changeQuantity('-',i)">-</a>
              <input type="text" matInput [value]="item.quantity" placeholder="Quantity"
                (change)="changeQuantity('qty',i,$event.target.value)" style="width: 5rem;text-align: center;"
                required />
              <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                (click)="changeQuantity('+',i)">+</a>
            </td>
            <td colspan="1">
              <p class="">
                <!-- {{ (item.pricePerUnit/(1 + item.gst/100)) | currency:'INR':'symbol':'1.2-2'}} -->
                AED&nbsp;{{ (item.pricePerUnit/(1 + item.gst/100)) | number:'1.2-2'}}
                <!-- {{ ((item.salePrice * item.quantity) - (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                    currency:'INR':'symbol':'1.2-2'}} -->
                <!-- {{ item.salePrice | currency:'INR':'symbol':'1.2-2'}} -->
              </p>
            </td>
            <td colspan="1">
              <p class="">
                <!-- {{ (item.pricePerUnit/(1 + item.gst/100) * item.quantity) | currency:'INR':'symbol':'1.2-2'}} -->
                AED&nbsp;{{ (item.pricePerUnit/(1 + item.gst/100) * item.quantity) | number:'1.2-2'}}
                <!-- {{ ((item.salePrice * item.quantity) - (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                    currency:'INR':'symbol':'1.2-2'}} -->
                <!-- {{ item.salePrice * item.quantity |
                  currency:'INR':'symbol':'1.2-2'}} -->
              </p>
            </td>
            <td colspan="1">
              <p class="" style="width: 3rem;">
                {{ item.gst }}%
              </p>
            </td>
            <td colspan="1" style="max-width:150px;">
              <p class="" style="width: 5rem;">
                <!-- {{ item.gstAmount | currency:'INR':'symbol':'1.2-2' }} -->
                AED&nbsp;{{ item.gstAmount | number:'1.2-2'}}
              </p>
            </td>
            <!-- <td colspan="1" *ngIf="isShown">
              <p class="" style="width: 3rem;">{{ item.additionalCess }}% </p>
            </td>
            <td colspan="1" *ngIf="isShown">
              <p class="" style="width: 5rem;">
                AED&nbsp;{{ (item.salePrice * (item.additionalCess / 100)) | number:'1.2-2'}}
              </p>
            </td> -->
            <td style="max-width: 150px;">
              <p class="">
                <!-- {{ item.finalAmount | currency:'INR':'symbol':'1.2-2'}} -->
                AED&nbsp;{{ item.finalAmount | number:'1.2-2'}}
                <!-- {{ ((item.salePrice * item.quantity) + (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                  currency:'INR':'symbol':'1.2-2'}} -->
              </p>
            </td>
            <td readonly>
              <button type="button" class="text-center btnb" style="width: 3rem!important;"
                (click)="changeQuantity('rm',i)">
                <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip" data-bs-placement="top"
                  title="Delete"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-responsive my-custom-scrollbar justify-content-start row">
      <div class="col-sm-12 col-md-6 col-lg-9"></div>
      <div class="col-sm-12 col-md-6 col-lg-3 table-bordered my-custom-scrollbar">
        <table class="table table-bordered p-5 my-custom-scrollbar">
          <tr>
            <td class="thWidth p-2" style="text-align:left!important">Subtotal</td>
            <td class="p-2" style="width: 50px;" style="text-align:right!important">
              <!-- {{ totalAmount -GstAmount | currency:'INR':'symbol':'1.2-2'}} -->
              AED&nbsp;{{ totalAmount - GstAmount | number:'1.2-2'}}
            </td>
          </tr>
          <tr>
            <td class="thWidth p-2" style="text-align:left!important">{{ lang.CGST }}</td>
            <td class="p-2" style="width: 50px;" style="text-align:right!important">
              <!-- {{ GstAmount/2| currency:'INR':'symbol':'1.2-2'}} -->
              AED&nbsp;{{ GstAmount | number:'1.2-2'}}
            </td>
          </tr>
          <!-- <tr>
            <td class="thWidth p-2" style="text-align:left!important">SGST</td>
            <td class="p-2" style="width:50px;" style="text-align:right!important">
              AED&nbsp;{{ GstAmount/2 | number:'1.2-2'}}
            </td>
          </tr> -->
          <tr>
            <td class="thWidth p-2" style="text-align:left!important">Total</td>
            <td class="p-2" style="width: 50px;" style="text-align:right!important">
              <!-- {{totalAmount| currency:'INR':'symbol':'1.2-2'}} -->
              AED&nbsp;{{ totalAmount | number:'1.2-2'}}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <!-- <div class="col-12 d-flex p-3 justify-content-end">

      <a class="btn btn-outline-primary" style="margin-left: 10px" type="submit" (click)="SavePurchase()">Save</a>
    </div> -->

  </form>
</div>









<!-- <form [formGroup]="purchaseForm">
  <div class="card">
    <div class="card-header bg-light pt-3 pb-3">
      <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
        Purchase Return
      </p>
    </div>
    <div class="progress" style="height: 2px">
      <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
      </div>
    </div>
    <div class="card-body fs--1">
      <div class="row d-flex justify-content-between">
        <div class="col-sm-12 col-md-9 col-lg-3"></div>
        <div class="col-sm-12 col-md-3 col-lg-3 mt-1 mt-sm-0">
          <div class="d-flex justify-content-end">
            <div>
              <label class="form-check-label me-2" [ngClass]="lblColor ? 'labelDark' : 'labelLight'"
                for="inlineCheckbox1">Full Return</label>
            </div>
            <div class="form-check form-switch mb-0">
              <input class="form-check-input" id="inlineCheckbox1" type="checkbox" checked="checked"
                (click)="getRadioBtnValue($event.target.checked)" />
              <label class="form-check-label  me-2" for="inlineCheckbox1">Partial Return</label>
            </div>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <mat-form-field style="font-size: 11px" class="example-full-width" appearance="outline">
            <mat-label>Purchase Invoice Number</mat-label>
            <input type="text" placeholder="Purchase Invoice Number." matInput (change)="getInvoiceById($event.target.value)"
            formControlName="purchaseInvoiceno" id="inputEmail3">
          </mat-form-field>
        </div>


        <div class="col-sm-4">
          <mat-form-field style="font-size: 11px" class="example-full-width" appearance="outline">
            <mat-label>Purchase Return Number</mat-label>
            <input type="text" matInput placeholder="Purchase Return Number"
            (keypress)="numberOnly($event)" formControlName="PurchaseReturnNo" id="inputEmail3">
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-form-field style="font-size: 11px" class="example-full-width" appearance="outline">
          <mat-label for="Date">Date</mat-label>
            <input matInput required ngModel bsDatepicker placeholder="Receipt Date." [(ngModel)]="todaysDate"
            style="text-transform:uppercase!important;" [maxDate]="today" id="datePicker" [bsConfig]="{
                containerClass: 'theme-dark-blue',
                dateInputFormat: 'DD MMM YYYY',
                showWeekNumbers:false
              }" [ngModelOptions]="{ standalone: true }">
            <mat-icon class="iconcalender">date_range</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <div class="table-responsive mt-2  my-custom-scrollbar ">
        <table class="table table-bordered p-2">
          <thead class="thead-light">
            <tr class="font_size font-weight-bold text-center">
                <th scope="col">#</th>
                <th scope="col" style="width: 10%;">
                    SKU<span class="text-danger"> *</span>
                </th>
                <th class="text-nowrap">SKU Description</th>
                <th>Unit</th>

                <th>Quantity</th>
                <th scope="col" class="text-nowrap" colspan="2">
                    Base Price (In Rs.)<span class="text-danger"> *</span>
                </th>
                <th scope="col" colspan="2">
                    GST<span class="text-danger"> *</span>
                </th>
                <th scope="col" colspan="2" class="text-nowrap" *ngIf="isShown">
                    Additional CESS (IN INR)<span class="text-danger"> *</span>
                </th>
                <th class="text-nowrap" scope="col ">
                    Total (In Rs.)<span class="text-danger text-nowrap"> *</span>
                </th>
                <th scope="col" class="text-nowrap" style="width:8%;">
                    Action<span class="text-danger"> *</span>
                </th>
            </tr>
        </thead>

          <tbody class="mod">
            <tr>
              <td>#</td>
              <td>
                <input type="text" matInput style="width: 100px;" placeholder="SKU" (change)="getGoodsByBarcode()"
                [(ngModel)]="barcode" [ngModelOptions]="{standalone: true}"  required />
              </td>
              <td>
                <input type="text" matInput placeholder="SKU Description" required />
            </td>

              <td colspan="1">
                <input type="text" matInput readonly placeholder="Unit" style="width: 5rem;" required />
              </td>
              <td colspan="1">
                <input type="text" matInput placeholder="Quantity" style="width: 5rem;" required />
              </td>
              <td colspan="1">
                <input type="text" matInput readonly placeholder= "Price / Unit" />
              </td>
              <td colspan="1">
                <input type="text" matInput readonly placeholder="Price" />
              </td>
              <td colspan="1">
                <input type="text" matInput readonly required placeholder="%" style="width: 3rem;" />
              </td>
              <td colspan="1">
                <input type="text" matInput readonly required placeholder="IN Rs." style="width: 3rem;" />
              </td>
              <td colspan="1" *ngIf="isShown">
                <input type="text" matInput readonly placeholder="%" style="width: 5rem;" required />
              </td>
              <td colspan="1" *ngIf="isShown">
                <input type="text" matInput readonly placeholder="In Rs" style="width: 5rem;" required />
              </td>
              <td>
                <input type="text" matInput readonly placeholder="Amount" required />
              </td>
              <td></td>
            </tr>
            <tr *ngFor="let item of productData;let i=index">
              <td>

              </td>
              <td>
                {{ item.barcode }}
              </td>
              <td>{{ item.itemName | titlecase}}</td>
              <td class="text-center">
                <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit"
                  style="width: 5rem;" readonly />
              </td>

              <td style="display:flex; flex-direction: row; justify-content: center; align-items: center;">
                <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                  (click)="changeQuantity('-',i)">-</a>
                <input type="text" matInput [value]="item.quantity" placeholder="Quantity"
                  (change)="changeQuantity('qty',i,$event.target.value)" style="width: 5rem;" required />
                <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                  (click)="changeQuantity('+',i)">+</a>
              </td>
              <td colspan="1">
                <p class="">
                  {{ item.pricePerUnit/(1 + item.gst/100) |
              currency:'INR':'symbol':'1.2-2'}}

                </p>
              </td>
              <td colspan="1">
                <p class="">
                  {{ item.pricePerUnit/(1 + item.gst/100) *item.quantity  |
                    currency:'INR':'symbol':'1.2-2'}}

                </p>
              </td>
              <td colspan="1">
                <p class="" style="width: 3rem;">
                  {{ item.gst }}%
                </p>
              </td>
              <td colspan="1">
                <p class="" style="width: 5rem;">
                  {{ item.gstAmount | currency:'INR':'symbol':'1.2-2' }}
                </p>
              </td>
              <td colspan="1" *ngIf="isShown">
                <p class="" style="width: 3rem;">{{ item.additionalCess }}% </p>
              </td>
              <td colspan="1" *ngIf="isShown">
                <p class="" style="width: 5rem;">
                  {{ (item.salePrice * item.additionalCess / 100) | currency:'INR':'symbol':'1.2-2'}}
                </p>
              </td>
              <td>
                <p class="" style="width:100% !important;">
                  {{ item.finalAmount | currency:'INR':'symbol':'1.2-2'}}

                </p>
              </td>
              <td readonly>
                  <button type="button" class="text-center btnb" style="width: 3rem!important;"
                      (click)="changeQuantity('rm',i)">
                      <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip"
                          data-bs-placement="top" title="Delete"></span>
                  </button>
              </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table-responsive my-custom-scrollbar justify-content-start row">
        <div class="col-sm-12 col-md-6 col-lg-9"></div>
        <div class="col-sm-12 col-md-6 col-lg-3 table-bordered my-custom-scrollbar">
          <table class="table table-bordered p-5 my-custom-scrollbar">
            <tr>
              <td class="thWidth p-2" style="text-align:left!important">Subtotal</td>
              <td class="p-2" style="width: 50px;" style="text-align:right!important">{{ totalAmount -GstAmount |
                currency:'INR':'symbol':'1.2-2'}}</td>
            </tr>
            <tr>
              <td class="thWidth p-2" style="text-align:left!important">CGST</td>
              <td class="p-2" style="width: 50px;" style="text-align:right!important">{{ GstAmount/2|
                currency:'INR':'symbol':'1.2-2'}}</td>
            </tr>
            <tr>
              <td class="thWidth p-2" style="text-align:left!important">SGST</td>
              <td class="p-2" style="width:50px;" style="text-align:right!important">{{ GstAmount/2 |
                currency:'INR':'symbol':'1.2-2'}}</td>
            </tr>
            <tr>
              <td class="thWidth p-2" style="text-align:left!important">Total</td>
              <td class="p-2" style="width: 50px;" style="text-align:right!important">{{totalAmount|
                currency:'INR':'symbol':'1.2-2'}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="col-12 d-flex p-3 justify-content-end">

      <a class="btn btn-outline-primary" style="margin-left: 10px" type="submit"
        (click)="SavePurchase()">Save</a>
    </div>
  </div>
</form> -->
