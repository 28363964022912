import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/core/services/product.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-product-service-list',
  templateUrl: './product-service-list.component.html',
  styleUrls: ['./product-service-list.component.scss']
})
export class ProductServiceListComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public serviceData: any = [];
  public serviceToggle: boolean;
  type="service";
  constructor(private productService: ProductService, private router: Router, private toastService: ToastNotificationService) { }

  ngOnInit(): void {
    this.getServiceByUser();
  }
 

  getServiceByUser() {
  }

  deleteServiceId(productId) {
    this.productService.deleteProductById({}, productId).then((res) => {
      // this.toastService.openSnackBar("Product Deleted Successfully!!!");
      this.toastService.toastMsg({
        title: "Success",
        content: "Product Deleted Successfully!!!",
      });
      this.getServiceByUser();
    })
  }
  updateProductId(id) {
    this.router.navigate(["/pages/product-service-update-list/" + id])
  }
}
