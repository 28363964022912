import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AllUsersService } from 'src/app/core/services/all-users.service';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddRoleBasedUsersComponent } from '../../add-role-based-users/add-role-based-users.component';
import { EmployeeDetailsComponent } from '../../Employee/employee-details/employee-details.component';
import { ViewEmployeeComponent } from '../../Employee/view-employee/view-employee.component';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-employee-roles',
  templateUrl: './employee-roles.component.html',
  styleUrls: ['./employee-roles.component.scss']
})
export class EmployeeRolesComponent implements OnInit {
  public userData: any = [];
  public addressData: any[] = [];
  public roleList;
  public roleList2 = [];
  allUsers: any;
  userEmp: any;
  userId: any;
  email: any;
 empId: string = "";
 public searchKey: any;
 public currentPageNo: number = 0;
 public partyPageSize: number = 5;
 public p = 1;
 public totalrow: any;
 public toatlPages: any;
 public pagesize: number = 1;

  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  excelFileUpload: any;
  public checkSingleArray: any[] = [];
  public selectedEmployeeIds: any[] = [];
  public isChecked: boolean = false;
  public upiCheckedId = [];
  public allSelect: boolean = false;
  public checkSelect: boolean = false;
  public checkSelectsingle:boolean =false;
  form: FormGroup;

  constructor(public rolesPermissionsService: RolesPermissionsService,
    public allUsersService: AllUsersService, public dialog: MatDialog,
    private toastService: ToastNotificationService, private formBuilder: FormBuilder,
    private titleCasePipe: TitleCasePipe,
    private router: Router,
    public route: ActivatedRoute,
    private modalService: NgbModal) { }
  public selectedClient: any
  public value: any
  ngOnInit(): void {
    this.getRole();
    this.getUsersList()
 
    this.rolesPermissionsService.userAdded$.subscribe(() => {

      this.getUsersList();
    });
    this.form = this.formBuilder.group({
      checkArray: this.formBuilder.array([]),
    });
  }
  //get users
  getUsersList() {
    this.rolesPermissionsService.getUsers({}, this.currentUser.id, this.partyPageSize, (this.currentPageNo + 1)).then((res:any) => {
      this.userData = res.data.pageData;
      this.p = res.data.currentPage;
      this.totalrow = res.data.totalrows;
      this.toatlPages = res.data.totalPages
      console.log(this.userData)
    })
  }

  getUsersListSearch(searchKey) {
    if (this.searchKey == "") {
      this.toastService.toastMsg({
        title: "Error",
        content: "Please Enter Valid Employee Name!!!",
      });
      // this.toastService.openErrorSnackBar("Please Enter Valid Employee Name!!!");
      this.rolesPermissionsService.getUsersSearch({}, this.currentUser.id,searchKey).then((res) => {
        this.userData = res.data.pageData;
      });
    }
    else{
      this.rolesPermissionsService.getUsersSearch({}, this.currentUser.id,searchKey).then((res) => {
        this.userData = res.data.pageData;
        if(this.userData.length > 0){
        }else{
          this.toastService.toastMsg({
            title: "Error",
            content: "Record Not Found Successfully!!!",
          });
          // this.toastService.openErrorSnackBar("Record Not Found Successfully!!!");
        }
      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })
    }
    
  }



  setValue(value) {
    this.value = value
  }
  //get list of all roles
  public getRole() {
    this.rolesPermissionsService.getRoles({}, this.currentUser.id).then((response => {
      this.roleList = response.data;
    }))
  }
  deleteUser() {
    let body = {
      "email": this.email
    }
    this.rolesPermissionsService.deleteUserId(body, this.currentUser.id).then((res) => {
      // this.toastService.openSnackBar("User Deleted Successfully!!!");
      this.toastService.toastMsg({
        title: "Success",
        content: "User Deleted Successfully!!!",
      });
      this.getUsersList()
      this.getRole();
      this.checkSelectsingle = false;
      this.upiCheckedId = [];
      this.checkSelect=false;
      this.allSelect=false;
    }, (err) => {
      if(err.error.error_message === "Cannot delete user!") {
        this.toastService.toastMsg({
          title: "Error",
          content: "Trying To Delete User Which Is Not Created By Client!",
        });
        // this.toastService.openErrorSnackBar("Trying to delete user which is not created by client!");
      }else if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");      
      }
    })
  }


  getUserRole(id: any, email: any) {
    this.userId = id;
    this.email = email;
  }
  assignRole() {
    let data = {
      roles: []
    }

    this.selectedClient.map((e) => {
      data.roles.push({
        roleId: e
      })
    })
    this.rolesPermissionsService.postAssignRole(this.value, data).then((res) => {
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Role Assigned Successfully!!!",
        });
        // this.toastService.openSnackBar("Role Assigned Successfully")
      }
    },

      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");        
        }

      })

  }

  openDialog(): void {
    const dialogRef: MatDialogRef<AddRoleBasedUsersComponent> = this.dialog.open(AddRoleBasedUsersComponent, {
      width: 'auto',
      height : '100%',
      data: { },
      panelClass: 'custom-dialog-add-employee',
      disableClose:true,
      position: {
        right: '0',
      },

    });
    document.body.classList.add('dialog-overlay');
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  updateModal(itemId): void {
    const dialogRef: MatDialogRef<EmployeeDetailsComponent> = this.dialog.open(EmployeeDetailsComponent, {
      width: 'auto',
      height : '100%',
      data: { itemId: itemId },
      panelClass: 'custom-dialog-update-employee',
      disableClose:true,
      position: {
        right: '0',
      },

    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  viewModal(itemId): void {
    const dialogRef: MatDialogRef<ViewEmployeeComponent> = this.dialog.open(ViewEmployeeComponent, {
      width: 'auto',
      height : '100%',
      data: { itemId: itemId },
      panelClass: 'custom-dialog-view-employee',
      disableClose:true,
      position: {
        right: '0',
      },

    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  handleInputChange(e){
    this.excelFileUpload= e.target.files[0];
    const pattern = /\.(xlsx|xls)$/i; // Pattern to match ".xlsx" or ".xls" extension
    if (!pattern.test(this.excelFileUpload.name)) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Invalid Format. Please Select An Excel File.",
      });
      // this.toastService.openErrorSnackBar("Invalid format. Please select an Excel file.");
      return;
    }
    if(this.excelFileUpload){
      let formdata = new FormData();
      console.log(this.excelFileUpload)
      formdata.set("file", this.excelFileUpload)
      this.rolesPermissionsService.uploadEmployee(formdata, this.currentUser.id).then((res) => {
        // this.toastService.openSnackBar("Excel Uploaded Successfully")
        this.toastService.toastMsg({
          title: "Success",
          content: "Excel Uploaded Successfully!!!",
        });
        this.rolesPermissionsService.notifyUserAdded();   // this service will be called for update the user list 
      },(err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else if(err.error.status == "409" && err.error.expose == false){
          this.toastService.toastMsg({
            title: "Error",
            content: "List Of Employees Already Exists",
          });
          // this.toastService.openErrorSnackBar("List Of Employees Already Exists");
        }
        else{
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      });
    }
    }

    startUpload(){
      // document.getElementById('uploadInput').click();
      var uploadInput = document.getElementById('uploadFile');
      if (uploadInput) {
        var event = new MouseEvent('click', {
          view: window,
          bubbles: false,
          cancelable: true
        });
        uploadInput.dispatchEvent(event);
      } else {
        console.log("Upload element not found");
      }
    }

    onChekedsingleCheck(e, partyId){
      if (e.target.checked) {
        this.selectedEmployeeIds.push(partyId);
      } else {
        const index = this.selectedEmployeeIds.indexOf(partyId);
        if (index !== -1) {
          this.selectedEmployeeIds.splice(index, 1);
        }
      }
      // const checkArray: FormArray = this.form.get('checkArray') as FormArray;
      if (e.target.checked) {
        this.checkSingleArray.push(e.target.value);
      }
      else {
        this.checkSingleArray = [];
      }
   
    }
    
    getSingleCheckedId(employeeId){
      this.userId = employeeId;
    }
    
    onChekMultiple($event){
      const isChecked = $event.target.checked;
      if (isChecked) {
        this.selectedEmployeeIds = this.userData.map(item => item.id);
      } else {
        this.selectedEmployeeIds = [];
      }
      this.checkSelect = isChecked; // Update the checkbox state in the header

      let id = $event.target.value;
      this.isChecked = $event.target.checked;
      this.upiCheckedId = this.userData.map((item) => {
        if (id == -1) {
          if (this.isChecked == true) {
            this.checkSelectsingle = true;
            return item.id;
          }
          else {
            this.checkSelectsingle = false;
            this.upiCheckedId = [];
          }
        }
      })
    }
    
    getUPIcheckId(employeeId){
      this.userId = employeeId;
    }  
    
    multipleEmployeeDel(){
      const selectedEmployeeEmails = this.selectedEmployeeIds.map(employeeId => {
        const employee = this.userData.find(emp => emp.id === employeeId);
        return employee ? employee.email : '';
      });
      
      const requestBody = {
        employeeEmail: selectedEmployeeEmails
      };
      console.log(requestBody);
      this.rolesPermissionsService.deleteMultipleEmp(requestBody, this.currentUser.id).then((result) => {
        this.getUsersList();
        this.toastService.toastMsg({
          title: "Success",
          content: "Employees Deleted Successfully!!!",
        });
        // this.toastService.openSnackBar("Employees Deleted Successfully!!!");
        this.selectedEmployeeIds = []; // Clear the selected employee IDs after deletion
        this.checkSelect = false // Reset the checkbox state in the header
      }, (err) => {
        if(err.error.error_message === "Cannot delete user!") {
          this.toastService.toastMsg({
            title: "Error",
            content: "Trying To Delete User Which Is Not Created By Client",
          });
          // this.toastService.openErrorSnackBar("Trying to delete user which is not created by client!");
        }else if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");      
        }
      });
    }

    showDeleteButton(): boolean {
      return this.selectedEmployeeIds.length > 0;
    }
    handlePage(e) {
      this.currentPageNo = e.pageIndex;
      this.pagesize = e.pageSize;
      if (this.userData.length > 0) {
        this.partyPageSize = e.pageSize;
        
      const searchInput = document.getElementById('searchInput') as HTMLInputElement;
      if (searchInput.value !== '') {
        this.getUsersListSearch(searchInput.value);
      } else {
        this.getUsersList();
      }
      }
    }
  
}
