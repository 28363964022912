<div class="modal-header pt-0">
    <h1 style="color: #EB8B3F; font-weight: bolder;margin-left:5px;" class="modal-title mb-0">Sale Invoice</h1>
    <div class="addProductButtons">
      <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel"
        (click)="onCancelModal()">Cancel</button>
      <button type="button" class="btn btn-save" (click)="postSaleInvoice()">Save</button>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="SaleInvoiceForm" *ngIf="!isArabic">
      <div class="row d-flex justify-content-start mb-2">
        <div class="col-sm-12 d-flex" *ngIf="!showInvoiceGenerateData">
          <div class="d-flex" style="font-size: 14px;padding-left: 5px;">
            <div class="form-check padding-left">
              <input class="" style="margin-left: 0px!important" id="inlineCheckbox1" type="checkbox"
                formControlName="cashSale" (change)="isCashSaleToggle($event.target.checked)"
                [disabled]="showInvoiceGenerateData" />
            </div>
            <label class="text-nowrap saleText form-label" style="margin-left:4px!important">Sale/Cash</label>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-between">
        <!-- Transaction Type -->
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" *ngIf="!showInvoiceGenerateData && !isCashSale">
          <label class="form-label">Transaction Type</label>
          <mat-form-field style=" font-size: 11px" class="example-full-width" appearance="outline">
            <mat-select disableOptionCentering aria-label="Default select example" formControlName="credit" required
              (selectionChange)="getCredit($event)" placeholder="Transaction Type" [ngClass]="{
              'is-invalid': (f.credit.invalid && f.credit.touched) && submitted }">
              <mat-option value="false">Cash</mat-option>
              <mat-option value="true">Credit</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="(submitted || f.credit.touched) && f.credit.errors?.required">
            Transaction Type Is Required!
          </mat-error>
        </div>
  
        <!-- Select Purchase Invoice For RCM -->
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
          <div class="d-flex mb-2" style="justify-content: flex-end;">
            <div class="" style="margin-right: 20px;">
              <label class="form-label">Select Purchase Invoice For RCM </label>
            </div>
            <div class="form-check form-switch">
              <input formControlName="reverseCharge" class="form-check-input" id="inlineCheckbox1" type="checkbox"
                [disabled]="showInvoiceGenerateData" />
            </div>
          </div>
        </div>
      </div>
  
      <div class="row d-flex justify-content-start">
        <!-- Party Name -->
        <div class="col-md-4 " *ngIf="!isCashSale">
          <label class="form-label">Vendor Name<span class="error-asterisk">*</span></label>
          <mat-form-field style="font-size: 11px" class=" example-full-width" appearance="outline">
            <input type="text" placeholder="Vendor Name" aria-label="Select Vendor Name" matInput [formControl]="myControl"
              (keyup)="searchPartyByName($event.target.value)" [matAutocomplete]="auto" [ngClass]="{
                      'is-invalid':
                        myControl.invalid && myControl.touched && submitted
                    }" required>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option>
                <a class="mobVerify" style="display: inline-block; width: 100%;" aria-hidden="true" title="Vendor"
                  (click)="openAddPartyModal(addParty)">
                  Add new Vendor
                </a>
              </mat-option>
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
                (onSelectionChange)="setSelectedPartyDeatails(option)">
                {{ option.partyName | titlecase }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="
                (submitted || myControl.touched) && myControl.errors?.required
              ">
            Vendor Is Required!
          </mat-error>
        </div>
  
        <!-- Gst number -->
        <div class="col-md-4 justify-content-between" *ngIf="!isCashSale">
          <label class="form-label">GSTIN<span class="error-asterisk">*</span></label>
          <mat-form-field style="font-size: 11px" class="example-full-width" appearance="outline">
            <input type="text" matInput placeholder="GSTIN" formControlName="gstNo" style="text-transform:uppercase;"
              required [ngClass]="{
                    'is-invalid': (f.gstNo.invalid && f.gstNo.touched) }" maxlength="15" minlength="15"
              pattern="^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$">
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="(submitted ||f.gstNo.touched) && f.gstNo.errors?.required">
            Enter a Valid GSTIN.
          </mat-error>
          <mat-error *ngIf="(submitted ||f.gstNo.touched) && f.gstNo.errors?.pattern || f.gstNo.errors?.minlength">
            Enter a Valid GSTIN.
          </mat-error>
          <mat-error *ngIf="(submitted ||f.gstNo.touched) && f.gstNo.errors?.maxlength">
            Enter a Valid GSTIN.
          </mat-error>
        </div>
  
        <!-- Tax Invoice No -->
        <div class="col-md-4 justify-content-end">
          <label class="form-label">Tax Invoice #</label>
          <mat-form-field style="font-size: 12px;" class="example-full-width" appearance="outline">
            <input type="text" placeholder="Enter Tax Invoice #." matInput formControlName="invoiceNo"
              [disabled]="showInvoiceGenerateData" [ngClass]="{
              'is-invalid': (f.invoiceNo.invalid && f.invoiceNo.touched) && submitted }" required>
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="(submitted || f.invoiceNo.touched) && f.invoiceNo.errors?.required">
            Tax Invoice Is Required!
          </mat-error>
        </div>
      </div>
  
      <div class="row d-flex justify-content-start">
        <!-- Invoice date -->
        <div class="col-md-4">
          <label class="form-label">Invoice Date</label>
          <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
            <input matInput required ngModel bsDatepicker placeholder="Receipt Date." [maxDate]="today"
              style="text-transform:uppercase!important;" [(ngModel)]="todaysDate" id="datePicker" bsDatepicker
              [bsConfig]="{
          containerClass: 'theme-dark-blue',
          dateInputFormat: 'DD MMM YYYY',
          showWeekNumbers:false }" [ngModelOptions]="{ standalone: true }" [ngClass]="{
          'is-invalid': (todaysDate.invalid && todaysDate.touched) && submitted }" (click)="openDatepicker()">
            <span>
              <mat-icon class="iconcalender">date_range</mat-icon>
            </span>
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="(submitted || f.invoiceDate.touched) && f.invoiceDate.errors?.required">
            Invoice Date Is Required!
          </mat-error>
        </div>


        <div [ngSwitch]="currentUser.userType"  class=" col-xs-12 col-sm-12 col-md-6 col-lg-4">
          <div *ngSwitchCase="'CLIENT'" style="font-size: 14px">
            <label class="form-label">Warehouse</label>
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse" (selectionChange)="onWarehouseSelectionChange($event)">
                <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                  {{warehouse.firstName}}
                </mat-option>
                <mat-option value="clear">Clear Selection</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
  
          <div *ngSwitchCase="'SHOP'" style="font-size: 14px">
            <label class="form-label">Warehouse</label>
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse" (selectionChange)="onWarehouseSelectionChange($event)">
                <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                  {{warehouse.firstName}}
                </mat-option>
                <mat-option value="clear">Clear Selection</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
  
        <!-- e-Way Bill No. -->
        <div class="col-md-4 justify-content-between">
          <label class="form-label">e-Way Bill #<span class="error-asterisk">*</span></label>
          <mat-form-field style="font-size: 11px" class="example-full-width" appearance="outline">
            <input type="text" required matInput [textMask]="{mask: mask}" placeholder="Enter e-way Bill #."
              formControlName="ewayBillNo" [disabled]="showInvoiceGenerateData" [ngClass]="{
                'is-invalid': (f.ewayBillNo.invalid && f.ewayBillNo.touched) && submitted }">
          </mat-form-field>
          <mat-error class="text-danger"
            *ngIf="(submitted || f.ewayBillNo.touched) && f.ewayBillNo.errors?.required">
            e-Way Bill # Is Required!
          </mat-error>
        </div>
  
        <!-- Shipped From -->
        <div class="col-md-4 justify-content-between">
          <label class="form-label">Shipped From(State)</label>
          <mat-form-field style=" font-size: 11px" class="example-full-width" appearance="outline">
            <mat-select formControlName="shippedFrom" disableOptionCentering aria-label="Default select example"
              placeholder="Select State" [disabled]="showInvoiceGenerateData">
              <mat-option [value]="state" *ngFor="let state of allStates">{{ state }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row d-flex justify-content-start">
        <!-- Billing Address -->
        <div class="col-sm-12 col-md-4 col-lg-4">
          <label class="form-label">Billing Address<span class="error-asterisk">*</span></label>
          <mat-form-field class="example-full-width " style=" font-size: 11px" appearance="outline">
            <textarea matInput rows="5" placeholder="Enter Billing Address" formControlName="billingAddress" required
              disabled [ngClass]="{
              'is-invalid': (f.billingAddress.invalid && f.billingAddress.touched) && submitted }"></textarea>
          </mat-form-field>
          <mat-error class="text-danger"
            *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
            Billing Address Is Required!
          </mat-error>
        </div>
        <!-- Shipping Address -->
        <div *ngIf="isShippingAddress" class="col-sm-12 col-md-4 col-lg-4">
          <label class="form-label">Shipping Address</label>
          <mat-form-field class="example-full-width" style=" font-size: 11px" appearance="outline">
            <textarea matInput rows="5" placeholder="Enter Shipping Address" formControlName="shippingAddress"
              [disabled]="showInvoiceGenerateData"></textarea>
          </mat-form-field>
        </div>
      </div>
  
      <div class="row container d-flex justify-content-start">
        <div class="col-md-4 d-flex" style="font-size: 12px;">
          <div class="form-check padding-left">
            <input class="form-check-input" id="inlineCheckbox1" type="checkbox" [disabled]="showInvoiceGenerateData"
              (change)="showShippingAddress($event.target.checked)" [checked]="isShippingAddress" />
          </div>
          <label style="margin-left:1px!important" class=" form-label">Add Shipping Address</label>
  
        </div>
      </div>
  
      <!-- Delete a -->
      <div class="problem mt-3 mb-2">
        <ul class="problem-nav">
          <li>
            <div class="d-flex justify-content-between mt-1" style="font-size: 14px;">
              <label class="form-label">Add Compensation Cess </label>
              <div class="form-check form-switch" style="font-size: 14px;">
                <input class="form-check-input toggleB mt-1" style="margin-left: 10px!important;" id="inlineCheckbox1"
                  type="checkbox" (click)="toggleShow()" />
              </div>
            </div>
          </li>
        </ul>
      </div>
  
      <!-- Product Table for Sale Invoice-->
      <div class="table-responsive my-custom-scrollbar " *ngIf="!showInvoiceGenerateData">
        <table class="table bg-white table-bordered table-stripped">
          <thead class="table inner-model-table-heading">
            <tr class="font_size font-weight-bold">
              <th scope="col" style="width: 7% !important;vertical-align: middle;">
                #
              </th>
              <th scope="col" style="width: 10%;vertical-align: middle;">
                SKU<span class="text-danger"> *</span>
              </th>
              <th class="text-nowrap" style = "vertical-align: middle;" scope="col">
                SKU Description
              </th>
              <th style = "vertical-align: middle;">Unit</th>
  
              <th style = "vertical-align: middle;">Quantity</th>
              <th style = "vertical-align: middle;" scope="col" class="text-nowrap" colspan="2">
                Base Price(In Rs.)<span class="text-danger"> *</span>
              </th>
  
              <th style = "vertical-align: middle;" class="text-center" scope="col" colspan="2">
                GST<span class="text-danger"> *</span>
              </th>
              <th style = "vertical-align: middle;" scope="col" colspan="2" class="text-nowrap" *ngIf="isShown">
                Additional Cess(In Rs.)<span class="text-danger"> *</span>
              </th>
              <th style = "vertical-align: middle;" scope="col" class="text-nowrap">
                Total(In Rs.)<span class="text-danger"> *</span>
              </th>
              <th scope="col" class="text-nowrap" style="width:8%;vertical-align: middle;">
                Action<span class="text-danger"> *</span>
              </th>
  
            </tr>
          </thead>
  
          <!-- <tbody class="mod">
            <tr>
              <td style = "vertical-align: middle;">#</td> -->
              <tbody class="mod">
                <!-- The first row for adding new items -->
                <tr>
                  <td style="vertical-align: middle;"></td>
              <td class="s23323">
                <mat-form-field class="example-full-width" appearance="outline">
                  <input type="text" id="inp12" placeholder="SKU" (input)="onSearchInputChange($event.target.value)"
                    aria-label="Select Vendor Name" matInput [formControl]="myControl1" [matAutocomplete]="auto">
                  <mat-icon class="m-2" matSuffix>search</mat-icon>
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOptions2 | async" [value]="option.itemName"
                      (onSelectionChange)="getGoodsByBarcodeSelected(option.barcode,$event)">
                      <span style="font-size: 12px;"> {{ option.itemName | titlecase }}/<b>{{
                          option.barcode}}</b></span>
  
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
              <td style = "vertical-align: middle;">
                <input type="text" matInput placeholder="SKU Description" required />
              </td>
              <td style = "vertical-align: middle;" colspan="1">
                <input type="text" matInput placeholder="Unit" required />
              </td>
              <td style = "vertical-align: middle;" colspan="1">
                <input type="text" matInput placeholder="Quantity" style="width: 5rem;" required />
              </td>
              <td style = "vertical-align: middle;" colspan="1">
                <input type="text" matInput placeholder="Price/Unit" />
              </td>
              <td style = "vertical-align: middle;" colspan="1">
                <input type="text" matInput placeholder="Price" />
              </td>
              <td style = "vertical-align: middle;" colspan="1">
                <input type="text" matInput required placeholder="%" style="width: 3rem;" />
              </td>
              <td style = "vertical-align: middle;" colspan="1">
                <input type="text" matInput required placeholder="In Rs." style="width: 3rem;" />
              </td>
              <td style = "vertical-align: middle;" colspan="1" *ngIf="isShown">
                <input type="text" matInput placeholder="%" style="width: 5rem;" required />
              </td>
              <td style = "vertical-align: middle;" colspan="1" *ngIf="isShown">
                <input type="text" matInput placeholder="In Rs." style="width: 5rem;" required />
              </td>
              <td style = "vertical-align: middle;">
                <input type="text" matInput placeholder="Amount" required />
              </td>
              <td style = "vertical-align: middle;"></td>
            </tr>
            <ng-container formArrayName="subcheckbox"
              *ngFor="let items of formArr.controls;let item of productData;let i=index">
              <tr [formGroupName]="i">
                <td style="vertical-align: middle;">{{ i + 1 }}</td>
                <td style = "vertical-align: middle;">
                  {{ item.barcode }}
                </td>
                <td style = "vertical-align: middle;">{{ item.itemName | titlecase}}</td>
                <td style = "vertical-align: middle;" class="text-center">
                  <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit"
                     readonly />
                </td>
                <td style="display:flex; flex-direction: row; justify-content: center; align-items: center;vertical-align: middle;">
                  <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                    (click)="changeQuantity('-',i)">-</a>
                  <input type="text" matInput [value]="item.quantity" placeholder="Quantity"
                    (change)="changeQuantity('qty',i,$event.target.value)" style="width: 5rem;text-align: center;"
                    required />
                  <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                    (click)="changeQuantity('+',i)">+</a>
                </td>
                <td style = "vertical-align: middle;" colspan="1">
                  <p class="">
                    ₹&nbsp;{{item.basePrice | number:'1.2-2'}}
                  </p>
                </td>
                <td style = "vertical-align: middle;" colspan="1">
                  <p class="">
                    ₹&nbsp;{{item.subTotal | number:'1.2-2'}}
                  </p>
                </td>
                <td style = "vertical-align: middle;" colspan="1">
                  <p class="" style="width: 3rem;">
                    {{ item.gstRate }}%
                  </p>
                </td>
                <td style = "vertical-align: middle;" colspan="1">
                  <p class="" style="width: 5rem;">
                    ₹&nbsp;{{item.gstAmount | number:'1.2-2'}}
                  </p>
                </td>
                <td style = "vertical-align: middle;" colspan="1" *ngIf="isShown">
                  <p class="" style="width: 3rem;">{{ item.additionalCess }}% </p>
                </td>
                <td style = "vertical-align: middle;" colspan="1" *ngIf="isShown">
                  <p class="" style="width: 5rem;">
                    ₹&nbsp;{{(item.salePrice * item.additionalCess / 100) | number:'1.2-2'}}
                  </p>
                </td>
                <td style = "vertical-align: middle;">
                  <p class="" style="width:100% !important;">
                    ₹&nbsp;{{item.total | number:'1.2-2'}}
                  </p>
                </td>
  
                <td style = "vertical-align: middle;">
                  <button type="button" class="text-center btnb" style="width: 3rem!important;"
                    (click)="changeQuantity('rm',i)">
                    <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip" data-bs-placement="top"
                      title="Delete"></span>
                  </button>
                </td>
              </tr>
              <tr *ngIf="item.isOffer">
                <td colspan="2">
                </td>
                <td colspan="3" class="text-success" style="text-align: right;">
                  {{ item.offerData?.schemaName}}
                </td>
                <td colspan="4" class="text-success" style="text-align: right;">
                    ₹&nbsp; - {{ (item?.gstAmount - item.offerData?.originalGst) | number:'1.2-2' }}
                </td>
                <td colspan="1" class="text-success" style="text-align: right;">
                    ₹&nbsp; - {{ (item?.total - item.offerData?.totalDiscountedPrice) | number:'1.2-2' }}
                </td>
                <td></td>
              </tr>
              <tr *ngIf="item.isFreeProduct">
                <td colspan="2">
                </td>
                <td colspan="2" class="text-success" style="text-align: right;">
                  {{ item?.schemaName | uppercase }}
                </td><td colspan="1" class="text-success" style="text-align: center;">
                  {{ item?.freeProductQuantity}}
                </td>
                <td colspan="4" class="text-success" style="text-align: right;">
                    ₹&nbsp; - {{ item?.freeProductTotalGst | number:'1.2-2' }}
                </td>
                <td colspan="1" class="text-success" style="text-align:left;">
                    ₹&nbsp; - {{ item?.freeProductTotalPrice | number:'1.2-2' }}
                </td>
                <td></td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="table-responsive mt-2  my-custom-scrollbar d-flex justify-content-end" *ngIf="!showInvoiceGenerateData">
        <div class="col-sm-6"></div>
  
        <div class="col-sm-4 table-bordered my-custom-scrollbar">
          <table class="table table-bordered  my-custom-scrollbar">
            <tr>
              <td class="thWidth p-2" style="text-align: left;">Subtotal</td>
              <td class="p-2" style="width: 50px;" style="text-align: right;">
                ₹&nbsp; {{ subTotal | number:'1.2-2'}}
              </td>
            </tr>
            <tr *ngIf="amtToMinusFromTotal !== 0 || null || undefined">
              <td class="thWidth text-success p-2" style="text-align: left;">Discounted Subtotal</td>
              <td class="p-2" style="width: 50px;" style="text-align: right;">
                <p style="text-align: right" class="text-success m-0 p-0">
                  (₹&nbsp;{{ - (amtToMinusFromTotal - newGstRate) | number:'1.2-2'}})
                </p>
              </td>
            </tr>
            <tr>
                <td class="thWidth  p-2" *ngIf="gstStateMatch" style="text-align:left!important;">CGST</td>
                <td class="p-2" *ngIf="gstStateMatch" style="width: 50px;" style="text-align:right!important;">
                  ₹&nbsp;{{gstRate/2 | number:'1.2-2'}}
                </td>
              </tr>
              <tr>
                <td class="thWidth  p-2" *ngIf="gstStateMatch" style="text-align:left!important;">SGST</td>
                <td class="p-2" style="width:50px;" *ngIf="gstStateMatch" style="text-align:right!important;">
                  ₹&nbsp;{{gstRate/2 | number:'1.2-2'}}
                </td>
              </tr>
              <tr>
                <td class="thWidth  p-2" *ngIf="!gstStateMatch" style="text-align:left!important;">IGST</td>
                <td class="p-2" *ngIf="!gstStateMatch" style="width: 50px;" style="text-align:right!important;">
                  ₹&nbsp;{{gstRate/2 + gstRate/2 | number:'1.2-2'}}
                </td>
              </tr>
    
              <tr>
                <td class="thWidth  p-2" *ngIf="isShown" style="text-align:left!important;">Additional Cess</td>
                <td class="p-2" style="width:50px;" *ngIf="isShown" style="text-align:right!important;">
                  ₹&nbsp;{{additionalCess | number:'1.2-2'}}
                </td>
              </tr>
            <tr *ngIf="newGstRate !== 0 || null || undefined">
              <td class="thWidth text-success p-2" style="text-align: left;">Discounted GST</td>
              <td class="p-2" style="width: 50px;" style="text-align: right;">
                <p class="text-success m-0 p-0" style="text-align: right">
                  (₹&nbsp;{{ - newGstRate | number:'1.2-2'}})
                </p>
              </td>
            </tr>
            <tr>
              <td class="thWidth  p-2" style="text-align: left;">Total</td>
              <td class="p-2" style="width: 50px;" style="text-align: right;">
                ₹&nbsp;{{ total | number:'1.2-2'}}            
              </td>
            </tr>
            <tr>
              <td class="thWidth p-2" style="text-align: left;" *ngIf="amtToMinusFromTotal !== 0 || null || undefined">Discounted Total</td>
              <td class="p-2" *ngIf="(amtToMinusFromTotal !== 0 || null || undefined && f.counterDiscount.value === (0 || null || undefined || ''))" style="width: 50px;" style="text-align: right;">
                ₹&nbsp;{{ (total - amtToMinusFromTotal) | number:'1.2-2'}}
              </td>            
            </tr>
          </table>
        </div>
      </div>
      <div class="table-responsive mt-2  my-custom-scrollbar d-flex justify-content-end" *ngIf="!showInvoiceGenerateData && showCounterDiscountField">      
        <div class="col-sm-6"></div>
        
        <div class="col-sm-4 table-bordered my-custom-scrollbar">
          <div class="d-flex justify-content-end text-success">
            <p class="counter-text p-2" (click)="getCounterDiscountAmount()" *ngIf="!counterOn">Apply Counter Discount</p>
          </div>
          <div class="d-flex justify-content-end text-success">
            <p class="counter-text p-2" (click)="openCouponModal()" *ngIf="!couponApplied">
              <i class="fas fa-tags"></i> Apply Coupon
            </p>
          </div>
          
          <table class="table table-bordered  my-custom-scrollbar">
            <tr *ngIf="counterOn">
              <td class="thWidth text-success p-2" style="text-align: left;">Counter Discount</td>
              <td class="p-0 text-success" style="text-align: right;">
                <mat-form-field style="font-size: 12px; width: 50%;"  appearance="outline">
                  <span matPrefix  style="font-size: 14px !important">₹&nbsp; -</span>
                  <input type="text" placeholder="Counter Discount" matInput formControlName="counterDiscount" 
                  (keypress)="numberOnly($event)">
                </mat-form-field>
              </td>
            </tr>
            <tr *ngIf="couponApplied">
              <td class="thWidth text-success p-2" style="text-align: left;">Coupon Discount</td>
              <td class="p-0 text-success" style="text-align: right;">
                <ng-container *ngIf="!couponApplied; else appliedCouponBlock" (click)="openCouponModal()">
                  Apply Coupon
                </ng-container>
                <ng-template #appliedCouponBlock>
                  <span (click)="removeCoupon()" class="text-danger" style="cursor: pointer;">Remove</span>
                  <span>₹&nbsp;- {{ appliedCoupon?.discount_amount | number:'1.2-2' }}</span>
                </ng-template>
              </td>
            </tr>
            <tr>
              <td class="thWidth p-2" style="text-align: left;">Final Total</td>
              <td class="p-2" *ngIf="calculateFinalTotal() !== null && calculateFinalTotal() !== undefined" style="width: 50px; text-align: right;">
                ₹&nbsp;{{ calculateFinalTotal() | number:'1.2-2'}}
              </td>
            </tr>          
          </table>
        </div>
      </div>
      <!-- Invoice Generate Table for Remote Orders -->
      <div class="table-responsive my-custom-scrollbar d-flex justify-content-start row" style="margin-right: 0.1rem;"
        *ngIf="showInvoiceGenerateData">
        <table class="table bg-white table-bordered table-stripped">
          <thead class="table-secondary">
            <tr class="font_size font-weight-bold">
              <th scope="col" style="width: 7% !important;">
                #
              </th>
              <th scope="col" style="width: 10%">
                SKU<span class="text-danger"> *</span>
              </th>
              <th scope="col" class="text-nowrap">
                SKU Description
              </th>
              <th>Unit</th>
              <th>Quantity</th>
              <th scope="col" class="text-nowrap" colspan="1">
                Base Price(In Rs.)<span class="text-danger"> *</span>
              </th>
  
              <th class="text-center" scope="col" colspan="2">
                GST<span class="text-danger"> *</span>
              </th>
              <th scope="col" colspan="2" class="text-nowrap">
                Additional Cess(In Rs.)<span class="text-danger"> *</span>
              </th>
              <th scope="col" class="text-nowrap">
                Total(In Rs.)<span class="text-danger"> *</span>
              </th>
            </tr>
          </thead>
          <tbody class="mod" *ngIf="orderData.length > 0">
            <tr *ngFor="let item of orderData;let i=index">
              <td>{{ i + 1 }}</td>
              <td>{{ item.barcode }}</td>
  
              <td>
                {{ item.itemName }}
              </td>
              <td colspan="1">
                <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit"
                  readonly />
              </td>
              <td colspan="1" class="text-center m-auto">
                <p class="">{{ item.quantity }}</p>
              </td>
              <td colspan="1">
                <p class="">₹&nbsp;{{subTotal | number:'1.2-2'}}
                  {{ subTotal | currency:'INR':'symbol':'1.2-2'}}
                </p>
              </td>
              <td colspan="1">
                <p class="" style="width: 5rem;">{{ item.gst }}%</p>
              </td>
              <td colspan="1">
                <p class="" style="width: 5rem;">
                  ₹&nbsp;{{item.gstAmount | number:'1.2-2'}}
                </p>
              </td>
              <td colspan="1">
                <p class="" style="width: 5rem;">{{ item.additionalCess }}% </p>
              </td>
              <td colspan="1">
                <p class="" style="width: 5rem;">
                  ₹&nbsp;{{item.additionalCess | number:'1.2-2'}}
                </p>
              </td>
              <td>
                <p class="">
                  ₹&nbsp;{{item.finalAmount | number:'1.2-2'}}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-responsive my-custom-scrollbar d-flex justify-content-end" *ngIf="showInvoiceGenerateData">
        <div class="col-lg-8 col-sm-6"></div>
        <div class="col-lg-4 col-sm-4 table-bordered my-custom-scrollbar" style="width: 30%;">
          <table class="table table-bordered  my-custom-scrollbar">
            <tr>
              <td style="text-align:left!important;">Subtotal</td>
              <td style="text-align:right!important;">
                ₹&nbsp;{{subTotal | number:'1.2-2'}}
              </td>
            </tr>
            <tr>
              <td style="text-align:left!important;">CGST</td>
              <td style="text-align:right!important;">
                ₹&nbsp;{{totalGST/2 | number:'1.2-2'}}
              </td>
            </tr>
            <tr>
              <td style="text-align:left!important;">SGST</td>
              <td style="text-align:right!important;">
                ₹&nbsp;{{totalGST/2 | number:'1.2-2'}}
              </td>
            </tr>
            <tr>
              <td style="text-align:left!important;">Total</td>
              <td style="text-align:right!important;">
                ₹&nbsp;{{total | number:'1.2-2'}}
              </td>
            </tr>
          </table>
        </div>
      </div>
  
  
      <!-- as (Save/Print/Close) -->
      <div class="col-12 d-flex  justify-content-end">
        <div class="" *ngIf="!showInvoiceGenerateData">
        </div>
      
        <a type="button" class="  btn btn-outline-primary" style="margin-left: 10px;"
          *ngIf="showInvoiceGenerateData && !showPrintBtn" (click)="patchGenerateRemoteInovice()">Generate
          Invoice</a>
  
        <a type="button" class="btn btn-primary" style="margin-left: 10px;" data-bs-toggle="modal"
          data-bs-target="#invoice-modal" *ngIf="showPrintBtn" (click)="getInvoiceByOrderId()">Print</a>
      </div>
  
      <!-- Add New Party Modal -->
      <ng-template #addParty let-modal>
        <div class="modal-content position-relative">
          <div class="position-absolute top-0 end-0 mt-2 me-2 options">
            <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" (click)="modalDismiss()"
              aria-label="Close"></a>
          </div>
          <div class="modal-body p-0">
            <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
              <h4 class="mb-1" id="modalExampleDemoLabel">Add New Vendor</h4>
            </div>
            <form [formGroup]="PartyForm">
              <div class="p-4 pb-0">
                <div class="form-check form-switch mb-0 lh-1 mb-3">
                  <input class="form-check-input" type="checkbox" [checked]="isShowGstFields" id="flexSwitchCheckDefault"
                    (click)="customertoggleShow($event.target.checked)" />
                  <label class="lab text-nowrap form-label p-1"> As Customer </label>
                </div>
  
                <div class="row d-flex justify-content-start" *ngIf="!isShowGstFields">
                  <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label class="form-label">GSTIN<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                      <input matInput required formControlName="gstIn" (change)="getGstNumber($event.target.value)"
                        maxlength="15" minlength="15" placeholder="GSTIN"
                        pattern="^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$"
                        placeholder="GSTIN" style="text-transform:uppercase">
                    </mat-form-field>
                    <mat-error *ngIf="(submittedParty ||fp.gstIn.touched) && fp.gstIn.errors?.required">
                      Enter a valid GSTIN.
                    </mat-error>
  
                    <mat-error
                      *ngIf="(submittedParty ||fp.gstIn.touched) && fp.gstIn.errors?.pattern || fp.gstIn.errors?.minlength">
                      Enter a valid GSTIN.
                    </mat-error>
                    <mat-error *ngIf="(submittedParty ||fp.gstIn.touched) && fp.gstIn.errors?.maxlength">
                      Enter a valid GSTIN.
                    </mat-error>
                  </div>
                  <div style=" font-size: 12px" class=" col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label class="form-label">GST Type<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-select matNativeControl formControlName="gstType" required disableOptionCentering
                        placeholder="GST Type" style="z-index: 9999!important">
                        <mat-option value="REGULAR">Regular</mat-option>
                        <mat-option value="COMPOSITE">Composite </mat-option>
                        <mat-option value="EXEMPTED">Exempted</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-error *ngIf="(submittedParty ||fp.gstType.touched) && fp.gstType.errors?.required">
                      GST Type Is Required!
                    </mat-error>
                  </div>
                </div>
  
                <div class="row d-flex justify-content-start">
                  <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label class="form-label">Vendor Name<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                      <input matNativeControl required matInput placeholder="Vendor Name" formControlName="partyName"
                        [ngClass]="{
                        'is-invalid': (fp.partyName.invalid && fp.partyName.touched) && submittedParty }">
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submittedParty || fp.partyName.touched)">
                      <mat-error *ngIf="(submittedParty || fp.partyName.touched) && fp.partyName.errors?.required">
                        Vendor Name Is Required!
                      </mat-error>
                    </mat-error>
  
                  </div>
                  <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label class="form-label">Mobile Number<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                      <input type="text" placeholder="Mobile Number" matInput formControlName="mobile" minlength="10"
                        maxLength="10" ngxOnlyNumbers [ngClass]="{
                          'is-invalid': (fp.mobile.invalid && fp.mobile.touched) && submittedParty }">
                    </mat-form-field>
  
                    <mat-error class="text-danger"
                      *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.pattern">
                      Enter a valid mobile number.
                    </mat-error>
                    <mat-error class="text-danger"
                      *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.required">
                      Mobile Number is required.
                    </mat-error>
                    <mat-error *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.maxlength">
                      Mobile Number must be of 10-digits
                    </mat-error>
                    <mat-error *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.minlength">
                      Mobile Number must be of 10-digits
                    </mat-error>
                  </div>
                  <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label class="form-label">E-Mail<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                      <input type="email" placeholder="e-mail" matInput formControlName="email" [ngClass]="{
                          'is-invalid': (fp.email.invalid && fp.email.touched) && submittedParty }">
                    </mat-form-field>
                    <mat-error class="text-danger"
                      *ngIf="(submittedParty || fp.email.touched) && fp.email.errors?.pattern && fp.email.errors?.email">
                      Enter a valid email id.
                    </mat-error>
                    <mat-error class="text-danger"
                      *ngIf="(submittedParty || fp.email.touched) && fp.email.errors?.required">
                      Email id is required.
                    </mat-error>
                  </div>
                </div>
  
                <div class="row d-flex justify-content-start">
                  <div class="col-lg-4 col-sm-12 col-md-6">
                    <label class="form-label">Billing Address<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                      <textarea matInput required formControlName="billingAddress" placeholder="Enter your address"
                        [ngClass]="{
                          'is-invalid': (fp.billingAddress.invalid && fp.billingAddress.touched) && submittedParty }"></textarea>
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submittedParty || fp.billingAddress.touched)">
                      <mat-error
                        *ngIf="(submittedParty || fp.billingAddress.touched) && fp.billingAddress.errors?.required">
                        Billing Address Is Required!
                      </mat-error>
                    </mat-error>
                  </div>
  
                  <div class="col-lg-4 col-sm-12 col-md-6" *ngIf="isShippingAddressinModal">
                    <label class="form-label">Shipping Address</label>
                    <mat-form-field class="example-full-width" appearance="outline">
                      <textarea matInput formControlName="shippingAddress" placeholder="Enter your address"></textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-check form-check-inline mb-2">
                  <label class="form-check-label form-label fs--1" for="inlineCheckbox1">Add Shipping Address</label>
                  <input class="form-check-input fs--1" id="inlineCheckbox1" type="checkbox"
                    (change)="showShippingAddressinModal($event.target.checked)" />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button class="btn btn-cancel" (click)="modalDismiss()">Close</button>
            <button class="btn btn-save" (click)="addNewParty()" data-bs-dismiss="modal">Add
            </button>
          </div>
        </div>
      </ng-template>
  
      <!-- Print Modal -->
      <div class="modal fade" id="invoice-modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
              <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" #closea></a>
            </div>
            <div class="modal-body p-0">
              <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md">
                    <h4 class="mb-2 mb-md-0">Order #{{ invoiceNo }}</h4>
                  </div>
                  <div class="col-auto">
                    <a class="btn btn-falcon-default btn-sm me-1 mb-2 mb-sm-0" (click)="onPrint()"><span
                        class="fas fa-print me-1"> </span>Print</a>
                  </div>
                </div>
              </div>
              <div class="container">
                <div class="card mb-3" id="panel">
                  <div class="card-body">
                    <h2 class="mb-3 text-center">Tax Invoice</h2>
                    <div class="row justify-content-betweenxt-center mb-3">
                      <div class="col-sm-6 text-sm-start">
                        <h3>To:</h3>
                        <h5>{{ customerName }}</h5>
                        <p class="fs--1 mb-0">{{ billingAddress }}<br /></p>
                      </div>
                      <div class="col text-sm-end mt-3 mt-sm-0">
                        <h3>From:</h3>
                        <h5>{{ legalName }}</h5>
                        <p class="fs--1 mb-0">{{ homeNo }} {{address }}, {{ district }}<br />{{ city }},{{ state |
                          titlecase}}, {{ country }}</p>
                      </div>
                      <div class="col-12">
                      </div>
                    </div>
                    <div class="table-responsive scrollbar mt-4 fs--1">
                      <table class="table table-striped table-bordered">
                        <thead class="light">
                          <tr class="bg-primary text-white dark__bg-1000">
                            <th class="border-0">Products</th>
                            <th class="border-0 text-center">Quantity</th>
                            <th class="border-0 text-end">Rate</th>
                            <th class="border-0 text-end">GST(In Rs.)</th>
                            <th class="border-0 text-end">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let i of remoteProductData">
                            <td class="align-middle">
                              {{ i.itemName | titlecase }}
                            </td>
                            <td class="align-middle text-center">{{ i.quantity }}</td>
                            <td class="align-middle text-end">
                              ₹&nbsp;{{i.totalPrice | number:'1.2-2'}}
                            </td>
                            <td class="align-middle text-end">
                              ₹&nbsp;{{i.gstAmount | number:'1.2-2'}}
                            </td>
                            <td class="align-middle text-end">
                              ₹&nbsp;{{i.finalAmount | number:'1.2-2'}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-md-6 col-lg-6"></div>
                      <div class="col-sm-6 col-md-6 col-lg-6">
                        <div class="table-responsive scrollbar fs--1">
                          <table class="table table-striped">
                            <thead class="light">
                              <tr class="bg-primary text-white dark__bg-1000">
                                <th class="border-0">Tax Type</th>
                                <th class="border-0 text-center"></th>
                                <th class="border-0 text-center">Tax Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="align-middle">SGST</td>
                                <td></td>
                                <td class="align-middle text-center">
                                  ₹&nbsp;{{remoteGSTAmount / 2 | number:'1.2-2'}}
                                </td>
                              </tr>
                              <tr>
                              </tr>
                              <tr>
                                <td class="align-middle">CGST</td>
                                <td></td>
                                <td class="align-middle text-center">
                                  ₹&nbsp;{{remoteGSTAmount / 2 | number:'1.2-2'}}
                                </td>
                              </tr>
                              <tr>
                                <td class="align-middle">Total</td>
                                <td></td>
                                <td class="align-middle text-center">
                                  ₹&nbsp;{{remoteTotalBillAmount | number:'1.2-2'}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer bg-light">
                    <p class="fs--1 mb-0"><strong>Notes: </strong>We really appreciate your business and if there’s
                      anything else we
                      can
                      do, please let us know!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  
  
    <!-- UAE -->
  
    <form [formGroup]="SaleInvoiceForm" *ngIf="isArabic">
      <div class="row d-flex justify-content-start mb-2">
        <div class="col-sm-12 d-flex" *ngIf="!showInvoiceGenerateData">
          <div class="d-flex" style="font-size: 14px;">
            <div class="form-check padding-left">
              <input class="" style="margin-left: 0px!important" id="inlineCheckbox1" type="checkbox"
                formControlName="cashSale" (change)="isCashSaleToggle($event.target.checked)"
                [disabled]="showInvoiceGenerateData" />
            </div>
            <label class="text-nowrap saleText form-label" style="margin-left:4px!important">Sale/Cash </label>
          </div>
        </div>
      </div>
  
      <div class="row d-flex justify-content-between">
        <!-- Transaction Type -->
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" *ngIf="!showInvoiceGenerateData && !isCashSale">
          <label class="form-label">Transaction Type</label>
          <mat-form-field style=" font-size: 11px" class="example-full-width" appearance="outline">
  
            <mat-select disableOptionCentering aria-label="Default select example" formControlName="credit" required
              (selectionChange)="getCredit($event)" placeholder="Transaction Type" [ngClass]="{
              'is-invalid': (f.credit.invalid && f.credit.touched) && submitted }">
              <mat-option value="false">Cash</mat-option>
              <mat-option value="true">Credit</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="(submitted || f.credit.touched) && f.credit.errors?.required">
            Transaction Type Is Required!
          </mat-error>
        </div>
      </div>
  
      <div class="row d-flex justify-content-start">
        <!-- Party Name -->
        <div class="col-md-4 " *ngIf="!isCashSale">
          <label class="form-label">Vendor Name<span class="error-asterisk">*</span></label>
          <mat-form-field style="font-size: 11px" class=" example-full-width" appearance="outline">
            <input type="text" placeholder="Vendor Name" aria-label="Select Vendor Name" matInput [formControl]="myControl"
              (keyup)="searchPartyByName($event.target.value)" [matAutocomplete]="auto" [ngClass]="{
                      'is-invalid':
                        myControl.invalid && myControl.touched && submitted
                    }" required>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option>
                <a class="mobVerify" style="display: inline-block; width: 100%;" aria-hidden="true" title="Vendor"
                  (click)="openAddPartyModal(addParty)">
                  Add new Vendor
                </a>
              </mat-option>
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
                (onSelectionChange)="setSelectedPartyDeatails(option)">
                {{ option.partyName | titlecase }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="
                (submitted || myControl.touched) && myControl.errors?.required
              ">
            Vendor Is Required!
          </mat-error>
        </div>
  
        <!-- Gst number -->
        <div class="col-md-4 justify-content-end" *ngIf="!isCashSale">
          <div><mat-label>{{ lang.GSTIN }}<span class="error-asterisk">*</span></mat-label></div>
          <mat-form-field class="example-full-width" appearance="outline">
            <input type="text" matInput formControlName="gstNo" placeholder="{{lang.GSTIN }}" #input1
              (keypress)="numberOnly($event)" maxlength="15" (keyup)="validateTRN($event.target.value)"
              [ngClass]="{'is-invalid': (f.gstNo.invalid && f.gstNo.touched) && submitted }" required>
          </mat-form-field>
  
          <mat-error class="text-danger" *ngIf="(submitted && f.gstNo.errors?.required)">
            {{ lang.GSTIN }} is Required!
          </mat-error>
          <mat-error class="text-danger" *ngIf="f.gstNo.invalid && (f.gstNo.dirty && f.gstNo.touched)">
            Please enter the valid {{ lang.GSTIN }} number
          </mat-error>
          <mat-error class="text-danger" *ngIf="f.gstNo.errors?.maxlength">
            {{ lang.GSTIN }} must be of 15-digits
          </mat-error>
          <mat-error class="text-danger" *ngIf="f.gstNo.errors?.minlength">
            {{ lang.GSTIN }} must be of 15-digits
          </mat-error>
          <mat-error class="text-danger" *ngIf="f.gstNo.errors?.pattern">
            {{ lang.GSTIN }} must be valid number
          </mat-error>
        </div>
  
        <!-- Tax Invoice No -->
        <div class="col-md-4 justify-content-end">
          <label class="form-label">Tax Invoice No</label>
          <mat-form-field style="font-size: 12px;" class="example-full-width" appearance="outline">
            <input type="text" placeholder="Enter Tax Invoice No." matInput formControlName="invoiceNo"
              [disabled]="showInvoiceGenerateData" [ngClass]="{
              'is-invalid': (f.invoiceNo.invalid && f.invoiceNo.touched) && submitted }" required>
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="(submitted || f.invoiceNo.touched) && f.invoiceNo.errors?.required">
            Tax Invoice Is Required!
          </mat-error>
        </div>
      </div>
  
      <div class="row d-flex justify-content-start">
        <!-- Invoice date -->
        <div class="col-md-4">
          <label class="form-label">Invoice Date</label>
          <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
            <input matInput required ngModel bsDatepicker placeholder="Receipt Date." [maxDate]="today"
              style="text-transform:uppercase!important;" [(ngModel)]="todaysDate" id="datePicker" bsDatepicker
              [bsConfig]="{
          containerClass: 'theme-dark-blue',
          dateInputFormat: 'DD MMM YYYY',
          showWeekNumbers:false }" [ngModelOptions]="{ standalone: true }" [ngClass]="{
          'is-invalid': (todaysDate.invalid && todaysDate.touched) && submitted }" (click)="openDatepicker()">
            <span>
              <mat-icon class="iconcalender">date_range</mat-icon>
            </span>
          </mat-form-field>
          <!-- <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
            <input matInput required ngModel bsDatepicker placeholder="Receipt Date" [maxDate]="today"
              style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                            containerClass: 'theme-dark-blue',
                            dateInputFormat: 'DD MMM YYYY',
                             showWeekNumbers:false
                            }" formControlName="invoiceDate" autocomplete="off" (click)="openDatepicker()">
            <span>
              <mat-icon class="iconcalender p-1">date_range</mat-icon>
            </span>
          </mat-form-field> -->
          <mat-error class="text-danger" *ngIf="(submitted || f.invoiceDate.touched) && f.invoiceDate.errors?.required">
            Invoice Date Is Required!
          </mat-error>
        </div>
  
        <!-- e-Way Bill No. -->
        <!-- <div class="col-md-4 justify-content-between">
        <label class = "form-label">e-Way Bill No</label>
        <mat-form-field style="font-size: 11px" class="example-full-width" appearance="outline">
          <input type="text" matInput [textMask]="{mask: mask}" placeholder="Enter e-way Bill No."
            formControlName="ewayBillNo" [disabled]="showInvoiceGenerateData">
        </mat-form-field>
      </div> -->
  
        <!-- Shipped From -->
        <!-- <div class="col-md-4 justify-content-between">
        <label class = "form-label">Shipped From(State)</label>
        <mat-form-field style=" font-size: 11px" class="example-full-width" appearance="outline">
          <mat-select formControlName="shippedFrom" disableOptionCentering aria-label="Default select example"
            placeholder="Select State" [disabled]="showInvoiceGenerateData">
            <mat-option [value]="state" *ngFor="let state of allStates">{{ state }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
      </div>
  
      <div class="row d-flex justify-content-start">
        <!-- Billing Address -->
        <div class="col-sm-12 col-md-4 col-lg-4">
          <label class="form-label">Billing Address<span class="error-asterisk">*</span></label>
          <mat-form-field class="example-full-width " style=" font-size: 12px" appearance="outline">
            <textarea matInput rows="5" placeholder="Enter Billing Address" formControlName="billingAddress" required
              disabled [ngClass]="{
              'is-invalid': (f.billingAddress.invalid && f.billingAddress.touched) && submitted }"></textarea>
          </mat-form-field>
          <mat-error class="text-danger"
            *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
            Billing Address Is Required!
          </mat-error>
        </div>
        <!-- Shipping Address -->
        <div *ngIf="isShippingAddress" class="col-sm-12 col-md-4 col-lg-4">
          <label class="form-label">Shipping Address</label>
          <mat-form-field class="example-full-width" style=" font-size: 12px" appearance="outline">
            <textarea matInput rows="5" placeholder="Enter Shipping Address" formControlName="shippingAddress"
              [disabled]="showInvoiceGenerateData"></textarea>
          </mat-form-field>
        </div>
      </div>
  
      <div class="row container d-flex justify-content-start">
        <div class="col-md-4 d-flex" style="font-size: 12px;">
          <div class="form-check padding-left">
            <input class="form-check-input" id="inlineCheckbox1" type="checkbox" [disabled]="showInvoiceGenerateData"
              (change)="showShippingAddress($event.target.checked)" [checked]="isShippingAddress" />
          </div>
          <label style="margin-left:1px!important" class=" form-label">Add Shipping Address</label>
  
        </div>
      </div>
  
      <!-- Delete a -->
      <!-- <div class="problem mt-3">
      <ul class="problem-nav">
        <li>
          <div class="d-flex justify-content-between mt-1" style="font-size: 12px;">
            <label class = "form-label">Add Compensation Cess </label>
            <div class="form-check form-switch">
              <input class="form-check-input toggleB mt-1" style="margin-left: 10px!important;" id="inlineCheckbox1"
                type="checkbox" (click)="toggleShow()" />
            </div>
          </div>
        </li>
      </ul>
    </div> -->
  
      <!-- Product Table for Sale Invoice-->
      <div class="table-responsive my-custom-scrollbar " *ngIf="!showInvoiceGenerateData">
        <table class="table bg-white table-bordered table-stripped">
          <thead class="table inner-model-table-heading">
            <tr class="font_size font-weight-bold">
              <th scope="col" style="width: 7% !important;">
                #
              </th>
              <th scope="col" style="width: 10%;">
                SKU<span class="text-danger"> *</span>
              </th>
              <th class="text-nowrap" scope="col">
                SKU Description
              </th>
              <th>Unit</th>
  
              <th>Quantity</th>
              <th scope="col" class="text-nowrap" colspan="2">
                {{ lang.Base_Price_In_Rs }}<span class="text-danger"> *</span>
              </th>
  
              <th class="text-center" style = "width: 14%;" scope="col" colspan="2">
                {{lang.GST}}<span class="text-danger"> *</span>
              </th>
              <!-- <th scope="col" colspan="2" class="text-nowrap" *ngIf="isShown">
              Additional Cess(In Rs,)<span class="text-danger"> *</span>
            </th> -->
              <th scope="col" class="text-nowrap">
                {{lang.Total_In_Rs}}<span class="text-danger"> *</span>
              </th>
              <th scope="col" class="text-nowrap" style="width:8%;">
                Action<span class="text-danger"> *</span>
              </th>
  
            </tr>
          </thead>
  
          <tbody class="mod">
            <!-- <tr>
              <td>#</td> -->
              <tbody class="mod">
                <!-- The first row for adding new items -->
                <tr>
                  <td style="vertical-align: middle;"></td>
              <!-- <td> -->
              <td class="s23323">
                <mat-form-field class="example-full-width" appearance="outline">
                  <input type="text" id="inp12" placeholder="Free Product"
                    (keyup.enter)="getGoodsList($event.target.value)" aria-label="Select Vendor Name" matInput
                    [formControl]="myControl1" [matAutocomplete]="auto">
                  <mat-icon class="m-2" matSuffix>search</mat-icon>
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOptions2 | async" [value]="option.itemName"
                      (onSelectionChange)="getGoodsByBarcodeSelected(option.barcode,$event)">
                      <span style="font-size: 12px;"> {{ option.itemName | titlecase }}/<b>{{
                          option.barcode}}</b></span>
  
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
              <!--          -->
              <!-- <td>
                <input type="text" matInput style="width: 90px;" placeholder="SKU" (change)="getGoodsByBarcode()"
                  [(ngModel)]="barcode" [ngModelOptions]="{standalone: true}" required />
              </td> -->
              <td>
                <input type="text" matInput placeholder="SKU Description" required />
              </td>
              <td colspan="1">
                <input type="text" matInput placeholder="Unit" required />
              </td>
              <td colspan="1">
                <input type="text" matInput placeholder="Quantity" style="width: 5rem;" required />
              </td>
              <td colspan="1">
                <input type="text" matInput placeholder="Price/Unit" />
              </td>
              <td colspan="1">
                <input type="text" matInput placeholder="Price" />
              </td>
              <td colspan="1">
                <input type="text" matInput required placeholder="%" />
              </td>
              <td colspan="1">
                <input type="text" matInput required placeholder="In ₹" style="width: 40px;" />
              </td>
              <!-- <td colspan="1" *ngIf="isShown">
              <input type="text" matInput placeholder="%" style="width: 5rem;" required />
            </td>
            <td colspan="1" *ngIf="isShown">
              <input type="text" matInput placeholder="In Rs." style="width: 5rem;" required />
            </td> -->
              <td>
                <input type="text" matInput placeholder="Amount" style="max-width: 150px;" required />
              </td>
              <td></td>
            </tr>
            <ng-container formArrayName="subcheckbox"
              *ngFor="let items of formArr.controls;let item of productData;let i=index">
              <tr [formGroupName]="i">
                  <!-- <td></td> -->
                  <td style="vertical-align: middle;">{{ i + 1 }}</td>
                <td>
                  {{ item.barcode }}
                </td>
                <td>{{ item.itemName | titlecase}}</td>
                <td class="text-center">
                  <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit" readonly />
                </td>
                <td style="display:flex; flex-direction: row; justify-content: center; align-items: center;">
                  <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                    (click)="changeQuantity('-',i)">-</a>
                  <input type="text" matInput [value]="item.quantity" placeholder="Quantity"
                    (change)="changeQuantity('qty',i,$event.target.value)" style="width: 5rem;text-align: center;"
                    required />
                  <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                    (click)="changeQuantity('+',i)">+</a>
                </td>
                <td colspan="1">
                  <p class="">
                    ₹&nbsp;{{item.basePrice | number:'1.2-2'}}
                  </p>
                </td>
                <td colspan="1">
                  <p class="">
                    ₹&nbsp;{{item.subTotal | number:'1.2-2'}}
                  </p>
                </td>
                <td colspan="1">
                  <p class="">
                    {{ item.gstRate }}%
                  </p>
                </td>
                <td colspan="1" style="max-width: 150px;">
                  <p class="">
                    ₹&nbsp;{{item.gstAmount | number:'1.2-2'}}
                  </p>
                </td>
                <td>
                  <p class="" style="max-width: 150px;">
                    ₹&nbsp;{{item.total | number:'1.2-2'}}
                  </p>
                </td>
  
                <td>
                  <button type="button" class="text-center btnb" style="width: 3rem!important;"
                    (click)="changeQuantity('rm',i)">
                    <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip" data-bs-placement="top"
                      title="Delete"></span>
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
  
      <div class="table-responsive mt-2  my-custom-scrollbar d-flex justify-content-end" *ngIf="!showInvoiceGenerateData">
        <div class="col-sm-6"></div>
        <div class="col-sm-4 table-bordered my-custom-scrollbar">
          <table class="table table-bordered  my-custom-scrollbar">
            <tr>
              <td class="thWidth p-2" style="text-align:left!important;">Subtotal</td>
              <td class="p-2" style="width: 50px;" style="text-align:right!important;">
                ₹&nbsp;{{ subTotal | number:'1.2-2'}}
              </td>
            </tr>
            <tr>
              <td class="thWidth  p-2" style="text-align:left!important;">{{lang.CGST}}</td>
              <td class="p-2" style="width: 50px;" style="text-align:right!important;">
                ₹&nbsp;{{gstRate | number:'1.2-2'}}
              </td>
            </tr>
            <!-- <tr>
            <td class="thWidth  p-2" *ngIf="gstStateMatch" style="text-align:left!important;">SGST</td>
            <td class="p-2" style="width:50px;" *ngIf="gstStateMatch" style="text-align:right!important;">
              ₹&nbsp;{{gstRate/2 | number:'1.2-2'}}
            </td>
          </tr> -->
            <!-- <tr>
            <td class="thWidth  p-2" *ngIf="!gstStateMatch" style="text-align:left!important;">IGST</td>
            <td class="p-2" *ngIf="!gstStateMatch" style="width: 50px;" style="text-align:right!important;">
              AED&nbsp;{{gstRate/2 + gstRate/2 | number:'1.2-2'}}
  
            </td>
          </tr> -->
  
            <!-- <tr>
            <td class="thWidth  p-2" *ngIf="isShown" style="text-align:left!important;">Additional Cess</td>
            <td class="p-2" style="width:50px;" *ngIf="isShown" style="text-align:right!important;">
              ₹&nbsp;{{additionalCess | number:'1.2-2'}}
            </td>
          </tr> -->
            <tr>
              <td class="thWidth  p-2" style="text-align:left!important;">Total</td>
              <td class="p-2" style="width: 50px;" style="text-align:right!important;">
                ₹&nbsp;{{total | number:'1.2-2'}}
              </td>
            </tr>
          </table>
        </div>
      </div>
  
      <!-- Invoice Generate Table for Remote Orders -->
      <div class="table-responsive my-custom-scrollbar d-flex justify-content-start row" style="margin-right: 0.1rem;"
        *ngIf="showInvoiceGenerateData">
        <table class="table bg-white table-bordered table-stripped">
          <thead class="table-secondary">
            <tr class="font_size font-weight-bold">
              <th scope="col" style="width: 7% !important;">
                #
              </th>
              <th scope="col" style="width: 10%">
                SKU<span class="text-danger"> *</span>
              </th>
              <th scope="col" class="text-nowrap">
                SKU Description
              </th>
              <th>Unit</th>
              <th>Quantity</th>
              <th scope="col" class="text-nowrap" colspan="1">
                {{lang.Base_Price_In_Rs}}<span class="text-danger"> *</span>
              </th>
  
              <th class="text-center" scope="col" colspan="2">
                {{lang.GST}}<span class="text-danger"> *</span>
              </th>
              <!-- <th scope="col" colspan="2" class="text-nowrap">
              Additional Cess(In Rs.)<span class="text-danger"> *</span>
            </th> -->
              <th scope="col" class="text-nowrap">
                {{lang.Total_In_Rs}}<span class="text-danger"> *</span>
              </th>
            </tr>
          </thead>
          <tbody class="mod" *ngIf="orderData.length > 0">
            <tr *ngFor="let item of orderData;let i=index">
              <td>{{ i + 1 }}</td>
              <td>{{ item.barcode }}</td>
  
              <td>
                {{ item.itemName }}
              </td>
              <td colspan="1">
                <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit"
                  readonly />
              </td>
              <td colspan="1" class="text-center m-auto">
                <p class="">{{ item.quantity }}</p>
              </td>
              <td colspan="1">
                <p class="">₹&nbsp;{{subTotal | number:'1.2-2'}}
                </p>
              </td>
              <td colspan="1">
                <p class="" style="width: 5rem;">{{ item.gst }}%</p>
              </td>
              <td colspan="1">
                <p class="" style="width: 5rem;">
                    ₹&nbsp;{{item.gstAmount | number:'1.2-2'}}
                </p>
              </td>
              <!-- <td colspan="1">
              <p class="" style="width: 5rem;">{{ item.additionalCess }}% </p>
            </td> -->
              <!-- <td colspan="1">
              <p class="" style="width: 5rem;">
                ₹&nbsp;{{item.additionalCess | number:'1.2-2'}}
              </p>
            </td> -->
              <td>
                <p class="">
                    ₹&nbsp;{{item.finalAmount | number:'1.2-2'}}
                  <!-- {{ item.finalAmount | currency:'INR':'symbol':'1.2-2'}} -->
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
  
      <div class="table-responsive my-custom-scrollbar d-flex justify-content-end" *ngIf="showInvoiceGenerateData">
        <div class="col-lg-8 col-sm-6"></div>
        <div class="col-lg-4 col-sm-4 table-bordered my-custom-scrollbar" style="width: 30%;">
          <table class="table table-bordered  my-custom-scrollbar">
            <tr>
              <td style="text-align:left!important;">Subtotal</td>
              <td style="text-align:right!important;">
                ₹&nbsp;{{subTotal | number:'1.2-2'}}
              </td>
            </tr>
            <tr>
              <td style="text-align:left!important;">{{lang.CGST}}</td>
              <td style="text-align:right!important;">
                ₹&nbsp;{{totalGST | number:'1.2-2'}}
              </td>
            </tr>
            <!-- <tr>
            <td style="text-align:left!important;">SGST</td>
            <td style="text-align:right!important;">
              AED&nbsp;{{totalGST/2 | number:'1.2-2'}}
            </td>
          </tr> -->
            <!-- <tr>
          <td style="text-align:left!important;">IGST</td>
          <td style="text-align:right!important;">{{
            gstRate/2 + gstRate/2|
            currency:'INR':'symbol':'1.2-2'}}</td>
        </tr> -->
  
            <!-- <tr>
          <td class="thWidth  p-2" *ngIf="isShown" style="text-align:left!important;">Additional Cess</td>
          <td class="p-2" style="width:50px;" *ngIf="isShown" style="text-align:right!important;">{{
            additionalCess | currency:'INR':'symbol':'1.2-2'}}</td>
        </tr> -->
            <tr>
              <td style="text-align:left!important;">Total</td>
              <td style="text-align:right!important;">
                ₹&nbsp;{{total | number:'1.2-2'}}
              </td>
            </tr>
          </table>
        </div>
      </div>
  
  
      <!-- as (Save/Print/Close) -->
      <div class="col-12 d-flex  justify-content-end">
        <div class="" *ngIf="!showInvoiceGenerateData">
          <!-- <a type="button" class="btn btn-outline-primary" style="margin-left: 10px;">Print</a> -->
  
          <!-- <a class="btn btn-outline-primary dropdown-toggle" id="dropdownMenu2" type="a" data-bs-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Share
        </a> -->
          <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
          <a class="dropdown-item" type="a btn-outline-primary">Print</a>
          <a class="dropdown-item" type="a btn-outline-primary">Share</a>
        </div> -->
        </div>
        <!-- <a type="submit" class="btn btn-outline-primary" style="margin-left: 10px;" (click)="postSaleInvoice()"
        *ngIf="!showInvoiceGenerateData">Save</a> -->
  
        <!-- Sale invoice for remote order a -->
        <a type="button" class="  btn btn-outline-primary" style="margin-left: 10px;"
          *ngIf="showInvoiceGenerateData && !showPrintBtn" (click)="patchGenerateRemoteInovice()">Generate
          Invoice</a>
  
        <a type="button" class="btn btn-primary" style="margin-left: 10px;" data-bs-toggle="modal"
          data-bs-target="#invoice-modal" *ngIf="showPrintBtn" (click)="getInvoiceByOrderId()">Print</a>
        <!-- *ngIf="showPrintBtn" -->
      </div>
  
      <!-- Add New Party Modal -->
      <ng-template #addParty let-modal>
        <div class="modal-content position-relative">
          <div class="position-absolute top-0 end-0 mt-2 me-2 options">
            <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" (click)="modalDismiss()"
              aria-label="Close"></a>
          </div>
          <div class="modal-body p-0">
            <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
              <h4 class="mb-1" id="modalExampleDemoLabel" style="font-size: 16px;font-weight:500;font-family: 'Poppins';">
                Add New Vendor</h4>
            </div>
            <form [formGroup]="PartyForm">
              <div class="p-4 pb-0">
                <div class="form-check form-switch mb-0 lh-1 mb-3">
                  <input class="form-check-input" type="checkbox" [checked]="isShowGstFields" id="flexSwitchCheckDefault"
                    (click)="customertoggleShow($event.target.checked)" />
                  <label class="lab text-nowrap form-label p-1" style="font-size: 14px;"> As Customer </label>
                </div>
  
                <div class="row d-flex justify-content-start" *ngIf="!isShowGstFields">
  
                  <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <div><mat-label>{{ lang.GSTIN }}<span class="error-asterisk">*</span></mat-label></div>
                    <mat-form-field class="example-full-width" appearance="outline">
                      <input type="text" matInput formControlName="gstIn" placeholder="{{lang.GSTIN }}" #input1
                        (keypress)="numberOnly($event)" maxlength="15" minlength="15"
                        (input)="validateTRN($event.target.value)"
                        [ngClass]="{'is-invalid': (pf.gstIn.invalid && pf.gstIn.touched) && submittedParty }" required>
                    </mat-form-field>
  
                    <mat-error class="text-danger"
                      *ngIf="((submittedParty || pf.gstIn.touched) && pf.gstIn.errors?.required)">
                      {{ lang.GSTIN }} is Required!
                    </mat-error>
                    <mat-error class="text-danger"
                      *ngIf="(pf.gstIn.invalid && pf.gstIn.dirty ) || pf.gstIn.errors?.maxlength || pf.gstIn.errors?.minlength">
                      Please enter {{ lang.GSTIN }} number with 15-digits.
                    </mat-error>
                  </div>
  
                </div>
  
                <div class="row d-flex justify-content-start">
                  <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label class="form-label">Vendor Name<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                      <input matNativeControl required matInput placeholder="Vendor Name" formControlName="partyName"
                        [ngClass]="{
                        'is-invalid': (fp.partyName.invalid && fp.partyName.touched) && submittedParty }">
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submittedParty || fp.partyName.touched)">
                      <mat-error *ngIf="(submittedParty || fp.partyName.touched) && fp.partyName.errors?.required">
                        Vendor Name Is Required!
                      </mat-error>
                    </mat-error>
  
                  </div>
                  <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label class="form-label">Mobile Number<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                      <input type="text" placeholder="Mobile Number" matInput formControlName="mobile" minlength="9"
                        maxLength="9" ngxOnlyNumbers [ngClass]="{
                          'is-invalid': (fp.mobile.invalid && fp.mobile.touched) && submittedParty }">
                    </mat-form-field>
  
                    <mat-error class="text-danger"
                      *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.pattern">
                      Enter a valid mobile number.
                    </mat-error>
                    <mat-error class="text-danger"
                      *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.required">
                      Mobile Number is required.
                    </mat-error>
                    <mat-error *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.maxlength">
                      Mobile Number must be of 9-digits
                    </mat-error>
                    <mat-error *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.minlength">
                      Mobile Number must be of 9-digits
                    </mat-error>
                  </div>
                  <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <label class="form-label">E-Mail<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                      <input type="email" placeholder="e-mail" matInput formControlName="email" [ngClass]="{
                          'is-invalid': (fp.email.invalid && fp.email.touched) && submittedParty }">
                    </mat-form-field>
                    <mat-error class="text-danger"
                      *ngIf="(submittedParty || fp.email.touched) && fp.email.errors?.pattern && fp.email.errors?.email">
                      Enter a valid email id.
                    </mat-error>
                    <mat-error class="text-danger"
                      *ngIf="(submittedParty || fp.email.touched) && fp.email.errors?.required">
                      Email id is required.
                    </mat-error>
                  </div>
                </div>
  
                <div class="row d-flex justify-content-start">
                  <div class="col-lg-4 col-sm-12 col-md-6">
                    <label class="form-label">Billing Address<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                      <textarea matInput required formControlName="billingAddress" placeholder="Enter your address"
                        [ngClass]="{
                          'is-invalid': (fp.billingAddress.invalid && fp.billingAddress.touched) && submittedParty }"></textarea>
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submittedParty || fp.billingAddress.touched)">
                      <mat-error
                        *ngIf="(submittedParty || fp.billingAddress.touched) && fp.billingAddress.errors?.required">
                        Billing Address Is Required!
                      </mat-error>
                    </mat-error>
                  </div>
  
                  <div class="col-lg-4 col-sm-12 col-md-6" *ngIf="isShippingAddressinModal">
                    <label class="form-label">Shipping Address</label>
                    <mat-form-field class="example-full-width" appearance="outline">
                      <textarea matInput formControlName="shippingAddress" placeholder="Enter your address"></textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-check form-check-inline mb-2">
                  <label class="form-check-label form-label fs--1" for="inlineCheckbox1"><span
                      style="font-size: 14px;">Add Shipping Address</span></label>
                  <input class="form-check-input fs--1" id="inlineCheckbox1" type="checkbox"
                    (change)="showShippingAddressinModal($event.target.checked)" />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button class="btn btn-cancel" (click)="modalDismiss()">Close</button>
            <button class="btn btn-save" (click)="addNewParty()" data-bs-dismiss="modal">Add
            </button>
          </div>
        </div>
      </ng-template>
  
      <!-- Print Modal -->
      <div class="modal fade" id="invoice-modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
              <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" #closea></a>
            </div>
            <div class="modal-body p-0">
              <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                <div class="row justify-content-between align-items-center">
                  <div class="col-md">
                    <h4 class="mb-2 mb-md-0">Order #{{ invoiceNo }}</h4>
                  </div>
                  <div class="col-auto">
                    <a class="btn btn-falcon-default btn-sm me-1 mb-2 mb-sm-0" (click)="onPrint()"><span
                        class="fas fa-print me-1"> </span>Print</a>
                  </div>
                </div>
              </div>
              <div class="container">
                <div class="card mb-3" id="panel">
                  <div class="card-body">
                    <h2 class="mb-3 text-center">Tax Invoice</h2>
                    <div class="row justify-content-betweenxt-center mb-3">
                      <div class="col-sm-6 text-sm-start">
                        <h3>To:</h3>
                        <h5>{{ customerName }}</h5>
                        <p class="fs--1 mb-0">{{ billingAddress }}<br /></p>
                      </div>
                      <div class="col text-sm-end mt-3 mt-sm-0">
                        <h3>From:</h3>
                        <h5>{{ legalName }}</h5>
                        <p class="fs--1 mb-0">{{ homeNo }} {{address }}, {{ district }}<br />{{ city }},{{ state |
                          titlecase}}, {{ country }}</p>
                      </div>
                      <div class="col-12">
                      </div>
                    </div>
                    <div class="table-responsive scrollbar mt-4 fs--1">
                      <table class="table table-striped table-bordered">
                        <thead class="light">
                          <tr class="bg-primary text-white dark__bg-1000">
                            <th class="border-0">Products</th>
                            <th class="border-0 text-center">Quantity</th>
                            <th class="border-0 text-end">Rate</th>
                            <th class="border-0 text-end">{{lang.GST_In_Rs}}</th>
                            <th class="border-0 text-end">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let i of remoteProductData">
                            <td class="align-middle">
                              {{ i.itemName | titlecase }}
                            </td>
                            <td class="align-middle text-center">{{ i.quantity }}</td>
                            <td class="align-middle text-end">
                                ₹&nbsp;{{i.totalPrice | number:'1.2-2'}}
                              <!-- {{ i.totalPrice | currency:'INR':'symbol':'1.2-2'}} -->
                            </td>
                            <td class="align-middle text-end">
                                ₹&nbsp;{{i.gstAmount | number:'1.2-2'}}
                              <!-- {{ i.gstAmount | currency:'INR':'symbol':'1.2-2'}}-->
                            </td>
                            <td class="align-middle text-end">
                                ₹&nbsp;{{i.finalAmount | number:'1.2-2'}}
                              <!-- {{ i.finalAmount | currency:'INR':'symbol':'1.2-2'}} -->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-md-6 col-lg-6"></div>
                      <div class="col-sm-6 col-md-6 col-lg-6">
                        <div class="table-responsive scrollbar fs--1">
                          <table class="table table-striped">
                            <thead class="light">
                              <tr class="bg-primary text-white dark__bg-1000">
                                <th class="border-0">Tax Type</th>
                                <th class="border-0 text-center"></th>
                                <th class="border-0 text-center">Tax Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <!-- <tr>
                              <td class="align-middle">SGST</td>
                              <td></td>
                              <td class="align-middle text-center">
                                ₹&nbsp;{{remoteGSTAmount / 2 | number:'1.2-2'}}
                              </td>
                            </tr> -->
                              <tr>
                              </tr>
                              <tr>
                                <td class="align-middle">{{lang.CGST}}</td>
                                <td></td>
                                <td class="align-middle text-center">
                                    ₹&nbsp;{{remoteGSTAmount / 2 | number:'1.2-2'}}
                                  <!-- {{ remoteGSTAmount / 2 |
                                currency:'INR':'symbol':'1.2-2'}} -->
                                </td>
                              </tr>
                              <tr>
                                <td class="align-middle">Total</td>
                                <td></td>
                                <td class="align-middle text-center">
                                    ₹&nbsp;{{remoteTotalBillAmount | number:'1.2-2'}}
                                  <!-- {{ remoteTotalBillAmount | currency:'INR':'symbol':'1.2-2'}} -->
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer bg-light">
                    <p class="fs--1 mb-0"><strong>Notes: </strong>We really appreciate your business and if there’s
                      anything else we can
                      do, please let us know!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  
  </div>
  
  
  
  <!-- <form [formGroup]="SaleInvoiceForm">
    <div class="card">
      <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
          Sales Invoice
        </p>
      </div>
      <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
      </div>
  
      <div class="card-body fs--1">
        <div class="row d-flex justify-content-start">
          <div class="col-sm-2 d-flex" *ngIf="!showInvoiceGenerateData">
            <div class="d-flex" style="font-size: 12px;">
              <div class="form-check padding-left">
                <input class="" style="margin-left: 0px!important" id="inlineCheckbox1" type="checkbox"
                  formControlName="cashSale" (change)="isCashSaleToggle($event.target.checked)"
                  [disabled]="showInvoiceGenerateData" />
              </div>
              <label class="text-nowrap saleText" style="margin-left:4px!important">Sale/Cash </label>
            </div>
          </div>
        </div>
  
        <div class="row d-flex justify-content-between">
  
          <div class="col-sm-12 col-lg-4 col-md-4">
            <mat-form-field *ngIf="!showInvoiceGenerateData && !isCashSale" style=" font-size: 11px"
              class="example-full-width" appearance="outline">
              <mat-label>Transaction Type</mat-label>
              <mat-select disableOptionCentering aria-label="Default select example" formControlName="credit" required
                (selectionChange)="getCredit($event)" placeholder="Transaction Type" [ngClass]="{
                  'is-invalid': (f.credit.invalid && f.credit.touched) && submitted }">
                <mat-option value="false">Cash</mat-option>
                <mat-option value="true">Credit</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error class="text-danger" *ngIf="(submitted || f.credit.touched) && f.credit.errors?.required">
              Transaction Type Is Required!
            </mat-error>
          </div>
  
  
          <div class="col-xs-4 col-sm-4 col-md-5 col-lg-4">
            <div class="d-flex justify-content-between mb-2">
              <div class="col-xs-4 col-sm-4 col-lg-2 col-md-3">
                <label class="text-nowrap">Select Purchase Invoice For RCM </label>
              </div>
              <div class="col-xs-4 col-sm-4 col-lg-2 col-md-2 form-check form-switch">
                <input formControlName="reverseCharge" class="form-check-input" id="inlineCheckbox1" type="checkbox"
                  [disabled]="showInvoiceGenerateData" />
              </div>
            </div>
          </div>
        </div>
  
        <div class="row d-flex justify-content-start">
  
          <div class="col-md-4 " *ngIf="!isCashSale">
            <mat-form-field style="font-size: 11px" class=" example-full-width" appearance="outline">
              <mat-label>Party Name</mat-label>
              <input type="text" placeholder="Party Name" aria-label="Select Party Name" matInput
                [formControl]="myControl" (keyup)="searchPartyByName($event.target.value)" [matAutocomplete]="auto"
                [ngClass]="{
                          'is-invalid':
                            myControl.invalid && myControl.touched && submitted
                        }" required>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option>
                  <a class="mobVerify" style="display: inline-block; width: 100%;" aria-hidden="true" title="Party"
                    (click)="openAddPartyModal(addParty)">
                    Add new party
                  </a>
                </mat-option>
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
                  (onSelectionChange)="setSelectedPartyDeatails(option)">
                  {{ option.partyName | titlecase }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-error class="text-danger" *ngIf="
                    (submitted || myControl.touched) && myControl.errors?.required
                  ">
              Party Is Required!
            </mat-error>
          </div>
  
  
          <div class="col-md-4 justify-content-between">
            <mat-form-field style="font-size: 11px" class="example-full-width" appearance="outline">
              <mat-label>GST Number</mat-label>
              <input type="text" matInput placeholder="GST number" formControlName="gstNo"
                style="text-transform:uppercase;" required [ngClass]="{
                        'is-invalid': (f.gstNo.invalid && f.gstNo.touched) }" maxlength="15" minlength="15"
                pattern="^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$">
            </mat-form-field>
            <mat-error class="text-danger" *ngIf="(submitted ||f.gstNo.touched) && f.gstNo.errors?.required">
              Enter a valid GSTIN.
            </mat-error>
            <mat-error *ngIf="(submitted ||f.gstNo.touched) && f.gstNo.errors?.pattern || f.gstNo.errors?.minlength">
              Enter a valid GSTIN.
            </mat-error>
            <mat-error *ngIf="(submitted ||f.gstNo.touched) && f.gstNo.errors?.maxlength">
              Enter a valid GSTIN.
            </mat-error>
  
          </div>
  
  
          <div class="col-md-4 justify-content-end">
            <mat-form-field style="font-size: 12px;" class="example-full-width" appearance="outline">
              <mat-label>Tax Invoice No.</mat-label>
              <input type="text" placeholder="Enter Tax Invoice No." matInput formControlName="invoiceNo"
                [disabled]="showInvoiceGenerateData" [ngClass]="{
                  'is-invalid': (f.invoiceNo.invalid && f.invoiceNo.touched) && submitted }" required>
            </mat-form-field>
            <mat-error class="text-danger" *ngIf="(submitted || f.invoiceNo.touched) && f.invoiceNo.errors?.required">
              Tax Invoice Is Required!
            </mat-error>
          </div>
        </div>
  
        <div class="row d-flex justify-content-start">
  
          <div class="col-md-4">
            <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
              <mat-label>Invoice Date</mat-label>
              <input matInput required ngModel bsDatepicker placeholder="Receipt Date." [maxDate]="today"
                style="text-transform:uppercase!important;" [(ngModel)]="todaysDate" id="datePicker" bsDatepicker
                [bsConfig]="{
              containerClass: 'theme-dark-blue',
              dateInputFormat: 'DD MMM YYYY',
              showWeekNumbers:false }" [ngModelOptions]="{ standalone: true }" [ngClass]="{
              'is-invalid': (todaysDate.invalid && todaysDate.touched) && submitted }">
              <span>
                <mat-icon class="iconcalender">date_range</mat-icon>
              </span>
            </mat-form-field>
            <mat-error class="text-danger" *ngIf="(submitted || todaysDate.touched) && todaysDate.errors?.required">
              Invoice Date Is Required!
            </mat-error>
          </div>
  
  
          <div class="col-md-4 justify-content-between">
            <mat-form-field style="font-size: 11px" class="example-full-width" appearance="outline">
              <mat-label>e-Way Bill No.</mat-label>
              <input type="text" matInput [textMask]="{mask: mask}" placeholder="Enter e-way Bill No."
                formControlName="ewayBillNo" [disabled]="showInvoiceGenerateData">
            </mat-form-field>
          </div>
  
  
          <div class="col-md-4 justify-content-between">
            <mat-form-field style=" font-size: 11px" class="example-full-width" appearance="outline">
              <mat-label>Shipped From(State)</mat-label>
              <mat-select formControlName="shippedFrom" disableOptionCentering aria-label="Default select example"
                placeholder="Select State" [disabled]="showInvoiceGenerateData">
                <mat-option [value]="state" *ngFor="let state of allStates">{{ state }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
  
        <div class="row d-flex justify-content-start">
  
          <div class="col-sm-12 col-md-4 col-lg-4">
            <mat-form-field class="example-full-width " style=" font-size: 11px" appearance="outline">
              <mat-label>Billing Address</mat-label>
              <textarea matInput rows="5" placeholder="Enter Billing Address" formControlName="billingAddress" required
                disabled [ngClass]="{
                  'is-invalid': (f.billingAddress.invalid && f.billingAddress.touched) && submitted }"></textarea>
            </mat-form-field>
            <mat-error class="text-danger"
              *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
              Billing Address Is Required!
            </mat-error>
          </div>
  
          <div *ngIf="isShippingAddress" class="col-sm-12 col-md-4 col-lg-4">
            <mat-form-field class="example-full-width" style=" font-size: 11px" appearance="outline">
              <mat-label>Shipping Address</mat-label>
              <textarea matInput rows="5" placeholder="Enter Shipping Address" formControlName="shippingAddress"
                [disabled]="showInvoiceGenerateData"></textarea>
            </mat-form-field>
          </div>
        </div>
  
        <div class="row container d-flex justify-content-start">
          <div class="col-md-4 d-flex" style="font-size: 12px;">
            <div class="form-check padding-left">
              <input class="form-check-input" id="inlineCheckbox1" type="checkbox" [disabled]="showInvoiceGenerateData"
                (change)="showShippingAddress($event.target.checked)" [checked]="isShippingAddress" />
            </div>
            <label style="margin-left:1px!important" class="text-nowrap ">Add Shipping Address</label>
  
          </div>
        </div>
  
  
        <div class="problem mt-3">
          <ul class="problem-nav">
            <li>
              <div class="d-flex justify-content-between mt-1" style="font-size: 12px;">
                <label>Add Compensation Cess </label>
                <div class="form-check form-switch">
                  <input class="form-check-input toggleB mt-1" style="margin-left: 10px!important;" id="inlineCheckbox1"
                    type="checkbox" (click)="toggleShow()" />
                </div>
              </div>
            </li>
          </ul>
        </div>
  
  
        <div class="table-responsive my-custom-scrollbar " *ngIf="!showInvoiceGenerateData">
          <table class="table bg-white table-bordered table-stripped">
            <thead class="table-secondary">
              <tr class="font_size font-weight-bold">
                <th scope="col" style="width: 7% !important;">
                  #
                </th>
                <th scope="col" style="width: 5%;">
                  SKU<span class="text-danger"> *</span>
                </th>
                <th class="text-nowrap" scope="col">
                  SKU Description
                </th>
                <th>Unit</th>
  
                <th>Quantity</th>
                <th scope="col" class="text-nowrap" colspan="2">
                  Base Price(In Rs.)<span class="text-danger"> *</span>
                </th>
  
                <th class="text-center" scope="col" colspan="2">
                  GST<span class="text-danger"> *</span>
                </th>
                <th scope="col" colspan="2" class="text-nowrap" *ngIf="isShown">
                  Additional CESS(IN INR)<span class="text-danger"> *</span>
                </th>
                <th scope="col" class="text-nowrap">
                  Total(In Rs.)<span class="text-danger"> *</span>
                </th>
                <th scope="col" class="text-nowrap" style="width:8%;">
                  Action<span class="text-danger"> *</span>
                </th>
  
              </tr>
            </thead>
  
            <tbody class="mod">
              <tr>
                <td>#</td>
                <td>
                  <input type="text" matInput style="width: 70px;" placeholder="SKU" (change)="getGoodsByBarcode()"
                    [(ngModel)]="barcode" [ngModelOptions]="{standalone: true}" required />
                </td>
                <td>
                  <input type="text" matInput placeholder="SKU Description" required />
                </td>
                <td colspan="1">
                  <input type="text" matInput placeholder="Unit" style="width: 5rem;" required />
                </td>
                <td colspan="1">
                  <input type="text" matInput placeholder="Quantity" style="width: 5rem;" required />
                </td>
                <td colspan="1">
                  <input type="text" matInput placeholder="Price / Unit" />
                </td>
                <td colspan="1">
                  <input type="text" matInput placeholder="Price" />
                </td>
                <td colspan="1">
                  <input type="text" matInput required placeholder="%" style="width: 3rem;" />
                </td>
                <td colspan="1">
                  <input type="text" matInput required placeholder="IN Rs." style="width: 3rem;" />
                </td>
                <td colspan="1" *ngIf="isShown">
                  <input type="text" matInput placeholder="%" style="width: 5rem;" required />
                </td>
                <td colspan="1" *ngIf="isShown">
                  <input type="text" matInput placeholder="In Rs" style="width: 5rem;" required />
                </td>
                <td>
                  <input type="text" matInput placeholder="Amount" required />
                </td>
                <td></td>
              </tr>
              <ng-container formArrayName="subcheckbox"
                *ngFor="let items of formArr.controls;let item of productData;let i=index">
                <tr [formGroupName]="i">
                  <td>
  
                  </td>
                  <td>
                    {{ item.barcode }}
                  </td>
                  <td>{{ item.itemName | titlecase}}</td>
                  <td class="text-center">
                    <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit"
                      style="width: 5rem;" readonly />
                  </td>
                  <td style="display:flex; flex-direction: row; justify-content: center; align-items: center;">
  
                    <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                      (click)="changeQuantity('-',i)">-</a>
                    <input type="text" matInput [value]="item.quantity" placeholder="Quantity"
                      (change)="changeQuantity('qty',i,$event.target.value)" style="width: 5rem;" required />
                    <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                      (click)="changeQuantity('+',i)">+</a>
                  </td>
                  <td colspan="1">
                    <p class="">
                      {{ item.basePrice |
                      currency:'INR':'symbol':'1.2-2'}}
  
                    </p>
                  </td>
                  <td colspan="1">
                    <p class="">
                      {{item.subTotal |
                      currency:'INR':'symbol':'1.2-2'}}
  
                    </p>
                  </td>
                  <td colspan="1">
                    <p class="" style="width: 3rem;">
                      {{ item.gstRate }}%
                    </p>
                  </td>
                  <td colspan="1">
                    <p class="" style="width: 5rem;">
                      {{ item.gstAmount | currency:'INR':'symbol':'1.2-2' }}
                    </p>
                  </td>
                  <td colspan="1" *ngIf="isShown">
                    <p class="" style="width: 3rem;">{{ item.additionalCess }}% </p>
                  </td>
                  <td colspan="1" *ngIf="isShown">
                    <p class="" style="width: 5rem;">
                      {{ (item.salePrice * item.additionalCess / 100) | currency:'INR':'symbol':'1.2-2'}}
                    </p>
                  </td>
                  <td>
                    <p class="" style="width:100% !important;">
                      {{ item.total | currency:'INR':'symbol':'1.2-2'}}
  
                    </p>
                  </td>
  
                  <td>
                    <button type="button" class="text-center btnb" style="width: 3rem!important;"
                      (click)="changeQuantity('rm',i)">
                      <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip" data-bs-placement="top"
                        title="Delete"></span>
                    </button>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
  
        <div class="table-responsive mt-2  my-custom-scrollbar d-flex justify-content-end"
          *ngIf="!showInvoiceGenerateData">
          <div class="col-sm-6"></div>
          <div class="col-sm-4 table-bordered my-custom-scrollbar">
            <table class="table table-bordered  my-custom-scrollbar">
              <tr>
                <td class="thWidth p-2" style="text-align:left!important;">Subtotal</td>
                <td class="p-2" style="width: 50px;" style="text-align:right!important;">{{ subTotal |
                  currency:'INR':'symbol':'1.2-2'}}</td>
              </tr>
              <tr>
                <td class="thWidth  p-2" *ngIf="gstStateMatch" style="text-align:left!important;">CGST</td>
                <td class="p-2" *ngIf="gstStateMatch" style="width: 50px;" style="text-align:right!important;">{{
                  gstRate/2|
                  currency:'INR':'symbol':'1.2-2'}}</td>
              </tr>
              <tr>
                <td class="thWidth  p-2" *ngIf="gstStateMatch" style="text-align:left!important;">SGST</td>
                <td class="p-2" style="width:50px;" *ngIf="gstStateMatch" style="text-align:right!important;">{{
                  gstRate/2 |
                  currency:'INR':'symbol':'1.2-2'}}</td>
              </tr>
              <tr>
                <td class="thWidth  p-2" *ngIf="!gstStateMatch" style="text-align:left!important;">IGST</td>
                <td class="p-2" *ngIf="!gstStateMatch" style="width: 50px;" style="text-align:right!important;">{{
                  gstRate/2 + gstRate/2|
                  currency:'INR':'symbol':'1.2-2'}}</td>
              </tr>
  
              <tr>
                <td class="thWidth  p-2" *ngIf="isShown" style="text-align:left!important;">Additional Cess</td>
                <td class="p-2" style="width:50px;" *ngIf="isShown" style="text-align:right!important;">{{
                  additionalCess | currency:'INR':'symbol':'1.2-2'}}</td>
              </tr>
              <tr>
                <td class="thWidth  p-2" style="text-align:left!important;">Total</td>
                <td class="p-2" style="width: 50px;" style="text-align:right!important;">{{ total |
                  currency:'INR':'symbol':'1.2-2'}}</td>
              </tr>
            </table>
          </div>
        </div>
  
  
        <div class="table-responsive my-custom-scrollbar d-flex justify-content-start row" style="margin-right: 0.1rem;"
          *ngIf="showInvoiceGenerateData">
          <table class="table bg-white table-bordered table-stripped">
            <thead class="table-secondary">
              <tr class="font_size font-weight-bold">
                <th scope="col" style="width: 7% !important;">
                  #
                </th>
                <th scope="col" style="width: 8%">
                  SKU<span class="text-danger"> *</span>
                </th>
                <th scope="col" class="text-nowrap">
                  SKU Description
                </th>
                <th>Unit</th>
                <th>Quantity</th>
                <th scope="col" class="text-nowrap" colspan="1">
                  Base Price(In Rs.)<span class="text-danger"> *</span>
                </th>
  
                <th class="text-center" scope="col" colspan="2">
                  GST<span class="text-danger"> *</span>
                </th>
                <th scope="col" colspan="2" class="text-nowrap">
                  Additional CESS(IN INR)<span class="text-danger"> *</span>
                </th>
                <th scope="col" class="text-nowrap">
                  Total(In Rs.)<span class="text-danger"> *</span>
                </th>
              </tr>
            </thead>
            <tbody class="mod" *ngIf="orderData.length > 0">
              <tr *ngFor="let item of orderData;let i=index">
                <td>{{ i + 1 }}</td>
                <td>{{ item.barcode }}</td>
  
                <td>
                  {{ item.itemName }}
                </td>
                <td colspan="1">
                  <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit"
                    style="width: 5rem;" readonly />
                </td>
                <td colspan="1" class="text-center m-auto">
                  <p class="">{{ item.quantity }}</p>
                </td>
                <td colspan="1">
                  <p class="">{{ subTotal | currency:'INR':'symbol':'1.2-2'}}</p>
                </td>
                <td colspan="1">
                  <p class="" style="width: 5rem;">{{ item.gst }}%</p>
                </td>
                <td colspan="1">
                  <p class="" style="width: 5rem;">{{ item.gstAmount |
                    currency:'INR':'symbol':'1.2-2'}}
                  </p>
                </td>
                <td colspan="1">
                  <p class="" style="width: 5rem;">{{ item.additionalCess }}% </p>
                </td>
                <td colspan="1">
                  <p class="" style="width: 5rem;">{{ item.additionalCess |
                    currency:'INR':'symbol':'1.2-2'}}
                  </p>
                </td>
                <td>
                  <p class="">{{ item.finalAmount | currency:'INR':'symbol':'1.2-2'}}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
  
  
  
  
        <div class="table-responsive my-custom-scrollbar d-flex justify-content-end" *ngIf="showInvoiceGenerateData">
          <div class="col-lg-8 col-sm-6"></div>
          <div class="col-lg-4 col-sm-4 table-bordered my-custom-scrollbar" style="width: 30%;">
            <table class="table table-bordered  my-custom-scrollbar">
              <tr>
                <td style="text-align:left!important;">Subtotal</td>
                <td style="text-align:right!important;">{{ subTotal |
                  currency:'INR':'symbol':'1.2-2'}}</td>
  
              </tr>
              <tr>
                <td style="text-align:left!important;">CGST</td>
                <td style="text-align:right!important;">
                  {{ totalGST/2 | currency:'INR':'symbol':'1.2-2'}}</td>
              </tr>
              <tr>
                <td style="text-align:left!important;">SGST</td>
                <td style="text-align:right!important;">{{ totalGST/2 | currency:'INR':'symbol':'1.2-2'}}</td>
              </tr>
  
              <tr>
                <td style="text-align:left!important;">Total</td>
                <td style="text-align:right!important;">{{ total |
                  currency:'INR':'symbol':'1.2-2'}}</td>
              </tr>
            </table>
          </div>
        </div>
  
  
  
        <div class="col-12 d-flex  justify-content-end">
          <div class="" *ngIf="!showInvoiceGenerateData">
  
          </div>
          <a type="submit" class="btn btn-outline-primary" style="margin-left: 10px;" (click)="postSaleInvoice()"
            *ngIf="!showInvoiceGenerateData">Save</a>
  
  
          <a type="button" class="  btn btn-outline-primary" style="margin-left: 10px;"
            *ngIf="showInvoiceGenerateData && !showPrintBtn" (click)="patchGenerateRemoteInovice()">Generate
            Invoice</a>
  
          <a type="button" class="btn btn-primary" style="margin-left: 10px;" data-bs-toggle="modal"
            data-bs-target="#invoice-modal" *ngIf="showPrintBtn" (click)="getInvoiceByOrderId()">Print</a>
  
        </div>
  
  
        <ng-template #addParty let-modal>
          <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
              <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" (click)="modalDismiss()"
                aria-label="Close"></a>
            </div>
            <div class="modal-body p-0">
              <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                <h4 class="mb-1" id="modalExampleDemoLabel">Add New Party</h4>
              </div>
              <form [formGroup]="PartyForm">
                <div class="p-4 pb-0">
                  <div class="form-check form-switch mb-0 lh-1 mb-3">
                    <input class="form-check-input" type="checkbox" [checked]="isShowGstFields"
                      id="flexSwitchCheckDefault" (click)="customertoggleShow($event.target.checked)" />
                    <label class="lab text-nowrap p-1"> As Customer </label>
                  </div>
  
                  <div class="row d-flex justify-content-start" *ngIf="!isShowGstFields">
                    <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>GST Number</mat-label>
                        <input matInput required formControlName="gstIn" (change)="getGstNumber($event.target.value)"
                          maxlength="15" minlength="15" placeholder="GST Number"
                          pattern="^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$"
                          placeholder="GST Number" style="text-transform:uppercase">
                      </mat-form-field>
                      <mat-error *ngIf="(submittedParty ||fp.gstIn.touched) && fp.gstIn.errors?.required">
                        Enter a valid GSTIN.
                      </mat-error>
  
                      <mat-error
                        *ngIf="(submittedParty ||fp.gstIn.touched) && fp.gstIn.errors?.pattern || fp.gstIn.errors?.minlength">
                        Enter a valid GSTIN.
                      </mat-error>
                      <mat-error *ngIf="(submittedParty ||fp.gstIn.touched) && fp.gstIn.errors?.maxlength">
                        Enter a valid GSTIN.
                      </mat-error>
  
                    </div>
                    <div style=" font-size: 12px" class=" col-xs-12 col-sm-12 col-md-4 col-lg-4">
  
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>GST Type</mat-label>
                        <mat-select matNativeControl required formControlName="gstType" disableOptionCentering
                          placeholder="GST Type" [disabled]="!isDisabledGST">
                          <mat-option value="REGULAR">Regular</mat-option>
                          <mat-option value="COMPOSITE">Composite</mat-option>
                          <mat-option value="EXEMPTED">Exempted</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-error *ngIf="(submittedParty ||fp.gstType.touched) && fp.gstType.errors?.required">
                        GST Type Is Required!
                      </mat-error>
                    </div>
                  </div>
  
                  <div class="row d-flex justify-content-start">
                    <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Party Name</mat-label>
                        <input matNativeControl required matInput placeholder="Party Name" formControlName="partyName"
                          [ngClass]="{
                            'is-invalid': (fp.partyName.invalid && fp.partyName.touched) && submittedParty }">
                      </mat-form-field>
                      <mat-error class="text-danger" *ngIf="(submittedParty || fp.partyName.touched)">
                        <mat-error *ngIf="(submittedParty || fp.partyName.touched) && fp.partyName.errors?.required">
                          Party Name Is Required!
                        </mat-error>
                      </mat-error>
  
                    </div>
                    <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Mobile Number</mat-label>
                        <input type="text" placeholder="Mobile Number" matInput formControlName="mobile" minlength="10"
                          maxLength="10" ngxOnlyNumbers [ngClass]="{
                              'is-invalid': (fp.mobile.invalid && fp.mobile.touched) && submittedParty }">
                      </mat-form-field>
  
                      <mat-error class="text-danger"
                        *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.pattern">
                        Enter a valid mobile number.
                      </mat-error>
                      <mat-error class="text-danger"
                        *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.required">
                        Mobile Number is required.
                      </mat-error>
                      <mat-error *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.maxlength">
                        Mobile Number must be of 10-digits
                      </mat-error>
                      <mat-error *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.minlength">
                        Mobile Number must be of 10-digits
                      </mat-error>
                    </div>
                    <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>E-Mail</mat-label>
                        <input type="email" placeholder="e-mail" matInput formControlName="email" [ngClass]="{
                              'is-invalid': (fp.email.invalid && fp.email.touched) && submittedParty }">
                      </mat-form-field>
                      <mat-error class="text-danger"
                        *ngIf="(submittedParty || fp.email.touched) && fp.email.errors?.pattern && fp.email.errors?.email">
                        Enter a valid email id.
                      </mat-error>
                      <mat-error class="text-danger"
                        *ngIf="(submittedParty || fp.email.touched) && fp.email.errors?.required">
                        Email id is required.
                      </mat-error>
                    </div>
                  </div>
  
                  <div class="row d-flex justify-content-start">
                    <div class="col-lg-4 col-sm-12 col-md-6">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Billing Address</mat-label>
                        <textarea matInput required formControlName="billingAddress" placeholder="Enter your address"
                          [ngClass]="{
                              'is-invalid': (fp.billingAddress.invalid && fp.billingAddress.touched) && submittedParty }"></textarea>
                      </mat-form-field>
                      <mat-error class="text-danger" *ngIf="(submittedParty || fp.billingAddress.touched)">
                        <mat-error
                          *ngIf="(submittedParty || fp.billingAddress.touched) && fp.billingAddress.errors?.required">
                          Billing Address Is Required!
                        </mat-error>
                      </mat-error>
                    </div>
  
                    <div class="col-lg-4 col-sm-12 col-md-6" *ngIf="isShippingAddressinModal">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Shipping Address</mat-label>
                        <textarea matInput formControlName="shippingAddress" placeholder="Enter your address"></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="form-check form-check-inline mb-2">
                    <label class="form-check-label fs--1" for="inlineCheckbox1">Add Shipping Address</label>
                    <input class="form-check-input fs--1" id="inlineCheckbox1" type="checkbox"
                      (change)="showShippingAddressinModal($event.target.checked)" />
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button class="btn btn-outline-primary" (click)="modalDismiss()">Close</button>
              <button class="btn btn-outline-primary" (click)="addNewParty()" data-bs-dismiss="modal">Add
              </button>
            </div>
          </div>
        </ng-template>
  
  
        <div class="modal fade" id="invoice-modal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content position-relative">
              <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" #closea></a>
              </div>
              <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                  <div class="row justify-content-between align-items-center">
                    <div class="col-md">
                      <h4 class="mb-2 mb-md-0">Order #{{ invoiceNo }}</h4>
                    </div>
                    <div class="col-auto">
                      <a class="btn btn-falcon-default btn-sm me-1 mb-2 mb-sm-0" (click)="onPrint()"><span
                          class="fas fa-print me-1"> </span>Print</a>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div class="card mb-3" id="panel">
                    <div class="card-body">
                      <h2 class="mb-3 text-center">Tax Invoice</h2>
                      <div class="row justify-content-betweenxt-center mb-3">
                        <div class="col-sm-6 text-sm-start">
                          <h3>To:</h3>
                          <h5>{{ customerName }}</h5>
                          <p class="fs--1 mb-0">{{ billingAddress }}<br /></p>
                        </div>
                        <div class="col text-sm-end mt-3 mt-sm-0">
                          <h3>From:</h3>
                          <h5>{{ legalName }}</h5>
                          <p class="fs--1 mb-0">{{ homeNo }} {{address }}, {{ district }}<br />{{ city }},{{ state |
                            titlecase}}, {{ country }}</p>
                        </div>
                        <div class="col-12">
                        </div>
                      </div>
                      <div class="table-responsive scrollbar mt-4 fs--1">
                        <table class="table table-striped table-bordered">
                          <thead class="light">
                            <tr class="bg-primary text-white dark__bg-1000">
                              <th class="border-0">Products</th>
                              <th class="border-0 text-center">Quantity</th>
                              <th class="border-0 text-end">Rate</th>
                              <th class="border-0 text-end">GST(in Rs.)</th>
                              <th class="border-0 text-end">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let i of remoteProductData">
                              <td class="align-middle">
                                {{ i.itemName | titlecase }}
                              </td>
                              <td class="align-middle text-center">{{ i.quantity }}</td>
                              <td class="align-middle text-end">{{ i.totalPrice | currency:'INR':'symbol':'1.2-2'}}</td>
                              <td class="align-middle text-end">{{ i.gstAmount | currency:'INR':'symbol':'1.2-2'}}</td>
                              <td class="align-middle text-end">{{ i.finalAmount | currency:'INR':'symbol':'1.2-2'}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-6"></div>
                        <div class="col-sm-6 col-md-6 col-lg-6">
                          <div class="table-responsive scrollbar fs--1">
                            <table class="table table-striped">
                              <thead class="light">
                                <tr class="bg-primary text-white dark__bg-1000">
                                  <th class="border-0">Tax Type</th>
                                  <th class="border-0 text-center"></th>
                                  <th class="border-0 text-center">Tax Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="align-middle">SGST</td>
                                  <td></td>
                                  <td class="align-middle text-center">{{ remoteGSTAmount / 2 |
                                    currency:'INR':'symbol':'1.2-2'}}
                                  </td>
                                </tr>
                                <tr>
                                </tr>
                                <tr>
                                  <td class="align-middle">CGST</td>
                                  <td></td>
                                  <td class="align-middle text-center">{{ remoteGSTAmount / 2 |
                                    currency:'INR':'symbol':'1.2-2'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="align-middle">Total</td>
                                  <td></td>
                                  <td class="align-middle text-center">
                                    {{ remoteTotalBillAmount | currency:'INR':'symbol':'1.2-2'}}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer bg-light">
                      <p class="fs--1 mb-0"><strong>Notes: </strong>We really appreciate your business and if there’s
                        anything else we
                        can
                        do, please let us know!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form> -->
  