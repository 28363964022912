import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import * as moment from 'moment';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardService } from 'src/app/core/services/dashboard.service';
HC_exporting(Highcharts);

@Component({
  selector: 'app-stock-details',
  templateUrl: './stock-details.component.html',
  styleUrls: ['./stock-details.component.scss']
})
export class StockDetailsComponent implements OnInit {

  public todaysDate = this.commonService.getTodaysDate();
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public stockDetailsChart: any = [];
  totalProfit: number;
  totalLoss: number;
  grossProfit: number;
  grossLoss: number;
  public salesType: string = 'Weekly';
  public defaultSaleSelect = "Weekly";
  public isWeek: boolean = true;
  public isMonth: boolean = false;
  public isYear: boolean = false;

  constructor(public commonService: CommonService, public dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.getProfitLoss()
  }

  getSaleInvoiceReportType(type) {
    this.salesType = type
    if (type == 'Weekly') {
      this.isWeek = true;
      this.isMonth = false;
      this.isYear = false;
      this.getProfitLoss()
    } else if (type == 'Monthly') {
      this.isWeek = false;
      this.isMonth = true;
      this.isYear = false;
      this.getProfitLoss()
    } else if (type == 'Yearly') {
      this.isWeek = false;
      this.isMonth = false;
      this.isYear = true;
      this.getProfitLoss()
    }
  }

  getProfitLoss(){
    const today = new Date(this.todaysDate);
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    const lastWeekStartDate = `${lastWeek.getFullYear()}-${lastWeek.getMonth() + 1}-${lastWeek.getDate()}`;
    if (this.salesType === 'Weekly'){
      this.dashboardService.getProfitLoss({}, this.currentUser.id, lastWeekStartDate, this.todaysDate).then((result: any) => {
        // console.log(result.data)
        this.grossProfit = result.data.grossProfit
        this.grossLoss = result.data.grossLoss
        this.totalProfit = result.data.profit
        this.totalLoss = result.data.loss
        this.getPieChart()
      }, (error) => {
        if (error.error.status == 404) {
        }
      })
    }

    // if monthly
    if (this.salesType === 'Monthly'){
      const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
      const oneMonthAgoDate = `${oneMonthAgo.getFullYear()}-${oneMonthAgo.getMonth() + 1}-${oneMonthAgo.getDate()}`;
      this.dashboardService.getProfitLoss({}, this.currentUser.id, oneMonthAgoDate, this.todaysDate).then((result: any) => {
        // console.log(" in monthlyh")
        // console.log(result.data)

        this.grossProfit = result.data.grossProfit
        this.grossLoss = result.data.grossLoss
        this.totalProfit = result.data.profit
        this.totalLoss = result.data.loss
        this.getPieChart()
      }, (error) => {
        if (error.error.status == 404) {
          // this.newPartiesList = [];
        }
      })
    }
    // // if yearly
    if (this.salesType === 'Yearly'){
      // console.log(" in yearly")
      const oneYearAgo = moment().subtract(1, 'years').format('YYYY-MM-DD')
      this.dashboardService.getProfitLoss({}, this.currentUser.id,  oneYearAgo, this.todaysDate).then((result: any) => {
        this.grossProfit = result.data.grossProfit
        this.grossLoss = result.data.grossLoss
        this.totalProfit = result.data.profit
        this.totalLoss = result.data.loss
        this.getPieChart()
      }, (error) => {
        if (error.error.status == 404) {
          // this.newPartiesList = [];
        }
      })
    }
  }

  getPieChart() {
    this.stockDetailsChart = Highcharts;

    if (this.grossProfit === 0 && this.grossLoss === 0 && this.totalLoss === 0 && this.totalProfit === 0) {
      // Display a "No data found" message
      const chartOptions = {
        chart: {
          type: 'pie',
          events: {
            load: function() {
              const chart = this;
              const centerX = chart.plotWidth / 2;
              const centerY = chart.plotHeight / 2;

              // Add the image element
              chart.noDataImage = chart.renderer
                .image('../../../assets/img/icons/spot-illustrations/notfound1.png', centerX - 90, centerY - 140, 200, 150)
                .add();
            }
          }
        },
        title: {
          // text: 'Gross Profit/Loss',
          text: ' ',
          style: {
            fontFamily: "'Poppins', sans-serif",
              fontWeight: '500',
            fontSize: '16px',
            color: '#737791'
          }
        },
        credits: {
          enabled: false
        }
      };
      this.stockDetailsChart.chart('stock-details-chart', chartOptions);
      document.getElementById('stock-details-chart').style.height = '300px';

    } else {
        const chartOptions = {
          chart: {
            type: 'pie',
          },
          credits:{
            enabled: false
         },
          title: {
            // text: 'Gross Profit/Loss',
            text: ' ',
            style: {
              fontFamily: "'Poppins', sans-serif",
              fontWeight: '500',
              fontSize: '16px',
              color: '#737791'
            }
          },
          yAxis : {
            gridLineWidth: 0
          },
          colors: ['#110152'],
          series: [
            {
              name: 'profit',
              size : '100%',
              data: [
                ['Gross Profit', this.grossProfit],
                {
                  name: 'Total Profit',
                  y: this.totalProfit,
                  color: '#EDEBF0'
                }
              ],
              innerSize: '80%',
              dataLabels: {
                enabled: true
              }
            },
            {
              name: 'Loss',
              size : '50%',
              data: [
                ['Gross Loss', this.grossLoss],
                {
                  name: 'Total Loss',
                  y: this.totalLoss,
                  color: '#FC7643'
                }
              ],
              innerSize: '70%',
              dataLabels: {
                enabled: true
              }
            }
          ]
        };
        this.stockDetailsChart.chart('stock-details-chart', chartOptions);

        // Add CSS to adjust the size visually
        // document.getElementById('salesCanvas').style.width = '300px'; // Set your desired width
        document.getElementById('stock-details-chart').style.height = '200px';
      }
}
}
