<!-- <div class="modal-header row">
    <div class="col-sm-6">
        <h1 style="color: #EB8B3F; font-weight: bolder;" class="modal-title">Create Campaign</h1>
    </div>
    <div class="col-sm-2"></div>
    <div class="col-sm-2">
        <button type="button" class="btn btn-cancel" (click)="cancel()">Cancel</button>
    </div>

    <div class="col-sm-2">
        <button type="button" class="btn btn-save" (click)="submitForm()">Save</button>
    </div>

</div> -->

<div class="modal-header pt-0">
  <h1 style="color: #EB8B3F; font-weight: 500;" class="modal-title mb-0 ms-2">Create Campaign</h1>

  <div class="addProductButtons">

    <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel" (click)="cancel()">Cancel</button>

    <button type="button" class="btn btn-save" (click)="submitForm()">Save</button>

  </div>
</div>

<div class="modal-body">
    <form [formGroup]="campForm">
        <div style="display: flex; justify-content: end;">
            <label class="form-label me-3"><b style = "font-weight: 500;">{{ isActive ? 'Active' : 'Inactive' }}</b></label>
            <button class="toggle-button" (click)="toggleActiveState()" [ngClass]="{ 'active': isActive , 'inactive': !isActive}">
                <div class="toggle-slider"></div>
            </button>
        </div>

        <div class="row">
            <div class="col-sm-12 col-lg-12 col-md-12 mb-3">
                <label class="form-label">Campaign Name <span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput formControlName="campaignName" required placeholder="Campaign Name"
                [ngClass]="{'is-invalid': (campForm.get('campaignName').invalid && campForm.get('campaignName').touched) && submitted }"/>
            </mat-form-field>
            <mat-error class="text-danger"
                        *ngIf="(submitted || campForm.get('campaignName').touched) && campForm.get('campaignName').errors?.required">
                        Campaign Name is required
            </mat-error>

            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-lg-12 col-md-12 mb-3">
                <label class="form-label">Campaign Description <span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput formControlName="campaignDescription" row="3" placeholder="Description"
                    [ngClass]="{'is-invalid': (campForm.get('campaignDescription').invalid && campForm.get('campaignDescription').touched) && submitted }">
                    </textarea>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || campForm.get('campaignDescription').touched) && campForm.get('campaignDescription').errors?.required">
                    Description is required!
                </mat-error>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-lg-12 col-md-12 mb-3">
                <label class="form-label">To <span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select formControlName="partySelection" multiple placeholder="To"
                    [ngClass]="{'is-invalid': (campForm.get('partySelection').invalid && campForm.get('partySelection').touched) && submitted }">
                        <option (click)="toggleSelectAllParties()" class="select-all" [value]="'all'">Select All</option>
                        <mat-option *ngFor="let item of toList" [value]="item.id" (click)="togglePartySelection(item)">
                            {{ item.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || campForm.get('partySelection').touched) && campForm.get('partySelection').errors?.required">
                    Select whom you want to sent!
                </mat-error>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-lg-6 col-md-6 mb-3">
                <label class="form-label">Select Date <span class="error-asterisk">*</span></label>
                <mat-form-field appearance="outline" class="example-full-width">
                    <input matInput formControlName="selectedDate" placeholder="DD MMM YYYY" [(ngModel)]="todaysDate"
                        autocomplete="off" [outsideClick]="true" style="text-transform: uppercase !important"
                        id="As On Date" bsDatepicker [bsConfig]="{
                                            dateInputFormat: 'DD MMM YYYY',
                                            containerClass: 'theme-dark-blue',
                                            showWeekNumbers: false,
                                            displayOneMonthRange: true
                                        }"
                        [minDate]="todaysDate"
                        [ngClass]="{'is-invalid': (campForm.get('selectedDate').invalid && campForm.get('selectedDate').touched) && submitted }"
                        (click)="openDatepicker()" />
                    <span>
                        <mat-icon class="iconcalender">date_range</mat-icon>
                    </span>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || campForm.get('selectedDate').touched) && campForm.get('selectedDate').errors?.required">
                    Select Date is required!
                </mat-error>
            </div>

            <!-- Do not remove this commented code need to customise this after some time -->
            <!-- <div class="col-sm-12 col-lg-6 col-md-6 mb-3">
                <label class="form-label">Select Time</label>
                <timepicker formControlName="selectedTime" #timepicker></timepicker>
            </div> -->

            <div class="col-sm-12 col-lg-6 col-md-6">
                <label class="form-label">Select Time <span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width time-field" appearance="outline">
                    <input type="time" matInput placeholder="Select Time" formControlName="selectedTime" [(ngModel)]="currentTime"
                    [ngClass]="{'is-invalid': (campForm.get('selectedTime').invalid && campForm.get('selectedTime').touched) && submitted }"
                    class="small-height-input" (change)="onTimeChange()">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || campForm.get('selectedTime').touched) && campForm.get('selectedTime').errors?.required">
                    Select Time is required!
                </mat-error>
            </div>
        </div>

        <div class="row">
          <div class = "col-sm-12 col-md-6 my-3"  style = "padding-left: 15px;">
            <mat-checkbox formControlName="sendSMS">
              <span style = "font-size: 14px !important;font-weight: 500;">Send SMS</span></mat-checkbox>
          </div>
          <div class = "col-sm-12 col-md-6 my-3"  style = "padding-left: 15px;">
            <mat-checkbox formControlName="sendNotification">
              <span style = "font-size: 14px !important;font-weight: 500;">Send Notification</span></mat-checkbox>
          </div>
          <div class = "col-sm-12 col-md-6 my-3"  style = "padding-left: 15px;">
            <mat-checkbox formControlName="sendEmail">
              <span style = "font-size: 14px !important;font-weight: 500;">Send Email</span></mat-checkbox>
          </div>
          <div class = "col-sm-12 col-md-6 my-3"  style = "padding-left: 15px;">
            <mat-checkbox formControlName="sendWhatsappMsg">
              <span style = "font-size: 14px !important;font-weight: 500;">Send WhatsApp Message</span></mat-checkbox>
          </div>
        </div>
    </form>

</div>
