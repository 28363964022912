<!-- <ul class="nav nav-tabs m-3 justify-content-center" id="myTab" role="tablist">
    <li class="nav-item"><a class="nav-link active tab-size" id="home-tab" data-bs-toggle="tab" href="#tab-home"
            role="tab" aria-controls="tab-home" aria-selected="true">My Retailers</a></li>
    <li class="nav-item"><a class="nav-link" id="profile-tab" data-bs-toggle="tab" href="#tab-profile" role="tab"
            aria-controls="tab-profile" aria-selected="false">Pending Order</a></li>
    <li class="nav-item"><a class="nav-link" id="contact-tab" data-bs-toggle="tab" href="#tab-contact" role="tab"
            aria-controls="tab-contact" aria-selected="false">Order Receipts</a></li>
</ul> -->
<div class="card mb-3">

    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
            Available Shop
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>
    <div class="card-body">
        <div class="bg-white">
            <div class="bg-white scroll">
                <div class="row">
                    <div class="col-lg-3 col-sm-12 col-md-3 col-xl-3" style="margin-left: 1px !important; font-size: 12px">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Search</mat-label>
                            <input type="text" matInput placeholder="Search" [(ngModel)]="searchKey"
                                (change)="getAppointmentUserssearch(searchKey)">
                            <span>
                                <mat-icon class="iconcalender p-1">search</mat-icon>
                            </span>
                        </mat-form-field>
                    </div>
                </div>
                <!-- <div class="row justify-content-end m-2">
                    <div class="search-box mb-3">
                        <form class="position-relative">
                            <input class="form-control search-input fuzzy-search" type="search"
                                placeholder="Search..." />
                            <span class="fas fa-search search-box-icon"></span>
                        </form>
                        <!-- <div class="btn-close-falcon-container position-absolute end-0 top-50 translate-middle shadow-none"
                            data-bs-dismiss="search">
                            <div class="btn-close-falcon" aria-label="Close"></div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="d-flex justify-content-center m-5" *ngIf="loading && tableData.length <= 1">
                    <div class="spinner-border">
    
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div> -->

                <div class="row mt-2 mb-2">
                    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3" *ngFor="let i of allAppointmentShops">
                        <div class="card bg-white pointer-event-cursor mb-3"
                            routerLink="/pages/customer/book-appointment/{{i.id}}">
                            <img class="card-img-top retailerCard" src="/assets/images/forshop.jpg">
                            <div class="card-body  card_height">
                                <h3 class="card-text m-0">
                                    {{ (i.firstName ? i.firstName : "-") + " " + (i.lastName ? i.lastName : "-") }}
                                </h3>
                                <p class="card-text m-0">
                                    {{ i.email ? i.email : "-" }}
                                    <!-- Appliances,Clothing,Electronics -->
                                </p>
                                <p class="card-text m-0">
                                    {{ i.client ? i.client.legalName : "" }}

                                    <span>
                                        <button class="btn btn-light float-right p-0 pointer-event-cursor"
                                            title="Delivery Service" style="background-color: none;">
                                        </button>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-3 card_radius" *ngFor="let p of nearByShops">
                        <div class="card shadow bg-white cardclick" routerLink="/pages/remoteViewProduct/{{ p.id }}">
                            <img class="card-img-top retailerCard" [src]="p.shopImage ? p.shopImage : defaultshopImg">
                            <div class="card-body card_height">
                                <h6 class="card-text m-0">
                                    {{ p.name | titlecase }}
                                </h6>
                                <p class="card-text m-0">
                                    {{ p.category | titlecase }}
                                </p>
                                <p class="card-text m-0">
                                    {{ p.address | titlecase }}
                                    <span>
                                        <button class="btn btn-light float-right p-0 pointer-event-cursor"
                                            [disabled]="p.deliveryService == 0" title="Delivery Service"
                                            style="background-color: none;">
                                            <i class="fas fa-truck"></i>
                                        </button>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
     -->
                    <!-- </div> -->
                    <!-- <mat-paginator class="float-right" *ngIf="tableData.length> 0" #paginator [pageSize]="pagesizeReat"
                    [pageSizeOptions]="[12, 24, 36]" [showFirstLastButtons]="true" [length]="count"
                    [pageIndex]="currentPageNo" (page)="pageEvent = handlePage($event)">
                </mat-paginator> -->
                </div>

            </div>
        </div>
    </div>
</div>

    <!-- <div class="tab-pane fade" id="tab-contact" role="tabpanel" aria-labelledby="contact-tab">
      
    </div> -->
