import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss']
})
export class DeleteUserComponent implements OnInit {
  conactForm: any;
  public submitted: boolean;

  constructor(private formBuilder: FormBuilder, private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe, public rolePermissionservice: RolesPermissionsService,
  ) { }

  ngOnInit(): void {

    this.conactForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")]],
      reason: ['', [Validators.required]],
    });

  }

  get f() {
    return this.conactForm.controls;
  }


  submitForm() {
    if (this.conactForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    } else {
      let usersData =
      {
        "email": this.conactForm.controls.email.value,
        "reason": this.conactForm.controls.reason.value
      }

      this.rolePermissionservice.deleteUsers(usersData).then((res) => {
        if (res.success) {
          this.toastService.toastMsg({
            title: "Success",
            content: "Account Delete Request Sent!!!"
          });
          this.conactForm.reset();
        }
      },
        (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        })
    }
  }
}
