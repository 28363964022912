<div class="modal-header pt-0">
    <div class="headingmodal">
        <h1 style="color: #EB8B3F; font-weight: bolder;" class="modal-title mb-2" *ngIf="!isArabic">{{serviceName}}</h1>
        <h1 style="color: #EB8B3F; font-weight: bolder;" class="modal-title mb-2" *ngIf="isArabic">{{serviceName}}</h1>
        <h2 style="color:black; font-weight: bolder;" class="modal-title mb-2" *ngIf="!isArabic">{{category}}</h2>
        <h2 style="color: black; font-weight: bolder;" class="modal-title mb-2" *ngIf="isArabic">{{category}}</h2>
    </div>

    <div class="addProductButtons">

        <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel"
            (click)="cancel()">Cancel</button>


    </div>

</div>
<div class="modal-body">

    <form [formGroup]="ProductForm" *ngIf="!isArabic">
        <div class="row mb-3">

            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Service Code</label>
                <h4>{{serviceCode}}</h4>
            </div>


            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Generate Barcode</label>
                <h4>{{generateBercode}}</h4>
            </div>

        </div>
        <div class="row mb-3">

            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">HSN/SAC Code</label>
                <h4>{{hsn}}</h4>
            </div>


            <!-- Price -->

            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Sale Price Inc. GST</label>
                <h4>₹&nbsp;{{price | number:'1.2-2'}}</h4>
            </div>

        </div>


        <div class="row mb-3">
            <div style="margin-bottom: -13px; font-size: 12px;width:95%;" class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <label class="fw-bolder form-label">Service Description</label>
                <h4 style="word-wrap: break-word;">{{description}}</h4>
            </div>
        </div>



        <!-- Additional Cess -->

        <div class="row mb-3">
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Additional Cess</label>
                <h4>₹&nbsp;{{additionalCess | number:'1.2-2'}}</h4>
            </div>

            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">GST Rate(%)</label>
                <h4>{{gstRate}}</h4>
            </div>


        </div>
        <div class="row mb-3">

            <!-- Opening Stock  -->

            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Opening Stock</label>
                <h4>{{openingStock}}</h4>
            </div>

            <!-- As on Date -->
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <label class="fw-bolder form-label">As on Date</label>
                <h4>{{asOfDate}}</h4>
            </div>
        </div>

        <div class="row" *ngIf="imageSrcSeven !== null || fetchImages.length > 0">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <p class="d-md-inline-block fs--1 ml-5" style="font-weight:600;font-size:20px!important;">
                    View Service Image
                </p>
            </div>

        </div>

        <div class="row mt-2">
            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 flex d-flex flex-row" style="justify-content: center;" *ngIf="imageSrcSeven !== null">
                <div class="{{imageSrcSeven ? '':'dash'}} " style="width: 200px !important; height: 200px !important;">


                    <img *ngIf="imageSrcSeven" class="m-2" height="200" width="200"
                        [src]="imageSrcSeven ? imageSrcSeven : ''" (load)="handleCoverImageLoad()"
                        [class.loaded]="imageLoaded" class="text-center" />
                    <div class="input--file">

                        <div *ngIf="!imageSrcSeven" class="mt-1 pl-0">
                            cover photo
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 mb-4 flex d-flex flex-row" *ngFor="let fetch of fetchImages" style="justify-content: center;align-items: center;">
                <img [src]="fetch.productImage" class="preview" height="200" width="200px" />
            </div>
        </div>

        <!-- <div class="row mt-2" *ngIf="imageSrcSeven !== null">
            <div class="col-sm-12 flex d-flex flex-row" style="justify-content: center;">

                <div class="{{imageSrcSeven ? '':'dash'}} " style="width: 150px !important; height: 150px !important;">


                    <img *ngIf="imageSrcSeven" class="m-2" height="150" width="150"
                        [src]="imageSrcSeven ? imageSrcSeven : ''" (load)="handleCoverImageLoad()"
                        [class.loaded]="imageLoaded" class="text-center" />
                    <div class="input--file">

                        <div *ngIf="!imageSrcSeven" class="mt-1 pl-0">
                            cover photo
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 mb-4" *ngFor="let fetch of fetchImages"
                style="justify-content: center;align-items: center;">
                <img [src]="fetch.productImage" class="preview" height="200" width="200px" />
            </div>

        </div> -->


        <!-- <div class="row mt-3">
            <div class="col-sm-12 col-md-6 col-lg-6 flex d-flex flex-row pt-3"
                style="justify-content: center;align-items: center;">
                <div class="{{imageSrcOne ? '':'dash2'}} " style="width: 150px !important;height:150px !important;">

                    <img *ngIf="imageSrcOne" height="150" width="150" [src]="imageSrcOne ? imageSrcOne : ''"
                        (load)="handleImageLoad()" [class.loaded]="imageLoaded" class="text-center" />

                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 flex d-flex flex-row pt-3"
                style="justify-content: center;align-items: center;">
                <div class="{{imageSrcTwo ? '':'dash2'}} " style="width: 150px !important;height:150px !important;">

                    <img class="m-2" *ngIf="imageSrcTwo" height="150" width="150" [src]="imageSrcTwo ? imageSrcTwo : ''"
                        (load)="handleImageLoad()" [class.loaded]="imageLoaded" class="text-center" />

                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-sm-12 col-md-6 col-lg-6 flex d-flex flex-row pt-3"
                style="justify-content: center;align-items: center;">
                <div class="{{imageSrcThree ? '':'dash2'}} " style="width: 150px !important;height:150px !important;">


                    <img *ngIf="imageSrcThree" height="150" width="150" class="m-2"
                        [src]="imageSrcThree ? imageSrcThree : ''" (load)="handleImageLoad()"
                        [class.loaded]="imageLoaded" class="text-center" />
                    <div class="input--file">
                    </div>
                </div>

            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 flex d-flex flex-row pt-3"
                style="justify-content: center;align-items: center;">
                <div class="{{imageSrcFour ? '':'dash2'}} " style="width: 150px !important;height:150px !important;">


                    <img *ngIf="imageSrcFour " height="150" width="150" class="m-2"
                        [src]="imageSrcFour ? imageSrcFour : ''" (load)="handleImageLoad()" [class.loaded]="imageLoaded"
                        class="text-center" />
                    <div class="input--file">
                    </div>
                </div>

            </div>
        </div>

        <div class="row mt-3">
            <div class="col-sm-12 col-md-6 col-lg-6 flex d-flex flex-row pt-3"
                style="justify-content: center;align-items: center;">
                <div class="{{imageSrcFive ? '':'dash2'}} " style="width: 150px !important;height:150px !important;">


                    <img *ngIf="imageSrcFive " height="150" width="150" class="m-2"
                        [src]="imageSrcFive ? imageSrcFive : ''" (load)="handleImageLoad()" [class.loaded]="imageLoaded"
                        class=" text-center" />
                    <div class="input--file">
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 flex d-flex flex-row pt-3"
                style="justify-content: center;align-items: center;">
                <div class="{{imageSrcSix ? '':'dash2'}} " style="width: 150px !important;height:150px !important;">

                    <img *ngIf="imageSrcSix " height="150" width="150" class="m-2"
                        [src]="imageSrcSix ? imageSrcSix : ''" (load)="handleImageLoad()" [class.loaded]="imageLoaded"
                        class="text-center" />
                    <div class="input--file">
                    </div>
                </div>
            </div>
        </div> -->

    </form>

    <form [formGroup]="ProductForm" *ngIf="isArabic">
        <div class="row mb-3">
                       <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Service Code</label>
                <h4>{{serviceCode}}</h4>
            </div>

            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Barcode/SKU</label>
                <h4>{{generateBercode}}</h4>
            </div>

        </div>
        <div class="row mb-3">

            <!-- Price -->

            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">{{ lang.SalePriceInc }}</label>
                <h4>AED&nbsp;{{price}}</h4>
            </div>

            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">{{lang.GSTRate}}</label>
                <h4>{{gstRate}}</h4>
            </div>

        </div>


        <div class="row mb-3">
            <div style=" font-size: 12px;width: 95%; " class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <label class="fw-bolder form-label">Service Description</label>
                <h4 style="word-wrap: break-word;">{{description}}</h4>
            </div>
        </div>

        <div class="row mb-3">

            <!-- Opening Stock  -->

            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Opening Stock</label>
                <h4>{{openingStock}}</h4>
            </div>

            <!-- As on Date
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <label class="fw-bolder form-label">As on Date</label>
                <h4>{{asOfDate}}</h4>
            </div> -->
        </div>

        <div class="row" *ngIf="imageSrcSeven !== null || fetchImages.length > 0">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <p class="d-md-inline-block fs--1 ml-5" style="font-weight:600;font-size:20px!important;">
                    View Service Image
                </p>
            </div>

        </div>

        <div class="row mt-2">
            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 flex d-flex flex-row" style="justify-content: center;" *ngIf="imageSrcSeven !== null">
                <div class="{{imageSrcSeven ? '':'dash'}} " style="width: 200px !important; height: 200px !important;">


                    <img *ngIf="imageSrcSeven" class="m-2" height="200" width="200"
                        [src]="imageSrcSeven ? imageSrcSeven : ''" (load)="handleCoverImageLoad()"
                        [class.loaded]="imageLoaded" class="text-center" />
                    <div class="input--file">

                        <div *ngIf="!imageSrcSeven" class="mt-1 pl-0">
                            cover photo
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 mb-4 flex d-flex flex-row" *ngFor="let fetch of fetchImages" style="justify-content: center;align-items: center;">
                <img [src]="fetch.productImage" class="preview" height="200" width="200px" />
            </div>
        </div>
    </form>



</div>
