import { HttpHeaders, HttpClient  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class RolesPermissionsService {
  private userAddedSource = new Subject<void>();
  userAdded$ = this.userAddedSource.asObservable();
  
  constructor(public httpClient: HttpService) { }

  notifyUserAdded() {
    this.userAddedSource.next();
  }

  getRoles(data: {}, userId:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getRole + userId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getAllPermissions(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getallPermission, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPermissions(data: {}, userId: string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getPermission + userId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }


  createCustomRoles(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.postCustomRoles, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  updateCustomRoles(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getRole + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getRolesPermissionById(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.editCustomRoles + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  createRoleUsers(data: {}): any {
    //let headers = new HttpHeaders().set('web','qa');
  
    return new Promise((resolve, reject) => {
     // this.httpClient.call(data, UrlConstants.addUserWithRole,{ requestOptions },'POST').subscribe(
      this.httpClient.call(data, UrlConstants.addUserWithRole,'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteUsers(data: {}): any {
    return new Promise((resolve, reject) => {
     this.httpClient.call(data, UrlConstants.userDelete,'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }


  UpdateUsers(data: {},id): any {
  
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.updateempUser+ id ,'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteRole(data: {},RoleNo): any {
  
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.deleterole+ RoleNo ,'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  


  deleteRoleById(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.deleteRole + id, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getUsers(data: {}, id,size,page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getUser + id + "?size="+ size + "&page=" + page, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getemployeeApproval(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getapprovals + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  approvalstatusById(data: {}, userId, expenseId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getapprovalsbyid + userId + "?expenseId=" + expenseId, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getUsersSearch(data: {}, id,search): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getUser + id + "?search="+search, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getExpenseApprovalsearch(data: {}, id,search): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getExpenseApproval + id + "?search="+search, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  postAssignRole(userId, data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.assignRole + userId, 'Post').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  deleteUserId(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.EmployeeDelete + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  
  getAllUsersRole(data: {}, empId:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getAllUsers + empId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getEmployeeById(data: {}, empId:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getEmployeeById + empId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  uploadImage(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.uploadImage ,'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getEmployeePincode(data: {}, pincode): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.addPincode + pincode, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  uploadEmployee(data:{},id){
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.uploadEmployee + "?userId=" + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteMultipleEmp(data:{},id){
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.deleteMultipleEmp + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }


}