<div class="modal-header pt-0">
    <h1 style="color: #EB8B3F; font-weight: bolder;margin-left: 5px;" class="modal-title mb-0">Update Product</h1>

    <div class="addProductButtons" *ngIf="!isArabic">
        <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel"
            (click)="cancel()">Cancel</button>
        <button type="submit" class="btn btn-save" (click)="updateGoods()"
            *appHasPermission="['delete', 'modify','create', 'read']; name 'products'">Save</button>
    </div>
</div>


<div class="modal-body">

    <form [formGroup]="ProductForm" *ngIf="!isArabic">
        <div class="row mb-3">
            <!-- Item Name -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Item Name<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="Item Name" formControlName="itemName"
                        [ngClass]="{'is-invalid': (f.itemName.invalid && f.itemName.touched) && submitted }"
                        autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.itemName.touched)">
                    <mat-error *ngIf="f.itemName.errors?.required">
                        Item Name is required!
                    </mat-error>
                </mat-error>
            </div>

            <!-- item Code -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Item Code<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="Item Code" formControlName="itemCode"
                        [ngClass]="{'is-invalid': (f.itemCode.invalid && f.itemCode.touched) && submitted }"
                        minlength="4" maxlength="8" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.itemCode.touched) && f.itemCode.errors?.pattern">
                    Item Code must be alphanumeric value, Special character not Allowed !
                </mat-error>
                <mat-error class="text-danger" *ngIf="(submitted || f.itemCode.touched) && f.itemCode.errors?.required">
                    Item Code is required!
                </mat-error>
                <mat-error *ngIf="f.itemCode.errors?.minlength">
                    Item Code must be at least 4 character.
                </mat-error>
                <mat-error *ngIf="f.itemCode.errors?.maxlength">
                    Item Code must be at least 8 characters.
                </mat-error>
            </div>

        </div>

        <div class="row mb-3">
            <!-- Generate Barcode -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Generate Barcode<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="Barcode" formControlName="generateBarcode"
                        [ngClass]="{
                        'is-invalid': (f.generateBarcode.invalid && f.generateBarcode.touched) && submitted }"
                        minlength="12" maxlength="12" (keypress)="numberOnly($event)" [readonly]="true" autocomplete="off">
                </mat-form-field>
                <!-- <a style="cursor: pointer;color:blue;margin-left: 5px;" (click)="autoGenerate()">Auto Generate</a> -->
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.generateBarcode.touched) && f.generateBarcode.errors?.pattern">
                    Barcode is required!
                </mat-error>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.generateBarcode.touched) && f.generateBarcode.errors?.required">
                    Barcode is required!
                </mat-error>
                <mat-error *ngIf="f.generateBarcode.errors?.maxlength">
                    Barcode must be 12 digit.
                </mat-error>
                <mat-error *ngIf="f.generateBarcode.errors?.minlength">
                    Barcode must be 12 digit.
                </mat-error>
            </div>

            <div style="margin-bottom: -13px; font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Brand</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl disableOptionCentering formControlName="brand"
                        placeholder="Brand">
                        <mat-option *ngFor="let item of ProductBrand" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row mb-3">
            <!-- Category -->
            <div style="margin-bottom: -13px; font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Category<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl required disableOptionCentering formControlName="category"
                        placeholder="Category" (selectionChange)="getCategoryType($event)">
                        <mat-option *ngFor="let item of productCategory"
                            [value]="item.id">{{item.categoryName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.category.touched)">
                    <mat-error *ngIf="f.category.errors?.required">
                        Category is required!
                    </mat-error>
                </mat-error>
            </div>
            <div style="margin-bottom: -13px; font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Sub Category</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl disableOptionCentering formControlName="subCategoryId"
                        placeholder="Sub Category">
                        <mat-option *ngFor="let item of activeSubCategoryList" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row mb-3">
            <div style="margin-bottom: -13px; font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Gender</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl disableOptionCentering formControlName="gender" placeholder="Gender">
                        <mat-option value="Boys"> Boys </mat-option>
                        <mat-option value="Girls"> Girls </mat-option>
                        <mat-option value="Man"> Man </mat-option>
                        <mat-option value="Women"> Women </mat-option>
                        <mat-option value="Unisex"> Unisex </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="margin-bottom: -13px; font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Section</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl matInput placeholder="Section" formControlName="section" autocomplete="off">
                </mat-form-field>
            </div>
        </div>

        <div class="row mb-3">
            <div style="margin-bottom: -13px; font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Color</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl matInput placeholder="Color" formControlName="color" autocomplete="off">
                </mat-form-field>
            </div>
            <div style="margin-bottom: -13px; font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Age Group</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl matInput placeholder="Age Group" formControlName="ageGroup"
                        autocomplete="off">
                </mat-form-field>
            </div>
        </div>

        <div class="row mb-3">
            <!-- HSN -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">HSN<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="HSN Code" formControlName="hsn"
                        [ngClass]="{'is-invalid': (f.hsn.invalid && f.hsn.touched) && submitted }" minlength="6"
                        maxlength="6" (keypress)="numberOnly($event)" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.hsn.touched) && f.hsn.errors?.pattern">
                    HSN is required!
                </mat-error>
                <mat-error class="text-danger" *ngIf="(submitted || f.hsn.touched) && f.hsn.errors?.required">
                    HSN is required!
                </mat-error>
                <mat-error *ngIf="f.hsn.errors?.maxlength">
                    HSN must be at least 6 digit.
                </mat-error>
                <mat-error *ngIf="f.hsn.errors?.minlength">
                    HSN must be at least 6 digit.
                </mat-error>
            </div>

            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Sale Price Inc. GST<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="Sale Price Inc. GST" formControlName="price"
                        [ngClass]="{'is-invalid': (f.price.invalid && f.price.touched) && submitted }"
                        (keypress)="numberOnly($event)" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.price.touched)">
                    <mat-error *ngIf="f.price.errors?.required">
                        Price is required!
                    </mat-error>
                </mat-error>
            </div>

        </div>

        <div class="row mb-2">
            <!-- User New Product -->
            <div style="margin-bottom: -13px; font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Product Type<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl required disableOptionCentering formControlName="used"
                        placeholder="Product Type">
                        <mat-option value=false>New</mat-option>
                        <mat-option value=true>Used</mat-option>
                    </mat-select>

                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.category.touched)">
                    <mat-error *ngIf="f.category.errors?.required">
                        Category is required!
                    </mat-error>
                </mat-error>

            </div>
            <!-- Item Description -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4">
                <label class="form-label">Item Description</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput rows="3" placeholder="Enter Item Description" formControlName="description"
                        autocomplete="off"></textarea>
                </mat-form-field>
                <!-- <mat-error class="text-danger" *ngIf="(submitted || f.description.touched)" autocomplete="off">
                    <mat-error *ngIf="f.description.errors?.required">
                        Item Description is required!
                    </mat-error>
                    [ngClass]="{'is-invalid': (f.description.invalid && f.description.touched) && submitted }"
                </mat-error> -->
            </div>

        </div>


        <div class="row mb-3">
            <!-- Additional Cess -->
            <div style=" margin-bottom: -13px; font-size: 12px"
                class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1 mt-4">
                <label class="form-label">Additional Cess</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl matInput placeholder="Additional Cess" formControlName="additionalCess"
                        (keypress)="numberOnly($event)" autocomplete="off">
                </mat-form-field>
                <!-- <mat-error class="text-danger" *ngIf="(submitted || f.additionalCess.touched)">
                                            <mat-error *ngIf="f.additionalCess.errors?.required">
                                                Additional Cess is required!
                                            </mat-error>
                                        </mat-error> -->
            </div>

            <!-- Gst Rate -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-4">
                <label class="form-label">GST Rate(%)<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl min="0" required matInput placeholder="GST Rate" type="number"
                        formControlName="gstRate" [ngClass]="{
                        'is-invalid': (f.gstRate.invalid && f.gstRate.touched) && submitted }"
                        (keypress)="numberDecimalOnly($event)" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.gstRate.touched)">
                    <mat-error *ngIf="f.gstRate.errors?.required">
                        Gst Rate is required!
                    </mat-error>
                </mat-error>


            </div>

        </div>

        <div class="row mb-3">

            <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Support Type</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select formControlName="supportType" matNativeControl required disableOptionCentering
                        placeholder="Select Support Type">
                        <mat-option value="GUARANTEE">Guarantee</mat-option>
                        <mat-option value="WARRANTY">Warranty</mat-option>
                        <mat-option value="NONE">None
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                *ngIf="this.ProductForm.get('supportType').value =='GUARANTEE' ||this.ProductForm.get('supportType').value =='WARRANTY'">
                <label class="form-label">Limit</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input formControlName="supportDuration" matNativeControl matInput
                    style="width: 50%!important;border-right: 1px solid gray;" placeholder="Duration" min="0"
                    type="number" autocomplete="off">
                </mat-form-field>
            </div>

        </div>






        <div class="row d-flex justify-content-start mb-3 mt-4" style="padding-left: 50px;">

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-check form-switch flex d-flex flex-row"
                style="justify-content: flex-start;padding-left: 0px;">
                <input class="form-check-input" type="checkbox" formControlName="secondaryUnitFlag"
                    id="flexSwitchCheckDefault" (click)="showSecondaryUnitField($event.target.checked)"
                    style="float: inherit;margin-right: 10px" />
                <label class="form-label" style="font-size: 14px;">Enable Secondary Unit</label>

            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-check form-switch flex d-flex flex-row"
                style="justify-content: flex-start;padding-left: 27px;">
                <input class="form-check-input mr-1 lowStckWarning" formControlName="lowStockWarning" type="checkbox"
                    id="flexSwitchCheckDefault" (click)="getEnableStockValue($event.target.checked)"
                    style="float: inherit;margin-right: 10px" />
                <label class="form-label" style="font-size: 14px;">Enable Low Stock Warning </label>
            </div>
        </div>

        <div class="row mb-3">
            <!-- Primary Unit -->
            <div style="  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Primary Unit<span class="error-asterisk">*</span></label>
                <mat-form-field style="margin-bottom: -13px; font-size: 12px" class="example-full-width"
                    appearance="outline">
                    <mat-select formControlName="unit" [(ngModel)]="unit" (selectionChange)="getUnit($event)"
                        [ngModelOptions]="{standalone: true}" [ngClass]="{
                        'is-invalid': (f.unit.invalid && f.unit.touched) && submitted }">
                        <mat-option *ngFor="let unit of Units" [value]="unit">
                            {{ unit}}
                        </mat-option>
                    </mat-select>

                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.unit.touched)">
                    <mat-error *ngIf="f.unit.errors?.required">
                        Unit is required!
                    </mat-error>
                </mat-error>
            </div>

            <!-- Secondary Unit -->
            <div *ngIf="isDisabled" style="  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Secondary Unit<span class="error-asterisk">*</span></label>
                <mat-form-field style=" font-size: 12px" class="example-full-width" appearance="outline">
                    <mat-select placeholder="Secondary Unit" formControlName="secondaryUnit" matNativeControl
                        disableOptionCentering (selectionChange)="getSecondaryUnit($event)" [ngClass]="{
                            'is-invalid': (f.secondaryUnit.invalid && f.secondaryUnit.touched) && submitted }">
                        <mat-option *ngFor="let secondaryunit of SecondaryUnit" [value]="secondaryunit">
                            {{ secondaryunit}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.secondaryUnit.touched)">
                    <mat-error *ngIf="f.secondaryUnit.errors?.required">
                        Secondary Unit is required!
                    </mat-error>
                </mat-error>
            </div>

            <!-- Low Stock Units -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1"
                *ngIf="this.isStockToggle">
                <label class="form-label">Low Stock Units<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl matInput placeholder="Low Stock Units" formControlName="lowStockUnit"
                        [ngClass]="{
                                                'is-invalid': (f.lowStockUnit.invalid && f.lowStockUnit.touched) && submitted }"
                        (keypress)="numberOnly($event)" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.lowStockUnit.touched)">
                    <mat-error *ngIf="f.lowStockUnit.errors?.required">
                        Low Stock Unit is required!
                    </mat-error>
                </mat-error>
            </div>


            <!-- Conversion Rate class="bg-light pt-3 pb-2 pl-2 " -->
            <div *ngIf="isDisabled" style=" margin-bottom: -13px; font-size: 12px"
                class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Conversion Rate<span class="error-asterisk">*</span></label>
                <!-- <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl matInput formControlName="conversionRate" (keypress)="numberOnly($event)"
                        autocomplete="off">
                    <span matPrefix style="font-size: 12px!important">1 {{primarySelectUnit}} =
                        &nbsp;</span>
                    <span matSuffix style="font-size: 12px !important">{{secondarySelectUnit}}</span>
                </mat-form-field> -->
                <div class="input-group mt-2" style="height: 38px !important;">
                    <span class="input-group-text ms-1"
                        style="font-size: 14px !important;padding-bottom: 3.75px !important;">1 {{primarySelectUnit}}
                        =</span>
                    <input class="form-control" min="0" formControlName="conversionRate" (keypress)="numberOnly($event)"
                        autocomplete="off" style="font-size: 14px !important;" />
                    <span class="input-group-text me-2"
                        style="font-size: 14px !important;padding-bottom: 3.75px !important;">{{secondarySelectUnit}}</span>
                </div>
            </div>

        </div>

        <div class="row mb-3">
            <!-- Opening Stock -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Opening Stock</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl type="number" matInput min="0" placeholder="Openeing Stock"
                        formControlName="openingStock"
                        [ngClass]="{'is-invalid': (f.openingStock.invalid && f.openingStock.touched) && submitted }"
                        autocomplete="off">
                </mat-form-field>
                <!-- <mat-error class="text-danger" *ngIf="(submitted || f.openingStock.touched)">
                                            <mat-error *ngIf="f.openingStock.errors?.required">
                                                Opening Stock is required!
                                            </mat-error>
                                        </mat-error> -->
            </div>

            <!-- As on Date -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">As on Date<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput required bsDatepicker formControlName="asOfDate"
                        style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                                            containerClass: 'theme-dark-blue',
                                            dateInputFormat: 'DD MMM YYYY',
                                            showWeekNumbers:false
                                        }" [ngClass]="{
                                            'is-invalid': (f.asOfDate.invalid && f.asOfDate.touched) && submitted }"
                        (click)="openDatepicker()">
                    <span>
                        <mat-icon class="iconcalender">date_range</mat-icon>
                    </span>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.asOfDate.touched)">
                    <mat-error *ngIf="f.asOfDate.errors?.required">
                        As Of Date is required!
                    </mat-error>
                </mat-error>
            </div>


        </div>

        <div class="row mb-3"
            *ngIf="this.catId == 2 || this.catId == 4 || this.catId == 8 || this.catId == 9 || this.catId == 10 || this.catId == 11 || this.catId == 14 || this.catId == 15 || this.catId == 18">
            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Expiry Date</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput ngModel bsDatepicker formControlName="expiry" [(ngModel)]="todaysDate2"
                        [minDate]="todaysDate2" style="text-transform:uppercase!important;" id="datePicker" bsDatepicker
                        [bsConfig]="{
                        containerClass: 'theme-dark-blue',
                        dateInputFormat: 'DD MMM YYYY',
                         showWeekNumbers:false
                        }" (click)="openDatepickerexpiry()">
                    <span>
                        <mat-icon class="iconcalender">date_range</mat-icon>
                    </span>
                </mat-form-field>
                <!-- <mat-error class="text-danger" *ngIf="(submitted || f.expiry.touched)">
                    <mat-error *ngIf="f.expiry.errors?.required">
                        Expiry Date is required!
                    </mat-error>
                    [ngClass]="{
                        'is-invalid': (f.expiry.invalid && f.expiry.touched) && submitted }"
                </mat-error> -->
            </div>
        </div>
        <div class="row mt-3" *ngIf="customFieldsList && customFieldsList.length > 0">
            <div class="col-sm-12 col-md-6 col-lg-12 col-xl-12">
                <p class="d-md-inline-block fs--1 ml-5"
                    style="font-weight:500;font-size:20px!important;margin-left: 5px;">
                    Custom Fields
                </p>
            </div>
        </div>
        <div class="row mt-3" *ngIf="customFieldsList && customFieldsList.length > 0">
            <ng-container *ngFor="let field of customFieldsList">
              <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">{{ field.fieldName }}</label>
                <mat-form-field appearance="outline" class="example-full-width">
                  <ng-container *ngIf="field.type === 'text'; else dropdownField">
                    <input matInput [formControlName]="field.fieldName" type="text" placeholder="{{ field.fieldName }}">
                  </ng-container>
                  <ng-template #dropdownField>
                    <mat-select [formGroup]="ProductForm.get(field.fieldName)" placeholder="{{ field.fieldName }}">
                        <mat-option *ngFor="let option of getDropdownOptions(field)" [value]="option.optionId" (click)="onOptionClick(field, option)">
                          {{ option.optionName }}
                        </mat-option>
                      </mat-select>
                  </ng-template>                  
                </mat-form-field>
              </div>
            </ng-container>
        </div>

        <div class="row mt-3">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="justify-content: start;">
                <p class="d-md-inline-block fs--1 ml-5"
                    style="font-weight:500;font-size:20px!important;margin-left: 5px;">
                    Update Products Image
                </p>
            </div>

        </div>

        <div class="row mt-3">

            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 flex d-flex flex-row mb-sm-1"
                style="justify-content: center;">

                <div *ngIf="imageSrcSeven" class="{{imageSrcSeven ? '':'dash'}} "
                    style="width: 200px !important; height: 200px !important;"
                    [ngStyle]="{'margin-top': imageSrcSeven ? '-20px' : '0px'}">
                    <button *ngIf="imageSrcSeven" class="delete-button-cover" type="button" (click)="cancelCoverImageCover()">
                        <i class="bi bi-x bi-sm" style="color:red"></i>
                    </button>

                    <img *ngIf="imageSrcSeven" class="m-2" height="200" width="200"
                        [src]="imageSrcSeven ? imageSrcSeven : ''" (load)="handleCoverImageLoad()"
                        [class.loaded]="imageLoaded" class="text-center" />
                </div>

                <label *ngIf="!imageSrcSeven" class="uploader2"
                    style="width: 200px !important;height:200px !important;">

                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        <i class="bi bi-plus-lg  fs-3" (click)="startUpload()"></i>

                        <span class="mt-2" style="font-size: 12px; font-weight:600;line-height: 21;">Add Product
                            Cover Image</span>
                        <span class="mt-2 text-muted" style="text-align: center; font-size: 12px;line-height: 21;">Drop
                            an Image or <span style="color: #1BB2F2;">Browsing images</span> it from your
                            computer</span>


                    </div>
                    <input name="Select File" type="file" id="uploadCoverPic" (change)="coverImgChange($event)" />

                </label>

            </div>

            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 flex d-flex flex-row" style="justify-content: center;">
                <label class="uploader2" style="width: 200px !important;height:200px !important;">

                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        <i class="bi bi-plus-lg  fs-3"></i>

                        <span class="mt-2" style="font-size: 12px; font-weight:600;line-height: 21;">Add Product
                            Image</span>
                        <span class="mt-2 text-muted" style="text-align: center; font-size: 12px;line-height: 21;">Drop
                            an Image or <span style="color: #1BB2F2;">Browsing images</span> it from your
                            computer</span>
                    </div>
                    <input type="file" name="file" accept="image/*" style="display:none;" [(ngModel)]="currentInput"
                        [ngModelOptions]="{ standalone: true }" multiple (change)="onFileSelected($event)" />

                </label>

            </div>

        </div>

        <div class="row mt-5">
            <!-- <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6"  *ngFor="let file of previews">
                {{file}}

            </div> -->
            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 mb-4 mt-2 flex d-flex flex-row"
                *ngFor="let fetch of fetchImages" style="justify-content: center;">
                <!-- <i class="bi bi-x-circle text-danger fs-1 fw-bold" (click)="cancelCoverImage(fetch.id)"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="delete image"></i> -->
                <button class="delete-button" type="button" (click)="cancelCoverImage(fetch.id)">
                    <i class="bi bi-x bi-sm" style="color:red"></i>
                </button>
                <img [src]="fetch.productImage" class="preview" height="200" width="200px" />
            </div>
            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 mb-4 mt-2 flex d-flex flex-row"
                *ngFor="let preview of previews;let i=index;" style="justify-content: center;">
                <!-- <i class="bi bi-x-circle text-danger fs-1 fw-bold" (click)="cancelmultiImage(i)"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="delete image"></i> -->
                <button class="delete-button" type="button" (click)="cancelmultiImage(i)">
                    <i class="bi bi-x bi-sm" style="color:red"></i>
                </button>
                <img [src]="preview" class="preview" height="200" width="200px" />
            </div>
        </div>

    </form>

    <form [formGroup]="ProductForm" *ngIf="isArabic">
        <div class="row mb-3">
            <!-- Item Name -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Item Name<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="Item Name" formControlName="itemName"
                        [ngClass]="{'is-invalid': (f.itemName.invalid && f.itemName.touched) && submitted }"
                        autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.itemName.touched)">
                    <mat-error *ngIf="f.itemName.errors?.required">
                        Item Name is required!
                    </mat-error>
                </mat-error>
            </div>

            <!-- item Code -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Item Code<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="Item Code" formControlName="itemCode"
                        [ngClass]="{'is-invalid': (f.itemCode.invalid && f.itemCode.touched) && submitted }"
                        minlength="4" maxlength="8" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.itemCode.touched) && f.itemCode.errors?.pattern">
                    Item Code must be alphanumeric value, Special character not Allowed !
                </mat-error>
                <mat-error class="text-danger" *ngIf="(submitted || f.itemCode.touched) && f.itemCode.errors?.required">
                    Item Code is required!
                </mat-error>
                <mat-error *ngIf="f.itemCode.errors?.minlength">
                    Item Code must be at least 4 character.
                </mat-error>
                <mat-error *ngIf="f.itemCode.errors?.maxlength">
                    Item Code must be at least 8 characters.
                </mat-error>
            </div>

        </div>

        <div class="row mb-3">
            <!-- Generate Barcode -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Barcode/SKU<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="Barcode/SKU"
                        formControlName="generateBarcode" [ngClass]="{
                        'is-invalid': (f.generateBarcode.invalid && f.generateBarcode.touched) && submitted }"
                        minlength="13" maxlength="13" (keypress)="numberOnly($event)" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.generateBarcode.touched) && f.generateBarcode.errors?.pattern">
                    Barcode/SKU is required!
                </mat-error>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.generateBarcode.touched) && f.generateBarcode.errors?.required">
                    Barcode/SKU is required!
                </mat-error>
                <mat-error *ngIf="f.generateBarcode.errors?.maxlength">
                    Barcode/SKU must be  13 digit.
                </mat-error>
                <mat-error *ngIf="f.generateBarcode.errors?.minlength">
                    Barcode/SKU must be  13 digit.
                </mat-error>
            </div>

            <!-- Category -->
            <div style="margin-bottom: -13px; font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Category<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl disableOptionCentering required formControlName="category"
                        placeholder="Category" (selectionChange)="getCategoryType($event)"
                        [ngClass]="{'is-invalid': (f.category.invalid && f.category.touched) && submitted }">
                        <mat-option *ngFor="let item of productCategory"
                            [value]="item.id">{{item.categoryName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.category.touched)">
                    <mat-error *ngIf="f.category.errors?.required">
                        Category is required!
                    </mat-error>
                </mat-error>
            </div>

        </div>

        <div class="row mb-3">


            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">{{ lang.SalePriceInc }}<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="{{ lang.SalePriceInc }}"
                        formControlName="price"
                        [ngClass]="{'is-invalid': (f.price.invalid && f.price.touched) && submitted }"
                        (keypress)="numberOnly($event)" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.price.touched)">
                    <mat-error *ngIf="f.price.errors?.required">
                        {{ lang.SalePriceInc }} is required!
                    </mat-error>
                </mat-error>
            </div>

            <!-- Gst Rate -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">{{lang.GSTRate}}<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl min="0" required matInput placeholder="{{lang.GSTRate}}" type="number"
                        formControlName="gstRate" [ngClass]="{
                        'is-invalid': (f.gstRate.invalid && f.gstRate.touched) && submitted }"
                        (keypress)="numberDecimalOnly($event)" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.gstRate.touched)">
                    <mat-error *ngIf="f.gstRate.errors?.required">
                        {{lang.GSTRate}} is required!
                    </mat-error>
                </mat-error>


            </div>


        </div>

        <div class="row mb-3">
            <!-- User New Product -->
            <div style="margin-bottom: -13px; font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Product Type<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl required disableOptionCentering formControlName="used"
                        placeholder="Product Type">
                        <mat-option value=false>New</mat-option>
                        <mat-option value=true>Used</mat-option>
                    </mat-select>

                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.category.touched)">
                    <mat-error *ngIf="f.category.errors?.required">
                        Category is required!
                    </mat-error>
                </mat-error>

            </div>
            <!-- Item Description -->
            <div style=" margin-bottom: -13px;margin-top: 10px; font-size: 12px"
                class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <label class="form-label">Item Description</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput rows="3" placeholder="Enter Item Description" formControlName="description"
                        autocomplete="off"></textarea>
                </mat-form-field>

            </div>

        </div>


        <div class="row mb-3">

            <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Support Type</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select formControlName="supportType" matNativeControl required disableOptionCentering
                        placeholder="Select Support Type">
                        <mat-option value="GUARANTEE">Guarantee</mat-option>
                        <mat-option value="WARRANTY">Warranty</mat-option>
                        <mat-option value="NONE">None
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                *ngIf="this.ProductForm.get('supportType').value =='GUARANTEE' ||this.ProductForm.get('supportType').value =='WARRANTY'">
                <label class="form-label">Duration</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input formControlName="supportDuration" matNativeControl matInput
                        style="width: 50%!important;border-right: 1px solid gray;" placeholder="Duration" min="0"
                        type="number" autocomplete="off">
                    <span class="m-2 p-0">

                        <mat-select (selectionChange)="selectWarrantyType($event)" matNativeControl
                            disableOptionCentering placeholder="Months" formControlName="months"
                            style="width:50%!important;float: right !important; position: absolute !important; margin-right :10px!important;">
                            <mat-option value="Days">Days</mat-option>
                            <mat-option value="Months">Months</mat-option>
                            <mat-option value="Year">Year
                            </mat-option>
                        </mat-select>
                    </span>
                </mat-form-field>
            </div>

        </div>






        <div class="row d-flex justify-content-start mb-3 mt-4" style="padding-left: 50px;">

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-check form-switch flex d-flex flex-row"
                style="justify-content: flex-start;padding-left: 0px;">
                <input class="form-check-input" type="checkbox" formControlName="secondaryUnitFlag"
                    id="flexSwitchCheckDefault" (click)="showSecondaryUnitField($event.target.checked)"
                    style="float: inherit;margin-right: 10px" />
                <label class="form-label" style="font-size: 14px;">Enable Secondary Unit</label>

            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-check form-switch flex d-flex flex-row"
                style="justify-content: flex-start;padding-left: 27px;">
                <input class="form-check-input mr-1 lowStckWarning" formControlName="lowStockWarning" type="checkbox"
                    id="flexSwitchCheckDefault" (click)="getEnableStockValue($event.target.checked)"
                    style="float: inherit;margin-right: 10px" />
                <label class="form-label" style="font-size: 14px;">Enable Low Stock Warning </label>
            </div>
        </div>

        <div class="row mb-3">
            <!-- Primary Unit -->
            <div style="  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Primary Unit<span class="error-asterisk">*</span></label>
                <mat-form-field style="margin-bottom: -13px; font-size: 12px" class="example-full-width"
                    appearance="outline">
                    <mat-select formControlName="unit" [(ngModel)]="unit" (selectionChange)="getUnit($event)"
                        [ngModelOptions]="{standalone: true}" [ngClass]="{
                        'is-invalid': (f.unit.invalid && f.unit.touched) && submitted }">
                        <mat-option *ngFor="let unit of Units" [value]="unit">
                            {{ unit}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.unit.touched)">
                    <mat-error *ngIf="f.unit.errors?.required">
                        Unit is required!
                    </mat-error>
                </mat-error>
            </div>

            <!-- Secondary Unit -->
            <div *ngIf="isDisabled" style="  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Secondary Unit<span class="error-asterisk">*</span></label>
                <mat-form-field style=" font-size: 12px" class="example-full-width" appearance="outline">
                    <mat-select placeholder="Secondary Unit" formControlName="secondaryUnit" matNativeControl
                        disableOptionCentering (selectionChange)="getSecondaryUnit($event)">
                        <mat-option *ngFor="let secondaryunit of SecondaryUnit" [value]="secondaryunit">
                            {{ secondaryunit}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="isDisabled && ProductForm.get('secondaryUnit').hasError('required')" class="text-danger">
                    Secondary Unit is required.
                </div>

            </div>

            <!-- Low Stock Units -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1"
                *ngIf="this.isStockToggle">
                <label class="form-label">Low Stock Units<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl matInput placeholder="Low Stock Units" formControlName="lowStockUnit"
                        (keypress)="numberOnly($event)" autocomplete="off">
                </mat-form-field>
                <div *ngIf="isStockToggle && ProductForm.get('lowStockUnit').hasError('required')" class="text-danger">
                    Low Stock Unit is required.
                </div>

            </div>


            <!-- Conversion Rate class="bg-light pt-3 pb-2 pl-2 " -->
            <div *ngIf="isDisabled" style=" margin-bottom: -13px; font-size: 12px"
                class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Conversion Rate<span class="error-asterisk">*</span></label>
                <!-- <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl matInput formControlName="conversionRate" (keypress)="numberOnly($event)"
                        autocomplete="off">
                    <span matPrefix style="font-size: 12px!important">1 {{primarySelectUnit}} =
                        &nbsp;</span>
                    <span matSuffix style="font-size: 12px !important">{{secondarySelectUnit}}</span>
                </mat-form-field> -->
                <div class="input-group mt-2" style="height: 38px !important;">
                    <span class="input-group-text ms-1"
                        style="font-size: 14px !important;padding-bottom: 3.75px !important;">1 {{primarySelectUnit}}
                        =</span>
                    <input class="form-control" min="0" formControlName="conversionRate" (keypress)="numberOnly($event)"
                        autocomplete="off" style="font-size: 14px !important;" />
                    <span class="input-group-text me-2"
                        style="font-size: 14px !important;padding-bottom: 3.75px !important;">{{secondarySelectUnit}}</span>
                </div>
            </div>

        </div>

        <div class="row mb-3">
            <!-- Opening Stock -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Opening Stock</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl type="number" matInput min="0" placeholder="Openeing Stock"
                        formControlName="openingStock"
                        [ngClass]="{'is-invalid': (f.openingStock.invalid && f.openingStock.touched) && submitted }"
                        autocomplete="off">
                </mat-form-field>

            </div>

            <!-- As on Date -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">As on Date<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput required bsDatepicker formControlName="asOfDate"
                        style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                                            containerClass: 'theme-dark-blue',
                                            dateInputFormat: 'DD MMM YYYY',
                                            showWeekNumbers:false
                                        }" [ngClass]="{
                                            'is-invalid': (f.asOfDate.invalid && f.asOfDate.touched) && submitted }"
                        (click)="openDatepicker()">
                    <span>
                        <mat-icon class="iconcalender">date_range</mat-icon>
                    </span>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.asOfDate.touched)">
                    <mat-error *ngIf="f.asOfDate.errors?.required">
                        As Of Date is required!
                    </mat-error>
                </mat-error>
            </div>

        </div>

        <div class="row mb-3"
            *ngIf="this.catId == 2 || this.catId == 4 || this.catId == 8 || this.catId == 9 || this.catId == 10 || this.catId == 11 || this.catId == 14 || this.catId == 15 || this.catId == 18">
            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Expiry Date</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput ngModel bsDatepicker formControlName="expiry" [(ngModel)]="todaysDate2"
                        [minDate]="todaysDate2" style="text-transform:uppercase!important;" id="datePicker" bsDatepicker
                        [bsConfig]="{
                        containerClass: 'theme-dark-blue',
                        dateInputFormat: 'DD MMM YYYY',
                         showWeekNumbers:false
                        }" (click)="openDatepickerexpiry()">
                    <span>
                        <mat-icon class="iconcalender">date_range</mat-icon>
                    </span>
                </mat-form-field>

            </div>
        </div>

        <div class="row mt-3">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="justify-content: start;">
                <p class="d-md-inline-block fs--1 ml-5"
                    style="font-weight:500;font-size:20px!important;margin-left: 5px;">
                    Update Products Image
                </p>
            </div>

        </div>

        <div class="row mt-3">

            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 flex d-flex flex-row mb-sm-1"
                style="justify-content: center;">

                <div *ngIf="imageSrcSeven" class="{{imageSrcSeven ? '':'dash'}} "
                    style="width: 200px !important; height: 200px !important;"
                    [ngStyle]="{'margin-top': imageSrcSeven ? '-20px' : '0px'}">
                    <button *ngIf="imageSrcSeven" class="delete-button-cover" type="button" (click)="cancelCoverImageCover()">
                        <i class="bi bi-x bi-sm" style="color:red"></i>
                    </button>

                    <img *ngIf="imageSrcSeven" class="m-2" height="200" width="200"
                        [src]="imageSrcSeven ? imageSrcSeven : ''" (load)="handleCoverImageLoad()"
                        [class.loaded]="imageLoaded" class="text-center" />
                </div>

                <label *ngIf="!imageSrcSeven" class="uploader2"
                    style="width: 200px !important;height:200px !important;">

                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        <i class="bi bi-plus-lg  fs-3" (click)="startUpload()"></i>

                        <span class="mt-2" style="font-size: 12px; font-weight:500;line-height: 21;">Add Product
                            Cover Image</span>
                        <span class="mt-2 text-muted" style="text-align: center; font-size: 12px;line-height: 21;">Drop
                            an Image or <span style="color: #1BB2F2;">Browsing images</span> it from your
                            computer</span>


                    </div>
                    <input name="Select File" type="file" id="uploadCoverPic" (change)="coverImgChange($event)" />

                </label>

            </div>

            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 flex d-flex flex-row" style="justify-content: center;">
                <label class="uploader2" style="width: 200px !important;height:200px !important;">

                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        <i class="bi bi-plus-lg  fs-3"></i>

                        <span class="mt-2" style="font-size: 12px; font-weight:500;line-height: 21;">Add Product
                            Image</span>
                        <span class="mt-2 text-muted" style="text-align: center; font-size: 12px;line-height: 21;">Drop
                            an Image or <span style="color: #1BB2F2;">Browsing images</span> it from your
                            computer</span>
                    </div>
                    <input type="file" name="file" accept="image/*" style="display:none;" [(ngModel)]="currentInput"
                        [ngModelOptions]="{ standalone: true }" multiple (change)="onFileSelected($event)" />

                </label>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 mb-4 mt-2 flex d-flex flex-row"
                *ngFor="let fetch of fetchImages" style="justify-content: center;">

                <button class="delete-button" type="button" (click)="cancelCoverImage(fetch.id)">
                    <i class="bi bi-x bi-sm" style="color:red"></i>
                </button>
                <img [src]="fetch.productImage" class="preview" height="200" width="200px" />
            </div>
            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 mb-4 mt-2 flex d-flex flex-row"
                *ngFor="let preview of previews;let i=index;" style="justify-content: center;">
                <button class="delete-button" type="button" (click)="cancelmultiImage(i)">
                    <i class="bi bi-x bi-sm" style="color:red"></i>
                </button>
                <img [src]="preview" class="preview" height="200" width="200px" />
            </div>
        </div>
    </form>
</div>