import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { AuthGuard } from "./core/guards/auth.guard";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { WidgetsLayoutHomeComponent } from "./widgets-layout-home/widgets-layout-home.component";
import { CustomerprivacypolicyComponent } from "./customerprivacypolicy/customerprivacypolicy.component";
import { DeleteUserComponent } from "./pages/delete-user/delete-user.component";

const appRoutes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "widegts-home",
    loadChildren: () => import("./widgets-layout-home/widgets-layout-home.module").then((m) => m.WidgetsLayoutHomeModule),
  },
  {
    path: "pages",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
    // canActivate: [AuthGuard]
   // canActivate: [AuthGuard]
  // canActivate: [AuthGuard]
  },
  {
    path:"privacyPolicy",
    component:CustomerprivacypolicyComponent

  },
  {
    path:"deleteUser",
    component:DeleteUserComponent

  },
  {
    path: "widegts-home",
    component:WidgetsLayoutHomeComponent
  },
  {
    path: "notfound",
    component: PageNotFoundComponent
  },
  { path: "", redirectTo: "auth", pathMatch: "full" },
  { path: "**", redirectTo: "notfound" },
];
const config: ExtraOptions = {
  useHash: true,
  relativeLinkResolution: 'legacy'
};
@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes),
    RouterModule.forRoot(appRoutes),
  //   RouterModule.forRoot(appRoutes, {
  //     enableTracing: true,
  //     scrollPositionRestoration: 'top'
  //  }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
