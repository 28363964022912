import { Component, OnInit, ViewChild } from '@angular/core';
import lang from "src/assets/langTranslation/language_translation"
import { FormArray, FormGroup } from '@angular/forms';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PartyService } from 'src/app/core/services/party.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { TitleCasePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SaleOrderService } from 'src/app/core/services/sale-order.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { BiddingService } from 'src/app/core/services/bidding.service';
import { WarehouseService } from 'src/app/core/services/warehouse.service';
import { ProductService } from 'src/app/core/services/product.service';



@Component({
  selector: 'app-sale-order',
  templateUrl: './sale-order.component.html',
  styleUrls: ['./sale-order.component.scss']
})
export class SaleOrderComponent implements OnInit {
  public gstRate = 0;
  @ViewChild('closebutton') closebutton;
  public lang = lang.UAE;
  public SaleOrderForm: FormGroup;

  public isShippingAddressinModal: any;
  public isShowGstFields: boolean = false;
  public gstDetails: any
  public isShown: boolean = false;
  public allPartyList: any;
  public filteredOptions: any;
  public myControl = new FormControl();
  public partyId: any;
  public partyData: any
  public unitValue: any;
  public index: any;
  public product: any;
  public amount = 0;
  public qty = 0;
  public unit: any;
  public wtax: any;
  public wotax: any;
  public ramount: any;
  public taxAmount = 0
  public fieldArray: Array<any> = [];
  public newAttribute: any = {};
  public today: Date;
  public maxDate: Date;
  public isDisabledGST: boolean = true;
  public submittedParty: boolean = false;
  public currentPageNo: number = 0;
  public partyPagesize: number = 10;
  public finalObject;
  public todaysDate: any;
  public todaysDate2: any;
  public sendArray: any = [];
  public totalPrice: any;
  public barcode: any;
  public refNo: any;
  public saleInvoiceNo;
  public SaleReturnNo;
  public subTotal = 0;
  public totalGST = 0;
  public total = 0;
  public additionalCess: number = 0;
  public productData: any = [];
  warehouseList: any=[];

  public gstType: any;
  public gstInRs: any
  public gstInPer: any;
  public compositeType: any;
  public lblColor: boolean = false;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public gstAmount = 0;
  public supplytype: any;
  public submitted: boolean = false;
  public SaleOrderGst: any;
  public gstStateMatch: boolean = true;
  public userProfileGSTNo: any;
  imageLoaded: boolean = false;
  public purchaseImage: any;
  public imageSrc: any = '';
  public imageSrc1: any = '';
  public paramID: any;
  public AddReceiptName: any;
  public isArabic: boolean;
  PartyForm: FormGroup;
  filteredOptions3: Observable<any>;
  searchedProduct: any;
  productOptions: Observable<any>;
  public myControl1 = new FormControl();
  productFilterOptions: Observable<any>;
  lastInvoiceNumber: any;
  public productPicUrl: string = '';

  // serialNumber: number = 1;


  constructor(private productService: ProductService,private biddingService: BiddingService,
    private saleorderservice: SaleOrderService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<SaleOrderComponent>,
    private partyService: PartyService,
    private toastService: ToastNotificationService,
    private saleInvoiceService: SaleInvoiceService,
    private titleCasePipe: TitleCasePipe,
    private profileService: ProfileService,
    private modal: NgbModal, private router: Router,private warehouseService: WarehouseService,) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
  }

  ngOnInit(): void {
    // Subscribe to the observable to receive updates
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });

    this.getAllParties();
    this.getProfileData();
    this.getWarehouseList();
    this.getSaleInvoiceNumber();
    this.todaysDate = new Date();
    this.todaysDate2 = new Date();
    this.newAttribute = { unit: "", ramount: "", wtax: "", wotax: "", index: "", product: "", gty: "", select: "" };
    this.fieldArray.push(this.newAttribute);

    if (this.isArabic) {
      this.SaleOrderForm = this.formBuilder.group({
        partyName: [""],
        orderNo: [""],
        MobileNo: [""],
        orderDate: ["", [Validators.required]],
        dueDate: ["", [Validators.required]],
        PurchaseType: [""],
        Description: [""],
        billingAddress: [""],
        warehouse: [null],
      });
    } else {
      this.SaleOrderForm = this.formBuilder.group({
        partyName: [""],
        orderNo: [""],
        MobileNo: ["",Validators.required],
        orderDate: ["", [Validators.required]],
        dueDate: ["", [Validators.required]],
        PurchaseType: [""],
        Description: [""],
        billingAddress: [""],
        warehouse: [null],
        subcheckbox: this.formBuilder.array([]),
        customerName: [null],

      });
    }

    if (this.isArabic) {
      this.PartyForm = this.formBuilder.group({
        gstType: [null],
        gstIn: [
          null,
          [
            Validators.minLength(15),
            Validators.maxLength(15),
          ],
        ],
        partyName: ["", [Validators.required]],
        mobile: [
          ,
          [
            Validators.minLength(9),
            Validators.maxLength(9),
            Validators.pattern("^[0-9]*$"),
            Validators.required,
          ],
        ],
        // email: [
        //   null,
        //   [
        //     Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"),
        //     Validators.email,
        //     Validators.required,
        //   ],
        // ],
        email: ["", [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")]],

        billingAddress: [null, [Validators.required]],
        shippingAddress: [],
        balanceStatus: ["1"],
        isCustomer: [false],
      });

    } else {
      this.PartyForm = this.formBuilder.group({
        gstType: [null],
        gstIn: [
          null,
          [
            Validators.pattern(
              "^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$"
            ),
            Validators.minLength(15),
            Validators.maxLength(15),
          ],
        ],
        partyName: ["", [Validators.required]],
        mobile: [
          ,
          [
            Validators.minLength(10),
            Validators.maxLength(10),
            Validators.pattern("^[0-9]*$"),
            Validators.required,
          ],
        ],
        email: [
          null,
          [
            Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"),
            Validators.email,
            Validators.required,
          ],
        ],
        billingAddress: [null, [Validators.required]],
        shippingAddress: [],
        balanceStatus: ["1"],
        isCustomer: [false],
      });
    }
  }
  getProfileData() {
    this.profileService.getUserProfile({}, this.currentUser.id).then((res => {
      this.userProfileGSTNo = res.data.additional.gstinNo ? res.data.additional.gstinNo.slice(0, 2) : "";
    }))

  }
  get formArr() {
    return this.SaleOrderForm.get("subcheckbox") as FormArray;
  }
  
  getSaleInvoiceNumber() {
    this.saleInvoiceService
      .getSaleInvoiceNumber({}, this.currentUser.id)
      .then((res) => {
        this.SaleOrderForm.patchValue({
          orderNo: String(res.data.prefix).concat(String(res.data.suffix)),
        });
        this.lastInvoiceNumber = res.data.suffix;
      });
  }

  get f() {
    return this.SaleOrderForm.controls;
  }
  handleInputChange(e) {
    this.purchaseImage = e.target.files[0];
    this.AddReceiptName = e.target.files[0].name;
    const file: File = e.target.files[0];
    const fileType: string = file.type;
    let formdata = new FormData();


    if (fileType === 'application/pdf' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.imageSrc1 = false;

    } else {
      this.imageSrc1 = true;

    }
    let reader = new FileReader();
    reader.readAsDataURL(this.purchaseImage);
    reader.onload = () => {
      this.imageSrc = reader.result;
    }
    
    formdata.set("upload", file)
    e.srcElement.value = "";
    this.productService.postCoverImage1(formdata).then((res) => {
      this.productPicUrl = res.data.locations[0];
      console.log("this.productPicUrl", this.productPicUrl);
      this.toastService.toastMsg({
        title: "Success",
        content: 'Receipt Added Successfully!!!',
      });
    })
  }
  startUpload() {
    document.getElementById('uploadFile').click();
  }
  handleImageLoad() {
    this.imageLoaded = true;
  }
  cancelImage() {
    this.imageSrc = null;
    this.imageLoaded = false;
    this.toastService.toastMsg({
      title: "Success",
      content: 'Receipt Deleted Successfully!!!',
    });
  }


  private filterNames(name: string): string[] {
    return this.allPartyList.filter(partyList =>
      partyList.partyName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
  getAllParties() {
    this.partyService
      .getAllPartiesDetails({},this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, this.partyPagesize, (this.currentPageNo + 1))
      .then((res) => {
        this.allPartyList = res.data.pageData;
        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filterNames(name) : this.allPartyList.slice())
          );
      });
  }
  get fp() {
    return this.PartyForm.controls;
  }


  validateTRN(TRN) {
    if (TRN.length === 15) {
      if (this.isArabic) {
        this.isDisabledGST = false;
        return true
      } else {
        this.isDisabledGST = true;
        this.PartyForm.controls.gstIn.setErrors({ incorrect: true });
        return
      }
    } else {
      this.isDisabledGST = true;
      this.PartyForm.controls.gstIn.setErrors({ incorrect: true });
      // this.setGSTINError = true;
    }
  }
  async calculationTotal() {
    this.barcode = "";
    let price = 0;
    let additionalCess = 0;
    let gstInPer = 0;
    this.totalPrice = 0;
    this.subTotal = 0;
    this.gstRate = 0;
    this.productData.forEach((element) => {
      let gstInPer = element.gstRate;
      price = price + (element.salePrice * element.quantity);
      this.gstRate = ((element.salePrice - (element.salePrice / (1 + gstInPer / 100))) * element.quantity) + this.gstRate
      this.gstAmount = (element.salePrice - (element.salePrice / (1 + gstInPer / 100))) * element.quantity
      additionalCess = element.salePrice * element.additionalCess / 100;
      this.subTotal = (element.basePrice * element.quantity) + this.subTotal;
      element.subTotal = element.basePrice * element.quantity;
      element.gstAmount = (element.salePrice - (element.salePrice / (1 + gstInPer / 100))) * element.quantity
      element.total = element.salePrice * element.quantity;
      gstInPer = element.gstRate;
      if (this.isShown) {
        this.totalPrice = price;
      } else {
        this.totalPrice = price;
      }
    });
    if (this.gstType === 'Exempted') {
      this.totalGST = 0;
      this.gstInRs = 0;
      this.gstInPer = 0
    } else if (this.gstType === 'Composite') {
      if (this.compositeType == 'Manufacturer' || this.compositeType == 'Trader') {
        this.totalGST = this.subTotal * 1 / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      } else if (this.compositeType == 'Restaurant') {
        this.totalGST = this.subTotal * 5 / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      } else {
        this.totalGST = this.subTotal * 6 / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      }
    } else {
      this.totalGST = this.gstRate;
      this.gstInRs = this.gstRate;
      this.gstInPer = gstInPer;
    }

    this.additionalCess = additionalCess
    if (this.isShown) {
      this.total = price;
    } else {
      this.total = price;
    }
  }
  setSelectedPartyDeatails(item) {
    this.partyId = item.id;
    if (this.partyData) {
      this.partyData.forEach((ele) => {
        this.SaleOrderGst = ele.gstIn
        this.SaleOrderForm.patchValue({
          'billingAddress': ele.billingAddress,
          'customerName': ele.partyName,
        })
        if (this.SaleOrderGst.slice(0, 2) == this.userProfileGSTNo) {
          this.gstStateMatch = true
          this.supplytype = "INTRASTATE"
        }
        else {
          this.gstStateMatch = false;
          this.supplytype = "INTERSTATE"
        }
      })

    }
    else {
      this.saleInvoiceService.getPartyByName({}, this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, item.partyName)
        .then((response: any) => {
          this.partyData = response.data.rows;
          this.allPartyList = response.data.rows;
          this.allPartyList.forEach((ele) => {
            this.SaleOrderGst = ele.gstIn
            this.SaleOrderForm.patchValue({
              'billingAddress': ele.billingAddress,
              'customerName': ele.partyName,
            })
            if (this.SaleOrderGst.slice(0, 2) == this.userProfileGSTNo) {
              this.gstStateMatch = true
              this.supplytype = "INTRASTATE"
            }
            else {
              this.gstStateMatch = false;
              this.supplytype = "INTERSTATE"
            }
          });
        })
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(name => name ? this.filterNames(name) : this.allPartyList.slice())
      );

    }


  }

  changeQtyCalculation(i) {
    this.productData[i].quantity
    // this.totalGST = (this.productData[i].salePrice * this.productData[i].quantity) * (this.productData[i].gstRate / 100);
    // this.subTotal = (this.productData[i].salePrice * this.productData[i].quantity);
    // this.total = this.totalGST + this.subTotal;
    this.calculationTotal();
  }

  changeQuantity(sign, i, quantity = 0) {
    switch (sign) {
      case "-":
        if (this.productData[i].quantity > 1) {
          this.productData[i].quantity = this.productData[i].quantity - 1;
        }
        // this.changeQtyCalculation(i);
        this.calculationTotal();
        break;
      case "+":
        this.productData[i].quantity = this.productData[i].quantity + 1;
        // this.changeQtyCalculation(i);
        this.calculationTotal();
        break;
      case "rm":
        this.productData.splice(i, 1);
        if (this.productData.length <= 0) {
          this.totalGST = 0;
          this.subTotal = 0;
          this.total = 0;
        }
        this.calculationTotal();
        break;
      case "qty":
        this.productData[i].quantity = quantity;
        this.calculationTotal();
        break;
      default:
        this.productData[i];
        break;
    }
  }
  getGoodsByBarcode() {
    this.saleInvoiceService.getGoodsByBarcode({}, this.barcode,this.SaleOrderForm.value.warehouse ? this.SaleOrderForm.value.warehouse : this.currentUser.id)
      .then((response) => {
        response.data.quantity = 1;
        this.productData.push(response.data);
        this.calculationTotal();
      });
  }
  getCredit(e) {
    if (e.value == "true") {
      e.value = true;
    } else {
      e.value = false;
    }
    this.SaleOrderForm.controls.PurchaseType = e.value;
  }
  SaveSaleOrder() {

    this.submitted = true;
    if (this.SaleOrderForm.invalid ) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
      return false;
    } else {
      if (this.productData.length < 1) {
        // this.toastService.openErrorSnackBar(
        //   "Please Add At Least One Product To Proceed!!!"
        // );
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Add At Least One Product To Proceed!!!",
        });
        return false;
      }


      this.productData.forEach(e => {
        let data = {
          quantity: e.quantity,
          barcode: e.barcode,
        }
        this.sendArray.push(data);
      })
      
      const userType = this.currentUser.userType.toLowerCase();
      const warehouseIdToSend =
        userType === 'warehouse' ? this.currentUser.id :
        userType === 'shop' ? (this.SaleOrderForm.value.warehouse || this.currentUser.id) :
        userType === 'client' && this.SaleOrderForm.value.warehouse !== null ? this.SaleOrderForm.value.warehouse : this.currentUser.id;

        let data = {
          invoiceDetails: {
            invoiceDate: this.SaleOrderForm.controls.orderDate.value,
            invoiceNo: this.SaleOrderForm.controls.orderNo.value,
            shippedFrom: "",
            ewayBillNo: "2123123",
            billingAddress: this.SaleOrderForm.controls.billingAddress.value,
            shippingAddress: this.SaleOrderForm.controls.billingAddress.value ? this.SaleOrderForm.controls.billingAddress.value : "",
            credit: this.SaleOrderForm.controls.PurchaseType,
            userId: this.SaleOrderForm.value.warehouse ? this.SaleOrderForm.value.warehouse : this.currentUser.id,
            partyId: this.partyId,
            rcm: false,
            shipmentCharges: this.SaleOrderForm.value.shipmentCharges ? parseFloat(this.SaleOrderForm.value.shipmentCharges) : 0,
            supplyType: null,
            dueDate: this.SaleOrderForm.controls.dueDate.value,
            warehouseId: `${warehouseIdToSend}`,
            cashSale: false,
            mobile: this.SaleOrderForm.value?.MobileNo ? this.SaleOrderForm.value?.MobileNo : null,
            customerName: this.SaleOrderForm.value?.customerName ? this.SaleOrderForm.value?.customerName : null,
            roundOff: false,
            coverImage: null,
            lastInvoiceNumber: `${this.lastInvoiceNumber}`,
            placeOfSupply: null,
          },
          products: this.sendArray
        }
  
      this.saleorderservice.saleOderForWarehouse(data).then((res) => {
        if (res) {
          this.toastService.toastMsg({
            title: "Success",
            content: "Sale Order Added Successfully!!!",
          });
          // this.toastService.openSnackBar("Sale Order Added Successfully");
          this.saleInvoiceService.notifySaleInvoiceAdded();
          this.dialogRef.close();

        }
      }, (err) => {
        this.sendArray = [];
        if (err.error.expose) {
          let errorMessage = err.error.error_message;
          if (errorMessage.includes('invoiceDetails.partyId')) {
              errorMessage = "Vendor Name  is required. Please select a valid Vendor Name option.";
          } else {
              errorMessage = this.titleCasePipe.transform(errorMessage);
          }
          this.toastService.toastMsg({
              title: "Error",
              content: errorMessage,
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      })
    }
  }


  /**
  * Post party details api call*/

  addNewParty() {
    this.submittedParty = true;
    if (this.isShowGstFields) {
      if ((this.PartyForm.controls.billingAddress.value == "" || this.PartyForm.controls.partyName.value == ""
        || this.PartyForm.controls.mobile.value == "" || this.PartyForm.controls.email.value == "")) {
        // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
        return false;
      } else if ((this.PartyForm.controls.billingAddress.value == "" && this.PartyForm.controls.partyName.value == ""
        && this.PartyForm.controls.mobile.value == "" && this.PartyForm.controls.email.value == "")) {
        // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
      }
      else {
        this.addPartyObject();
      }
    }
    else {
      if ((this.PartyForm.controls.billingAddress.value == "" || this.PartyForm.controls.partyName.value == ""
        || this.PartyForm.controls.mobile.value == "" || this.PartyForm.controls.email.value == ""
        || this.PartyForm.controls.gstType.value == "" || this.PartyForm.controls.gstIn.value == "")) {
        // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
        return false;
      }

      else {
        this.addPartyObject();
      }
    }
  }
  addPartyObject() {

    this.finalObject = {
      "partyDetails": {
        "isCustomer": this.isShowGstFields,
        "gstType": this.PartyForm.controls.gstType.value ? this.PartyForm.controls.gstType.value : null,
        "gstIn": this.PartyForm.controls.gstIn.value ? this.PartyForm.controls.gstIn.value : null,
        "partyName": this.PartyForm.controls.partyName.value,
        "mobile": this.PartyForm.controls.mobile.value ? (this.PartyForm.controls.mobile.value).toString() : null,
        "email": this.PartyForm.controls.email.value.toLowerCase(),
        "billingAddress": this.PartyForm.controls.billingAddress.value,
        "shippingAddress": this.PartyForm.controls.shippingAddress.value,
        "openingBalance": null,
        "asOfDate": null,
        "balanceStatus": this.PartyForm.controls.balanceStatus.value,
        "payment": null,
        "creditPeriodDay": null,
        "creditLimit": null,
        "noOfCredits": null,
        "relationSince": null,
        "userId":this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
        "distance": null,
        "transporation": null,
      },
      "groupAdd": null
    }

    this.partyService.postParty(this.finalObject).then((res) => {
      this.paramID = res.data.id
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Vendor Added Successfully",
        })
        // this.toastService.openSnackBar("Vendor Added Successfully")
        this.PartyForm.reset();
        this.modal.dismissAll();
        this.getAllParties();
        this.submittedParty = false;
      }
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })
  }

  Units = [
    "BAGS(BAG)",
    "BALE(BAL)",
    "BUNDLES(BDL)",
  ]
  getUnit(value) {
    this.unitValue = "1 " + value;
  }
  showShippingAddressinModal(checked: boolean) {
    this.isShippingAddressinModal = checked;
  }
  customertoggleShow(value) {
    this.isShowGstFields = value;
  }
  getGstNumber(gstin) {
    this.partyService.getGstNumber({}, gstin).then((res) => {
      this.gstDetails = res;
      if (res) {
        this.PartyForm.patchValue({
          gstType: res.data.taxPayerType,
          gstNo: res.data.gstIn
        })
      }
      this.isDisabledGST = false;
    }, (err) => {
      this.isDisabledGST = true;
    })
  }
  searchPartyByName(item) {
    this.partyId = item.id;
    this.saleInvoiceService.getPartyByName({},this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, item.partyName)
      .then((response) => {
        this.partyData = response.data.rows;
        this.partyData.forEach((ele) => {
        })
      });
  }
  addFieldValue() {
    this.fieldArray.push(this.newAttribute)
    this.newAttribute = {};
  }

  deleteFieldValue(index) {
    this.fieldArray.splice(index, 1);
  }
  onFileSelect(fileselect) {

  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  get pf() {
    return this.PartyForm.controls;
  }
  openAddPartyModal(name) {
    this.modal.open(name, {
      centered: true,
      backdrop: "static",
      size: "lg"
    });
  }
  modalDismiss() {
    this.PartyForm.reset();
    this.modal.dismissAll();
  }

  onCancelModal() {
    this.dialogRef.close();

  }

  openDatepickerOrderDate() {
    // Check if the current value of invoiceDate is an "Invalid Date"
    const orderDate = this.SaleOrderForm.get('orderDate').value;
    if (isNaN(orderDate.getTime())) {
      // Set a default date or any valid date here
      this.SaleOrderForm.get('orderDate').setValue(new Date());
    }
  }
  openDatepickerDueDate() {
    // Check if the current value of invoiceDate is an "Invalid Date"
    const dueDate = this.SaleOrderForm.get('dueDate').value;
    if (isNaN(dueDate.getTime())) {
      // Set a default date or any valid date here
      this.SaleOrderForm.get('dueDate').setValue(new Date());
    }
  }
  
  getGoodsByBarcodeSelected(barcode, event) {
    if (event.isUserInput) {
      this.saleInvoiceService
        .getGoodsByBarcode({}, barcode, this.SaleOrderForm.value.warehouse ? this.SaleOrderForm.value.warehouse : this.currentUser.id)
        .then(async (response) => {
          response.data.quantity = 1;
          // Check if the barcode already exists in productData
          const existingProductIndex = this.productData.findIndex(
            (product) => product.barcode === response.data.barcode
            );

          if (existingProductIndex !== -1) {
            // Barcode exists, update quantity
            this.productData[existingProductIndex].quantity += 1;
            // await this.checkFreeProducts(this.productData)
            // await this.checkOffer(this.productData[existingProductIndex].quantity, this.productData[existingProductIndex].id)
            // this.removeCoupon()
          } else {
            // Barcode doesn't exist, add new product
            response.data.quantity = 1;
            this.productData.push(response.data);
            this.addDetailsButtonClick(this.productData.length - 1);
            // await this.checkFreeProducts(this.productData)
            // await this.checkOffer(response.data.quantity, response.data.id)
            // this.removeCoupon()
          }

          this.calculationTotal();
          this.myControl1.patchValue('')
          document.getElementById('inp12').nodeValue = ''
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        });
    }
  }


  getGoodsList(value) {
    if (value && (String(value).trim() !== '' || value !== null)) {
      this.biddingService.getAllProductsByUserSearch({}, this.SaleOrderForm.value.warehouse ? this.SaleOrderForm.value.warehouse : this.currentUser.id, value, 1000, 1,this.SaleOrderForm.value.warehouse ? this.SaleOrderForm.value.warehouse : this.currentUser.id)
        .then((res) => {
          if(res.success) {
            this.searchedProduct = res.data.pageData;
            this.productOptions = this.myControl1.valueChanges
            .pipe(
              startWith(''),
              map(name => name ? this.filterNames(name) : this.searchedProduct.slice())
              );
            }
        },
          (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
            }

          })
    } else {}
  }

  onSearchInputChange(searchKey: string) {
    if (searchKey.length >= 3) {
      this.getGoodsList(searchKey);
    }
  }
  
  getWarehouseList() {
    this.warehouseService.getAllWarehouse({
      data:{},
      userId: this.currentUser.userType !== 'CLIENT' ? this.currentUser.parentId : this.currentUser.id,
      size: 1000,
      page: 1,
      search:'',
    }).then((res: any) => {
      this.warehouseList = res.data.pageData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      };
    })
  }
   // Function to handle selection change in warehouse selection dropdown
  onWarehouseSelectionChange(event: any) {
    if (event.value === 'clear') {
      this.SaleOrderForm.get('warehouse').setValue(null); // Clear the selection
    }
  }
  addDetailsButtonClick(i): void {
    const content = this.SaleOrderForm.get("subcheckbox") as FormArray;
    content.push(this.addRolesPermission(i));
  }
  addRolesPermission(id): FormGroup {
    return new FormGroup({
      read: new FormControl(false),
    });
  }


}
