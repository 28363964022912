import { TitleCasePipe } from '@angular/common';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CashInHandServiceService } from 'src/app/core/services/cash-in-hand-service.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-cash-in-hand',
  templateUrl: './cash-in-hand.component.html',
  styleUrls: ['./cash-in-hand.component.scss']
})
export class CashInHandComponent implements OnInit {
  public formControl = new FormControl(new Date());
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public ngModelDate = new Date();
  public today: Date;
  public maxDate: Date;
  public todaysDate;
  public paymentType: boolean = false;
  public CashInHandForm: FormGroup;
  invoiceData: any;
  partyId: any
  saleInvoiceId: any;
  public partyData: any;
  public submitted: boolean;
  public newAmount:string='';

  constructor(
    private formBuilder: FormBuilder,
    private toastService: ToastNotificationService,
    private cashInHandService: CashInHandServiceService,
    private saleInvoiceService: SaleInvoiceService,
    private titleCasePipe: TitleCasePipe
  ) { }
  ngOnInit(): void {
    this.CashInHandForm = this.formBuilder.group({
      currentBal: [],
      date: [],
      paymentType: ["", [Validators.required]],
      saleInvoiceNo: [],
      enterAmount: [ ,[Validators.required]],
      NewAmount: [ ,[Validators.required]],
      NewBalance: [ ,[Validators.required]],
      denomination: [ ,[Validators.required]],
      noOfPieces: [ ,[Validators.required]],
      total: [,[Validators.required]],
      coin: [],
      grandTotal: [ ,[Validators.required]],
      fivehundred: [],
      nofivehundred: [],
      totalfivehundred: [],
      hundred: [],
      nohundred: [],
      totalhundred: [],
      fifty: [],
      nofifty: [],
      totalfifty: [],
      twenty: [],
      notwenty: [],
      totaltwenty: [],
      ten: [],
      noten: [],
      totalten: [],
      five: [],
      nofive: [],
      totalfive: []
    })
    // this.CashInHandForm.get('date').patchValue(new Date());
    this.CashInHandForm.patchValue({

      'denomination': 2000,
      'fivehundred': 500,
      'hundred': 100,
      'fifty': 50,
      'twenty': 20,
      'ten': 10,
      'five': 5,

    })
    this.updateCount();
    this.  getCurrentBal()

  }
  OnChange(value) {
    if (value == "Receipt") {
      this.paymentType = true;
    } else {
      this.paymentType = false
    }
  }
  get f() {
    return this.CashInHandForm.controls;
  }
  updateCount(){
    this.CashInHandForm.get('noOfPieces').valueChanges.subscribe((val)=>{
     this.CashInHandForm.get('total').setValue(val * this.CashInHandForm.get('denomination').value )
    })
    this.CashInHandForm.get('nofivehundred').valueChanges.subscribe((val)=>{
      this.CashInHandForm.get('totalfivehundred').setValue(val * this.CashInHandForm.get('fivehundred').value )
     })
     this.CashInHandForm.get('nohundred').valueChanges.subscribe((val)=>{
      this.CashInHandForm.get('totalhundred').setValue(val * this.CashInHandForm.get('hundred').value )
     })
     this.CashInHandForm.get('nofifty').valueChanges.subscribe((val)=>{
      this.CashInHandForm.get('totalfifty').setValue(val * this.CashInHandForm.get('fifty').value )
     })
     this.CashInHandForm.get('notwenty').valueChanges.subscribe((val)=>{
      this.CashInHandForm.get('totaltwenty').setValue(val * this.CashInHandForm.get('twenty').value )
     })
     this.CashInHandForm.get('noten').valueChanges.subscribe((val)=>{
      this.CashInHandForm.get('totalten').setValue(val * this.CashInHandForm.get('ten').value )
     })
     this.CashInHandForm.get('nofive').valueChanges.subscribe((val)=>{
      this.CashInHandForm.get('totalfive').setValue(val * this.CashInHandForm.get('five').value );
      this.CashInHandForm.get('grandTotal').setValue(this.CashInHandForm.get('total').value + this.CashInHandForm.get('totalfivehundred').value + this.CashInHandForm.get('totalhundred').value +
      this.CashInHandForm.get('totalfifty').value+ this.CashInHandForm.get('totaltwenty').value + this.CashInHandForm.get('totalten').value+this.CashInHandForm.get('totalfive').value )
     })
  }
  save() {
    this.submitted = true;
    if (this.CashInHandForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
      return false;
    }


    let body = {
      "cashadjustments": {
        "paymentType": this.CashInHandForm.controls.paymentType.value,
        "saleInvoiceId":this.saleInvoiceId ? this.saleInvoiceId : null,
        "enterAmount": this.CashInHandForm.controls.enterAmount.value,
        "newAmount": this.CashInHandForm.controls.NewAmount.value,
        "newBalance": this.CashInHandForm.controls.NewBalance.value,
        "grandTotal":  this.CashInHandForm.controls.grandTotal.value,
        "coins":0,
        "remark": "PAID",
        "userId":this.currentUser.id
        
    },
      // "cashadjustments": {
      //   "paymentType": this.CashInHandForm.controls.paymentType.value,
      //   "saleInvoiceId": this.saleInvoiceId,
      //   "enterAmount": this.CashInHandForm.controls.enterAmount.value,
      //   "newAmount": this.CashInHandForm.controls.NewAmount.value,
      //   "newBalance": this.CashInHandForm.controls.NewBalance.value,
      //   "grandTotal": this.CashInHandForm.controls.grandTotal.value,
      //   //"date": this.CashInHandForm.controls.date.value,
      //   //"saleInvoiceNo": this.CashInHandForm.controls.saleInvoiceNo.value,
      //  // "partyId": this.partyId,
      //   "remark": "PAID",
      //  "coins": 0,
      // },
        "denominations":[
          {
            "denomination":this.CashInHandForm.controls.denomination.value,
            "noOfPieces": this.CashInHandForm.controls.noOfPieces.value,
            "total": this.CashInHandForm.controls.total.value,
          },
          {
            "denomination":this.CashInHandForm.controls.fivehundred.value,
            "noOfPieces": this.CashInHandForm.controls.nofivehundred.value,
            "total": this.CashInHandForm.controls.totalfivehundred.value,
          },
         
          {
            "denomination":this.CashInHandForm.controls.hundred.value,
            "noOfPieces": this.CashInHandForm.controls.nohundred.value,
            "total": this.CashInHandForm.controls.totalhundred.value,
          },
          {
            "denomination":this.CashInHandForm.controls.fifty.value,
            "noOfPieces": this.CashInHandForm.controls.nofifty.value,
            "total": this.CashInHandForm.controls.totalfifty.value,
          },
          {
            "denomination":this.CashInHandForm.controls.twenty.value,
            "noOfPieces": this.CashInHandForm.controls.notwenty.value,
            "total": this.CashInHandForm.controls.totaltwenty.value,
          },
          {
            "denomination":this.CashInHandForm.controls.ten.value,
            "noOfPieces": this.CashInHandForm.controls.noten.value,
            "total": this.CashInHandForm.controls.totalten.value,
          },
          {
            "denomination":this.CashInHandForm.controls.five.value,
            "noOfPieces": this.CashInHandForm.controls.nofive.value,
            "total": this.CashInHandForm.controls.totalfive.value,
          },
        ]  
    }
    this.cashInHandService.cashInHandpost(body).then((res) => {
      // this.toastService.openSnackBar("Cash In Hand Updated");
      this.toastService.toastMsg({
        title: "Success",
        content: "Cash In Hand Updated!!!"
      });
      this.CashInHandForm.reset();
      this.submitted=false;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    })   
  }
  getCurrentBal(){
    this.cashInHandService.getCurrentBalance({}, this.currentUser.id).then((res) => {
      // this.CashInHandForm = res.data[0].currentBalance;

      this.CashInHandForm.patchValue({
        currentBal: res.data[0].currentBalance,
      });

    },
    (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }

    })
}
  searchPartyByName(item) {
    this.partyId = item.id;
    this.cashInHandService.getPartyByName({}, this.currentUser.id, item.partyName)
      .then((response) => {
        this.partyData = response.data.rows;
      });
  }  
  cancel() {
    document.getElementById("gray-btn").style.background = "#748194";
    document.getElementById("gray-btn").style.color = "black";
  }
  GetpartyId(e) {
    if (e) {
      this.saleInvoiceService.getsaleInvoiceByIncoice({}, this.currentUser.id, e).then((res) => {
        this.partyId = res.data.pageData[0].partyId;
        this.saleInvoiceId=res.data.pageData[0].id;
      });
    }
  }

  clearInput() {
    this.CashInHandForm.reset();
     this.submitted=false;
    this.CashInHandForm.patchValue({

      'denomination': 2000,
      'fivehundred': 500,
      'hundred': 100,
      'fifty': 50,
      'twenty': 20,
      'ten': 10,
      'five': 5,

    })
  }

  tramsperValue(){
    //console.log(myLocalRef);
    

    this.CashInHandForm.patchValue({

      'enterAmount': this.CashInHandForm.controls.grandTotal.value,

    })
    
  }
  openDatepicker() {
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.CashInHandForm.get('expenseDate').value;
    if (isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.CashInHandForm.get('expenseDate').setValue(new Date());
    }
  }
}
