import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BiddingService } from 'src/app/core/services/bidding.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ProductService } from 'src/app/core/services/product.service';
import { RemoteShopService } from 'src/app/core/services/remote-shop.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';

@Component({
  selector: 'app-seller-bid',
  templateUrl: './seller-bid.component.html',
  styleUrls: ['./seller-bid.component.scss']
})
export class SellerBidComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  public bidData:any = [];
  public todaysDate;
  public wishlistId: number;
  public goodsData = [];
  public searchedProduct = [];
  public prodName: string;
  public prodId: string;
  public isHome:any;
  public myControl = new FormControl();
  public filteredOptions: Observable<string[]>
  public p=1;
  public currentpage:any;
  public totalrow:any;
  public toatlPages:any;
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  public pagesize: number = 1;
  public count: number = 0;
  public showTimeInterval: any;
  public interval: any;
  public difference: any;
  public submitted:boolean = false

  public quotePriceForm = this.formBuilder.group({
    description: ["",[Validators.required]],
    deliveryDate: ["",[Validators.required]],
    sellerRange: ["",[Validators.required]],
    prodName: ["",[Validators.required]],
    homeDelivery: [false,[Validators.required]],
    deleveryCharge:[0,[Validators.required]]
  })

  constructor(private biddingService: BiddingService, 
    private commonService: CommonService,
    private formBuilder: FormBuilder, 
    private productService: ProductService,
    private toastService : ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
    private modal: NgbModal) { }

  ngOnInit(): void {
    this.getAllBiddings();
    this.getGoodsbyUser();
    this.todaysDate = new Date();
    this.interval = setInterval(() => {
      this.getExpiryTime();
    }, 1000)
  }

  get f() {
    return this.quotePriceForm.controls;
  }

  getAllBiddings() {
    this.biddingService.getAllBiddings({}, this.currentUser.id).then((res) => {
      // this.bidData = res.data.pageData;
      this.totalrow=res.data.totalrows;
      let bid = res.data.pageData
      bid.forEach(element => {
        let createDate = new Date(element.createdAt).getTime();
        let lastDate = new Date(moment(createDate).add(7, 'days').format("YYYY-MM-DD HH:mm:ss")).getTime();
        let todaysDate = new Date().getTime();
        this.difference = lastDate - todaysDate;
        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(this.difference / (1000 * 60 * 60 * 24));
        var hours = Math.floor((this.difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((this.difference % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((this.difference % (1000 * 60)) / 1000);
        this.showTimeInterval = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
        if(element.product){
          let data = {
            itemName: element.product ? element.product.itemName : "-",
            id:element.product ? element.product.id : "-",
            publish: element.publish ? element.publish : "-",
            description: element.product ? element.product.description : "-",
            budgetMin: element.budgetMin ? element.budgetMin :"-",
            budgetMax: element.budgetMax ? element.budgetMax : "-",
            pincode: element.pincode ? element.pincode :"-",
            location: element.location ? element.location : "-",
            approxCompletedDate: element.approxCompletedDate ? element.approxCompletedDate : "-",
            price: element.product ? element.product.salePrice : "-",
            showTimeInterval: this.showTimeInterval ? this.showTimeInterval : "",
            biddings: element.biddings ? element.biddings : "-",
            createdAt: element.createdAt ? element.createdAt : "",
            wishlistId: element.id,
           coverPic:element.product ? element.product.coverImage : "",
          }
          this.bidData.push(data);
        }
        // If the count down is over, write some text 
        if (this.difference < 0) {
          clearInterval(this.interval);
          this.showTimeInterval = "EXPIRED";
        }
      })
    },(err)=>{
      if(err.error.expose){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message)
          });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else{
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong."
          });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    }
    )
  }

  getExpiryTime() {
    this.bidData.forEach((element) => {
      let createDate = new Date(element.createdAt).getTime();
      let lastDate = new Date(moment(createDate).add(7, 'days').format("YYYY-MM-DD HH:mm:ss")).getTime();
      let todaysDate = new Date().getTime();
      this.difference = lastDate - todaysDate;
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(this.difference / (1000 * 60 * 60 * 24));
      var hours = Math.floor((this.difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((this.difference % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((this.difference % (1000 * 60)) / 1000);
      this.showTimeInterval = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
      element.showTimeInterval = this.showTimeInterval;
    });
    if (this.difference < 0) {
      clearInterval(this.interval);
      this.showTimeInterval = "EXPIRED";
    }
  }

  searchProduct() {
    this.biddingService.getAllProductsByUserSearch({}, this.currentUser.id, this.myControl.value,this.partyPageSize,(this.currentPageNo + 1))
    .then((res) => {
      this.toatlPages = res.data.totalPages;
      this.totalrow = res.data.totalRows;
      this.p = res.data.currentPage;
      this.searchedProduct =  res.data.pageData;
      console.log("searchedProduct",this.searchProduct)
      this.prodName = this.searchedProduct[0].itemName;
      this.prodId = this.searchedProduct[0].id,
      this.quotePriceForm.patchValue({
        prodName:this.prodName
      })
    })
  }

  selectedProduct(itemName){
    this.biddingService.getAllProductsByUserSearch({}, this.currentUser.id, itemName,this.partyPageSize,(this.currentPageNo + 1))
    .then((res) => {
      this.toatlPages = res.data.totalPages;
      this.totalrow = res.data.totalRows;
      this.p = res.data.currentPage;
      this.searchedProduct =  res.data.pageData;
       this.prodName = res.data.pageData[0].itemName;
      this.prodId = res.data.pageData[0].id
      this.quotePriceForm.patchValue({
        prodName:this.prodName
      })  
    })
  }

  postClientQuotePrice() {
    this.submitted = true;
    if(this.quotePriceForm.invalid){
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields."
        });
      // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
      return false;
    }
    else{
      let data = {
        "wishlistId": this.wishlistId,
        "userId": this.currentUser.id,
        "price": this.quotePriceForm.controls.sellerRange.value,
        "description": this.quotePriceForm.controls.description.value,
        "productId": this.prodId,
        "deliveryDate": this.todaysDate,
        "homeDelivery": this.quotePriceForm.controls.homeDelivery.value,
        "deliveryCharge":this.quotePriceForm.controls.deleveryCharge.value,
      }
      this.biddingService.postClientQuotePrice(data).then((res) => {
        this.toastService.toastMsg({
          title: "Success",
          content: "Price Quoted Successfully!!!",
        })
        // this.toastService.openSnackBar("Price Quoted Successfully!!!");
        this.quotePriceForm.reset();
        this.searchedProduct = [];
        this.modal.dismissAll();
      }, (err)=>{
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields."
          });
        // this.toastService.openErrorSnackBar("Please Try Again Later!!!");
      })
    }
  }

  /**
   * Filter customer name search
   * @param name 
   * @returns 
   */
   private filterNames(name: string): string[] {
    return this.goodsData.filter(goods =>
      goods.itemName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  getGoodsbyUser(){
    this.productService.getGoodsProductByUser_Type({},this.currentUser.id,"goods","DESC",this.partyPageSize,(this.currentPageNo + 1)).then((res)=>{
      this.toatlPages = res.data.totalPages;
      this.totalrow = res.data.totalRows;
      this.p = res.data.currentPage;
      this.goodsData = res.data.pageData;
      this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filterNames(name) : this.goodsData.slice())
          );
    },(err)=>{
      if(err.error.status == 404){
      }
    })
  }

  openAcceptBidModal(wishId,name){
    this.wishlistId = wishId;
    this.modal.open(name, {
      centered: true,
      backdrop: "static",
      size: "md"
    });
  }
  showHomeDelevery(checked){
    this.isHome = checked;
  }

  public handlePage(e: any) {
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.bidData.length > 0) {
      this.partyPageSize = e.pageSize;
      this.getAllBiddings();
    }
  }

  modalDismiss(){
    this.modal.dismissAll();
  }

  openDatepicker() {
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.quotePriceForm.get('deliveryDate').value;
    if (isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.quotePriceForm.get('deliveryDate').setValue(new Date());
    }
  }
}
