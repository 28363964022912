import { Component, OnInit, ViewChild } from '@angular/core';
import lang from "src/assets/langTranslation/language_translation"
import { FormGroup, NgForm } from '@angular/forms';
import { FormArray, FormBuilder, FormControl, Validators } from "@angular/forms";
import { PartyService } from 'src/app/core/services/party.service';
import { PaymentInService } from 'src/app/core/services/payment-in.service';
import { map, startWith } from 'rxjs/operators';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { CommonService } from 'src/app/core/services/common.service';
import { TitleCasePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/core/services/product.service';
@Component({
  selector: 'app-payment-in',
  templateUrl: './payment-in.component.html',
  styleUrls: ['./payment-in.component.scss']
})
export class PaymentInComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  @ViewChild('closebutton') closebutton;
  public lang = lang.UAE;
  public PaymentInForm: FormGroup;
  public gstDetails: any
  public dateRange: FormGroup;
  public updated: boolean = false;
  public submitted: boolean = false;
  public date = new Date;
  public allPartyList: any;
  public filteredOptions: any;
  public myControl = new FormControl();
  public isShown: boolean = false;
  public isShowGstFields: boolean = false;
  public isShippingAddressinModal: any;
  public partyId: any;
  public todaysDate;
  public today: Date;
  public maxDate: Date;
  public partyData: any
  public submittedParty: boolean = false;
  public isDisabledGST: boolean = true;
  public currentPageNo: number = 0;
  public partyPagesize: number = 10;
  public finalObject;
  public paymentId: any;
  public paymentImage: any;
  public imageSrc: any = '';
  imageLoaded: boolean = false;
  public paramID: any;
  public productPicUrl: string = '';
  public isArabic: boolean;
  PartyForm: FormGroup;


  @ViewChild('d') myNgForm: any;
  constructor(private productService: ProductService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PaymentInComponent>,
    private payemntInService: PaymentInService,
    private partyService: PartyService,
    private toastService: ToastNotificationService,
    private saleInvoiceService: SaleInvoiceService,
    private commonService: CommonService,
    private titleCasePipe: TitleCasePipe,
    private router: Router,
    private modal: NgbModal,) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
    this.todaysDate = new Date();
    this.getAllParties();

    if (this.isArabic) {
      this.PaymentInForm = this.formBuilder.group({
        party: [""],
        ReceiptNo: ["", [Validators.required]],
        paymentType: ["", [Validators.required]],
        date: ["", new Date()],
        Description: ["", [Validators.required]],
        // Files: [""],
        recieved: [""],
        total: [""]
      })
    } else {
      this.PaymentInForm = this.formBuilder.group({
        party: [""],
        ReceiptNo: ["", [Validators.required]],
        paymentType: ["", [Validators.required]],
        date: ["", new Date()],
        Description: ["", [Validators.required]],
        // Files: [""],
        recieved: [""],
        total: [""]
      })
    }
  }


  ngOnInit(): void {

    this.todaysDate = new Date();

    // Subscribe to the observable to receive updates
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });



    if(this.isArabic){
      this.PartyForm = this.formBuilder.group({
        gstType: [null],
        gstIn: [
          null,
          [
            Validators.minLength(15),
            Validators.maxLength(15),
          ],
        ],
        partyName: ["", [Validators.required]],
        mobile: [
          ,
          [
            Validators.minLength(9),
            Validators.maxLength(9),
            Validators.pattern("^[0-9]*$"),
            Validators.required,
          ],
        ],
        // email: [
        //   null,
        //   [
        //     Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"),
        //     Validators.email,
        //     Validators.required,
        //   ],
        // ],
        email: ["", [Validators.required, Validators.email,Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")]],

        billingAddress: [null, [Validators.required]],
        shippingAddress: [],
        balanceStatus: ["1"],
        isCustomer: [false],
      });

    }else{
      this.PartyForm = this.formBuilder.group({
        gstType: [null],
        gstIn: [
          null,
          [
            Validators.pattern(
              "^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$"
            ),
            Validators.minLength(15),
            Validators.maxLength(15),
          ],
        ],
        partyName: ["", [Validators.required]],
        mobile: [
          ,
          [
            Validators.minLength(10),
            Validators.maxLength(10),
            Validators.pattern("^[0-9]*$"),
            Validators.required,
          ],
        ],
        // email: [
        //   null,
        //   [
        //     Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"),
        //     Validators.email,
        //     Validators.required,
        //   ],
        // ],
        email: ["", [Validators.required, Validators.email,Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")]],

        billingAddress: [null, [Validators.required]],
        shippingAddress: [],
        balanceStatus: ["1"],
        isCustomer: [false],
      });
    }
  }
  private filterNames(name: string): string[] {
    return this.allPartyList.filter(partyList =>
      partyList.partyName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
  getAllParties() {
    this.partyService
      .getAllPartiesDetails({},this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, this.partyPagesize, (this.currentPageNo + 1))
      .then((res) => {
        this.allPartyList = res.data.pageData;
        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filterNames(name) : this.allPartyList.slice())
          );
      });
  }
  /* 1 */
  onlyNumber(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


  validateTRN(TRN) {
    if (TRN.length === 15) {
      if (this.isArabic) {
        this.isDisabledGST = false;
        return true
      } else {
        this.isDisabledGST = true;
        this.PartyForm.controls.gstIn.setErrors({ incorrect: true });
        return
      }
    } else {
      this.isDisabledGST = true;
      this.PartyForm.controls.gstIn.setErrors({ incorrect: true });
      // this.setGSTINError = true;
    }
  }

  postPaymentInDetails() {
    this.submitted = true;
    if (this.PaymentInForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
      return false;
    } else {
      let body = {
        "payments": {
          "userId": this.currentUser.id,
          "partyId": this.partyId,
          "receiptNumber": this.PaymentInForm.controls.ReceiptNo.value,
          "paymentType": this.PaymentInForm.controls.paymentType.value,
          "date": this.todaysDate,
          "description": this.PaymentInForm.controls.Description.value,
          "image":  this.productPicUrl,
          "invoiceType": "RECEIVED",
          "total": this.PaymentInForm.controls.total.value,
          "warehouseId": this.currentUser.id
        }
      }

      this.payemntInService.postpaymentInParty(body).then((res) => {
        if (res.success === true ) {
          this.paymentId = res.data.id;
          this.toastService.toastMsg({
            title: "Success",
            content: "Payment In Added Successfully!!!",
            });
          // this.toastService.openSnackBar("Payment In Added Successfully");
          this.PaymentInForm.reset();
          this.partyId = "";
          this.todaysDate = "";
          this.submitted = false;
          this.saleInvoiceService.notifySaleInvoiceAdded();
          this.dialogRef.close();
        }
      },
        (err) => {

          if (err.error.expose) {
            let errorMessage = err.error.error_message;
            if (errorMessage.includes('Party credit is 0 ')) {
                errorMessage = "Vendor credit is 0 ";
            } else {
                errorMessage = this.titleCasePipe.transform(errorMessage);
            }
            this.toastService.toastMsg({
                title: "Error",
                content: errorMessage,
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }

        })
    }

  }

  get fp() {
    return this.PartyForm.controls;
  }

  customertoggleShow(value) {
    this.isShowGstFields = value;
  }
  showShippingAddressinModal(checked: boolean) {
    this.isShippingAddressinModal = checked;
  }
  getGstNumber(gstin) {
    this.partyService.getGstNumber({}, gstin).then((res) => {
      this.gstDetails = res;
      if (res) {
        this.PartyForm.patchValue({
          gstType: res.data.taxPayerType,
          gstNo: res.data.gstIn
        })
      }
      this.isDisabledGST = false;
    }, (err) => {
      this.isDisabledGST = true;
    })
  }
  searchPartyByName(item) {
    this.saleInvoiceService.getPartyByName({},this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, item)
      .then((response) => {
        this.partyData = response.data.rows;
        this.allPartyList = response.data.rows;
        if (this.allPartyList) {
          this.filteredOptions = this.myControl.valueChanges
            .pipe(
              startWith(''),
              map(name => name ? this.filterNames(name) : this.allPartyList.slice())
            );
        }
      });
  }
  setSelectedPartyDeatails(item) {
    this.partyId = item.id;
  }

  get f() {
    return this.PaymentInForm.controls;
  }
  startUpload() {
    document.getElementById('uploadFile').click();
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  get pf() {
    return this.PartyForm.controls;
  }

  /**
  * Post party details api call
  */
  addNewParty() {
    this.submittedParty = true;
    if (this.isShowGstFields) {
      if ((this.PartyForm.controls.billingAddress.value == "" || this.PartyForm.controls.partyName.value == ""
        || this.PartyForm.controls.mobile.value == "" || this.PartyForm.controls.email.value == "")) {
        // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
        return false;
      } else if ((this.PartyForm.controls.billingAddress.value == "" && this.PartyForm.controls.partyName.value == ""
        && this.PartyForm.controls.mobile.value == "" && this.PartyForm.controls.email.value == "")) {
        // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
      }
      else {
        this.addPartyObject();
      }
    }
    else {
      if ((this.PartyForm.controls.billingAddress.value == "" || this.PartyForm.controls.partyName.value == ""
        || this.PartyForm.controls.mobile.value == "" || this.PartyForm.controls.email.value == ""
        || this.PartyForm.controls.gstType.value == "" || this.PartyForm.controls.gstIn.value == "")) {
        // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
        return false;
      }

      else {
        this.addPartyObject();
      }
    }
  }
  addPartyObject() {

    this.finalObject = {
      "partyDetails": {
        "isCustomer": this.isShowGstFields,
        "gstType": this.PartyForm.controls.gstType.value ? this.PartyForm.controls.gstType.value : null,
        "gstIn": this.PartyForm.controls.gstIn.value ? this.PartyForm.controls.gstIn.value : null,
        "partyName": this.PartyForm.controls.partyName.value,
        "mobile": this.PartyForm.controls.mobile.value ? (this.PartyForm.controls.mobile.value).toString() : null,
        "email": this.PartyForm.controls.email.value.toLowerCase(),
        "billingAddress": this.PartyForm.controls.billingAddress.value,
        "shippingAddress": this.PartyForm.controls.shippingAddress.value,
        "openingBalance": null,
        "asOfDate": null,
        "balanceStatus": this.PartyForm.controls.balanceStatus.value,
        "payment": null,
        "creditPeriodDay": null,
        "creditLimit": null,
        "noOfCredits": null,
        "relationSince": null,
        "userId": this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
        "distance": null,
        "transporation": null,
      },
      "groupAdd": null
    }

    this.partyService.postParty(this.finalObject).then((res) => {
      this.paramID = res.data.id
      if (res) {
         this.toastService.toastMsg({
            title: "Success",
            content: "Vendor Added Successfully!!!",
            });
        // this.toastService.openSnackBar("Vendor Added Successfully")
        this.PartyForm.reset();
        this.modal.dismissAll();
        this.getAllParties();
        this.submittedParty = false;
      }
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })
  }

  openAddPartyModal(name) {
    this.modal.open(name, {
      centered: true,
      backdrop: "static",
      size: "lg"
    });
  }
  modalDismiss() {
    this.PartyForm.reset();
    this.modal.dismissAll();
  }
  handleImageLoad() {
    this.imageLoaded = true;
  }
  // handleInputChange(e) {
  //   this.paymentImage = e.target.files[0];
  //   let reader = new FileReader();
  //   reader.readAsDataURL(this.paymentImage);
  //   reader.onload = () => {
  //     this.imageSrc = reader.result;
  //   }

  //   if (this.paymentImage) {
  //     let formdata = new FormData();
  //     formdata.set("upload", this.paymentImage)
  //     this.payemntInService.PaymentImagepost(formdata, this.paymentId).then((res) => {
  //       this.toastService.openSnackBar("Payment Image Uploaded Successfully")
  //       // this.getAllParties();
  //       // window.location.reload()
  //     }, (err) => {
  //       if (err.error.expose) {
  //         this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
  //       }
  //       else {
  //         this.toastService.openErrorSnackBar("Something Went Wrong.");
  //       }
  //     });
  //   }
  // }

  cancelImage() {
    this.imageSrc = '';
    this.productPicUrl = '';
    this.imageLoaded = false;
    this.toastService.toastMsg({
      title: "Success",
      content: 'Receipt Deleted Successfully!!!',
    });
  }

  onCancelModal(){
    this.PaymentInForm.reset();
    this.dialogRef.close();

  }

  // openDatepicker(){
  //   // Check if the current value of invoiceDate is an "Invalid Date"
  //   const selectedDate = this.PaymentInForm.get('date').value;
  //   if (isNaN(selectedDate.getTime())) {
  //     // Set a default date or any valid date here
  //     this.PaymentInForm.get('date').setValue(new Date());
  //   }
  // }

  openDatepicker(){
    const selectedDate = this.todaysDate;
    if (isNaN(selectedDate.getTime())) {
      this.todaysDate = new Date();
    }
  }

  handleInputChange(e, imgNumber?: any) {
    //this.imgNumber = imgNumber;
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];


    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    //this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.PaymentInForm.patchValue({
          file: reader.result,
        });

        if (file == null) {
          this.toastService.toastMsg({
            title: "Error",
            content: "Please Select Image To Upload.",
          });
        } else {
          this.imageSrc = reader.result;
          formdata.set("upload", file)
          e.srcElement.value = "";
          this.productService.postCoverImage1(formdata).then((res) => {
            this.productPicUrl = res.data.locations[0];
            this.toastService.toastMsg({
              title: "Success",
              content: 'Receipt Added Successfully!!!',
            });
          });
        }
      }
    }
  }
}
