import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EventListener } from 'ngx-bootstrap/utils/facade/browser';
import { CommonService } from 'src/app/core/services/common.service';
import { ProductService } from 'src/app/core/services/product.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';
import { TitleCasePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import lang from "src/assets/langTranslation/language_translation"

@Component({
  selector: 'app-serviceadd',
  templateUrl: './serviceadd.component.html',
  styleUrls: ['./serviceadd.component.scss']
})
export class ServiceaddComponent implements OnInit {
  public lang = lang.UAE;
  public isArabic: boolean;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public unitValue: any;
  public unitSecondaryValue: any;
  public isDisabled: boolean = false;
  public showProductType: any;
  public serviceToggle: boolean;
  public isStockToggle: boolean = false;
  public ProductForm: any;
  public todaysDate;
  public today: Date;
  public unit: any;
  public primaryUnitShort: any;
  public secondaryUnitShort: any;
  public secondaryunit: any;
  public maxDate: Date;
  // Units = Constants.Units
  public Units = Constants.UnitFS
  public SecondaryUnit = Constants.Units
  public activeColor: string = 'green';
  public baseColor: string = '#ccc';
  public overlayColor: string = 'rgba(255,255,255,0.5)';
  public dragging: boolean = false;
  public loaded: boolean = false;
  public imageLoaded: boolean = false;
  public imageSrc: any = '';
  public imageSrcOne: any = '';
  public imageSrcTwo: any = '';
  public imageSrcThree: any = '';
  public imageSrcFour: any = '';
  public imageSrcFive: any = '';
  public imageSrcSix: any = '';
  public imageSrcSeven: any = '';
  public imgNumber: any;
  public selectedFiles: any;
  public selectedFileName: any;
  public submitted: boolean = false;
  public productCategory: any;

  public productPicUrl: string = '';
  public multipleImage = [];
  public multipleImage1 = [];
  public currentInput:any;
  previews: string[] = [];
  public maxFiles: number = 0;
  public Filesmax: number = 5;

  constructor(private productService: ProductService,
    private formBuilder: FormBuilder,
    private titleCasePipe: TitleCasePipe,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ServiceaddComponent>,
    private toastService: ToastNotificationService,
    private commonService: CommonService) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
  }

  ngOnInit(): void {
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });
    this.todaysDate = new Date();
    this.getProductCategory();
    if(this.isArabic){
      this.ProductForm = this.formBuilder.group({
        itemName: ["",],
        category: [],
        itemCode: [""],
        generateBarcode: ["",[Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern("^[0-9]*$")]],
        description: ["",],
        //hsn: ["", [Validators.pattern("^[0-9]*$")]],
        hsn:[null],
        unit: [""],
        //price: ["", [Validators.pattern("^[0-9]*$")]],
        price: ["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
        //gstRate: ["", [Validators.pattern("^[0-9]*$")]],
        // gstRate: ["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
        gstRate: ["5",],
        secondaryUnitFlag: [false],
        secondaryUnit: [""],
        conversionRate: [null],
        openingStock: [],
        asOfDate: [""],
        lowStockWarning: [false],
        lowStockUnit: [null],
        //additionalCess: ["", [Validators.pattern("^[0-9]*$")]],
        additionalCess: ["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
        serviceCode: ["",[Validators.required, Validators.minLength(4), Validators.maxLength(8),Validators.pattern('^[a-zA-Z0-9]*$')]],
        serviceName: [""],
        supportType: ["GUARANTEE"],
        supportDuration: []

      });
    }
    else{
      this.ProductForm = this.formBuilder.group({
        itemName: ["",],
        category: [],
        itemCode: [""],
        generateBarcode: ["",[Validators.required, Validators.minLength(12), Validators.maxLength(12), Validators.pattern("^[0-9]*$")]],
        description: ["",],
        //hsn: ["", [Validators.pattern("^[0-9]*$")]],
        hsn:["", [Validators.required, Validators.minLength(6), Validators.maxLength(6),Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
        unit: [""],
        //price: ["", [Validators.pattern("^[0-9]*$")]],
        price: ["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
        //gstRate: ["", [Validators.pattern("^[0-9]*$")]],
        gstRate: ["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
        secondaryUnitFlag: [false],
        secondaryUnit: [""],
        conversionRate: [null],
        openingStock: [],
        asOfDate: [""],
        lowStockWarning: [false],
        lowStockUnit: [null],
        //additionalCess: ["", [Validators.pattern("^[0-9]*$")]],
        additionalCess: ["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
        serviceCode: ["",[Validators.required, Validators.minLength(4), Validators.maxLength(8),Validators.pattern('^[a-zA-Z0-9]*$')]],
        serviceName: [""],
        supportType: ["GUARANTEE"],
        supportDuration: []

      });
    }

    this.ProductForm.patchValue({
      additionalCess: 0,
      openingStock: 0
    })

  }
  getProductCategory() {
    this.productService.getproductCategory({}).then((res: any) => {
      this.productCategory = res.data;
    })
  }
  get f() {
    return this.ProductForm.controls;
  }

  getPrimaryUnit(event) {
    this.primaryUnitShort = event.source.value.shortName;
    this.unit = event.source.value.fullName;
    this.unitValue = "1 " + event.source.value.fullName;
    this.filterSecondary();
  }

  private filterSecondary() {
    const selectedPrimary = this.SecondaryUnit.filter(us => this.unit === us);
    const index = this.SecondaryUnit.indexOf(this.unit);
    if (index > -1) {
      this.SecondaryUnit.splice(index, 1);
    }
    //this.unitSecondaryValue = selectedPrimary;
  }
  getEnableStockValue(event) {
    this.isStockToggle = event;
  }

  showSecondaryUnitField(value) {
    this.isDisabled = value;
    // if(this.isDisabled == false){
    //   this.unitSecondaryValue = null;
    // }
  }

  getSecondaryUnit(event) {
    this.secondaryUnitShort = event.source.value.shortName;
    this.unitSecondaryValue = event.source.value;

  }

  show(id) {
    if (id === 5) {
      this.showProductType = Constants.USER.GOODS;
      this.serviceToggle = false;
      //this.disabled = false;
    }
    else if (id === 6) {
      this.showProductType = Constants.USER.SERVICE;
      this.serviceToggle = true;
    }
  }

  addService() {
    this.submitted = true;
    if(this.ProductForm.invalid){
      // this.toastService.openErrorSnackBar("Fill all required fields.")
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      console.log("this.ProductForm",this.ProductForm)
      return false;
    }
    else{
      let data = {
        "addService": {
          "userId": this.currentUser.id,
          "itemName": this.ProductForm.controls.serviceName.value,
          "barcode": this.ProductForm.controls.generateBarcode.value,
          "categoryId":this.ProductForm.controls.category.value,
          "itemCode": this.ProductForm.controls.serviceCode.value,
          "description": this.ProductForm.controls.description.value,
          "hsn": this.ProductForm.controls.hsn.value,
          "salePrice": this.ProductForm.controls.price.value,
          "gstRate":this.ProductForm.controls.gstRate.value,
          "stock": this.ProductForm.controls.openingStock.value,
          "asOfDate": this.ProductForm.controls.asOfDate.value,
          "additionalCess": this.ProductForm.controls.additionalCess.value,
          "coverImage": this.productPicUrl
      },
        "productImages":
           this.multipleImage ? this.multipleImage : null

      }
      this.productService.postService(data).then((res) => {
        if (res) {
          this.toastService.toastMsg({
            title: "Success",
            content: "Service Added Successfully!!!",
          });
          this.productService.notifyProductAdded();
          this.dialogRef.close();
        }
      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went To Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went To Wrong");
        }
      })

    }
    // let data = {
    //   "addService": {
    //     "userId": this.currentUser.id,
    //     "itemName": this.ProductForm.controls.serviceName.value,
    //     "categoryId": this.ProductForm.controls.category.value,
    //     "itemCode": this.ProductForm.controls.serviceCode.value,
    //     "barcode": this.ProductForm.controls.serviceCode.value,
    //     "description": this.ProductForm.controls.description.value,
    //     "hsn": this.ProductForm.controls.hsn.value,
    //     "salePrice": this.ProductForm.controls.price.value,
    //     "gstRate": this.ProductForm.controls.gstRate.value,
    //     "stock": this.ProductForm.controls.openingStock.value,
    //     "asOfDate": this.ProductForm.controls.asOfDate.value,
    //     "additionalCess": this.ProductForm.controls.additionalCess.value,
    //     "coverImage":this.productPicUrl
    //   },
    //   "productImages":
    //      this.multipleImage ? this.multipleImage : null

    // }

    // }, (err) => {
    //   this.toastService.openErrorSnackBar("Please Try Again Later!!!")
    // })
  }

  saveAndNewService() {
    let data = {
      "userId": this.currentUser.id,
      "itemName": this.ProductForm.controls.serviceName.value,
      "categoryId": this.ProductForm.controls.category.value,
      "itemCode": this.ProductForm.controls.serviceCode.value,
      "barcode": this.ProductForm.controls.serviceCode.value,
      "description": this.ProductForm.controls.description.value,
      "hsn": this.ProductForm.controls.hsn.value,
      "salePrice": this.ProductForm.controls.price.value,
      "gstRate": this.ProductForm.controls.gstRate.value,
      "stock": this.ProductForm.controls.openingStock.value,
      "asOfDate": this.ProductForm.controls.asOfDate.value,
      "additionalCess": this.ProductForm.controls.additionalCess.value,
    }
    this.productService.postService(data).then((res) => {
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Service Added Successfully!!!",
        });
        // this.toastService.openSnackBar("Service Added Successfully")
        this.submitted = false;
        this.ProductForm.reset();
        this.todaysDate = new Date();
        this.ProductForm.patchValue({
          additionalCess: 0,
          openingStock: 0
        })
        this.imageSrcOne = '';
        this.imageSrcTwo = '';
        this.imageSrcThree = '';
        this.imageSrcFour = '';
        this.imageSrcFive = '';
        this.imageSrcSix = '';
        this.imageSrcSeven = '';
      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went To Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went To Wrong");
      }
    })

    // }, (err) => {
    //   console.log(this.ProductForm);
    //   this.toastService.openErrorSnackBar("Please Try Again Later!!!")
    // })
  }
  addGoods() {
    this.submitted = true;
    // if(this.ProductForm.invalid){
    //   this.toastService.openErrorSnackBar("Fill all required fields.")
    //   return false;
    // }
    let data = {
      "userId": this.currentUser.id,
      "itemName": this.ProductForm.controls.itemName.value,
      "categoryId": this.ProductForm.controls.category.value,
      "itemCode": this.ProductForm.controls.itemCode.value,
      "barcode": this.ProductForm.controls.generateBarcode.value,
      "description": this.ProductForm.controls.description.value,
      "hsn": this.ProductForm.controls.hsn.value,
      "salePrice": this.ProductForm.controls.price.value,
      "gstRate": this.ProductForm.controls.gstRate.value,
      //"unit": this.ProductForm.controls.unit.value,
      "unit": this.unit,
      // "secondaryUnit": this.ProductForm.controls.secondaryUnit.value,
      "secondaryUnitFlag": this.ProductForm.controls.secondaryUnitFlag.value,

      "secondaryUnit": this.unitSecondaryValue,
      "conversionRate": this.ProductForm.controls.conversionRate.value,
      "stock": this.ProductForm.controls.openingStock.value,
      "asOfDate": this.ProductForm.controls.asOfDate.value,
      "lowStockWarning": this.ProductForm.controls.lowStockWarning.value,
      "lowStockUnits": this.ProductForm.controls.lowStockUnit.value,
      "additionalCess": this.ProductForm.controls.additionalCess.value,
      "supportType": this.ProductForm.controls.supportType.value,
      "supportDuration": this.ProductForm.controls.supportDuration.value,

    }
    this.productService.postGoods(data).then((res) => {
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Product Added Successfully!!!",
        });
        // this.toastService.openSnackBar("Product added Successfully")
      }
      this.router.navigate(["/pages/product-list"])
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went To Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went To Wrong");
      }
    })
    // }, (err) => {
    //   this.toastService.openErrorSnackBar("Try Again")
    // })
  }

  saveAndNewGoods() {
    this.submitted = true;
    // if(this.ProductForm.invalid){
    //   this.toastService.openErrorSnackBar("Fill all required fields.")
    //   return false;
    // }
    let data = {
      "userId": this.currentUser.id,
      "itemName": this.ProductForm.controls.itemName.value,
      "categoryId": this.ProductForm.controls.category.value,
      "itemCode": this.ProductForm.controls.itemCode.value,
      "barcode": this.ProductForm.controls.generateBarcode.value,
      "description": this.ProductForm.controls.description.value,
      "hsn": this.ProductForm.controls.hsn.value,
      "salePrice": this.ProductForm.controls.price.value,
      "gstRate": this.ProductForm.controls.gstRate.value,
      //"unit": this.ProductForm.controls.unit.value,
      "unit": this.unit,
      // "secondaryUnit": this.ProductForm.controls.secondaryUnit.value,
      "secondaryUnitFlag": this.ProductForm.controls.secondaryUnitFlag.value,

      "secondaryUnit": this.unitSecondaryValue,
      "conversionRate": this.ProductForm.controls.conversionRate.value,
      "stock": this.ProductForm.controls.openingStock.value,
      "asOfDate": this.ProductForm.controls.asOfDate.value,
      "lowStockWarning": this.ProductForm.controls.lowStockWarning.value,
      "lowStockUnits": this.ProductForm.controls.lowStockUnit.value,
      "additionalCess": this.ProductForm.controls.additionalCess.value,
      "supportType": this.ProductForm.controls.supportType.value,
      "supportDuration": this.ProductForm.controls.supportDuration.value,

    }
    this.productService.postGoods(data).then((res) => {
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Product Added Successfully!!!",
        });
        // this.toastService.openSnackBar("Product added Successfully")
        this.submitted = false;
        this.ProductForm.reset();
        this.isDisabled = false;
        this.isStockToggle = false;
        this.todaysDate = new Date();
        this.ProductForm.patchValue({
          additionalCess: 0,
          openingStock: 0,
          secondaryUnitFlag: false,
          lowStockWarning: false


        })
        this.imageSrcOne = '';
        this.imageSrcTwo = '';
        this.imageSrcThree = '';
        this.imageSrcFour = '';
        this.imageSrcFive = '';
        this.imageSrcSix = '';
        this.imageSrcSeven = '';
      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went To Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went To Wrong");
      }
    })

    // }, (err) => {
    //   this.toastService.openErrorSnackBar("Please Try Again Later!!!")
    // })
  }

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  handleInputChange(e, imgNumber?: any) {
    this.imgNumber = imgNumber;
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];


    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.ProductForm.patchValue({
          file: reader.result,
        });

        if (file == null) {
          // this.toastService.openErrorSnackBar(
          //   "Please Select Image To Upload.",
          // );
          this.toastService.toastMsg({
            title: "Error",
            content: "Please Select Image To Upload.",
          });
        } else {
          if (this.imgNumber == 1) {
            this.imageSrcOne = reader.result;
            formdata.set("uploadProductImage", file)
          } else if (this.imgNumber == 2) {
            this.imageSrcTwo = reader.result;
            formdata.set("uploadProductImage", file)
          } else if (this.imgNumber == 3) {
            this.imageSrcThree = reader.result;
            formdata.set("uploadProductImage", file)
          } else if (this.imgNumber == 4) {
            this.imageSrcFour = reader.result;
            formdata.set("uploadProductImage", file)
          } else if (this.imgNumber == 5) {
            this.imageSrcFive = reader.result;
            formdata.set("uploadProductImage", file)
          } else if (this.imgNumber == 6) {
            this.imageSrcSix = reader.result;
            formdata.set("uploadProductImage", file)
          } else if (this.imgNumber == 7) {
            this.imageSrcSeven = reader.result;
            formdata.set("uploadProductImage", file)
          }
          this.productService.postProductImage(formdata, this.currentUser.id).then((res) => {


          });
        }
      }
    };
  }
  handleCoverDragEnter() {
    this.dragging = true;
  }
  handleCoverDragLeave() {
    this.dragging = false;
  }
  handleCoverImageLoad() {
    this.imageLoaded = true;
  }
  handleCoverDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.coverImgChange(e);
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  numberDecimalOnly(event):boolean{
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
       return false;

    return true;
  }

  coverImgChange(e, imgNumber?: any) {
    this.imgNumber = imgNumber;
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];


    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    const fileSizeLimit = 1 * 1024 * 1024; // 6 MB
    if (file.size > fileSizeLimit) {
      alert("File size exceeds the limit. Please select a smaller image (up to 1 MB).");
      return;
    }
    else{
      this.loaded = false;
      if (e.target.files && e.target.files[0]) {
        reader.readAsDataURL(file);

        reader.onload = () => {
          this.ProductForm.patchValue({
            file: reader.result,
          });

          if (file == null) {
            // this.toastService.openErrorSnackBar(
            //   "Please Select Image To Upload.",
            // );
            this.toastService.toastMsg({
              title: "Error",
              content: "Please Select Image To Upload.",
            });
          } else {

            this.imageSrcSeven = reader.result;

            formdata.set("upload", file)
            e.srcElement.value = "";

            this.productService.postCoverImage1(formdata).then((res) => {
              console.log("*****", res);
              this.productPicUrl = res.data.locations[0];

            });
          }
        }
      }
    }

  }



  // onFileSelected(event){
  //   const frmData = new FormData();
  //  // this.previews = [];
  //   for (var i = 0; i < event.target.files.length; i++) {
  //     //this.myFiles.push(event.target.files[i]);
  //     const reader = new FileReader();
  //     reader.onload = (e: any) => {
  //       this.previews.push(e.target.result);
  //     };

  //     reader.readAsDataURL(event.target.files[i]);
  //     frmData.append("upload" , event.target.files[i]);
  //   }
  //   console.log(this.previews)

  //   this.uploadMultiplefile(frmData)



  // }
  onFileSelected(event) {
    const maxFileSize = 1024 * 1024; // 1 MB
    const selectedFiles = event.target.files;
    if (this.maxFiles == 5) {
      this.toastService.toastMsg({
        title: "Error",
        content: "You Have All Ready Select 5 Images.",
      });
      // this.toastService.openErrorSnackBar(`You have all ready select 5 images.`);
    }
    else{
      if (selectedFiles.length > (this.Filesmax - this.maxFiles)) {
        // Handle too many files error
        console.error("You can only select up to 5 files.");
        this.toastService.toastMsg({
          title: "Error",
          content: "You Can Only Select Up To ${this.Filesmax - this.maxFiles} Images."
        });
        // this.toastService.openErrorSnackBar(`You can only select up to ${this.Filesmax - this.maxFiles} images.`);
        return;
      }

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        console.log("file", file.size)
        if (file.size > maxFileSize) {
          // Handle file size too large error
          //console.error(`File ${file.name} exceeds the maximum size of 1 MB.`);
          this.toastService.toastMsg({
            title: "Error",
            content: "File ${file.name} Exceeds The Maximum Size Of 1 MB."
          });
          // this.toastService.openErrorSnackBar(`File ${file.name} exceeds the maximum size of 1 MB.`);
          return;
        }
      }

      // Continue with file processing
      const frmData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };
        reader.readAsDataURL(selectedFiles[i]);
        frmData.append("upload", selectedFiles[i]);
      }
      this.maxFiles = this.maxFiles + selectedFiles.length;
      console.log("this.maxFiles",this.maxFiles)
      console.log(this.previews);
      this.uploadMultiplefile(frmData);
    }


  }
  uploadMultiplefile(frmData:FormData){
    this.productService.postCoverImage1(frmData).then((res) => {
      if(res){
        if(this.multipleImage.length == 0){
          this.multipleImage = res.data.locations;;
          console.log("1 st Multiple",this.multipleImage)
        }
        else{
          this.multipleImage1 = res.data.locations;

          for(var i=0 ; i < this.multipleImage1.length;i++){
            this.multipleImage.push(this.multipleImage1[i]);
          }
          console.log("length Multiple",this.multipleImage.length)
          console.log("again Multiple",this.multipleImage)

        }
        this.toastService.toastMsg({
          title: "Success",
          content: "Product Images Added Successfully!!!"
        });
      // this.toastService.openSnackBar("Product Images added Successfully!!!");
      //console.log("before",this.multipleImage);

      }

    },(err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went To Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went To Wrong");
      }
    });

 }

 cancelmultiImage(i:number){
  //var index = this.previews.indexOf(i);

  this.previews.splice(i, 1); // Removes one element, starting from index
  this.maxFiles = this.maxFiles - 1; // Decrement maxFiles by 1
  console.log("cancelmultiImage, this.maxFiles", this.maxFiles)
  //console.log("before1",this.multipleImage);
  //var index1 = this.multipleImage.indexOf(removeItem);

  this.multipleImage.splice(i,1);
 // console.log("after",this.multipleImage);
 }

  // Clear the inouts in the form.
  clearInput() {
    this.dialogRef.close();
    this.ProductForm.reset();
    this.isDisabled = false;
    this.isStockToggle = false;
    this.todaysDate = new Date();
    this.imageSrcOne = '';
    this.imageSrcTwo = '';
    this.imageSrcThree = '';
    this.imageSrcFour = '';
    this.imageSrcFive = '';
    this.imageSrcSix = '';
    this.imageSrcSeven = '';
  }

  cancelCoverImage() {

    this.imageSrcSeven = '';
    this.productPicUrl = '';
    this.imageLoaded = false;

  }
  openDatepicker(){
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.ProductForm.get('asOfDate').value;
    if (isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.ProductForm.get('asOfDate').setValue(new Date());
    }

 }
 autoGenerate() {
  this.productService.autoGenerateBarcode({}, this.currentUser.id).then((codeData) => {
    if (codeData) {
      this.ProductForm.patchValue({
        generateBarcode: codeData.data
      })
    }
  }, (err) => {
    if (err.error.expose) {
      this.toastService.toastMsg({
        title: "Error",
        content: this.titleCasePipe.transform(err.error.error_message),
      });
    }
    else {
      this.toastService.toastMsg({
        title: "Error",
        content: "Something Went Wrong.",
      });
    }
  })
}
}
