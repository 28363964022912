import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ContactUsServiceService } from 'src/app/core/services/contact-us-service.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-contact-list-ticket',
  templateUrl: './contact-list-ticket.component.html',
  styleUrls: ['./contact-list-ticket.component.scss']
})
export class ContactListTicketComponent implements OnInit {
  public isAllChecked: boolean = false;
  public contactDetails: any = [];
  public read: boolean;
  public selectedCheckBox: boolean = false;
  public singleChecked: boolean = false;
  public searchNot: boolean = false;
  public searchNot1: boolean = true;
  public p = 1;
  public currentpage: any;
  public totalrow: any;
  public toatlPages: any;
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  public pagesize: number = 1;
  public count: number = 0;
  public search: any;
  public userID: any;


  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public selectType = ["Issue", "Query", "Feedback"]
  constructor(public contactService: ContactUsServiceService, private titleCasePipe: TitleCasePipe, private toastService: ToastNotificationService) { }


  ngOnInit(): void {
    this.getAllCotactEn();
  }
  toggleCheckboxAll(event) {
    this.isAllChecked = event;
    if (this.isAllChecked == true) {
      this.read = true;
    }
    else {
      this.read = false;
    }
  }
  getAllCotactEn() {
    // this.contactService.getAllContactEnDetails({}, this.currentUser.id, this.partyPageSize, (this.currentPageNo + 1)).then((res) => {
    //   this.p = res.data.currentpage;
    //   this.totalrow = res.data.totalrows
    //   this.contactDetails = res.data.pageData
    // }, (err) => {
    //   if (err.error.expose) {
    //     this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
    //   }
    //   else {
    //     this.toastService.openErrorSnackBar("Something Went Wrong.");
    //   }
    // })

  }
  getEnquiries(search) {
    this.search = search;
    this.searchNot=true;
    this.searchNot1=false;
    this.contactService.getEnquiriesList({}, this.currentUser.id, this.partyPageSize, (this.currentPageNo + 1), this.search).then((res) => {
      this.contactDetails = res.data.pageData
      this.totalrow = res.data.totalrows
      if( this.contactDetails.length > 0)
      {
        this.toastService.toastMsg({
          title: "Success",
          content: "Record Found Successfully!!!"
        });
        // this.toastService.openSnackBar("Record Found Successfully!!!")
      }
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })
  }


  toggleCheckboxSingle(e) {
    this.read = e;
    this.singleChecked = this.read;
  }
  public handlePage(e: any) {
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.contactDetails.length > 0) {
      this.partyPageSize = e.pageSize;
      this.getAllCotactEn();
      //this.getEnquiries(this.search);
    }
  }

  public handlePagesearch(e: any) {
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.contactDetails.length > 0) {
      this.partyPageSize = e.pageSize;
      //this.getAllCotactEn();
      this.getEnquiries(this.search);
    }
  }

}
