import { Component, OnInit } from '@angular/core';
import lang from "src/assets/langTranslation/language_translation"
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SundryCreditorService } from 'src/app/core/services/sundry-creditor.service';
import { SundryDebtorService } from 'src/app/core/services/sundry-debtor.service';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { TitleCasePipe } from '@angular/common';
import { CommonService } from "src/app/core/services/common.service";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';

@Component({
  selector: 'app-sundry-debtor',
  templateUrl: './sundry-debtor.component.html',
  styleUrls: ['./sundry-debtor.component.scss']
})
export class SundryDebtorComponent implements OnInit {

  public lang = lang.UAE;
  daysList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  UnpaidAmout = ["<Rs.500", "Rs.500 -Rs.1000", "Rs.1000-Rs.2500", "Rs.2500 -Rs.5000", "Rs.5000-Rs.7500", "Rs.7500 -Rs.1000", "Rs.1000>"]
  filterShow: boolean = false;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  debitdata: any = [];
  type = "RECEIVED"
  public currentpage: any;
  public totalrow: any;
  public toatlPages: any;
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  public pagesize: number = 1;
  public count: number = 0;
  downloadData: any = [];
  partyName: any
  creditPeriodDay: any
  email: any
  totalCredit: any
  totalDebt: any
  gstIn: any
  public isArabic: boolean;
  p = 1;
  public slectDays: any
  public NoOfDays: any
  public amountFrom: any;
  public amountTo: any;

  constructor(private modal: NgbModal, private titleCasePipe: TitleCasePipe,
    private sundryservice: SundryCreditorService, private toastService: ToastNotificationService, private commonService: CommonService,
  ) {

  }


  ngOnInit(): void {
    // Subscribe to the observable to receive updates
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });

    this.getAllsundryDebit();
  }
  modalDismiss() {
    this.modal.dismissAll();
  }
  GotoFilter() {
    this.filterShow = !this.filterShow
  }
  getAllsundryDebit() {
    this.sundryservice.sundryCredit({}, this.currentUser.id, this.type, this.partyPageSize, (this.currentPageNo + 1)).then((res) => {
      this.debitdata = res.data.pageData;
      this.totalrow = res.data.totalrows
      this.toatlPages = res.data.totalPages
      this.p = res.data.currentPage;
    })
  }
  // for pagination
  public handlePage(e: any) {
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.debitdata.length > 0) {
      this.partyPageSize = e.pageSize;
      this.getAllsundryDebit();
    }
  }

  closeModal() {
  }
  print() {
    const printContents = document.getElementById('panel').innerHTML;
    const originalContents = document.body.innerHTML;
    console.log("originalContents", originalContents)

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }

  downlaodAllData() {
    if (this.totalrow === undefined || this.totalrow === null) {
      this.toastService.toastMsg({
        title: "Error",
        content: "No Data Available For Download",
      });
    } else {
      this.sundryservice.sundryCredit({}, this.currentUser.id, this.type, this.totalrow, (this.currentPageNo + 1)).then((res) => {
        this.downloadData = res.data.pageData;
        if (this.downloadData.length > 0) {
          this.exportToExcel()
        } else {
          this.toastService.toastMsg({
            title: "Error",
            content: "No Data Available For Download",
          });
        }
      }, (err) => {
        if (err.error.status === 500) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        }
      })
    }
  }

  exportToExcel() {
    if (this.isArabic) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet 1');

      // Define a header style
      const headerStyle = {
        font: { color: { argb: 'FF0000FF' }, bold: true },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFF00FF' } },
      };

      // Define your headers
      const headers = [
        'Debitor',
        'Contact #',
        'Email ID',
        'TRN #',
        'Credit Period (In Days)',
        'Credit Amount (In AED)',
      ];
      const headerRow = worksheet.addRow(headers);
      headerRow.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
        };
        cell.fill = headerStyle.fill;
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

      });
      // Set column widths for header cells
      worksheet.columns.forEach((column, index) => {
        // Assuming you want a fixed width of 120 for all header columns
        column.width = 15;
      });

      // Set background colors for multiple cells using a loop
      const cellBackgroundColors = [
        { cell: 'A1', color: 'FF8DB4E2', width: 5 }, // light blue background for cell A1
        { cell: 'B1', color: 'FF8DB4E2' },
        { cell: 'C1', color: 'FF8DB4E2' },
        { cell: 'D1', color: 'FF8DB4E2' },
        { cell: 'E1', color: 'FFFFC4D5' }, //for orange
        { cell: 'F1', color: 'FFFFC4D5' },
      ];
      cellBackgroundColors.forEach((cellColor) => {
        worksheet.getCell(cellColor.cell).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: cellColor.color },
        };
      });

      this.downloadData.forEach((item) => {
        const row = [];
        row.push(item.partyName ? item.partyName : "NA",)
        row.push("NA"),
          row.push(item.email),
          row.push(item.gstIn ? item.gstIn : '')
        row.push(item.creditPeriodDay ? item.creditPeriodDay : "NA",),
          row.push(item.totalDebt ? item.totalDebt : "NA")
        worksheet.addRow(row);
      });

      // Generate the Excel file
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'sundry_debitor_data.xlsx');
      });

    }
    else {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet 1');

      // Define a header style
      const headerStyle = {
        font: { color: { argb: 'FF0000FF' }, bold: true },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFF00FF' } },
      };

      // Define your headers
      const headers = [
        'Debitor',
        'Contact #',
        'Email ID',
        'GSTIN ',
        'Credit Period (In Days)',
        'Credit Amount (In Rs)',
      ];

      const headerRow = worksheet.addRow(headers);
      headerRow.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
        };
        cell.fill = headerStyle.fill;
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        //  cell.width = 120

      });
      // Set column widths for header cells
      worksheet.columns.forEach((column, index) => {
        // Assuming you want a fixed width of 120 for all header columns
        column.width = 15;
      });

      // Set background colors for multiple cells using a loop
      const cellBackgroundColors = [
        { cell: 'A1', color: 'FF8DB4E2', width: 5 }, // light blue background for cell A1
        { cell: 'B1', color: 'FF8DB4E2' },
        { cell: 'C1', color: 'FF8DB4E2' },
        { cell: 'D1', color: 'FF8DB4E2' },
        { cell: 'E1', color: 'FFFFC4D5' }, //for orange
        { cell: 'F1', color: 'FFFFC4D5' },
      ];
      cellBackgroundColors.forEach((cellColor) => {
        worksheet.getCell(cellColor.cell).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: cellColor.color },
        };
      });

      this.downloadData.forEach((item) => {
        const row = [];
        row.push(item.partyName ? item.partyName : "NA",)
        row.push("NA"),
          row.push(item.email),
          row.push(item.gstIn ? item.gstIn : '')
        row.push(item.creditPeriodDay ? item.creditPeriodDay : "NA",),
          row.push(item.totalDebt ? item.totalDebt : "NA")
        worksheet.addRow(row);
      });

      // Generate the Excel file
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'sundry_debitor_data.xlsx');
      });
    }
  }

  getReceiptById(receiptId) {
    const data = this.debitdata.find(item => item.id === receiptId);
    this.partyName = data.partyName;
    this.creditPeriodDay = data.creditPeriodDay;
    this.email = data.email;
    this.totalCredit = data.totalCredit;
    this.totalDebt = data.totalDebt;
    this.gstIn = data.gstIn;
  }
  applyFilter() {
    this.sundryservice.sundryDebitFilter({}, this.currentUser.id, this.amountFrom, this.amountTo, "", this.totalrow, (this.currentPageNo + 1), this.type, this.NoOfDays).then((res: any) => {
      this.debitdata = res.data.pageData;
      this.totalrow = res.data.totalrows
      this.toatlPages = res.data.totalPages
      this.p = res.data.currentPage;
      this.slectDays = null
      this.NoOfDays = ""
      this.amountFrom = ""
      this.amountTo = ""

    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
      }
    })
  }
  cancelModal() {
    this.getAllsundryDebit();

  }
}
