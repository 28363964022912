
<form [formGroup]="DashboardForm">
    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 mb-3">
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Location Type</mat-label>
                <mat-select formControlName="LocationType" (onchange)="locationChange()">
                    <mat-option *ngFor="let location of locations"[value]="location.id">
                        {{location.locationName}}
                    </mat-option>
                </mat-select>
            <mat-error *ngIf="f.LocationType.invalid && submitted" class="invalid-feedback">
                <div style="font-weight: 300; font-size: 11px; " *ngIf="f.LocationType.errors.required">Location Type Is Required!</div>
            </mat-error>
        </mat-form-field>
    </div>
</form>

<div data-calendar='{"events":[{"title":"Bootcamp","start":"2022-08-05"}]}'></div>
