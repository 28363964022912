import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { BsDaterangepickerConfig, MonthPickerComponent } from 'ngx-bootstrap/datepicker';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PartyReportsService } from 'src/app/core/services/party-reports.service';
import { PartyService } from 'src/app/core/services/party.service';
import { PurchaseInvoiceService } from 'src/app/core/services/purchase-invoice.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import * as XLSX from 'xlsx';
interface IRange {
  value: Date[];
  label: string;

}
@Component({
  selector: 'app-party-statement',
  templateUrl: './party-statement.component.html',
  styleUrls: ['./party-statement.component.scss']
})
export class PartyStatementComponent implements OnInit {
  public quarterStartDate: any;
  public quarterEndDate: any;
  public today: Date;
  public quarter;
  public maxDate: Date;
  public start = moment().subtract(29, 'days');
  public end = moment();
  public ranges: IRange[];
  public partyName = new FormControl();
  public partyId: any;
  public allPartyList: any = [];
  public partyList: any;

  public partyData: any;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public filteredOptions: Observable<string[]>;
  public currentPageNo: number = 0;
  public partyPagesize: number = 10;
  public submitted: boolean = false;
  public supplytype: any;
  public userProfileGSTNo: any;
  public gstStateMatch: boolean = true;
  public purchaseGst: any;
  public myControl = new FormControl();
  public dateRange: Date[]
  public startOfDateRange: any;
  public endOfDateRange: any;
  public search: any;
  public searchPartyName: any;
  public searchpartyList: any = [];
  fileName = 'partyStatement.xlsx';
  // public salepurchaseList: any = [];

  constructor(private purchaseInvoiceService: PurchaseInvoiceService,
    private partyService: PartyService,
    private saleInvoiceService: SaleInvoiceService,
    private partyreportsService: PartyReportsService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
    let quarter = moment(this.today).utc().quarter() //outputs 4
    this.getQuarterRange();
  }

  ngOnInit(): void {
    this.getAllParties();
    // this.getpartystmt();
    this.ranges = [

      {
        value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 7 Days'
      },
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 30 Days'
      },
      {
        value: [new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()), new Date(new Date(new Date().setDate(0)).toDateString())],
        label: 'Last Month'

      },
      {
        value: [this.quarterStartDate._d, this.quarterEndDate._d],
        label: ' Last Quarter'
      },
      {
        value: [new Date(new Date(new Date().getFullYear() - 1, 3, 1).toDateString()), new Date(new Date(new Date().getFullYear(), 2, 31).toDateString())],
        label: 'Last Financial Year'

      },
      {
        value: [new Date(new Date(new Date().getFullYear(), 3, 1).toDateString()), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'This Financial Year'

      },
      {
        value: [],
        label: 'Custom Range'

      }];
    this.dateRange = [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate()))]

    for (let i = 0; i < this.ranges.length; i++) {
      if (this.ranges[i].label == "Last Quarter") {
      }
    }
  }
  getReceiptById() {

  }
  getpartystmt() {
    this.partyreportsService.getPartyByStatement({}, this.partyId, "2023-01-01", "2023-11-01", this.searchPartyName).then((res) => {
      this.searchpartyList = res.data;

      // },
      //   (err) => {
      //     if (err.error.expose) {
      //       this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      //     }
      //     else {
      //       this.toastService.openErrorSnackBar("Something Went Wrong.");
      //     }
    })
  }
  private filterNames(name: string): string[] {
    return this.allPartyList.filter(partyList =>
      partyList.partyName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
  getAllParties() {
    this.partyService
      .getAllPartiesDetails({}, this.currentUser.id, this.partyPagesize, (this.currentPageNo + 1))
      .then((res) => {
        this.allPartyList = res.data.pageData;
        this.filteredOptions = this.partyName.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filterNames(name) : this.allPartyList.slice())
          );
      });
  }

  searchPartyByName(item) {
    this.searchPartyName = item.partyName

    console.log("item", item);
    this.partyId = item.id;
    this.saleInvoiceService.getPartyByName({}, this.currentUser.id, item)
      .then((response: any) => {
        this.partyData = response.data.rows;
        this.allPartyList = response.data.rows;
        if (this.allPartyList) {
          this.filteredOptions = this.myControl.valueChanges
            .pipe(
              startWith(''),
              map(name => name ? this.filterNames(name) : this.allPartyList.slice())
            );
        }
      });
    this.getpartystmt()
  }
  printComponent(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;

  }
  getQuarterRange() {
    this.quarterStartDate = moment(this.today).subtract(1, 'quarter').startOf('quarter');
    this.quarterEndDate = moment(this.today).subtract(1, 'quarter').endOf('quarter');
  }

  setSelectedPartyDeatails(item) {
    this.partyId = item.id;
    this.partyData.forEach((ele) => {
      // this.purchaseGst= ele.gstIn
      // this.PurchaseInvoiceForm.patchValue({
      // 'gstNo': ele.gstIn,
      // 'billingAddress': ele.billingAddress
      // })
      if (this.purchaseGst.slice(0, 2) == this.userProfileGSTNo) {
        this.gstStateMatch = true
        this.supplytype = "INTRASTATE"
      }
      else {
        this.gstStateMatch = false;
        this.supplytype = "INTERSTATE"
      }

    })
  }
  exportExcel() {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }
  onPrint() {
    const elementsToHide = ['headingPrintHide', 'dateRangePrintHide', 'searchBarHide'];

    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = 'none';
      }
    });

    // Hide headers and footers for printing
    const style = document.createElement('style');
    style.innerHTML = `
        @media print {
            @page {
                size: auto;   /* auto is the initial value */
                margin: 0;  /* this affects the margin in the printer settings */
            }
            @page {
                margin-top: 0;
                margin-bottom: 0;
            }
            body {
                margin: 0;  /* this affects the margin on the content before sending to the printer */
            }
            @page :first {
                margin-top: 0;
            }
            @page :last {
                margin-bottom: 0;
            }
            /* Define CSS rules to hide headers and footers */
            header, footer {
                display: none !important;
            }
        }
    `;
    document.head.appendChild(style);

    // Print
    const printContents = document.getElementById('excel-table').innerHTML;
    const originalContents = document.body.innerHTML;
    this.removeScrollbar();
    window.print();
    this.restoreScrollbar();
    // Restore hidden elements after printing
    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = '';
      }
    });
    // Remove the added style for hiding headers and footers
    document.head.removeChild(style);
  }
  private removeScrollbar() {
    document.body.style.overflow = 'hidden';
  }

  private restoreScrollbar() {
    document.body.style.overflow = 'auto';
  }
}



