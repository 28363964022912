import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'src/app/core/services/product.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';
import * as moment from 'moment';
import { TitleCasePipe } from '@angular/common';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WarehouseService } from 'src/app/core/services/warehouse.service';

import lang from "src/assets/langTranslation/language_translation"
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-product-clone',
  templateUrl: './product-clone.component.html',
  styleUrls: ['./product-clone.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class ProductCloneComponent implements OnInit {
  public lang = lang.UAE;
  public isArabic: boolean;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public unitValue: any;
  public unitSecondaryValue: any;
  public isDisabled: boolean = false;
  public showProductType: any;
  public serviceToggle: boolean;
  public isStockToggle: boolean = true;
  public ProductForm: any;
  public productId: any;
  public todaysDate;
  public unit: any;
  public units: any;
  public secondaryunit: any;
  // image upload
  public imagediv: boolean = false;
  public formdiv: boolean = true;
  public activeColor: string = 'green';
  public baseColor: string = '#ccc';
  public overlayColor: string = 'rgba(255,255,255,0.5)';
  public dragging: boolean = false;
  public loaded: boolean = false;
  public imageLoaded: boolean = false;
  public imageSrc: string = '';
  public file: any;
  public selectedFiles: any;
  public selectedFileName: any;
  public imgNumber: any;
  public submitted: boolean = false;
  public productCategory: any;
  public imageSrcOne: any = "";
  public imageSrcOneId: any;
  public imageSrcTwo: any = "";
  public imageSrcTwoId: any;
  public imageSrcThree: any = "";
  public imageSrcThreeId: any = ""
  public imageSrcFour: any = "";
  public imageSrcFourId: any = "";
  public imageSrcFive: any = "";
  public imageSrcFiveId: any = "";
  public multipleImage1 = [];

  public imageSrcSix: any = "";
  public imageSrcSixId: any = "";
  public imageSrcSeven: any = "";
  public isNextDisabled: boolean = true;

  public fetchImages: any;
  public currentInput: any;
  public multipleImage = [];
  previews: string[] = [];
  public productPicUrl: string = '';
  public coverDisabled: boolean = true;
  public catId: number;
  public primarySelectUnit: any;
  public secondarySelectUnit: any;
  public unitsecondary: any;
  public todaysDate2: any;
  step1Completed = false;
  public maxFiles: number = 0;
  public Filesmax: number = 5;
  public warrantyType: string;

  public static Uni = [
    "BAGS(BAG)",
    "BALE(BAL)",
    "BUNDLES(BDL)",
    "BUCKLES(BKL)",
    "BILLIONS OF UNITS(BOU)",
    "BOX(BOX)",
    "BOTTLES(BTL)",
    "BUNCHES(BUN)",
    "CANS(CAN)",
    "CUBIC METER(CBM)",
    "CUBIC CENTIMETER(CCM)",
    "CENTIMETER(CMS)",
    "CARTONS(CTN)",
    "DOZEN(DOZ)",
    "DRUM(DRM)",
    "GREAT GROSS(GGR)",
    "GRAMS(GMS)",
    "GROSS(GRS)",
    "GROSS YARDS(GYD)",
    "KILOGRAMS(KGS)",
    "KILOLITER(KLR)",
    "KILOMETRE(KME)",
    "MILLILITRE(MLT)",
    "METERS(MTR)",
    "METRIC TONS(MTS)",
    "NUMBERS(NOS)",
    "PACKS(PAC)",
    "PIECES(PCS)",
    "PAIRS(PRS)",
    "QUINTAL(QTL)",
    "ROLLS(ROL)",
    "SETS(SET)",
    "SQUARE FEET(SQF)",
    "SQUARE METERS(SQM)",
    "SQUARE YARDS(SQY)",
    "TABLETS(TBS)",
    "TEN GROSS(TGM)",
    "THOUSANDS(THD)",
    "TONNES(TON)",
    "TUBES(TUB)",
    "US GALLONS(UGS)",
    "UNITS(UNT)",
    "YARDS(YDS)",
  ]
  SecondaryUnit = ProductCloneComponent.Uni;
  Units = ProductCloneComponent.Uni;
  subCategoryList: any;
  activeSubCategoryList: any;
  ProductBrand: any;
  productCustomFieldsDetails: any[];
  customFieldsList: any[];
  public userType:any='';
  warehouseList: any;
  warehouseId: any;



  constructor(private productService: ProductService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ProductCloneComponent>,
    @Inject(MAT_DIALOG_DATA) public ProductData: any,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastNotificationService, private titleCasePipe: TitleCasePipe, 
    private warehouseService: WarehouseService) {
      this.productId = this.ProductData.productId
      this.warehouseId = this.ProductData.warehouseId
    } 

  ngOnInit(): void {
    
    this.userType=this.currentUser.userType
    this.todaysDate2 = new Date(new Date().setDate(new Date().getDate() + 1));
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });
    this.getProductCategory();
    if (this.isArabic) {
      this.ProductForm = this.formBuilder.group({
        itemName: ["",],
        category: [],
        itemCode: ["", [Validators.required, Validators.minLength(4), Validators.maxLength(8), Validators.pattern('^[a-zA-Z0-9]*$')]],
        generateBarcode: ["", [Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern("^[0-9]*$")]],
        description: [null],
        // hsn: ["", [Validators.pattern("^[0-9]*$")]],
        hsn: [null],
        unit: [null],
        //price: ["", [Validators.pattern("^[0-9]*$")]],
        //gstRate: ["", [Validators.pattern("^[0-9]*$")]],
        price: ["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
        // gstRate: ["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
        gstRate: ["5",],
        secondaryUnitFlag: [false],
        secondaryUnit: ["", Validators.required],
        conversionRate: [""],
        openingStock: [],
        asOfDate: [""],
        lowStockWarning: [false],
        lowStockUnit: ["", Validators.required],
        //additionalCess: ["", [Validators.pattern("^[0-9]*$")]],
        additionalCess: [null],
        serviceCode: [""],
        serviceName: [""],
        supportType: ["GUARANTEE"],
        supportDuration: [],
        expiry: [""],
        warehouse: [null],
        months: ['']

      });
    }
    else {
      this.ProductForm = this.formBuilder.group({
        itemName: ["",],
        category: [],
        itemCode: ["", [Validators.required, Validators.minLength(4), Validators.maxLength(8), Validators.pattern('^[a-zA-Z0-9]*$')]],
        generateBarcode: ["", [Validators.required, Validators.minLength(12), Validators.maxLength(12), Validators.pattern("^[0-9]*$")]],
        description: [""],
        // hsn: ["", [Validators.pattern("^[0-9]*$")]],
        hsn: ["", [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
        unit: [""],
        //price: ["", [Validators.pattern("^[0-9]*$")]],
        //gstRate: ["", [Validators.pattern("^[0-9]*$")]],
        price: ["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
        gstRate: ["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
        secondaryUnitFlag: [false],
        secondaryUnit: [""],
        conversionRate: [""],
        openingStock: [],
        asOfDate: [""],
        lowStockWarning: [false],
        lowStockUnit: [""],
        //additionalCess: ["", [Validators.pattern("^[0-9]*$")]],
        additionalCess: ["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
        serviceCode: [""],
        serviceName: [""],
        supportType: ["GUARANTEE"],
        supportDuration: [],
        expiry: [""],
        brand: [""],
        section: [""],
        subCategoryId: [""],
        gender: [""],
        color: [""],
        warehouse: [null],
        ageGroup: [""]

      });

    }


    // this.route.paramMap.subscribe((p) => {
    //   this.productId = p.get("id");
    // });
    this.productId = this.ProductData.productId
    this.getCustomFieldDetails();
    this.getProductCategory();
    this.getProductBrand();
    this.getProductSubCategory();
    this.getGoodsById();
    this.getWarehouseList();
    //this.getProductImage();

    this.ProductForm.valueChanges.subscribe((v) => {
      this.isNextDisabled = !this.ProductForm.valid;
    });
  }

  get f() {
    return this.ProductForm.controls;
  }
  generateCustomFields(): void {
    if (!this.customFieldsList || !Array.isArray(this.customFieldsList)) {
      console.error('Custom fields list is not properly initialized or is not an array.');
      return;
    }
  
    // Loop through customFieldsList and create form controls dynamically
    this.customFieldsList.forEach(field => {
      this.ProductForm.addControl(field.fieldName, this.formBuilder.control(''));
      // You might want to add validators here if needed, like Validators.required
    });
  }
  getDropdownOptions(field: any): any[] {
    const formArray = this.ProductForm.get(field.fieldName) as FormArray;
    return formArray ? formArray.value : [];
  }
    onOptionClick(field: any, option: any): void {
    const formArray = this.ProductForm.get(field.fieldName) as FormArray;
    
    // Clear existing values in the FormArray
    while (formArray.length) {
      formArray.removeAt(0);
    }
    formArray.push(this.formBuilder.control(option));
  }  
  

  getCustomFieldDetails(){
    this.productService.getCustomFields({
      data:{}, 
      userId: this.currentUser.id, 
      size: 1000, 
      page: 1, 
      search:'', 
      isActive: '' 
    }).then((res) => {
      // Filter and assign active custom fields to customFieldsList
      if (res.data) {
        this.customFieldsList = res.data.pageData.filter(field => field.isActive);
      // Call the function once data is assigned
      this.generateCustomFields();
      } 
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      }
    })
  }
  
  getProductCategory() {
    this.productService.getCategory({
      data:{}, 
      userId: this.currentUser.id, 
      size: 1000, 
      page: 1, 
      search: '', 
      isActive:''
    }).then((res: any) => {
      this.productCategory = res.data.pageData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      }
    })
  }
  getProductBrand() {
    this.productService.getBrandList({
      data:{}, 
      userId: this.currentUser.id, 
      size: 1000, 
      page: 1, 
      search:'', 
      isActive:''
    }).then((res: any) => {
      this.ProductBrand = res.data.pageData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })

      };
    })
  }
  getProductSubCategory() {
    this.productService.getSubCategory({
      data:{}, 
      userId: this.currentUser.id, 
      size: 1000, 
      page: 1, 
      search:'', 
      categoryId: this.ProductForm.controls.category.value, 
      isActive:''
    }).then((res: any) => {
      if (res.success) {
        this.subCategoryList = res.data.pageData;
        this.activeSubCategoryList = this.subCategoryList ?.filter(subcategory => subcategory.isActive === true);

      }
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      })
  }

  getWarehouseList() {
    this.warehouseService.getAllWarehouse({
      data:{}, 
      userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, 
      size: 1000, 
      page: 1, 
      search:'', 
    }).then((res: any) => {
      this.warehouseList = res.data.pageData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      };
    })
  }


  getUnit(event) {
    this.unit = event.source.value
    console.log("unit", this.unit)
    // const inputString = this.unit;
    // const startIndex = 0;
    // const endIndex = inputString.indexOf("(");

    // if (endIndex !== -1) {
    //   const result = inputString.substring(startIndex, endIndex);
    //   console.log("result", result)
    //   this.primarySelectUnit = result;
    // } else {
    //   console.log("Substring not found.");
    // }
    const regex = /\(([^)]+)\)/;
    const match = this.unit.match(regex);

    if (match) {
      const result4 = match[1]; // The matched text inside the parentheses
      console.log(result4);
      this.primarySelectUnit = result4;
    } else {
      console.log("Substring not found.");
    }
    this.SecondaryUnit = this.SecondaryUnit.filter(e => e !== this.unit);
  }
  private filterSecondary() {
    const selectedPrimary = this.SecondaryUnit.filter(us => this.unit === us);
    const index = this.SecondaryUnit.indexOf(this.unit);
    if (index > -1) {
      this.SecondaryUnit.splice(index, 1);
    }
  }

  getEnableStockValue(event) {
    this.isStockToggle = event;
    console.log("this.isStockToggle", this.isStockToggle);
    // if (this.isStockToggle == false) {
    //   this.ProductForm.patchValue({
    //     lowStockUnit: "",
    //   });
    //   console.log("enter patch low")
    // }
    if (!this.isStockToggle) {
      // Reset the form control and clear errors when the toggle is off
      this.ProductForm.get('lowStockUnit').reset();
      this.ProductForm.get('lowStockUnit').clearValidators();
      // console.log("enter patch sec if")
    } else {
      // Add the required validator when the toggle is on
      this.ProductForm.get('lowStockUnit').setValidators([Validators.required]);

    }
    // Update the validity of the form control
    this.ProductForm.get('lowStockUnit').updateValueAndValidity();
  }
  showSecondaryUnitField(value) {
    this.isDisabled = value;
    console.log("this.isDisabled", this.isDisabled);
    if (!this.isDisabled) {
      // Reset the form control and clear errors when the toggle is off
      this.ProductForm.get('secondaryUnit').reset();
      this.ProductForm.get('secondaryUnit').clearValidators();
      // console.log("enter patch sec if")
    } else {
      // Add the required validator when the toggle is on
      this.ProductForm.get('secondaryUnit').setValidators([Validators.required]);
      // console.log("enter patch sec else")
    }
    // Update the validity of the form control
    this.ProductForm.get('secondaryUnit').updateValueAndValidity();
  }
  getSecondaryUnit(event) {
    this.unitSecondaryValue = event.source.value;
    const regex = /\(([^)]+)\)/;
    const match = this.unitSecondaryValue.match(regex);

    if (match) {
      const result3 = match[1]; // The matched text inside the parentheses
      console.log(result3);
      this.secondarySelectUnit = result3;
    } else {
      console.log("Substring not found.");
    }
    // const inputString = this.unitSecondaryValue;
    // const startIndex = 0;
    // const endIndex = inputString.indexOf("(");

    // if (endIndex !== -1) {
    //   const result1 = inputString.substring(startIndex, endIndex);
    //   console.log(result1);
    //   this.secondarySelectUnit = result1;

    // } else {
    //   console.log("Substring not found.");
    // }
  }
  show(id) {
    if (id === 5) {
      this.showProductType = Constants.USER.GOODS;
      this.serviceToggle = false;
    }
    else if (id === 6) {
      this.showProductType = Constants.USER.SERVICE;
      this.serviceToggle = true;
    }
  }
  getGoodsById() {
    this.productService.getGoodsById({}, this.productId).then((res) => {
      this.unit = res.data.unit
      //this.catId = res.data.categoryId ? res.data.categoryId : null,
      this.ProductForm.patchValue({
        itemName: res.data.itemName,
        category: res.data.category.id,
        // itemCode: res.data.itemCode,
        //  generateBarcode: res.data.barcode,
        description: res.data.description,
        hsn: res.data.hsn,
        unit: res.data.unit,
        // price: res.data.salePrice,
        gstRate: res.data.gstRate,
        secondaryUnitFlag: res.data.secondaryUnitFlag,
        secondaryUnit: res.data.secondaryUnit,
        conversionRate: res.data.conversionRate,
        openingStock: res.data.stock,
        asOfDate: (moment(res.data.asOfDate).format("DD MMM YYYY")),
        expiry: (moment(res.data.expiryDate).format("DD MMM YYYY")),
        lowStockWarning: res.data.lowStockWarning,
        lowStockUnit: 0,
        additionalCess: res.data.additionalCess,
        supportType: res.data.supportType,
        supportDuration: res.data.supportDuration,
        brand: res.data.brand ? res.data.brand.id:null,
        section: res.data.section,
        subCategoryId: res.data.subCategory ? res.data.subCategory.id:null,
        gender: res.data.gender,
        color: res.data.color,
        ageGroup: res.data.ageGroup,
        warehouse: res.data?.warehouse,
      })
      this.warehouseId = res.data.warehouseId

      
      // Loop through customFieldsList and create form controls dynamically
      if (this.productCustomFieldsDetails) {
      this.productCustomFieldsDetails.forEach(field => {
        if (field.type === 'dropdown') {
          // For dropdown fields, initialize with an empty array
          this.ProductForm.addControl(field.fieldName, this.formBuilder.control({ value: null, options: [] }));
        } else {
          // For other types, use a simpler control
          this.ProductForm.addControl(field.fieldName, this.formBuilder.control(''));
        }      
      });
    }
      // Loop through the custom fields data
    if (this.productCustomFieldsDetails) {
      for (const customField of this.productCustomFieldsDetails) {
        // Check if the field name exists in the form controls
        if (this.ProductForm.get(customField.fieldName)) {
          // Patch the value to the respective form control
          this.ProductForm.get(customField.fieldName)?.patchValue(customField?.value);
        } else {
          // Add the missing field to the form dynamically
          this.ProductForm.addControl(customField.fieldName, this.formBuilder.control(customField?.value || ''));
        }
      }
    }

      if (!res.data.secondaryUnitFlag) {
        this.ProductForm.get('secondaryUnit').clearValidators();
        this.ProductForm.get('secondaryUnit').updateValueAndValidity();

      }
      if (!res.data.lowStockWarning) {

        this.ProductForm.get('lowStockUnit').clearValidators();
        this.ProductForm.get('lowStockUnit').updateValueAndValidity();
      }
      this.isStockToggle = res.data.lowStockWarning;
      this.isDisabled = res.data.secondaryUnitFlag


      console.log("unit", this.unit)
      const regex = /\(([^)]+)\)/;
      const match = this.unit.match(regex);

      if (match) {
        const result1 = match[1]; // The matched text inside the parentheses
        console.log(result1);
        this.primarySelectUnit = result1;
      } else {
        console.log("Substring not found.");
      }
      // const inputString = this.unit;
      // const startIndex = 0;
      // const endIndex = inputString.indexOf("(");

      // if (endIndex !== -1) {
      //   const result = inputString.substring(startIndex, endIndex);
      //   console.log("result", result)
      //   this.primarySelectUnit = result;
      // } else {
      //   console.log("Substring not found.");
      // }

      this.unitsecondary = res.data.secondaryUnit;
      if (this.unitsecondary !== null) {
        const regex = /\(([^)]+)\)/;
        const match = this.unitsecondary.match(regex);

        if (match) {
          const result2 = match[1]; // The matched text inside the parentheses
          console.log(result2);
          this.secondarySelectUnit = result2;
        } else {
          console.log("Substring not found.");
        }

        // const inputString1 = this.unitsecondary;
        // const startIndex1 = 0;
        // const endIndex1 = inputString1.indexOf("(");

        // if (endIndex1 !== -1) {
        //   const result1 = inputString1.substring(startIndex1, endIndex1);
        //   console.log(result1);
        //   this.secondarySelectUnit = result1;

        // } else {
        //   console.log("Substring not found.");
        // }
      }
      //this.imageSrcSeven = res.data.coverImage;
      this.SecondaryUnit = this.SecondaryUnit.filter(e => e !== this.unit);

      //this.imageSrcSeven = res.data.coverImage ? res.data.coverImage :null;
      this.fetchImages = res.data.productsImages;
      console.log("res.data.supportDuration", res.data.supportDuration);
      if (res.data.supportDuration) {
        console.log("enter support")
        const inputString = res.data.supportDuration;
        const number = inputString.match(/\d+/)?.[0];
        const text = inputString.replace(number || '', '');

        console.log("Number: " + number);
        console.log("Text: " + text);
        if (number !== 'undefined') {
          this.ProductForm.get('supportDuration').setValue(number);
        }

        // if ((text !== 'undefined') || (text !== '') || (text !== null )) {
        //   this.ProductForm.get('months').setValue(text);
        //   this.warrantyType = text;
        // }
        // Check if the control exists in the form before setting its value
    if (this.ProductForm.get('months')) {
    if (text !== undefined && text !== '' && text !== null) {
    this.ProductForm.get('months').setValue(text);
    this.warrantyType = text;
  }
} else {
  // console.error("Control 'months' does not exist in the form.");
}


      }



    })

  }

  cancel() {
    //  this.location.back();
    this.dialogRef.close();
    // this.router.navigate(["/pages/product-page"]);
    this.imageSrcOne = '';
    this.imageSrcTwo = '';
    this.imageSrcThree = '';
    this.imageSrcFour = '';
    this.imageSrcFive = '';
    this.imageSrcSix = '';
    this.imageSrcSeven = '';
  }
  // updateGoods() {
  //   this.submitted = true;

  //   let data = {
  //     "userId": this.currentUser.id,
  //     "itemName": this.ProductForm.controls.itemName.value,
  //     "categoryId": this.ProductForm.controls.category.value,
  //     "itemCode": this.ProductForm.controls.itemCode.value,
  //     "barcode": this.ProductForm.controls.generateBarcode.value,
  //     "description": this.ProductForm.controls.description.value,
  //     "hsn": this.ProductForm.controls.hsn.value,
  //     "salePrice": this.ProductForm.controls.price.value,
  //     "gstRate": this.ProductForm.controls.gstRate.value,
  //     "unit": this.unit,
  //     "secondaryUnitFlag":this.ProductForm.controls.secondaryUnitFlag.value,
  //     "secondaryUnit":this.ProductForm.controls.secondaryUnit.value ? this.ProductForm.controls.secondaryUnit.value : "0" ,
  //     "conversionRate": this.ProductForm.controls.conversionRate.value ?  this.ProductForm.controls.conversionRate.value : "0",
  //     "stock": this.ProductForm.controls.openingStock.value ? this.ProductForm.controls.openingStock.value :"0",
  //     "asOfDate": moment(this.ProductForm.controls.asOfDate.value, 'DD MMM YYYY').format('YYYY-MM-DD'),
  //     "lowStockWarning": this.ProductForm.controls.lowStockWarning.value,
  //     "lowStockUnits": this.ProductForm.controls.lowStockUnit.value ? this.ProductForm.controls.lowStockUnit.value :0,
  //     "additionalCess": this.ProductForm.controls.additionalCess.value,
  //     "supportType": this.ProductForm.controls.supportType.value,
  //     "supportDuration": this.ProductForm.controls.supportDuration.value,
  //   }

  //   this.productService.postGoods(data).then((res) => {
  //     if (res) {
  //       this.toastService.openSnackBar("Product Cloned Successfully!!!")
  //       console.log ("Res",res)
  //       this.imagediv = true;
  //       this.formdiv = false;
  //       this.productId=res.data.product.id

  //     }
  //   }, (err) => {
  //     if (err.error.expose) {
  //       this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
  //     }
  //     else {
  //       this.toastService.openErrorSnackBar("Something Went Wrong.");
  //     }

  //   })

  // }
  getCategoryType(event) {
    console.log("*****", event.source.value)
    this.catId = event.source.value;
    this.getProductSubCategory()

  }
  selectWarrantyType(event) {
    console.log("event.target.valuee", event)
    this.warrantyType = event.value
  }
  updateGoods() {
    this.submitted = true;
    if (this.ProductForm.invalid) {
      // this.toastService.openErrorSnackBar("Fill all required fields.");
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      console.log("Message Product", this.ProductForm)
      return false;
    }
    else {
      if (this.catId == 2 || this.catId == 4 || this.catId == 8 || this.catId == 9 || this.catId == 10 || this.catId == 11 || this.catId == 14 || this.catId == 15 || this.catId == 18) {
        let data = {
          "addProducts": {
            "userId": this.currentUser.id,
            "itemName": this.ProductForm.controls.itemName.value,
            "itemCode": this.ProductForm.controls.itemCode.value,
            "barcode": this.ProductForm.controls.generateBarcode.value,
            "description": this.ProductForm.controls.description.value,
            "hsn": this.ProductForm.controls.hsn.value,
            "salePrice": this.ProductForm.controls.price.value,
            "gstRate": this.ProductForm.controls.gstRate.value,
            "unit": this.unit,
            "secondaryUnitFlag": this.ProductForm.controls.secondaryUnitFlag.value,
            "secondaryUnit": this.ProductForm.controls.secondaryUnit.value,
            "conversionRate": this.ProductForm.controls.conversionRate.value,
            "stock": this.ProductForm.controls.openingStock.value,
            //"asOfDate": this.ProductForm.controls.asOfDate.value,
            "asOfDate": moment(this.ProductForm.controls.asOfDate.value, "DD MMM YYYY").format("YYYY-MM-DD"),
            "lowStockWarning": this.ProductForm.controls.lowStockWarning.value,
            "lowStockUnits": this.ProductForm.controls.lowStockUnit.value,
            //"expiryDate": this.ProductForm.controls.expiry.value,
            "expiryDate": moment(this.ProductForm.controls.expiry.value, "DD MMM YYYY").format("YYYY-MM-DD"),
            "additionalCess": this.ProductForm.controls.additionalCess.value,
            "supportType": this.ProductForm.controls.supportType.value,
            "supportDuration": String(String(this.ProductForm.controls.supportDuration.value) + this.warrantyType),
            "categoryId": this.ProductForm.controls.category.value,
            "brandId": this.ProductForm.controls.brand.value.toString(),
            "section": this.ProductForm.controls.section.value,
            "subCategoryId": this.ProductForm.controls.subCategoryId.value.toString(),
            "gender": this.ProductForm.controls.gender.value,
            "color": this.ProductForm.controls.color.value,
            "ageGroup": this.ProductForm.controls.ageGroup.value,
          
            //"coverImage": this.productPicUrl
          },
          "warehouseId": this.warehouseId || this.currentUser.id,
          "productImages":
            this.multipleImage ? this.multipleImage : null,
            "fields": []

        }


        let datatrue = {
          "addProducts": {
            "userId": this.currentUser.id,
            "itemName": this.ProductForm.controls.itemName.value,
            "itemCode": this.ProductForm.controls.itemCode.value,
            "barcode": this.ProductForm.controls.generateBarcode.value,
            "description": this.ProductForm.controls.description.value,
            "hsn": this.ProductForm.controls.hsn.value,
            "salePrice": this.ProductForm.controls.price.value,
            "gstRate": this.ProductForm.controls.gstRate.value,
            "unit": this.unit,
            "secondaryUnitFlag": this.ProductForm.controls.secondaryUnitFlag.value,
            "secondaryUnit": this.ProductForm.controls.secondaryUnit.value,
            "conversionRate": this.ProductForm.controls.conversionRate.value,
            "stock": this.ProductForm.controls.openingStock.value,
            //"asOfDate": this.ProductForm.controls.asOfDate.value,
            "asOfDate": moment(this.ProductForm.controls.asOfDate.value, "DD MMM YYYY").format("YYYY-MM-DD"),
            "lowStockWarning": this.ProductForm.controls.lowStockWarning.value,
            "lowStockUnits": this.ProductForm.controls.lowStockUnit.value,
            //"expiryDate": this.ProductForm.controls.expiry.value,
            "expiryDate": moment(this.ProductForm.controls.expiry.value, "DD MMM YYYY").format("YYYY-MM-DD"),
            "additionalCess": this.ProductForm.controls.additionalCess.value,
            "supportType": this.ProductForm.controls.supportType.value,
            "supportDuration": String(String(this.ProductForm.controls.supportDuration.value) + this.warrantyType),
            "categoryId": this.ProductForm.controls.category.value,
            "coverImage": this.productPicUrl,
            "brandId": this.ProductForm.controls.brand.value.toString(),
            "section": this.ProductForm.controls.section.value,
            "subCategoryId": this.ProductForm.controls.subCategoryId.value.toString(),
            "gender": this.ProductForm.controls.gender.value,
            "color": this.ProductForm.controls.color.value,
            "ageGroup": this.ProductForm.controls.ageGroup.value,
          },
          "warehouseId": this.warehouseId || this.currentUser.id,
          "productImages":
            this.multipleImage ? this.multipleImage : null,
            "fields": []

        }

        if (this.coverDisabled == true) {
          this.productService.postGoods(data).then((res) => {
            if (res) { 
              this.toastService.toastMsg({
              title: "Success",
              content: "Clone Product Added Successfully!!!",
            });
              // this.toastService.openSnackBar("Clone Product added Successfully");
              this.productService.notifyProductAdded();
              this.dialogRef.close();
            }

          }, (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
              // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
              // this.toastService.openErrorSnackBar("Something Went To Wrong");
            }
          })

        }
        else {
          this.productService.postGoods(datatrue).then((res) => {
            if (res) {
              this.toastService.toastMsg({
                title: "Success",
                content: "Clone Product Added Successfully!!!",
              });
              // this.toastService.openSnackBar("Clone Product added Successfully");
              this.productService.notifyProductAdded();
              this.dialogRef.close();
            }

          }, (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
              // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
              // this.toastService.openErrorSnackBar("Something Went To Wrong");
            }
          })
        }
      }
      else {
        let data = {
          "addProducts": {
            "userId": this.currentUser.id,
            "itemName": this.ProductForm.controls.itemName.value,
            "itemCode": this.ProductForm.controls.itemCode.value,
            "barcode": this.ProductForm.controls.generateBarcode.value,
            "description": this.ProductForm.controls.description.value,
            "hsn": this.ProductForm.controls.hsn.value,
            "salePrice": this.ProductForm.controls.price.value,
            "gstRate": this.ProductForm.controls.gstRate.value,
            "unit": this.unit,
            "secondaryUnitFlag": this.ProductForm.controls.secondaryUnitFlag.value,
            "secondaryUnit": this.ProductForm.controls.secondaryUnit.value,
            "conversionRate": this.ProductForm.controls.conversionRate.value,
            "stock": this.ProductForm.controls.openingStock.value,
            //"asOfDate": this.ProductForm.controls.asOfDate.value,
            "asOfDate": moment(this.ProductForm.controls.asOfDate.value, "DD MMM YYYY").format("YYYY-MM-DD"),
            "lowStockWarning": this.ProductForm.controls.lowStockWarning.value,
            "lowStockUnits": this.ProductForm.controls.lowStockUnit.value,
            //"expiryDate": this.ProductForm.controls.expiry.value,
            //"expiryDate":moment(this.ProductForm.controls.expiry.value,"DD MMM YYYY").format("YYYY-MM-DD"),
            "additionalCess": this.ProductForm.controls.additionalCess.value,
            "supportType": this.ProductForm.controls.supportType.value,
            "supportDuration": String(String(this.ProductForm.controls.supportDuration.value) + this.warrantyType),
            "categoryId": this.ProductForm.controls.category.value,
            "brandId": this.ProductForm.controls.brand.value ? this.ProductForm.controls.brand.value.toString():null,
            "section": this.ProductForm.controls.section.value,
            "subCategoryId":  this.ProductForm.controls.subCategoryId.value ?this.ProductForm.controls.subCategoryId.value.toString():null,
            "gender": this.ProductForm.controls.gender.value,
            "color": this.ProductForm.controls.color.value,
            "ageGroup": this.ProductForm.controls.ageGroup.value,
            //"coverImage": this.productPicUrl
          },
          "warehouseId": this.warehouseId || this.currentUser.id,
          "productImages":
            this.multipleImage ? this.multipleImage : null,
            "fields": []

        }


        let datatrue = {
          "addProducts": {
            "userId": this.currentUser.id,
            "itemName": this.ProductForm.controls.itemName.value,
            "itemCode": this.ProductForm.controls.itemCode.value,
            "barcode": this.ProductForm.controls.generateBarcode.value,
            "description": this.ProductForm.controls.description.value,
            "hsn": this.ProductForm.controls.hsn.value,
            "salePrice": this.ProductForm.controls.price.value,
            "gstRate": this.ProductForm.controls.gstRate.value,
            "unit": this.unit,
            "secondaryUnitFlag": this.ProductForm.controls.secondaryUnitFlag.value,
            "secondaryUnit": this.ProductForm.controls.secondaryUnit.value,
            "conversionRate": this.ProductForm.controls.conversionRate.value,
            "stock": this.ProductForm.controls.openingStock.value,
            //"asOfDate": this.ProductForm.controls.asOfDate.value,
            "asOfDate": moment(this.ProductForm.controls.asOfDate.value, "DD MMM YYYY").format("YYYY-MM-DD"),
            "lowStockWarning": this.ProductForm.controls.lowStockWarning.value,
            "lowStockUnits": this.ProductForm.controls.lowStockUnit.value,
            //"expiryDate": this.ProductForm.controls.expiry.value,
            //"expiryDate":moment(this.ProductForm.controls.expiry.value,"DD MMM YYYY").format("YYYY-MM-DD"),
            "additionalCess": this.ProductForm.controls.additionalCess.value,
            "supportType": this.ProductForm.controls.supportType.value,
            "supportDuration": String(String(this.ProductForm.controls.supportDuration.value) + this.warrantyType),
            "categoryId": this.ProductForm.controls.category.value,
            "coverImage": this.productPicUrl,
            "brandId": this.ProductForm.controls.brand.value ?this.ProductForm.controls.brand.value.toString():null,
            "section": this.ProductForm.controls.section.value,
            "subCategoryId":this.ProductForm.controls.subCategoryId.value ?this.ProductForm.controls.subCategoryId.value.toString():null,
            "gender": this.ProductForm.controls.gender.value,
            "color": this.ProductForm.controls.color.value,
            "ageGroup": this.ProductForm.controls.ageGroup.value,
          },
          "warehouseId": this.warehouseId || this.currentUser.id,
          "productImages":
            this.multipleImage ? this.multipleImage : null,
            "fields": []

        }

        if (this.coverDisabled == true) {
          this.productService.postGoods(data).then((res) => {
            if (res) {
              this.toastService.toastMsg({
                title: "Success",
                content: "Clone Product Added Successfully!!!",
              });
              // this.toastService.openSnackBar("Clone Product added Successfully");
              this.productService.notifyProductAdded();
              this.dialogRef.close();
            }

          }, (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
              // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
              // this.toastService.openErrorSnackBar("Something Went To Wrong");
            }
          })

        }
        else {
          this.productService.postGoods(datatrue).then((res) => {
            if (res) {
              this.toastService.toastMsg({
                title: "Success",
                content: "Clone Product Added Successfully!!!",
              });
              // this.toastService.openSnackBar("Clone Product added Successfully");
              this.productService.notifyProductAdded();
              this.dialogRef.close();
            }

          }, (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
              // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
              // this.toastService.openErrorSnackBar("Something Went To Wrong");
            }
          })
        }

      }
    }



    // }, (err) => {
    //   this.toastService.openErrorSnackBar("Try Again")
    // })
  }
  GoToproductList() {
    this.router.navigate(["/pages/new-product"]);
    this.dialogRef.close();
  }


  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }
  handleInputChange(e, imgNumber?: any) {
    this.imgNumber = imgNumber;

    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];

    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.ProductForm.patchValue({
          file: reader.result,
        });
        if (file == null) {
          // this.toastService.openErrorSnackBar(
          //   "Please Select Image To Upload.",
          // );
          this.toastService.toastMsg({
            title: "Error",
            content: "Please Select Image To Upload",
          });
        } else {
          if (this.imgNumber == 1) {
            //this.imageSrcOne = reader.result;
            formdata.set("uploadProductImage", file)
          } else if (this.imgNumber == 2) {
            //this.imageSrcTwo = reader.result;
            formdata.set("uploadProductImage", file)
          } else if (this.imgNumber == 3) {
            //this.imageSrcThree = reader.result;
            formdata.set("uploadProductImage", file)
          } else if (this.imgNumber == 4) {
            // this.imageSrcFour = reader.result;
            formdata.set("uploadProductImage", file)
          } else if (this.imgNumber == 5) {
            //this.imageSrcFive = reader.result;
            formdata.set("uploadProductImage", file)
          } else if (this.imgNumber == 6) {
            // this.imageSrcSix = reader.result;
            formdata.set("uploadProductImage", file)
          } else if (this.imgNumber == 7) {
            //    this.imageSrcSeven = reader.result;
            formdata.set("uploadProductImage", file)
          }
          this.productService.postProductImage(formdata, this.productId).then((res) => {
            if (res) {
              this.getProductImage();
            }
          });
        }
      }
    };
  }

  getProductImage() {
    this.productService.getProductImage({}, this.productId).then((res) => {
      let productImage = res.data;
      if (productImage) {
        for (let i = 0; i <= productImage.length; i++) {
          if (productImage[0] && i == 0) {
            this.imageSrcOne = productImage[0].productImage;
          }
          if (productImage[1] && i == 1) {
            this.imageSrcTwo = productImage[1].productImage;
          }
          if (productImage[2] && i == 2) {
            this.imageSrcThree = productImage[2].productImage;
          }
          if (productImage[3] && i == 3) {
            this.imageSrcFour = productImage[3].productImage;
          }
          if (productImage[4] && i == 4) {
            this.imageSrcFive = productImage[4].productImage;
          }
          if (productImage[5] && i == 5) {
            this.imageSrcSix = productImage[5].productImage;
          }
        }
      }
    })
  }

  handleCoverDragEnter() {
    this.dragging = true;
  }
  handleCoverDragLeave() {
    this.dragging = false;
  }
  handleCoverImageLoad() {
    this.imageLoaded = true;
  }
  handleCoverDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.coverImgChange(e);
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  numberDecimalOnly(event): boolean {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
  // coverImgChange(e, imgNumber?: any) {
  //   this.imgNumber = imgNumber;
  //   var pattern = /image-*/;
  //   let formdata = new FormData();
  //   let reader = new FileReader();
  //   let file = e.target.files[0];


  //   if (!file.type.match(pattern)) {
  //     alert('invalid format');
  //     return;
  //   }
  //   this.loaded = false;
  //   if (e.target.files && e.target.files[0]) {
  //     reader.readAsDataURL(file);

  //     reader.onload = () => {
  //       this.ProductForm.patchValue({
  //         file: reader.result,
  //       });

  //       if (file == null) {
  //         this.toastService.openErrorSnackBar(
  //           "Please Select Image To Upload.",
  //         );
  //       } else {
  //         this.imageSrcSeven = reader.result;
  //         formdata.set("uploadProductImage", file)
  //         this.productService.postCoverImage(formdata, this.productId).then((res) => {
  //         }
  //         );
  //       }
  //     }
  //   }
  // }

  coverImgChange(e, imgNumber?: any) {
    this.imgNumber = imgNumber;
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];


    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    const fileSizeLimit = 1 * 1024 * 1024; // 6 MB
    if (file.size > fileSizeLimit) {
      alert("File size exceeds the limit. Please select a smaller image (up to 1 MB).");
      return;
    }
    else {
      this.loaded = false;
      if (e.target.files && e.target.files[0]) {
        reader.readAsDataURL(file);

        reader.onload = () => {
          this.ProductForm.patchValue({
            file: reader.result,
          });

          if (file == null) {
            // this.toastService.openErrorSnackBar(
            //   "Please Select Image To Upload.",
            // );
            this.toastService.toastMsg({
              title: "Error",
              content: "Please Select Image To Upload",
            });
          } else {

            this.imageSrcSeven = reader.result;

            formdata.set("upload", file)
            e.srcElement.value = "";
            this.productService.postCoverImage1(formdata).then((res) => {
              console.log("*****", res);
              this.productPicUrl = res.data.locations[0];
              this.coverDisabled = false;


            });
          }
        }
      }
    }

  }


  onFileSelected(event) {
    const maxFileSize = 1024 * 1024; // 1 MB
    const selectedFiles = event.target.files;
    if (this.maxFiles == 5) {
      this.toastService.toastMsg({
        title: "Error",
        content: "You Have All Ready Select 5 Images.",
      });
      // this.toastService.openErrorSnackBar(`You have all ready select 5 images.`);
    }
    else {
      if (selectedFiles.length > (this.Filesmax - this.maxFiles)) {
        // Handle too many files error
        console.error("You can only select up to 5 files.");
        this.toastService.toastMsg({
          title: "Error",
          content: "You Can Only Select Up To ${this.Filesmax - this.maxFiles} Images.",
        });
        // this.toastService.openErrorSnackBar(`You can only select up to ${this.Filesmax - this.maxFiles} images.`);
        return;
      }

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        console.log("file", file.size)
        if (file.size > maxFileSize) {
          // Handle file size too large error
          //console.error(`File ${file.name} exceeds the maximum size of 1 MB.`);
          // this.toastService.openErrorSnackBar(`File ${file.name} exceeds the maximum size of 1 MB.`);
          this.toastService.toastMsg({
            title: "Error",
            content: "File ${file.name} Exceeds The Maximum Size Of 1 MB.",
          });
          return;
        }
      }

      // Continue with file processing
      const frmData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };
        reader.readAsDataURL(selectedFiles[i]);
        frmData.append("upload", selectedFiles[i]);
      }
      this.maxFiles = this.maxFiles + selectedFiles.length;
      // console.log("this.maxFiles",this.maxFiles)
      console.log(this.previews);
      this.uploadMultiplefile(frmData);
    }


  }
  uploadMultiplefile(frmData: FormData) {
    this.productService.postCoverImage1(frmData).then((res) => {
      if (res) {
        if (this.multipleImage.length == 0) {
          this.multipleImage = res.data.locations;;
          console.log("1 st Multiple", this.multipleImage)
        }
        else {
          this.multipleImage1 = res.data.locations;

          for (var i = 0; i < this.multipleImage1.length; i++) {
            this.multipleImage.push(this.multipleImage1[i]);
          }
          console.log("length Multiple", this.multipleImage.length)
          console.log("again Multiple", this.multipleImage)

        }
        this.toastService.toastMsg({
          title: "Success",
          content: "Product Images Added Successfully!!!",
        });
        // this.toastService.openSnackBar("Product Images added Successfully!!!");
        //console.log("before",this.multipleImage);

      }

    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went To Wrong");
      }
    });

  }

  cancelmultiImage(i: number) {
    //var index = this.previews.indexOf(i);

    this.previews.splice(i, 1); // Removes one element, starting from index
    this.maxFiles = this.maxFiles - 1; // Decrement maxFiles by 1
    console.log("cancelmultiImage, this.maxFiles", this.maxFiles)
    //console.log("before1",this.multipleImage);
    //var index1 = this.multipleImage.indexOf(removeItem);

    this.multipleImage.splice(i, 1);
    // console.log("after",this.multipleImage);
  }



  cancelCoverImage() {

    this.imageSrcSeven = '';
    this.productPicUrl = '';
    this.imageLoaded = false;

  }









  complete() {
    // this.stepper.selected.completed = true;
    // this.stepper.selected.editable = false;
    //this.stepper.next();
  }

  next() {
    // this.stepper.next()
  }
  openDatepicker() {
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.ProductForm.get('asOfDate').value;
    if (isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.ProductForm.get('asOfDate').setValue(new Date());
    }

  }
  openDatepickerexpiry() {
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate2 = this.ProductForm.get('expiry').value;
    if (isNaN(selectedDate2.getTime())) {
      // Set a default date or any valid date here
      this.ProductForm.get('expiry').setValue(new Date());
    }
  }
  autoGenerate() {
    this.productService.autoGenerateBarcode({}, this.currentUser.id).then((codeData) => {
      if (codeData) {
        this.ProductForm.patchValue({
          generateBarcode: codeData.data
        })
      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
      }
    })
  }
}
