<div class="content">
    <h2>Add New Schedule</h2>
    <div class="progress" style="height:2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>
    <form [formGroup]="scheduleForm">
        <div class="row mt-3">
            <div class="col-md-4">
                <div class="col-12 mb-2">
                    <b>Holiday</b>
                </div>
            </div>
        </div>
        <div class="row d-flex mb-1 mt-3">
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <mat-button-toggle-group name="ingredients" aria-label="Ingredients" multiple>
                    <mat-button-toggle (click)='getHoliday("0")'>S</mat-button-toggle>
                    <mat-button-toggle (click)='getHoliday("1")'>M</mat-button-toggle>
                    <mat-button-toggle (click)='getHoliday("2")'>T</mat-button-toggle>
                    <mat-button-toggle (click)='getHoliday("3")'>W</mat-button-toggle>
                    <mat-button-toggle (click)='getHoliday("4")'>T</mat-button-toggle>
                    <mat-button-toggle (click)='getHoliday("5")'>F</mat-button-toggle>
                    <mat-button-toggle (click)='getHoliday("6")'>S</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
        <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" [checked]="isFillBreakSlots" id="flexSwitchCheckDefault"
                (change)="toggleBreakSlots($event.target.checked)" />
            <label class="lab text-nowrap p-1"> Fill Break Slots </label>
        </div>
        <div class="row">
            <div class="col-6">
                <mat-form-field style="font-size: 12px" class="example-full-width " appearance="outline">
                    <mat-label>Appointment Type</mat-label>
                    <mat-select formControlName="appointmentType"  required [ngClass]="{
                        'is-invalid':
                          f.appointmentType.invalid && f.appointmentType.touched && submitted
                      }">
                        <mat-option *ngFor="let appointmentList of appointmentData" [value]="appointmentList.id">
                            {{appointmentList.name | titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger mb-1" *ngIf="
                (submitted || f.appointmentType.touched) &&
                f.appointmentType.errors?.required
              ">
              Appointment Type Is Required!
            </mat-error>
            </div>
            <!-- <div class="col-6">
                <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
                    <mat-label>Shop Name</mat-label>
                    <input matInput placeholder="Shop Name" formControlName="shopName">
                </mat-form-field>
            </div> -->
        </div>
        <div class="row">
            <div class="col-12 mb-2">
                <b>Shop Timing</b>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Start Time</mat-label>
                    <input type="time" matInput placeholder="Start Time" formControlName="start_time"
                    [ngClass]="{
                        'is-invalid':
                          f.start_time.invalid && f.start_time.touched && submitted
                      }">
                </mat-form-field>
                <mat-error class="text-danger mb-1" *ngIf="
                (submitted || f.start_time.touched) &&
                f.start_time.errors?.required
              ">
              Shop Start Timing Is Required!
            </mat-error>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
                    <mat-label>End Time</mat-label>
                    <input type="time" matInput placeholder="End Time" formControlName="end_time"
                    [ngClass]="{
                        'is-invalid':
                          f.end_time.invalid && f.end_time.touched && submitted
                      }">
                </mat-form-field>
                <mat-error class="text-danger mb-1" *ngIf="
                (submitted || f.end_time.touched) &&
                f.end_time.errors?.required
              ">
              Shop End Timing Is Required!
            </mat-error>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-2">
                <b> Break Timing</b>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
                    <mat-label>Start Time</mat-label>
                    <input type="time" matInput placeholder="Start Time" formControlName="break_start_time"
                    [ngClass]="{
                        'is-invalid':
                          f.break_start_time.invalid && f.break_start_time.touched && submitted
                      }">
                </mat-form-field>
                <mat-error class="text-danger mb-1" *ngIf="
                (submitted || f.break_start_time.touched) &&
                f.break_start_time.errors?.required
              ">
              Break  Start Timing Is Required!
            </mat-error>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
                    <mat-label>End Time</mat-label>
                    <input type="time" matInput placeholder="End Time" formControlName="break_end_time"
                    [ngClass]="{
                        'is-invalid':
                          f.break_end_time.invalid && f.break_end_time.touched && submitted
                      }" >
                </mat-form-field>
                <mat-error class="text-danger mb-1" *ngIf="
                (submitted || f.break_end_time.touched) &&
                f.break_end_time.errors?.required
              ">
              Break End Timing Is Required!
            </mat-error>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="col-12 mb-2">
                    <b>Slot Duration (in Minutes)</b>
                </div>
                <div>
                    <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
                        <input type="number" matInput placeholder="Slot Duration" formControlName="slotDuration"
                        min="0" oninput="this.value = Math.abs(this.value)"
                        [ngClass]="{
                            'is-invalid':
                              f.slotDuration.invalid && f.slotDuration.touched && submitted
                          }">
                    </mat-form-field>
                    <mat-error class="text-danger mb-1" *ngIf="
                    (submitted || f.slotDuration.touched) &&
                    f.slotDuration.errors?.required
                  ">
                 Slot Duration Is Required!
                </mat-error>
                </div>
            </div>
            <div class="col-md-4">
                <div class="col-12 mb-2">
                    <b>Capacity (in Number)</b>
                </div>
                <div>
                    <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
                        <input type="number" matInput placeholder="Capacity" formControlName="capacity"
                        min="0" oninput="this.value = Math.abs(this.value)"
                        [ngClass]="{
                            'is-invalid':
                              f.capacity.invalid && f.capacity.touched && submitted
                          }">
                    </mat-form-field>
                    <mat-error class="text-danger mb-1" *ngIf="
                    (submitted || f.capacity.touched) &&
                    f.capacity.errors?.required
                  ">
                  Capacity Duration Is Required!
                </mat-error>
                </div>
            </div>
            <div class="col-md-4">
                <div class="col-12  mb-2">
                    <b>Buffer Time (in Minutes)</b>
                </div>
                <div>
                    <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
                        <input type="number" matInput placeholder="Buffer Time" formControlName="bufferTime"
                        min="0" oninput="this.value = Math.abs(this.value)"
                        [ngClass]="{
                            'is-invalid':
                              f.bufferTime.invalid && f.bufferTime.touched && submitted
                          }" >
                    </mat-form-field>
                    <mat-error class="text-danger mb-1" *ngIf="
                    (submitted || f.bufferTime.touched) &&
                    f.bufferTime.errors?.required
                  ">
                  Buffer Time Duration Is Required!
                </mat-error>
                </div>
            </div>
        </div>
        <div class="col-12 d-flex  justify-content-end">
            <button class="btn btn-outline-primary" (click)="postTimeSlots()" type="button">
                Submit
            </button>
        </div>
        <!-- <div>
            <button class="btn btn-primary" (click)="postTimeSlots()">Submit</button>
        </div> -->
    </form>
    <div class="row" *ngIf="isConfirmBtn">
        <div class="col-12">
            <div class="row" *ngFor="let day of days">
                <div class="col-2 m-auto text-center">
                    {{ day }}
                </div>
                <div class="col-10">
                    <div class="cards-wrapper" *ngIf="!dayWiseSlots[day].dayDetails.holiday">
                        <div class="card" *ngFor="let i of dayWiseSlots[day].slotDetails">
                            Start Slot - {{i.startSlot}}<br>
                            End Slot - {{i.endSlot}} <br>
                            Capacity - {{i.capacity}}<br>
                            <!-- <h4>Slot No - {{i.slotNo}}</h4> -->
                        </div>
                    </div>
                    <div class="cards-wrapper" *ngIf="dayWiseSlots[day].dayDetails.holiday">
                        <div class="card">
                            <h4>Holiday</h4>
                        </div>
                    </div>
                </div>
                <hr class="mt-2">
            </div>
        </div>
    </div>
    <div class="justify-content-end">
        <button class="btn btn-outline-primary" (click)="postCreateSchedule()" *ngIf="isConfirmBtn" type="button">
            Confirm
        </button>
    </div>

</div>