<div class="component-container container-fluid p-4">
  <div id="navPanel" class="row">
    <div class="navOptions col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12">
      <div class="row page-nav-link mt-3">
        <div class="col-xxl-1 col-xl-1 col-lg-2 col-md-3 col-sm-6 links">
          <a class="mt-3 pb-1 ps-1 text-nowrap" routerLink="./party-list" routerLinkActive="active">Vendors</a>
        </div>
        <div class="col-xxl-1 col-xl-1 col-lg-2 col-md-3 col-sm-6 links">
          <a class="mt-3 pb-1 ps-1 text-nowrap" routerLink="./customer-list" routerLinkActive="active">Customers</a>
        </div>
        <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-sm-6 links">
          <a class="mt-3 pb-1 ps-1 text-nowrap" routerLink="./party-group-list" routerLinkActive="active">Vendor
            Group List</a>
        </div>
        <div class="col-lg-6 "></div>
      </div>
    </div>
    <!-- <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12">
      <div class="row navOptions">
        <div class="col-lg-5 col-md-12 col-sm-12 containerSearchBar mt-2" *ngIf="partyListPage  && !partyGrpListPage">
          <input type="search" id="searchInput" class="form-control pe-5" placeholder="Search by Party Name"
          [(ngModel)]="searchKey" (ngModelChange)="getPartyBySearch(searchKey)">
          <button class="searh-btn" type="submit" (click)="getPartyBySearch(searchKey)"><i class="fa fa-search iconcalender p-1"></i></button>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 containerSearchBar mt-2" *ngIf="partyGrpListPage && !partyListPage">
          <input type="search" id="searchInput" class="form-control pe-5" placeholder="Search by Group Name"
          [(ngModel)]="searchGroupName" (ngModelChange)="searchByGroupName()">
          <button class="searh-btn" type="submit" (click)="searchByGroupName()"><i class="fa fa-search iconcalender p-1"></i></button>
        </div>
    
        <div class="navOption-btns mt-2 col-lg-7 col-md-12 col-sm-12" *ngIf="partyListPage  && !partyGrpListPage">
          <button class="btn" ngbTooltip="Add New Party" (click)="openDialog()"><i class="fas fa-plus"></i> Add Parties</button>
          <input type="file" name="file" id="uploadFile" accept=".xls,.xlsx" class="hidden-input"
                (change)="handleInputChange($event)" />      
          <button class="btn" ngbTooltip="Import Excel" id="uploadFile" (click)="startUpload()">
            <i class="fas fa-upload"></i> Import
          </button>
          <button class="btn" ngbTooltip="Download" accept=".xls,.xlsx"  id="download"
          onclick="location.href = 'https://web.iceipts.com/backend/inventory/parties/downloadParties/';"><i class="fas fa-download"></i></button>
        </div>
        
        <div class="navOption-btns mt-2 col-lg-7 col-md-12 col-sm-12" *ngIf="partyGrpListPage && !partyListPage">
          <button class="btn" ngbTooltip="Add New Group" (click)="openDialogAddGrp()"><i class="fas fa-plus"></i> Add Group</button>
          <input type="file" name="file" id="uploadFile" accept=".xls,.xlsx" class="hidden-input"
          (change)="handleInputChange($event)" />      
          <button class="btn" ngbTooltip="Import Excel" id="uploadFile" (click)="startUpload()">
            <i class="fas fa-upload"></i> Import
          </button>
          <button class="btn" ngbTooltip="Download" accept=".xls,.xlsx" id="download"
          onclick="location.href = 'https://web.iceipts.com/backend/inventory/parties/downloadParties/';"><i class="fas fa-download"></i></button>
        </div>
    </div>
  </div> -->
  </div>
  <hr class="mt-1">
  <div>
    <router-outlet></router-outlet>
  </div>
</div>

<!-- Add Party Modal component -->
<ng-template #modalContent>
  <div>
    <app-add-party></app-add-party>
  </div>
</ng-template>