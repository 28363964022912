import { CurrencyPipe, TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/core/services/common.service';
import { CustomerProfileService } from 'src/app/core/services/customer-profile.service';
import { RemoteShopService } from 'src/app/core/services/remote-shop.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';
import { environment } from 'src/environments/environment';
import * as uuid from 'uuid';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  public customerName;
  public mobile;
  public billingAddress;
  public productArray = [];
  public quantity: any;
  public totalBillAmount;
  public payUurl = Constants.url;
  public billGstAmount;
  public submitted: boolean;
  public subTotal;
  public submitObj: any;
  public showForm: boolean = true;
  public clientId: any;
  public formHideContinue: boolean = false;
  public firstName: any;
  public address: any;
  public count:any;
  public remoteProductData = [];
  public remoteGSTAmount: any;
  public remoteTotalBillAmount: any; 
  public orderId: any;
  public coverPic:any;
  public checkoutForm: FormGroup = this.formBuilder.group({
    customerName: ["", [Validators.required]],
    mobile: ["", [Validators.required]],
    shippingAddress: [""],
    billingAddress: ["", [Validators.required]],
  })

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private commonService: CommonService,
    private remoteShopService: RemoteShopService,
    private toastNotificationService: ToastNotificationService,
    public subscriptionService: SubscriptionService,
    private cp: CurrencyPipe,
    private spinner: NgxSpinnerService,
    private toastService: ToastNotificationService,
    private profileService: CustomerProfileService,
    private titleCasePipe: TitleCasePipe) { }

  ngOnInit(): void {
    this.getCustomerCartItems();
    this.getCustomerDetails();
    this.getCartCount();
  }

  getCustomerCartItems() {
    this.spinner.show();
    this.remoteShopService.getCustomerCartItems({}, this.currentUser.id)
      .then((res) => {
        this.spinner.hide();
        this.productArray = res.data.products;
        this.clientId = res.data.invoiceDetails.clientId;
        this.billGstAmount = res.data.invoiceDetails.billGstAmount;
        this.totalBillAmount = res.data.invoiceDetails.totalBillAmount;
        this.subTotal = this.totalBillAmount - this.billGstAmount
      }, (err) => {
        if (err.error.status === 500) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
          // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        // this.toastNotificationService.openErrorSnackBar("No items found...")
      })
  }

  get f() {
    return this.checkoutForm.controls;
  }

  postRemoteOrder() {
    this.submitted = true;
    console.log(this.checkoutForm.controls)
    if (this.checkoutForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
        });
      // this.toastNotificationService.openErrorSnackBar("Please Fill Mandatory Fields!!!")
    }
    else {
      let cartArray = []
      this.formHideContinue = true;
      this.productArray.forEach((element) => {
        let array = {
          quantity: element.quantity,
          // type: "GOODS",
          barcode: element.barcode
        }
        cartArray.push(array)
      })
      let data = {
        "orderDetails": {
          "orderDate": this.commonService.getTodaysDate(),
          "billingAddress": this.checkoutForm.controls.billingAddress.value,
          "shippingAddress": this.checkoutForm.controls.billingAddress.value,
          "mobile": this.checkoutForm.controls.mobile.value,
          "customerName": this.checkoutForm.controls.customerName.value,
          "customerId": this.currentUser.id,
          "clientId": this.clientId,
        },
        "products": cartArray
      }
      this.remoteShopService.postRemoteOrder(data).then((res) => {
        if (res) {
          this.showForm = false;
          this.orderId = res.data.orderId
          this.getInvoiceByOrderId(this.orderId)
          this.setPaymentFormData(this.totalBillAmount.toString());
        }
        // this.router.navigate(["/pages/customer/checkout"]);
      },(err)=>{
        if(err.error.status === 500){
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
          // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
      })
    }
  }

  async setPaymentFormData(totalBillAmount) {

    let obj = {
      key: "oZ7oo9",
      txnId: uuid.v4(),
      productinfo: "shopping",
      amount: totalBillAmount,
      surl: environment.webUrl + "#/pages/customer/remote-customer",
      furl: environment.webUrl + "#/pages/customer/remote-customer",
      phone: "1234567890",
      hash: "",
      udf1: "",
      udf2: "",
      udf3: "",
      udf4: "",
      udf5: "",
      email: "abc@gmail.com",
      firstName: Constants.currentUser.firstName,
      lastName: Constants.currentUser.lastName
    }
    this.submitObj = obj;
    await this.setHash();
  }

  /**
   * Set Hash code
   */
  setHash() {
    this.subscriptionService.getPaymentHash(this.submitObj, "").then((res) => {
      this.submitObj.hash = res
    });
  }

  getCustomerDetails() {
    this.profileService.getCustomerProfileByUserId({},this.currentUser.id).then((res => {
      this.checkoutForm.patchValue({
        customerName: res.data.basicDetails.fullName,
        mobile: res.data.basicDetails.mobile,
        billingAddress:
        Object.values(res.data.primaryAddress).reduce((acc:any,next:any)=> {
          next ? 
          acc =  acc + ' ' + next : ''
          return acc;
          },''),
      //   (res.data.primaryAddress.homeno + ", " 
      //                   + res.data.primaryAddress.address + ", "
      //                   + res.data.primaryAddress.district + ", " 
      //                   + res.data.primaryAddress.city + ", " 
      //                   + res.data.primaryAddress.pincode) 
      //                   ? 
      //                   (res.data.primaryAddress.homeno + ", " 
      //                   + res.data.primaryAddress.address + ", "
      //                   + res.data.primaryAddress.district + ", " 
      //                   + res.data.primaryAddress.city + ", " 
      //                   + res.data.primaryAddress.pincode) : ""
      })
      this.firstName = res.data.basicDetails.fullName;
      this.address = (res.data.primaryAddress.homeno + ", " 
      + res.data.primaryAddress.address + ", "
      + res.data.primaryAddress.district + ", " 
      + res.data.primaryAddress.city + ", " 
      + res.data.primaryAddress.pincode);
      this.mobile = res.data.basicDetails.mobile;
    }),(err)=>{
      if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }
  /**
   * get user cart count
  */
  getCartCount(){
    this.remoteShopService.getCartCount({},this.currentUser.id).then((res)=>{
      this.count = res.data.count
      // this.commonService.sendData(res.data.count)
    },(err)=>{
      if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }

  getInvoiceByOrderId(orderId){
    this.remoteShopService.getInvoiceByOrderId({},orderId).then((res) => {
      this.remoteProductData = res.data.sales_products;
      this.remoteGSTAmount = res.data.billGstAmount;
      this.remoteTotalBillAmount = res.data.totalBillAmount;
      this.billingAddress = res.data.billingAddress;
      this.customerName = res.data.customerName;
      this.coverPic=res.data.sales_products[0].product.coverImage;
    },(err)=>{
      if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }
}
