<div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
    <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" 
        aria-label="Close"></a>
</div>
<div class="modal-body p-0">
    <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
        <h2 class="mb-1 text-center font-weight-bold" style="font-weight: 800; ">
            Delete Party from Group? </h2>
    </div>
    <h4 class="text-center ">Are you sure to Delete Party from Group?</h4>

</div>
<div class="modal-footer justify-content-center">
    <a class="btn btn-outline-success" (click)="cancel()">Close</a>
    <a class="btn btn-outline-danger" (click)="DeletePartyFromGroup()">Delete
    </a>

</div>
