import { ErrorHandler, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CoreRoutingModule } from "./core-routing.module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { GlobalErrorHandler } from "./error/global-error-handler";
import { AdminGuard } from "./guards/admin.guard";
import { AuthGuard } from "./guards/auth.guard";
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { NgOtpInputModule } from "ng-otp-input";
// import { ToastcompComponent } from './services/toastcomp/toastcomp.component';

@NgModule({
  declarations: [
  ],
  imports: [CommonModule, CoreRoutingModule],
  providers: [
    AuthGuard,
    AdminGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    // { provide: NGXLogger, useClass: NGXLogger },
    { provide: "LOCALSTORAGE", useValue: window.localStorage },
  ],
})
export class CoreModule {}
