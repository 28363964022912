export class Constants {
  public static USER = {
    CLIENT: 1,
    CUSTOMER: 2,
    CLIENT_LOGIN: 3,
    CUSTOMER_LOGIN: 4,
    GOODS: 5,
    SERVICE: 6,
  }
  public static images = {
    defaultShopImg: "../../../../../assets/images/960x0.jpg",
    defaultProductImg:"../../../assets/img/products/7.jpg",
    defaultLogoImage: "../../../assets/images/71b3e4159892bb319292ab3b76900930.jpg",
    defaultScheduleImage:"../../../assets/images/Schedule.jpg"
  }
  public static defaultSliderImages = [
    "../../../assets/images/oppo-9svwLNfE2Ts-unsplash.jpg",
    "../../../assets/images/wasa-crispbread-LBl3Csr96YI-unsplash.jpg",
    "../../../assets/images/lola-rose-3_qkCtmsEMk-unsplash.jpg",
    "../../../assets/images/wasa-crispbread-OkEWBR1g-2c-unsplash.jpg"
  ]
  public static defaultGalleryImages = [
    "../../../assets/images/galleryImg1.jpg",
    "../../../assets/images/galleryImg2.jpg",
    "../../../assets/images/galleryImg3.jpg",
    "../../../assets/images/galleryImg4.jpg",
    "../../../assets/images/galleryImg5.jpg",
    "../../../assets/images/galleryImg6.jpg",
    "../../../assets/images/galleryImg7.jpg",
    "../../../assets/images/galleryImg8.jpg"
  ]
public static Units = [
    "BAGS(BAG)",
    "BALE(BAL)",
    "BUNDLES(BDL)",
    "BUCKLES(BKL)",
    "BILLIONS OF UNITS(BOU)",
    "BOX(BOX)",
    "BOTTLES(BTL)",
    "BUNCHES(BUN)",
    "CANS(CAN)",
    "CUBIC METER(CBM)",
    "CUBIC CENTIMETER(CCM)",
    "CENTIMETER(CMS)",
    "CARTONS(CTN)",
    "DOZEN(DOZ)",
    "DRUM(DRM)",
    "GREAT GROSS(GGR)",
    "GRAMS(GMS)",
    "GROSS(GRS)",
    "GROSS YARDS(GYD)",
    "KILOGRAMS(KGS)",
    "KILOLITER(KLR)",
    "KILOMETRE(KME)",
    "MILLILITRE(MLT)",
    "METERS(MTR)",
    "METRIC TONS(MTS)",
    "NUMBERS(NOS)",
    "PACKS(PAC)",
    "PIECES(PCS)",
    "PAIRS(PRS)",
    "QUINTAL(QTL)",
    "ROLLS(ROL)",
    "SETS(SET)",
    "SQUARE FEET(SQF)",
    "SQUARE METERS(SQM)",
    "SQUARE YARDS(SQY)",
    "TABLETS(TBS)",
    "TEN GROSS(TGM)",
    "THOUSANDS(THD)",
    "TONNES(TON)",
    "TUBES(TUB)",
    "US GALLONS(UGS)",
    "UNITS(UNT)",
    "YARDS(YDS)",
  ]  
  public static UnitFS=[
    {
      "id":1,
      "fullName":"BAGS(BAG)",
      "shortName":"BAG"
    },
    {
      "id":2,
      "fullName":"BALE(BAL)",
      "shortName":"BAL" 
    },
    {
      "id":3,
      "fullName":"BUNDLES(BDL)",
      "shortName":"BDL"
    },
    {
      "id":4,
      "fullName":"BUCKLES(BKL)",
      "shortName":"BKL"
    },
    {
      "id":5,
      "fullName":"BILLIONS OF UNITS(BOU)",
      "shortName":"BOU"
    },
    {
      "id":6,
      "fullName":"BOX(BOX)",
      "shortName":"BOX"
    },
    {
      "id":7,
      "fullName":"BOTTLES(BTL)",
      "shortName":"BTL"
    },
    {
      "id":8,
      "fullName":"BUNCHES(BUN)",
      "shortName":"BUN"
    },
    {
      "id":9,
      "fullName":"CANS(CAN)",
      "shortName":"CAN"
    },
    {
      "id":10,
      "fullName":"CUBIC METER(CBM)",
      "shortName":"CBM"
    },
    {
      "id":11,
      "fullName":"CUBIC CENTIMETER(CCM)",
      "shortName":"CCM"
    },
    {
      "id":12,
      "fullName":"CENTIMETERS(CMS)",
      "shortName":"CMS"
    },
    {
      "id":13,
      "fullName":"CARTONS(CTN)",
      "shortName":"CTN"
    },
    {
      "id":14,
      "fullName":"DOZEN(DOZ)",
      "shortName":"DOZ"
    },
    {
      "id":15,
      "fullName":"DRUM(DRM)",
      "shortName":"DRM"
    },
    {
      "id":16,
      "fullName":"GREAT GROSS(GGR)",
      "shortName":"GGR"
    },
    {
      "id":17,
      "fullName":"GRAMS(GMS)",
      "shortName":"GMS"
    },
    {
      "id":18,
      "fullName":"GROSS(GRS)",
      "shortName":"GRS"
    },
    {
      "id":19,
      "fullName":"GROSS YARDS(GYD)",
      "shortName":"GYD"
    },
    {
      "id":20,
      "fullName":"KILOGRAMS(KGS)",
      "shortName":"KGS"
    },
    {
      "id":21,
      "fullName":"KILOLITER(KLR)",
      "shortName":"KLR"
    },
    {
      "id":22,
      "fullName":"KILOMETRE(KME)",
      "shortName":"KME"
    },
    {
      "id":23,
      "fullName":"MILLILITRE(MLT)",
      "shortName":"MLT"
    },
    {
      "id":24,
      "fullName":"METERS(MTR)",
      "shortName":"MTR"
    },
    {
      "id":25,
      "fullName":"METRIC TONS(MTS)",
      "shortName":"MTS"
    },
    {
      "id":26,
      "fullName":"NUMBERS(NOS)",
      "shortName":"NOS"
    },
    {
      "id":27,
      "fullName":"PACKS(PAC)",
      "shortName":"PAC"
    },
    {
      "id":28,
      "fullName":"PIECES(PCS)",
      "shortName":"PCS"
    },
    {
      "id":29,
      "fullName":"PAIRS(PRS)",
      "shortName":"PRS"
    },
    {
      "id":30,
      "fullName":"QUINTAL(QTL)",
      "shortName":"QTL"
    },
    {
      "id":31,
      "fullName":"ROLLS(ROL)",
      "shortName":"ROL"
    },
    {
      "id":32,
      "fullName":"SETS(SET)",
      "shortName":"SET"
    },
    {
      "id":33,
      "fullName":"SQUARE FEET(SQF)",
      "shortName":"SQF"
    },
    {
      "id":34,
      "fullName":"SQUARE METERS(SQM)",
      "shortName":"SQM"
    },
    // {
    //   "id":30,
    //   "fullName":"",
    //   "shortName":"BKL"
    // },
    {
      "id":35,
      "fullName":"SQUARE YARDS(SQY)",
      "shortName":"SQY"
    },
    {
      "id":36,
      "fullName":"TABLETS(TBS)",
      "shortName":"TBS"
    },
    {
      "id":37,
      "fullName":"TEN GROSS(TGM)",
      "shortName":"TGM"
    },
    {
      "id":38,
      "fullName":"THOUSANDS(THD)",
      "shortName":"THD"
    },
    {
      "id":39,
      "fullName":"TONNES(TON)",
      "shortName":"TON"
    },
    {
      "id":40,
      "fullName":"TUBES(TUB)",
      "shortName":"TUB"
    },
    {
      "id":41,
      "fullName":"US GALLONS(UGS)",
      "shortName":"UGS"
    },
    {
      "id":42,
      "fullName":"UNITS(UNT)",
      "shortName":"UNT"
    },
    {
      "id":43,
      "fullName":"YARDS(YDS)",
      "shortName":"YDS"
    }
  ]
  public static bankNames = [
    {
      "id": "1",
      "bankName": "Axis Bank Ltd"
    },
    {
      "id": "2",
      "bankName": "Bandhan Bank Ltd."
    },
    {
      "id": "3",
      "bankName": "CSB Bank Limited"
    },
    {
      "id": "4",
      "bankName": "City Union Bank Ltd"
    },
    {
      "id": "5",
      "bankName": "DCB Bank Ltd."
    },
    {
      "id": "6",
      "bankName": "Dhanlaxmi Bank Ltd."
    },
    {
      "id": "7",
      "bankName": "Federal Bank Ltd."
    },
    {
      "id": "8",
      "bankName": "HDFC Bank"
    },

    {
      "id": "9",
      "bankName": "ICICI Bank Ltd."
    },
    {
      "id": "10",
      "bankName": "IndusInd Bank Ltd"
    },
    {
      "id": "11",
      "bankName": "IDFC FIRST Bank Limited"
    },
    {
      "id": "12",
      "bankName": "Jammu & Kashmir Bank Ltd."
    },
    {
      "id": "13",
      "bankName": "Karnataka Bank Ltd."
    },
    {
      "id": "14",
      "bankName": "Karur Vysya Bank Ltd."
    },
    {
      "id": "15",
      "bankName": "Kotak Mahindra Bank Ltd"
    },
    {
      "id": "16",
      "bankName": "Nainital bank Ltd."
    },
    {
      "id": "17",
      "bankName": "RBL Bank Ltd."
    },
    {
      "id": "18",
      "bankName": "South Indian Bank Ltd."
    },
    {
      "id": "19",
      "bankName": "Tamilnad Mercantile Bank Ltd"
    },
    {
      "id": "20",
      "bankName": "YES Bank Ltd."
    },
    {
      "id": "21",
      "bankName": "IDBI Bank Limited"
    },
    {
      "id": "22",
      "bankName": "Coastal Local Area Bank Ltd"
    },
    {
      "id": "23",
      "bankName": "Krishna Bhima Samruddhi LAB Ltd"
    },
    {
      "id": "24",
      "bankName": "Subhadra Local Bank Ltd"
    },
    {
      "id": "25",
      "bankName": "Au Small Finance Bank Ltd."
    },
    {
      "id": "26",
      "bankName": "Capital Small Finance Bank Ltd"
    },
    {
      "id": "27",
      "bankName": "Fincare Small Finance Bank Ltd"
    },
    {
      "id": "28",
      "bankName": "Equitas Small Finance Bank Ltd"
    },
    {
      "id": "29",
      "bankName": "ESAF Small Finance Bank Ltd."
    },
    {
      "id": "30",
      "bankName": "Suryoday Small Finance Bank Ltd."
    },
    {
      "id": "31",
      "bankName": "Ujjivan Small Finance Bank Ltd."
    },
    {
      "id": "32",
      "bankName": "Utkarsh Small Finance Bank Ltd."
    },
    {
      "id": "33",
      "bankName": "North East Small finance Bank Ltd"
    },
    {
      "id": "34",
      "bankName": "Jana Small Finance Bank Ltd"
    },
    {
      "id": "35",
      "bankName": "Shivalik Small Finance Bank Ltd"
    },
    {
      "id": "36",
      "bankName": "Unity Small Finance Bank Ltd"
    },
    {
      "id": "37",
      "bankName": "Airtel Payments Bank Ltd"
    },
    {
      "id": "38",
      "bankName": "India Post Payments Bank Ltd"
    },
    {
      "id": "39",
      "bankName": "FINO Payments Bank Ltd"
    },
    {
      "id": "40",
      "bankName": "Paytm Payments Bank Ltd"
    },
    {
      "id": "41",
      "bankName": "Jio Payments Bank Ltd"
    },
    {
      "id": "42",
      "bankName": "NSDL Payments Bank Limited"
    },
    {
      "id": "43",
      "bankName": "Bank of Baroda"
    },
    {
      "id": "44",
      "bankName": "Bank of India"
    },
    {
      "id": "45",
      "bankName": "Bank of Maharashtra"
    },
    {
      "id": "46",
      "bankName": "Canara Bank"
    },
    {
      "id": "47",
      "bankName": "Central Bank of India"
    },
    {
      "id": "48",
      "bankName": "Indian Bank"
    },
    {
      "id": "49",
      "bankName": "Indian Overseas Bank"
    },
    {
      "id": "50",
      "bankName": "Punjab & Sind Bank"
    },
    {
      "id": "51",
      "bankName": "Punjab National Bank"
    },
    {
      "id": "52",
      "bankName": "State Bank of India"
    },
    {
      "id": "53",
      "bankName": "UCO Bank"
    },
    {
      "id": "54",
      "bankName": "Union Bank of India"
    },
    {
      "id": "55",
      "bankName": "National Bank for Agriculture and Rural Development"
    },
    {
      "id": "56",
      "bankName": "Export-Import Bank of India"
    },
    {
      "id": "57",
      "bankName": "National Housing Bank"
    },
    {
      "id": "58",
      "bankName": "Small Industries Development Bank of India"
    },
    {
      "id": "59",
      "bankName": "Assam Gramin Vikash Bank"
    },
    {
      "id": "60",
      "bankName": "Andhra Pradesh Grameena Vikas Bank"
    },
    {
      "id": "61",
      "bankName": "Andhra Pragathi Grameena Bank"
    },
    {
      "id": "62",
      "bankName": "Arunachal Pradesh Rural Bank"
    },
    {
      "id": "63",
      "bankName": "Aryavart Bank"
    },
    {
      "id": "64",
      "bankName": "Bangiya Gramin Vikash Bank"
    },
    {
      "id": "65",
      "bankName": "Baroda Gujarat Gramin Bank"
    },
    {
      "id": "66",
      "bankName": "Baroda Rajasthan Kshetriya Gramin Bank"
    },
    {
      "id": "67",
      "bankName": "Baroda UP Bank"
    },
    {
      "id": "68",
      "bankName": "Chaitanya Godavari GB"
    },
    {
      "id": "69",
      "bankName": "Chhattisgarh Rajya Gramin Bank"
    },
    {
      "id": "70",
      "bankName": "Dakshin Bihar Gramin Bank"
    },
    {
      "id": "71",
      "bankName": "Ellaquai Dehati Bank"
    },
    {
      "id": "72",
      "bankName": "Himachal Pradesh Gramin Bank"
    },
    {
      "id": "73",
      "bankName": "J&K Grameen Bank"
    },
    {
      "id": "74",
      "bankName": "Jharkhand Rajya Gramin Bank"
    },
    {
      "id": "75",
      "bankName": "Karnataka Gramin Bank"
    },
    {
      "id": "76",
      "bankName": "Karnataka Vikas Gramin Bank"
    },
    {
      "id": "77",
      "bankName": "Kerala Gramin Bank"
    },
    {
      "id": "78",
      "bankName": "Madhya Pradesh Gramin Bank"
    },
    {
      "id": "79",
      "bankName": "Madhyanchal Gramin Bank"
    },
    {
      "id": "80",
      "bankName": "Maharashtra Gramin Bank"
    },
    {
      "id": "81",
      "bankName": "Manipur Rural Bank"
    },
    {
      "id": "82",
      "bankName": "Meghalaya Rural Bank"
    },
    {
      "id": "83",
      "bankName": "Mizoram Rural Bank"
    },
    {
      "id": "84",
      "bankName": "Nagaland Rural Bank"
    },
    {
      "id": "85",
      "bankName": "Odisha Gramya Bank"
    },
    {
      "id": "86",
      "bankName": "Paschim Banga Gramin Bank"
    },
    {
      "id": "87",
      "bankName": "Prathama U.P. Gramin Bank"
    },
    {
      "id": "88",
      "bankName": "Puduvai Bharathiar Grama Bank"
    },
    {
      "id": "89",
      "bankName": "Punjab Gramin Bank"
    },
    {
      "id": "90",
      "bankName": "Rajasthan Marudhara Gramin Bank"
    },
    {
      "id": "91",
      "bankName": "Saptagiri Grameena Bank"
    },
    {
      "id": "92",
      "bankName": "Sarva Haryana Gramin Bank"
    },
    {
      "id": "93",
      "bankName": "Saurashtra Gramin Bank"
    },
    {
      "id": "94",
      "bankName": "Tamil Nadu Grama Bank"
    },
    {
      "id": "95",
      "bankName": "Telangana Grameena Bank"
    },
    {
      "id": "96",
      "bankName": "Tripura Gramin Bank"
    },
    {
      "id": "97",
      "bankName": "Uttar Bihar Gramin Bank"
    },
    {
      "id": "98",
      "bankName": "Utkal Grameen Bank"
    },
    {
      "id": "99",
      "bankName": "Uttarbanga Kshetriya Gramin Bank"
    },
    {
      "id": "100",
      "bankName": "Vidharbha Konkan Gramin Bank"
    },
    {
      "id": "101",
      "bankName": "Uttarakhand Gramin Bank"
    },
    {
      "id": "102",
      "bankName": "Australia and New Zealand Banking Group Ltd."
    },
    {
      "id": "103",
      "bankName": "National Australia Bank"
    },
    {
      "id": "104",
      "bankName": "Bank of Bahrain & Kuwait BSC"
    },
    {
      "id": "105",
      "bankName": "AB Bank Ltd"
    },
    {
      "id": "106",
      "bankName": "Sonali Bank Ltd"
    },
    {
      "id": "107",
      "bankName": "Bank of Nova Scotia"
    },
    {
      "id": "108",
      "bankName": "Industrial & Commercial Bank of China Ltd"
    },
    {
      "id": "109",
      "bankName": "BNP Paribas"
    },
    {
      "id": "110",
      "bankName": "Credit Agricole Corporate & Investment Bank #"
    },
    {
      "id": "111",
      "bankName": "Societe Generale"
    },
    {
      "id": "112",
      "bankName": "Deutsche Bank"
    },
    {
      "id": "113",
      "bankName": "HSBC Ltd #"
    },
    {
      "id": "114",
      "bankName": "PT Bank Maybank Indonesia TBK"
    },
    {
      "id": "115",
      "bankName": "Mizuho Bank Ltd."
    },
    {
      "id": "116",
      "bankName": "Sumitomo Mitsui Banking Corporation"
    },
    {
      "id": "117",
      "bankName": "MUFG Bank, Ltd."
    },
    {
      "id": "118",
      "bankName": "Cooperatieve Rabobank U.A."
    },
    {
      "id": "119",
      "bankName": "Doha Bank"
    },
    {
      "id": "120",
      "bankName": "Qatar National Bank SAQ"
    },
    {
      "id": "121",
      "bankName": "JSC VTB Bank"
    },
    {
      "id": "122",
      "bankName": "Sberbank"
    },
    {
      "id": "123",
      "bankName": "United Overseas Bank Ltd."
    },
    {
      "id": "124",
      "bankName": "FirstRand Bank Ltd."
    },
    {
      "id": "125",
      "bankName": "Shinhan Bank"
    },
    {
      "id": "126",
      "bankName": "Woori Bank"
    },
    {
      "id": "127",
      "bankName": "KEB Hana Bank"
    },
    {
      "id": "128",
      "bankName": "Industrial Bank of Korea #"
    },
    {
      "id": "129",
      "bankName": "Bank of Ceylon"
    },
    {
      "id": "130",
      "bankName": "Credit Suisse A.G"
    },
    {
      "id": "131",
      "bankName": "CTBC Bank Co., Ltd."
    },
    {
      "id": "132",
      "bankName": "Abu Dhabi Commercial Bank Ltd."
    },
    {
      "id": "133",
      "bankName": "Mashreq Bank PSC"
    },
    {
      "id": "134",
      "bankName": "First Abu Dhabi Bank PJSC"
    },
    {
      "id": "135",
      "bankName": "Emirates NBD Bank PJSC"
    },
    {
      "id": "136",
      "bankName": "Barclays Bank Plc."
    },
    {
      "id": "137",
      "bankName": "Standard Chartered Bank"
    },
    {
      "id": "138",
      "bankName": "NatWest Markets plc"
    },
    {
      "id": "139",
      "bankName": "American Express Banking Corp."
    },
    {
      "id": "140",
      "bankName": "Bank of America"
    },
    {
      "id": "141",
      "bankName": "Citibank N.A."
    },
    {
      "id": "142",
      "bankName": "J.P. Morgan Chase Bank N.A."
    }
  ]

  public static currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public static clientId = JSON.parse(localStorage.getItem("clientId"))
  public static url = "https://test.payu.in/_payment";
  public static warningText = "Are you sure you want to request for bidding got this product?";
  public static chkBoxContactText = "Publish Bid"
}
