import { Injectable } from '@angular/core';
import { AuthGroup } from 'src/app/_helpers/authorization.types';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service'
@Injectable({
  providedIn: 'root'
})

export class AuthorizationService {
  permissions: Array<string>;
  public permissionArray = JSON.parse(localStorage.getItem("Permission"))
  currentUser = JSON.parse(localStorage.getItem("currentUser"))
  userId: string = "";
  constructor(public rolePermissions: RolesPermissionsService) {

  }

  // hasPermission(authGroup:AuthGroup){
  //     if(this.permissions&&this.permissions.find(permission=>{
  //         return permission===authGroup;
  //     })){
  //         return true;
  //     }
  //     return false;
  // }

  //    public  initializePermissions(){
  //         return new Promise<void>((resolve,reject)=>{
  //             this.rolePermissions.getPermissions({},this.CurrentUser.id).then(permissions=>{
  //                 this.permissions=permissions.data.permissionName;
  //                 resolve();
  
  //             }).catch((e)=>{
  //                 reject(e)
  //             });
  //         })
  //     }

  getPermissionById() {

    this.rolePermissions
      .getPermissions({}, this.currentUser.id).
      then((res) => {
        // this.permissionData=res.data;
        let i = 0;
        

        localStorage.setItem("Permission", JSON.stringify({
          data: res.data,
          // id:res.id,
          // permissionName:res.permissionName,
          // create:res.create,
          // modify:res.modify,
          // read:res.read,
          // delete:res.delete

        })

        )

      })
  }
  public initializePermissions(permissionName: string, checkPermissions: String[]) {
    this.getPermissionById();
    var authorized = false

    
    
    this.permissionArray.data.forEach(permission => {
     
      if (permission.permissionName == permissionName) {
        
        checkPermissions.forEach(element => {
                   if (permission[`${element}`] == true) {
            authorized = true;
          } else {
            authorized = false;
          }
        });
      }
    })
    
    return authorized
  }

}

function e(e: any) {
  throw new Error('Function not implemented.');
}
