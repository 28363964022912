<div class="row g-3">
  <div class="col-xl-12">
    <div class="mb-3">
      <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
          Your Shipping Address
        </p>
      </div>
      <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
      </div>
      <!-- User Form -->
      <div class="card">
        <div class="card-body" *ngIf="showForm">
          <form [formGroup]="checkoutForm">
            <div class="row">
              <div class="col-md-12">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Full Name</mat-label>
                  <input type="text" matInput formControlName="customerName" maxLength="50" placeholder="Full Name"
                    minlength="2" [ngClass]="{'is-invalid': f.customerName.invalid && submitted}" required>
                  <mat-error *ngIf="f.customerName.invalid && submitted" class="invalid-feedback">
                    <div *ngIf="f.customerName.errors.required">
                      Name is required!
                    </div>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Mobile Number</mat-label>
                  <input type="text" matInput formControlName="mobile" maxLength="10" minlength="10"
                    placeholder="Full Name" [ngClass]="{'is-invalid': f.mobile.invalid && submitted}" required>
                  <mat-error *ngIf="f.mobile.invalid && submitted" class="invalid-feedback">
                    <div *ngIf="f.mobile.errors.required">
                      Mobile is required!
                    </div>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Address</mat-label>
                  <textarea type="text" matInput formControlName="billingAddress" rows="3" placeholder="Enter Address"
                    [ngClass]="{'is-invalid': f.billingAddress.invalid && submitted}" required></textarea>
                  <mat-error *ngIf="f.billingAddress.invalid && submitted" class="invalid-feedback">
                    <div *ngIf="f.billingAddress.errors.required">
                      Address is required!
                    </div>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </form>
          <!-- <div class="row">
            <div class="col-md-4 mb-3 mb-md-0">
              <div class="form-check mb-0 custom-radio radio-select">
                <input class="form-check-input" id="address-1" type="radio" name="clientName" checked="checked" />
                <label class="form-check-label mb-0 fw-bold d-block" for="address-1">Antony Hopkins<span
                    class="radio-select-content"><span> 2392 Main Avenue,<br />Pensaukee,<br />New Jersey 02139<span
                        class="d-block mb-0 pt-2">+(856) 929-229</span></span></span></label><a class="fs--1"
                  href="#!">Edit</a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="position-relative">
                <div class="form-check mb-0 custom-radio radio-select">
                  <input class="form-check-input" id="address-2" type="radio" name="clientName" />
                  <label class="form-check-label mb-0 fw-bold d-block" for="address-2">Robert Bruce<span
                      class="radio-select-content"><span>3448 Ile De France St #242<br />Fort Wainwright, <br />Alaska,
                        99703<span class="d-block mb-0 pt-2">+(901) 637-734</span></span></span></label><a class="fs--1"
                    href="#!">Edit</a>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <!-- Place Order -->
      <div class="card-body" *ngIf="!showForm" style="padding: 0.25rem 13px">
        <!-- <div class="row justify-content-start card mb-2 p-2">
          <div class="d-flex col-10">
            <div class="avatar avatar-xl">
              <img class="rounded-circle" src="../../assets/img/team/4.jpg" alt="" />
            </div>
            <div class="ms-3 mt-1 text-dark fs-0 fs-lg-1">3Rd Eye Camera store</div>
            <div class="ms-3 mt-1"><b>Rating</b></div>
            <div class="ms-3 mt-1"><span class="badge bg-success">4.2 <span class="fas fa-star mt-1"></span></span>
            </div>
            <div class="ms-3 mt-1 text-danger">
              <span class="material-icons text-success fs-0 ms-1 mt-1">check_circle</span>
            </div>
          </div>
        </div> -->

        <div class="row">
          <div class="overflow-hidden bg-light shadow p-2 mb-2 rounded">
            <div class="row g-0">
              <div class="col-sm-12 col-md-12 col-lg-12 bg-light">
                <div class="row mb-3" *ngFor="let item of remoteProductData">
                  <div class="col-sm-12 col-md-4 col-lg-4 bg-white">
                    <div class="position-relative h-sm-100"><a class="d-block h-100"><img
                          class="img-fluid w-sm-100 h-sm-100 rounded-1 absolute-sm-centered"
                          [src]="coverPic"
                          onerror="this.src='../../../assets/img/products/2.jpg';"
                          alt="" 
                          style = "width: 100% !important; height: 200px !important;"
                          /></a>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-8 col-lg-8 bg-white">
                    <div class="row">
                      <div class="col-lg-12 mt-2">
                        <h5 class="mt-3 mt-sm-0"><a class="text-dark fs-0 fs-lg-1">{{ item.itemName | titlecase }}</a>
                        </h5>
                        <p class="fs--1 mb-2 mb-md-3"><a class="text-500">
                          <strong>Quantity:   </strong>{{ item.quantity }}
                        </a>
                      </p>
                        <p class="fs--1 mb-2 mb-md-3"><a class="text-500">
                            <strong>GST (in Rs.)   </strong>{{ item.gstAmount | currency:'INR':'symbol':'1.2-2'}}
                          </a>
                        </p>
                        <p class="fs--1 mb-2 mb-md-3"><a class="text-500">
                          <strong>Price:   </strong>{{ item.totalPrice | currency:'INR':'symbol':'1.2-2'}}
                        </a>
                      </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row mb-2">
            <div class="col-sm-12 col-md-8 col-lg-8 card">
              <div class="row">
                <div class="col-sm-12 col-md-3 col-lg-3">
                  <div class="position-relative h-sm-100"><a class="d-block h-100"
                      href="../../../app/e-commerce/product/product-details.html"><img
                        class="img-fluid fit-cover w-sm-100 h-sm-100 rounded-1 absolute-sm-centered"
                        src="../../../assets/img/products/2.jpg" alt="" /></a>
                    <div class="badge rounded-pill bg-success position-absolute top-0 end-0 me-2 mt-2 fs--2 z-index-2">
                      New
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-9 col-lg-9 mt-2 ">
                  <div class="row">
                    <div class="col-sm-12 col-md-9 col-lg-9">
                      <h3 class="fw-bold">bidProduct</h3>
                    </div>
                    <div class="col-sm-12 col-md-3 col-lg-3 fw-bold">
                      <mat-icon style="font-size: 12px!important;" class="iconcalender p-1">currency_rupee
                      </mat-icon> bidArrayPrice
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-md-9 col-lg-9">
                      <h3>productDescription</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
            <div class="col-md-4 col-lg-4 col-sm-12 card mb-2 ">
              <div class="row">
                <div>
                  <h3 class="fw-bold">Price details</h3>
                  <hr>
                  <div class="row d-flex justify-content-end">
                    <div class="col-md-6 col-lg-6 col-sm-12">
                      <h5>Price (1 item)</h5>
                      <h5>Delivery Charges </h5>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12">
                      <h5><span>
                          <mat-icon style="font-size: 12px;" class="iconcalender p-1">currency_rupee
                          </mat-icon>
                        </span> bidArrayPrice</h5>
                      <h5><span>
                          <mat-icon style="font-size: 12px;" class="iconcalender p-1">currency_rupee
                          </mat-icon>
                        </span>50</h5>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-md-6 col-lg-6 col-sm-12">
                      <h5>Total amount</h5>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12">
                      <h5><span>
                          <mat-icon style="font-size: 12px;" class="iconcalender p-1">currency_rupee
                          </mat-icon>
                        </span>30000</h5>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div> -->
        <div class="row overflow-hidden bg-light shadow p-2 mb-2 rounded">
          <div class="col-sm-12 col-md-9 col-lg-9 p-3 mb-2 bg-white">
            <h3 class="fw-bold">{{ customerName }}</h3>
            <h4>Address: {{ billingAddress }}</h4>
            <h4>Mob No: {{ mobile }}</h4>
          </div>
          <div class="col-sm-12 col-md-3 col-lg-3 d-flex justify-content-between flex-column bg-white">
            <div>
              <h3 class="fw-bold">Price details</h3>
              <hr>
              <div class="row d-flex justify-content-end">
                <div class="col-md-6 col-lg-6 col-sm-12">
                  <h4 class="fw-bold">Price : </h4>
                  <!-- <h4 class="fw-bold">GST (in %) : </h4> -->
                  <h4 class="fw-bold">GST (in Rs.)</h4>
                  <h4 class="fw-bold">Quantity :    </h4>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                  <h4>{{ subTotal | currency:'INR':'symbol':'1.2-2'}}
                  </h4>
                  <!-- <h4>
                    <span>
                      <mat-icon style="font-size: 12px;" class="iconcalender p-1">currency_rupee
                      </mat-icon>
                    </span>{{ billGstAmount | currency:'INR':'symbol':'1.2-2' }}
                  </h4> -->
                  <h4>{{ remoteGSTAmount | currency:'INR':'symbol':'1.2-2' }}
                  </h4>
                  <h4>
                    <span>
                    </span>{{ count }}
                  </h4>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-12">
                  <h4 class="fw-bold">Total amount</h4>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                  <h4>{{ remoteTotalBillAmount |
                    currency:'INR':'symbol':'1.2-2' }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>


        <form ngNoForm [action]='payUurl' method='post'>
          <div *ngIf="submitObj != null">

            <input class="form-control" type="text" (input)="setHash()" name="firstname" [hidden]="true"
              [(ngModel)]="submitObj.firstName" type="hidden" />

            <input class="form-control" type="text" (input)="setHash()" name="lastname" [hidden]="true"
              [(ngModel)]="submitObj.lastName" type="hidden" />

            <div class="mb-2" [hidden]="true">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Total Amount to be paid</mat-label>
                <input matInput type="text" (keyup)="setHash()" name="amount" [(ngModel)]="totalBillAmount"
                  [hidden]="true" readonly />
              </mat-form-field>
            </div>

            <input class="form-control" type="hidden" name="key" [(ngModel)]="submitObj.key" readonly [hidden]="true" />
            <input class="form-control" type="text" name="txnid" [(ngModel)]="submitObj.txnId" readonly
              [hidden]="true" />
            <input class="form-control" type="text" (keyup)="setHash()" name="productinfo"
              [(ngModel)]="submitObj.productinfo" [hidden]="true" />

            <input class="form-control" type="text" (keyup)="setHash()" name="email" [(ngModel)]="submitObj.email"
              [hidden]="true" />
            <!-- <input class="form-control" type="text" name="service_provider" value="payu_paisa"> -->

            <input class="form-control" type="text" name="surl" [(ngModel)]="submitObj.surl" [hidden]="true" />
            <input class="form-control" type="text" name="furl" [(ngModel)]="submitObj.furl" [hidden]="true" />
            <input class="form-control" type="text" (keyup)="setHash()" name="phone" [(ngModel)]="submitObj.phone"
              [hidden]="true">
            <input class="form-control" placeholder="Enter Number" type="text" name="hash"
              [(ngModel)]="this.submitObj.hash" [hidden]="true" />

            <div class="row d-flex justify-content-end shadow mb-5 bg-white rounded">
              <div class="col-sm-12 col-md-9 col-lg-9"></div>
              <div class="col-dm-12 col-md-3 col-lg-3">
                <input button class="btn btn-warning text-center mt-1 mb-1 placeorderButton" type="submit"
                  value="Place Order">
              </div>
            </div>
            <!-- <div class="text-center">
              <input class="btn btn-primary mt-3" type="submit" value="Place Order">
            </div> -->
          </div>
        </form>

        <div class="card-body p-0" *ngIf="productArray.length <= 0">
          <ngx-spinner bdOpacity="1" bdColor="rgba(97,197,212,0.07)" size="medium" color="#0047AB"
            type="ball-spin-clockwise" fullScreen="true">
            <p style="color: #61c5d4;">Loading..</p>
          </ngx-spinner>
        </div>
      </div>
    </div>
    <div class="row g-3" *ngIf="showForm">
      <div class="col-xl-12 order-xl-1">
        <div class="card">
          <div class="card-header bg-light btn-reveal-trigger d-flex flex-between-center">
            <h4 class="mb-0 fw-bold">Order Summary</h4>
            <!-- <a class="btn btn-link btn-sm btn-reveal text-600" routerLink="">
              <span class="fas fa-pencil-alt"></span>
            </a> -->
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered fs--1 mb-0">
                <thead class="bg-200 text-900">
                  <tr class="btn-reveal-trigger">
                    <th class="text-nowrap"><span class = "productName">Product Name</span></th>
                    <!-- <th class="text-end pt-0 head-color">Price</th> -->
                    <th class="text-nowrap">GST (in %)</th>
                    <th class="text-nowrap">GST (in Rs.)</th>
                    <th class="text-nowrap">Price</th>
                    <th class="text-nowrap"></th>
                  </tr>     
                </thead>
                <tr class="btn-reveal-trigger" *ngFor="let item of productArray">
                  <td class="text-left pt-3">
                    <b>{{ item.itemName | titlecase }}</b>
                    <div class="text-800">Quantity : {{ item.quantity }}</div>
                  </td>
                  <td class="pb-5 text-left pt-3">
                    {{ item.gst }}%
                  </td>
                  <td class="pb-5 text-left pt-3">
                    {{ item.gstAmount | currency:'INR':'symbol':'1.2-2' }}
                  </td>
                  <td class="pb-5 text-left pt-3">
                    {{ item.totalPrice | currency:'INR':'symbol':'1.2-2' }}
                  </td>
                  <!-- <td class="pe-0 text-end pt-0">
                    {{ item.finalAmount | currency:'INR':'symbol':'1.2-2' }}
                  </td> -->
                </tr>
                <!-- <tr class="border-bottom">
              <th class="ps-0">Subtotal</th>
              <th class="pe-0 text-end">{{ subTotal | currency:'INR':'symbol':'1.2-2' }}</th>
            </tr>
            <tr class="border-bottom">
              <th class="ps-0">Coupon: <span class="text-success">40SITEWIDE</span></th>
              <th class="pe-0 text-end">-$55</th>
            </tr>
            <tr class="border-bottom">
              <th class="ps-0">Shipping</th>
              <th class="pe-0 text-end">$20</th>
            </tr> -->
                <tr>
                  <th class="text-left">Total
                  </th>
                  <!--  <th class="pe-0 text-end pb-0"></th> -->
                  <th class="pe-0 text-left pb-0"></th>
                  <th class="pe-0 text-left pb-0"></th>
                  <th class="pe-0 text-left pb-0">
                    {{ subTotal | currency:'INR':'symbol':'1.2-2'
                    }}
                  </th>
                  <th class="pe-0 text-left pb-0"></th>
                </tr>
              </table>
            </div>
          </div>
          <div class="card-footer bg-light">
            <div class="d-flex justify-content-between">
              <div class="fw-semi-bold">GST Amount</div>
              <div class="fw-semi-bold">{{ billGstAmount | currency:'INR':'symbol':'1.2-2' }}</div>
            </div>
            <div class="d-flex justify-content-between">
              <div class="fw-semi-bold">Payable Total</div>
              <div class="fw-semi-bold">{{ totalBillAmount | currency:'INR':'symbol':'1.2-2' }}</div>
            </div>
          </div>
          
        </div>
        <div class="card mt-3 p-2">
          <div class="text-center">
            <button class="btn btn-success mt-3 px-5" type="submit" (click)="postRemoteOrder()">Continue</button>
            <!-- <button class="btn btn-success mt-3 px-5" type="submit" (click)="postRemoteOrder()">Confirm &amp; Pay</button> -->
            <p class="fs--1 mt-3 mb-0">By clicking <strong>Continue </strong>button you agree to the <a>Terms
                &amp; Conditions</a></p>
          </div>
        </div>
      </div>
    </div>