<div class="card mb-3">

    <!-- <div class="card-body bg-light">
        <div class="row text-center">
            <div class="col-sm-4 mb-4">
                <button class=" p-0 fas fa-check-circle text-success float-right fa-2x"
                    style="height: 50px; border-radius: 30px; width: 50px; border: none; background-color: rgb(171, 235, 171); margin-left: 14px;"></button>
                <div class="fs--2 sm text-nowrap mt-1" style="margin-left: 22px;">Appointment</div>
            </div>
            <div class="col-sm-4 mb-4">
                <button class=" p-0 fas fas fa-heart text-youtube float-right fa-2x"
                    style="height: 50px; border-radius: 30px; width: 50px; border: none; background-color: rgb(236, 172, 172); margin-left: 14px;"></button>
                <div class="fs--2 sm text-nowrap mt-1" style="margin-left: 22px;">Family</div>
            </div>
            <div class="col-sm-4 mb-4">
                <button class=" p-0 fas fas fa-rocket text-danger  float-right fa-2x"
                    style="height: 50px; border-radius: 30px; width: 50px; border: none; background-color: rgb(214, 155, 189); margin-left: 14px;"></button>
                <div class="fs--2 sm text-nowrap mt-1" style="margin-left: 22px;">Journeys</div>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-sm-4">
                <button class=" p-0 fas fa-comment-dots text-google-plus float-right fa-2x"
                    style="height: 50px; border-radius: 30px; width: 50px; border: none; background-color:rgba(151, 68, 29, 0.103); margin-left: 14px;"></button>
                <div class="fs--2 sm text-nowrap mt-1" style="margin-left: 22px;">Messages</div>
            </div>
            <div class="col-sm-4">
                <button class=" p-0 fas fa-shopping-cart  text-warning  float-right fa-2x"
                    style="height: 50px; border-radius: 30px; width: 50px; border: none; background-color: rgb(226, 212, 131); margin-left: 14px;"></button>
                <div class="fs--2 sm text-nowrap mt-1" style="margin-left: 22px;">Orders</div>
            </div>
            <div class="col-sm-4">
                <button class=" p-0 bi-gear-fill text-800 float-right fa-2x"
                    style="height: 50px; border-radius: 30px; width: 50px; border: none; background-color: rgb(172, 205, 236); margin-left: 14px;"></button>
                <div class="fs--2 sm text-nowrap mt-1" style="margin-left: 22px;">Services</div>
            </div>
        </div>

    </div> -->

</div>
<div class="card mb-3">

    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
            Book Appointment
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>
    <div class="card-body">
        <div class="text-center">
            <span class=" p-0 far fa-calendar-times  float-right fa-2x"
                style="height: 50px; border-radius: 30px; width: 50px; border: none; background-color: white; margin-left: 14px;"></span>
        </div>
        <div class="text-center">
            You do not have any upcoming appointment
        </div>
        <div class="text-center mb-3">
            Book one by clicking here.
        </div>
        <div>
            <button class="btn btn-primary me-6 mb-1 text-center" routerLink="/pages/customer/book-appointment-shop"
                style="width: 100%; border-radius: 10px;" type="button">Book an appointment
            </button>
        </div>
    </div>
</div>
<div class="card mb-3" *ngIf="customerApintlistres">
    <div class="card-header">
        <h4 style="font-weight: 600;">Appointment list</h4>
    </div>
    <div class="card-body">
        <div class="card-body p-0 overflow-hidden">
            <div class="table-responsive">
                <table class="table table-striped table-bordered mt-2 bg-white">
                    <thead>
                        <tr class="btn-reveal-trigger">
                            <th scope="col">Booking Date</th>
                            <th scope="col">Start Time</th>
                            <th scope="col">End Time</th>
                            <th scope="col">Phone</th>

                            <th scope="col" class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let appointment of customerApintlistres">
                            <td>{{appointment.bookingDate| date}}</td>
                            <td>{{appointment.startTime}}</td>
                            <td>{{appointment.endTime}}</td>
                            <td>{{appointment.phone}}</td>
                            <td>
                                <!-- <i class="fas fa-edit icolor"></i> -->

                                <button class="btn btnp">
                                <span class="bi-pencil-square icon-color fs-1" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Edit"></span>
                                <!-- //edit -->
                            </button>

                            </td>

                        </tr>
                    </tbody>

                </table>
            </div>
        </div>


    </div>
</div>