import { TitleCasePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { SaleOrderService } from 'src/app/core/services/sale-order.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { ToWords } from 'to-words';
import { PurchaseOrderComponent } from '../purchase-order/purchase-order.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { PartyService } from 'src/app/core/services/party.service';
import lang from "src/assets/langTranslation/language_translation"
import { CommonService } from 'src/app/core/services/common.service';
import * as numberToWords from 'number-to-words';

@Component({
  selector: 'app-purchase-order-list',
  templateUrl: './purchase-order-list.component.html',
  styleUrls: ['./purchase-order-list.component.scss']
})
export class PurchaseOrderListComponent implements OnInit {

  public lang = lang.UAE;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public receiptType: any;
  saleOrderList: any = [];
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  public pagesize: number = 1;
  public count: number = 0;
  public currentpage: any;
  public totalrow: any;
  public toatlPages: any;
  public searchKey: any;
  downloadData: any = [];

  // public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  @ViewChild('closebutton') closebutton;
  totalGST = 0;
  public invoiceData: any = [];
  public saleProductData: any = [];
  public basicDetails: any = [];
  public addressDetails: any = [];
  public salesData: any = [];
  public BusinessName: any;
  public invoiceNo: any;
  public invoiceDate: any;
  public dueDate: any;
  public billingAddress: any;
  public placeOfSupply: any;
  public shippingAddress: any;
  public imageSrc: any = '';
  public imageLoaded: boolean = false;
  public billGstAmount: number;
  public totalBillAmount: number;
  public finalAmount: any;
  public wordAmount = '';
  public gstStateMatch: boolean = true;
  public tradeName: any;
  public gstinNo: any;
  public mobile: any;
  public state: any;
  public hsn: any;
  public currencySymbol: any;

  public toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: true,
    }
  });
  //  public  toWordsUAE = new ToWords({
  //     localeCode: 'ar-AE', // Set the locale code to 'ar-AE' for UAE Dirhams
  //     converterOptions: {
  //       currency: true,
  //       ignoreDecimal: false,
  //       ignoreZeroCurrency: true,
  //     }
  //   });
  public p = 1;
  isArabic: boolean;
  public signImg: any = '';
  TextSizeprint: string = 'Medium';
  public invoiceTermsCon:any;

  constructor(private saleorderservice: SaleOrderService, public dialog: MatDialog, private commonService: CommonService,
    private toastService: ToastNotificationService, private partyService: PartyService,
    private titleCasePipe: TitleCasePipe, private profileService: ProfileService, private saleInvoiceService: SaleInvoiceService) { }

  ngOnInit(): void {
    this.invoiceTermsCon=localStorage.getItem('termsAndConditions');
    // Subscribe to the observable to receive updates
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });
    this.getSaleOrder();
    this.partyService.partyAdded$.subscribe(() => {
      // Fetch the updated list
      this.getSaleOrder();
    });
    this.getProfileData();
  }
  getSaleOrder() {
    this.saleorderservice.getPurchaseOrderList({}, this.currentUser.id, "PURCHASEORDER", this.partyPageSize, (this.currentPageNo + 1)).then((res) => {
      this.saleOrderList = res.data.pageData;
      this.totalrow = res.data.totalrows
    },
      (err) => {
        // if (err.error.expose) {
        //   this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        // }
        // else {
        //   this.toastService.openErrorSnackBar("Something Went Wrong.");
        // }
      })
  }

  getSaleOrderlistSearch(searchKey) {
    if (this.searchKey == "") {
      this.currentPageNo = 0;
      this.saleorderservice.getSaleOrderListSearch({}, this.currentUser.id, "PURCHASEORDER", this.partyPageSize, (this.currentPageNo + 1), searchKey.trim()).then((res) => {
        this.saleOrderList = res.data.pageData;
        this.totalrow = res.data.totalrows;
        if (this.currentPageNo > 0) {
          if (this.saleOrderList.length === 0) {
            this.currentPageNo = 0;
            this.getSaleOrderlistSearch(this.searchKey);
          }
        }
      });
    }
    else {
      this.currentPageNo = 0;
      this.saleorderservice.getSaleOrderListSearch({}, this.currentUser.id, "PURCHASEORDER", this.partyPageSize, (this.currentPageNo + 1), searchKey.trim()).then((res) => {
        this.saleOrderList = res.data.pageData;
        this.totalrow = res.data.totalrows;
        if (this.currentPageNo > 0) {
          if (this.saleOrderList.length === 0) {
            this.currentPageNo = 0;
            this.getSaleOrderlistSearch(this.searchKey);
          }
        }
      },
        (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
              });
            // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong",
              });
            // this.toastService.openErrorSnackBar("Something Went Wrong.");
          }
        })
    }

  }

  handlePage(e) {
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.saleOrderList.length > 0) {
      this.partyPageSize = e.pageSize;
      
    const searchInput = document.getElementById('searchInput') as HTMLInputElement;
    if (searchInput.value !== '') {
      this.getSaleOrderlistSearch(searchInput.value);
    } else {
      this.getSaleOrder();
    }
    }
  }

  getProfileData() {
    this.profileService.getUserProfile({}, this.currentUser.id).then((res => {
      this.imageSrc = res.data.basicdetails.profileImage;
      this.addressDetails = res.data.primaryAddress;
      this.basicDetails = res.data.basicdetails;
      this.BusinessName = res.data.additional.legalName;
      this.tradeName = res.data.additional.tradeName;
      this.mobile = res.data.basicdetails.mobile;
      this.gstinNo = res.data.additional.gstinNo;
      this.state = res.data.primaryAddress.state;
      this.signImg = res.data.basicdetails.addSignature
    }),
    )
  }
  getReceiptById(receiptId) {
    this.saleInvoiceService.getSaleInvoiceById({}, receiptId).then((res) => {
      this.salesData = res.data;
      this.saleProductData = res.data.sales_products;
      this.invoiceNo = res.data.invoiceNo;
      console.log("-----", this.invoiceNo)
      this.invoiceDate = res.data.invoiceDate;
      this.dueDate = res.data.dueDate;
      this.billingAddress = res.data.billingAddress;
      this.shippingAddress = res.data.shippingAddress;
      this.placeOfSupply = res.data.placeOfSupply;
      this.billGstAmount = res.data.billGstAmount;
      this.totalGST = res.data.billGstAmount;
      this.totalBillAmount = res.data.totalBillAmount;
      this.finalAmount = this.totalBillAmount;
      this.hsn = res.data.sales_products[0].product.hsn;
      this.convertToWord(this.finalAmount)

      if (this.salesData.supplyType == 'INTERSTATE') {

        this.gstStateMatch = false;
      } else {
        this.gstStateMatch = true;
      }

    })
  }
  onPrint() {
    const printContents = document.getElementById('panel').innerHTML;
    const originalContents = document.body.innerHTML;
    console.log("originalContents", originalContents)

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  closeModal() {
  }
  convertToWord(num) {
    if (!this.isArabic) {
      this.finalAmount = this.toWords.convert(num);
    }
    else {
      const toWords = new ToWords({
        converterOptions: {
          currency: false,
          ignoreDecimal: false,
          ignoreZeroCurrency: true,
        },
      });
      this.finalAmount = numberToWords.toWords(num);
      this.currencySymbol = 'AED';
      // this.finalAmount = toWords.convert(num);
      console.log(`${this.finalAmount} ${this.currencySymbol}`);

    }
  }


  openDialog(): void {
    const dialogRef: MatDialogRef<PurchaseOrderComponent> = this.dialog.open(PurchaseOrderComponent, {
      // width: '950px',
      height: '730px',
      data: {},
      panelClass: 'custom-Modal-purchase-order',
      disableClose: true,
      position: {
        right: '0',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  downlaodAllData() {
    if (this.totalrow === undefined || this.totalrow === null) {
      // this.toastService.openErrorSnackBar("No Data Available For Download");
      this.toastService.toastMsg({
        title: "Error",
        content: "No Data Available For Download",
        });
    } else {
      this.saleorderservice.getPurchaseOrderList({}, this.currentUser.id, "PURCHASEORDER", this.partyPageSize, (this.currentPageNo + 1)).then((res) => {
        this.downloadData = res.data.pageData;
        if (this.downloadData.length > 0) {
          this.exportToExcel()
        } else {
          this.toastService.toastMsg({
            title: "Error",
            content: "No Data Available For Download",
            });
          // this.toastService.openErrorSnackBar("No Data Available to Download");
        }
      }, (err) => {
        if (err.error.status === 500) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
      })
    }
  }

  exportToExcel(): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.downloadData);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(data, 'purchase_order_data.xlsx');
  }
  getTextSize(selectedTextSize: string): number {
    switch (selectedTextSize) {
      case 'Large':
      return 22;
      case 'Medium':
      return 20;
      case 'Small':
      return 18;
      default:
      return 20; // Default to 'Medium' size if an invalid option is selected
    }
    }

    getTextSizeprint(TextSizeprint: string): number {
    switch (TextSizeprint) {
      case 'Large':
      return 16;
      case 'Medium':
      return 14;
      case 'Small':
      return 12;
      default:
      return 14; // Default to 'Medium' size if an invalid option is selected
    }
    }
}
