import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { PromotionService } from 'src/app/core/services/promotion.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-apply-coupon',
  templateUrl: './apply-coupon.component.html',
  styleUrls: ['./apply-coupon.component.scss']
})
export class ApplyCouponComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  couponApplied: boolean = true;
  couponList: any[] = [];
  billAmt: any;
  appliedCoupon: any;

  constructor(private promotionService: PromotionService,
    public dialogRef: MatDialogRef<ApplyCouponComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastService: ToastNotificationService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private titleCasePipe: TitleCasePipe,
    ) { 
      this.billAmt = this.data.billAmt;
      this.appliedCoupon = this.data.appliedCoupon || null;
      console.log(this.data)
      // if(this.data.data.appliedCoupon !== (undefined || null)) {
      //   this.appliedCoupon = this.data.data.appliedCoupon.coupon_code === this.appliedCoupon.data.coupon_code ? this.appliedCoupon : null 
      //   console.log(this.appliedCoupon)
      //   this.couponApplied = true
      // }
    }

  ngOnInit(): void {
    this.couponsAvailableToApply()
  }

  // Function for a Coupons API
  async couponsAvailableToApply() {
    await this.promotionService.couponsAvailableToApply({ billAmount: Math.round(this.billAmt) }, this.currentUser.id).then(
      async (response: any) => {
        if (response.success && response.data !== null) {
          this.couponList = response.data;
        } else {
          this.couponApplied = false
          this.toastService.toastMsg({
            title: 'Error',
            content: 'Coupons are not available to apply'
          })
        }
      },
      (error) => {
        // Handle errors if the API call fails
        console.error('Error checking offer:', error);
      }
    );
  }

  // Function to apply a coupon
  selectCoupon(coupon: any): void {
    this.appliedCoupon = this.appliedCoupon === coupon ? null : coupon;
  }
  
  // Function to remove a coupon
  deselectCoupon(coupon: any): void {
    this.appliedCoupon = this.appliedCoupon === coupon ? null : null;
  }

  // Function to cancel the coupon application
  close(): void {
    this.dialogRef.close();
  }

  // Function to apply the selected coupon
  applyCoupon(): void {
    if (this.appliedCoupon) {
      // Apply the coupon and perform any necessary actions
      this.dialogRef.close({ couponApplied: this.appliedCoupon });  // Close the modal and send data back to the parent component
    } else {
      this.toastService.toastMsg({
        title: 'Error',
        content: 'Coupon not applied, please select one.'
      })
    }
  }
}
