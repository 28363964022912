<div class="container-fluid mb-3 mx-0 bg-white">
  <div class="row pt-3 pb-3 mt-" id="headingPrintHide">
      <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:600;font-size:20px!important;">
        Vendor Wise Profit/Loss
      </div>
      <div class="col-6 navOption-btns" style ="display: flex; justify-content: flex-end;">
        <button class="btn me-3" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()" type="button"><i class="fas fa-download"></i></button>
         
        <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
        </button>
      </div>
  </div>
  <hr class="mt-1" />

  <div class="fs--1 ">
    <div class="row">
      <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;" class="col-xs-12 col-sm-12 col-md-4 col-lg-3"  id = "dateRangePrintHide">
        <!-- <mat-form-field class="example-full-width" appearance="outline">
          <mat-label for="Date Range">Date Range</mat-label>
          <input matInput placeholder="Date Range" id="Date Range" type="text" [outsideClick]="true" ngModel
            bsDaterangepicker [maxDate]="today" [(ngModel)]="dateRange" style="text-transform:uppercase!important;"
            [bsConfig]="{ranges: ranges,showWeekNumbers:false, showNextMonth: false, rangeInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true }">
          <span>
            <mat-icon class="iconcalender p-1">date_range</mat-icon>
          </span>
        </mat-form-field> -->
        <label for="Date Range" class="form-label">Date</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input class="uppercase-date-range" matInput placeholder="Date" id="Date Range" type="text" [outsideClick]="true" 
              [(ngModel)]="dateRange" bsDaterangepicker [maxDate]="today" (ngModelChange)="changeDate($event)"
              [bsConfig]="{ranges: ranges,showWeekNumbers:false, showNextMonth: false, rangeInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true }">
          <span><mat-icon class="iconcalender p-1">date_range</mat-icon></span>
      </mat-form-field>
      </div>

      <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;" class="col-xs-12 col-sm-12 col-md-4 col-lg-3" id = "searchBarHide">
        <label class="form-label">Select Vendor Name</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input type="text" placeholder="Vendor Name" aria-label="Select Party Name" matInput
            (keyup)="searchPartyByName($event.target.value)" [matAutocomplete]="auto" [ngClass]="{
          'is-invalid': (partyName.invalid && partyName.touched) && submitted }" required>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
              (onSelectionChange)="setSelectedPartyDeatails1(option)">
              {{option.partyName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <!-- <div class="col-sm-4"></div>
      <div class="col-sm-1  mt-2 text-center" id = "excelPrintHide"  (click)="exportExcel()">
        <a type="button" style="margin-left: 14px;" class="p-0 text-900 bi-file-earmark-excel fa-2x "></a>
        <div class="text-nowrap text-900  fs--2  mt-1">Export to Excel</div>

      </div>
      <div class="col-sm-1 mt-2 text-center"  id = "PrintoptionHide" (click)="onPrint()">
        <a type="button" class=" p-0 text-900 bi-printer float-right fa-2x" (click)="printComponent('component1')"></a>
        <div class="fs--2 text-900 text-nowrap mt-1">Print</div>
      </div> -->
    </div>


    <div class="table-responsive scrollbar mt-4 fs--1">
      <table class="table bg-white table-bordered table-stripped" id="excel-table">
        <thead class="" style="background-color: #F0F0F0;">
          <tr class="text-900">
            <th scope="col" class="text-center text-nowrap p-1 " style="width: 6%; height: 10px;">#
              <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false"><i style="margin-left: 1px;"
                  class="text-1400  fs-1"></i></button>
            </th>
            <th scope="col" class="text-center text-nowrap p-1 " style="width: 6%; height: 10px;">VENDOR NAME
              <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false"><i style="margin-left: 1px;"
                  class="text-1400  fs-1"></i></button>
            </th>
              <!-- <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false"><i style="margin-left: 10px;"
                  class="text-1400  fs-1 bi-filter-right"></i></button> -->
              <!-- <div class="dropdown-menu scrollbar p-3 " style="width: 22%;" aria-labelledby="dropdownMenuButton">
                <li>
                  <select class="form-select form-select-sm" aria-label="Default select example">
                    <option selected="">Contains</option>
                    <option value="1">Exact match</option>
                  </select>
                </li>
                <li>
                  <div class="col-sm" style="margin-top: 10px;">
                    <input class="form-control fs--1" id="inputPassword3" type="input" />
                  </div>
                </li>
                <div class="dropdown-divider"></div>
                <div class="text-center">
                  <div class="btn-group mb-2 mb-md-0">
                    <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                  </div>
                  <div class="btn-group mb-2 mb-md-0">
                    <button class="btn btn-primary btn-sm " type="button" style="margin-left: 8px;">APPLY</button>
                  </div>
                </div>
              </div> -->
            <!-- <th scope="col" class="text-center text-nowrap p-1">MOBILE NUMBER</th>
            <th scope="col" class="text-center text-nowrap p-1">ADDRESS </th> -->
            <th scope="col" class="text-center text-nowrap p-1 " style="width: 6%; height: 10px;">MOBILE #
              <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false"><i style="margin-left: 1px;"
                  class="text-1400  fs-1"></i></button>
            </th>
            <th scope="col" class="text-center text-nowrap p-1 " style="width: 6%; height: 10px;">ADDRESS
              <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false"><i style="margin-left: 1px;"
                  class="text-1400  fs-1"></i></button>
            </th>
            <th scope="col text-nowrap" class="text-nowrap text-center p-1">GSTIN
              <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false"><i style="margin-left: 10px; color:#FF9A62"
                  class="text-1400  fs-1 bi-filter-right"></i></button>
              <div class="dropdown-menu scrollbar p-3 " style="width: 22%;" aria-labelledby="dropdownMenuButton">
                <li>
                  <select class="form-select form-select-sm" aria-label="Default select example"
                    (change)="onSelectgst($event.target.value)">

                    <!-- <option selected="">Contains</option>
                    <option value="1">Exact match</option> -->
                    <option value="Equal">Equal to</option>
                  </select>
                </li>
                <li>
                  <div class="col-sm" style="margin-top: 10px;">
                    <input class="form-control fs--1" id="inputPassword3" type="input"  [(ngModel)]="totalgst"/>
                  </div>
                </li>
                <div class="dropdown-divider"></div>
                <div class="text-center">
                  <div class="btn-group mb-2 mb-md-0">
                    <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                  </div>
                  <div class="btn-group mb-2 mb-md-0">
                    <button class="btn btn-primary btn-sm " type="button" style="margin-left: 8px;" (click)="Applygst()">APPLY</button>
                  </div>
                </div>
              </div>
            </th>
            <th scope="col" class="text-center text-nowrap p-1">TOTAL SALES AMOUNT
              <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false"><i style="margin-left: 10px;color:#FF9A62"
                  class="text-1400  fs-1 bi-filter-right"></i></button>
              <div class="dropdown-menu scrollbar p-3 " style="width: 22%;" aria-labelledby="dropdownMenuButton">
                <li>
                  <select class="form-select form-select-sm" aria-label="Default select example"
                    (change)="onSelectRange($event.target.value)">
                    <option value="Equal">Equal to</option>
                    <option value="Greater">Greater than</option>
                    <option value="Less">Less than</option>
                  </select>
                </li>
                <li>
                  <div class="col-sm" style="margin-top: 10px;">
                    <input class="form-control fs--1" id="inputPassword3" type="input" [(ngModel)]="totalSaleInput" />
                  </div>
                </li>
                <div class="dropdown-divider"></div>
                <div class="text-center">
                  <div class="btn-group mb-2 mb-md-0">
                    <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                  </div>
                  <div class="btn-group mb-2 mb-md-0">
                    <button class="btn btn-primary btn-sm " type="button" style="margin-left: 8px;"
                      (click)="ApplyValue()">APPLY</button>
                  </div>
                </div>
              </div>
            </th>
            <th scope="col" class="text-center text-nowrap p-1">TOTAL PURCHASE AMOUNT
              <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false"><i style="margin-left: 10px;color:#FF9A62"
                  class="text-1400  fs-1 bi-filter-right"></i></button>
              <div class="dropdown-menu scrollbar p-3 " style="width: 22%;" aria-labelledby="dropdownMenuButton">
                <li>
                  <select class="form-select form-select-sm" aria-label="Default select example"
                    (change)="onSelectRange($event.target.value)">
                    <option value="Equal">Equal to</option>
                    <option value="Greater">Greater than</option>
                    <option value="Less">Less than</option>
                  </select>
                </li>
                <li>
                  <div class="col-sm" style="margin-top: 10px;">
                    <input class="form-control fs--1" id="inputPassword3" type="input" [(ngModel)]="totalPurchaseInput" />
                  </div>
                </li>
                <div class="dropdown-divider"></div>
                <div class="text-center">
                  <div class="btn-group mb-2 mb-md-0">
                    <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                  </div>
                  <div class="btn-group mb-2 mb-md-0">
                    <button class="btn btn-primary btn-sm " type="button" style="margin-left: 8px;"
                      (click)="ApplyPurchaseValue()">APPLY</button>
                  </div>
                </div>
              </div>
            </th>
            <th scope="col" class="text-center text-nowrap p-1">PROFIT(+)/LOSS(-)
              <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false"><i style="margin-left: 10px;color:#FF9A62"
                  class="text-1400  fs-1 bi-filter-right"></i></button>
              <div class="dropdown-menu scrollbar p-3 " style="width: 22%;" aria-labelledby="dropdownMenuButton">
                <li>
                  <select class="form-select form-select-sm" aria-label="Default select example"
                    (change)="onSelectProfitLoss($event.target.value)">
                    <option value="Equal">Equal to</option>
                    <option value="Greater">Greater than</option>
                    <option value="Less">Less than</option>
                  </select>
                </li>
                <li>
                  <div class="col-sm" style="margin-top: 10px;">
                    <input class="form-control fs--1" id="inputPassword3" type="input" [(ngModel)]="totalProfitInput" />
                  </div>
                </li>
                <div class="dropdown-divider"></div>
                <div class="text-center">
                  <div class="btn-group mb-2 mb-md-0">
                    <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                  </div>
                  <div class="btn-group mb-2 mb-md-0">
                    <button class="btn btn-primary btn-sm " type="button" style="margin-left: 8px;"
                      (click)="ApplyProfit()">APPLY</button>
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="btn-reveal-trigger text-left" *ngFor="let item of profitList;let i = index">
            <td>{{ i + 1}}</td>
            <td class="VENDOR NAME">{{item.partyName}}</td>
            <td class="MOBILE NUMBER">{{item.mobile}}</td>
            <td class="ADDRESS">{{item.billingAddress}}</td>
            <td class="PHONE NO">{{item.gstIn}}</td>
            <td class="TOTAL SALE AMOUNT">{{item.saleTotal}}</td>
            <td class="TOTAL Purchase AMOUNT">{{item.stockPurchase}}</td>
            <td class="PROFIT(+)/LOSS(-) ">{{item.profitLoss}}</td>
          </tr>
        </tbody>
        <tbody *ngIf="profitList == 0">
          <tr class="text-center">
            <td colspan="10">
              <img class="image-responsive" src="assets/img/icons/spot-illustrations/GST No Transaction.png" width="124" height="142" alt="">
              <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Data Found.</h5>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td>            
            <td></td>
            <td></td>
            <td></td>

            <td class = "nowrap" colspan = "1">
              <strong class=" text-dark">Total</strong>
            </td>
            <!-- <td colspan = "1"></td> -->
            <td class = "nowrap"  colspan = "1">
              <strong class=" text-dark">
                <a class="text-success">
                 {{profitLossTotal.totalSales}} 
                </a>
              </strong>
            </td>
            <td class = "nowrap" colspan = "1">
              <strong class=" text-dark">
                <a class="text-success">
                  {{profitLossTotal.totalPurchase}}
                </a>
              </strong>
            </td>
            <td class = "nowrap" colspan = "1">
              <strong class="fw-bold text-dark">
                <a class="text-success"> 
                  {{profitLossTotal.totalProfitLoss}}
                </a> 
              </strong>
            </td>
          </tr>
        </tfoot>
      </table>
      <div id="headingPrintHide1">
        <mat-paginator class="float-right mt-2" *ngIf="profitList.length > 0" [pageSize]="partyPageSize"
          [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo" showFirstLastButtons
          (page)="handlePage($event)" aria-label="Select page">
      </mat-paginator>
      </div>
      <!-- <mat-paginator class="float-right mt-2" *ngIf="profitList.length > 0" [pageSize]="partyPageSize"
        [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo" showFirstLastButtons
        (page)="handlePage($event)" aria-label="Select page">
    </mat-paginator> -->
    </div>
    <!-- <div class="card-footer mt-4">
      <div class="" id="accordionPanelsStayOpenExample">
        <div class="accordion-item position-absolute bottom-0 start-0" style=" width: 100%;">
          <div class="accordion-body">
            <div class="row sm p-0" style="height: px;">
              <div class="col-sm-3 p-2">
                <strong class=" text-dark p-2" style="margin-top: 20px; margin-left: 15px;">Total Sale Amount:
                  <a class="text-success"> {{profitLossTotal.totalSales}} </a></strong>
              </div>
              <div class="col-sm-6">
                <strong class=" text-dark"></strong>
              </div>
              <div class="col-sm-3 p-2">
                <strong class="fw-bold text-dark " style="margin-left: 30px; ">Total Profit(+) / Loss (-): <a
                    class="text-success"> {{profitLossTotal.totalProfitLoss}}</a> </strong>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</div>