<div class="card-body">
    <div class="row flex-between-center">
        <div class="col-sm-auto mb-2 mb-sm-0">
            <h2 class="mb-0">Update Appointment Type</h2>
        </div>

    </div>
</div>
<div class="card-body ">
    <form [formGroup]="appointMentForm">
        <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Name</mat-label>
                <input matNativeControl type="text" required matInput formControlName="Name" placeholder="Name"
                    [ngClass]="{
                        'is-invalid':
                        f.Name.invalid && f.Name.touched && submitted
                      }" />
            </mat-form-field>
            <mat-error class="text-danger" *ngIf="
              (submitted || f.Name.touched) && f.Name.errors?.required
            ">
                Name Is Required!
            </mat-error>
        </div>

        <div class="row m-2">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="padding: 0px!important;margin:0 !important">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Duration</mat-label>
                    <input matNativeControl type="number" required matInput formControlName="Duration"
                        placeholder="Duration" [ngClass]="{
                        'is-invalid':
                        f.Duration.invalid && f.Duration.touched && submitted
                      }" />
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="
              (submitted || f.Duration.touched) && f.Duration.errors?.required
            ">
                    Duration Is Required!
                </mat-error>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Location Type</mat-label>

                    <mat-select formControlName="LocationType" [ngClass]="{
                        'is-invalid':
                        f.LocationType.invalid && f.LocationType.touched && submitted
                      }">
                        <mat-option *ngFor="let location of locations" [value]="location.locationName">
                            {{location.locationName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="
                (submitted || f.LocationType.touched) && f.LocationType.errors?.required
              ">
                    LocationType Is Required!
                </mat-error>
            </div>
        </div>
        <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-0">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>User Role</mat-label>
                <mat-select formControlName="UserRole" [ngClass]="{
                    'is-invalid':
                    f.UserRole.invalid && f.UserRole.touched && submitted
                  }">
                    <mat-option *ngFor="let userR of userRoles" [value]="userR.userRole">
                        {{userR.userRole}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-error class="text-danger" *ngIf="
                (submitted || f.UserRole.touched) && f.UserRole.errors?.required
              ">
                User Role Is Required!
            </mat-error>
        
        </div>
        <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Description</mat-label>
                <textarea matInput cols="35" rows="8" placeholder="Description" formControlName="Description" [ngClass]="{
                    'is-invalid':
                    f.Description.invalid && f.Description.touched && submitted
                  }">
                </textarea>
            </mat-form-field>

            <mat-error class="text-danger" *ngIf="
                (submitted || f.Description.touched) && f.Description.errors?.required
              ">
                Description Is Required!
            </mat-error>
            
        </div>
        <div>
            <mat-checkbox formControlName="Cancellation">
                Cancellation
            </mat-checkbox>

        </div>
        <div>
            <mat-checkbox formControlName="isDisable">
                Is Disable
            </mat-checkbox>
            
        </div>
        <div class="row">
            <div class="col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Within Days</mat-label>
                    <input matNativeControl type="number" required matInput formControlName="withinDays"
                        placeholder="Enter Days" [ngClass]="{
                            'is-invalid':
                            f.withinDays.invalid && f.withinDays.touched && submitted
                          }">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="
                (submitted || f.withinDays.touched) && f.withinDays.errors?.required
              ">
                    Within Days Is Required!
                </mat-error>

            </div>
        </div>
        <!-- <div class="card-footer bg-light">
            <div class="px-sm-1 px-md-2 text-center">
                <ul class="pager wizard list-inline mb-0">
                    <li></li>
                    <li class="">
                        <button class="btn btn-outline-primary px-2 px-sm-6 fs--1" (click)="updateAppointment()"
                            type="submit">
                            Submit<span class=" ms-2 fs--1" data-fa-transform="shrink-3">
                            </span>
                        </button>
                    </li>
                </ul>
            </div>
        </div> -->
        <div class="col-12 d-flex p-3 justify-content-end">
            <button class="btn btn-outline-primary me-1 mb-1" (click)="updateAppointment()" type="submit">
                Update
            </button>
            <button class="btn btn-outline-primary me-1 mb-1 ml-10" type="button" (click)="backButton()">
                Cancel
            </button>
        </div>
        <!-- <div class="col-12 d-flex p-3 justify-content-end">
            <button class="btn btn-outline-primary me-1 mb-1" (click)="saveAppointForm()" type="button">
                Save
            </button>
             <button class="btn btn-outline-primary me-1 mb-1 ml-10" type="button">
                Cancel
            </button> -->
        <!-- </div>  --> 
    </form>
</div>
