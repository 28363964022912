import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FireStorageService } from 'src/app/core/services/fire-storage.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { WidegtsLayoutService } from 'src/app/core/services/widegts-layout.service';

@Component({
  selector: 'app-widgets-layout',
  templateUrl: './widgets-layout.component.html',
  styleUrls: ['./widgets-layout.component.scss']
})
export class WidgetsLayoutComponent implements OnInit {
  public isFullScreeenMode:Boolean = false;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public gotData = false;
  public Form: FormArray;
  public generalForm: FormGroup;
  public sliderForm: FormGroup;
  public galleryForm: FormGroup;
  public addWidgets: FormGroup;
  public footerForm: FormGroup;
  public headerForm: FormGroup;
  public testimonialForm: FormGroup;
  public sectionForm: FormGroup;
  public sectionFormArray: FormGroup;
  public numberOfImages = [1,2,3,4,6];
  public categories: Array<any> = [];
  public selectedItems: Array<any> = [];
  public dropdownSettings: any = {};
  public panelOpenState = true;
  public ShowFilter = false;
  public isDataLoaded: boolean = false;
  public loader = true;
  public id: any;
  public defaultData: any = [];
  public newWidgetId: any;
  public getArrData: any = [];
  public labelPosition = "after";
  public finalObject: any = [];
  public finalPostData: any = [];
  public seqFlag: boolean = false;
  public disabled: boolean = true;
  public searchToggle: any;
  public filepath;
  public downloadableURL;
  public deleteURL;
  public filename;
  public defaultImage:"../../../assets/images/imageAdd.jfif";


  //Form elements
  testimonial = {
    title: "",
    quote: "",
    name: "",
    pos: "",
    comp: ""
  }
  section = { sectionTitle: "", sectionDesc: "" };

  constructor(
    private fb: FormBuilder,
    public toastService: ToastNotificationService,
    //private homeLayoutService: HomeLayoutService,
    private widgetLayoutService: WidegtsLayoutService,
    // private retailerProfileService: RetailerProfileService,
    private alertService: ToastNotificationService,
    public router: Router,
    public fireService:FireStorageService,
  ) {
    this.footerForm = this.fb.group({
      desc: [''],
      contactUs: [''],
      gmail: [''],
      mobile: [''],
      isSocial: [''],
      catalog: [''],
      aboutUs: [''],
      help: [''],
      isFeedback: [''],
      facebook: [''],
      insta: [''],
      linkedIn: [''],
      name: [''],
      website: [''],
      copy: [''],
      PrimaryColor: [''],
      SecondaryColor:[''],
    });

    this.headerForm = this.fb.group({
      enterpriseName: [''],
      isNotification: [''],
      signupIcon: [''],
      PrimaryColor: [''],
      SecondaryColor:[''],
      isSearch: [""],
    });


    this.sliderForm = this.fb.group({
      slider: this.fb.array([
        // this.addSliderFields()
      ]),
    });

    this.galleryForm = this.fb.group({
      gallery: this.fb.array([
        this.addGalleryFields()
      ]),
    });

    this.testimonialForm = this.fb.group({
      testimonial: this.fb.array([
        // this.addTestimonFields()
      ]),
    });
    
  }

  get secForm() {
    return this.sectionForm.get('sectionFormArray') as FormArray;
  }
  get formArr() {
    return this.generalForm.get('Form') as FormArray
  }
  accordions = [
    // Header
    {
      type: "Header",
    },
    // Slider
    {
      type: "Slider",
    },
    // Gallery
    {
      type: "Gallery",
    },
    // Section
    {
      type: "Section",
    },
    // Image
    {
      type: "Image",
    },
    // Testimonial
    {
      type: "Testimonial",
    },
    // Offer
    // {
    //   type: "Offer",
    // },
    // Feature Product
    {
      type: "Feature Product",
    },
     // Footer
     {
      type: "Footer",
    },
  ];
  ngOnInit(): void {
    this.addWidgets = this.fb.group({
      widegtType: [""]
    })
    this.generalForm = new FormGroup({
      Form: new FormArray([])
    })
  }

  getValue(search) {
    search.value = !search.value
    search.setValue(search.value);
  }

  getwidgetData(updated?) {
    if (updated) {
      this.formArr.clear();
    }
    this.widgetLayoutService.getDefaultWidgets({}, "All", this.currentUser.id).then((res) => {
      this.defaultData = res.data;
      for (let i = 0; i < this.defaultData.length; i++) {
        let data = {
          sequence: this.defaultData[i].sequence,
          widgetId: this.defaultData[i].widgetId,
          type: this.defaultData[i].type,
        }
        this.getArrData.push(data);
      }
      this.defaultData.sort((a, b) => a.sequence - b.sequence);
      this.arrangeWidgets(this.defaultData);
      this.defaultData.forEach((i, index) => {
        if (i.type != undefined ) {
          if (i.type === "Header") {
            this.formArr.push(new FormGroup({
              isSearch: new FormControl(i.data.isSearch),
              signupIcon: new FormControl(i.data.signupIcon),
              isNotification: new FormControl(i.data.isNotification),
              enterpriseName: new FormControl(i.data.enterpriseName),
              type: new FormControl(i.type),
              id: new FormControl(i.id),
              imagePath: new FormControl(i.data.imagePath),
              imageUrl: new FormControl(i.data.imageUrl),
              PrimaryColor:new FormControl(i.data.PrimaryColor),
              SecondaryColor: new FormControl(i.data.SecondaryColor),
              // disabled: true,
            }));
        
          } else if (i.type === "Footer") {
            this.formArr.push(new FormGroup({
              desc: new FormControl(i.data.desc),
              contactUs: new FormControl(i.data.contactUs),
              facebook: new FormControl(i.data.facebook),
              linkedIn: new FormControl(i.data.linkedIn),
              help: new FormControl(i.data.help),
              aboutUs: new FormControl(i.data.aboutUs),
              catalog: new FormControl(i.data.catalog),
              mobile: new FormControl(i.data.mobile),
              gmail: new FormControl(i.data.gmail),
              website: new FormControl(i.data.website),
              name: new FormControl(i.data.name),
              copy: new FormControl(i.data.copy),
              PrimaryColor:new FormControl(i.data.PrimaryColor),
              SecondaryColor: new FormControl(i.data.SecondaryColor),
              type: new FormControl(i.type),
              insta: new FormControl(),  // not coming from backend
              id: new FormControl(i.id)
            }));
          } else if (i.type === "Testimonial") {
            this.formArr.push(new FormGroup({
              type: new FormControl(i.type),
              id: new FormControl(i.id),
              PrimaryColor:new FormControl(i.data.PrimaryColor),
              SecondaryColor: new FormControl(i.data.SecondaryColor),
            }))
          } else if (i.type === "Slider") {
            this.formArr.push(new FormGroup({
              type: new FormControl(i.type),
              id: new FormControl(i.id),
              slider: new FormArray([
                // this.addSliderFields()
              ])
            }))
            if (typeof i.data == "object") {
              if (i.data.slider) {
                i.data.slider.forEach((item) => {
                  (this.formArr.controls[this.formArr.length - 1].get('slider') as FormArray).push(new FormGroup({
                    name: new FormControl(item.name),
                    description: new FormControl(item.description),
                    imagePath: new FormControl(item.imagePath),
                    imageUrl: new FormControl(item.imageUrl),
                  }))
                });
              } else {
                (this.formArr.controls[this.formArr.length - 1].get('slider') as FormArray).push(new FormGroup({
                  name: new FormControl(i.data.name),
                  description: new FormControl(i.data.description),
                  imageUrl: new FormControl(i.data.imageUrl)
                }))
              }
              // console.log(this.formArr.controls[this.formArr.length - 1].get('slider') as FormArray, 'form after');
            }
          } else if (i.type === "Gallery") {
            // this.formArr.push(new FormGroup({
            //   type: new FormControl(i.type),
            //   id: new FormControl(i.id),
            //   gallery: new FormArray([
            //     this.addGalleryFields()
            //   ])
            // }));
            this.formArr.push(new FormGroup({
              type: new FormControl(i.type),
              id: new FormControl(i.id),
              gallery: new FormArray([
                // this.addSliderFields()
              ])
            }))
            if (typeof i.data == "object") {
              if (i.data.gallery) {
                i.data.gallery.forEach((item) => {
                  (this.formArr.controls[this.formArr.length - 1].get('gallery') as FormArray).push(new FormGroup({
                    name: new FormControl(item.name),
                    description: new FormControl(item.description),
                    // imagePath: new FormControl(item.imagePath),
                    imageUrl1: new FormControl(item.imageUrl1),
                    imageUrl2: new FormControl(item.imageUrl2),
                    imageUrl3: new FormControl(item.imageUrl3),
                  }))
                });
              } else {
                // (this.formArr.controls[this.formArr.length - 1].get('gallery') as FormArray).push(new FormGroup({
                //   imagePath: new FormControl(i.data.imagePath),
                //   imageUrl1: new FormControl(i.data.imageUrl1),
                //   imageUrl2: new FormControl(i.data.imageUrl2),
                //   imageUrl3: new FormControl(i.data.imageUrl3),
                // }))
              }
              // console.log(this.formArr.controls[this.formArr.length - 1].get('slider') as FormArray, 'form after');
            }
          } else if (i.type === "Offer") {
            this.formArr.push(new FormGroup({
              type: new FormControl(i.type),
              id: new FormControl(i.id),
            }))
          } else if (i.type === "Feature Product") {
            this.formArr.push(new FormGroup({
              type: new FormControl(i.type),
              id: new FormControl(i.id),
            }))
          } else if (i.type === "Section") {
              this.formArr.push(new FormGroup({
                sectionTitle: new FormControl(i.data.sectionTitle),
                sectionDesc: new FormControl(i.data.sectionDesc),
                PrimaryColor:new FormControl(i.data.PrimaryColor),
                SecondaryColor: new FormControl(i.data.SecondaryColor),
                type: new FormControl(i.type),
                id: new FormControl(i.id)
              }))
            this.formArr.controls.map((item, index) => {
          
            })
  
          } else if (i.type === "Image"){
            this.formArr.push(new FormGroup({
              text: new FormControl(i.data.text),
              imageUrl:new FormControl(i.data.imageUrl),
              type: new FormControl(i.type),
              id: new FormControl(i.id)
            }))
          }
        }
      });
      // if(updated) {
      //   this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      //   this.router.onSameUrlNavigation = 'reload';
      //   this.router.navigate(['/pages/home-layout']);
      // }
    });
  }

  // patch value to testimonial form 
  patchValuesTestimon(title, quote, name, pos, comp) {
    return this.fb.group({
      title: [title],
      quote: [quote],
      name: [name],
      pos: [pos],
      comp: [comp],
    })
  }
  // patch value to slider form
  patchValuesSlider(name, description) {
    return this.fb.group({
      name: [name],
      description: [description],
    })
  }
  patchValuesSection(sectionTitle, sectionDesc) {
    return this.fb.group({
      sectionTitle: [sectionTitle],
      sectionDesc: [sectionDesc],
    })
  }
  /**
   * Add Testimonial Form
   */
  addTestimonialButtonClick(): void {
    (<FormArray>this.testimonialForm.get('testimonial')).push(this.addTestimonFields());
  }
  addTestimonFields(): FormGroup {
    return this.fb.group({
      title: [""],
      quote: [""],
      name: [""],
      pos: [""],
      comp: [""],
    })
  }
  /**
   * Add slider Form
   */
  addSLiderButtonClick(index): void {
    const content = this.formArr.controls[index].get('slider') as FormArray;
    content.push(this.addSliderFields());
  }
  addSliderFields(): FormGroup {
    return new FormGroup({
      name: new FormControl(),
      description: new FormControl()
    })
  }

  addSectionButtonClick(): void {
    (<FormArray>this.sectionForm.get('sectionFormArray')).push(this.addSectionFields());
  }
  // Section Form Array ADD
  addSectionFields(): FormGroup {
    return this.fb.group({
      sectionTitle: [""],
      sectionDesc: [""],
    })
  }
  /**
   * Remove Testimonial Form
   * @param i
   */
  removeTestimonFields(i) {
    (<FormArray>this.testimonialForm.get("testimonial")).removeAt(i);
  }

  /**
   * remove slider form
   * @param i
   */
  removeSliderFields(index, sliderIndex) {
    const content = this.formArr.controls[index].get('slider') as FormArray;
    content.removeAt(sliderIndex);
  }

  /**
   * Add Gallery Form
   */
  addGalleryButtonClick(index): void {
    const content = this.formArr.controls[index].get('gallery') as FormArray;
    content.push(this.addGalleryFields());
  }
  addGalleryFields(): FormGroup {
    return new FormGroup({
      imageUrl1: new FormControl(),
      imageUrl2: new FormControl(),
      imageUrl3: new FormControl(),
    })
  }

  /**
   * Remove Gallery Form
   * @param i
   */
  removeGalleryFields(index, galleryIndex) {
    const content = this.formArr.controls[index].get('gallery') as FormArray;
    content.removeAt(galleryIndex);
  }

  /**
   * get and set default value of widgets
   * @param key
   */
  get f() {
    return this.footerForm.controls;
  }

  addWidgetByRetailer() {
    // map filter reduce some every push pop slice splice replace
    if (this.seqFlag == true) {
      this.finalPostData = this.finalObject;
    }
    else {
      this.finalPostData = this.formArr.value.map((item, index) => {
        let tempArray = {
          "sequence": index + 1,
          "type": item.type,
          "data": item,
          "pageNumber": 1,
          "pageName": "Home",
          "id": item.id
        }
        return tempArray;
      })
    }
    this.widgetLayoutService.postWidgetsByClient(this.finalPostData)
      .subscribe((res) => {
        this.toastService.toastMsg({
          title: "Success",
          content: "Widgets Added!!!",
          })
        // this.alertService.openSnackBar("Widgets Added");
        this.getwidgetData(true);
      }, (error) => {
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Try Again Later!!!",
        });
        // this.alertService.openErrorSnackBar("Please Try Again Later!!!");
      })
  }
  /**
   * Change Sequence of Widgets
   * @param event 
   */
  drop(event: CdkDragDrop<string[]>) {
    let copyOfPreviousIndex: any;
    let isSwapped: boolean;
    if(event.previousIndex == 0 
      || event.currentIndex == 0 
      || event.previousIndex == event.item.dropContainer.data.length -1 
      || event.currentIndex == event.item.dropContainer.data.length -1) {
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      isSwapped = false;
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      // Reference of read only data.
     
    }
  }
  /**
   * Delete Widgets
   */
  deleteWidget(id) {
    let d = {
      "id": id
    }
    this.widgetLayoutService.deleteWidgetsByClient({},id)
      .subscribe((res) => {
        this.toastService.toastMsg({
          title: "Success",
          content: "Widget Removed Successfully!!!",
          })
        // this.alertService.openSnackBar("Widget Removed Successfully");
        this.getwidgetData(true);
      }, (error) => {
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Try Again Later!!!",
          })
        // this.alertService.openErrorSnackBar("Please Try Again Later!!!");
      })
  }

  /**
   * Add new Widgets
   */
  addNewWidgets() {
    this.finalPostData = [
      {
        "sequence": this.formArr.length + 1,
        "type": this.addWidgets.value.widegtType,
        "data": [{}],
        "pageNumber": 1,
        "pageName": "Home",
      }
    ]
    this.widgetLayoutService.postWidgetsByClient(this.finalPostData)
      .then((res) => {
        this.toastService.toastMsg({
          title: "Success",
          content: "Widgets Added!!!",
          })
        // this.alertService.openSnackBar("Widgets Added");
        this.getwidgetData(true);
      }, (error) => {
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Try Again Later!!!",
          })
        // this.alertService.openErrorSnackBar("Please Try Again Later!!!");
      })
  }

  arrangeWidgets(data) {
    let footer:any;
    let header:any;
    data.map((item,index,arr) => {
      if(item.type == 'Footer') {
        footer = arr.splice(index,1)[0];
      }
      if(item.type == 'Header') {
        header = arr.splice(index,1)[0];
      }
    });
    this.defaultData.push(footer);
    this.defaultData.unshift(header);
  }
  /**
   * Image Upload
   */
  sliderImageUpload(file,index,sliderId) {
    const fileData = file.target.files[0];
    this.filename = file.target.files[0].name;
    if (fileData) {
      this.filepath = `iceipts/widgets/slider/${this.filename}` + this.currentUser.id + (Math.random() *100);
      // this.fireService.upload(this.filepath,fileData).then(url =>{
      //   this.downloadableURL = url;
      //   const content = this.formArr.controls[index].get('slider') as FormArray;
      //   content.controls[sliderId].patchValue({
      //     imagePath: this.filepath,
      //     imageUrl: url, 
      //   });
      // })
    }
  }
  headerImageUpload(file,index){
    const fileData = file.target.files[0];
    this.filename = file.target.files[0].name;
    if (fileData) {
      this.filepath = `iceipts/widgets/header/${this.filename}`  + this.currentUser.id + (Math.random() *100);
      // this.fireService.upload(this.filepath,fileData).then(url =>{
      //   this.downloadableURL = url;
      //   const content = this.formArr.controls[index];
      //   content.patchValue({
      //     imagePath: this.filepath,
      //     imageUrl: url, 
      //   });
      // })
    }
  }
  imageUpload(file,index){
    const fileData = file.target.files[0];
    this.filename = file.target.files[0].name;
    if (fileData) {
      this.filepath = `iceipts/widgets/image/${this.filename}`  + this.currentUser.id + (Math.random() *100);
      // this.fireService.upload(this.filepath,fileData).then(url =>{
      //   this.downloadableURL = url;
      //   const content = this.formArr.controls[index];
      //   content.patchValue({
      //     imagePath: this.filepath,
      //     imageUrl: url, 
      //   });
      // })
    }
  }
  galleryImageUpload(file,index,galleryId,value) {
    const fileData = file.target.files[0];
    this.filename = file.target.files[0].name;
    if (fileData) {
      this.filepath = `iceipts/widgets/gallery/${this.filename}`  + this.currentUser.id + (Math.random() *100);
      // this.fireService.upload(this.filepath,fileData).then(url =>{
      //   this.downloadableURL = url;
      //   const content = this.formArr.controls[index].get('gallery') as FormArray;
      //   content.controls[galleryId].get(`imageUrl${value}`).patchValue(url);
      //   // content.controls[galleryId].get(`imagePath${value}`).patchValue(this.filepath);
      // })
    }
  }
  deleteImage(filepath) {
    // this.fireService.delete(filepath).then(del=>{
    //   this.downloadableURL = '../../../assets/images/imageAdd.jfif';
    //   // this.deleteURL = this.downloadableURL;
    // })
  }

  /**
   * View in full screen
   */
  ViewFullScreen() {
    this.isFullScreeenMode = ! this.isFullScreeenMode; 
  }
}
