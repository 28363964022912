import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { PartGroupServiceService } from 'src/app/core/services/part-group-service.service';
import { PartyService } from 'src/app/core/services/party.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContinuePopupModalComponent } from '../continue-popup-modal/continue-popup-modal.component';
import lang from "src/assets/langTranslation/language_translation"

@Component({
  selector: 'app-edit-party',
  templateUrl: './edit-party.component.html',
  styleUrls: ['./edit-party.component.scss']
})
export class EditPartyComponent implements OnInit {
  public lang = lang.UAE;
  private pincodeSubscription: Subscription;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public today: Date;
  public maxDate: Date;
  public PartyForm: FormGroup;
  public isShippingAddress: boolean;
  public data: any;
  public partyId: string = "";
  public selectedYear: number;
  public years: number[] = [];
  public gstDetails: any;
  public isDisabledGST: boolean = true;
  public submitted: boolean = false;
  public todayDate: any;
  public updatePartyFinalObject;
  public filteredOptions: Observable<string[]>;
  public partyName = new FormControl();
  public partyGroupData: any = []
  public selectedGroupId: any = []
  public slectedGroup: any;
  public selectedClient: any[] = []
  public p = 1;
  public currentpage: any;
  public totalrow: any;
  public toatlPages: any;
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  public pagesize: number = 1;
  public count: number = 0;
  partyListLength: any;
  isOn = false;
  groupNames: any;
  isArabic: boolean;
  gstTypes = [
    { label: 'Regular', value: 'REGULAR' },
    { label: 'Composite', value: 'COMPOSITE' },
    { label: 'Exempted', value: 'EXEMPTED' }
  ];
  groupId: any;
  formArrayData: any[];
  pinDetails: any;
  updatePartyCustomerObject: { isCustomer: boolean; gstType: any; gstIn: any; partyName: any; mobile: any; email: any; billingAddress: any; shippingAddress: any; openingBalance: any; asOfDate: string; balanceStatus: any; relationSince: any; userId: any; distance: any; transporation: any; payment: any; creditPeriodDay: any; creditLimit: any; noOfCredits: any; pincode: any; city: any; state: any; country: any; groupAdd: any; partyAdditional: any[]; };
  updatePartySupplierObject: any;
  countryList: any[] = [];
  isSaveButtonDisabled: boolean = false;

  constructor(private formBuilder: FormBuilder,
    public router: Router, private dialog: MatDialog,
    public partyService: PartyService,
    public route: ActivatedRoute,
    public profileService: ProfileService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
    public commonService: CommonService, private partyGroupservice: PartGroupServiceService,
    public dialogRef: MatDialogRef<EditPartyComponent>,
    @Inject(MAT_DIALOG_DATA) public partyData: any
  ) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25)
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year >= 1901; year--) {
      this.years.push(year);
    }
  }
  isShown: boolean;
  ngOnInit(): void {
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });
    this.partyId = this.partyData.partyId
    this.getAllGroupList();
    if (this.isArabic) {
      this.PartyForm = this.formBuilder.group({
        gstType: [null],
        gstIn: [null, [
          Validators.minLength(15), Validators.maxLength(15)]],
        status: [],
        legalName: [],
        partyName: ["", [Validators.required]],
        mobile: [, [Validators.minLength(9), Validators.maxLength(9), Validators.pattern("^[0-9]*$")]],
        // email: [null, [Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"), Validators.email]],
        email: ["", [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"),Validators.email]],

        billingAddress: [null, [Validators.required]],
        shippingAddress: [null],
        openingBalance: [null],
        asOfDate: [null],
        balanceStatus: ["1"],
        relationSince: [null],
        userId: [""],
        distance: [null],
        transporation: [null],
        addtionalDetails: new FormArray([this.createaddtionalDetails()]),
        isCustomer: [true],
        creditPeriod: [""],
        creditLimit: [""],
        noOfCredit: ["", [Validators.pattern("^[0-9]*$")]],
        PaymentMode: [""],
        pincode: [""],
        city: [""],
        state: [""],
        country: ["United Arab Emirates"],
        partyGrpArr: new FormControl([]),
      });
    } else {
      this.PartyForm = this.formBuilder.group({
        gstType: [null, [Validators.required]],
        gstIn: [null, [Validators.pattern("^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$"),
        Validators.minLength(15), Validators.maxLength(15)]],
        status: [],
        legalName: [],
        partyName: ["", [Validators.required]],
        mobile: [, [Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]],
        // email: [null, [Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"), Validators.email]],
        email: ["", [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"),Validators.email]],

        billingAddress: [null, [Validators.required]],
        shippingAddress: [null],
        openingBalance: [null],
        asOfDate: [null],
        balanceStatus: ["1"],
        relationSince: [null],
        userId: [""],
        distance: [null],
        transporation: [null],
        addtionalDetails: new FormArray([this.createaddtionalDetails()]),
        isCustomer: [true],
        creditPeriod: [""],
        creditLimit: [""],
        noOfCredit: ["", [Validators.pattern("^[0-9]*$")]],
        PaymentMode: [""],
        pincode: [""],
        city: [""],
        state: [""],
        country: ["India"],
        partyGrpArr: new FormControl([]),
      });
    }
    this.todayDate = this.commonService.getTodaysDate()
    // this.route.paramMap.subscribe((p) => {
    //   this.partyId = p.get("id");
    // });
    this.getPartyDetails()
    // this.pincodeSubscription = this.PartyForm.get('pincode').valueChanges
    // .pipe(debounceTime(500)) // Add a debounce time to avoid making API calls on every keystroke
    // .subscribe((pincode) => {
    //   this.getPincodeDetails(pincode);
    // });
    this.getAllCountries()
  }

  get selectedGroups(): string[] {
    return this.PartyForm.get('groupName').value;
  }

  // Handle the selection change
  onSelectionChange(selected: string[]): void {
    this.PartyForm.get('groupName').setValue(selected);
  }

  getPincodeDetails(pin) {
    this.profileService.getUserPincode({}, pin).then((res) => {
      this.pinDetails = res;
      this.PartyForm.patchValue({
        city: res.data.districtName,
        state: res.data.stateName,
      });
    });
  }

  getAllCountries() {
    this.partyService.getAllCountries({}, "").then((res) => {
      this.countryList = res.data;
      console.log(this.countryList);
    });
  }

  continueModal() {
    const dialogRef = this.dialog.open(ContinuePopupModalComponent, {
      width: '300px', // Adjust the width as needed
      disableClose: true, // Prevent closing the dialog by clicking outside
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // User clicked "Leave"
        this.isSaveButtonDisabled = true;
      } else {
        // User clicked "Continue Anyway"
        this.isSaveButtonDisabled = false;
      }
    });
  }

  get f() {
    return this.PartyForm.controls;
  }
  /**
   * get controls of form array
   */
  get additionalDetailsControls() {
    return this.PartyForm.get('addtionalDetails') as FormArray;
  }
  /**
   * create formarray controls ofr sdditional address
   * @returns 
   */
  createaddtionalDetails(): FormGroup {
    return new FormGroup({
      department: new FormControl(""),
      additionalMobile: new FormControl(""),
      additionalContact: new FormControl("",),
      additionalId: new FormControl(null),
    });
  }
  /**
   * Add form array on click
   */
  addAddtionalDetails(): void {
    const data = this.PartyForm.get('addtionalDetails') as FormArray;
    data.push(this.createaddtionalDetails());
    // console.log(data)
  }
  /**
   * delete form array using index
   * @param index 
   */
  deleteaddAddtionalDetails(index: number, additionalId: any) {
    const data = this.PartyForm.get('addtionalDetails') as FormArray;
    // console.log(additionalId);
    if (additionalId) {
      this.partyService.deletePartyAdditional({}, additionalId).then((res) => {
        if (res.success) {
          this.toastService.toastMsg({
            title: "Success",
            content: "Additional Details Record Deleted Successfully!!!",
          });
          // this.toastService.openSnackBar("Additional details record deleted successfully!!!")
        }
      }, (err) => {
        if (err.error) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      });
    }
    data.removeAt(index);
  }
  /**
   * toggle for shipping address
   * @param checked 
   */
  showShippingAddress(checked: boolean) {
    this.isShippingAddress = checked;
  }
  /**
   * get party details with id
   */
  getPartyDetails() {
    this.additionalDetailsControls.clear();
    this.partyService.getPartyDetails({}, this.partyId).then((res) => {
      // console.log(res.data);
      let groupData;
      groupData = res.data.group.map((item) => item.id);
      console.log(groupData)
      this.selectedClient = groupData; // Populate the selectedClient array with group IDs
      console.log("selectedClient", this.selectedClient)

      this.data = res.data.a;
      res.data = res.data.a;

      if (res.data.isCustomer == true) {
        this.isShown = true;
      } else {
        this.isShown = false
      }
      let partyList = res.data.parties_additionals;

      this.partyListLength = partyList.length;
      if (this.partyListLength) {
        for (let i = 0; i < this.partyListLength; i++) {
          this.additionalDetailsControls.push(
            new FormGroup({
              department: new FormControl(partyList[i].department),
              additionalMobile: new FormControl(partyList[i].additionalMobile),
              additionalContact: new FormControl(partyList[i].additionalContact),
              additionalId: new FormControl(partyList[i].id),
            })
          )
        }
      }

      this.PartyForm.patchValue({
        isCustomer: res.data.isCustomer ? res.data.isCustomer : false,
        asOfDate: res.data.asOfDate ? (moment(res.data.asOfDate).format("DD-MMM-YYYY")) : (moment(this.maxDate).format("DD-MMM-YYYY")),
        balanceStatus: res.data.balanceStatus,
        billingAddress: res.data.billingAddress,
        distance: res.data.distance,
        email: res.data.email,
        gstIn: res.data.gstIn,
        gstType: res.data.gstType ? res.data.gstType : null,
        status: res.data.status,
        id: res.data.id,
        mobile: res.data.mobile,
        openingBalance: res.data.openingBalance,
        partyName: res.data.partyName,
        relationSince: new Date(res.data.relationSince).getFullYear(),
        shippingAddress: res.data.shippingAddress,
        transporation: res.data.transporation,
        userId: res.data.userId,
        PaymentMode: res.data.payment,
        creditPeriod: res.data.creditPeriodDay,
        creditLimit: res.data.creditLimit,
        noOfCredit: res.data.noOfCredits,
        pincode: res.data.pincode,
        city: res.data.city,
        state: res.data.state,
        country: res.data.country,
      })
      this.PartyForm.patchValue({
        partyGrpArr: this.selectedClient,
      })

      if (this.PartyForm.value.gstIn !== null) {
        if (this.isArabic) {
          this.isDisabledGST = false;
          return
        }
        this.profileService.getGstNumber({}, this.PartyForm.value.gstIn).then(
          (res) => {
            this.PartyForm.patchValue({
              gstType: res.data.taxPayerType.toUpperCase(),
              legalName: res.data.legalName,
              status: res.data.status
    
            })
            if (res && res.data && res.data.status === 'Active') {
              this.isDisabledGST = false
              this.isSaveButtonDisabled = false
            } else {
              this.isDisabledGST = true
              this.continueModal()
            }
          }, (err) => {
            this.continueModal()
            this.isDisabledGST = true
          })
      }
    })

  }
  /**
   * Update party details
   */

  updateParty() {
    console.log("this.PartyForm.controls", this.PartyForm.controls)
    this.submitted = true;
    if (this.isShown) {
      if ((this.PartyForm.controls.billingAddress.value == "" || this.PartyForm.controls.partyName.value == ""
        || this.PartyForm.controls.mobile.value == "" || this.PartyForm.controls.email.value == "") && (this.PartyForm.invalid)) {
        // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
        return false;
      } else if ((this.PartyForm.controls.billingAddress.value == "" && this.PartyForm.controls.partyName.value == ""
        && this.PartyForm.controls.mobile.value == "" && this.PartyForm.controls.email.value == "") && (this.PartyForm.invalid)) {
        // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
      }
      else {
        this.updatePartyObject();
      }
    }
    else {
      if ((this.PartyForm.controls.billingAddress.value == "" || this.PartyForm.controls.partyName.value == ""
        || this.PartyForm.controls.mobile.value == "" || this.PartyForm.controls.email.value == ""
        || this.PartyForm.controls.gstType.value == "" || this.PartyForm.controls.gstIn.value == "") && (this.PartyForm.invalid)) {
        // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
        return false;
      } else if ((this.PartyForm.controls.billingAddress.value != "" && this.PartyForm.controls.partyName.value != ""
        && this.PartyForm.controls.mobile.value != "" && this.PartyForm.controls.email.value != ""
        && this.PartyForm.controls.gstType.value != "" && this.PartyForm.controls.gstIn.value != "") && (this.PartyForm.invalid)) {
        // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
        // this.addPartyObject()
      } else {
        this.updatePartyObject();
      }
    }
    this.router.navigate(["pages/party-page/"]);

  }
  updatePartyObject() {
    this.formArrayData = this.additionalDetailsControls.value;
    if (!this.isShown === false) {
      this.updatePartyCustomerObject = {
        "isCustomer": this.isShown,
        "gstType": null,
        "gstIn": null,
        "partyName": this.PartyForm.controls.partyName.value,
        "mobile": this.PartyForm.controls.mobile.value,
        "email": this.PartyForm.controls.email.value.toLowerCase(),
        "billingAddress": this.PartyForm.controls.billingAddress.value ? this.PartyForm.controls.billingAddress.value : null,
        "shippingAddress": this.PartyForm.controls.shippingAddress.value,
        "openingBalance": this.PartyForm.controls.openingBalance.value,
        "asOfDate": moment(this.PartyForm.controls.asOfDate.value, 'DD-MMM-YYYY').format('YYYY-MM-DD'),
        "balanceStatus": this.PartyForm.controls.balanceStatus.value,
        "relationSince": this.PartyForm.controls.relationSince.value,
        "userId": this.currentUser.id,
        "distance": this.PartyForm.controls.distance?.value?.toString(),
        "transporation": this.PartyForm.controls.transporation.value,
        "payment": this.PartyForm.controls.PaymentMode.value ? this.PartyForm.controls.PaymentMode.value : null,
        "creditPeriodDay": this.PartyForm.controls.creditPeriod.value,
        "creditLimit": this.PartyForm.controls.creditLimit.value,
        "noOfCredits": this.PartyForm.controls.noOfCredit.value,
        "pincode": this.PartyForm.controls.pincode.value,
        "city": this.PartyForm.controls.city.value,
        "state": this.PartyForm.controls.state.value,
        "country": this.PartyForm.controls.country.value,
        "groupAdd": this.PartyForm.controls.partyGrpArr.value || [],
        "partyAdditional": this.formArrayData
      }
      this.updateSaveParty(this.updatePartyCustomerObject)
    } else if (this.isArabic && !this.isShown) {
      this.updatePartySupplierObject = {
        "isCustomer": this.isShown,
        // "gstType": this.PartyForm.controls.gstType.value ? this.PartyForm.controls.gstType.value : null,
        "gstIn": this.PartyForm.controls.gstIn.value,
        "status": this.PartyForm.controls.status.value,
        "partyName": this.PartyForm.controls.partyName.value,
        "mobile": this.PartyForm.controls.mobile.value,
        "email": this.PartyForm.controls.email.value.toLowerCase(),
        "billingAddress": this.PartyForm.controls.billingAddress.value ? this.PartyForm.controls.billingAddress.value : null,
        "shippingAddress": this.PartyForm.controls.shippingAddress.value,
        "openingBalance": this.PartyForm.controls.openingBalance.value,
        "asOfDate": moment(this.PartyForm.controls.asOfDate.value, 'DD-MMM-YYYY').format('YYYY-MM-DD'),
        "balanceStatus": this.PartyForm.controls.balanceStatus.value,
        "relationSince": this.PartyForm.controls.relationSince.value,
        "userId": this.currentUser.id,
        "distance": this.PartyForm.controls.distance?.value?.toString,
        "transporation": this.PartyForm.controls.transporation.value,
        "payment": this.PartyForm.controls.PaymentMode.value ? this.PartyForm.controls.PaymentMode.value : null,
        "creditPeriodDay": this.PartyForm.controls.creditPeriod.value,
        "creditLimit": this.PartyForm.controls.creditLimit.value,
        "noOfCredits": this.PartyForm.controls.noOfCredit.value,
        "pincode": this.PartyForm.controls.pincode.value,
        "city": this.PartyForm.controls.city.value,
        "state": this.PartyForm.controls.state.value,
        "country": this.PartyForm.controls.country.value,
        "groupAdd": this.PartyForm.controls.partyGrpArr.value || [],
        "partyAdditional": this.formArrayData
      }
      this.updateSaveParty(this.updatePartySupplierObject)
    } else {
      this.updatePartySupplierObject = {
        "isCustomer": this.isShown,
        "gstType": this.PartyForm.controls.gstType.value ? this.PartyForm.controls.gstType.value : null,
        "gstIn": this.PartyForm.controls.gstIn.value,
        "status": this.PartyForm.controls.status.value,
        "partyName": this.PartyForm.controls.partyName.value,
        "mobile": this.PartyForm.controls.mobile.value,
        "email": this.PartyForm.controls.email.value.toLowerCase(),
        "billingAddress": this.PartyForm.controls.billingAddress.value ? this.PartyForm.controls.billingAddress.value : null,
        "shippingAddress": this.PartyForm.controls.shippingAddress.value,
        "openingBalance": this.PartyForm.controls.openingBalance.value,
        "asOfDate": moment(this.PartyForm.controls.asOfDate.value, 'DD-MMM-YYYY').format('YYYY-MM-DD'),
        "balanceStatus": this.PartyForm.controls.balanceStatus.value,
        "relationSince": this.PartyForm.controls.relationSince.value,
        "userId": this.currentUser.id,
        "distance": this.PartyForm.controls.distance?.value?.toString,
        "transporation": this.PartyForm.controls.transporation.value,
        "payment": this.PartyForm.controls.PaymentMode.value ? this.PartyForm.controls.PaymentMode.value : null,
        "creditPeriodDay": this.PartyForm.controls.creditPeriod.value,
        "creditLimit": this.PartyForm.controls.creditLimit.value,
        "noOfCredits": this.PartyForm.controls.noOfCredit.value,
        "pincode": this.PartyForm.controls.pincode.value,
        "city": this.PartyForm.controls.city.value,
        "state": this.PartyForm.controls.state.value,
        "country": this.PartyForm.controls.country.value,
        "groupAdd": this.PartyForm.controls.partyGrpArr.value || [],
        "partyAdditional": this.formArrayData
      }
      this.updateSaveParty(this.updatePartySupplierObject)
    }
  }

  updateSaveParty(updatePartyFinalObject) {
    this.partyService.updateParty(updatePartyFinalObject, this.partyId).then((res) => {
      if (res.success) {
        if (this.pincodeSubscription) {
          this.pincodeSubscription.unsubscribe();
        }
        this.partyService.notifyPartyAdded();
        if(this.isShown===true){
          this.toastService.toastMsg({
            title: "Success",
            content: "Customer Updated Successfully!!!!!!",
            });
        
        }else{
          this.toastService.toastMsg({
            title: "Success",
            content: "Vendor Updated Successfully!!!!!!",
            });
        }
        this.dialogRef.close();
      }
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })
  }

  validateGSTIN(GSTIN) {
    let gstNumber = GSTIN.toUpperCase();
    if (this.isArabic) {
      console.log("in validateGSTIN")
      this.getGstNumber(gstNumber)
      return
    }
    var regex =
      /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/;
    if (gstNumber.length == 15) {
      if (regex.test(gstNumber)) {
        this.getGstNumber(gstNumber);
      } else {
        this.PartyForm.controls.gstIn.setErrors({ incorrect: true });
      }
    } else {
      this.PartyForm.controls.gstIn.setErrors({ incorrect: true });
    }
  }

  validateTRN(TRN) {
    if (TRN.length === 15) {
      if (this.isArabic) {
        this.isDisabledGST = false;
        return true
      } else {
        this.isDisabledGST = true;
        this.PartyForm.controls.gstIn.setErrors({ incorrect: true });
        return
      }
    } else {
      this.isDisabledGST = true;
      this.PartyForm.controls.gstIn.setErrors({ incorrect: true });
      // this.setGSTINError = true;
    }
  }

  getGstNumber(gstin) {
    console.log("in get gst number")
    if (this.isArabic) {
      this.isDisabledGST = false;
      return
    }
    this.partyService.getGstNumber({}, gstin).then((res) => {
      this.gstDetails = res;
      console.log(res.data);
      if (res) {
        this.PartyForm.patchValue({
          gstType: res.data.taxPayerType.toUpperCase(),
          legalName: res.data.legalName,
          status: res.data.status

        })
        if (res && res.data && res.data.status === 'Active') {
          this.isDisabledGST = false;
          this.isSaveButtonDisabled = false;
        } else {
          this.continueModal()
          this.isDisabledGST = true;
        }
      }
    }, (err) => {
      this.continueModal()
      this.isDisabledGST = true
    })
  }
  /**
   * update additional details of party
   * @param item 
   */
  updateAdditionalDetailsbyId(item) {
    let data = {
      "department": item.value.department,
      "additionalMobile": item.value.additionalMobile,
      "additionalContact": item.value.additionalContact,
    }
    this.partyService.updateAdditionalDetails(data, item.value.id).then((res) => { })
  }
  /**
   * toggle for isCustomer
   */
  toggleShow(value) {
    this.isShown = value;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  StringOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;

  }

  getAllGroupList() {
    this.partyGroupservice.getPartiGroupsDetails({}, this.currentUser.id, this.partyPageSize, (this.currentPageNo + 1)).then((res) => {

      this.partyGroupData = res.data.pageData.map((data) => data.group)
      this.filteredOptions = this.partyName.valueChanges
        .pipe(
          startWith(''),
          map(name => name ? this.filterNamesGroups(name) : this.partyGroupData.slice())
        );
      console.log(this.filteredOptions)
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    })
  }

  filterNamesGroups(name: string): string[] {
    return this.partyGroupData.filter(
      (option) => option.groupName.toLowerCase().indexOf(name.toLowerCase()) === 0
    ).map((option) => option.groupName);
  }


  cancel() {
    this.PartyForm.reset();
    this.submitted = false;
    this.dialogRef.close();
  }
  openDatepicker() {
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.PartyForm.get('asOfDate').value;
    if (isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.PartyForm.get('asOfDate').setValue(new Date());
    }
  }


}

