import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { BsDatepickerConfig, BsDatepickerViewMode } from 'ngx-bootstrap/datepicker';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
interface IRange {
	value: Date[];
	label: string;
}
@Component({
	selector: 'app-invoice-number',
	templateUrl: './invoice-number.component.html',
	styleUrls: ['./invoice-number.component.scss']
})
export class InvoiceNumberComponent implements OnInit {
	public isCalendar: boolean = false;
	public today: Date;
	public maxDate: Date;
	public currentUser = JSON.parse(localStorage.getItem("currentUser"))
	public NumberForm: any;
	public isDisabled: boolean = false;
	isShown: boolean = true;
	isShownm: boolean = true;
	public yearValue: any;
	public monthValue: any;
	public showbutton: boolean = false;
	public Id: any;
	public invoiceNo: any;
	public lastInvoiceNumber: any;
	public checked = false;
	public indeterminate = false;
	public labelPosition: 'before' | 'after' = 'after';
	public disabled = true;
	public FormatId: any;
	public isShowButton: boolean = false;
	public openPreview: boolean = false;
	public prefixOne: any;
	public seprator1: any;
	public prefixTwo: any;
	public seprator2: any;
	public calendarTypeTxt: any;
	public year: any;
	public yearformat: any;
	public month: any;
	public numberOfZeros: any;
	public monthformat: any;
	public lastDigit: any;
	public calenderYearformat: any;
	public calenderMonthformat: any;
	public financialYear: any;
	public monthShortName: any;
	public monthNumber: any;
	public currentYear: any;
	public twoDigitFinancialYear: number;
	constructor(private formBuilder: FormBuilder,
		private saleInvoiceService: SaleInvoiceService,
		private route: ActivatedRoute,
		private toastService: ToastNotificationService,
	) {
		this.today = new Date();
		this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25)
		if ((this.today.getMonth() + 1) <= 3) {
			this.financialYear = (this.today.getFullYear() - 1) + "-" + this.today.getFullYear()
		} else {
			this.financialYear = this.today.getFullYear() + "-" + (this.today.getFullYear() + 1)
		}
		this.monthShortName = this.today.toLocaleString('en-US', { month: 'short' });
		this.monthNumber = this.today.getMonth() + 1;
		this.currentYear = this.today.getFullYear();
		this.twoDigitFinancialYear = parseInt(this.today.toLocaleDateString('en', { year: '2-digit' }));
	}

	ngOnInit(): void {
		this.NumberForm = this.formBuilder.group({
			calendarType: [""],
			prefixOne: [""],
			prefixTwo: [""],
			seprator1: [""],
			year: [false],
			month: [false],
			yearformat: [],
			monthformat: [],
			seprator2: [""],
			numberOfZeros: [""],
			lastDigit: ["1"],
			invoiceNo: [""]
		});
		this.getInvoiceNumber();
		//this.getSaleInvoiceNumber();
	}

	openSalesPreview() {
		this.openPreview = !this.openPreview;
	}


	toggleDisplay() {
		this.isShown = !this.isShown;
		// console.log(this.isShown);
		// console.log(this.yearformat);

		// if (this.isShown && this.yearformat == undefined) {
		// 	console.log("inside");
		// 	this.yearformat = " ";
		// 	console.log(this.yearformat);
		// } else if (this.isShown && this.yearformat != undefined) {
		// 	console.log("inside 2nd");
		// 	// if(this.calenderYearformat == this.twoDigitFinancialYear + "-" + (this.twoDigitFinancialYear + 1) 
		// 	// || this.calenderYearformat == this.currentYear 
		// 	// || this.calenderYearformat == this.financialYear)
		// 	// {
		// 		console.log("inside 2nd");
		// 	//this.calenderYearformat = " ";
		// 	this.yearformat = " ";
		// 	console.log(this.yearformat);
		// 	// }
		// }
	}

	toggleDisplaym() {
		this.isShownm = !this.isShownm;
		// console.log(this.isShown);
		// if (this.isShown && this.yearformat == undefined) {
		// 	console.log("inside");
		// 	this.monthformat = "";
		// }
	}

	/**
	 * Save Invoice Number
	 */
	postInvoiceNumber() {
		let data = {
			"userId": this.currentUser.id,
			"calendarType": this.NumberForm.controls.calendarType.value,
			"prefixOne": this.NumberForm.controls.prefixOne.value,
			"prefixTwo": this.NumberForm.controls.prefixTwo.value,
			"seprator1": this.NumberForm.controls.seprator1.value,
			"year": this.NumberForm.controls.year.value,
			"month": this.NumberForm.controls.month.value,
			"yearformat": this.NumberForm.controls.yearformat.value,
			"monthformat": this.NumberForm.controls.monthformat.value,
			"seprator2": this.NumberForm.controls.seprator2.value,
			"numberOfZeros": this.NumberForm.controls.numberOfZeros.value,
			"lastDigit": 1,

		}
		this.saleInvoiceService.postInvoiceNumber(data).then((res) => {
			if (res) {
				this.toastService.toastMsg({
					title: "Success",
					content: "Invoice Number Added Successfully!!!",
				  });
				// this.toastService.openSnackBar("Invoice Number Added Successfully")
				this.getInvoiceNumber();
			}

		}, (err) => {
			this.toastService.toastMsg({
				title: "Error",
				content: "Please Try Again Later!!!",
			  });
			// this.toastService.openErrorSnackBar("Please Try Again Later!!!")
		})

	}

	/**
	 * Update Invoice Number Method
	*/
	updateInvoiceNumber() {
		let data = {
			"userId": this.currentUser.id,
			"calendarType": this.NumberForm.controls.calendarType.value,
			"prefixOne": this.NumberForm.controls.prefixOne.value,
			"prefixTwo": this.NumberForm.controls.prefixTwo.value,
			"seprator1": this.NumberForm.controls.seprator1.value,
			"year": this.NumberForm.controls.year.value,
			"month": this.NumberForm.controls.month.value,
			"yearformat": this.NumberForm.controls.yearformat.value,
			"monthformat": this.NumberForm.controls.monthformat.value,
			"seprator2": this.NumberForm.controls.seprator2.value,
			"numberOfZeros": this.NumberForm.controls.numberOfZeros.value,
			"lastDigit": 1,
		}
		this.saleInvoiceService.updateInvoiceNumber(data, this.FormatId).then((res) => {
			if (res) {
				this.toastService.toastMsg({
					title: "Success",
					content: "Invoice Number Updated Successfully!!!",
				  });
				// this.toastService.openSnackBar("Invoice Number Updated Succesfully")
				this.getInvoiceNumber()
			}

		}, (err) => {
			this.toastService.toastMsg({
				title: "Error",
				content: "Please Try Again Later!!!",
			  });
			// this.toastService.openErrorSnackBar("Please Try Again Later!!!")
		})


	}

	/**
	 * get calendar type
	 * @param value 
	 */
	calendarType(value) {
		this.calenderMonthformat = "";
		this.calenderYearformat = "";
		this.yearformat = "";
		this.monthformat = "";
		if (value == 'CALENDAR') {
			this.isCalendar = true
		}
		else {
			this.isCalendar = false
		}

	}

	/**
	 * get Invoice number
	 */
	getInvoiceNumber() {
		this.saleInvoiceService.getInvoiceNumber({}, this.currentUser.id).then((res) => {
			if (res) {
				this.isShowButton = true
			}
			this.FormatId = res.data.id
			if (res.data.calendarType == 'CALENDAR') {
				this.isCalendar = true;
			} else {
				this.isCalendar = false
			}
			if (res.data.year == 'true') {
				this.isShown = true;
			} else {
				this.isShown = false
			}
			if (res.data.month == 'true') {
				this.isShownm = true;
			} else {
				this.isShownm = false
			}
			this.NumberForm.patchValue({
				invoiceNo: res.data.invoiceNo,
				calendarType: res.data.calendarType,
				prefixOne: res.data.prefixOne,
				prefixTwo: res.data.prefixTwo,
				seprator1: res.data.seprator1,
				year: res.data.year,
				month: res.data.month,
				yearformat: res.data.yearformat,
				monthformat: res.data.monthformat,
				seprator2: res.data.seprator2,
				numberOfZeros: res.data.numberOfZeros,
				lastDigit: res.data.lastDigit,
			})
			this.lastDigit = res.data.lastDigit
			this.calenderYearformat = res.data.yearformat
			if (this.calenderYearformat == "YYYY-YYYY") {
				this.yearformat = this.financialYear
			} else if (this.calenderYearformat == "YYYY") {
				this.yearformat = this.currentYear;
			} else if (this.calenderYearformat = "YY-YY") {
				this.yearformat = this.twoDigitFinancialYear + "-" + (this.twoDigitFinancialYear + 1)
			}
			this.calenderMonthformat = res.data.monthformat
			if (this.calenderMonthformat == "MMM") {
				this.monthformat = this.monthShortName
			} else if (this.calenderMonthformat == "MM") {
				this.monthformat = this.monthNumber
			}
		}, (error) => {
			if (error.error.status == 404) {
				this.isShowButton = false
			}
		})
	}

	/**
	 * get & set no of zeroes
	 * @param event 
	 */
	getNoOfZeros(event) {
		if (event == 1) {
			this.numberOfZeros = "0";
		} else if (event == 2) {
			this.numberOfZeros = "00";
		} else if (event == 3) {
			this.numberOfZeros = "000";
		} else if (event == 4) {
			this.numberOfZeros = "0000";
		} else if (event == 5) {
			this.numberOfZeros = "00000";
		} else if (event == 6) {
			this.numberOfZeros = "000000";
		} else if (event == 7) {
			this.numberOfZeros = "0000000";
		} else if (event == 8) {
			this.numberOfZeros = "00000000";
		}
	}

	getMonthFormat(monthType) {
		if (monthType == "MMM") {
			this.monthformat = this.monthShortName
		} else if (monthType == "MM") {
			this.monthformat = this.monthNumber
		}
	}
	getYearFormat(yearType) {
		if (yearType == "YYYY-YYYY") {
			this.yearformat = this.financialYear
		} else if (yearType == "YYYY") {
			this.yearformat = this.currentYear;
		} else if (yearType = "YY-YY") {
			this.yearformat = this.twoDigitFinancialYear + "-" + (this.twoDigitFinancialYear + 1)
		}
	}
}
