<div class="row">
    <div class="col-9">
        <div class="card mb-2 p-2 mt-1">
            <h3>Saved Details</h3>
        </div>
        <div class="card  mb-2 p-2">
            <h3>RBL Bank credit cash  XXXXXXXXX19</h3>
            <div class="row">
                <div class="col-2">
                    <button class="btn btn-warning-outline"> CVV</button>
                </div>
                <div class="col-8"></div>
                <div class="col-2">
                    <button class="btn btn-warning">Continue</button>
                </div>

            </div>
          
            

        </div>
        <div class="card mb-2 p-2">
            <h3>Payment Option</h3>
        </div>
    </div>
    <div class="col-3">
        <div class="card p-2">
            <h4>Price Details</h4>
            <hr>
            <h4>Price (1 Item)  30000</h4>
            <h4>delivery charges  50rs </h4>
            <hr>
            <h4>Total amount   300000 </h4>
        </div>
    </div>

</div>

<div class="row">
    <div class="col-9 ">
        <div class="card p-2 mb-2">
            <mat-radio-group aria-label="Select an option">
                <mat-radio-button value="1">UPI</mat-radio-button>
                <hr>
                <mat-radio-button value="2">Credit/Debit/ATM Card</mat-radio-button>
                <hr>
                <mat-radio-button value="2">Net Banking</mat-radio-button>
                <hr>
                <mat-radio-button value="2">Cash On Delivery</mat-radio-button>
                <hr>
              </mat-radio-group>
        </div>
      
    </div>
    <div class="col-3"></div>
</div>
<div class="row">
    <div class="card">

<div class="offset-10 col-3 btn-lg text-right">
    <button class="btn btn-warning">Proceed</button>  
</div>
</div>

</div>