
<div class="card-body">
    <div class="row flex-between-center">
        <div class="col-sm-auto mb-2 mb-sm-0">
            <h2 class="mb-0">Appointment Type</h2>
        </div>
        <div class="col-sm-auto">
            <div class="row gx-2 align-items-center">
                <div class="col-auto">
                    <button class="col-auto pe-0"><a class="text-600 px-1"
                        data-bs-toggle="tooltip">cancel</a>
                    </button>
                </div>
                <button class="col-auto pe-0" (click)="saveAppointForm()"><a class="text-600 px-1"
               data-bs-toggle="tooltip">save</a>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="card-body">
    <form [formGroup]="appointMentForm">
        <div class=" row col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-12">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Name</mat-label>
                <input matNativeControl type="text"  required matInput formControlName="Name"
                    placeholder="Name" [ngClass]="{
                    'is-invalid': f.Name.invalid && submitted
                    }" value="">
                <mat-error *ngIf="f.Name.invalid && submitted" class="invalid-feedback">
                    <div style="font-weight: 400; font-size: 11px; " *ngIf="f.Name.errors.required">Name ID Is Required!</div>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="row d-flex justify-content-start">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                
                <mat-form-field class="example-full-width" appearance="outline">
                   
                    <mat-label>Duration</mat-label>
                    
                    <input matNativeControl type="text"  required matInput formControlName="Name"
                        placeholder="Duration" [ngClass]="{
                        'is-invalid': f.Duration.invalid && submitted
                        }" value="">

                    <mat-error *ngIf="f.Duration.invalid && submitted" class="invalid-feedback">
                        <div style="font-weight: 400; font-size: 11px; " *ngIf="f.Duration.errors.required">Duration Is Required!</div>
    
                    </mat-error>
             
                </mat-form-field>
            
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Location Type</mat-label>
                    <!-- <input matNativeControl type="text"  required matInput formControlName="LocationType"
                        placeholder="Lacation Type" [ngClass]="{
                        'is-invalid': f.LocationType.invalid && submitted
                        }" value=""> -->
                        <mat-select formControlName="LocationType">
                            <mat-option *ngFor="let location of locations"[value]="location.id">
                                {{location.locationName}}
                            </mat-option>
                        </mat-select>
                    <mat-error *ngIf="f.LocationType.invalid && submitted" class="invalid-feedback">
                        <div style="font-weight: 300; font-size: 11px; " *ngIf="f.LocationType.errors.required">Location Type Is Required!</div>
                    </mat-error>
                </mat-form-field>
            </div> 
        </div>

        <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-0">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>User Role</mat-label>
                <!-- <input matNativeControl type="text"  required matInput formControlName="UserRole"
                    placeholder="User Role" [ngClass]="{
                    'is-invalid': f.UserRole.invalid && submitted
                    }" value=""> -->
                    <mat-select formControlName="UserRole">
                        <mat-option *ngFor="let userR of userRoles"[value]="userR.id">
                            {{userR.userRole}}
                        </mat-option>
                    </mat-select>

                <mat-error *ngIf="f.UserRole.invalid && submitted" class="invalid-feedback">
                    <div style="font-weight: 400; font-size: 11px; " *ngIf="f.UserRole.errors.required">User Role Is Required!</div>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Description</mat-label>
                <textarea matInput  cols="35" rows="8" placeholder="Description" formControlName="Description" [ngClass]="{
                    'is-invalid': f.Description.invalid && submitted
                    }"></textarea>
                <!-- <input matNativeControl type="text"  required matInput formControlName="Description"
                    placeholder="Description" [ngClass]="{
                    'is-invalid': f.Description.invalid && submitted
                    }" value=""> -->
                <mat-error *ngIf="f.Description.invalid && submitted" class="invalid-feedback">
                    <div style="font-weight: 400; font-size: 11px; " *ngIf="f.Description.errors.required">DescriptionIs Required!</div>
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-checkbox formControlName="QAppointMentType">
                Can Patient schedule this type of appointment
            </mat-checkbox>
        </div>
        <div>
            <mat-checkbox formControlName="QRSVPtype">
                Is RSVP enabled for this type of appointment
            </mat-checkbox>
        </div>
        <mat-label>Invites Can schedule</mat-label><br>
            <mat-radio-group formControlName="inviteSchedule" >
                <mat-radio-button value="inFuture">indefinationately In future</mat-radio-button><br>
                <mat-radio-button value=" within"> within</mat-radio-button>
              </mat-radio-group>
      
        <!-- <div>
            <div>
                <mat-label>Invites Can schedule</mat-label>
                <mat-radio-button formControlName="inviteSchedule" value="indefinationately In future" >
                  <span class="wrap-label-text">
                    indefinationately In future
                  </span>
                </mat-radio-button>
                <mat-radio-button formControlName="inviteSchedule" value="within" >
                    <span class="wrap-label-text">
                      within
                    </span>
                  </mat-radio-button>
            </div>
             
        </div> -->


      
     
    </form>
</div>

