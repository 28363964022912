<!-- <div class="modal-header row">
    <div class="col-sm-6">
        <h1 style="color: #EB8B3F; font-weight: bolder;" class="modal-title">Update {{groupName}}</h1>
    </div>
    <div class="col-sm-2"></div>
    <div class="col-sm-2">
        <button type="button" class="btn btn-cancel" (click)="cancel()">Cancel</button>
    </div>

    <div class="col-sm-2">
        <button type="button" class="btn btn-save" (click)="postPartyGroup()">Save</button>
    </div>

</div>
 -->

<div class="modal-header pt-0" id="Add-Party-modal">
  <h1 style="color: #EB8B3F; font-weight: bolder;margin-left:11px;" class="modal-title mb-0">Update {{groupName}}</h1>
  <div class="addProductButtons">

      <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel" (click)="cancel()">Cancel</button>
      <button type="button" class="btn btn-save" (click)="postPartyGroup()">Save</button>

  </div>
</div>


<div class="modal-body">


    <div class="container-fluid px-2">
        <form [formGroup]="PartyGroupForm">
            <label class="form-label">Group Name <span class="error-asterisk">*</span></label>
            <mat-form-field class="example-full-width" appearance="outline">
                <input matInput required formControlName="groupName" rows="3" placeholder="Group Name"
                    autocomplete="off" />
            </mat-form-field>
            <mat-error class="text-danger" *ngIf="
              (submitted || f.groupName.touched) && f.groupName.errors?.required
            ">
                Group name is required
            </mat-error>

            <label class="form-label">Group Description <span class="error-asterisk">*</span></label>
            <mat-form-field class="example-full-width" appearance="outline">
                <textarea matInput autocomplete="off" required formControlName="groupDescription" rows="3"
                    placeholder="Group Description"></textarea>
            </mat-form-field>
            <mat-error class="text-danger" *ngIf="
              (submitted || f.groupDescription.touched) &&
              f.groupDescription.errors?.required
            ">
                Group description is required
            </mat-error>
        </form>




        <!-- <div class="container-fluid px-4">
          <label class="example-full-width form-label"><b>Group Description</b></label>
          <h4>{{groupDescription}}</h4>
      </div> -->
        <div style="font-size: 12px" class="">
            <label class="form-label">Vendor Name  <span class="error-asterisk">*</span></label>
            <mat-form-field class="example-full-width" appearance="outline">
                <input type="text" placeholder="Vendor Name" aria-label="Select Party Name" matInput
                    (keyup)="searchPartyByName($event.target.value)" [matAutocomplete]="auto" required
                    autocomplete="off">
                <mat-autocomplete #auto="matAutocomplete" style="z-index: 9999" [multiple]="true"
                    [disableOptionCentering]="true">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
                        (onSelectionChange)="setSelectedPartyDeatails(option)">
                        {{option.partyName}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

        </div>
        <table class="table table-bordered table-striped fs--1 mb-0 mt-2 bg-white mx-2">
            <thead class="bg-200 text-900">
                <tr class="btn-reveal-trigger">
                    <th style = "vertical-align: middle;">#</th>
                    <th style = "vertical-align: middle;" class="text-nowrap">Name</th>
                    <th style = "vertical-align: middle;" class="text-nowrap">Email</th>
                    <th style = "vertical-align: middle;" class="text-nowrap">Phone</th>
                    <th style = "vertical-align: middle;" class="text-nowrap">Billing Address</th>
                    <th style = "vertical-align: middle;" class="text-nowrap">Joined</th>
                    <th style = "vertical-align: middle;">Action</th>
                </tr>

            </thead>
            <tbody>
                <tr class="btn-reveal-trigger text-left" *ngFor="let item of partyDataDetails;let i = index">
                    <td style = "vertical-align: middle;">{{ i + 1}}</td>
                    <td style = "vertical-align: middle;" class="partyName">{{ item.partyName | titlecase }}</td>
                    <td style = "vertical-align: middle;" class="email">{{ item.email }}</td>
                    <td style = "vertical-align: middle;" class="mobile">{{ item.mobile }}</td>
                    <td style = "vertical-align: middle;" class="billingAddress">{{ item.billingAddress | titlecase }}</td>
                    <td style = "vertical-align: middle;" class="asOfDate">{{ (item.createdAt ? item.createdAt: item.group_parties.createdAt) | date:"dd
                        MMM YYYY"| uppercase}}</td>
                    <td style = "vertical-align: middle;">
                        <div class="">
                            <button class="btn btnp">
                                <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip" style="color: red"
                                    data-bs-placement="top" title="Delete" (click)="openDeleteModal(item.id)"></span>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>






































<!-- <div class="card mb-3" id="customersTable">
    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
            Add Group Party
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>

    <div class="card-body">
        <p class="d-md-inline-block fs--1  ml-5" style="font-weight:500;font-size:18px!important;">
            Add Group Party
        </p>

        <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Party Name</mat-label>
                <input type="text" placeholder="Party Name" aria-label="Select Party Name" matInput
                     (keyup)="searchPartyByName($event.target.value)"
                    [matAutocomplete]="auto" required>
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
                        (onSelectionChange)="setSelectedPartyDeatails(option)">
                        {{option.partyName}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>

        <table class="table table-bordered table-striped fs--1 mb-0 bg-white">
            <thead class="bg-200 text-900">
                <tr class="btn-reveal-trigger">
                    <th>#</th>
                    <th class="text-nowrap">Name</th>
                    <th class="text-nowrap">Email</th>
                    <th class="text-nowrap">Phone</th>
                    <th class="text-nowrap">Billing Address</th>
                    <th class="text-nowrap">Joined</th>
                    <th>Action</th>
                </tr>

            </thead>
            <tbody>
                <tr class="btn-reveal-trigger text-left" *ngFor="let item of partyDataDetails;let i = index">
                    <td>{{ i + 1}}</td>
                    <td class="partyName">{{ item.partyName | titlecase }}</td>
                    <td class="email">{{ item.email }}</td>
                    <td class="mobile">{{ item.mobile }}</td>
                    <td class="billingAddress">{{ item.billingAddress | titlecase }}</td>
                    <td class="asOfDate">{{ (item.createdAt ? item.createdAt: item.group_parties.createdAt) | date:"dd MMM YYYY"| uppercase}}</td>
                    <td>
                        <div class="">
                            <button class="btn btnp" data-bs-toggle="modal" data-bs-target="#errorDelete-modal">
                                <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Delete" (click)="deleteParty(item)"></span> -->
<!-- Delete -->
<!-- </button>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="partyDataDetails == 0">
                <tr class="text-center">
                    <td colspan="10">
                        <h5 class="text-nowrap">No Party Details Added. Please Add Party Details To Be
                            Displayed
                            Here.
                             <span>
                                <a class="nav-link" routerLink="/pages/add-party">Add New Party </a>
                            </span></h5>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="col-12 d-flex  justify-content-end mt-3">
            <button type="submit" class="btn btn-outline-primary" style="margin-left: 10px;" (click)="postPartyGroup()">Save</button>
        </div>
    </div>
</div> -->
