import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  constructor(public httpClient: HttpService) { }

  getAllPromotion(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllPromotion + `${userId}`, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  addPromotion(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.addPromotion + `${userId}`, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPromotionById(data: {}, promotionId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getPromotionById + `${promotionId}`, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
    
  assignProductsToPromotions(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.assignProductsToPromotions + `${userId}`, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
    
  checkoffer(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.checkoffer + `${userId}`, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAllProductsAvailableForPromotion({ data, userId, page, size, productType}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllProductsAvailableForPromotion + `${userId}` + (page ? ('?page=' + (page || '')) : '') + (size ? ('&size=' + (size || '')) : '') + (productType ? ('&productType=' + (productType || '')) : ''), 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  updatePromotion({ data, userId }): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.promotions + `updatePromotion/${userId}`, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  promotionToggle({ data, userId }): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.promotions + `promotionToggle/${userId}`, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  unArchiveMultiplePromotions({ data, userId }): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.promotions + `unArchiveMultiplePromotions/${userId}`, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteMultiplePromotions({ data, userId }): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.promotions + `deleteMultiplePromotions/${userId}`, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  checkFreeProducts(data, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.promotions + `checkFreeProducts/${userId}`, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  counterDiscountForm(data): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.promotions + `counterDiscountForm`, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  counterDiscountAmount(data, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.promotions + `counterDiscountAmount/${userId}`, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getCounterDiscountForm(data, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.promotions + `getCounterDiscountForm/${userId}`, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  updateCounterDiscount(data, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.promotions + `updateCounterDiscount/${userId}`, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  createCoupon(data, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.promotions + `createCoupon/${userId}`, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  updateCoupon(data, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.promotions + `updateCoupon/${userId}`, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  generateCoupon(data, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.promotions + `generateCoupon/${userId}`, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAllCoupons(data, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.promotions + `getAllCoupons/${userId}`, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  couponsAvailableToApply(data, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.promotions + `couponsAvailableToApply/${userId}`, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

}
