import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScheduleAppointmentService {
  public static schappointPost = "appointment"
  public static schAppointListByShopId = "appointment/"

  constructor(public httpClient: HttpService, private http: HttpClient) { }
  getAppointTypeList(data: {}, shopId, size, page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.appointment(data, UrlConstants.schAppointListByShopId + shopId + "?size=" + size + "&page=" + page, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getmultipleShop(data: {}, userId:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getmultipleShop + userId , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  postAppointDetails(data: {}) {
    return new Promise((resolve, reject) => {
      this.httpClient.appointment(data, UrlConstants.schappointPost, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    })

  }
  deleteAppointmentById(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.appointment(data, UrlConstants.deleteAppointmentId + id, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deletemultipleAppointment(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.appointment(data, UrlConstants.deletemultipleAppointment + userId, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  

  getAppointmentSearch(data: {}, userId,size, page,search:string): any {
    return new Promise((resolve, reject) => {
        this.httpClient.appointment(data, UrlConstants.searchAppointmentId + userId + "?size=" + size + "&page=" + page + "&search="+search, 'GET').subscribe(

      (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAllAppointMent(data: {}, userId,size, page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.appointment(data, UrlConstants.getAllAppointMent + userId + "?size=" + size + "&page=" + page, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}
