import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
  viewMode = 'tab1';

  constructor() { }

  ngOnInit(): void {
  }
  changed(event:any){
    
  }

}
