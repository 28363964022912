<div class="container-fluid p-0 mt-3">
    <div class="row headerButtons mb-3" id="showoptionHide">
        <div class="flex-item searchBar">
            <input type="search" id="searchInput" class="form-control pe-5 my-2" placeholder="Offer Name"
                [(ngModel)]="searchKey" (change)="getoffersearch(searchKey)">
        </div>
        <div class="navOption-btns flex-item formButtons mx-0">
            <button id="saleButtonPrint1" class="btn text-nowrap my-2" type="button" (click)="openDialogaddoffer()">
                <i class="fas fa-plus me-2"> </i>Add Offer
            </button>
        </div>
    </div>

    <!-- <hr class="my-0 py-0" /> -->
    <div class="tableoverflow" style="width:100% ;overflow-x: auto;">
        <table class="table table-responsive table-bordered fs--1">
            <thead class="text-600">
                <!-- <tr>
                    <th class="text-nowrap" style="vertical-align:middle;display: flex; align-items: center;">
                        <input type="checkbox" class="form-check-input mb-2"  (change)="onChekMultiple($event)"
                            [value]="-1" [checked]="checkSelect" [(ngModel)]="allSelect" />

                        <span *ngIf="archiveIconfalse">
                            <button style="padding: 0px!important;"
                                *ngIf="checkSingleArray.length > 0 || upiCheckedId.length > 0" class="btn ms-3"
                                data-bs-toggle="modal" data-bs-target="#errorDeleteSelect-modal">
                                <span *ngIf="checkSingleArray.length > 0 || upiCheckedId.length > 0"
                                    class="bi-trash icon-color fs-1" style="color: red;" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Archive"
                                    (click)=" upiCheckedId.length > 0? getUPIcheckId($event,upiCheckedId):getSingleCheckedId($event,checkSingleArray)"></span>
                            </button>
                        </span>
                    </th>
                    <th class="text-nowrap col-2">Offer Name</th>
                    <th class="text-nowrap col-2">Status</th>
                    <th class="text-nowrap col-2">Valid From</th>
                    <th class="text-nowrap col-2 ">Valid To</th>
                    <th class="text-nowrap ">Description</th>
                    <th class="text-nowrap "></th>
                </tr> -->
                <tr>
                    <th class="text-nowrap" style="vertical-align: middle; display: flex; align-items: center;">
                      <input type="checkbox" class="form-check-input mb-2" (change)="onChekMultiple($event)"
                        [value]="-1" [checked]="checkSelect" [(ngModel)]="allSelect" />
                  
                      <span *ngIf="archiveIconfalse">
                        <button style="padding: 0px!important;"
                          *ngIf="checkSingleArray.length > 0 || upiCheckedId.length > 0" class="btn ms-3"
                          data-bs-toggle="modal" data-bs-target="#errorDeleteSelect-modal">
                          <span *ngIf="checkSingleArray.length > 0 || upiCheckedId.length > 0"
                            class="bi-trash icon-color fs-1" style="color: red;" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Archive"
                            (click)="upiCheckedId.length > 0 ? getUPIcheckId($event, upiCheckedId) : getSingleCheckedId($event, checkSingleArray)"></span>
                        </button>
                      </span>
                    </th>
                    <th class="text-nowrap col-2">Offer Name</th>
                    <th class="text-nowrap col-2">Status</th>
                    <th class="text-nowrap col-2">Valid From</th>
                    <th class="text-nowrap col-2">Valid To</th>
                    <th class="text-nowrap">Description</th>
                    <th class="text-nowrap"></th>
                  </tr>
                  
            </thead>
            <tbody>
                <tr class="btn-reveal-trigger text-left" *ngFor="let item of offerData; let i = index"
                    style="color: #676398!important;">
                    <td scope="row" style="vertical-align:middle;">
                        <input class="form-check-input" type="checkbox" [checked]="checkSelectsinglefalse"
                            (change)="onChekedsingleCheckfalse($event,item.id)" [value]="item.id" />
                    </td>
                    <td class="Offer Name text-nowrap" style="vertical-align:middle;">{{ item.offerName }}</td>
                    <td class="Status text-nowrap" style="vertical-align:middle;" *ngIf="item.status== true">Active</td>
                    <td class="Status text-nowrap" style="vertical-align:middle;" *ngIf="item.status== false">Inactive
                    </td>
                    <td class="Valid From text-nowrap" style="vertical-align:middle;">{{ item.fromDate | date:"dd MMM
                        YYYY"|
                        uppercase}}</td>
                    <td class="Valid To text-nowrap" style="vertical-align:middle;">{{ item.toDate | date:"dd MMM YYYY"|
                        uppercase}}
                    </td>
                    <td class="Decription" style="vertical-align:middle;width:110%;">{{ item.description }}
                    </td>
                    <td style="vertical-align:middle;white-space:nowrap;text-align: center; ">
                        <div class="">
                            <button class="btn btnp me-4" type="button" (click)="viewoffer(item.id)">
                                <span class="bi bi-eye icon-color iconFontSize" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="View"></span>
                            </button>

                            <button class="btn btnp me-4">
                                <span class="bi-pencil-square icon-color iconFontSize" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Edit" (click)="updateOfferId(item.id)"></span>
                            </button>

                            <button class="btn btnp me-4" data-bs-toggle="modal" data-bs-target="#errorDelete-modal">
                                <span class="bi-trash icon-color iconFontSize" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Delete" style="color: red;"
                                    (click)=" GetOfferById(item.id)"></span>
                                <!-- Delete -->
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="offerData.length == 0">
                <tr class="text-center">
                    <td colspan="10">
                        <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
                            alt="notfound1" width="200" height="150" />

                        <!-- <h5 class="text-nowrap">No Offers Added. Please Add Offers To Be
                            Displayed Here. <span>
                                <a class="nav-link" (click)="openDialogaddoffer()">Add New Offers</a>
                            </span></h5> -->
                    </td>
                </tr>
            </tbody>
        </table>
        <mat-paginator class="float-right mt-2" *ngIf="offerData.length> 0" [pageSize]="partyPageSize"
            [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo" showFirstLastButtons
            (page)="handlePage($event)" aria-label="Select page">
        </mat-paginator>
    </div>

</div>
<div class="modal fade" id="errorDelete-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 350px">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
                    aria-label="Close"></a>
            </div>
            <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                    <h2 class="mb-1 text-center font-weight-bold" style="font-weight: 500;font-family: 'Poppins'; " id="modalExampleDemoLabel">
                        Delete Offer? </h2>
                </div>
                <h4 class="text-center " style = "font-family: 'Poppins';">Are You Sure To Delete Offer?</h4>

            </div>
            <div class="modal-footer justify-content-center">
                <a class="btn btn-outline-success" data-bs-dismiss="modal">Close</a>
                <a class="btn btn-outline-danger" (click)="deleteOfferById()" data-bs-dismiss="modal">Delete
                </a>

            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="errorDeleteSelect-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 350px">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
                    aria-label="Close"></a>
            </div>
            <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                    <h2 class="mb-1 text-center font-weight-bold" style="font-weight: 500;font-family: 'Poppins'; " id="modalExampleDemoLabel">
                        Delete Offer? </h2>
                </div>
                <!-- <h4   *ngIf="!isChecked  && !checkSingleArray "class="text-center ">Are you sure to delete this UPI?</h4> -->
                <h4 *ngIf="isChecked " class="text-center " style = "font-family: 'Poppins';">Are you sure to Delete All Offer?</h4>
                <h4 *ngIf="!isChecked " class="text-center " style = "font-family: 'Poppins';">Are you sure to Delete selected offer?</h4>

            </div>
            <div class="modal-footer justify-content-center">
                <a class="btn btn-outline-success" data-bs-dismiss="modal">Close</a>
                <!-- <a   *ngIf="!isChecked  && !checkSingleArray " class="btn btn-outline-primary" data-bs-dismiss="modal" (click)="deletExpenseById()">Delete
                </a> -->
                <a *ngIf="isChecked " class="btn btn-outline-danger" data-bs-dismiss="modal"
                    (click)="deleteProductById()">Delete All
                </a>
                <a *ngIf="!isChecked" class="btn btn-outline-danger" data-bs-dismiss="modal"
                    (click)="deleteProductById()">Delete Selected
                </a>

            </div>
        </div>
    </div>
</div>
<!-- <div class="modal fade" id="errorDeleteService-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 350px">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
                    aria-label="Close"></a>
            </div>
            <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                    <h2 class="mb-1 text-center font-weight-bold" style="font-weight: 800; " id="modalExampleDemoLabel">
                        Delete Service? </h2>
                </div>
                <h4 class="text-center ">Are You Sure To Delete?</h4>

            </div>
            <div class="modal-footer justify-content-center">
                <a class="btn btn-outline-primary" data-bs-dismiss="modal">Delete
                </a>
                <a class="btn btn-outline-primary" data-bs-dismiss="modal">Close</a>
            </div>
        </div>
    </div>
</div> -->
