
  <div mat-dialog-actions>
    <h3>The GSTIN is inactive. Do you still want to continue?</h3>
    <button class="btn-save" style="margin-left: 20px !important;" (click)="continueAnyway()">Continue Anyway</button>
    <button class="btn-cancel m-3" (click)="cancelContinue()">Leave</button>
  </div>
  <!-- <div mat-dialog-actions *ngIf="isArabic">
    <h3>The {{lang.GSTIN}} is inactive. Do you still want to continue?</h3>
    <button class="btn-save" style="margin-left: 20px !important;" (click)="continueAnyway()">Continue Anyway</button>
    <button class="btn-cancel m-3" (click)="cancelContinue()">Leave</button>
  </div> -->