
<div class="modal-header pt-0">
    <h1 style="color: #EB8B3F; font-weight: bolder;margin-left:5px;" class="modal-title mb-0">Estimation/Performa Invoice</h1>

    <div class="addProductButtons">

        <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel"
        (click)="onCancelModal()">Cancel</button>
        <button type="button" class="btn btn-save" (click)="PostEstimateDetail()">Save</button>

    </div>

</div>

<div class="modal-body">
  <form [formGroup]="estimateInvoiceForm" *ngIf="!isArabic">
      <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-4">
              <!-- Select Type -->
              <label class = "form-label" style="font-size: 14px !important">Select Type<span class="error-asterisk">*</span></label>
              <mat-form-field style=" font-size: 14px" class="example-full-width" appearance="outline">
                  <mat-select disableOptionCentering required placeholder="Select Type"
                      formControlName="receiptType" [ngClass]="{
                          'is-invalid': (f.receiptType.invalid && f.receiptType.touched) && submitted }"
                      >
                      <mat-option value="ESTIMATION">Estimation Invoice</mat-option>
                      <mat-option value="PERFOMA">Performa Invoice</mat-option>
                  </mat-select>
              </mat-form-field>
              <mat-error class="text-danger"
              *ngIf="(submitted || f.receiptType.touched) && f.receiptType.errors?.required">
              Select Type  Is Required!
          </mat-error>
          <label class = "form-label" style="font-size: 14px !important">Reference #<span class="error-asterisk">*</span></label>
              <!-- Reference Number -->
              <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline">
                  <input placeholder="Reference #" ngxOnlyNumbers matInput formControlName="referenceNumber" [ngClass]="{
                      'is-invalid': (f.referenceNumber.invalid && f.referenceNumber.touched) && submitted }">
              </mat-form-field>
              <mat-error class="text-danger"
                  *ngIf="(submitted || f.referenceNumber.touched) && f.referenceNumber.errors?.required">
                  Reference # Is Required!
              </mat-error>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4">
              <!-- Party Name -->
              <label class = "form-label" style="font-size: 14px !important">Vendor Name<span class="error-asterisk">*</span></label>
              <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline">
                  <input type="text" placeholder="Vendor Name" aria-label="Select Party Name" matInput
                  (keyup)="searchPartyByName($event.target.value)" [formControl]="myControl" [matAutocomplete]="auto" [ngClass]="{
                          'is-invalid': (myControl.invalid && myControl.touched) && submitted }" required>
                  <mat-autocomplete #auto="matAutocomplete">
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
                      (onSelectionChange)="setSelectedPartyDeatails(option)" >
                          {{option.partyName}}
                      </mat-option>
                  </mat-autocomplete>

              </mat-form-field>
              <mat-error class="text-danger"
              *ngIf="(submitted || myControl.touched) && myControl.errors?.required">
              Vendor Name Is Required!
          </mat-error>


              <!-- Date -->
              <label class = "form-label" style="font-size: 14px !important">Date</label>
              <mat-form-field style=" font-size: 14px" class="example-full-width" appearance="outline">
                  <input matInput required ngModel bsDatepicker placeholder="Receipt Date." [maxDate]="today"
                  style="text-transform:uppercase!important;" [(ngModel)]="todaysDate" id="datePicker" bsDatepicker [bsConfig]="{
              containerClass: 'theme-dark-blue',
              dateInputFormat: 'DD MMM YYYY',
              showWeekNumbers:false
              }" [ngModelOptions]="{ standalone: true }" (click)="openDatepicker()">
                  <span>
                      <mat-icon class="iconcalender">date_range</mat-icon>
                  </span>
              </mat-form-field>
              <!-- <mat-form-field class="example-full-width" appearance="outline">
                <input matInput required ngModel bsDatepicker placeholder="Receipt Date." [maxDate]="today"
                  style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                                containerClass: 'theme-dark-blue',
                                dateInputFormat: 'DD MMM YYYY',
                                 showWeekNumbers:false
                                }" formControlName="invoiceDate" autocomplete="off" (click)="openDatepicker()">
                <span>
                  <mat-icon class="iconcalender p-1">date_range</mat-icon>
                </span>
              </mat-form-field> -->
          </div>

          <!-- Billing Address -->
          <div class="col-sm-12 col-md-12 col-lg-4">
              <label class = "form-label" style="font-size: 14px !important">Billing Address<span class="error-asterisk">*</span></label>
              <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline">
                  <textarea matInput rows="6" placeholder="Billing Address" formControlName="billingAddress"
                      required
                      [ngClass]="{
                      'is-invalid': (f.billingAddress.invalid && f.billingAddress.touched) && submitted }"></textarea>
              </mat-form-field>
              <mat-error class="text-danger"
                  *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
                  Billing Address Is Required!
              </mat-error>
          </div>
      </div>
      <div class="row"> 
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" [ngSwitch]="currentUser.userType">
            <div *ngSwitchCase="'CLIENT'" style="font-size: 14px">
                <label class="form-label">Warehouse<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse" (selectionChange)="onWarehouseSelectionChange($event)">
                    <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                    {{warehouse.firstName}}
                    </mat-option>
                    <mat-option value="clear">Clear Selection</mat-option>
                </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.warehouse.touched)">
                <mat-error *ngIf="f.warehouse.errors?.required">
                    Warehouse is required!
                </mat-error>
                </mat-error>
            </div>
              <div *ngSwitchCase="'SHOP'" style="font-size: 14px">
                <label class="form-label">Warehouse</label>
                <mat-form-field class="example-full-width" appearance="outline">
                <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse" (selectionChange)="onWarehouseSelectionChange($event)">
                    <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                    {{warehouse.firstName}}
                    </mat-option>
                    <mat-option value="clear">Clear Selection</mat-option>
                </mat-select>
                </mat-form-field>
            </div>
        </div>
      </div>
      <div class="row d-flex justify-content-end">
          <div class="col-xs-8 col-sm-8 col-md-8 col-lg-9"></div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-3 mt-3 mb-2">
              <div class="d-flex justify-content-between mt-1" style="font-size: 14px;">
                  <label class = "form-label">Add Compensation Cess</label>
                  <div class="form-check form-switch" style = "font-size: 14px;">
                      <input class="form-check-input" id="inlineCheckbox1" type="checkbox"
                          (click)="toggleShow()" />
                  </div>
              </div>
          </div>
      </div>
      <div class="table-responsive my-custom-scrollbar">

          <table class="table table-bordered ">
              <thead class="table-secondary">
                  <tr class="font_size font-weight-bold">
                      <th scope="col" style="width:4%;vertical-align: middle;">
                          #
                      </th>
                      <th scope="col" style="width: 10%;vertical-align: middle;">
                          SKU<span class="text-danger"> *</span>
                      </th>
                      <th style = "vertical-align: middle;" class="text-nowrap" scope="col">
                          SKU Description
                      </th>
                      <th style = "vertical-align: middle;">Unit</th>

                      <th style = "vertical-align: middle;">Quantity</th>
                      <th style = "vertical-align: middle;" scope="col" class="text-nowrap" colspan="2">
                          Base Price(In Rs.)<span class="text-danger"> *</span>
                      </th>

                      <th style = "vertical-align: middle;" class="text-center" scope="col" colspan="2">
                          GST<span class="text-danger"> *</span>
                      </th>
                      <th style = "vertical-align: middle;" scope="col" colspan="2" class="text-nowrap" *ngIf="isShown">
                          Additional Cess(In Rs.)<span class="text-danger"> *</span>
                      </th>
                      <th scope="col" class="text-nowrap" style="width:8%;vertical-align: middle;">
                          Total(In Rs.)<span class="text-danger"> *</span>
                      </th>
                      <th scope="col" class="text-nowrap" style="width:8%;vertical-align: middle;">
                          Delete<span class="text-danger"> *</span>
                      </th>
                  </tr>
              </thead>

              <tbody class="mod">
                  <tr>
                      <td style="vertical-align: middle;"></td>
                      <td style="vertical-align: middle;">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="text" id="inp12" placeholder="SKU" (input)="onSearchInputChange($event.target.value)"
                                matInput [formControl]="myControl1" [matAutocomplete]="productAuto">
                            <mat-icon class="m-2" matSuffix>search</mat-icon>        
                            <mat-autocomplete #productAuto="matAutocomplete">
                                <mat-option *ngFor="let option of productOptions | async" [value]="option.itemName"
                                (onSelectionChange)="getGoodsByBarcodeSelected(option.barcode,$event)">
                                <span style="font-size: 12px;">
                                {{option.itemName | titlecase}}/<b>{{ option.barcode}}</b>
                                </span>
                            </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>    
                    </td>
                      <td style="vertical-align: middle;" class="text-nowrap" scope="col">
                          <input type="text" matInput placeholder="SKU Description" required />
                      </td>
                      <td style="vertical-align: middle;" colspan="1">
                          <input type="text" matInput placeholder="Unit"  required />
                      </td>
                      <td style="vertical-align: middle;" colspan="1">
                          <input type="text" matInput placeholder="Quantity" style="width: 5rem !important;" required />
                      </td>
                      <td style="vertical-align: middle;" colspan="1">
                          <input type="text" matInput  placeholder="Price/Unit" />
                      </td>
                      <td style="vertical-align: middle;" colspan="1">
                          <input type="text" matInput  placeholder="Price" />
                      </td>
                      <td style="vertical-align: middle;" colspan="1">
                          <input type="text" matInput required placeholder="%" style="width: 3rem;" />
                      </td>
                      <td style="vertical-align: middle;" colspan="1">
                          <input type="text" matInput required placeholder="In Rs." style="width: 3rem;" />
                      </td>
                      <td style="vertical-align: middle;" colspan="1" *ngIf="isShown">
                          <input type="text" matInput placeholder="%" style="width: 3rem;" required />
                      </td>
                      <td style="vertical-align: middle;" colspan="1" *ngIf="isShown">
                          <input type="text" matInput placeholder="In Rs" style="width: 3rem;" required />
                      </td>
                      <td style="vertical-align: middle;">
                          <input type="text" style="width: 4rem;" matInput placeholder="Amount" required />
                      </td>
                      <td style="vertical-align: middle;" style="width: 3rem;"></td>
                  </tr>
                  <tr *ngFor="let item of productData;let i=index">
                    <td style="vertical-align: middle;">{{ i + 1 }}</td>

                      <td style="vertical-align: middle;">
                        {{ item.barcode }}
                      </td>
                      <td style="vertical-align: middle;">{{ item.itemName | titlecase}}</td>
                      <td style="vertical-align: middle;" class="text-center">
                        <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit"
                           readonly />
                      </td>
                      <!-- <td class="">
                        <p style="margin-top: 0px!important;">
                          <a class="border-white btn"  style="margin-top: 0px!important;padding: 0px!important;"(click)="changeQuantity('-',i)">-</a>
                          <span class="">{{ item.quantity }}</span>
                          <a class="border-white btn"  style="margin-top: 0px!important;padding: 0px!important;"(click)="changeQuantity('+',i)">+</a>
                        </p>
                      </td> -->
                      <td style="display:flex; flex-direction: row; justify-content: center; align-items: center;vertical-align: middle;">
                          <!-- <input type="text" matinput [value]="item.quantity" style="width : 5 rem" /> -->
                          <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                            (click)="changeQuantity('-',i)">-</a>
                          <input type="text" matInput [value]="item.quantity" placeholder="Quantity"
                            (change)="changeQuantity('qty',i,$event.target.value)" style="width: 5rem;text-align: center;" required />
                          <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                            (click)="changeQuantity('+',i)">+</a>
                          <!-- <p style="margin-top: 0px!important;">
                            <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                              (click)="changeQuantity('-',i)">-</a>
                          <span class="">{{ item.quantity }}</span> -->

                          <!-- <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                            (click)="changeQuantity('+',i)">+</a>
                          </p> -->
                        </td>
                      <td style="vertical-align: middle;" colspan="1">
                        <p class="">
                          <!-- {{ item.basePrice | currency:'INR':'symbol':'1.2-2'}} -->
                          ₹&nbsp;{{ item.basePrice | number:'1.2-2'}}
                          <!-- {{ ((item.salePrice * item.quantity) - (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                            currency:'INR':'symbol':'1.2-2'}} -->
                          <!-- {{ item.salePrice | currency:'INR':'symbol':'1.2-2'}} -->
                        </p>
                      </td>
                      <td style="vertical-align: middle;" colspan="1">
                        <p class="">
                          <!-- {{ item.subTotal | currency:'INR':'symbol':'1.2-2'}} -->
                          ₹&nbsp;{{ item.subTotal | number:'1.2-2'}}
                          <!-- {{ ((item.salePrice * item.quantity) - (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                            currency:'INR':'symbol':'1.2-2'}} -->
                          <!-- {{ item.salePrice * item.quantity |
                          currency:'INR':'symbol':'1.2-2'}} -->
                        </p>
                      </td>
                      <td style="vertical-align: middle;" colspan="1">
                        <p class="" style="width: 3rem;">
                          {{ item.gstRate }}%
                        </p>
                      </td>
                      <td style="vertical-align: middle;" colspan="1">
                        <p class="" style="width: 5rem;">
                          <!-- {{ item.gstAmount | currency:'INR':'symbol':'1.2-2' }} -->
                          ₹&nbsp;{{ item.gstAmount | number:'1.2-2'}}
                        </p>
                      </td>
                      <td style="vertical-align: middle;" colspan="1" *ngIf="isShown">
                        <p class="" style="width: 3rem;">{{ item.additionalCess }}% </p>
                      </td>
                      <td style="vertical-align: middle;" colspan="1" *ngIf="isShown">
                        <p class="" style="width: 5rem;">
                          <!-- {{ (item.salePrice * item.additionalCess / 100) | currency:'INR':'symbol':'1.2-2'}} -->
                          ₹&nbsp;{{ (item.salePrice * item.additionalCess / 100) | number:'1.2-2'}}
                        </p>
                      </td>
                      <td style="vertical-align: middle;">
                        <p class="" style="width:100% !important;">
                          <!-- {{ item.total | currency:'INR':'symbol':'1.2-2'}} -->
                          ₹&nbsp;{{ item.total | number:'1.2-2'}}
                          <!-- {{ ((item.salePrice * item.quantity) + (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                          currency:'INR':'symbol':'1.2-2'}} -->
                        </p>
                      </td>
                      <td style="vertical-align: middle;">
                          <a class="text-center btnb" style="width: 3rem!important;"
                              (click)="changeQuantity('rm',i)">
                              <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip"
                                  data-bs-placement="top" title="Delete"></span>
                          </a>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
      <div class="table-responsive mt-2 my-custom-scrollbar d-flex justify-content-start row">
          <div class="col-sm-12 col-md-6 col-lg-9"></div>
          <div class="col-sm-12 col-md-6 col-lg-3 table-bordered my-custom-scrollbar">
              <table class="table table-bordered p-5 my-custom-scrollbar">
                  <tr>
                      <td class="thWidth p-2" style="text-align: left !important;vertical-align: middle;">Subtotal</td>
                      <td class="p-2" style="width: 50px;" style="text-align: right !important;vertical-align: middle;">
                        <!-- {{ subTotal | currency:'INR':'symbol':'1.2-2'}} -->
                        ₹&nbsp;{{ subTotal | number:'1.2-2'}}
                      </td>
                  </tr>
                  <tr>
                      <td class="thWidth p-2" *ngIf="gstStateMatch"  style="text-align: left !important;vertical-align: middle;">CGST</td>
                      <td class="p-2"  *ngIf="gstStateMatch" style="width: 50px;" style="text-align: right !important;vertical-align: middle;">
                        <!-- {{ gstRate/2 | currency:'INR':'symbol':'1.2-2'}} -->
                        ₹&nbsp;{{ gstRate/2 | number:'1.2-2'}}
                      </td>
                  </tr>
                  <tr>
                      <td class="thWidth p-2" *ngIf="gstStateMatch" style="text-align: left !important;vertical-align: middle;">SGST</td>
                      <td class="p-2"  *ngIf="gstStateMatch"style="width:50px;" style="text-align: right !important;vertical-align: middle;">
                        <!-- {{ gstRate/2 | currency:'INR':'symbol':'1.2-2'}} -->
                        ₹&nbsp;{{ gstRate/2 | number:'1.2-2'}}
                      </td>
                  </tr>
                  <tr>
                      <td class="thWidth  p-2" *ngIf="!gstStateMatch" style="text-align:left!important;vertical-align: middle;">IGST</td>
                      <td class="p-2" *ngIf="!gstStateMatch" style="width: 50px;" style="text-align:right!important;vertical-align: middle;">
                        <!-- {{ (gstRate/2 + gstRate/2) | currency:'INR':'symbol':'1.2-2'}} -->
                        ₹&nbsp;{{ (gstRate/2 + gstRate/2) | number:'1.2-2'}}
                      </td>
                    </tr>
                  <tr>
                      <td class="thWidth p-2" *ngIf="isShown" style="text-align: left !important;vertical-align: middle;">Additional Cess
                      </td>
                      <td class="p-2" style="width:50px;" *ngIf="isShown" style="text-align: right !important;vertical-align: middle;">
                        <!-- {{ additionalCess | currency:'INR':'symbol':'1.2-2'}} -->
                          ₹&nbsp;{{ additionalCess | number:'1.2-2'}}
                      </td>
                  </tr>
                  <tr>
                      <td class="thWidth p-2" style="text-align: left !important;vertical-align: middle;">Total</td>
                      <td class="p-2" style="width: 50px;" style="text-align: right !important;vertical-align: middle;">
                        <!-- {{ total | currency:'INR':'symbol':'1.2-2'}} -->
                        ₹&nbsp;{{ total | number:'1.2-2'}}
                      </td>
                  </tr>
              </table>
          </div>
      </div>
  </form>

  <form [formGroup]="estimateInvoiceForm" *ngIf="isArabic">
      <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-4">
              <!-- Select Type -->
              <label class = "form-label" style="font-size: 14px !important">Select Type<span class="error-asterisk">*</span></label>
              <mat-form-field style=" font-size: 14px" class="example-full-width" appearance="outline">
                  <mat-select disableOptionCentering required placeholder="Select Type"
                      formControlName="receiptType" [ngClass]="{
                          'is-invalid': (f.receiptType.invalid && f.receiptType.touched) && submitted }"
                      >
                      <mat-option value="ESTIMATION">Estimation Invoice</mat-option>
                      <mat-option value="PERFOMA">Performa Invoice</mat-option>
                  </mat-select>
              </mat-form-field>
              <mat-error class="text-danger"
              *ngIf="(submitted || f.receiptType.touched) && f.receiptType.errors?.required">
              Select Type  Is Required!
          </mat-error>
          <label class = "form-label" style="font-size: 14px !important">Reference #<span class="error-asterisk">*</span></label>
              <!-- Reference Number -->
              <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline">
                  <input placeholder="Reference #" ngxOnlyNumbers matInput formControlName="referenceNumber" [ngClass]="{
                      'is-invalid': (f.referenceNumber.invalid && f.referenceNumber.touched) && submitted }">
              </mat-form-field>
              <mat-error class="text-danger"
                  *ngIf="(submitted || f.referenceNumber.touched) && f.referenceNumber.errors?.required">
                  Reference # Is Required!
              </mat-error>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4">
              <!-- Party Name -->
              <label class = "form-label" style="font-size: 14px !important">Vendor Name<span class="error-asterisk">*</span></label>
              <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline">
                  <input type="text" placeholder="Vendor Name" aria-label="Select Party Name" matInput
                  (keyup)="searchPartyByName($event.target.value)" [formControl]="myControl" [matAutocomplete]="auto" [ngClass]="{
                          'is-invalid': (myControl.invalid && myControl.touched) && submitted }" required>
                  <mat-autocomplete #auto="matAutocomplete">
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
                      (onSelectionChange)="setSelectedPartyDeatails(option)" >
                          {{option.partyName}}
                      </mat-option>
                  </mat-autocomplete>

              </mat-form-field>
              <mat-error class="text-danger"
              *ngIf="(submitted || myControl.touched) && myControl.errors?.required">
              Vendor Name Is Required!
          </mat-error>


              <!-- Date -->
              <label class = "form-label" style="font-size: 14px !important">Date</label>
              <mat-form-field style=" font-size: 14px" class="example-full-width" appearance="outline">
                  <input matInput required ngModel bsDatepicker placeholder="Receipt Date." [maxDate]="today"
                  style="text-transform:uppercase!important;" [(ngModel)]="todaysDate" id="datePicker" bsDatepicker [bsConfig]="{
              containerClass: 'theme-dark-blue',
              dateInputFormat: 'DD MMM YYYY',
              showWeekNumbers:false
              }" [ngModelOptions]="{ standalone: true }" (click)="openDatepicker()">
                  <span>
                      <mat-icon class="iconcalender">date_range</mat-icon>
                  </span>
              </mat-form-field>
              <!-- <mat-form-field class="example-full-width" appearance="outline">
                <input matInput required ngModel bsDatepicker placeholder="Receipt Date." [maxDate]="today"
                  style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                                containerClass: 'theme-dark-blue',
                                dateInputFormat: 'DD MMM YYYY',
                                 showWeekNumbers:false
                                }" formControlName="invoiceDate" autocomplete="off" (click)="openDatepicker()">
                <span>
                  <mat-icon class="iconcalender p-1">date_range</mat-icon>
                </span>
              </mat-form-field> -->
          </div>

          <!-- Billing Address -->
          <div class="col-sm-12 col-md-12 col-lg-4">
              <label class = "form-label" style="font-size: 14px !important">Billing Address<span class="error-asterisk">*</span></label>
              <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline">
                  <textarea matInput rows="6" placeholder="Billing Address" formControlName="billingAddress"
                      required
                      [ngClass]="{
                      'is-invalid': (f.billingAddress.invalid && f.billingAddress.touched) && submitted }"></textarea>
              </mat-form-field>
              <mat-error class="text-danger"
                  *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
                  Billing Address Is Required!
              </mat-error>
          </div>
      </div>
      <!-- <div class="row d-flex justify-content-end">
          <div class="col-xs-8 col-sm-8 col-md-8 col-lg-9"></div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-3 mt-3">
              <div class="d-flex justify-content-between mt-1" style="font-size: 12px;">
                  <label class = "form-label">Add Compensation Cess</label>
                  <div class="form-check form-switch">
                      <input class="form-check-input" id="inlineCheckbox1" type="checkbox"
                          (click)="toggleShow()" />
                  </div>
              </div>
          </div>
      </div> -->
      <div class="table-responsive my-custom-scrollbar">

          <table class="table table-bordered ">
              <thead class="table-secondary">
                  <tr class="font_size font-weight-bold">
                      <th scope="col" style="width:4%;vertical-align: middle;">
                          #
                      </th>
                      <th scope="col" style="width: 10%;vertical-align: middle;">
                          SKU<span class="text-danger"> *</span>
                      </th>
                      <th style = "vertical-align: middle;" class="text-nowrap" scope="col">
                          SKU Description
                      </th>
                      <th style = "vertical-align: middle;">Unit</th>

                      <th style = "vertical-align: middle;">Quantity</th>
                      <th style = "vertical-align: middle;" scope="col" class="text-nowrap" colspan="2">
                          {{lang.Base_Price_In_Rs}}<span class="text-danger"> *</span>
                      </th>

                      <th style = "vertical-align: middle;" class="text-center" scope="col" colspan="2">
                          {{lang.GST}}<span class="text-danger"> *</span>
                      </th>
                      <!-- <th scope="col" colspan="2" class="text-nowrap" *ngIf="isShown">
                          Additional Cess(In Rs.)<span class="text-danger"> *</span>
                      </th> -->
                      <th scope="col" class="text-nowrap" style="width:8%;vertical-align: middle;">
                          {{lang.Total_In_Rs}}<span class="text-danger"> *</span>
                      </th>
                      <th scope="col" class="text-nowrap" style="width:8%;vertical-align: middle;">
                          Action<span class="text-danger"> *</span>
                      </th>
                  </tr>
              </thead>

              <tbody class="mod">
                <tr>
                  <td style="vertical-align: middle;"></td>
                  <td>
                    <!-- <input type="text" matInput placeholder="SKU" (change)="getGoodsByBarcode()"
                      [(ngModel)]="barcode" [ngModelOptions]="{standalone: true}" required /> -->
                      <mat-form-field class="example-full-width" appearance="outline">
                        <input type="text" id="inp12" placeholder="SKU" (input)="onSearchInputChange($event.target.value)"
                          matInput [formControl]="myControl1" [matAutocomplete]="productAuto">
                        <mat-icon class="m-2" matSuffix>search</mat-icon>
                        <mat-autocomplete #productAuto="matAutocomplete">
                          <mat-option *ngFor="let option of productOptions | async" [value]="option.itemName"
                            (onSelectionChange)="getGoodsByBarcodeSelected(option.barcode,$event)">
                            <span style="font-size: 12px;">
                            {{ option.itemName | titlecase }}/<b>{{ option.barcode }}</b>
                            </span>
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                  </td>
                  <td class="text-nowrap" scope="col">
                    <input type="text" matInput placeholder="SKU Description" required />
                  </td>
                  <td colspan="1">
                    <input type="text" matInput placeholder="Unit" required />
                  </td>
                  <td colspan="1">
                    <input type="text" matInput placeholder="Quantity" style="width: 5rem !important;" required />
                  </td>
      
                  <td colspan="1">
                    <input type="text" matInput placeholder="Price / Unit" />
                  </td>
                  <td colspan="1">
                    <input type="text" matInput placeholder="Price" />
                  </td>
                  <td colspan="1">
                    <input type="text" matInput required placeholder="%" style="width: 3rem;" />
                  </td>
                  <td colspan="1">
                    <input type="text" matInput required placeholder="In Rs." style="width: 3rem;" />
                  </td>
                  <td colspan="1" *ngIf="isShown">
                    <input type="text" matInput placeholder="%" style="width: 3rem;" required />
                  </td>
                  <td colspan="1" *ngIf="isShown">
                    <input type="text" matInput placeholder="In Rs" style="width: 3rem;" required />
                  </td>
                  <td>
                    <input type="text" style="width: 5rem;" matInput placeholder="Amount" required />
                  </td>
                  <td style="width: 3rem;"></td>
                </tr>
                <tr *ngFor="let item of productData;let i=index">
                  <td style="vertical-align: middle;">{{i + 1 }}</td>
      
                  <td>
                    {{ item.barcode }}
                  </td>
                  <td>{{ item.itemName | titlecase}}</td>
                  <td class="text-center">
                    <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit"
                       readonly />
                  </td>
                  <!-- <td class="">
                        <p style="margin-top: 0px!important;">
                          <a class="border-white btn"  style="margin-top: 0px!important;padding: 0px!important;"(click)="changeQuantity('-',i)">-</a>
                          <span class="">{{ item.quantity }}</span>
                          <a class="border-white btn"  style="margin-top: 0px!important;padding: 0px!important;"(click)="changeQuantity('+',i)">+</a>
                        </p>
                      </td> -->
                  <td style="display:flex; flex-direction: row; justify-content: center; align-items: center;">
                    <!-- <input type="text" matinput [value]="item.quantity" style="width : 5 rem" /> -->
                    <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                      (click)="changeQuantity('-',i)">-</a>
                    <input type="text" matInput [value]="item.quantity" placeholder="Quantity"
                      (change)="changeQuantity('qty',i,$event.target.value)" style="width: 5rem;text-align: center;" required />
                    <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                      (click)="changeQuantity('+',i)">+</a>
                  </td>
                  <td colspan="1">
                    <p class="">
                      <!-- {{ item.basePrice | currency:'INR':'symbol':'1.2-2'}} -->
                      ₹&nbsp;{{ item.basePrice | number:'1.2-2'}}
                      <!-- {{ ((item.salePrice * item.quantity) - (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                            currency:'INR':'symbol':'1.2-2'}} -->
                      <!-- {{ item.salePrice | currency:'INR':'symbol':'1.2-2'}} -->
                    </p>
                  </td>
                  <td colspan="1">
                    <p class="">
                      <!-- {{item.subTotal | currency:'INR':'symbol':'1.2-2'}} -->
                      ₹&nbsp;{{ item.subTotal | number:'1.2-2'}}
                      <!-- {{ ((item.salePrice * item.quantity) - (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                            currency:'INR':'symbol':'1.2-2'}} -->
                      <!-- {{ item.salePrice * item.quantity |
                          currency:'INR':'symbol':'1.2-2'}} -->
                    </p>
                  </td>
                  <td colspan="1">
                    <p class="" style="width: 3rem;">
                      {{ item.gstRate }}%
                    </p>
                  </td>
                  <td colspan="1">
                    <p class="" style="width: 5rem;">
                      <!-- {{ item.gstAmount | currency:'INR':'symbol':'1.2-2' }} -->
                      ₹&nbsp;{{ item.gstAmount | number:'1.2-2'}}
                    </p>
                  </td>
                  <td colspan="1" *ngIf="isShown">
                    <p class="" style="width: 3rem;">{{ item.additionalCess }}% </p>
                  </td>
                  <td colspan="1" *ngIf="isShown">
                    <p class="" style="width: 5rem;">
                      <!-- {{ (item.salePrice * item.additionalCess / 100) | currency:'INR':'symbol':'1.2-2'}} -->
                      ₹&nbsp;{{ (item.salePrice * item.additionalCess / 100) | number:'1.2-2'}}
                    </p>
                  </td>
                  <td>
                    <p class="" style="width:100% !important;">
                      <!-- {{ item.total | currency:'INR':'symbol':'1.2-2'}} -->
                      ₹&nbsp;{{ item.total | number:'1.2-2'}}
                      <!-- {{ ((item.salePrice * item.quantity) + (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                          currency:'INR':'symbol':'1.2-2'}} -->
                    </p>
                  </td>
                  <td>
                    <a class="text-center btnb" style="width: 3rem!important;" (click)="changeQuantity('rm',i)">
                      <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip" data-bs-placement="top"
                        title="Delete"></span>
                    </a>
                  </td>
                </tr>
              </tbody>
          </table>
      </div>
      <div class="table-responsive mt-2 my-custom-scrollbar d-flex justify-content-start row">
          <div class="col-sm-12 col-md-6 col-lg-9"></div>
          <div class="col-sm-12 col-md-6 col-lg-3 table-bordered my-custom-scrollbar">
              <table class="table table-bordered p-5 my-custom-scrollbar">
                  <tr>
                      <td class="thWidth p-2" style="text-align: left !important;vertical-align: middle;">Subtotal</td>
                      <td class="p-2" style="width: 50px;" style="text-align: right !important;vertical-align: middle;">
                        <!-- {{ subTotal | currency:'INR':'symbol':'1.2-2'}} -->
                        AED&nbsp;{{ subTotal | number:'1.2-2'}}
                      </td>
                  </tr>
                  <tr>
                      <td class="thWidth p-2" *ngIf="gstStateMatch"  style="text-align: left !important;vertical-align: middle;">{{lang.CGST}}</td>
                      <td class="p-2"  *ngIf="gstStateMatch" style="width: 50px;" style="text-align: right !important;vertical-align: middle;">
                        <!-- {{ gstRate/2 | currency:'INR':'symbol':'1.2-2'}} -->
                        AED&nbsp;{{ gstRate | number:'1.2-2'}}
                      </td>
                  </tr>
                  <!-- <tr>
                      <td class="thWidth p-2" *ngIf="gstStateMatch" style="text-align: left !important;">SGST</td>
                      <td class="p-2"  *ngIf="gstStateMatch"style="width:50px;" style="text-align: right !important;">
                        ₹&nbsp;{{ gstRate/2 | number:'1.2-2'}}
                      </td>
                  </tr> -->
                  <!-- <tr>
                      <td class="thWidth  p-2" *ngIf="!gstStateMatch" style="text-align:left!important;">IGST</td>
                      <td class="p-2" *ngIf="!gstStateMatch" style="width: 50px;" style="text-align:right!important;">
                        ₹&nbsp;{{ (gstRate/2 + gstRate/2) | number:'1.2-2'}}
                      </td>
                    </tr>-->
                  <!-- <tr>
                      <td class="thWidth p-2" *ngIf="isShown" style="text-align: left !important;">Additional Cess
                      </td>
                      <td class="p-2" style="width:50px;" *ngIf="isShown" style="text-align: right !important;">
                          ₹&nbsp;{{ additionalCess | number:'1.2-2'}}
                      </td>
                  </tr> -->
                  <tr>
                      <td class="thWidth p-2" style="text-align: left !important;vertical-align: middle;">Total</td>
                      <td class="p-2" style="width: 50px;" style="text-align: right !important;vertical-align: middle;">
                        <!-- {{ total | currency:'INR':'symbol':'1.2-2'}} -->
                        AED&nbsp;{{ total | number:'1.2-2'}}
                      </td>
                  </tr>
              </table>
          </div>
      </div>
  </form>
</div>

<!-- <form [formGroup]="estimateInvoiceForm">
    <div class="card">
        <div class="card-header bg-light pt-3 pb-3">
            <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
                Estimation/Performa Invoice
            </p>
        </div>
        <div class="progress" style="height: 2px">
            <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
            </div>
        </div>
        <div class="card-body fs--1 bg-white">
            <div class="row">
                <div class="col-sm-4">

                    <mat-form-field style=" font-size: 11px" class="example-full-width" appearance="outline">
                        <mat-label>Select Type</mat-label>
                        <mat-select disableOptionCentering required placeholder="Composite Type"
                            formControlName="receiptType" [ngClass]="{
                                'is-invalid': (f.receiptType.invalid && f.receiptType.touched) && submitted }"
                            >
                            <mat-option value="ESTIMATION">Estimation Invoice</mat-option>
                            <mat-option value="PERFOMA">Performa Invoice</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error class="text-danger"
                    *ngIf="(submitted || f.receiptType.touched) && f.receiptType.errors?.required">
                    Select Type  Is Required!
                </mat-error>

                    <mat-form-field style="font-size: 11px" class="example-full-width" appearance="outline">
                        <mat-label> Reference Number</mat-label>
                        <input placeholder="Reference Number" ngxOnlyNumbers matInput formControlName="referenceNumber" [ngClass]="{
                            'is-invalid': (f.referenceNumber.invalid && f.referenceNumber.touched) && submitted }">
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.referenceNumber.touched) && f.referenceNumber.errors?.required">
                        Reference Number Is Required!
                    </mat-error>
                </div>
                <div class="col-sm-4">

                    <mat-form-field style="font-size: 11px" class="example-full-width" appearance="outline">
                        <mat-label>Party Name</mat-label>
                        <input type="text" placeholder="Party Name" aria-label="Select Party Name" matInput
                        (keyup)="searchPartyByName($event.target.value)" [formControl]="myControl" [matAutocomplete]="auto" [ngClass]="{
                                'is-invalid': (myControl.invalid && myControl.touched) && submitted }" required>
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
                            (onSelectionChange)="setSelectedPartyDeatails(option)" >
                                {{option.partyName}}
                            </mat-option>
                        </mat-autocomplete>

                    </mat-form-field>
                    <mat-error class="text-danger"
                    *ngIf="(submitted || myControl.touched) && myControl.errors?.required">
                    Party Name Is Required!
                </mat-error>


                    <mat-form-field style=" font-size: 11px" class="example-full-width" appearance="outline">
                        <mat-label for="Date">Date</mat-label>
                        <input matInput required ngModel bsDatepicker placeholder="Receipt Date." [maxDate]="today"
                        style="text-transform:uppercase!important;" [(ngModel)]="todaysDate" id="datePicker" bsDatepicker [bsConfig]="{
                    containerClass: 'theme-dark-blue',
                    dateInputFormat: 'DD MMM YYYY',
                    showWeekNumbers:false
                    }" [ngModelOptions]="{ standalone: true }">
                        <span>
                            <mat-icon class="iconcalender">date_range</mat-icon>
                        </span>
                    </mat-form-field>
                </div>

                <div class="col-sm-4">
                    <mat-form-field style="font-size: 11px" class="example-full-width" appearance="outline">
                        <mat-label>Billing Address</mat-label>
                        <textarea matInput rows="5" placeholder="Billing Address" formControlName="billingAddress"
                            required
                            [ngClass]="{
                            'is-invalid': (f.billingAddress.invalid && f.billingAddress.touched) && submitted }"></textarea>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
                        Billing Address Is Required!
                    </mat-error>
                </div>
            </div>
            <div class="row d-flex justify-content-end">
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-9"></div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-3 mt-3">
                    <div class="d-flex justify-content-between mt-1" style="font-size: 12px;">
                        <label>Add Compensation Cess</label>
                        <div class="form-check form-switch">
                            <input class="form-check-input" id="inlineCheckbox1" type="checkbox"
                                (click)="toggleShow()" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-responsive my-custom-scrollbar">

                <table class="table table-bordered ">
                    <thead class="table-secondary">
                        <tr class="font_size font-weight-bold">
                            <th scope="col" style="width:4%;">
                                #
                            </th>
                            <th scope="col" style="width: 5%;">
                                SKU<span class="text-danger"> *</span>
                            </th>
                            <th class="text-nowrap" scope="col">
                                SKU Description
                            </th>
                            <th>Unit</th>

                            <th>Quantity</th>
                            <th scope="col" class="text-nowrap" colspan="2">
                                Base Price(In Rs.)<span class="text-danger"> *</span>
                            </th>

                            <th class="text-center" scope="col" colspan="2">
                                GST<span class="text-danger"> *</span>
                            </th>
                            <th scope="col" colspan="2" class="text-nowrap" *ngIf="isShown">
                                Additional CESS(IN INR)<span class="text-danger"> *</span>
                            </th>
                            <th scope="col" class="text-nowrap" style="width:8%;">
                                Total(In Rs.)<span class="text-danger"> *</span>
                            </th>
                            <th scope="col" class="text-nowrap" style="width:8%;">
                                Delete<span class="text-danger"> *</span>
                            </th>
                        </tr>
                    </thead>

                    <tbody class="mod">
                        <tr>
                            <td>#</td>
                            <td>
                                <input type="text" matInput style="width:55px;" placeholder="SKU"
                                    (change)="getGoodsByBarcode()" [(ngModel)]="barcode"
                                    [ngModelOptions]="{standalone: true}" required />
                            </td>
                            <td class="text-nowrap" scope="col">
                                <input type="text" matInput placeholder="SKU Description" required />
                            </td>
                            <td colspan="1">
                                <input type="text" matInput placeholder="Unit" style="width: 4rem;" required />
                            </td>
                            <td colspan="1">
                                <input type="text" matInput placeholder="Quantity" style="width: 5rem !important;" required />
                            </td>
                            <td colspan="1">
                                <input type="text" matInput  placeholder="Price / Unit" />
                            </td>
                            <td colspan="1">
                                <input type="text" matInput  placeholder="Price" />
                            </td>
                            <td colspan="1">
                                <input type="text" matInput required placeholder="%" style="width: 3rem;" />
                            </td>
                            <td colspan="1">
                                <input type="text" matInput required placeholder="IN Rs." style="width: 3rem;" />
                            </td>
                            <td colspan="1" *ngIf="isShown">
                                <input type="text" matInput placeholder="%" style="width: 3rem;" required />
                            </td>
                            <td colspan="1" *ngIf="isShown">
                                <input type="text" matInput placeholder="In Rs" style="width: 3rem;" required />
                            </td>
                            <td>
                                <input type="text" style="width: 3rem;" matInput placeholder="Amount" required />
                            </td>
                            <td style="width: 3rem;"></td>
                        </tr>
                        <tr *ngFor="let item of productData;let i=index">
                            <td>

                            </td>
                            <td>
                              {{ item.barcode }}
                            </td>
                            <td>{{ item.itemName | titlecase}}</td>
                            <td class="text-center">
                              <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit"
                                style="width: 5rem;" readonly />
                            </td>

                            <td style="display:flex; flex-direction: row; justify-content: center; align-items: center;">
                                 <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                                  (click)="changeQuantity('-',i)">-</a>
                                <input type="text" matInput [value]="item.quantity" placeholder="Quantity"
                                  (change)="changeQuantity('qty',i,$event.target.value)" style="width: 5rem;" required />
                                <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                                  (click)="changeQuantity('+',i)">+</a>

                              </td>
                            <td colspan="1">
                              <p class="">
                                {{ item.basePrice |
                            currency:'INR':'symbol':'1.2-2'}}

                              </p>
                            </td>
                            <td colspan="1">
                              <p class="">
                                {{item.subTotal |
                                  currency:'INR':'symbol':'1.2-2'}}

                              </p>
                            </td>
                            <td colspan="1">
                              <p class="" style="width: 3rem;">
                                {{ item.gstRate }}%
                              </p>
                            </td>
                            <td colspan="1">
                              <p class="" style="width: 5rem;">
                                {{ item.gstAmount | currency:'INR':'symbol':'1.2-2' }}
                              </p>
                            </td>
                            <td colspan="1" *ngIf="isShown">
                              <p class="" style="width: 3rem;">{{ item.additionalCess }}% </p>
                            </td>
                            <td colspan="1" *ngIf="isShown">
                              <p class="" style="width: 5rem;">
                                {{ (item.salePrice * item.additionalCess / 100) | currency:'INR':'symbol':'1.2-2'}}
                              </p>
                            </td>
                            <td>
                              <p class="" style="width:100% !important;">
                                {{ item.total | currency:'INR':'symbol':'1.2-2'}}

                              </p>
                            </td>
                            <td>
                                <a class="text-center btnb" style="width: 3rem!important;"
                                    (click)="changeQuantity('rm',i)">
                                    <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="Delete"></span>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="table-responsive mt-2 my-custom-scrollbar d-flex justify-content-start row">
                <div class="col-sm-12 col-md-6 col-lg-9"></div>
                <div class="col-sm-12 col-md-6 col-lg-3 table-bordered my-custom-scrollbar">
                    <table class="table table-bordered p-5 my-custom-scrollbar">
                        <tr>
                            <td class="thWidth p-2" style="text-align: left !important;">Subtotal</td>
                            <td class="p-2" style="width: 50px;" style="text-align: right !important;">{{ subTotal |
                                currency:'INR':'symbol':'1.2-2'}}</td>
                        </tr>
                        <tr>
                            <td class="thWidth p-2" *ngIf="gstStateMatch"  style="text-align: left !important;">CGST</td>
                            <td class="p-2"  *ngIf="gstStateMatch" style="width: 50px;" style="text-align: right !important;">{{ gstRate/2|
                                currency:'INR':'symbol':'1.2-2'}}</td>
                        </tr>
                        <tr>
                            <td class="thWidth p-2" *ngIf="gstStateMatch" style="text-align: left !important;">SGST</td>
                            <td class="p-2"  *ngIf="gstStateMatch"style="width:50px;" style="text-align: right !important;"> {{ gstRate/2 |
                                currency:'INR':'symbol':'1.2-2'}}</td>
                        </tr>
                        <tr>
                            <td class="thWidth  p-2" *ngIf="!gstStateMatch" style="text-align:left!important;">IGST</td>
                            <td class="p-2" *ngIf="!gstStateMatch" style="width: 50px;" style="text-align:right!important;">{{
                                gstRate/2 + gstRate/2|
                              currency:'INR':'symbol':'1.2-2'}}</td>
                          </tr>
                        <tr>
                            <td class="thWidth p-2" *ngIf="isShown" style="text-align: left !important;">Additional Cess
                            </td>
                            <td class="p-2" style="width:50px;" *ngIf="isShown" style="text-align: right !important;">{{
                                additionalCess | currency:'INR':'symbol':'1.2-2'}}</td>
                        </tr>
                        <tr>
                            <td class="thWidth p-2" style="text-align: left !important;">Total</td>
                            <td class="p-2" style="width: 50px;" style="text-align: right !important;">{{ total |
                                currency:'INR':'symbol':'1.2-2'}}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="col-12  d-flex justify-content-end">
                <div class="">

                </div>
                <a class="btn btn-outline-primary" style="margin-left: 10px" type="submit"
                    (click)="PostEstimateDetail()">Save</a>
            </div>
        </div>
    </div>
</form> -->
