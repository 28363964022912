import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SaleInvoiceService {

  private SaleInvoiceAddedSource = new Subject<void>();
  saleInvoiceAdded$ = this.SaleInvoiceAddedSource.asObservable();

  notifySaleInvoiceAdded() {
    this.SaleInvoiceAddedSource.next();
  }


  constructor(public httpClient:HttpService) { }
  getPartyByName(data: {}, userId:string , name:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.searchPartyByName +"?userId="+ userId + "&search="+name ,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getGoodsByBarcode(data: {}, barcode:any, userId:string, warehouseId?): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getGoodsByBarcode + barcode + "&userId="+ userId + "&warehouseId=" + (warehouseId || ''),  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  postSaleInvoice(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postSalesInvoice,  'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getAllSaleInvoice(data: {},userId:string,receiptType,size,page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllSalesInvoice + userId + "?receiptsType=" + receiptType + "&size="+ size + "&page=" + page,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAllSaleInvoiceSearch(data: {},userId:string,receiptType,size,page,search): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllSalesInvoice + userId + "?receiptsType=" + receiptType + "&size="+ size + "&page=" + page + "&search="+search,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  // {{INVENTORYSERVER}}/invoice/getSaleReturn/{{userId}}?search=SALES&searchInvoice=12

  getAllSaleReturnSearch(data: {},userId:string,receiptType,search): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllSalesReturn + userId + "?search=" + receiptType +"&searchInvoice="+search,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getSaleInvoiceById(data: {},receiptId:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getSaleInvoiceById + receiptId,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  postInvoiceNumber(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postinvoiceFormat,  'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  postreciptprofiledata(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.settingprofiledata,  'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getInvoiceNumber(data: {},Id:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postinvoiceFormat + Id,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getInvoicesetting(data: {},Id:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postinvoicesetting + Id,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  updateInvoiceNumber(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postinvoiceFormat+ id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  updateInvoicesetting(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.putinvoicesetting+ id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getSaleInvoiceNumber(data: {},Id:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getSaleInvoiceNumber + Id,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getsaleInvoiceByIncoice(data:{},Id:string,invoiceNumber):any{

   return new Promise((resolve, reject) => {
    this.httpClient.inventory(data, UrlConstants.getsaleInvoiceByinvoiceNumber + Id +"?invoiceNo=" +invoiceNumber,  'GET').subscribe(
      (res) => resolve(res),
      (err) => reject(err)
    );
  });
  }

  getsaleReturnByList(data: {},userId:string,size,page,search){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getSaleReturnList + userId +  "?size="+ size + "&page=" + page +"&search=" +search ,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}

