export class UrlConstants {
  static sundryDebit(data: {}, sundryDebit: any, arg2: string) {
    throw new Error("Method not implemented.");
  }

  //payumoney
  public static getPaymentSuccess = "subscription/pay";


  // Payroll
  public static addSalary = "employees/addSalary/";
  public static assignStructure = "employees/assignStructure/"
  public static addStructure = "employees/addStructure/";
  public static getAllStructures = "employees/getAllStructures/";
  public static getStructureById = "employees/getStructureById/";

  // Time Sheet
  public static addTime = "addTime/addTime/";
  public static editTime = "addTime/updateTime/";
  public static getDailyTime = "addTime/getDailyTime/";
  public static getDailyTimesearch = "addTime/getDailyTime/";
  public static getweekTimesearch = "addTime/getWeeklyData/";
  public static getweeklyTime = "addTime/getWeeklyData/";
  public static getTimeSheetById = "addTime/getTimeSheetById/";
  public static deleteTimeSheetById = "addTime/deleteTime/"
  public static updateTimeSheetById = "addTime/updateTime/"


  // User Registartion
  public static postClientUser = "users/clientRegister";
  public static warehouseRegister = "users/warehouseRegister";
  public static postCustomerUser = "users/customerRegister";
  public static getAllCountryClient = "users/getAllCountries";
  public static searchCountryByNameId = "users/getAllCountries/";

  // Role based users register verification
  public static sendRoleUserOtp = "users/sendClientOtp";
  public static roleUserVerify = "users/setEmployeePassword";
  public static getUserBasedRole = "users/getemployeebyemail/";

  // Login api
  public static login = "users/login";
  public static forgotPassword = "users/forgotPassword";
  public static verifyforgetOTP = "users/verifyOtpForgotPassword";
  public static changeOtpEmailPassword = "users/changeForgotPassword";

  // subscription
  public static getAllPlan = "plans/";
  public static getAllFeatures = "features/";
  public static getFeatureById = "subscription/getSubcriptionWiseFeatures/";
  public static postBasicSubscription = "subscription/subscribeBasic";
  public static postPremiumSubscription = "subscription/subscribePremium";
  public static getSubscribedUserDetails = "user_subscription/";

  // OTP verification
  public static postClientOtp = "users/verifyClientOTP";
  public static sendCustomerOtp = "users/sendCustomerOtp";
  // public static verifyCustomerOtp = "users/verifyCustomerOTP"

  // User profile
  public static putUserProfile = "users/";
  public static deleteProfileImg = "users/deleteProfilePicture/";
  public static deleteSignature = "users/deleteDigitalSignature/";
  public static signatureImage = "users/uploadSignature/";
  public static profileImagePost = "users/uploadProfile/";
  public static getUserProfile = "users/profile/";
  public static getBusNessCategory = "category/getAllCategories";
  public static addMultipleAddress = "useraddresses/";
  public static updateMultipleAddress = "useraddresses/";
  public static deleteMultipleAddress = "useraddresses/";
  public static addPincode = "pincode/";
  public static getTypes = "businessTypes";
  public static getGst = "gst/";
  public static mobileUpdateOtp = "users/updateUserMobile/";
  public static clientMobileUpdate = "users/changeClientMobileOtp";
  public static getCategoryByCAtegorySearch = "category/getAllCategories/";

  //user profile partners api list
  public static addBusinessPartners = "users/addBusinessPartners/"
  public static addLicDocument = "users/addLicDocument/"
  public static getBusinessPartners = "users/getBusinessPartners/"
  public static deletePartner = "users/deleteBusinessPartners"
  public static updateBusinessPartner = "users/updateBusinessPartner/"

  // Delete User
  public static userDelete = "users/deleteUser/";

  // Roles and Permissions
  public static getRole = "roles/";
  public static deleteRole = "roles/deleteRole/";
  public static getallPermission = "permissions";
  public static getPermission = "rolePermissions/getUserRolePermissions/";
  public static postCustomRoles = "roles/addRole";
  public static editCustomRoles = "rolePermissions/";
  public static addUserWithRole = "users/addEmployeeUser";
  public static updateempUser = "users/updateEmployee/";
  public static deleterole = "users/removeRoleOfEmployee/";
  public static getUser = "users/getEmployees/";
  public static getExpenseApproval = "employees/getAllExpensesEmployer/";
  public static getapprovals = "employees/getAllExpensesEmployer/";
  public static getapprovalsbyid = "employees/updateExpenseStatus/";
  public static assignRole = "users/assignUserToRoles/";
  public static getHash = "getHash/";
  public static uploadImage = "users/uploadImage";
  public static uploadEmployee = "users/uploadBulkEmployee";
  public static deleteMultipleEmp = "users/deleteMultipleEmp/";

  //employee all users
  public static getAllUsers = "roles/getAllUserAssignedRole/";
  public static getEmployeeById = "users/getEmployeeByEmployeeId/";

  //Sundry Debtor
  // public static getdebit = "invoice/debit/"
  //Sundry Creditor
  public static getcredit = "invoice/credit/";
  // Party
  public static postParty = "parties/";
  public static getAllPartiesApi = "parties/getAllParty/";
  public static getParty = "parties/";
  public static updateParty = "parties/partyUpdate/";
  public static updateAdditionalDeatils = "parties/partyAdditionalUpdate/";
  public static deletePartyAdditional = "parties/deletePartyAdditional/";
  public static deletePartyById = "parties/deleteParty/";
  public static searchPartyByName = "parties/searchPartyByNameGST/";
  public static postBulkParty = "parties/uploadbulkParties?userId=";
  public static UnarchivedParty = "parties/unArchiveParty/"
  public static multiplePartyDel = "parties/deleteMultipleParty/"
  public static unArchiveMultipleParties = "parties/unArchiveMultipleParties/"
  public static downloadParties = "parties/downloadParties/"

  // Get all countries list
  public static getAllCountries = "parties/getAllCountries"

  //party Reports API
  // public static partyAllReport = "partyReport/allPartiesReport/"
  public static PartyReportAllPartyReport = "partyReport/allPartiesReport/";
  public static getPartyByItem = "partyReport/getPartyByItem/";
  public static getPurchaseByParty = "partyReport/salePurchase/";
  public static getPartyStatement = "partyReport/getPartyStatement/";
  public static getProfitLoss = "partyReport/profitLoss/";
  // public static getsalePurchase = "partyReport/salePurchase/";
  public static getsalePurchaseGroup = "partyReport/salePurchaseGroup/";
  //partyGroup
  public static getGroupParty = "parties/getGroupParties/";
  public static getAllGroup = "parties/getAllGroupsByUser/"  // old API for party group list
  public static getAllPartyGroup = "parties/getAllPartiesInGroup/"
  public static postSinglepartyGroup = "parties/groupParties";
  public static postMultiplePartyGroup = "parties/groupPartiesaddParties/";
  public static getAllGroupById = "parties/getGroupParties/"

  public static DeleteGroup = "parties/deleteGroupBygroupId/"
  public static DeletePartyFromGroup = "parties/deletepartyFromGroup/"

  public static updateGroupByGroupId = "/parties/updateGroupName/"
  public static deleteMultipleGroup = "parties/deleteMultipleGroup/"
  // public static getGroupParty = "/parties/getGroupParties/";
  // public static getAllGroup = "parties/getAllGroupsByUser/"

  // public static getAllGroup="/parties/getAllGroupsByParty/"
  // public static postSinglepartyGroup = "/parties/groupParties";
  // public static postMultiplePartyGroup = "/parties/groupPartiesaddParties/";

  //product
  //public static addGoods="goods/addGoods"
  public static addGoods = "products/addGoods";
  public static productImage = "products/productImage/";
  public static productImage1 = "users/uploadImage";
  public static coverImage = "products/coverImage/";
  public static coverImage1 = "products/uploadImage";
  public static getProductImg = "products/getProductImages/";
  public static autoGenerateBarcode = 'invoice/autoGenerateBarcode/'
  //delete product cover pic api

  public static deleteCoverPiProduct = "products/deleteCoverImage/";
  public static deleteCoverPioffer = "products/deleteOfferImage/"

  // public static getGoodsByUser = "goods/getAllGoodsByUser/"
  public static getAllProductsByUserTYpe = "products/getAllProductsByUser/";
  public static getAllServiceByUserTYpe="products/getAllServiceByUser/"
  public static getAllProductsoffer = "products/getAllNonDeletedProducts/";
  //public static getGoodsByUser="products/getProductById/goods/"

  public static updateProduct = "products/goodUpdate/";
  //public static deleteProductById="goods/deleteGood/"
  public static deleteProductById = "products/deleteProduct/";
  public static deletemultiProductById = "products/deleteMultipleProduct/";
  public static deletemultiProductByIdunarchive = "products/unArchiveMultipleProduct/";
  //public static getGoodsById="goods/getGoodsById/"
  public static getGoodsById = "products/getProductById/";
  public static productUnarchived = "products/unArchiveProduct/"
  public static deleteproductImage = "products/deleteProductImages/"

  //product report
  public static getProductReport = "products/itemBatchReport/";
  public static productprofitLossDate = "products/itemWiseProfitLoss/"
  public static StockDetailsDate = "products/stockDetailsReport/";
  //low stock warning
  public static getLowStock = "products/lowStockWaring/";
  public static stockSummaryGet = "products/stockSummaryReport/";
  public static productCategoryGet = "products/getAllCategories";
  public static productItem = "products/itemBatchReport/";
  public static productDetails = "products/itemDetailsReport/";
  public static productItemReport = "products/itemReportByParty/";
  public static getAllCategoriesByUserSearch = "products/lowStockWaring/";//categories



  public static addService = "products/addService";
  // public static addService="service/addService"
  public static getAllServicesByUser = "service/getAllServicesByUser/";
  public static serviceUpdate = "products/serviceUpdate/";
  public static deleteServiceById = "service/deleteService/";
  // public static getServiceById="service/getServiceById/"
  public static getServiceById = "products/getProductById/";
  public static postBulkProduct = "products/uploadbulkPorducts?userId=";

  //offer list
  public static getOfferList = "products/getAllOffers/";
  public static deleteProductByOffer = "products/deleteOffer/";
  public static multideleteByOffer = "products/deleteMultipleOffer/";
  //add Offer
  public static postaddoffer = "products/addOffer";
  public static getOfferById = "products/getOfferById/"
  public static PutOffer = "products/updateOffer/"
  //offer image
  public static postOfferImage = "users/uploadImage"
  // public static postOfferImage = "products/offerImage/"
  //invoice
  public static getGoodsByBarcode = "products/getProductByBarcode/?barcode=";
  public static postPurchaseInvoice = "invoice/purchase";
  public static getAllPurchaseInvoice = "invoice/getAllInvoices/";
  public static getPurchaseInvoiceById = "invoice/";
  public static getSaleInvoiceById = "invoice/";
  public static postSalesInvoice = "invoice/sales/";
  public static getAllSalesInvoice = "invoice/getAllInvoices/";
  public static getAllSalesReturn = "invoice/getSaleReturn/";
  public static postinvoiceFormat = "invoiceFormat/";
  public static postinvoicesetting = "invoice/getInvoiceSetting/";
  public static putinvoicesetting = "invoice/updateInvoiceSetting/";
  public static settingprofiledata = "invoice/invoiceSetting";
  public static getSaleInvoiceNumber = "invoice/invoiceNumber/";
  public static getsaleInvoiceByinvoiceNumber = "invoice/getAllInvoices/";

  //sale return
  public static postSaleReturn = "invoice/saleReturn";
  public static getSaleReturnList = "invoice/getSaleReturn/";

  //sale order
  public static postSaleOrder = "invoice/saleOrder";
  public static saleOderForWarehouse = "invoice/saleOderForWarehouse";
  public static PostPurchaseOrder = "invoice/purchaseOrder"
  public static getSaleOrder = "invoice/getAllInvoices/";
  public static getSaleOderForWarehouseBySaleOrderId = "invoice/getSaleOderForWarehouseBySaleOrderId/";
  //purchase return
  public static postPurchaseReturn = "invoice/purchaseReturn";


  // Widgets
  public static addWidgetsByClient = "widget/addWidgetByRetailer/retailer";
  public static deleteWidgetById = "widget/deleteWidget/";
  public static getDefaultWidgets =
    "widget/getDefaultWidgets/retailer?retailerWidget=";

  // IFSC code
  public static ifscApi = "https://ifsc.razorpay.com/";

  //Remote Shopping // Client
  public static remoteShopToggle = "client/remoteShop/";
  public static getAllRetailers = "client/isRemoteShop";
  // public static getClientInventoryGoodsAndServices = "goods/getInventoryRemote?clientId="
  public static getClientInventoryGoodsAndServices =
    "products/getInventoryRemote?clientId=";
  // Remote Order  // Customer
  public static postAddtoCart = "cart";
  public static getCustomerCartItems = "cart/getCartByUser/";
  public static deleteCustomerCartItems = "cart/removeProduct/";
  public static putProductQuantity = "cart/editQuantity/";
  public static postRemoteOrder = "remoteOrder";
  public static getClientPendingOrder = "remoteOrder/allPendingRemoteOrder/";
  public static getGeneratedOrder = "remoteOrder/allPendingRemoteOrder/";
  public static getRemoteOrder = "remoteOrder/allPendingRemoteOrder/";

  public static getCartCount = "cart/getProductCountByUserId/";
  public static getCustomerProductByOrderId =
    "remoteOrder/getInvoiceByOrderId/";
  public static patchGenerateRemoteInovice =
    "remoteOrder/generateInvoiceByOrderId";
  public static getInvoiceByOrderId = "remoteOrder/getInvoiceByOrderId/";
  public static getCustomerAllOrders = "remoteOrder/getRemoteOrderByUser/";

  //appointment customer
  public static getAppointmentUsers = "users/getAppointmentUsers";
  public static appointmentType = "appointment/";
  public static getDaySlot = "schedule/dayslot/";
  public static customerBookAppointPOST = "bookings";
  public static CustomerListByuser = "bookings/getAllCustomerBooking/";

  //Bank Data Api
  public static getBankDeatilsApi = "bankdetails/getUserBankData/";
  public static postBankDetails = "bankdetails/";
  public static deleteBankByACC = "bankdetails/deletebankAccount/";
  public static updateBank = "bankdetails/updatebankdata/";
  public static getbankDataByACC = "bankdetails/getbankdata/";
  public static getBankDetails = "bankdetails/ifsc/";

  //Upi details api
  public static getUpIList = "upidetails/getUpi/";
  public static PostUpI = "upidetails/";
  public static DeleteUPI = "upidetails/deleteUpi/";
  public static multipleDeleteUpi = "upidetails/deleteAllUpi/";
  public static IsPrimaryUPI = "upidetails/isPrimeUpi/";


  //schedule- shops api
  public static addNewShop = "mulipleShops/addNewShop";
  public static schShopList = "mulipleShops/getAllChildShops/";
  public static schShopListByShopId = "mulipleShops/getSingleShop/";
  public static upadateShop = "mulipleShops/updateShop/";
  public static isActiveShop = "mulipleShops/isActiveShop/";

  //appointment Api.
  public static schappointPost = "appointment";
  public static schAppointListByShopId = "appointment/";
  public static deleteAppointmentId = "appointment/deleteAppointment/";
  public static searchAppointmentId = "appointment/";
  public static updateAppointment = "/appointment/appointmentUpdate/";
  public static singleAppointmentId = "/appointment/getAppointmentById/"
  public static getAllAppointMent = "appointment/getAllAppointMent/"
  public static getmultipleShop = "/mulipleShops/getAllShops/";
  public static deletemultipleAppointment = "/appointment/deleteMultipleAppointment/";

  //schedule
  public static getAllSchByShopId = "schedule/getAllScheduleByShopId/";
  public static getAllSchedule = "schedule/getAllSchedule/";
  public static getTimeSlots = "schedule/getTimeSlots";
  public static createSchedule = "schedule/createSchedule";
  public static getAppointmentType = "appointment/";
  public static getAppointmentTypeShopid = "appointment/getAllAppointMent/";
  public static deleteschedulybyId = "schedule/deleteschedulybyId/";
  public static deletemultipleSchedule = "schedule/deleteMultipleSchedule/";
  //paymentIn
  public static PaymentInPost = "invoice/payment/";
  public static postuploadImagePayment = "payment/uploadPaymentImages/";
  //paymentOut
  public static PaymentOutPost = "invoice/payment/";
  // Bidding
  public static postwishlistBid = "wishlist/createInternalWishlist";
  public static getWishlistsByUserId = "wishlist/getWishlistsByUserId/";
  public static getAllBiddings = "wishlist/getAllBiddings/";
  public static getWishlistById = "wishlist/getWishlistById/";
  public static removeWishlist = "wishlist/removeWishlist/";
  public static clientQuotePrice = "biddings/quotePrice";
  public static getAllProductsByUserSearch = "products/getAllProductsByUser/";


  public static getProductByBarcode = "products/getProductByBarcode/?barcode=";
  public static PostCustomerAcceptBid = "biddings/acceptByCustomer";
  public static rejectBidCustomer = "/biddings/rejectBid";

  // Notification
  public static postFcm = "fcm";
  public static getAllNotification = "notifications/getNotification/";
  //CashInHand
  public static CashInHandPost = "payment/cashadjustments/";
  public static denominationPost = "payment/denomination/";
  public static getCurrentBalance = "payment/getcurrentBalance/"

  //Customer Profile
  public static CustomerProfile = "customer/profile/";
  public static CustomerProfileEditByCustomerUserId = "customer/profile/";

  //contactUs
  public static ContactUsPost = "enquiries/";
  public static contactImagePost = "products/uploadImage";
  public static getAllContactEn = "enquiries/getEnquiries/";
  public static getEnquiriesSearch = "enquiries/getEnquiries/";
  public static deleteticketById = "enquiries/deleteTicket/";
  public static deleteMultipeticket = "enquiries/deleteMultipleTickets/";
  public static closeticket = "enquiries/closeTicket/";
  public static closeMulTicket = "/enquiries/closeMultipleTickets/";
  //Estimation-
  public static GetAllEstimation = "invoice/getAllInvoices/";
  public static postEstimationDetails = "invoice/estimation";

  //Dashboard
  public static getTotalSales = "dashboard/getTotalSales/";
  public static getDayWiseInvoiceReport = "dashboard/getDayWiseInvoiceReport/";
  public static getTotalInvoiceWeekReport =
    "dashboard/getTotalInvoiceWeekReport/";
  public static getTotalInvoiceMonthReport =
    "dashboard/getTotalInvoiceMonthReport/";
  public static getTotalInvoiceYearReport =
    "/dashboard/getTotalInvoiceYearReport/";
  public static getNewParties = "dashboard/newPartyJoined/";
  public static getNetSalesProfit = "dashboard/netSalesandProfit/";
  public static getRecentSale = "dashboard/recentSales/";
  public static getSalesPurchase = "dashboard/saleVsPurchase/";
  public static getProductsSold = "dashboard/productSoldToday/";
  public static getTopProductsSold = "dashboard/topSoldProducts/";
  public static getgrossProfitLossDashboard = "dashboard/grossProfitLoss/";
  public static getProfitLossCurrentYear = "dashboard/profitLoss/";
  public static getProfitLossLastYear = "dashboard/profitLossLastYear/";
  public static demandSupply="https://uaestaging.iceipts.com/api/inventory/products/demandPrediction/"
  public static reviewAi="products/customersentiment?userId="
  public static reviewAip="products/customersentiment"
  // public static getTotalSale = "dashboard/getTotalSales/";

  //Employee
  public static EmployeeDelete = "users/deleteUser/";

  //gst Report
  public static gstr1 = "invoice/gstr1/";
  public static gstrTax = "invoice/gstTax/";
  public static gstr3B = "invoice/gst3b/";
  public static gstr4B = "invoice/gst4/";
  public static salesummary = "invoice/saleSummary/";
  public static salesummarysearch = "invoice/saleSummary/";
  public static gstr2A = "invoice/gst2A/";
  public static gstrTaxRate = "invoice/gstTaxRate/";
  public static gstr9 = "invoice/gstr9/";


  //expense API's
  public static expenseListData = "expense/getAllExpense/"
  public static expnenseDeleteSingle = "expense/deleteExpense/"
  public static saveExpnese = "/expense/addExpense/"
  public static expenseCategory = "/expense/getAllExpenseCategory"
  public static expenseDetailsById = "/expense/getExpenseById/"
  public static expenseUpdate = "/expense/updateExpense/"
  public static expenseItemsUpdate = "/expense/updateExpenseItems/"

  //Lead
  public static addLead = "crm/addLeads/";
  public static leadList = "crm/getAllLeads/";
  public static searchleadList = "crm/getAllLeads/";
  public static editleadList = "crm/getCrmById/";
  public static updateleadList = "crm/updateLeads/";
  public static deleteleadList = "crm/deletLead/";
  public static deletemultileadList = "crm/deleteMultipleLeads/";
  public static imageleadedit = "crm/deletLeadImage/";
  public static meetingList = "crm/getAllContactsByLead/";
  public static activityList = "crm/getAllActivity/";
  public static searchactivityList = "crm/getAllActivity/";
  public static noteactivityList = "crm/getAllContactsByLead/";
  public static taskactivityList = "crm/getAllContactsByLead/";
  public static noteList = "crm/getAllContactsByLead/";
  public static taskList = "crm/getAllContactsByLead/";
  public static mailList = "crm/getAllContactsByLead/";
  public static AddNote = "crm/addNotes/";
  public static AddTask = "crm/addTask/";
  public static AddEmail = "crm/addEmail/";
  public static updateNote = "crm/updateNote/";
  public static updateTask = "crm/updateTask/";
  public static updateemail = "crm/updateEmailLog/";
  public static crmDataContactByLeadId = "crm/getContactById/";
  public static sendUserMail = "users/sendUserMail/"
  public static logMeeeingAdd = "/crm/addMeeting/";
  public static postBulkLead = "crm/uploadBulkLead?userId=";

  //Campaign
  public static getAllCampaigns = "newcampaigns/getAllCampaigns/";
  public static addCampaign = "newcampaigns/addCampaign/";
  public static deleteCampaigns = "newcampaigns/deleteCampaigns/";
  public static updateCampaign = "newcampaigns/updateCampaign/";
  public static GetCampaignBYCamapignId = "newcampaigns/getCampaignById/"

  //EamilSetup
  public static postEmailSetup = "users/setMailPassword";

  /*-----------------------------------  role selection All URl below Employee Employee----------------------------------------------------------*/
  //Education-----------------------------------------------
  //Add Employee -- POST
  public static empaddeduction = "employees/addEducation/";
  // Get All Education: -- GET
  public static empeductionlist = "employees/getEmployeeEducationList/";
  // Get Education By Id: -- GET
  public static empgeteduction = "employees/getEducationById/";
  //Update Employee Education Data: -- PUT
  public static empupdateeduction = "employees/updateEducationList/";
  //. Delete Education: -- DELETE
  public static empdeleteduction = "employees/deleteEducationDetails/";


  //---EMPLOYEE ID AND CERTIFCATE -------------
  // ADD ID OR CERTIFICATE: -- POST
  public static empaddidcertificate = "employees/addIdAndCertificate/";
  // Get ID or Certificate
  public static empgetidcertificate = "employees/getIdAndCerti/";
  //3. Get Document By Id:
  public static empgetdocument = "employees/getIdAndCertiById/";
  //4.Update Document: --PUT
  public static empupdatedocument = "employees/updateIdOrDocument/";
  //5. Delete Documents:
  public static empdeletedocument = "employees/deleteDocument/";


  //---EMPLOYEE Leave -------------
  //1. Add Leave: POST
  public static empaddleave = "employees/addLeave/";
  // 2. Get All Leave: Get
  public static empgetAllleave = "employees/getEmployeeLeaveList/";
  public static empgetAllleave1 = "employees/getLeaveTaken/";
  // Get Leave By Id:
  public static empgetByIdleave = "employees/getLeaveListById/";
  // Update Leave Request:
  public static empupdateByIdleave = "employees/updateLeave/";
  //.Delete Leave: --Delete
  public static empdeleteByIdleave = "employees/deleteLeave/";

  //---EMPLOYEE Expense -------------
  //1. Add expense: POST
  public static empaddexpense = "employees/addExpense/";

  //2. Get All Expense: GET
  public static empgetAllexpense = "employees/getAllExpenses/";

  //3. Get Expense By Id:
  public static empgetByIdexpense = "employees/getExpensesById/";

  //4. Delete Expense :
  public static empdeleteexpense = "employees/deletExpenses/";

  //5.update expense
  public static updateexpenseById = "employees/updateExpense/";

  //upload image with pdf file
  public static uploadDoc = "users/uploadImage"

  //EmployeeAttendence Api
  public static postAttendence = "employees/addAttendence";
  public static getAttendence = "employees/getEmployeeAttendence/";

  //Time-sheet Apis
  public static timeleaveapproval = "employees/getEmployeeLeaveListUser/";
  public static timeleaveapprovalsearch = "employees/getEmployeeLeaveListUser/";
  public static deleteapproval = "employees/deleteLeave/";
  public static satusapproval = "employees/updateLeaveStatus/";
  public static settingleave = "employees/assignLeaves/";
  public static settingleaveupdate = "employees/updateLeaveSettings/";
  public static empgetAllleavedays = "employees/getAssignedLeave/";
  public static deletetime = "addTime/deleteTime/";
  public static multideletetime = "addTime/deleteMultipleTask/";
  public static deletetimeedit = "addTime/getTimeSheetById/";

  //payrol
  public static payrollSettingSave = "employees/salaryComponents/"
  public static salarySubmit="employees/generateSlips/"
  //Retailer Payroll
  public static PayrollPut = "employees/setEmployeePayroll/"


  //Ledger Api's
  public static addLedger = "ledger/addLedger/"
  public static getUserLedger2 = "ledger/getUserLedger2/"
  public static getAllGlnames = "ledger/getAllGlnames/"
  public static addCustomLedger = "ledger/addCustomLedger/"
  public static assignLedgersv2 = "ledger/assignLedgersv2/"
  public static getUserChildLedger = "ledger/getUserChildLedger/"
  public static getUserUsecaseData = "ledger/getUserUsecaseData/"
  public static masterLedgerTrueFalse = "ledger/masterLedgerTrueFalse/"

  //Organization Structure
  public static addOrganizationstructure = "og/addOrganizationstructure/"
  public static getOrganizationstructure = "og/getOrganizationstructure/"
  public static updateOrganizationstructure = "og/updateOrganizationstructure/"

  //Assets Api 
  public static currentASSEtAdd = "equipments/"
  public static nonCurrentAsset = "equipments/getAllNonCurrentAssets/"
  public static getCurrentAssetData = "equipments/getAllCurrentAssets/"
  public static DeleteCurrentAndNonCurrent = "equipments/"
  public static assetsName = "ledger/getUserChildLedger/"
  
  public static rundeprecationPost="equipments/equipmentSetting/"
  public static updateRunDeprac="equipments/updateSettings/"
  public static updateNonCurrentAsset= "equipments/"
  public static nonCurrentAssetbYId = "equipments/"

  //books of Accounts
  public static getJournalList = "ledger/getUserJournalData/"
  public static getTrialList = "ledger/getUserTrailBalance/"

  //salary release 
  public static getAllSalary = "employees/salaryRelese/"
  public static AddEarningSalary = "employees/addBulkEarnandDeduct/"
  public static getSalaryByEmpoyeeId = "employees/salaryReleseEmployeeId/"
    // Warehouse
    public static warehouse = "warehouse/"
    public static packProducts = "invoice/packProducts/"
    public static changeStatusOfPackage = "invoice/changeStatusOfPackage/"
    public static updatePackage = "invoice/updatePackage/"
    public static getAllPackagesByUser = "invoice/getAllPackagesByUser/"
    public static warehouseDashboardCount = "invoice/warehouseDashboardCount/"
    public static requestStockTransfer = "invoice/requestStockTransfer/"
    public static getAllStockTransfer = "invoice/stock/getAllStockTransfer/"
    public static getStockTransferById = "invoice/stock/getStockTransferById/"
    public static stockTransferAcceptReject = "invoice/stockTransferAcceptReject/"

    // Promotions
    public static addPromotion = "promotions/addPromotion/"
    public static getAllPromotion = "promotions/getAllPromotion/"
    public static getPromotionById = "promotions/getPromotionById/"
    public static assignProductsToPromotions = "promotions/assignProductsToPromotions/"
    public static checkoffer = "promotions/checkoffer/"
    public static getAllProductsAvailableForPromotion = "promotions/getAllProductsAvailableForPromotion/"
    public static promotions = "promotions/"

     //product Setting 
  public static brands="brands"
  public static brand_chnageStatus="brands/changeStatus/"
  public static brand_getById="brands/getById/"
  public static category = "category"
  public static category_changeStatus = "category/changeStatus/"
  public static category_getById = "category/getById/"
  public static subCategory = "subCategory"
  public static subCategory_changeStatus = "subCategory/changeStatus/"
  public static subCategory_getById = "subCategory/getById/"
  public static customeField = "products/customeField/default"
  // public static customeField_Details = "products/customeField/details"
}
