import { TitleCasePipe } from '@angular/common';
import lang from "src/assets/langTranslation/language_translation"
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { SalesReturnComponent } from '../sales-return/sales-return.component';
import { saveAs } from 'file-saver';
import { CommonService } from "src/app/core/services/common.service";
import * as XLSX from 'xlsx';
import { ProfileService } from 'src/app/core/services/profile.service';

@Component({
  selector: 'app-sales-return-list',
  templateUrl: './sales-return-list.component.html',
  styleUrls: ['./sales-return-list.component.scss']
})
export class SalesReturnListComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public search="SALES"
  public lang = lang.UAE;
  public partyPageSize: number = 5;
  public currentPageNo: number = 0;
  public pagesize: number = 1;
  public count: number = 0;
  userId:any;
  public saleListRes: any=[];
  public SalesPartYName:any[];
  public saleExclude:any[];
  public saleInclude:any[];
  public downloadData:any[];
  public searchKey: any;
  billGstAmount: any
  isOrderReturn: any
  partyName: any
  returnDate: any
  saleReturnNumber: any
  amountExcludingGst: any
  amountIncludingGst: any
  totalBillAmount: any;
  invoiceNo: any;
  public excelbutton : boolean = false;
  serachexcel: any;
  public isArabic: boolean;
  public signImg: any = '';
  TextSizeprint: string = 'Medium';
  public invoiceTermsCon:any;


  constructor(private saleInvoiceService: SaleInvoiceService,
    private toastService: ToastNotificationService,
    public dialog: MatDialog,
    private commonService: CommonService,
    private titleCasePipe: TitleCasePipe,
    public profileService: ProfileService,
    ) { }

  ngOnInit(): void {
    this.invoiceTermsCon=localStorage.getItem('termsAndConditions');
    // Subscribe to the observable to receive updates
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });
   
    this.getsaleReturnByList();
    this.saleInvoiceService.saleInvoiceAdded$.subscribe(() => {
      // Fetch the updated list of users
      this.getsaleReturnByList();
    });
    this.getUserProfile();
  }
  getUserProfile(){
    this.profileService.getUserProfile({}, this.currentUser.id).then((res => {
      this.signImg = res.data.basicdetails.addSignature
    }),
    )
  }
 

  openDialog(): void {
    const dialogRef: MatDialogRef<SalesReturnComponent> = this.dialog.open(SalesReturnComponent, {
      // width: '1050px',
      height : '730px',
      data: { },
      panelClass: 'custom-Modal-saleReturn-invoice',
      disableClose:true,
      position: {
        right: '0',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  getsaleReturnByList() {
      this.saleInvoiceService.getsaleReturnByList({}, this.currentUser.id,this.partyPageSize,(this.currentPageNo + 1),this.search).then((res:any) => {
        console.log(res.data)
        this.saleListRes= res.data;
        let a=res.data[0];
        this.SalesPartYName=a['party?.partyName']
        let b=res.data[0];
        this.saleExclude=b['sales_products?.amountExcludingGst']
        let c=res.data[0];
         this.saleInclude= c['sales_products?.amountIncludingGst']

      },
        (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
            // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
            // this.toastService.openErrorSnackBar("Something Went Wrong.");
          }
    })
  }


  downlaodAllData(){
    if(this.saleListRes.length === 0){
      this.toastService.toastMsg({
        title: "Error",
        content: "No Data Available For Download",
      });
      // this.toastService.openErrorSnackBar("No Data Available For Download");
    } else {
        this.saleInvoiceService.getsaleReturnByList({}, this.currentUser.id,this.partyPageSize,(this.currentPageNo + 1),this.search).then((res:any) => {
          this.downloadData = res.data;
          if(this.downloadData.length > 0){
            this.exportToExcel()
          } else {
            this.toastService.toastMsg({
              title: "Error",
              content: "No Data Available For Download",
            });
            // this.toastService.openErrorSnackBar("No Data Available For Download");
          }
        },(err)=>{
          if(err.error.status === 500){
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
            // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
          }
        })
    }
  }
  downlaodDatasearch(){
    console.log("search excel")
    if(this.saleListRes.length === 0){
      // this.toastService.openErrorSnackBar("No Data Available For Download");
      this.toastService.toastMsg({
        title: "Error",
        content: "No Data Available For Download",
      });
    } else {
      this.saleInvoiceService.getAllSaleReturnSearch({}, this.currentUser.id, "SALES", this.serachexcel)
      .then((res) => {
          this.downloadData = res.data;
          if(this.downloadData.length > 0){
            this.exportToExcel()
          } else {
            this.toastService.toastMsg({
              title: "Error",
              content: "No Data Available For Download",
            });
            // this.toastService.openErrorSnackBar("No Data Available For Download");
          }
        },(err)=>{
          if(err.error.status === 500){
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
            // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
          }
        })
    }
  }
  exportToExcel(): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.downloadData);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(data, 'sale_return_data.xlsx');
  }

  getAllsalereturnbySearch(searchKey) {
    console.log("kyasearch kar raha hai -->", this.searchKey);
    this.serachexcel = this.searchKey;
    if (this.searchKey == "") {
      // this.toastService.openErrorSnackBar("Please Enter Valid Invoice Number!!!");
      this.saleInvoiceService.getAllSaleReturnSearch({}, this.currentUser.id, "SALES", searchKey)
        .then((res) => {
          console.log("Empty Search", res)
          this.saleListRes = res.data;
          this.excelbutton = false;
          // this.totalrow = res.data.totalrows;
        });
    }
    else {
      this.saleInvoiceService.getAllSaleReturnSearch({}, this.currentUser.id, "SALES", searchKey)
        .then((res) => {
          console.log("Empty Search", res)
          this.saleListRes = res.data;
          this.excelbutton = true;
          // this.totalrow = res.data.totalrows;
          // this.toastService.openSnackBar("Sales Found Successfully!!!");
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
            // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
            // this.toastService.openErrorSnackBar("Something Went Wrong.");
          }

        })
    }

  }

  getReceiptById(receiptId) {
    const data = this.saleListRes.find(item => item.id === receiptId);
    console.log(data);
    this.billGstAmount = data.billGstAmount;
    this.invoiceNo = data.invoiceNo;
    this.isOrderReturn = data.isOrderReturn;
    this.partyName = data['party.partyName'];
    this.returnDate = data.returnDate;
    this.saleReturnNumber = data.saleReturnNumber;
    this.amountExcludingGst = data['sales_products.amountExcludingGst'];
    this.amountIncludingGst = data['sales_products.amountIncludingGst'];
    this.totalBillAmount = data.totalBillAmount;
  }
  getTextSize(selectedTextSize: string): number {
		switch (selectedTextSize) {
		  case 'Large':
			return 22;
		  case 'Medium':
			return 20;
		  case 'Small':
			return 18;
		  default:
			return 20; // Default to 'Medium' size if an invalid option is selected
		}
	  }

	  getTextSizeprint(TextSizeprint: string): number {
		switch (TextSizeprint) {
		  case 'Large':
			return 16;
		  case 'Medium':
			return 14;
		  case 'Small':
			return 12;
		  default:
			return 14; // Default to 'Medium' size if an invalid option is selected
		}
	  }

}
