import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardService } from 'src/app/core/services/dashboard.service'

@Component({
  selector: 'app-sales-purchase-chart',
  templateUrl: './sales-purchase-chart.component.html',
  styleUrls: ['./sales-purchase-chart.component.scss']
})
export class SalesPurchaseChartComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public todaysDate = this.commonService.getTodaysDate();
  public totalSales: any;
  public totalPurchase: any;
  public salesPurchaseChart: any = [];

  constructor(public commonService: CommonService, public dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.getColumnBarChart()
  }

  getColumnBarChart(){
    // Sale vs purchase column chart data
    this.dashboardService.getSalesPurchase({}, this.currentUser.id, this.todaysDate).subscribe((res:any) => {
      // console.log(res.data.dataset);
      const data = res.data.dataset;
      const salesData = [];
      const purchaseData = [];

      data.forEach(item => {
        salesData.push(item.salesTotalBillAmount);
        purchaseData.push(item.purchaseTotalBillAmount);
      });
      const monthNames = data.map(item => {
        const month = new Date(0, item.date - 1).toLocaleString('en-US', { month: 'short' });
        return month;
      });
      this.totalPurchase = res.data.totalPurchase
      this.totalSales = res.data.totalSales

  this.salesPurchaseChart = Highcharts;
  var issalesDataEmpty = salesData.every(value => value === 0);
    var ispurchaseDataEmpty = purchaseData.every(value => value === 0);
    if (issalesDataEmpty && ispurchaseDataEmpty) {
      // Display a "No data found" message
      const chartOptions = {
        chart: {
          type: 'column',
          events: {
            load: function() {
              const chart = this;
              const centerX = chart.plotWidth / 2;
              const centerY = chart.plotHeight / 2;

              // Add the image element
              chart.noDataImage = chart.renderer
                .image('../../../assets/img/icons/spot-illustrations/notfound1.png', centerX - 90, centerY - 70, 200, 150)
                .add();
            }
          }
        },
        title: {
          text: 'Sale Vs Purchase',
          // text: ' ',
          style: {
            fontFamily: "'Poppins', sans-serif",
            fontWeight: '500',
            fontSize: '18px',
            color: '#737791'
          }
        },
        xAxis: {
          categories: monthNames
        },
        credits: {
          enabled: false
        },
      };
      this.salesPurchaseChart.chart('sales-purchase-chart', chartOptions);
      document.getElementById('sales-purchase-chart').style.height = '300px';

    } else {
        const chartOptions = {
          chart: {
         type: 'column',
          },
          title: {
             text: 'Sale Vs Purchase',
            //  text: ' ',
             style: {
              fontFamily: "'Poppins', sans-serif",
              fontWeight: '500',
              fontSize: '18px',
              color: '#737791'
            }
          },
          yAxis : {
            gridLineWidth: 0
          },
          xAxis : {
            categories: monthNames,
            gridLineWidth: 0
          },
          // tooltip : {
          //    valueSuffix: ''
          // },
          plotOptions : {
            column: {
              dataLabels: {
                enabled: true,
              },
              tooltip: {
                enabled: true,
                formatter: function () {
                  return this.y; // Display the amount as the tooltip content
                },
              },
            },
          },
          credits:{
             enabled: false
          },
          series: [
             {
               name: 'Sale',
               data: salesData,
               color: '#110152',

              },
              {
                name: 'Purchase',
                data: purchaseData,
                color: '#FC7643',
             },
          ]
        };
        this.salesPurchaseChart.chart('sales-purchase-chart', chartOptions);

        // Add CSS to adjust the size visually
        // document.getElementById('salesCanvas').style.width = '300px'; // Set your desired width
        document.getElementById('sales-purchase-chart').style.height = '300px';
      }
  }, (err) => {
    if (err.error.status == 404) {
      // Handle error
    }
  });
}
}
