import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[ngxOnlyEmails]'
})
export class OnlyEmailDirective {
  pattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  constructor() { }

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return new RegExp(this.pattern).test(event.key);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    event.preventDefault();
  }
}
