import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(public httpClient: HttpService,private http:HttpClient) { }

  getTotalSales(data: {},userId, receiptsType): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getTotalSales + userId + "?receiptsType=" + receiptsType, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  
  getDayWiseInvoiceReport(data: {},userId,startDate,receiptsType): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getDayWiseInvoiceReport + userId + "?startDate=" + startDate + "&receiptsType=" + receiptsType, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getTotalInvoiceWeekReport(data: {},userId,startDate,receiptsType): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getTotalInvoiceWeekReport + "?userId=" + userId + "&startDate=" + startDate + "&receiptsType=" + receiptsType, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getTotalInvoiceMonthReport(data: {},userId, startDate,receiptsType): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getTotalInvoiceMonthReport + "?userId=" + userId + "&startDate=" + startDate + "&receiptsType=" + receiptsType, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  
  getTotalInvoiceYearReport(data: {},userId, startDate,receiptsType): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getTotalInvoiceYearReport + "?userId=" + userId + "&startDate=" + startDate + "&receiptsType=" + receiptsType, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getNewParties(data : {}, userId){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getNewParties + userId, 'GET')
        .subscribe((res) => {
          resolve(res), 
          (err) => reject(err)
        })
    })
  }

  getNetSalesProfit(data : {}, userId){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getNetSalesProfit + userId, 'GET')
        .subscribe((res) => {
          resolve(res), 
          (err) => reject(err)
        })
    })
  }

  getRecentSale(data : {}, userId){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getRecentSale + userId, 'GET')
        .subscribe((res) => {
          resolve(res), 
          (err) => reject(err)
        })
    })
  }
  
  getSalesPurchase(data : {}, userId,  startDate){
    // return new Promise((resolve, reject) => {
      return this.httpClient.inventory(data, UrlConstants.getSalesPurchase + userId + "?startDate=" + startDate, 'GET')
    //     .subscribe((res) => {
    //       console.log(res);
    //       resolve(res), 
    //       (err) => reject(err)
    //     })
    // })
  }
  
  getProductSold(data : {}, userId,  receiptsType){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getProductsSold + userId + "?receiptsType=" + receiptsType, 'GET')
        .subscribe((res) => {
          resolve(res), 
          (err) => reject(err)
        })
    })
  }
  
  getTopProductSold(data : {}, userId, limit,  receiptsType){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getTopProductsSold + userId + "?limit=" + limit + "&receiptType=" + receiptsType, 'GET')
        .subscribe((res) => {
          resolve(res), 
          (err) => reject(err)
        })
    })
  }
  
  getProfitLoss(data : {}, userId, startDate, endDate){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getgrossProfitLossDashboard + userId + "?startDate=" + startDate + "&endDate=" + endDate, 'GET')
        .subscribe((res) => {
          resolve(res), 
          (err) => reject(err)
        })
    })
  }
  
  getProfitLossCurrentYear(data : {}, userId){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getProfitLossCurrentYear + userId, 'GET')
        .subscribe((res) => {
          resolve(res), 
          (err) => reject(err)
        })
    })
  }
  
  getProfitLossLastYear(data : {}, userId){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getProfitLossLastYear + userId, 'GET')
        .subscribe((res) => {
          resolve(res), 
          (err) => reject(err)
        })
    })
  }

  postdemand(data: {}) {
    return new Promise((resolve, reject) => {
      this.httpClient.demand(data, UrlConstants.demandSupply, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  reviewProductdash(data: {},id) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.reviewAi + id , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  reviewProduct(data: {},productId,userId) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.reviewAip + "?productId=" + productId +"&userId=" + userId , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

}
