<div class="card-body p-0 overflow-hidden">
        
    <div class="table-responsive">
        <table class="table table-striped  bg-white">
            <thead>
                <tr class="btn-reveal-trigger">
                    <th scope="col">Name</th>
                    <th scope="col"></th>
                    <th scope="col">Users</th>
                    <th scope="col" class="text-center">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let manage of manageCalenderData">
                    <th>{{manage.Name}}</th>   
                    <th>{{manage.schedule}}</th> 
                    <th>{{manage.Users}}</th>                     
                    <td>
                        <i class="fas fa-edit icolor"></i>
                    
                    </td>
                   
                </tr>
            </tbody>
           
        </table>
    </div>
</div>
