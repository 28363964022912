import { Component, OnInit, ViewChild } from '@angular/core';
import lang from "src/assets/langTranslation/language_translation"
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { ToWords } from 'to-words';
import { DatePipe, TitleCasePipe } from "@angular/common";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SalesInvoiceComponent } from '../sales-invoice/sales-invoice.component';
import { CommonService } from "src/app/core/services/common.service";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import * as numberToWords from 'number-to-words';

@Component({
  selector: 'app-sale-invoice-list',
  templateUrl: './sale-invoice-list.component.html',
  styleUrls: ['./sale-invoice-list.component.scss']
})
export class SaleInvoiceListComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  @ViewChild('closebutton') closebutton;
  public lang = lang.UAE;
  totalGST = 0;
  public invoiceData: any = [];
  public saleProductData: any = [];
  public basicDetails: any = [];
  public addressDetails: any = [];
  public salesData: any = [];
  public BusinessName: any;
  public invoiceNo: any;
  public invoiceDate: any;
  public dueDate: any;
  public billingAddress: any;
  public placeOfSupply: any;
  public shippingAddress: any;
  public imageSrc: any = '';
  public imageLoaded: boolean = false;
  public billGstAmount: number;
  public totalBillAmount: number;
  public finalAmount: any;
  public wordAmount = '';
  public gstStateMatch: boolean = true;
  public tradeName: any
  public gstinNo: any;
  public mobile: any;
  public hsn: any;
  public searchKey: any;
  public Registered: any;

  downloadData: any = [];

  toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: true,
    }
  });
  public p = 1;
  public currentpage: any;
  public totalrow: any;
  public toatlPages: any;

  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  public pagesize: number = 1;
  public count: number = 0;
  public isArabic: boolean;
  currencySymbol: string;
  public signImg: any = '';
  TextSizeprint: string = 'Medium';
  public invoiceTermsCon:any;

  constructor(private saleInvoiceService: SaleInvoiceService,
    public dialog: MatDialog,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
    public profileService: ProfileService,
    private commonService: CommonService,
    private modal: NgbModal, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.invoiceTermsCon=localStorage.getItem('termsAndConditions');
    // Subscribe to the observable to receive updates
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });
    this.getAllSaleInvoice();

    this.saleInvoiceService.saleInvoiceAdded$.subscribe(() => {
      // Fetch the updated list of users
      this.getAllSaleInvoice();
    });

    //call profile get api
    this.getProfileData();


  }

  getAllSaleInvoice() {
    this.saleInvoiceService.getAllSaleInvoice({}, this.currentUser.id, "SALES", this.partyPageSize, (this.currentPageNo + 1)).then((res) => {
      this.invoiceData = res.data.pageData;
      this.totalrow = res.data.totalrows
    })
  }

  getAllsaleinvoicebySearch(searchKey) {
    if (this.searchKey == "") {
      this.currentPageNo = 0;
      this.saleInvoiceService.getAllSaleInvoiceSearch({}, this.currentUser.id, "SALES", this.partyPageSize, (this.currentPageNo + 1), searchKey.trim())
        .then((res) => {
          this.invoiceData = res.data.pageData;
          this.totalrow = res.data.totalrows;
          if (this.currentPageNo > 0) {
            if (this.invoiceData.length === 0) {
              this.currentPageNo = 0;
              this.getAllsaleinvoicebySearch(this.searchKey);
            }
          }
        });
    }
    else {
      this.currentPageNo = 0;
      this.saleInvoiceService.getAllSaleInvoiceSearch({}, this.currentUser.id, "SALES", this.partyPageSize, (this.currentPageNo + 1), searchKey.trim())
        .then((res) => {
          this.invoiceData = res.data.pageData;
          this.totalrow = res.data.totalrows;
          if (this.currentPageNo > 0) {
            if (this.invoiceData.length === 0) {
              this.currentPageNo = 0;
              this.getAllsaleinvoicebySearch(this.searchKey);
            }
          }
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        })
    }

  }
  /**
   * get receipt for invoive by id
   * @param receiptId
   */
  getReceiptById(receiptId) {
    this.saleInvoiceService.getSaleInvoiceById({}, receiptId).then((res) => {
      this.salesData = res.data;
      this.saleProductData = res.data.sales_products;
      this.invoiceNo = res.data.invoiceNo;
      this.invoiceDate = res.data.invoiceDate;
      this.dueDate = res.data.dueDate;
      this.billingAddress = res.data.billingAddress;
      this.shippingAddress = res.data.shippingAddress;
      this.placeOfSupply = res.data.placeOfSupply;
      this.billGstAmount = res.data.billGstAmount;
      console.log("billGstAmount", this.billGstAmount);
      this.totalGST = res.data.billGstAmount;
      this.totalBillAmount = res.data.totalBillAmount;
      this.finalAmount = this.totalBillAmount;
      this.hsn = res.data.sales_products[0].product.hsn;
      console.log("this.hsn", this.hsn);
      this.convertToWord(this.finalAmount)

      if (this.salesData.supplyType == 'INTERSTATE') {
        this.gstStateMatch = false;
        console.log("-----------", this.gstStateMatch)
      } else {
        this.gstStateMatch = true;
      }

    })
  }
  getProfileData() {
    this.profileService.getUserProfile({}, this.currentUser.id).then((res => {
      this.imageSrc = res.data.basicdetails.profileImage;
      this.addressDetails = res.data.primaryAddress;
      this.basicDetails = res.data.basicdetails;
      this.BusinessName = res.data.additional.legalName;
      this.tradeName = res.data.additional.tradeName;
      this.mobile = res.data.basicdetails.mobile;
      this.gstinNo = res.data.additional.gstinNo;
      this.signImg = res.data.basicdetails.addSignature
    }),
    )
  }



  onPrint() {
    const printContents = document.getElementById('panel').innerHTML;
    const originalContents = document.body.innerHTML;
    console.log("originalContents", originalContents)

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }

  // onPrint() {
  //   const printContents = document.getElementById('panel').outerHTML;
  //   const originalContents = document.body.innerHTML;

  //   const printWindow = window.open('', '_blank');
  //   printWindow.document.write('<html><head><title>Print</title>');
  //   // You may want to include additional stylesheets, meta tags, or other head content here
  //   printWindow.document.write('</head><body>');
  //   printWindow.document.write(printContents);
  //   printWindow.document.write('</body></html>');
  //   printWindow.document.close();

  //   printWindow.print();
  //   printWindow.onload = function() {
  //     printWindow.print();
  //     printWindow.onafterprint = function () {
  //       // Delay closing the window to ensure print is complete
  //       setTimeout(function() {
  //         printWindow.close();
  //       }, 100);
  //     };
  //   };

  //   // Restore the original contents
  //   document.body.innerHTML = originalContents;
  // }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  closeModal() {
  }
  convertToWord(num) {
    if (!this.isArabic) {
      this.finalAmount = this.toWords.convert(num);
    }
    else {
      const toWords = new ToWords({
        converterOptions: {
          currency: false,
          ignoreDecimal: false,
          ignoreZeroCurrency: true,
        },
      });
      this.finalAmount = numberToWords.toWords(num);
      this.currencySymbol = 'AED';
      // this.finalAmount = toWords.convert(num);
      console.log(`${this.finalAmount} ${this.currencySymbol}`);

    }
  }

  // for pagination
  handlePage(e) {
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.invoiceData.length > 0) {
      this.partyPageSize = e.pageSize;
      
    const searchInput = document.getElementById('searchInput') as HTMLInputElement;
    if (searchInput.value !== '') {
      this.getAllsaleinvoicebySearch(searchInput.value);
    } else {
      this.getAllSaleInvoice();
    }
    }
  }

  openDialog(): void {
    const dialogRef: MatDialogRef<SalesInvoiceComponent> = this.dialog.open(SalesInvoiceComponent, {
      // width: '1050px',
      height: '730px',
      data: {},
      panelClass: 'custom-Modal-sale-invoice',
      disableClose: true,
      position: {
        right: '0',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  downlaodAllData() {
    if (this.totalrow === undefined || this.totalrow === null) {
      this.toastService.toastMsg({
        title: "Error",
        content: "No Data Available For Download",
      });
      // this.toastService.openErrorSnackBar("No Data Available For Download");
    } else {
      this.saleInvoiceService.getAllSaleInvoice({}, this.currentUser.id, "SALES", this.totalrow, (this.currentPageNo + 1)).then((res) => {
        this.downloadData = res.data.pageData;
        if (this.downloadData.length > 0) {
          this.exportToExcel()
        } else {
          this.toastService.toastMsg({
            title: "Error",
            content: "No Data Available For Download",
          });
          // this.toastService.openErrorSnackBar("No Data Available For Download");
        }
      }, (err) => {
        if (err.error.status === 500) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
      })
    }
  }
  formatDate(date: Date): string {
    return

  }
  exportToExcel() {
    if (this.isArabic) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet 1');

      // Define a header style
      const headerStyle = {
        font: { color: { argb: 'FF0000FF' }, bold: true },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFF00FF' } },
      };

      // Define your headers
      const headers = [
        'Invoice Number',
        'Date',
        'Due Date',
        'Customer Name',
        'Contact #',
        'Registered',
        'TRN  #',
        'Payment Status',
        'Invoice  Amount (In AED)',
        'VAT (in AED.)',
        'Billing Address',
        'Shipping Address',
      ];

      // Apply the header style to the header row
      const headerRow = worksheet.addRow(headers);
      headerRow.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
        };
        cell.fill = headerStyle.fill;
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        //  cell.width = 120

      });
      // Set column widths for header cells
      worksheet.columns.forEach((column, index) => {
        // Assuming you want a fixed width of 120 for all header columns
        column.width = 15;
      });

      // Set background colors for multiple cells using a loop
      const cellBackgroundColors = [
        { cell: 'A1', color: 'FF8DB4E2', width: 5 }, // light blue background for cell A1
        { cell: 'B1', color: 'FF8DB4E2' },
        { cell: 'C1', color: 'FF8DB4E2' },
        { cell: 'D1', color: 'FFFFC4D5' }, //for orange
        { cell: 'E1', color: 'FFFFC4D5' },
        { cell: 'F1', color: 'FFFFC4D5' },
        { cell: 'G1', color: 'FFFFC4D5' },
        { cell: 'H1', color: 'FF8DB4E2' },
        { cell: 'I1', color: 'FF8DB4E2' },
        { cell: 'J1', color: 'FF8DB4E2' },
        { cell: 'K1', color: 'FFFFC4D5' },
        { cell: 'L1', color: 'FFFFC4D5' },

         // FF8DB4E2- blue color code  FFFFC4D5 Orange
      ];
      cellBackgroundColors.forEach((cellColor) => {
        worksheet.getCell(cellColor.cell).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: cellColor.color },
        };
      });

      this.downloadData.forEach((item) => {
        const row = [];
        // Add your data to the row
        row.push(item.invoiceNo);
        const formattedInvoiceDate = this.datePipe.transform(item.invoiceDate, 'dd MMM yyyy');
        row.push(formattedInvoiceDate ? formattedInvoiceDate : "NA");
        const dueDate = this.datePipe.transform(item.dueDate, 'dd MMM yyyy');
        row.push(dueDate ? dueDate : "NA");
        row.push(item.party ? item.party.partyName : "NA",)
        row.push(item.mobile)
        row.push((item && item.party?.gstIn) ? "Registered" : "Unregistered"),
          row.push((item && item.party?.gstIn) ? item.party.gstIn : "NA",)
        row.push(item.paymentStatus ? "Credit" : "Paid",),
          row.push(item.received,)
        const vatAmount = parseFloat(item.billGstAmount);
        row.push(vatAmount.toFixed(2));
        row.push(item.billingAddress,)
        row.push(item.shippingAddress);
        worksheet.addRow(row);
        const vatCell = worksheet.getCell('J' + worksheet.rowCount);

        // Set the alignment of the VAT cell to right
        vatCell.alignment = { horizontal: 'right' };

        // Get the cell in the VAT column for the current row

      });


      // Generate the Excel file
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'sale_invoice_data.xlsx');
      });

    }
    else{
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet 1');

      // Define a header style
      const headerStyle = {
        font: { color: { argb: 'FF0000FF' }, bold: true },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFF00FF' } },
      };

      // Define your headers
      const headers = [
        'Invoice #',
        'Date',
        'Due Date',
        'Customer Name',
        'Contact #',
        'Registered',
        'GSTIN',
        'Payment Status',
        'Invoice  Amount (In Rs)',
        'GST (in Rs)',
        'Cess (in Rs.)',
        'Billing Address',
        'Shipping Address',
        'E-Way Bill #',
        'Reverse Charge',
        'Supply',
        'Supply to (State)'
      ];



      // Apply the header style to the header row
      const headerRow = worksheet.addRow(headers);
      headerRow.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
        };
        cell.fill = headerStyle.fill;
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        //  cell.width = 120

      });
      // Set column widths for header cells
      worksheet.columns.forEach((column, index) => {
        // Assuming you want a fixed width of 120 for all header columns
        column.width = 15;
      });

      // Set background colors for multiple cells using a loop
      const cellBackgroundColors = [
        { cell: 'A1', color: 'FF8DB4E2', width: 5 }, // light blue background for cell A1
        { cell: 'B1', color: 'FF8DB4E2' },
        { cell: 'C1', color: 'FF8DB4E2' },
        { cell: 'D1', color: 'FFFFC4D5' }, //for orange
        { cell: 'E1', color: 'FFFFC4D5' },
        { cell: 'F1', color: 'FFFFC4D5' },
        { cell: 'G1', color: 'FFFFC4D5' },
        { cell: 'H1', color: 'FF8DB4E2' },
        { cell: 'I1', color: 'FF8DB4E2' },
        { cell: 'J1', color: 'FF8DB4E2' },
        { cell: 'K1', color: 'FF8DB4E2' },
        { cell: 'L1', color: 'FFFFC4D5' },
        { cell: 'M1', color: 'FFFFC4D5' },
        { cell: 'N1', color: 'FF8DB4E2' },
        { cell: 'O1', color: 'FF8DB4E2' },
        { cell: 'P1', color: 'FF8DB4E2' },
        { cell: 'Q1', color: 'FF8DB4E2' },

        // FF8DB4E2- blue color code  FFFFC4D5 Orange

      ];
      cellBackgroundColors.forEach((cellColor) => {
        worksheet.getCell(cellColor.cell).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: cellColor.color },
        };
      });





      this.downloadData.forEach((item) => {
        const row = [];
        // Add your data to the row
        row.push(item.invoiceNo);
        const formattedInvoiceDate = this.datePipe.transform(item.invoiceDate, 'dd MMM yyyy');
        row.push(formattedInvoiceDate ? formattedInvoiceDate : "NA");
        const dueDate = this.datePipe.transform(item.dueDate, 'dd MMM yyyy');
        row.push(dueDate ? dueDate : "NA");
        row.push(item.party ? item.party.partyName : "NA",)
        row.push(item.mobile)
        row.push((item && item.party?.gstIn) ? "Registered" : "Unregistered"),
          row.push((item && item.party?.gstIn) ? item.party.gstIn : "NA",)
        row.push(item.paymentStatus ? "Credit" : "Paid",),
          row.push(item.received,)
        const vatAmount = parseFloat(item.billGstAmount);
        row.push(vatAmount.toFixed(2));
        row.push(item.totalCess,)
        row.push(item.billingAddress,)
        row.push(item.shippingAddress);
        row.push(item.ewayBillNo);
        row.push(item.reverseCharge ? "Applicable" : "Not Applicable",)
        row.push(item.supplyType)
        row.push(item.placeOfSupply ? item.placeOfSupply : 'NA')


        worksheet.addRow(row);
        const vatCell = worksheet.getCell('J' + worksheet.rowCount);

        // Set the alignment of the VAT cell to right
        vatCell.alignment = { horizontal: 'right' };


      });


      // Generate the Excel file
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'sale_invoice_data.xlsx');
      });


    }
  }
  getTextSize(selectedTextSize: string): number {
		switch (selectedTextSize) {
		  case 'Large':
			return 22;
		  case 'Medium':
			return 20;
		  case 'Small':
			return 18;
		  default:
			return 20; // Default to 'Medium' size if an invalid option is selected
		}
	  }

	  getTextSizeprint(TextSizeprint: string): number {
		switch (TextSizeprint) {
		  case 'Large':
			return 16;
		  case 'Medium':
			return 14;
		  case 'Small':
			return 12;
		  default:
			return 14; // Default to 'Medium' size if an invalid option is selected
		}
	  }
}
