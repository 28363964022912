import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class PayrollService {

  constructor(public httpClient:HttpService) { }

  // {{APISERVER}}/employees/addSalary/
  addSalary(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.addSalary , 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  // {{APISERVER}}/employees/assignStructure/
  assignStructure(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.assignStructure , 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }


  // {{APISERVER}}/employees/addStructure/
  addStructure(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.addStructure , 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  // {{APISERVER}}/employees/getAllStructures/{{userId}}
  getAllStructures(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getAllStructures + userId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  // {{APISERVER}}/employees/getStructureById/1
  getStructureById(data: {}, structureId: string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getStructureById + structureId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  payrollSettingSave(data,structureId){
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.payrollSettingSave +structureId, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });

  }
  retailerPayrollPut(data:{},id){
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.PayrollPut + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });

  }
   
  salarySubmit(data: {}, userid): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.salarySubmit + userid , 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

}
