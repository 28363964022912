import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { PartyReportsService } from 'src/app/core/services/party-reports.service';
import { PartyService } from 'src/app/core/services/party.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { map, startWith } from 'rxjs/operators';
import { BiddingService } from 'src/app/core/services/bidding.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { TitleCasePipe } from '@angular/common';
import { ProductService } from 'src/app/core/services/product.service';
import * as XLSX from 'xlsx';

interface IRange {
  value: Date[];
  label: string;

}

@Component({
  selector: 'app-item-wise-discount',
  templateUrl: './item-wise-discount.component.html',
  styleUrls: ['./item-wise-discount.component.scss']
})
export class ItemWiseDiscountComponent implements OnInit {
  fileName= 'ItemWizeDiscount.xlsx';
  public dateRange: Date[];
  public quarterStartDate: any;
  public quarterEndDate: any;
  public today: Date;
  public quarter;
  public maxDate: Date;
  public start = moment().subtract(29, 'days');
  public end = moment();
  public ranges: IRange[];
  public allPartyList: any = [];
  public partyList: any;
  public partyData: any;
  public partyId: any;
  public partyName = new FormControl();

  public filteredOptions: Observable<string[]>;
  public filteredOptionsParty: Observable<string[]>;
  public myControl = new FormControl();
  public myControlParty=new FormControl();
  public partyPagesize: number = 10;
  public currentPageNo: number = 0;
  public submitted: boolean = false;
  public searchedProduct = [];
  public prodName: string;
  public prodId: string;
  public goodsData = [];
  public partyPageSize: number = 5;
  public ABC:any
  public productCategory:any;
  public search: any;

  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public selectedPartyId:any;
  toatlPages: any;
 // toatlPages: any;
  totalrow: any;
  p: any;



  constructor(private partyservice: PartyReportsService,
    private saleInvoiceService: SaleInvoiceService,
    private partyService: PartyService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
    private biddingService: BiddingService,
    private productService: ProductService) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
    let quarter = moment(this.today).utc().quarter() //outputs 4
    this.getQuarterRange();

  }
  isShown: boolean = false;


  ngOnInit(): void {
    this.ranges = [

      {
        value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 7 Days'
      },
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 30 Days'
      },
      {
        value: [new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()), new Date(new Date(new Date().setDate(0)).toDateString())],
        label: 'Last Month'

      },
      {
        value: [this.quarterStartDate._d, this.quarterEndDate._d],
        label: ' Last Quarter'
      },
      {
        value: [new Date(new Date(new Date().getFullYear() - 1, 3, 1).toDateString()), new Date(new Date(new Date().getFullYear(), 2, 31).toDateString())],
        label: 'Last Financial Year'

      },
      {
        value: [new Date(new Date(new Date().getFullYear(), 3, 1).toDateString()), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'This Financial Year'

      },
      {
        value: [],
        label: 'Custom Range'

      }];
    this.dateRange = [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate() ))]
    this.getProductCategory();
    this.getGoodsbyUser();
    this.getAllParties();
  }

  getProductCategory(){
    this.productService.getproductCategory({}).then((res:any) => {
      this.productCategory=res.data;
    })
  }

  printComponent(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;

  }
  getQuarterRange() {
    this.quarterStartDate = moment(this.today).subtract(1, 'quarter').startOf('quarter');
    this.quarterEndDate = moment(this.today).subtract(1, 'quarter').endOf('quarter');
  }
  getGoodsbyUser() {
    this.productService.getGoodsProductByUser_Type({}, this.currentUser.id, "goods","DESC", this.partyPageSize, (this.currentPageNo + 1)).then((res) => {

      this.goodsData = res.data.pageData;
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(name => name ? this.filterNamesGoods(name) : this.goodsData.slice())
        );
    }, (err) => {
      if (err.error.status == 404) {
      }
    })
  }
  private filterNamesGoods(name: string): string[] {
    return this.goodsData.filter(goods =>
      goods.itemName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  //categories.

  selectedProduct(itemName) {
    this.biddingService.getAllProductsByUserSearch({}, this.currentUser.id, itemName, this.partyPageSize, (this.currentPageNo + 1))
      .then((res) => {
        this.toatlPages = res.data.totalPages;
        this.totalrow = res.data.totalRows;
        this.p = res.data.currentPage;
        this.searchedProduct = res.data.pageData;
      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
            });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })
  }

  getCategories(search){
    this.search = search;
    console.log(search);
    this.productService.getCategoriesList({},this.currentUser.id, this.partyPageSize, (this.currentPageNo + 1),this.search.categoryName).then((res) => {

   // this.productService.getCategoriesList({},this.search.categoryName).then((res) => {
    //this.searchedProduct = res.data.pageData;
      console.log(res);
      // if( this.searchedProduct.length > 0)
      // {
      //   this.toastService.openSnackBar("Record Found Successfully!!!")
      // }
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
            });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })
  }

  searchProduct() {
    this.biddingService.getAllProductsByUserSearch({}, this.currentUser.id, this.myControl.value,this.partyPageSize, (this.currentPageNo + 1))
      .then((res) => {
        this.toatlPages = res.data.totalPages;
        this.totalrow = res.data.totalRows;
        this.p = res.data.currentPage;
        this.searchedProduct = res.data.pageData;
      })
  }



  getAllParties() {
    this.partyService
      .getAllPartiesDetails({}, this.currentUser.id, this.partyPagesize, (this.currentPageNo + 1))
      .then((res) => {
        this.allPartyList = res.data.pageData;
        this.filteredOptionsParty = this.partyName.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filterNames(name) : this.allPartyList.slice())
          );
      });
  }
  searchPartyByName(item) {
    this.saleInvoiceService
      .getPartyByName({}, this.currentUser.id, item)
      .then((response: any) => {
        this.partyData = response.data.rows;
        this.allPartyList = response.data.rows;
        if (this.allPartyList) {
          this.filteredOptionsParty = this.myControl.valueChanges.pipe(
            startWith(""),
            map((name) =>
              name ? this.filterNames(name) : this.allPartyList.slice()
            )
          );
        }
      });
  }
  setSelectedPartyDeatails(item) {
    this.selectedPartyId = item.id;
    this.partyId = item.id;
    console.log("Item",item)
    if (this.partyData) {

    } else {
      this.saleInvoiceService
        .getPartyByName({}, this.currentUser.id, item.partyName)
        .then((response: any) => {
          this.partyData = response.data.rows;
          this.allPartyList = response.data.rows;
        });
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(""),
        map((name) =>
          name ? this.filterNames(name) : this.allPartyList.slice()
        )
      );
    }
   // this.getSelectedItemRepoByprty();
   // this.getsalePurchase();
  }
  private filterNames(name: string): string[] {
    return this.allPartyList.filter(partyList =>
      partyList.partyName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  exportExcel(){
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

}
onPrint() {
  const elementsToHide = ['headingPrintHide', 'dateRangePrintHide', 'searchBarHide'];

  elementsToHide.forEach((elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.style.display = 'none';
    }
  });

  // Hide headers and footers for printing
  const style = document.createElement('style');
  style.innerHTML = `
      @media print {
          @page {
              size: auto;   /* auto is the initial value */
              margin: 0;  /* this affects the margin in the printer settings */
          }
          @page {
              margin-top: 0;
              margin-bottom: 0;
          }
          body {
              margin: 0;  /* this affects the margin on the content before sending to the printer */
          }
          @page :first {
              margin-top: 0;
          }
          @page :last {
              margin-bottom: 0;
          }
          /* Define CSS rules to hide headers and footers */
          header, footer {
              display: none !important;
          }
      }
  `;
  document.head.appendChild(style);

  // Print
  const printContents = document.getElementById('excel-table').innerHTML;
  const originalContents = document.body.innerHTML;
  this.removeScrollbar();
  window.print();
  this.restoreScrollbar();
  // Restore hidden elements after printing
  elementsToHide.forEach((elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.style.display = '';
    }
  });
  // Remove the added style for hiding headers and footers
  document.head.removeChild(style);
}
private removeScrollbar() {
  document.body.style.overflow = 'hidden';
}

private restoreScrollbar() {
  document.body.style.overflow = 'auto';
}


}
