import { Component, OnInit, ViewChild } from '@angular/core';
import lang from "src/assets/langTranslation/language_translation"
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { ToWords } from 'to-words';
import { TitleCasePipe } from "@angular/common";
import { PaymentOutComponent } from '../payment-out/payment-out.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { PartyService } from 'src/app/core/services/party.service';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-payment-out-list',
  templateUrl: './payment-out-list.component.html',
  styleUrls: ['./payment-out-list.component.scss']
})
export class PaymentOutListComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  @ViewChild('closebutton') closebutton;
  public lang = lang.UAE;
  totalGST = 0;
  public invoiceData: any = [];
  public saleProductData: any = [];
  public basicDetails:any=[];
  public addressDetails:any=[];
  public salesData:any=[];
  public BusinessName:any;
  public invoiceNo: any;
  public imageSrc: any = '';
  public imageLoaded: boolean = false;
  public billGstAmount: number;
  public totalBillAmount: number;
  public finalAmount:any;
  public wordAmount='';
  public gstStateMatch: boolean = true;
  public tradeName:any
  public searchKey: any;
  public isArabic: boolean;

  toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: true,
    }
  });
  public p=1;
  public currentpage:any;
  public totalrow:any;
  public toatlPages:any;

  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  public pagesize: number = 1;
  public count: number = 0;
  customerName: any;
  description: any;
  invoiceDate: any;
  receiptType: any;
  supplyType: any;
  billingAddress: any;
  shippingAddress: any;
  downloadData: any;
  currencySymbol: string;
  public signImg: any = '';
  TextSizeprint: string = 'Medium';
  public invoiceTermsCon:any;

  constructor(private saleInvoiceService: SaleInvoiceService,  public dialog: MatDialog,
    private toastService: ToastNotificationService, private partyService: PartyService,
    private titleCasePipe: TitleCasePipe,
    private commonService: CommonService,
    public profileService: ProfileService
    ,private modal: NgbModal) { }

  ngOnInit(): void {
    this.invoiceTermsCon=localStorage.getItem('termsAndConditions');

    // Subscribe to the observable to receive updates
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });
   
    this.getAllpaymentOut();
    this.partyService.partyAdded$.subscribe(() => {
      // Fetch the updated list
      this.getAllpaymentOut();
    });
    this.getProfileData();
  }
  getProfileData() {
    this.profileService.getUserProfile({}, this.currentUser.id).then((res => {
      this.imageSrc = res.data.basicdetails.profileImage;
      this.addressDetails = res.data.primaryAddress;
      this.basicDetails = res.data.basicdetails;
      this.BusinessName = res.data.additional.legalName;
      this.tradeName = res.data.additional.tradeName;
      this.signImg = res.data.basicdetails.addSignature

    }),
    )
  }

  getAllpaymentOut() {
    this.saleInvoiceService.getAllSaleInvoice({}, this.currentUser.id,"PAID",this.partyPageSize,(this.currentPageNo + 1)).then((res) => {
      this.invoiceData = res.data.pageData;
      this.totalrow=res.data.totalrows;
    })
  }

  getAllPaymentOutSearch(searchKey){
    if (this.searchKey == "") {
      this.currentPageNo = 0;
    this.saleInvoiceService.getAllSaleInvoiceSearch({}, this.currentUser.id,"PAID",this.partyPageSize,(this.currentPageNo + 1),searchKey.trim())
    .then((res) => {
      this.invoiceData = res.data.pageData;
      this.totalrow=res.data.totalrows;
      if (this.currentPageNo > 0) {
        if (this.invoiceData.length === 0) {
          this.currentPageNo = 0;
          this.getAllPaymentOutSearch(this.searchKey);
        }
      }
    });
  }
  else {
    this.currentPageNo = 0;
    this.saleInvoiceService.getAllSaleInvoiceSearch({}, this.currentUser.id,"PAID",this.partyPageSize,(this.currentPageNo + 1),searchKey.trim())
    .then((res) => {
      this.invoiceData = res.data.pageData;
      this.totalrow=res.data.totalrows;
      if (this.currentPageNo > 0) {
        if (this.invoiceData.length === 0) {
          this.currentPageNo = 0;
          this.getAllPaymentOutSearch(this.searchKey);
        }
      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
      }
    })
  }

}
  /**
   * get receipt for invoive by id
   * @param receiptId
   */
  getReceiptById(receiptId) {
    const invoice = this.invoiceData.find(item => item.id === receiptId);
    this.customerName = invoice.customerName;
    this.billingAddress = invoice.billingAddress;
    this.shippingAddress = invoice.shippingAddress;
    this.description = invoice.description;
    this.invoiceDate = invoice.invoiceDate;
    this.invoiceNo = invoice.invoiceNo;
    this.receiptType = invoice.receiptType;
    this.supplyType = invoice.supplyType;
    this.totalBillAmount = invoice.totalBillAmount;
  }

  onPrint() {
  const printContents = document.getElementById('panel').innerHTML;
  const originalContents = document.body.innerHTML;
  console.log("originalContents",originalContents)

  window.print();
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  closeModal(){
  }

  convertToWord(num) {
    if (!this.isArabic) {
      this.finalAmount = this.toWords.convert(num);
    }
    else {
      const toWords = new ToWords({
        converterOptions: {
          currency: false,
          ignoreDecimal: false,
          ignoreZeroCurrency: true,
        },
      });
      this.currencySymbol = 'AED';
      this.finalAmount = toWords.convert(num);
      console.log(`${this.finalAmount} ${this.currencySymbol}`);

    }
  }

    // for pagination
    handlePage(e) {
      this.currentPageNo = e.pageIndex;
      this.pagesize = e.pageSize;
      if (this.invoiceData.length > 0) {
          this.partyPageSize = e.pageSize;

      const searchInput = document.getElementById('searchInput') as HTMLInputElement;
      if (searchInput.value !== '') {
        this.getAllPaymentOutSearch(searchInput.value);
      } else {
        this.getAllpaymentOut();
      }
      }
    }

    openDialog(): void {
      const dialogRef: MatDialogRef<PaymentOutComponent> = this.dialog.open(PaymentOutComponent, {
        width: '850px',
        height : '730px',
        data: { },
        panelClass: 'custom-Modal-purchase-Out',
        disableClose:true,
        position: {
          right: '0',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      });
    }

    downlaodAllData(){
      if(this.totalrow === undefined || this.totalrow === null){
        this.toastService.toastMsg({
          title: "Error",
          content: "No Data Available For Download",
        });
        // this.toastService.openErrorSnackBar("No Data Available For Download");
      } else {
          this.saleInvoiceService.getAllSaleInvoice({}, this.currentUser.id,"PAID",this.totalrow,(this.currentPageNo + 1)).then((res) => {
            this.downloadData = res.data.pageData;
            if(this.downloadData.length > 0){
              this.exportToExcel()
            } else {
              this.toastService.toastMsg({
                title: "Error",
                content: "No Data Available For Download",
              });
              // this.toastService.openErrorSnackBar("No Data Available For Download");
            }
          },(err)=>{
            if(err.error.status === 500){
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
              // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
            }
          })
      }
    }

    exportToExcel(): void {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.downloadData);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      saveAs(data, 'payment_out_data.xlsx');
    }
    getTextSize(selectedTextSize: string): number {
      switch (selectedTextSize) {
        case 'Large':
        return 22;
        case 'Medium':
        return 20;
        case 'Small':
        return 18;
        default:
        return 20; // Default to 'Medium' size if an invalid option is selected
      }
      }
  
      getTextSizeprint(TextSizeprint: string): number {
      switch (TextSizeprint) {
        case 'Large':
        return 16;
        case 'Medium':
        return 14;
        case 'Small':
        return 12;
        default:
        return 14; // Default to 'Medium' size if an invalid option is selected
      }
      }
}
