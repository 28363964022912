import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amountFormat'
})
export class AmountFormatPipe implements PipeTransform {
  transform(value: number) {
    console.log(value)
    if (value >= 1000000000) {
      return (value / 1000000000).toFixed(2) + 'B';
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(2) + 'M';
    } else if (value >= 100000) {
      return (value / 100000).toFixed(2) + 'L';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(2) + 'K';
    } else {
      return value.toFixed(0);
    }
  }
}
