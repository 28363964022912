import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerProfileService {

  constructor(public httpClient: HttpService,private http:HttpClient) { }
  getCustomerProfileByUserId(data: {},id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.CustomerProfile +id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getCustomerEdit(data: {},customerId):any{
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.CustomerProfileEditByCustomerUserId + customerId, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });

  }
 getUserPincode(data: {}, pincode): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.addPincode + pincode, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  updateClientMobile(data: {}) {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.clientMobileUpdate, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  updateClientMobileOTP(data: {}, userId) {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.mobileUpdateOtp + userId, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  postMultipleAddress(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.addMultipleAddress + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  
  updateMultipleAddress(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.updateMultipleAddress + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteAddressById(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.deleteMultipleAddress + id, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  postProfileImage(data: {}, userId:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.profileImagePost + userId ,  'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}
