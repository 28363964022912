export class StateConstants{
    public static states = [ "ANDHRA PRADESH",
                "ARUNACHAL PRADESH",
                "ASSAM",
                "BIHAR",
                "CHATTISGARH",
                "GOA",
                "GUJARAT", 
                "HARYANA",
                "HIMACHAL PRADESH",
                "JAMMU & KASHMIR",
                "JHARKHAND",
                "KARNATAKA",
                "KERALA",
                "MADHYA PRADESH",
                "MAHARASHTRA",
                "MANIPUR",
                "MEGHALAYA",
                "MIZORAM",
                "NAGALAND",
                "ODISHA",
                "PUNJAB",
                "RAJASTHAN",
                "SIKKIM",
                "TAMIL NADU",
                "TELANGANA",
                "TRIPURA",
                "UTTARAKHAND",
                "UTTAR PRADESH",
                "WEST BENGAL",
                "ANDAMAN & NICOBAR ISLANDS",
                "CHANDIGARH",
                "DADRA & NAGAR HAVELI",
                "DAMAN & DIU",
                "DELHI",
                "LAKSHADWEEP",
                "PONDICHERRY"]
}