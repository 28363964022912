import { ChangeDetectorRef, Directive, ElementRef, Input, NgZone, TemplateRef, ViewContainerRef } from '@angular/core';
import { RolesPermissionsService } from '../core/services/roles-permissions.service';

@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective {
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public permissionFromLocal = JSON.parse(localStorage.getItem("Permission"));
  private permissionType = [];
  public permissionName: any;
  public permissionFromMethod;
  private logicalOp = 'AND';
  private isHidden = true;
  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private rolePermission: RolesPermissionsService,
    private changeRef: ChangeDetectorRef
  ) {
    if(!this.permissionFromLocal){
    this.getPermissionById()
  }
}

  ngOnInit() {
  }

  @Input()
  set appHasPermission(permissionType: String[]) {
    this.permissionType = permissionType
    this.updateView();
  }
  @Input()
  set appHasPermissionName(permissionName: string) {
    this.permissionName = permissionName
    this.updateView();
  }

  @Input()
  set appHasPermissionOp(permop: string) {
    this.logicalOp = permop;

    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {

      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }
  getPermissionById() {
    if (this.currentUser != null) {
      this.rolePermission.getPermissions({}, this.currentUser.id).then((res) => {
        localStorage.setItem("Permission", JSON.stringify({
          data: res.data,
          id: res.id,
        }))
        // NgZone.run(()=>)
        // 
        this.permissionFromMethod = JSON.parse(localStorage.getItem("Permission"));
        this.updateView();
        this.checkPermission(this.permissionFromMethod);
      })

    }

  }

  public checkPermission(data?: any) {
    let hasPermission = false;
    if (this.permissionFromLocal) {
      this.permissionFromLocal = this.permissionFromLocal
    } else {
      this.permissionFromLocal = this.permissionFromMethod;
    }
    if (this.currentUser && this.permissionFromLocal) {
      let permissionType = this.permissionType;
      let permissionName = this.permissionName;
      this.permissionFromLocal.data.forEach(x => {
        if (x.permissionName == permissionName) {
          for (let ck = 0; ck < permissionType.length; ck++) {
            if (x[permissionType[ck]] == 1) {
              hasPermission = true;
              if (this.logicalOp === 'OR') {
                break;
              }
            } else {
              hasPermission = false;
              if (this.logicalOp === 'AND') {
                break;
              }
            }
          }
        }
      })
    }
    return hasPermission
  }

}
