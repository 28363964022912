<div class="container-fluid mb-3 px-0 mx-0">
    <div class="row pt-2 pb-3" id="headingPrintHide">
        <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:500;font-size:20px!important;padding-left: 15px;">
            GSTR 8- Statement For Tax Collection At Source
        </div>
        <div class="col-6 navOption-btns" style="display: flex; justify-content: flex-end;">
            <button class="btn me-3" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()"
                type="button"><i class="fas fa-download"></i></button>

            <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
            </button>
        </div>
    </div>
    <hr class="mt-1" />


    <div class="fs--1 bg-white">
        <div class="row mx-auto mb-3">
            <div class="col-sm-6 mb-3 mt-2" id="headingPrintHide">
                <u class="text-nowrap"><strong style="padding-left: 4px !important;font-weight: 500; font-size: 14px;">Uploaded by
                        Supplier</strong></u>
            </div>

            <!-- <div class="col-sm-4"></div>
                <div class="col-sm-1  mt-2 text-center" id="excelPrintHide" (click)="exportExcel()">
                    <a type="button" style="margin-left: 14px;" class="p-0 text-900 bi-file-earmark-excel fa-2x "></a>
                    <div class="text-nowrap text-900  fs--2  mt-1">Export to Excel</div>
                </div>
                <div class="col-sm-1 mt-2 text-center" id="PrintoptionHide" (click)="onPrint()">
                    <a type="button" class=" p-0 text-900 bi-printer float-right fa-2x"></a>
                    <div class="fs--2 text-900 text-nowrap mt-1">Print</div>
                </div> -->

        </div>

        <h4 style="font-weight: 400; font-size: 14px;font-family: 'Poppins';">Details of supplies made through e-commerce operator</h4>
        <div class="table-responsive scrollbar">
            <div class="container">
                <table class="table bg-white table-bordered table-stripped" id="excel-table">
                    <!-- <table class="table table-bordered"> -->
                    <thead class="table-secondary"style="background-color: #F0F0F0;">
                        <tr class="btn-reveal-trigger">
                            <th class="text-nowrap" scope="col">No of Records</th>
                            <th class="text-nowrap" colspan="3">Details of the supplies made which attract TCS</th>
                            <th class="text-nowrap" colspan="3">Amount of tax Collected at source</th>

                        </tr>
                    </thead>
                    <tbody>

                        <tr class="btn-reveal-trigger">
                            <td></td>
                            <td style="text-align: right;">Gross Value of supplies made</td>
                            <td style="text-align: right;">Value of supplies renamed</td>
                            <td style="text-align: right;">Net amount liable for TCS</td>
                            <td style="text-align: right;">Integrated Tax</td>
                            <td style="text-align: right;">Central Tax</td>
                            <td style="text-align: right;">State/UT Tax</td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>1</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <h4 style="font-weight: 400; font-size: 14px;font-family: 'Poppins';">Amendments to details of supplies in respect of any earlier statement</h4>
        <div class="table-responsive scrollbar">
            <div class="container">
                <table class="table bg-white table-bordered table-stripped">
                    <!-- <table class="table table-bordered"> -->
                    <thead class="table-secondary" style="background-color: #F0F0F0;">
                        <tr class="btn-reveal-trigger">
                            <th class="text-nowrap" scope="col">No of Records</th>
                            <th class="text-nowrap" colspan="3">Details of the supplies made which attract TCS</th>
                            <th class="text-nowrap" colspan="3">Amount of tax Collected at source</th>

                        </tr>
                    </thead>
                    <tbody>

                        <tr class="btn-reveal-trigger">
                            <td></td>
                            <td style="text-align: right;">Gross Value of supplies made</td>
                            <td style="text-align: right;">Value of supplies renamed</td>
                            <td style="text-align: right;">Net amount liable for TCS</td>
                            <td style="text-align: right;">Integrated Tax</td>
                            <td style="text-align: right;">Central Tax</td>
                            <td style="text-align: right;">State/UT Tax</td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>2</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <h4 style="font-weight: 400; font-size: 14px;font-family: 'Poppins';">Details of interest(On account of late payment of TCS amount)</h4>
        <div class="table-responsive scrollbar">
            <div class="container">
                <table class="table bg-white table-bordered table-stripped">
                    <!-- <table class="table table-bordered"> -->
                    <thead class="table-secondary" style="background-color: #F0F0F0;">
                        <tr class="btn-reveal-trigger">
                            <th scope="col">Amount of default</th>
                            <th colspan="3">Amount of interest</th>

                        </tr>
                    </thead>
                    <tbody>

                        <tr class="btn-reveal-trigger">
                            <td></td>
                            <td style="text-align: right;">Integrated Tax</td>
                            <td style="text-align: right;">Central Tax</td>
                            <td style="text-align: right;">State/UT Tax</td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>


                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <h4 style="font-weight: 400; font-size: 14px;font-family: 'Poppins';">Payment of tax and interest</h4>
        <div class="table-responsive scrollbar">
            <div class="container">
                <table class="table bg-white table-bordered table-stripped">

                    <thead class="table-secondary" style="background-color: #F0F0F0;">
                        <tr class="btn-reveal-trigger">
                            <th class="text-nowrap" scope="col">Description</th>
                            <th class="text-nowrap" scope="col" style="text-align: right;">Tax Payable</th>
                            <th class="text-nowrap" scope="col" style="text-align: right;">Tax Paid</th>
                            <th class="text-nowrap" scope="col" style="text-align: right;">Interest Payable</th>
                            <th class="text-nowrap" scope="col" style="text-align: right;">Interest Paid</th>

                        </tr>
                    </thead>
                    <tbody>

                        <tr class="btn-reveal-trigger">
                            <td>Integrated Tax</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>Central Tax</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>State/UT Tax</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
