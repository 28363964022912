<div class="card">
    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
            Contact Us
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>
    <div class="card-body">
        <p class="d-md-inline-block fs--1  ml-5" style="font-weight:500;font-size:18px!important;">
            Raise A Complaints</p> 
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 container">
                    <form [formGroup]="conactForm">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Feedback</mat-label>
                            <mat-select matNativeControl formControlName="ticketType" required disableOptionCentering
                            placeholder="Select Type" 
                            >
                           <mat-option value="ISSUE">Issue</mat-option>
                           <mat-option value="QUERY">Query</mat-option>
                           <mat-option value="FEEDBACK">Feedback</mat-option>
                       </mat-select>
                  
                        </mat-form-field>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Add more features which help us</mat-label>
                            <textarea matInput required formControlName="Message" rows="3"
                            placeholder="Your Message"
                          ></textarea>
                        </mat-form-field>
                        <label class="btn btn-default">
                            <i class="fas fa-paperclip fs--1 textColor"></i>
                            <input 
                            [(ngModel)]="currentInput" [ngModelOptions]="{ standalone: true }" type="file" multiple   (change)="onFileSelected($event)"/>Attach File
                          </label>
                          <div  class="row" *ngFor="let file of myFiles">
                            <div class="col-10 ">
                                <h4 class="textColor">{{file.name}}</h4>
                            </div>
                            <div class="col-2 ">
                                <h4 class="bi-x fw-bold text-end textColor"></h4>
                            </div>
                        </div>
                    </form>
                    <div class="text-right">
                        <button type="button" class="btn btn-outline-primary"(click)="PostContactUsDetails()" data-bs-target="#staticBackdrop"  data-bs-toggle="modal">Submit</button>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                </div>
            </div>
   </div>  
</div>
<!-- <div *ngIf="this.conactForm.valid" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="staticBackdrop"
data-bs-keyboard="false" data-bs-backdrop="static" aria-labelledby="staticBackdropLabel">
<div class="modal-dialog modal-dialog-centered" role="document" style="max-width:300px">
    <div class="modal-content position-relative">
        <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">

        </div>
        <div class="modal-body p-0">
            <div class="rounded-top-lg py-3 ps-4 pe-6">
                <h4 class="mb-0" style="font-weight: 600; " id="modalExampleDemoLabel">
                </h4>
            </div>
            <div class="p-3 pb-0" style="margin-top: -20px;">
                <div class="mb-3">
                    <div>
                        <p style="font-size: 16px!important;">
                            Thanku for Raising Ticket Our Expert Team will get back with in 48 hrs
                        </p>
                    </div>
                    <div>
                        <p  style="font-size: 14px!important;" class="fw-bold text-center">Your Ticket Number is {{ TicketId }}</p>
                    </div>
                    <div>
                        <p class="text-danger" style="font-size: 9px!important;">Note : Rember the ticket no. for further communication 
                            (or) Check the Ticket List </p>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button type="submit" data-bs-dismiss="modal" class="btn btn-outline-primary w-100"
                             (click)="formRest()">Cancel</button>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-outline-primary w-100" (click)="formRest()" data-bs-dismiss="modal">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div> -->

