import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class PartyReportsService {

  constructor(public httpClient: HttpService) { }
  //all parties
  getPartyReportAllPartyReport(data: {}, id ,size,page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.PartyReportAllPartyReport + id +"?size=" + size + "&page=" +page , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getPartyRepoALLFilter(data: {}, userId: string, startOfDateRange, endOfDateRange): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.PartyReportAllPartyReport + userId + "?startOfDateRange=" + startOfDateRange + "&endOfDateRange=" + endOfDateRange, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPartyRepoALLFiltersearchPartyName(data: {}, userId: string,partyName: any,size,page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.PartyReportAllPartyReport + userId + "?partyName=" + partyName +"&size=" + size + "&page=" +page, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }


  //table gst equal filter
  getPartyRepoALLFilteGst(data: {}, userId: string,filter) {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.PartyReportAllPartyReport + userId + "?gstIn="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  //all parties min max payable PartyReportAllPartyReport
  getPartyRepoALLFiltePayableGreater(data: {}, userId: string,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.PartyReportAllPartyReport + userId + "?minPayable="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPartyRepoALLFiltePayableEqual(data: {}, userId: string,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.PartyReportAllPartyReport + userId + "?equalPayable="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPartyRepoALLFiltePayableless(data: {}, userId: string,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.PartyReportAllPartyReport + userId + "?maxPayable="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  //all parties min max receivable
  // getPartyRepoALLFilteReceivable(data: {}, userId: string, startOfDateRange, endOfDateRange, partyName: any, filter): any {
  //   return new Promise((resolve, reject) => {
  //     let link = UrlConstants.PartyReportAllPartyReport + userId + "?startOfDateRange=" + startOfDateRange + "&endOfDateRange=" + endOfDateRange + "&partyName=" + partyName

  //     if (filter.type == "Equal") {

  //       link = link + "&equalReceivable=" + filter.value
  //     }
  //     else if (filter.type == "Greater") {
  //       link = link + "&minReceivable=" + filter.value
  //     }
  //     else if (filter.type == "Less") {
  //       link = link + "&minReceivable=" + filter.value

  //     }
  //     this.httpClient.inventory(data, link, 'GET').subscribe(
  //       (res) => resolve(res),
  //       (err) => reject(err)
  //     );
  //   });
  // }

  getPartyRepoALLFilteReceivableGreater(data: {}, userId: string,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.PartyReportAllPartyReport + userId + "?minReceivable="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPartyRepoALLFilteReceivableEqual(data: {}, userId: string,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.PartyReportAllPartyReport + userId + "?equalReceivable="+filter 
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPartyRepoALLFilteReceivableless(data: {}, userId: string,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.PartyReportAllPartyReport + userId + "?maxReceivable="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }


  //party report by item
  getPartyByItem(data: {}, id, productId ,size,page) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getPartyByItem + id + "?productId=" + productId + "&size=" + size + "&page=" +page, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getPartyByItemName(data: {}, id, ItemName) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getPartyByItem + id + "&itemName" + ItemName, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getItemPartyReport(data: {}, id, productId,size,page, startDate, endDate) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getPartyByItem + id + "?productId=" + productId  + "&size=" + size + "&page=" +page + "&startDate=" + startDate + "&endDate=" + endDate, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  //party report by item table filter sale quantity
  getPartyRepoSaleQuant(data: {}, id, productId, startDate, endDate, filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPartyByItem + id + "?productId=" + productId + "?startDate=" + startDate + "&endDate=" + endDate

      if (filter.type == "Equal") {

        link = link + "&equalSaleQuantity=" + filter.value
      }
      else if (filter.type == "Greater") {
        link = link + "&saleQuantityFilterMin=" + filter.value
      }
      else if (filter.type == "Less") {
        link = link + "&saleQuantityFilterMax=" + filter.value

      }
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPartyRepoSaleQuantGreater(data: {}, id, productId,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPartyByItem + id + "?productId=" + productId + "&saleQuantityFilterMin="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPartyRepoSaleQuantEqual(data: {}, id, productId,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPartyByItem + id + "?productId=" + productId + "&equalSaleQuantity="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPartyRepoSaleQuantless(data: {}, id, productId,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPartyByItem + id + "?productId=" + productId + "&saleQuantityFilterMax="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  //party report by item table filter sale amount
  getPartyRepoSaleAmount(data: {}, id, productId, startDate, endDate, filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPartyByItem + id + "?productId=" + productId + "?startDate=" + startDate + "&endDate=" + endDate

      if (filter.type == "Equal") {

        link = link + "&equalSaleAmount=" + filter.value
      }
      else if (filter.type == "Greater") {
        link = link + "&saleAmountFilterMin=" + filter.value
      }
      else if (filter.type == "Less") {
        link = link + "&saleAmountFilterMax=" + filter.value

      }
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPartyRepoSaleAmountGreater(data: {}, id, productId,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPartyByItem + id + "?productId=" + productId + "&saleAmountFilterMin="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPartyRepoSaleAmountEqual(data: {}, id, productId,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPartyByItem + id + "?productId=" + productId + "&equalSaleAmount="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPartyRepoSaleAmountless(data: {}, id, productId,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPartyByItem + id + "?productId=" + productId + "&saleAmountFilterMax="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  // //party report by item table filter purchase amount
  getPartyRepoPurchaseQuantity(data: {}, id, productId, startDate, endDate, filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPartyByItem + id + "?productId=" + productId + "?startDate=" + startDate + "&endDate=" + endDate

      if (filter.type == "Equal") {

        link = link + "&equalPurchaseAmount=" + filter.value
      }
      else if (filter.type == "Greater") {
        link = link + "&purchaseAmountFilterMin=" + filter.value
      }
      else if (filter.type == "Less") {
        link = link + "&purchaseAmountFilterMax=" + filter.value

      }
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPartyRepoPurchaseQuantityGreater(data: {}, id, productId,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPartyByItem + id + "?productId=" + productId + "&purchaseAmountFilterMin="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPartyRepoPurchaseQuantityEqual(data: {}, id, productId,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPartyByItem + id + "?productId=" + productId + "&equalPurchaseAmount="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPartyRepoPurchaseQuantityless(data: {}, id, productId,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPartyByItem + id + "?productId=" + productId + "&purchaseAmountFilterMax="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }



  // //party report by item table filter purchase quantity
  getPartyRepoPurchaseAmount(data: {}, id, productId, startDate, endDate, filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPartyByItem + id + "?productId=" + productId + "?startDate=" + startDate + "&endDate=" + endDate

      if (filter.type == "Equal") {

        link = link + "&equalPurchaseQuantity=" + filter.value
      }
      else if (filter.type == "Greater") {
        link = link + "&purchaseQuantityFilterMin=" + filter.value
      }
      else if (filter.type == "Less") {
        link = link + "&purchaseQuantityFilterMax=" + filter.value

      }
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }


  getPartyRepoPurchaseAmountGreater(data: {}, id, productId,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPartyByItem + id + "?productId=" + productId + "&purchaseQuantityFilterMin="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPartyRepoPurchaseAmountEqual(data: {}, id, productId,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPartyByItem + id + "?productId=" + productId +"&equalPurchaseQuantity="+filter 
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getPartyRepoPurchaseAmountless(data: {}, id, productId,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPartyByItem + id + "?productId=" + productId +"&purchaseQuantityFilterMax="+filter 
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  //sale purchase
  getSalePurchaseByParty(data: {}, id,size,page) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getPurchaseByParty  +  id +"?size=" + size + "&page=" +page, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  //sale purchase party 
  getSalePurchaseReport(data: {}, userId: string, startDate, endDate): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getPurchaseByParty + userId + "?startDate=" + startDate + "&endDate=" + endDate, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getSalePurchaseReportsearch(data: {}, userId: string,partyId: any,size,page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getPurchaseByParty + userId +"?partyId=" + partyId +"&size=" + size + "&page=" +page, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  //sale purchase by party table filter sale amount
  getSalePurchaseReportSale(data: {}, userId: string, startDate, endDate, partyId: any, filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPurchaseByParty + userId + "?startDate=" + startDate + "&endDate=" + endDate + "&partyId=" + partyId

      if (filter.type == "Equal") {

        link = link + "&equalSale=" + filter.value
      }
      else if (filter.type == "Greater") {
        link = link + "&salesLessThan=" + filter.value
      }
      else if (filter.type == "Less") {
        link = link + "&salesGreaterThan=" + filter.value

      }
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getSalePurchaseReportSaleGreater(data: {}, userId: string,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPurchaseByParty + userId + "?salesGreaterThan="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getSalePurchaseReportSaleEqual(data: {}, userId: string,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPurchaseByParty + userId + "?equalSale="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getSalePurchaseReportSaleless(data: {}, userId: string,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPurchaseByParty + userId + "?salesLessThan="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  //sale purchase by party table filter sale amount
  // getSalePurchaseReportPurchase(data: {}, userId: string, startDate, endDate, partyId: any, filter): any {
  //   return new Promise((resolve, reject) => {
  //     let link = UrlConstants.getPurchaseByParty + userId + "?startDate=" + startDate + "&endDate=" + endDate + "&partyId=" + partyId

  //     if (filter.type == "Equal") {

  //       link = link + "&equalPurchase=" + filter.value
  //     }
  //     else if (filter.type == "Greater") {
  //       link = link + "&purchaseLessThan=" + filter.value
  //     }
  //     else if (filter.type == "Less") {
  //       link = link + "&purchaseGreaterThan=" + filter.value

  //     }
  //     this.httpClient.inventory(data, link, 'GET').subscribe(
  //       (res) => resolve(res),
  //       (err) => reject(err)
  //     );
  //   });
  // }
  getSalePurchaseReportPurchaseGreater(data: {}, userId: string,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPurchaseByParty + userId + "?purchaseGreaterThan="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getSalePurchaseReportPurchaseEqual(data: {}, userId: string,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPurchaseByParty + userId + "?equalPurchase="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getSalePurchaseReportPurchaseGreaterless(data: {}, userId: string,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getPurchaseByParty + userId + "?purchaseLessThan="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  //profit and loss
  getProfitandLoss(data: {}, userId,size,page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getProfitLoss + userId +"?size=" + size + "&page=" +page , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getProfitLossByParty(data: {}, userId: string,size,page, startDate, endDate): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getProfitLoss + userId + +"?size=" + size + "&page=" +page +"?startDate=" + startDate + "&endDate=" + endDate , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getProfitLossByPartysearch(data: {}, userId: string,value,size,page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getProfitLoss + userId + "?partyId="+value +"&size=" + size + "&page=" +page, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getProfitLossByPartyTotalgst(data: {}, userId: string, startDate, endDate, partyId: any, filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getProfitLoss + userId + "?startDate=" + startDate + "&endDate=" + endDate
      if (partyId != undefined) {
        link = link + "&partyName=" + partyId
      }
      if (filter.type == "Equal") {

        link = link + "&gstIn=" + filter.value
      }

      // else if(filter.type=="Greater"){
      //   link=link +"&minSaleAmount=" +filter.value
      // }
      // else if(filter.type=="Less"){
      //   link=link +"&minSaleAmount=" +filter.value

      // }
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getProfitLossByPartyTotalgst1(data: {}, userId: string,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getProfitLoss + userId + "?gstIn="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  //profit loss table min max saleAmount filter

  getProfitLossByPartyTotalSalesGreater(data: {}, userId: string,value): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getProfitLoss + userId + "?minSaleAmount="+value
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getProfitLossByPartyTotalSalesEqual(data: {}, userId: string,value): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getProfitLoss + userId + "?equalSaleAmount="+value
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getProfitLossByPartyTotalSalesless(data: {}, userId: string,value): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getProfitLoss + userId + "?maxSaleAmount="+value
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  // getProfitLossByPartyTotalSales(data: {}, userId: string, startDate, endDate, partyId: any, filter): any {
  //   return new Promise((resolve, reject) => {
  //     let link = UrlConstants.getProfitLoss + userId + "?startDate=" + startDate + "&endDate=" + endDate + "&partyId=" + partyId

  //     if (filter.type == "Equal") {

  //       link = link + "&equalSaleAmount=" + filter.value
  //     }
  //     else if (filter.type == "Greater") {
  //       link = link + "&minSaleAmount=" + filter.value
  //     }
  //     else if (filter.type == "Less") {
  //       link = link + "&maxSaleAmount=" + filter.value

  //     }
  //     this.httpClient.inventory(data, link, 'GET').subscribe(
  //       (res) => resolve(res),
  //       (err) => reject(err)
  //     );
  //   });
  // }
//
  getProfitLossByPartyTotalPurchase(data: {}, userId: string, startDate, endDate, partyId: any, filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getProfitLoss + userId + "?startDate=" + startDate + "&endDate=" + endDate + "&partyId=" + partyId

      if (filter.type == "Equal") {

        link = link + "&equalPurchaseAmount=" + filter.value
      }
      else if (filter.type == "Greater") {
        link = link + "&minPurchaseAmount=" + filter.value
      }
      else if (filter.type == "Less") {
        link = link + "&maxPurchaseAmount=" + filter.value

      }
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getProfitLossByPartyTotalPurchaseGreater(data: {}, userId: string,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getProfitLoss + userId + "?minPurchaseAmount="+ filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getProfitLossByPartyTotalPurchaseEqual(data: {}, userId: string,value): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getProfitLoss + userId + "?equalPurchaseAmount="+value
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getProfitLossByPartyTotalPurchaseless(data: {}, userId: string,value): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getProfitLoss + userId + "?maxPurchaseAmount="+value
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  //profit losss min max profit loss table filter
  getProfitLossByPartyTotalProfit(data: {}, userId: string, startDate, endDate, partyId: any, filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getProfitLoss + userId + "?startDate=" + startDate + "&endDate=" + endDate + "&partyId=" + partyId

      if (filter.type == "Equal") {

        link = link + "&equalProfitLoss=" + filter.value
      }
      else if (filter.type == "Greater") {
        link = link + "&minProfitLoss=" + filter.value
      }
      else if (filter.type == "Less") {
        link = link + "&maxProfitLoss=" + filter.value

      }
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getProfitLossByPartyTotalProfitGreater(data: {}, userId: string,value): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getProfitLoss + userId + "?minProfitLoss="+value
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getProfitLossByPartyTotalProfitEqual(data: {}, userId: string,value): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getProfitLoss + userId + "?equalProfitLoss="+value
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getProfitLossByPartyTotalProfitless(data: {}, userId: string,value): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getProfitLoss + userId + "?maxProfitLoss="+value
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getPartyByStatement(data: {}, partyId: string, startDate, endDate, search: any): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getPartyStatement + partyId + "?startDate=" + startDate + "&endDate=" + endDate + "&search=" + search, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  //sale purchase by group
  getSalePurchaseByGroup(data: {}, userId,size,page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getsalePurchaseGroup + userId +"?size=" + size + "&page=" +page, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getSalePurchaseGroupReport(data: {}, userId: string, startDate, endDate): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getsalePurchaseGroup + userId + "?startDate=" + startDate + "&endDate=" + endDate, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getSalePurchaseGroupReportsearch(data: {}, userId: string,groupName,size,page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getsalePurchaseGroup + userId +"?groupName=" + groupName +"&size=" + size + "&page=" +page, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  //sale purchase by party group table filter sale amount
  getGroupReportPurchaseGreater(data: {}, userId: string,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getsalePurchaseGroup + userId + "?minPurchaseAmount="+filter 
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getGroupReportPurchaseEqual(data: {}, userId: string,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getsalePurchaseGroup + userId + "?eqPurchaseAmount="+filter 
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getGroupReportPurchaseless(data: {}, userId: string,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getsalePurchaseGroup + userId + "?maxPurchaseAmount="+filter 
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getGroupReportSaleGreater(data: {}, userId: string,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getsalePurchaseGroup + userId + "?minSaleAmount="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getGroupReportSaleEqual(data: {}, userId: string,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getsalePurchaseGroup + userId + "?eqSaleAmount="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getGroupReportSaleless(data: {}, userId: string,filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getsalePurchaseGroup + userId + "?maxSaleAmount="+filter
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  //
  getGroupReportPurchase(data: {}, userId: string, startDate, endDate, partyId, filter): any {
    return new Promise((resolve, reject) => {
      let link = UrlConstants.getsalePurchaseGroup + userId + "?startDate=" + startDate + "&endDate=" + endDate + "&partyId=" + partyId

      if (filter.type == "Equal") {

        link = link + "&eqPurchaseAmount=" + filter.value
      }
      else if (filter.type == "Greater") {
        link = link + "&minPurchaseAmount=" + filter.value
      }
      else if (filter.type == "Less") {
        link = link + "&maxPurchaseAmount=" + filter.value

      }
      this.httpClient.inventory(data, link, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}

