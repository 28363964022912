

<div class="modal-header pt-0">

  <h1 style="color: #EB8B3F; font-weight: 500;margin-left: 4px;" class="modal-title mb-0">Add Time</h1>

  <div class="payrollButtons">

    <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel"
      (click)="clearAddTimeForm()">Cancel</button>
    <button type="button" class="btn btn-save" (click)="postAddTimeForm()">Save</button>

  </div>
</div>

<div class="modal-body">

  <form [formGroup]="AddTimeForm">

    <div class="row mb-3">

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
        <label class="form-label">Select Employee <span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-select matNativeControl required disableOptionCentering formControlName="employeeName"
            placeholder="Employees">
            <mat-option *ngFor="let item of employeeList" [value]="item">{{item}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.employeeName.touched)">
          <mat-error *ngIf="f.employeeName.errors?.required">
            Employee Name is required!
          </mat-error>
        </mat-error>
      </div>

      <div class="col-lg-6 col-md-12 col-xl-6 col-sm-12">
        <label class="form-label">Date <span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matInput required ngModel bsDatepicker placeholder="DD MMM YYYY" [minDate]="today"
            style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                containerClass: 'theme-dark-blue',
                dateInputFormat: 'DD MMM YYYY',
                 showWeekNumbers:false
                }" formControlName="startDate" autocomplete="off" (click)="openDatepicker()">
          <span>
            <mat-icon class="iconcalender p-0">date_range</mat-icon>
          </span>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.startDate.touched) && f.startDate.errors?.required">
          Date is required!
        </mat-error>

      </div>

    </div>

    <div class="row mb-3">

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <label class="form-label">Start Time <span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input type="time" matInput placeholder="Start Time" formControlName="start_time"
          (blur)="calculateTimeDifference()"
            [ngClass]="{ 'is-invalid': f.start_time.invalid && f.start_time.touched && submitted }"
            [value]="AddTimeForm.controls.start_time.value || null">
        </mat-form-field>
        <mat-error class="text-danger mb-1"
          *ngIf="(submitted || f.start_time.touched) && f.start_time.errors?.required">
          Start Time Is Required!
        </mat-error>

      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <label class="form-label">End Time <span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input type="time" matInput placeholder="End Time" formControlName="end_time"
            (blur)="calculateTimeDifference()"
            [ngClass]="{ 'is-invalid': f.end_time.invalid && f.end_time.touched && submitted }"
            [value]="AddTimeForm.controls.end_time.value || null">
        </mat-form-field>
        <mat-error class="text-danger mb-1" *ngIf="(submitted || f.end_time.touched) && f.end_time.errors?.required">
          End Time Is Required!
        </mat-error>

      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <label class="form-label">Period <span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matNativeControl required matInput placeholder="Period" autocomplete="off" formControlName="period"
            readonly>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.period.touched) && f.period.errors?.required">
          Period is required!
        </mat-error>
      </div>

    </div>

    <div class="row mb-3">
      <div class="col-12 form-check form-switch">
        <input class="form-check-input mr-1" type="checkbox" id="flexSwitchCheckDefault" formControlName="isBillable"
          style="float: inherit;margin-right: 10px;margin-left: -20px;cursor: pointer;" />
        <label class="text-nowrap form-label" style="font-size: 14px;">Is Billable</label>
      </div>
    </div>

    <div class="row mb-3">

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
        <label class="form-label">Select Client <span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-select formControlName="clientName" matNativeControl disableOptionCentering placeholder="Select Client"
            autocomplete="off">
            <mat-option value="client1">client1</mat-option>
            <mat-option value="client2">client2</mat-option>
            <mat-option value="client3">client3</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.clientName.touched) && f.clientName.errors?.required">
          Select Client is required!
        </mat-error>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
        <label class="form-label">Select Project <span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-select formControlName="projectName" matNativeControl disableOptionCentering placeholder="Select Project"
            autocomplete="off">
            <mat-option value="Iceipts">Iceipts</mat-option>
            <mat-option value="FIFM">FIFM</mat-option>
            <mat-option value="FIGO">FIGO</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.projectName.touched) && f.projectName.errors?.required">
          Select Project is required!
        </mat-error>
      </div>

    </div>

    <div class="row mb-3">

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <label class="form-label">Enter Task <span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matNativeControl required matInput placeholder="Enter Your Task" autocomplete="off"
            formControlName="task">
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.task.touched) && f.task.errors?.required">
          Task is required!
        </mat-error>
      </div>

    </div>

    <div class="row mb-3">

      <div class="col-sm-12 col-md-12 col-lg-12">
        <label class="form-label">Description <span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <textarea matInput rows="3" placeholder="Enter The Description" required autocomplete="off"
            formControlName="description"></textarea>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.description.touched)">
          <mat-error *ngIf="f.description.errors?.required">
            Description is required!
          </mat-error>
        </mat-error>
      </div>

    </div>

    <div class="row">

      <div class="col-sm-12 col-md-12 col-lg-12">
        <label class="form-label">Reasons <span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <textarea matInput rows="3" placeholder="Enter Your Reason" required autocomplete="off"
            formControlName="reason"></textarea>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.reason.touched)">
          <mat-error *ngIf="f.reason.errors?.required">
            Reason is required!
          </mat-error>
        </mat-error>
      </div>

    </div>

  </form>

</div>
