<div class="container-fluid productcomponent">
    <div class="row mt-2">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
            <div class="row productLink mt-3">
               <div class="col-lg-2" id="productlistLink">
                    <p class="mt-3 pb-1  text-nowrap" (click)="selectedPage('product-list')" 
                    [ngClass]="{ 'active': productListPage }"><b>Product List</b></p>
                </div>
                <div class="col-lg-2">
                    <p class="mt-3 pb-1 text-nowrap" (click)="selectedPage('service-list')" 
                    [ngClass]="{ 'active': serviceListPage }"><b>Service List</b></p>
                </div>
               <div class="col-lg-2">
                    <p class="mt-3 pb-1 text-nowrap" (click)="selectedPage('offer-list')" 
                    [ngClass]="{ 'active': offerListPage }"><b>Offer List</b></p>
                </div>
                <div class="col-lg-6"></div>

            </div>

        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
            <div class="row navOption-btns">
                <div class="col-lg-5 containerSearchBar mt-2" *ngIf="productListPage && !serviceListPage && !offerListPage">
                    <div class="form">
                        <input type="search" placeholder="Search..." [(ngModel)]="searchKey"
                        (change)="getGoodsByBarcode(searchKey)">
                        <button type="submit">Search</button>
                    </div>
                    
                       
                    
                </div>

                <div class="col-lg-5 containerSearchBar mt-2" *ngIf="serviceListPage && !productListPage && !offerListPage">
                    <div class="form">
                        <input type="search" placeholder="Search..." [(ngModel)]="searchKey"
                        (change)="getGoodsByBarcodeservice(searchKey)">
                        <button type="submit">Search</button>
                    </div>
                    
                       
                    
                </div>

                <div class="col-lg-5 containerSearchBar mt-2" *ngIf="offerListPage && !productListPage && !serviceListPage">
                    <div class="form">
                        <input type="search" placeholder="Search..." [(ngModel)]="searchKey">
                        <button type="submit">Search</button>
                    </div>
                    
                       
                    
                </div>
                <div class="col-lg-7 productButton mt-2" *ngIf="productListPage && !serviceListPage && !offerListPage">
                    <button class="btn" ngbTooltip="Add New Product" [openDelay]="100" [closeDelay]="100" (click)="openDialog()"><i class="fas fa-plus"></i> Add Product</button>
                    <button class="btn"><i class="fas fa-upload"></i> Import</button>
                    <button class="btn" id="offerlistbtn"><i class="fas fa-download"></i></button>
                </div>
                <div class="col-lg-7 productButton mt-2" *ngIf="serviceListPage && !productListPage && !offerListPage">
                    <button class="btn" ngbTooltip="Add New Service" (click)="openDialogservice()"><i class="fas fa-plus"></i> Add Service</button>
                    <button class="btn"><i class="fas fa-upload"></i> Import</button>
                    <button class="btn" id="offerlistbtn"><i class="fas fa-download"></i></button>
                </div>
                <div class="col-lg-7 productButton mt-2" *ngIf="offerListPage && !productListPage && !serviceListPage">
                    <button class="btn" ngbTooltip="Add New Offer" (click)="openDialogaddoffer()"><i class="fas fa-plus"></i> Add Offer</button>
                    <button class="btn"><i class="fas fa-upload"></i> Import</button>
                    <button class="btn" id="offerlistbtn"><i class="fas fa-download"></i></button>
                </div>
                   
            </div>

        </div>

    </div>
    <hr  class="mb-2">
    <div class="mt-2">
        <div *ngIf="productListPage">
            <app-product-list [goodsData]="goodsData"></app-product-list>
          </div>
          <div *ngIf="serviceListPage">
          <app-servicelist [serviceData]="serviceData"></app-servicelist>
          </div>
          <div *ngIf="offerListPage">
            <app-offer-list></app-offer-list>
          </div>


    </div>

</div>
