<div class="" style="background-color: #e7e6ee;padding:12px;" *ngIf="!isArabic">
  <div class="d-flex justify-content-end mb-3">
    <div class="customise-widget-btn" style=" margin-right: 1%">
      <!-- <button mat-raised-button class="px-2 py-1" id="customizeWidgetBtn" style="border: '1px solid #C3D3E2'" routerLink="/pages/customise-widget-modal" -->
      <button mat-raised-button class="px-2 py-1 mt-2" id="customizeWidgetBtn"
        (click)="openWidgetModal(selectedWidget)"><mat-icon>widgets</mat-icon> <span
          style="font-family: 'Poppins', sans-serif;padding-left: 8px;">Customize Your Widgets</span>
      </button>
      <ng-template #widgetModalContent let-modal>
        <div class="modal-header">
          <h2 class="modal-title mt-3">Select Widgets...</h2>
          <button type="button" class="btn-close btn btn-sm btn-circle" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-11 col-md-11 col-lg-12 mb-2">
              <div class="card mt-1" *ngFor="let widget of widgets">
                <div class="row customise-widget-modal-row mt-1" (click)="widget.checked = !widget.checked">
                  <div class="col-sm-9 p-1">
                    <h3 class="card-title ms-3"><b>{{ widget.name }}</b></h3>
                  </div>
                  <!-- <div class="col-sm-1"></div> -->
                  <div class="col-sm-2 align-items-center ps-5">
                    <button *ngIf="widget.checked" class="close-button" mat-button matTooltip="Select">
                      <mat-icon [ngClass]="{'green-icon': widget.checked}">checked</mat-icon>
                    </button>
                    <!-- <button class="close-button" mat-button matTooltip="Close" (click)="widget.checked = false">
                      <mat-icon [ngClass]="{'red-icon': !widget.checked}">close</mat-icon>
                    </button> -->
                  </div>
                </div>

              </div>
            </div>
          </div>
          <app-widget *ngIf="selectedWidget"></app-widget>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="row" style="margin: 14px 0px;">
    <div class="col-sm-12 col-md-12 col-lg-12 mb-2 " *ngIf="isWidgetChecked(1)">

      <div class="saleSummaryBoxes">
        <div class="my-2 block py-3 px-2">
          <div class = "block-container">
            <div class = "leftSection">
              <span class = "block-headers">
                Product Sold
              </span>
              <span class="block-value">
                {{(todaysProductSold ? todaysProductSold : 0) | number:'1.0-2'}}
              </span>
            </div>
            <div>
              <span class="rounded-circle" style = "margin: 0 4px;">
                <img src="assets/img/dashboard-party-profiles/product-sold.png" alt = "product sold" width = "40" height = "40">
              </span>
            </div>
          </div>
          <div class = "lowerSection">
              <!-- <span style = "color: #00B69B;"> -->
              <span [ngStyle]="{'color': todaysProductSoldPercent >= 0 ? '#00B69B' : '#FF0000'}">
                <img src="assets/img/dashboard-party-profiles/{{ todaysProductSoldPercent >= 0 ? 'profit.png' : 'loss.png' }}" alt="Total Sales" style = "width: 15px;height: 15px;">
                <!-- <img src="assets/img/dashboard-party-profiles/profit.png" alt = "Total Sales" width = "10" height = "10"> -->
                {{(todaysProductSoldPercent ? todaysProductSoldPercent : 0) | number:'1.0-2'}}%
              </span>
              <span *ngIf = "todaysProductSoldPercent >= 0">Up</span>
              <span *ngIf = "todaysProductSoldPercent < 0">Down</span>
              from yesterday
          </div>
        </div>
        <div class="my-2 block py-3 px-2">
          <div class = "block-container">
            <div class = "leftSection">
              <span class = "block-headers">
                New Customers
              </span>
              <span class="block-value">
                {{(newPartiesJoinedCount ? newPartiesJoinedCount : 0) | number:'1.0-2'}}
              </span>
            </div>
            <div>
              <span class="rounded-circle" style = "margin: 0 4px;">
                <img src="assets/img/dashboard-party-profiles/new-customer.png" alt = "new customer" width = "40" height = "40">
              </span>
            </div>
          </div>
          <div class = "lowerSection">
              <!-- <span style = "color: #00B69B;"> -->
              <span [ngStyle]="{'color': newPartiesPercent >= 0 ? '#00B69B' : '#FF0000'}">
                <img src="assets/img/dashboard-party-profiles/{{ newPartiesPercent >= 0 ? 'profit.png' : 'loss.png' }}" alt="Total Sales" style = "width: 15px;height: 15px;">
                <!-- <img src="assets/img/dashboard-party-profiles/profit.png" alt = "Total Sales" width = "10" height = "10"> -->
                {{(newPartiesPercent ? newPartiesPercent : 0) | number:'1.0-2'}}%
              </span>
              <span *ngIf = "newPartiesPercent >= 0">Up</span>
              <span *ngIf = "newPartiesPercent < 0">Down</span>
              from yesterday
          </div>
        </div>
        <div class="my-2 block py-3 px-2">
          <div class = "block-container">
            <div class = "leftSection">
              <span class = "block-headers">
                Total Sales
              </span>
              <span class="block-value">
                {{(todaySales ? todaySales : 0) | number:'1.0-2' }}
              </span>
            </div>
            <div>
              <span class="rounded-circle" style = "margin: 0 4px;">
                <img src="assets/img/dashboard-party-profiles/total-sales.png" alt = "Total Sales" width = "40" height = "40">
              </span>
            </div>
          </div>
          <div class = "lowerSection">
              <!-- <span style = "color: #00B69B;"> -->
              <span [ngStyle]="{'color': percentageCount >= 0 ? '#00B69B' : '#FF0000'}">
                <img src="assets/img/dashboard-party-profiles/{{ percentageCount >= 0 ? 'profit.png' : 'loss.png' }}" alt="Total Sales" style = "width: 15px;height: 15px;">
                <!-- <img src="assets/img/dashboard-party-profiles/profit.png" alt = "Total Sales" style = "width: 15px;height: 15px;"> -->
                {{(percentageCount ? percentageCount : 0) | number:'1.0-2'}}%
              </span>
              <span *ngIf = "percentageCount >= 0">Up</span>
              <span *ngIf = "percentageCount < 0">Down</span>
              from yesterday
          </div>
        </div>
        <div class="my-2 block py-3 px-2">
          <div class = "block-container">
            <div class = "leftSection">
              <span class = "block-headers">
                Total Net Profit
              </span>
              <span class="block-value">
                Rs. {{(netProfit ? netProfit : 0) | amountFormat }}
              </span>
            </div>
            <div>
              <span class="rounded-circle" style = "margin: 0 4px;">
                <!-- <img src="assets/img/dashboard-party-profiles/Group 1000002808.svg"> -->
                <img src="assets/img/dashboard-party-profiles/net-profit-1.png" alt = "Total Sales" width = "40" height = "40">
              </span>
            </div>
          </div>
          <div style="font-family: 'Poppins', sans-serif;margin-top:8px;">
              <span [ngStyle]="{'color': todaysProductSoldPercent >= 0 ? '#00B69B' : '#FF0000'}">
                <!-- <img src="assets/img/dashboard-party-profiles/profit.png" alt = "Total Sales" width = "10" height = "10"> -->
                <img src="assets/img/dashboard-party-profiles/{{ todaysProductSoldPercent >= 0 ? 'profit.png' : 'loss.png' }}" alt="Total Sales" style = "width: 15px;height: 15px;">
                {{(todaysProductSoldPercent ? todaysProductSoldPercent : 0) | number:'1.0-2'}}%
              </span>
              <span *ngIf = "todaysProductSoldPercent >= 0">Up</span>
              <span *ngIf = "todaysProductSoldPercent < 0">Down</span>
              from yesterday
          </div>
        </div>
        <div class="my-2 getPro p-3" style = "box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);">
          <div class = "getProContainer">
            <span class = "getProHeader">
              Upgrade to PRO to get access to all features.
            </span>
            <span class = "getProButton">
              Get Pro Now
            </span>
          </div>
        </div>
      </div>
    </div>

  </div>



  <!-- <div class="row" style="margin: 14px 0px;"> -->
    <!-- <div class="col-sm-12 col-md-6 col-lg-7 mb-2 " *ngIf="isWidgetChecked(1)">
      <div class="card widget"> -->
        <!-- <div class="card-body"> -->

          <!-- <div class="row todaySaleHeader">
            <div class="flex-item saleHeading">
              <h2 class="card-title"><b style="font-family: 'Poppins', sans-serif;">Today's Sale</b></h2>
              <h4><b style="font-family: 'Poppins', sans-serif;">Sales Summary</b></h4>
            </div>
            <div class="flex-item exportButton">
              <div class="dropdown">
                <button mat-raised-button class="dropdown-toggle" type="button" id="filterByDropdown"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [matMenuTriggerFor]="filterMenu"
                  style="height: 35px;">
                  <mat-icon>get_app</mat-icon> Export
                </button>

                <mat-menu #filterMenu="matMenu">
                  <button mat-menu-item (click)="exportData('csv')">CSV</button>
                  <button mat-menu-item (click)="exportData('excel')">Excel</button>
                  <button mat-menu-item (click)="exportData('pdf')">PDF</button>
                </mat-menu>
              </div>
            </div>
          </div> -->

          <!-- <div class="saleSummaryBoxes mt-4"> -->
            <!-- <div class="mb-1 block p-3" style = "background-color: #fff;">
              <span class="rounded-circle" >
                <img src="assets/img/dashboard-party-profiles/Group 1000002808.svg">
              </span>
              <h2><b>{{(todaySales ? todaySales : 0) | number:'1.0-2' }}</b></h2>
              <p style="font-family: 'Poppins', sans-serif;">Total Sales</p>
              <p class="block-sales" style="font-family: 'Poppins', sans-serif;">{{(percentageCount ? percentageCount :
                0) | number:'1.0-2'}}% <br>from yesterday</p>
            </div>
            <div class="mb-1 block p-3" style = "background-color: #fff;">
              <span class="rounded-circle" >
                <img src="assets/img/dashboard-party-profiles/Group 1000002807.svg">
              </span>
              <h2><b>{{(todaysProductSold ? todaysProductSold : 0) | number:'1.0-2'}}</b></h2>
              <p>Product Sold</p>
              <p class="block-sales">{{(todaysProductSoldPercent ? todaysProductSoldPercent : 0) | number:'1.0-2'}}%
                <br>from yesterday</p>
            </div>
            <div class="mb-1 block p-3" style = "background-color: #fff;">
              <span class="rounded-circle" >
                <img src="assets/img/dashboard-party-profiles/Group 1000002806.svg">
              </span>
              <h2><b>{{(newPartiesJoinedCount ? newPartiesJoinedCount : 0) | number:'1.0-2'}}</b></h2>
              <p>New Customers</p>
              <p class="block-sales">{{(newPartiesPercent ? newPartiesPercent : 0) | number:'1.0-2'}}% <br>from yesterday
              </p>
            </div> -->
            <!-- <div class="mb-1 block p-3" style = "background-color: #fff;">
              <span class="rounded-circle" >
                <img src="assets/img/dashboard-party-profiles/Group 1000002808.svg">
              </span>
              <h2><b>{{(todaySales ? todaySales : 0) | number:'1.0-2' }}</b></h2>
              <p style="font-family: 'Poppins', sans-serif;">Total Sales</p>
              <p class="block-sales" style="font-family: 'Poppins', sans-serif;">{{(percentageCount ? percentageCount :
                0) | number:'1.0-2'}}% <br>from yesterday</p>
            </div>
            <div class="mb-1 block p-3" style = "background-color: #fff;">
              <span class="rounded-circle" >
                <img src="assets/img/dashboard-party-profiles/Group 1000002807.svg">
              </span>
              <h2><b>{{(todaysProductSold ? todaysProductSold : 0) | number:'1.0-2'}}</b></h2>
              <p>Product Sold</p>
              <p class="block-sales">{{(todaysProductSoldPercent ? todaysProductSoldPercent : 0) | number:'1.0-2'}}%
                <br>from yesterday</p>
            </div>
            <div class="mb-1 block p-3" style = "background-color: #fff;">
              <span class="rounded-circle" >
                <img src="assets/img/dashboard-party-profiles/Group 1000002806.svg">
              </span>
              <h2><b>{{(newPartiesJoinedCount ? newPartiesJoinedCount : 0) | number:'1.0-2'}}</b></h2>
              <p>New Customers</p>
              <p class="block-sales">{{(newPartiesPercent ? newPartiesPercent : 0) | number:'1.0-2'}}% <br>from yesterday
              </p>
            </div> -->
          <!-- </div> -->

        <!-- </div> -->

      <!-- </div>
    </div> -->
    <!-- <div class="col-sm-12 col-md-4 col-lg-3 mb-2" *ngIf="isWidgetChecked(2)">
      <div class="net-profit-card card widget h-lg-100">
        <div class="card-body pe-3 ps-3 py-3">
          <h2 class="card-title" style="margin-bottom: 48px;"><b style="font-family: 'Poppins', sans-serif;">Net Profit
              and Net Sales</b></h2>
          <div class="row mb-2">
            <div class="col-md-2"></div>
            <div class="col-md-2 col-sm-3 p-2">
              <img src="assets/img/dashboard-party-profiles/net-profit.png">
            </div>
            <div class="col-md-1 col-sm-2"></div>

            <div class="col-md-6 col-sm-3 p-2">
              <h4 class="text-right mb-0" style="font-family: 'Poppins', sans-serif;"><b>Net Profit</b></h4>
              <h1 class="text-right mb-0"><b style="font-family: 'Poppins', sans-serif;">Rs. {{(netProfit ? netProfit :
                  0) | amountFormat }}</b></h1>
            </div>
          </div>
          <hr>
          <div class="row mt-2">
            <div class="col-md-2"></div>
            <div class="col-md-2 col-sm-3 p-2">
              <img src="assets/img/dashboard-party-profiles/net-sales.png">
            </div>
            <div class="col-md-1 col-sm-2"></div>
            <div class="col-md-6 col-sm-3 p-2 mb-5">
              <h4 class="text-right mb-0" style="font-family: 'Poppins', sans-serif;"><b>Net Sales</b></h4>
              <h1 class="text-right mb-0"><b style="font-family: 'Poppins', sans-serif;">Rs. {{(netSales ? netSales : 0)
                  | amountFormat }}</b></h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-3 col-lg-2 mb-2 img">
      <div class="card widget">
        <img class="img-responsive inner-img" height="340" width="200"
          src="assets/img/dashboard-party-profiles/Get pro.png">
      </div>
    </div> -->
  <!-- </div> -->

  <div class="row" style="margin: 14px 0px;">
    <div class="col-sm-12 col-md-6 col-lg-4 mb-2" *ngIf="isWidgetChecked(3)">
      <app-total-sales></app-total-sales>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4 mb-2" *ngIf="isWidgetChecked(4)">
      <app-total-purchase></app-total-purchase>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4 ps-lg-3 mb-2" *ngIf="isWidgetChecked(5)">
      <app-stock-details></app-stock-details>
    </div>
  </div>

  <div class="row" style="margin: 14px 0px;">
    <div class="col-sm-12 col-md-6 col-lg-6 mb-2" *ngIf="isWidgetChecked(6)">
      <app-sales-purchase-chart></app-sales-purchase-chart>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6 mb-2" *ngIf="isWidgetChecked(7)">
      <app-net-profit-chart></app-net-profit-chart>
    </div>
  </div>

  <div class="row" style="margin: 14px 0px;">
    <div class="col-sm-12 col-md-6 col-lg-6 mb-2" *ngIf="isWidgetChecked(8)">
      <div class="card widget" style = "box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);">
        <div class = "card-head">
          <div class="row">
            <span class="col-sm-6 col-md-6 col-lg-9 cardHeader">
              Recent Contacts
            </span>
            <h5 class="col-sm-6 col-md-6 col-lg-3 recentContact-Sales">
              Sales
            </h5>
          </div>
        </div>
        <div class="card-body hidden-scroll" style = "height: 277px;">
          <div class="row" *ngFor="let item of recentPartyList | slice:0:5">
            <div class="col-sm-2 col-md-2 p-2 profile-pic">
              <img src="assets/img/dashboard-party-profiles/party-profile-1.png" alt="Profile" class="rounded-circle">
            </div>
            <div class="col-sm-6 col-md-6 p-2 text-left">
              <h3 class="card-title partyName" style="font-family: 'Poppins', sans-serif;">{{item.partyName}}</h3>
              <h5 class="card-text" style="font-family: 'Poppins', sans-serif;">{{item.email}}</h5>
            </div>
            <div class="col-sm-3 col-md-3 mt-3" style = "text-align: right;">
              <span class="green-digit p-1" style="font-family: 'Poppins', sans-serif;">
                ₹&nbsp;{{item.saleAmount | number:'1.2-2'}}</span>
            </div>
          </div>
          <div *ngIf="recentPartyList.length === 0" class = "recentParty-notFound">
            <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
              alt="notfound1" width="200" height="150" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6 mb-2" *ngIf="isWidgetChecked(9)">
      <div class="card widget" style = "box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);">
        <span class="card-title" style = "font-family: 'Poppins', sans-serif;font-weight: 500;font-size: 18px;padding:16px 12px 0;">
          Low Stocks Products
        </span>
        <div class="card-body hidden-scroll" style="height:283px;">
          <!-- <h2 class="card-title"><b style="font-family: 'Poppins', sans-serif;">Low Stocks Products</b></h2> -->
          <table class="table mt-3">
            <thead>
              <tr class="stocks-col">
                <th class="text-nowrap" scope="col" style="width: 10%">#</th>
                <th class="text-nowrap" scope="col" style="width: 40%">Name</th>
                <th class="text-nowrap" scope="col" style="width: 50%">Left Stock</th>
              </tr>
            </thead>
            <tbody *ngFor="let item of lowStockProductList | slice:0:5; let i = index ">
              <tr>
                <td style="font-family: 'Poppins', sans-serif;">{{i + 1 | number:'2.0-0'}}</td>
                <td style="font-family: 'Poppins', sans-serif;">{{item.itemName}}</td>
                <td>
                  <progress value="{{item.stock}}"
                    ngbTooltip="{{item.stock}} stocks are remaining out of {{item.openingStock}}"
                    max="{{item.openingStock}}"></progress>&nbsp;
                  <span class="sales-percentage p-2" style="font-family: 'Poppins', sans-serif;">{{item.stock}}</span>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="lowStockProductList.length === 0"
              style="display: flex;justify-content: center;align-items: center; margin-left: 127%; margin-top: 50px;">
              <tr class="text-center">
                <td colspan="3">

                  <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
                    alt="notfound1" width="200" height="150" />
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <table class="table" style = "width: 100% !important;">
            <thead>
              <tr>
                <th class="text-nowrap" style = "width:5%;" >#</th>
                <th class="text-nowrap" >Name</th>
                <th class="text-nowrap" >Left Stock</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="font-family: 'Poppins', sans-serif;">1</td>
                <td style="font-family: 'Poppins', sans-serif;">Product A</td>
                <td>
                  <progress value="15"
                    ngbTooltip="15 stocks are remaining out of 100"
                    max="100"></progress>&nbsp;
                  <span class="sales-percentage p-2" style="font-family: 'Poppins', sans-serif;">15</span>
                </td>
              </tr>
              <tr>
                <td style="font-family: 'Poppins', sans-serif;">2</td>
                <td style="font-family: 'Poppins', sans-serif;">Product B</td>
                <td>
                  <progress value="30"
                    ngbTooltip="30 stocks are remaining out of 200"
                    max="200"></progress>&nbsp;
                  <span class="sales-percentage p-2" style="font-family: 'Poppins', sans-serif;">30</span>
                </td>
              </tr>
              <tr>
                <td style="font-family: 'Poppins', sans-serif;">3</td>
                <td style="font-family: 'Poppins', sans-serif;">Product C</td>
                <td>
                  <progress value="25"
                    ngbTooltip="25 stocks are remaining out of 150"
                    max="150"></progress>&nbsp;
                  <span class="sales-percentage p-2" style="font-family: 'Poppins', sans-serif;">25</span>
                </td>
              </tr>
              <tr>
                <td style="font-family: 'Poppins', sans-serif;">4</td>
                <td style="font-family: 'Poppins', sans-serif;">Product D</td>
                <td>
                  <progress value="10"
                    ngbTooltip="10 stocks are remaining out of 80"
                    max="80"></progress>&nbsp;
                  <span class="sales-percentage p-2" style="font-family: 'Poppins', sans-serif;">10</span>
                </td>
              </tr>
              <tr>
                <td style="font-family: 'Poppins', sans-serif;">5</td>
                <td style="font-family: 'Poppins', sans-serif;">Product E</td>
                <td>
                  <progress value="40"
                    ngbTooltip="40 stocks are remaining out of 250"
                    max="250"></progress>&nbsp;
                  <span class="sales-percentage p-2" style="font-family: 'Poppins', sans-serif;">40</span>
                </td>
              </tr>
            </tbody>
            <tbody style="display: flex;justify-content: center;align-items: center; margin-left: 127%; margin-top: 50px;">
              <tr class="text-center">
                <td colspan="3">
                  <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
                    alt="notfound1" width="200" height="150" />
                </td>
              </tr>
            </tbody>
          </table> -->

        </div>
      </div>

    </div>
  </div>

  <div class="row" style="margin: 14px 0px;">
    <div class="col-sm-12 col-md-6 col-lg-6 mb-2" *ngIf="isWidgetChecked(10)">
      <div class="card widget" style = "box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);">
        <div class = "card-head">
          <div class="row">
            <span class="col-sm-6 col-md-6 col-lg-9 cardHeader">
              Pending Payment
            </span>
            <h5 class="col-sm-6 col-md-6 col-lg-3 pending-Payment">
              Payment
            </h5>
          </div>
        </div>
        <div class="card-body hidden-scroll" style="height:277px;">
          <div class="row" *ngFor="let item of pendingPartyList | slice:0:5">
            <div class="col-md-2 col-sm-2 p-2 profile-pic">
              <img src="assets/img/dashboard-party-profiles/party-profile-1.png" alt="Profile" class="rounded-circle">
            </div>
            <div class="col-md-7 col-sm-7 p-1 text-left">
              <h3 class="card-title partyName" style="font-family: 'Poppins', sans-serif;">{{item.partyName}}</h3>
              <h5 class="card-text" style="font-family: 'Poppins', sans-serif;">{{item.email}}</h5>
            </div>
            <div class="col-md-2 col-sm-2 p-1" style = "text-align: right;">
              <span class="green-digit" style="font-family: 'Poppins', sans-serif;"> ₹&nbsp;{{ item.totalDebt | number:'1.2-2'}}</span>
            </div>
          </div>
          <div *ngIf="pendingPartyList.length === 0" class = "pendingParty-notFound">
            <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
              alt="notfound1" width="200" height="150" />
          </div>
        </div>

      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6 mb-2" *ngIf="isWidgetChecked(11)">
      <div class="card widget" style = "box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);">
        <div class = "card-head">
          <div class="row">
            <span class="col-sm-6 col-md-6 col-lg-8 cardHeader">
              Credit Vendor Name
            </span>
            <h5 class="col-sm-6 col-md-6 col-lg-4 receivable-Payment">
              Receivable Payment
            </h5>
          </div>
        </div>
        <div class="card-body hidden-scroll" style="height:277px;">
          <div class="row" *ngFor="let item of creditorsPartyList | slice:0:5">
            <div class="col-sm-2 col-md-2 p-2 profile-pic">
              <img src="assets/img/dashboard-party-profiles/party-profile-1.png" alt="Profile" class="rounded-circle">
            </div>
            <div class="col-sm-6 col-md-6 p-2 text-left">
              <h3 class="card-title partyName" style="font-family: 'Poppins', sans-serif;">{{item.partyName}}</h3>
              <h5 class="card-text" style="font-family: 'Poppins', sans-serif;">{{item.email}}</h5>
            </div>
            <div class="col-sm-3 col-md-3 p-3" style = "text-align: right;">
              <span class="green-digit" style="font-family: 'Poppins', sans-serif;"> ₹&nbsp;{{ item.totalCredit | number:'1.2-2'}}</span>
            </div>
          </div>
          <div *ngIf="creditorsPartyList.length === 0" class = "creditorsParty-notFound">
            <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
              alt="notfound1" width="200" height="150" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" style="margin: 14px 0px;">
    <div class="col-sm-12 col-md-12 col-lg-12 mb-2" *ngIf="isWidgetChecked(12)">
      <div class="card widget" style = "box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 col-sm-5">
              <span class="cardHeader ps-0">
                Best Selling Products
              </span>
            </div>
            <div class="col-md-2 col-sm-3" *ngIf="updatedProductList.length !== 0">
              <div class="dropdown">
                <button mat-raised-button class="dropdown-toggle" type="button" id="filterByDropdown"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [matMenuTriggerFor]="filterMenu">
                  Filter By : {{ selectedFilter || 'All' }}
                </button>
                <mat-menu #filterMenu="matMenu">
                  <button mat-menu-item (click)="selectFilter('low Stock')"
                    [ngClass]="{ 'selected' : selectedFilter === 'low Stock' }">
                    Low Stock
                  </button>
                  <button mat-menu-item (click)="selectFilter('In Stock')"
                    [ngClass]="{ 'selected' : selectedFilter === 'In Stock' }">
                    In Stock
                  </button>
                  <button mat-menu-item (click)="selectFilter('out Of Stock')"
                    [ngClass]="{ 'selected' : selectedFilter === 'out Of Stock' }">
                    Out Of Stock
                  </button>
                  <button mat-menu-item (click)="openPriceRangeDialog()"
                    [ngClass]="{ 'selected' : selectedFilter === 'Price' }">
                    Price
                  </button>
                  <button mat-menu-item (click)="selectFilter('All')"
                    [ngClass]="{ 'selected' : selectedFilter === 'All' }">
                    Remove All
                  </button>
                </mat-menu>
              </div>
            </div>
            <div class="col-md-3 col-sm-3" *ngIf="updatedProductList.length !== 0">
              <div class="dropdown">
                <button mat-raised-button class="dropdown-toggle" type="button" id="sortByDropdown"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [matMenuTriggerFor]="sortMenu">
                  Sort By : {{ selectedSort || 'Relevance' }}
                </button>
                <mat-menu #sortMenu="matMenu">
                  <button mat-menu-item (click)="selectSort('Price: Low to High')">Price: Low to High</button>
                  <button mat-menu-item (click)="selectSort('Price: High to Low')">Price: High to Low</button>
                  <button mat-menu-item (click)="selectSort('Stock: Low to High')">Stock: Low to High</button>
                  <button mat-menu-item (click)="selectSort('Stock: High to Low')">Stock: High to Low</button>
                  <button mat-menu-item (click)="selectSort('Total Sales')">Total Sales</button>
                  <button mat-menu-item (click)="selectSort('Relevance')">Relevance</button>
                </mat-menu>
              </div>
            </div>
          </div>
          <div class="table-container hidden-scroll" style = "height: 277px;">
            <div *ngIf="filteredProductList.length === 0" style="color : red">{{ emptyListMessage }}</div>

            <table class="table table-responsive mt-3">
              <thead>
                <tr style="font-family: 'Poppins', sans-serif;">
                  <th style = "width:15%;">Product ID</th>
                  <th>Image</th>
                  <th>Product Name</th>
                  <th>Total Sales</th>
                  <th>Stock</th>
                  <th>Status</th>
                  <th style = "text-align: right;width:5%;">Price</th>
                </tr>
              </thead>
              <tbody *ngFor="let item of updatedProductList" style="font-family: 'Poppins', sans-serif;">
                <tr style="align-items: center;">
                  <td style = "width:15%;">#{{item.itemCode}}</td>
                  <td><img src="{{item.coverImage}}" height="70px" width="90px" alt="Product Image"
                      onerror="this.src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcm9rPgL_MfNP7BIgeb8vn5Gb8J0F2Y_EAYylwRWTakA&usqp=CAU&ec=48665699'">
                  </td>
                  <td class = "long-name">{{item.itemName}}</td>
                  <td>{{item.totalSold}}</td>
                  <td>{{item.stock}}</td>
                  <td>
                    <button mat-flat-button
                    [ngClass]="{'status-badge': true, 'danger': item.status === 'outOfStock', 'warning': item.status === 'lowStock', 'success': item.status !== 'outOfStock' && item.status !== 'lowStock'}">
                    {{
                      item.status === 'outOfStock' ? 'Out of Stock' :
                      item.status === 'lowStock' ? 'Low Stock' :
                      item.status === 'inStock' ? 'In Stock' :
                      item.status
                    }}
                  </button>
                </td>
                <td style = "text-align: right;width:5%;">₹&nbsp;{{ item.salePrice | number:'1.2-2'}}</td>
                  <!-- <td><i class="bi bi-three-dots-vertical"></i></td> -->
                </tr>
              </tbody>
              <tbody *ngIf="updatedProductList.length === 0" class = "updatedProduct-notFound">
                <tr class="text-center">
                  <td colspan="7">
                    <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
                      alt="notfound1" width="200" height="150" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="row" style="margin: 14px 0px;">
    <div class="col-sm-12 col-md-6 col-lg-6 mb-2">
      <div class="card widget" style = "box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 col-sm-5">
              <span class="cardHeader ps-0" style="font-family: 'Poppins', sans-serif;display:flex;align-items: center;">Demand Prediction</span>
              <!-- <h2 class="card-title"><b style="font-family: 'Poppins', sans-serif;">Demand Prediction</b></h2> -->
            </div>
            <div class="col-md-6 col-sm-3">
              <div class="navOption-btns flex-item formButtons mx-0" style="float:right">
                <button id="saleButtonPrint1" class="btn text-nowrap my-2" type="button" (click)="startUpload()">
                    <i class="fas fa-upload"></i> Import
                </button>
                <input class="inputhide" type="file" name="file" id="uploadFile" accept=".csv" (change)="handleInputChange($event)" />

             </div>

            </div>
          </div>
          <div class="table-container hidden-scroll" style="height:277px;">
            <table class="table table-responsive mt-3">
              <thead>
                <tr style="font-family: 'Poppins', sans-serif;">
                  <th>Month</th>
                  <th>Demand Prediction</th>
                  <th>Prediction Value</th>
                 </tr>
              </thead>
              <tbody *ngFor="let item of demandPrediction" style="font-family: 'Poppins', sans-serif;">
                <tr style="align-items: center;">
                  <td>{{item.month}}</td>
                  <td>{{item.demand_percentage}} %</td>
                  <td>{{item.prediction_value.toFixed(4) }}</td>
                </tr>
              </tbody>
              <tbody *ngIf="demandPrediction.length === 0"
                style="display: flex;justify-content: center;align-items: center; margin-left: 103%; margin-top: 70px;">
                <tr class="text-center">
                  <td>
                   <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
                      alt="notfound1" width="200" height="150" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-6 mb-2" *ngIf="isWidgetChecked(13)">
      <app-review-product></app-review-product>
    </div>
  </div>
</div>
