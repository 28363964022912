<div class="modal-header pt-0">
    <div class="headingmodal">
        <h1 style="color: #EB8B3F; font-weight: bolder;" class="modal-title mb-2" *ngIf="!isArabic">{{itemName}}</h1>
        <h1 style="color: #EB8B3F; font-weight: bolder;" class="modal-title mb-2" *ngIf="isArabic">{{itemName}}</h1>
        <h2 style="color:black; font-weight: bolder;" class="modal-title mb-2" *ngIf="!isArabic">{{category}}</h2>
        <h2 style="color: black; font-weight: bolder;" class="modal-title mb-2" *ngIf="isArabic">{{category}}</h2>
    </div>


    <div class="addProductButtons">

        <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel"
            (click)="cancel()">Cancel</button>

    </div>


</div>
<div class="modal-body">

    <form [formGroup]="ProductForm" *ngIf="!isArabic">
        <div class="row mb-3">

            <!-- item Code -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Item Code</label>
                <h4>{{itemCode}}</h4>
            </div>
            <!-- Generate Barcode -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Barcode</label>
                <h4>{{generateBarcode}}</h4>

            </div>

        </div>


        <div class="row mb-3">
            <!-- HSN -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">HSN</label>
                <h4>{{hsn}}</h4>

            </div>

            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Sale Price Inc. GST</label>
                <h4>₹&nbsp;{{price | number:'1.2-2'}}</h4>
            </div>


        </div>
        <div class="row mb-2">
            <!-- Item Description -->
            <div style=" margin-bottom: -13px; font-size: 12px; width: 95%;" class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <label class="fw-bolder form-label">Item Description</label>
                <h4 style="word-wrap: break-word;">{{description}}</h4>

            </div>


        </div>


        <div class="row mb-3">
            <!-- Additional Cess -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Additional Cess</label>
                <h4>₹&nbsp;{{additionalCess | number:'1.2-2'}}</h4>
            </div>
            <!-- Gst Rate -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">GST Rate(%)</label>
                <h4>{{gstRate}}</h4>


            </div>


        </div>


        <div class="row mb-3">

            <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Support Type</label>
                <h4>{{supportType}}</h4>
            </div>
            <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Limit</label>
                <h4>{{supportDuration}}</h4>
            </div>

        </div>






        <!-- <div class="row d-flex justify-content-start mb-3">

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-check form-switch"
                style="text-align: right;">
                <input class="form-check-input" type="checkbox" formControlName="secondaryUnitFlag"
                    id="flexSwitchCheckDefault" (click)="showSecondaryUnitField($event.target.checked)"
                    style="float: inherit;margin-right: 10px" />
                <label class="fw-bolder form-label">Enable Secondary Unit</label>

            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-check form-switch"
                style="text-align: right;">
                <input class="form-check-input mr-1" formControlName="lowStockWarning" type="checkbox"
                    id="flexSwitchCheckDefault" (click)="getEnableStockValue($event.target.checked)"
                    style="float: inherit;margin-right: 10px" />
                <label class="fw-bolder form-label">Enable Low Stock Warning </label>
            </div>
        </div> -->

        <div class="row mb-3">
            <!-- Primary Unit -->
            <div style="  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Primary Unit</label>
                <h4>{{unit}}</h4>

            </div>

            <!-- Secondary Unit -->
            <div *ngIf="secondaryunit" style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div class="mb-3">
                    <label class="fw-bolder form-label">Secondary Unit</label>
                    <h4>{{secondaryunit ? secondaryunit : 'Null'}}</h4>
                </div>
            </div>
        </div>


        <div class="row mb-3">

            <!-- Low Stock Units -->
            <div *ngIf="lowStockUnit" style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div class="mb-3">
                    <label class="fw-bolder form-label">Low Stock Units</label>
                    <h4>{{lowStockUnit ? lowStockUnit : 'Null'}}</h4>
                </div>
            </div>


            <!-- Conversion Rate -->
            <div *ngIf="conversionRate" style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div class="mb-3">
                    <label class="fw-bolder form-label">Conversion Rate</label>
                    <h4>{{conversionRate ? conversionRate : 'Null'}}</h4>
                </div>
            </div>

        </div>

        <div class="row mb-3">
             <!-- Opening Stock -->
             <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Opening Stock</label>
                <h4>{{openingStock}}</h4>
            </div>

            <div *ngIf="this.expiry!==null" style=" margin-bottom: -13px; font-size: 12px"
                class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div class="mb-3">
                    <label class="fw-bolder form-label">Expiry Date</label>
                    <h4>{{expiry==null ? expiry: expiry | date: 'dd MMM yyyy'}}</h4>
                    <!-- {{(value==null) ? value: value | date: 'dd/MM/yyyy'}} -->
                </div>
            </div>
        </div>

        <div class="row mt-3" *ngIf="imageSrcSeven !== null || fetchImages.lenght > 0">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="justify-content: start;">
                <p class="d-md-inline-block fs--1 ml-5" style="font-weight:600;font-size:20px!important;">
                    View Products Image
                </p>
            </div>

        </div>

        <div class="row mt-2">
            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 flex d-flex flex-row" style="justify-content: center;"
                *ngIf="imageSrcSeven !== null">
                <div class="{{imageSrcSeven ? '':'dash'}} " style="width: 200px !important; height: 200px !important;">


                    <img *ngIf="imageSrcSeven" class="m-2" height="200" width="200"
                        [src]="imageSrcSeven ? imageSrcSeven : ''" (load)="handleCoverImageLoad()"
                        [class.loaded]="imageLoaded" class="text-center" />
                    <div class="input--file">

                        <div *ngIf="!imageSrcSeven" class="mt-1 pl-0">
                            cover photo
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 mb-4 flex d-flex flex-row" style="justify-content: center;"
                *ngFor="let fetch of fetchImages" style="justify-content: center;align-items: center;">
                <img [src]="fetch.productImage" class="preview" height="200" width="200px" />
            </div>
        </div>

        <!-- <div class="row mt-1" *ngIf="imageSrcSeven !== null">
            <div class="col-sm-12 flex d-flex flex-row mt-2" style="justify-content: center;">

                <div class="{{imageSrcSeven ? '':'dash'}} " style="width: 150px !important; height: 150px !important;">


                    <img *ngIf="imageSrcSeven" class="m-2" height="150" width="150"
                        [src]="imageSrcSeven ? imageSrcSeven : ''" (load)="handleCoverImageLoad()"
                        [class.loaded]="imageLoaded" class="text-center" />
                    <div class="input--file">

                        <div *ngIf="!imageSrcSeven" class="mt-1 pl-0">
                            cover photo
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 mb-4" *ngFor="let fetch of fetchImages" style="justify-content: center;align-items: center;">
                <img [src]="fetch.productImage" class="preview" height="200" width="200px" />
            </div>
        </div> -->


    </form>

    <form [formGroup]="ProductForm" *ngIf="isArabic">
        <div class="row mb-3">
            <!-- item Code -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Item Code</label>
                <h4>{{itemCode}}</h4>
            </div>

            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Barcode/SKU</label>
                <h4>{{generateBarcode}}</h4>

            </div>

        </div>
        <div class="row mb-2">
            <!-- Item Description -->
            <div style=" margin-bottom: -13px; font-size: 12px;width:95%;" class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <label class="fw-bolder form-label">Item Description</label>
                <h4 style="word-wrap: break-word;">{{description}}</h4>

            </div>

        </div>


        <div class="row mb-3">

            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">{{ lang.SalePriceInc }}</label>
                <h4>AED&nbsp;{{price}}</h4>

            </div>

            <!-- Gst Rate -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">{{lang.GSTRate}}</label>
                <h4>{{gstRate}}</h4>


            </div>


        </div>



        <div class="row mb-3">

            <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Support Type</label>
                <h4>{{supportType}}</h4>
            </div>
            <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Duration</label>
                <h4>{{supportDuration}}</h4>
            </div>

        </div>






        <!-- <div class="row d-flex justify-content-start mb-3">

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-check form-switch"
                style="text-align: right;">
                <input class="form-check-input" type="checkbox" formControlName="secondaryUnitFlag"
                    id="flexSwitchCheckDefault" (click)="showSecondaryUnitField($event.target.checked)"
                    style="float: inherit;margin-right: 10px" />
                <label class="fw-bolder form-label">Enable Secondary Unit</label>

            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-check form-switch"
                style="text-align: right;">
                <input class="form-check-input mr-1" formControlName="lowStockWarning" type="checkbox"
                    id="flexSwitchCheckDefault" (click)="getEnableStockValue($event.target.checked)"
                    style="float: inherit;margin-right: 10px" />
                <label class="fw-bolder form-label">Enable Low Stock Warning </label>
            </div>
        </div> -->

        <div class="row mb-3">
            <!-- Primary Unit -->
            <div style="  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Primary Unit</label>
                <h4>{{unit}}</h4>

            </div>

            <!-- Secondary Unit -->
            <div *ngIf="secondaryunit" style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div class="mb-3">
                    <label class="fw-bolder form-label">Secondary Unit</label>
                    <h4>{{secondaryunit ? secondaryunit : 'Null'}}</h4>
                </div>
            </div>
        </div>



        <div class="row mb-3">

            <!-- Low Stock Units -->
            <div *ngIf="lowStockUnit" style=" margin-bottom: -13px; font-size: 12px"
                class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div class="mb-3">
                    <label class="fw-bolder form-label">Low Stock Units</label>
                    <h4>{{lowStockUnit ? lowStockUnit : 'Null'}}</h4>
                </div>
            </div>


            <!-- Conversion Rate -->
            <div *ngIf="conversionRate" style=" margin-bottom: -13px; font-size: 12px"
                class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div class="mb-3">
                    <label class="fw-bolder form-label">Conversion Rate</label>
                    <h4>{{conversionRate ? conversionRate : 'Null'}}</h4>
                </div>
            </div>

        </div>
        <div class="row mb-3">
            <!-- Opening Stock -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Opening Stock</label>
                <h4>{{openingStock}}</h4>
            </div>
            <div *ngIf="this.expiry!==null" style=" margin-bottom: -13px; font-size: 12px"
                class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div class="mb-3">
                    <label class="fw-bolder form-label">Expiry Date</label>
                    <h4>{{expiry==null ? expiry: expiry | date: 'dd MMM yyyy'}}</h4>
                </div>
            </div>


        </div>

        <div class="row mt-3" *ngIf="imageSrcSeven !== null || fetchImages.lenght > 0">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="justify-content: start;">
                <p class="d-md-inline-block fs--1 ml-5" style="font-weight:600;font-size:20px!important;">
                    View Products Image
                </p>
            </div>

        </div>

        <div class="row mt-2">
            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 flex d-flex flex-row" style="justify-content: center;"
                *ngIf="imageSrcSeven !== null">
                <div class="{{imageSrcSeven ? '':'dash'}} " style="width: 200px !important; height: 200px !important;">


                    <img *ngIf="imageSrcSeven" class="m-2" height="200" width="200"
                        [src]="imageSrcSeven ? imageSrcSeven : ''" (load)="handleCoverImageLoad()"
                        [class.loaded]="imageLoaded" class="text-center" />
                    <div class="input--file">

                        <div *ngIf="!imageSrcSeven" class="mt-1 pl-0">
                            cover photo
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 mb-4 flex d-flex flex-row" style="justify-content: center;"
                *ngFor="let fetch of fetchImages" style="justify-content: center;align-items: center;">
                <img [src]="fetch.productImage" class="preview" height="200" width="200px" />
            </div>
        </div>

    </form>

    <div class="row mt-3">
        <div class="row" style="vertical-align: middle;">
            <span class="pt-3" style = "font-family: 'Poppins', sans-serif;font-weight: 500;font-size: 18px;">AI Review</span>
        </div>
         
        <div class="row" style = "height: 310px;">
            <div class="max-w-100" style = "margin: auto;" id="ai-details-chart">{{reviewAi}}</div>
        </div>
    </div>

</div>
