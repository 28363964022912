import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { PaymentModel } from 'src/app/_models/payment.model';
import { HttpService } from './http.service';


@Injectable({
  providedIn: 'root'
})
export class PayumoneyPaymentService {

  constructor(
    public httpClient:HttpService) { }
  // createPayment(paymentRequest: PaymentModel) {
  //   const PAYMENT_URL = 'https://test.payu.in/_payment';
  //   const headers = { 'Authorization': 'Bearer my-token' };
  //   this.httpClient.post(PAYMENT_URL,{ headers },  paymentRequest).subscribe;
  // }
  getPaymentSuccess(data:{}):any{
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getPaymentSuccess, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  postPaymentSuccess(data:{}):any{
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getPaymentSuccess, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  
}
