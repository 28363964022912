import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { logWarnings } from "protractor/built/driverProviders";
import { ProductService } from "src/app/core/services/product.service";
import { ToastNotificationService } from "src/app/core/services/toast-notification.service";
import { Constants } from "src/app/_helpers/constant";
import { Location, TitleCasePipe } from '@angular/common';
import { idText } from "typescript";
import { resolveForwardRef } from "@angular/compiler/src/util";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import lang from "src/assets/langTranslation/language_translation"
import { CommonService } from "src/app/core/services/common.service";
import { DashboardService } from "src/app/core/services/dashboard.service";
import * as Highcharts from "highcharts";

@Component({
  selector: 'app-productlist-view',
  templateUrl: './productlist-view.component.html',
  styleUrls: ['./productlist-view.component.scss']
})
export class ProductlistViewComponent implements OnInit {
  public lang = lang.UAE;
  public isArabic: boolean;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public unitValue: any;
  public unitSecondaryValue: any;
  public isDisabled: boolean = false;
  public showProductType: any;
  public serviceToggle: boolean;
  public isStockToggle: boolean = true;
  public ProductForm: any;
  public productId: string ="";
  public imageId: any;
  public todaysDate;
  public unit: any;
  public units: any;
  public secondaryunit: any;
  // image upload
  public activeColor: string = "green";
  public baseColor: string = "#ccc";
  public overlayColor: string = "rgba(255,255,255,0.5)";
  public dragging: boolean = false;
  public loaded: boolean = false;
  public imageLoaded: boolean = false;
  public imageSrc: string = "";
  public file: any;
  public selectedFiles: any;
  public selectedFileName: any;
  public imageSrcOne: any = "";
  public imageSrcOneId:any;
  public imageSrcTwo: any = "";
  public imageSrcTwoId :any;
  public imageSrcThree: any = "";
  public imageSrcThreeId :any=""
  public imageSrcFour: any = "";
  public imageSrcFourId: any = "";
  public imageSrcFive: any = "";
  public imageSrcFiveId: any = "";

  public imageSrcSix: any = "";
  public imageSrcSixId: any = "";
  public imageSrcSeven: any = "";
  public imgNumber: any;
  public submitted: boolean = false;
  public productCategory: any;
  public categoryIddd: any;

  ///
  itemName : string = "";
  category: any;
  itemCode:any;
  generateBarcode:any;
  description:any;
  hsn:any;
  price:any;
  gstRate:any;
  secondaryUnitFlag: boolean=false;
  //secondaryUnit:any;
  conversionRate:any;
  openingStock:any;
  asOfDate:any;
  expiry:any;
  lowStockWarning:any;
  lowStockUnit:any;
  additionalCess:any;
  supportType:any;
  supportDuration:any;

  public fetchImages:any;

  constructor(private commonService: CommonService,
    private productService: ProductService,
    private formBuilder: FormBuilder,
    private router: Router,
    private titleCasePipe: TitleCasePipe,
    public dialogRef: MatDialogRef<ProductlistViewComponent>,
    @Inject(MAT_DIALOG_DATA) public ProductData: any,

    private route: ActivatedRoute,
    private toastService: ToastNotificationService,
    private location: Location,public dashboardService: DashboardService
  ) { }

  ngOnInit(): void {
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });
    this.productId = this.ProductData.productId
    this.getProductCategory();
    this.ProductForm = this.formBuilder.group({
      itemName: [""],
      category: [""],
      itemCode: [""],
      generateBarcode: [""],
      description: [""],
      //hsn: [""],
      hsn:["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
      unit: [""],
     // price: [""],
     price:["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
      //gstRate: [],
      gstRate: ["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
      secondaryUnitFlag: [],
      secondaryUnit: [""],
      conversionRate: [""],
      openingStock: [],
      asOfDate: [""],
      lowStockWarning: [],
      lowStockUnit: [""],
      //additionalCess: [""],
      additionalCess:["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
      supportType: ["GUARANTEE"],
      supportDuration: [],
      expiry:[""]
    });
    // this.route.paramMap.subscribe((p) => {
    //   this.productId = p.get("id");
    // });
    this.getGoodsById();
    this.getProfitLoss()
    // this.getProductImage();
  }
  getProductCategory() {
    this.productService.getproductCategory({}).then((res: any) => {
      this.productCategory = res.data;
    });
  }

  get f() {
    return this.ProductForm.controls;
  }

  SecondaryUnit = Constants.Units;
  Units = Constants.Units;
  //Units = Constants.Units
  getUnit(event) {
    this.unit = event.source.value;
    this.SecondaryUnit = this.SecondaryUnit.filter((e) => e !== this.unit);
  }
  private filterSecondary() {
    const selectedPrimary = this.SecondaryUnit.filter((us) => this.unit === us);
    const index = this.SecondaryUnit.indexOf(this.unit);
    if (index > -1) {
      this.SecondaryUnit.splice(index, 1);
    }
  }

  getEnableStockValue(event) {
    this.isStockToggle = event;
  }
  showSecondaryUnitField(value) {
    this.isDisabled = value;
  }
  getSecondaryUnit(value) {
    this.unitSecondaryValue = value;
  }
  show(id) {
    if (id === 5) {
      this.showProductType = Constants.USER.GOODS;
      this.serviceToggle = false;
    } else if (id === 6) {
      this.showProductType = Constants.USER.SERVICE;
      this.serviceToggle = true;
    }
  }
  getGoodsById() {
    this.productService.getGoodsById({}, this.productId).then((res) => {
      this.itemName = res.data.itemName,
      this.category= res.data.category.categoryName,
        this.itemCode= res.data.itemCode,
        this.generateBarcode= res.data.barcode,
        this.description= res.data.description,
        this.hsn= res.data.hsn,
        this.unit= res.data.unit,
        this.price= res.data.salePrice,
        this.gstRate= res.data.gstRate,
        this.secondaryUnitFlag= res.data.secondaryUnitFlag,
        this.conversionRate= res.data.conversionRate,
        this.openingStock= res.data.stock,
        this.asOfDate= moment(res.data.asOfDate).format("DD MMM YYYY"),
        this.expiry= res.data.expiryDate,
        this.lowStockWarning= res.data.lowStockWarning,
        this.lowStockUnit= res.data.lowStockUnits,
        this.additionalCess= res.data.additionalCess,
        this.supportType= res.data.supportType,
        this.supportDuration= res.data.supportDuration,
      // this.ProductForm.patchValue({
      //   itemName: res.data.itemName,
      //   category: res.data.categoryId ? res.data.categoryId : null,
      //   itemCode: res.data.itemCode,
      //   generateBarcode: res.data.barcode,
      //   description: res.data.description,
      //   hsn: res.data.hsn,
      //   unit: res.data.unit,
      //   price: res.data.salePrice,
      //   gstRate: res.data.gstRate,
      //   secondaryUnitFlag: res.data.secondaryUnitFlag,
      //   secondaryUnit: res.data.secondaryUnit,
      //   conversionRate: res.data.conversionRate,
      //   openingStock: res.data.stock,
      //   asOfDate: moment(res.data.asOfDate).format("DD MMM YYYY"),

      //   lowStockWarning: res.data.lowStockWarning,
      //   lowStockUnit: res.data.lowStockUnits,
      //   additionalCess: res.data.additionalCess,
      //   supportType: res.data.supportType,
      //   supportDuration: res.data.supportDuration,
      // });
      this.isStockToggle = res.data.lowStockWarning;
      this.isDisabled = res.data.secondaryUnitFlag;
      this.secondaryunit = res.data.secondaryUnit;
      this.unit = res.data.unit;
      this.SecondaryUnit = this.SecondaryUnit.filter((e) => e !== this.unit);


      this.imageSrcSeven = res.data.coverImage ? res.data.coverImage :null;
      this.fetchImages = res.data.productsImages;
      if(res.data.productsImages){
        this.imageSrcOne = res.data.productsImages ? res.data.productsImages[0].productImage :null;
        this.imageSrcOneId = res.data.productsImages ?res.data.productsImages[0].id :null ;
        this.imageSrcTwo = res.data.productsImages ? res.data.productsImages[1].productImage :null;
        this.imageSrcTwoId = res.data.productsImages? res.data.productsImages[1].id:null;
        this.imageSrcThree = res.data.productsImages ? res.data.productsImages[2].productImage :null;
        this.imageSrcThreeId = res.data.productsImages ? res.data.productsImages[2].id:null;
        this.imageSrcFour = res.data.productsImages ? res.data.productsImages[3].productImage:null;
        this.imageSrcFourId = res.data.productsImages ? res.data.productsImages[3].id:null;
        this.imageSrcFive = res.data.productsImages ? res.data.productsImages[4].productImage :null;
        this.imageSrcFiveId = res.data.productsImages ? res.data.productsImages[4].id :null;
         this.imageSrcSix = res.data.productsImages ? res.data.productsImages[5].productImage : null;
        this.imageSrcSixId =res.data.productsImages ? res.data.productsImages[5].id : null;
      }


    });
  }



  cancel() {
    //  this.location.back();
    this.dialogRef.close();
   // this.router.navigate(["/pages/product-page"]);
    this.imageSrcOne = '';
    this.imageSrcTwo = '';
    this.imageSrcThree = '';
    this.imageSrcFour = '';
    this.imageSrcFive = '';
    this.imageSrcSix = '';
    this.imageSrcSeven = '';
   }

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }
  handleInputChange(e, imgNumber?: any) {
    this.imgNumber = imgNumber;

    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];

    if (!file.type.match(pattern)) {
      alert("invalid format");
      return;
    }
    this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.ProductForm.patchValue({
          file: reader.result,
        });
        if (file == null) {
          // this.toastService.openErrorSnackBar("Please Select Image To Upload.");
          this.toastService.toastMsg({
            title: "Error",
            content: "Please Select Image To Upload",
          });
        } else {
          if (this.imgNumber == 1) {
          //  this.imageSrcOne = reader.result;
            formdata.set("uploadProductImage", file);
          } else if (this.imgNumber == 2) {
           // this.imageSrcTwo = reader.result;
            formdata.set("uploadProductImage", file);
          } else if (this.imgNumber == 3) {
           // this.imageSrcThree = reader.result;
            formdata.set("uploadProductImage", file);
          } else if (this.imgNumber == 4) {
            //this.imageSrcFour = reader.result;
            formdata.set("uploadProductImage", file);
          } else if (this.imgNumber == 5) {
           // this.imageSrcFive = reader.result;
            formdata.set("uploadProductImage", file);
          } else if (this.imgNumber == 6) {
           // this.imageSrcSix = reader.result;
            formdata.set("uploadProductImage", file);
          } else if (this.imgNumber == 7) {
            this.imageSrcSeven = reader.result;
            formdata.set("uploadProductImage", file);
          }
          this.productService
            .postProductImage(formdata, this.productId)
            .then((res) => {
              if (res) {
                this.toastService.toastMsg({
                  title: "Success",
                  content: "Image Uploaded Successfully!!!",
                });
                // this.toastService.openSnackBar("Image uploaded Successfully!!!");
                this.getProductImage();
                this.getGoodsById();
              }
            });
        }
      };
    }
  }

  getProductImage() {
    this.productService.getProductImage({}, this.productId).then((res) => {
      let productImage = res.data;
      if (productImage) {
        for (let i = 0; i <= productImage.length; i++) {
          if (productImage[0] && i == 0) {
            this.imageSrcOne = productImage[0].productImage;
          }
          if (productImage[1] && i == 1) {
            this.imageSrcTwo = productImage[1].productImage;
          }
          if (productImage[2] && i == 2) {
            this.imageSrcThree = productImage[2].productImage;
          }
          if (productImage[3] && i == 3) {
            this.imageSrcFour = productImage[3].productImage;
          }
          if (productImage[4] && i == 4) {
            this.imageSrcFive = productImage[4].productImage;
          }
          if (productImage[5] && i == 5) {
            this.imageSrcSix = productImage[5].productImage;
          }
        }
      }
    });
  }

  handleCoverDragEnter() {
    this.dragging = true;
  }
  handleCoverDragLeave() {
    this.dragging = false;
  }
  handleCoverImageLoad() {
    this.imageLoaded = true;
  }
  handleCoverDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.coverImgChange(e);
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  coverImgChange(e, imgNumber?: any) {
    this.imgNumber = imgNumber;
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];

    if (!file.type.match(pattern)) {
      alert("invalid format");
      return;
    }
    this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.ProductForm.patchValue({
          file: reader.result,
        });

        if (file == null) {
          this.toastService.toastMsg({
            title: "Error",
            content: "Please Select Image To Upload.",
          });
          // this.toastService.openErrorSnackBar("Please Select Image To Upload.");
        } else {
          this.imageSrcSeven = reader.result;

          // let data = {
          //   location:
          formdata.set("uploadProductImage", file);
          e.srcElement.value = "";
          // }

          this.productService
            .postCoverImage(formdata, this.productId)
            .then((res) => {
              this.toastService.toastMsg({
                title: "Success",
                content: "Image Uploaded Successfully!!!",
              });
              // this.toastService.openSnackBar("Image uploaded Successfully!!!");
              this.getGoodsById();
            });
        }
      };
    }
  }

  public reviewAi: any = [];
  totalProfit: number=80;
  totalLoss: number=80;
  grossProfit: number=80;
  grossLoss: number;
  products: any[] = [];
  getProfitLoss(){
      this.dashboardService.reviewProduct({},"8f39f76a-4200-4219-99be-0c36aa1d2fc8","b119433e-20d1-44be-9f11-817890f0feef").then((res: any) => {
         console.log("res reviewAi",res)
        this.products.push(res);
        if (this.products.length !== 0) {
        this.totalProfit =this.convertStringToNumber(this.products[0].sentiment_distribution.positive);
        this.totalLoss =  this.convertStringToNumber(this.products[0].sentiment_distribution.negative);
        this.grossProfit = this.convertStringToNumber(this.products[0].sentiment_distribution.neutral);
        }
        this.getPieChart()
      }, (error) => {
        console.log("this.products.length",this.products.length)
        this.getPieChart()
      })

      
  }

  // getProfitLoss() {


  //   // const newProduct = {
  //   //     "product_id": "0148355e-2d09-4685-8565-b4e98b5ae02a",
  //   //     "reviews": [
  //   //         {
  //   //             "predicted_sentiment": "Positive",
  //   //             "review": "good"
  //   //         }
  //   //     ],
  //   //     "sentiment_distribution": {
  //   //         "negative": "0%",
  //   //         "neutral": "0%",
  //   //         "positive": "100%"
  //   //     }
  //   // };

  //   this.products.push({
  //     "product_id": "0148355e-2d09-4685-8565-b4e98b5ae02a",
  //     "reviews": [
  //         {
  //             "predicted_sentiment": "Positive",
  //             "review": "good"
  //         }
  //     ],
  //     "sentiment_distribution": {
  //         "negative": "0%",
  //         "neutral": "0%",
  //         "positive": "100%"
  //     }
  // });
  //   if (this.products.length !== 0) {
  //   this.totalProfit =this.convertStringToNumber(this.products[0].sentiment_distribution.positive);
  //   this.totalLoss =  this.convertStringToNumber(this.products[0].sentiment_distribution.negative);
  //   this.grossProfit = this.convertStringToNumber(this.products[0].sentiment_distribution.neutral);
    
  //   }
  //   this.getPieChart()
    
  // }

  convertStringToNumber(value: string): number {
    // Remove the percentage sign from the string
    const numericValue = parseFloat(value.replace('%', ''));
    // Check if the result is a valid number
    if (!isNaN(numericValue)) {
      return numericValue;
    } else {
      // Handle invalid input, return NaN or any default value
      return 0; // Or you can return NaN if preferred
    }
  }

  getPieChart() {
    this.reviewAi = Highcharts;
    if (this.products.length === 0) {
      const chartOptions = {
        chart: {
          type: 'pie',
          events: {
            load: function () {
              const chart = this;
              const centerX = chart.plotWidth / 2;
              const centerY = chart.plotHeight / 2;

              // Add the image element
              chart.noDataImage = chart.renderer
                .image('../../../assets/img/icons/spot-illustrations/notfound1.png', centerX - 90, centerY - 140, 200, 150)
                .add();
            }
          }
        },
        title: {
          // text: 'Gross Profit/Loss',
          text: ' ',
          style: {
            fontFamily: "'Poppins', sans-serif",
            fontWeight: '500',
            fontSize: '16px',
            color: '#737791'
          }
        },
        credits: {
          enabled: false
        }
      };
      this.reviewAi.chart('ai-details-chart', chartOptions);
      document.getElementById('ai-details-chart').style.height = '300px';

    } else {
      const chartOptions = {
        chart: {
          type: 'pie',
        },
        credits: {
          enabled: false
        },
        title: {
          // text: 'Gross Profit/Loss',
          text: ' ',
          style: {
            fontFamily: "'Poppins', sans-serif",
            fontWeight: '500',
            fontSize: '16px',
            color: '#737791'
          }
        },
        yAxis: {
          gridLineWidth: 0
        },
        colors: ['#110152'],
        series: [
          {
            name: 'Sentiment distribution',
            size: '100%',
            data: [
              // ['Gross Profit', this.grossProfit],
              {
                name: 'Postive',
                y: this.totalProfit,
                color: '#FF0000'
              },
              {
                name: 'Negative',
                y: this.totalLoss,
                color: '#00FF00'
              },
              {
                name: 'Neutral',
                y: this.grossProfit,
                color: '#0000FF'
              },
            ],
            // innerSize: '80%',
            // dataLabels: {
            //   enabled: true
            // }
          },
          // {
          //   name: 'Loss',
          //   size: '50%',
          //   data: [
          //     ['Gross Loss', this.grossLoss],
          //     {
          //       name: 'Total Loss',
          //       y: this.totalLoss,
          //       color: '#FC7643'
          //     }
          //   ],
          //   innerSize: '70%',
          //   dataLabels: {
          //     enabled: true
          //   }
          // }
        ]
      };
      this.reviewAi.chart('ai-details-chart', chartOptions);
      document.getElementById('ai-details-chart').style.height = '200px';
    }
  }
}
