<div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content position-relative">
        <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
            <button #closebutton class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
                aria-label="Close" (click)="closeModal()"></button>
        </div>
        <div class="modal-body p-0">
            <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                <div class="row justify-content-between align-items-center">
                    <div class="col-md">
                        <h4 class="mb-2 mb-md-0"><b>Order #{{ invoiceNo }}</b></h4>
                    </div>
                    <div class="col-auto">
                        <!-- <button class="btn btn-falcon-default btn-sm me-1 mb-2 mb-sm-0" type="button"><span
                                class="fas fa-arrow-down me-1"> </span>Download (.pdf)</button> -->
                        <button class="btn btn-falcon-default btn-sm me-1 mb-2 mb-sm-0" type="button"
                            (click)="onPrint()" data-bs-dismiss="modal"><span class="fas fa-print me-1"> </span>Print</button>
                        <!-- <button class="btn btn-falcon-success btn-sm mb-2 mb-sm-0" type="button"><span
                                class="fas fa-dollar-sign me-1"></span>Receive Payment</button> -->
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="card mb-3" id="panel">
                    <div class="card-body">
                        <div class="row align-items-center text-center mb-3">
                            <div class="col-sm-6 text-sm-start"><img src="../../assets/img/logos/logo-invoice.png"
                                    alt="invoice" width="150" /></div>
                            <div class="col text-sm-end mt-3 mt-sm-0">
                                <h2 class="mb-3">Tax Invoice</h2>
                                <h5>Falcon Design Studio</h5>
                                <p class="fs--1 mb-0">156 University Ave, Toronto<br />On, Canada, M5H 2H7</p>
                            </div>
                            <div class="col-12">
                                <!-- <hr /> -->
                            </div>
                        </div>

                        <div class="table-responsive scrollbar mt-4 fs--1">
                            <table class="table table-striped table-bordered">
                                <thead class="light">
                                    <tr class="bg-primary text-white dark__bg-1000">
                                        <th class="border-0">Products</th>
                                        <th class="border-0 text-center">Quantity</th>
                                        <th class="border-0 text-end">Rate</th>
                                        <th class="border-0 text-end">GST(in Rs.)</th>
                                        <th class="border-0 text-end">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let i of receiptProductData">
                                        <td class="align-middle">
                                            {{ i.itemName | titlecase }}
                                        </td>
                                        <td class="align-middle text-center">{{ i.quantity }}</td>
                                        <td class="align-middle text-end">{{ i.totalPrice |
                                            currency:'INR':'symbol':'1.2-2'}}</td>
                                        <td class="align-middle text-end">{{ i.gstAmount |
                                            currency:'INR':'symbol':'1.2-2'}}</td>
                                        <td class="align-middle text-end">{{ i.finalAmount |
                                            currency:'INR':'symbol':'1.2-2'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-md-5 col-lg-6"></div>
                            <div class="col-sm-6 col-md-6 col-lg-6">
                                <div class="table-responsive scrollbar fs--1">
                                    <table class="table table-striped">
                                        <thead class="light">
                                            <tr class="bg-primary text-white dark__bg-1000">
                                                <th class="border-0">Tax Type</th>
                                                <th class="border-0 text-center"></th>
                                                <th class="border-0 text-center">Tax Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="align-middle">SGST</td>
                                                <td></td>
                                                <td class="align-middle text-center">
                                                    {{ billGstAmount / 2 |
                                                    currency:'INR':'symbol':'1.2-2'}}
                                                </td>
                                            </tr>
                                            <tr>
                                            </tr>
                                            <tr>
                                                <td class="align-middle">CGST</td>
                                                <td></td>
                                                <td class="align-middle text-center">
                                                    {{ billGstAmount / 2 |
                                                    currency:'INR':'symbol':'1.2-2'}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="align-middle"><b>Total</b></td>
                                                <td></td>
                                                <td class="align-middle text-center">
                                                    <b>{{ totalBillAmount | currency:'INR':'symbol':'1.2-2'}}</b>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer bg-light">
                        <p class="fs--1 mb-0"><strong>Notes: </strong>We really appreciate your business and if
                            there’s
                            anything else we
                            can
                            do, please let us know!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>