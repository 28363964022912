import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ProductService } from 'src/app/core/services/product.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import * as XLSX from 'xlsx';

interface IRange {
  value: Date[];
  label: string;

}

@Component({
  selector: 'app-stock-summary',
  templateUrl: './stock-summary.component.html',
  styleUrls: ['./stock-summary.component.scss']
})
export class StockSummaryComponent implements OnInit {
  fileName='StockSummary.xlsx';
  public start = moment().subtract(29, 'days');
  public end = moment();
  public ranges: IRange[];
  public dateRange:Date[];
  public today: Date;
  public maxDate: Date;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public p = 1;
  public currentpage: any;
  public totalrow: any;
  public totalPage: any;
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  public pagesize: number = 1;
  public productName:any="";
  public stockSummaryList:any=[];
  public total:any=0;

  constructor(private productService: ProductService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
  }
  isShown: boolean = false;
  ngOnInit(): void {
    //this.dateRange = new Date();
    this.dateRange=[new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate()))]

    this.ranges = [

      {
        value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 7 Days'
      },
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 30 Days'
      },
      {
        value: [new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()), new Date(new Date(new Date().setDate(0)).toDateString())],
        label: 'Last Month'

      },
      {
        value: [new Date(new Date(new Date().getFullYear() - 1, 3, 1).toDateString()), new Date(new Date(new Date().getFullYear(), 2, 31).toDateString())],
        label: 'Last Financial Year'

      },
      {
        value: [new Date(new Date(new Date().getFullYear() , 3, 1).toDateString()), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'This Financial Year'

      },
      {
        value: [],
        label: 'Custom Range'

      }];

   this.getStockSummary();
  }
  //getReportStockSummry
  getStockSummary(){
    this.productService.getReportStockSummry({}, this.currentUser.id,this.partyPageSize, (this.currentPageNo + 1),moment(this.dateRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD') ,moment(this.dateRange[1], 'DD-MMM-YYYY').format('YYYY-MM-DD')).then((res:any) => {
       //this.p = res.data.currentpage;
       //console.log(res)
      this.stockSummaryList = res.data.pageData;
      this.total = res.data.total;
      this.totalrow = res.data.totalRows;
      this.totalPage = res.data.totalPages;
    },
    (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    })
  }
  printComponent(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
  public handlePage(e: any) {
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.stockSummaryList.length > 0) {
      this.partyPageSize = e.pageSize;
      this.getStockSummary();
    }
  }
  exportExcel(){
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

}
onPrint() {
  const elementsToHide = ['headingPrintHide', 'dateRangePrintHide','headingPrintHide1'];

  elementsToHide.forEach((elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.style.display = 'none';
    }
  });

  // Hide headers and footers for printing
  const style = document.createElement('style');
  style.innerHTML = `
      @media print {
          @page {
              size: auto;   /* auto is the initial value */
              margin: 0;  /* this affects the margin in the printer settings */
          }
          @page {
              margin-top: 0;
              margin-bottom: 0;
          }
          body {
              margin: 0;  /* this affects the margin on the content before sending to the printer */
          }
          @page :first {
              margin-top: 0;
          }
          @page :last {
              margin-bottom: 0;
          }
          /* Define CSS rules to hide headers and footers */
          header, footer {
              display: none !important;
          }
      }
  `;
  document.head.appendChild(style);

  // Print
  const printContents = document.getElementById('excel-table').innerHTML;
  const originalContents = document.body.innerHTML;
  this.removeScrollbar();
  window.print();
  this.restoreScrollbar();
  // Restore hidden elements after printing
  elementsToHide.forEach((elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.style.display = '';
    }
  });
  // Remove the added style for hiding headers and footers
  document.head.removeChild(style);
}
private removeScrollbar() {
  document.body.style.overflow = 'hidden';
}

private restoreScrollbar() {
  document.body.style.overflow = 'auto';
}


 changeDate(e){
  this.getStockSummary();
  }

}
