<div class="card mb-3">
    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
            Roles List
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>

    <div *ngIf="showDeleteMessage" class="alert alert-success border-2 d-flex align-items-center" role="alert">
        <div class="bg-success me-3 icon-item"><span class="fas fa-check-circle text-white fs-3"></span></div>
        <p class="mb-0 flex-1">Role deleted successfully!!!</p>
        <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
    <div class="table-responsive scrollbar m-2" *appHasPermission="['read']; name 'grantpermission'">

        <table class="table bg-white table-striped table-bordered overflow-auto">
            <thead>
                <tr class="btn-reveal-trigger">
                    <th scope="col">#</th>
                    <th scope="col">Role Name</th>
                    <th scope="col">Description</th>
                    <th scope="col">No.of Users</th>
                    <th class="text-center">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr class="btn-reveal-trigger" *ngFor="let item of roleData;let i = index">
                    <td>{{ i+1 }}</td>
                    <!-- <td> {{ item.display == null ? item.roleName : item.display}}</td> -->
                    <td> {{item.roleName}}</td>
                    <!-- <td>{{ item.display | titlecase}}</td> -->
                    <td>{{item.description}}</td>
                    <td>{{item.countOfUsers}}</td>
                    <td class="text-end">
                        <div class="dropdown font-sans-serif position-static">
                            <button class="btn btn-link text-600 btn-sm btnp" *ngIf="item.isRoot"
                                style="cursor: not-allowed;" title="not allowed">
                                <span class="fas fa-ellipsis-h fs--1" disabled></span></button>
                            <button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal" type="submit"
                                data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                                aria-expanded="true" *ngIf="!item.isRoot" style="cursor: pointer;"><span
                                    class="fas fa-ellipsis-h fs--1"></span></button>
                            <div class="dropdown-menu dropdown-menu-end">
                                <div class="bg-white py-0">

                                    <button class="dropdown-item text-danger" class="btn btn-light btn-sm btn2"
                                        routerLink="/pages/edit-role/{{ item.id }}" routerLinkActive="active" title="Edit Role"
                                        *appHasPermission="['modify']; name 'grantpermission'">Edit</button>
                                </div>
                                <div class="bg-white py-0">

                                    <button class="dropdown-item text-danger" class="btn btn-light btn-sm btn2" aria-hidden="true"
                                        (click)="getRoleID(item.id)" title="Delete Role" data-bs-toggle="modal"
                                        data-bs-target="#error-modal">Delete</button>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="roleData == 0">
                <tr class="text-center">
                    <td colspan="5">
                        <h5 class="text-nowrap">No Roles Details Added. Please Add Roles Details To Be Displayed Here.
                            <span>
                                <a class="nav-link" routerLink="/pages/add-role">Add New Roles Retailer </a>
                            </span></h5>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal fade" id="error-modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 350px">
            <div class="modal-content position-relative">
                <div class="position-absolute top-0 end-0 mt-2 me-3 z-index-1">
                    <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
                    aria-label="Close"></a>
                </div>
                <div class="modal-body p-0">
                    <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                        <h2 class="mb-1 text-center font-weight-bold" style="font-weight: 800; "
                            id="modalExampleDemoLabel">Delete Role? </h2>
                    </div>
                    <h4 class="text-center ">Are you sure to delete this role?</h4>
                    <h4 class="text-center ">User already have assign to this role!!</h4>
                </div>
                <div class="modal-footer justify-content-center">
                    <a class="btn btn-outline-primary" data-bs-dismiss="modal">Close</a>
                    <a class="btn btn-outline-primary" *appHasPermission="['delete']; name 'grantpermission'"
                        data-bs-dismiss="modal" (click)="deleteRole()">Delete
                    </a>

                </div>
            </div>
        </div>
    </div>
</div>
