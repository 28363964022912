import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProfileService } from 'src/app/core/services/profile.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-edit-business-partner-modal',
  templateUrl: './edit-business-partner-modal.component.html',
  styleUrls: ['./edit-business-partner-modal.component.scss']
})
export class EditBusinessPartnerModalComponent implements OnInit {
  editForm: FormGroup;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  businessType: string;
  partnerId: number;

  constructor(
    private fb: FormBuilder, public profileService: ProfileService, public toastService: ToastNotificationService,
    public dialogRef: MatDialogRef<EditBusinessPartnerModalComponent>, private titleCasePipe: TitleCasePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.partnerId = data.partnerId; // Assign partnerId from the data passed to the modal
      this.businessType = data.businessType; // Assign businessType from the data passed to the modal

      this.editForm = this.fb.group({
        position: [data.position, Validators.required],
        nameOfPartner: [data.nameOfPartner, Validators.required],
        businessType : data.businessType,
        partnerId : data.partnerId
      });
     }

  ngOnInit(): void {
  }

  saveChanges() {
    if (this.editForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      // this.toastService.openErrorSnackBar('Please fill all the fields.');
    }

    const updateBusinessData = {

      updateBusiness: [
        {
          "partnerId": this.partnerId, // Use the stored partnerId
          "businessType": this.businessType, // Use the stored businessType
          "position": this.editForm.get('position').value,
          "nameOfPartner": this.editForm.get('nameOfPartner').value,
        }
      ]
    }

    this.profileService.updateBusinessPartner(updateBusinessData, this.currentUser.id)
      .then((res) => {
        if(res.success) {
          // API call successful
          this.dialogRef.close('save'); // Close the modal with a result
          // this.toastService.openSnackBar('Row Updated Successfully!!!');
          this.toastService.toastMsg({
            title: "Success",
            content: "Row Updated Successfully!!!",
          })
        }
      })
      .catch((err) => {
        // API call failed
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        } else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar('Something Went Wrong.');
        }
      });
  }
  

  onNoClick(): void {
    this.dialogRef.close();
  }

}
