<div>
    <div>
        <ul class="nav nav-pills mb-3" role="tablist" id="pill-tab2">
            <li class="nav-item" role="presentation">
              <button routerLinkActive="active" routerLink="/pages/schedule/practiceAvailability" class="nav-link active" data-wizard-step="data-wizard-step" data-bs-toggle="pill" data-bs-target="#form-wizard-progress-tab1" type="button" role="tab" aria-controls="form-wizard-progress-tab1" aria-selected="true">
                Practice Availability 
              </button>
           </li>
           <li class="nav-item" role="presentation">
             <button class="nav-link" routerLinkActive="active"  routerLink="/pages/schedule/appointmentType" data-wizard-step="data-wizard-step" data-bs-toggle="pill" data-bs-target="#form-wizard-progress-tab2" type="button" role="tab" aria-controls="form-wizard-progress-tab2" aria-selected="false">
                 Appointment Type
             </button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" routerLinkActive="active"  routerLink="/pages/schedule/manageCalender" data-wizard-step="data-wizard-step" data-bs-toggle="pill" data-bs-target="#form-wizard-progress-tab3" type="button" role="tab" aria-controls="form-wizard-progress-tab3" aria-selected="false" >
                  Manage Calender
                </button>
            </li>
             <li class="nav-item" role="presentation">
                 <button class="nav-link" routerLinkActive="active"  routerLink="/pages/schedule/automation" data-wizard-step="data-wizard-step" data-bs-toggle="pill" data-bs-target="#form-wizard-progress-tab4" type="button" role="tab" aria-controls="form-wizard-progress-tab4" aria-selected="false">
                    Automation
                   </button>
            </li>
        </ul>
    </div>
      <router-outlet></router-outlet> 
</div>