
<!-- <div class="row d-flex flex-center">
  <div class="col-xs-10 col-sm-10 col-md-8 col-lg-5 col-xl-3"></div>
  <div class="col-md-4 col-lg-1 col-xl-4"></div>
  <div id = "saleSearchPrint"  class="col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 gx-0 pe-3" style = "padding-left: 3rem;">
    <input type="search" id="searchInput" class="form-control pe-5 mb-2" placeholder="Invoice Number/Shipping Address" autocomplete="off"
        [(ngModel)]="searchKey" (ngModelChange)="getAllPurchaseinvoicebySearch(searchKey)">
  </div>

  <div class="navOption-btns col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-2 mx-0 px-0 mb-2" style = "display:flex;flex-direction:row;justify-content:space-evenly;">
    <button id = "saleButtonPrint1" class="btn" ngbTooltip="Add New Invoice" (click)="openDialog()"><i class="fas fa-plus"></i> Purchase Invoice</button>
    <button class="btn" ngbTooltip="Download" accept=".xls,.xlsx"  id="download"
    (click)="downlaodAllData()"><i class="fas fa-download"></i></button> -->
    <!-- onclick="location.href = 'https://web.iceipts.com/backend/inventory/parties/downloadParties/';"><i class="fas fa-download"></i></button> -->
  <!-- </div>
</div>  -->

<div class="row headerButtons" id="showoptionHide">
  <div class = "flex-item searchBar">
    <input type="search" id="searchInput" class="form-control pe-5 my-2" placeholder="Invoice #/Shipping Address" autocomplete="off"
        [(ngModel)]="searchKey" (ngModelChange)="getAllPurchaseinvoicebySearch(searchKey)">
  </div>
  <div class="navOption-btns flex-item formButtons mx-0">
    <button id = "saleButtonPrint1" class="btn text-nowrap my-2" ngbTooltip="Add New Invoice" (click)="openDialog()"><i class="fas fa-plus"></i> Purchase Invoice</button>
    <button class="btn my-2" ngbTooltip="Download" accept=".xls,.xlsx"  id="download"
    (click)="downlaodAllData()"><i class="fas fa-download"></i></button>
    <!-- onclick="location.href = 'https://web.iceipts.com/backend/inventory/parties/downloadParties/';"><i class="fas fa-download"></i></button> -->
  </div>
</div>

<!-- <hr class=" mb-2"> -->

<div style = "width:100% ; overflow-x: auto; margin-top: 8px;" id = "saleTablePrint">

  <div class="table-container overflow-visible">
    <div class="m-2">

      <table class="table table-responsive datalist" *ngIf="!isArabic">
          <thead>
              <tr class="btn-reveal-trigger">
                  <!-- <th scope="col">
                      <input type="checkbox" />
                  </th> -->
                  <th class="text-nowrap" style="vertical-align:middle;width:200px;">Invoice #</th>
                  <th class="text-nowrap" style="vertical-align:middle;width:180px;">Shipping Address</th>
                  <th class="text-nowrap" style="vertical-align:middle;width:150px;text-align: right;">GST Amount</th>
                  <th class="text-nowrap" style="vertical-align:middle;width:200px;text-align: right;">Total Bill</th>
                  <th class="text-nowrap" style="vertical-align:middle;width:auto;"></th>
              </tr>
          </thead>
          <tbody>

              <tr *ngFor="let item of invoiceData; let i = index">
                  <!-- <td scope="row">
                      <input type="checkbox" />
                  </td> -->

                  <td style="vertical-align:middle;">{{ item.invoiceNo}}</td>
                  <td style="vertical-align:middle;">{{ item.shippingAddress | titlecase}}</td>
                  <td style="vertical-align:middle;text-align: right;">
                    <!-- {{ item.billGstAmount | currency:'INR':'symbol':'1.2-2' }} -->
                    ₹&nbsp;{{ item.billGstAmount | number:'1.2-2'}}
                  </td>
                  <td style="vertical-align:middle;text-align: right;">
                    <!-- {{ item.totalBillAmount | currency:'INR'}} -->
                    ₹&nbsp;{{ item.totalBillAmount | number:'1.2-2'}}
                  </td>
                  <td style="vertical-align:middle;text-align: center;">
                      <button class="btn" type="button"  data-bs-toggle="modal"
                          data-bs-target="#invoice-modal2" (click)="getReceiptById(item.id)">
                          <span class="bi-printer iconFontSize" data-bs-toggle="tooltip"
                              data-bs-placement="top" title="print"></span>
                      </button>
                  </td>
              </tr>
          </tbody>
          <tbody *ngIf="invoiceData == 0">
            <tr class="text-center">
              <td colspan="12">
                <img class="image-responsive" src="assets/img/icons/spot-illustrations/GST No Transaction.png" width="124" height="142" alt="">
                <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Data Found.</h5>
                <!-- <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png" alt="notfound1" width="200" height="150" />

                <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Purchase Invoice Details Added. Please Add Purchase Invoice Details To Be Displayed Here.
                  <span>
                    <a class="nav-link" (click)="openDialog()">Add New Purchase Invoice
                    </a>
                  </span>
                </h5> -->
              </td>
            </tr>
              <!-- <tr class="text-center">
                  <td colspan="5">
                      <h5 class="text-nowrap">No Purchase Invoice Details Added. Please Add Purchase Invoice
                          Details To Be Displayed Here. <span>
                              <a class="nav-link" (click)="openDialog()">Add New Purchase Invoice
                              </a>
                          </span></h5>
                  </td>
              </tr> -->
          </tbody>
      </table>

      <!-- UAE -->

      <table class="table table-responsive datalist" *ngIf="isArabic">
          <thead>
              <tr class="btn-reveal-trigger">
                  <!-- <th scope="col">
                      <input type="checkbox" />
                  </th> -->
                  <th class="text-nowrap" style="vertical-align:middle;width:200px;">Invoice #</th>
                  <th class="text-nowrap" style="vertical-align:middle;width:180px;">Shipping Address</th>
                  <th class="text-nowrap" style="vertical-align:middle;width:150px;text-align: right;">{{lang.GST_Amount}}</th>
                  <th class="text-nowrap" style="vertical-align:middle;width:200px;text-align: right;">Total Bill</th>
                  <th class="text-nowrap" style="vertical-align:middle;width:auto;"></th>
              </tr>
          </thead>
          <tbody>

              <tr *ngFor="let item of invoiceData; let i = index">
                  <!-- <td scope="row">
                      <input type="checkbox" />
                  </td> -->

                  <td style="vertical-align:middle;">{{ item.invoiceNo}}</td>
                  <td style="vertical-align:middle;">{{ item.shippingAddress | titlecase}}</td>
                  <td style="vertical-align:middle;text-align: right;">
                    AED&nbsp;{{ item.billGstAmount | number:'1.2-2'}}
                  </td>
                  <td style="vertical-align:middle;text-align: right;">
                    AED&nbsp;{{ item.totalBillAmount | number:'1.2-2'}}
                  </td>
                  <td style="vertical-align:middle;text-align: center;">
                      <button class="btn" type="button"  data-bs-toggle="modal"
                          data-bs-target="#invoice-modal2" (click)="getReceiptById(item.id)">
                          <span class="bi-printer iconFontSize" data-bs-toggle="tooltip"
                              data-bs-placement="top" title="print"></span>
                      </button>
                  </td>
              </tr>
          </tbody>
          <tbody *ngIf="invoiceData == 0">
            <tr class="text-center">
              <td colspan="12">
                <img class="image-responsive" src="assets/img/icons/spot-illustrations/GST No Transaction.png" width="124" height="142" alt="">
                <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Data Found.</h5>
                <!-- <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png" alt="notfound1" width="200" height="150" />

                <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Purchase Invoice Details Added. Please Add Purchase Invoice Details To Be Displayed Here.
                  <span>
                    <a class="nav-link" (click)="openDialog()">Add New Purchase Invoice
                    </a>
                  </span>
                </h5> -->
              </td>
            </tr>
              <!-- <tr class="text-center">
                  <td colspan="5">
                      <h5 class="text-nowrap">No Purchase Invoice Details Added. Please Add Purchase Invoice
                          Details To Be Displayed Here. <span>
                              <a class="nav-link" (click)="openDialog()">Add New Purchase Invoice
                              </a>
                          </span></h5>
                  </td>
              </tr> -->
          </tbody>
      </table>

        <mat-paginator class="float-right mt-2" *ngIf="invoiceData.length> 0" showFirstLastButtons
            [pageSize]="purchaseListPageSize" [pageSizeOptions]="[5, 10, 20,30]"
            [length]="totalrow" [pageIndex]="currentPageNo" (page)="handlePage($event)"
            aria-label="Select page">
        </mat-paginator>
    </div>
  </div>


</div>


<!-- Print Modal -->
<!--
  <div class="modal fade" #closebutton id="invoice-modal2" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal" (click)="closeModal()"></button>
            </div>
            <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-md">
                        </div>
                        <div class="col-auto">
                            <button id="printPageButton" class="btn btn-falcon-default btn-sm me-1 mb-2 mb-sm-0" type="button"
                                (click)="onPrint()"><span class="fas fa-print me-1">
                                </span>Print</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4"></div>
                        <div class="col-4">
                            <h2 class=""><b>Purchase Invoice</b></h2>
                        </div>
                        <div class="col-4">
                            <div style="font-size: 12px" class="text-end">
                                Original for Recipient
                                <mat-checkbox class="">
                                </mat-checkbox>
                            </div>
                            <div style="font-size: 12px" class="text-end">
                                Duplicate for Transporator
                                <mat-checkbox class="">
                                </mat-checkbox>
                            </div>
                            <div style="font-size: 12px" class="text-end">
                                Triplicator for Supplier
                                <mat-checkbox class="">
                                </mat-checkbox>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="card" >
                        <div class="card-body">
                            <div class="row bordered" id="panel">
                                <div class="col-sm-6  ">

                                </div>
                                <div class="col-sm-6  text-sm-end mt-1 mt-sm-0 justify-content-end ">
                                    <h5 class="fw-bold"  style="margin-right: 10px !important;">{{tradeName}}</h5>
                                    <p class="fs--1 mb-0 "  style="margin-right: 10px !important;">{{addressDetails.homeno}} {{addressDetails.address}} {{addressDetails.landmark}}<br />{{addressDetails.state}} {{addressDetails.country}} <br /> {{addressDetails.pincode}}</p>


                                </div>
                                <div class="col-12">
                                </div>
                            </div>
                            <div class="row bordered table-responsive scrollbar fs--1 borderbottom2 ">
                                <table class="table table-striped tablePrint" style="font-size: 11px!important;">
                                    <thead class="inner-model-table-heading">
                                        <tr class="text-white dark__bg-1000" >
                                            <th class="borderbottom2" style="width: 33% !important;">Bill To</th>
                                            <th class="borderLeft borderbottom2 " style="width: 33% !important;">Ship To</th>
                                            <th class="borderLeft borderbottom2" style="width: 33% !important;"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-body">
                                        <tr>
                                            <td class="align-middle" style="width: 33% !important;">
                                                <p>{{salesData.customerName}}</p>
                                                <p>{{salesData.billingAddress}}</p>
                                            </td>
                                            <td class="align-middle text-center borderLeft" style="width: 33% !important;">
                                                {{salesData.shippingAddress}}

                                            </td>
                                            <td class="align-middle  borderLeft " style="width: 33% !important;text-align: left !important;">
                                               <p> Place of Supply: {{salesData.shippingAddress}}</p>
                                               <p>Invoice No.:{{salesData.invoiceNo}}</p>
                                               <p> Date:{{salesData.invoiceDate| date:"dd MMM YYYY"| uppercase}}</p>
                                               <p *ngIf="salesData.ewayBillNo">E-way Bill number:{{salesData.ewayBillNo}}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class=" row  borderbottom2 table-responsive scrollbar fs--1">
                                <table class="table table-striped  tablePrint" style="font-size: 11px!important;" >
                                    <thead class="inner-model-table-heading">
                                        <tr class="text-white dark__bg-1000">
                                            <th class="borderedTopNone">#</th>
                                            <th class="borderedTopNone">Item Name</th>
                                            <th class="borderedTopNone">HSN/ SAC</th>
                                            <th class="borderedTopNone">Quantity</th>
                                            <th class="borderedTopNone">Unit</th>
                                            <th class="borderedTopNone">Price/ Unit</th>
                                            <th class="borderedTopNone">Taxable amount</th>
                                            <th *ngIf="gstStateMatch"  class="borderedTopNone">CGST</th>
                                            <th  *ngIf="gstStateMatch" class="borderedTopNone">SGST</th>
                                            <th *ngIf="!gstStateMatch"  class="borderedTopNone">IGST</th>
                                            <th class="borderedTopNone">Support </th>
                                            <th class="borderedTopNone">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-body">
                                        <tr *ngFor="let i of saleProductData; let index = index">
                                            <td class="bordered">{{ index+1 }}</td>
                                            <td class="bordered text-capitalize">{{i.itemName}}</td>
                                            <td class="bordered">{{i.barcode}}</td>
                                            <td class="bordered">{{i.quantity}}</td>
                                            <td class="bordered">{{i.unit}}</td>
                                            <td class="bordered">{{i.pricePerUnit| currency:'INR':'symbol':'1.2-2'}}</td>
                                            <td class="bordered"> {{i.totalPrice - billGstAmount | currency:'INR':'symbol':'1.2-2'}}</td>
                                            <td *ngIf="gstStateMatch"  class="bordered"> {{ (billGstAmount / 2) | currency:'INR':'symbol':'1.2-2'}}</td>
                                            <td   *ngIf="gstStateMatch" class="bordered"> {{ (billGstAmount / 2) | currency:'INR':'symbol':'1.2-2'}}</td>
                                            <td  *ngIf="!gstStateMatch"  class="bordered">{{billGstAmount/2 + billGstAmount/2| currency:'INR':'symbol':'1.2-2'}}</td>
                                            <td class="bordered">{{i.supportType}}-{{i.supportDuration}} Months</td>
                                            <td class="bordered">{{i.finalAmount}}</td>

                                        </tr>
                                        <tr >
                                            <td  class="bordered"></td>
                                            <td  class="bordered">Total</td>
                                            <td  class="bordered"></td>
                                            <td  class="bordered"></td>
                                            <td  class="bordered"></td>
                                            <td  class="bordered"></td>
                                            <td  class="bordered"></td>
                                            <td *ngIf="gstStateMatch"  class="bordered"></td>
                                            <td *ngIf="gstStateMatch"  class="bordered"></td>
                                            <td  *ngIf="!gstStateMatch"  class="bordered"></td>
                                            <td  class="bordered"></td>
                                            <td  class="bordered">{{salesData.totalBillAmount}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row ">
                                <div class="col-6  borderbottom2 borderLeft">
                                    <div class="table-responsive scrollbar fs--1">
                                        <table class="table table-striped tablePrint" style="font-size: 11px!important;">
                                            <thead class="inner-model-table-heading">
                                                <tr class="text-white dark__bg-1000" >
                                                    <th class=""> Tax Type</th>
                                                    <th class="">Taxable Amount</th>
                                                    <th class="">Rate</th>
                                                    <th class="">Tax Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-body">
                                                <tr *ngFor="let i of saleProductData; let index = index">
                                                    <td class="align-middle text-center">
                                                        {{salesData.supplyType}}
                                                    </td>
                                                    <td class="align-middle text-center">
                                                        {{i.totalPrice - billGstAmount| currency:'INR':'symbol':'1.2-2'}}
                                                    </td>
                                                    <td class="align-middle text-center">
                                                        {{i.gst}}
                                                    </td>
                                                    <td class="align-middle text-center">
                                                        {{totalGST |currency:'INR':'symbol':'1.2-2' }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div class="col-6">
                                        <table class="table tablePrint" style="font-size: 11px!important;">
                                          <tr class = "inner-model-table-heading">
                                            <th class="thWidth borderLeft p-3" style="text-align:left!important">Amounts:</th>
                                            <th class="borderLeft borderbottom2 borderRIGHT p-3" style="width: 50px;" style="text-align:right!important">In Rs:</th>
                                          </tr>
                                          <tr>
                                            <td class="thWidth bordered p-2" style="text-align:left!important">Total Base Price</td>
                                            <td class="borderedLeftNone p-2" style="width: 50px;" style="text-align:right!important" *ngFor="let i of saleProductData; let index = index" >{{totalBillAmount -  billGstAmount|currency:'INR':'symbol':'1.2-2'}}</td>
                                          </tr>
                                          <tr>
                                            <td class="thWidth bordered p-2 " style="text-align:left!important">Total</td>
                                            <td class=" borderedLeftNone p-2" style="width:50px;" style="text-align:right!important">
                                                {{ totalBillAmount | currency:'INR':'symbol':'1.2-2'}}
                                            </td>
                                          </tr>
                                          <tr >
                                            <td class="thWidth bordered p-2" style="text-align:left!important">Received</td>
                                            <td class="borderedLeftNone p-2" style="width: 50px;" style="text-align:right!important">
                                                {{ salesData.received| currency:'INR':'symbol':'1.2-2'}}
                                            </td>
                                          </tr>

                                          <tr class="borderbottom2">
                                            <td class="thWidth bordered p-2" style="text-align:left!important">Balance</td>
                                            <td class="bordered p-2" style="width: 50px;" style="text-align:right!important">
                                                {{  salesData.balance| currency:'INR':'symbol':'1.2-2'}}
                                            </td>
                                          </tr>

                                        </table>

                                </div>
                            </div>
                            <div class="row borderLeft" style="font-size: 11px!important;">
                                <div class="col-6 ">
                                    <p style="background: #e4e3e6; width: 100%!important;" class="inner-model-table-heading p-0 text-black"><b>Invoice Amount In Words</b></p>
                                    <p style="padding-left: 5px !important;">{{ finalAmount }}
                                    </p>
                                </div>
                                <div class="col-6 borderLeft borderRIGHT">
                                    <p class="p-1"></p>
                                    <p>
                                    </p>
                                </div>
                            </div>
                            <div class="row borderedBottom" style="font-size: 11px!important;">
                                <div class="col-6 ">
                                    <p style="background: #e4e3e6;" class="inner-model-table-heading p-0 text-black"><b>Terms and conditions:</b>
                                    </p>
                                    <p class="pb-2"  style="padding-left: 5px !important;">Thanks for doing business with us.
                                    </p>
                                </div>
                                <div class="col-6 borderLeft">
                                    <p class="text-center pb-2"><b>{{BusinessName}}</b></p>
                                    <p class="text-center pt-3">Authorized Signatory</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 -->

<div class="modal fade" #closebutton id="invoice-modal2" tabindex="-1" role="dialog" aria-hidden="true" *ngIf="!isArabic">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content position-relative">
          <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
              <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal" (click)="closeModal()"></button>
          </div>
          <div class="modal-body p-0">
              <div class="rounded-top-lg py-3 ps-4 pe-6">
                  <!-- <div class="row justify-content-between align-items-center">
                      <div class="col-md">
                      </div>
                      <div class="col-auto">
                          <button id="printPageButton" class="btn btn-falcon-default btn-sm me-1 mb-2 mb-sm-0" type="button"
                              (click)="onPrint()"><span class="fas fa-print me-1">
                              </span>Print</button>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-4"></div>
                      <div class="col-4">
                          <h2 class=""><b>Purchase Invoice</b></h2>
                      </div>
                      <div class="col-4">
                          <div style="font-size: 12px" class="text-end">
                              Original for Recipient
                              <mat-checkbox class="">
                              </mat-checkbox>
                          </div>
                          <div style="font-size: 12px" class="text-end">
                              Duplicate for Transporator
                              <mat-checkbox class="">
                              </mat-checkbox>
                          </div>
                          <div style="font-size: 12px" class="text-end">
                              Triplicator for Supplier
                              <mat-checkbox class="">
                              </mat-checkbox>
                          </div>

                      </div>
                  </div> -->
              </div>
              <div class="container" id = "panel">
                  <div class="card" >
                      <div class="card-body">
                          <div class="row my-2" id="panel">

                            <div class="col-6 pt-2">
                              <p style = "color: red;font-weight: 600;">{{tradeName ? tradeName : "NA"}}</p>
                              <p>Mobile:&nbsp;{{ mobile ? mobile : 'NA'}}</p>
                            </div>
                            <div class="col-6">
                              <div class="row headerAlignment">
                                <div class="col-6" style = "font-weight: 600;">
                                  TAX INVOICE
                                </div>
                                <div class="col-6">
                                  <button style = "float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;font-weight: 600;" id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0"
                                    type="button" (click)="onPrint()"><span class="fas fa-print me-1">
                                    </span>Print
                                  </button>
                                  <div id = "originalForReceiptPrint">
                                    ORIGINAL FOR RECIPIENT
                                  </div>
                                </div>
                              </div>
                              <div class="row headerAlignment">
                                <div class="col-6">
                                  Invoice #<span style = "float: right;">:</span>
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                  {{invoiceNo ? invoiceNo : "NA"}}
                                </div>
                              </div>
                              <div class="row headerAlignment">
                                <div class="col-6">
                                  Invoice Date<span style = "float: right;">:</span>
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                  {{invoiceDate | date:"dd MMM YYYY" | uppercase }}
                                </div>
                              </div>
                              <div class="row headerAlignment">
                                <div class="col-6">
                                  Due Date<span style = "float: right;">:</span>
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                  {{ dueDate ? dueDate : 'NA' }}
                                </div>
                              </div>
                            </div>

                              <!-- <div class="col-sm-6  text-sm-end mt-1 mt-sm-0 justify-content-end ">
                                  <h5 class="fw-bold"  style="margin-right: 10px !important;">{{tradeName}}</h5>
                                  <p class="fs--1 mb-0 "  style="margin-right: 10px !important;">{{addressDetails.homeno}}
                                    {{addressDetails.address}} {{addressDetails.landmark}}<br />
                                    {{addressDetails.state}} {{addressDetails.country}} <br />
                                    {{addressDetails.pincode}}</p>


                              </div>
                              <div class="col-12">
                              </div> -->
                          </div>
                          <!-- <div class="row bordered table-responsive scrollbar fs--1 borderbottom2 ">
                              <table class="table table-striped tablePrint" style="font-size: 11px!important;">
                                  <thead class="inner-model-table-heading">
                                      <tr class="text-white dark__bg-1000" >
                                          <th class="borderbottom2" style="width: 33% !important;">Bill To</th>
                                          <th class="borderLeft borderbottom2 " style="width: 33% !important;">Ship To</th>
                                          <th class="borderLeft borderbottom2" style="width: 33% !important;"></th>
                                      </tr>
                                  </thead>
                                  <tbody class="table-body">
                                      <tr>
                                          <td class="align-middle" style="width: 33% !important;">
                                              <p>{{salesData.customerName}}</p>
                                              <p>{{salesData.billingAddress}}</p>
                                          </td>
                                          <td class="align-middle text-center borderLeft" style="width: 33% !important;">
                                              {{salesData.shippingAddress}}

                                          </td>
                                          <td class="align-middle  borderLeft " style="width: 33% !important;text-align: left !important;">
                                             <p> Place of Supply: {{salesData.shippingAddress}}</p>
                                             <p>Invoice No.:{{salesData.invoiceNo}}</p>
                                             <p> Date:{{salesData.invoiceDate| date:"dd MMM YYYY"| uppercase}}</p>
                                             <p *ngIf="salesData.ewayBillNo">E-way Bill number:{{salesData.ewayBillNo}}</p>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div> -->
                          <div class = "row mt-2 mb-4">
                            <div class="col-6">
                              <div style="display: flex;flex-direction: column;justify-content: flex-start;">
                                <span class = "inner-model-table-heading p-2 my-2" style = "width: 130px;font-weight: 600;">BILLED FROM</span>
                                <span style = "font-weight: 600;">{{salesData.customerName ? salesData.customerName : "NA" }}</span>
                                <span>{{ billingAddress ? billingAddress : "NA"}}</span>
                                <span>Mobile: &nbsp;{{ mobile ? mobile : "NA" }}</span>
                                <span>GSTIN: &nbsp;{{gstinNo ? gstinNo : "NA"}}</span>
                                <span>State: &nbsp;{{ state ? state : "NA" }}</span>
                              </div>
                            </div>
                            <div class="col-6">
                              <div style="display: flex;flex-direction: column;justify-content: flex-start;">
                                <span class = "inner-model-table-heading p-2 my-2" style = "width: 130px;font-weight: 600;">SHIPPED FROM</span>
                                <span style = "font-weight: 600;">{{salesData.customerName ? salesData.customerName : "NA"}}</span>
                                <span>{{ shippingAddress ? shippingAddress : "NA"}}</span>
                              </div>
                            </div>
                          </div>
                          <!-- <div class=" row  borderbottom2 table-responsive scrollbar fs--1">
                              <table class="table table-striped  tablePrint" style="font-size: 11px!important;" >
                                  <thead class="inner-model-table-heading">
                                      <tr class="text-white dark__bg-1000">
                                          <th class="borderedTopNone">#</th>
                                          <th class="borderedTopNone">Item Name</th>
                                          <th class="borderedTopNone">HSN/ SAC</th>
                                          <th class="borderedTopNone">Quantity</th>
                                          <th class="borderedTopNone">Unit</th>
                                          <th class="borderedTopNone">Price/ Unit</th>
                                          <th class="borderedTopNone">Taxable amount</th>
                                          <th *ngIf="gstStateMatch"  class="borderedTopNone">CGST</th>
                                          <th  *ngIf="gstStateMatch" class="borderedTopNone">SGST</th>
                                          <th *ngIf="!gstStateMatch"  class="borderedTopNone">IGST</th>
                                          <th class="borderedTopNone">Support </th>
                                          <th class="borderedTopNone">Amount</th>
                                      </tr>
                                  </thead>
                                  <tbody class="table-body">
                                      <tr *ngFor="let i of saleProductData; let index = index">
                                          <td class="bordered">{{ index+1 }}</td>
                                          <td class="bordered text-capitalize">{{i.itemName}}</td>
                                          <td class="bordered">{{i.barcode}}</td>
                                          <td class="bordered">{{i.quantity}}</td>
                                          <td class="bordered">{{i.unit}}</td>
                                          <td class="bordered">{{i.pricePerUnit| currency:'INR':'symbol':'1.2-2'}}</td>
                                          <td class="bordered"> {{i.totalPrice - billGstAmount | currency:'INR':'symbol':'1.2-2'}}</td>
                                          <td *ngIf="gstStateMatch"  class="bordered"> {{ (billGstAmount / 2) | currency:'INR':'symbol':'1.2-2'}}</td>
                                          <td   *ngIf="gstStateMatch" class="bordered"> {{ (billGstAmount / 2) | currency:'INR':'symbol':'1.2-2'}}</td>
                                          <td  *ngIf="!gstStateMatch"  class="bordered">{{billGstAmount/2 + billGstAmount/2| currency:'INR':'symbol':'1.2-2'}}</td>
                                          <td class="bordered">{{i.supportType}}-{{i.supportDuration}} Months</td>
                                          <td class="bordered">{{i.finalAmount}}</td>

                                      </tr>
                                      <tr >
                                          <td  class="bordered"></td>
                                          <td  class="bordered">Total</td>
                                          <td  class="bordered"></td>
                                          <td  class="bordered"></td>
                                          <td  class="bordered"></td>
                                          <td  class="bordered"></td>
                                          <td  class="bordered"></td>
                                          <td *ngIf="gstStateMatch"  class="bordered"></td>
                                          <td *ngIf="gstStateMatch"  class="bordered"></td>
                                          <td  *ngIf="!gstStateMatch"  class="bordered"></td>
                                          <td  class="bordered"></td>
                                          <td  class="bordered">{{salesData.totalBillAmount}}</td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div> -->


                          <div class=" row table-responsive scrollbar fs--1" style = "padding: 0px 10px;">
                            <table class="table  tablePrint" style="font-size: 14px !important;">
                                <thead class="inner-model-table-heading printHead">
                                    <tr class="text-white dark__bg-1000">
                                        <th class="borderedTopNone" style="text-align: left;font-size: 14px !important;">#</th>
                                        <th class="borderedTopNone" style="text-align: left;font-size: 14px !important;width:20rem">ITEMS</th>
                                        <th class="borderedTopNone" style="text-align: right;font-size: 14px !important;">HSN</th>
                                        <th class="borderedTopNone" style="text-align: right;font-size: 14px !important;">QTY.</th><th class="borderedTopNone" style = "text-align: right;">RATE</th>
                                        <th class="borderedTopNone" style = "text-align: right;font-size: 14px !important;">TAX</th>
                                        <th class="borderedTopNone" style = "text-align: right;font-size: 14px !important;">AMOUNT</th>
                                    </tr>
                                </thead>
                                <tbody class = "printBody" style = "max-height: 200px;">
                                    <tr *ngFor="let i of saleProductData; let index = index">
                                        <td class="bordered" style = "text-align: left;font-size: 14px !important;">{{ index+1 }}</td>
                                        <td class="bordered">
                                          <span style = "text-transform: uppercase;font-size: 14px !important;">{{i.itemName}}</span>
                                        </td>
                                        <td class="bordered" style = "text-align: right;font-size: 14px !important;">{{hsn}}</td>
                                        <td class="bordered" style = "text-align: right;white-space: nowrap;font-size: 14px !important;">{{i.quantity}} {{i.unit.substr(i.unit.length - 5)}}</td>
                                        <td class="bordered" style = "text-align: right;font-size: 14px !important;">
                                          <!-- {{ (i.quantity * (i.pricePerUnit / (1 + (i.gst/100)))) | currency:'INR':'symbol':'1.2-2'}} -->
                                          ₹&nbsp;{{ (i.quantity * (i.pricePerUnit / (1 + (i.gst/100)))) | number:'1.2-2'}}
                                        </td>
                                        <td class="bordered" style = "display: flex;flex-direction:column;align-items:flex-end;font-size: 14px !important;">
                                          <!-- <span>{{ (i.gstAmount/2 + i.gstAmount/2)| currency:'INR':'symbol':'1.2-2'}}</span> -->
                                          <span>₹&nbsp;{{ (i.gstAmount/2 + i.gstAmount/2) | number:'1.2-2'}}</span>
                                          <span style = "font-size: 12px;">({{ i.gst}}%)</span>
                                        </td>
                                        <td class="bordered" style = "text-align: right;font-size: 14px !important;">
                                          <!-- {{i.finalAmount | currency:'INR':'symbol':'1.2-2'}} -->
                                          ₹&nbsp;{{ i.finalAmount | number:'1.2-2'}}
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot class="inner-model-table-heading printFoot">
                                    <tr>
                                        <td class="bordered"></td>
                                        <th class="bordered" style = "text-align: left;font-size: 14px !important;">SUBTOTAL</th>
                                        <td class="bordered"></td>
                                        <td class="bordered"></td>
                                        <td class="bordered"></td>
                                        <td class="bordered" style = "text-align: right;font-weight: 600;font-size: 14px !important;">
                                          <!-- {{salesData.billGstAmount | currency:'INR':'symbol':'1.2-2'}} -->
                                          ₹&nbsp;{{ salesData.billGstAmount | number:'1.2-2'}}
                                        </td>
                                        <td class="bordered" style = "text-align: right;font-weight: 600;font-size: 14px !important;">
                                          <!-- {{salesData.totalBillAmount | currency:'INR':'symbol':'1.2-2'}} -->
                                          ₹&nbsp;{{ salesData.totalBillAmount | number:'1.2-2'}}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                          </div>

                          <div class = "row my-2">
                            <div class="col-6" style = "display: flex;flex-direction: column;justify-content: center;">
                            </div>
                            <div class="col-6">
                              <div class="row headerAlignment mt-2">
                                <div class="col-6">
                                  TAXABLE AMOUNT
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                    <!-- {{totalBillAmount - billGstAmount | currency:'INR':'symbol':'1.2-2'}} -->
                                    ₹&nbsp;{{ totalBillAmount - billGstAmount | number:'1.2-2'}}
                                </div>
                              </div>
                              <div class="row headerAlignment mt-1" *ngIf="gstStateMatch">
                                <div class="col-6">
                                  CGST
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                  <!-- {{salesData.billGstAmount/2 | currency:'INR':'symbol':'1.2-2'}} -->
                                  ₹&nbsp;{{ salesData.billGstAmount/2 | number:'1.2-2'}}
                                </div>
                              </div>
                              <div class="row headerAlignment mt-1" *ngIf="gstStateMatch">
                                <div class="col-6">
                                  SGST
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                  <!-- {{salesData.billGstAmount/2 | currency:'INR':'symbol':'1.2-2'}} -->
                                  ₹&nbsp;{{ salesData.billGstAmount/2 | number:'1.2-2'}}
                                </div>
                              </div>
                              <div class="row headerAlignment mt-1" *ngIf="!gstStateMatch">
                                <div class="col-6">
                                  IGST
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                  <!-- {{ (salesData.billGstAmount/2 + salesData.billGstAmount/2) | currency:'INR':'symbol':'1.2-2'}} -->
                                  ₹&nbsp;{{ (salesData.billGstAmount/2 + salesData.billGstAmount/2) | number:'1.2-2'}}
                                </div>
                              </div>
                              <hr style = "border: 1px solid grey;" />
                                <div class="row headerAlignment">
                                  <div class="col-6"  style = "font-weight: 600;">
                                    TOTAL AMOUNT
                                  </div>
                                  <div class="col-6" style = "text-align: end;font-weight: 600;">
                                    <!-- {{salesData.totalBillAmount | currency:'INR':'symbol':'1.2-2'}} -->
                                    ₹&nbsp;{{ salesData.totalBillAmount | number:'1.2-2'}}
                                  </div>
                                </div>
                              <hr style = "border: 1px solid grey;" />
                              <div class="row headerAlignment mt-1">
                                <div class="col-6">
                                  Paid Amount
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                    <!-- {{ salesData.received| currency:'INR':'symbol':'1.2-2'}} -->
                                    ₹&nbsp;{{ salesData.received | number:'1.2-2'}}
                                </div>
                              </div>
                              <div class="row headerAlignment mt-1">
                                <div class="col-6" style = "font-weight: 600;">
                                  Balance
                                </div>
                                <div class="col-6" style = "text-align: end;font-weight: 600;">
                                    <!-- {{ salesData.balance | currency:'INR':'symbol':'1.2-2'}} -->
                                    ₹&nbsp;{{ salesData.balance | number:'1.2-2'}}
                                </div>
                              </div>
                              <div class="row headerAlignment mt-3">
                                <div style = "text-align: end;">
                                  <p class = "mb-0">Total Amounts (in words)</p>
                                  <span>{{ finalAmount }} {{currencySymbol}}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6" style="display: flex;flex-direction: column;justify-content: flex-start;">
                              <span class="mb-1 mt-5" style="font-weight: 600;">TERMS AND CONDITIONS</span>
                              <span #dynamicText [style.fontSize.px]="getTextSizeprint(TextSizeprint)" style = "margin-bottom: 4px;">{{invoiceTermsCon ? invoiceTermsCon :'NA'}}</span>
                            </div>
                            <div class="col-md-6 col-lg-6 col-sm-12" style="display: flex;flex-direction: column;justify-content: flex-end;">
                              <div class="image-wrapper">
                                  <label class="signature-img" ondragover="return false;">
                                      <img *ngIf="signImg" [src]="signImg" />
                                  </label>
                              </div>
                          </div>
                        </div>

                          <!-- <div class="row ">
                              <div class="col-6  borderbottom2 borderLeft">
                                  <div class="table-responsive scrollbar fs--1">
                                      <table class="table table-striped tablePrint" style="font-size: 11px!important;">
                                          <thead class="inner-model-table-heading">
                                              <tr class="text-white dark__bg-1000" >
                                                  <th class=""> Tax Type</th>
                                                  <th class="">Taxable Amount</th>
                                                  <th class="">Rate</th>
                                                  <th class="">Tax Amount</th>
                                              </tr>
                                          </thead>
                                          <tbody class="table-body">
                                              <tr *ngFor="let i of saleProductData; let index = index">
                                                  <td class="align-middle text-center">
                                                      {{salesData.supplyType}}
                                                  </td>
                                                  <td class="align-middle text-center">
                                                      {{i.totalPrice - billGstAmount| currency:'INR':'symbol':'1.2-2'}}
                                                  </td>
                                                  <td class="align-middle text-center">
                                                      {{i.gst}}
                                                  </td>
                                                  <td class="align-middle text-center">
                                                      {{totalGST |currency:'INR':'symbol':'1.2-2' }}
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </div>

                              </div>
                              <div class="col-6">
                                      <table class="table tablePrint" style="font-size: 11px!important;">
                                        <tr class = "inner-model-table-heading">
                                          <th class="thWidth borderLeft p-3" style="text-align:left!important">Amounts:</th>
                                          <th class="borderLeft borderbottom2 borderRIGHT p-3" style="width: 50px;" style="text-align:right!important">In Rs:</th>
                                        </tr>
                                        <tr>
                                          <td class="thWidth bordered p-2" style="text-align:left!important">Total Base Price</td>
                                          <td class="borderedLeftNone p-2" style="width: 50px;" style="text-align:right!important" *ngFor="let i of saleProductData; let index = index" >{{totalBillAmount -  billGstAmount|currency:'INR':'symbol':'1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                          <td class="thWidth bordered p-2 " style="text-align:left!important">Total</td>
                                          <td class=" borderedLeftNone p-2" style="width:50px;" style="text-align:right!important">
                                              {{ totalBillAmount | currency:'INR':'symbol':'1.2-2'}}
                                          </td>
                                        </tr>
                                        <tr >
                                          <td class="thWidth bordered p-2" style="text-align:left!important">Received</td>
                                          <td class="borderedLeftNone p-2" style="width: 50px;" style="text-align:right!important">
                                              {{ salesData.received| currency:'INR':'symbol':'1.2-2'}}
                                          </td>
                                        </tr>

                                        <tr class="borderbottom2">
                                          <td class="thWidth bordered p-2" style="text-align:left!important">Balance</td>
                                          <td class="bordered p-2" style="width: 50px;" style="text-align:right!important">
                                              {{  salesData.balance| currency:'INR':'symbol':'1.2-2'}}
                                          </td>
                                        </tr>

                                      </table>

                              </div>
                          </div>
                          <div class="row borderLeft" style="font-size: 11px!important;">
                              <div class="col-6 ">
                                  <p style="background: #e4e3e6; width: 100%!important;" class="inner-model-table-heading p-0 text-black"><b>Invoice Amount In Words</b></p>
                                  <p style="padding-left: 5px !important;">{{ finalAmount }}
                                  </p>
                              </div>
                              <div class="col-6 borderLeft borderRIGHT">
                                  <p class="p-1"></p>
                                  <p>
                                  </p>
                              </div>
                          </div>
                          <div class="row borderedBottom" style="font-size: 11px!important;">
                              <div class="col-6 ">
                                  <p style="background: #e4e3e6;" class="inner-model-table-heading p-0 text-black"><b>Terms and conditions:</b>
                                  </p>
                                  <p class="pb-2"  style="padding-left: 5px !important;">Thanks for doing business with us.
                                  </p>
                              </div>
                              <div class="col-6 borderLeft">
                                  <p class="text-center pb-2"><b>{{BusinessName}}</b></p>
                                  <p class="text-center pt-3">Authorized Signatory</p>
                              </div>
                          </div> -->
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- UAE -->

<div class="modal fade" #closebutton id="invoice-modal2" tabindex="-1" role="dialog" aria-hidden="true" *ngIf="isArabic">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content position-relative">
          <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
              <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal" (click)="closeModal()"></button>
          </div>
          <div class="modal-body p-0">
              <div class="rounded-top-lg py-3 ps-4 pe-6">

              </div>
              <div class="container" id = "panel">
                  <div class="card" >
                      <div class="card-body">
                          <div class="row my-2" id="panel">

                            <div class="col-6 pt-2">
                              <p style = "color: red;font-weight: 600;">{{tradeName ? tradeName : "NA"}}</p>
                              <p>Mobile:&nbsp;{{ mobile ? mobile : 'NA'}}</p>
                            </div>
                            <div class="col-6">
                              <div class="row headerAlignment">
                                <div class="col-6" style = "font-weight: 600;">
                                  TAX INVOICE
                                </div>
                                <div class="col-6">
                                  <button style = "float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;font-weight: 600;" id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0"
                                    type="button" (click)="onPrint()"><span class="fas fa-print me-1">
                                    </span>Print
                                  </button>
                                  <div id = "originalForReceiptPrint">
                                    ORIGINAL FOR RECIPIENT
                                  </div>
                                </div>
                              </div>
                              <div class="row headerAlignment">
                                <div class="col-6">
                                  Invoice #<span style = "float: right;">:</span>
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                  {{invoiceNo ? invoiceNo : "NA"}}
                                </div>
                              </div>
                              <div class="row headerAlignment">
                                <div class="col-6">
                                  Invoice Date<span style = "float: right;">:</span>
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                  {{invoiceDate | date:"dd MMM YYYY" | uppercase }}
                                </div>
                              </div>
                              <div class="row headerAlignment">
                                <div class="col-6">
                                  Due Date<span style = "float: right;">:</span>
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                  {{ dueDate ? dueDate : 'NA' }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class = "row mt-2 mb-4">
                            <div class="col-6">
                              <div style="display: flex;flex-direction: column;justify-content: flex-start;">
                                <span class = "inner-model-table-heading p-2 my-2" style = "width: 130px;font-weight: 600;">BILLED FROM</span>
                                <span style = "font-weight: 600;">{{salesData.customerName ? salesData.customerName : "NA" }}</span>
                                <span>{{ billingAddress ? billingAddress : "NA"}}</span>
                                <span>Mobile: &nbsp;{{ mobile ? mobile : "NA" }}</span>
                                <span>{{lang.GSTIN}}: &nbsp;{{gstinNo ? gstinNo : "NA"}}</span>
                                <!-- <span>State: &nbsp;{{ state ? state : "NA" }}</span> -->
                              </div>
                            </div>
                            <div class="col-6">
                              <div style="display: flex;flex-direction: column;justify-content: flex-start;">
                                <span class = "inner-model-table-heading p-2 my-2" style = "width: 130px;font-weight: 600;">SHIPPED FROM</span>
                                <span style = "font-weight: 600;">{{salesData.customerName ? salesData.customerName : "NA"}}</span>
                                <span>{{ shippingAddress ? shippingAddress : "NA"}}</span>
                              </div>
                            </div>
                          </div>

                          <div class=" row table-responsive scrollbar fs--1" style = "padding: 0px 10px;">
                            <table class="table  tablePrint" style="font-size: 14px !important;">
                                <thead class="inner-model-table-heading printHead">
                                    <tr class="text-white dark__bg-1000">
                                        <th class="borderedTopNone" style="text-align: left;font-size: 14px !important;">#</th>
                                        <th class="borderedTopNone" style="text-align: left;font-size: 14px !important;width:20rem">ITEMS</th>
                                        <!-- <th class="borderedTopNone" style="text-align: right;font-size: 14px !important;">HSN</th> -->
                                        <th class="borderedTopNone" style="text-align: right;font-size: 14px !important;">QTY.</th><th class="borderedTopNone" style = "text-align: right;">RATE</th>
                                        <th class="borderedTopNone" style = "text-align: right;font-size: 14px !important;">TAX</th>
                                        <th class="borderedTopNone" style = "text-align: right;font-size: 14px !important;">AMOUNT</th>
                                    </tr>
                                </thead>
                                <tbody class = "printBody" style = "max-height: 200px;">
                                    <tr *ngFor="let i of saleProductData; let index = index">
                                        <td class="bordered" style = "text-align: left;font-size: 14px !important;">{{ index+1 }}</td>
                                        <td class="bordered">
                                          <span style = "text-transform: uppercase;font-size: 14px !important;">{{i.itemName}}</span>
                                        </td>
                                        <!-- <td class="bordered" style = "text-align: right;font-size: 14px !important;">{{hsn}}</td> -->
                                        <td class="bordered" style = "text-align: right;white-space: nowrap;font-size: 14px !important;">{{i.quantity}} {{i.unit.substr(i.unit.length - 5)}}</td>
                                        <td class="bordered" style = "text-align: right;font-size: 14px !important;">
                                          AED&nbsp;{{ (i.quantity * (i.pricePerUnit / (1 + (i.gst/100)))) | number:'1.2-2'}}
                                        </td>
                                        <td class="bordered" style = "display: flex;flex-direction:column;align-items:flex-end;font-size: 14px !important;">
                                          <span>AED&nbsp;{{ (i.gstAmount/2 + i.gstAmount/2) | number:'1.2-2'}}</span>
                                          <span style = "font-size: 12px;">({{ i.gst}}%)</span>
                                        </td>
                                        <td class="bordered" style = "text-align: right;font-size: 14px !important;">
                                          <!-- {{i.finalAmount | currency:'INR':'symbol':'1.2-2'}} -->
                                          AED&nbsp;{{ i.finalAmount | number:'1.2-2'}}
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot class="inner-model-table-heading printFoot">
                                    <tr>
                                        <td class="bordered"></td>
                                        <th class="bordered" style = "text-align: left;font-size: 14px !important;">SUBTOTAL</th>
                                        <td class="bordered"></td>
                                        <td class="bordered"></td>
                                        <!-- <td class="bordered"></td> -->
                                        <td class="bordered" style = "text-align: right;font-weight: 600;font-size: 14px !important;">
                                          AED&nbsp;{{ salesData.billGstAmount | number:'1.2-2'}}
                                        </td>
                                        <td class="bordered" style = "text-align: right;font-weight: 600;font-size: 14px !important;">
                                          AED&nbsp;{{ salesData.totalBillAmount | number:'1.2-2'}}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                          </div>

                          <div class = "row my-2">
                            <div class="col-6" style = "display: flex;flex-direction: column;justify-content: center;">
                            </div>
                            <div class="col-6">
                              <div class="row headerAlignment mt-2">
                                <div class="col-6">
                                  TAXABLE AMOUNT
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                    AED&nbsp;{{ totalBillAmount - billGstAmount | number:'1.2-2'}}
                                </div>
                              </div>
                              <div class="row headerAlignment mt-1">
                                <div class="col-6">
                                  {{lang.CGST}}
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                  AED&nbsp;{{ salesData.billGstAmount | number:'1.2-2'}}
                                </div>
                              </div>
                              <!-- <div class="row headerAlignment mt-1">
                                <div class="col-6">
                                  SGST
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                  ₹&nbsp;{{ salesData.billGstAmount/2 | number:'1.2-2'}}
                                </div>
                              </div> -->
                              <!-- <div class="row headerAlignment mt-1" *ngIf="!gstStateMatch">
                                <div class="col-6">
                                  IGST
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                  ₹&nbsp;{{ (salesData.billGstAmount/2 + salesData.billGstAmount/2) | number:'1.2-2'}}
                                </div>
                              </div> -->
                              <hr style = "border: 1px solid grey;" />
                                <div class="row headerAlignment">
                                  <div class="col-6"  style = "font-weight: 600;">
                                    TOTAL AMOUNT
                                  </div>
                                  <div class="col-6" style = "text-align: end;font-weight: 600;">
                                    AED&nbsp;{{ salesData.totalBillAmount | number:'1.2-2'}}
                                  </div>
                                </div>
                              <hr style = "border: 1px solid grey;" />
                              <div class="row headerAlignment mt-1">
                                <div class="col-6">
                                  Paid Amount
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                    <!-- {{ salesData.received| currency:'INR':'symbol':'1.2-2'}} -->
                                    AED&nbsp;{{ salesData.received | number:'1.2-2'}}
                                </div>
                              </div>
                              <div class="row headerAlignment mt-1">
                                <div class="col-6" style = "font-weight: 600;">
                                  Balance
                                </div>
                                <div class="col-6" style = "text-align: end;font-weight: 600;">
                                    <!-- {{ salesData.balance | currency:'INR':'symbol':'1.2-2'}} -->
                                    AED&nbsp;{{ salesData.balance | number:'1.2-2'}}
                                </div>
                              </div>
                              <div class="row headerAlignment mt-3">
                                <div style = "text-align: end;">
                                  <p class = "mb-0">Total Amounts (in words)</p>
                                  <span>{{ finalAmount | titlecase }} {{currencySymbol}}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6" style="display: flex;flex-direction: column;justify-content: flex-start;">
                              <span class="mb-1 mt-5" style="font-weight: 600;">TERMS AND CONDITIONS</span>
                              <span #dynamicText [style.fontSize.px]="getTextSizeprint(TextSizeprint)" style = "margin-bottom: 4px;">{{invoiceTermsCon ? invoiceTermsCon :'NA'}}</span>
                            </div>
                            <div class="col-md-6 col-lg-6 col-sm-12" style="display: flex;flex-direction: column;justify-content: flex-end;">
                              <div class="image-wrapper">
                                  <label class="signature-img" ondragover="return false;">
                                      <img *ngIf="signImg" [src]="signImg" />
                                  </label>
                              </div>
                          </div>
                        </div>

                          <!-- <div class="row ">
                              <div class="col-6  borderbottom2 borderLeft">
                                  <div class="table-responsive scrollbar fs--1">
                                      <table class="table table-striped tablePrint" style="font-size: 11px!important;">
                                          <thead class="inner-model-table-heading">
                                              <tr class="text-white dark__bg-1000" >
                                                  <th class=""> Tax Type</th>
                                                  <th class="">Taxable Amount</th>
                                                  <th class="">Rate</th>
                                                  <th class="">Tax Amount</th>
                                              </tr>
                                          </thead>
                                          <tbody class="table-body">
                                              <tr *ngFor="let i of saleProductData; let index = index">
                                                  <td class="align-middle text-center">
                                                      {{salesData.supplyType}}
                                                  </td>
                                                  <td class="align-middle text-center">
                                                      {{i.totalPrice - billGstAmount| currency:'INR':'symbol':'1.2-2'}}
                                                  </td>
                                                  <td class="align-middle text-center">
                                                      {{i.gst}}
                                                  </td>
                                                  <td class="align-middle text-center">
                                                      {{totalGST |currency:'INR':'symbol':'1.2-2' }}
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </div>

                              </div>
                              <div class="col-6">
                                      <table class="table tablePrint" style="font-size: 11px!important;">
                                        <tr class = "inner-model-table-heading">
                                          <th class="thWidth borderLeft p-3" style="text-align:left!important">Amounts:</th>
                                          <th class="borderLeft borderbottom2 borderRIGHT p-3" style="width: 50px;" style="text-align:right!important">In Rs:</th>
                                        </tr>
                                        <tr>
                                          <td class="thWidth bordered p-2" style="text-align:left!important">Total Base Price</td>
                                          <td class="borderedLeftNone p-2" style="width: 50px;" style="text-align:right!important" *ngFor="let i of saleProductData; let index = index" >{{totalBillAmount -  billGstAmount|currency:'INR':'symbol':'1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                          <td class="thWidth bordered p-2 " style="text-align:left!important">Total</td>
                                          <td class=" borderedLeftNone p-2" style="width:50px;" style="text-align:right!important">
                                              {{ totalBillAmount | currency:'INR':'symbol':'1.2-2'}}
                                          </td>
                                        </tr>
                                        <tr >
                                          <td class="thWidth bordered p-2" style="text-align:left!important">Received</td>
                                          <td class="borderedLeftNone p-2" style="width: 50px;" style="text-align:right!important">
                                              {{ salesData.received| currency:'INR':'symbol':'1.2-2'}}
                                          </td>
                                        </tr>

                                        <tr class="borderbottom2">
                                          <td class="thWidth bordered p-2" style="text-align:left!important">Balance</td>
                                          <td class="bordered p-2" style="width: 50px;" style="text-align:right!important">
                                              {{  salesData.balance| currency:'INR':'symbol':'1.2-2'}}
                                          </td>
                                        </tr>

                                      </table>

                              </div>
                          </div>
                          <div class="row borderLeft" style="font-size: 11px!important;">
                              <div class="col-6 ">
                                  <p style="background: #e4e3e6; width: 100%!important;" class="inner-model-table-heading p-0 text-black"><b>Invoice Amount In Words</b></p>
                                  <p style="padding-left: 5px !important;">{{ finalAmount }}
                                  </p>
                              </div>
                              <div class="col-6 borderLeft borderRIGHT">
                                  <p class="p-1"></p>
                                  <p>
                                  </p>
                              </div>
                          </div>
                          <div class="row borderedBottom" style="font-size: 11px!important;">
                              <div class="col-6 ">
                                  <p style="background: #e4e3e6;" class="inner-model-table-heading p-0 text-black"><b>Terms and conditions:</b>
                                  </p>
                                  <p class="pb-2"  style="padding-left: 5px !important;">Thanks for doing business with us.
                                  </p>
                              </div>
                              <div class="col-6 borderLeft">
                                  <p class="text-center pb-2"><b>{{BusinessName}}</b></p>
                                  <p class="text-center pt-3">Authorized Signatory</p>
                              </div>
                          </div> -->
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>




























<!-- <div class="card mb-3">
    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
            Purchase Invoice List
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>
    <div class="card-body p-0 overflow-hidden ">
        <div class="row">
            <div class="col-lg-3 col-sm-12 col-md-3 col-xl-3" style="margin-left: 21px;margin-top: 10px; margin-bottom: -13px;font-size: 12px">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Search</mat-label>
                    <input type="text" matInput placeholder="Search" [(ngModel)]="searchKey"
                        (change)="getAllPurchaseinvoicebySearch(searchKey)">
                    <span>
                        <mat-icon class="iconcalender p-1">search</mat-icon>
                    </span>
                </mat-form-field>
            </div>
        </div>

        <div class="table-responsive m-2">
            <table class="table table-striped table-bordered mt-2 bg-white">
                <thead>
                    <tr class="btn-reveal-trigger">
                        <th scope="col">#</th>
                        <th scope="col">Invoice No.</th>
                        <th scope="col">Shipping State</th>
                        <th scope="col">GST Amount</th>
                        <th scope="col">Total Bill</th>
                        <th scope="col" class="text-center">Print</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let item of invoiceData; let i = index">
                        <td scope="row">{{ i+1 }}</td>
                        <td>{{ item.invoiceNo}}</td>
                        <td>{{ item.shippingAddress | titlecase}}</td>
                        <td>{{ item.billGstAmount | currency:'INR':'symbol':'1.2-2' }}</td>
                        <td>{{ item.totalBillAmount | currency:'INR'}}</td>
                        <td>
                            <button class="btn btnp" type="button"  data-bs-toggle="modal"
                                data-bs-target="#invoice-modal2" (click)="getReceiptById(item.id)">
                                <span class="bi-printer icon-color fs-1" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="print"></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="invoiceData == 0">
                    <tr class="text-center">
                        <td colspan="5">
                            <h5 class="text-nowrap">No Purchase Invoice Details Added. Please Add Purchase Invoice
                                Details To Be Displayed Here. <span>
                                    <a class="nav-link" routerLink="/pages/purchase-invoice">Add New Purchase Invoice
                                    </a>
                                </span></h5>
                        </td>
                    </tr>
                </tbody>
            </table>
            <mat-paginator class="float-right mt-2" *ngIf="invoiceData.length> 0"
                [pageSize]="purchaseListPageSize" [pageSizeOptions]="[5, 10, 20,30]"
                [length]="totalrow" [pageIndex]="currentPageNo" (page)="handlePage($event)"
             aria-label="Select page">
            </mat-paginator>
        </div>
    </div>

</div> -->

<!-- Print Modal -->
<!-- <div class="modal fade" #closebutton id="invoice-modal2" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal" (click)="closeModal()"></button>
            </div>
            <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-md">
                        </div>
                        <div class="col-auto">
                            <button id="printPageButton" class="btn btn-falcon-default btn-sm me-1 mb-2 mb-sm-0" type="button"
                                (click)="onPrint()"><span class="fas fa-print me-1">
                                </span>Print</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4"></div>
                        <div class="col-4">
                            <h3 class="">Purchase Invoice</h3>
                        </div>
                        <div class="col-4">
                            <div style="font-size: 12px" class="text-end">
                                Original for Recipient
                                <mat-checkbox class="">
                                </mat-checkbox>
                            </div>
                            <div style="font-size: 12px" class="text-end">
                                Duplicate for Transporator
                                <mat-checkbox class="">
                                </mat-checkbox>
                            </div>
                            <div style="font-size: 12px" class="text-end">
                                Triplicator for Supplier
                                <mat-checkbox class="">
                                </mat-checkbox>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="card" >
                        <div class="card-body">
                            <div class="row bordered" id="panel">
                                <div class="col-sm-6  ">
                                    <img [src]="imageSrc ? imageSrc : '../../assets/img/logos/logo-invoice.png'"
                                        (load)="handleImageLoad()" class="rounded-circle img-thumbnail shadow-sm"
                                      style="width:100px!important;height:100px!important;" />

                                </div>
                                <div class="col-sm-6  text-sm-end mt-1 mt-sm-0 justify-content-end ">
                                    <h5 class="fw-bold"  style="margin-right: 10px !important;">{{tradeName}}</h5>
                                    <p class="fs--1 mb-0 "  style="margin-right: 10px !important;">{{addressDetails.homeno}} {{addressDetails.address}} {{addressDetails.landmark}}<br />{{addressDetails.state}} {{addressDetails.country}} <br /> {{addressDetails.pincode}}</p>

                                    -->
                                    <!-- <h5>Falcon Design Studio</h5>
                                    <p class="fs--1 mb-0">156 University Ave, Toronto<br />On, Canada, M5H 2H7</p> -->
                                <!-- </div>
                                <div class="col-12">
                                </div>
                            </div>
                            <div class="row bordered table-responsive scrollbar fs--1 borderbottom2 ">
                                <table class="table table-striped tablePrint" style="font-size: 11px!important;">
                                    <thead class="light">
                                        <tr class="bg-primary text-white dark__bg-1000" >
                                            <th class="borderbottom2" style="width: 33% !important;">Bill To</th>
                                            <th class="borderLeft borderbottom2 " style="width: 33% !important;">Ship To</th>
                                            <th class="borderLeft borderbottom2" style="width: 33% !important;"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="align-middle" style="width: 33% !important;">
                                                <p>{{salesData.customerName}}</p>
                                                <p>{{salesData.billingAddress}}</p>
                                            </td>
                                            <td class="align-middle text-center borderLeft" style="width: 33% !important;">
                                                {{salesData.shippingAddress}}

                                            </td>
                                            <td class="align-middle  borderLeft " style="width: 33% !important;text-align: left !important;">
                                               <p> Place of Supply: {{salesData.shippingAddress}}</p>
                                               <p>Invoice No.:{{salesData.invoiceNo}}</p>
                                               <p> Date:{{salesData.invoiceDate| date:"dd MMM YYYY"| uppercase}}</p>
                                               <p *ngIf="salesData.ewayBillNo">E-way Bill number:{{salesData.ewayBillNo}}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class=" row  borderbottom2 table-responsive scrollbar fs--1">
                                <table class="table table-striped  tablePrint" style="font-size: 11px!important;" >
                                    <thead class="light">
                                        <tr class="bg-primary text-white dark__bg-1000">
                                            <th class="borderedTopNone">#</th>
                                            <th class="borderedTopNone">Item Name</th>
                                            <th class="borderedTopNone">HSN/ SAC</th>
                                            <th class="borderedTopNone">Quantity</th>
                                            <th class="borderedTopNone">Unit</th>
                                            <th class="borderedTopNone">Price/ Unit</th>
                                            <th class="borderedTopNone">Taxable amount</th>
                                            <th *ngIf="gstStateMatch"  class="borderedTopNone">CGST</th>
                                            <th  *ngIf="gstStateMatch" class="borderedTopNone">SGST</th>
                                            <th *ngIf="!gstStateMatch"  class="borderedTopNone">IGST</th>
                                            <th class="borderedTopNone">Support </th>
                                            <th class="borderedTopNone">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let i of saleProductData; let index = index">
                                            <td class="bordered">{{ index+1 }}</td>
                                            <td class="bordered text-capitalize">{{i.itemName}}</td>
                                            <td class="bordered">{{i.barcode}}</td>
                                            <td class="bordered">{{i.quantity}}</td>
                                            <td class="bordered">{{i.unit}}</td>
                                            <td class="bordered">{{i.pricePerUnit| currency:'INR':'symbol':'1.2-2'}}</td>
                                            <td class="bordered"> {{i.totalPrice - billGstAmount | currency:'INR':'symbol':'1.2-2'}}</td>
                                            <td *ngIf="gstStateMatch"  class="bordered"> {{ (billGstAmount / 2) | currency:'INR':'symbol':'1.2-2'}}</td>
                                            <td   *ngIf="gstStateMatch" class="bordered"> {{ (billGstAmount / 2) | currency:'INR':'symbol':'1.2-2'}}</td>
                                            <td  *ngIf="!gstStateMatch"  class="bordered">{{billGstAmount/2 + billGstAmount/2| currency:'INR':'symbol':'1.2-2'}}</td>
                                            <td class="bordered">{{i.supportType}}-{{i.supportDuration}} Months</td>
                                            <td class="bordered">{{i.finalAmount}}</td>

                                        </tr>
                                        <tr >
                                            <td  class="bordered"></td>
                                            <td  class="bordered">Total</td>
                                            <td  class="bordered"></td>
                                            <td  class="bordered"></td>
                                            <td  class="bordered"></td>
                                            <td  class="bordered"></td>
                                            <td  class="bordered"></td>
                                            <td *ngIf="gstStateMatch"  class="bordered"></td>
                                            <td *ngIf="gstStateMatch"  class="bordered"></td>
                                            <td  *ngIf="!gstStateMatch"  class="bordered"></td>
                                            <td  class="bordered"></td>
                                            <td  class="bordered">{{salesData.totalBillAmount}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row ">
                                <div class="col-6  borderbottom2 borderLeft">
                                    <div class="table-responsive scrollbar fs--1">
                                        <table class="table table-striped tablePrint" style="font-size: 11px!important;">
                                            <thead class="light">
                                                <tr class="bg-primary text-white dark__bg-1000" >
                                                    <th class=""> Tax Type</th>
                                                    <th class="">Taxable Amount</th>
                                                    <th class="">Rate</th>
                                                    <th class="">Tax Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let i of saleProductData; let index = index">
                                                    <td class="align-middle text-center">
                                                        {{salesData.supplyType}}
                                                    </td>
                                                    <td class="align-middle text-center">
                                                        {{i.totalPrice - billGstAmount| currency:'INR':'symbol':'1.2-2'}}
                                                    </td>
                                                    <td class="align-middle text-center">
                                                        {{i.gst}}
                                                    </td>
                                                    <td class="align-middle text-center">
                                                        {{totalGST |currency:'INR':'symbol':'1.2-2' }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div class="col-6">
                                        <table class="table tablePrint" style="font-size: 11px!important;">
                                          <tr >
                                            <th class="thWidth borderLeft p-3" style="text-align:left!important">Amounts:</th>
                                            <th class="borderLeft borderbottom2 borderRIGHT p-3" style="width: 50px;" style="text-align:right!important">In Rs:</th>
                                          </tr>
                                          <tr>
                                            <td class="thWidth bordered p-2" style="text-align:left!important">Total Base Price</td>
                                            <td class="borderedLeftNone p-2" style="width: 50px;" style="text-align:right!important" *ngFor="let i of saleProductData; let index = index" >{{totalBillAmount -  billGstAmount|currency:'INR':'symbol':'1.2-2'}}</td>
                                          </tr>
                                          <tr>
                                            <td class="thWidth bordered p-2 " style="text-align:left!important">Total</td>
                                            <td class=" borderedLeftNone p-2" style="width:50px;" style="text-align:right!important">
                                                {{ totalBillAmount | currency:'INR':'symbol':'1.2-2'}}
                                            </td>
                                          </tr>
                                          <tr >
                                            <td class="thWidth bordered p-2" style="text-align:left!important">Received</td>
                                            <td class="borderedLeftNone p-2" style="width: 50px;" style="text-align:right!important">
                                                {{ salesData.received| currency:'INR':'symbol':'1.2-2'}}
                                            </td>
                                          </tr>

                                          <tr class="borderbottom2">
                                            <td class="thWidth bordered p-2" style="text-align:left!important">Balance</td>
                                            <td class="bordered p-2" style="width: 50px;" style="text-align:right!important">
                                                {{  salesData.balance| currency:'INR':'symbol':'1.2-2'}}
                                            </td>
                                          </tr>

                                        </table>

                                </div>
                            </div>
                            <div class="row borderLeft" style="font-size: 11px!important;">
                                <div class="col-6 ">
                                    <p style="background-color: #5CABFA !important; width: 100%!important;" class="p-1">Invoice Amount In Words</p>
                                    <p style="padding-left: 5px !important;">{{ finalAmount }}
                                    </p>
                                </div>
                                <div class="col-6 borderLeft borderRIGHT">
                                    <p class="p-1"></p>
                                    <p>
                                    </p>
                                </div>
                            </div>
                            <div class="row borderedBottom" style="font-size: 11px!important;">
                                <div class="col-6 ">
                                    <p style="background-color: #5CABFA !important;" class="p-2">Terms and conditions:
                                    </p>
                                    <p class="pb-2"  style="padding-left: 5px !important;">Thanks for doing business with us.
                                    </p>
                                </div>
                                <div class="col-6 borderLeft">
                                    <p class="text-center pb-2">{{BusinessName}}</p>
                                    <p class="text-center pt-3">Authorized Signatory</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
