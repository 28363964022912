import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { type } from "os";
import { FormControl, FormGroup } from "@angular/forms";

import { ProductService } from "src/app/core/services/product.service";
import { ToastNotificationService } from "src/app/core/services/toast-notification.service";
import { TitleCasePipe } from "@angular/common";
import { ProductServiceUpdateListComponent } from "../product-service-update-list/product-service-update-list.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ServiceaddComponent } from "../serviceadd/serviceadd.component";
import { ServicelistViewComponent } from "../servicelist-view/servicelist-view.component";
import lang from "src/assets/langTranslation/language_translation"
import { CommonService } from "src/app/core/services/common.service";
import { CloneServiceComponent } from "../clone-service/clone-service.component";


@Component({
  selector: 'app-servicelist',
  templateUrl: './servicelist.component.html',
  styleUrls: ['./servicelist.component.scss']
})
export class ServicelistComponent implements OnInit {
  public lang = lang.UAE;
  public isArabic: boolean;
  title="Product List";
  public searchTypeservice = "service";
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public goodsData: any = [];
  @Input() serviceData: any = [];
  public selectType: any = "Product";
  public typeService = "service";
  public selected = "Product";
  public isProductNotFound: boolean;
  public pid: any;
  public productId: any;
  public serviceId: any;
  public searchKey: any;
  public searchType: any = "Product";
  public p = 1;
  public currentpage: any;
  public totalrow: any;
  public totalServiceRow: any;
  public toatlPages: any;
  public excelFileUpload: any;
  public currentPageNo: number = 0;
  public currentServicePageNo: number = 0;
  public partyPageSize: number = 5;
  public servicePageSize:number = 5;
  public pagesize: number = 1;
  public servicePagesize: number = 1;
  public count: number = 0;
  public isDeletedProduct:boolean=false;
  public isDeletedservice:boolean=false;

  public archiveIconfalse: boolean = false;
  public archiveIcontrue: boolean = false;

  //
  public checkSingleArray: any = [];
  public checkSingleArraytrue: any = [];

  public isChecked: boolean = false;
  public upiCheckedId = [];
  public upiCheckedId1 = [];
  public upiCheckedId2 = [];
  public allSelect: boolean = false;
  public checkSelect: boolean = false;

  public checkSelectsinglefalse: boolean = false;
  public checkSelectsingletrue: boolean = false;
  public form: FormGroup;
  public eventchecktrue: boolean = false;
  public eventcheckfalse: boolean = false;

  public selectedPartyIds: any[] = [];
  public checkSelectsingle: boolean = false;
  archivedPartyIds: any[] = [];
  unarchivedPartyIds: any[] = [];
  showDeleteButton: boolean = false;
  showUnarchiveButton: boolean = false;
  partyId: string = "";

  constructor(
    private productService: ProductService,
    public dialog: MatDialog,
    private router: Router,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });
    //this.getGoodsbyUser();
    this.getServiceByUser();
    this.productService.productAdded$.subscribe(() => {
      // Fetch the updated list of users
      this.getServiceByUser();
    });
  }
  getSelectType(e) {
    this.selectType = e;
    if (this.selectType == "Product") {
     // this.getGoodsbyUser();
    } else {
      this.getServiceByUser();
    }
  }

  // getGoodsbyUser() {
  //   this.productService
  //     .getGoodsProductByUser_Type({}, this.currentUser.id, 'Goods',"DESC" , this.partyPageSize, (this.currentPageNo))
  //     .then(
  //       (res) => {
  //         this.serviceData =[]
  //         this.p = res.data.currentPage;
  //         this.goodsData = res.data.pageData;
  //         this.isProductNotFound = false;
  //         this.totalrow = res.data.totalrows;
  //         this.toatlPages = res.data.totalPages
  //       },
  //       (err) => {
  //         if (err.error.status == 404) {
  //           this.goodsData = [];
  //           this.isProductNotFound = true;
  //         }
  //       }
  //     );
  // }

  getServiceByUser() {
    this.productService.getServiceByUser_Type({}, this.currentUser.id, this.typeService,"DESC" ,this.servicePageSize, (this.currentServicePageNo + 1))
      .then(
        (res) => {
          this.serviceData = res.data.pageData;
          this.totalrow = res.data.totalrows;
          this.toatlPages = res.data.totalPages
        },
        (err) => {
          if (err.error.status == 404) {
            
          }
        }
      );
  }

  getGoodsByBarcode(searchKey) {
    if (this.selectType == 'Product') {
      this.searchType = 'Goods';
    } else {
      this.searchType = 'Service';
    }
    this.productService.getGoodsProductByUser_TypeSearch({}, this.currentUser.id, this.searchType,"DESC", searchKey)
      .then((res) => {
        if (this.searchType == 'Goods') {
          this.goodsData = res.data.pageData
        } else {
          this.serviceData = res.data.pageData;
        }
      }
        , (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went To Wrong.",
            });
          }

        }
      );
  }

  getGoodsByBarcodeservice(searchKey) {
    console.log("****",this.searchKey)
    if(this.searchKey == ""){
          this.currentServicePageNo = 0; // Reset the page index on search

      this.productService.getGoodsService_TypeSearch({}, this.currentUser.id, this.searchTypeservice,"DESC", searchKey.trim()).then((res) => {
        this.serviceData = res.data.pageData;
        this.totalServiceRow = res.data.totalrows;
        if (this.currentServicePageNo > 0) {
          if (this.serviceData.length === 0) {
            this.currentServicePageNo = 0;
            this.getGoodsByBarcodeservice(this.searchKey)
          }
        }
           
    })

    }
    else{
      this.currentServicePageNo = 0; // Reset the page index on search

      this.productService.getGoodsService_TypeSearch({}, this.currentUser.id, this.searchTypeservice,"DESC", searchKey.trim()).then((res) => {
        this.serviceData = res.data.pageData;
        this.totalServiceRow = res.data.totalrows;
        if (this.currentServicePageNo > 0) {
          if (this.serviceData.length === 0) {
            this.currentServicePageNo = 0;
            this.getGoodsByBarcodeservice(this.searchKey)
          }
        }
    }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went To Wrong.",
          });
        }

      }
    );

    }


  }

  deleteProductId() {
    if(this.isDeletedProduct== true){
     this.productService.UnarchivedProduct({},this.productId).then((res)=>{
      this.toastService.toastMsg({
        title: "Success",
        content: "Product Unarchived Successfully!!!",
      });
      //this.getGoodsbyUser();
     },(err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went To Wrong.",
        });
      }
    }
  );
    }
    else{
      this.productService.deleteProductById({}, this.productId).then((res) => {
        this.toastService.toastMsg({
          title: "Success",
          content: "Product Archived Successfully!!!",
        });
        //this.getGoodsbyUser();
      }
        , (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went To Wrong.",
            });
          }

        }
      );

    }

  }

  updateProductId(id) {
    this.router.navigate(["/pages/product-update/" + id]);
  }
  CloneProduct(id){
    this.router.navigate(["/pages/product-clone/" + id]);
  }
  deleteServiceId() {
    // this.productService.deleteProductById({}, this.serviceId).then((res) => {
    //   this.toastService.openSnackBar("Service Deleted Successfully!!!");
    //   this.getServiceByUser();
    // }
    //   , (err) => {
    //     if (err.error.expose) {
    //       this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
    //     }
    //     else {
    //       this.toastService.openErrorSnackBar("Something Went Wrong.");
    //     }

    //   });

    if(this.isDeletedservice== true){
      this.productService.UnarchivedProduct({},this.serviceId).then((res)=>{
      //  this.toastService.openSnackBar("Product Unarchived Successfully!!!");
       this.toastService.toastMsg({
        title: "Success",
        content: "Service Unarchived Successfully!!!",
      });
       //this.getGoodsbyUser();
       this.getServiceByUser();
      },(err) => {
       if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
       }
       else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went To Wrong.",
        });
       }
     }
   );
     }
     else{
       this.productService.deleteProductById({}, this.serviceId).then((res) => {
        this.toastService.toastMsg({
          title: "Success",
          content: "Service Archived Successfully!!!",
        });
         //this.getGoodsbyUser();
         this.getServiceByUser();
       }
         , (err) => {
           if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
           }
           else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went To Wrong.",
            });
           }

         }
       );

     }

  }

  updateProductServiceId(productId:string) {
    //this.router.navigate(["/pages/product-service-update-list/" + id]);
    const dialogRef = this.dialog.open(ProductServiceUpdateListComponent, {
      width: '5700px',
      height : '730px',
      data: { productId: productId },
      panelClass: 'custom-dialog-updateservice-class',
      disableClose:true,
      position: {
        right: '0',
      },

    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  CloneService(productId: string) {
    const dialogRef = this.dialog.open(CloneServiceComponent, {
      width: '700px',
      height: '730px',
      data: { productId: productId },
      panelClass: 'custom-dialog-clone-class',
      disableClose: true,
      position: {
        right: '0',
      },

    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  viewService(productId:string) {
    //this.router.navigate(["/pages/product-service-update-list/" + id]);
    const dialogRef = this.dialog.open(ServicelistViewComponent, {
      width: '700px',
      height : '730px',
      data: { productId: productId },
      panelClass: 'custom-dialog-viewservice-class',
      disableClose:true,
      position: {
        right: '0',
      },

    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  GetProductById(productId,isDeleted) {
    this.productId = productId;
    this.isDeletedProduct =isDeleted
  }


  GetServiceById(serviceId,isDeleted) {
    this.serviceId = serviceId;
    this.isDeletedservice =isDeleted
  }
  
   public handleServicePage(e: any) {
    this.currentServicePageNo = e.pageIndex;
    this.servicePageSize = e.pageSize;
    if (this.serviceData.length > 0) {
      this.servicePageSize = e.pageSize;
    const searchInput = document.getElementById('searchInput') as HTMLInputElement;
    if (searchInput.value !== '') {
      this.getGoodsByBarcodeservice(searchInput.value);
    } else {
      this.getServiceByUser();
    }
    }
  }
  handleInputChange(e) {
    this.excelFileUpload = e.target.files[0];
    if (this.excelFileUpload) {
      let formdata = new FormData();
      formdata.set("file", this.excelFileUpload)
      this.productService.postBulkProduct(formdata, this.currentUser.id).then((res) => {
        this.toastService.toastMsg({
          title: "Success",
          content: "Excel Uploaded Successfully!!!",
        });
       // this.getGoodsbyUser();
        this.getServiceByUser()
        // window.location.reload()
      },(err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else if(err.error.status == "409" && err.error.expose == false){
            this.toastService.toastMsg({
              title: "Error",
              content: "List Of Service Already Exists",
            });
          }
          else{
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        });
    }
  }
  startUpload(){
    document.getElementById('uploadFile').click();
  }


  handlePageChange(pageNumber: number): void {
    console.log("handlePageChange", pageNumber);
    this.servicePageSize = this.servicePageSize
    this.currentServicePageNo = pageNumber;
    this.p = pageNumber;
    this.getServiceByUser();

  }

  getTotalPages(): number {
    return Math.ceil(this.totalServiceRow / this.servicePageSize);
  }

  openDialogservice(): void {
    const dialogRef: MatDialogRef<ServiceaddComponent> = this.dialog.open(ServiceaddComponent, {
      width: '700px',
      height : '730px',
      data: { },
      panelClass: 'custom-dialog-service-class',
      disableClose:true,
      position: {
        right: '0',
      },

    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }


  // onChekMultiple($event){
  //   let id = $event.target.value;
  //   this.isChecked = $event.target.checked;
  //   this.upiCheckedId = this.serviceData.map((item) => {
  //     if (id == -1) {
  //       if (this.isChecked == true) {
  //         this.archiveIconfalse = true;
  //         this.checkSelectsinglefalse = true;
  //         return item.id;
  //       }
  //       else {
  //         this.checkSelectsinglefalse = false;
  //         this.upiCheckedId = [];
  //       }
  //     }
  //   })

  // }

  // onChekedsingleChecktrue(e){

  //   if (e.target.checked ) {
  //     this.archiveIcontrue = true;
  //     this.checkSingleArraytrue.push(e.target.value);

  //   }
  //   else {
  //     //this.archiveIcon = true;
  //     this.checkSingleArraytrue = [];
  //   }
  // }
  // onChekedsingleCheckfalse(e){

  //   //const checkArray: FormArray = this.form.get('checkArray') as FormArray;
  //   if (e.target.checked ) {
  //     this.archiveIconfalse = true;
  //     this.checkSingleArray.push(e.target.value);

  //   }
  //   else {
  //     //this.archiveIconfalse = true;
  //     this.checkSingleArray = [];
  //   }
  // }

  // getUPIcheckId(e,productId){
  //   this.productId = productId;


  // }

  // getSingleCheckedId(e,productId){
  //   this.productId = productId;

  // }

  // getSingleCheckedIdtrue(e,productId){
  //   this.isChecked = e.target.checked;
  //   this.productId = productId;

  // }

  // deleteProductById() {
  //   let request = {
  //     "archiveProducts": this.productId

  //   }

  //   this.productService.deletemultiProductById(request, this.currentUser.id).then((res) => {
  //     if(res){
  //      // this.goodsData = res.data.pageData;

  //       this.toastService.openSnackBar("Product Archived Successfully!!!");
  //       this.archiveIconfalse = false;
  //       this.allSelect=false;
  //       this.checkSelectsinglefalse=false;

  //       this.getServiceByUser();
  //     }

  //   }
  //     , (err) => {
  //       if (err.error.expose) {
  //         this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
  //       }
  //       else {
  //         this.toastService.openErrorSnackBar("Something Went Wrong.");
  //       }

  //     }
  //   );
  // }

  // deleteProductByIdUnarchive() {
  //   let request = {
  //     "archiveProducts": this.productId

  //   }

  //   this.productService.deletemultiProductByIdunarchive(request, this.currentUser.id).then((res) => {
  //     if(res){
  //      // this.goodsData = res.data.pageData;

  //       this.toastService.openSnackBar("Product Unrchived Successfully!!!");
  //       this.archiveIcontrue = false;
  //       this.allSelect=false;
  //       this.checkSelectsingletrue=false;

  //       this.getServiceByUser();
  //     }

  //   }
  //     , (err) => {
  //       if (err.error.expose) {
  //         this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
  //       }
  //       else {
  //         this.toastService.openErrorSnackBar("Something Went Wrong.");
  //       }

  //     }
  //   );
  // }

  onChekMultiple($event) {
    const isChecked = $event.target.checked;
    if (isChecked) {
      this.selectedPartyIds = this.serviceData.map(item => item.id);
    } else {
      this.selectedPartyIds = [];
      this.showDeleteButton = false;
      this.showUnarchiveButton = false
    }
    this.checkSelect = isChecked; // Update the checkbox state in the header

    let id = $event.target.value;
    this.isChecked = $event.target.checked;
    this.upiCheckedId = this.serviceData.map((item) => {
      if (id == -1) {
        if (this.isChecked == true) {
          this.checkSelectsingle = true;
          return item.id;
        }
        else {
          this.checkSelectsingle = false;
          this.upiCheckedId = [];
        }
      }
    })
    // Clear the archived and unarchived party IDs
    this.archivedPartyIds = [];
    this.unarchivedPartyIds = [];

    // Update the archived and unarchived party IDs based on the selected parties
    this.selectedPartyIds.forEach(productId => {
      const party = this.serviceData.find(item => item.id === productId);
      if (party) {
        if (party.isDeleted) {
          this.archivedPartyIds.push(productId);
        } else {
          this.unarchivedPartyIds.push(productId);
        }
      }

      // Check if all the selected parties are archived
      const allArchived = this.selectedPartyIds.every(productId => {
        const party = this.serviceData.find(item => item.id === productId);
        return party && party.isDeleted;
      });
      // Check if all the selected parties are unarchived
      const allUnarchived = this.selectedPartyIds.every(productId => {
        const party = this.serviceData.find(item => item.id === productId);
        return party && !party.isDeleted;
      });

      if (allArchived) {
        // Show unarchive button
        this.showUnarchiveButton = true
        this.showDeleteButton = false;
      } else if (allUnarchived) {
        // Show archive button
        this.showDeleteButton = true;
        this.showUnarchiveButton = false
      } else {
        // Show error message or handle the case when there are both archived and unarchived parties selected
      }
    });

    if (this.archivedPartyIds.length > 0 && this.unarchivedPartyIds.length > 0) {
      alert("Please select either archived or unarchived services.");
      // Uncheck all the selected parties and header checkbox
      this.selectedPartyIds = [];
      this.checkSingleArray = [];
      this.checkSelectsingle = false;
      this.upiCheckedId = [];
      this.isChecked = false;
      this.allSelect = false;
      this.checkSelect = false;
      this.checkSelectsingle = false;

      // Uncheck all the checkboxes in the template
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        const inputElement = checkbox as HTMLInputElement;
        inputElement.checked = false;
      });
      // Throw an error to prevent further processing
      throw new Error("Please select either archived or unarchived services.");
    }
  }

  onChekedsingleCheck(e, productId) {
    if (e.target.checked) {
      this.selectedPartyIds.push(productId);
      this.checkSingleArray.push(e.target.value);
    } else {
      const index = this.selectedPartyIds.indexOf(productId);
      if (index !== -1) {
        this.selectedPartyIds.splice(index, 1);
        this.checkSingleArray.splice(index, 1);
      }
    }

    // Clear the archived and unarchived party IDs
    this.archivedPartyIds = [];
    this.unarchivedPartyIds = [];

    // Update the archived and unarchived party IDs based on the selected parties
    this.selectedPartyIds.forEach(productId => {
      const party = this.serviceData.find(item => item.id === productId);
      if (party) {
        if (party.isDeleted) {
          this.archivedPartyIds.push(productId);
        } else {
          this.unarchivedPartyIds.push(productId);
        }
      }
    })

    // Check if all the selected parties are archived
    const allArchived = this.selectedPartyIds.every(productId => {
      const party = this.serviceData.find(item => item.id === productId);
      return party && party.isDeleted;
    });

    // Check if all the selected parties are unarchived
    const allUnarchived = this.selectedPartyIds.every(productId => {
      const party = this.serviceData.find(item => item.id === productId);
      return party && !party.isDeleted;
    });

    if (this.archivedPartyIds.length > 0 && this.unarchivedPartyIds.length > 0) {
      // Show an alert error message to the user
      alert("Please select either archived or unarchived services.");

      // Uncheck all the selected parties and header checkbox
      this.selectedPartyIds = [];
      this.checkSingleArray = [];
      this.checkSelectsingle = false;
      this.upiCheckedId = [];
      this.isChecked = false;
      this.allSelect = false;
      this.checkSelect = false;
      this.checkSelectsingle = false;

      // Uncheck all the checkboxes in the template
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        const inputElement = checkbox as HTMLInputElement;
        inputElement.checked = false;
      });
    } else if (allArchived) {
      // Show unarchive button
      this.showUnarchiveButton = true;
      this.showDeleteButton = false;
    } else if (allUnarchived) {
      // Show archive button
      this.showDeleteButton = true;
      this.showUnarchiveButton = false;
    } else {
      alert("Please select either archived or unarchived services.");
      this.selectedPartyIds = [];
      this.checkSingleArray = [];
      this.checkSelectsingle = false;
      this.upiCheckedId = [];
      this.isChecked = false;
      this.allSelect = false;
      this.checkSelect = false;
      this.checkSelectsingle = false;
      this.showDeleteButton = false;
      this.showUnarchiveButton = false;

      // Uncheck all the checkboxes in the template
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        const inputElement = checkbox as HTMLInputElement;
        inputElement.checked = false;
      });
    }
    if (this.archivedPartyIds.length == 0 && this.unarchivedPartyIds.length == 0) {
      this.selectedPartyIds = [];
      this.checkSingleArray = [];
      this.checkSelectsingle = false;
      this.upiCheckedId = [];
      this.isChecked = false;
      this.allSelect = false;
      this.checkSelect = false;
      this.checkSelectsingle = false;
      this.showDeleteButton = false;
      this.showUnarchiveButton = false;

      // Uncheck all the checkboxes in the template
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        const inputElement = checkbox as HTMLInputElement;
        inputElement.checked = false;
      });
    }

  }


  // onChekMultiple($event) {
  //   let id = $event.target.value;
  //   this.isChecked = $event.target.checked;
  //   console.log("this.isChecked", this.isChecked)
  //   this.upiCheckedId1 = this.serviceData.map((item) => {
  //     if (id == -1) {
  //       if (this.isChecked == true) {
  //         this.archiveIconfalse = true;
  //         this.checkSelectsinglefalse = true;
  //         return item;
  //       }
  //       else {
  //         this.checkSelectsinglefalse = false;
  //         this.checkSelectsingletrue = false;
  //         this.upiCheckedId = [];
  //         this.upiCheckedId2 = [];
  //         this.count = 0;
  //       }
  //     }
  //   })

  //   console.log("multiple click 111", this.upiCheckedId1)
  //   for (var i = 0; i < this.upiCheckedId1.length; i++) {
  //     if (this.upiCheckedId1[i].isDeleted == false) {
  //       this.upiCheckedId.push(this.upiCheckedId1[i].id);
  //     }
  //     else {
  //       this.count = this.count + 1;
  //       console.log("count", this.count)
  //     }

  //   }

  //   if (this.count === this.upiCheckedId1.length) {
  //     this.archiveIcontrue = true;
  //     for (var i = 0; i < this.upiCheckedId1.length; i++) {
  //       if (this.upiCheckedId1[i].isDeleted == true) {
  //         this.upiCheckedId2.push(this.upiCheckedId1[i].id);
  //         this.checkSelectsingletrue = true;
  //       }

  //     }
  //   }
  //   console.log("multiple click", this.upiCheckedId)
  //   console.log("multiple click 222", this.upiCheckedId2)

  // }

  onChekedsingleChecktrue(e, id, deleted) {
    console.log("unarchieve single", e.target.checked)
    console.log("deleted true", deleted)

    if (e.target.checked == true && deleted == true) {
      this.archiveIcontrue = true;
      this.checkSingleArraytrue.push(e.target.value);
      // this.eventchecktrue=true;
      // this.eventcheckfalse=false;
      console.log("this.checkSingleArraytrue", this.checkSingleArraytrue)
      this.archiveIconfalse = false;
      this.checkSingleArray = [];
      // this.checkSelectsinglefalse = false;

    }
    else {
      if (this.upiCheckedId2.length > this.checkSingleArraytrue.length) {

        var index11 = this.upiCheckedId2.indexOf(id);
        console.log(index11)
        this.upiCheckedId2.splice(index11, 1);
        console.log("after single click upiCheckedId2", this.upiCheckedId2)

        if (this.upiCheckedId2.length > 0) {
          this.archiveIcontrue = true;
          this.checkSelect = false;
          this.isChecked = false;
        }
        else {
          this.archiveIcontrue = false;
          this.upiCheckedId2 = [];
        }

      }
      else {
        var index2 = this.checkSingleArraytrue.indexOf(id);
        console.log(index2)
        this.checkSingleArraytrue.splice(index2, 1);
        console.log("this.checkSingleArraytrue", this.checkSingleArraytrue)

        if (this.checkSingleArraytrue.length > 0) {
          this.archiveIcontrue = true;
        }
        else {
          this.archiveIcontrue = false;
          this.checkSingleArraytrue = [];
        }
      }



    }
    //this.compareArchiveUnarchive();
  }
  onChekedsingleCheckfalse(e, id, deleted) {
    console.log("checked", e.target.checked)
    console.log("deleted true", deleted)
    console.log("this.checkSingleArraytrue", this.checkSingleArraytrue)

    if (e.target.checked == true && deleted == false) {
      this.archiveIconfalse = true;
      this.checkSingleArray.push(e.target.value);
      // this.eventcheckfalse=true;
      // this.eventchecktrue=false;
      console.log("checkSingleArray", this.checkSingleArray)

      this.archiveIcontrue = false;
      this.checkSingleArraytrue = [];
      console.log("this.checkSingleArraytrue", this.checkSingleArraytrue)
      // this.checkSelectsingletrue=false;

    }
    else {
      if (this.upiCheckedId.length > this.checkSingleArray.length) {

        var index1 = this.upiCheckedId.indexOf(id);
        console.log(index1)
        this.upiCheckedId.splice(index1, 1);
        console.log("after single click upiCheckedId", this.upiCheckedId)

        if (this.upiCheckedId.length > 0) {
          this.archiveIconfalse = true;
          this.checkSelect = false;
          this.isChecked = false;
        }
        else {
          this.archiveIconfalse = false;
          this.upiCheckedId = [];
        }

      }
      else {
        var index = this.checkSingleArray.indexOf(id);
        console.log(index)
        this.checkSingleArray.splice(index, 1);
        console.log("this.checkSingleArray", this.checkSingleArray)

        if (this.checkSingleArray.length > 0) {
          this.archiveIconfalse = true;
        }
        else {
          this.archiveIconfalse = false;
          this.checkSingleArray = [];
        }

      }



    }

  }


  getUPIcheckId(productId) {
    this.productId = productId;
  }

  getUPIcheckIdtrue(e, productId) {
    this.productId = productId;
  }

  getSingleCheckedId(productId) {
    this.productId = productId;

  }

  getSingleCheckedIdtrue(e, productId) {
    this.isChecked = e.target.checked;
    this.productId = productId;

  }

  deleteProductById() {
    if(this.p == 1){
      this.currentPageNo = this.currentPageNo;
    }
    else{
      if(this.toatlPages > this.currentPageNo){
        this.currentPageNo = this.currentPageNo -1;
      }
    }
    let request = {
      "archiveProducts": this.selectedPartyIds

    }

    this.productService.deletemultiProductById(request, this.currentUser.id).then((res) => {
      if (res) {
        // this.goodsData = res.data.pageData;

        this.toastService.toastMsg({
          title: "Success",
          content: "Service Archived Successfully!!!",
        });
        this.isChecked = false;
        this.archiveIconfalse = false;
        this.allSelect = false;
        this.checkSelectsinglefalse = false;
        this.upiCheckedId = [];
        this.checkSingleArray = [];

        this.archiveIcontrue = false;
        this.allSelect = false;
        this.checkSelectsingletrue = false;
        this.upiCheckedId2 = [];
        this.checkSingleArraytrue = [];
        this.showDeleteButton = false;
        this.showUnarchiveButton = false
        this.selectedPartyIds = [];
        this.checkSelect = false;
        this.upiCheckedId1=[];
        this.count=0;

        this.getServiceByUser();
      }

    }
      , (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }

      }
    );
  }

  deleteProductByIdUnarchive() {
    let request = {
      "archiveProducts": this.selectedPartyIds

    }

    this.productService.deletemultiProductByIdunarchive(request, this.currentUser.id).then((res) => {
      if (res) {
        // this.goodsData = res.data.pageData;
        this.toastService.toastMsg({
          title: "Success",
          content: "Service Unarchived Successfully!!!",
        });
        this.isChecked = false;
        this.archiveIcontrue = false;
        this.allSelect = false;
        this.checkSelectsingletrue = false;
        this.upiCheckedId2 = [];
        this.checkSingleArraytrue = [];

        this.archiveIconfalse = false;
        this.checkSelectsinglefalse = false;
        this.upiCheckedId = [];
        this.checkSingleArray = [];
        this.showDeleteButton = false;
        this.showUnarchiveButton = false
        this.selectedPartyIds = [];
        this.checkSelect = false;
        this.upiCheckedId1=[];
        this.count=0;
        this.getServiceByUser();
      }

    }
      , (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }

      }
    );
  }

  downloadServices(){
    this.productService.downloadProduct({}).subscribe(res => {
      const blob = new Blob([res], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Sample Services.xlsx'; // You can specify the filename here
      a.click();
      window.URL.revokeObjectURL(url);
    })
  }
}
