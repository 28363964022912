<div class="modal-header">
    <div class="headingmodal">
        <h1 style="color: #EB8B3F; font-weight: bolder;" class="modal-title mb-0">{{offerName}}</h1>
        <h2 style="color:black; font-weight: bolder;" class="modal-title mb-0" *ngIf="offerStatus == true ">Active</h2>
        <h2 style="color: black; font-weight: bolder;" class="modal-title mb-0" *ngIf="offerStatus == false ">Inactive</h2>
    </div>
    <div class="addProductButtons">

        <button style="margin-right: 10px !important;" (click)="oncancel()"  type="button" 
        class="btn btn-cancel">Cancel</button>


    </div>

</div>
<div class="modal-body">

    <form [formGroup]="OfferInfoForm">
        <!-- <div class="row">


            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div class="mb-3">
                <label class="fw-bolder form-label">Status</label>
                <h4 *ngIf="offerStatus == true ">Active</h4>
                <h4 *ngIf="offerStatus == false ">Inactive</h4>
                </div>
            </div>


        </div> -->

        <div class="row mt-2">
            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">From Date</label>
                <h4>{{validFrom | date: 'dd MMM yyyy'}}</h4>


            </div>

            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">To Date</label>
                <h4>{{validTo | date: 'dd MMM yyyy'}}</h4>


            </div>

        </div>

        <div class="row mt-2">
            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <label class="fw-bolder form-label">Description</label>
                <h4>{{description}}</h4>


            </div>

        </div>

    </form>

    <form [formGroup]="ConditionForm">
        <div class="row mt-2">
            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Rules Name</label>
                <h4>{{ruleName}}</h4>
            </div>

            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Offer Type</label>
                <h4>{{offerType}}</h4>


            </div>
        </div>

        <div class="row" *ngIf="ConditionForm.get('offerType').value =='PRODUCT'">
            <!-- Buy Product -->
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Buy Product</label>
                <h4>{{buyProduct}}</h4>

            </div>
            <!-- Barcode -->
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Barcode</label>
                <h4>{{barcode}}</h4>

            </div>
            <!-- Quantity -->
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Quantity</label>
                <h4>{{quantity}}</h4>

            </div>
            <!-- Discount Type -->
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Discount Type</label>
                <h4>{{discountType}}</h4>

            </div>
        </div>

        <!-- Offer type = Bill -->
        <div class="row" *ngIf="ConditionForm.get('offerType').value =='BILL'">
            <!-- Minimum Amount -->
            <div class="col-xs-12 xol-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Minimum Amount</label>
                <h4>{{minAmount}}</h4>

            </div>
            <!-- Discount Type -->
            <div class="col-xs-12 xol-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Discount Type</label>
                <h4>{{discountType}}</h4>

            </div>
        </div>

        <!-- Discount Type = Product -->
        <div class="row" *ngIf="ConditionForm.get('discountType').value == 'PRODUCT'">
            <!-- Free Product -->
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Free Product</label>
                <h4>{{freeProduct}}</h4>

            </div>
            <!-- Barcode -->
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Barcode</label>
                <h4>{{barcode1 ? barcode1 : '--'}}</h4>

            </div>
            <!-- Quantity -->
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Quantity</label>
                <h4>{{quantity1 ? quantity1 : '--'}}</h4>

            </div>

        </div>

        <!-- Discount Type = Bill -->
        <div class="row" *ngIf="ConditionForm.get('discountType').value  == 'BILL'">
            <!-- Select Discount -->
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Select Discount</label>
                <h4>{{selectDiscount}}</h4>

            </div>

            <!-- Discount -->
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="fw-bolder form-label">Discount</label>
                <h4>{{discount}}</h4>

            </div>
        </div>

        <div class="row mt-3" *ngIf="imageSrcSeven !== null">
            <div class="col-sm-12 col-md-6 col-lg-12 col-xl-12">
                <p class="d-md-inline-block fs--1 ml-5" style="font-weight:600;font-size:20px!important;">
                    View Offer Image
                </p>
            </div>

        </div>

        <div class="row mt-2" *ngIf="imageSrcSeven !== null">
            <div class="flex d-flex flex-row" style="justify-content: center; align-items: center;">
                <label class="uploader2"  style="width: 200px !important;height:200px !important;"
                ondragover="return false;" [class.loaded]="loaded"
                    [style.outlineColor]="dragging ? activeColor : baseColor">

                    <img [src]="imageSrcSeven"  width="200" height="200" (load)="handleCoverImageLoad()" [class.loaded]="imageLoaded" />

                    
                </label>
            </div>
        </div>

    </form>
</div>


