<div class="modal-header pt-0" id="Add-Party-modal">
    <h1 style="color: #EB8B3F; font-weight: bolder;margin-left:11px;" class="modal-title mb-0">Add {{isVendor?"Vendor" : "Customer"}}
    </h1>
    <div class="addProductButtons">
        <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel"
            (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-save" [disabled]="isSaveButtonDisabled"
            (click)="postPartyDetails()">Save</button>
    </div>
</div>


<div class="modal-body">
    <div class="container-fluid px-2">
        <div class="toggle-container container-fluid d-flex justify-content-center" style = "align-items: flex-start;">
            <div class="lable-container container d-flex justify-content-end" style = "font-size: 14px;align-items: flex-start;" [class.bold]="!isOn">Vendor</div>
            <input class="input-container container" type="checkbox" [(ngModel)]="isOn" [checked]="isShown" id="switch"
                (click)="toggleShow($event.target.checked)" />
            <label for="switch" class="small-toggle container"></label>
            <div class="lable-container container d-flex justify-content-start"  style = "font-size: 14px;align-items: flex-start;" [class.bold]="isOn">Customer</div>
        </div>

        <form [formGroup]="PartyForm" (ngSubmit)="postPartyDetails()" *ngIf="isShown &&  !isArabic">
            <div class="row">
                <div class="col-sm-12 col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label class="form-label">Customer Name <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl required matInput placeholder="Customer Name" formControlName="partyName"
                                [ngClass]="{'is-invalid':f.partyName.invalid && f.partyName.touched && submitted}" />
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.partyName.touched) &&f.partyName.errors?.required">
                            Enter a valid Customer Name.
                        </mat-error>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label class="form-label">E-Mail <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="email" matInput placeholder="E-Mail" formControlName="email"
                                [ngClass]="{'is-invalid': f.email.invalid && f.email.touched && submitted}" />
                        </mat-form-field>
                        <!-- <mat-error class="text-danger"
                            *ngIf="(submitted || f.email.touched) &&f.email.errors?.pattern &&f.email.errors?.email">
                            Enter a valid email id.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                            Enter a valid email id.
                        </mat-error> -->
                        <!-- <mat-error class="text-danger"
                        *ngIf="PartyForm.get('email').hasError('required') && PartyForm.get('email').touched">
                         Enter a valid email id.
                     </mat-error>
                     <mat-error class="text-danger"
                         *ngIf="PartyForm.get('email').hasError('email') && PartyForm.get('email').touched">
                         Enter a valid email id.
                     </mat-error> -->
                     <mat-error class="text-danger"
                     *ngIf="(submitted || f.email.touched) &&f.email.errors?.pattern &&f.email.errors?.pattern">
                     Enter a valid email id.
                 </mat-error>
                 <mat-error class="text-danger"
                     *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                     Enter a valid email id.
                 </mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Mobile # <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="text" matInput placeholder="Mobile #" formControlName="mobile"
                                minlength="10" maxLength="10" ngxOnlyNumbers autocomplete="off"
                                [ngClass]="{'is-invalid':f.mobile.invalid && f.mobile.touched && submitted}" />
                        </mat-form-field>

                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.pattern">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.required">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.maxlength">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.minlength">
                            Enter a valid mobile #.
                        </mat-error>
                    </div>
                </div>

                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="" style="border-radius: 10px;">
                        <label class="form-label">Group</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select matNativeControl disableOptionCentering formControlName="partyGrpArr" multiple
                                placeholder="Select Multiple Groups">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
                                    {{ option.groupName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!-- <ng-select [items]="partyGroupData" bindLabel="groupName" bindValue="id" [multiple]="true"
                            placeholder="Select multiple Groups" [closeOnSelect]="false" [(ngModel)]="selectedClient"
                            [ngModelOptions]="{standalone: true}">
                        </ng-select> -->


                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label for="openingBalance" class="form-label">Opening Balance</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Enter Opening Balance" (keypress)="numberOnly($event)"
                                formControlName="openingBalance" autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <label for="asOfDate" class="form-label">As Of Date</label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input matInput formControlName="asOfDate" placeholder="DD MMM YYYY" [(ngModel)]="todaysDate"
                            autocomplete="off" [outsideClick]="true" style="text-transform: uppercase !important"
                            id="As On Date" bsDatepicker [maxDate]="today" [bsConfig]="{
                                            dateInputFormat: 'DD MMM YYYY',
                                            containerClass: 'theme-dark-blue',
                                            showWeekNumbers: false,
                                            displayOneMonthRange: true
                                        }"  (click)="openDatepicker()"/>
                        <span>
                            <mat-icon class="iconcalender">date_range</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <label class="form-label">Payment Terms</label>
                <div class="col-sm-6 col-lg-6 col-md-6 mt-3" style="padding-left:14px">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="PaymentMode" value="RECEIVE"
                                formControlName="PaymentMode">
                            <label class="form-check-label" for="toReceive" style = "font-size: 14px;">
                                To Receive
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-6 col-md-6 mt-3" style="padding-left:14px">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="PaymentMode" value="PAY"
                                formControlName="PaymentMode">
                            <label class="form-check-label" for="toPay" style = "font-size: 14px;">
                                To Pay
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <div class="mb-3">
                        <label class="form-label" for="billingAddress">Billing Address <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <textarea matNativeControl matInput id="billingAddress" formControlName="billingAddress"
                                required rows="2" placeholder="Billing Address" autocomplete="off"
                                [ngClass]="{'is-invalid': f.billingAddress.invalid && f.billingAddress.touched && submitted}"></textarea>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
                            Enter a valid Billing Address.
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="country">Country</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select matNativeControl disableOptionCentering  formControlName="country" placeholder="Country" autocomplete="off" panelClass="custom-mat-select-panel">
                                <mat-option *ngFor="let country of countryList" [value]="country.countryName">{{ country.countryName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="pincode">Pincode</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" id="pincode" maxlength="6" minlength="6" formControlName="pincode"
                                placeholder="Pincode" autocomplete="off" (change) ="getPincodeDetails(this.PartyForm.get('pincode').value)"
                                 />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="city">City </label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" id="city" formControlName="city"
                                placeholder="City" autocomplete="off"/>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="state">State</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" id="state" formControlName="state"
                                placeholder="State" autocomplete="off"/>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- credit terms -->
            <div class="row" role="tabpanel" aria-labelledby="form-wizard-progress-tab3" id="form-wizard-progress-tab3">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Credit Period Day</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput type="number" placeholder="Enter Credit Period Days" class=""
                                formControlName="creditPeriod" min="0" autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Credit Limits</label>
                        <mat-form-field class="example-full-width" floatLabel="always" data-placeholder="my placeholder"
                            appearance="outline">
                            <div style="display: flex;flex-direction: row;align-items: center;">
                                <span style = "font-size: 14px;" matPrefix>₹&nbsp;</span>
                                <input matInput type="number" class="" placeholder="Enter Limit" title="Enter Limit"
                                    formControlName="creditLimit" min="0" autocomplete="off" />
                            </div>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">No. Of Credits </label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Enter Number Of Credits" type="number" autocomplete="off"
                                formControlName="noOfCredit" min="0" [ngClass]="{
                                                'is-invalid': f.noOfCredit.invalid && f.noOfCredit.touched && submitted
                                              }" />

                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="
                                    (submitted || f.noOfCredit.touched) &&
                                    f.noOfCredit.errors?.pattern
                                  ">
                            No. Of Credits required #!
                        </mat-error>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Relationship Since</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select matNativeControl disableOptionCentering formControlName="relationSince"
                                placeholder="Relationship Since">
                                <mat-option *ngFor="let y of years" [value]="y">{{ y }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Distance (In Km)</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput type="number" (keypress)="numberOnly($event)" placeholder="Distance In Km" formControlName="distance"
                                autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Transportation</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Ex.Truck - MH01OO1234" formControlName="transporation"
                                autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- Additional Fields -->
            <div class="row">
                <div class="card-body p-0 m-2 col-12">
                    <div id="tableExample"
                        data-list='{"valueNames":["partyName","email","mobile","billingAddress","asOfDate"],"page":5,"pagination":true}'>
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped fs--1 mb-0">
                                <thead class="text-900 text-center inner-model-table-heading">
                                    <tr>
                                        <th class="p-2" style = "vertical-align: middle;">Department</th>
                                        <th class="p-2" style = "vertical-align: middle;">Contact</th>
                                        <th class="p-2" style = "vertical-align: middle;">E-Mail</th>
                                        <th class="p-2" style = "vertical-align: middle;">Delete</th>
                                    </tr>
                                </thead>
                                <tbody class="list text-center" id="table-customers-body">
                                    <ng-container formArrayName="addtionalDetails" *ngFor="
                let group of additionalDetailsControls.controls;
                let i = index
              ">
                                        <tr [formGroupName]="i">
                                            <!-- *ngIf="group.get('isEditable').value" -->
                                            <td style = "vertical-align: middle;">
                                                <input matInput type="text" class="" formControlName="department" />
                                            </td>
                                            <td style = "vertical-align: middle;">
                                                <input matInput type="text" class="" minlength="10" maxLength="10"
                                                    ngxOnlyNumbers formControlName="additionalMobile" />
                                            </td>
                                            <td style = "vertical-align: middle;">
                                                <input matInput type="text" class="" formControlName="additionalContact"
                                                    (keyup.enter)="addAddtionalDetails()" />
                                            </td>
                                            <td style = "vertical-align: middle;">
                                                <a class="btn" *appHasPermission="['delete'];name: 'grantpermission'"
                                                    (click)="deleteaddAddtionalDetails(i)">
                                                    <span class="bi-trash fs-1" style="color : red;"
                                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                                        title="Delete"></span>
                                                    <!-- Delete -->
                                                </a>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                                <tbody *ngIf="">
                                    <tr class="text-center">
                                        <td colspan="10">
                                            <h5 class="text-nowrap">
                                                No {{isVendor?"Vendor" : "Customer"}} Details Added. Please Add
                                                {{isVendor?"Vendor" : "Customer"}}
                                                Details To Be Displayed Here.
                                                <span>
                                                    <a class="nav-link" routerLink="/pages/add-party">Add New
                                                        {{isVendor?"Vendor" : "Customer"}}
                                                    </a>
                                                </span>
                                            </h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-between">
                <div class="col-sm-12 col-md-9 col-lg-9"></div>
                <div class="col-sm-12 col-md-3 col-lg-3 text-nowrap mb-3">
                    <!-- TODO: ADD BUTTON -->
                    <a class="btn btn-outline-warn dash fs--1" (click)="addAddtionalDetails()">
                        <span id="boot-icon" class="bi bi-plus-lg newrow" style="color: #FC7643;"></span>
                        <span class="me-2 newrow" data-bs-toggle="tooltip" style="color: #FC7643;"
                            data-bs-placement="top" title="Add Row">
                            Add New Row</span>
                    </a>
                </div>
            </div>
            <!-- Save and Cancel buttons -->
        </form>

        <form [formGroup]="PartyForm" (ngSubmit)="postPartyDetails()" *ngIf="!isShown && !isArabic">
            <div class="row">
                <div class="col-sm-12 col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label class="form-label">{{isVendor?"Vendor" : "Customer"}} Name <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl required matInput placeholder="Name" formControlName="partyName"
                                autocomplete="off"
                                [ngClass]="{'is-invalid':f.partyName.invalid && f.partyName.touched && submitted}" />
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.partyName.touched) &&f.partyName.errors?.required">
                            Enter a valid {{isVendor?"Vendor" : "Customer"}} Name.
                        </mat-error>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label class="form-label">E-Mail <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="email" matInput placeholder="E-Mail" formControlName="email" autocomplete="off"
                                [ngClass]="{'is-invalid': f.email.invalid && f.email.touched && submitted}" />
                        </mat-form-field>
                        <!-- <div class="text-danger" *ngIf="PartyForm.get('email').hasError('required') && PartyForm.get('email').touched">
                            Email is required.
                          </div>
                          <div class="text-danger" *ngIf="PartyForm.get('email').hasError('email') && PartyForm.get('email').touched">
                            Invalid email address.
                          </div> -->
                        <!-- <mat-error class="text-danger"
                           *ngIf="PartyForm.get('email').hasError('required') && PartyForm.get('email').touched">
                            Enter a valid email id.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="PartyForm.get('email').hasError('email') && PartyForm.get('email').touched">
                            Enter a valid email id.
                        </mat-error> -->
                        <mat-error class="text-danger"
                        *ngIf="(submitted || f.email.touched) &&f.email.errors?.pattern &&f.email.errors?.pattern">
                        Enter a valid email id.
                    </mat-error>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                        Enter a valid email id.
                    </mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Mobile # <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="text" matInput placeholder="Mobile #" formControlName="mobile"
                                minlength="10" maxLength="10" ngxOnlyNumbers autocomplete="off"
                                [ngClass]="{'is-invalid':f.mobile.invalid && f.mobile.touched && submitted}" />
                        </mat-form-field>

                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.pattern">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.required">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.maxlength">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.minlength">
                            Enter a valid mobile #.
                        </mat-error>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="" style="border-radius: 10px;">
                        <label class="form-label">Group</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select matNativeControl disableOptionCentering formControlName="partyGrpArr" multiple
                                placeholder="Select Multiple Groups">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
                                    {{ option.groupName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-12">
                    <div class="mb-3">
                        <label class="form-label example-full-width">GSTIN<span class="error-asterisk">*</span></label>
                        <mat-form-field appearance="outline" style="min-width: calc(85% + 13px)!important;">
                            <input matNativeControl formControlName="gstIn" (change)="getGstNumber($event.target.value)"
                                maxlength="15" minlength="15" autocomplete="off"
                                pattern="^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$"
                                placeholder="GSTIN" style="text-transform: uppercase"
                                [ngClass]="{'is-invalid': f.gstIn.invalid && f.gstIn.touched}" required />
                        </mat-form-field>
                        <mat-spinner *ngIf="isLoading" diameter="30"></mat-spinner>
                        <mat-icon *ngIf="PartyForm.controls.gstIn.valid && !isLoading"
                            [ngClass]="{ 'gst-status-icon': true, 'active': !isDisabledGST }">
                            {{ isDisabledGST ? '✗' : '✓' }}
                        </mat-icon>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.gstIn.touched) && (f.gstIn.errors?.required || f.gstIn.errors?.pattern || f.gstIn.errors?.minlength || f.gstIn.errors?.maxlength)">
                            Enter a valid GSTIN.
                        </mat-error>
                    </div>
                </div>

                <div class="col-sm-12 col-lg-6 col-md-12">
                    <div class="example-full-width">
                        <label class="form-label">GST Type <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select matNativeControl disableOptionCentering formControlName="gstType" required
                                placeholder="GST Type" [disabled]="!isDisabledGST"
                                [ngClass]="{'is-invalid':f.gstType.invalid && f.gstType.touched && submitted}">
                                <mat-option value="REGULAR">REGULAR</mat-option>
                                <mat-option value="COMPOSITE">COMPOSITE</mat-option>
                                <mat-option value="EXEMPTED">EXEMPTED</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.gstType.touched) && f.gstType.errors?.required">
                            Enter a valid GST Type
                        </mat-error>
                    </div>

                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Legal Name</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput formControlName="legalName" [disabled]="!isDisabledGST"
                                [readonly]="!isDisabledGST" placeholder="Legal Name" autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label for="openingBalance" class="form-label">Opening Balance</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Enter Opening Balance" (keypress)="numberOnly($event)"
                                formControlName="openingBalance" autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <label for="asOfDate" class="form-label">As Of Date</label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input matInput formControlName="asOfDate" placeholder="DD MMM YYYY" [(ngModel)]="todaysDate"
                            style="text-transform: uppercase !important" id="As On Date" [outsideClick]="true"
                            bsDatepicker [maxDate]="today" [bsConfig]="{
                                            dateInputFormat: 'DD MMM YYYY',
                                            containerClass: 'theme-dark-blue',
                                            showWeekNumbers: false,
                                            displayOneMonthRange: true
                                        }" autocomplete="off" (click)="openDatepicker()" />
                        <span>
                            <mat-icon class="iconcalender">date_range</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <label class="form-label mb-2">Payment Terms</label>
                <div class="col-sm-12 col-lg-6 col-md-6 mt-3" style="padding-left:14px">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="PaymentMode" value="RECEIVE"
                                formControlName="PaymentMode">
                            <label class="form-check-label" for="toReceive" style = "font-size: 14px;">
                                To Receive
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-md-6 mt-3" style="padding-left:14px">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="PaymentMode" value="PAY"
                                formControlName="PaymentMode">
                            <label class="form-check-label" for="toPay" style = "font-size: 14px;">
                                To Pay
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <div class="mb-3">
                        <label for="billingAddress" class="form-label">Billing Address <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <textarea matInput id="billingAddress" formControlName="billingAddress" required rows="2"
                                placeholder="Billing Address" [ngClass]="{
                                                'is-invalid': f.billingAddress.invalid && f.billingAddress.touched && submitted
                                            }" autocomplete="off"></textarea>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
                            Enter a valid Billing Address.
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="country">Country</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select matNativeControl disableOptionCentering  formControlName="country" placeholder="Country" autocomplete="off" panelClass="custom-mat-select-panel">
                                <mat-option *ngFor="let country of countryList" [value]="country.countryName">{{ country.countryName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                       
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="pincode">Pincode</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput type="text" id="pincode" formControlName="pincode" placeholder="Pincode" maxlength="6" minlength="6"
                                autocomplete="off"  (change) ="getPincodeDetails(this.PartyForm.get('pincode').value)"/>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="city">City </label>
                        <mat-form-field class="mb-3" class="example-full-width" appearance="outline">
                            <input matInput type="text" id="city" formControlName="city" placeholder="City"
                                autocomplete="off" />
                        </mat-form-field>
                     
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="state">State</label>
                        <mat-form-field class="mb-3" class="example-full-width" appearance="outline">
                            <input matInput type="text" id="state" formControlName="state" placeholder="State"
                                autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row" role="tabpanel" aria-labelledby="form-wizard-progress-tab3" id="form-wizard-progress-tab3">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Credit Period Day</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput type="number" placeholder="Enter Credit Period Days" class=""
                                formControlName="creditPeriod" min="0" autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Credit Limits</label>
                        <mat-form-field class="example-full-width" floatLabel="always" data-placeholder="my placeholder"
                            appearance="outline">
                            <div style="display: flex;flex-direction: row;align-items: center;">
                                <span style = "font-size: 14px;" matPrefix>₹&nbsp;</span>
                                <input matInput type="number" class="" placeholder="Enter Limit" title="Enter Limit"
                                    formControlName="creditLimit" min="0" autocomplete="off" />
                            </div>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">No. Of Credits</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Enter Number Of Credits" type="number"
                                formControlName="noOfCredit" min="0" [ngClass]="{
                                                'is-invalid': f.noOfCredit.invalid && f.noOfCredit.touched && submitted
                                              }" autocomplete="off" />

                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="
                                    (submitted || f.noOfCredit.touched) &&
                                    f.noOfCredit.errors?.pattern
                                  ">
                            No. Of Credits required #!
                        </mat-error>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Relationship Since</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select matNativeControl disableOptionCentering formControlName="relationSince"
                                placeholder="Relationship Since">
                                <mat-option *ngFor="let y of years" [value]="y">{{ y }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Distance (In Km)</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="number" matInput (keypress)="numberOnly($event)" placeholder="Distance In Km" formControlName="distance"
                                autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Transportation</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Ex.Truck - MH01OO1234" formControlName="transporation"
                                autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- Additional Fields -->
            <div class="row">
                <div class="card-body p-0 m-2 col-12">
                    <div id="tableExample"
                        data-list='{"valueNames":["partyName","email","mobile","billingAddress","asOfDate"],"page":5,"pagination":true}'>
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped fs--1 mb-0">
                                <thead class="text-900 text-center inner-model-table-heading">
                                    <tr>
                                        <th class="p-2" style = "vertical-align: middle;">Department</th>
                                        <th class="p-2" style = "vertical-align: middle;">Contact</th>
                                        <th class="p-2" style = "vertical-align: middle;">E-Mail</th>
                                        <th class="p-2" style = "vertical-align: middle;">Delete</th>
                                    </tr>
                                </thead>
                                <tbody class="list text-center" id="table-customers-body">
                                    <ng-container formArrayName="addtionalDetails"
                                        *ngFor="let group of additionalDetailsControls.controls; let i = index">
                                        <tr [formGroupName]="i">
                                            <!-- *ngIf="group.get('isEditable').value" -->
                                            <td style = "vertical-align: middle;">
                                                <input matInput type="text" class="" formControlName="department" />
                                            </td>
                                            <td style = "vertical-align: middle;">
                                                <input matInput type="text" class="" minlength="10" maxLength="10"
                                                    ngxOnlyNumbers formControlName="additionalMobile"  />
                                            </td>
                                            <td style = "vertical-align: middle;">
                                                <input matInput type="text" class="" formControlName="additionalContact"
                                                    (keyup.enter)="addAddtionalDetails()"  />
                                            </td>
                                            <td style = "vertical-align: middle;">
                                                <a class="btn" *appHasPermission="
                      ['delete'];
                      name: 'grantpermission'
                    " (click)="deleteaddAddtionalDetails(i)">
                                                    <span class="bi-trash fs-1" style="color : red;"
                                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                                        title="Delete"></span>
                                                    <!-- Delete -->
                                                </a>
                                            </td>
                                        </tr>
                                        <div *ngIf="group.get('additionalMobile').invalid && group.get('additionalMobile').touched"
                                            class="text-danger">

                                            <div *ngIf="group.get('additionalMobile').errors.minlength">Enter a valid
                                                mobile #.</div>
                                            <div *ngIf="group.get('additionalMobile').errors.maxlength">Enter a valid
                                                mobile #.</div>
                                            <div *ngIf="group.get('additionalMobile').errors.pattern">Invalid mobile
                                                format.</div>
                                        </div>
                                        <mat-error *ngIf="f.mobile.errors?.maxlength">
                                            Enter a valid mobile #.
                                        </mat-error>
                                        <mat-error *ngIf="f.mobile.errors?.minlength">
                                            Enter a valid mobile #.
                                        </mat-error>
                                        <div *ngIf="group.get('additionalContact').invalid && group.get('additionalContact').touched"
                                            class="text-danger">

                                            <div *ngIf="group.get('additionalContact').errors.email">Invalid email
                                                format.</div>
                                        </div>
                                    </ng-container>
                                </tbody>
                                <tbody *ngIf="">
                                    <tr class="text-center">
                                        <td colspan="10">
                                            <h5 class="text-nowrap">
                                                No {{isVendor?"Vendor" : "Customer"}} Details Added. Please Add
                                                {{isVendor?"Vendor" : "Customer"}}
                                                Details To Be Displayed Here.
                                                <span>
                                                    <a class="nav-link" routerLink="/pages/add-party">Add New
                                                        {{isVendor?"Vendor" : "Customer"}}
                                                    </a>
                                                </span>
                                            </h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-between">
                <div class="col-sm-12 col-md-9 col-lg-9"></div>
                <div class="col-sm-12 col-md-3 col-lg-3 text-nowrap mb-3">
                    <!-- TODO: ADD BUTTON -->
                    <a class="btn btn-outline-warn dash fs--1" (click)="addAddtionalDetails()">
                        <span id="boot-icon" class="bi bi-plus-lg newrow" style="color: #FC7643;"></span>
                        <span class="me-2 newrow" data-bs-toggle="tooltip" style="color: #FC7643;"
                            data-bs-placement="top" title="Add Row">
                            Add New Row</span>
                    </a>
                </div>
            </div>
        </form>



        <!-- For UAE -->
        <form [formGroup]="PartyForm" (ngSubmit)="postPartyDetails()" *ngIf="isShown && isArabic">
            <div class="row">
                <div class="col-sm-12 col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label class="form-label">Customer Name <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl required matInput placeholder="Customer Name" formControlName="partyName"
                                [ngClass]="{'is-invalid':f.partyName.invalid && f.partyName.touched && submitted}" />
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.partyName.touched) &&f.partyName.errors?.required">
                            Enter a valid Customer Name.
                        </mat-error>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label class="form-label">E-Mail <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="email" matInput placeholder="E-Mail" formControlName="email"
                                [ngClass]="{'is-invalid': f.email.invalid && f.email.touched && submitted}" />
                        </mat-form-field>
                        <!-- <mat-error class="text-danger"
                            *ngIf="(submitted || f.email.touched) &&f.email.errors?.pattern &&f.email.errors?.email">
                            Enter a valid email id.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                            Enter a valid email id.
                        </mat-error> -->
                        <mat-error class="text-danger"
                        *ngIf="(submitted || f.email.touched) &&f.email.errors?.pattern &&f.email.errors?.pattern">
                        Enter a valid email id.
                    </mat-error>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                        Enter a valid email id.
                    </mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Mobile # <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="text" matInput placeholder="Mobile #" formControlName="mobile"
                                minlength="9" maxLength="9" ngxOnlyNumbers autocomplete="off"
                                [ngClass]="{'is-invalid':f.mobile.invalid && f.mobile.touched && submitted}" />
                        </mat-form-field>

                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.pattern">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.required">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.maxlength">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.minlength">
                            Enter a valid mobile #.
                        </mat-error>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="" style="border-radius: 10px;">
                        <label class="form-label">Group</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select matNativeControl disableOptionCentering formControlName="partyGrpArr" multiple
                                placeholder="Select Multiple Groups">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
                                    {{ option.groupName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label for="openingBalance" class="form-label">Opening Balance</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Enter Opening Balance" (keypress)="numberOnly($event)"
                                formControlName="openingBalance" autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <label for="asOfDate" class="form-label">As Of Date</label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input matInput formControlName="asOfDate" placeholder="DD MMM YYYY" [(ngModel)]="todaysDate"
                            style="text-transform: uppercase !important" id="As On Date" [outsideClick]="true"
                            bsDatepicker [maxDate]="today" [bsConfig]="{
                                            dateInputFormat: 'DD MMM YYYY',
                                            containerClass: 'theme-dark-blue',
                                            showWeekNumbers: false,
                                            displayOneMonthRange: true
                                        }" autocomplete="off" (click)="openDatepicker()" />
                        <span>
                            <mat-icon class="iconcalender">date_range</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <label class="form-label mb-2">Payment Terms</label>
                <div class="col-sm-12 col-lg-6 col-md-6 mt-3" style="padding-left:14px">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="PaymentMode" value="RECEIVE"
                                formControlName="PaymentMode">
                            <label class="form-check-label" for="toReceive" style = "font-size: 14px;">
                                To Receive
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-md-6 mt-3" style="padding-left:14px">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="PaymentMode" value="PAY"
                                formControlName="PaymentMode">
                            <label class="form-check-label" for="toPay" style = "font-size: 14px;">
                                To Pay
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <div class="mb-3">
                        <label for="billingAddress" class="form-label">Billing Address <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <textarea matInput id="billingAddress" formControlName="billingAddress" required rows="2"
                                placeholder="Billing Address" [ngClass]="{
                                                'is-invalid': f.billingAddress.invalid && f.billingAddress.touched && submitted
                                            }" autocomplete="off"></textarea>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
                            Enter a valid Billing Address.
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="country">Country</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select matNativeControl disableOptionCentering  formControlName="country" placeholder="Country" autocomplete="off" panelClass="custom-mat-select-panel">
                                <mat-option *ngFor="let country of countryList" [value]="country.countryName">{{ country.countryName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="pincode">Pincode</label>
                        <mat-form-field class="mb-3 example-full-width" appearance="outline">
                            <input matInput type="text" id="pincode" formControlName="pincode" placeholder="Pincode"
                                autocomplete="off"
                           >
                        </mat-form-field>

                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="city">City</label>
                        <mat-form-field class="mb-3" class="example-full-width" appearance="outline">
                            <input matInput type="text" id="city" formControlName="city" placeholder="City"
                                autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="state">State</label>
                        <mat-form-field class="mb-3" class="example-full-width" appearance="outline">
                            <input matInput type="text" id="state" formControlName="state" placeholder="State"
                                autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row" role="tabpanel" aria-labelledby="form-wizard-progress-tab3" id="form-wizard-progress-tab3">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Credit Period Day</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput type="number" placeholder="Enter Credit Period Days" class=""
                                formControlName="creditPeriod" min="0" autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Credit Limits</label>
                        <mat-form-field class="example-full-width" floatLabel="always" data-placeholder="my placeholder"
                            appearance="outline">
                            <div style="display: flex;flex-direction: row;align-items: center;">
                                <span class="pe-2" style="font-size: 12px;" matPrefix>AED </span>
                                <input matInput type="number" class="" placeholder="Enter Limit" title="Enter Limit"
                                    formControlName="creditLimit" min="0" autocomplete="off" />
                            </div>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">No. Of Credits </label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Enter Number Of Credits" type="number"
                                formControlName="noOfCredit" min="0" [ngClass]="{
                                                'is-invalid': f.noOfCredit.invalid && f.noOfCredit.touched && submitted
                                              }" autocomplete="off" />

                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="
                                    (submitted || f.noOfCredit.touched) &&
                                    f.noOfCredit.errors?.pattern
                                  ">
                            No. Of Credits required #!
                        </mat-error>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Relationship Since</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select matNativeControl disableOptionCentering formControlName="relationSince"
                                placeholder="Relationship Since">
                                <mat-option *ngFor="let y of years" [value]="y">{{ y }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Distance (In Km)</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="number" (keypress)="numberOnly($event)" matInput placeholder="Distance In Km" formControlName="distance"
                                autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Transportation</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Ex.Truck - MH01OO1234" formControlName="transporation"
                                autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- Additional Fields -->
            <div class="row">
                <div class="card-body p-0 m-2 col-12">
                    <div id="tableExample"
                        data-list='{"valueNames":["partyName","email","mobile","billingAddress","asOfDate"],"page":5,"pagination":true}'>
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped fs--1 mb-0">
                                <thead class="text-900 text-center inner-model-table-heading">
                                    <tr>
                                        <th class="p-2" style = "vertical-align: middle;">Department</th>
                                        <th class="p-2" style = "vertical-align: middle;">Contact</th>
                                        <th class="p-2" style = "vertical-align: middle;">E-Mail</th>
                                        <th class="p-2" style = "vertical-align: middle;">Delete</th>
                                    </tr>
                                </thead>
                                <tbody class="list text-center" id="table-customers-body">
                                    <ng-container formArrayName="addtionalDetails"
                                        *ngFor="let group of additionalDetailsControls.controls; let i = index">
                                        <tr [formGroupName]="i">
                                            <!-- *ngIf="group.get('isEditable').value" -->
                                            <td style = "vertical-align: middle;">
                                                <input matInput type="text" class="" formControlName="department" />
                                            </td>
                                            <td style = "vertical-align: middle;">
                                                <input matInput type="text" class="" minlength="9" maxLength="9"
                                                    ngxOnlyNumbers formControlName="additionalMobile"/>
                                            </td>
                                            <td style = "vertical-align: middle;">
                                                <input matInput type="text" class="" formControlName="additionalContact"
                                                    (keyup.enter)="addAddtionalDetails()" />
                                            </td>
                                            <td style = "vertical-align: middle;">
                                                <a class="btn" *appHasPermission="
                      ['delete'];
                      name: 'grantpermission'
                    " (click)="deleteaddAddtionalDetails(i)">
                                                    <span class="bi-trash fs-1" style="color : red;"
                                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                                        title="Delete"></span>
                                                    <!-- Delete -->
                                                </a>
                                            </td>
                                        </tr>
                                        <div *ngIf="group.get('additionalMobile').invalid && group.get('additionalMobile').touched"
                                            class="text-danger">

                                            <div *ngIf="group.get('additionalMobile').errors.minlength">Enter a valid
                                                mobile #.</div>
                                            <div *ngIf="group.get('additionalMobile').errors.maxlength">Enter a valid
                                                mobile #.</div>
                                            <div *ngIf="group.get('additionalMobile').errors.pattern">Invalid mobile
                                                format.</div>
                                        </div>
                                        <mat-error *ngIf="f.mobile.errors?.maxlength">
                                            Enter a valid mobile #.
                                        </mat-error>
                                        <mat-error *ngIf="f.mobile.errors?.minlength">
                                            Enter a valid mobile #.
                                        </mat-error>

                                    </ng-container>
                                </tbody>
                                <tbody *ngIf="">
                                    <tr class="text-center">
                                        <td colspan="10">
                                            <h5 class="text-nowrap">
                                                No {{isVendor?"Vendor" : "Customer"}} Details Added. Please Add
                                                {{isVendor?"Vendor" : "Customer"}}
                                                Details To Be Displayed Here.
                                                <span>
                                                    <a class="nav-link" routerLink="/pages/add-party">Add New
                                                        {{isVendor?"Vendor" : "Customer"}}
                                                    </a>
                                                </span>
                                            </h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-between">
                <div class="col-sm-12 col-md-9 col-lg-9"></div>
                <div class="col-sm-12 col-md-3 col-lg-3 text-nowrap mb-3">
                    <!-- TODO: ADD BUTTON -->
                    <a class="btn btn-outline-warn dash fs--1" (click)="addAddtionalDetails()">
                        <span id="boot-icon" class="bi bi-plus-lg newrow" style="color: #FC7643;"></span>
                        <span class="me-2 newrow" data-bs-toggle="tooltip" style="color: #FC7643;"
                            data-bs-placement="top" title="Add Row">
                            Add New Row</span>
                    </a>
                </div>
            </div>
        </form>

        <form [formGroup]="PartyForm" *ngIf="!isShown && isArabic">
            <div class="row">
                <div class="col-sm-12 col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label class="form-label">Vendor Name <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl required matInput placeholder="Vendor Name" autocomplete="off"
                                formControlName="partyName"
                                [ngClass]="{'is-invalid':f.partyName.invalid && f.partyName.touched && submitted}" />
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.partyName.touched) &&f.partyName.errors?.required">
                            Enter a valid Vendor Name.
                        </mat-error>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label class="form-label">E-Mail <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="email" matInput placeholder="E-Mail" formControlName="email"
                                [ngClass]="{'is-invalid': f.email.invalid && f.email.touched && submitted}"
                                autocomplete="off" />
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.email.touched) &&f.email.errors?.pattern &&f.email.errors?.email">
                            Enter a valid email id.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                            Enter a valid email id.
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label for="mobileNo" class="form-label">Mobile # <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="text" matInput placeholder="Mobile #" formControlName="mobile"
                                autocomplete="off" minlength="9" maxLength="9" ngxOnlyNumbers
                                [ngClass]="{'is-invalid':f.mobile.invalid && f.mobile.touched && submitted}" />
                        </mat-form-field>

                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.pattern">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.required">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.maxlength">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.minlength">
                            Enter a valid mobile #.
                        </mat-error>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="" style="border-radius: 10px;">
                        <label class="form-label">Group</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select formControlName="partyGrpArr" multiple [(value)]="selectedClient"
                                placeholder="Select Multiple Groups">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
                                    {{ option.groupName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>


                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label w-100">{{ lang.GSTIN }} <span class="error-asterisk">*</span></label>
                        <mat-form-field appearance="outline" style="min-width: calc(85% + 13px)!important;">
                            <input matNativeControl formControlName="gstIn" maxlength="15" minlength="15" autocomplete="off"
                            (keypress)="numberOnly($event)" maxlength="15" (keyup)="validateTRN($event.target.value)"
                                placeholder="{{ lang.GSTIN }}" style="text-transform: uppercase"
                                [ngClass]="{'is-invalid': f.gstIn.invalid && f.gstIn.touched && submitted}" required />
                        </mat-form-field>
                        <mat-icon *ngIf="PartyForm.controls.gstIn.valid"
                            [ngClass]="{ 'gst-status-icon': true, 'active': !isDisabledGST }">
                            {{ isDisabledGST ? '✗' : '✓' }}
                        </mat-icon>
                        <!-- <mat-error class="text-danger"
                            *ngIf="(submitted || f.gstIn.touched) && (f.gstIn.errors?.required || f.gstIn.errors?.minlength || f.gstIn.errors?.maxlength)">
                            Enter a valid {{lang.GSTIN }} with exactly 15 characters.
                        </mat-error> -->
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.gstIn.touched) && f.gstIn.errors?.required">
                            {{lang.GSTIN }} required with exactly 15 characters.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="f.gstIn.errors?.minlength">
                            Enter a valid {{lang.GSTIN }} with exactly 15 characters.
                        </mat-error>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-sm-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label for="openingBalance" class="form-label">Opening Balance</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Enter Opening Balance" autocomplete="off"
                                (keypress)="numberOnly($event)" formControlName="openingBalance" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label for="asOfDate" class="form-label">As Of Date</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput formControlName="asOfDate" placeholder="DD MMM YYYY"
                                style="text-transform:uppercase!important;" [outsideClick]="true" bsDatepicker
                                [maxDate]="today"
                                [bsConfig]="{ dateInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true, showWeekNumbers:false }" (click)="openDatepicker()">
                                <span>
                                    <mat-icon class="iconcalender">date_range</mat-icon>
                                </span>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <label class="form-label">Payment Terms</label>
                <div class="col-sm-6 col-lg-6 col-md-6  mt-3" style="padding-left:14px">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="PaymentMode" value="RECEIVE"
                                formControlName="PaymentMode">
                            <label class="form-check-label" for="toReceive" style = "font-size: 14px;">
                                To Receive
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-6 col-md-6  mt-3" style="padding-left:14px">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="PaymentMode" value="PAY"
                                formControlName="PaymentMode">
                            <label class="form-check-label" for="toPay" style = "font-size: 14px;">
                                To Pay
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <div class="mb-3">
                        <label for="billingAddress" class="form-label">Billing Address <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <textarea matInput id="billingAddress" formControlName="billingAddress" required rows="2"
                                placeholder="Billing Address" [ngClass]="{
                                        'is-invalid': f.billingAddress.invalid && f.billingAddress.touched && submitted
                                    }" autocomplete="off"></textarea>
                        </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
                                Enter a valid Billing Address.
                            </mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="country">Country</label>
                        <mat-form-field style=" font-size: 14px" class="example-full-width" appearance="outline">
                            <mat-select id="country" formControlName="country" placeholder="Country" autocomplete="off">
                                <mat-option *ngFor="let country of countryList" [value]="country.countryName">{{ country.countryName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="pincode">Pincode</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" id="pincode" formControlName="pincode"
                                autocomplete="off" placeholder="Pincode" />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="city">City</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" id="city" formControlName="city"
                                autocomplete="off" placeholder="City" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="state">State</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" id="state" formControlName="state"
                                autocomplete="off" placeholder="State" />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-6">
                    <div class="mb-3">
                        <label class="form-label">Credit Period Day</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput type="number" min="0" placeholder="Enter Credit Period Days" class=""
                                formControlName="creditPeriod" autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6">
                    <div class="mb-3">
                        <label class="form-label">Credit Limits</label>
                        <mat-form-field class="example-full-width" floatLabel="always" data-placeholder="my placeholder"
                            appearance="outline">
                            <div style="display: flex;flex-direction: row;align-items: center;">
                                <span class="pe-2" style="font-size: 12px;" matPrefix>AED</span>
                                <input matInput type="number" class="" placeholder="Enter Limit" title="Enter Limit"
                                    formControlName="creditLimit" min="0" autocomplete="off" />
                            </div>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">No. Of Credits </label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Enter Number Of Credits" type="number" min="0"
                                formControlName="noOfCredit" [ngClass]="{
                                                'is-invalid': f.noOfCredit.invalid && f.noOfCredit.touched && submitted
                                              }" autocomplete="off" />

                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="
                                    (submitted || f.noOfCredit.touched) &&
                                    f.noOfCredit.errors?.pattern
                                  ">
                            No. Of Credits required #!
                        </mat-error>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Relationship Since</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select formControlName="relationSince" placeholder="Relationship Since">
                                <mat-option *ngFor="let y of years" [value]="y">{{ y }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Distance</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Distance" type="number" (keypress)="numberOnly($event)" formControlName="distance"
                                autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Transportation</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Ex.Truck - MH01OO1234" formControlName="transporation"
                                autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- Additional Fields -->
            <div class="row">
                <div class="card-body p-0 m-2 col-12">
                    <div id="tableExample"
                        data-list='{"valueNames":["partyName","email","mobile","billingAddress","asOfDate"],"page":5,"pagination":true}'>
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped fs--1 mb-0">
                                <thead class="text-900 text-center inner-model-table-heading">
                                    <tr>
                                        <th class="p-2"  style = "vertical-align: middle;">Department</th>
                                        <th class="p-2"  style = "vertical-align: middle;">Contact</th>
                                        <th class="p-2"  style = "vertical-align: middle;">E-Mail</th>
                                        <th class="p-2"  style = "vertical-align: middle;">Delete</th>
                                    </tr>
                                </thead>
                                <tbody class="list text-center" id="table-customers-body">
                                    <ng-container formArrayName="addtionalDetails" *ngFor="
                let group of additionalDetailsControls.controls;
                let i = index
              ">
                                        <tr [formGroupName]="i">

                                            <td  style = "vertical-align: middle;">
                                                <input matInput type="text" class="" formControlName="department" />
                                            </td>
                                            <td  style = "vertical-align: middle;">
                                                <input matInput type="text" class="" minlength="9" maxLength="9"
                                                    ngxOnlyNumbers formControlName="additionalMobile" />
                                            </td>
                                            <td  style = "vertical-align: middle;">
                                                <input matInput type="text" class="" formControlName="additionalContact"
                                                    (keyup.enter)="addAddtionalDetails()"/>
                                            </td>
                                            <td  style = "vertical-align: middle;">
                                                <a class="btn" *appHasPermission="
                      ['delete'];
                      name: 'grantpermission'
                    " (click)="deleteaddAddtionalDetails(i)">
                                                    <span class="bi-trash fs-1" style="color : red;"
                                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                                        title="Delete"></span>
                                                </a>
                                            </td>
                                        </tr>

                                    </ng-container>
                                </tbody>
                                <tbody *ngIf="">
                                    <tr class="text-center">
                                        <td colspan="10">
                                            <h5 class="text-nowrap">
                                                No Vendor Details Added. Please Add Vendor
                                                Details To Be Displayed Here.
                                                <span>
                                                    <a class="nav-link" routerLink="/pages/add-party">Add New
                                                        Vendor
                                                    </a>
                                                </span>
                                            </h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-between">
                <div class="col-sm-12 col-md-9 col-lg-9"></div>
                <div class="col-sm-12 col-md-3 col-lg-3 text-nowrap mb-3">
                   <!-- TODO: ADD BUTTON -->
                    <a class="btn btn-outline-warn dash fs--1" (click)="addAddtionalDetails()">
                        <span id="boot-icon" class="bi bi-plus-lg newrow" style="color: #FC7643;"></span>
                        <span class="me-2 newrow" data-bs-toggle="tooltip" style="color: #FC7643;"
                            data-bs-placement="top" title="Add Row">
                            Add New Row</span>
                    </a>
                </div>
            </div>
        </form>




    </div>
</div>















































<!-- <form [formGroup]="PartyForm">
    <div class="card mb-2">
        <div>
            <div class="card-header bg-light pt-3 pb-3 d-flex justify-content-between">
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight: 800; font-size: 20px !important; color: #EB8B3F;                    ">
                        Add Party
                    </p>
                </div>
                <div class="col-sm-6 col-md-2 col-lg-2 form-check form-switch mt-3">
                    <input class="form-check-input" type="checkbox" [checked]="isShown" id="flexSwitchCheckDefault"
                        (click)="toggleShow($event.target.checked)" />
                    <label class="text-blue text-nowrap"> As Customer </label>
                </div>
            </div>
            <div class="progress" style="height: 2px">
                <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
                </div>
            </div>
            <div class="card-body px-sm-4 px-md-6" *appHasPermission="
          ['delete', 'modify', 'create', 'read'];
          name: 'parties';
          op: 'OR'
        ">
                <div class="row d-flex justify-content-start" *ngIf="!isShown">
                    <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>GST Number</mat-label>
                            <input matNativeControl formControlName="gstIn" (change)="getGstNumber($event.target.value)"
                                maxlength="15" minlength="15"
                                pattern="^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$"
                                placeholder="GST Number" style="text-transform: uppercase" [ngClass]="{
                  'is-invalid': f.gstIn.invalid && f.gstIn.touched
                }" required />
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.gstIn.touched) && f.gstIn.errors?.required">
                            Enter a valid GSTIN.
                        </mat-error>
                        <mat-error *ngIf="(submitted || f.gstIn.touched) && f.gstIn.errors?.pattern || f.gstIn.errors?.minlength">
                            Enter a valid GSTIN.
                        </mat-error>
                        <mat-error *ngIf="
                (submitted || f.gstIn.touched) && f.gstIn.errors?.maxlength
              ">
                            Enter a valid GSTIN.
                        </mat-error>
                        <!-- <mat-error *ngIf="
                (submitted || f.gstIn.touched) && f.gstIn.errors?.minlength
              ">
                            Enter a valid GSTIN.
                        </mat-error>
                    </div>

                    <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>GST Type</mat-label>
                            <mat-select matNativeControl formControlName="gstType" required disableOptionCentering
                                placeholder="GST Type" [disabled]="!isDisabledGST" [ngClass]="{
                  'is-invalid':
                    f.gstType.invalid && f.gstType.touched && submitted
                }">
                                <mat-option value="REGULAR">Regular</mat-option>
                                <mat-option value="COMPOSITE">Composite </mat-option>
                                <mat-option value="EXEMPTED">Exempted</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="
                (submitted || f.gstType.touched) && f.gstType.errors?.required
              ">
                            Enter a valid GST Type
                        </mat-error>
                    </div>
                    <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>GST Status</mat-label> -->
<!-- <input matNativeControl matInput formControlName="status" [disabled]="!isDisabledGST" [readonly]="!isDisabledGST"> -->
<!-- <mat-select matNativeControl formControlName="status" [disabled]="!isDisabledGST"
                                [readonly]="!isDisabledGST" placeholder="Select GST Status">
                                <mat-option value="Active">Active</mat-option>
                                <mat-option value="Inactive">Inactive</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Legal Name</mat-label>
                            <input matNativeControl matInput formControlName="legalName" [disabled]="!isDisabledGST"
                                [readonly]="!isDisabledGST" placeholder="Legal Name" />
                        </mat-form-field>
                    </div>
                </div>

                <div class="row d-flex justify-content-start">
                    <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Party Name</mat-label>
                            <input matNativeControl required matInput placeholder="Party Name"
                                formControlName="partyName" [ngClass]="{
                  'is-invalid':
                    f.partyName.invalid && f.partyName.touched && submitted
                }" />
                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="
                (submitted || f.partyName.touched) &&
                f.partyName.errors?.required
              ">
                            Enter a valid Party Name.
                        </mat-error>
                    </div>
                    <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Mobile Number</mat-label>
                            <input type="text" matInput placeholder="Mobile Number" formControlName="mobile"
                                minlength="10" maxLength="10" ngxOnlyNumbers [ngClass]="{
                  'is-invalid':
                    f.mobile.invalid && f.mobile.touched && submitted
                }" />
                        </mat-form-field>

                        <mat-error class="text-danger" *ngIf="
                (submitted || f.mobile.touched) && f.mobile.errors?.pattern
              ">
                            Enter a valid mobile number.
                        </mat-error>
                        <mat-error class="text-danger" *ngIf="
                (submitted || f.mobile.touched) && f.mobile.errors?.required
              ">
                            Enter a valid mobile number.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.maxlength">
                            Enter a valid mobile number.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.minlength">
                            Enter a valid mobile number.
                        </mat-error>
                    </div>
                    <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>E-Mail</mat-label>
                            <input type="email" matInput placeholder="E-Mail" formControlName="email" [ngClass]="{
                  'is-invalid': f.email.invalid && f.email.touched && submitted
                }" />
                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="
                (submitted || f.email.touched) &&
                f.email.errors?.pattern &&
                f.email.errors?.email
              ">
                            Enter a valid email id.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                            Enter a valid email id.
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2 mt-lg-0 mt-xl-2 mt-xxl-0">
        <div class="col-lg-12 col-xl-12 col-xxl-12 h-100">
            <div class="card theme-wizard">
                <div class="card-header bg-light pt-3 pb-1">
                    <ul class="nav nav-pills mb-3" role="tablist" id="pill-tab2">
                        <li class="nav-item" role="presentation">
                            <button class="mt-1 nav-link active" data-wizard-step="data-wizard-step"
                                data-bs-toggle="pill" data-bs-target="#form-wizard-progress-tab1" type="button"
                                role="tab" aria-controls="form-wizard-progress-tab1" aria-selected="true">
                                <span class="fas fa-home me-2" data-fa-transform="shrink-2"></span><span
                                    class="d-none d-md-inline-block fs--1">Address</span>
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="mt-1 nav-link" data-wizard-step="data-wizard-step" data-bs-toggle="pill"
                                data-bs-target="#form-wizard-progress-tab2" type="button" role="tab"
                                aria-controls="form-wizard-progress-tab2" aria-selected="false">
                                <span class="fas fa-rupee-sign me-2" data-fa-transform="shrink-2"></span><span
                                    class="d-none d-md-inline-block fs--1">Opening Balance</span>
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="mt-1 nav-link" data-wizard-step="data-wizard-step" data-bs-toggle="pill"
                                data-bs-target="#form-wizard-progress-tab3" type="button" role="tab"
                                aria-controls="form-wizard-progress-tab3" aria-selected="false">
                                <span class="far fa-credit-card me-2" data-fa-transform="shrink-2"></span><span
                                    class="d-none d-md-inline-block fs--1">Credit Terms</span>
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="mt-1 nav-link" data-wizard-step="data-wizard-step" data-bs-toggle="pill"
                                data-bs-target="#form-wizard-progress-tab4" type="button" role="tab"
                                aria-controls="form-wizard-progress-tab4" aria-selected="false">
                                <span class="fas fa-plus-square me-2" data-fa-transform="down-2 shrink-2"></span><span
                                    class="d-none d-md-inline-block fs--1">Additional Fields</span>
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="progress" style="height: 2px">
                    <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0"
                        aria-valuemax="100"></div>
                </div>
                <div class="card-body py-4">
                    <div class="tab-content"> -->
<!-- Address -->
<!-- <div class="tab-pane active px-sm-3 px-md-5" role="tabpanel"
                            aria-labelledby="form-wizard-progress-tab1" id="form-wizard-progress-tab1">
                            <div novalidate="novalidate">
                                <div class="row d-flex justify-content-start">
                                    <div style="margin-bottom: -13px" class="col-lg-4 col-sm-12 col-md-6 mb-1">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Billing Address</mat-label>
                                            <textarea matInput required formControlName="billingAddress" rows="3"
                                                placeholder="Enter your Billing Address" [ngClass]="{
                          'is-invalid':
                            f.billingAddress.invalid &&
                            f.billingAddress.touched &&
                            submitted
                        }"></textarea>
                                        </mat-form-field>
                                        <mat-error class="text-danger" *ngIf="
                        (submitted || f.billingAddress.touched) &&
                        f.billingAddress.errors?.required
                      ">
                                            Enter a valid Billing Address.
                                        </mat-error>
                                    </div>

                                    <div style="margin-bottom: -13px" class="col-lg-4 col-sm-12 col-md-6 mb-1"
                                        *ngIf="isShippingAddress">
                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Shipping Address</mat-label>
                                            <textarea matInput formControlName="shippingAddress" rows="3"
                                                placeholder="Enter your Shipping Address"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="form-check form-check-inline mb-4">
                                    <label class="form-check-label fs--1" for="inlineCheckbox1">Add Shipping
                                        Address</label>
                                    <input class="form-check-input fs--1" id="inlineCheckbox1" type="checkbox"
                                        (change)="showShippingAddress($event.target.checked)" />
                                </div>
                                <div class="row d-flex justify-content-start  mb-4">
                                    <div style="font-size: 12px" class="col-lg-4 col-sm-12 col-md-6">

                                        <ng-select [items]="partyGroupData" bindLabel="groupName" bindValue="id"
                                            [multiple]="true" placeholder="Select multiple Groups"
                                            [closeOnSelect]="false" [(ngModel)]="selectedClient"
                                            [ngModelOptions]="{standalone: true}">
                                        </ng-select>

                                        <mat-form-field class="example-full-width" appearance="outline">
                                            <mat-label>Group Name</mat-label>
                                            <input type="text" placeholder="Group Name" aria-label="FILTERS" matInput
                                            (keyup)="searchGroupByName($event.target.value)"[matAutocomplete]="auto" [ngClass]="{
                                                    'is-invalid': (partyName.invalid && partyName.touched) && submitted }" required>
                                            <mat-autocomplete #auto="matAutocomplete" multiple>
                                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.groupName"
                                                (onSelectionChange)="setSelectedGroupDeatails(option)">
                                                    {{option.groupName}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div> -->
<!-- Opening Balance -->
<!-- <div class="tab-pane px-sm-3 px-md-5" role="tabpanel"
                            aria-labelledby="form-wizard-progress-tab2" id="form-wizard-progress-tab2">
                            <div class="row d-flex justify-content-start">
                                <div style="font-size: 12px" class="col-lg-4 col-sm-12 col-md-6">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Opening Balance</mat-label>
                                        <input matInput placeholder="Enter Openeing Balance"
                                            (keypress)="numberOnly($event)" formControlName="openingBalance" />
                                    </mat-form-field>
                                </div>
                                <div style="font-size: 12px" class="col-lg-4 col-sm-12 col-md-6">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label for="As On Date">As On Date</mat-label>
                                        <input matInput formControlName="asOfDate" placeholder="DD MMM YYYY"
                                            [(ngModel)]="todaysDate" style="text-transform: uppercase !important"
                                            id="As On Date" [outsideClick]="true" ngModel bsDatepicker [maxDate]="today"
                                            [bsConfig]="{
                        dateInputFormat: 'DD MMM YYYY',
                        containerClass: 'theme-dark-blue',
                        showWeekNumbers: false,
                        displayOneMonthRange: true
                      }" />
                                        <span>
                                            <mat-icon class="iconcalender">date_range</mat-icon>
                                        </span>
                                    </mat-form-field>
                                </div>
                                <div style="font-size: 12px" class="col-lg-4 col-sm-12 col-md-6">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Payment</mat-label>
                                        <mat-select matNativeControl disableOptionCentering placeholder="Payment"
                                            formControlName="PaymentMode" [ngClass]="{
                                                'is-invalid':
                                                  f.PaymentMode.invalid &&
                                                  f.PaymentMode.touched &&
                                                  submitted
                                              }">
                                            <mat-option value="RECEIVE">Receive</mat-option>
                                            <mat-option value="PAY">Pay </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-error class="text-danger" *ngIf="
                                    (submitted || f.PaymentMode.touched) &&
                                    f.PaymentMode.errors?.required
                                  ">
                                        Select a valid Payment.
                                    </mat-error>

                                </div>
                            </div>
                        </div> -->
<!-- Credit Terms -->
<!-- <div style="font-size: 12px" class="tab-pane px-sm-3 px-md-5" role="tabpanel"
                            aria-labelledby="form-wizard-progress-tab3" id="form-wizard-progress-tab3">
                            <div class="row d-flex justify-content-start">
                                <div class="col-lg-4 col-sm-12 col-md-6">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>Credit Period Day</mat-label>
                                        <input matInput type="number" placeholder="Enter Credit Period Days" class=""
                                            formControlName="creditPeriod" />
                                    </mat-form-field>
                                </div>

                                <div style="font-size: 12px" class="col-lg-4 col-sm-12 col-md-6">
                                    <mat-form-field class="example-full-width" floatLabel="always"
                                        data-placeholder="my placeholder" appearance="outline">
                                        <mat-label>Credit Limits</mat-label>
                                        <input matInput type="number" class="" placeholder="Enter Limit"
                                            title="Enter Limit" formControlName="creditLimit" />
                                        <span class="fs-1" matPrefix>₹&nbsp;</span>
                                    </mat-form-field>
                                </div>

                                <div style="font-size: 12px" class="col-lg-4 col-sm-12 col-md-6">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>No. Of Credits</mat-label>
                                        <input matInput placeholder="Enter Number Of Credits" type="number"
                                            formControlName="noOfCredit" [ngClass]="{
                                                'is-invalid': f.noOfCredit.invalid && f.noOfCredit.touched && submitted
                                              }" />

                                    </mat-form-field>
                                    <mat-error class="text-danger" *ngIf="
                                    (submitted || f.noOfCredit.touched) &&
                                    f.noOfCredit.errors?.pattern
                                  ">
                                        No. Of Credits required number!
                                    </mat-error>
                                </div>
                            </div>
                        </div> -->
<!-- Additional Fields -->
<!-- <div class="tab-pane px-sm-3 px-md-5" role="tabpanel"
                            aria-labelledby="form-wizard-progress-tab4" id="form-wizard-progress-tab4">
                            <div class="form-validation">
                                <div class="row">
                                    <div style="margin-bottom: -13px; font-size: 12px"
                                        class="col-sm-12 col-lg-4 col-md-3">
                                        <div class="mb-3">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                                <mat-label>Relationship Since</mat-label>
                                                <mat-select formControlName="relationSince">
                                                    <mat-option *ngFor="let y of years" [value]="y">{{ y }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div style="margin-bottom: -13px; font-size: 12px"
                                        class="col-sm-12 col-lg-4 col-md-3">
                                        <div class="mb-3">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                                <mat-label>Distance</mat-label>
                                                <input matInput placeholder="Distance" type="text"
                                                    formControlName="distance" />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div style="margin-bottom: -13px; font-size: 12px"
                                        class="col-sm-12 col-lg-4 col-md-3">
                                        <div class="mb-3">
                                            <mat-form-field class="example-full-width" appearance="outline">
                                                <mat-label>Transporation</mat-label>
                                                <input matInput placeholder="Transporation"
                                                    formControlName="transporation" />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="card-body p-0 m-2">
                                        <div id="tableExample"
                                            data-list='{"valueNames":["partyName","email","mobile","billingAddress","asOfDate"],"page":5,"pagination":true}'>
                                            <div class="table-responsive">
                                                <table class="table table-bordered table-striped fs--1 mb-0">
                                                    <thead class="bg-200 text-900 text-center">
                                                        <tr>
                                                            <th class="p-2">Department</th>
                                                            <th class="p-2">Contact</th>
                                                            <th class="p-2">E-Mail</th>
                                                            <th class="p-2">Delete</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="list text-center" id="table-customers-body">
                                                        <ng-container formArrayName="addtionalDetails" *ngFor="
                                let group of additionalDetailsControls.controls;
                                let i = index
                              ">
                                                            <tr [formGroupName]="i"> -->
<!-- *ngIf="group.get('isEditable').value" -->
<!-- <td>
                                                                    <input matInput type="text" class=""
                                                                        formControlName="department" />
                                                                </td>
                                                                <td>
                                                                    <input matInput type="text" class="" minlength="10"
                                                                        maxLength="10" ngxOnlyNumbers
                                                                        formControlName="additionalMobile" />
                                                                </td>
                                                                <td>
                                                                    <input matInput type="text" class=""
                                                                        formControlName="additionalContact" />
                                                                </td>
                                                                <td>
                                                                    <a class="btn" *appHasPermission="
                                      ['delete'];
                                      name: 'grantpermission'
                                    " (click)="deleteaddAddtionalDetails(group)">
                                                                        <span class="bi-trash icon-color fs-1"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title="Delete"></span> -->
<!-- Delete -->
<!-- </a>
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </tbody>
                                                    <tbody *ngIf="">
                                                        <tr class="text-center">
                                                            <td colspan="10">
                                                                <h5 class="text-nowrap">
                                                                    No Party Details Added. Please Add Party
                                                                    Details To Be Displayed Here.
                                                                    <span>
                                                                        <a class="nav-link"
                                                                            routerLink="/pages/add-party">Add New Party
                                                                        </a>
                                                                    </span>
                                                                </h5>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-flex justify-content-between">
                                    <div class="col-sm-12 col-md-10 col-lg-10"></div>
                                    <div class="col-sm-12 col-md-2 col-lg-2 text-nowrap mb-1"> -->
<!-- TODO: ADD BUTTON -->
<!-- <a class="btn btn-outline-primary dash fs--1" (click)="addAddtionalDetails()">
                                            <span id="boot-icon" class="bi bi-plus-lg newrow"></span>
                                            <span class="me-2 fs--1 text-black newrow" data-bs-toggle="tooltip"
                                                data-bs-placement="top" title="Add Row">
                                                Add New Row</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer bg-light">
                        <div class="px-sm-1 px-md-2 text-center">
                            <ul class="pager wizard list-inline mb-0">
                                <li></li>
                                <li class="">
                                    <a class="btn btn-outline-primary px-2 px-sm-6 fs--1" (click)="postPartyDetails()"
                                        type="submit">
                                        Submit<span class="ms-2 fs--1" data-fa-transform="shrink-3">
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form> -->
