<div class="container-fluid mb-3 mx-0 bg-white">
  <div class="row pt-3 pb-3 mt-" id="headingPrintHide">
      <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:600;font-size:20px!important;">
        Low Stock Summary
      </div>
      <div class="col-6 navOption-btns" style ="display: flex; justify-content: flex-end;">
        <button class="btn me-3" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()" type="button"><i class="fas fa-download"></i></button>
  
        <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
        </button>
      </div>
  </div>
  <hr class="mt-1" />
  <div class="fs--1">
    <div class="row mx-auto">
      <!-- <div class="row mb-2 mt-1">
            <h5>LOW STOCK SUMMARY</h5>
      </div><br> -->
      <div class="container">
        <div class="row mb-3" id = "searchBarHide">
          <!-- <label class="col-sm-1 mt-2 text-right mt-3">Filters</label> -->
          <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;" class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <label class="form-label">Select Categories</label>
            <mat-form-field style="margin-bottom: -13px; font-size: 11px" class="example-full-width" appearance="outline">
              <mat-select placeholder="Select Categories" (selectionChange)="getCategories($event.value)">
                <!-- <mat-option [value]="'All'">All</mat-option> -->
                <mat-option *ngFor="let item of productCategory" [value]="item">{{item.categoryName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- <label class="col-sm-1 mt-2 text-right mt-3"></label>

          <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 ">
            
          </div>

          <div class="col-sm-4 mt-2"></div>
          <div class="col-sm-1  mt-2 text-center"  (click)="exportExcel()">
            <a type="button" style="margin-left: 14px;" class="p-0 text-900 bi-file-earmark-excel fa-2x "></a>
            <div class="text-nowrap text-900  fs--2  mt-1">Export to Excel</div>
          </div>
          <div class="col-sm-1 mt-2 text-center" (click)="onPrint()">
            <a type="button" class=" p-0 text-900 bi-printer float-right fa-2x"
              (click)="printComponent('component1')"></a>
            <div class="fs--2 text-900 text-nowrap mt-1">Print</div>
          </div> -->
        </div>
        <div class="table-responsive scrollbar mt-3">
          <table class="table table-bordered table-striped fs--1 mb-3" id="excel-table">
            <thead class="" style="background-color: #F0F0F0;">
              <tr class="text-900">
                <th class="text-nowrap text-center">#</th>
                <th class="text-nowrap text-center">Item Name</th>
                <th class="text-nowrap text-center">Minimum Stock Qty</th>
                <th class="text-nowrap text-center">Stock Qty</th>
                <th class="text-nowrap text-center">Stock Value</th>
              </tr>
            </thead>
            <tbody>
              <tr class="btn-reveal-trigger text-left" *ngFor="let item of stockList;let i = index">
                <td class="text-center">{{ i + 1}}</td>
                <td class="text-center">{{ item.itemName| titlecase }}</td>
                <td class="text-center">{{ item.lowStockUnits }}</td>
                <td class="text-center">{{ item.stock }}</td>
                <td class="text-center">{{ item.stockValue }}</td>
              </tr>
            </tbody>
            <tbody *ngIf="stockList == 0">
              <tr class="text-center">
                <td colspan="10">
                  <img class="image-responsive" src="assets/img/icons/spot-illustrations/GST No Transaction.png" width="124" height="142" alt="">
                  <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Data Found.</h5>
                </td>
              </tr>
            </tbody>
          </table>
          <div id="headingPrintHide1">
            <mat-paginator class="float-right mt-2" *ngIf="stockList.length > 0" [pageSize]="partyPageSize"
              [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo" showFirstLastButtons
              (page)="handlePage($event)" aria-label="Select page">
            </mat-paginator>
          </div>
          
        </div>
      </div>
    </div>