import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScheduleShopService {

  constructor(public httpClient: HttpService,private http:HttpClient) { }
  
  getShopList(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.schShopList + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getshopListBYshopId(data: {},shopId){
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.schShopListByShopId + shopId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });

  }

  addNewShop(data:{}){
  return new Promise((resolve,reject)=>{
    this.httpClient.call(data,UrlConstants.addNewShop,'POST').subscribe(
      (res) => resolve(res),
      (err) => reject(err)
    );
  })
 }

 UpdateShopDetails(data:{},shopId){
  return new Promise((resolve,reject)=>{
    this.httpClient.call(data,UrlConstants.upadateShop +shopId,'PUT').subscribe(
      (res) => resolve(res),
      (err) => reject(err)
    );
  })
 }
 getTimeSlots(data:{}){
  return new Promise((resolve,reject)=>{
    this.httpClient.appointment(data,UrlConstants.getTimeSlots,'POST').subscribe(
      (res) => resolve(res),
      (err) => reject(err)
    );
  })
 }
 postCreateSchedule(data:{}){
  return new Promise((resolve,reject)=>{
    this.httpClient.appointment(data,UrlConstants.createSchedule,'POST').subscribe(
      (res) => resolve(res),
      (err) => reject(err)
    );
  })
 }
 getAppointmentType(data:{},shopId){
  return new Promise((resolve,reject)=>{
    this.httpClient.appointment(data,UrlConstants.getAppointmentType + shopId,'GET').subscribe(
      (res) => resolve(res),
      (err) => reject(err)
    );
  })
 }

 getAppointmentTypeshopid(data:{},id ,size,page,shopId){
  return new Promise((resolve,reject)=>{
    this.httpClient.appointment(data,UrlConstants.getAppointmentTypeShopid + id + "?size="+size + "&page=" +page + "&shopId="+shopId,'GET').subscribe(
      (res) => resolve(res),
      (err) => reject(err)
    );
  })
 }

 isActiveShop(data:{},shopId){
  return new Promise((resolve,reject)=>{
    this.httpClient.call(data,UrlConstants.isActiveShop + shopId, 'PATCH').subscribe(
      (res) => resolve(res),
      (err) => reject(err)
    );
  })
 }

 deleteschedulybyId(data:{},shopId){
  return new Promise((resolve,reject)=>{
    this.httpClient.appointment(data,UrlConstants.deleteschedulybyId + shopId, 'DELETE').subscribe(
      (res) => resolve(res),
      (err) => reject(err)
    );
  })
 }

//  deletemultipleSchedule(data:{},userId){
//   return new Promise((resolve,reject)=>{
//     this.httpClient.appointment(data,UrlConstants.deletemultipleSchedule + userId, 'DELETE').subscribe(
//       (res) => resolve(res),
//       (err) => reject(err)
//     );
//   })
//  }
// }
deletemultipleSchedule(data: {}, userId): any {
  return new Promise((resolve, reject) => {
    this.httpClient.appointment(data, UrlConstants.deletemultipleSchedule + userId, 'DELETE').subscribe(
      (res) => resolve(res),
      (err) => reject(err)
    );
  });
}
}
