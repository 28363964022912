<!-- Weekly Data -->
<!-- Weekly Purchase -->
  <div class="card widget" style = "box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);">
    <div class="card-header">
      <div class="row headerRow">
        <div class="col-sm-6">
          <span class="pt-3" style = "font-family: 'Poppins', sans-serif;font-weight: 500;font-size: 18px;">{{purchaseType}} Purchase</span>
        </div>
        <div class="col-sm-6">
          <div class="row justify-content-end">
            <div class="col-auto">
              <!-- <mat-label style="margin-left: 15px; font-size:12px;">Select Type</mat-label> -->
              <mat-form-field appearance="outline" style="width: 120px;">
                <mat-select matNativeControl matInput disableOptionCentering [(value)]="defaultPurchaseSelect" (selectionChange)="getPurchaseReportType($event.value)">
                  <mat-option value=Weekly>Weekly</mat-option>
                  <mat-option value=Monthly>Monthly</mat-option>
                  <mat-option value=Yearly>Yearly</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" style = "height: 325px;">
      <div class="max-w-100" style = "margin: auto;" id="purchaseCanvas">{{purchaseChart}}</div>
    </div>
  </div>

  <!-- Monthly Data -->
  <!-- <div class="row g-0" >
    <div class="col-lg-12 ps-lg-2 mb-3">
      <div class="card h-lg-100">
        <div class="card-header">
          <div class="row flex-between-center">
            <div class="col-auto">
              <h5 class="mb-0">Total Monthly Purchase</h5>
            </div>
            <div class="col-auto d-flex">
              <mat-form-field appearance="outline">
                <input matInput [matDatepicker]="picker4" [(ngModel)]="monthPurchaseDateValue"
                  (dateChange)="getUpdatedPurchaseMonthReport($event.target.value)">
                <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                <mat-datepicker #picker4></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="card-body h-100 pe-0">
          <canvas class="max-w-100" id="puchaseMonthcanvas" width="1618" height="1000">{{puchaseMonthChart}}</canvas>
        </div>
      </div>
    </div>
  </div>  -->

  <!-- Yearly Data -->
  <!-- <div class="row g-0">
    <div class="col-lg-12 ps-lg-2 mb-3">
      <div class="card h-lg-100">
        <div class="card-header">
          <div class="row flex-between-center">
            <div class="col-auto">
              <h5 class="mb-0">Total Yearly Sales</h5>
            </div>
            <div class="col-auto d-flex">
              <mat-form-field appearance="outline">
                <input matInput [matDatepicker]="picker6" placeholder="" bsDatepicker
                  [bsConfig]="{ dateInputFormat : 'YYYY-MM-DD', containerClass: 'theme-dark-blue', showWeekNumbers:false, displayOneMonthRange: true }"
                  [(bsValue)]="yearPurchaseDateValue" (dateChange)="getUpdatedPurchaseYearReport($event.target.value)">
                <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                <mat-datepicker #picker6></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="card-body h-100 pe-0">
          <canvas class="max-w-100" id="puchaseYearcanvas" width="1618" height="1000">{{puchaseYearChart}}</canvas>
        </div>
      </div>
    </div>
  </div>  -->
