import { TitleCasePipe } from "@angular/common";
import lang from "src/assets/langTranslation/language_translation"
import { ConstantPool } from "@angular/compiler";
import { isNull } from "@angular/compiler/src/output/output_ast";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { CommonService } from "src/app/core/services/common.service";
import { PartyService } from "src/app/core/services/party.service";
import { ProfileService } from "src/app/core/services/profile.service";
import { RemoteShopService } from "src/app/core/services/remote-shop.service";
import { SaleInvoiceService } from "src/app/core/services/sale-invoice.service";
import { ToastNotificationService } from "src/app/core/services/toast-notification.service";
import { Constants } from "src/app/_helpers/constant";
import { StateConstants } from "src/app/_helpers/state-constans";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { BiddingService } from "src/app/core/services/bidding.service";
import { ApplyCouponComponent } from "./apply-coupon/apply-coupon.component";
import { PromotionService } from "src/app/core/services/promotion.service";
import { WarehouseService } from "src/app/core/services/warehouse.service";

@Component({
  selector: "app-sales-invoice",
  templateUrl: "./sales-invoice.component.html",
  styleUrls: ["./sales-invoice.component.scss"],
})
export class SalesInvoiceComponent implements OnInit {
  @ViewChild("closebutton") closebutton;
  public lang = lang.UAE;
  public gstRate = 0;
  public gstAmount = 0;
  public Units = Constants.Units;
  public unitValue: any;
  public index: any;
  public product: any;
  public amount: any;
  public qty: any;
  public billNo: any;
  public allStates = StateConstants.states;
  public unit: any;
  public wtax: any;
  public wotax: any;
  public ramount: any;
  public formControl = new FormControl(new Date());
  public ngModelDate = new Date();
  public isShippingAddress: boolean;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public partyData: any;
  public allPartyList: any = [];
  public partyId: any;
  public today: Date;
  public selectedClient: any;
  public productData: any = [];
  public sendArray: any = [];
  public taxRate: any;
  public maxDate: Date;
  public fieldArray: Array<any> = [];
  public newAttribute: any = {};
  public SaleInvoiceForm: any;
  public subTotal = 0;
  public totalGST = 0;
  public total = 0;
  public todaysDate;
  public invoiceNo;
  public lastInvoiceNumber: string;
  public invoiceDate;
  public gstType: any;
  public gstInRs: any;
  public gstInPer: any;
  public additionalCess: number = 0;
  public myControl = new FormControl();
  public myControl1 = new FormControl();
  public isShown: boolean = false;
  amtToMinusFromTotal: number = 0;
  warehouseList : any[];
  // serialNumber: number = 1;

  public mask = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  public filteredOptions: Observable<string[]>;
  public partyName: any;
  public compositeType: any;
  public isShowGstFields: boolean = false;
  public gstDetails: any;
  public isShippingAddressinModal: boolean = false;
  public orderId: any;
  public orderData: any;
  public showInvoiceGenerateData: boolean = false;
  public showPrintBtn: boolean = false;
  public remoteProductData = [];
  public remoteGSTAmount: any;
  public remoteTotalBillAmount: any;
  public legalName: any;
  public homeNo: any;
  public city: string;
  public state: string;
  public district: any;
  public pincode: number;
  public country: string;
  public address: any;
  public billingAddress: any;
  public customerName: string;
  public ismodelShow: boolean = false;
  public isCashSale: boolean = false;
  public selectedCheckBox: boolean = false;
  public checkedAll: boolean = false;
  public singleChecked: boolean = false;
  public read: boolean;
  public deleteProductData: any = [];
  public isAllChecked: boolean = false;
  public totalPrice: any;
  public isDisabledGST: boolean = true;
  public barcode: any;
  public submitted: boolean = false;
  public submittedParty: boolean = false;
  public currentPageNo: number = 0;
  public partyPagesize: number = 10;
  public userProfileGSTNo: any;
  public gstStateMatch: boolean = true;
  public finalObject;
  public supplytype: any;
  public SearchParty: any;
  public paramID: any;
  public isArabic: boolean;
  PartyForm: FormGroup;
  searchedProduct: any;
  filteredOptions2: Observable<any>;
  newGstRate: number = 0;
  counterAmt: any;
  counterOn: boolean = false;
  showCounterDiscountField: boolean = false;
  freeProductDataList: any[] = [];
  appliedCoupon: any;
  couponApplied: boolean = false;
  suggesionList: any;
  public userType:any='';

  constructor(
    private saleInvoiceService: SaleInvoiceService,
    public dialogRef: MatDialogRef<SalesInvoiceComponent>,
    private formBuilder: FormBuilder,
    private partyService: PartyService,
    private biddingService: BiddingService,
    private router: Router,
    private toastService: ToastNotificationService,
    @Inject(MAT_DIALOG_DATA) public estimationData: any,
    private commonService: CommonService,
    private profileService: ProfileService,
    private modal: NgbModal,
    private route: ActivatedRoute,
    private remoteShopService: RemoteShopService,
    private titleCasePipe: TitleCasePipe,
    private promotionService: PromotionService,
    public dialog: MatDialog,
    public warehouseService : WarehouseService
  ) { }

  ngOnInit(): void {
    this.userType=this.currentUser.userType
    // Subscribe to the observable to receive updates
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });
    this.getAllParties();
    this.getSaleInvoiceNumber();
    this.getProfileData();
     this.getWarehouseList();
    this.todaysDate = new Date();
    this.today = new Date();
    this.newAttribute = {
      unit: "",
      ramount: "",
      wtax: "",
      wotax: "",
      index: "",
      product: "",
      gty: "",
      select: "",
    };
    this.fieldArray.push(this.newAttribute);

    // Sale invoice form
    if (this.isArabic) {
      this.SaleInvoiceForm = this.formBuilder.group({
        partyName: ["", [Validators.required]],
        invoiceNo: ["", [Validators.required]],
        reverseCharge: [false, [Validators.required]],
        invoiceDate: ["", new Date(), [Validators.required]],
        gstNo: [
          null,
          [
            Validators.minLength(15),
            Validators.maxLength(15),
          ],
        ],
        barcode: [""],
        totalBillAmount: [""],
        billGstAmount: [""],
        credit: [false, [Validators.required]],
        gstRate: [""],
        billingAddress: [null],
        shippingAddress: [null],
        ewayBillNo: [null],
        cashSale: [""],
        subcheckbox: this.formBuilder.array([]),
        shippedFrom: [""],
        counterDiscount: [0],
      });
    } else {
      this.SaleInvoiceForm = this.formBuilder.group({
        partyName: ["", [Validators.required]],
        invoiceNo: ["", [Validators.required]],
        reverseCharge: [false, [Validators.required]],
        invoiceDate: ["", new Date(), [Validators.required]],
        gstNo: [
          null,
          [
            Validators.pattern(
              "^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$"
            ),
            Validators.minLength(15),
            Validators.maxLength(15),
          ],
        ],
        barcode: [""],
        totalBillAmount: [""],
        billGstAmount: [""],
        credit: [false, [Validators.required]],
        gstRate: [""],
        billingAddress: [null],
        shippingAddress: [null],
        ewayBillNo: [null],
        cashSale: [""],
        subcheckbox: this.formBuilder.array([]),
        shippedFrom: [""],
        counterDiscount: [0],
        warehouse: [null],
      });
    }

    if (this.isArabic) {
      this.PartyForm = this.formBuilder.group({
        gstType: [null],
        gstIn: [
          null,
          [
            Validators.minLength(15),
            Validators.maxLength(15),
          ],
        ],
        partyName: ["", [Validators.required]],
        mobile: [
          ,
          [
            Validators.minLength(9),
            Validators.maxLength(9),
            Validators.pattern("^[0-9]*$"),
            Validators.required,
          ],
        ],
        email: [
          null,
          [
            Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"),
            Validators.email,
            Validators.required,
          ],
        ],
        billingAddress: [null, [Validators.required]],
        shippingAddress: [],
        balanceStatus: ["1"],
        isCustomer: [false],
      });

    } else {
      this.PartyForm = this.formBuilder.group({
        gstType: [null],
        gstIn: [
          null,
          [
            Validators.pattern(
              "^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$"
            ),
            Validators.minLength(15),
            Validators.maxLength(15),
          ],
        ],
        partyName: ["", [Validators.required]],
        mobile: [
          ,
          [
            Validators.minLength(10),
            Validators.maxLength(10),
            Validators.pattern("^[0-9]*$"),
            Validators.required,
          ],
        ],
        // email: [
        //   null,
        //   [
        //     Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"),
        //     Validators.email,
        //     Validators.required,
        //   ],
        // ],
        email: ["", [Validators.required, Validators.email,Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")]],

        billingAddress: [null, [Validators.required]],
        shippingAddress: [],
        balanceStatus: ["1"],
        isCustomer: [false],

      });
    }
    console.log("RRRRRRRR222222222", this.estimationData)

    if (this.estimationData && this.estimationData.item?.id) {
      this.setEstimateToSaleForm(this.estimationData)
    }

    this.route.paramMap.subscribe((p) => {
      this.orderId = p.get("id");
    });
    if (this.orderId) {
      this.getCustomerProductByOrderId();
    }
    const counterDiscountControl = this.SaleInvoiceForm.get('counterDiscount');

    // Subscribe to valueChanges of counterDiscount control
    counterDiscountControl?.valueChanges.subscribe(value => {
      // const maxAllowedAmount = (this.total - (this.amtToMinusFromTotal ? this.amtToMinusFromTotal : 0) - 1);
      // const maxAllowedAmount = (this.total * this.counterAmt.percent) / 100; // Assuming 25% is the maximum allowed discount
      if (value > this.counterAmt.calculatedAmount) {
        // If the entered value exceeds the maximum allowed, set it to the maximum
        counterDiscountControl?.setValue(this.counterAmt.calculatedAmount);
      }
    });


  }
  
  getWarehouseList() {
    console.log(this.currentUser)
    this.warehouseService.getAllWarehouse({
      data:{},
      userId: this.currentUser.userType !== 'CLIENT' ? this.currentUser.parentId : this.currentUser.id,
      size: 1000,
      page: 1,
      search:'',
    }).then((res: any) => {
      this.warehouseList = res.data.pageData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      };
    })
  }


  get f() {
    return this.SaleInvoiceForm.controls;
  }

  get pf() {
    return this.PartyForm.controls;
  }

  get formArr() {
    return this.SaleInvoiceForm.get("subcheckbox") as FormArray;
  }

  addDetailsButtonClick(i): void {
    const content = this.SaleInvoiceForm.get("subcheckbox") as FormArray;
    content.push(this.addRolesPermission(i));
  }

  /**
   * Method for creating foemcontrol for each row in role permission table
   * @param id
   * @returns
   */
  addRolesPermission(id): FormGroup {
    return new FormGroup({
      read: new FormControl(false),
    });
  }
  setEstimateToSaleForm(estimationData:any){
    this.saleInvoiceService.getSaleInvoiceById({}, this.estimationData.item.id).then((saleInvoiceData: any) => {
      for (let index = 0; index < saleInvoiceData.data.sales_products.length; index++) {
        const element = saleInvoiceData.data.sales_products[index];
        this.productData.push({
          basePrice: element.pricePerUnit,
          salePrice: element.totalPrice,
          gstRate: element.gst,
          ...element
        })
      }
      this.SaleInvoiceForm.patchValue({
        gstNo: saleInvoiceData.data.party.gstIn,
        billingAddress:  saleInvoiceData.data.party.billingAddress,
        shippedFrom:  saleInvoiceData.data.party.shippingAddress,
        ewayBillNo : saleInvoiceData.data.party.ewayBillNo,
        credit : saleInvoiceData.data.credit ? true : false,
        cashSale : saleInvoiceData.data.cashSale ? true : false,
        reverseCharge : saleInvoiceData.data.reverseCharge ? true : false,

      });

      this.myControl.patchValue(this.estimationData.item.party.partyName)
      this.setSelectedPartyDeatails({
        id: saleInvoiceData.data.partyId,
        partyName: saleInvoiceData.data.party.partyName
      })

      for (let i = 0; i <= this.productData.length; i++) {
        this.addDetailsButtonClick(i);
      }
      this.calculationTotal();
    });


  }

  getProfileData() {
    this.profileService.getUserProfile({}, this.currentUser.id).then((res) => {
      this.gstType = res.data.additional.gstType;
      this.compositeType = res.data.additional.compositeType;
      this.legalName = res.data.additional.legalName;
      this.homeNo = res.data.primaryAddress.homeno;
      this.city = res.data.primaryAddress.city;
      this.state = res.data.primaryAddress.state;
      this.district = res.data.primaryAddress.district;
      this.country = res.data.primaryAddress.country;
      this.pincode = res.data.primaryAddress.pincode;
      this.address = res.data.primaryAddress.address;
      this.userProfileGSTNo = res.data.additional.gstinNo
        ? res.data.additional.gstinNo.slice(0, 2)
        : "";
    });
  }

  /**
   * Filter customer name search
   * @param name s
   * @returns
   */
  private filterNames(name: string): string[] {
    return this.allPartyList.filter(
      (partyList) =>
        partyList.partyName.toLowerCase().indexOf(name.toLowerCase()) === 0
    );
  }

  getUnit(value: string) {
    this.unitValue = "1 " + value;
  }

  addFieldValue() {
    this.fieldArray.push(this.newAttribute);
    this.newAttribute = {};
  }

  deleteFieldValue(index: number) {
    this.fieldArray.splice(index, 1);
  }

  showShippingAddress(checked: boolean) {
    this.isShippingAddress = checked;
  }

  showShippingAddressinModal(checked: boolean) {
    this.isShippingAddressinModal = checked;
  }

  /**
   * get all party name
   */
  getAllParties() {
    this.partyService
      .getAllPartiesDetails(
        {},
        this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,this.partyPagesize,
        this.currentPageNo + 1
      )
      .then((res) => {
        this.allPartyList = res.data.pageData;
        if (this.allPartyList) {
          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(""),
            map((name) =>
              name ? this.filterNames(name) : this.allPartyList.slice()
            )
          );
        }
      });
  }

  validateTRN(TRN) {
    if (TRN.length === 15) {
      if (this.isArabic) {
        this.isDisabledGST = false;
        return true
      } else {
        this.isDisabledGST = true;
        this.PartyForm.controls.gstIn.setErrors({ incorrect: true });
        return
      }
    } else {
      this.isDisabledGST = true;
      this.PartyForm.controls.gstIn.setErrors({ incorrect: true });
      // this.setGSTINError = true;
    }
  }

  /**
   * get party by name
   * @param item
   */
  searchPartyByName(item) {
    this.saleInvoiceService
      .getPartyByName({}, this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, item)
      .then((response: any) => {
        this.partyData = response.data.rows;
        this.allPartyList = response.data.rows;
        if (this.allPartyList) {
          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(""),
            map((name) =>
              name ? this.filterNames(name) : this.allPartyList.slice()
            )
          );
        }
      });
  }
  setSelectedPartyDeatails(item) {
    this.partyId = item.id;
    if (this.partyData) {
      this.partyData.forEach((ele) => {
        this.SaleInvoiceForm.patchValue({
          gstNo: ele.gstIn,
          billingAddress: ele.billingAddress,
        });
        if (
          this.SaleInvoiceForm.controls.gstNo.value.slice(0, 2) ==
          this.userProfileGSTNo
        ) {
          this.gstStateMatch = true;
          this.supplytype = "INTRASTATE";
        } else {
          this.gstStateMatch = false;
          this.supplytype = "INTERSTATE";
        }
      });
    } else {
      this.saleInvoiceService
        .getPartyByName({}, this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, item.partyName)
        .then((response: any) => {
          this.partyData = response.data.rows;
          this.allPartyList = response.data.rows;
          this.allPartyList.forEach((ele) => {
            this.SaleInvoiceForm.patchValue({
              gstNo: ele.gstIn,
              billingAddress: ele.billingAddress,
            });
            if (
              this.SaleInvoiceForm.controls.gstNo.value.slice(0, 2) ==
              this.userProfileGSTNo
            ) {
              this.gstStateMatch = true;
              this.supplytype = "INTRASTATE";
            } else {
              this.gstStateMatch = false;
              this.supplytype = "INTERSTATE";
            }
          });
        });
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(""),
        map((name) =>
          name ? this.filterNames(name) : this.allPartyList.slice()
        )
      );
    }
  }
  /**
   * calculation total for product
   */
  async calculationTotal() {
    this.barcode = "";
    let price = 0;
    let additionalCess = 0;
    let gstInPer = 0;
    this.totalPrice = 0;
    this.subTotal = 0;
    this.gstRate = 0;
    this.amtToMinusFromTotal = 0;
    this.newGstRate = 0;
    this.productData.forEach((element) => {
      let gstInPer = element.gstRate;
      price = price + element.salePrice * element.quantity;
      this.gstRate = (element.salePrice - element.salePrice / (1 + gstInPer / 100)) * element.quantity + this.gstRate;
      this.gstAmount = (element.salePrice - element.salePrice / (1 + gstInPer / 100)) * element.quantity;
      additionalCess = (element.salePrice * element.additionalCess) / 100;
      this.subTotal = element.basePrice * element.quantity + this.subTotal;
      element.subTotal = element.basePrice * element.quantity;
      element.gstAmount =
      (element.salePrice - element.salePrice / (1 + gstInPer / 100)) *
      element.quantity;
      element.total = element.salePrice * element.quantity;
      gstInPer = element.gstRate;
      // new gst rate and amount to Minus from total amounnt for discounted 
      if(element.isFreeProduct) {
        // element.quantity = (element.quantity > 1) ? (element.quantity - element.freeProductQuantity) : element.quantity;
        this.subTotal = this.subTotal - element.basePrice * element.freeProductQuantity;      
        this.gstRate = this.gstRate - element.freeProductTotalGst;      
        price = price - element.freeProductTotalPrice;
        this.total = this.total - element.freeProductTotalPrice;     
      }

      if(element.offerData) {
        this.amtToMinusFromTotal = (element.total - element?.offerData.totalDiscountedPrice) + this.amtToMinusFromTotal;
        this.newGstRate = element.gstAmount - (element.offerData.originalGst + this.newGstRate);
      }
      
      if (this.isShown) {
        this.totalPrice = price;
      } else {
        this.totalPrice = price;
      }
    });
    if (this.gstType === "Exempted") {
      this.totalGST = 0;
      this.gstInRs = 0;
      this.gstInPer = 0;
    } else if (this.gstType === "Composite") {
      if (
        this.compositeType == "Manufacturer" ||
        this.compositeType == "Trader"
      ) {
        this.totalGST = (this.subTotal * 1) / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      } else if (this.compositeType == "Restaurant") {
        this.totalGST = (this.subTotal * 5) / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      } else {
        this.totalGST = (this.subTotal * 6) / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      }
    } else {
      this.totalGST = this.gstRate;
      this.gstInRs = this.gstRate;
      this.gstInPer = gstInPer;
    }

    this.additionalCess = additionalCess;
    if (this.isShown) {
      this.total = price;
    } else {
      this.total = price;
    }
  }

  getGoodsList(value) {
    if (value && (String(value).trim() !== '' || value !== null)) {
      this.biddingService.getAllProductsByUserSearch({}, this.SaleInvoiceForm.value.warehouse ? this.SaleInvoiceForm.value.warehouse : this.currentUser.id, value, 10, 1, this.SaleInvoiceForm.value.warehouse ? this.SaleInvoiceForm.value.warehouse : this.currentUser.id)
        .then((res) => {
          this.searchedProduct = res.data.pageData;
          // this.toatlPages = res.data.totalPages;
          // this.totalrow = res.data.totalRows;
          // this.p = res.data.currentPage;
          this.filteredOptions2 = this.myControl1.valueChanges
            .pipe(
              startWith(''),
              map(name => name ? this.filterNames(name) : this.searchedProduct.slice())
            );
          // this.byPid = res.data.pageData[0].id;
          //this.barcodeProduct=res.data.pageData[0].barcode;
          // this.OfferInfoForm.patchValue({
          // barcode: res.data.pageData[0].barcode,

          // })
        },
          (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
              // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
              // this.toastService.openErrorSnackBar("Something Went Wrong.");
            }
            // this.byPid = null;
            //this.barcodeProduct=res.data.pageData[0].barcode;
            // this.OfferInfoForm.patchValue({
            // barcode: null,

            // })
          })
    } else {
      // this.byPid = null;
      //this.barcodeProduct=res.data.pageData[0].barcode;
      // this.OfferInfoForm.patchValue({
      // barcode: null,

      // })
    }
  }


  /**
   * calculation for quantity change
   * @param i
   */
  changeQtyCalculation(i) {
    this.productData[i].quantity;
    this.calculationTotal();
  }

  /**
   * create sale invoice method
   */
  postSaleInvoice() {
    this.submitted = true;
    if (this.todaysDate == "") {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
      return false;
    } else {
      if (this.productData.length < 1) {
        // this.toastService.openErrorSnackBar(
        //   "Please Add At Least One Product To Proceed!!!"
        // );
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Add At Least One Product To Proceed!!!",
        });
        return false;
      }

      this.productData.forEach((e) => {
        let data = {
          quantity: e.quantity,
          barcode: e.barcode,
        };
        this.sendArray.push(data);
      });
      let data = {}
      const userType = this.currentUser.userType.toLowerCase();
      const warehouseIdToSend =
        userType === 'warehouse' ? this.currentUser.id :
        (userType === 'shop' ? (this.SaleInvoiceForm.value.warehouse || this.currentUser.id) :
        ((userType === 'client' && this.SaleInvoiceForm.value.warehouse !== null) ? this.SaleInvoiceForm.value.warehouse : this.currentUser.id));
      if (this.isCashSale) {
        data = {
          invoiceDetails: {
            cashSale: this.isCashSale,
            invoiceNo: this.SaleInvoiceForm.controls.invoiceNo.value.toString(),
            invoiceDate: this.todaysDate,
            credit: this.SaleInvoiceForm.controls.credit.value,
            roundOff: false,
            userId: this.SaleInvoiceForm.value.warehouse ? this.SaleInvoiceForm.value.warehouse : this.currentUser.id,
            lastInvoiceNumber: String(this.lastInvoiceNumber),
            // billingAddress: this.isCashSale
            //   ? null
            //   : this.SaleInvoiceForm.controls.billingAddress.value,
            // shippingAddress: this.isCashSale
            //   ? null
            //   : this.SaleInvoiceForm.controls.shippingAddress.value,
            // ewayBillNo: this.isCashSale
            //   ? null
            //   : this.SaleInvoiceForm.controls.ewayBillNo.value
            //     ? this.SaleInvoiceForm.controls.ewayBillNo.value
            //     : null,
            // mobile: this.isCashSale ? null : this.partyData[0].mobile,
            // customerName: this.isCashSale ? null : this.partyData[0].partyName,
            reverseCharge: this.SaleInvoiceForm.controls.reverseCharge.value,
            rcm: this.SaleInvoiceForm.controls.reverseCharge.value,
            supplyType: this.supplytype,
            placeOfSupply: this.SaleInvoiceForm.controls.shippedFrom.value ? this.SaleInvoiceForm.controls.shippedFrom.value : null,
            counterDiscountAmount : this.SaleInvoiceForm.value.counterDiscount ? this.SaleInvoiceForm.value.counterDiscount : 0,
            couponId : this.appliedCoupon?.coupon_id,
            warehouseId: `${warehouseIdToSend}`,
          },
          products: this.sendArray,
        };
      } else {
        data = {
          invoiceDetails: {
            cashSale: this.isCashSale,
            partyName: this.isCashSale ? null : this.partyName,
            invoiceNo: this.SaleInvoiceForm.controls.invoiceNo.value.toString(),
            invoiceDate: this.todaysDate,
            credit: this.SaleInvoiceForm.controls.credit.value,
            roundOff: false,
            userId: this.SaleInvoiceForm.value.warehouse ? this.SaleInvoiceForm.value.warehouse : this.currentUser.id,
            partyId: this.isCashSale ? null : this.partyId,
            lastInvoiceNumber: String(this.lastInvoiceNumber),
            billingAddress: this.isCashSale
              ? null
              : this.SaleInvoiceForm.controls.billingAddress.value,
            shippingAddress: this.isCashSale
              ? null
              : this.SaleInvoiceForm.controls.shippingAddress.value,
            ewayBillNo: this.isCashSale
              ? null
              : this.SaleInvoiceForm.controls.ewayBillNo.value
                ? this.SaleInvoiceForm.controls.ewayBillNo.value
                : null,
            mobile: this.isCashSale ? null : this.partyData[0].mobile,
            customerName: this.isCashSale ? null : this.partyData[0].partyName,
            reverseCharge: this.SaleInvoiceForm.controls.reverseCharge.value,
            rcm: this.SaleInvoiceForm.controls.reverseCharge.value,
            supplyType: this.supplytype,
            placeOfSupply: this.SaleInvoiceForm.controls.shippedFrom.value ? this.SaleInvoiceForm.controls.shippedFrom.value : null,
            counterDiscountAmount : this.SaleInvoiceForm.value.counterDiscount ? this.SaleInvoiceForm.value.counterDiscount : 0,
            couponId : this.appliedCoupon?.coupon_id,
            warehouseId: `${warehouseIdToSend}`,          },
          products: this.sendArray,
        };
      }

      this.saleInvoiceService.postSaleInvoice(data).then(
        (res) => {
          if (res.success === true) {
            if (res) {
              this.sendArray = [];
              this.toastService.toastMsg({
                title: "Success",
                content: "Sale Invoice Added Successfully!!!",
              });
              // this.toastService.openSnackBar("Sale Invoice Added Successfully");
            }
            this.saleInvoiceService.notifySaleInvoiceAdded();
            this.dialogRef.close();
          }
        },
        (err) => {
          this.sendArray = [];
          if (err.error.expose) {
            let errorMessage = err.error.error_message;
            if (errorMessage.includes('invoiceDetails.partyId')) {
                errorMessage = "Vendor Name  is required. Please select a valid Vendor Name option.";
            } else {
                errorMessage = this.titleCasePipe.transform(errorMessage);
            }
            this.toastService.toastMsg({
                title: "Error",
                content: errorMessage,
            });
          } else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        }
      );
    }
  }

  getSaleInvoiceNumber() {
    this.saleInvoiceService
      .getSaleInvoiceNumber({}, this.currentUser.id)
      .then((res) => {
        this.SaleInvoiceForm.patchValue({
          invoiceNo: String(res.data.prefix).concat(String(res.data.suffix)),
        });
        this.lastInvoiceNumber = res.data.suffix;
      });
  }

  toggleShow() {
    this.isShown = !this.isShown;
    if (this.isShown) {
      this.total = this.subTotal + this.totalGST + this.additionalCess;
    } else {
      this.total = this.subTotal + this.totalGST;
    }
  }

  /**
   * get gstin
   * @param gstin
   */
  getGstNumber(gstin) {
    this.partyService.getGstNumber({}, gstin).then(
      (res) => {
        this.gstDetails = res;
        if (res) {
          this.PartyForm.patchValue({
            gstType: res.data.taxPayerType,
            gstNo: res.data.gstIn,
          });
        }
        this.isDisabledGST = false;
      },
      (err) => {
        this.isDisabledGST = true;
      }
    );
  }

  get fp() {
    return this.PartyForm.controls;
  }
  /**
   * Post party details api call
   */
  addNewParty() {
    this.submittedParty = true;
    if (this.PartyForm.valid) {

      if (!this.isShowGstFields) {
        if ((this.PartyForm.controls.billingAddress.value == "" || this.PartyForm.controls.partyName.value == ""
          || this.PartyForm.controls.mobile.value == "" || this.PartyForm.controls.email.value == "")) {
          // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
          this.toastService.toastMsg({
            title: "Error",
            content: "Fill All Required Fields.",
          });
          return false;
        } else if ((this.PartyForm.controls.billingAddress.value == "" && this.PartyForm.controls.partyName.value == ""
          && this.PartyForm.controls.mobile.value == "" && this.PartyForm.controls.email.value == "")) {
          // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
          this.toastService.toastMsg({
            title: "Error",
            content: "Fill All Required Fields.",
          });
        }
        else {
          this.addPartyObject();
        }
      }
      else {
        if ((this.PartyForm.controls.billingAddress.value == "" || this.PartyForm.controls.partyName.value == ""
          || this.PartyForm.controls.mobile.value == "" || this.PartyForm.controls.email.value == ""
          || this.PartyForm.controls.gstType.value == "" || this.PartyForm.controls.gstIn.value == "")) {
          // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
          this.toastService.toastMsg({
            title: "Error",
            content: "Fill All Required Fields.",
          });
          return false;
        }

        else {
          this.addPartyObject();
        }
      }
    } else {
      // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields");
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
    }
  }

  addPartyObject() {
    this.finalObject = {
      "partyDetails": {
        "isCustomer": this.isShowGstFields,
        "gstType": this.PartyForm.controls.gstType.value ? this.PartyForm.controls.gstType.value : null,
        "gstIn": this.PartyForm.controls.gstIn.value ? this.PartyForm.controls.gstIn.value : null,
        "partyName": this.PartyForm.controls.partyName.value,
        "mobile": this.PartyForm.controls.mobile.value ? (this.PartyForm.controls.mobile.value).toString() : null,
        "email": this.PartyForm.controls.email.value.toLowerCase(),
        "billingAddress": this.PartyForm.controls.billingAddress.value,
        "shippingAddress": this.PartyForm.controls.shippingAddress.value,
        "openingBalance": null,
        "asOfDate": null,
        "balanceStatus": this.PartyForm.controls.balanceStatus.value,
        "payment": null,
        "creditPeriodDay": null,
        "creditLimit": null,
        "noOfCredits": null,
        "relationSince": null,
        "userId": this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
        "distance": null,
        "transporation": null,
      },
      "groupAdd": null
    }

    this.partyService.postParty(this.finalObject).then((res) => {
      this.paramID = res.data.id
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Vendor Added Successfully!!!",
        });
        // this.toastService.openSnackBar("Vendor Added Successfully")
        this.PartyForm.reset();
        this.modal.dismissAll();
        this.getAllParties();
        this.submittedParty = false;
      }
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })
  }

  customertoggleShow(value) {
    this.isShowGstFields = value;
  }

  /**
   * Get Customer product info for remote inovoice generation
   */
  getCustomerProductByOrderId() {
    this.subTotal = 0;
    this.totalGST = 0;
    this.total = 0;
    this.remoteShopService
      .getCustomerProductByOrderId({}, this.orderId)
      .then((res) => {
        if (res) {
          this.showInvoiceGenerateData = true;
        }
        this.SaleInvoiceForm.patchValue({
          partyName: res.data.customerName,
          billingAddress: res.data.billingAddress,
          gstNo: res.data.gstIn,
        });
        this.subTotal = res.data.totalBillAmount - res.data.billGstAmount;
        this.totalGST = res.data.billGstAmount;
        this.total = res.data.totalBillAmount;
        this.orderData = res.data.sales_products;
      });
  }

  /**
   * Generate Sale Invoice of Remote Order
   */
  patchGenerateRemoteInovice() {
    let data = {
      orderDetails: {
        orderId: this.orderId,
        invoiceDate: this.todaysDate,
        ewayBillNo: this.SaleInvoiceForm.controls.ewayBillNo.value,
        invoiceNo: this.SaleInvoiceForm.controls.invoiceNo.value,
        lastInvoiceNumber: this.lastInvoiceNumber,
      },
    };
    this.remoteShopService.patchGenerateRemoteInovice(data).then(
      (res) => {
        if (res) {
          this.showPrintBtn = true;
          this.toastService.toastMsg({
            title: "Success",
            content: "Receipt Generated!!!",
          });
          // this.toastService.openSnackBar("Receipt Generated!!!");
        }
      },
      (err) => {
        if (err.error.status == 406) {
          this.toastService.toastMsg({
            title: "Error",
            content: err.error.error_message,
          });
          // this.toastService.openErrorSnackBar(err.error.error_message);
        } else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Internal Server Error",
          });
          // this.toastService.openErrorSnackBar("Internal Server Error");
        }
      }
    );
  }

  /**
   * print receipt
   */
  onPrint() {
    const printContents = document.getElementById("panel").innerHTML;
    const originalContents = document.body.innerHTML;
    window.print();
  }

  /**
   * Get Invoice details by orderId
   */
  getInvoiceByOrderId() {
    this.remoteShopService.getInvoiceByOrderId({}, this.orderId).then((res) => {
      this.remoteProductData = res.data.sales_products;
      this.remoteGSTAmount = res.data.billGstAmount;
      this.remoteTotalBillAmount = res.data.totalBillAmount;
      this.billingAddress = res.data.billingAddress;
      this.customerName = res.data.customerName;
    });
  }

  /**
   * toggle for cash/sale
   * @param event
   */
  isCashSaleToggle(event) {
    this.isCashSale = event;
  }

  toggleCheckboxAll(event) {
    this.deleteProductData = [];
    this.isAllChecked = event;

    if (event) {
      this.deleteProductData = this.productData;
      for (let i = 0; i < this.deleteProductData.length; i++) {
        this.formArr.controls[i].patchValue({
          read: true,
        });
        this.checkedAll = true;
      }
    } else {
      this.deleteProductData = [];
      for (let i = 0; i < this.productData.length; i++) {
        this.formArr.controls[i].patchValue({
          read: false,
        });
        this.checkedAll = false;
      }
    }
  }
  async changeQuantity(sign, i, quantity = 0) {
    switch (sign) {
      case "-":
        if (this.productData[i].quantity > 1) {
          this.productData[i].quantity = this.productData[i].quantity - 1;
        }
        await this.checkFreeProducts(this.productData)
        await this.checkOffer(this.productData[i].quantity, this.productData[i].id)
        this.removeCoupon()
        // this.calculationTotal();
        break;
      case "+":
        this.productData[i].quantity = this.productData[i].quantity + 1;
        await this.checkFreeProducts(this.productData)
        await this.checkOffer(this.productData[i].quantity, this.productData[i].id)
        this.removeCoupon()
        // this.calculationTotal();
        break;
      case "rm":
        this.productData.splice(i, 1);
        if (this.productData.length <= 0) {
          this.totalGST = 0;
          this.subTotal = 0;
          this.total = 0;
          this.newGstRate = 0;
          this.gstRate = 0;
          this.amtToMinusFromTotal = 0;
          this.showCounterDiscountField = false;
          // this.allValuesZero = true
        }
        await this.checkFreeProducts(this.productData)
        await this.checkOffer(this.productData[i]?.quantity, this.productData[i]?.id)
        this.removeCoupon()
        // this.calculationTotal();
        break;
      case "qty":
        this.productData[i].quantity = quantity;
        await this.checkFreeProducts(this.productData)
        await this.checkOffer(this.productData[i].quantity, this.productData[i].id)
        this.removeCoupon()
        // this.calculationTotal();
        break;
      default:
        this.productData[i];
        break;
    }
  }


  toggleCheckboxSingle(event, i) {
    if (event) {
      for (let j = 0; j < this.productData.length; j++) {
        if (j == i) {
          this.formArr.controls[i].patchValue({
            read: true,
          });
          this.singleChecked = event;
          this.deleteProductData.push(this.productData[i]);
        }
      }
    } else {
      this.formArr.controls[i].patchValue({
        read: false,
      });
      this.singleChecked = this.selectedCheckBox;
      for (let n = 0; n <= this.deleteProductData.length; n++) {
        if (this.productData[i].barcode == this.deleteProductData[n].barcode)
          this.deleteProductData.splice(n, 1);
      }
    }
  }
  getCredit(e) {
    if (e.value == "true") {
      e.value = true;
    } else {
      e.value = false;
    }
    this.SaleInvoiceForm.controls.credit.value = e.value;
  }

  StringOnly(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  openAddPartyModal(name) {
    this.modal.open(name, {
      centered: true,
      backdrop: "static",
      size: "lg",
    });
  }
  modalDismiss() {
    this.PartyForm.reset()
    this.modal.dismissAll();
  }

  onCancelModal() {
    this.dialogRef.close();

  }

  // openDatepicker(){
  //   // Check if the current value of invoiceDate is an "Invalid Date"
  //   const selectedDate = this.SaleInvoiceForm.get('invoiceDate').value;
  //   if (isNaN(selectedDate.getTime())) {
  //     // Set a default date or any valid date here
  //     this.SaleInvoiceForm.get('invoiceDate').setValue(new Date());
  //   }
  // }

  openDatepicker(){
    const selectedDate = this.todaysDate;
    if (isNaN(selectedDate.getTime())) {
      this.todaysDate = new Date();
    }
  }
  
  onSearchInputChange(searchKey: string) {
    if (searchKey.length >= 3) {
      this.getGoodsList(searchKey);
    }
  }
  calculateFinalTotal(): number {
    const total = this.total;
    const amtToMinusFromTotal = this.amtToMinusFromTotal || 0;
    const counterDiscount = this.f.counterDiscount ? this.f.counterDiscount.value : 0;
    const couponDiscount = this.appliedCoupon ? this.appliedCoupon.discount_amount : 0;

    let finalTotal: number;

    // if (amtToMinusFromTotal !== 0 && counterDiscount !== 0 && couponDiscount !== 0) {
    //   finalTotal = total - amtToMinusFromTotal - counterDiscount - couponDiscount;
    // } else if(amtToMinusFromTotal === 0 && counterDiscount !== 0 && couponDiscount !== 0) {
    //   finalTotal = total - counterDiscount - couponDiscount;
    // } else if(amtToMinusFromTotal === 0 && counterDiscount !== 0 && couponDiscount !== 0) {
    //   finalTotal = total - counterDiscount - couponDiscount;
    // } else {
    //   finalTotal = total
    // }
    if ((amtToMinusFromTotal !== (undefined || null)) && (counterDiscount !== (undefined || null)) && (couponDiscount !== (undefined || null))) {
      finalTotal = total - (amtToMinusFromTotal || 0) - (counterDiscount || 0) - (couponDiscount || 0);
    } else {
      finalTotal = total
    }

    return finalTotal;
  }
  removeCoupon() {
    this.couponApplied = false;
    this.appliedCoupon = null
  }
  openCouponModal() {
    const dialogRef: MatDialogRef<ApplyCouponComponent> = this.dialog.open(ApplyCouponComponent, {
      width: '500px',
      height: 'auto',
      // data: { billAmt : this.total },
      data: {
        billAmt: this.total,/* Pass the bill amount */
        appliedCoupon: this.appliedCoupon, // Pass the appliedCoupon data to the modal
      },
      // data: { data },
      disableClose: true,
      // position: {
      //   right: '0',
      // },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.couponApplied) {
        // Handle the data received from the modal
        this.appliedCoupon = result.couponApplied;
        console.log(this.appliedCoupon)
        if(this.appliedCoupon !== (undefined || null)){
          this.couponApplied = true
        }
        this.calculationTotal()
      }
    });
  }

  async getCounterDiscountAmount(){
    this.counterOn = true
    await this.promotionService.counterDiscountAmount({ billAmount: Math.round(this.total - (this.amtToMinusFromTotal ? this.amtToMinusFromTotal : 0)) }, this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id).then(
      (response: any) => {
        if(response.success && response.data !== null) {
          this.counterAmt = response.data;
          const counterDiscountControl = this.SaleInvoiceForm.get('counterDiscount');
          counterDiscountControl?.setValue(Math.round(this.counterAmt.calculatedAmount))
        } else {
          this.counterOn = false
          this.toastService.toastMsg({
            title: 'Error',
            content: 'Please add data in the counter discount to apply discount'
          })
        }
      },
      (error) => {
        // Handle errors if the API call fails
        console.error('Error checking offer:', error);
      }
    );
    }
    getGoodsByBarcodeSelected(barcode, event) {
      if (event.isUserInput) {
        this.saleInvoiceService
          .getGoodsByBarcode({}, barcode, this.SaleInvoiceForm.value.warehouse ? this.SaleInvoiceForm.value.warehouse : this.currentUser.id)
          .then(async (response) => {
            response.data.quantity = 1;
            // Check if the barcode already exists in productData
            const existingProductIndex = this.productData.findIndex(
              (product) => product.barcode === response.data.barcode
              );
              
            if (existingProductIndex !== -1) {
              // Barcode exists, update quantity
              this.productData[existingProductIndex].quantity += 1;
              await this.checkFreeProducts(this.productData)
              await this.checkOffer(this.productData[existingProductIndex].quantity, this.productData[existingProductIndex].id)
              this.removeCoupon()
            } else {
              // Barcode doesn't exist, add new product
              response.data.quantity = 1;
              this.productData.push(response.data);
              this.addDetailsButtonClick(this.productData.length - 1);
              await this.checkFreeProducts(this.productData)
              await this.checkOffer(response.data.quantity, response.data.id)
              this.removeCoupon()
            }
  
            this.showCounterDiscountField = this.productData.length > 0;
            // this.calculationTotal();
            this.myControl1.patchValue('')
            document.getElementById('inp12').nodeValue = ''
          }, (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
            }
          });
      }
    }
  
   





    // Function to check offer for a product
    async checkOffer(quantity: number, productId: string) {
      if(quantity > 0 && quantity) {
        const requestData = {
          quantity : quantity,
          productId : productId
        }
        await this.promotionService.checkoffer(requestData, this.currentUser.id).then(
          (response: any) => {
            if(response.success && response.data !== null) {
              const offerData = response.data;
              // Loop through your formArr and update each form group with the 'offer' value
              this.productData.forEach((product: any) => {
                // Find the matching product in productData list using product id and freeProductId
                if (product.id === offerData.freeProductId && offerData.type === 'DISCOUNT') {
                  // Assign offer details to the matched product
                  product.isOffer = true;
                  product.offerData = response.data;
                }
              });
            } else {
              this.productData.forEach((product: any) => {
                if (product.id === productId) {
                  product.isOffer = false;
                  product.offerData = null;
                }
              })
            }    
          },
          (error) => {
            // Handle errors if the API call fails
            console.error('Error checking offer:', error);
          }
        );
      }
      this.calculationTotal()
    }

      // Function for a free product API
  async checkFreeProducts(productData: any[]) {
    const productArr = []
    productData.forEach((product) => {
      productArr.push({quantity: product.quantity, productId: product.id});
    })

    await this.promotionService.checkFreeProducts({productData: productArr}, this.currentUser.id).then(
      async (response: any) => {
        if (response.success && response.data !== null) {
          this.freeProductDataList = response.data.free;
          this.suggesionList = response.data.suggession;
          if(this.freeProductDataList && this.freeProductDataList.length) {
          
            // Loop through your formArr and update each form group with the 'free Product' value
            await this.productData.forEach(product => {
              // Find the matching product in productData list using product id and freeProductId
              const matchingFreeProduct = this.freeProductDataList.find(
                freeProduct => product.id === freeProduct.freeProductId
              );

              if (matchingFreeProduct) {
                product.isFreeProduct = true;
                product.schemaName = matchingFreeProduct.schemaName;
                product.freeProductQuantity = matchingFreeProduct.extraQuantity;
                product.freeProductTotalGst = matchingFreeProduct.gstOnExtraQuantity;
                product.freeProductTotalPrice = matchingFreeProduct.extraQuantityPrice;
                // product.freeProductsalePrice = matchingFreeProduct.freeProductsalePrice;
                // product.numberOfProductsApplied = matchingFreeProduct.numberOfProductsApplied;
                // product.freeProductExtraQuantity = matchingFreeProduct.extraQuantity;
                // product.freeProductExtraQuantityPrice = matchingFreeProduct.extraQuantityPrice;
              }
            });
          } else {
            this.productData.forEach((product: any) => {
              product.isFreeProduct = false;
            })
          }
        }  
      },
      (error) => {
        // Handle errors if the API call fails
        console.error('Error checking offer:', error);
      }
    );
    // this.calculationTotal()
  }
    // Function to handle selection change in warehouse selection dropdown
    onWarehouseSelectionChange(event: any) {
      if (event.value === 'clear') {
        this.SaleInvoiceForm.get('warehouse').setValue(null); // Clear the selection
      }
    }
}
