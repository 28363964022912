import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class EstimationService {

  constructor(public httpClient: HttpService,private http:HttpClient) { }
  getAllEstimationDetails(data: {},id,receiptType:string,filter,size,page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.GetAllEstimation +id + "?receiptsType=" + receiptType +"&filter=" + filter + "&size="+ size + "&page=" + page,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAllEstimationSearch(data: {},id,receiptType:string,filter,size,page,search): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.GetAllEstimation +id + "?receiptsType=" + receiptType +"&filter=" + filter + "&size="+ size + "&page=" + page + "&search="+search,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  postEstimationDetails(data:{}){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postEstimationDetails, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}
