<main class="main" id="top">
    <!-- <div class="{{ isFluid ? 'container-fluid' : 'container' }}" data-layout="container-fluid"> -->
    <div class="" data-layout="container-fluid">
        <script>
            var isFluid = JSON.parse(localStorage.getItem("isFluid"));
            if (isFluid) {
                var container = document.querySelector("[data-layout]");
                container.classList.remove("container");
                container.classList.add("container-fluid");
            }
        </script>

        <!-- side bar -->
        <nav class="navbar navbar-vertical navbar-expand-md " id="sidebarPrint">
            <div class="row">
                <script>
                    var navbarStyle = localStorage.getItem("isNavbarVerticalCollapsed");
                    if (isNavbarVerticalCollapsed === "true") {
                        document
                            .querySelector(".navbar-vertical")
                            .classList.add(`navbar-${navbarStyle}`);
                    }
                </script>
                <div class="d-flex align-items-center hide22">
                    <div class="toggle-icon-wrapper d-flex align-items-center m-2">

                        <button class="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                            data-bs-toggle="tooltip" data-bs-placement="left" title=""
                            data-bs-original-title="Toggle Navigation" aria-label="Toggle Navigation"><span
                                class="navbar-toggle-icon"><span class="toggle-line"></span></span></button>

                    </div>
                </div>
                <!-- <div class="d-flex align-items-center m-2">
                  <button class="btn navbar-toggler-humburger-icon navbar-vertical-toggle" data-bs-toggle="tooltip" data-bs-placement="left" title="" data-bs-original-title="Toggle Navigation" aria-label="Toggle Navigation"><span class="navbar-toggle-icon"><span class="toggle-line"></span></span></button>
                  <button class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse" aria-controls="navbarVerticalCollapse" aria-expanded="true" aria-label="Toggle Navigation"><span class="navbar-toggle-icon"><span class="toggle-line"></span></span></button>
                   <button class="btn navbar-toggler-humburger-icon navbar-vertical-toggle" data-bs-toggle="tooltip"
                      data-bs-placement="left" title="Toggle Navigation" style="display: inline-flex !important;
                      justify-content: flex-start !important;">
                      <span class="navbar-toggle-icon"><span class="toggle-line"></span></span>
                  </button> -->
                <!-- <button (click)=test() style="border: 0px !important;">
                      <span class="navbar-toggle-icon"><span class="toggle-line"></span></span>
                  </button> -->
                <!-- </div> -->

                <!-- <div class="col-2"> -->
                <!-- <a class="navbar-brand">
                      <div class="d-flex align-items-center py-3">
                          <img class="me-2" src="assets/img/icons/spot-illustrations/new-logo-iceipt-blue.png" alt=""
                              width="42" /> -->
                <!-- <span class="font-sans-serif">iCeipts</span> -->
                <!-- </div>
                  </a> -->
                <!-- </div> -->
            </div>
            <div class="custom-li collapse navbar-collapse" id="navbarVerticalCollapse"
                style="background-color: #110152!important;overflow: auto;width: 100%!important;margin:0!important;padding: 0!important;height:100vh;">
                <div class="navbar-vertical-content scrollbar" style="width: auto;">
                    <!-- <div class="navbar-vertical-content scrollbar"> -->
                    <ul class="navbar-nav d-flex align-items-center flex-column mb-3" id="navbarVerticalNav" *ngIf="!isArabic">

                        <!-- parent pages Dashboard-->
                        <li routerLink="/pages/dashboard" routerLinkActive="active"
                            class="nav-item d-flex justify-content-center mb-2 align-items-center">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="dashboard">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/Vector.png" class="bi-pie-chart">
                                </div>

                                <div style="font-size: 16px;" class="nav-link-text text-center">Dashboard</div>
                            </a>
                        </li>

                        <!-- parent pages Parties-->
                        <li routerLink="/pages/party-page" routerLinkActive="active"
                            class="nav-item d-flex justify-content-center mb-2 align-items-center"
                            *appHasPermission="['read']; name 'parties'">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="dashboard">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/party.png" class="bi-pie-chart">
                                </div>
                                <div style="font-size: 16px;" class="nav-link-text text-center">
                                    Contacts
                                </div>
                            </a>
                        </li>

                        <!-- parent pages Product-->
                        <li routerLink="/pages/new-product" routerLinkActive="active"
                            class="nav-item d-flex justify-content-center mb-2 align-items-center">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="products">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/product.png" class="bi-pie-chart"></div>
                                <div style="font-size: 16px;" class="nav-link-text text-center">Product</div>
                            </a>
                        </li>

                        <!-- parent pages Sale-->
                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center"
                            routerLinkActive="active" routerLink="/pages/sale_new">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="sale">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/sale.png" class="bi-pie-chart"></div>
                                <div style="font-size: 16px;" class="nav-link-text text-center">Sale</div>
                            </a>
                        </li>

                        <!-- parent pages Purchase-->
                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center"
                            routerLinkActive="active" routerLink="/pages/purchase-page">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="purchase">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/purchase.png" class="bi-pie-chart">
                                </div>

                                <div style="font-size: 16px;" class="nav-link-text text-center">Purchase</div>
                            </a>
                        </li>
                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center"
                        routerLinkActive="active" routerLink="/pages/prmotions">
                        <a class="nav-link" role="button" aria-expanded="true" aria-controls="Promotions">
                            <div class="nav-link-icon"><img style="font-size: 1.1rem;" width="27" height="27"
                                    src="/assets/icons/dashboard-icons/promotions.png" class="bi-pie-chart">
                            </div>

                            <div style="font-size: 16px;" class="nav-link-text text-center">Offers</div>
                        </a>
                    </li>
                          <!-- Warehouse Page  -->
                          <li class="nav-item d-flex justify-content-center mb-2 align-items-center"
                          routerLinkActive="active" routerLink="/pages/warehouse">
                          <a class="nav-link" role="button" aria-expanded="true">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                src="/assets/icons/dashboard-icons/warehouse.png" width = "20" height = "20" class="bi-pie-chart">
                              </div>

                              <div style="font-size: 16px;" class="nav-link-text text-center">Warehouse</div>
                          </a>
                      </li>

                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center"
                            routerLinkActive="active" routerLink="/pages/Bank-page">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="Expenses">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/bank&cash.png" class="bi bi-wallet">

                                </div>
                                <div style="font-size: 16px;" class="nav-link-text text-center">Bank</div>
                            </a>


                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active" routerLink="/pages/Bid-page">
                          <a class="nav-link" role="button"
                              aria-expanded="true" aria-controls="bid">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                      src="/assets/icons/dashboard-icons/bidding.png" width = "20" height = "20" class="bi-pie-chart"></div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Bid</div>
                          </a>
                      </li>

                        <!-- parent pages Shop schedule -->
                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active"
                      routerLink="/pages/shop-schedule-page" >
                          <a class="nav-link" role="button" aria-expanded="true" aria-controls="dashboard">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                      src="/assets/icons/dashboard-icons/shop-schedule.png" class="bi-pie-chart">
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Shop schedule</div>
                          </a>
                      </li>

                        <!-- parent pages Expenses -->
                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active" routerLink="/pages/expense_page">
                          <a class="nav-link" role="button"
                              aria-expanded="true" aria-controls="Expenses">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                      src="/assets/icons/dashboard-icons/expenses.png" class="bi-pie-chart"></div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Expenses</div>
                          </a>
                      </li>

                        <!-- parent pages Campaign -->
                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active" routerLink="/pages/campaign-page">
                          <a class="nav-link" role="button"
                              aria-expanded="true">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                src="/assets/icons/dashboard-icons/campaign.png" width = "20" height = "20" class="bi-pie-chart"></div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Campaign</div>
                          </a>
                      </li>

                        <!-- parent pages Invoice Number -->
                      <!-- <li class="nav-item d-flex justify-content-center mb-2 align-items-center">
                          <a class="nav-link" role="button" routerLinkActive="active" routerLink="/pages/dashboard"
                              aria-expanded="true" aria-controls="Expenses">
                                  <div class="nav-link-icon"><span style="font-size: 1.1rem;"
                                          class="bi-pie-chart"></span></div>
                                  <div style="font-size: 16px;"
                                      class="nav-link-text text-center">Invoice Number</div>
                          </a>
                      </li> -->

                        <!-- parent pages Admin -->
                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active" routerLink="/pages/Admin-page">
                          <a class="nav-link" role="button"
                              aria-expanded="true" aria-controls="Expenses">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                src="/assets/icons/dashboard-icons/admin.png" width = "20" height = "20" class="bi-pie-chart">
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Admin</div>
                          </a>
                      </li>

                        <!-- parent pages Employees -->
                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active"
                      routerLink="/pages/employee-page">
                          <a class="nav-link" role="button"  aria-expanded="true" aria-controls="Expenses">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;" 
                                src="/assets/icons/dashboard-icons/employee.png"  width="20" height="20" class="bi-pie-chart">
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Employees</div>
                          </a>
                      </li>

                      <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active"
                      routerLink="/pages/payroll-page">
                          <a class="nav-link" role="button"  aria-expanded="true" aria-controls="Expenses">
                              <div class="nav-link-icon"><span style="font-size: 1.1rem;" class="bi-pie-chart"></span>
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Payroll</div>
                          </a>
                      </li>

                      <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active"
                      routerLink="/pages/time-sheet">
                          <a class="nav-link" role="button"  aria-expanded="true" aria-controls="Expenses">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                src="/assets/icons/dashboard-icons/Timesheet.png" width = "20" height = "20" class="bi-pie-chart">
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Time Sheet</div>
                          </a>
                      </li>

                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active"
                      routerLink="/pages/crm">
                          <a class="nav-link" role="button"  aria-expanded="true" aria-controls="Expenses">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                src="/assets/icons/dashboard-icons/crm_ui.png" width = "20" height = "20" class="bi-pie-chart">
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">CRM</div>
                          </a>
                      </li>

                      <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active" routerLink="/pages/reportgst">
                          <a class="nav-link" role="button"
                              aria-expanded="true" aria-controls="gst">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                      src="/assets/icons/dashboard-icons/gst-report.png" class="bi-pie-chart"></div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">GST Report</div>
                          </a>
                      </li>

                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active"
                      routerLink="/pages/remote-shop-page" >
                          <a class="nav-link" role="button"aria-expanded="true" aria-controls="gst">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                      src="/assets/icons/dashboard-icons/remote-shopping.png" class="bi-pie-chart">
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center m-1">Remote Shopping</div>
                          </a>
                      </li>

                      <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active"
                      routerLink="/pages/ledger-page" >
                          <a class="nav-link" role="button"aria-expanded="true" aria-controls="gst">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                src="/assets/icons/dashboard-icons/ledger.png" width = "20" height = "20" class="bi-pie-chart">
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Ledger</div>
                          </a>
                      </li>

                      <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active"
                      routerLink="/pages/new-assets" >
                          <a class="nav-link" role="button"aria-expanded="true" aria-controls="gst">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                src="/assets/icons/dashboard-icons/assets.png" width = "20" height = "20" class="bi-pie-chart">
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Assets</div>
                          </a>
                      </li>

                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active"
                      routerLink="/pages/books-of-account" >
                          <a class="nav-link" role="button"aria-expanded="true" aria-controls="gst">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                src="/assets/icons/dashboard-icons/book_of_account.png" width = "20" height = "20" class="bi-pie-chart">
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Books of Account</div>
                          </a>
                      </li>

                      <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active"
                      routerLink="/pages/organization-structure" >
                          <a class="nav-link" role="button"aria-expanded="true" aria-controls="gst">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                src="/assets/icons/dashboard-icons/organization.png" width = "20" height = "20" class="bi-pie-chart">
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Organization Structure</div>
                          </a>
                      </li>

                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center"
                            routerLinkActive="active" routerLink="/pages/Contact-page-new">
                            <a class="nav-link" role="button" aria-expanded="true">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                  src="/assets/icons/dashboard-icons/contact_us.png" width = "20" height = "20" class="bi-pie-chart">
                                </div>

                                <div style="font-size: 16px;" class="nav-link-text text-center">Contact Us</div>
                            </a>
                        </li>

                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center"
                            routerLinkActive="active" routerLink="/pages/contactlist-page">
                            <a class="nav-link" role="button" aria-expanded="true">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;" 
                                    src="/assets/icons/dashboard-icons/tickets.png" width="20" height="20" class="bi-pie-chart">
                                </div>

                                <div style="font-size: 16px;" class="nav-link-text text-center">Tickets</div>
                            </a>
                        </li>
                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center"
                        routerLinkActive="active" routerLink="/pages/settings">
                        <a class="nav-link" role="button" aria-expanded="true">
                            <div class="nav-link-icon"><img style="font-size: 1.1rem;" 
                                src="/assets/icons/dashboard-icons/invoice-setting.png" width="20" height="20" class="bi-pie-chart">
                            </div>

                            <div style="font-size: 16px;" class="nav-link-text text-center">Settings</div>
                        </a>
                    </li>

                         <!-- <li class="nav-item d-flex justify-content-center mb-2 align-items-center"
                            routerLinkActive="active" routerLink="/pages/Setting-page">
                            <a class="nav-link" role="button" aria-expanded="true">
                                <div class="nav-link-icon"><span style="font-size: 1.1rem;" class="bi-pie-chart"></span>
                                </div>

                                <div style="font-size: 16px;" class="nav-link-text text-center">Invoice Setting</div>
                            </a>
                        </li> -->

                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center"
                            routerLinkActive="active" routerLink="/pages/help-faq-page">
                            <a class="nav-link" role="button" aria-expanded="true">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                  src="/assets/icons/dashboard-icons/help_faq.png" width = "20" height = "20" class="bi-pie-chart">
                                </div>

                                <div style="font-size: 16px;" class="nav-link-text text-center">Help and FAQs</div>
                            </a>
                        </li>

                    </ul>

                    <!-- For UAE -->
                    <ul class="navbar-nav d-flex align-items-center flex-column mb-3" id="navbarVerticalNav" *ngIf="isArabic">
                        <!-- parent pages Dashboard-->
                        <li routerLink="/pages/dashboard" routerLinkActive="active"
                            class="nav-item d-flex justify-content-center mb-2 align-items-center">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="dashboard">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/Vector.png" class="bi-pie-chart">
                                </div>

                                <div style="font-size: 16px;" class="nav-link-text text-center">Dashboard</div>
                            </a>
                        </li>
                        <!-- parent pages Parties-->
                        <li routerLink="/pages/party-page" routerLinkActive="active"
                            class="nav-item d-flex justify-content-center mb-2 align-items-center"
                            *appHasPermission="['read']; name 'parties'">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="dashboard">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/party.png" class="bi-pie-chart">
                                </div>
                                <div style="font-size: 16px;" class="nav-link-text text-center">
                                    Contacts
                                </div>
                            </a>
                        </li>
                        <!-- parent pages Product-->
                        <li routerLink="/pages/new-product" routerLinkActive="active"
                            class="nav-item d-flex justify-content-center mb-2 align-items-center">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="products">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/product.png" class="bi-pie-chart"></div>
                                <div style="font-size: 16px;" class="nav-link-text text-center">Product</div>
                            </a>
                        </li>
                        <!-- parent pages Sale-->
                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center"
                            routerLinkActive="active" routerLink="/pages/sale_new">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="sale">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/sale.png" class="bi-pie-chart"></div>
                                <div style="font-size: 16px;" class="nav-link-text text-center">Sale</div>
                            </a>
                        </li>
                        <!-- parent pages Purchase-->
                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center"
                            routerLinkActive="active" routerLink="/pages/purchase-page">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="purchase">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/purchase.png" class="bi-pie-chart">
                                </div>

                                <div style="font-size: 16px;" class="nav-link-text text-center">Purchase</div>
                            </a>
                        </li>



                        <!-- <li class="nav-item d-flex justify-content-center mb-2 align-items-center"
                            routerLinkActive="active" routerLink="/pages/Bank-page">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="Expenses">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/bank&cash.png" class="bi bi-wallet">

                                </div>
                                <div style="font-size: 16px;" class="nav-link-text text-center">Bank</div>
                            </a> -->


                        <!-- <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active" routerLink="/pages/Bid-page">
                          <a class="nav-link" role="button"
                              aria-expanded="true" aria-controls="bid">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                      src="/assets/icons/dashboard-icons/bid.png" class="bi-pie-chart"></div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Bid</div>
                          </a>
                      </li> -->
                        <!-- parent pages Shop schedule -->
                        <!-- <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active"
                      routerLink="/pages/shop-schedule-page" >
                          <a class="nav-link" role="button" aria-expanded="true" aria-controls="dashboard">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                      src="/assets/icons/dashboard-icons/shop-schedule.png" class="bi-pie-chart">
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Shop schedule</div>
                          </a>
                      </li> -->

                        <!-- parent pages Expenses -->
                        <!-- <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active" routerLink="/pages/expense_page">
                          <a class="nav-link" role="button"
                              aria-expanded="true" aria-controls="Expenses">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                      src="/assets/icons/dashboard-icons/expenses.png" class="bi-pie-chart"></div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Expenses</div>
                          </a>
                      </li> -->

                        <!-- parent pages Campaign -->
                        <!-- <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active" routerLink="/pages/campaign-page">
                          <a class="nav-link" role="button"
                              aria-expanded="true">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                      src="/assets/icons/dashboard-icons/expenses.png" class="bi-pie-chart"></div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Campaign</div>
                          </a>
                      </li> -->


                        <!-- parent pages Invoice Number
                      <li class="nav-item d-flex justify-content-center mb-2 align-items-center">
                          <a class="nav-link" role="button" routerLinkActive="active" routerLink="/pages/dashboard"
                              aria-expanded="true" aria-controls="Expenses">
                                  <div class="nav-link-icon"><span style="font-size: 1.1rem;"
                                          class="bi-pie-chart"></span></div>
                                  <div style="font-size: 16px;"
                                      class="nav-link-text text-center">Invoice Number</div>
                          </a>
                      </li> -->
                        <!-- parent pages Admin -->
                        <!-- <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active" routerLink="/pages/Admin-page">
                          <a class="nav-link" role="button"
                              aria-expanded="true" aria-controls="Expenses">
                              <div class="nav-link-icon"><span style="font-size: 1.1rem;" class="bi-pie-chart"></span>
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Admin</div>
                          </a>
                      </li> -->
                        <!-- parent pages Employees -->
                        <!-- <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active"
                      routerLink="/pages/employee-page">
                          <a class="nav-link" role="button"  aria-expanded="true" aria-controls="Expenses">
                              <div class="nav-link-icon"><span style="font-size: 1.1rem;" class="bi-pie-chart"></span>
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Employees</div>
                          </a>
                      </li>
                      <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active"
                      routerLink="/pages/payroll-page">
                          <a class="nav-link" role="button"  aria-expanded="true" aria-controls="Expenses">
                              <div class="nav-link-icon"><span style="font-size: 1.1rem;" class="bi-pie-chart"></span>
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Payroll</div>
                          </a>
                      </li>
                      <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active"
                      routerLink="/pages/time-sheet">
                          <a class="nav-link" role="button"  aria-expanded="true" aria-controls="Expenses">
                              <div class="nav-link-icon"><span style="font-size: 1.1rem;" class="bi-pie-chart"></span>
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Time Sheet</div>
                          </a>
                      </li> -->
                        <!-- <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active"
                      routerLink="/pages/crm">
                          <a class="nav-link" role="button"  aria-expanded="true" aria-controls="Expenses">
                              <div class="nav-link-icon"><span style="font-size: 1.1rem;" class="bi-pie-chart"></span>
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">CRM</div>
                          </a>
                      </li>

                      <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active" routerLink="/pages/reportgst">
                          <a class="nav-link" role="button"
                              aria-expanded="true" aria-controls="gst">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                      src="/assets/icons/dashboard-icons/gst-report.png" class="bi-pie-chart"></div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">GST Report</div>
                          </a>
                      </li> -->

                        <!-- <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active"
                      routerLink="/pages/remote-shop-page" >
                          <a class="nav-link" role="button"aria-expanded="true" aria-controls="gst">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                      src="/assets/icons/dashboard-icons/remote-shopping.png" class="bi-pie-chart">
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center m-1">Remote Shopping</div>
                          </a>
                      </li> -->

                      <!-- <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active"
                      routerLink="/pages/ledger-page" >
                          <a class="nav-link" role="button"aria-expanded="true" aria-controls="gst">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                      src="/assets/icons/dashboard-icons/remote-shopping.png" class="bi-pie-chart">
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Ledger</div>
                          </a>
                      </li> -->
                      <!--
                      <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active"
                      routerLink="/pages/new-assets" >
                          <a class="nav-link" role="button"aria-expanded="true" aria-controls="gst">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                      src="/assets/icons/dashboard-icons/remote-shopping.png" class="bi-pie-chart">
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Assets</div>
                          </a>
                      </li> -->


                        <!-- <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active"
                      routerLink="/pages/books-of-account" >
                          <a class="nav-link" role="button"aria-expanded="true" aria-controls="gst">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                      src="/assets/icons/dashboard-icons/remote-shopping.png" class="bi-pie-chart">
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Books of Account</div>
                          </a>
                      </li>

                      <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active"
                      routerLink="/pages/organization-structure" >
                          <a class="nav-link" role="button"aria-expanded="true" aria-controls="gst">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                      src="/assets/icons/dashboard-icons/remote-shopping.png" class="bi-pie-chart">
                              </div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Organization Structure</div>
                          </a>
                      </li> -->

                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center"
                            routerLinkActive="active" routerLink="/pages/Contact-page-new">
                            <a class="nav-link" role="button" aria-expanded="true">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                  src="/assets/icons/dashboard-icons/contact_us.png" width = "20" height = "20" class="bi-pie-chart">
                                </div>

                                <div style="font-size: 16px;" class="nav-link-text text-center">Contact Us</div>
                            </a>
                        </li>

                        <!-- <li class="nav-item d-flex justify-content-center mb-2 align-items-center"
                            routerLinkActive="active" routerLink="/pages/contactlist-page">
                            <a class="nav-link" role="button" aria-expanded="true">
                                <div class="nav-link-icon"><span style="font-size: 1.1rem;" class="bi-pie-chart"></span>
                                </div>

                                <div style="font-size: 16px;" class="nav-link-text text-center">Tickets</div>
                            </a>
                        </li> -->
                        <!-- <li class="nav-item d-flex justify-content-center mb-2 align-items-center"
                            routerLinkActive="active" routerLink="/pages/Setting-page">
                            <a class="nav-link" role="button" aria-expanded="true">
                                <div class="nav-link-icon"><span style="font-size: 1.1rem;" class="bi-pie-chart"></span>
                                </div>

                                <div style="font-size: 16px;" class="nav-link-text text-center">Invoice Setting</div>
                            </a>
                        </li> -->

                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center"
                            routerLinkActive="active" routerLink="/pages/help-faq-page">
                            <a class="nav-link" role="button" aria-expanded="true">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                  src="/assets/icons/dashboard-icons/help_faq.png" width = "20" height = "20" class="bi-pie-chart">
                                </div>

                                <div style="font-size: 16px;" class="nav-link-text text-center">Help and FAQs</div>
                            </a>
                        </li>


                        <!-- Old settings -->


                        <!-- Old Remote shopping -->
                        <!-- <li class="nav-item d-flex justify-content-center mb-2 align-items-center">
                          <a class="nav-link dropdown-indicator" href="#remoteShopping" role="button"
                              data-bs-toggle="collapse" aria-expanded="false" aria-controls="remoteshop">
                              <div class="nav-link-icon"><span style="font-size: 1.1rem;" class="bi bi-gear"></span>
                              </div>

                              <div style="font-size: 16px;" class="nav-link-text text-center">Remote Shopping</div>
                          </a>
                      </li>



                      <ul class="nav collapse" id="remoteShopping">
                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" data-bs-toggle=""
                                  routerLink="/pages/remote-retailer" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Remote Orders</span>
                                  </div>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/order-receipts"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Order Receipts</span>
                                  </div>
                              </a>
                          </li>
                      </ul> -->


                        <!-- parent pages Admin -->
                        <li class="nav-item mb-2" *appHasPermission="['read']; name 'grantpermission'">
                            <!-- <div class="row navbar-vertical-label-wrapper mt-3 mb-2">
                              <div class="col-auto navbar-vertical-label d-flex justify-content-center">Setting</div>
                              <div class="col ps-0">
                                  <hr class="mb-0 navbar-vertical-divider" />
                              </div>
                              </div> -->
                            <!-- <a class="nav-item d-flex justify-content-center mb-2 align-items-center " routerLinkActive="active" routerLink="/pages/invoice-number"
                                  role="button" data-bs-toggle="" aria-expanded="false" aria-controls="wishlist">
                                  <div class="d-flex item-align-center">
                                      <span class="nav-link-icon "><span style="font-size: 1.1rem;" class="bi bi-card-list"></span></span>
                                  </div>
                                  <div class="d-flex item-align-center">
                                      <span
                                          style="font-size: 16px;" class="nav-link-text ">Invoice Number</span>
                                  </div>
                              </a> -->

                        </li>
                        <!-- <ul class="nav collapse" id="admin">
                          <li class="nav-item" *appHasPermission="['read']; name 'grantpermission'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/add-role"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Add Role</span>
                                  </div>
                              </a>
                   more inner pages-->
                        <!-- </li>

                          <li class="nav-item" *appHasPermission="['read']; name 'grantpermission'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/roles-list"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Roles List</span>
                                  </div>
                              </a>
                       more inner pages-->
                        <!-- </li>
                      </ul> -->

                    </ul>
                </div>
            </div>
        </nav>

        <div class="content" style="width: auto;margin-right: 0 !important;padding-left: 10px;padding-right: 10px;">
            <!-- Header -->
            <!-- <nav class="navbar navbar-light navbar-glass navbar-top navbar-expand">
              <button class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3" type="button"
                  data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse"
                  aria-controls="navbarVerticalCollapse" aria-expanded="false" aria-label="Toggle Navigation">
                  <span class="navbar-toggle-icon"><span class="toggle-line"></span></span>
              </button>
              <a class="navbar-brand me-1 me-sm-3">
                  <div class="d-flex align-items-center">
                      <img class="me-2" src="assets/img/icons/spot-illustrations/48_icon3.png" alt=""
                          width="40" /><span class="font-sans-serif">iCeipts</span>
                  </div>
              </a> -->
            <!-- <ul class="navbar-nav align-items-center d-none d-lg-block">
                  <li class="nav-item">
                      <div class="search-box" data-list='{"valueNames":["title"]}'>
                          <form class="position-relative" data-bs-toggle="search" data-bs-display="static">
                              <input class="form-control search-input fuzzy-search" type="search"
                                  placeholder="Search..." aria-label="Search" />
                              <span class="fas fa-search search-box-icon"></span>
                          </form>
                          <div class="btn-close-falcon-container position-absolute end-0 top-50 translate-middle shadow-none"
                              data-bs-dismiss="search">
                              <div class="btn-close-falcon" aria-label="Close"></div>
                          </div>
                          <div class="dropdown-menu border font-base start-0 mt-2 py-0 overflow-hidden w-100">
                              <div class="scrollbar list py-3" style="max-height: 24rem">
                                  <h6 class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2">
                                      Recently Browsed
                                  </h6>
                                  <a class="dropdown-item fs--1 px-card py-1 hover-primary"
                                      href="app/events/event-detail.html">
                                      <div class="d-flex align-items-center">
                                          <span class="fas fa-circle me-2 text-300 fs--2"></span>

                                          <div class="fw-normal title">
                                              Pages
                                              <span class="fas fa-chevron-right mx-1 text-500 fs--2"
                                                  data-fa-transform="shrink-2"></span> Events
                                          </div>
                                      </div>
                                  </a>
                                  <a class="dropdown-item fs--1 px-card py-1 hover-primary"
                                      routerLink="/pages/customer-list">
                                      <div class="d-flex align-items-center">
                                          <span class="fas fa-circle me-2 text-300 fs--2"></span>

                                          <div class="fw-normal title">
                                              E-commerce
                                              <span class="fas fa-chevron-right mx-1 text-500 fs--2"
                                                  data-fa-transform="shrink-2"></span> Customers
                                          </div>
                                      </div>
                                  </a>

                                  <hr class="bg-200 dark__bg-900" />
                                  <h6 class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2">
                                      Suggested Filter
                                  </h6>
                                  <a class="dropdown-item px-card py-1 fs-0" href="app/e-commerce/customers.html">
                                      <div class="d-flex align-items-center">
                                          <span
                                              class="badge fw-medium text-decoration-none me-2 badge-soft-warning">customers:</span>
                                          <div class="flex-1 fs--1 title">All customers list</div>
                                      </div>
                                  </a>
                                  <a class="dropdown-item px-card py-1 fs-0" href="app/events/event-detail.html">
                                      <div class="d-flex align-items-center">
                                          <span
                                              class="badge fw-medium text-decoration-none me-2 badge-soft-success">events:</span>
                                          <div class="flex-1 fs--1 title">
                                              Latest events in current month
                                          </div>
                                      </div>
                                  </a>
                                  <a class="dropdown-item px-card py-1 fs-0"
                                      href="app/e-commerce/product/product-grid.html">
                                      <div class="d-flex align-items-center">
                                          <span
                                              class="badge fw-medium text-decoration-none me-2 badge-soft-info">products:</span>
                                          <div class="flex-1 fs--1 title">
                                              Most popular products
                                          </div>
                                      </div>
                                  </a>

                                  <hr class="bg-200 dark__bg-900" />
                                  <h6 class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2">
                                      Files
                                  </h6>
                                  <a class="dropdown-item px-card py-2" href="#!">
                                      <div class="d-flex align-items-center">
                                          <div class="file-thumbnail me-2">
                                              <img class="border h-100 w-100 fit-cover rounded-3"
                                                  src="assets/img/products/3-thumb.png" alt="" />
                                          </div>
                                          <div class="flex-1">
                                              <h6 class="mb-0 title">iPhone</h6>
                                              <p class="fs--2 mb-0 d-flex">
                                                  <span class="fw-semi-bold">Antony</span><span
                                                      class="fw-medium text-600 ms-2">27 Sep at 10:30
                                                      AM</span>
                                              </p>
                                          </div>
                                      </div>
                                  </a>
                                  <a class="dropdown-item px-card py-2" href="#!">
                                      <div class="d-flex align-items-center">
                                          <div class="file-thumbnail me-2">
                                              <img class="img-fluid" src="assets/img/icons/zip.png" alt="" />
                                          </div>
                                          <div class="flex-1">
                                              <h6 class="mb-0 title">Falcon v1.8.2</h6>
                                              <p class="fs--2 mb-0 d-flex">
                                                  <span class="fw-semi-bold">John</span><span
                                                      class="fw-medium text-600 ms-2">30 Sep at 12:30
                                                      PM</span>
                                              </p>
                                          </div>
                                      </div>
                                  </a>

                                  <hr class="bg-200 dark__bg-900" />
                                  <h6 class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2">
                                      Members
                                  </h6>
                                  <a class="dropdown-item px-card py-2" href="pages/user/profile.html">
                                      <div class="d-flex align-items-center">
                                          <div class="avatar avatar-l status-online me-2">
                                              <img class="rounded-circle" src="assets/img/team/1.jpg" alt="" />
                                          </div>
                                          <div class="flex-1">
                                              <h6 class="mb-0 title">Anna Karinina</h6>
                                              <p class="fs--2 mb-0 d-flex">Technext Limited</p>
                                          </div>
                                      </div>
                                  </a>
                                  <a class="dropdown-item px-card py-2" href="pages/user/profile.html">
                                      <div class="d-flex align-items-center">
                                          <div class="avatar avatar-l me-2">
                                              <img class="rounded-circle" src="assets/img/team/2.jpg" alt="" />
                                          </div>
                                          <div class="flex-1">
                                              <h6 class="mb-0 title">Antony Hopkins</h6>
                                              <p class="fs--2 mb-0 d-flex">Brain Trust</p>
                                          </div>
                                      </div>
                                  </a>
                                  <a class="dropdown-item px-card py-2" href="pages/user/profile.html">
                                      <div class="d-flex align-items-center">
                                          <div class="avatar avatar-l me-2">
                                              <img class="rounded-circle" src="assets/img/team/3.jpg" alt="" />
                                          </div>
                                          <div class="flex-1">
                                              <h6 class="mb-0 title">Emma Watson</h6>
                                              <p class="fs--2 mb-0 d-flex">Google</p>
                                          </div>
                                      </div>
                                  </a>
                              </div>
                              <div class="text-center mt-n3">
                                  <p class="fallback fw-bold fs-1 d-none">No Result Found.</p>
                              </div>
                          </div>
                      </div>
                  </li>
              </ul> -->
            <nav class="navbar navbar-expand-md bg-white" id="headNavPrint">
                <div class="container-fluid">
                    <a class="navbar-brand ">
                        <button class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3 collapsed"
                            type="button" data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse"
                            aria-controls="navbarVerticalCollapse" aria-expanded="false"
                            aria-label="Toggle Navigation"><span class="navbar-toggle-icon"><span
                                    class="toggle-line"></span></span></button>
                        <div class="d-flex align-items-center py-3">
                            <!-- <img class="me-2" src="assets/img/icons/spot-illustrations/new-logo-iceipt-blue.png" alt=""
                              width="42" /> -->
                            <img class="me-2" src="assets/img/icons/spot-illustrations/test_logo.png" alt=""
                                alt="iceipts logo" width="35" height="35" id = "printPageButton" />
                            <!-- <img class="me-2" src="assets/img/icons/spot-illustrations/TitleIcon.png" alt=""
                                alt="iceipts logo" width="50" height="50" /> -->
                            <!-- <img src="assets/img/icons/spot-illustrations/Logo-2.png"
                                                alt="iceipts logo" width="40" height="40" style="position: absolute !important; top: -17% !important;left: 45%"> -->
                            <!-- <span class="font-sans-serif">iCeipts</span> -->
                        </div>
                    </a>

                    <form #insideElement #searchInput class="search-form" (ngSubmit)="onSearch()" id="printPageButton">
                        <input type="search" [(ngModel)]="searchTerm" name="searchTerm" placeholder="Search Here..."
                            (input)="onSearch()" (keyup.enter)="onEnter()">
                        <button type="submit" (keyup.enter)="onSearch()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                    </form>

                    <div class="dropdown" [ngClass]="{'show': filteredCategories.length > 0}" (click)="closeDropdown()">
                        <button class="dropbtn align-items-center"
                            [style.display]="filteredCategories.length > 0 ? 'flex' : 'none'">Categories</button>
                        <div class="dropdown-content"
                            [style.display]="filteredCategories.length > 0 ? 'block' : 'none'">
                            <a *ngFor="let category of filteredCategories"
                                [routerLink]="categoryRouterLinks[category]">{{ category }}</a>
                        </div>
                    </div>


                    <div class="collapse navbar-collapse nav-list-items" id="navbarNav">
                        <div class="navbar-nav navbar-horizantal">
                            <!-- <li class="new nav-item me-2">
                              <mat-form-field appearance="outline" style="width: 100px;">
                                  <mat-select class="custom-mat-select" value="en">
                                      <mat-option value="en">Eng (US)</mat-option>
                                      <mat-option value="fr">French</mat-option>
                                      <mat-option value="de">German</mat-option>
                                  </mat-select>
                              </mat-form-field>
                          </li> -->

                            <div class="nav-item createNewButton navOption-btns" *ngIf="!isArabic">
                                <button type="button" id="printPageButton" class="btn text-nowrap" [matMenuTriggerFor]="createNew">
                                    <span>
                                        <i class="bi bi-check2-circle my-2 mx-1"></i></span>
                                    Create New</button>
                                <mat-menu #createNew="matMenu">
                                    <button mat-menu-item [matMenuTriggerFor]="Contact">Contact</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Product">Product</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Sale">Sale</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Purchase">Purchase</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Bank">Bank</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Shop_Schedule">Shop Schedule</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Expense">Expense</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Campaign">Campaign</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Admin">Admin</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Employee">Employee</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Payroll">Payroll</button>
                                    <button mat-menu-item [matMenuTriggerFor]="TimeSheet">Time Sheet</button>
                                    <button mat-menu-item [matMenuTriggerFor]="CRM">CRM</button>
                                </mat-menu>
                                <mat-menu #Contact="matMenu">
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Contact')">Contact</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Vendor_Group')">Vendor
                                        Group</button>
                                </mat-menu>

                                <mat-menu #Product="matMenu">
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Product')">Product</button>
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Service')">Service</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Offer')">Offer</button>
                                </mat-menu>

                                <mat-menu #Sale="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('sale-invoice')">Sale
                                        Invoice</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('payment-in')">Payment
                                        In</button>
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Estimation-Invoice')">Estimation Invoice</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('sale-order')">Sale
                                        Order</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('sale-return')">Sale
                                        Return</button>
                                </mat-menu>

                                <mat-menu #Purchase="matMenu">
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('purchase-invoice')">Purchase Invoice</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('payment-out')">Payment
                                        Out</button>
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('purchase-order')">Purchase Order</button>
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('purchase-return')">Purchase Return</button>
                                </mat-menu>

                                <mat-menu #Bank="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('bank')">Bank</button>
                                </mat-menu>

                                <mat-menu #Shop_Schedule="matMenu">
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Appointment')">Appointment</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Time-Schedule')">Time
                                        Schedule</button>
                                </mat-menu>

                                <mat-menu #Expense="matMenu">
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Expense')">Expense</button>
                                </mat-menu>

                                <mat-menu #Campaign="matMenu">
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Campaign')">Campaign</button>
                                </mat-menu>

                                <mat-menu #Admin="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Admin')">Admin</button>
                                </mat-menu>

                                <mat-menu #Employee="matMenu">
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Employee')">Employee</button>
                                </mat-menu>

                                <mat-menu #Payroll="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('New-Structure')">New
                                        Structure</button>
                                </mat-menu>

                                <mat-menu #TimeSheet="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Time')">Time</button>
                                </mat-menu>

                                <mat-menu #CRM="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Lead')">Lead</button>
                                </mat-menu>
                            </div>

                            <!-- For UAE -->
                            <div class="nav-item createNewButton navOption-btns" *ngIf="isArabic">
                                <button type="button" class="btn text-nowrap" [matMenuTriggerFor]="createNew">
                                    <span>
                                        <i class="bi bi-check2-circle my-2 mx-1"></i></span>
                                    Create New</button>
                                <mat-menu #createNew="matMenu">
                                    <button mat-menu-item [matMenuTriggerFor]="Contact">Contact</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Product">Product</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Sale">Sale</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Purchase">Purchase</button>
                                    <!-- <button mat-menu-item [matMenuTriggerFor]="Bank">Bank</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Shop_Schedule">Shop Schedule</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Expense">Expense</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Campaign">Campaign</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Admin">Admin</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Employee">Employee</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Payroll">Payroll</button>
                                    <button mat-menu-item [matMenuTriggerFor]="TimeSheet">Time Sheet</button>
                                    <button mat-menu-item [matMenuTriggerFor]="CRM">CRM</button> -->
                                </mat-menu>
                                <mat-menu #Contact="matMenu">
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Contact')">Contact</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Vendor_Group')">Vendor
                                        Group</button>
                                </mat-menu>

                                <mat-menu #Product="matMenu">
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Product')">Product</button>
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Service')">Service</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Offer')">Offer</button>
                                </mat-menu>

                                <mat-menu #Sale="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('sale-invoice')">Sale
                                        Invoice</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('payment-in')">Payment
                                        In</button>
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Estimation-Invoice')">Estimation Invoice</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('sale-order')">Sale
                                        Order</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('sale-return')">Sale
                                        Return</button>
                                </mat-menu>

                                <mat-menu #Purchase="matMenu">
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('purchase-invoice')">Purchase Invoice</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('payment-out')">Payment
                                        Out</button>
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('purchase-order')">Purchase Order</button>
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('purchase-return')">Purchase Return</button>
                                </mat-menu>

                                <mat-menu #Bank="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('bank')">Bank</button>
                                </mat-menu>

                                <mat-menu #Shop_Schedule="matMenu">
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Appointment')">Appointment</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Time-Schedule')">Time
                                        Schedule</button>
                                </mat-menu>

                                <mat-menu #Expense="matMenu">
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Expense')">Expense</button>
                                </mat-menu>

                                <mat-menu #Campaign="matMenu">
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Campaign')">Campaign</button>
                                </mat-menu>

                                <mat-menu #Admin="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Admin')">Admin</button>
                                </mat-menu>

                                <mat-menu #Employee="matMenu">
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Employee')">Employee</button>
                                </mat-menu>

                                <mat-menu #Payroll="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('New-Structure')">New
                                        Structure</button>
                                </mat-menu>

                                <mat-menu #TimeSheet="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Time')">Time</button>
                                </mat-menu>

                                <mat-menu #CRM="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Lead')">Lead</button>
                                </mat-menu>
                            </div>

                            <div class="nav-item reportButton navOption-btns" *ngIf="!isArabic">
                                <button type="button" id="printPageButton" class="btn" [matMenuTriggerFor]="Reports">
                                    <span>
                                        <i class="bi bi-check2-circle m-2"></i></span>
                                    Reports</button>
                                <mat-menu #Reports="matMenu">
                                    <button mat-menu-item [matMenuTriggerFor]="Contact_Reports">Contact Reports</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Product_Reports">Product Reports</button>
                                </mat-menu>
                                <mat-menu #Contact_Reports="matMenu">
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/party-statement">Contact Statement</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/party-wise-profit-loss">Contact Wise Profit &
                                        Loss</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/all-parties">All Contacts</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/party-report-by-item">Contact Report By Item</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/sale-purchase-by-party">Sale Purchase By Contact</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/sale-purchase-by-party-group"> Sale
                                        Purchase By Contact Group</button>
                                </mat-menu>

                                <mat-menu #Product_Reports="matMenu">
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/item-batch-report">Item
                                        Batch Report</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/item-detail">Item Detail</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/item-report-by-party">Item Report By Contact</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/item-wise-discount">Item Wise Discount</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/item-wise-profit-and-loss">Item Wise Profit &
                                        Loss</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/low-stock-summary">Low
                                        Stock Summary</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/stock-summary">Stock Summary</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/stock-detail">Stock Detail</button>
                                </mat-menu>
                            </div>

                            <div class="nav-item mx-4 bellIcon">

                                <div class=" col-4">
                                    <a class="nav-link notification-indicator notification-indicator-primary px-2 fa-icon-wait"
                                        *ngIf="isShow" id="navbarDropdownNotification" role="button"
                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span
                                            class="svg-inline--fa fa fa-bell fa-w-14 fs-2"
                                            data-fa-transform="shrink-6"></span></a>
                                    <a *ngIf="!isShow" style="color: var(--falcon-navbar-light-color)"
                                        id="navbarDropdownNotification" role="button" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false"><span id="printPageButton"
                                            class="svg-inline--fa fa fa-bell fa-w-14 fs-2"
                                            data-fa-transform="shrink-6"></span></a>
                                    <div class="dropdown-menu dropdown-menu-end dropdown-menu-card dropdown-menu-notification dropdown-caret-bg"
                                        aria-labelledby="navbarDropdownNotification">
                                        <div class="card card-notification shadow-none">
                                            <div class="card-header">
                                                <div class="row justify-content-between align-items-center">
                                                    <div class="col-auto">
                                                        <h4 class="card-header-title mb-0">Notifications</h4>
                                                    </div>
                                                    <div class="col-auto ps-0 ps-sm-3">
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="font-size: 12px;" class="scrollbar-overlay"
                                                style="max-height: 19rem">
                                                <div class="list-group list-group-flush fw-normal">
                                                    <div class="notification-body list-group-title border-bottom text-center p-2"
                                                        *ngIf="!isData">
                                                        No new notifications.
                                                    </div>
                                                    <div *ngIf="isData && !isShow">
                                                        <div class="list-group-item"
                                                            *ngFor="let i of notificationReadArray">
                                                            <a class="notification notification-flush notification-unread"
                                                                (click)="GoTOPages(type)">
                                                                <div class="notification-avatar">
                                                                    <div class="avatar avatar-2xl me-3">

                                                                    </div>
                                                                </div>
                                                                <div class="notification-body">
                                                                    <strong>{{i.title}}</strong>
                                                                    <p class="mb-1">
                                                                        {{i.msg}}
                                                                    </p>
                                                                    <p>{{type}}</p>
                                                                    <span class="notification-time"><span class="me-2"
                                                                            role="img"
                                                                            aria-label="Emoji">💬</span>{{i.timeAgo}}
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="isData && isShow">
                                                        <div class="list-group-item"
                                                            *ngFor="let i of notificationUnreadArray">
                                                            <a class="notification notification-flush notification-unread"
                                                                (click)="GoTOPages(type)">
                                                                <div class="notification-avatar">
                                                                    <div class="avatar avatar-2xl me-3">

                                                                    </div>
                                                                </div>
                                                                <div class="notification-body">
                                                                    <strong>{{i.title}}</strong>
                                                                    <p class="mb-1">
                                                                        {{i.msg}}
                                                                    </p>
                                                                    <p>{{type}}</p>
                                                                    <span class="notification-time"><span class="me-2"
                                                                            role="img"
                                                                            aria-label="Emoji">💬</span>{{i.timeAgo}}
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer text-center border-top" *ngIf="isData">
                                                <a class="card-link d-block" routerLink="/pages/notifications">View
                                                    all</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="nav-item profilePic">
                                <a class="nav-link p-0" id="navbarDropdownUser" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <div class="avatar avatar-xl" id="printPageButton">
                                        <img class="rounded-circle"
                                            [src]="imageSrc ? imageSrc : '/assets/images/default-profile-pic.JPG'" />
                                    </div>
                                </a>
                                <div class="dropdown-menu dropdown-menu-end py-3"
                                    style="font-size: 13px !important;min-width: 150px;"
                                    aria-labelledby="navbarDropdownUser">
                                    <div class="bg-white dark__bg-1000 rounded-2 py-2">
                                        <a class="dropdown-item fw-bold text-warning"
                                            routerLink="/auth/subscription"><span
                                                class="fas fa-crown me-1"></span><span>Go
                                                Pro</span></a>

                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" *ngIf="this.currentUser.userType == 'CLIENT'"
                                            routerLinkActive="active" routerLink="/pages/profile">Profile &amp;
                                            account</a>

                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" *ngIf="this.currentUser.userType == 'CLIENT'"
                                            routerLinkActive="active" routerLink="/pages/profile-settings">Settings</a>
                                        <a class="dropdown-item" (click)="logout()">Logout</a>
                                    </div>
                                </div>

                            </div>

                            <!-- <li class="new nav-item ps-4">
                              <mat-icon>widgets</mat-icon>
                            </li> -->

                        </div>
                    </div>
                </div>
            </nav>
            <!-- <div class=" navbar-nav-icons row flex d-flex flex-row">
                  <div class="nav-logo nav-item col-sm-12 col-md-12 col-lg-1 col-xl-1"> -->
            <!-- <a class="navbar-brand">
                          <div class="d-flex align-items-center py-3">
                              <img class="me-2" src="assets/img/icons/spot-illustrations/new-logo-iceipt-blue.png" alt=""
                                  width="42" /> -->
            <!-- <span class="font-sans-serif">iCeipts</span> -->
            <!-- </div>
                      </a> -->
            <!-- </div>
                  <div class="nav-item col-lg-2 col-xl-2"></div>
                  <div class="nav-search nav-item col-sm-12 col-md-12 col-lg-4 mt-2"> -->

            <!-- <label class="search-label">Search</label> -->
            <!-- <div class="search-box">
                          <input class="search-input" placeholder="Search Here..." [(ngModel)]="searchTerm" [matAutocomplete]="auto" (input)="updateOptions()">
                          <mat-autocomplete #auto="matAutocomplete" class="matautocomplete">
                            <mat-option class="search-option" *ngFor="let option of searchOptions" [value]="option">
                              {{ option }}
                            </mat-option>
                          </mat-autocomplete>
                          <button mat-button class="search-btn" (click)="search()">
                              <mat-icon>search</mat-icon>
                          </button>
                      </div> -->

            <!-- </div>
                  <div class="col-lg-2"></div> -->
            <!-- <li class="nav-item dropdown mt-2 "> -->
            <!-- <mat-form-field appearance="outline" style="width: 120px;">
                          <mat-label>Select type</mat-label>
                          <mat-select class="custom-mat-select">
                              <mat-option value="en">Eng (US)</mat-option>
                              <mat-option value="fr">French</mat-option>
                              <mat-option value="de">German</mat-option>
                          </mat-select>
                      </mat-form-field> -->
            <!-- </li> -->
            <!-- <li class="nav-item">
                                      <div class="theme-control-toggle fa-icon-wait px-2">
                                          <input class="form-check-input ms-0 theme-control-toggle-input" id="themeControlToggle"
                                              type="checkbox" data-theme-control="theme" value="dark" />
                                          <label class="mb-0 theme-control-toggle-label theme-control-toggle-light"
                                              for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left"
                                              title="Switch to light theme"><span class="fas fa-sun fs-0"></span></label>
                                          <label class="mb-0 theme-control-toggle-label theme-control-toggle-dark"
                                              for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left"
                                              title="Switch to dark theme"><span class="fas fa-moon fs"></span></label>
                                      </div>
                                  </li> -->

            <!-- <li class="nav-item dropdown mt-3 col-1">
                  </li> -->
            <!-- <div class="nav-profile nav-item dropdown col-sm-12 col-md-12 col-lg-3 mt-1">
                      <div class="row">
                          <div class="col-lg-2"></div>
                          <div class="col-lg-6 col-sm-12 col-md-12"> -->
            <!-- <mat-form-field appearance="outline" style="width: 120px;">
                                  <mat-label>Select type</mat-label>
                                  <mat-select class="custom-mat-select">
                                      <mat-option value="en">Eng (US)</mat-option>
                                      <mat-option value="fr">French</mat-option>
                                      <mat-option value="de">German</mat-option>
                                  </mat-select>
                              </mat-form-field> -->
            <!-- </div>
                          <div class="col-lg-2"></div> -->
            <!-- <div class="col-4"> -->
            <!-- <a class="nav-link notification-indicator notification-indicator-primary px-2 fa-icon-wait" *ngIf="isShow"
                              id="navbarDropdownNotification" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                              aria-expanded="false"><span class="svg-inline--fa fa fa-bell fa-w-14 fs-2"
                                  data-fa-transform="shrink-6"></span></a>
                          <a *ngIf="!isShow" style="color: var(--falcon-navbar-light-color)" id="navbarDropdownNotification" role="button"
                              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span
                                  class="svg-inline--fa fa fa-bell fa-w-14 fs-2" data-fa-transform="shrink-6"></span></a>
                          <div class="dropdown-menu dropdown-menu-end dropdown-menu-card dropdown-menu-notification dropdown-caret-bg"
                              aria-labelledby="navbarDropdownNotification">
                              <div class="card card-notification shadow-none">
                                  <div class="card-header">
                                      <div class="row justify-content-between align-items-center">
                                          <div class="col-auto">
                                              <h5 class="card-header-title mb-0">Notifications</h5>
                                          </div>
                                          <div class="col-auto ps-0 ps-sm-3">
                                          </div>
                                      </div>
                                  </div>
                                  <div class="scrollbar-overlay" style="max-height: 19rem">
                                      <div class="list-group list-group-flush fw-normal fs--1">
                                          <div class="notification-body list-group-title border-bottom text-center p-2" *ngIf="!isData">
                                              No notifications recevied yet!!!!
                                          </div>
                                          <div *ngIf="isData && !isShow">
                                              <div class="list-group-item" *ngFor="let i of notificationReadArray">
                                                  <a class="notification notification-flush notification-unread"
                                                      (click)="GoTOPages(type)">
                                                      <div class="notification-avatar">
                                                          <div class="avatar avatar-2xl me-3">

                                                          </div>
                                                      </div>
                                                      <div class="notification-body">
                                                          <strong>{{i.title}}</strong>
                                                          <p class="mb-1">
                                                              {{i.msg}}
                                                          </p>
                                                          <p>{{type}}</p>
                                                          <span class="notification-time"><span class="me-2" role="img"
                                                                  aria-label="Emoji">💬</span>{{i.timeAgo}}
                                                          </span>
                                                      </div>
                                                  </a>
                                              </div>
                                          </div>
                                          <div *ngIf="isData && isShow">
                                              <div class="list-group-item" *ngFor="let i of notificationUnreadArray">
                                                  <a class="notification notification-flush notification-unread"
                                                      (click)="GoTOPages(type)">
                                                      <div class="notification-avatar">
                                                          <div class="avatar avatar-2xl me-3">

                                                          </div>
                                                      </div>
                                                      <div class="notification-body">
                                                          <strong>{{i.title}}</strong>
                                                          <p class="mb-1">
                                                              {{i.msg}}
                                                          </p>
                                                          <p>{{type}}</p>
                                                          <span class="notification-time"><span class="me-2" role="img"
                                                                  aria-label="Emoji">💬</span>{{i.timeAgo}}
                                                          </span>
                                                      </div>
                                                  </a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="card-footer text-center border-top" *ngIf="isData">
                                      <a class="card-link d-block" routerLink="/pages/notifications">View all</a>
                                  </div>
                              </div>
                          </div> -->
            <!-- </div>
                          <div class="col-lg-2 col-sm-12 col-md-12">-->
            <!-- <a class="nav-link pe-0 ps-2" id="navbarDropdownUser" role="button" data-bs-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="false">
                              <div class="avatar avatar-xl">
                                  <img class="rounded-circle" [src]="imageSrc" />
                              </div>
                              </a>
                              <div class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="navbarDropdownUser">
                                  <div class="bg-white dark__bg-1000 rounded-2 py-2">
                                      <a class="dropdown-item fw-bold text-warning" routerLink="/auth/subscription"><span
                                              class="fas fa-crown me-1"></span><span>Go
                                              Pro</span></a>

                                      <div class="dropdown-divider"></div>
                                      <a class="dropdown-item" *ngIf="this.currentUser.userType == 'CLIENT'" routerLinkActive="active"
                                          routerLink="/pages/profile">Profile &amp;
                                          account</a>

                                      <div class="dropdown-divider"></div>
                                      <a class="dropdown-item" *ngIf="this.currentUser.userType == 'CLIENT'" routerLinkActive="active"
                                          routerLink="/pages/profile-settings">Settings</a>
                                      <a class="dropdown-item" (click)="logout()">Logout</a>
                                  </div>
                              </div> -->
            <!-- </div>
                      </div>
                  </div>
              </div> -->
            <!-- <hr> -->
            <!-- <ul class="navbar-nav navbar-nav-icons flex-row" style="display: flex; justify-content: space-between;">
                  <div class="nav-item">
                      <div class="search-box" data-list='{"valueNames":["title"]}'>
                          <form class="position-relative" data-bs-toggle="search" data-bs-display="static">
                              <input class="form-control search-input fuzzy-search" type="search"
                                  placeholder="Search..." aria-label="Search" />
                              <span class="fas fa-search search-box-icon"></span>
                          </form>
                          <div class="btn-close-falcon-container position-absolute end-0 top-50 translate-middle shadow-none"
                              data-bs-dismiss="search">
                              <div class="btn-close-falcon" aria-label="Close"></div>
                          </div>
                          <div class="dropdown-menu border font-base start-0 mt-2 py-0 overflow-hidden w-100">
                              <div class="scrollbar list py-3" style="max-height: 24rem">
                                  <h6 class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2">
                                      Recently Browsed
                                  </h6>
                                  <a class="dropdown-item fs--1 px-card py-1 hover-primary"
                                      href="app/events/event-detail.html">
                                      <div class="d-flex align-items-center">
                                          <span class="fas fa-circle me-2 text-300 fs--2"></span>

                                          <div class="fw-normal title">
                                              Pages
                                              <span class="fas fa-chevron-right mx-1 text-500 fs--2"
                                                  data-fa-transform="shrink-2"></span> Events
                                          </div>
                                      </div>
                                  </a>
                                  <a class="dropdown-item fs--1 px-card py-1 hover-primary"
                                      routerLink="/pages/customer-list">
                                      <div class="d-flex align-items-center">
                                          <span class="fas fa-circle me-2 text-300 fs--2"></span>

                                          <div class="fw-normal title">
                                              E-commerce
                                              <span class="fas fa-chevron-right mx-1 text-500 fs--2"
                                                  data-fa-transform="shrink-2"></span> Customers
                                          </div>
                                      </div>
                                  </a>

                                  <hr class="bg-200 dark__bg-900" />
                                  <h6 class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2">
                                      Suggested Filter
                                  </h6>
                                  <a class="dropdown-item px-card py-1 fs-0" href="app/e-commerce/customers.html">
                                      <div class="d-flex align-items-center">
                                          <span
                                              class="badge fw-medium text-decoration-none me-2 badge-soft-warning">customers:</span>
                                          <div class="flex-1 fs--1 title">All customers list</div>
                                      </div>
                                  </a>
                                  <a class="dropdown-item px-card py-1 fs-0" href="app/events/event-detail.html">
                                      <div class="d-flex align-items-center">
                                          <span
                                              class="badge fw-medium text-decoration-none me-2 badge-soft-success">events:</span>
                                          <div class="flex-1 fs--1 title">
                                              Latest events in current month
                                          </div>
                                      </div>
                                  </a>
                                  <a class="dropdown-item px-card py-1 fs-0"
                                      href="app/e-commerce/product/product-grid.html">
                                      <div class="d-flex align-items-center">
                                          <span
                                              class="badge fw-medium text-decoration-none me-2 badge-soft-info">products:</span>
                                          <div class="flex-1 fs--1 title">
                                              Most popular products
                                          </div>
                                      </div>
                                  </a>

                                  <hr class="bg-200 dark__bg-900" />
                                  <h6 class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2">
                                      Files
                                  </h6>
                                  <a class="dropdown-item px-card py-2" href="#!">
                                      <div class="d-flex align-items-center">
                                          <div class="file-thumbnail me-2">
                                              <img class="border h-100 w-100 fit-cover rounded-3"
                                                  src="assets/img/products/3-thumb.png" alt="" />
                                          </div>
                                          <div class="flex-1">
                                              <h6 class="mb-0 title">iPhone</h6>
                                              <p class="fs--2 mb-0 d-flex">
                                                  <span class="fw-semi-bold">Antony</span><span
                                                      class="fw-medium text-600 ms-2">27 Sep at 10:30
                                                      AM</span>
                                              </p>
                                          </div>
                                      </div>
                                  </a>
                                  <a class="dropdown-item px-card py-2" href="#!">
                                      <div class="d-flex align-items-center">
                                          <div class="file-thumbnail me-2">
                                              <img class="img-fluid" src="assets/img/icons/zip.png" alt="" />
                                          </div>
                                          <div class="flex-1">
                                              <h6 class="mb-0 title">Falcon v1.8.2</h6>
                                              <p class="fs--2 mb-0 d-flex">
                                                  <span class="fw-semi-bold">John</span><span
                                                      class="fw-medium text-600 ms-2">30 Sep at 12:30
                                                      PM</span>
                                              </p>
                                          </div>
                                      </div>
                                  </a>

                                  <hr class="bg-200 dark__bg-900" />
                                  <h6 class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2">
                                      Members
                                  </h6>
                                  <a class="dropdown-item px-card py-2" href="pages/user/profile.html">
                                      <div class="d-flex align-items-center">
                                          <div class="avatar avatar-l status-online me-2">
                                              <img class="rounded-circle" src="assets/img/team/1.jpg" alt="" />
                                          </div>
                                          <div class="flex-1">
                                              <h6 class="mb-0 title">Anna Karinina</h6>
                                              <p class="fs--2 mb-0 d-flex">Technext Limited</p>
                                          </div>
                                      </div>
                                  </a>
                                  <a class="dropdown-item px-card py-2" href="pages/user/profile.html">
                                      <div class="d-flex align-items-center">
                                          <div class="avatar avatar-l me-2">
                                              <img class="rounded-circle" src="assets/img/team/2.jpg" alt="" />
                                          </div>
                                          <div class="flex-1">
                                              <h6 class="mb-0 title">Antony Hopkins</h6>
                                              <p class="fs--2 mb-0 d-flex">Brain Trust</p>
                                          </div>
                                      </div>
                                  </a>
                                  <a class="dropdown-item px-card py-2" href="pages/user/profile.html">
                                      <div class="d-flex align-items-center">
                                          <div class="avatar avatar-l me-2">
                                              <img class="rounded-circle" src="assets/img/team/3.jpg" alt="" />
                                          </div>
                                          <div class="flex-1">
                                              <h6 class="mb-0 title">Emma Watson</h6>
                                              <p class="fs--2 mb-0 d-flex">Google</p>
                                          </div>
                                      </div>
                                  </a>
                              </div>
                              <div class="text-center mt-n3">
                                  <p class="fallback fw-bold fs-1 d-none">No Result Found.</p>
                              </div>
                          </div>
                      </div>
                  </li>
                  <li class="nav-item dropdown mt-2">
                      <mat-form-field appearance="outline" style="width: 120px;">
                          <mat-label>Select type</mat-label>
                          <mat-select class="custom-mat-select">
                              <mat-option value="en">Eng (US)</mat-option>
                              <mat-option value="fr">French</mat-option>
                              <mat-option value="de">German</mat-option>
                          </mat-select>
                      </mat-form-field>
                  </li>

                  <li class="nav-item dropdown mt-3">
                      <a class="nav-link notification-indicator notification-indicator-primary px-2 fa-icon-wait" *ngIf="isShow"
                          id="navbarDropdownNotification" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="false"><span class="svg-inline--fa fa fa-bell fa-w-14 fs-2"
                              data-fa-transform="shrink-6"></span></a>
                      <a *ngIf="!isShow" style="color: var(--falcon-navbar-light-color)" id="navbarDropdownNotification" role="button"
                          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span
                              class="svg-inline--fa fa fa-bell fa-w-14 fs-2" data-fa-transform="shrink-6"></span></a>
                      <div class="dropdown-menu dropdown-menu-end dropdown-menu-card dropdown-menu-notification dropdown-caret-bg"
                          aria-labelledby="navbarDropdownNotification">
                          <div class="card card-notification shadow-none">
                              <div class="card-header">
                                  <div class="row justify-content-between align-items-center">
                                      <div class="col-auto">
                                          <h5 class="card-header-title mb-0">Notifications</h5>
                                      </div>
                                      <div class="col-auto ps-0 ps-sm-3">
                                      </div>
                                  </div>
                              </div>
                              <div class="scrollbar-overlay" style="max-height: 19rem">
                                  <div class="list-group list-group-flush fw-normal fs--1">
                                      <div class="notification-body list-group-title border-bottom text-center p-2" *ngIf="!isData">
                                          No notifications recevied yet!!!!
                                      </div>
                                      <div *ngIf="isData && !isShow">
                                          <div class="list-group-item" *ngFor="let i of notificationReadArray">
                                              <a class="notification notification-flush notification-unread"
                                                  (click)="GoTOPages(type)">
                                                  <div class="notification-avatar">
                                                      <div class="avatar avatar-2xl me-3">

                                                      </div>
                                                  </div>
                                                  <div class="notification-body">
                                                      <strong>{{i.title}}</strong>
                                                      <p class="mb-1">
                                                          {{i.msg}}
                                                      </p>
                                                      <p>{{type}}</p>
                                                      <span class="notification-time"><span class="me-2" role="img"
                                                              aria-label="Emoji">💬</span>{{i.timeAgo}}
                                                      </span>
                                                  </div>
                                              </a>
                                          </div>
                                      </div>
                                      <div *ngIf="isData && isShow">
                                          <div class="list-group-item" *ngFor="let i of notificationUnreadArray">
                                              <a class="notification notification-flush notification-unread"
                                                  (click)="GoTOPages(type)">
                                                  <div class="notification-avatar">
                                                      <div class="avatar avatar-2xl me-3">

                                                      </div>
                                                  </div>
                                                  <div class="notification-body">
                                                      <strong>{{i.title}}</strong>
                                                      <p class="mb-1">
                                                          {{i.msg}}
                                                      </p>
                                                      <p>{{type}}</p>
                                                      <span class="notification-time"><span class="me-2" role="img"
                                                              aria-label="Emoji">💬</span>{{i.timeAgo}}
                                                      </span>
                                                  </div>
                                              </a>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="card-footer text-center border-top" *ngIf="isData">
                                  <a class="card-link d-block" routerLink="/pages/notifications">View all</a>
                              </div>
                          </div>
                      </div>
                  </li>
                  <li class="nav-item dropdown">
                      <a class="nav-link pe-0 ps-2" id="navbarDropdownUser" role="button" data-bs-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false">
                          <div class="avatar avatar-xl">
                              <img class="rounded-circle" [src]="imageSrc" />
                          </div>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="navbarDropdownUser">
                          <div class="bg-white dark__bg-1000 rounded-2 py-2">
                              <a class="dropdown-item fw-bold text-warning" routerLink="/auth/subscription"><span
                                      class="fas fa-crown me-1"></span><span>Go
                                      Pro</span></a>

                              <div class="dropdown-divider"></div>
                              <a class="dropdown-item" *ngIf="this.currentUser.userType == 'CLIENT'" routerLinkActive="active"
                                  routerLink="/pages/profile">Profile &amp;
                                  account</a>

                              <div class="dropdown-divider"></div>
                              <a class="dropdown-item" *ngIf="this.currentUser.userType == 'CLIENT'" routerLinkActive="active"
                                  routerLink="/pages/profile-settings">Settings</a>
                              <a class="dropdown-item" (click)="logout()">Logout</a>
                          </div>
                      </div>
                  </li>
              </ul> -->
            <!-- </nav> -->

            <router-outlet></router-outlet>
            <!-- Dashboard cards -->


        </div>
    </div>
</main>














































































<!-- Old Code -->
<!-- <main class="main" id="top"> -->
<!-- <div class="{{ isFluid ? 'container-fluid' : 'container' }}" data-layout="container-fluid"> -->
<!-- <div class="container-fluid" data-layout="container-fluid">
      <script>
          var isFluid = JSON.parse(localStorage.getItem("isFluid"));
          if (isFluid) {
              var container = document.querySelector("[data-layout]");
              container.classList.remove("container");
              container.classList.add("container-fluid");
          }
      </script> -->

<!-- side bar -->
<!-- <nav class="navbar navbar-light navba
          r-vertical navbar-expand-xl">
          <script>
              var navbarStyle = localStorage.getItem("navbarStyle");
              if (navbarStyle && navbarStyle !== "transparent") {
                  document
                      .querySelector(".navbar-vertical")
                      .classList.add(`navbar-${navbarStyle}`);
              }
          </script>
          <div class="d-flex align-items-center">
              <div class="toggle-icon-wrapper">
                  <button class="btn navbar-toggler-humburger-icon navbar-vertical-toggle" data-bs-toggle="tooltip"
                      data-bs-placement="left" title="Toggle Navigation">
                      <span class="navbar-toggle-icon"><span class="toggle-line"></span></span>
                  </button>
              </div>
              <a class="navbar-brand">
                  <div class="d-flex align-items-center py-3">
                      <img class="me-2" src="assets/img/icons/spot-illustrations/new-logo-iceipt-blue.png" alt=""
                          width="42" /> -->
<!-- <span class="font-sans-serif">iCeipts</span> -->
<!-- </div>
              </a>
          </div>
          <div class="collapse navbar-collapse" id="navbarVerticalCollapse">
              <div class="navbar-vertical-content scrollbar">
                  <ul class="navbar-nav flex-column mb-3" id="navbarVerticalNav"> -->
<!-- parent pages Dashboard-->
<!-- <li class="nav-item">
                          <a class="nav-link" role="button" routerLinkActive="active" routerLink="/pages/dashboard"
                              aria-expanded="true" aria-controls="dashboard">
                              <div class="d-flex align-items-center">
                                  <span class="nav-link-icon"><span style="font-size: 1.1rem;"
                                          class="bi-pie-chart"></span></span><span style="font-size: 16px;"
                                      class="nav-link-text  ps-1">Dashboard</span>
                              </div>
                          </a>
                      </li> -->

<!-- parent pages Parties-->

<!-- <li class="nav-item">
                          <div class="row navbar-vertical-label-wrapper mt-3 mb-2">
                              <div class="col-auto navbar-vertical-label">Pages</div>
                              <div class="col ps-0">
                                  <hr class="mb-0 navbar-vertical-divider" />
                              </div>
                          </div>
                          <a *appHasPermission="['read']; name 'parties'" class="nav-link dropdown-indicator"
                              href="#parties" role="button" data-bs-toggle="collapse" aria-expanded="false"
                              aria-controls="parties">
                              <div class="d-flex align-items-center">
                                  <span class="nav-link-icon"><span style="font-size: 1.1rem;"
                                          class="bi bi-people "></span></span><span style="font-size: 16px;"
                                      class="nav-link-text ps-1">Parties</span>
                              </div>
                          </a>
                      </li>

                      <ul class="nav collapse" id="parties">
                          <li class="nav-item" *appHasPermission="['read']; name 'parties'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/add-party"
                                  data-bs-target="#parties" data-bs-toggle="" aria-expanded="true">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Add Party</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>
                          <li class="nav-item" *appHasPermission="['read']; name 'parties'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/party-list"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Party List</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>

                          <li class="nav-item">
                              <a class="nav-link dropdown-indicator" href="#groups" data-bs-toggle="collapse"
                                  aria-expanded="false" aria-controls="parties">
                                  <div class="d-flex align-items-center"><span class="nav-link-text ps-1">Party
                                          Groups</span>
                                  </div>
                              </a>
                              <ul class="nav collapse" id="groups">
                                  <li class="nav-item">
                                      <a class="nav-link" routerLinkActive="active"
                                      routerLink="/pages/group-add" data-bs-toggle="" aria-expanded="false">
                                          <div class="d-flex align-items-center"><span
                                                  class="nav-link-text ps-1">Add Group</span>
                                          </div>
                                      </a> -->
<!-- more inner pages-->
<!-- </li> -->
<!-- <li class="nav-item">
                                      <a class="nav-link" routerLinkActive="active"
                                      routerLink="/pages/multiple-party-group-add" data-bs-toggle="" aria-expanded="false">
                                          <div class="d-flex align-items-center"><span
                                                  class="nav-link-text ps-1">Add Group party</span>
                                          </div>
                                      </a>
                                      more inner pages-->
<!-- </li> -->
<!-- <li class="nav-item">
                                      <a class="nav-link" routerLinkActive="active"
                                      routerLink="/pages/party-group-list" data-bs-toggle="" aria-expanded="false">
                                          <div class="d-flex align-items-center"><span
                                                  class="nav-link-text ps-1">Party Group List</span>
                                          </div>
                                      </a> -->
<!-- more inner pages-->
<!-- </li>
                              </ul>
                              </li> -->









<!-- <li class="nav-item">
                              <a class="nav-link dropdown-indicator" href="#reports" data-bs-toggle="collapse"
                                  aria-expanded="false" aria-controls="parties">
                                  <div class="d-flex align-items-center"><span class="nav-link-text ps-1">Party
                                          Reports</span>
                                  </div>
                              </a>
                              <ul class="nav collapse" id="reports">
                                  <li class="nav-item">
                                      <a class="nav-link" routerLinkActive="active"
                                          routerLink="/pages/party-statement" data-bs-toggle="" aria-expanded="false">
                                          <div class="d-flex align-items-center"><span
                                                  class="nav-link-text ps-1">Party Statement</span>
                                          </div>
                                      </a> -->
<!-- more inner pages-->
<!-- </li>
                                  <li class="nav-item">
                                      <a class="nav-link" routerLinkActive="active"
                                          routerLink="/pages/party-wise-profit-loss" data-bs-toggle=""
                                          aria-expanded="false">
                                          <div class="d-flex align-items-center"><span
                                                  class="nav-link-text ps-1 text-nowrap">Party Wise Profit &
                                                  Loss</span>
                                          </div>
                                      </a> -->
<!-- more inner pages-->
<!-- </li>
                                  <li class="nav-item">
                                      <a class="nav-link" routerLinkActive="active" routerLink="/pages/all-parties"
                                          data-bs-toggle="" aria-expanded="false">
                                          <div class="d-flex align-items-center"><span
                                                  class="nav-link-text ps-1 text-nowrap">All Parties</span>
                                          </div>
                                      </a> -->
<!-- more inner pages-->
<!-- </li>
                                  <li class="nav-item">
                                      <a class="nav-link" routerLinkActive="active"
                                          routerLink="/pages/party-report-by-item" data-bs-toggle=""
                                          aria-expanded="false">
                                          <div class="d-flex align-items-center"><span
                                                  class="nav-link-text ps-1 text-nowrap">Party Report By Item</span>
                                          </div>
                                      </a> -->
<!-- more inner pages-->
<!-- </li>
                                  <li class="nav-item">
                                      <a class="nav-link" routerLinkActive="active"
                                          routerLink="/pages/sale-purchase-by-party" data-bs-toggle=""
                                          aria-expanded="false">
                                          <div class="d-flex align-items-center"><span
                                                  class="nav-link-text ps-1 text-nowrap">Sale Purchase By Party</span>
                                          </div>
                                      </a> -->
<!-- more inner pages-->
<!-- </li>
                                  <li class="nav-item">
                                      <a class="nav-link" routerLinkActive="active"
                                          routerLink="/pages/sale-purchase-by-party-group" data-bs-toggle=""
                                          aria-expanded="false">
                                          <div class="d-flex align-items-center"><span class="nav-link-text ps-1">Sale
                                                  Purchase By Party Group</span>
                                          </div>
                                      </a> -->
<!-- more inner pages-->
<!-- </li>
                              </ul>
                          </li>

                      </ul> -->

<!-- parent pages Product-->
<!-- <li class="nav-item" *appHasPermission="['read']; name 'products'">
                          <a class="nav-link dropdown-indicator" href="#products" role="button"
                              data-bs-toggle="collapse" aria-expanded="false" aria-controls="products">
                              <div class="d-flex align-items-center">
                                  <span class="nav-link-icon"><span style="font-size: 1.1rem;"
                                          class="bi bi-cart-check"></span></span><span style="font-size: 16px;"
                                      class="nav-link-text  ps-1">Products</span>
                              </div>
                          </a>
                      </li>
                      <ul class="nav collapse" id="products">
                          <li class="nav-item" *appHasPermission="['read']; name 'products'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/product-add"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Add Product</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>
                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/product-list"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Product list</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>
                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/add-offer"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Add Offer</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>
                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/offer-list"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Offer list</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>

                          <li class="nav-item">
                              <a class="nav-link dropdown-indicator" href="#preports" data-bs-toggle="collapse"
                                  aria-expanded="false" aria-controls="product">
                                  <div class="d-flex align-items-center"><span class="nav-link-text ps-1">Product
                                          Reports</span>
                                  </div>
                              </a>
                              <ul class="nav collapse" id="preports">
                                  <li class="nav-item">
                                      <a class="nav-link" routerLinkActive="active"
                                          routerLink="/pages/item-batch-report" data-bs-toggle=""
                                          aria-expanded="false">
                                          <div class="d-flex align-items-center"><span class="nav-link-text ps-1">Item
                                                  Batch Report</span>
                                          </div>
                                      </a> -->
<!-- more inner pages-->
<!-- </li>
                                  <li class="nav-item">
                                      <a class="nav-link" routerLinkActive="active" routerLink="/pages/item-detail"
                                          data-bs-toggle="" aria-expanded="false">
                                          <div class="d-flex align-items-center"><span
                                                  class="nav-link-text ps-1 text-nowrap">Item Detail</span>
                                          </div>
                                      </a> -->
<!-- more inner pages-->
<!-- </li>
                                  <li class="nav-item">
                                      <a class="nav-link" routerLinkActive="active"
                                          routerLink="/pages/item-report-by-party" data-bs-toggle=""
                                          aria-expanded="false">
                                          <div class="d-flex align-items-center"><span
                                                  class="nav-link-text ps-1 text-nowrap">Item Report By Party</span>
                                          </div>
                                      </a> -->
<!-- more inner pages-->
<!-- </li>
                                  <li class="nav-item">
                                      <a class="nav-link" routerLinkActive="active"
                                          routerLink="/pages/item-wise-discount" data-bs-toggle=""
                                          aria-expanded="false">
                                          <div class="d-flex align-items-center"><span
                                                  class="nav-link-text ps-1 text-nowrap">Item Wise Discount</span>
                                          </div>
                                      </a> -->
<!-- more inner pages-->
<!-- </li>
                                  <li class="nav-item">
                                      <a class="nav-link" routerLinkActive="active"
                                          routerLink="/pages/item-wise-profit-and-loss" data-bs-toggle=""
                                          aria-expanded="false">
                                          <div class="d-flex align-items-center"><span
                                                  class="nav-link-text ps-1 text-nowrap">Item Wise Profit &
                                                  Loss</span>
                                          </div>
                                      </a> -->
<!-- more inner pages-->
<!-- </li>
                                  <li class="nav-item">
                                      <a class="nav-link" routerLinkActive="active"
                                          routerLink="/pages/low-stock-summary" data-bs-toggle=""
                                          aria-expanded="false">
                                          <div class="d-flex align-items-center"><span class="nav-link-text ps-1">Low
                                                  Stock Summary</span>
                                          </div>
                                      </a> -->
<!-- more inner pages-->
<!-- </li>
                                  <li class="nav-item">
                                      <a class="nav-link" routerLinkActive="active" routerLink="/pages/stock-summary"
                                          data-bs-toggle="" aria-expanded="false">
                                          <div class="d-flex align-items-center"><span
                                                  class="nav-link-text ps-1">Stock Summary</span>
                                          </div>
                                      </a> -->
<!-- more inner pages-->
<!-- </li>
                                  <li class="nav-item">
                                      <a class="nav-link" routerLinkActive="active" routerLink="/pages/stock-detail"
                                          data-bs-toggle="" aria-expanded="false">
                                          <div class="d-flex align-items-center"><span
                                                  class="nav-link-text ps-1">Stock Detail</span>
                                          </div>
                                      </a> -->
<!-- more inner pages-->
<!-- </li>
                              </ul>
                          </li>

                      </ul> -->

<!-- -------parent pages Sale---- -->
<!-- <li class="nav-item" *appHasPermission="['read']; name 'sales'">
                          <a class="nav-link dropdown-indicator" href="#sale" role="button" data-bs-toggle="collapse"
                              aria-expanded="false" aria-controls="sale">
                              <div class="d-flex align-items-center">
                                  <span class="nav-link-icon"><span style="font-size: 1.1rem;"
                                          class="bi bi-arrow-up-square"></span></span><span style="font-size: 16px;"
                                      class="nav-link-text  ps-1">Sale</span>
                              </div>
                          </a>
                      </li>
                      <ul class="nav collapse" id="sale">
                          <li class="nav-item" *appHasPermission="['read']; name 'sales'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/sales-invoice"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Sales Invoice</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>
                          <li class="nav-item" *appHasPermission="['read']; name 'purchase'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/sale-invoice-list"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Sale Invoice List</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>
                          <li class="nav-item" *appHasPermission="['read']; name 'purchase'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/sundry-debtor"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Sundry Debtor</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>
                          <li class="nav-item" *appHasPermission="['read']; name 'sales'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/payment-in"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex aligh-items-center">
                                      <span class="nav-link-text ps-1">Payment In</span>
                                  </div>
                              </a>
                          </li>
                          <li class="nav-item" *appHasPermission="['read']; name 'sales'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/estimation"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex aligh-items-center">
                                      <span class="nav-link-text ps-1">Estimation/Performa Invoice</span>
                                  </div>
                              </a>
                          </li>
                          <li class="nav-item" *appHasPermission="['read']; name 'sales'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/estimation-list"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex aligh-items-center">
                                      <span class="nav-link-text ps-1">Estimation List</span>
                                  </div>
                              </a>
                          </li>

                          <li class="nav-item" *appHasPermission="['read']; name 'sales'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/sale-order"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex aligh-items-center">
                                      <span class="nav-link-text ps-1">Sale Order</span>
                                  </div>
                              </a>
                          </li>
                          <li class="nav-item" *appHasPermission="['read']; name 'sales'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/sale-order-list"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex aligh-items-center">
                                      <span class="nav-link-text ps-1">Sale Order List</span>
                                  </div>
                              </a>
                          </li>

                          <li class="nav-item" *appHasPermission="['read']; name 'sales'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/sales-return"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex aligh-items-center">
                                      <span class="nav-link-text ps-1">Sales Return</span>
                                  </div>
                              </a>
                          </li>
                          <li class="nav-item" *appHasPermission="['read']; name 'sales'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/sales-return-list"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex aligh-items-center">
                                      <span class="nav-link-text ps-1">Sales Return List</span>
                                  </div>
                              </a>
                          </li>
                      </ul> -->
<!-- ---------parent page Purchase------ -->

<!-- <li class="nav-item" *appHasPermission="['read']; name 'purchase'">
                          <a class="nav-link dropdown-indicator" href="#purchase" role="button"
                              data-bs-toggle="collapse" aria-expanded="false" aria-controls="purchase">
                              <div class="d-flex align-items-center">
                                  <span class="nav-link-icon"><span style="font-size: 1.1rem;"
                                          class="bi bi-arrow-down-square"></span></span><span style="font-size: 16px;"
                                      class="nav-link-text  ps-1">Purchase</span>
                              </div>
                          </a>
                      </li>
                      <ul class="nav collapse" id="purchase">
                          <li class="nav-item" *appHasPermission="['read']; name 'purchase'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/purchase-invoice"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Purchase Invoice</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>
                          <li class="nav-item" *appHasPermission="['read']; name 'purchase'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/purchase-invoice-list"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Purchase Invoice List</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>
                          <li class="nav-item" *appHasPermission="['read']; name 'purchase'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/payment-out"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex aligh-items-center">
                                      <span class="nav-link-text ps-1">Payment Out</span>
                                  </div>
                              </a>
                          </li>

                          <li class="nav-item" *appHasPermission="['read']; name 'purchase'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/sundry-creditor"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Sundry Creditor</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>

                          <li class="nav-item" *appHasPermission="['read']; name 'purchase'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/purchase-order"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex aligh-items-center">
                                      <span class="nav-link-text ps-1">Purchase Order</span>
                                  </div>
                              </a>
                          </li>
                          <li class="nav-item" *appHasPermission="['read']; name 'purchase'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/purchase-order-list"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex aligh-items-center">
                                      <span class="nav-link-text ps-1">Purchase Order list</span>
                                  </div>
                              </a>
                          </li>
                          <li class="nav-item" *appHasPermission="['read']; name 'purchase'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/purchase-return"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex aligh-items-center">
                                      <span class="nav-link-text ps-1">Purchase Return</span>
                                  </div>
                              </a>
                          </li>
                          <li class="nav-item" *appHasPermission="['read']; name 'purchase'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/purchase-return-list"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex aligh-items-center">
                                      <span class="nav-link-text ps-1">Purchase Return list</span>
                                  </div>
                              </a>
                          </li>


                      </ul> -->




<!-- -------parent page Bank and Cash------- -->

<!-- <li class="nav-item">
                          <a class="nav-link dropdown-indicator" routerLinkActive="active" href="#bankandcash"
                              role="button" data-bs-toggle="collapse" aria-expanded="false"
                              aria-controls="bankandcash">
                              <div class="d-flex align-items-center">
                                  <span class="nav-link-icon"><span style="font-size: 1.1rem;"
                                          class="bi bi-wallet"></span></span><span style="font-size: 16px;"
                                      class="nav-link-text  ps-1">Bank and Cash</span>
                              </div>
                          </a>
                      </li>

                      <ul class="nav collapse" id="bankandcash">

                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/BankDetailsGrid"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex aligh-items-center">
                                      <span class="nav-link-text ps-1">Bank Details</span>
                                  </div>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/upi-pay"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex aligh-items-center">
                                      <span class="nav-link-text ps-1">UPI Payment</span>
                                  </div>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/cash-in-hand"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex aligh-items-center">
                                      <span class="nav-link-text ps-1">Cash-In-Hand(Adjustment)</span>
                                  </div>
                              </a>
                          </li>
                      </ul> -->




<!-- parent pages Bid-->
<!-- <li class="nav-item">
                          <a class="nav-link dropdown-indicator" routerLinkActive="active" href="#bid" role="button"
                              data-bs-toggle="collapse" aria-expanded="false" aria-controls="bid">
                              <div class="d-flex align-items-center">
                                  <span class="nav-link-icon"><span style="font-size: 1.1rem;"
                                          class="bi bi-tags"></span></span><span style="font-size: 16px;"
                                      class="nav-link-text  ps-1">Bid</span>
                              </div>
                          </a>
                      </li>

                      <ul class="nav collapse" id="bid">
                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/client-bid"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex aligh-items-center">
                                      <span class="nav-link-text ps-1">All Bid Products</span>
                                  </div>
                              </a>
                          </li> -->
<!-- <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/chats"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex aligh-items-center">
                                      <span class="nav-link-text ps-1">Bid Chat</span>
                                  </div>
                              </a>
                          </li> -->

<!-- </ul> -->


<!-- parent pages shop-page-->

<!-- <li class="nav-item">
                          <a class="nav-link" routerLinkActive="active" routerLink="/pages/shop-page" role="button"
                              aria-expanded="false" aria-controls="dashboard">
                              <div class="d-flex align-items-center">
                                  <span class="nav-link-icon"><span style="font-size: 1.1rem;"
                                          class="bi bi-calendar"></span></span><span style="font-size: 16px;"
                                      class="nav-link-text  ps-1">Shop Schedule</span>
                              </div>
                          </a>
                      </li> -->


<!-- parent pages WishList-->
<!-- <li class="nav-item">
                          <a class="nav-link" routerLinkActive="active" href="#wishlist" role="button"
                              data-bs-toggle="collapse" aria-expanded="true" aria-controls="wishlist">
                              <div class="d-flex align-items-center">
                                  <span class="nav-link-icon"><span style="font-size: 1.1rem;"
                                          class="bi bi-card-list"></span></span><span style="font-size: 16px;"
                                      class="nav-link-text  ps-1">Wishlist</span>
                              </div>
                          </a>
                      </li> -->

<!-- <li class="nav-item">

                          <a class="nav-link dropdown-indicator" routerLinkActive="active" href="#gstReport"
                              role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="parties">
                              <div class="d-flex align-items-center">
                                  <span class="nav-link-icon"><i style="font-size: 1.3rem;"
                                          class="fas fa-file-invoice"></i></span><span style="font-size: 16px;"
                                      class="nav-link-text  ps-1">GST Report</span>
                              </div>
                          </a>
                      </li>

                      <ul class="nav collapse" id="gstReport">
                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/gst-report"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">GSTR-1</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>

                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/gst-report2"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">GSTR-2</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>


                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/gst-report3-b"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">GSTR-3B</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>

                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/gst-report4"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">GSTR4</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>

                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/gst-report5"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">GSTR5</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>

                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/gst-report8"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">GSTR8</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>


                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/gst-report2-a"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">GSTR-2A</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>


                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/gst-report9"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">GSTR-9</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>

                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/sale-summary"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Sale Summary</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>

                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/gst-tax-report"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">GST Tax</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>

                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/gst-tax-rate-report"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">GST Tax Rate</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>

                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/gst-form-no"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">GST Form No</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>

                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/tcs-receivable"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">TCS Receivable</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>
                      </ul> -->
<!-- <li class="nav-item">
                          <a class="nav-link dropdown-indicator" href="#settings" role="button"
                              data-bs-toggle="collapse" aria-expanded="false" aria-controls="parties">
                              <div class="d-flex align-items-center">
                                  <span class="nav-link-icon"><span style="font-size: 1.1rem;"
                                          class="bi-gear"></span></span><span style="font-size: 16px;"
                                      class="nav-link-text  ps-1">Settings</span>
                              </div>
                          </a>
                      </li> -->

<!-- <ul class="nav collapse" id="settings">
                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/invoice-number"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Invoice Number</span>
                                  </div>
                              </a>
                              more inner pages-->
<!-- </li> -->
<!-- <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/widgets"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Widgets</span>
                                  </div>
                              </a>
                          </li> -->
<!-- </ul> -->
<!-- Remote shopping -->
<!-- <li class="nav-item">
                          <a class="nav-link dropdown-indicator" href="#remoteShopping" role="button"
                              data-bs-toggle="collapse" aria-expanded="false" aria-controls="parties">
                              <div class="d-flex align-items-center">
                                  <span class="nav-link-icon"><span style="font-size: 1rem;"
                                          class="bi bi-gear"></span></span><span style="font-size: 16px;"
                                      class="nav-link-text  ps-1">Remote Shopping</span>
                              </div>
                          </a>
                      </li>

                      <ul class="nav collapse" id="remoteShopping">
                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" data-bs-toggle=""
                                  routerLink="/pages/remote-retailer" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Remote Orders</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>
                          <li class="nav-item">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/order-receipts"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Order Receipts</span>
                                  </div>
                              </a> -->
<!-- more inner pages -->
<!-- </li>
                      </ul> -->


<!-- parent pages Admin -->
<!-- <li class="nav-item" *appHasPermission="['read']; name 'grantpermission'">
                          <div class="row navbar-vertical-label-wrapper mt-3 mb-2">
                              <div class="col-auto navbar-vertical-label">Setting</div>
                              <div class="col ps-0">
                                  <hr class="mb-0 navbar-vertical-divider" />
                              </div>
                              <a class="nav-link ps-1" routerLinkActive="active" routerLink="/pages/invoice-number"
                                  role="button" data-bs-toggle="" aria-expanded="false" aria-controls="wishlist">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-icon ps-2"><span style="font-size: 1.1rem;" class="bi bi-card-list"></span></span><span
                                          style="font-size: 16px;" class="nav-link-text  ps-2">Invoice Number</span>
                                  </div>
                              </a>
                          </div>

                          <a class="nav-link dropdown-indicator" href="#admin" role="button" data-bs-toggle="collapse"
                              aria-expanded="false" aria-controls="admin"
                              *appHasPermission="['read']; name 'grantpermission'">
                              <div class="d-flex align-items-center">
                                  <span class="nav-link-icon"><span style="font-size: 1.1rem;"
                                          class="bi bi-cart-check"></span></span><span style="font-size: 16px;"
                                      class="nav-link-text  ps-1">Admin</span>
                              </div>
                          </a>
                      </li>
                      <ul class="nav collapse" id="admin">
                          <li class="nav-item" *appHasPermission="['read']; name 'grantpermission'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/add-role"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Add Role</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>

                          <li class="nav-item" *appHasPermission="['read']; name 'grantpermission'">
                              <a class="nav-link" routerLinkActive="active" routerLink="/pages/roles-list"
                                  data-bs-toggle="" aria-expanded="false">
                                  <div class="d-flex align-items-center">
                                      <span class="nav-link-text ps-1">Roles List</span>
                                  </div>
                              </a> -->
<!-- more inner pages-->
<!-- </li>
                      </ul> -->
<!-- parent pages Employees-->
<!-- <li class="nav-item" *appHasPermission="['read']; name 'employees'">
                          <a class="nav-link dropdown-indicator" href="#employees" role="button"
                              data-bs-toggle="collapse" aria-expanded="false" aria-controls="employees">
                              <div class="d-flex align-items-center">
                                  <span class="nav-link-icon"><span style="font-size: 1.2rem;"
                                          class="bi bi-person-plus"></span></span><span style="font-size: 16px;"
                                      class="nav-link-text  ps-1">Employees</span>
                              </div>
                          </a>
                          <ul class="nav collapse" id="employees">
                              <li class="nav-item" *appHasPermission="['read']; name 'employees'">
                                  <a class="nav-link" routerLinkActive="active" routerLink="/pages/allEmployees"
                                      data-bs-toggle="" aria-expanded="false">
                                      <div class="d-flex align-items-center">
                                          <span class="nav-link-text ps-1">All Users</span>
                                      </div>
                                  </a> -->
<!-- more inner pages-->
<!-- </li>
                              <li class="nav-item" *appHasPermission="['read']; name 'employees'">
                                  <a class="nav-link" routerLinkActive="active" routerLink="/pages/add-roleUsers"
                                      data-bs-toggle="" aria-expanded="false">
                                      <div class="d-flex align-items-center">
                                          <span class="nav-link-text ps-1">Rolebased Users Add</span>
                                      </div>
                                  </a> -->
<!-- more inner pages-->
<!-- </li>
                          </ul>
                      </li> -->

<!-- parent pages Help & FAQs-->
<!-- <li class="nav-item">
                          <div class="row navbar-vertical-label-wrapper mt-3 mb-2">
                              <div class="col-auto navbar-vertical-label">Help</div>
                              <div class="col ps-0">
                                  <hr class="mb-0 navbar-vertical-divider" />
                              </div>
                          </div>
                          <a class="nav-link" routerLinkActive="active" routerLink="/pages/contact-us" role="button"
                              data-bs-toggle="" aria-expanded="false">
                              <div class="d-flex align-items-center">
                                  <span class="nav-link-icon"><span style="font-size: 1.1rem;"
                                          class="bi bi-person-lines-fill"></span></span><span style="font-size: 16px;"
                                      class="nav-link-text  ps-1">Contact Us</span>
                              </div>
                          </a>
                          <a class="nav-link" routerLinkActive="active" routerLink="/pages/ticket-list" role="button"
                              data-bs-toggle="" aria-expanded="false">
                              <div class="d-flex align-items-center">
                                  <span class="nav-link-icon"><span style="font-size: 1.1rem;"
                                          class="bi bi-ticket"></span></span><span style="font-size: 16px;"
                                      class="nav-link-text  ps-1">Tickets </span>
                              </div>
                          </a> -->


<!-- <a class="nav-link" routerLinkActive="active" href="#help" role="button"
                              data-bs-toggle="collapse" aria-expanded="true" aria-controls="settings">
                              <div class="d-flex align-items-center">
                                  <span class="nav-link-icon"><span style="font-size: 1.1rem;"
                                          class="bi bi-question-octagon"></span></span><span style="font-size: 16px;"
                                      class="nav-link-text  ps-1">Help and FAQs</span>
                              </div>
                          </a> -->
<!-- </li> -->

<!-- parent pages Feedback-->
<!-- <li class="nav-item">
                          <a class="nav-link" routerLinkActive="active" href="#feedback" role="button"
                              data-bs-toggle="collapse" aria-expanded="true" aria-controls="feedback">
                              <div class="d-flex align-items-center">
                                  <span class="nav-link-icon"><span style="font-size: 1.1rem;"
                                          class="bi bi-emoji-smile"></span></span><span style="font-size: 16px;"
                                      class="nav-link-text  ps-1">Feedback</span>
                              </div>
                          </a>
                      </li> -->

<!-- parent pages Referal Code-->
<!-- <li class="nav-item">
                          <a class="nav-link" href="#rCode" role="button" data-bs-toggle="collapse"
                              aria-expanded="true" aria-controls="rCode">
                              <div class="d-flex align-items-center">
                                  <span class="nav-link-icon"><span class="bi bi-key"></span></span><span
                                      style="font-size: 16px;" class="nav-link-text  ps-1">Referal Code</span>
                              </div>
                          </a>
                      </li> -->
<!-- </ul>
              </div>
          </div>
      </nav>

      <div class="content"> -->
<!-- Header -->
<!-- <nav class="navbar navbar-light navbar-glass navbar-top navbar-expand">
              <button class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3" type="button"
                  data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse"
                  aria-controls="navbarVerticalCollapse" aria-expanded="false" aria-label="Toggle Navigation">
                  <span class="navbar-toggle-icon"><span class="toggle-line"></span></span>
              </button>
              <a class="navbar-brand me-1 me-sm-3">
                  <div class="d-flex align-items-center">
                      <img class="me-2" src="assets/img/icons/spot-illustrations/48_icon3.png" alt=""
                          width="40" /><span class="font-sans-serif">iCeipts</span>
                  </div>
              </a>
              <ul class="navbar-nav align-items-center d-none d-lg-block"> -->
<!-- <li class="nav-item">
                      <div class="search-box" data-list='{"valueNames":["title"]}'>
                          <form class="position-relative" data-bs-toggle="search" data-bs-display="static">
                              <input class="form-control search-input fuzzy-search" type="search"
                                  placeholder="Search..." aria-label="Search" />
                              <span class="fas fa-search search-box-icon"></span>
                          </form>
                          <div class="btn-close-falcon-container position-absolute end-0 top-50 translate-middle shadow-none"
                              data-bs-dismiss="search">
                              <div class="btn-close-falcon" aria-label="Close"></div>
                          </div>
                          <div class="dropdown-menu border font-base start-0 mt-2 py-0 overflow-hidden w-100">
                              <div class="scrollbar list py-3" style="max-height: 24rem">
                                  <h6 class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2">
                                      Recently Browsed
                                  </h6>
                                  <a class="dropdown-item fs--1 px-card py-1 hover-primary"
                                      href="app/events/event-detail.html">
                                      <div class="d-flex align-items-center">
                                          <span class="fas fa-circle me-2 text-300 fs--2"></span>

                                          <div class="fw-normal title">
                                              Pages
                                              <span class="fas fa-chevron-right mx-1 text-500 fs--2"
                                                  data-fa-transform="shrink-2"></span> Events
                                          </div>
                                      </div>
                                  </a>
                                  <a class="dropdown-item fs--1 px-card py-1 hover-primary"
                                      routerLink="/pages/customer-list">
                                      <div class="d-flex align-items-center">
                                          <span class="fas fa-circle me-2 text-300 fs--2"></span>

                                          <div class="fw-normal title">
                                              E-commerce
                                              <span class="fas fa-chevron-right mx-1 text-500 fs--2"
                                                  data-fa-transform="shrink-2"></span> Customers
                                          </div>
                                      </div>
                                  </a>

                                  <hr class="bg-200 dark__bg-900" />
                                  <h6 class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2">
                                      Suggested Filter
                                  </h6>
                                  <a class="dropdown-item px-card py-1 fs-0" href="app/e-commerce/customers.html">
                                      <div class="d-flex align-items-center">
                                          <span
                                              class="badge fw-medium text-decoration-none me-2 badge-soft-warning">customers:</span>
                                          <div class="flex-1 fs--1 title">All customers list</div>
                                      </div>
                                  </a>
                                  <a class="dropdown-item px-card py-1 fs-0" href="app/events/event-detail.html">
                                      <div class="d-flex align-items-center">
                                          <span
                                              class="badge fw-medium text-decoration-none me-2 badge-soft-success">events:</span>
                                          <div class="flex-1 fs--1 title">
                                              Latest events in current month
                                          </div>
                                      </div>
                                  </a>
                                  <a class="dropdown-item px-card py-1 fs-0"
                                      href="app/e-commerce/product/product-grid.html">
                                      <div class="d-flex align-items-center">
                                          <span
                                              class="badge fw-medium text-decoration-none me-2 badge-soft-info">products:</span>
                                          <div class="flex-1 fs--1 title">
                                              Most popular products
                                          </div>
                                      </div>
                                  </a>

                                  <hr class="bg-200 dark__bg-900" />
                                  <h6 class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2">
                                      Files
                                  </h6>
                                  <a class="dropdown-item px-card py-2" href="#!">
                                      <div class="d-flex align-items-center">
                                          <div class="file-thumbnail me-2">
                                              <img class="border h-100 w-100 fit-cover rounded-3"
                                                  src="assets/img/products/3-thumb.png" alt="" />
                                          </div>
                                          <div class="flex-1">
                                              <h6 class="mb-0 title">iPhone</h6>
                                              <p class="fs--2 mb-0 d-flex">
                                                  <span class="fw-semi-bold">Antony</span><span
                                                      class="fw-medium text-600 ms-2">27 Sep at 10:30
                                                      AM</span>
                                              </p>
                                          </div>
                                      </div>
                                  </a>
                                  <a class="dropdown-item px-card py-2" href="#!">
                                      <div class="d-flex align-items-center">
                                          <div class="file-thumbnail me-2">
                                              <img class="img-fluid" src="assets/img/icons/zip.png" alt="" />
                                          </div>
                                          <div class="flex-1">
                                              <h6 class="mb-0 title">Falcon v1.8.2</h6>
                                              <p class="fs--2 mb-0 d-flex">
                                                  <span class="fw-semi-bold">John</span><span
                                                      class="fw-medium text-600 ms-2">30 Sep at 12:30
                                                      PM</span>
                                              </p>
                                          </div>
                                      </div>
                                  </a>

                                  <hr class="bg-200 dark__bg-900" />
                                  <h6 class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2">
                                      Members
                                  </h6>
                                  <a class="dropdown-item px-card py-2" href="pages/user/profile.html">
                                      <div class="d-flex align-items-center">
                                          <div class="avatar avatar-l status-online me-2">
                                              <img class="rounded-circle" src="assets/img/team/1.jpg" alt="" />
                                          </div>
                                          <div class="flex-1">
                                              <h6 class="mb-0 title">Anna Karinina</h6>
                                              <p class="fs--2 mb-0 d-flex">Technext Limited</p>
                                          </div>
                                      </div>
                                  </a>
                                  <a class="dropdown-item px-card py-2" href="pages/user/profile.html">
                                      <div class="d-flex align-items-center">
                                          <div class="avatar avatar-l me-2">
                                              <img class="rounded-circle" src="assets/img/team/2.jpg" alt="" />
                                          </div>
                                          <div class="flex-1">
                                              <h6 class="mb-0 title">Antony Hopkins</h6>
                                              <p class="fs--2 mb-0 d-flex">Brain Trust</p>
                                          </div>
                                      </div>
                                  </a>
                                  <a class="dropdown-item px-card py-2" href="pages/user/profile.html">
                                      <div class="d-flex align-items-center">
                                          <div class="avatar avatar-l me-2">
                                              <img class="rounded-circle" src="assets/img/team/3.jpg" alt="" />
                                          </div>
                                          <div class="flex-1">
                                              <h6 class="mb-0 title">Emma Watson</h6>
                                              <p class="fs--2 mb-0 d-flex">Google</p>
                                          </div>
                                      </div>
                                  </a>
                              </div>
                              <div class="text-center mt-n3">
                                  <p class="fallback fw-bold fs-1 d-none">No Result Found.</p>
                              </div>
                          </div>
                      </div>
                  </li> -->
<!-- </ul>

              <ul class="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center"> -->
<!-- <li class="nav-item">
                      <!-- <div class="theme-control-toggle fa-icon-wait px-2">
                          <input class="form-check-input ms-0 theme-control-toggle-input" id="themeControlToggle"
                              type="checkbox" data-theme-control="theme" value="dark" />
                          <label class="mb-0 theme-control-toggle-label theme-control-toggle-light"
                              for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left"
                              title="Switch to light theme"><span class="fas fa-sun fs-0"></span></label>
                          <label class="mb-0 theme-control-toggle-label theme-control-toggle-dark"
                              for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left"
                              title="Switch to dark theme"><span class="fas fa-moon fs"></span></label>
                      </div>
                  </li> -->

<!-- <li class="nav-item dropdown">
                      <a class="nav-link notification-indicator notification-indicator-primary px-2 fa-icon-wait"
                          *ngIf="isShow" id="navbarDropdownNotification" role="button" data-bs-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false"><span
                              class="svg-inline--fa fa fa-bell fa-w-14 fs-2" data-fa-transform="shrink-6"></span></a>
                      <a *ngIf="!isShow" style="color: var(--falcon-navbar-light-color)"
                          id="navbarDropdownNotification" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="false"><span class="svg-inline--fa fa fa-bell fa-w-14 fs-2"
                              data-fa-transform="shrink-6"></span></a>
                      <div class="dropdown-menu dropdown-menu-end dropdown-menu-card dropdown-menu-notification dropdown-caret-bg"
                          aria-labelledby="navbarDropdownNotification">
                          <div class="card card-notification shadow-none">
                              <div class="card-header">
                                  <div class="row justify-content-between align-items-center">
                                      <div class="col-auto">
                                          <h5 class="card-header-title mb-0">Notifications</h5>
                                      </div>
                                      <div class="col-auto ps-0 ps-sm-3"> -->
<!-- <a class="card-link fw-normal" href="#">Mark all as read</a> -->
<!-- </div>
                                  </div>
                              </div>
                              <div class="scrollbar-overlay" style="max-height: 19rem">
                                  <div class="list-group list-group-flush fw-normal fs--1">
                                      <div class="notification-body list-group-title border-bottom text-center p-2" *ngIf="!isData">
                                          No notifications recevied yet!!!!
                                      </div>
                                      <div *ngIf="isData && !isShow">
                                          <div class="list-group-item" *ngFor="let i of notificationReadArray">
                                              <a class="notification notification-flush notification-unread"
                                                  (click)="GoTOPages(type)">
                                                  <div class="notification-avatar">
                                                      <div class="avatar avatar-2xl me-3"> -->
<!-- <img class="rounded-circle"
                                                              src="assets/img/team/1-thumb.png" alt="" /> -->
<!-- </div>
                                                  </div>
                                                  <div class="notification-body">
                                                      <strong>{{i.title}}</strong>
                                                      <p class="mb-1">
                                                          {{i.msg}}
                                                      </p>
                                                      <p>{{type}}</p>
                                                      <span class="notification-time"><span class="me-2" role="img"
                                                              aria-label="Emoji">💬</span>{{i.timeAgo}}
                                                      </span>
                                                  </div>
                                              </a>
                                          </div>
                                      </div>
                                      <div *ngIf="isData && isShow">
                                          <div class="list-group-item" *ngFor="let i of notificationUnreadArray">
                                              <a class="notification notification-flush notification-unread"
                                                  (click)="GoTOPages(type)">
                                                  <div class="notification-avatar">
                                                      <div class="avatar avatar-2xl me-3"> -->
<!-- <img class="rounded-circle"
                                                              src="assets/img/team/1-thumb.png" alt="" /> -->
<!-- </div>
                                                  </div>
                                                  <div class="notification-body">
                                                      <strong>{{i.title}}</strong>
                                                      <p class="mb-1">
                                                          {{i.msg}}
                                                      </p>
                                                      <p>{{type}}</p>
                                                      <span class="notification-time"><span class="me-2" role="img"
                                                              aria-label="Emoji">💬</span>{{i.timeAgo}}
                                                      </span>
                                                  </div>
                                              </a>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="card-footer text-center border-top" *ngIf="isData">
                                  <a class="card-link d-block" routerLink="/pages/notifications">View all</a>
                              </div>
                          </div>
                      </div>
                  </li>
                  <li class="nav-item dropdown">
                      <a class="nav-link pe-0 ps-2" id="navbarDropdownUser" role="button" data-bs-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false">
                          <div class="avatar avatar-xl">
                              <img class="rounded-circle" [src]="imageSrc" />
                          </div>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="navbarDropdownUser">
                          <div class="bg-white dark__bg-1000 rounded-2 py-2">
                              <a class="dropdown-item fw-bold text-warning" routerLink="/auth/subscription"><span
                                      class="fas fa-crown me-1"></span><span>Go
                                      Pro</span></a>

                              <div class="dropdown-divider"></div>
                              <a class="dropdown-item" *ngIf="this.currentUser.userType == 'CLIENT'"
                                  routerLinkActive="active" routerLink="/pages/profile">Profile &amp; account</a>

                              <div class="dropdown-divider"></div>
                              <a class="dropdown-item" *ngIf="this.currentUser.userType == 'CLIENT'"
                                  routerLinkActive="active" routerLink="/pages/profile-settings">Settings</a>
                              <a class="dropdown-item" (click)="logout()">Logout</a>
                          </div>
                      </div>
                  </li>
              </ul>
          </nav>
          <div class="alert alert-warning d-flex justify-content-center p-2 mb-2 mt-2" role="alert"
              *ngIf="this.currentUser && this.currentUser.userType == 'CLIENT' && this.currentUser.isTrial">
              <div class="px-2" *ngIf="this.currentUser.isTrial">
                  <span class="badge-soft-danger" style="font-size: 1rem;cursor: pointer;"
                      routerLink="/auth/subscription" routerLinkActive="router-link-active">Free-Trial Expires
                      in</span>
              </div>
              <b class="text-primary">{{ showTimeInterval }}</b>
          </div>

          <router-outlet></router-outlet> -->
<!-- Dashboard cards -->


<!-- </div>
  </div>
</main> -->
