import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CustomerProfileService } from 'src/app/core/services/customer-profile.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
@Component({
  selector: 'app-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.scss']
})
export class CustomerProfileComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  activeColor: string = 'green';
  baseColor: string = '#ccc';
  overlayColor: string = 'rgba(255,255,255,0.5)';
  dragging: boolean = false;
  loaded: boolean = false;
  imageLoaded: boolean = false;
  public imageSrc: any = '';

  customerProfileDataFullName: any;
  customerProfileDatamobile: any;
  customerProfileDataEmail: any;
  customerProfileDatahomeno: any;
  customerProfileDatapincode: any;
  customerProfileDatalandmark: any;
  customerProfileDatadistrict: any;
  customerProfileDataaddress: any;
  customerProfileDatacity: any;
  customerProfileDatastate: any;
  customerProfileDataCountry: any;
  multipleAddress = [];

  constructor(private profileService: CustomerProfileService, private titleCasePipe: TitleCasePipe,
    private toastService: ToastNotificationService,
  ) { }

  ngOnInit(): void {
    this.getCustomerProfileData();
  }
  //get user profile data
  getCustomerProfileData() {
    this.profileService.getCustomerProfileByUserId({}, this.currentUser.id).then(((res:any) => {
      this.customerProfileDataFullName = res.data.basicDetails.fullName;
      this.customerProfileDatamobile = res.data.basicDetails.mobile;
      this.customerProfileDataEmail = res.data.basicDetails.email;
      this.customerProfileDatahomeno = res.data.primaryAddress.homeno;
      this.customerProfileDatapincode = res.data.primaryAddress.pincode;
      this.customerProfileDatalandmark = res.data.primaryAddress.landmark;
      this.customerProfileDatadistrict = res.data.primaryAddress.district;
      this.customerProfileDataaddress = res.data.primaryAddress.address;
      this.customerProfileDatacity = res.data.primaryAddress.city;
      this.customerProfileDatastate = res.data.primaryAddress.state;
      this.customerProfileDataCountry = res.data.primaryAddress.country;
      this.imageSrc =res.data.basicDetails.profileImage;
      if(!this.imageSrc){
        this.imageSrc="../../assets/img/team/2.jpg";
      }
      if (res.data.otherAddresses) {
        res.data.otherAddresses.forEach(element => {
          this.multipleAddress.push({
            address: this.titleCasePipe.transform(element.address),
            country: this.titleCasePipe.transform(element.country),
            state: this.titleCasePipe.transform(element.state),
            pincode: element.pincode,
          })
        });
      }
    }),(err)=>{
      if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }
  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }
  handleInputChange(e) {
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result;
      }
      if (file == null) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Select Image To Upload.",
        });
        // this.toastService.openErrorSnackBar(
        //   "Please Select Image To Upload.",
        // );
      } else {
        formdata.set("upload", file)
        this.profileService.postProfileImage(formdata, this.currentUser.id).then((res) => {
          // this.toastService.openSnackBar("Image Uploaded Successfully");
          this.toastService.toastMsg({
            title: "Success",
            content: "Image Uploaded Successfully!!!",
          });
          this.getCustomerProfileData();
          window.location.reload()
        },(err)=>{
          if(err.error.status === 413){
            this.toastService.toastMsg({
              title: "Success",
              content: "Upload Image Size In 5MB"
            });
            // this.toastService.openSnackBar("Upload Image Size In 5MB");
          }
        });
        this.loaded = true;
      }
    }
  }
}


