<div class="modal-header pt-0">

  <h1 class="modal-title">New Structure</h1>

  <div class="payrollButtons">

      <button style="margin-right: 10px !important;" type="button"
          class="btn btn-cancel" (click) = "clearNewStructureForm()">Cancel</button>
      <button type="button" class="btn btn-save" (click) = "postStructureForm()">Save</button>

  </div>

</div>

<div class="modal-body">

  <form [formGroup]="NewStructureForm">

    <div class="row my-3">

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Name<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
            <input matNativeControl required matInput placeholder="Name"
                autocomplete="off" formControlName = "name">
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.name.touched) && f.name.errors?.required">
          Name is required!
        </mat-error>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Type<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
            <input matNativeControl required matInput placeholder="Type"
                autocomplete="off" formControlName = "type">
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.type.touched) && f.type.errors?.required">
          Type is required!
        </mat-error>
      </div>

    </div>

    <div class="row mb-3">

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <label class="form-label">Description<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
            <input matNativeControl required matInput placeholder="Description"
                autocomplete="off" formControlName = "description">
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.description.touched) && f.description.errors?.required">
          Description is required!
        </mat-error>
      </div>

    </div>

  </form>

</div>
