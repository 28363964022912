import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ScheduleShopService } from "src/app/core/services/schedule-shop.service";
import { ToastNotificationService } from "src/app/core/services/toast-notification.service";
import { Location, TitleCasePipe } from "@angular/common";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { CommonService } from "src/app/core/services/common.service";
@Component({
  selector: "app-add-time-schedule",
  templateUrl: "./add-time-schedule.component.html",
  styleUrls: ["./add-time-schedule.component.scss"],
})
export class AddTimeScheduleComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public isFillBreakSlots: boolean = false;
  public isConfirmBtn: boolean = false;
  public submitted: boolean = false;
  public slots = {
    "0": {
      holiday: false,
      breakStart: "13:00",
      breakEnd: "14:00",
      shopStartTime: "09:00",
      shopEndTime: "17:00",
      bufferTime: 10,
      slotDuration: 120,
      capacity: 1,
    },
    "1": {
      holiday: false,
      breakStart: "13:00",
      breakEnd: "14:00",
      shopStartTime: "09:00",
      shopEndTime: "17:00",
      bufferTime: 10,
      slotDuration: 60,
      capacity: 1,
    },
    "2": {
      holiday: false,
    },
    "3": {
      holiday: false,
    },
    "4": {
      holiday: false,
    },
    "5": {
      holiday: false,
    },
    "6": {
      holiday: false,
    },
  };
  appointMentType = [
    {
      id: 1,
      appointMent: "Routine Visit",
    },
    {
      id: 2,
      appointMent: "Virtual appt	",
    },
    {
      id: 3,
      appointMent: "One To One",
    },
  ];

  selectedDays: string[] = []; // Array to store selected days
  public scheduleForm: FormGroup;
  selectedStartTime: string; // Variable to store the selected start time
  selectedEndTime: string; // Variable to store the selected end time
  public shopId;
  public appointmentData;
  public slotTiming;
  public dayWiseSlots;
  public days = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
  dayDetailsData = {
    MON: {
      dayDetails: {
        holiday: false,
        breakStart: "13:00",
        breakEnd: "14:00",
        shopStartTime: "09:00",
        shopEndTime: "17:00",
        bufferTime: 0,
        slotDuration: 120,
        capacity: 1,
        slotNo: 1,
        day: "MON",
      },
      slotDetails: [
        {
          startSlot: "09:00",
          endSlot: "11:00",
          slotNo: 1,
          capacity: 1,
        },
        {
          startSlot: "11:00",
          endSlot: "13:00",
          slotNo: 2,
          capacity: 1,
        },
        {
          startSlot: "14:00",
          endSlot: "16:00",
          slotNo: 3,
          capacity: 1,
        },
      ],
    },
  };
  shopListWithShipId: any[] = [];
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toastService: ToastNotificationService,
    private router: Router, private commonService : CommonService,
    private location: Location,
    private titleCasePipe: TitleCasePipe,
    public dialogRef: MatDialogRef<AddTimeScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private scheduleShopService: ScheduleShopService
  ) { }

  ngOnInit(): void {
    this.scheduleForm = this.formBuilder.group({
      appointmentType: ["", [Validators.required]],
      start_time: ["", [Validators.required]],
      break_start_time: ["", [Validators.required]],
      break_end_time: ["", [Validators.required]],
      end_time: ["", [Validators.required]],
      start_time_time_period: [""],
      break_start_time_time_period: [""],
      break_end_time_time_period: [""],
      end_time_time_period: [""],
      slotDuration: ["", [Validators.required]],
      capacity: [, [Validators.required]],
      bufferTime: [, [Validators.required]],
      shopSelected: [, [Validators.required]],
    });
    // this.route.paramMap.subscribe((p) => {
    //   this.shopId = p.get("id");
    //   console.log(this.shopId);
    //   if (this.shopId != null) {
    //     this.getAppointmentType();
    //   }
    // });
    // this.getTimeSlots();
    this.getShopList()
  }

  getAppointmentType() {
    this.scheduleShopService.getAppointmentTypeshopid({}, this.currentUser.id,this.partyPageSize, (this.currentPageNo + 1), this.shopId).then((res: any) => {
      console.log("res new",res)
      this.appointmentData = res.data.pageData;
      console.log(this.appointmentData)
      if(this.appointmentData === undefined || this.appointmentData === null || this.appointmentData.length === 0) {
        this.toastService.toastMsg({
          title: "Error",
          content: "There Is No Appointment Created For The Selected Shop."
          });
        // this.toastService.openErrorSnackBar("There is no appointment created for the selected shop.");
      }
    });
    // if(this.appointmentData === undefined || this.appointmentData === null || this.appointmentData.length === 0) {
    //   this.toastService.openErrorSnackBar('Appointment not available. Please create a new appointment before add new schedule.')
    // }
  }

  onShopSelected(event: any) {
    this.shopId = event.value;
    console.log("this.shopId",this.shopId)
    this.getAppointmentType();
  }

  postTimeSlots() {
    this.shopId = this.scheduleForm.value.shopSelected
    this.submitted = true;
    if (this.scheduleForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields."
        });
      // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
      return false;
    } else {
      this.slotTiming = {
        breakStart: this.scheduleForm.controls.break_start_time.value,
        breakEnd: this.scheduleForm.controls.break_end_time.value,
        shopStartTime: this.scheduleForm.controls.start_time.value,
        shopEndTime: this.scheduleForm.controls.end_time.value,
        breakStartTime_period: this.scheduleForm.controls.break_start_time_time_period.value,
        breakEndTime_period: this.scheduleForm.controls.break_end_time_time_period.value,
        shopStartTimeTime_period: this.scheduleForm.controls.start_time_time_period.value,
        shopEndTimeTime_period: this.scheduleForm.controls.end_time_time_period.value,
        bufferTime: this.scheduleForm.controls.bufferTime.value,
        slotDuration: this.scheduleForm.controls.slotDuration.value,
        capacity: this.scheduleForm.controls.capacity.value,
        match: false,
        sameAs: 0,
      };
      let b = ["0", "1", "2", "3", "4", "5", "6"];
      b.forEach((i) => {
        this.slots[i] = {
          ...this.slots[i],
          ...this.slotTiming,
        };
      });
      console.log(this.slots);
      console.log(this.slotTiming);
      let data = {
        shopId: this.shopId,
        fillBreakSlots: this.isFillBreakSlots,
        slots: this.slots,
      };
      this.scheduleShopService.getTimeSlots(data).then((res: any) => {
        console.log(res);
        this.dayWiseSlots = res.data;
        this.isConfirmBtn = true;
        // this.scheduleForm.reset();
        // this.location.back()
      });
    }
  }

  async getShopList() {
    try {
      const res = await this.scheduleShopService.getShopList({}, this.currentUser.id);
      
      this.shopListWithShipId = res.data.rows.map((data: any) => {
        return {
          tradeName: data.client.tradeName,
          shopId: data.id,
        };
      });
    
      if (this.shopListWithShipId.length === 0 || !this.shopListWithShipId) {
        this.toastService.toastMsg({
          title: "Error",
          content: "No shops available. Please create a new shop before adding a new schedule."
          });
        // this.toastService.openErrorSnackBar('No shops available. Please create a new shop before adding a new schedule.');
      }
    } catch (error) {
      if (error.status === 404){
        this.toastService.toastMsg({
          title: "Error",
          content: "No Shops Available. Please Create A New Shop Before Adding A New Schedule."
          });
        // this.toastService.openErrorSnackBar('No shops available. Please create a new shop before adding a new schedule.')
      } else{
        this.toastService.toastMsg({
          title: "Error",
          content: "error"
          });
        // this.toastService.openErrorSnackBar(error)
      }
    }
  }
  

  postCreateSchedule() {
    console.log('POST CREATE SCHEDULE', this.scheduleForm.controls.appointmentType.value)
    let data = {
      shopId: this.scheduleForm.controls.shopSelected.value,
      // shopSelected: this.scheduleForm.controls.shopSelected.value,
      appointmentTypeId: this.scheduleForm.controls.appointmentType.value,
      userId : this.currentUser.id,
      scheduleName: "S3",
      scheduleDesc: "BASIC",
      data: this.dayWiseSlots,
    };
    this.scheduleShopService.postCreateSchedule(data).then(
      (res) => {
        this.toastService.toastMsg({
          title: "Success",
          content: "Slot Created Successfully!!!",
          });
        // this.toastService.openSnackBar("Slot Created Successfully!!!");
        this.router.navigate(["/pages/shop-schedule-page/time-schedule-list"]);
        this.commonService.notifyDataAdded();
        this.dialogRef.close();
      },
      (err) => {
        if (err.error.expose) {
          // this.toastService.openErrorSnackBar(
          //   this.titleCasePipe.transform(err.error.error_message)
          // );
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message)
            });
        } else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong."
            });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      }
    );
  }
  toggleBreakSlots(event: boolean) {
    this.isFillBreakSlots = event;
  }

  getHoliday(day) {
    // Check if the day is already selected, then remove it from the array, otherwise add it to the array
    const index = this.selectedDays.indexOf(day);
    if (index !== -1) {
      this.selectedDays.splice(index, 1);
    } else {
      this.selectedDays.push(day);
    }

    this.slots[day].holiday = !this.slots[day].holiday;
    console.log(this.slots);
  }
  get f() {
    return this.scheduleForm.controls;
  }

  cancel() {
    // Reset the form and submitted flag
    this.submitted = false;
    this.dialogRef.close();
  }
}
