<div class="modal-header pt-0">
    <h1 style="color: #EB8B3F; font-weight: bolder;margin-left: 5px;" class="modal-title mb-0">Apply Coupon</h1>
    <button type="button" class="btn-close" (click)="close()">&times;</button>

</div>

<div class="modal-body hidden-scroll" style="background: rgb(242, 242, 242); max-height: 500px;">
    <!-- Display Coupons -->
    <div *ngFor="let coupon of couponList" class="coupon-item mb-3">
        <div class="coupon-header row">
            <div class="col-5 my-3">
                <span class="coupon-code">{{ coupon?.coupon_code }}</span>
            </div>
            <div class="col-7 d-flex justify-content-end">
                <div class="coupon-name"><b>{{ coupon?.coupon_name }}</b></div>
            </div>
            <div class="coupon-details col-12 mt-1">
                <div class="coupon-description">{{ coupon?.coupon_description }}</div>
            </div>
        </div>
        <div class="coupon-footer d-flex justify-content-between align-items-center">
            <div class="discount-amount"><b>Save AED {{ coupon?.discount_amount }}</b></div>
            <button type="button" class="btn btn-apply d-flex justify-content-end text-success" (click)="selectCoupon(coupon)">
              {{ (appliedCoupon?.coupon_code === coupon?.coupon_code) ? 'Applied' : 'Apply' }}
            </button>
            <button type="button" class="btn btn-apply text-danger" *ngIf="appliedCoupon?.coupon_code === coupon?.coupon_code" (click)="deselectCoupon(coupon)">
              Remove
            </button>
          </div>          
      </div>      
</div>
<div class="modal-footer row">
    <div class="max-saving col-6">Total Maximum Saving: AED {{ appliedCoupon?.discount_amount ? appliedCoupon?.discount_amount : 0 }}</div>
    <button type="button" class="btn btn-modify col-6" (click)="applyCoupon()">
        Apply Selected Coupon
    </button>
</div>
    
