import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private newAddedSource = new Subject<void>();
  rowAdded$ = this.newAddedSource.asObservable();
  private isArabicSubject = new BehaviorSubject<boolean>(
    localStorage.getItem('isArabic') === 'true' || false
  );

  constructor() { }

  notifyDataAdded() {
    this.newAddedSource.next();
  }

  // Expose an observable to subscribe to
  isArabic$: Observable<boolean> = this.isArabicSubject.asObservable();

  // Function to set the language value based on the countryId
  setIsArabicByCountryId(countryId: number): void {
    // Assuming that countryId 228 corresponds to Arabic otherwise assume that countryId 102 corresponds to India
    const isArabic = countryId === 228;
    this.isArabicSubject.next(isArabic);
    
    // Store the isArabic value in local storage
    localStorage.setItem('isArabic', isArabic.toString());
  }

  getTodaysDate() {
    let currentDate = new Date();
    let month = currentDate.getMonth() + 1;
    let date = currentDate.getDate();
    let datestr = currentDate.getFullYear() + "-" +
      (month < 10 ? "0" + month : month) + "-" + (date < 10 ? "0" + date : date);
    return datestr;
  }

  private subject = new Subject<any>();

  sendData(count: any) {
    this.subject.next({ count });
  }

  clearData() {
    this.subject.next();
  }

  getData(): Observable<any> {
    return this.subject.asObservable();
  }
}
