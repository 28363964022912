import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class WidegtsLayoutService {

  constructor(public httpClient:HttpService) { }
  postWidgetsByClient(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.widgets(data, UrlConstants.addWidgetsByClient ,  'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  deleteWidgetsByClient(data: {},id:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.widgets(data, UrlConstants.deleteWidgetById + id ,  'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getDefaultWidgets(data: {}, widgetName, retailerId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getDefaultWidgets + widgetName + "&retailerId=" + retailerId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }  

  postWidgetsByRetailer(data: {},): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.addWidgetsByClient, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}
