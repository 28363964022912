import { TitleCasePipe } from "@angular/common";
import { elementEventFullName } from "@angular/compiler/src/view_compiler/view_compiler";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { Moment } from "moment";
import { AuthorizationService } from "src/app/core/services/authorization.service";
import { BankServiceService } from "src/app/core/services/bank-service.service";
import { CommonService } from "src/app/core/services/common.service";
import { DashboardService } from "src/app/core/services/dashboard.service";
import { PartyService } from "src/app/core/services/party.service";
import { ProfileService } from "src/app/core/services/profile.service";
import { RemoteShopService } from "src/app/core/services/remote-shop.service";
import { ToastNotificationService } from "src/app/core/services/toast-notification.service";
import lang from "src/assets/langTranslation/language_translation";
@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  activeColor: string = "green";
  baseColor: string = "#ccc";
  overlayColor: string = "rgba(255,255,255,0.5)";
  dragging: boolean = false;
  loaded: boolean = false;
  imageLoaded: boolean = false;
  public imageSrc: any = "";
  public img: any;
  public data: any;
  public email: any;
  public additionalPlaceOfBusiness: any;
  public addressOfBusiness: any;
  public businessType: any;
  public firstName: any;
  public legalName: any;
  public gstType: any;
  public lastName: any;
  public gstinNo: any;
  public profileImage: any;
  public liability: any;
  public mobile: any;
  public tradeName: any = "";
  public users = [];
  public multipleAddress = [];
  public startValidity: any;
  public endValidity: any;
  public gstIssue: any;
  public state: string;
  public address: string;
  public pincode: string;
  public city: string;
  public homeno: string;
  public district: string;
  public country: string;
  public isRemoteToggle: boolean = false;
  public isShown: boolean = false;
  public isArabic : boolean
  public lang = lang.UAE;
  userSubscribeplanRes: any;
  planName: any;
  endDate: any;
  ProfileForm: any;
  // today: Date .= new Date();
  activeWidget: string = 'personalDetails'; // Default to the Personal Details widget

  currentDate: Date = new Date();
  calendarDates: Date[] = this.generateCalendarDates(7); // Display 7 days
  cinNumber: any;
  panNumber: any;
  accountMethod: any;
  compositeType: any;
  topProductSoldList: any[] = [];
  updatedProductList: any[] = [];
  singleBankRecord: any;
  singleBankName: any;
  singleBankAcc: any;
  singleIfscCode: any;
  accoutNumber: any;
  bankApiResponse: any[] = [];
    
  private generateCalendarDates(numDays: number): Date[] {
    const dates: Date[] = [];
    for (let i = 0; i < numDays; i++) {
      const date = new Date();
      date.setDate(this.currentDate.getDate() + i);
      dates.push(date);
    }
    return dates;
  }

  constructor(
    public authorizationService: AuthorizationService, private dashboardService : DashboardService,
    public profileService: ProfileService, public partyService : PartyService,
    public router: Router,     private bankService: BankServiceService,
    private remoteShopService: RemoteShopService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
    private commonService: CommonService,
  ) {}

  ngOnInit(): void {
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });
    this.getbankdetails();
    this.bankService.partyAdded$.subscribe(() => {
      this.getbankdetails();
    });
    this.getProfileData();
    this.getUserSuscription();
    this.getTopProductSoldToday();
  }

  getUserSuscription() {
    this.profileService
      .getUserSubscriptionDeatils({}, this.currentUser.id)
      .then((res) => {
        this.userSubscribeplanRes = res;
        this.planName = this.titleCasePipe.transform(
          this.userSubscribeplanRes.data.planDetails.planName
        );
        this.endDate =
          this.userSubscribeplanRes.data.userSubscription.endValidity;
      });
  }
  //get user profile data
  getProfileData() {
    this.profileService
      .getUserProfile({}, this.currentUser.id)
      .then((res: any) => {
        console.log(res.data)
        this.users = res.data;
        (this.email = res.data.basicdetails.email),
          (this.isRemoteToggle = res.data.additional.isRemoteShop);
        (this.additionalPlaceOfBusiness =
          res.data.additional.additionalPlaceOfBusiness),
          (this.businessType = res.data.additional.businessType),
          (this.firstName = res.data.basicdetails.firstName),
          (this.imageSrc = res.data.basicdetails.profileImage),
          (this.legalName = res.data.additional.legalName),
          (this.gstType = res.data.additional.gstType),
          (this.cinNumber = res.data.additional.cinNumber),
          (this.accountMethod = res.data.additional.accountMethod),
          (this.compositeType = res.data.additional.compositeType),
          (this.panNumber = res.data.additional.panNumber),
          (this.gstinNo = res.data.additional.gstinNo),
          (this.lastName = res.data.basicdetails.lastName),
          (this.mobile = res.data.basicdetails.mobile),
          (this.tradeName = res.data.additional.tradeName),
          (this.state = this.titleCasePipe.transform(
            res.data.primaryAddress.state
          )),
          (this.pincode = res.data.primaryAddress.pincode),
          (this.district = this.titleCasePipe.transform(
            res.data.primaryAddress.district
          )),
          (this.homeno = res.data.primaryAddress.homeno),
          (this.city = this.titleCasePipe.transform(
            res.data.primaryAddress.city
          )),
          (this.address = this.titleCasePipe.transform(
            res.data.primaryAddress.address
          )),
          (this.country = this.titleCasePipe.transform(
            res.data.primaryAddress.country
          ));
        if (res.data.otherAddresses) {
          res.data.otherAddresses.forEach((element) => {
            this.multipleAddress.push({
              address: this.titleCasePipe.transform(element.address),
              country: this.titleCasePipe.transform(element.country),
              state: this.titleCasePipe.transform(element.state),
              pincode: element.pincode,
            });
          });
        }
      });
  }

  redirectToSubscription() {
    this.router.navigate(["/auth/subscription"]);
  }
  remoteShopToggle() {
    this.remoteShopService.postRemoteShopToggle({}, this.currentUser.id).then(
      (res) => {
        if (res) {
          if (this.isRemoteToggle == true) {
            // this.toastService.openSnackBar(
            //   "Remote Shopping Service Enabled Successfully!!!"
            // );
            this.toastService.toastMsg({
              title: "Success",
              content: "Remote Shopping Service Enabled Successfully!!!",
            });
          } else {
            // this.toastService.openSnackBar(
            //   "Remote Shopping Service disabled Successfully!!!"
            // );
            this.toastService.toastMsg({
              title: "Success",
              content: "Remote Shopping Service Disabled Successfully!!!",
            });
          }
        }
      },
      (err) => {
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Try Again Later!!!",
        });
        // this.toastService.openErrorSnackBar("Please Try Again Later!!!");
      }
    );
  }
  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }
  handleInputChange(e) {
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];
    const fileSizeLimit = 1 * 1024 * 1024; // 6 MB
    if (!file.type.match(pattern)) {
      alert("invalid format");
      return;
    }
    if (file.size > fileSizeLimit) {
      alert("File size exceeds the limit. Please select a smaller image (up to 1 MB).");
      return;
    }
    this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result;
      };
      if (file == null) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Select Image To Upload.",
        });
        // this.toastService.openErrorSnackBar("Please Select Image To Upload.");
      } else {
        formdata.set("upload", file);
        this.profileService
          .postProfileImage(formdata, this.currentUser.id)
          .then((res) => {
            this.toastService.toastMsg({
              title: "Success",
              content: "Image Uploaded Successfully!!!",
            })
            // this.toastService.openSnackBar("Image Uploaded Successfully");
            this.getProfileData();
            this.partyService.notifyPartyAdded();
          });
        this.loaded = true;
      }
    }
  }

  getTopProductSoldToday() { 
    this.dashboardService.getTopProductSold({}, this.currentUser.id,10, "SALES").then((res:any) => {
      this.topProductSoldList = res.data
      this.updatedProductList = this.topProductSoldList;
      console.log(this.updatedProductList)
    }, (err) => {
      if (err.error.status == 404) {
      }
    })
  }

  dateClass = (date: Date | moment.Moment): string => {
    // Check if 'date' is a Moment.js object and convert it to a JavaScript Date if needed
    if (moment.isMoment(date)) {
      date = date.toDate();
    }
  
    if (this.isToday(date)) {
      return 'today-date'; // CSS class name for today's date
    }
    return '';
  };
  
  isToday(date: Date): boolean {
    // this.today = new Date();
    return (
      date.getDate() === this.currentDate.getDate() &&
      date.getMonth() === this.currentDate.getMonth() &&
      date.getFullYear() === this.currentDate.getFullYear()
    );
  }
 
  // Method to activate a specific widget
  activateWidget(widget: string): void {
    this.activeWidget = widget;
  }

  getbankdetails() {
    this.bankService.getBankDetails({}, this.currentUser.id).then((res) => {
      this.bankApiResponse = res.data;
      this.bankApiResponse.forEach((ele) => {
        ele.isEnable = false;
      })
    },
      (err) => {
        if (err.error.expose) {
       //   this.bankApiResponse = [];
       this.toastService.toastMsg({
        title: "Error",
        content: this.titleCasePipe.transform(err.error.error_message),
      });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }

      }
    )
  }
  
  getbankdetailsById(id) {
    this.bankService.getBankDetailsById({}, this.currentUser.id, id).then((res) => {

      this.singleBankRecord = res.data;
      this.singleBankName = this.singleBankRecord.bankName
      this.singleBankAcc = this.singleBankRecord.accountNumber
      this.singleIfscCode = this.singleBankRecord.ifscCode

    })
  }

  GetbankById(offerId: any) {
    this.accoutNumber = offerId;
  }
}
