<div class="card mb-3">
  <div class="card-body">
    <div class="row">
      <div class="col-lg-6 mb-4 mb-lg-0">
        <img class="rounded-1 w-100" 
        [src]="inventoryData.coverImage"  
        onerror="this.src='../../../assets/img/products/1.jpg';" 
        style = "height: 300px !important"
        alt="" />
      

        <!-- <div class="product-slider" id="galleryTop">
            <div class="swiper-container theme-slider position-lg-absolute all-0" data-swiper='{"autoHeight":true,"spaceBetween":5,"loop":true,"loopedSlides":5,"thumb":{"spaceBetween":5,"slidesPerView":5,"loop":true,"freeMode":true,"grabCursor":true,"loopedSlides":5,"centeredSlides":true,"slideToClickedSlide":true,"watchSlidesVisibility":true,"watchSlidesProgress":true,"parent":"#galleryTop"},"slideToClickedSlide":true}'>
              <div class="swiper-wrapper h-100">
                <div class="swiper-slide h-100"><img class="rounded-1 fit-cover h-100 w-100" src="../../../assets/img/products/1.jpg" alt="" /></div>
                <div class="swiper-slide h-100"><img class="rounded-1 fit-cover h-100 w-100" src="../../../assets/img/products/1-2.jpg" alt="" /></div>
                <div class="swiper-slide h-100"> <img class="rounded-1 fit-cover h-100 w-100" src="../../../assets/img/products/1-3.jpg" alt="" /></div>
                <div class="swiper-slide h-100"> <img class="rounded-1 fit-cover h-100 w-100" src="../../../assets/img/products/1-4.jpg" alt="" /></div>
                <div class="swiper-slide h-100"> <img class="rounded-1 fit-cover h-100 w-100" src="../../../assets/img/products/1-5.jpg" alt="" /></div>
                <div class="swiper-slide h-100"> <img class="rounded-1 fit-cover h-100 w-100" src="../../../assets/img/products/1-6.jpg" alt="" /></div>
              </div>
              <div class="swiper-nav">
                <div class="swiper-button-next swiper-button-white"></div>
                <div class="swiper-button-prev swiper-button-white"></div>
              </div>
            </div>
          </div> -->
      </div>
      <div class="col-lg-6">
        <h5>{{inventoryData.itemName}}</h5><a class="fs--1 mb-2 d-block" href="#!">{{inventoryData.category == null ? '-': inventoryData.category.categoryName }}</a>
        <div class="fs--2 mb-3 d-inline-block text-decoration-none"><span class="fa fa-star text-warning"></span><span
            class="fa fa-star text-warning"></span><span class="fa fa-star text-warning"></span><span
            class="fa fa-star text-warning"></span><span class="fa fa-star-half-alt text-warning star-icon"></span><span
            class="ms-1 text-600">(8)</span>
        </div>
        <p class="fs--1">{{inventoryData.description}}</p>
        <p class="text-warning me-2">{{inventoryData.salePrice}}</p>
            <!-- <del class="me-1">$2400</del><strong>-50%</strong></span>-->
            
        <!-- <p class="fs--1 mb-1"> <span>Shipping Cost: </span><strong>$50</strong></p> -->
        <p class="fs--1">Stock: <strong class="text-success">{{inventoryData.stock}}</strong></p>
        <!-- <p class="fs--1 mb-3">Tags: <a class="ms-2" href="#!">Computer,</a><a class="ms-1" href="#!">Mac Book,</a><a
            class="ms-1" href="#!">Mac Book Pro,</a><a class="ms-1" href="#!">Laptop </a></p> -->
        <div class="row margin-top">
          <!-- <div class="col-auto pe-0">
              <div class="input-group input-group-sm" data-quantity="data-quantity">
                <button class="btn btn-sm btn-outline-secondary border-300" data-field="input-quantity" data-type="minus">-</button>
                <input class="form-control text-center input-quantity input-spin-none" type="number" min="0" value="0" aria-label="Amount (to the nearest dollar)" style="max-width: 50px" />
                <button class="btn btn-sm btn-outline-secondary border-300" data-field="input-quantity" data-type="plus">+</button>
              </div>
            </div> -->
          <div class="col-auto px-2 px-md-3"><a class="btn btn-sm btn-warning" (click)="addBidModal(inventoryData.id,inventoryData.itemName,inventoryData.salePrice,addBid)"><span>Request for
                Bid</span></a></div>
          <div class="col-auto px-2 px-md-3 mb-" (click)="addProductToCart(inventoryData,'false')"><a class="btn btn-sm btn-warning"><span
                class="fas fa-cart-plus me-sm-2"></span><span>Add To Cart</span></a>
          </div>
          <div class="col-auto px-2 px-md-3" (click)="addProductToCart(inventoryData,'true')"><a class="btn btn-sm btn-warning" ><span>Buy Now</span></a>
          </div>
          <!-- <div class="col-auto px-0"><a class="btn btn-sm btn-outline-danger border-300" href="#!" data-bs-toggle="tooltip" data-bs-placement="top" title="Add to Wish List"><span class="far fa-heart me-1"></span>282</a></div> -->
        </div>
    </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="overflow-hidden mt-4">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item"><a class="nav-link active ps-0" id="description-tab" data-bs-toggle="tab"
                href="#tab-description" role="tab" aria-controls="tab-description" aria-selected="true">Description</a>
            </li>
            <li class="nav-item"><a class="nav-link px-2 px-md-3" id="specifications-tab" data-bs-toggle="tab"
                href="#tab-specifications" role="tab" aria-controls="tab-specifications"
                aria-selected="false">Specifications</a></li>
            <li class="nav-item"><a class="nav-link px-2 px-md-3" id="reviews-tab" data-bs-toggle="tab"
                href="#tab-reviews" role="tab" aria-controls="tab-reviews" aria-selected="false">Reviews</a></li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="tab-description" role="tabpanel"
              aria-labelledby="description-tab">
              <div class="mt-3">
                <p>{{inventoryData.description}}</p>
                <!-- <p>Over the years, Apple has built a reputation for releasing its products with a lot of fanfare – but
                  that didn’t exactly happen for the MacBook Pro 2018. Rather, Apple’s latest pro laptop experienced a
                  subdued launch, in spite of it offering a notable spec upgrade over the 2017 model – along with an
                  improved keyboard. And, as with previous generations the 15-inch MacBook Pro arrives alongside a
                  13-inch model.</p> -->
                <!-- <p>Apple still loves the MacBook Pro though, despite the quiet release. This is because, while the
                  iPhone XS and iPad, along with the 12-inch MacBook, are aimed at everyday consumers, the MacBook Pro
                  has always aimed at the creative and professional audience. This new MacBook Pro brings a level of
                  performance (and price) unlike its more consumer-oriented devices. </p> -->
                <!-- <p>Still, Apple wants mainstream users to buy the MacBook Pro, too. So, if you’re just looking for the
                  most powerful MacBook on the market, you’ll love this new MacBook Pro. Just keep in mind that, while
                  the keyboard has been updated, there are still some issues with it.</p>
                <p>There’s enough of a difference between the two sizes when it comes to performance to warrant two
                  separate reviews, and here we’ll be looking at how the flagship 15-inch MacBook Pro performs in 2019.
                </p>
                <p>It's build quality and design is batter than elit. Numquam excepturi a debitis, sint voluptates, nam
                  odit vel delectus id repellendus vero reprehenderit quidem totam praesentium vitae nesciunt deserunt.
                  Sint, veniam?</p> -->
              </div>
            </div>
            <div class="tab-pane fade" id="tab-specifications" role="tabpanel" aria-labelledby="specifications-tab">
              <!-- <table class="table fs--1 mt-3">
                <tbody>
                  <tr>
                    <td class="bg-100" style="width: 30%;">Processor</td>
                    <td>2.3GHz quad-core Intel Core i5,</td>
                  </tr>
                  <tr>
                    <td class="bg-100" style="width: 30%;">Memory</td>
                    <td>8GB of 2133MHz LPDDR3 onboard memory</td>
                  </tr>
                  <tr>
                    <td class="bg-100" style="width: 30%;">Brand Name</td>
                    <td>Apple</td>
                  </tr>
                  <tr>
                    <td class="bg-100" style="width: 30%;">Model</td>
                    <td>Mac Book Pro</td>
                  </tr>
                  <tr>
                    <td class="bg-100" style="width: 30%;">Display</td>
                    <td>13.3-inch (diagonal) LED-backlit display with IPS technology</td>
                  </tr>
                  <tr>
                    <td class="bg-100" style="width: 30%;">Storage</td>
                    <td>512GB SSD</td>
                  </tr>
                  <tr>
                    <td class="bg-100" style="width: 30%;">Graphics</td>
                    <td>Intel Iris Plus Graphics 655</td>
                  </tr>
                  <tr>
                    <td class="bg-100" style="width: 30%;">Weight</td>
                    <td>7.15 pounds</td>
                  </tr>
                  <tr>
                    <td class="bg-100" style="width: 30%;">Finish</td>
                    <td>Silver, Space Gray</td>
                  </tr>
                </tbody>
              </table> -->
            </div>
            <div class="tab-pane fade" id="tab-reviews" role="tabpanel" aria-labelledby="reviews-tab">
              <!-- <div class="row mt-3">
                <div class="col-lg-6 mb-4 mb-lg-0">
                  <div class="mb-1"><span class="fa fa-star text-warning fs--1"></span><span
                      class="fa fa-star text-warning fs--1"></span><span
                      class="fa fa-star text-warning fs--1"></span><span
                      class="fa fa-star text-warning fs--1"></span><span
                      class="fa fa-star text-warning fs--1"></span><span class="ms-3 text-dark fw-semi-bold">Awesome
                      support, great code 😍</span>
                  </div>
                  <p class="fs--1 mb-2 text-600">By Drik Smith • October 14, 2019</p>
                  <p class="mb-0">You shouldn't need to read a review to see how nice and polished this theme is. So
                    I'll tell you something you won't find in the demo. After the download I had a technical question,
                    emailed the team and got a response right from the team CEO with helpful advice.</p>
                  <hr class="my-4" />
                  <div class="mb-1"><span class="fa fa-star text-warning fs--1"></span><span
                      class="fa fa-star text-warning fs--1"></span><span
                      class="fa fa-star text-warning fs--1"></span><span
                      class="fa fa-star text-warning fs--1"></span><span
                      class="fa fa-star-half-alt text-warning star-icon fs--1"></span><span
                      class="ms-3 text-dark fw-semi-bold">Outstanding Design, Awesome Support</span>
                  </div>
                  <p class="fs--1 mb-2 text-600">By Liane • December 14, 2019</p>
                  <p class="mb-0">This really is an amazing template - from the style to the font - clean layout. SO
                    worth the money! The demo pages show off what Bootstrap 4 can impressively do. Great template!!
                    Support response is FAST and the team is amazing - communication is important.</p>
                </div>
                <div class="col-lg-6 ps-lg-5">
                  <form>
                    <h5 class="mb-3">Write your Review</h5>
                    <div class="mb-3">
                      <label class="form-label">Ratting: </label>
                      <div class="d-block" data-rater='{"starSize":32,"step":0.5}'></div>
                    </div>
                    <div class="mb-3">
                      <label class="form-label" for="formGroupNameInput">Name:</label>
                      <input class="form-control" id="formGroupNameInput" type="text" />
                    </div>
                    <div class="mb-3">
                      <label class="form-label" for="formGroupEmailInput">Email:</label>
                      <input class="form-control" id="formGroupEmailInput" type="email" />
                    </div>
                    <div class="mb-3">
                      <label class="form-label" for="formGrouptextareaInput">Review:</label>
                      <textarea class="form-control" id="formGrouptextareaInput" rows="3"></textarea>
                    </div>
                    <button class="btn btn-primary" type="submit">Submit</button>
                  </form>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Bid Modal -->
<ng-template #addBid let-modal>
  <div>
      <form [formGroup]="wishlistForm">
          <div class="modal-content position-relative">
              <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                  <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                      (click)="modalDismiss()" aria-label="Close"></a>
              </div>

              <div class="modal-body p-2">
                  <div class="rounded-top-lg ps-2">
                      <p class="d-none d-md-inline-block fs--1 mt-3 ml-2"
                          style="font-weight:800;font-size:20px!important;">
                          Bidding Request
                      </p>
                  </div>
                  <div class="pb-0 ps-2">
                      <div class="row">
                          <div class="col-6">
                              <h3 class="text-capitalize">{{selectedProctData}}</h3>
                          </div>

                      </div>
                      <div class="row ">
                          <!-- Pincode -->
                          <div class="col-6">
                              <h5>Pincode</h5>
                              <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Pincode</mat-label>
                                  <input matNativeControl type="text" ngxOnlyNumbers required matInput
                                      formControlName="pincode" minlength="6" maxlength="6"
                                      (change)="getPincodeDetails($event.target.value)" [ngClass]="{
                          'is-invalid': f.pincode.invalid && f.pincode.touched && f.pincode.pristine }" value="">
                              </mat-form-field>
                              <mat-error class="text-danger"
                                  *ngIf="(submitted ||f.pincode.touched) && f.pincode.errors?.required">
                                  Pincode Is Required!
                              </mat-error>
                              <mat-error class="text-danger"
                                  *ngIf="(submitted ||f.pincode.touched) && f.pincode.errors?.maxlength">
                                  Pincode must be of 6-digits!
                              </mat-error>
                              <mat-error class="text-danger"
                                  *ngIf="(submitted ||f.pincode.touched) && f.pincode.errors?.minlength">
                                  Pincode must be of 6-digits!
                              </mat-error>
                          </div>
                          <!-- State -->
                          <div class="col-6">
                              <h5>Location</h5>
                              <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>State</mat-label>
                                  <mat-select matNativeControl aria-label="Default select example"
                                      placeholder="Select State" formControlName="location">
                                      <mat-option [value]="state" *ngFor="let state of allStates">{{ state }}
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field>
                              <mat-error class="text-danger"
                                  *ngIf="(submitted ||f.location.touched) && f.location.errors?.required">
                                  Location Is Required!
                              </mat-error>
                          </div>
                      </div>

                      <div class="row ">
                          <h5>Expected Range</h5>
                          <!-- Budget Min -->
                          <div class="col-6">
                              <h5>Minimum</h5>
                              <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Minimum</mat-label>
                                  <input matNativeControl type="text" ngxOnlyNumbers required matInput
                                      formControlName="budgetMin" placeholder="Budget Min" (change)="getMinBudget()"
                                      [ngClass]="{
                          'is-invalid': f.budgetMin.invalid && f.budgetMin.touched && f.budgetMin.pristine }"
                                      value="">
                              </mat-form-field>
                              <mat-error class="text-danger"
                              *ngIf="(submitted ||f.budgetMin.touched) && f.budgetMin.errors?.required">
                              Minimum budget Is Required!
                          </mat-error>
                          <mat-error class="text-danger" *ngIf="isLessPrice">
                            Budget  Should be less than original price
                          </mat-error>
                          </div>
                          

                          <!-- Budget Max -->
                          <div class="col-6">
                              <h5>Maximum</h5>
                              <mat-form-field class="example-full-width" appearance="outline">
                                  <mat-label>Maximum</mat-label>
                                  <input matNativeControl type="text" ngxOnlyNumbers required matInput
                                      formControlName="budgetMax" placeholder="Budget Max" (change)="getMaxBudget()"
                                      [ngClass]="{
                          'is-invalid': f.budgetMax.invalid && f.budgetMax.touched && f.budgetMax.pristine }"
                                      value="">
                              </mat-form-field>
                              <mat-error class="text-danger"
                                  *ngIf="(submitted ||f.budgetMax.touched) && f.budgetMax.errors?.required">
                                  Maximum  budget Is Required!
                              </mat-error>
                              <mat-error class="text-danger" *ngIf="isHighPrice">
                                Budget should be less than original price
                              </mat-error>
                              <mat-error class="text-danger" *ngIf="isHighPriceMin">
                                Budget should be greater than minimum price
                              </mat-error>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col">
                              <div class="form-check mt-2">
                                  <input class="form-check-input" type="checkbox" formControlName="publish" />
                                  <label class="form-check-label mr-2" for="flexCheckDefault">
                                      {{ chkBoxTxt }}
                                  </label>
                                  <span class="bi-info-circle text-primary fs-1 margin-left" data-bs-toggle="tooltip"
                                      data-bs-placement="bottom" title="Publish bid to notify retailer"></span>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row mt-3 mb-3 m-2">
                  <div class="col-6 text-center d-grid gap-2">
                      <a class="btn btn-warning" (click)="modalDismiss()">Cancel</a>
                  </div>
                  <div class="col-6 d-grid">
                      <a class="btn btn-warning" data-bs-target="#PopUp" (click)="postwishlistBid(popup)">Submit</a>
                  </div>
              </div>
          </div>
      </form>
  </div>
</ng-template>


<ng-template #popup let-modal>
  <div class="modal-header">
      <h4 class="modal-title">Bid Added</h4>
      <button type="button" class="close btn btn-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body p-0">
      <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light" *ngIf="this.wishlistForm.controls.publish.value == false">
          <h2 class="mb-1 text-center font-weight-bold" style="font-weight: 800; " id="modalExampleDemoLabel">
              <!-- {{this.wishlistForm.controls.publish.value}} -->
              Bid sent to bid list sucessfully.
          </h2>
      </div>
      <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light" *ngIf="this.wishlistForm.controls.publish.value == true">
          <h2 class="mb-1 text-center font-weight-bold" style="font-weight: 800; " id="modalExampleDemoLabel">
              <!-- {{this.wishlistForm.controls.publish.value}} -->
              Bid notified to the seller sucessfully.
          </h2>
      </div>
  </div>
  <div *ngIf="this.wishlistForm.controls.publish.value == true" class="modal-footer justify-content-center">
      <button class="btn btn-warning" (click)="modal.dismiss()" routerLink="/pages/customer/bidding">Go To Bid
          List
      </button>
  </div>
  <div *ngIf="this.wishlistForm.controls.publish.value == false" class="modal-footer justify-content-center">
      <button class="btn btn-warning" (click)="modal.dismiss()" routerLink="/pages/customer/bidding">Go To Bid
          List
      </button>
  </div>
</ng-template>