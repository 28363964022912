import { Component, OnInit, ViewChild } from '@angular/core';
import lang from "src/assets/langTranslation/language_translation"
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { ToWords } from 'to-words';
import { TitleCasePipe } from "@angular/common";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PaymentInComponent } from '../payment-in/payment-in.component';
import { saveAs } from 'file-saver';
import { CommonService } from "src/app/core/services/common.service";
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-payment-in-list',
  templateUrl: './payment-in-list.component.html',
  styleUrls: ['./payment-in-list.component.scss']
})
export class PaymentInListComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  @ViewChild('closebutton') closebutton;
  public lang = lang.UAE;
  totalGST = 0;
  public invoiceData: any = [];
  public saleProductData: any = [];
  public basicDetails: any = [];
  public addressDetails: any = [];
  public salesData: any = [];
  public BusinessName: any;
  public invoiceNo: any;
  public imageSrc: any = '';
  public imageLoaded: boolean = false;
  public billGstAmount: number;
  public totalBillAmount: number;
  public finalAmount: any;
  public wordAmount = '';
  public gstStateMatch: boolean = true;
  public tradeName: any
  public searchKey: any;
  downloadData: any = [];
  billingAddress: any;
  shippingAddress: any;
  customerName: any;
  invoiceDate:any;
  description:any;
  receiptType: any;
  supplyType: any;
  public isArabic: boolean;

  toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: true,
    }
  });
  public p = 1;
  public currentpage: any;
  public totalrow: any;
  public toatlPages: any;

  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  public pagesize: number = 1;
  public count: number = 0;
  public signImg: any = '';
  TextSizeprint: string = 'Medium';
  public invoiceTermsCon:any;

  constructor(private saleInvoiceService: SaleInvoiceService,
    public dialog: MatDialog,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
    private commonService: CommonService,
    public profileService: ProfileService
    , private modal: NgbModal) { }

  ngOnInit(): void {
    this.invoiceTermsCon=localStorage.getItem('termsAndConditions');
    // Subscribe to the observable to receive updates
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });

    this.getAllpaymentIn();

    this.saleInvoiceService.saleInvoiceAdded$.subscribe(() => {
      // Fetch the updated list of users
      this.getAllpaymentIn();
    });
    this.getProfileData();
  }
 
  getAllpaymentIn() {
    this.saleInvoiceService.getAllSaleInvoice({}, this.currentUser.id, "RECEIVED", this.partyPageSize, (this.currentPageNo + 1)).then((res) => {
      this.invoiceData = res.data.pageData;
      this.totalrow = res.data.totalrows;
      //console.log("PAYMENTIN response",res);
    })
  }

  getAllPaymentInSearch(searchKey) {
    if (this.searchKey == "") {
      this.currentPageNo = 0;
      this.saleInvoiceService.getAllSaleInvoiceSearch({}, this.currentUser.id, "RECEIVED", this.partyPageSize, (this.currentPageNo + 1), searchKey.trim())
        .then((res) => {
          this.invoiceData = res.data.pageData;
          this.totalrow = res.data.totalrows;
          if (this.currentPageNo > 0) {
            if (this.invoiceData.length === 0) {
              this.currentPageNo = 0;
              this.getAllPaymentInSearch(this.searchKey);
            }
          }
        });
    }
    else {
      this.currentPageNo = 0;
      this.saleInvoiceService.getAllSaleInvoiceSearch({}, this.currentUser.id, "RECEIVED", this.partyPageSize, (this.currentPageNo + 1), searchKey.trim())
        .then((res) => {
          this.invoiceData = res.data.pageData;
          this.totalrow = res.data.totalrows;
          if (this.currentPageNo > 0) {
            if (this.invoiceData.length === 0) {
              this.currentPageNo = 0;
              this.getAllPaymentInSearch(this.searchKey);
            }
          }
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        })
    }

  }
  /**
   * get receipt for invoive by id
   * @param receiptId
   */
  getReceiptById(receiptId) {
    this.saleInvoiceService.getSaleInvoiceById({}, receiptId).then((res) => {
      this.salesData = res.data;
      this.customerName = res.data.customerName;
      this.description = res.data.description;
      this.saleProductData = res.data.sales_products;
      this.billingAddress = res.data.billingAddress;
      this.shippingAddress = res.data.shippingAddress;
      this.invoiceNo = res.data.invoiceNo;
      this.invoiceDate = res.data.invoiceDate;
      this.billGstAmount = res.data.billGstAmount;
      this.totalGST = res.data.billGstAmount;
      this.totalBillAmount = res.data.totalBillAmount;
      this.receiptType = res.data.receiptType;
      this.supplyType = res.data.supplyType;
      this.finalAmount = this.totalBillAmount
      this.convertToWord(this.finalAmount)

      if (this.salesData.supplyType == 'INTERSTATE') {

        this.gstStateMatch = false;
      } else {
        this.gstStateMatch = true;
      }

    })
  }
  getProfileData() {
    this.profileService.getUserProfile({}, this.currentUser.id).then((res => {
      this.imageSrc = res.data.basicdetails.profileImage;
      this.addressDetails = res.data.primaryAddress;
      this.basicDetails = res.data.basicdetails;
      this.BusinessName = res.data.additional.legalName;
      this.tradeName = res.data.additional.tradeName;
      this.signImg = res.data.basicdetails.addSignature

    }),
    )
  }



  onPrint() {
    const printContents = document.getElementById('panel').innerHTML;
    const originalContents = document.body.innerHTML;
    console.log("originalContents", originalContents)

    window.print();
  }
  handleImageLoad() {
    this.imageLoaded = true;
  }

  closeModal() {
  }
  convertToWord(num) {
    this.finalAmount = this.toWords.convert(num);
  }
  // for pagination
  handlePage(e) {
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.invoiceData.length > 0) {
      this.partyPageSize = e.pageSize;
      
    const searchInput = document.getElementById('searchInput') as HTMLInputElement;
    if (searchInput.value !== '') {
      this.getAllPaymentInSearch(searchInput.value);
    } else {
      this.getAllpaymentIn();
    }
    }
  }

  openDialogPayment(): void {
    const dialogRef: MatDialogRef<PaymentInComponent> = this.dialog.open(PaymentInComponent, {
      // width: '1050px',
      height : '730px',
      data: { },
      panelClass: 'custom-Modal-payment-invoice',
      disableClose:true,
      position: {
        right: '0',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  downlaodAllData(){
    if(this.totalrow === undefined || this.totalrow === null){
      // this.toastService.openErrorSnackBar("No Data Available For Download");
      this.toastService.toastMsg({
        title: "Error",
        content: "No Data Available For Download",
      });
    } else {
        this.saleInvoiceService.getAllSaleInvoice({}, this.currentUser.id, "RECEIVED", this.totalrow, (this.currentPageNo + 1)).then((res) => {
          this.downloadData = res.data.pageData;
          if(this.downloadData.length > 0){
            this.exportToExcel()
          } else {
            this.toastService.toastMsg({
              title: "Error",
              content: "No Data Available For Download",
            });
            // this.toastService.openErrorSnackBar("No Data Available For Download");
          }
        },(err)=>{
          if(err.error.status === 500){
            // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
        })
    }
  }

  exportToExcel(): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.downloadData);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(data, 'payment_in_data.xlsx');
  }


  //

  openViewModel(paymentInId: any) {
    // const dialogRef = this.dialog.open(ExpenseViewComponent, {
    //   width: '1000px', // Adjust the width as per your requirement
    //   height: '600px', // Adjust the height as per your requirement
    //   data: { paymentInId: paymentInId },
    //   panelClass: 'custom-dialog-expense-class'
    // });

    // dialogRef.afterClosed().subscribe(result => {

    // });
  }
  getTextSize(selectedTextSize: string): number {
		switch (selectedTextSize) {
		  case 'Large':
			return 22;
		  case 'Medium':
			return 20;
		  case 'Small':
			return 18;
		  default:
			return 20; // Default to 'Medium' size if an invalid option is selected
		}
	  }

	  getTextSizeprint(TextSizeprint: string): number {
		switch (TextSizeprint) {
		  case 'Large':
			return 16;
		  case 'Medium':
			return 14;
		  case 'Small':
			return 12;
		  default:
			return 14; // Default to 'Medium' size if an invalid option is selected
		}
	  }


}


