import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-receip-format',
  templateUrl: './receip-format.component.html',
  styleUrls: ['./receip-format.component.scss']
})
export class ReceipFormatComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @Input() receiptProductData = [];
  @Input() invoiceNo;
  @Input() totalBillAmount;
  @Input() billGstAmount;
  constructor(private modal: NgbActiveModal) { }

  ngOnInit(): void {
    console.log("hi");
    console.log(this.invoiceNo)
  }

  /**
   * print receipt
   */
  onPrint() {
    const printContents = document.getElementById('panel').innerHTML;
    const originalContents = document.body.innerHTML;
    //document.body.innerHTML = printContents;
    window.print();
    //document.body.innerHTML = originalContents;
    setTimeout(() => {
      // location.reload();
      this.closebutton.nativeElement.click()
    }, 1000);
  

  // let w = window.open();
  // let html = $("#panel").html();

  // $(w.document.body).html(html);
  // w.print();
}
closeModal(){
  //window.close();
  //this.closebutton.nativeElement.click();
  this.modal.dismiss();
}
}
