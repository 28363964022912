import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RemoteShopService } from 'src/app/core/services/remote-shop.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';

@Component({
  selector: 'app-order-receipts',
  templateUrl: './order-receipts.component.html',
  styleUrls: ['./order-receipts.component.scss']
})
export class OrderReceiptsComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  public myOrderData =[];
  public orderDetails = [];
  public orderId:any;
  public p = 1;
  public currentpage: any;
  public totalrow: any;
  public toatlPages: any;
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  public pagesize: number = 1;
  public count: number = 0;
  constructor(private remoteShopService: RemoteShopService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe) { 
  }
  ngOnInit(): void {
    this.getCustomerAllOrders(); 
  }
  getCustomerAllOrders() {
    this.remoteShopService.getCustomerAllOrders({}, this.currentUser.id,this.partyPageSize, (this.currentPageNo + 1)).then((res) => {
      this.myOrderData = res.data;
    },(err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
          });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    })
  }
  getOrderDetails(item,id) {
    this.orderDetails = item;
    this.orderId = id;
  }
}
