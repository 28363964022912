<div class="card mb-3">
    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
          Product Grid
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="mb-4 col-md-6 col-lg-4">
                <div class="border rounded-1 h-100 d-flex flex-column justify-content-between pb-3">
                    <div class="overflow-hidden">
                        <div class="position-relative rounded-top overflow-hidden"><a class="d-block"
                                routerLink="/pages/product-details"><img class="img-fluid rounded-top"
                                    src="../../../assets/img/products/2.jpg" alt="" /></a><span
                                class="badge rounded-pill bg-success position-absolute mt-2 me-2 z-index-2 top-0 end-0">New</span>
                        </div>
                        <div class="p-3">
                            <h5 class="fs-0"><a class="text-dark" routerLink="/pages/product-details">Apple iMac Pro
                                    (27-inch
                                    with Retina 5K Display, 3.0GHz 10-core Intel Xeon W, 1TB SSD)</a></h5>
                            <p class="fs--1 mb-3"><a class="text-500" href="#!">Computer &amp; Accessories</a></p>
                            <h5 class="fs-md-2 text-warning mb-0 d-flex align-items-center mb-3"> $1199.5
                                <del class="ms-2 fs--1 text-500">$2399 </del>
                            </h5>
                            <p class="fs--1 mb-1">Shipping Cost: <strong>$50</strong></p>
                            <p class="fs--1 mb-1">Stock: <strong class="text-success">Available</strong>
                            </p>
                        </div>
                    </div>
                    <div class="d-flex flex-between-center px-3">
                        <div><span class="fa fa-star text-warning"></span><span
                                class="fa fa-star text-warning"></span><span
                                class="fa fa-star text-warning"></span><span
                                class="fa fa-star text-warning"></span><span class="fa fa-star text-300"></span><span
                                class="ms-1">(8)</span>
                        </div>
                        <div><a class="btn btn-sm btn-falcon-default me-2" href="#!" data-bs-toggle="tooltip"
                                data-bs-placement="top" title="Add to Wish List"><span
                                    class="far fa-heart"></span></a><a class="btn btn-sm btn-falcon-default" href="#!"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Add to Cart"><span
                                    class="fas fa-cart-plus"></span></a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer bg-light d-flex justify-content-center">
        <div>
            <button class="btn btn-falcon-default btn-sm me-2" type="button" disabled="disabled"
                data-bs-toggle="tooltip" data-bs-placement="top" title="Prev"><span
                    class="fas fa-chevron-left"></span></button><a
                class="btn btn-sm btn-falcon-default text-primary me-2" href="#!">1</a><a
                class="btn btn-sm btn-falcon-default me-2" href="#!">2</a><a class="btn btn-sm btn-falcon-default me-2"
                href="#!"> <span class="fas fa-ellipsis-h"></span></a><a class="btn btn-sm btn-falcon-default me-2"
                href="#!">35</a>
            <button class="btn btn-falcon-default btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="top"
                title="Next"><span class="fas fa-chevron-right"> </span></button>
        </div>
    </div>
</div>