<div class="container-fluid mb-3 mx-0 bg-white">
  <div class="row pt-3 pb-3 mt-" id="headingPrintHide">
    <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:600;font-size:20px!important;">
      All Vendors
    </div>
    <div class="col-6 navOption-btns" style="display: flex; justify-content: flex-end;">
      <button class="btn me-3" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()" type="button"><i
          class="fas fa-download"></i></button>
        
      <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
      </button>
    </div>
  </div>
  <hr class="mt-1" />
  <div class="fs--1 ">
    <div class="row ">
      <!-- <div class="col-sm-2 mt-2 p-2" style="padding-left: 17px !important;" id="dateRangePrintHide">
        <div class="form-check form-switch mb-0 lh-1">
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" (click)="toggleShow()" />
          <label class="lab text-nowrap"> Date Filter </label>
        </div>
      </div> -->
      <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;"
        class="col-xs-12 col-sm-12 col-md-3 col-lg-3" id="dateRangePrintHide">
        <label for="Date Range" class="form-label">Date</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input class="uppercase-date-range" matInput placeholder="Date" id="Date" type="text" [(ngModel)]="dateRange"
           [outsideClick]="true" ngModel bsDaterangepicker
            (bluer)="getDate()" [maxDate]="today" (ngModelChange)="changeDate($event)"
            [bsConfig]="{ranges: ranges,showWeekNumbers:false, showNextMonth: false, rangeInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true }">
          <span><mat-icon class="iconcalender p-1">date_range</mat-icon></span>
        </mat-form-field>
        <!-- <mat-form-field class="example-full-width" appearance="outline">
          <mat-label for="Date Range">Date Range</mat-label>
          <input matInput type="text" [outsideClick]="true" style="text-transform:uppercase!important;"
              [(ngModel)]="dateRange" bsDaterangepicker [maxDate]="today" (ngModelChange)="changeDate($event)"
              [bsConfig]="{ranges: ranges,showWeekNumbers:false, showNextMonth: false, rangeInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true }">
          <span><mat-icon class="iconcalender p-1">date_range</mat-icon></span>
      </mat-form-field> -->

      </div>


      <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;"
        class="col-xs-12 col-sm-12 col-md-3 col-lg-3" id="searchBarHide">
        <label class="form-label">Select Vendor Name</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input type="text" placeholder="Vendor Name" aria-label="Select Party Name" matInput
            (keyup)="searchPartyByName($event.target.value)" [matAutocomplete]="auto" [ngClass]="{
          'is-invalid': (partyName.invalid && partyName.touched) && submitted }" required>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
              (onSelectionChange)="searchPartyByName1(option)">
              {{option.partyName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <!-- <div class="col-sm-4"></div>
      <div class="col-sm-1  mt-2 text-center" id="excelPrintHide" (click)="exportExcel()">
        <a type="button" style="margin-left: 14px;" class="p-0 text-900 bi-file-earmark-excel fa-2x "></a>
        <div class="text-nowrap text-900  fs--2  mt-1">Export to Excel</div>

      </div>
      <div class="col-sm-1 mt-2 text-center" id="PrintoptionHide" (click)="onPrint()">
        <a type="button" class=" p-0 text-900 bi-printer float-right fa-2x" (click)="printComponent('component1')"></a>
        <div class="fs--2 text-900 text-nowrap mt-1">Print</div>
      </div>
    </div> -->


      <div class="table-responsive scrollbar mt-4 fs--1">
        <table class="table bg-white table-bordered table-stripped mb-3" id="excel-table">
          <thead class="" style="background-color: #F0F0F0;">
            <tr class="text-900">
              <th scope="col" class="text-center text-nowrap p-1" style="width: 6%;">#
                <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false"><i style="margin-left: 1px;"
                    class="text-1400  fs-1"></i></button>
              </th>
              <th scope="col" class="text-center text-nowrap p-1" style="width: 6%;">VENDOR NAME
                <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false"><i style="margin-left: 1px;"
                    class="text-1400  fs-1"></i></button>
              </th>
              <!-- <th scope="col" class="text-center text-nowrap pt-1">PARTY NAME -->
              <!-- <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false"><i style="margin-left: 10px;"
                  class="text-1400  fs-1 bi-filter-right"></i></button> -->
              <!-- <div class="dropdown-menu scrollbar p-3 " style="width: 22%;" aria-labelledby="dropdownMenuButton">
                <li>
                  <select class="form-select form-select-sm" aria-label="Default select example">
                    <option selected="">Contains</option>
                    <option value="1">Exact match</option>
                  </select>
                </li>
                <li>
                  <div class="col-sm" style="margin-top: 10px;">
                    <input class="form-control fs--1" id="inputPassword3" type="input" />
                  </div>
                </li>
                <div class="dropdown-divider"></div>
                <div class="text-center">
                  <div class="btn-group mb-2 mb-md-0">
                    <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                  </div>
                  <div class="btn-group mb-2 mb-md-0">
                    <button class="btn btn-primary btn-sm " type="button" style="margin-left: 8px;">APPLY</button>
                  </div>
                </div>
              </div> -->
              <!-- </th> -->
              <!-- <th scope="col text-center text-nowrap" class="text-nowrap text-center p-1">EMAIL
              <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false"><i style="margin-left: 10px;"
                  class="text-1400  fs-1 bi-filter-right"></i></button>
              <div class="dropdown-menu scrollbar p-3 " style="width: 22%;" aria-labelledby="dropdownMenuButton">
                <li>
                  <select class="form-select form-select-sm" aria-label="Default select example">
                    <option selected="">Contains</option>
                    <option value="1">Exact match</option>
                  </select>
                </li>
                <li>
                  <div class="col-sm" style="margin-top: 10px;">
                    <input class="form-control fs--1" id="inputPassword3" type="input" />
                  </div>
                </li>
                <div class="dropdown-divider"></div>
                <div class="text-center">
                  <div class="btn-group mb-2 mb-md-0">
                    <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                  </div>
                  <div class="btn-group mb-2 mb-md-0">
                    <button class="btn btn-primary btn-sm " type="button" style="margin-left: 8px;">APPLY</button>
                  </div>
                </div>
              </div>
            </th> -->
              <!-- <th scope="col" class="text-center text-nowrap p-1">MOBILE NUMBER</th>
            <th scope="col" class="text-center text-nowrap p-1">ADDRESS</th> -->
              <th scope="col" class="text-center text-nowrap p-1" style="width: 6%;">MOBILE #
                <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false"><i style="margin-left: 1px;"
                    class="text-1400  fs-1"></i></button>
              </th>
              <th scope="col" class="text-center text-nowrap p-1" style="width: 6%;">ADDRESS
                <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false"><i style="margin-left: 1px;"
                    class="text-1400  fs-1"></i></button>
              </th>
              <th scope="col" class="text-center text-nowrap p-1">GSTIN
                <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false"><i style="margin-left: 10px;color:#FF9A62"
                    class="text-1400  fs-1 bi-filter-right"></i></button>
                <div class="dropdown-menu scrollbar p-3 " style="width: 22%;" aria-labelledby="dropdownMenuButton">
                  <li>
                    <select class="form-select form-select-sm" aria-label="Default select example"
                      (change)="onSelectgst($event.target.value)">

                      <!-- <option selected="">Contains</option>
                    <option value="1">Exact match</option> -->
                      <option value="Equal">Equal to</option>
                    </select>
                  </li>
                  <li>+
                    <div class="col-sm" style="margin-top: 10px;">
                      <input class="form-control fs--1" id="inputPassword3" type="input" [(ngModel)]="totalgst" />
                    </div>
                  </li>
                  <div class="dropdown-divider"></div>
                  <div class="text-center">
                    <div class="btn-group mb-2 mb-md-0">
                      <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                    </div>
                    <div class="btn-group mb-2 mb-md-0">
                      <button class="btn btn-primary btn-sm " type="button" style="margin-left: 8px;"
                        (click)="Applygst()">APPLY</button>
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col" class="text-center text-nowrap p-1">RECEIVABLE BALANCE
                <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false"><i style="margin-left: 10px;color:#FF9A62"
                    class="text-1400  fs-1 bi-filter-right"></i></button>
                <div class="dropdown-menu scrollbar p-3 " style="width: 22%;" aria-labelledby="dropdownMenuButton">
                  <li>
                    <select class="form-select form-select-sm" aria-label="Default select example"
                      (change)="onSelectReceivable($event.target.value)">
                      <option value="Equal">Equal to</option>
                      <option value="Greater">Greater than</option>
                      <option value="Less">Less than</option>
                    </select>
                  </li>
                  <li>
                    <div class="col-sm" style="margin-top: 10px;">
                      <input class="form-control fs--1" id="inputPassword3" type="input"
                        [(ngModel)]="totalReceivable" />
                    </div>
                  </li>
                  <div class="dropdown-divider"></div>
                  <div class="text-center">
                    <div class="btn-group mb-2 mb-md-0">
                      <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                    </div>
                    <div class="btn-group mb-2 mb-md-0">
                      <button class="btn btn-primary btn-sm " type="button" style="margin-left: 8px;"
                        (click)="ApplyReceivable()">APPLY</button>
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col" class="text-center text-nowrap p-1">PAYABLE BALANCE
                <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false"><i style="margin-left: 10px;color:#FF9A62"
                    class="text-1400  fs-1 bi-filter-right"></i></button>
                <div class="dropdown-menu scrollbar p-3 " style="width: 22%;" aria-labelledby="dropdownMenuButton">
                  <li>
                    <select class="form-select form-select-sm" aria-label="Default select example"
                      (change)="onSelectPayable($event.target.value)">
                      <option value="Equal">Equal to</option>
                      <option value="Greater">Greater than</option>
                      <option value="Less">Less than</option>
                    </select>
                  </li>
                  <li>
                    <div class="col-sm" style="margin-top: 10px;">
                      <input class="form-control fs--1" id="inputPassword3" type="input" [(ngModel)]="totalPayable" />
                    </div>
                  </li>
                  <div class="dropdown-divider"></div>
                  <div class="text-center">
                    <div class="btn-group mb-2 mb-md-0">
                      <button class="btn btn-primary btn-sm " type="button">CLEAR</button>
                    </div>
                    <div class="btn-group mb-2 mb-md-0">
                      <button class="btn btn-primary btn-sm " type="button" style="margin-left: 8px;"
                        (click)="ApplyPayable()">APPLY</button>
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of partyList; let i = index">
              <td>{{ i + 1 }} </td>
              <td>{{item.partyName}}</td>
              <td>{{ item.mobile }}</td>
              <td>{{ item.billingAddress }}</td>
              <!-- <td>{{item.email}}</td> -->
              <td class="text-center">{{item.gstIn}}</td>
              <td class="text-center">{{item.totalDebt}}</td>
              <td class="text-center">{{item.totalCredit}}</td>

            </tr>
          </tbody>

          <tbody *ngIf="partyList == 0">
            <tr class="text-center">
              <td colspan="10">
                <img class="image-responsive" src="assets/img/icons/spot-illustrations/GST No Transaction.png"
                  width="124" height="142" alt="">
                  <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Data Found.</h5>

                <!-- <h5 class="text-nowrap">No Party Details Added. Please Add Party Details To Be
                  Displayed
                  Here. <span id="linkPrintHide">
                   
                  </span></h5> -->
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>

              <td class="nowrap" colspan="1">
                <strong class=" text-dark">
                  Total
                </strong>
              </td>
              <!-- <td class = "nowrap" colspan="4"></td> -->
              <td class="nowrap" colspan="1">
                <strong class=" text-dark">
                  <a class="text-success">
                    {{itemPartyList.TotalSaleAmount ? itemPartyList.TotalSaleAmount :"-" }}
                  </a>
                </strong>
              </td>
              <td class="nowrap" colspan="1">
                <strong class="fw-bold text-dark">
                  <a class="text-success">
                    {{itemPartyList2.TotalPurchasAmount ? itemPartyList2.TotalPurchasAmount :"-"}}
                  </a>
                </strong>
              </td>
            </tr>
          </tfoot>
        </table>
        <div id="headingPrintHide1">
          <mat-paginator class="float-right mt-2" *ngIf="partyList" [pageSize]="partyPageSize" showFirstLastButtons
            [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo"
            (page)="handlePage($event)" aria-label="Select page">
          </mat-paginator>
        </div>

      </div>
    </div>

  </div>