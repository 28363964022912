<div class="card">
    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-none d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
            My Orders
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>
    <div class="card-body p-0 m-2">
        <div class="table-responsive">
            <table class="table table-bordered  fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Order Id</th>
                        <th class="text-nowrap">Customer Name</th>
                        <th class="text-nowrap">Billing Address</th>
                        <th class="text-nowrap">Price</th>
                        <th class="text-nowrap">GST Rate</th>
                        <th class="text-nowrap">Total</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="btn-reveal-trigger text-left" *ngFor="let item of myOrderData;let i = index">
                        <td>{{i+1}}</td>
                        <td>{{ item.orderId }}</td>
                        <td>{{ item.customerName | titlecase }}</td>
                        <td>{{ item.billingAddress | titlecase }}</td>
                        <td>{{ item.totalBillAmount - item.billGstAmount | currency:'INR':'symbol':'1.2-2'}}</td>
                        <td>{{ item.billGstAmount | currency:'INR':'symbol':'1.2-2'}}</td>
                        <td>{{ item.totalBillAmount | currency:'INR':'symbol':'1.2-2'}}</td>
                        <td class="text-nowrap">
                            <button class="btn btn-outline-primary btnp"
                                (click)="getOrderDetails(item.sales_products,item.orderId)" data-bs-toggle="modal"
                                data-bs-target="#orderDetails-modal">Order Details</button>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="myOrderData.length == 0">
                    <tr class="text-center">
                        <td colspan="10">
                            <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png" alt="notfound1" width="200" height="150" />

                            <h5 class="text-nowrap">No Data Found. Please Create an Order To Be Displayed Here. <span>
                                    <a class="nav-link" routerLink="/pages/customer/remote-customer">Start Shopping</a>
                                </span></h5>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="modal fade" id="orderDetails-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <!-- <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" #closebutton
                    data-bs-dismiss="modal" aria-label="Close"></a>
            </div> -->
            <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                    <h4 class="mb-1" id="modalExampleDemoLabel">Order #{{ orderId }}</h4>
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered fs--1 mb-0  m-2">
                        <thead class="bg-200 text-900">
                            <tr>
                                <th scope="col">#</th>
                                <th class="text-nowrap">Item Name</th>
                                <th class="text-nowrap">Quantity</th>
                                <th class="text-nowrap">Price</th>
                                <th class="text-nowrap">GST(in %)</th>
                                <th class="text-nowrap">GST(in Rs.)</th>
                                <th class="text-nowrap">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="btn-reveal-trigger text-left" *ngFor="let item of orderDetails; let i = index">
                                <td >{{ i+1 }}</td>
                                <td>{{ item.itemName | titlecase }}</td>
                                <td>{{ item.quantity }}</td>
                                <td>{{ item.totalPrice | currency:'INR':'symbol':'1.2-2'}}</td>
                                <td>{{ item.gst }}</td>
                                <td>{{ item.gstAmount | currency:'INR':'symbol':'1.2-2'}}</td>
                                <td>{{ item.finalAmount | currency:'INR':'symbol':'1.2-2'}}</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="orderDetails.length == 0">
                            <tr class="text-center">
                                <td colspan="10">
                                    <h5 class="text-nowrap">404 Not found<span>
                                        </span></h5>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <a class="btn btn-outline-primary" data-bs-dismiss="modal">Close</a>
            </div>
        </div>
    </div>
</div>

