import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BrowserDynamicTestingModule } from '@angular/platform-browser-dynamic/testing';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { UPIserviceService } from 'src/app/core/services/upiservice.service';

@Component({
  selector: 'app-upi-payment',
  templateUrl: './upi-payment.component.html',
  styleUrls: ['./upi-payment.component.scss']
})
export class UpiPaymentComponent implements OnInit {

  public upiForm: any;
  public submitted: boolean;
  public UpiListRes: any;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public isDisabled: boolean;  /* c */
  public upiNo: any;
  public SelectedUpiPrimary: boolean = false;
  public Upitoggel: boolean = true;
  public upiCheckedId = [];
  public allSelect: boolean = false;
  public checkSelect: boolean = false;
  public checkSelectsingle: boolean = false;
  public singleCheckData: any;
  public form: FormGroup;
  public checkSingleArray: any = [];
  public isChecked: boolean = false;


  constructor(private formBuilder: FormBuilder,
    private upiservice: UPIserviceService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,


    private modal: NgbModal,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.upiForm = this.formBuilder.group({
      UPIID: ["", [Validators.required, Validators.pattern('[a-zA-Z0-9]*@[a-zA-Z]*')]],

    })
    this.form = this.formBuilder.group({
      checkArray: this.formBuilder.array([]),
    });

    this.getAllUpi();

  }
  getAllUpi() {
    this.upiservice.getUPIDetails({}, this.currentUser.id).then((res) => {
      this.UpiListRes = res.data;
      this.SelectedUpiPrimary = true;
    })
  }
  upiSave() {
    this.submitted = true;
    let body = {
      "upiDetails": {
        "upiNo": this.upiForm.controls.UPIID.value,
      }

    }
    if (this.upiForm.valid) {
      this.upiservice.postUPIDetails(body, this.currentUser.id).then((res) => {
        if (res) {
          this.toastService.toastMsg({
            title: "Success",
            content: "UPI Added Successfully!!!",
          });
          // this.toastService.openSnackBar("UPI Added Successfully!!!");
          this.getAllUpi();
          this.upiForm.reset();
          this.submitted = false;
          this.modal.dismissAll();
        }
      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      });

    }
  }

  deleteUPI() {
    this.upiservice.deleteUPIupiNo({}, this.upiNo).then((res) => {
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "UPI Deleted Successfully!!!",
        });
        // this.toastService.openSnackBar("UPI Deleted Successfully!!!");
        this.getAllUpi();
      }
    })

  }
  deletemultipleUPI() {

    let body = {
      "upiNo": this.upiNo
    }

    this.upiservice.deletemultipleUPIupiNo(body, this.currentUser.id).then((res) => {
      // this.toastService.openSnackBar("UPI Deleted Successfully!!!");
      
      this.getAllUpi();
      this.isChecked = false;
      this.checkSingleArray = [];
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    })

  }

  get f() {
    return this.upiForm.controls;
  }

  modalDismiss() {
    this.upiForm.reset();
    this.modal.dismissAll();
  }

  getUPIID(upiNo: any) {
    this.upiNo = upiNo;
  }
  getUPIcheckId(upino: any) {
    this.upiNo = upino;
  }
  getSingleCheckedId(upino: any) {
    this.upiNo = upino;
  }

  openAddUpiPopup(modal) {
    this.modal.open(modal, {
      centered: true,
      backdrop: "static",
      size: "md"
    });
  }
  toggleShow(id, e, i) {
    this.SelectedUpiPrimary = e;
    let body =
    {
      "upiId": id
    }
    if (this.SelectedUpiPrimary === true) {

      this.upiservice.getPrimaryUpi(body, this.currentUser.id).then((res) => {
        // this.toastService.openSnackBar("UPI Primary ID Added Successfully");
        this.toastService.toastMsg({
          title: "Success",
          content: "UPI Primary ID Added Successfully!!!",
        });
        this.getAllUpi();
      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })
    }
  }
  onChekMultiple($event) {
    let id = $event.target.value;
    this.isChecked = $event.target.checked;
    this.upiCheckedId = this.UpiListRes.map((item) => {
      if (id == -1) {
        if (this.isChecked == true) {
          this.checkSelectsingle = true;
          return item.id;
        }
        else {
          this.checkSelectsingle = false;
          this.upiCheckedId = [];
        }
      }
    })


  }
  onChekedsingleCheck(e) {
    const checkArray: FormArray = this.form.get('checkArray') as FormArray;
    if (e.target.checked) {
      this.checkSingleArray.push(e.target.value);
    }
    else {
      this.checkSingleArray = [];
    }
  }
}


