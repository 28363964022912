
<div class="modal-header pt-0" id="Add-Party-modal">

  <h1 style="color: #EB8B3F; font-weight: 500;margin-left: 8px;" class="modal-title mb-0">Update Employee</h1>

  <div class="addProductButtons">

    <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel" (click)="cancel()">Cancel</button>
    <button type="submit" class="btn btn-save" *appHasPermission="['create']; name 'employees'"
    (click)="UpdateUsersWithRoles()">Save</button>
  </div>
</div>


<div class="modal-body">
    <div class="container-fluid p-2">
        <form [formGroup]="usersForm" autocomplete="off">
            <div class="row">
                <div class="mb-3 col-lg-6">
                    <div class="image-wrapper">
                        <label class="profile-pic" ondragover="return false;" [class.loaded]="loaded"
                            [style.outlineColor]="dragging ? activeColor : baseColor" (dragenter)="handleDragEnter()"
                            (dragleave)="handleDragLeave()" (drop)="handleDrop($event)">
                            <img [src]="profilePic ? profilePic : '/assets/images/default-profile-pic.JPG'"
                                (load)="handleImageLoad()" [class.loaded]="imageLoaded" class="shadow-sm" />
                            <input type="file" name="file" accept="image/*" (change)="handleInputChange($event)">
                        </label>
                        <button *ngIf="profilePic" class="delete-button" type="button" (click)="deleteProfilePic()">
                            <i class="bi bi-x bi-sm"></i>
                        </button>
                    </div>
                </div>
                <div class="mb-3 col-lg-6">
                    <label class="form-label">Full Name  <span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input type="text" matInput required formControlName="firstName" maxLength="50"
                            placeholder="Full Name" [ngClass]="{
                          'is-invalid': (f.firstName.invalid && f.firstName.touched) && submitted }">
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.firstName.touched) && f.firstName.errors?.required">
                        Full Name is Required!
                    </mat-error>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.firstName.touched) && f.firstName.errors?.alphabeticOnly && !f.firstName.errors?.required">
                        Only alphabets are allowed.
                    </mat-error>
                </div>
            </div>
            <div class="row">
                <div class="mb-3 col-lg-6">
                    <label class="form-label">Email  <span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">

                        <input type="text" matInput required formControlName="email" placeholder="Email" [ngClass]="{
                              'is-invalid': (f.email.invalid && f.email.touched) && submitted }" disabled>
                    </mat-form-field>
                    <!-- <mat-error class="text-danger" *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                        Email is required!
                    </mat-error>
                    <mat-error class="text-danger" *ngIf="(submitted || f.email.touched) && f.email.errors?.pattern">
                        Email must be a valid email address.
                    </mat-error> -->
                    <mat-error class="text-danger" *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                        Email is required!
                      </mat-error>
                      <mat-error class="text-danger" *ngIf="(submitted || f.email.touched) && f.email.errors?.pattern">
                        Email must be a valid email address.
                      </mat-error>
                </div>
                <div class="mb-3 col-lg-6">
                    <label class="form-label">Mobile #</label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input type="text" (keypress)="numberOnly($event)" matInput  formControlName="mobile"
                            minlength="10" maxlength="10" placeholder="Mobile #" [ngClass]="{
                              'is-invalid': (f.mobile.invalid && f.mobile.touched) && submitted }">
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.pattern">
                        mobile must be a valid mobile number .
                    </mat-error>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.minlength">
                        Mobile # must be 10-digits only
                    </mat-error>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.maxlength">
                        Mobile # must be 10-digits only
                    </mat-error>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <label class="form-label">Pincode</label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input type="text" matInput formControlName="pincode" maxlength="6" minlength="6"
                            placeholder="Pincode"
                            [ngClass]="{'is-invalid': (f.pincode.invalid && f.pincode.touched) && submitted }">
                    </mat-form-field>

                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.pincode.touched) && f.pincode.errors?.pattern">
                        Pincode should be only digits
                    </mat-error>
                </div>
                <div class="col-lg-6">
                    <label class="form-label">City</label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input type="text" matInput   formControlName="city" maxLength="50" disabled
                            placeholder="City">
                    </mat-form-field>
                </div>
            </div>
          <div class="row">
                <div class="col-lg-6">
                    <label class="form-label">State</label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input type="text" matInput   formControlName="state" maxLength="50" disabled
                            placeholder="State">
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="form-label">Country</label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input type="text" matInput  formControlName="country" maxLength="50"
                            placeholder="Country">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="mb-3">
                        <label class="form-label" for="Address">Address</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <textarea matNativeControl matInput id="address" formControlName="address"
                                placeholder="Address" rows="3"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <label class="form-label">Role Assign  <span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input type="text" matInput required  formControlName="roleAssign" maxLength="50"
                            placeholder="Role Assign" [ngClass]="{
                          'is-invalid': (f.roleAssign.invalid && f.roleAssign.touched) && submitted }">
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.roleAssign.touched) && f.roleAssign.errors?.required">
                        Role Assign is Required!
                    </mat-error>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.roleAssign.touched) && f.roleAssign.errors?.alphabeticOnly && !f.roleAssign.errors?.required">
                        Only alphabets are allowed.
                    </mat-error>
                </div>

                <div class="col-lg-6">
                    <label class="form-label">Designation  <span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input type="text" matInput required formControlName="designation" maxLength="50"
                            placeholder="Designation" [ngClass]="{
                          'is-invalid': (f.designation.invalid && f.designation.touched) && submitted }">
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.designation.touched) && f.designation.errors?.required">
                        Designation is Required!
                    </mat-error>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.designation.touched) && f.designation.errors?.alphabeticOnly && !f.designation.errors?.required">
                        Only alphabets are allowed.
                    </mat-error>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <label class="form-label">Joining Date  <span class="error-asterisk">*</span></label>
                    <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
                        <input matInput bsDatepicker placeholder="Joining Date" (click)="openDatepickerJoinDate()"
                            formControlName="joiningDate" bsDatepicker [bsConfig]="{
          containerClass: 'theme-dark-blue',
          dateInputFormat: 'DD MMM YYYY',
          showWeekNumbers:false }" [ngClass]="{
            'is-invalid': (f.joiningDate.invalid && f.joiningDate.touched) && submitted }">
                        <span>
                            <mat-icon class="iconcalender">date_range</mat-icon>
                        </span>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.joiningDate.touched) && f.joiningDate.errors?.required">
                        Joining Date is Required!
                    </mat-error>
                </div>

                <div class="col-lg-6">
                    <label class="form-label">Department</label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input type="text" matInput formControlName="department" maxLength="50"
                            placeholder="Department" [ngClass]="{
                                      'is-invalid': (f.department.invalid && f.department.touched) && submitted }">
                    </mat-form-field>

                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.department.touched) && f.department.errors?.alphabeticOnly && !f.designation.errors?.required">
                        Only alphabets are allowed.
                    </mat-error>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <label class="form-label">Salary per Year <span class="error-asterisk">*</span></label>
                    <mat-form-field appearance="outline" class="example-full-width">
                      <input matInput type="number" step="0.10"
                      [ngClass]="{
                        'is-invalid': f.salary.invalid && f.salary.touched && submitted }"formControlName="salary" placeholder="Enter salary per year"
                        min="0">

                    </mat-form-field>
                    <mat-error  class="text-danger" *ngIf="(submitted || f.salary.touched) && f.salary.errors?.required
                    ">  Salary per year is required!
                      </mat-error>
                      <mat-error class="text-danger"
                        *ngIf="(submitted || f.salary.touched) && f.salary.errors?.min && !f.salary.errors?.required">
                        Salary per year must be greater than or equal to 0
                      </mat-error>

                  </div>

                <div class="col-lg-6">
                    <label class="form-label">Gender</label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-select matNativeControl disableOptionCentering required placeholder="Gender"
                             formControlName="gender"
                            [ngClass]="{
                                'is-invalid': (f.gender.invalid && f.gender.touched) && submitted }">
                            <mat-option value="Male">Male</mat-option>
                            <mat-option value="Female">Female</mat-option>
                            <mat-option value="Transgender">Transgender</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submitted || f.gender.touched) && f.gender.errors?.required">
                        Gender is Required!
                    </mat-error>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.gender.touched) && f.gender.errors?.alphabeticOnly && !f.designation.errors?.required">
                        Only alphabets are allowed.
                    </mat-error>
                </div>


            </div>

            <div class="row">
                <div class="col-lg-6">
                  <label class="form-label">Assets</label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select  placeholder="Select Assets" formControlName="asset" >
                      <mat-option *ngFor="let item of assets" [value]="item.name"> {{ item.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="form-label">Resignation Date</label>
                    <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
                        <input matInput  bsDatepicker placeholder="Resignation Date" [minDate]="usersForm.get('joiningDate').value"
                            formControlName="resignationDate" bsDatepicker [bsConfig]="{
                          containerClass: 'theme-dark-blue',
                          dateInputFormat: 'DD MMM YYYY',
                          showWeekNumbers:false }" (click)="openDatepickeresignDate()">
                        <span>
                            <mat-icon class="iconcalender">date_range</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
              </div>
        </form>
    </div>
</div>
