import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentInService {

  constructor( public httpClient:HttpService) { }
  postpaymentInParty(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.PaymentInPost ,'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  // postupload(data: {}): any {
  //   return new Promise((resolve, reject) => {
  //     this.httpClient.inventory(data, UrlConstants.postuploadImagePayment+ id, 'POST').subscribe(
  //       (res) => resolve(res),
  //       (err) => reject(err)
  //     );
  //   });
  // }

  PaymentImagepost(data: {}, Id:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data,UrlConstants.postuploadImagePayment +Id,'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}
