import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'src/app/core/services/product.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProductAddComponent } from '../product-add/product-add.component';
import { ServiceaddComponent } from '../serviceadd/serviceadd.component';
import { AddOfferService } from 'src/app/core/services/add-offer.service';
import { AddOffersComponent } from '../add-offers/add-offers.component';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  [x: string]: any;

  productListPage: boolean = true;
  serviceListPage: boolean = false;
  offerListPage: boolean = false;

  public searchKey:any;
  public searchType = 'Goods';
  public searchTypeservice = "service";
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public goodsData: any = [];
  public serviceData : any =[];
  constructor(private router: Router,
    private addofferService: AddOfferService,
    private productService: ProductService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
    private dialog: MatDialog) {

    }

  ngOnInit(): void {
  }

  isLinkActive(route: string): boolean {
    return this.router.isActive(route, true);
  }
  selectedPage(str : string) {
    if(str === 'product-list'){
      this.productListPage = true;
      this.serviceListPage = false;
      this.offerListPage = false;
    }
    if(str === 'service-list'){
      this.serviceListPage = true;
      this.productListPage = false;
      this.offerListPage = false;
    }
    if(str === 'offer-list'){
      this.offerListPage = true;
      this.serviceListPage = false;
      this.productListPage = false;
    }
  }

  getGoodsByBarcode(searchKey) {
    console.log("****",this.searchKey)
    if(this.searchKey == ""){
      this.toastService.toastMsg({
        title: "Error",
        content: "Please Enter Valid Product Name!!!",
      });
      // this.toastService.openErrorSnackBar("Please Enter Valid Product Name!!!");
      this.productService.getGoodsProductByUser_TypeSearch({}, this.currentUser.id, this.searchType,"DESC", searchKey).then((res) => {
        this.goodsData = res.data.pageData;
    })
    }
    else{
      this.productService.getGoodsProductByUser_TypeSearch({}, this.currentUser.id, this.searchType,"DESC", searchKey).then((res) => {
        this.goodsData = res.data.pageData;
        // this.toastService.openSnackBar("Product Found Successfully!!!");
        this.toastService.toastMsg({
          title: "Success",
          content: "Product Found Successfully!!!",
        });
    }, (err) => {
        if (err.error.expose) { 
          this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });

          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }

      }
    );

    }


  }

  getGoodsByBarcodeservice(searchKey) {
    console.log("****",this.searchKey)
    if(this.searchKey == ""){
      // this.toastService.openErrorSnackBar("Please Enter Valid Service Name!!!");
      this.toastService.toastMsg({
        title: "Error",
        content: "Please Enter Valid Service Name!!!",
      });
      this.productService.getGoodsProductByUser_TypeSearch({}, this.currentUser.id, this.searchTypeservice,"DESC", searchKey).then((res) => {
        this.serviceData = res.data.pageData;
    })

    }
    else{
      this.productService.getGoodsProductByUser_TypeSearch({}, this.currentUser.id, this.searchTypeservice,"DESC", searchKey).then((res) => {
        this.serviceData = res.data.pageData;
        this.toastService.toastMsg({
          title: "Success",
          content: "Service Found Successfully!!!!",
        });
        // this.toastService.openSnackBar("Service Found Successfully!!!");
    }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }

      }
    );

    }


  }



  openDialog(): void {
    const dialogRef: MatDialogRef<ProductAddComponent> = this.dialog.open(ProductAddComponent, {
      width: '550px',
      height : '730px',
      data: { },
      panelClass: 'custom-dialog-Product-class'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openDialogservice(): void {
    const dialogRef: MatDialogRef<ServiceaddComponent> = this.dialog.open(ServiceaddComponent, {
      width: '500px',
      height : '730px',
      data: { },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openDialogaddoffer(): void {
    const dialogRef: MatDialogRef<AddOffersComponent> = this.dialog.open(AddOffersComponent, {
      width: '500px',
      height : '730px',
      data: { },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }




}
