import { TitleCasePipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ScheduleAppointmentService } from 'src/app/core/services/schedule-appointment.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { ScheduleShopService } from 'src/app/core/services/schedule-shop.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-add-appointment',
  templateUrl: './add-appointment.component.html',
  styleUrls: ['./add-appointment.component.scss']
})
export class AddAppointmentComponent implements OnInit {
  public submitted: boolean = false;
  isActive: boolean;
  public ShopListResponse:any;
  // public filteredOptions: Observable<string[]>;

  public addAppointmentform: FormGroup;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  shopID: any;

  // locations = [
  //   {
  //     "id": 1,
  //     "locationName": "At Home"

  //   },
  //   {
  //     "id": 2,
  //     "locationName": "Virtual/Technician"

  //   },
  //   {
  //     "id": 2,
  //     "locationName": "Phone Call"

  //   },
  // ]
  // locations = [];
  locations: any[] = [];
  userRoles = [
    {
      "id": 1,
      "userRole": "Admin"

    },
    {
      "id": 1,
      "userRole": "User"

    }
  ]
  constructor(private formBuilder: FormBuilder,
    private scheduleAppointmentService: ScheduleAppointmentService,
    private ScheduleShopService:ScheduleShopService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
    private commonService : CommonService,
    private route: ActivatedRoute, private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddAppointmentComponent>) { }

  ngOnInit(): void {
    // this.getShopList();
    this.getmultipleShop();

    this.addAppointmentform = this.formBuilder.group({
      name: ['', Validators.required],
      duration: ['', Validators.required],
      LocationType: ["", [Validators.required]],
      UserRole: ["", Validators.required],
      Description: ["", Validators.required],
      Cancellation: [false, Validators.required],
      isDisable: [false, Validators.required],
      withinDays: ["",[Validators.required]]

    });
    this.route.params.subscribe(
      params => this.shopID = params['id']
    );
  
  }
  get f() {
    return this.addAppointmentform.controls;
  }

  onclear() {

  }

  //  getShopList(){
  //   // debugger
  //   this.ScheduleShopService.getShopList({},this.currentUser.id).then((res) => {

  //       this.locations= res.data.rows.map((data : any) => {
  //         return {
  //           tradeName: data.client.tradeName,
  //           shopId: data.id
  //         };
  //   });
  //   if(this.locations === undefined || this.locations === null || this.locations.length === 0) {
  //     this.toastService.openErrorSnackBar("No shops available. Please create a new shop before adding a new appointment.");
  //   }
   
  //   });
  //   if(this.locations === undefined || this.locations === null || this.locations.length === 0) {
  //     this.toastService.openErrorSnackBar('No shops available. Please create a new shop before adding a new appointment.')
  //   }
  // }
  getmultipleShop(){
    this.scheduleAppointmentService.getmultipleShop({},this.currentUser.id).then((res) => {
      console.log("response", res);
      this.locations = res.data.map((data : any) => {
        console.log("data", data);
        return {
          // shopId: data[0].client.shopId,
            tradeName: data.client.tradeName,
            shopId: data.id
          // tradeName: data[0].client.tradeName
        }
       
      })
      if (this.locations.length === 0) {
        this.toastService.toastMsg({
          title: "Error",
          content: "No shops Available. Please Create A New Shop Before Adding A New Appointment.",
          });
        // this.toastService.openErrorSnackBar("No shops available. Please create a new shop before adding a new appointment.");
    }
      console.log("locations", this.locations); 

    })
  }

  
  
getAppointmentType() {
   this.submitted = true;
    if (this.addAppointmentform.invalid) {
      console.log("this.addAppointmentform",this.addAppointmentform)
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
        });
      // this.toastService.openErrorSnackBar("Fill all required fields.")
      return false;
    }
    else {
    let body = {
      "name": this.addAppointmentform.controls.name.value,
      "duration": this.addAppointmentform.controls.duration.value,
      "locationType": this.addAppointmentform.controls.LocationType.value,
      "userRole": this.addAppointmentform.controls.UserRole.value,
      "description": this.addAppointmentform.controls.Description.value,
      "cancellation": this.addAppointmentform.controls.Cancellation.value ? this.addAppointmentform.controls.Cancellation.value : false,
      "isDisable": this.addAppointmentform.controls.isDisable.value ? this.addAppointmentform.controls.isDisable.value : false,
      "withinDays": this.addAppointmentform.controls.withinDays.value,
      "userId": this.currentUser.id,
      "shopId": this.addAppointmentform.controls.LocationType.value, //we are sending shopId which is getting form location list
    }

    this.scheduleAppointmentService.postAppointDetails(body).then((res:any) => {
      if (res.success) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Shop Appointment Added Successfully!!!",
          });
        // this.toastService.openSnackBar("Shop Appointment Added Successfully.");
        // this.router.navigate(["/pages/shop-setting/shopID/shop-appointment/shopID"]);
        // this.appointMentForm.reset();
        this.resetForm();
        // this.submitted = false;
        this.commonService.notifyDataAdded();
       this.dialogRef.close();
      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
          });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    });


  }
}
  resetForm() {
    this.addAppointmentform = this.formBuilder.group({
      Name: [""],
      Duration: [""],
      LocationType: [""],
      UserRole: [""],
      Description: [""],
      Cancellation: [false],
      isDisable: [false],
      withinDays: [""]
    });
  }

  cancel() {
    // Reset the form and submitted flag
    this.addAppointmentform.reset();
    this.submitted = false;
    this.dialogRef.close();

  }
 
}
