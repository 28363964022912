import { TitleCasePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RouteReuseStrategy, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfirmedValidators } from 'src/app/_helpers/confirmed.validators';
import { BankServiceService } from 'src/app/core/services/bank-service.service';
import { CashInHandServiceService } from 'src/app/core/services/cash-in-hand-service.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-add-bank',
  templateUrl: './add-bank.component.html',
  styleUrls: ['./add-bank.component.scss']
})
export class AddBankComponent implements OnInit {
  submitted: boolean;
  isEdit: boolean;
  public bankForm: FormGroup;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public hide: boolean = true;
  public filteredOptions: Observable<string[]>;


  constructor(private formBuilder: FormBuilder,
    public router: Router,
    private toastService: ToastNotificationService,
    private bankService: BankServiceService,
    private http: HttpClient,

    private titleCasePipe: TitleCasePipe,
    private cashInHandService: CashInHandServiceService,
    private saleInvoiceService: SaleInvoiceService, private dialog: MatDialog , public dialogRef: MatDialogRef<AddBankComponent>) { }

  ngOnInit(): void {
    this.bankForm = this.formBuilder.group({
      IFSC: ["", [Validators.required, Validators.pattern(/^[a-zA-Z0-9]*$/)]],
      AccountHolderName: ["", [Validators.required]],
      BankName: ["", [Validators.required]],
      AccountNumber: ["", [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(9), Validators.maxLength(19)]],
      ConfirmAccountNumber: ["", [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(9), Validators.maxLength(19)]],
      Branch: ["", [Validators.required]],
    },
      {
        validator: ConfirmedValidators('AccountNumber', 'ConfirmAccountNumber')
      });
  }
  BankDatasave() {
    this.submitted = true;
    this.isEdit = false;
    if (this.bankForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields."
      });
      // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
      return false;
    }
    else {
      let body = {
        "bankDetails": {
          "bankName": this.bankForm.controls.BankName.value,
          "branchCode": this.bankForm.controls.Branch.value,
          "accountNumber": this.bankForm.controls.AccountNumber.value,
          "ifscCode": this.bankForm.controls.IFSC.value.toUpperCase(),
          "accountHolderName":this.bankForm.controls.AccountHolderName.value

        }
      }
      if (this.bankForm.valid) {
        this.bankService.postBankDetails(body, this.currentUser.id).then((res) => {
          if (res) {
            this.toastService.toastMsg({
              title: "Success",
              content: "Bank Record Added Successfully!!!"
            });
            // this.toastService.openSnackBar("Bank Record Added Successfully!!!");
            this.router.navigate(["/pages/Bank-page"]);
            this.bankService.notifyPartyAdded();
            this.dialogRef.close();
          }
        }, (err) => {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message))
        });
      }
    }
  }
  cancel() {
     this.bankForm.reset();
     this.submitted = false;
      this.dialogRef.close();
    }
    get f() {
      return this.bankForm.controls;
    }
    numberOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }

  getIFSCCode(ifscCode) {
    if (ifscCode) {
      let body =
      {
        "bankDetails": {
          "ifscCode": this.bankForm.controls.IFSC.value
        }
      }
      this.bankService.getBankDetailsByIFSC(body, this.currentUser.id).then((res) => {
        this.bankForm.patchValue({
            'BankName': res.data.bankName,
            'Branch': this.titleCasePipe.transform(res.data.branchCode)
            })
            this.toastService.toastMsg({
              title: "Success",
              content: "IFSC Code Validated Successfully!!!"
            });
        // this.toastService.openSnackBar("IFSC Code Validated Successfully!!!");
      }, (err) => {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message))
      })
    }
  }


}
