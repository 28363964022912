import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { PartyService } from 'src/app/core/services/party.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { map, startWith } from 'rxjs/operators';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { PartGroupServiceService } from 'src/app/core/services/part-group-service.service';
import { TitleCasePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';


@Component({
  selector: 'app-add-group-multiple-party',
  templateUrl: './add-group-multiple-party.component.html',
  styleUrls: ['./add-group-multiple-party.component.scss']
})
export class AddGroupMultiplePartyComponent implements OnInit {
  public GroupId:any;

  public partyName = new FormControl();
  public partyId: any;
  public allPartyList: any = [];
  public partyData: any;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public currentPageNo: number = 0;
  public partyPagesize: number = 10;
  public filteredOptions: Observable<string[]>;
  public myControl = new FormControl();
  public selecetedParty: any = [];
  public partyDataDetails: any = [];
  public sendArray:any=[];
  submitted: boolean;
  @ViewChild('confirmDeleteTemplate') confirmDeleteTemplate: TemplateRef<any>;
  groupDescription: any;
  groupName: any;
  public PartyGroupForm: FormGroup;

  constructor(private partyService: PartyService, private saleInvoiceService: SaleInvoiceService,public route: ActivatedRoute,private deletedialog: MatDialog, private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddGroupMultiplePartyComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public deletedialogRef: MatDialogRef<any>,
    private toastService: ToastNotificationService,public router: Router, private groupService: PartGroupServiceService, private titleCasePipe: TitleCasePipe,
    public activeModal: NgbActiveModal, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.GroupId = this.data.groupId
    this.getPartyGroupForm();
    this.getAllParties();
  
   this.getGroupDataById();
   this.partyService.partyAdded$.subscribe(() => {
 
     this.getGroupDataById();
 
  });
  }
  getPartyGroupForm() {
    this.PartyGroupForm = this.formBuilder.group({
      groupDescription: [""],
      groupName: [""],
    });
  }
  get f() {
    return this.PartyGroupForm.controls;
  }


  getGroupDataById(){
    this.groupService.getPartiGroupsDetailsByGrpId({},this.GroupId).then((res)=>{
      this.partyDataDetails=res.data.parties
    
      this.groupDescription = res.data.groupDescription
      this.groupName = res.data.groupName
      
      this.PartyGroupForm.patchValue({
        groupDescription: res.data.groupDescription,
        groupName:res.data.groupName

      })
      
    },(err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong."
          });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    })
  }

  getAllParties() {
    this.partyService
      .getAllPartiesDetails({}, this.currentUser.id, this.partyPagesize, (this.currentPageNo + 1))
      .then((res) => {
        this.allPartyList = res.data.pageData;
        this.filteredOptions = this.partyName.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filterNames(name) : this.allPartyList.slice())
          );
      });
  }

  searchPartyByName(item) {
    this.saleInvoiceService.getPartyByName({}, this.currentUser.id, item)
      .then((response: any) => {
        this.partyData = response.data.rows;
        this.allPartyList = response.data.rows;
        if (this.allPartyList) {
          this.filteredOptions = this.myControl.valueChanges
            .pipe(
              startWith(''),
              map(name => name ? this.filterNames(name) : this.allPartyList.slice())
            );
        }
      });
  }
  setSelectedPartyDeatails(item) {
    this.selecetedParty = item;
    let index = this.partyDataDetails.findIndex(x => x.id === this.selecetedParty.id)
    if(index == -1){
      this.partyDataDetails.push(this.selecetedParty);
      this.partyId = item.id;
    }
    
   
  }
  private filterNames(name: string): string[] {
    return this.allPartyList.filter(partyList =>
      partyList.partyName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }


  openDeleteModal(partyId): void {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      width: '400px',
      data: { partyId : partyId, GroupId : this.GroupId },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  postPartyGroup() {
    this.submitted = true
    if (this.partyDataDetails.length < 1) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Please Add At Least One Product To Proceed!!!",
      });
      // this.toastService.openErrorSnackBar("Please Add At Least One Product To Proceed!!!");
      return false;
    }
    else{
      this.partyDataDetails.forEach(e => {
        let data = e.id;
        this.sendArray.push(e.id);
      })
      let body = {
        "groupParties": this.sendArray
      }
      this.groupService.postMultiplePartyGroup(body,this.GroupId).then((res:any) => {
        if (res.success) {
          // this.DeletePartyFromGroup()
          this.toastService.toastMsg({
            title: "Success",
            content: "Group Record Added Successfully!!!",
          });
          // this.toastService.openSnackBar("Group Record Added Successfully");
          this.dialogRef.close();
          this.router.navigate(["pages/party-page/party-group-list"]);
          this.partyService.notifyPartyAdded();
        }
      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong."
            });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })

    }

    if (this.PartyGroupForm.valid) {
      let data={
        "userId":this.currentUser.id,
        "groupName": this.PartyGroupForm.controls.groupName.value,
        "groupDescription": this.PartyGroupForm.controls.groupDescription.value,
      };
  
  
      this.groupService.UpdateGroupByGrpId(data,this.GroupId).then((res:any)=>{
        // this.toastService.openSnackBar("Group  Details Updated  Successfully");
        this.toastService.toastMsg({
          title: "Success",
          content: "Group Details Updated Successfully!!!",
        });
        this.dialogRef.close();
  
      }
      , (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong."
            });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })
    }
    else{
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
      return false;
    }
  }

  cancel() {
    // this.PartyGroupForm.reset();
    this.submitted = false;
    this.dialogRef.close();

  }
}


