<div class="container mt-2">
    <div class="card row mx-2 px-2" style="background-color: aliceblue;">
      <div class="col-12">
        <h1 style="text-align:center;">Privacy Policy</h1>
        <h1>Your Privacy Matters</h1>
        <p>Retailer is dedicated to safeguarding your privacy and providing a secure online experience through advanced technology. By using the Retailer Application, you agree to the data practices described below.</p>
        <hr />
  
        <h2>Collection of Personal Information</h2>
        <p>We collect personally identifiable information, such as your email address and contact details, to operate the Retailer application and deliver requested services.</p>
        <p>Data related to your computer hardware and software is automatically collected for operational purposes and to provide general statistical insights.</p>
        <p>Be mindful that information disclosed through the Retailer application may be accessed by third parties.</p>
        <hr />
  
        <h2>Use of Personal Information</h2>
        <p>We use your personal information to operate the Retailer application, conduct research through surveys, and occasionally contact you about potential offerings.</p>
        <p>We do not sell, rent, or lease customer lists to third parties.</p>
        <p>Data may be shared with trusted partners for statistical analysis, customer support, or deliveries.</p>
        <hr />
  
        <h2>Security Measures</h2>
        <p>We prioritize the security of your personal information and use encryption methods when transmitting data to other websites.</p>
        <hr />
  
        <h2>Policy Amendments</h2>
        <p>We may update this Privacy Policy periodically. Users are encouraged to review this statement to stay informed about how we protect your information.</p>
        <hr />
  
        <h2>Contact Information</h2>
        <p>For inquiries or concerns about this Privacy Policy, please contact us at contact@iceipts.com</p>
        <hr />

        <h2>Media Access</h2>
        <p>We require the Media Access so that the user can select the pictures or related assets type while performing various activities required in the application like profile update, feature addition, updation, etc.</p>
        <hr />

        <h2>Camera Access</h2>
        <p>We require the Camera Access so that the user can select the pictures or related assets type while performing various activities required in the application like profile update, feature addition, updation, etc.</p>
        <hr />

        <h2>Notification Access</h2>
        <p>We require the Notification access so that we can add and show the Notifications in the mobile's notification tray to inform the user about various activities going around</p>
        <hr />
  
        <h2>Internet Access</h2>
        <p>We seek internet access permissions to fetch the resources from the web apis to perform the required opertaion of the app across the web application, ensuring a seamless user experience.</p>
      </div>
    </div>
  </div>
  
