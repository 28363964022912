import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CashInHandServiceService {
  

  constructor(public httpClient:HttpService) { }
  getPartyByName(data: {}, userId:string , name:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.searchPartyByName +"?userId="+ userId + "&search=" + name ,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  cashInHandpost(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.CashInHandPost,'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  denominationPost(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.denominationPost,'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getCurrentBalance(data: {}, userId:string ): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getCurrentBalance +userId,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  // getCurrentBalance
}
