<div class="modal-header pt-0" id="Add-Party-modal">
    <h1 style="color: #EB8B3F; font-weight: bolder;margin-left:11px;" class="modal-title mb-0">Update {{isShown ? 'Customer' : 'Vendor'}}</h1>
    <div class="addProductButtons">
        <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel"
        (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-save" [disabled]="isSaveButtonDisabled" (click)="updateParty()">Save</button>
    </div>
</div>

<div class="modal-body">
    <div class="container-fluid px-2">
        <div class="toggle-container container-fluid d-flex justify-content-center" style = "align-items: flex-start;">
            <div class="lable-container container d-flex justify-content-end" style = "font-size: 14px;align-items: flex-start;" [class.bold]="!isOn">Vendor</div>
            <input class="input-container container" type="checkbox" [(ngModel)]="isOn" [checked]="isShown" id="switch"
                (click)="toggleShow($event.target.checked)" />
            <label for="switch" class="small-toggle container"></label>
            <div class="lable-container container d-flex justify-content-start"  style = "font-size: 14px;align-items: flex-start;" [class.bold]="isOn">Customer</div>
        </div>

        <form [formGroup]="PartyForm" *ngIf="isShown && !isArabic">
            <div class="row">
                <div class="col-sm-12 col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label class="form-label">Customer Name <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl required matInput placeholder="Customer Name" autocomplete="off"
                                formControlName="partyName"
                                [ngClass]="{'is-invalid':f.partyName.invalid && f.partyName.touched && submitted}" />
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.partyName.touched) &&f.partyName.errors?.required">
                            Enter a valid Customer Name.
                        </mat-error>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label class="form-label">E-Mail <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="email" matInput placeholder="E-Mail" autocomplete="off" formControlName="email"
                                [ngClass]="{'is-invalid': f.email.invalid && f.email.touched && submitted}" />
                        </mat-form-field>
                        <!-- <mat-error class="text-danger"
                            *ngIf="(submitted || f.email.touched) &&f.email.errors?.pattern &&f.email.errors?.email">
                            Enter a valid email id.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                            Enter a valid email id.
                        </mat-error> -->
                        <mat-error class="text-danger" *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                            Email is required!
                          </mat-error>
                          <mat-error class="text-danger" *ngIf="(submitted || f.email.touched) && f.email.errors?.pattern">
                            Email must be a valid email address.
                          </mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Mobile # <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="text" matInput minlength="10" maxLength="10" autocomplete="off" ngxOnlyNumbers
                                placeholder="Mobile #" formControlName="mobile"
                                [ngClass]="{ 'is-invalid': PartyForm.controls.mobile.invalid && (PartyForm.controls.mobile.touched || submitted) }">
                        </mat-form-field>

                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.pattern">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.required">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.maxlength">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.minlength">
                            Enter a valid mobile #.
                        </mat-error>
                    </div>
                </div>

                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="" style="border-radius: 10px;">
                        <label class="form-label">Group</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select formControlName="partyGrpArr" multiple [(value)]="selectedClient"
                                placeholder="Select Multiple Groups">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
                                    {{ option.groupName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>


                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label for="openingBalance" class="form-label">Opening Balance</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Enter Opening Balance" autocomplete="off"
                                (keypress)="numberOnly($event)" formControlName="openingBalance" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label for="asOfDate" class="form-label">As Of Date</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput formControlName="asOfDate" placeholder="DD MMM YYYY"
                                style="text-transform:uppercase!important;" [outsideClick]="true" bsDatepicker
                                [maxDate]="today" autocomplete="off"
                                [bsConfig]="{ dateInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true, showWeekNumbers:false }" (click)="openDatepicker()">
                            <span>
                            <mat-icon class="iconcalender">date_range</mat-icon>
                        </span>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <label class="form-label">Payment Terms</label>
                <div class="col-sm-6 col-lg-6 col-md-6  mt-3" style="padding-left:14px">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="PaymentMode" value="RECEIVE"
                                autocomplete="off" formControlName="PaymentMode">
                            <label class="form-check-label" for="toReceive" style = "font-size: 14px;">
                                To Receive
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-6 col-md-6 mt-3" style="padding-left:14px">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="PaymentMode" value="PAY" autocomplete="off"
                                formControlName="PaymentMode">
                            <label class="form-check-label" for="toPay" style = "font-size: 14px;">
                                To Pay
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <div class="mb-3">
                        <label class="form-label" for="billingAddress">Billing Address <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <textarea matNativeControl matInput id="billingAddress" formControlName="billingAddress"
                                required rows="2" placeholder="Billing Address"
                                [ngClass]="{'is-invalid': f.billingAddress.invalid && f.billingAddress.touched && submitted}"
                                autocomplete="off"></textarea>
                        </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
                                Enter a valid Billing Address.
                            </mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="country">Country</label>
                        <mat-form-field  style=" font-size: 14px" class="example-full-width" appearance="outline">
                            <mat-select id="country" formControlName="country" placeholder="Country" autocomplete="off">
                                <mat-option class="demo" *ngFor="let country of countryList" [value]="country.countryName">{{ country.countryName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="pincode">Pincode</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" id="pincode" autocomplete="off" maxlength="6" minlength="6"
                                formControlName="pincode" placeholder="Pincode" (change)="getPincodeDetails(this.PartyForm.get('pincode').value)">
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="city">City</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" id="city" autocomplete="off"
                                formControlName="city" placeholder="City" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="state">State</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" id="state" autocomplete="off"
                                formControlName="state" placeholder="State" />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <!-- credit terms -->
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Credit Period Day</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput type="number" min="0" placeholder="Enter Credit Period Day" class=""
                                formControlName="creditPeriod" autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Credit Limits</label>
                        <mat-form-field class="example-full-width" floatLabel="always" data-placeholder="my placeholder"
                            appearance="outline">
                            <div style="display: flex;flex-direction: row;align-items: center;">
                                <span style = "font-size: 14px;" matPrefix>₹&nbsp;</span>
                                <input matInput type="number" class="" placeholder="Enter Limit" title="Enter Limit"
                                    formControlName="creditLimit" min="0" autocomplete="off" />
                            </div>
                        </mat-form-field>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">No. Of Credits</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Enter Number Of Credits" type="number" min="0"
                                formControlName="noOfCredit" autocomplete="off" [ngClass]="{
                                                'is-invalid': f.noOfCredit.invalid && f.noOfCredit.touched && submitted
                                              }" />

                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="
                                    (submitted || f.noOfCredit.touched) &&
                                    f.noOfCredit.errors?.pattern
                                  ">
                            No. Of Credits required #!
                        </mat-error>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Relationship Since</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select formControlName="relationSince">
                                <mat-option *ngFor="let y of years" [value]="y">{{ y }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Distance</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Distance" type="number"  (keypress)="numberOnly($event)" formControlName="distance" />
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Transporation</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Transporation" formControlName="transporation" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- Additional Fields -->
            <div class="row">
                <div class="card-body p-0 m-2 col-12">
                    <div id="tableExample"
                        data-list='{"valueNames":["partyName","email","mobile","billingAddress","asOfDate"],"page":5,"pagination":true}'>
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped fs--1 mb-0">
                                <thead class="text-900 text-center inner-model-table-heading">
                                    <tr>
                                        <th class="p-2" style = "vertical-align: middle;">Department </th>
                                        <th class="p-2" style = "vertical-align: middle;">Contact</th>
                                        <th class="p-2" style = "vertical-align: middle;">E-Mail</th>
                                        <th class="p-2" style = "vertical-align: middle;">Delete</th>
                                    </tr>
                                </thead>
                                <tbody class="list text-center" id="table-customers-body">
                                    
                                    <ng-container formArrayName="addtionalDetails"
                                        *ngFor="let group of additionalDetailsControls.controls; let i = index">
                                        <tr [formGroupName]="i">
                                            <!-- *ngIf="group.get('isEditable').value" -->
                                            <td style = "vertical-align: middle;">
                                                <input matInput type="text" class="" formControlName="department" />
                                            </td>
                                            <td style = "vertical-align: middle;">
                                                <input matInput type="text" class="" minlength="10" maxLength="10"
                                                    ngxOnlyNumbers formControlName="additionalMobile"  />
                                            </td>
                                            <td style = "vertical-align: middle;">
                                                <input matInput type="text" class="" formControlName="additionalContact"
                                                    (keyup.enter)="addAddtionalDetails()"  />
                                            </td>
                                            <td style = "vertical-align: middle;">
                                                <a class="btn" *appHasPermission="
                      ['delete'];
                      name: 'grantpermission'
                    " (click)="deleteaddAddtionalDetails(i, group.get('additionalId').value)">
                                                    <span class="bi-trash fs-1" style="color : red;"
                                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                                        title="Delete"></span>
                                                    <!-- Delete -->
                                                </a>
                                            </td>
                                        </tr>
                                        <div *ngIf="group.get('additionalMobile').invalid && group.get('additionalMobile').touched"
                                            class="text-danger">

                                            <div *ngIf="group.get('additionalMobile').errors.minlength">Enter a valid
                                                mobile #.</div>
                                            <div *ngIf="group.get('additionalMobile').errors.maxlength">Enter a valid
                                                mobile #.</div>
                                            <div *ngIf="group.get('additionalMobile').errors.pattern">Invalid mobile
                                                format.</div>
                                        </div>
                                        <mat-error *ngIf="f.mobile.errors?.maxlength">
                                            Enter a valid mobile #.
                                        </mat-error>
                                        <mat-error *ngIf="f.mobile.errors?.minlength">
                                            Enter a valid mobile #.
                                        </mat-error>
                                        <div *ngIf="group.get('additionalContact').invalid && group.get('additionalContact').touched"
                                            class="text-danger">

                                            <div *ngIf="group.get('additionalContact').errors.email">Invalid email
                                                format.</div>
                                        </div>
                                    </ng-container>
                                </tbody>
                                <tbody *ngIf="">
                                    <tr class="text-center">
                                        <td colspan="10">
                                            <h5 class="text-nowrap">
                                                No Vendor Details Added. Please Add Vendor
                                                Details To Be Displayed Here.
                                                <span>
                                                    <a class="nav-link" routerLink="/pages/add-party">Add New
                                                        Vendor
                                                    </a>
                                                </span>
                                            </h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-between">
                <div class="col-sm-12 col-md-9 col-lg-9"></div>
                <div class="col-sm-12 col-md-3 col-lg-3 text-nowrap mb-3">
                    <!-- TODO: ADD BUTTON -->
                    <a class="btn btn-outline-warn dash fs--1" (click)="addAddtionalDetails()">
                        <span id="boot-icon" class="bi bi-plus-lg newrow" style="color: #FC7643;"></span>
                        <span class="me-2 newrow" data-bs-toggle="tooltip" style="color: #FC7643;"
                            data-bs-placement="top" title="Add Row">
                            Add New Row</span>
                    </a>
                </div>
            </div>
        </form>

        <form [formGroup]="PartyForm" *ngIf="!isShown && !isArabic">
            <div class="row">
                <div class="col-sm-12 col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label class="form-label">Vendor Name <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl required matInput placeholder="Vendor Name" autocomplete="off"
                                formControlName="partyName"
                                [ngClass]="{'is-invalid':f.partyName.invalid && f.partyName.touched && submitted}" />
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.partyName.touched) &&f.partyName.errors?.required">
                            Enter a valid Vendor Name.
                        </mat-error>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label class="form-label">E-Mail <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="email" matInput placeholder="E-Mail" formControlName="email"
                                [ngClass]="{'is-invalid': f.email.invalid && f.email.touched && submitted}"
                                autocomplete="off" />
                        </mat-form-field>
                        <!-- <mat-error class="text-danger"
                            *ngIf="(submitted || f.email.touched) &&f.email.errors?.pattern &&f.email.errors?.email">
                            Enter a valid email id.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                            Enter a valid email id.
                        </mat-error> -->
                        <mat-error class="text-danger" *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                            Email is required!
                          </mat-error>
                          <mat-error class="text-danger" *ngIf="(submitted || f.email.touched) && f.email.errors?.pattern">
                            Email must be a valid email address.
                          </mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label for="mobileNo" class="form-label">Mobile # <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="text" matInput placeholder="Mobile #" formControlName="mobile"
                                autocomplete="off" minlength="10" maxLength="10" ngxOnlyNumbers
                                [ngClass]="{'is-invalid':f.mobile.invalid && f.mobile.touched && submitted}" />
                        </mat-form-field>

                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.pattern">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.required">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.maxlength">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.minlength">
                            Enter a valid mobile #.
                        </mat-error>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="" style="border-radius: 10px;">
                        <label class="form-label">Group</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select formControlName="partyGrpArr" multiple [(value)]="selectedClient"
                                placeholder="Select Multiple Groups">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
                                    {{ option.groupName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>


                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">GSTIN<span class="error-asterisk">*</span></label>
                        <mat-form-field appearance="outline" style="min-width: calc(85% + 13px)!important;">
                            <input matNativeControl formControlName="gstIn" (keyup)="validateGSTIN($event.target.value)"
                                maxlength="15" minlength="15" autocomplete="off"
                                placeholder="GSTIN" style="text-transform: uppercase"
                                [ngClass]="{'is-invalid': f.gstIn.invalid && f.gstIn.touched}" required />
                        </mat-form-field>
                        <mat-icon *ngIf="PartyForm.controls.gstIn.valid"
                            [ngClass]="{ 'gst-status-icon': true, 'active': !isDisabledGST }">
                            {{ isDisabledGST ? '✗' : '✓' }}
                        </mat-icon>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.gstIn.touched) && (f.gstIn.errors?.required || f.gstIn.errors?.pattern || f.gstIn.errors?.minlength || f.gstIn.errors?.maxlength)">
                            Enter a valid GSTIN.
                        </mat-error>
                    </div>
                </div>

                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="example-full-width">
                        <label class="form-label">GST Type <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select matNativeControl formControlName="gstType" required disableOptionCentering
                                placeholder="GST Type" [disabled]="!isDisabledGST"
                                [ngClass]="{'is-invalid':f.gstType.invalid && f.gstType.touched && submitted}">
                                <mat-option value="REGULAR">REGULAR</mat-option>
                                <mat-option value="COMPOSITE">COMPOSITE </mat-option>
                                <mat-option value="EXEMPTED">EXEMPTED</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.gstType.touched) && f.gstType.errors?.required">
                            Enter a valid GST Type
                        </mat-error>
                    </div>
                    <!-- <div class="text-danger" *ngIf="gstTypeControl.invalid && (gstTypeControl.dirty || gstTypeControl.touched)">
                        Enter a valid GST Type.
                      </div> -->
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Legal Name</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput formControlName="legalName" [disabled]="!isDisabledGST"
                                [readonly]="!isDisabledGST" placeholder="Legal Name" />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label for="openingBalance" class="form-label">Opening Balance</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Enter Opening Balance" autocomplete="off"
                                (keypress)="numberOnly($event)" formControlName="openingBalance" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label for="asOfDate" class="form-label">As Of Date</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput formControlName="asOfDate" placeholder="DD MMM YYYY"
                                style="text-transform:uppercase!important;" [outsideClick]="true" bsDatepicker
                                [maxDate]="today"
                                [bsConfig]="{ dateInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true, showWeekNumbers:false }" (click)="openDatepicker()">
                                <span>
                                    <mat-icon class="iconcalender">date_range</mat-icon>
                                </span>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <label class="form-label">Payment Terms</label>
                <div class="col-sm-6 col-lg-6 col-md-6 mt-3" style="padding-left:14px">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="PaymentMode" value="RECEIVE"
                                formControlName="PaymentMode">
                            <label class="form-check-label" for="toReceive" style = "font-size: 14px;">
                                To Receive
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-6 col-md-6 mt-3" style="padding-left:14px">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="PaymentMode" value="PAY"
                                formControlName="PaymentMode">
                            <label class="form-check-label" for="toPay" style = "font-size: 14px;">
                                To Pay
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <div class="mb-3">
                        <label for="billingAddress" class="form-label">Billing Address <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <textarea matInput id="billingAddress" formControlName="billingAddress" required rows="2"
                                placeholder="Billing Address" [ngClass]="{
                                        'is-invalid': f.billingAddress.invalid && f.billingAddress.touched && submitted
                                    }" autocomplete="off"></textarea>
                        </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
                                Enter a valid Billing Address.
                            </mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="country">Country</label>
                        <mat-form-field style=" font-size: 14px" class="example-full-width" appearance="outline">
                            <mat-select id="country" formControlName="country" placeholder="Country" autocomplete="off">
                                <mat-option *ngFor="let country of countryList" [value]="country.countryName">{{ country.countryName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="pincode">Pincode</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" id="pincode" formControlName="pincode" maxlength="6" minlength="6"
                                autocomplete="off" placeholder="Pincode"  (change)="getPincodeDetails(this.PartyForm.get('pincode').value)" />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="city">City</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" id="city" formControlName="city"
                                autocomplete="off" placeholder="City" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="state">State</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" id="state" formControlName="state"
                                autocomplete="off" placeholder="State" />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-6">
                    <div class="mb-3">
                        <label class="form-label">Credit Period Day</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput type="number" min="0" placeholder="Enter Credit Period Days" class=""
                                formControlName="creditPeriod" autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6">
                    <div class="mb-3">
                        <label class="form-label">Credit Limits</label>
                        <mat-form-field class="example-full-width" floatLabel="always" data-placeholder="my placeholder"
                            appearance="outline">
                            <div style="display: flex;flex-direction: row;align-items: center;">
                                <span style = "font-size: 14px;" matPrefix>₹&nbsp;</span>
                                <input matInput type="number" class="" placeholder="Enter Limit" title="Enter Limit"
                                    formControlName="creditLimit" min="0" autocomplete="off" />
                            </div>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">No. Of Credits</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Enter Number Of Credits" type="number" min="0"
                                formControlName="noOfCredit" [ngClass]="{
                                                'is-invalid': f.noOfCredit.invalid && f.noOfCredit.touched && submitted
                                              }" autocomplete="off" />

                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="
                                    (submitted || f.noOfCredit.touched) &&
                                    f.noOfCredit.errors?.pattern
                                  ">
                            No. Of Credits required #!
                        </mat-error>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Relationship Since</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select formControlName="relationSince" placeholder="Relationship Since">
                                <mat-option *ngFor="let y of years" [value]="y">{{ y }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Distance</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Distance" (keypress)="numberOnly($event)" type="number" formControlName="distance"
                                autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Transporation</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Transporation" formControlName="transporation"
                                autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- Additional Fields -->
            <div class="row">
                <div class="card-body p-0 m-2 col-12">
                    <div id="tableExample"
                        data-list='{"valueNames":["partyName","email","mobile","billingAddress","asOfDate"],"page":5,"pagination":true}'>
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped fs--1 mb-0">
                                <thead class="text-900 text-center inner-model-table-heading">
                                    <tr>
                                        <th class="p-2"  style = "vertical-align: middle;">Department </th>
                                        <th class="p-2"  style = "vertical-align: middle;">Contact</th>
                                        <th class="p-2"  style = "vertical-align: middle;">E-Mail</th>
                                        <th class="p-2"  style = "vertical-align: middle;">Delete</th>
                                    </tr>
                                </thead>
                                <tbody class="list text-center" id="table-customers-body">
                                    <ng-container formArrayName="addtionalDetails" *ngFor="
                let group of additionalDetailsControls.controls;
                let i = index
              ">
                                        <tr [formGroupName]="i">

                                            <td  style = "vertical-align: middle;">
                                                <input matInput type="text" class="" formControlName="department" />
                                            </td>
                                            <td  style = "vertical-align: middle;">
                                                <input matInput type="text" class="" minlength="10" maxLength="10"
                                                    ngxOnlyNumbers formControlName="additionalMobile" />
                                            </td>
                                            <td  style = "vertical-align: middle;">
                                                <input matInput type="text" class="" formControlName="additionalContact"
                                                    (keyup.enter)="addAddtionalDetails()"/>
                                            </td>
                                            <td  style = "vertical-align: middle;">
                                                <a class="btn" *appHasPermission="
                      ['delete'];
                      name: 'grantpermission'
                    " (click)="deleteaddAddtionalDetails(i, group.get('additionalId').value)">
                                                    <span class="bi-trash fs-1" style="color : red;"
                                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                                        title="Delete"></span>
                                                    <!-- Delete -->
                                                </a>
                                            </td>
                                        </tr>
                                        <div *ngIf="group.get('additionalContact').invalid && group.get('additionalContact').touched" class="text-danger">

                                            <div *ngIf="group.get('additionalContact').errors.email">Invalid email format.</div>
                                        </div>
                                    </ng-container>
                                </tbody>
                                <tbody *ngIf="">
                                    <tr class="text-center">
                                        <td colspan="10">
                                            <h5 class="text-nowrap">
                                                No Vendor Details Added. Please Add Vendor
                                                Details To Be Displayed Here.
                                                <span>
                                                    <a class="nav-link" routerLink="/pages/add-party">Add New
                                                        Vendor
                                                    </a>
                                                </span>
                                            </h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-between">
                <div class="col-sm-12 col-md-9 col-lg-9"></div>
                <div class="col-sm-12 col-md-3 col-lg-3 text-nowrap mb-3">
                    <!-- TODO: ADD BUTTON -->
                    <a class="btn btn-outline-warn dash fs--1" (click)="addAddtionalDetails()">
                        <span id="boot-icon" class="bi bi-plus-lg newrow" style="color: #FC7643;"></span>
                        <span class="me-2 newrow" data-bs-toggle="tooltip" style="color: #FC7643;"
                            data-bs-placement="top" title="Add Row">
                            Add New Row</span>
                    </a>
                </div>
            </div>
        </form>

        <!-- For UAE -->

        <form [formGroup]="PartyForm" *ngIf="isShown && isArabic">
            <div class="row">
                <div class="col-sm-12 col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label class="form-label">Cutomer Name <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl required matInput placeholder="Customer Name" autocomplete="off"
                                formControlName="partyName"
                                [ngClass]="{'is-invalid':f.partyName.invalid && f.partyName.touched && submitted}" />
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.partyName.touched) &&f.partyName.errors?.required">
                            Enter a valid Customer Name.
                        </mat-error>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label class="form-label">E-Mail <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="email" matInput placeholder="E-Mail" autocomplete="off" formControlName="email"
                                [ngClass]="{'is-invalid': f.email.invalid && f.email.touched && submitted}" />
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.email.touched) &&f.email.errors?.pattern &&f.email.errors?.email">
                            Enter a valid email id.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                            Enter a valid email id.
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Mobile # <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="text" matInput minlength="9" maxLength="9" autocomplete="off" ngxOnlyNumbers
                                placeholder="Mobile #" formControlName="mobile"
                                [ngClass]="{ 'is-invalid': PartyForm.controls.mobile.invalid && (PartyForm.controls.mobile.touched || submitted) }">
                        </mat-form-field>

                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.pattern">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.required">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.maxlength">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.minlength">
                            Enter a valid mobile #.
                        </mat-error>
                    </div>
                </div>

                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="" style="border-radius: 10px;">
                        <label class="form-label">Group</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select formControlName="partyGrpArr" multiple [(value)]="selectedClient"
                                placeholder="Select Multiple Groups">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
                                    {{ option.groupName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>


                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label for="openingBalance" class="form-label">Opening Balance</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Enter Opening Balance" autocomplete="off"
                                (keypress)="numberOnly($event)" formControlName="openingBalance" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label for="asOfDate" class="form-label">As Of Date</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput formControlName="asOfDate" placeholder="DD MMM YYYY"
                                style="text-transform:uppercase!important;" [outsideClick]="true" bsDatepicker
                                [maxDate]="today" autocomplete="off"
                                [bsConfig]="{ dateInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true, showWeekNumbers:false }" (click)="openDatepicker()">
                            <span>
                            <mat-icon class="iconcalender">date_range</mat-icon>
                        </span>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <label class="form-label">Payment Terms</label>
                <div class="col-sm-6 col-lg-6 col-md-6 mt-3" style="padding-left:14px">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="PaymentMode" value="RECEIVE"
                                autocomplete="off" formControlName="PaymentMode">
                            <label class="form-check-label" for="toReceive" style = "font-size: 14px;">
                                To Receive
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-6 col-md-6 mt-3" style="padding-left:14px">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="PaymentMode" value="PAY" autocomplete="off"
                                formControlName="PaymentMode">
                            <label class="form-check-label" for="toPay" style = "font-size: 14px;">
                                To Pay
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <div class="mb-3">
                        <label class="form-label" for="billingAddress">Billing Address <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <textarea matNativeControl matInput id="billingAddress" formControlName="billingAddress"
                                required rows="2" placeholder="Billing Address"
                                [ngClass]="{'is-invalid': f.billingAddress.invalid && f.billingAddress.touched && submitted}"
                                autocomplete="off"></textarea>
                        </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
                                Enter a valid Billing Address.
                            </mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="country">Country</label>
                        <mat-form-field  style=" font-size: 14px" class="example-full-width" appearance="outline">
                            <mat-select id="country" formControlName="country" placeholder="Country" autocomplete="off">
                                <mat-option class="demo" *ngFor="let country of countryList" [value]="country.countryName">{{ country.countryName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="pincode">Pincode</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" id="pincode" autocomplete="off"
                                formControlName="pincode" placeholder="Pincode">
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="city">City</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" id="city" autocomplete="off"
                                formControlName="city" placeholder="City" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="state">State</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" id="state" autocomplete="off"
                                formControlName="state" placeholder="State" />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <!-- credit terms -->
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Credit Period Day</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput type="number" min="0" placeholder="Enter Credit Period Day" class=""
                                formControlName="creditPeriod" autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Credit Limits</label>
                        <mat-form-field class="example-full-width" floatLabel="always" data-placeholder="my placeholder"
                            appearance="outline">
                            <div style="display: flex;flex-direction: row;align-items: center;">
                                <span class="pe-2" style="font-size: 12px;" matPrefix>AED </span>
                                <input matInput type="number" class="" placeholder="Enter Limit" title="Enter Limit"
                                    formControlName="creditLimit" min="0" autocomplete="off" />
                            </div>
                        </mat-form-field>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">No. Of Credits </label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Enter Number Of Credits" type="number" min="0"
                                formControlName="noOfCredit" autocomplete="off" [ngClass]="{
                                                'is-invalid': f.noOfCredit.invalid && f.noOfCredit.touched && submitted
                                              }" />

                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="
                                    (submitted || f.noOfCredit.touched) &&
                                    f.noOfCredit.errors?.pattern
                                  ">
                            No. Of Credits required #!
                        </mat-error>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Relationship Since</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select formControlName="relationSince">
                                <mat-option *ngFor="let y of years" [value]="y">{{ y }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Distance</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Distance" (keypress)="numberOnly($event)" type="number" formControlName="distance" />
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Transporation</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Transporation" formControlName="transporation" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- Additional Fields -->
            <div class="row">
                <div class="card-body p-0 m-2 col-12">
                    <div id="tableExample"
                        data-list='{"valueNames":["partyName","email","mobile","billingAddress","asOfDate"],"page":5,"pagination":true}'>
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped fs--1 mb-0">
                                <thead class="text-900 text-center inner-model-table-heading">
                                    <tr>
                                        <th class="p-2"  style = "vertical-align: middle;">Department </th>
                                        <th class="p-2"  style = "vertical-align: middle;">Contact</th>
                                        <th class="p-2"  style = "vertical-align: middle;">E-Mail</th>
                                        <th class="p-2"  style = "vertical-align: middle;">Delete</th>
                                    </tr>
                                </thead>
                                <tbody class="list text-center" id="table-customers-body">
                                    <ng-container formArrayName="addtionalDetails" *ngFor="
                let group of additionalDetailsControls.controls;
                let i = index
              ">
                                        <tr [formGroupName]="i">
                                            <!-- *ngIf="group.get('isEditable').value" -->
                                            <td  style = "vertical-align: middle;">
                                                <input matInput type="text" class="" formControlName="department" />
                                            </td>
                                            <td  style = "vertical-align: middle;">
                                                <input matInput type="text" class="" minlength="9" maxLength="9"
                                                    ngxOnlyNumbers formControlName="additionalMobile" />
                                            </td>
                                            <td  style = "vertical-align: middle;">
                                                <input matInput type="text" class="" formControlName="additionalContact"
                                                    (keyup.enter)="addAddtionalDetails()" />
                                            </td>
                                            <td  style = "vertical-align: middle;">
                                                <a class="btn" *appHasPermission="
                      ['delete'];
                      name: 'grantpermission'
                    " (click)="deleteaddAddtionalDetails(i, group.get('additionalId').value)">
                                                    <span class="bi-trash fs-1" style="color : red;"
                                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                                        title="Delete"></span>
                                                    <!-- Delete -->
                                                </a>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                                <tbody *ngIf="">
                                    <tr class="text-center">
                                        <td colspan="10">
                                            <h5 class="text-nowrap">
                                                No Vendor Details Added. Please Add Vendor
                                                Details To Be Displayed Here.
                                                <span>
                                                    <a class="nav-link" routerLink="/pages/add-party">Add New
                                                        Vendor
                                                    </a>
                                                </span>
                                            </h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-between">
                <div class="col-sm-12 col-md-9 col-lg-9"></div>
                <div class="col-sm-12 col-md-3 col-lg-3 text-nowrap mb-3">
                    <!-- TODO: ADD BUTTON -->
                    <a class="btn btn-outline-warn dash fs--1" (click)="addAddtionalDetails()">
                        <span id="boot-icon" class="bi bi-plus-lg newrow" style="color: #FC7643;"></span>
                        <span class="me-2 newrow" data-bs-toggle="tooltip" style="color: #FC7643;"
                            data-bs-placement="top" title="Add Row">
                            Add New Row</span>
                    </a>
                </div>
            </div>
        </form>

        <form [formGroup]="PartyForm" *ngIf="!isShown && isArabic">
            <div class="row">
                <div class="col-sm-12 col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label class="form-label">Vendor Name <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl required matInput placeholder="Vendor Name" autocomplete="off"
                                formControlName="partyName"
                                [ngClass]="{'is-invalid':f.partyName.invalid && f.partyName.touched && submitted}" />
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.partyName.touched) &&f.partyName.errors?.required">
                            Enter a valid Vendor Name.
                        </mat-error>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-12 col-md-12">
                    <div class="mb-3">
                        <label class="form-label">E-Mail <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="email" matInput placeholder="E-Mail" formControlName="email"
                                [ngClass]="{'is-invalid': f.email.invalid && f.email.touched && submitted}"
                                autocomplete="off" />
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.email.touched) &&f.email.errors?.pattern &&f.email.errors?.email">
                            Enter a valid email id.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                            Enter a valid email id.
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label for="mobileNo" class="form-label">Mobile # <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="text" matInput placeholder="Mobile #" formControlName="mobile"
                                autocomplete="off" minlength="9" maxLength="9" ngxOnlyNumbers
                                [ngClass]="{'is-invalid':f.mobile.invalid && f.mobile.touched && submitted}" />
                        </mat-form-field>

                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.pattern">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.required">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.maxlength">
                            Enter a valid mobile #.
                        </mat-error>
                        <mat-error *ngIf="f.mobile.errors?.minlength">
                            Enter a valid mobile #.
                        </mat-error>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="" style="border-radius: 10px;">
                        <label class="form-label">Group</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select formControlName="partyGrpArr" multiple [(value)]="selectedClient"
                                placeholder="Select Multiple Groups">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
                                    {{ option.groupName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>


                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label w-100">{{ lang.GSTIN }} <span class="error-asterisk">*</span></label>
                        <mat-form-field appearance="outline" style="min-width: calc(85% + 13px)!important;">
                            <input matNativeControl formControlName="gstIn" maxlength="15" minlength="15" autocomplete="off"
                            (keypress)="numberOnly($event)" maxlength="15" (keyup)="validateTRN($event.target.value)"
                                placeholder="{{ lang.GSTIN }}" style="text-transform: uppercase"
                                [ngClass]="{'is-invalid': f.gstIn.invalid && f.gstIn.touched && submitted}" required />
                        </mat-form-field>
                        <mat-icon *ngIf="PartyForm.controls.gstIn.valid"
                            [ngClass]="{ 'gst-status-icon': true, 'active': !isDisabledGST }">
                            {{ isDisabledGST ? '✗' : '✓' }}
                        </mat-icon>
                        <!-- <mat-error class="text-danger"
                            *ngIf="(submitted || f.gstIn.touched) && (f.gstIn.errors?.required || f.gstIn.errors?.minlength || f.gstIn.errors?.maxlength)">
                            Enter a valid {{lang.GSTIN }} with exactly 15 characters.
                        </mat-error> -->
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.gstIn.touched) && f.gstIn.errors?.required">
                            {{lang.GSTIN }} required with exactly 15 characters.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="f.gstIn.errors?.required && f.gstIn.errors?.minlength">
                            Enter a valid {{lang.GSTIN }} with exactly 15 characters.
                        </mat-error>
                    </div>
                </div>
<!--
                <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="example-full-width">
                        <label class="form-label">GST Type</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select matNativeControl formControlName="gstType" required disableOptionCentering
                                placeholder="GST Type" [disabled]="!isDisabledGST"
                                [ngClass]="{'is-invalid':f.gstType.invalid && f.gstType.touched && submitted}">
                                <mat-option value="REGULAR">REGULAR</mat-option>
                                <mat-option value="COMPOSITE">COMPOSITE </mat-option>
                                <mat-option value="EXEMPTED">EXEMPTED</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.gstType.touched) && f.gstType.errors?.required">
                            Enter a valid GST Type
                        </mat-error>
                    </div>
                    <div class="text-danger" *ngIf="gstTypeControl.invalid && (gstTypeControl.dirty || gstTypeControl.touched)">
                        Enter a valid GST Type.
                      </div> -->
                <!-- </div> -->
            </div>

            <div class="row">
                <!-- <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Legal Name</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput formControlName="legalName" [disabled]="!isDisabledGST"
                                [readonly]="!isDisabledGST" placeholder="Legal Name" />
                        </mat-form-field>
                    </div>
                </div> -->
            </div>

            <div class="row">
                <div class="col-sm-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label for="openingBalance" class="form-label">Opening Balance</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Enter Opening Balance" autocomplete="off"
                                (keypress)="numberOnly($event)" formControlName="openingBalance" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-6 col-md-6">
                    <div class="mb-3">
                        <label for="asOfDate" class="form-label">As Of Date</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput formControlName="asOfDate" placeholder="DD MMM YYYY"
                                style="text-transform:uppercase!important;" [outsideClick]="true" bsDatepicker
                                [maxDate]="today"
                                [bsConfig]="{ dateInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true, showWeekNumbers:false }" (click)="openDatepicker()">
                                <span>
                                    <mat-icon class="iconcalender">date_range</mat-icon>
                                </span>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <label class="form-label">Payment Terms</label>
                <div class="col-sm-6 col-lg-6 col-md-6 mt-3" style="padding-left:14px">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="PaymentMode" value="RECEIVE"
                                formControlName="PaymentMode">
                            <label class="form-check-label" for="toReceive" style = "font-size: 14px;">
                                To Receive
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-6 col-md-6 mt-3" style="padding-left:14px">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="PaymentMode" value="PAY"
                                formControlName="PaymentMode">
                            <label class="form-check-label" for="toPay" style = "font-size: 14px;">
                                To Pay
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <div class="mb-3">
                        <label for="billingAddress" class="form-label">Billing Address <span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <textarea matInput id="billingAddress" formControlName="billingAddress" required rows="2"
                                placeholder="Billing Address" [ngClass]="{
                                        'is-invalid': f.billingAddress.invalid && f.billingAddress.touched && submitted
                                    }" autocomplete="off"></textarea>
                        </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
                                Enter a valid Billing Address.
                            </mat-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="country">Country</label>
                        <mat-form-field style=" font-size: 14px" class="example-full-width" appearance="outline">
                            <mat-select id="country" formControlName="country" placeholder="Country" autocomplete="off">
                                <mat-option *ngFor="let country of countryList" [value]="country.countryName">{{ country.countryName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="pincode">Pincode</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" id="pincode" formControlName="pincode"
                                autocomplete="off" placeholder="Pincode"/>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="city">City</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" id="city" formControlName="city"
                                autocomplete="off" placeholder="City" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label" for="state">State</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" id="state" formControlName="state"
                                autocomplete="off" placeholder="State" />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-6">
                    <div class="mb-3">
                        <label class="form-label">Credit Period Day</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput type="number" min="0" placeholder="Enter Credit Period Days" class=""
                                formControlName="creditPeriod" autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6">
                    <div class="mb-3">
                        <label class="form-label">Credit Limits</label>
                        <mat-form-field class="example-full-width" floatLabel="always" data-placeholder="my placeholder"
                            appearance="outline">
                            <div style="display: flex;flex-direction: row;align-items: center;">
                                <span class="pe-2" style="font-size: 12px;" matPrefix>AED</span>
                                <input matInput type="number" class="" placeholder="Enter Limit" title="Enter Limit"
                                    formControlName="creditLimit" min="0" autocomplete="off" />
                            </div>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">No. Of Credits</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Enter Number Of Credits" type="number" min="0"
                                formControlName="noOfCredit" [ngClass]="{
                                                'is-invalid': f.noOfCredit.invalid && f.noOfCredit.touched && submitted
                                              }" autocomplete="off" />

                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="
                                    (submitted || f.noOfCredit.touched) &&
                                    f.noOfCredit.errors?.pattern
                                  ">
                            No. Of Credits required #!
                        </mat-error>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Relationship Since</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select formControlName="relationSince" placeholder="Relationship Since">
                                <mat-option *ngFor="let y of years" [value]="y">{{ y }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Distance</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Distance" type="number" (keypress)="numberOnly($event)" formControlName="distance"
                                autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                    <div class="mb-3">
                        <label class="form-label">Transporation</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matInput placeholder="Transporation" formControlName="transporation"
                                autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- Additional Fields -->
            <div class="row">
                <div class="card-body p-0 m-2 col-12">
                    <div id="tableExample"
                        data-list='{"valueNames":["partyName","email","mobile","billingAddress","asOfDate"],"page":5,"pagination":true}'>
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped fs--1 mb-0">
                                <thead class="text-900 text-center inner-model-table-heading">
                                    <tr>
                                        <th class="p-2"  style = "vertical-align: middle;">Department </th>
                                        <th class="p-2"  style = "vertical-align: middle;">Contact</th>
                                        <th class="p-2"  style = "vertical-align: middle;">E-Mail</th>
                                        <th class="p-2"  style = "vertical-align: middle;">Delete</th>
                                    </tr>
                                </thead>
                                <tbody class="list text-center" id="table-customers-body">
                                    <ng-container formArrayName="addtionalDetails" *ngFor="
                let group of additionalDetailsControls.controls;
                let i = index
              ">
                                        <tr [formGroupName]="i">

                                            <td  style = "vertical-align: middle;">
                                                <input matInput type="text" class="" formControlName="department" />
                                            </td>
                                            <td  style = "vertical-align: middle;">
                                                <input matInput type="text" class="" minlength="9" maxLength="9"
                                                    ngxOnlyNumbers formControlName="additionalMobile" />
                                            </td>
                                            <td  style = "vertical-align: middle;">
                                                <input matInput type="text" class="" formControlName="additionalContact"
                                                    (keyup.enter)="addAddtionalDetails()"/>
                                            </td>
                                            <td  style = "vertical-align: middle;">
                                                <a class="btn" *appHasPermission="
                      ['delete'];
                      name: 'grantpermission'
                    " (click)="deleteaddAddtionalDetails(i, group.get('additionalId').value)">
                                                    <span class="bi-trash fs-1" style="color : red;"
                                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                                        title="Delete"></span>
                                                    <!-- Delete -->
                                                </a>
                                            </td>
                                        </tr>
                                        <div *ngIf="group.get('additionalContact').invalid && group.get('additionalContact').touched" class="text-danger">

                                            <div *ngIf="group.get('additionalContact').errors.email">Invalid email format.</div>
                                        </div>
                                    </ng-container>
                                </tbody>
                                <tbody *ngIf="">
                                    <tr class="text-center">
                                        <td colspan="10">
                                            <h5 class="text-nowrap">
                                                No Vendor Details Added. Please Add Vendor
                                                Details To Be Displayed Here.
                                                <span>
                                                    <a class="nav-link" routerLink="/pages/add-party">Add New
                                                        Vendor
                                                    </a>
                                                </span>
                                            </h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-between">
                <div class="col-sm-12 col-md-9 col-lg-9"></div>
                <div class="col-sm-12 col-md-3 col-lg-3 text-nowrap mb-3">
                   <!-- TODO: ADD BUTTON -->
                    <a class="btn btn-outline-warn dash fs--1" (click)="addAddtionalDetails()">
                        <span id="boot-icon" class="bi bi-plus-lg newrow" style="color: #FC7643;"></span>
                        <span class="me-2 newrow" data-bs-toggle="tooltip" style="color: #FC7643;"
                            data-bs-placement="top" title="Add Row">
                            Add New Row</span>
                    </a>
                </div>
            </div>
        </form>

    </div>
</div>
