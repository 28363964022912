import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { CrmService } from 'src/app/core/services/crm.service';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-add-lead',
  templateUrl: './add-lead.component.html',
  styleUrls: ['./add-lead.component.scss']
})
export class AddLeadComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public LeadForm: any;
  public submitted: boolean = false;
  public profilePic: any = '';
  public dragging: boolean = false;
  public imageLoaded: boolean = false;
  public loaded: boolean = false;
  public profilePicUrl: any = '';
  isProfilePicSelects: boolean = false;
  public activeColor: string = 'green';
  public baseColor: string = '#ccc';

  public type;
  pinDetails: any;

  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddLeadComponent>,
    private toastService: ToastNotificationService,
    public crmservice:CrmService,
    private titleCasePipe: TitleCasePipe,
    private router: Router,
    public rolesPermissionsService: RolesPermissionsService) { }

  ngOnInit(): void {

    this.LeadForm = this.formBuilder.group({
      firstName: ["", Validators.required],
      email: [null, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"), Validators.email]],
      mobile: ['', [Validators.required,Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      address: ['', Validators.required],
      pincode: [null, Validators.required],
      city: [null, Validators.required],
      state: [null, Validators.required],
      country: ['India', Validators.required],
      product: ["P1"],
      status: ['', Validators.required],
      contact: ['', Validators.required],
      type: ['', Validators.required],
      hours: ['', Validators.required],


    });

    this.LeadForm.get('pincode').valueChanges.pipe(debounceTime(500)).subscribe((pincode) => {
      this.getPincodeDetails(pincode);
    });
  }

  clearInput() {
    this.dialogRef.close();
  }

  get f() {
    return this.LeadForm.controls;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  getPincodeDetails(pin) {
    this.rolesPermissionsService.getEmployeePincode({}, pin).then((res) => {
      this.pinDetails = res;
      console.log("this.pinDetails",this.pinDetails)
      this.LeadForm.patchValue({
        state: res.data.stateName,
        city: res.data.districtName
      });
    });
  }

  onSaveLead() {
    this.submitted = true;
    if (this.LeadForm.invalid) {
      console.log("this.LeadForm",this.LeadForm)
      // this.toastService.openErrorSnackBar("Fill all required fields.")
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    }
    else {

      let data =
      {
        "userId": this.currentUser.id,
        "name": this.LeadForm.value.firstName,
        "email": this.LeadForm.value.email.toLowerCase(),
        "mobile": this.LeadForm.value.mobile,
        //"website": "examplewebsite.com",
        //"fiscalInformation": "ABC123XYZ",
        "projects": this.LeadForm.value.product,
        "contactType": this.LeadForm.value.contact,
        "status": this.LeadForm.value.status,
        "image": this.profilePicUrl,
        "address": this.LeadForm.value.address,
        "country": this.LeadForm.value.country,
        "pincode": this.LeadForm.value.pincode,
        "city": this.LeadForm.value.city,
        "state": this.LeadForm.value.state,
        "type": this.LeadForm.value.type,
        "hours": this.LeadForm.value.hours
      }

      this.crmservice.postLead(data,this.currentUser.id).then((res) => {
        if (res) {
          this.toastService.toastMsg({
            title: "Success",
            content: "Lead Added Successfully!!!"
          });
          // this.toastService.openSnackBar("Lead added Successfully");
          this.router.navigate(["/pages/crm/lead-list"]);
          this.crmservice.notifyLeadAdded();
          this.dialogRef.close();
        }

       }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
            // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went To Wrong",
            });
            // this.toastService.openErrorSnackBar("Something Went To Wrong");
          }
        })
    }

}
  getOfferType(event) {
    this.type = event.source.value;
    console.log(this.type)
  }

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  async handleInputChange(e) {
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file.type.match(pattern)) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Invalid format.Please Select An Image File.",
      });
      // this.toastService.openErrorSnackBar("Invalid format. Please select an image file.");
      return;
    }
    const fileSizeLimit = 1 * 1024 * 1024; // 6 MB
    if (file.size > fileSizeLimit) {
      alert("File size exceeds the limit. Please select a smaller image (up to 1 MB).");
      return;
    }
    this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.profilePic = reader.result;
      };
      if (file == null) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Select Image To Upload.",
        });
        // this.toastService.openErrorSnackBar("Please Select Image To Upload.");
      } else {
        formdata.set("upload", file);
        e.srcElement.value = "";
        this.rolesPermissionsService.uploadImage(formdata).then((res) => {
          console.log("image Upload",res);
          this.profilePicUrl = res.data.location
          this.isProfilePicSelects = true
          this.toastService.toastMsg({
            title: "Success",
            content: "Image Uploaded Successfully!!!"
          });
            // this.toastService.openSnackBar("Image Uploaded Successfully");
          });
        this.loaded = true;
      }
    }
  }
  deleteProfilePic() {

    // this.profileService.deleteProfileImg({}, this.employeeId)
    // .then((res) => {
    //   console.log(res)
    //   this.toastService.openSnackBar("Image deleted successfully");
    //   this.profilePic = '';
    //   this.profilePicUrl = null; // Clear the profilePic value
    //   this.isProfilePicSelects = false
    //   this.loaded = true;
    // })
    // .catch((error) => {
    //   console.log(error);
    // });
  }

}
