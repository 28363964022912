import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProfileService } from 'src/app/core/services/profile.service';

@Component({
  selector: 'app-add-role-based-users',
  templateUrl: './add-role-based-users.component.html',
  styleUrls: ['./add-role-based-users.component.scss']
})
export class AddRoleBasedUsersComponent implements OnInit {
  today: Date;
  maxDate: Date;
  public roleData: any;
  public usersForm: FormGroup;
  public roleName: any;
  public roleId: any;
  public submitted: boolean;

  public alreadyExists: boolean = false;
  public showAlert: boolean = false;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public dragging: boolean = false;
  public loaded: boolean = false;
  public imageLoaded: boolean = false;
  public activeColor: string = 'green';
  public baseColor: string = '#ccc';
  public profilePic: any = '';
  public profilePicUrl: any = '';
  pinDetails: any;
  assets: any[] = [
    { id: 1, name: 'Laptop', selected: false },
    { id: 2, name: 'Mobile', selected: false },
    { id: 3, name: 'headphones', selected: false },
  ];

  constructor(public rolePermissionservice: RolesPermissionsService,
    public formBuilder: FormBuilder, public profileService : ProfileService,
    private route: ActivatedRoute,
    private titleCasePipe: TitleCasePipe,public activeModal: NgbActiveModal, @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddRoleBasedUsersComponent>,
    private router: Router,
    private toastService: ToastNotificationService,
  ) { }

  ngOnInit(): void {
    this.today=new Date();
    //call roles list
    // form for role based users
    this.usersForm = this.formBuilder.group({
      firstName: ["", [Validators.required, this.alphabeticOnlyValidator()]],
      lastName: [""],
      email: ["", [Validators.required,Validators.email,Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")]],

      // email: ["", [Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"),Validators.email]],
      // email: [null, [Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"), Validators.email, Validators.required]],
      address: [null,],
      roleAssign: ["", [Validators.required, this.alphabeticOnlyValidator()]],
      designation: ["", [Validators.required, this.alphabeticOnlyValidator()]],
      parentId: [this.currentUser.id],
      joiningDate :["", Validators.required],
      resignationDate :[null],
      salary :["", Validators.required],
      department :[null,[ this.alphabeticOnlyValidator()]],
      asset: [''],
      gender:['',[Validators.required, this.alphabeticOnlyValidator()]],
      // salary :["", Validators.required, Validators.min(0)],
      //pf :["", Validators.required],
      // pf :["", Validators.required, Validators.min(0)],
      // mobile: ["", [Validators.required]],
        mobile: [null, [ Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        homeno: [null],
        pincode: [null],
        city: [null],
        district: [null],
        landmark: [null],
        state: [null],
        country: ["India"],
    });

    this.usersForm.get('pincode').valueChanges
    .pipe(debounceTime(500)) // Add a debounce time to avoid making API calls on every keystroke
    .subscribe((pincode) => {
      this.getPincodeDetails(pincode);
    });
  }

  alphabeticOnlyValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const alphabeticOnlyRegex = /^[a-zA-Z\s]+$/;
      const value = control.value;
      if (value && !alphabeticOnlyRegex.test(value)) {
        return { alphabeticOnly: true };
      }
      return null;
    };
  }

  get f() {
    return this.usersForm.controls;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  //add role based user
  addUsersWithRoles() {
    this.submitted= true;
    console.log(this.usersForm)
    if(this.usersForm.invalid){
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
      return false;
    }else {
    let usersData = {
      basicdetails: {
        firstName: this.usersForm.value.firstName,
        lastName: "lastname",
        email: this.usersForm.value.email.toLowerCase(),
        mobile: this.usersForm.value.mobile ? this.usersForm.value.mobile:null,
        parentId: this.currentUser.id,
        profileImage: this.profilePicUrl ? this.profilePicUrl : null,
      },
      additional:{
        roleAssign: this.usersForm.value.roleAssign,
        designation: this.usersForm.value.designation,
        joiningDate:this.usersForm.value.joiningDate,
        resignationDate: this.usersForm.value.resignationDate,
        salary: this.usersForm.value.salary,
        assets: this.usersForm.value.asset[0],
        department:this.usersForm.value.department,
        gender:this.usersForm.value.gender
      },
      address: {
        isPrimary: true,
        homeno: this.usersForm.value?.homeno,
        pincode: this.usersForm.value?.pincode,
        address: this.usersForm.value?.address,
        city: this.usersForm.value?.city,
        district: this.usersForm.value?.district,
        landmark: this.usersForm.value?.landmark,
        state: this.usersForm.value?.state,
        country: this.usersForm.value?.country
      }
    }
    console.log(usersData)


    this.rolePermissionservice.createRoleUsers(usersData).then((res) => {
      if(res.success){
        this.showAlert = true;
        this.toastService.toastMsg({
          title: "Success",
          content: "Employee Added Successfully!!!"
        });
        // this.toastService.openSnackBar("Employee Added Successfully!!!")
        this.dialogRef.close();
        this.router.navigate(['/pages/employee-page/allEmployees'])
        this.rolePermissionservice.notifyUserAdded();
      }
    },
    (err) => {
      // this.sendArray = [];
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }

    })
  }
    // (err) => {
    //   if (err.error.status == 409) {
    //     this.toastService.openErrorSnackBar("Employee Already Exists!!! Try with another E-mail")
    //   }
    // })
  }

  getPincodeDetails(pin) {
    this.rolePermissionservice.getEmployeePincode({}, pin).then((res) => {
      this.pinDetails = res;
      this.usersForm.patchValue({
        state: res.data.stateName,
        city: res.data.districtName
      });
    });
  }

  cancel() {
    // Reset the form and submitted flag
    this.usersForm.reset();
    this.submitted = false;
    this.dialogRef.close();

  }

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  async handleInputChange(e) {
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file.type.match(pattern)) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Invalid Format. Please Select An Image File.",
      });
      // this.toastService.openErrorSnackBar("Invalid format. Please select an image file.");
      return;
    }
    const fileSizeLimit = 1 * 1024 * 1024; // 6 MB
    if (file.size > fileSizeLimit) {
      alert("File size exceeds the limit. Please select a smaller image (up to 1 MB).");
      return;
    }
    this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.profilePic = reader.result;
      };
      if (file == null) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Select Image To Upload.",
        });
        // this.toastService.openErrorSnackBar("Please Select Image To Upload.");
      } else {
        formdata.set("upload", file);
        e.srcElement.value = "";
        this.rolePermissionservice
        .uploadImage(formdata)
        .then((res) => {
          this.profilePicUrl = res.data.location
          this.toastService.toastMsg({
            title: "Success",
            content: "Image Uploaded Successfully!!!",
          });          
        });
        this.loaded = true;
      }
    }
  }

  deleteProfilePic() {
    // Clear the profilePic value
    this.profilePic = '';
    let formdata = new FormData();
    formdata.set("upload", null); // Sending an empty file or null to indicate deletion
    this.profileService.deleteProfileImg(formdata, this.currentUser.id)
    .then((res) => {
      console.log(res)
      this.toastService.toastMsg({
        title: "Success",
        content: "Image Deleted successfully!!!",
      });
      // this.toastService.openSnackBar("Image deleted successfully");
      // this.getProfileData();
      this.profilePicUrl = null; // Clear the profilePic value
      this.loaded = true;
    })
    .catch((error) => {
      console.log(error);
    });
  }
  openDatepickerJoinDate(){
      // Check if the current value of taskdate is an "Invalid Date"
      const selectedDate = this.usersForm.get('joiningDate').value;
      if (isNaN(selectedDate.getTime())) {
        // Set a default date or any valid date here
        this.usersForm.get('joiningDate').setValue(new Date());
      }

  }
  openDatepickeresignDate(){
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.usersForm.get('resignationDate').value;
    if (isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.usersForm.get('resignationDate').setValue(new Date());
    }

}


}
