<div>
    <app-header></app-header>
    <app-slider></app-slider>
    <app-section></app-section>
    <app-gallery></app-gallery>
    <!-- <app-feature-product></app-feature-product>
    <app-image></app-image> -->
    <app-testimonial></app-testimonial>
    <app-footer></app-footer>
</div>
