import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BiddingService } from 'src/app/core/services/bidding.service';
import { PayumoneyPaymentService } from 'src/app/core/services/payumoney-payment.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';
import { environment } from 'src/environments/environment';
import { getAllJSDocTags } from 'typescript';
import * as uuid from 'uuid';

@Component({
  selector: 'app-place-order',
  templateUrl: './place-order.component.html',
  styleUrls: ['./place-order.component.scss']
})
export class PlaceOrderComponent implements OnInit {
  submitObj:any;
  public url = Constants.url; 
  public payUurl = Constants.url;
  public showForm:boolean = true;

  public basicPlanIdTwo: any;
  public basicPlanIdThree: any;
  //public submitObj: any;
  public basicPlanIdFour: any;
  public premiumPlanIdFive: any;
  public premiumPlanIdSix: any;
  public premiumPlanIdSeven: any;
  public currentUser: any;
  //public url = Constants.url; //"https://secure.payu.in/_payment"//
  public basicId: any;
  public planArray: any;
  public description: any;
  public planName: any;
  public validity: any;
  public features: any;
  public totalAmount: any;
  public subscriptionPaymentId: number;
  public userSubscriptionId: number;
  public data: any;
  public BidId:any
  public bidArray:any;
  public bidArrayId:any;
  public bidProduct:any;
  public bidProductprice:any;
  public BidLocation:any;
  public bidExpectedDate:any;
  public whitsStatus:any;
  public productDescription:any;
  public BidPincode:any;
  public BiDetailsAddressArea:any
  public   BiDetailsAddressBuilding:any
  public BiDetailsAddressCity:any;
  public BiDetailsAddressLandmark:any;
  public BiDetailsAddressPincode:any;
  public bidArrayPrice:any;
  constructor(public paymentService: PayumoneyPaymentService, 
    public subscriptionService: SubscriptionService,
    public route: ActivatedRoute,
    public router: Router,
    private toastService: ToastNotificationService,
    private bidservice:BiddingService) { }

  ngOnInit(): void {
    this.route.params.subscribe(
      params => {
        this.BidId = params['id']
    });
    if(this.BidId){
      this.getBidDetails();
    }
    this.getAllPayments();
 
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

  }
  setHash() {
    this.subscriptionService.getPaymentHash(this.submitObj, "").then((res) => {
      this.submitObj.hash = res
      console.log("this.submitObj.hash",this.submitObj.hash)
    });
  }
 
 getBidDetails(){
    this.bidservice.getWishlistById({},this.BidId).then((res)=>{
      this.bidArray= res.data.biddings;
     this.bidArrayId= res.data.biddings.id;
     this.bidArrayPrice= res.data.biddings[0].price;
      this.bidProduct= res.data.product.itemName;
      this.bidProductprice= res.data.product.price;
       this.BidLocation=res.data.location;
       this.BidPincode=res.data.pincode;
       this.bidExpectedDate=res.data.approxCompletedDate;
       this.whitsStatus=res.data.wishStatus;
       this.productDescription= res.data.description;
       this.BiDetailsAddressArea=res.data.acceptedbid.area;
       this.BiDetailsAddressBuilding=res.data.acceptedbid.building;
       this.BiDetailsAddressCity=res.data.acceptedbid.city;
       this.BiDetailsAddressLandmark=res.data.acceptedbid.landmark;
       this.BiDetailsAddressPincode=res.data.acceptedbid.pincode;
       console.log("this.bidArrayPrice",this.bidArrayPrice,res.data.biddings[0].price);
      // //  if(res){
      //    this.setPaymentFormData(this.bidArrayPrice.toString());
      // //  }
    })
   // this.setPaymentFormData(this.bidArrayPrice.toString());
   
  
  }
  getAllPayments(){
    this.setPaymentFormData();
  }
 
  async setPaymentFormData(totalAmount?: any) {
    let obj = {
      key: "oZ7oo9",
      txnId: uuid.v4(),
      productinfo:"Payment",
      //amount:totalAmount,
     amount: 1200.00,
      surl: environment.webUrl + "#/pages/customer/remote-customer",
      furl: environment.webUrl + "#/pages/customer/remote-customer",
      // surl: environment.apiUrl + "subscription/pay",
      // furl: environment.apiUrl + "subscription/pay",
      phone: "1234567890",
      hash: "",
      udf1: "",
      udf2: "",
      udf3: "",
      udf4: "",
      udf5: "",
      email:"abcd@gmail.com",
      firstName:Constants.currentUser.firstName,
      lastName:Constants.currentUser.lastName
    }
    this.submitObj = obj;
    console.log("submit Obj", this.submitObj)
    await this.setHash();
  }
  
 
}
