<div class="container-fluid mb-3 mx-0 bg-white">
  <div class="row pt-3 pb-3 mt-" id="headingPrintHide">
    <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:600;font-size:20px!important;">
      Item Batch Report
    </div>
    <div class="col-6 navOption-btns" style="display: flex; justify-content: flex-end;">
      <button class="btn me-3" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()" type="button"><i
          class="fas fa-download"></i></button>

      <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
      </button>
    </div>
  </div>
  <hr class="mt-1" />

  <div class="fs--1">
    <!-- <div class="row mx-auto"> -->
    <div class="row mx-auto mb-3">
      <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;"
        class="col-xs-12 col-sm-12 col-md-3 col-lg-3" id="searchBarHide">
        <label class="form-label">Select Product Name</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input type="text" placeholder="Product Name" (input)="searchProduct()" aria-label="Select Party Name"
            matInput [formControl]="myControl" [matAutocomplete]="auto">
          <mat-icon class="m-2" matSuffix>search</mat-icon>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.itemName"
              (onSelectionChange)="selectedProduct(option.itemName)">

              {{ option.itemName | titlecase }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 " id = "showoptionHide">
        
      </div>
      <div class="col-sm-4 mt-1"></div>

      <div class="col-sm-1  mt-2 text-center" id = "excelPrintHide" (click)="exportExcel()">
        <a type="button" style="margin-left: 14px;" class="p-0 text-900 bi-file-earmark-excel fa-2x "></a>
        <div class="text-nowrap text-900  fs--2  mt-1" >Export to Excel</div>
      </div>
      <div class="col-sm-1 mt-2 text-center" id = "PrintoptionHide" (click)="onPrint()">
        <a type="button" class=" p-0 text-900 bi-printer float-right fa-2x" (click)="printComponent('component1')"></a>
        <div class="fs--2 text-900 text-nowrap mt-1">Print</div>
      </div> -->

      <div class="table-responsive scrollbar mt-3">
        <table class="table table-bordered table-striped fs--1 mb-0" id="excel-table">
          <thead class="" style="background-color: #F0F0F0;">
            <tr class="text-900">
              <th class="text-center" scope="col">#</th>
              <th class="text-center" scope="col">Item Name</th>
              <th class="text-center" scope="col">Current Quantity</th>
            </tr>
          </thead>
          <tbody>
            <tr class="btn-reveal-trigger text-left" *ngFor="let item of itemList;let i = index">
              <td class="text-center">{{ i + 1}}</td>
              <td class="Item Name text-center">{{item.itemName}}</td>
              <td class="Current Quantity text-center">{{item.stock}}</td>
            </tr>
          </tbody>
          <tbody *ngIf="itemList == 0">
            <tr class="text-center">
              <td colspan="10">
                <img class="image-responsive" src="assets/img/icons/spot-illustrations/GST No Transaction.png"
                  width="124" height="142" alt="">
                <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Data Found.</h5>
                <!-- <h5 class="text-nowrap">No Data Found.</h5> -->
              </td>
            </tr>
          </tbody>
        </table>
        <div id="headingPrintHide1">
          <mat-paginator class="float-right mt-2" *ngIf="itemList.length > 0" [pageSize]="partyPageSize"
            [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo" showFirstLastButtons
            (page)="handlePage($event)" aria-label="Select page">
          </mat-paginator>
        </div>


        <!-- <mat-paginator class="float-right mt-2" *ngIf="itemList.length > 0" [pageSize]="partyPageSize" showFirstLastButtons
      [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo"
      (page)="handlePage($event)" aria-label="Select page">
  </mat-paginator>
   -->
      </div>
    </div>
  </div>
</div>