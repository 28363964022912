<main class="main" id="top">
    <div class="" data-layout="container-fluid">
        <script>
            var isFluid = JSON.parse(localStorage.getItem("isFluid"));
            if (isFluid) {
                var container = document.querySelector("[data-layout]");
                container.classList.remove("container");
                container.classList.add("container-fluid");
            }
        </script>

        <!-- side bar -->
        <nav class="navbar navbar-vertical navbar-expand-md " id="sidebarPrint">
            <!-- <div class="row">
                <script>
                    var navbarStyle = localStorage.getItem("isNavbarVerticalCollapsed");
                    if (isNavbarVerticalCollapsed === "true") {
                        document
                            .querySelector(".navbar-vertical")
                            .classList.add(`navbar-${navbarStyle}`);
                    }
                </script>
                <div class="d-flex align-items-center hide22">
                    <div class="toggle-icon-wrapper d-flex align-items-center m-2">

                        <button class="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                            data-bs-toggle="tooltip" data-bs-placement="left" title=""
                            data-bs-original-title="Toggle Navigation" aria-label="Toggle Navigation"><span
                                class="navbar-toggle-icon"><span class="toggle-line"></span></span></button>

                    </div>
                </div>

            </div> -->
            <div class="row">
                <script>
                    var navbarStyle = localStorage.getItem("isNavbarVerticalCollapsed");
                    if (navbarStyle === "true") {
                        document.querySelector(".navbar-vertical").classList.add(`navbar-${navbarStyle}`);
                    }
                </script>
                
                <div class="d-flex align-items-center hide22">
                    <div class="toggle-icon-wrapper d-flex align-items-center m-2">
            
                        <!-- Replace the toggle button with a back button -->
                        <button class="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                            data-bs-toggle="tooltip" data-bs-placement="left" title=""
                            data-bs-original-title="Toggle Navigation" aria-label="Toggle Navigation">
                            <span class="navbar-toggle-icon"><span class="toggle-line"></span></span>
                        </button>
            
                        <!-- Add a back button -->
                        <!-- <button class="btn back-button pt-2" matTooltip="Go Back" routerLink="/pages/dashboard">
                            <span class="bi bi-arrow-left-circle" style="font-size: 1.6rem;"></span>
                        </button> -->
            
                    </div>
                </div>
            </div>
            
            <div class="custom-li collapse navbar-collapse m-0 p-0" id="navbarVerticalCollapse"
                style="background-color: #110152;overflow: auto;width: 100%">
                <div class="navbar-vertical-content scrollbar" style="width: auto;">

                    <!-- For Warehouse Side Panel -->
                    <ul class="navbar-nav d-flex align-items-center flex-column mb-3" id="navbarVerticalNav">
                        <!-- parent pages Dashboard-->
                        <li routerLink="/pages/warehouse-landing/dashboard" routerLinkActive="active"
                            class="nav-item d-flex justify-content-center mb-2 align-items-center">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="dashboard">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/Vector.png" class="bi-pie-chart">
                                </div>

                                <div style="font-size: 16px;" class="nav-link-text text-center">Dashboard</div>
                            </a>
                        </li>
                        
                        <!-- parent pages Product-->
                        <li routerLink="/pages/warehouse-landing/product" routerLinkActive="active"
                            class="nav-item d-flex justify-content-center mb-2 align-items-center">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="products">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/product.png" class="bi-pie-chart"></div>
                                <div style="font-size: 16px;" class="nav-link-text text-center">Product</div>
                            </a>
                        </li>
                        <!-- parent pages Sale-->
                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center"
                            routerLinkActive="active" routerLink="/pages/warehouse-landing/sale">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="sale">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/sale.png" class="bi-pie-chart"></div>
                                <div style="font-size: 16px;" class="nav-link-text text-center">Sale</div>
                            </a>
                        </li>
                        <!-- parent pages Purchase-->
                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center"
                            routerLinkActive="active" routerLink="/pages/warehouse-landing/purchase">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="purchase">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/purchase.png" class="bi-pie-chart">
                                </div>

                                <div style="font-size: 16px;" class="nav-link-text text-center">Purchase</div>
                            </a>
                        </li>

                        <!-- Warehouse Page  -->
                        <!-- <li class="nav-item d-flex justify-content-center mb-2 align-items-center"
                            routerLinkActive="active" routerLink="/pages/warehouse-landing/warehouse">
                            <a class="nav-link" role="button" aria-expanded="true">
                                <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                        src="/assets/icons/dashboard-icons/shop-schedule.png" width="20" height="20"
                                        class="bi-pie-chart">
                                </div>

                                <div style="font-size: 16px;" class="nav-link-text text-center">Warehouse</div>
                            </a>
                        </li> -->

                        <!-- parent pages Expenses -->
                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active" routerLink="/pages/warehouse-landing/expense">
                          <a class="nav-link" role="button"
                              aria-expanded="true" aria-controls="Expenses">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                      src="/assets/icons/dashboard-icons/expenses.png" class="bi-pie-chart"></div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Expenses</div>
                          </a>
                        </li>

                        <!-- parent pages Packages -->
                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active" routerLink="/pages/warehouse-landing/packages">
                          <a class="nav-link" role="button"
                              aria-expanded="true" aria-controls="Packages">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                      src="/assets/icons/dashboard-icons/expenses.png" class="bi-pie-chart"></div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Packages</div>
                          </a>
                        </li>

                        <!-- parent pages Shipping -->
                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active" routerLink="/pages/warehouse-landing/shipping">
                          <a class="nav-link" role="button"
                              aria-expanded="true" aria-controls="Packages">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                      src="/assets/icons/dashboard-icons/expenses.png" class="bi-pie-chart"></div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Shipping</div>
                          </a>
                        </li>

                        <!-- parent pages Transfer -->
                        <!-- <li class="nav-item d-flex justify-content-center mb-2 align-items-center" routerLinkActive="active" routerLink="/pages/warehouse-landing/transfer">
                          <a class="nav-link" role="button"
                              aria-expanded="true" aria-controls="Packages">
                              <div class="nav-link-icon"><img style="font-size: 1.1rem;"
                                      src="/assets/icons/dashboard-icons/expenses.png" class="bi-pie-chart"></div>
                              <div style="font-size: 16px;" class="nav-link-text text-center">Transfer Order</div>
                          </a>
                        </li> -->

                    </ul>
                </div>
            </div>
        </nav>

        <div class="content" style="width: auto;margin-right: 0 !important;padding-left: 10px;padding-right: 10px;">

            <nav class="navbar navbar-expand-md bg-white" id="headNavPrint">
                <div class="container-fluid">
                    <a class="navbar-brand ">
                        <button class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3 collapsed"
                            type="button" data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse"
                            aria-controls="navbarVerticalCollapse" aria-expanded="false"
                            aria-label="Toggle Navigation"><span class="navbar-toggle-icon"><span
                                    class="toggle-line"></span></span></button>
                        <div class="d-flex align-items-center py-3">

                            <img class="me-2" src="assets/img/icons/spot-illustrations/test_logo.png" alt=""
                                alt="iceipts logo" width="50" height="50" />

                        </div>
                    </a>

                    <form #insideElement #searchInput class="search-form" (ngSubmit)="onSearch()">
                        <input type="search" [(ngModel)]="searchTerm" name="searchTerm" placeholder="Search Here..."
                            (input)="onSearch()" (keyup.enter)="onEnter()">
                        <button type="submit" (keyup.enter)="onSearch()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                    </form>

                    <div class="dropdown" [ngClass]="{'show': filteredCategories.length > 0}" (click)="closeDropdown()">
                        <button class="dropbtn align-items-center"
                            [style.display]="filteredCategories.length > 0 ? 'flex' : 'none'">Categories</button>
                        <div class="dropdown-content"
                            [style.display]="filteredCategories.length > 0 ? 'block' : 'none'">
                            <a *ngFor="let category of filteredCategories"
                                [routerLink]="categoryRouterLinks[category]">{{ category }}</a>
                        </div>
                    </div>


                    <div class="collapse navbar-collapse nav-list-items" id="navbarNav">
                        <div class="navbar-nav navbar-horizantal">

                            <!-- <div class="nav-item createNewButton navOption-btns">
                                <button type="button" class="btn text-nowrap" [matMenuTriggerFor]="createNew">
                                    <span>
                                        <i class="bi bi-check2-circle my-2 mx-1"></i></span>
                                    Create New</button>
                                <mat-menu #createNew="matMenu">
                                    <button mat-menu-item [matMenuTriggerFor]="Contact">Contact</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Product">Product</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Sale">Sale</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Purchase">Purchase</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Promotions">Promotions</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Bank">Bank</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Shop_Schedule">Shop Schedule</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Expense">Expense</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Campaign">Campaign</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Admin">Admin</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Employee">Employee</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Payroll">Payroll</button>
                                    <button mat-menu-item [matMenuTriggerFor]="TimeSheet">Time Sheet</button>
                                    <button mat-menu-item [matMenuTriggerFor]="CRM">CRM</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Assets">Assets</button>
                                </mat-menu>
                                <mat-menu #Contact="matMenu">
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Contact')">Contact</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Vendor_Group')">Vendor
                                        Group</button>
                                </mat-menu>

                                <mat-menu #Product="matMenu">
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Product')">Product</button>
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Service')">Service</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Offer')">Offer</button>
                                </mat-menu>

                                <mat-menu #Sale="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('sale-invoice')">Sale
                                        Invoice</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('payment-in')">Payment
                                        In</button>
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Estimation-Invoice')">Estimation Invoice</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('sale-order')">Sale
                                        Order</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('sale-return')">Sale
                                        Return</button>
                                </mat-menu>

                                <mat-menu #Purchase="matMenu">
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('purchase-invoice')">Purchase Invoice</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('payment-out')">Payment
                                        Out</button>
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('purchase-order')">Purchase Order</button>
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('purchase-return')">Purchase Return</button>
                                </mat-menu>
                                <mat-menu #Promotions="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('add-promotion')">Add Promotions</button>
                                </mat-menu>

                                <mat-menu #Bank="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('bank')">Bank</button>
                                </mat-menu>

                                <mat-menu #Shop_Schedule="matMenu">
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Appointment')">Appointment</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Time-Schedule')">Time
                                        Schedule</button>
                                </mat-menu>

                                <mat-menu #Expense="matMenu">
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Expense')">Expense</button>
                                </mat-menu>

                                <mat-menu #Campaign="matMenu">
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Campaign')">Campaign</button>
                                </mat-menu>

                                <mat-menu #Admin="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Admin')">Admin</button>
                                </mat-menu>

                                <mat-menu #Employee="matMenu">
                                    <button mat-menu-item class="nav-link"
                                        (click)="openDialog('Employee')">Employee</button>
                                </mat-menu>

                                <mat-menu #Payroll="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('New-Structure')">New
                                        Structure</button>
                                </mat-menu>

                                <mat-menu #TimeSheet="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Time')">Time</button>
                                </mat-menu>

                                <mat-menu #CRM="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Lead')">Lead</button>
                                </mat-menu>

                                <mat-menu #Assets="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('add-asset')">Add Current Asset</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('add-non-asset')">Add Non Current Asset</button>

                                </mat-menu>
                            </div> -->

                            <!-- <div class="nav-item reportButton navOption-btns">
                                <button type="button" class="btn" [matMenuTriggerFor]="Reports">
                                    <span>
                                        <i class="bi bi-check2-circle m-2"></i></span>
                                    Reports</button>
                                <mat-menu #Reports="matMenu">
                                    <button mat-menu-item [matMenuTriggerFor]="Contact_Reports">Contact Reports</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Product_Reports">Product Reports</button>
                                </mat-menu>
                                <mat-menu #Contact_Reports="matMenu">
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/party-statement">Contact Statement</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/party-wise-profit-loss">Contact Wise Profit &
                                        Loss</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/all-parties">All Contacts</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/party-report-by-item">Contact Report By Item</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/sale-purchase-by-party">Sale Purchase By Contact</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/sale-purchase-by-party-group"> Sale
                                        Purchase By Contact Group</button>
                                </mat-menu>

                                <mat-menu #Product_Reports="matMenu">
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/item-batch-report">Item
                                        Batch Report</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/item-detail">Item Detail</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/item-report-by-party">Item Report By Contact</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/item-wise-discount">Item Wise Discount</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/item-wise-profit-and-loss">Item Wise Profit &
                                        Loss</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/low-stock-summary">Low
                                        Stock Summary</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/stock-summary">Stock Summary</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/stock-detail">Stock Detail</button>
                                </mat-menu>
                            </div> -->

                            <div class="nav-item mx-4 bellIcon">

                                <div class=" col-4">
                                    <a class="nav-link notification-indicator notification-indicator-primary px-2 fa-icon-wait"
                                        *ngIf="isShow" id="navbarDropdownNotification" role="button"
                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span
                                            class="svg-inline--fa fa fa-bell fa-w-14 fs-2"
                                            data-fa-transform="shrink-6"></span></a>
                                    <a *ngIf="!isShow" style="color: var(--falcon-navbar-light-color)"
                                        id="navbarDropdownNotification" role="button" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false"><span
                                            class="svg-inline--fa fa fa-bell fa-w-14 fs-2"
                                            data-fa-transform="shrink-6"></span></a>
                                    <div class="dropdown-menu dropdown-menu-end dropdown-menu-card dropdown-menu-notification dropdown-caret-bg"
                                        aria-labelledby="navbarDropdownNotification">
                                        <div class="card card-notification shadow-none">
                                            <div class="card-header">
                                                <div class="row justify-content-between align-items-center">
                                                    <div class="col-auto">
                                                        <h4 class="card-header-title mb-0">Notifications</h4>
                                                    </div>
                                                    <div class="col-auto ps-0 ps-sm-3">
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="font-size: 12px;" class="scrollbar-overlay"
                                                style="max-height: 19rem">
                                                <div class="list-group list-group-flush fw-normal">
                                                    <div class="notification-body list-group-title border-bottom text-center p-2"
                                                        *ngIf="!isData">
                                                        No new notifications.
                                                    </div>
                                                    <div *ngIf="isData && !isShow">
                                                        <div class="list-group-item"
                                                            *ngFor="let i of notificationReadArray">
                                                            <a class="notification notification-flush notification-unread"
                                                                (click)="GoTOPages(type)">
                                                                <div class="notification-avatar">
                                                                    <div class="avatar avatar-2xl me-3">

                                                                    </div>
                                                                </div>
                                                                <div class="notification-body">
                                                                    <strong>{{i.title}}</strong>
                                                                    <p class="mb-1">
                                                                        {{i.msg}}
                                                                    </p>
                                                                    <p>{{type}}</p>
                                                                    <span class="notification-time"><span class="me-2"
                                                                            role="img"
                                                                            aria-label="Emoji">💬</span>{{i.timeAgo}}
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="isData && isShow">
                                                        <div class="list-group-item"
                                                            *ngFor="let i of notificationUnreadArray">
                                                            <a class="notification notification-flush notification-unread"
                                                                (click)="GoTOPages(type)">
                                                                <div class="notification-avatar">
                                                                    <div class="avatar avatar-2xl me-3">

                                                                    </div>
                                                                </div>
                                                                <div class="notification-body">
                                                                    <strong>{{i.title}}</strong>
                                                                    <p class="mb-1">
                                                                        {{i.msg}}
                                                                    </p>
                                                                    <p>{{type}}</p>
                                                                    <span class="notification-time"><span class="me-2"
                                                                            role="img"
                                                                            aria-label="Emoji">💬</span>{{i.timeAgo}}
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer text-center border-top" *ngIf="isData">
                                                <a class="card-link d-block" routerLink="/pages/notifications">View
                                                    all</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="nav-item profilePic">
                                <a class="nav-link p-0" id="navbarDropdownUser" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <div class="avatar avatar-xl">
                                        <img class="rounded-circle"
                                            [src]="imageSrc ? imageSrc : '/assets/images/default-profile-pic.JPG'" />
                                    </div>
                                </a>
                                <div class="dropdown-menu dropdown-menu-end py-3"
                                    style="font-size: 13px !important;min-width: 150px;"
                                    aria-labelledby="navbarDropdownUser">
                                    <div class="bg-white dark__bg-1000 rounded-2 py-2">
                                        <a class="dropdown-item fw-bold text-warning"
                                            routerLink="/auth/subscription"><span
                                                class="fas fa-crown me-1"></span><span>Go
                                                Pro</span></a>

                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" *ngIf="this.currentUser.userType == 'CLIENT'"
                                            routerLinkActive="active" routerLink="/pages/warehouse-landing/warehouse-profile">Profile &amp;
                                            account</a>

                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" *ngIf="this.currentUser.userType == 'CLIENT'"
                                            routerLinkActive="active" routerLink="/pages/warehouse-landing/warehouse-profile-setting">Settings</a>
                                        <a class="dropdown-item" style="cursor: pointer;" (click)="logout()">Logout</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <router-outlet></router-outlet>
        </div>
    </div>
</main>