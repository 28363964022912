<div class="row g-0 mb-3">
    <div class="card">
        <div class="card-header position-relative min-vh-25 mb-7">
            <div class="bg-holder rounded-3 rounded-bottom-0"
                style="background-image:url(../../assets/img/generic/4.jpg);">
            </div>
            <!--/.bg-holder-->

            <div class="avatar avatar-5xl avatar-profile">
                <img class="rounded-circle img-thumbnail shadow-sm" [src]="imageSrc" width="200" alt="" />

                <label class="uploader" ondragover="return false;" [class.loaded]="loaded"
                    [style.outlineColor]="dragging ? activeColor : baseColor" (dragenter)="handleDragEnter()"
                    (dragleave)="handleDragLeave()" (drop)="handleDrop($event)">
                    <i class="bi bi-camera profile-icon bottom-right2 fw-bold" style="font-size: 2rem; text-align: center !important; opacity: 1 !important;" ></i>
                    
                    <img [src]="imageSrc  ? imageSrc : ''" (load)="handleImageLoad()" [class.loaded]="imageLoaded"
                        class="rounded-circle img-thumbnail shadow-sm" />

                    <input type="file" name="file" accept="image/*" (change)="handleInputChange($event)">
                </label>

            </div>
        </div>

        <div class="card-body">

            <div class="row">
                <div class="col-lg-7 col-md-7 col-sm-12 col-xl-7 text-capatilize">
                    <p class="fontweightTitle" style="font-weight:500;font-size:18px!important;">
                        {{customerProfileDataFullName}}<span data-bs-toggle="tooltip" class="m-2"
                            data-bs-placement="right" title="Verified">
                            <!-- <small class="fa fa-check-circle text-primary"
                                data-fa-transform="shrink-4 down-2"></small> -->
                            </span>
                    </p>
                    <p class="fontweight">{{customerProfileDatamobile}}, {{customerProfileDataEmail}}</p>
                    <p class="text-500 fontSpace mb-0 text-capatilize">{{customerProfileDatahomeno}} ,
                        {{customerProfileDatadistrict}} , {{customerProfileDatacity}} , {{customerProfileDataaddress}}
                    </p>
                    <p class="text-500 fontSpace mb-0 text-capatilize">{{customerProfileDatalandmark}},
                        {{customerProfileDatapincode}} </p>
                    <p class="text-500 fontSpace mb-0 text-capatilize"> {{customerProfileDatastate}} ,
                        {{customerProfileDataCountry}} </p>

                    <button class="btn btn-falcon-primary btn-sm p-0" routerLink="/pages/customer/customer-profile-setting"
                        type="button">Edit</button>

                    <div class="border-dashed-bottom my-4 d-lg-none"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row card">
    <div class="">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xl-12">
            <div class="card-header bg-light d-flex justify-content-between">
                <h5 class="mb-0" style="font-weight:500;font-size:18px;height:38px!important;">Your Address</h5><a
                    class="font-sans-serif pointer-event-cursor"></a>
            </div>
            <div class="card-body" *ngFor="let add of multipleAddress;let i=index" style="line-height:30px!important;">
                <a class="notification">
                    <div class="notification-avatar">
                        <div class="avatar avatar-xl me-3">
                            <div class="avatar-emoji rounded-circle "><span role="img" aria-label="Emoji"><i
                                        class="fas fa-city fs-1"></i></span></div>
                        </div>
                    </div>
                    <div class="notification-body">
                        <p class="mb-1"><strong>{{add.address}}, {{add.state}}, {{add.country}}</strong></p>
                        <span class="notification-time">{{add.pincode}}</span>
                    </div>
                </a>
            </div>

            <div class="card-body fs--1 p-0" *ngIf="multipleAddress.length ==0" style="line-height:19px!important;">
                <a class="border-bottom-0 notification rounded-0 border-x-0 border border-300">
                    <a class="notification ">
                        <div class="notification-body" style="margin-right: 180px;">
                            <p>No Additional Places of Business. Please Add at least one
                                Additional Places of Business Details to be displayed here</p>
                            <div class="text-center"><a routerLink="/pages/customer/customer-profile-setting">
                                    Add new Additional Places of Business
                                </a></div>
                        </div>
                    </a>
                </a>
            </div>
        </div>
    </div>
</div>