import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-book-appointment',
  templateUrl: './book-appointment.component.html',
  styleUrls: ['./book-appointment.component.scss']
})
export class BookAppointmentComponent implements OnInit {
  public allAppointmentShopType :any = [];
  shopId: any;
  lang:any;
  param1:any;
  param2:any;

  constructor( private appointmentService : AppointmentService,
    private router: Router,
    private route: ActivatedRoute,private toastService:ToastNotificationService, private titleCasePipe: TitleCasePipe
    ) { 
      this.route.queryParams.subscribe(params => {
          this.param1 = params['param1'];
          this.param2 = params['param2'];
      });
    }

  ngOnInit(): void {
    // this.getAppointmentType();
    this.route.paramMap.subscribe((p) => {
      this.shopId = p.get("id");
      if (this.shopId != null) {
        this.getAppointmentType()
      }
    });
    this.lang=this.route.snapshot.paramMap.get('lang');
  }
  getAppointmentType() {
    this.appointmentService.getAppointmentType({},this.shopId).then((res) => {
      this.allAppointmentShopType = res.data.pageData 
    },(err) => {
      if(err.error.expose){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      } else if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      } else{
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");      
      }      
    })
  }
}
