<!-- <div class="modal-header flex d-flex flex-row pt-0" style="justify-content:center; align-items: center;">
    <h1 style="color: #EB8B3F; font-weight: bolder;" class="modal-title mb-0">Update Offer</h1>

    <div class="flex d-flex flex-row" style="margin-left: auto;">

        <button style="margin-right: 10px !important;" (click)="oncancel()" type="button"
            class="btn btn-cancel">Cancel</button>
        <button type="button" class="btn btn-save" (click)="getAllOffersType()">Save</button>

    </div>

</div> -->

<div class="modal-header pt-0">
  <h1 style="color: #EB8B3F; font-weight: bolder;margin-left: 5px;" class="modal-title mb-0">Update Offer</h1>

  <div class="addOfferButtons">

    <button style="margin-right: 10px !important;" (click)="oncancel()" type="button"
    class="btn btn-cancel">Cancel</button>
    <button type="button" class="btn btn-save" (click)="getAllOffersType()">Save</button>

  </div>

</div>

<div class="modal-body">

    <form [formGroup]="OfferInfoForm">
        <div class="row mb-3">
            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Offer Name<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Offer Name" formControlName="offerName"
                    [ngClass]="{
                        'is-invalid': (f.offerName.invalid && f.offerName.touched) && submitted }" required autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.offerName.touched)">
                    <mat-error *ngIf="f.offerName.errors?.required">
                        Offer Name is required!
                    </mat-error>
                </mat-error>

            </div>

            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Status<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl  disableOptionCentering required placeholder="Status"
                    [ngClass]="{
                        'is-invalid': (f.offerStatus.invalid && f.offerStatus.touched) && submitted }" (selectionChange)="getCredit($event)"
                        formControlName="offerStatus">
                        <mat-option value="true">Active</mat-option>
                        <mat-option value="false">Inactive</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.offerStatus.touched)">
                    <mat-error *ngIf="f.offerStatus.errors?.required">
                        Status is required!
                    </mat-error>
                </mat-error>

            </div>

        </div>

        <div class="row mb-3">
            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">From Date<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput required  bsDatepicker  [minDate]="today1"
                        style="text-transform:uppercase!important;" formControlName="validFrom"
                        id="datePicker" [bsConfig]="{
                containerClass: 'theme-dark-blue',
                dateInputFormat: 'DD MMM YYYY',
                 showWeekNumbers:false
                }" (click)="openDatepicker()">
                    <span>
                        <mat-icon class="iconcalender">date_range</mat-icon>
                    </span>
                </mat-form-field>


            </div>

            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">To Date<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput required  bsDatepicker  [minDate]="OfferInfoForm.get('validFrom').value"
                        style="text-transform:uppercase!important;" formControlName="validTo"
                        id="datePicker" [bsConfig]="{
                containerClass: 'theme-dark-blue',
                dateInputFormat: 'DD MMM YYYY',
                 showWeekNumbers:false
                }" (click)="openDatepickerValidto()">
                    <span>
                        <mat-icon class="iconcalender">date_range</mat-icon>
                    </span>
                </mat-form-field>


            </div>

        </div>

        <div class="row mb-3">
            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <label class="form-label">Description<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput placeholder="Description" rows="3" formControlName="description" minlength="4" maxlength="150"
                    [ngClass]="{
                        'is-invalid': (f.description.invalid && f.description.touched) && submitted }" required autocomplete="off"></textarea>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.description.touched) && f.description.errors?.required">
                    Description is required!
                </mat-error>
                <mat-error *ngIf="f.description.errors?.minlength">
                    Description must be at least 4 character.
                </mat-error>
                <mat-error *ngIf="f.description.errors?.maxlength">
                    Description must be at least 150 characters.
                </mat-error>

            </div>

        </div>

        <div class="row mb-3">
            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Rules Name<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Rules Name" formControlName="ruleName"
                    [ngClass]="{
                        'is-invalid': (f.ruleName.invalid && f.ruleName.touched) && submitted }" required autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.ruleName.touched)">
                    <mat-error *ngIf="f.ruleName.errors?.required">
                        Rules Name is required!
                    </mat-error>
                </mat-error>

            </div>

            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Offer Type<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl disableOptionCentering
                    [ngClass]="{
                        'is-invalid': (f.offerType.invalid && f.offerType.touched) && submitted }" required placeholder="Offer Type" formControlName="offerType"
                        (selectionChange)="getOfferType($event)">
                        <mat-option value="BILL">Bill</mat-option>
                        <mat-option value="PRODUCT">Product</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.offerType.touched)">
                    <mat-error *ngIf="f.offerType.errors?.required">
                        Offer Type is required!
                    </mat-error>
                </mat-error>


            </div>
        </div>

        <div class="row mb-3" *ngIf="OfferInfoForm.get('offerType').value =='PRODUCT'">
            <!-- Buy Product -->
            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Buy Product<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" placeholder="Buy Product" (keyup.enter)="searchProduct($event.target.value)"
                        aria-label="Select Party Name" matInput [formControl]="myControl"
                        [ngClass]="{
                            'is-invalid': (f.buyProduct.invalid && f.buyProduct.touched) && submitted }" formControlName="buyProduct"
                        [matAutocomplete]="auto" required>
                    <mat-icon class="m-2" matSuffix>search</mat-icon>
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.itemName"
                            (onSelectionChange)="selectedProduct(option.itemName,$event)">
                            {{ option.itemName | titlecase }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.buyProduct.touched)">
                    <mat-error *ngIf="f.buyProduct.errors?.required">
                        Buy Product is required!
                    </mat-error>
                </mat-error>

            </div>
            <!-- Barcode -->
            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Barcode</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Barcode" formControlName="barcode" required
                     (keypress)="numberOnly($event)" autocomplete="off" readonly>
                </mat-form-field>

            </div>
            <!-- Quantity -->
            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-3 mb-sm-1">
                <label class="form-label">Quantity<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Quantity" formControlName="quantity" required
                    (keypress)="numberOnly($event)" autocomplete="off">
                </mat-form-field>

            </div>
            <!-- Discount Type -->
            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-3">
                <label class="form-label">Discount Type<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl disableOptionCentering required placeholder="Discount Type" formControlName="discountType"
                    [ngClass]="{
                        'is-invalid': (f.discountType.invalid && f.discountType.touched) && submitted }" (selectionChange)="getDiscountType($event)">
                        <mat-option value="BILL">Bill</mat-option>
                        <mat-option value="PRODUCT">Product</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.discountType.touched)">
                    <mat-error *ngIf="f.discountType.errors?.required">
                        Discount Type is required!
                    </mat-error>
                </mat-error>

            </div>
        </div>

        <!-- Offer type = Bill -->
        <div class="row mb-3" *ngIf="OfferInfoForm.get('offerType').value =='BILL'">
            <!-- Minimum Amount -->
            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 xol-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Minimum Amount<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Minimum Amount" formControlName="minAmount"
                    (keypress)="numberOnly($event)" [ngClass]="{
                        'is-invalid': (f.minAmount.invalid && f.minAmount.touched) && submitted }" required>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.minAmount.touched)">
                    <mat-error *ngIf="f.minAmount.errors?.required">
                        Minimum Amount is required!
                    </mat-error>
                </mat-error>

            </div>
            <!-- Discount Type -->
            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 xol-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Discount Type<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl disableOptionCentering required placeholder="Discount Type" formControlName="discountType"
                        (selectionChange)="getDiscountType($event)" [ngClass]="{
                            'is-invalid': (f.discountType.invalid && f.discountType.touched) && submitted }">
                        <mat-option value="BILL">Bill</mat-option>
                        <mat-option value="PRODUCT">Product</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.discountType.touched)">
                    <mat-error *ngIf="f.discountType.errors?.required">
                        Discount Type is required!
                    </mat-error>
                </mat-error>

            </div>
        </div>

        <!-- Discount Type = Product -->
        <div class="row mb-3" *ngIf="OfferInfoForm.get('discountType').value == 'PRODUCT'">
            <!-- Free Product -->
            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Free Product</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" placeholder="Free Product" (keyup.enter)="searchProduct1($event.target.value)"
                        aria-label="Select Party Name" matInput [formControl]="myControl1" formControlName="freeProduct"
                        [matAutocomplete]="auto">
                    <mat-icon class="m-2" matSuffix>search</mat-icon>
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.itemName"
                            (onSelectionChange)="selectedProduct1(option.itemName,$event)">
                            {{ option.itemName | titlecase }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

            </div>
            <!-- Barcode -->
            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Barcode</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Barcode" formControlName="barcode1"
                     (keypress)="numberOnly($event)" autocomplete="off" readonly>
                </mat-form-field>

            </div>
            <!-- Quantity -->
            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-3 mb-sm-1">
                <label class="form-label">Quantity</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Quantity" formControlName="quantity1"
                    (keypress)="numberOnly($event)" autocomplete="off">
                </mat-form-field>

            </div>

        </div>

        <!-- Discount Type = Bill -->
        <div class="row mb-3" *ngIf="OfferInfoForm.get('discountType').value  == 'BILL'">
            <!-- Select Discount -->
            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Select Discount<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl disableOptionCentering required placeholder="Select Discount" formControlName="selectDiscount"
                        (selectionChange)="selectDiscount($event)" [ngClass]="{
                            'is-invalid': (f.selectDiscount.invalid && f.selectDiscount.touched) && submitted }">
                        <mat-option value="FLAT">Flat Discount</mat-option>
                        <mat-option value="PERCENT">% Discount</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.selectDiscount.touched)">
                    <mat-error *ngIf="f.selectDiscount.errors?.required">
                        Select Discount is required!
                    </mat-error>
                </mat-error>

            </div>

            <!-- Discount -->
            <div style="margin-bottom: -13px;  font-size: 12px"  class="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                *ngIf="OfferInfoForm.get('selectDiscount').value   == 'PERCENT' || OfferInfoForm.get('selectDiscount').value  == 'FLAT'">
                <label class="form-label">Discount<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Discount" formControlName="discount"
                    [ngClass]="{
                        'is-invalid': (f.discount.invalid && f.discount.touched) && submitted }" required
                    (keypress)="numberOnly($event)" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.discount.touched)">
                    <mat-error *ngIf="f.discount.errors?.required">
                        Discount is required!
                    </mat-error>
                </mat-error>

            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-12 col-xl-12 mt-2">
                <p class="d-md-inline-block fs--1 ml-5" style="font-weight:500;font-size:20px!important;margin-left: 5px;">
                    Update Offer Image
                </p>
            </div>

        </div>

        <div class="row mt-2" style="justify-content: space-around; align-items: center;">
            <div style="padding-left:56%">
                <button *ngIf="imageSrcSeven" class="delete-button" (click)="cancelCoverImageCover()">
                    <i class="bi bi-x bi-sm" style="color:red"></i>
                </button>
            </div>

            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 flex d-flex flex-row">
                <label class="uploader2"  style="width: 200px !important;height:200px !important;"
                ondragover="return false;" [class.loaded]="loaded"
                    [style.outlineColor]="dragging ? activeColor : baseColor" (dragenter)="handleCoverDragEnter()"
                    (dragleave)="handleCoverDragLeave()" (drop)="handleCoverDrop($event)">

                    <i class="bi bi-plus-lg  fs-3" *ngIf="!imageSrcSeven" [style.color]="dragging
             ? ((imageSrcSeven) ? overlayColor : activeColor)
                     : ((imageSrcSeven) ? overlayColor : baseColor)" style = "display: flex;align-items: center;">
                       <span class="fs--1 m-2">Offer Image</span> </i>

                    <img [src]="imageSrcSeven ? imageSrcSeven : ''"  width="200" height="200" (load)="handleCoverImageLoad()" [class.loaded]="imageLoaded" />

                    <input type="file" name="file" accept="image/*" (change)="coverImgChange($event)">
                </label>
            </div>
        </div>

    </form>
</div>
