import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ProductService } from 'src/app/core/services/product.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';
import { Location, TitleCasePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import lang from "src/assets/langTranslation/language_translation"
import { CommonService } from 'src/app/core/services/common.service';
@Component({
  selector: 'app-servicelist-view',
  templateUrl: './servicelist-view.component.html',
  styleUrls: ['./servicelist-view.component.scss']
})
export class ServicelistViewComponent implements OnInit {
  public lang = lang.UAE;
  public isArabic: boolean;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public unitValue: any;
  public unitSecondaryValue: any;
  public isDisabled: boolean = false;
  public showProductType: any;
  public serviceToggle: boolean = true;
  public isStockToggle: boolean = true;
  public ProductForm: any;
  public productId: string ="";
  public todaysDate;
  public unit: any;
  public units: any;
  public secondaryunit: any;

  // image upload
  public activeColor: string = 'green';
  public baseColor: string = '#ccc';
  public overlayColor: string = 'rgba(255,255,255,0.5)';
  public dragging: boolean = false;
  public loaded: boolean = false;
  public imageLoaded: boolean = false;
  public imageSrc: string = '';
  public file: any;
  public selectedFiles: any;
  public selectedFileName: any;
  public imageSrcOne: any = "";
  public imageSrcOneId: any;
  public imageSrcTwo: any = "";
  public imageSrcTwoId: any;
  public imageSrcThree: any = "";
  public imageSrcThreeId: any = ""
  public imageSrcFour: any = "";
  public imageSrcFourId: any = "";
  public imageSrcFive: any = "";
  public imageSrcFiveId: any = "";

  public imageSrcSix: any = "";
  public imageSrcSixId: any = "";
  public imageSrcSeven: any = "";
  public imgNumber: any;
  public submitted: boolean = false;
  public productCategory: any;
  public categoryIddd: any = 0;

  serviceName:any;
  category:any;
  serviceCode:any;
  description:any;
  hsn:any;
  price:any;
  gstRate:any;
  openingStock:any;
  generateBercode:any;
  additionalCess:any;
  asOfDate:any;
  public fetchImages: any;

  constructor(private productService: ProductService,private commonService: CommonService,
    private formBuilder: FormBuilder,
    private titleCasePipe: TitleCasePipe,
    public dialogRef: MatDialogRef<ServicelistViewComponent>,
    @Inject(MAT_DIALOG_DATA) public serviceData: any,

    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private toastService: ToastNotificationService) { }

  ngOnInit(): void {
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });
    this.productId = this.serviceData.productId
    this.getProductCategory();
    this.ProductForm = this.formBuilder.group({
      serviceName: [""],
      serviceCode: [""],
      category: [""],
      description: [""],
      unit: [""],

      hsn: [""],
      price: [""],
      gstRate: [""],
      secondaryUnit: [""],
      conversionRate: [""],
      openingStock: [],
      asOfDate: [""],
      additionalCess: [""]

    });



    this.getServiceById();
    this.getProductImage();
  }

  get f() {
    return this.ProductForm.controls;
  }

  SecondaryUnit = Constants.Units
  Units = Constants.Units
  getUnit(value) {
    this.unitValue = "1 " + value;
  }

  getEnableStockValue(event) {
    this.isStockToggle = event;
  }

  showSecondaryUnitField(value) {
    this.isDisabled = value;
  }

  getSecondaryUnit(value) {
    this.unitSecondaryValue = value;
  }

  show(id) {
    if (id === 5) {
      this.showProductType = Constants.USER.GOODS;
      this.serviceToggle = true;
      //this.disabled = false;
    }
    else if (id === 6) {
      this.showProductType = Constants.USER.SERVICE;
      this.serviceToggle = true;

    }
  }
  getProductCategory() {
    this.productService.getproductCategory({}).then((res: any) => {
      this.productCategory = res.data;
    })
  }
  getServiceById() {
    this.isDisabled = true
    this.productService.getServiceById({}, this.productId).then((res) => {
      this.serviceName = res.data.itemName,
      this.category = res.data.category.categoryName,
      this.serviceCode = res.data.itemCode,
      this.generateBercode=res.data.barcode,
      this.description = res.data.description,
      this.hsn = res.data.hsn,
      this.price = res.data.salePrice,
      this.gstRate = res.data.gstRate,
      this.openingStock = res.data.stock,
      this.asOfDate = (moment(res.data.asOfDate).format("DD MMM YYYY")),
      this.additionalCess = res.data.additionalCess

      // this.ProductForm.patchValue({
      //   serviceName: res.data.itemName,
      //   category: res.data.category.id,
      //   serviceCode: res.data.itemCode,
      //   description: res.data.description,
      //   hsn: res.data.hsn,
      //   price: res.data.salePrice,
      //   gstRate: res.data.gstRate,
      //   openingStock: res.data.stock,
      //   asOfDate: (moment(res.data.asOfDate).format("DD MMM YYYY")),
      //   additionalCess: res.data.additionalCess
      // })
      this.imageSrcSeven = res.data.coverImage ? res.data.coverImage : null;
      this.fetchImages = res.data.productsImages;
      if (res.data.productsImages) {
        this.imageSrcOne = res.data.productsImages[0].productImage;
        this.imageSrcOneId = res.data.productsImages[0].id;
        this.imageSrcTwo = res.data.productsImages[1].productImage;
        this.imageSrcTwoId = res.data.productsImages[1].id;
        this.imageSrcThree = res.data.productsImages[2].productImage;
        this.imageSrcThreeId = res.data.productsImages[2].id;
        this.imageSrcFour = res.data.productsImages[3].productImage;
        this.imageSrcFourId = res.data.productsImages[3].id;
        this.imageSrcFive = res.data.productsImages[4].productImage;
        this.imageSrcFiveId = res.data.productsImages[4].id;
        this.imageSrcSix = res.data.productsImages[5].productImage;
        this.imageSrcSixId = res.data.productsImages[5].id;

      }



    })
  }


  cancel() {
    //this._location.back();
    this.dialogRef.close();
  }

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }
  handleInputChange(e, imgNumber?: any) {
    this.imgNumber = imgNumber;
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];

    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.ProductForm.patchValue({
          file: reader.result,
        });
        if (file == null) {
          this.toastService.toastMsg({
            title: "Error",
            content: "Please Select Image To Upload.",
          });
          // this.toastService.openErrorSnackBar(
          //   "Please Select Image To Upload.",
          // );
        } else {
          if (this.imgNumber == 1) {
            //  this.imageSrcOne = reader.result;
            formdata.set("uploadProductImage", file)

          } else if (this.imgNumber == 2) {
            // this.imageSrcTwo = reader.result;
            formdata.set("uploadProductImage", file)

          } else if (this.imgNumber == 3) {
            // this.imageSrcThree = reader.result;
            formdata.set("uploadProductImage", file)

          } else if (this.imgNumber == 4) {
            //this.imageSrcFour = reader.result;
            formdata.set("uploadProductImage", file)

          } else if (this.imgNumber == 5) {
            // this.imageSrcFive = reader.result;
            formdata.set("uploadProductImage", file)

          } else if (this.imgNumber == 6) {
            // this.imageSrcSix = reader.result;
            formdata.set("uploadProductImage", file)

          } else if (this.imgNumber == 7) {
            this.imageSrcSeven = reader.result;
            formdata.set("uploadProductImage", file)
          }
          this.productService.postProductImage(formdata, this.productId).then((res) => {
            if (res) {
              this.getProductImage();
              this.getServiceById();
            }
            // this.toastService.openSnackBar("Image Upload successfully)
          });
        }
      }
    };
  }

  getProductImage() {
    this.productService.getProductImage({}, this.productId).then((res) => {
      if (res) {
        let productImage = res.data;
        for (let i = 0; i <= productImage.length; i++) {
          if (i == 0) {
            this.imageSrcOne = productImage[0].productImage;
          }
          if (i == 1) {
            this.imageSrcTwo = productImage[1].productImage;
          }
          if (i == 2) {
            this.imageSrcThree = productImage[2].productImage;
          }
          if (i == 3) {
            this.imageSrcFour = productImage[3].productImage;
          }
          if (i == 4) {
            this.imageSrcFive = productImage[4].productImage;
          }
          if (i == 5) {
            this.imageSrcSix = productImage[5].productImage;
          }
        }
      }
    })
  }

  handleCoverDragEnter() {
    this.dragging = true;
  }
  handleCoverDragLeave() {
    this.dragging = false;
  }
  handleCoverImageLoad() {
    this.imageLoaded = true;
  }
  handleCoverDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.coverImgChange(e);
  }
  coverImgChange(e, imgNumber?: any) {
    this.imgNumber = imgNumber;
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];


    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.ProductForm.patchValue({
          file: reader.result,
        });

        if (file == null) {
          // this.toastService.openErrorSnackBar(
          //   "Please Select Image To Upload.",
          // );
          this.toastService.toastMsg({
            title: "Error",
            content: "Please Select Image To Upload.",
          });
        } else {
          this.imageSrcSeven = reader.result;
          formdata.set("uploadProductImage", file);
          e.srcElement.value = "";
          this.productService.postCoverImage(formdata, this.productId).then((res) => {

          });
        }
      }
    }
  }


}
