<div class="container-fluid mb-3 px-0 mx-0">
    <div class="row pt-2 pb-3" id="headingPrintHide">
        <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:500;font-size:20px!important;padding-left: 15px;">
            GSTR-9
        </div>
        <div class="col-6 navOption-btns" style="display: flex; justify-content: flex-end;">
            <button class="btn me-3"style="white-space: nowrap;" (click)="exportExcel()"
                type="button"><i class="fas fa-download"></i></button>

            <button class="btn" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
            </button>
        </div>
    </div>
    <hr class="mt-1" />

    <div class="fs--1 bg-white">
        <div class="row mb-3">
            <div style="font-size: 12px;font-family: 'Poppins';display: flex;flex-direction:column;align-items:flex-start;flex-basis:300px;" id="datePrintHide">
                <label for="Date Range" class="form-label">From Month/Year</label>
                <!-- <input class="form-control fs--1" id="inputPassword3" type="date" /> -->
                <mat-form-field class="example-full-width" appearance="outline">
                    <!-- <mat-label>Year</mat-label> -->
                    <!-- <input matNativeControl matInput formControlName="status" [disabled]="!isDisabledGST" [readonly]="!isDisabledGST"> -->
                    <mat-select matNativeControl placeholder="Year">
                        <mat-option value="2017-2018">2017-2018</mat-option>
                        <mat-option value="2018-2019">2018-2019</mat-option>
                        <mat-option value="2019-2020">2019-2020</mat-option>
                        <mat-option value="2020-2021">2020-2021</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <!-- <div class="col-sm-4"></div>
                <div class="col-sm-1  mt-2 text-center" id="excelPrintHide" (click)="exportExcel()">
                    <a type="button" style="margin-left: 14px;" class="p-0 text-900 bi-file-earmark-excel fa-2x "></a>
                    <div class="text-nowrap text-900  fs--2  mt-1">Export to Excel</div>
                </div>
                <div class="col-sm-1 mt-2 text-center" id="PrintoptionHide" (click)="onPrint()">
                    <a type="button" class=" p-0 text-900 bi-printer float-right fa-2x"></a>
                    <div class="fs--2 text-900 text-nowrap mt-1">Print</div>
                </div> -->

        </div>


        <div class="form-check" id="showoptionHide">
            <input class="form-check-input" id="flexCheckIndeterminate" type="checkbox" value="" />
            <label class="form-check-label" for="flexCheckIndeterminate"><strong style="font-weight: 500; font-size: 14px;">Consider non-tax as
                    exempted</strong></label>
        </div>

        <!-- <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped table-hover">
                <thead class="table-secondary">
                    <tr>
                        <th scope="col">Pt.I</th>
                        <th scope="col"></th>
                        <th scope="col">Basic Details</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>Financial Year</td>
                        <td>2020-2021</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>GSTIN</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>3A</td>
                        <td>Legal Name</td>
                        <td>My Company</td>
                    </tr>
                    <tr>
                        <td>3B</td>
                        <td>Trade Name (if any)</td>
                        <td></td>
                    </tr>

                </tbody>
            </table>
        </div> -->


        <!-- <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped table-hover">
                <thead class="table-secondary">
                    <tr class="btn-reveal-trigger">
                        <th scope="col">Pt.II</th> -->
        <!-- <th></th>
                        <th></th>
                        <th></th>
                        <th></th> -->
        <!-- <th class="text-nowrap" scope="col">Details Of Outward And Inward Supplies Declared During The
                            Financial Year</th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th colspan="4">(Amount in Rupees in All Tables)</th>
                    </tr>
                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td>Nature of Supplies</td>
                        <td>Taxable Value</td>
                        <td class="text-nowrap">Central Tax</td>
                        <td class="text-nowrap">State Tax/ UT Tax</td>
                        <td class="text-nowrap">Integrated Tax</td>
                        <td class="text-nowrap">Cess</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td>1</td>
                        <td>2</td>
                        <td>3</td>
                        <td>4</td>
                        <td>5</td>
                        <td>6</td>
                    </tr>
                </tbody>
            </table>
        </div>



        <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped table-hover">
                <thead class="table-secondary">

                    <tr class="btn-reveal-trigger">
                        <th scope="col">4</th>

                        <div class="text-nowrap" style="width: 50rem;">Details of advances, inward and outward supplies on which
                            tax is payable as declared in returns filed during the financial year</div>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <tr class="btn-reveal-trigger">
                        <td>A</td>
                        <td>Supplies made to un-registered persons(B2C)</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>B</td>
                        <td>Supplies made to registered persons(B2B)</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>C</td>
                        <td>Zero rated supply(Export) on payment of tax (except supplies to SEZs)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>D</td>
                        <td>Supplies to SEZs on payment of tax</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>E</td>
                        <td>Deemed Exports</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>F</td>
                        <td>Advances on which tax has been paid but invoice has not been issued (not cover under (A) to
                            (E) above)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>G</td>
                        <td>Inward supplies on which tax is to be paid on reverse charge basis</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>H</td>
                        <td>Sub-total (A to G above)</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>I</td>
                        <td>Credit Notes issued in respect of transactions specified in (B) to (E) above (-)</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>J</td>
                        <td>Debit Notes issued in respect of transactions specified in (B) to (E) above (+)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>K</td>
                        <td>Supplies/tax declared through Amendments(+)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>L</td>
                        <td>Supplies/tax reduced through Amendments(+)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>M</td>
                        <td>Sub-total (I to L above)</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>N</td>
                        <td>Supplies and advances on which tax is to be paid (H+M) above</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped table-hover">
                <thead class="table-secondary">
                    <tr class="btn-reveal-trigger">
                        <th scope="col">5</th>
                        <!-- <th></th>
                        <th></th>
                        <th></th>
                        <th></th> -->
        <!-- <th class="text-nowrap" scope="col">Details of Outward supplies on which tax is not payable as declared in returns filed during the financial year</th>

                    </tr>
                </thead>
                <tbody>

                    <tr class="btn-reveal-trigger">
                        <td>A</td>
                        <td>Zero rated supply (Export) without payment of tax</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>B</td>
                        <td>Supply to SEZs without payment of tax</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>C</td>
                        <td>Supplies on which tax is to be paid by the recipient on reverse charge basis</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>D</td>
                        <td>Exempted</td>
                        <td>0</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>E</td>
                        <td>Nil Rated</td>
                        <td>0</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr> -->

        <!-- <tr class="btn-reveal-trigger">
                        <td>F</td>
                        <td>Non-GST supply</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>G</td>
                        <td>Sub-total (A to F above)</td>
                        <td>0</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>H</td>
                        <td>Credit Notes issued in respect of transactions specified in A to F above (-)</td>
                        <td>0</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>I</td>
                        <td>Debit Notes issued in respect of transactions specified in A to F above (+)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>J</td>
                        <td>Supplies declared through Amendments(+)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>K</td>
                        <td>Supplies reduced through Amendments(+)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>L</td>
                        <td>Sub-Total (H to K above)</td>
                        <td>0</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>M</td>
                        <td>Turnover on which tax is not to be paid (G + L above)</td>
                        <td>0</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>N</td>
                        <td>Total Turnover (including advances) (4N + 5M - 4G above))</td>
                        <td>0</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>


        <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped table-hover">
                <thead class="table-secondary">
                    <tr class="btn-reveal-trigger">
                        <th scope="col">Pt.III</th> -->
        <!-- <th></th>
                        <th></th>
                        <th></th>
                        <th></th> -->
        <!-- <th class="text-nowrap" scope="col">Details Of ITC As Declared In Returns Filed During The Financial Year</th>

                    </tr>
                </thead>
                <tbody>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td>Description</td>
                        <td>Type</td>
                        <td class="text-nowrap" scope="col">Central Tax</td>
                        <td class="text-nowrap" scope="col">State Tax/ UT Tax</td>
                        <td class="text-nowrap" scope="col">Integrated Tax</td>
                        <td>Cess</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td>1</td>
                        <td>2</td>
                        <td class="text-nowrap" scope="col">3</td>
                        <td class="text-nowrap" scope="col">4</td>
                        <td class="text-nowrap" scope="col">5</td>
                        <td>6</td>
                    </tr>

                    </tbody>
            </table>
        </div>

        <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped table-hover">
                <thead class="table-secondary">
                    <tr class="btn-reveal-trigger">
                        <th scope="col">6</th> -->
        <!-- <th></th>
                        <th></th>
                        <th></th>
                        <th></th> -->
        <!-- <th class="text-nowrap" scope="col">Details of ITC availed as declared in returns filed during the financial year</th>

                    </tr>
                </thead>
                <tbody>

                    <tr class="btn-reveal-trigger">
                        <td>A</td>
                        <td>Total amount of input tax credit availed through FORM GSTR-3B (sum total of Table 4A of FORM GSTR-3B)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td></td>
                        <td>Inputs</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>

                    </tr>

                    </tbody>
            </table>
        </div> -->

        <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped table-hover" id="excel-table">
                <thead class="table-secondary" style="background-color: #F0F0F0;">
                    <tr class="btn-reveal-trigger">
                        <th class="text-nowrap" scope="col">Pt.I</th>
                        <th class="text-nowrap text-left-center" scope="col">Basic Details</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>

                    <tr class="btn-reveal-trigger">
                        <td>1</td>
                        <td>Financial Year</td>
                        <td>{{clientDatafinancialYearFrom}}</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>2</td>
                        <td>GSTIN</td>
                        <td>{{clientDatagstinNo}}</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>3A</td>
                        <td>Legal Name</td>
                        <td>{{clientDatalegalName}}</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>3B</td>
                        <td>Trade Name (if any)</td>
                        <td>{{clientDatatradeName}}</td>
                    </tr>

                </tbody>
            </table>
        </div>

        <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped table-hover">
                <thead class="table-secondary" style="background-color: #F0F0F0;">
                    <tr class="btn-reveal-trigger">
                        <th scope="col">Pt.II</th>
                        <th scope="col">Details Of Outward And Inward Supplies Declared During The
                            Financial Year</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th colspan="4">(Amount in Rupees in All Tables)</th>
                    </tr>

                    <!--  -->
                    <tr class="d-none">
                        <th colspan="7"></th>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td class="text-nowrap">Nature of Supplies</td>
                        <td class="">Taxable Value</td>
                        <td class="text-nowrap">Central Tax</td>
                        <td class="">State Tax/ UT Tax</td>
                        <td class="">Integrated Tax</td>
                        <td class="text-nowrap">Cess</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td class="text-nowrap">1</td>
                        <td class="text-nowrap">2</td>
                        <td class="text-nowrap">3</td>
                        <td class="text-nowrap">4</td>
                        <td class="text-nowrap">5</td>
                        <td class="text-nowrap">6</td>
                    </tr>


                    <tr class="btn-reveal-trigger">
                        <th class="text-nowrap" scope="col">4</th>

                        <th class="" scope="col">Details of advances, inward and outward supplies on which tax is
                            payable as declared in returns filed during the financial year</th>

                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>

                    <tr class="btn-reveal-trigger" *ngFor="let unresister of gstr9AListunregistered">
                        <td>A</td>
                        <td class="text-nowrap">Supplies made to un-registered persons(B2C)</td>
                        <td style="text-align: right;">₹&nbsp;{{unresister.taxableValue | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{unresister.intrastate | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{unresister.stateTax | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{unresister.integrated | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{unresister.totalCess | number:'1.2-2'}}</td>
                    </tr>

                    <tr class="btn-reveal-trigger" *ngFor="let resister of gstr9AListregistered">
                        <td>B</td>
                        <td class="text-nowrap">Supplies made to registered persons(B2B)</td>
                        <td style="text-align: right;">₹&nbsp;{{resister.taxableValue | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{resister.intrastate | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{resister.stateTax | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{resister.integrated | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{resister.totalCess | number:'1.2-2'}}</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>C</td>
                        <td class="">Zero rated supply (Export) on payment of tax (except supplies to SEZs)
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>D</td>
                        <td class="text-nowrap">Supplies to SEZs on payment of tax</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>E</td>
                        <td class="text-nowrap">Deemed Exports</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>F</td>
                        <td class="">Advances on which tax has been paid but invoice has not been issued (not
                            cover under (A) to (E) above)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger" *ngFor="let part2Gdata of part2GList">
                        <td>G</td>
                        <td class="">Inward supplies on which tax is to be paid on reverse charge basis</td>
                        <td style="text-align: right;">₹&nbsp;{{part2Gdata.taxableValue | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{part2Gdata.intrastate | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{part2Gdata.stateTax | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{part2Gdata.integrated | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{part2Gdata.totalCess | number:'1.2-2'}}</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>H</td>
                        <td class="text-nowrap">Sub-total (A to G above)</td>
                        <td style="text-align: right;">₹&nbsp;{{subTotalAtoGtaxableValue | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{subTotalAtoGintrastate | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{subTotalAtoGstateTax | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{subTotalAtoGtaxableValue | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{subTotalAtoGtotalCess | number:'1.2-2'}}</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>I</td>
                        <td class="">Credit Notes issued in respect of transactions specified in (B) to (E)
                            above (-)</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>J</td>
                        <td class="">Debit Notes issued in respect of transactions specified in (B) to (E)
                            above (+)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>K</td>
                        <td class="text-nowrap">Supplies/tax declared through Amendments(+)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>L</td>
                        <td class="text-nowrap">Supplies/tax reduced through Amendments(-)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>M</td>
                        <td class="text-nowrap">Sub-total (I to L above)</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>N</td>
                        <td class="">Supplies and advances on which tax is to be paid (H+M) above</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                    </tr>

                    <!-- To tackle the effect of table stripped property since background color and
                    text color becomes same with "th" -->
                    <tr class="d-none">
                        <th colspan="7"></th>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <th class="text-nowrap" scope="col">5.</th>

                        <th class="" scope="col">Details of Outward supplies on which tax is not payable as
                            declared in returns filed during the financial year</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>A</td>
                        <td class="text-nowrap">Supplies made to un-registered persons(B2C)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>B</td>
                        <td class="text-nowrap">Supply to SEZs without payment of tax</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>C</td>
                        <td class="">Supplies on which tax is to be paid by the recipient on reverse charge
                            basis</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>D</td>
                        <td class="text-nowrap">Exempted</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>E</td>
                        <td class="text-nowrap">Nil Rated</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>F</td>
                        <td class="text-nowrap">Non-GST supply</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>G</td>
                        <td class="text-nowrap">Sub-total (A to F above)</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>H</td>
                        <td class="">Credit Notes issued in respect of transactions specified in A to F above
                            (-)</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>I</td>
                        <td class="">Debit Notes issued in respect of transactions specified in A to F above
                            (+)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>J</td>
                        <td class="text-nowrap">Supplies declared through Amendments(+) </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>K</td>
                        <td class="text-nowrap">Supplies reduced through Amendments(-)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>L</td>
                        <td class="text-nowrap">Sub-Total (H to K above)</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>M</td>
                        <td class="text-nowrap">Turnover on which tax is not to be paid (G + L above)</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>N</td>
                        <td class="text-nowrap">Total Turnover (including advances) (4N + 5M - 4G above)</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped table-hover">
                <thead class="table-secondary" style="background-color: #F0F0F0;">
                    <tr class="btn-reveal-trigger">
                        <th scope="col">Pt.III</th>


                        <th style="width: 40%;" class="" scope="col">Details Of ITC As Declared In Returns Filed During
                            The
                            Financial Year</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>

                    </tr>
                </thead>
                <tbody>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td>Description</td>
                        <td>Type</td>
                        <td class="text-nowrap" scope="col" style="text-align: right;">Central Tax</td>
                        <td class="text-nowrap" scope="col" style="text-align: right;">State Tax/ UT Tax</td>
                        <td class="text-nowrap" scope="col" style="text-align: right;">Integrated Tax</td>
                        <td style="text-align: right;">Cess</td>
                    </tr>

                    <tr class="btn-reveal-trigger" *ngFor="let pt3data of pt3list">
                        <td></td>
                        <td>-</td>
                        <td>-</td>
                        <td class="text-nowrap" scope="col" style="text-align: right;">₹&nbsp;{{pt3data.central_Tax | number:'1.2-2'}}</td>
                        <td class="text-nowrap" scope="col" style="text-align: right;">₹&nbsp;{{pt3data.state_Tax | number:'1.2-2'}}</td>
                        <td class="text-nowrap" scope="col" style="text-align: right;">₹&nbsp;{{pt3data.igst | number:'1.2-2'}}</td>
                        <td style="text-align: right;">-</td>
                    </tr>

                </tbody>
            </table>
        </div>

        <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped table-hover">
                <thead class="table-secondary" style="background-color: #F0F0F0;">
                    <tr class="btn-reveal-trigger">
                        <th scope="col">6</th>

                        <th style="width: 60%;" scope="col">Details of ITC availed as declared in returns filed during
                            the financial year
                        </th>
                        <th></th>
                        <th style="text-align: right;">Central Tax</th>
                        <th style="text-align: right;">State Tax/ UT Tax</th>
                        <th style="text-align: right;">Integrated Tax</th>
                        <th style="text-align: right;">Cess</th>

                    </tr>
                </thead>
                <tbody>

                    <tr class="btn-reveal-trigger" *ngFor="let data6A of pt3list6A">
                        <td>A</td>
                        <td class="">Total amount of input tax credit availed through FORM GSTR-3B (sum total
                            of Table 4A of FORM GSTR-3B)</td>
                        <td>-</td>
                        <td style="text-align: right;">₹&nbsp;{{data6A.central_Tax| number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{data6A.state_Tax| number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{data6A.integrated| number:'1.2-2'}}</td>
                        <td style="text-align: right;">-</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td></td>
                        <td>Inputs</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>

                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>B</td>
                        <td class="">Inward supplies (other than imports and inward supplies liable to
                            reverse charge but includes service received from SEZs)</td>
                        <td>Capital Goods</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td></td>
                        <td class="text-nowrap">Input Services</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td></td>
                        <td>Inputs</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>C</td>
                        <td class="">Inward supplies received from unregistered persons liable to reverse
                            charge (other than B above) on which tax is paid & ITC availed</td>
                        <td>Capital Goods</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                    </tr>

                    <tr class="btn-reveal-trigger" *ngFor="let data of pt3list6Cservice">
                        <td></td>
                        <td></td>
                        <td>Input Services</td>
                        <td  style="text-align: right;">₹&nbsp;{{data.central_Tax | number:'1.2-2'}}</td>
                        <td  style="text-align: right;">₹&nbsp;{{data.state_Tax | number:'1.2-2'}}</td>
                        <td  style="text-align: right;">₹&nbsp;{{data.integrated | number:'1.2-2'}}</td>
                        <td>{{data.cess}}</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td></td>
                        <td>Inputs</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>D</td>
                        <td class="">Inward supplies received from registered persons liable to reverse
                            charge (other than B above) on which tax is paid & ITC availed</td>
                        <td>Capital Goods</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                    </tr>
                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td></td>
                        <td>Input Services</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                    </tr>
                    <tr class="btn-reveal-trigger">
                        <td rowspan=2>E</td>
                        <td rowspan=2>Import of goods (including suppliesfrom SEZs)</td>
                        <td>Inputs</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                    </tr>
                    <tr class="btn-reveal-trigger">


                        <td>Capital Goods</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>F</td>
                        <td class="text-nowrap">Import of services (excluding inward supplies from SEZs)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>G</td>
                        <td class="text-nowrap">Input Tax credit received from ISD</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>H</td>
                        <td class="text-nowrap">Amount of ITC reclaimed (other than B above) under the provisons of Act
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>I</td>
                        <td class="text-nowrap">Sub-total (B to H above)</td>
                        <td></td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>J</td>
                        <td class="text-nowrap">Difference (I - A above)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>K</td>
                        <td class="text-nowrap">Transition credit through TRAN-I (including revisions if any)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>L</td>
                        <td class="text-nowrap">Transition credit through TRAN-II</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>M</td>
                        <td class="text-nowrap">Any other ITC availed but not specified above</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>N</td>
                        <td class="text-nowrap">Sub-total (K to M above)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>O</td>
                        <td class="text-nowrap">Total ITC availed (I + N above)</td>
                        <td></td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>

                    </tr>
                </tbody>
            </table>
        </div>

        <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped table-hover">
                <thead class="table-secondary" style="background-color: #F0F0F0;">
                    <tr class="btn-reveal-trigger">
                        <th scope="col">7</th>

                        <th style="width: 60%;" class="" scope="col">Details of ITC Reversed and Ineligible ITC as
                            declared in
                            rerurns filed during the financial year</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>



                    </tr>
                </thead>
                <tbody>

                    <tr class="btn-reveal-trigger">
                        <td>A</td>
                        <td>As per Rule 37</td>
                        <td></td>
                        <td></td>
                        <td></td>


                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>B</td>
                        <td>As per Rule 39</td>
                        <td></td>
                        <td></td>
                        <td></td>

                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>C</td>
                        <td>As per Rule 42</td>
                        <td></td>
                        <td></td>
                        <td></td>


                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>D</td>
                        <td>As per Rule 43</td>
                        <td></td>
                        <td></td>
                        <td></td>

                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>E</td>
                        <td>As per section 17(5)</td>
                        <td></td>
                        <td></td>
                        <td></td>

                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>F</td>
                        <td>Reversal of TRAN-I credit</td>
                        <td></td>
                        <td></td>
                        <td></td>

                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>G</td>
                        <td>Reversal of TRAN-II credit</td>
                        <td></td>
                        <td></td>
                        <td></td>

                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>H</td>
                        <td>Other reversals (pl. specify)</td>
                        <td></td>
                        <td></td>
                        <td></td>

                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>I</td>
                        <td>Total ITC reversed (A to H above)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>J</td>
                        <td>Net ITC Available for Utilization (6O -7I)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                    </tr>
                </tbody>
            </table>
        </div>

        <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped table-hover">
                <thead class="table-secondary" style="background-color: #F0F0F0;">
                    <tr class="btn-reveal-trigger">
                        <th scope="col">8</th>

                        <th class="text-nowrap" scope="col">Other ITC related information</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>


                    </tr>
                </thead>
                <tbody>

                    <tr class="btn-reveal-trigger">
                        <td>A</td>
                        <td>ITC as per GSTR-2A (Table 3 & 5 thereof)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>B</td>
                        <td>ITC as per sum total of 6(B) and 6(H) above</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>


                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>C</td>
                        <td style="width: 60%;">ITC on inward supplies (other than imports and inward supplies liable to
                            reverse charge but
                            includes services received from SEZs) received during 2017-18 but availed during April to
                            September, 2018e</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                    </tr>


                    <tr class="btn-reveal-trigger">
                        <td>D</td>
                        <td>Difference [A-(B+C)]</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>E</td>
                        <td>ITC available but not availed (out of D)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>F</td>
                        <td>ITC available but ineligible (out of D)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>G</td>
                        <td>IGST paid on import of goods (as per 6(E) above)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>H</td>
                        <td>IGST credit availed on import of goods (as per 6(E) above)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>I</td>
                        <td>Difference (G -H)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>J</td>
                        <td>ITC available but not availed on import of goods (Equal to I)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>K</td>
                        <td>Total ITC to be lapsed in current financial year (E+F+J)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                    </tr>
                </tbody>
            </table>
        </div>

        <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped table-hover">
                <thead class="table-secondary" style="background-color: #F0F0F0;">
                    <tr class="btn-reveal-trigger">
                        <th scope="col">Pt.IV</th>
                        <th class="" scope="col">Details Of Tax Paid As Declared In Returns Filed During The
                            Financial Year</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>

                    </tr>
                </thead>
                <tbody>
                    <tr class="btn-reveal-trigger">
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th colspan="4">Paid through ITC</th>
                    </tr>
                    <tr class="btn-reveal-trigger">
                        <td>9</td>
                        <td class="text-nowrap">Description</td>
                        <td class="text-nowrap">Tax Payable</td>
                        <td class="text-nowrap">Paid through cash</td>
                        <td class="text-nowrap">Central Tax</td>
                        <td class="text-nowrap">State Tax/ UT Tax</td>
                        <td class="text-nowrap">Integrated Tax</td>
                        <td class="text-nowrap">Cess</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td>1</td>
                        <td>2</td>
                        <td>3</td>
                        <td>4</td>
                        <td>5</td>
                        <td>6</td>
                        <td>7</td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td>Integrated Tax</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td>Central Tax</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td>State/ UT tax</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td>Cess</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td>Interest</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td>Late fee</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td>Penalty</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td>Other</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped table-hover">
                <thead class="table-secondary" style="background-color: #F0F0F0;">
                    <tr class="btn-reveal-trigger">
                        <th scope="col">Pt. V</th>

                        <th class="" scope="col">Particulars Of The Transactions For The Previous FY Declared
                            In Returns Of April To September Of Current FY Or Upto Date Of Filing Of Annual Return Of
                            Previous FY Whichever Is Earlier</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>

                    </tr>
                </thead>
                <tbody>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td>Description</td>
                        <td class="text-nowrap">Tax Payable</td>
                        <td class="text-nowrap">Central Tax</td>
                        <td class="text-nowrap">State Tax/ UT Tax</td>
                        <td class="text-nowrap">Integrated Tax</td>
                        <td>Cess</td>

                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td></td>
                        <td>1</td>
                        <td>2</td>
                        <td>3</td>
                        <td>4</td>
                        <td>5</td>
                        <td>6</td>

                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>10</td>
                        <td>Supplies/tax declared through Amendments (+) (net of debit notes)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>11</td>
                        <td>Supplies/tax declared through Amendments (+) (net of debit notes)Supplies/tax reduced
                            through Amendments (+) (net of credit notes)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>12</td>
                        <td>Reversal of ITC availed during previous financial year</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr class="btn-reveal-trigger">
                        <td>13</td>
                        <td>ITC availed for the previous financial year</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>

            <div class="table-responsive scrollbar">
                <table class="table table-bordered table-striped table-hover">
                    <thead class="table-secondary" style="background-color: #F0F0F0;">
                        <tr class="btn-reveal-trigger">
                            <th scope="col">14</th>
                            <th class="text-nowrap" scope="col">Differential Tax Paid On Account Of Declaration In 10 &
                                11 Above</th>
                            <th></th>
                            <th></th>

                        </tr>
                    </thead>
                    <tbody>

                        <tr class="btn-reveal-trigger">
                            <td></td>
                            <td>Description</td>
                            <td>Payable</td>
                            <td>Paid</td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td></td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td></td>
                            <td>Integrated Tax</td>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td></td>
                            <td>Central Tax</td>
                            <td></td>
                            <td></td>

                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td></td>
                            <td>State/ UT Tax</td>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td></td>
                            <td>Cess</td>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td></td>
                            <td>Interest</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-responsive scrollbar">
                <table class="table table-bordered table-striped table-hover">
                    <thead class="table-secondary" style="background-color: #F0F0F0;">
                        <tr class="btn-reveal-trigger">
                            <th scope="col">Pt. VI </th>
                            <th class="text-nowrap" scope="col">Other Information</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                    </thead>
                    <tbody>

                        <tr class="btn-reveal-trigger">
                            <td>15</td>
                            <td class="text-nowrap">Particulars of Demands and Refunds</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td></td>
                            <td>Details</td>
                            <td class="">Central Tax</td>
                            <td class="">State Tax/ UT Tax</td>
                            <td class="">Integrated Tax</td>
                            <td class="text-nowrap">Cess</td>
                            <td class="text-nowrap">Interest</td>
                            <td class="">Penalty</td>
                            <td class="">Late Fee/ Others</td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td></td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                            <td>5</td>
                            <td>6</td>
                            <td>7</td>
                            <td>8</td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>A</td>
                            <td>Total Refund claimed</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>B</td>
                            <td>Total Refund sanctioned</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>C</td>
                            <td>Total Refund rejected</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>D</td>
                            <td>Total Refund pending</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>E</td>
                            <td>Total demand of taxes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>F</td>
                            <td>Total taxes paid in respect of E above</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>G</td>
                            <td>Total demands pending out of E above</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-responsive scrollbar">
                <table class="table table-bordered table-striped table-hover">
                    <thead class="table-secondary" style="background-color: #F0F0F0;">
                        <tr class="btn-reveal-trigger">
                            <th scope="col">16</th>
                            <th class="" scope="col">Information On Supplies Received From Composition
                                Taxpayers, Deemed Supply Under Section 143 And Goods Sent On Approval Basis</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>

                        </tr>
                    </thead>
                    <tbody>

                        <tr class="btn-reveal-trigger">
                            <td></td>
                            <td>Details</td>
                            <td class="text-nowrap">Taxable Value</td>
                            <td class="text-nowrap">Central Tax</td>
                            <td class="text-nowrap">State/ UT Tax</td>
                            <td class="text-nowrap">Integrated Tax</td>
                            <td>Cess</td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td></td>
                            <td>1</td>
                            <td class="text-nowrap">2</td>
                            <td class="text-nowrap">3</td>
                            <td class="text-nowrap">4</td>
                            <td class="text-nowrap">5</td>
                            <td>6</td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>A</td>
                            <td>Supplies received from composition taxpayers</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>B</td>
                            <td>Deemed supply under section 143</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>C</td>
                            <td>Goods sent on approval basis but not returned</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-responsive scrollbar">
                <table class="table table-bordered table-striped table-hover">
                    <thead class="table-secondary" style="background-color: #F0F0F0;">
                        <tr class="btn-reveal-trigger">
                            <th scope="col">17</th>
                            <th class="text-nowrap" scope="col">HSN Wise Summary Of Outward Supplies</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr class="btn-reveal-trigger">
                            <td>HSN Code</td>
                            <td>UQC</td>
                            <td class="">Total Quantity</td>
                            <td class="">Taxable Value</td>
                            <td class="">Rate of Tax</td>
                            <td class="">Central Tax</td>
                            <td class="">State/ UT Tax</td>
                            <td class="">Integrated Tax</td>
                            <td>Cess</td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                            <td>5</td>
                            <td>6</td>
                            <td>7</td>
                            <td>8</td>
                            <td>9</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-responsive scrollbar">
                <table class="table table-bordered table-striped table-hover">
                    <thead class="table-secondary" style="background-color: #F0F0F0;">
                        <tr class="btn-reveal-trigger">
                            <th scope="col">18</th>
                            <th class="text-nowrap" scope="col">HSN Wise Summary Of Inward Supplies</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr class="btn-reveal-trigger">
                            <td>HSN Code</td>
                            <td>UQC</td>
                            <td class="">Total Quantity</td>
                            <td class="">Taxable Value</td>
                            <td class="">Rate of Tax</td>
                            <td class="">Central Tax</td>
                            <td class="">State/ UT Tax</td>
                            <td class="">Integrated Tax</td>
                            <td>Cess</td>
                        </tr>
                        <tr class="btn-reveal-trigger">
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                            <td>5</td>
                            <td>6</td>
                            <td>7</td>
                            <td>8</td>
                            <td>9</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-responsive scrollbar">
                <table class="table table-bordered table-striped table-hover">
                    <thead class="table-secondary" style="background-color: #F0F0F0;">
                        <tr class="btn-reveal-trigger">
                            <th scope="col">19</th>
                            <th class="text-nowrap" scope="col">Late Fee Payable And Paid</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr class="btn-reveal-trigger">
                            <td></td>
                            <td>Description</td>
                            <td>Payable</td>
                            <td>Paid</td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td></td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                        </tr>


                        <tr class="btn-reveal-trigger">
                            <td>A</td>
                            <td>Central Tax</td>
                            <td></td>
                            <td></td>
                        </tr>


                        <tr class="btn-reveal-trigger">
                            <td>B</td>
                            <td>State Tax</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
