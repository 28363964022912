import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedRoutingModule } from './shared-routing.module';
import { TotalSalesComponent } from '../dashboard/total-sales/total-sales/total-sales.component';
import { TotalPurchaseComponent } from '../dashboard/total-purchase/total-purchase/total-purchase.component';
import { SalesPurchaseChartComponent } from '../dashboard/sales-purchase-chart/sales-purchase-chart/sales-purchase-chart.component';
import { NetProfitChartComponent } from '../dashboard/net-profit-chart/net-profit-chart/net-profit-chart.component';
import { AmountFormatPipe } from '../dashboard/amountFormat.pipe';
import { MaterialExampleModule } from 'src/app/material.module';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RouterModule } from '@angular/router';
import { StockDetailsComponent } from '../dashboard/stock-details/stock-details/stock-details.component';


@NgModule({
  declarations: [
    TotalSalesComponent,
    TotalPurchaseComponent,
    StockDetailsComponent,
    SalesPurchaseChartComponent,
    NetProfitChartComponent,
    AmountFormatPipe 
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    MaterialExampleModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatMomentDateModule,    
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RouterModule,
    BsDatepickerModule.forRoot(),
   
  ],
  exports: [
    TotalSalesComponent,
    TotalPurchaseComponent,
    StockDetailsComponent,
    SalesPurchaseChartComponent,
    NetProfitChartComponent,
    AmountFormatPipe,
  
  ],
})
export class SharedModule { }
