import { TitleCasePipe } from "@angular/common";
import lang from "src/assets/langTranslation/language_translation"
import { Component, OnInit } from "@angular/core";
import { EstimationService } from "src/app/core/services/estimation.service";
import { ProfileService } from "src/app/core/services/profile.service";
import { SaleInvoiceService } from "src/app/core/services/sale-invoice.service";
import { ToastNotificationService } from "src/app/core/services/toast-notification.service";
import { ToWords } from "to-words";
import { EstimateComponent } from "../estimate/estimate.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { saveAs } from 'file-saver';
import { CommonService } from "src/app/core/services/common.service";
import * as XLSX from 'xlsx';
import { SalesInvoiceComponent } from "../sales-invoice/sales-invoice.component";
import * as numberToWords from 'number-to-words';
import { DatePipe } from "@angular/common";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';



@Component({
  selector: "app-estimate-list",
  templateUrl: "./estimate-list.component.html",
  styleUrls: ["./estimate-list.component.scss"],
})
export class EstimateListComponent implements OnInit {

  public lang = lang.UAE;
  public selected = "Estimation";
  public selectType: any = "Estimation";
  public searchType: any = "Estimation";
  public typeService = "PERFOMA";
  public addressDetails: any = [];
  public salesData: any = [];
  public basicDetails: any = [];
  public totalGST = 0;
  public BusinessName: any;
  public finalAmount: any;
  public wordAmount = "";
  public invoiceData: any = [];
  public invoiceData1: any = [];
  public saleProductData: any = [];
  public invoiceNo: any;
  public invoiceDate: any;
  public dueDate: any;
  public billingAddress: any;
  public placeOfSupply: any;
  public shippingAddress: any;
  public billGstAmount: number;
  public totalBillAmount: number;
  public imageSrc: any = "";
  public imageLoaded: boolean = false;
  public gstStateMatch: boolean = true;
  public p = 1;
  public currentpage: any;
  public totalrow: any;
  public totalrow1: any;
  public toatlPages: any;
  public currentPageNo: number = 0;
  public currentPageNo1: number = 0;
  public estimationListPageSize: number = 5;
  public performaListPageSize: number = 5;
  public pagesize: number = 1;
  public pagesize1: number = 1;
  public count: number = 0;
  public tradeName: any;
  public gstinNo: any;
  public mobile: any;
  public state: any;
  public hsn: any;
  public searchKey: any;
  downloadData: any = [];
  public isArabic: boolean;

  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: true,
    },
  });
  currencySymbol: string;
  public signImg: any = '';
  TextSizeprint: string = 'Medium';
  public invoiceTermsCon:any;

  constructor(
    private estimationService: EstimationService,
    public dialog: MatDialog,
    private commonService: CommonService,
    public profileService: ProfileService,
    private saleInvoiceService: SaleInvoiceService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.invoiceTermsCon=localStorage.getItem('termsAndConditions');

    // Subscribe to the observable to receive updates
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });

    this.getAllEstimation();

    this.saleInvoiceService.saleInvoiceAdded$.subscribe(() => {
      // Fetch the updated list of users
      this.getAllEstimation();
    });

    //call profile get api
    this.getProfileData();
  }

  getSelectType(e) {
    this.selectType = e;
    if (this.selectType == "Estimation") {
      this.getAllEstimation();
    } else {
      this.getAllPerforma();
    }
  }
  getAllEstimation() {
    this.estimationService
      .getAllEstimationDetails(
        {},
        this.currentUser.id,
        "ESTIMATION",
        "DESC",
        this.estimationListPageSize,
        this.currentPageNo + 1
      )
      .then((res) => {
        this.invoiceData1 = [];
        this.invoiceData = res.data.pageData;
        this.totalrow = res.data.totalrows;
      });
  }

  getAllPerforma() {
    this.estimationService
      .getAllEstimationDetails(
        {},
        this.currentUser.id,
        this.typeService,
        "DESC",
        this.performaListPageSize,
        this.currentPageNo1 + 1
      )
      .then((res) => {
        this.invoiceData = [];
        this.invoiceData1 = res.data.pageData;
        this.totalrow1 = res.data.totalrows;
      });
  }

  getAllEstimationPerfomaSearch(searchKey) {
    if(this.searchKey == ""){
      if (this.selectType == 'Estimation') {
        this.currentPageNo = 0;
        this.searchType = 'Estimation';
        this.estimationService.getAllEstimationSearch({},this.currentUser.id,this.searchType,"DESC",this.estimationListPageSize,this.currentPageNo + 1,searchKey.trim())
        .then((res) => {
          this.invoiceData1 = [];
          this.invoiceData = res.data.pageData;
          this.totalrow = res.data.totalrows;
          if (this.currentPageNo > 0) {
            if (this.invoiceData.length === 0) {
              this.currentPageNo = 0;
              this.getAllEstimationPerfomaSearch(this.searchKey);
            }
          }
        });
      } else {
        this.currentPageNo1 = 0;
        this.searchType = 'PERFOMA';
        this.estimationService.getAllEstimationSearch({},this.currentUser.id,this.searchType,"DESC",this.performaListPageSize,this.currentPageNo1 + 1,searchKey.trim())
        .then((res) => {
          this.invoiceData = [];
          this.invoiceData1 = res.data.pageData;
          this.totalrow1 = res.data.totalrows;
          if (this.currentPageNo > 0) {
            if (this.invoiceData1.length === 0) {
              this.currentPageNo = 0;
              this.getAllEstimationPerfomaSearch(this.searchKey);
            }
          }
        });
      }

    }
    else{
      if (this.selectType == 'Estimation') {
        this.searchType = 'Estimation';
        this.currentPageNo = 0;
        this.estimationService.getAllEstimationSearch({},this.currentUser.id,this.searchType,"DESC",this.estimationListPageSize,this.currentPageNo + 1,searchKey.trim())
        .then((res) => {
          this.invoiceData1 = [];
          this.invoiceData = res.data.pageData;
          this.totalrow = res.data.totalrows;
          if (this.currentPageNo > 0) {
            if (this.invoiceData.length === 0) {
              this.currentPageNo = 0;
              this.getAllEstimationPerfomaSearch(this.searchKey);
            }
          }
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        });
      } else {
        this.searchType = 'PERFOMA';
        this.currentPageNo1 = 0;
        this.estimationService.getAllEstimationSearch({},this.currentUser.id,this.searchType,"DESC",this.performaListPageSize,this.currentPageNo1 + 1,searchKey.trim())
        .then((res) => {
          this.invoiceData = [];
          this.invoiceData1 = res.data.pageData;
          this.totalrow1 = res.data.totalrows;
          if (this.currentPageNo > 0) {
            if (this.invoiceData1.length === 0) {
              this.currentPageNo = 0;
              this.getAllEstimationPerfomaSearch(this.searchKey);
            }
          }
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        });
      }
    }
  }

  getProfileData() {
    this.profileService.getUserProfile({}, this.currentUser.id).then((res) => {
      (this.imageSrc = res.data.basicdetails.profileImage);
      (this.addressDetails = res.data.primaryAddress);
      this.basicDetails = res.data.basicdetails;
      this.BusinessName = res.data.additional.legalName;
      this.tradeName = res.data.additional.tradeName;
      this.mobile = res.data.basicdetails.mobile;
      this.gstinNo = res.data.additional.gstinNo;
      this.state = res.data.primaryAddress.state;
      this.signImg = res.data.basicdetails.addSignature
    });
  }

  onPrint() {
    const printContents = document.getElementById('panel').innerHTML;
    const originalContents = document.body.innerHTML;
    console.log("originalContents", originalContents)

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }
  getReceiptById(receiptId) {
    this.saleInvoiceService.getSaleInvoiceById({}, receiptId).then((res) => {
      this.salesData = res.data;
      this.saleProductData = res.data.sales_products;
      this.invoiceNo = res.data.invoiceNo;
      this.invoiceDate = res.data.invoiceDate;
      this.dueDate = res.data.dueDate;
      this.billingAddress = res.data.billingAddress;
      this.shippingAddress = res.data.shippingAddress;
      this.placeOfSupply = res.data.placeOfSupply;
      this.billGstAmount = res.data.billGstAmount;
      this.totalGST = res.data.billGstAmount;
      this.totalBillAmount = res.data.totalBillAmount;
      this.finalAmount = this.totalBillAmount;
      this.hsn = res.data.sales_products[0].product.hsn;
      this.convertToWord(this.finalAmount);
      if (this.salesData.supplyType == "INTERSTATE") {
        this.gstStateMatch = false;
      } else {
        this.gstStateMatch = true;
      }
    });
  }
  closeModal() {}

  handlePage(e) {
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.invoiceData.length > 0) {
      this.estimationListPageSize = e.pageSize;

    const searchInput = document.getElementById('searchInput') as HTMLInputElement;
    if (searchInput.value !== '') {
      this.getAllEstimationPerfomaSearch(searchInput.value);
    } else {
      this.getAllEstimation();
    }
    }
  }

  handlePage1(e) {
    this.currentPageNo1 = e.pageIndex;
    this.pagesize1 = e.pageSize;
    if (this.invoiceData1.length > 0) {
      this.performaListPageSize = e.pageSize;

    const searchInput = document.getElementById('searchInput') as HTMLInputElement;
    if (searchInput.value !== '') {
      this.getAllEstimationPerfomaSearch(searchInput.value);
    } else {
      this.getAllPerforma();
    }
    }
  }

  convertToWord(num) {
    if (!this.isArabic) {
      this.finalAmount = this.toWords.convert(num);
    }
    else {
      const toWords = new ToWords({
        converterOptions: {
          currency: false,
          ignoreDecimal: false,
          ignoreZeroCurrency: true,
        },
      });
      this.finalAmount = numberToWords.toWords(num);
      this.currencySymbol = 'AED';
      // this.finalAmount = toWords.convert(num);
      console.log(`${this.finalAmount} ${this.currencySymbol}`);

    }
  }

  openDialog(): void {
    const dialogRef: MatDialogRef<EstimateComponent> = this.dialog.open(EstimateComponent, {
      // width: '1050px',
      height : '730px',
      data: { },
      panelClass: 'custom-Modal-estimation-invoice',
      disableClose:true,
      position: {
        right: '0',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }


  downlaodAllData(){
    if(this.totalrow === undefined || this.totalrow === null){
      // this.toastService.openErrorSnackBar("No Data Available For Download");
      this.toastService.toastMsg({
        title: "Error",
        content: "No Data Available For Download",
      });
    } else {
        if (this.selectType === 'Estimation') {
          this.estimationService.getAllEstimationDetails(  {},  this.currentUser.id,  "ESTIMATION",  "DESC",  this.totalrow,  this.currentPageNo + 1).then((res) => {
            this.downloadData = res.data.pageData;
            if(this.downloadData.length > 0){
              this.exportToExcel()
            } else {
              this.toastService.toastMsg({
                title: "Error",
                content: "No Data Available For Download",
              });
              // this.toastService.openErrorSnackBar("No Data Available For Download");
            }
          },(err)=>{
            if(err.error.status === 500){
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
              // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
            }
          })
        } else {
          this.estimationService.getAllEstimationDetails(  {},  this.currentUser.id,  "PERFOMA",  "DESC",  this.totalrow,  this.currentPageNo + 1).then((res) => {
            this.downloadData = res.data.pageData;
            if(this.downloadData.length > 0){
              this.exportToExcel()
            } else {
              this.toastService.toastMsg({
                title: "Error",
                content: "No Data Available For Download",
              });
              // this.toastService.openErrorSnackBar("No Data Available For Download");
            }
          },(err)=>{
            if(err.error.status === 500){
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
              // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
            }
          })
        }
    }
  }

  exportToExcel() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1', {
      views: [
        {
          state: 'frozen',
          xSplit: 1, // Number of columns to freeze (0 means no freezing)
          ySplit: 1, // Number of rows to freeze (e.g., 2 means freeze the first two rows)
        },
      ],
    });
    // Define a header style
    const headerStyle = {
      font: { color: { argb: 'FF0000FF' }, bold: true },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFF00FF' } },
    };

    // Define your headers
    const headers = [
   'Type',
   'Date',
   'Reference #',
   "Customer's Name",
   'Billing Address',
   'Product',
   'Description',
   'Quantity',
   'Base Price',
   'GST %',
   'GST Amount (In Rs)',
   'Total Amount(In Rs)'
    ];

    // Apply the header style to the header row
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.fill = headerStyle.fill;
      cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      //  cell.width = 120

    });
    // Set column widths for header cells
    worksheet.columns.forEach((column, index) => {
      // Assuming you want a fixed width of 120 for all header columns
      column.width = 15;
    });

    // Set background colors for multiple cells using a loop
    const cellBackgroundColors = [
      { cell: 'A1', color: 'FF8DB4E2', width: 5 }, // light blue background for cell A1
      { cell: 'B1', color: 'FF8DB4E2' },
      { cell: 'C1', color: 'FF8DB4E2' },
      { cell: 'D1', color: 'FFFCD5B4' }, //for pink
      { cell: 'E1', color: 'FFFCD5B4' },
      { cell: 'F1', color: 'FF8DB4E2' },//light blue 
      { cell: 'G1', color: 'FF8DB4E2' },//light blue
      { cell: 'H1', color: 'FF8DB4E2' },//light blue
      { cell: 'I1', color: 'FFFCD5B4' },//pink
      { cell: 'J1', color: 'FFFCD5B4' },
      { cell: 'K1', color: 'FFFCD5B4' },
      { cell: 'L1', color: 'FFFCD5B4' },

      // FF8DB4E2- blue color code  FFFFC4D5 Orange
    ];
    cellBackgroundColors.forEach((cellColor) => {
      worksheet.getCell(cellColor.cell).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: cellColor.color },
      };
    });

    this.downloadData.forEach((item) => {
      const row = [];
      row.push(item.invoiceType ? item.invoiceType :'NA');
      const formattedInvoiceDate = this.datePipe.transform(item.invoiceDate, 'dd MMM yyyy');
      row.push(formattedInvoiceDate ? formattedInvoiceDate : "NA");
      row.push(item.invoiceNo ? item.invoiceNo: 0);
      row.push(item.party ? item.party.partyName : "NA");
      row.push(item.billingAddress);
      row.push("product");
      row.push(item.description ? item.description : 'NA');
      row.push("quantity");
      row.push("Base Price");
      const vatPer = parseFloat(item.billGstAmount);
      row.push(vatPer.toFixed(2));
      const vatAmount = parseFloat(item.billGstAmount);
      row.push((vatAmount.toFixed(2)));
      row.push((item.totalBillAmount ? item.totalBillAmount : 0));

      worksheet.addRow(row);
      const vatCell = worksheet.getCell('J' + worksheet.rowCount);

      // Set the alignment of the VAT cell to rights
      vatCell.alignment = { horizontal: 'right' };
      const vatCellK = worksheet.getCell('K' + worksheet.rowCount);

      // Set the alignment of the VAT cell to right
      vatCellK.alignment = { horizontal: 'right'};
      const vatCellL = worksheet.getCell('L' + worksheet.rowCount);

      // Set the alignment of the VAT cell to right
      vatCellL.alignment = { horizontal: 'right'};

      // Get the cell in the VAT column for the current row
    });

    // Generate the Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      if (this.selectType == 'Estimation') {
            saveAs(blob, 'Estimation Invoice List.xlsx');
          } else {
            saveAs(blob, 'Performa Invoice List.xlsx');
          }
    });
  }
  createSaleInvoiceDialog(item): void {
    const dialogRef: MatDialogRef<SalesInvoiceComponent> = this.dialog.open(SalesInvoiceComponent, {
      // width: '1050px',
      height: '730px',
      data: {
        item : item
      },
      panelClass: 'custom-Modal-sale-invoice',
      disableClose: true,
      position: {
        right: '0',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  getTextSizeprint(TextSizeprint: string): number {
		switch (TextSizeprint) {
		  case 'Large':
			return 16;
		  case 'Medium':
			return 14;
		  case 'Small':
			return 12;
		  default:
			return 14; // Default to 'Medium' size if an invalid option is selected
		}
	  }
}