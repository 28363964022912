import { TitleCasePipe } from "@angular/common";
import { NullTemplateVisitor } from "@angular/compiler";
import { NullVisitor } from "@angular/compiler/src/render3/r3_ast";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subscription } from "rxjs";
import { debounceTime, map, startWith } from "rxjs/operators";
import { PartGroupServiceService } from "src/app/core/services/part-group-service.service";
import { PartyService } from "src/app/core/services/party.service";
import { ProfileService } from "src/app/core/services/profile.service";
import { ToastNotificationService } from "src/app/core/services/toast-notification.service";
import { ContinuePopupModalComponent } from "../continue-popup-modal/continue-popup-modal.component";
import lang from "src/assets/langTranslation/language_translation"
import { CommonService } from "src/app/core/services/common.service";

interface IRange {
  value: Date[];
  label: string;
}
@Component({
  selector: "app-add-party",
  templateUrl: "./add-party.component.html",
  styleUrls: ["./add-party.component.scss"],
})
export class AddPartyComponent implements OnInit {
  public lang = lang.UAE;
  private pincodeSubscription: Subscription;
  public submitted: boolean;
  public partyGroupData: any;
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  @Input() isVendor: boolean
  showPopupModal: boolean = false; // Flag to control the popup modal state
  public ranges: IRange[] = [
    {
      value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()],
      label: 'Last 7 Days'
    },
    {
      value: [new Date(new Date().setDate(new Date().getMonth() - 1)), new Date()],
      label: 'Last Month'
    }
  ];
  public today: Date;
  public maxDate: Date;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public isShippingAddress: boolean;
  public date = new Date
  // public additionalDetailsForm: FormArray;
  public PartyForm: FormGroup;
  public fieldArray: Array<any> = [];
  public newAttribute: any = {};
  public selectedYear: number;
  public years: number[] = [];
  public paramID: any;
  public gstDetails: any;
  public isDisabledGST: boolean = true;
  public finalObject;
  public formArrayData;
  public todaysDate;
  public filteredOptions: Observable<string[]>;
  public partyName = new FormControl();
  public selectedGroupId: any = []
  public slectedGroup: any;
  public sendGroupData: any;
  public selectedClient: any
  // public selectedGroups: any[] = [];
  @Input() modalRef: NgbModalRef | undefined;
  selectedOption: string = 'customer';
  isOn = false;
  gstTypes = [
    { label: 'Regular', value: 'REGULAR' },
    { label: 'Composite', value: 'COMPOSITE' },
    { label: 'Exempted', value: 'EXEMPTED' }
  ];

  gstTypeControl = new FormControl();
  pinDetails: any;
  isLoading: boolean = false;
  countryList: any[] = [];
  isSaveButtonDisabled: boolean = false;
  distanceAsString: any;
  @Input() dialogData: any;
  isArabic: boolean;


  constructor(private formBuilder: FormBuilder,
    public router: Router, private profileService: ProfileService,
    public partyService: PartyService, private partyGroupservice: PartGroupServiceService,
    private toastService: ToastNotificationService, private titleCasePipe: TitleCasePipe,
    public activeModal: NgbActiveModal, @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddPartyComponent>, private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data2: any, private commonService: CommonService
  ) {
    this.dialogData = data2;
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year >= 1901; year--) {
      this.years.push(year);
    }

  }
  isShown: boolean = false;
  ngOnInit(): void {
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });
    this.isVendor = this.dialogData.isVendor

    this.todaysDate = new Date();
    this.getAllGroupList()
    if (this.isArabic) {
      this.PartyForm = this.formBuilder.group({
        gstType: [null,],
        gstIn: [null, [Validators.required, Validators.minLength(15), Validators.maxLength(15)]],
        status: [],
        legalName: [],
        partyName: ["", [Validators.required]],
        mobile: [, [Validators.required, Validators.minLength(9), Validators.maxLength(9), Validators.pattern("^[0-9]*$")]],
        email: [null, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"), Validators.email]],

        billingAddress: [null, [Validators.required]],
        shippingAddress: [null],
        openingBalance: [null],
        asOfDate: [null],
        balanceStatus: ["1"],
        relationSince: [null],
        userId: [""],
        distance: [""],
        transporation: [null],
        addtionalDetails: new FormArray([this.createaddtionalDetails()]),
        isCustomer: [this.isShown],
        creditPeriod: [],
        creditLimit: [],
        noOfCredit: [0, [Validators.pattern("^[0-9]*$")]],
        PaymentMode: [""],
        pincode: [null,],
        city: [null, [Validators.required]],
        state: [null, [Validators.required]],
        country: ["United Arab Emirates", [Validators.required]],
        partyGrpArr: new FormControl([]),
      });
    } else {
      this.PartyForm = this.formBuilder.group({
        gstType: [null,],
        gstIn: [null, [Validators.pattern("^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$"),
        Validators.minLength(15)]],
        status: [],
        legalName: [],
        partyName: ["", [Validators.required]],
        mobile: [, [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]],
        // email: [null, [Validators.required, Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"), Validators.email]],
        email: [null, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"), Validators.email]],

        billingAddress: [null, [Validators.required]],
        shippingAddress: [null],
        openingBalance: [null],
        asOfDate: [null],
        balanceStatus: ["1"],
        relationSince: [null],
        userId: [""],
        distance: [""],
        transporation: [null],
        addtionalDetails: new FormArray([this.createaddtionalDetails()]),
        isCustomer: [this.isShown],
        creditPeriod: [],
        creditLimit: [],
        noOfCredit: [0, [Validators.pattern("^[0-9]*$")]],
        PaymentMode: [""],
        pincode: [null],
        city: [null],
        state: [null],
        country: ["India"],
        partyGrpArr: new FormControl([]),
      });
    }
    // this.pincodeSubscription = this.PartyForm.get('pincode').valueChanges
    //   .pipe(debounceTime(500)) // Add a debounce time to avoid making API calls on every keystroke
    //   .subscribe((pincode) => {
    //     this.getPincodeDetails(pincode);
    //   });

    this.getAllCountries()
    if (!this.isVendor) {
      this.isOn = true
      this.toggleShow(true)
    }
  }

  get selectedGroups(): string[] {
    return this.PartyForm.get('groupName').value;
  }

  // Handle the selection change
  onSelectionChange(selected: string[]): void {
    this.PartyForm.get('groupName').setValue(selected);
  }

  getPincodeDetails(pin) {
    this.profileService.getUserPincode({}, pin).then((res) => {
      this.pinDetails = res;
      this.PartyForm.patchValue({
        city: res.data.districtName,
        state: res.data.stateName,
      });
    });
  }

  getAllCountries() {
    this.partyService.getAllCountries({}, "").then((res) => {
      this.countryList = res.data;
      // console.log(this.countryList);
    });
  }

  get f() {
    return this.PartyForm.controls;
  }


  /**
    * Get additional filed input fields
    */
  get additionalDetailsControls() {
    return this.PartyForm.get('addtionalDetails') as FormArray;
  }
  /**
   * Create additinla details form group
   * @returns 
   */
  createaddtionalDetails(): FormGroup {
    return new FormGroup({
      department: new FormControl(""),
      additionalMobile: new FormControl(""),
      additionalContact: new FormControl("",),
      // additionalId: new FormControl(null),
    });
  }
  /**
  * add rows to additional details
  */
  addAddtionalDetails(): void {
    const data = this.PartyForm.get('addtionalDetails') as FormArray;
    data.push(this.createaddtionalDetails());
  }
  /**
 * delete rows from additional details
 * @param index 
 */
  deleteaddAddtionalDetails(index: number): void {
    // console.log('delete addtional index', index);
    const data = this.PartyForm.get('addtionalDetails') as FormArray;
    data.removeAt(index)
  }

  getGstNumber(gstin) {
    if (this.isArabic) {
      this.isDisabledGST = false;
      return
    }
    this.isLoading = true;
    this.partyService.getGstNumber({}, gstin).then((res) => {
      this.gstDetails = res;
      if (res.success) {
        this.PartyForm.patchValue({
          gstType: res.data.taxPayerType.toUpperCase(),
          legalName: res.data.legalName,
          status: res.data.status
        })
        if (res && res.data && res.data.status === 'Active') {
          this.isDisabledGST = false;
          this.isSaveButtonDisabled = false
        } else {
          // GSTIN is inactive, show the popup modal
          this.continueModal()
          this.isDisabledGST = true;
        }
      } else if (res.success === false) {
        // GSTIN is inactive, show the popup modal
        this.continueModal()
        this.isDisabledGST = true;
      }
    }, (err) => {
      this.isDisabledGST = true;
      this.continueModal()
    })
    this.isLoading = false;
  }

  validateTRN(TRN) {
    if (TRN.length === 15 && this.isArabic) {
      this.isDisabledGST = false;
      return true
    } else {
      this.isDisabledGST = true;
      this.PartyForm.controls.gstIn.setErrors({ incorrect: true });
      // this.setGSTINError = true;
    }
  }

  continueModal() {
    const dialogRef = this.dialog.open(ContinuePopupModalComponent, {
      width: '300px', // Adjust the width as needed
      disableClose: true, // Prevent closing the dialog by clicking outside
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // User clicked "Leave"
        this.isSaveButtonDisabled = true;
      } else {
        // User clicked "Continue Anyway"
        this.isSaveButtonDisabled = false;
      }
    });
  }

  getAllGroupList() {
    this.partyGroupservice.getPartiGroupsDetails({}, this.currentUser.id, this.partyPageSize, (this.currentPageNo + 1)).then((res) => {
      this.partyGroupData = res.data.pageData.map((data) => data.group)
      // this.groupNames = this.partyGroupData.map(group => group.group.groupName)
      // this.groupId = this.partyGroupData.map(group => group.group.id)
      this.filteredOptions = this.partyName.valueChanges
        .pipe(
          startWith(''),
          map(name => name ? this.filterNamesGroups(name) : this.partyGroupData.slice())
        );
    })
  }

  private filterNamesGroups(name: string): string[] {
    // console.log(this.partyGroupData)
    return this.partyGroupData.filter(partyList =>
      partyList.groupName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }


  addFieldValue() {
    this.fieldArray.push(this.newAttribute)
    this.newAttribute = {};
  }

  deleteFieldValue(index) {
    this.fieldArray.splice(index, 1);
  }

  /**
   * Post party details api call
  */
  public postPartyDetails() {
    this.submitted = true;
    // console.log("Post party details", this.additionalDetailsControls)
    this.formArrayData = this.additionalDetailsControls.value;

    if (this.isShown) {
      if ((this.PartyForm.controls.billingAddress.value == "" || this.PartyForm.controls.partyName.value == ""
        // if ((this.PartyForm.controls.partyName.value == ""
        || this.PartyForm.controls.mobile.value == "" || this.PartyForm.controls.email.value == "" || this.PartyForm.controls.PaymentMode.value == "") && (this.PartyForm.invalid)) {
        // this.toastService.openErrorSnackBar("Please fill mandatory fields!!!");
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
        return false;
      } else if ((this.PartyForm.controls.billingAddress.value == "" && this.PartyForm.controls.partyName.value == ""
        && this.PartyForm.controls.mobile.value == "" && this.PartyForm.controls.email.value == "" || this.PartyForm.controls.PaymentMode.value == "") && (this.PartyForm.invalid)) {
        // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
      }
      else {
        this.addPartyObject();
      }
    }
    else {
      if ((this.PartyForm.controls.billingAddress.value == "" || this.PartyForm.controls.partyName.value == ""
        || this.PartyForm.controls.mobile.value == "" || this.PartyForm.controls.email.value == ""
        || this.PartyForm.controls.gstType.value == "" || this.PartyForm.controls.gstIn.value == "" || this.PartyForm.controls.PaymentMode.value == "") && (this.PartyForm.invalid)) {
          this.toastService.toastMsg({
            title: "Error",
            content: "Fill All Required Fields.",
          });
          // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
        return false;
      } else if ((this.PartyForm.controls.billingAddress.value != "" && this.PartyForm.controls.partyName.value != ""
        && this.PartyForm.controls.mobile.value != "" && this.PartyForm.controls.email.value != ""
        && this.PartyForm.controls.gstType.value != "" && this.PartyForm.controls.gstIn.value != "" || this.PartyForm.controls.PaymentMode.value == "") && (this.PartyForm.invalid)) {
          this.toastService.toastMsg({
            title: "Error",
            content: "Fill All Required Fields.",
          });
          // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
        // this.addPartyObject()
      } else {
        this.addPartyObject();
      }
    }
  }

  addPartyObject() {
    // this.slectedGroup.forEach((e) => {
    //   let data = {
    //      e.id, e.groupName,
    //   };
    //   this.sendGroupData.push(data);
    // });


    let grpData = []
    if (this.selectedClient && this.selectedClient.length > 0) {
      this.selectedClient.map((e) => {
        grpData.push(e)
      })
    }
    this.distanceAsString = this.PartyForm.controls.distance.value.toString(),
      this.finalObject = {


        "partyDetails": {
          "isCustomer": this.isShown,
          "gstType": this.PartyForm.controls.gstType.value ? this.PartyForm.controls.gstType.value : null,
          "gstIn": this.PartyForm.controls.gstIn.value ? this.PartyForm.controls.gstIn.value : null,
          "status": this.PartyForm.controls.status.value ? this.PartyForm.controls.status.value : null,
          "partyName": this.PartyForm.controls.partyName.value,
          "mobile": this.PartyForm.controls.mobile.value ? (this.PartyForm.controls.mobile.value).toString() : null,
          "email": this.PartyForm.controls.email.value.toLowerCase(),
          "billingAddress": this.PartyForm.controls.billingAddress.value,
          "shippingAddress": this.PartyForm.controls.shippingAddress.value,
          "openingBalance": this.PartyForm.controls.openingBalance.value,
          "asOfDate": this.PartyForm.controls.asOfDate.value ? this.PartyForm.controls.asOfDate.value : null,
          "balanceStatus": this.PartyForm.controls.balanceStatus.value,
          "payment": this.PartyForm.controls.PaymentMode.value ? this.PartyForm.controls.PaymentMode.value : null,
          "creditPeriodDay": this.PartyForm.controls.creditPeriod.value,
          "creditLimit": this.PartyForm.controls.creditLimit.value,
          "noOfCredits": this.PartyForm.controls.noOfCredit.value,
          "relationSince": this.PartyForm.controls.relationSince.value,
          "userId": this.currentUser.id,
          "distance": this.distanceAsString ? this.distanceAsString : null,
          "transporation": this.PartyForm.controls.transporation.value,
          "pincode": this.PartyForm.controls.pincode.value,
          "city": this.PartyForm.controls.city.value,
          "state": this.PartyForm.controls.state.value,
          "country": this.PartyForm.controls.country.value,
          // "partyAdditional": this.formArrayData
        },
        "groupAdd": this.PartyForm.controls.partyGrpArr.value || []
      }
    this.formArrayData.forEach(element => {
      if (element.department != "" && element.additionalContact != "" && element.additionalMobile != "") {
        this.finalObject.addtionalDetails = this.formArrayData
      }
    });

    this.partyService.postParty(this.finalObject).then((res) => {
      this.paramID = res.data.id
      if (res.success) {
      if(this.isVendor===true){
        this.toastService.toastMsg({
          title: "Success",
          content: "Vendor Added Successfully!!!",
          });
      }else{
        this.toastService.toastMsg({
          title: "Success",
          content: "Customer Added Successfully!!!",
          });
      }
        if (this.pincodeSubscription) {
          this.pincodeSubscription.unsubscribe();
        }
      }
      this.partyService.notifyPartyAdded();
      this.dialogRef.close();
    },
      (err) => {
        if (err.error.error_message === "party already exists!") {
          this.toastService.toastMsg({
            title: "Error",
            content: "Please Check If The Entered Mail Id Or GSTIN Is Already Registered",
          });
        } else if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content:this.titleCasePipe.transform(err.error.error_message),
          });
        } else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      })
  }

  removeDetailsFields(index) {
    const content = this.PartyForm.get("additionalDetailsForm") as FormArray;
    content.removeAt(index);
  }

  toggleShow(value) {
    this.f.gstIn.setValidators(null);
    this.f.gstType.setValidators(null);
    this.isShown = value;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  StringOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }
  /**
  * toggle to hide shipping address
  * @param checked 
  */
  showShippingAddress(checked: boolean) {
    this.isShippingAddress = checked;
  }
  /**
  * toggle to hide shipping address
  * @param checked 
  */
  hideShippingAddress(checked: boolean) {
    if (checked) {
      this.isShippingAddress = false;
    }
  }


  cancel() {
    // Reset the form and submitted flag
    this.PartyForm.reset();
    this.submitted = false;
    this.dialogRef.close();

  }
  openDatepicker() {
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.PartyForm.get('asOfDate').value;
    if (isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.PartyForm.get('asOfDate').setValue(new Date());
    }
  }

}

