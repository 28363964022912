import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AddOfferService } from 'src/app/core/services/add-offer.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import * as moment from 'moment';
import { Observable } from 'rxjs/internal/Observable';
import { BiddingService } from 'src/app/core/services/bidding.service';
import { map, startWith } from 'rxjs/operators';
import { ProductService } from 'src/app/core/services/product.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-offerlist-view',
  templateUrl: './offerlist-view.component.html',
  styleUrls: ['./offerlist-view.component.scss']
})
export class OfferlistViewComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public submitted: boolean;
 // public offerId: any;
  public OfferInfoForm: any;
  public showInvoiceGenerateData: boolean = false;

  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  public goodsData = [];
  public filteredOptions: Observable<string[]>
  public myControl = new FormControl();
  public searchedProduct = [];

  public uploadImage=false;
  public showdivwisedata : boolean = true;

  public searchedProduct1 = [];
  public myControl1 = new FormControl();
  public filteredOptions1: Observable<string[]>
  public goodsData1 = [];

  public todaysDate;
  public maxDate: Date;
  public today: Date;
  public todaysDate2: any;
  public options;
  public selectType;
  public activeColor: string = 'green';
  public baseColor: string = '#ccc';
  public overlayColor: string = 'rgba(255,255,255,0.5)';
  public dragging: boolean = false;
  public loaded: boolean = false;
  public imageLoaded: boolean = false;
  public imageSrcSeven: any;
  public OfferId:any;

  offerName:any;
  description:any;
  offerStatus:any;
  validFrom:any;
  validTo:any;

  ruleName:any;
  offerType:any;
  buyProduct:any;
  barcode:any;
  quantity:any;
  barcode1:any;
  quantity1:any;
  discountType:any;
  minAmount:any;
  freeProduct:any;
 // selectDiscount:any;
 discount:any;
 public byp: any;

  public ConditionForm = this._formBuilder.group({
    ruleName: ['', Validators.required],
    offerType: ['', Validators.required],
    buyProduct: ['', Validators.required],
    barcode: ['', Validators.required],
    quantity: ['', Validators.required],
    barcode1: ['', Validators.required],
    quantity1: ['', Validators.required],
    discountType: [null, Validators.required],
    minAmount: [null, Validators.required],
    freeProduct: ['', Validators.required],
    selectDiscount: [null, Validators.required],
    discount: [null, [Validators.required]],
    options: ['', Validators.required],
  });

  constructor(private _formBuilder: FormBuilder, private commonService: CommonService,
    public dialogRef: MatDialogRef<OfferlistViewComponent>,
    @Inject(MAT_DIALOG_DATA) public offerData: any,
    private addofferService: AddOfferService,
    private productService: ProductService,
    private biddingService: BiddingService,
    private titleCasePipe: TitleCasePipe,
    private toastService: ToastNotificationService, private router: Router, private route: ActivatedRoute,) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
    }

    async ngOnInit() {
      try{
        this.OfferInfoForm = this._formBuilder.group({
          offerName: ['', Validators.required],
          description: ['', Validators.required],
          offerStatus: ['', Validators.required],
          validFrom: ['', Validators.required],
          validTo: ['', Validators.required]
        });

        await this.getGoodsbyUser();
        this.getGoodsbyUser1();

        this.OfferId = this.offerData.OfferId

        this.getOfferById();
        this.todaysDate = new Date();
        this.todaysDate2 = new Date();
        this.todaysDate = this.commonService.getTodaysDate();
      }
      catch (e) {
        console.log("Enter catch")
      }

  }

  async getGoodsbyUser() {
    await this.productService.getGoodsProductByUser_Type({}, this.currentUser.id, "goods", "DESC", this.partyPageSize, (this.currentPageNo + 1)).then((res) => {

      this.goodsData = res.data.pageData;
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(name => name ? this.filterNames(name) : this.goodsData.slice())
        );
    }  ,
    (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    })
  }

  getGoodsbyUser1() {
    this.productService.getGoodsProductByUser_Type({}, this.currentUser.id, "goods", "DESC", this.partyPageSize, (this.currentPageNo + 1)).then((res) => {

      this.goodsData1 = res.data.pageData;
      this.filteredOptions1 = this.myControl1.valueChanges
        .pipe(
          startWith(''),
          map(name => name ? this.filterNames(name) : this.goodsData1.slice())
        );
    }  ,
    (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    })
  }

  private filterNames(name: string): string[] {
    return this.goodsData.filter(goods =>
      goods.itemName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }







  getOfferById(){
    this.addofferService.getAllOfferListById({},this.OfferId).then((res)=>{
      console.log("view:",res);

      this.offerName =  res.data.offerName,
      this.description = res.data.description,
      this.offerStatus = res.data.status,
      this.validFrom = res.data.fromDate ? (moment(res.data.fromDate).format("DD-MMM-YYYY")) :null ,
      this.validTo = res.data.toDate? (moment(res.data.toDate).format("DD-MMM-YYYY")) :null

      this.ruleName = res.data.ruleName ?  res.data.ruleName:null,
      this.offerType = res.data.offerType ? res.data.offerType : null ,
      //this.buyProduct = res.data.productId ? res.data.productId :null,
      this.barcode = res.data.barcode ? res.data.barcode :null,
      this.quantity = res.data.quantity ? res.data.quantity :null,
      this.discountType = res.data.offerDiscountType ? res.data.offerDiscountType :null,
      this.minAmount = res.data.minimunAmount ? res.data.minimunAmount :null,
      this.freeProduct = res.data.freeProduct ? res.data.freeProduct :null,
      this.selectDiscount = res.data.selectDiscount ? res.data.selectDiscount :null,
      this.discount = res.data.discount ? res.data.discount :null,
      this.quantity1 = res.data.freeproductQuantity,
      this.OfferInfoForm.patchValue({
        offerName: res.data.offerName,
        description:res.data.description,
        offerStatus: res.data.status == true ? 'true' : 'false',
        validFrom: res.data.fromDate ? (moment(res.data.fromDate).format("DD-MMM-YYYY")) :null ,
        validTo: res.data.toDate? (moment(res.data.toDate).format("DD-MMM-YYYY")) :null

      })
      this.ConditionForm.patchValue({
        ruleName: res.data.ruleName ?  res.data.ruleName:null,
        offerType: res.data.offerType ? res.data.offerType : null ,
        //buyProduct: res.data.productId ? res.data.productId :null,
        barcode:res.data.barcode ? res.data.barcode :null,
        quantity:res.data.quantity ? res.data.quantity :null,
        discountType: res.data.offerDiscountType ? res.data.offerDiscountType :null,
        minAmount: res.data.minimunAmount ? res.data.minimunAmount :null,
        freeProduct: res.data.freeProduct ? res.data.freeProduct :null,
        selectDiscount:res.data.selectDiscount ? res.data.selectDiscount :null,
        discount:res.data.discount ? res.data.discount :null,
      })
      this.imageSrcSeven = res.data.offerImage,
      this.byp = res.data.productId ? res.data.productId :null
      console.log("this.byp",this.byp)
      console.log("this.byp", this.goodsData)
      let p= res.data.freeProduct;

      for (var i = 0; i < this.goodsData.length; i++) {
        // Do something with this.goodsData[i]
        if (this.byp === this.goodsData[i].id) {
          console.log(i)

            this.buyProduct= this.goodsData[i].itemName

          // this.byPid = this.byp;
        }
        if(p == this.goodsData[i].itemName){

            this.barcode1= this.goodsData[i].barcode

        }
      }
    })


  }


  oncancel(){
    this.dialogRef.close();
  }
  getCredit(e) {
    if (e.value == "true") {
      e.value = true;
    }
    else {
      e.value = false
    }
    this.OfferInfoForm.controls.offerStatus.value = e.value;

  }
  postOfferImage(){
    let data= {
      "location": this.OfferInfoForm.controls.offerName.value,
  }
}

  getOfferType(event) {
    this.offerType = event.source.value;
  }
  getDiscountType(event) {
    this.discountType = event.source.value;
  }
  selectDiscount(event) {
    this.selectType = event.source.value;
  }
  handleCoverDragEnter() {
    this.dragging = true;
  }
  handleCoverDragLeave() {
    this.dragging = false;
  }
  handleCoverImageLoad() {
    this.imageLoaded = true;
  }
  handleCoverDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.coverImgChange(e);
  }
  coverImgChange(e) {
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];


    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);

      reader.onload = () => {


        if (file == null) {
          // this.toastService.openErrorSnackBar(
          //   "Please Select Image To Upload.",
          // );
          this.toastService.toastMsg({
            title: "Error",
            content: "Please Select Image To Upload.",
          });
        } else {

          this.imageSrcSeven = reader.result;

          let data = {
            location: formdata.set("uploadOfferImage", file)
          }
          e.srcElement.value = "";
          this.addofferService.postAddOfferImage(formdata).then((res) => {
          }, (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
              // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
              // this.toastService.openErrorSnackBar("Something Went Wrong.");
            }
          });
        }
      }
    }
  }

}
