import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { RemoteShopService } from 'src/app/core/services/remote-shop.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';

@Component({
  selector: 'app-remote-customer',
  templateUrl: './remote-customer.component.html',
  styleUrls: ['./remote-customer.component.scss']
})
export class RemoteCustomerComponent implements OnInit {
  public defaultShopImg = Constants.images.defaultShopImg;
  public allRetailers :any = [];
  public isData: boolean = true;
  public imageSrc: any = '';
  constructor(private remoteShopService: RemoteShopService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private titleCasePipe: TitleCasePipe,
    private toastService : ToastNotificationService
    ) { }

  ngOnInit(): void {
    this.getAllRetailer();
  }
  getAllRetailer() {
    this.spinner.show();
    this.remoteShopService.gettRemoteShops({}).then((res) => {
      this.spinner.hide();
      this.allRetailers = res.data; 
    },(err)=>{
      this.isData = false;
      if(err.error.status == 404){
        this.isData = false;
      } else if(err.error.status === 500){
        this.spinner.hide();
        this.isData = false;
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }
  // viewRetailerInventory(clientId){
  //   this.router.navigate(["/pages/customer/viewProduct/"+clientId])
  //   // routerLink="/pages/customer/viewProduct/{{i.id}}
  //   localStorage.setItem('currentShopId',clientId);
  // }
}
