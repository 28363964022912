import { I } from '@angular/cdk/keycodes';
import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { BiddingService } from 'src/app/core/services/bidding.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { RemoteShopService } from 'src/app/core/services/remote-shop.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';
import { StateConstants } from 'src/app/_helpers/state-constans';

@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.scss']
})
export class ViewProductComponent implements OnInit {
  public currentUser = JSON.parse(this.localStorage.getItem('currentUser'));
  @ViewChild('closebutton') closebutton;
  public hideProdCard: boolean = false;
  public defaultProductImg = Constants.images.defaultProductImg
  public hideProdList: boolean = false;
  public clientId: any;
  public inventoryData: any = []
  public cartArray: any = [];
  public productArray = [];
  public isStockAvailable: boolean = false;
  public isGoods: boolean = true;
  public isData: boolean = true;
  public isChkbox: boolean = true;
  public warnTxt = Constants.warningText;
  public chkBoxTxt = Constants.chkBoxContactText;
  public productId: any;
  public allStates = StateConstants.states;
  public checked: any;
  public notchecked: any;
  public selectedProctData: any;
  public pinDetails: any;
  public states: any;
  public prodPrice: any;
  public isLessPrice: boolean = false;
  public isHighPrice: boolean = false;
  public isHighPriceMin: boolean = false;
  public p = 1;
  public currentpage: any;
  public totalrow: any;
  public toatlPages: any;
  public currentPageNo: number = 0;
  public partyPageSize: number = 6;
  public pagesize: number = 1;
  public count: number = 0;
  public submitted: boolean = false;

  constructor(private fb: FormBuilder,
    private remoteShopService: RemoteShopService,
    private route: ActivatedRoute,
    private router: Router,
    private toastNotificationService: ToastNotificationService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private modal: NgbModal,
    private formBuilder: FormBuilder,
    private biddingService: BiddingService,
    private notificationService: ToastNotificationService,
    public profileService: ProfileService,
    private titleCasePipe: TitleCasePipe,
    private toastService : ToastNotificationService,
    @Inject("LOCALSTORAGE") private localStorage: Storage) { }

  public wishlistForm = this.formBuilder.group({
    budgetMax: ["", [Validators.required]],
    budgetMin: ["", [Validators.required]],
    pincode: ["", [Validators.required, Validators.maxLength(6), Validators.minLength(6)]],
    location: ["", [Validators.required]],
    publish: [false,]
  })
  public todaysDate = this.commonService.getTodaysDate();

  ngOnInit(): void {
    this.route.paramMap.subscribe((p) => {
      this.clientId = p.get("id");
      if (this.clientId != null) {
        this.getClientInventoryGoodsAndServices("goods")
      }
    });
    this.localStorage.setItem("clientId", JSON.stringify({ id: this.clientId })
    );
    //this.getCustomerCartItems();
    this.getCartCount();
    this.getWishlistsByUserId();
  }
  /**
   * get retailer inventory by userId
   */
  getClientInventoryGoodsAndServices(productType) {
    this.spinner.show();
    this.remoteShopService.getClientInventoryGoodsAndServices({}, this.clientId, productType, this.partyPageSize, (this.currentPageNo + 1)).then((res) => {
      this.spinner.hide();
      this.inventoryData = res.data.pageData
      this.totalrow = res.data.totalrows
      this.inventoryData.forEach(element => {
        // this.quantityCount = this.quantityCount + parseInt(element.quantity)
        if (element.openingStock > 10) {
          this.isStockAvailable = true;
        }
      });
    }, (err) => {
      if (err.error.status == 404) {
        this.inventoryData = [];
        this.spinner.hide();
        this.isData = false;
      } else if(err.error.status === 500){
        this.spinner.hide();
        this.isData = false;
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }
  /**
   * add to cart item 
   * @param item 
   */
  addProductToCart(item, changePath) {
    this.cartArray.push(item)
    this.addToCart(item, changePath);
  }

  /**
   * add to cart api call
   * @param element 
   */
  async addToCart(element, changePath) {
    let data = {
      "customerId": this.currentUser.id,
      "clientId": this.clientId,
      "barcode": element.barcode,
      "quantity": 1,
    }
    await this.remoteShopService.postAddToCart(data).then((res) => {
      this.toastService.toastMsg({
        title: "Success",
        content: "Product Added Successfully!!!",
      })
      // this.toastNotificationService.openSnackBar("Product Added Successfully!!!");
      // this.getCustomerCartItems();
      if (changePath == 'true') {
        this.router.navigate(["/pages/customer/my-cart"])
      }
      this.getCartCount();
    },(err)=>{
      if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }

  // /**
  //  * get user cart items
  //  */
  // getCustomerCartItems() {
  //   this.remoteShopService.getCustomerCartItems({}, Constants.currentUser.id)
  //     .then((res) => {
  //       this.productArray = res.data.products;
  //       this.commonService.sendData(res.data.invoiceDetails.totalProducts)
  //     })
  // }

  /**
   * get current Product Id
   * @param prodId 
   */
  addBidModal(prodId, ProductName, price, modalName) {
    this.productId = prodId;
    this.selectedProctData = ProductName;
    this.prodPrice = price;
    this.modal.open(modalName, { size: "md", centered: true, backdrop: "static", });
  }

  get f() {
    return this.wishlistForm.controls;
  }
  public showPopup: boolean = false

  postwishlistBid(name) {
    this.submitted = true;
    if (this.wishlistForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
        });
      // this.notificationService.openErrorSnackBar("Please Fill Mandatory Fields!!!")
    } else if (this.isLessPrice || this.isHighPrice || this.isHighPriceMin) {
      // this.notificationService.openErrorSnackBar("Please Fill Mandatory Fields!!!")
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
        });
    }
    else {
      let data = {
        "userId": this.currentUser.id,
        "publish": this.wishlistForm.controls.publish.value,
        "productId": this.productId,
        "budgetMax": this.wishlistForm.controls.budgetMax.value,
        "location": this.wishlistForm.controls.location.value,
        "pincode": this.wishlistForm.controls.pincode.value,
        "approxCompletedDate": this.todaysDate,
        "budgetMin": this.wishlistForm.controls.budgetMin.value,
      }
      this.biddingService.postwishlistBid(data).then((res) => {
        if (res) {
          this.toastService.toastMsg({
            title: "Success",
            content: "Bid Request Submitted Successfully!!!",
          })
          // this.notificationService.openSnackBar("Bid Request Submitted Successfully!!!");
          this.getWishlistsByUserId();
          this.modal.dismissAll();
          this.modal.open(name, { size: "sm", centered: true });
        }
      }, (err) => {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      })

    }
  }

  getWishlistsByUserId() {
    this.biddingService.getWishlistsByUserId({}, this.currentUser.id, this.partyPageSize, (this.currentPageNo + 1)).then((res) => {
      localStorage.setItem("BidData", JSON.stringify({
        data: res.data,
      }))
    }, (err) => {
      if (err.error.status === 500) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }

  /**
   * get user cart count
  */
  getCartCount() {
    this.remoteShopService.getCartCount({}, this.currentUser.id).then((res) => {
      this.commonService.sendData(res.data.count)
    }, (err) => {
      if (err.error.status === 500) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }

  getPincodeDetails(e) {
    this.profileService.getUserPincode({}, e).then((res) => {
      this.pinDetails = res;
      this.wishlistForm.patchValue({
        'location': this.pinDetails.data.stateName,
      })
    }, (err) => {
      if (err.error.status === 500) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }

  getMinBudget() {
    if (this.wishlistForm.controls.budgetMin.value > this.prodPrice) {
      this.isLessPrice = true;
    } else {
      this.isLessPrice = false;
    }
  }

  getMaxBudget() {
    if (this.wishlistForm.controls.budgetMax.value > this.prodPrice) {
      this.isHighPrice = true;
    } else if (this.wishlistForm.controls.budgetMax.value < this.wishlistForm.controls.budgetMin.value) {
      this.isHighPriceMin = true;
    }
    else {
      this.isHighPrice = false;
      this.isHighPriceMin = false;
    }
  }

  // for pagination
  public handlePage(e: any) {
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.inventoryData.length > 0) {
      this.partyPageSize = e.pageSize;
      if (this.clientId != null) {
        this.getClientInventoryGoodsAndServices("goods")
      }
    }
  }

  modalDismiss() {
    this.modal.dismissAll();
    this.wishlistForm.reset();
  }
 
}
