import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { PuchaseReturnComponent } from '../puchase-return/puchase-return.component';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { PartyService } from 'src/app/core/services/party.service';
import lang from "src/assets/langTranslation/language_translation"
import { CommonService } from 'src/app/core/services/common.service';
import { ProfileService } from 'src/app/core/services/profile.service';

@Component({
  selector: 'app-purchase-return-list',
  templateUrl: './purchase-return-list.component.html',
  styleUrls: ['./purchase-return-list.component.scss']
})
export class PurchaseReturnListComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public search="PURCHASE";
  public lang = lang.UAE;
  public partyPageSize: number = 5;
  public currentPageNo: number = 0;
  public pagesize: number = 1;
  public count: number = 0;
  userId:any;
  public saleListRes: any=[];
  public SalesPartYName:any[];
  public saleExclude:any[];
  public saleInclude:any[];
  searchTerm: string;
  billGstAmount: any
  isOrderReturn: any
  partyName: any
  returnDate: any
  saleReturnNumber: any
  amountExcludingGst: any
  amountIncludingGst: any
  totalBillAmount: any;
  invoiceNo: any;
  downloadData: any[]
  public excelbutton: boolean = false;
  isArabic: boolean;
  public signImg: any = '';
  TextSizeprint: string = 'Medium';
  public invoiceTermsCon:any;

  constructor(private saleInvoiceService: SaleInvoiceService, public dialog: MatDialog,
    private toastService: ToastNotificationService, private partyService : PartyService,
    private titleCasePipe: TitleCasePipe, private commonService : CommonService, public profileService: ProfileService,
    ) { }

  ngOnInit(): void {
    this.invoiceTermsCon=localStorage.getItem('termsAndConditions');
    // Subscribe to the observable to receive updates
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });
    this.getsaleReturnByList();
    this.partyService.partyAdded$.subscribe(() => {
      // Fetch the updated list
      this.getsaleReturnByList();
    });
    this.getUserProfile();
  }
  getUserProfile(){
    this.profileService.getUserProfile({}, this.currentUser.id).then((res => {
      this.signImg = res.data.basicdetails.addSignature
    }),
    )
  }

  searchFunc(){
    // Perform search on the saleListRes based on the search term
    if (this.searchTerm.trim() === '') {
      // If search term is empty, reset the list to the original data
      this.getsaleReturnByList();
      this.excelbutton = false;
    } else {
      // Filter the list based on the search term
      this.saleInvoiceService.getsaleReturnByList({}, this.currentUser.id,this.partyPageSize,(this.currentPageNo + 1),this.search).then((res:any) => {
        this.saleListRes = res.data.filter(item => {
          return (
            item.invoiceNo.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            item.saleReturnNumber.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            item['party.partyName'].toString().includes(this.searchTerm) ||
            item.isOrderReturn.toString().includes(this.searchTerm)
          );
        });
        this.excelbutton = true;
      });
    }
  }

  getsaleReturnByList() {
      this.saleInvoiceService.getsaleReturnByList({}, this.currentUser.id,this.partyPageSize,(this.currentPageNo + 1),this.search).then((res:any) => {
        this.saleListRes= res.data;
        let a=res.data[0];
        this.SalesPartYName=a['party.partyName']
        let b=res.data[0];
        this.saleExclude=b['sales_products.amountExcludingGst']
        let c=res.data[0];
         this.saleInclude= c['sales_products.amountIncludingGst']

      },
        (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
            // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
            // this.toastService.openErrorSnackBar("Something Went Wrong.");
          }
    })
  }

  openDialog(): void {
    const dialogRef: MatDialogRef<PuchaseReturnComponent> = this.dialog.open(PuchaseReturnComponent, {
      // width: '950px',
      height : '730px',
      data: { },
      panelClass: 'custom-Modal-purchase-order',
      disableClose:true,
      position: {
        right: '0',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  getReceiptById(receiptId) {
    const data = this.saleListRes.find(item => item.id === receiptId);
    console.log(data);
    this.billGstAmount = data.billGstAmount;
    this.invoiceNo = data.invoiceNo;
    this.isOrderReturn = data.isOrderReturn;
    this.partyName = data['party.partyName'];
    this.returnDate = data.returnDate;
    this.saleReturnNumber = data.saleReturnNumber;
    this.amountExcludingGst = data['sales_products.amountExcludingGst'];
    this.amountIncludingGst = data['sales_products.amountIncludingGst'];
    this.totalBillAmount = data.totalBillAmount;
  }

  downlaodAllData(){
    if(this.saleListRes.length === 0){
      this.toastService.toastMsg({
        title: "Error",
        content: "No Data Available For Download",
      });
      // this.toastService.openErrorSnackBar("No Data Available For Download");
    } else {
        this.saleInvoiceService.getsaleReturnByList({}, this.currentUser.id,this.partyPageSize,(this.currentPageNo + 1),this.search).then((res:any) => {
          this.downloadData = res.data;
          if(this.downloadData.length > 0){
            this.exportToExcel()
          } else {
            this.toastService.toastMsg({
              title: "Error",
              content: "No Data Available For Download",
            });
            // this.toastService.openErrorSnackBar("No Data Available For Download");
          }
        },(err)=>{
          if(err.error.status === 500){
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
            // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
          }
        })
    }
  }

  downlaodDatasearch(){
    if(this.saleListRes.length === 0){
      this.toastService.toastMsg({
        title: "Error",
        content: "No Data Available For Download"
      });
      // this.toastService.openErrorSnackBar("No Data Available For Download");
    } else {
        this.saleInvoiceService.getsaleReturnByList({}, this.currentUser.id,this.partyPageSize,(this.currentPageNo + 1),this.search).then((res:any) => {
          this.downloadData = res.data.filter(item => {
            return (
              item.invoiceNo.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
              item.saleReturnNumber.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
              item['party.partyName'].toString().includes(this.searchTerm) ||
              item.isOrderReturn.toString().includes(this.searchTerm)
            );
          });
         // console.log("this.downloadData",this.downloadData)
          if(this.downloadData.length > 0){
            this.exportToExcel()
          } else {
            this.toastService.toastMsg({
              title: "Error",
              content: "No Data Available For Download"
            });
            // this.toastService.openErrorSnackBar("No Data Available For Download");
          }
        },(err)=>{
          if(err.error.status === 500){
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
            // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
          }
        })
    }
  }

  exportToExcel(): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.downloadData);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(data, 'purchase_return_data.xlsx');
  }
  getTextSize(selectedTextSize: string): number {
    switch (selectedTextSize) {
      case 'Large':
      return 22;
      case 'Medium':
      return 20;
      case 'Small':
      return 18;
      default:
      return 20; // Default to 'Medium' size if an invalid option is selected
    }
    }

    getTextSizeprint(TextSizeprint: string): number {
    switch (TextSizeprint) {
      case 'Large':
      return 16;
      case 'Medium':
      return 14;
      case 'Small':
      return 12;
      default:
      return 14; // Default to 'Medium' size if an invalid option is selected
    }
    }

}
