import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AllUsersService {

  constructor(public httpClient:HttpService) { }
  // getAllUsersRole(data: {}, empId:string): any {
  //   return new Promise((resolve, reject) => {
  //     this.httpClient.inventory(data, UrlConstants.getAllUsers + empId, 'GET').subscribe(
  //       (res) => resolve(res),
  //       (err) => reject(err)
  //     );
  //   });
  // }
}
