<div class="card mb-3 mt-3">
    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
            Add New Shop Details
        </p>
    </div>
    <div class="progress" style="height:2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>
</div>
<form [formGroup]="shopDetailsForm">
    <div class="card mb-3 mt-3">
        <div class="card-header bg-light pt-3 pb-3">
            <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:600;font-size:20px!important;">
                Basic Details
            </p>
        </div>
        <div class="progress" style="height:2px">
            <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>E-Mail</mat-label>
                        <input type="email" placeholder="E-mail" matInput formControlName="email" 
                        [ngClass]="{'is-invalid': f.email.invalid && f.email.touched && submitted }" />
                    </mat-form-field>
                    <mat-error class="text-danger errormsg" *ngIf="(submitted || f.email.touched) && f.email.errors?.pattern && f.email.errors?.email">
                        Enter a valid email id.
                    </mat-error>
                    <mat-error class="text-danger errormsg"
                        *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                        Enter a valid email id.
                    </mat-error>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Mobile Number</mat-label>
                        <input type="text" placeholder="Enter Mobile Number" matInput formControlName="mobile" minlength="10" maxLength="10"
                            ngxOnlyNumbers [ngClass]="{'is-invalid': f.mobile.invalid && f.mobile.touched && submitted }" />
                    </mat-form-field>
                    <mat-error class="text-danger errormsg" *ngIf=" (submitted || f.mobile.touched) && f.mobile.errors?.pattern">
                        Enter a valid mobile number.
                    </mat-error>
                    <mat-error class="text-danger errormsg" *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.required">
                        Enter a valid mobile number.
                    </mat-error>
                    <mat-error class="text-danger errormsg" *ngIf="f.mobile.errors?.maxlength">
                        Enter a valid mobile number.
                    </mat-error>
                    <mat-error class="text-danger errormsg" *ngIf="f.mobile.errors?.minlength">
                        Enter a valid mobile number.
                    </mat-error>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>First Name</mat-label>
                        <input type="text" placeholder="First Name" matInput formControlName="firstName" placeholder="First Name" 
                        [ngClass]="{'is-invalid': f.firstName.invalid && f.firstName.touched && submitted }" />
                    </mat-form-field>
                    <mat-error class="text-danger errormsg" *ngIf="(submitted || f.firstName.touched) && f.firstName.errors?.required">
                        Enter a valid first name.
                    </mat-error>

                </div>

            </div>
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Last Name</mat-label>
                        <input type="text" matInput formControlName="lastName" placeholder="Last Name"
                        [ngClass]="{'is-invalid': f.lastName.invalid && f.lastName.touched && submitted }" />
                    </mat-form-field>
                    <mat-error class="text-danger errormsg" *ngIf="(submitted || f.lastName.touched) && f.lastName.errors?.required">
                        Enter a valid last name.
                    </mat-error>

                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <!-- <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>Parent Id</mat-label>
                                    <input type="text" matInput formControlName="parentId"
                                    placeholder="Parent Id" readonly/>
                                </mat-form-field> -->
                </div>
            </div>
        </div>
    </div>


    <div class="card mb-3 mt-3">
        <div class="card-header bg-light pt-3 pb-3">
            <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:600;font-size:20px!important;">
                Shop Details
            </p>
        </div>
        <div class="progress" style="height:2px">
            <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
            </div>
        </div>
        <div class="card-body ">
            <div class="d-flex justify-content-start">
                <div class="margin-right">
                    <label class="form-check-label" for="customSwitch1">Pay</label>
                </div>
                <div class="form-check form-switch mb-0">
                    <input class="form-check-input" formControlName="isRegistered" id="customSwitch1" type="checkbox"
                        checked="checked" />
                    <label class="form-check-label align-top" for="customSwitch1">Recieve</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Legal Name</mat-label>
                        <input type="text" matInput formControlName="legalName" placeholder="Legal Name" />
                    </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-4 col-lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Account Method</mat-label>
                        <mat-select formControlName="accountMethod">
                            <mat-option *ngFor="let b of accountMethods" [value]="b.accMethod">
                                {{b.accMethod}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-4 col-lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Business Type</mat-label>
                        <mat-select formControlName="businessType" [disabled]="!isDisabledGST"
                            [readonly]="!isDisabledGST">
                            <mat-option *ngFor="let b of businessTypes" [value]="b.buisness">
                                {{b.buisness}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Gstin No</mat-label>
                        <input type="text" matInput formControlName="gstinNo" placeholder="Gstin No" maxlength="15"
                            minlength="15" (change)="getGstNumber($event.target.value)" style="text-transform: uppercase"
                            pattern = "^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$"
                            [ngClass]="{'is-invalid': f.gstinNo.invalid && f.gstinNo.touched}" required />
                    </mat-form-field>
                    <!-- <mat-error class="text-danger" *ngIf="(f.gstinNo.touched) && f.gstinNo.errors?.required">
                        GST Number Is Required!
                    </mat-error> -->
                    <mat-error class="text-danger"
                            *ngIf="(submitted || f.gstinNo.touched) && f.gstinNo.errors?.required">
                            Enter a valid GSTIN.
                    </mat-error>
                    <mat-error *ngIf="(submitted || f.gstinNo.touched) && f.gstinNo.errors?.pattern || f.gstinNo.errors?.minlength">
                        Enter a valid GSTIN.
                    </mat-error>
                    <mat-error *ngIf="(submitted || f.gstinNo.touched) && f.gstinNo.errors?.maxlength">
                            Enter a valid GSTIN.
                    </mat-error>

                    <!-- <mat-error *ngIf="f.gstinNo.errors?.pattern">
                        GST must be valid number
                    </mat-error>
                    <mat-error *ngIf="f.gstinNo.errors?.maxlength">
                        GST must be of 15-digits
                    </mat-error>
                    <mat-error *ngIf="f.gstinNo.errors?.minlength">
                        GST must be of 15-digits
                    </mat-error> -->
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Gst Type</mat-label>
                        <mat-select formControlName="gstType" [disabled]="!isDisabledGST">
                            <mat-option *ngFor="let b of gstTypes" [value]="b.gstName">
                                {{b.gstName}}
                            </mat-option>
                        </mat-select>
                        <!-- <input type="text" matInput formControlName="gstType" 
                                    placeholder="Gst Type" /> -->
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Composite Type</mat-label>
                        <mat-select formControlName="compositeType" [disabled]="!isDisabledGST"
                            [readonly]="!isDisabledGST">
                            <mat-option *ngFor="let b of compositeTypes" [value]="b.composite">
                                {{b.composite}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <div class="card mb-3 mt-3">
        <div class="card-header bg-light pt-3 pb-3">
            <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:600;font-size:20px!important;">
                Address
            </p>
        </div>
        <div class="progress" style="height:2px">
            <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-sm-12 col-md-4 col--lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Home no</mat-label>
                        <input type="text" matInput formControlName="homeno" placeholder="Home no" />
                    </mat-form-field>
                </div>

                <!-- <div class="col-sm-12 col-md-4 col--lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Pin Code</mat-label>
                        <input type="text" matInput formControlName="pincode" placeholder="Pin Code" minlength="6" maxlength="6"
                            (change)="getPincodeDetails($event.target.value)" />
                    </mat-form-field>
                </div> -->

                <div class="col-sm-12 col-md-4 col--lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Pin Code</mat-label>
                        <input type="text" matInput formControlName="pincode" placeholder="Pin Code" minlength="6" maxlength="6"
                        (change)="getPincodeDetails($event.target.value)"
                        ngxOnlyNumbers [ngClass]="{'is-invalid': f.pincode.invalid && f.pincode.touched && submitted }" />
                    </mat-form-field>
                    <mat-error class="text-danger errormsg" *ngIf=" (submitted || f.pincode.touched) && f.pincode.errors?.pattern">
                        Enter a valid pincode number.
                    </mat-error>
                    <mat-error class="text-danger errormsg" *ngIf="(submitted || f.pincode.touched) && f.pincode.errors?.required">
                        Enter a valid pincode number.
                    </mat-error>
                    <mat-error class="text-danger errormsg" *ngIf="f.pincode.errors?.maxlength">
                        Enter a valid pincode number.
                    </mat-error>
                    <mat-error class="text-danger errormsg" *ngIf="f.pincode.errors?.minlength">
                        Enter a valid pincode number.
                    </mat-error>
                </div>

                <div class="col-sm-12 col-md-4 col--lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Address</mat-label>
                        <input type="text" matInput formControlName="address" placeholder="Address" />
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-4 col--lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>City</mat-label>
                        <input type="text" matInput formControlName="city" placeholder="City" />
                    </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-4 col--lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>District</mat-label>
                        <input type="text" matInput formControlName="district" placeholder="District" />
                    </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-4 col--lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Landmark</mat-label>
                        <input type="text" matInput formControlName="landmark" placeholder="Landmark" />
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>State</mat-label>
                        <input type="text" matInput formControlName="state" placeholder="State" />
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Country</mat-label>
                        <input type="text" matInput formControlName="country" placeholder="Country" />
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="col-12 d-flex p-3 justify-content-end">
            <button class="btn btn-outline-primary  me-1" (click)="saveShop()" type="button">
                <i class="fas fa-plus-square me-2"></i>Save Shop
            </button>
            <button class="btn btn-outline-primary  me-2" (click)="backBtn()" type="button">
                Cancel
            </button>
        </div>
    </div>
</form>