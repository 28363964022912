<div class="modal-header pt-0">
    <h1 style="color: #EB8B3F; font-weight: bolder;margin-left: 5px;" class="modal-title mb-0">Add Service</h1>

    <div class="addServiceButtons" *ngIf="!isArabic">

        <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel"
            (click)="clearInput()">Cancel</button>
        <button type="submit" class="btn btn-save" (click)="addService()"
            *appHasPermission="['delete', 'modify','create', 'read']; name 'products'">Save</button>

    </div>

    <div class="addServiceButtons" *ngIf="isArabic">

        <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel"
            (click)="clearInput()">Cancel</button>
        <button type="submit" class="btn btn-save" (click)="addService()"
            *appHasPermission="['delete', 'modify','create', 'read']; name 'products'">Save</button>

    </div>

</div>

<div class="modal-body">

    <form [formGroup]="ProductForm" *ngIf="!isArabic">
        <div class="row mb-3">
            <!-- Service Name -->
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Service Name<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput formControlName="serviceName" placeholder="Service Name"
                        [ngClass]="{
                    'is-invalid': (f.serviceName.invalid && f.serviceName.touched) && submitted }" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.serviceName.touched)">
                    <mat-error *ngIf="f.serviceName.errors?.required">
                        Service Name is required!
                    </mat-error>
                </mat-error>
            </div>

            <!-- Service Code -->
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Service Code<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput formControlName="serviceCode" placeholder="Service Code"
                        [ngClass]="{
                            'is-invalid': (f.serviceCode.invalid && f.serviceCode.touched) && submitted }"
                        minlength="4" maxlength="8" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.serviceCode.touched) && f.serviceCode.errors?.pattern">
                    Service Code must be alphanumeric value, Special character not Allowed !
                </mat-error>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.serviceCode.touched) && f.serviceCode.errors?.required">
                    Service Code is required!
                </mat-error>
                <mat-error *ngIf="f.serviceCode.errors?.minlength">
                    Service Code must be at least 4 character.
                </mat-error>
                <mat-error *ngIf="f.serviceCode.errors?.maxlength">
                    Service Code must be at least 8 characters.
                </mat-error>
            </div>
        </div>

        <div class="row mb-3">

            <!-- Generate Barcode -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Generate Barcode<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="Barcode" type="text"
                        formControlName="generateBarcode" [ngClass]="{
                        'is-invalid': (f.generateBarcode.invalid && f.generateBarcode.touched) && submitted }"
                        minlength="12" maxlength="12" (keypress)="numberOnly($event)" autocomplete="off">
                </mat-form-field>
                <a style="cursor: pointer;color:blue;margin-left: 5px;" (click)="autoGenerate()">Auto Generate</a>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.generateBarcode.touched) && f.generateBarcode.errors?.pattern">
                    Barcode is required!
                </mat-error>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.generateBarcode.touched) && f.generateBarcode.errors?.required">
                    Barcode is required!
                </mat-error>
                <mat-error *ngIf="f.generateBarcode.errors?.maxlength">
                    Barcode must be 12 digit.
                </mat-error>
                <mat-error *ngIf="f.generateBarcode.errors?.minlength">
                    Barcode must be 12 digit.
                </mat-error>
            </div>

            <!-- Category -->
            <div style="font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Category<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl disableOptionCentering required formControlName="category"
                        placeholder="Category" [ngClass]="{
                            'is-invalid': (f.category.invalid && f.category.touched) && submitted }">
                        <mat-option *ngFor="let item of productCategory"
                            [value]="item.id">{{item.categoryName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.category.touched)">
                    <mat-error *ngIf="f.category.errors?.required">
                        Category is required!
                    </mat-error>
                </mat-error>
            </div>
        </div>

        <div class="row mb-3">
            <!-- HSN/SAC Code -->
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">HSN/SAC Code<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="HSN Code" type="text" formControlName="hsn"
                        [ngClass]="{
                            'is-invalid': (f.hsn.invalid && f.hsn.touched) && submitted }" minlength="6" maxlength="6"
                        (keypress)="numberOnly($event)" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.hsn.touched) && f.hsn.errors?.pattern">
                    HSN is required!
                </mat-error>
                <mat-error class="text-danger" *ngIf="(submitted || f.hsn.touched) && f.hsn.errors?.required">
                    HSN is required!
                </mat-error>
                <mat-error *ngIf="f.hsn.errors?.maxlength">
                    HSN must be at least 6 digit.
                </mat-error>
                <mat-error *ngIf="f.hsn.errors?.minlength">
                    HSN must be at least 6 digit.
                </mat-error>
            </div>

            <!-- Price -->
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Sale Price Inc. GST<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" matNativeControl required matInput placeholder="Sale Price Inc. GST"
                        formControlName="price" [ngClass]="{
                            'is-invalid': (f.price.invalid && f.price.touched) && submitted }"
                        (keypress)="numberOnly($event)" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.price.touched)">
                    <mat-error *ngIf="f.price.errors?.required">
                        Price is required!
                    </mat-error>
                    <mat-error *ngIf="f.price.errors?.pattern">
                        Price Only number allowed!
                    </mat-error>
                </mat-error>
            </div>
        </div>

        <div class="row mb-3">
            <!-- Service Description -->
            <div class="col-sm-12 col-md-12 col-lg-12">
                <label class="form-label">Service Description<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput rows="3" required placeholder="Service Description" formControlName="description"
                        [ngClass]="{
                            'is-invalid': (f.description.invalid && f.description.touched) && submitted }"
                        autocomplete="off"></textarea>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.description.touched)">
                    <mat-error *ngIf="f.description.errors?.required">
                        Service Description is required!
                    </mat-error>
                </mat-error>


            </div>
        </div>

        <div class="row mb-3">

            <!-- Additional Cess -->
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Additional Cess</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="Additional Cess" type="text"
                        formControlName="additionalCess" [ngClass]="{
                            'is-invalid': (f.additionalCess.invalid && f.additionalCess.touched) && submitted }"
                        (keypress)="numberOnly($event)" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.additionalCess.touched)">
                    <mat-error *ngIf="f.additionalCess.errors?.required">
                        Additional Cess is required!
                    </mat-error>
                    <mat-error *ngIf="f.additionalCess.errors?.pattern">
                        Additional Only number allowed!
                    </mat-error>
                </mat-error>
            </div>

            <!-- GST Rate(%) -->
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">GST Rate(%)<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl min="0" required matInput placeholder="GST Rate" type="text"
                        formControlName="gstRate" [ngClass]="{
                            'is-invalid': (f.gstRate.invalid && f.gstRate.touched) && submitted }"
                        (keypress)="numberDecimalOnly($event)" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.gstRate.touched)">
                    <mat-error *ngIf="f.gstRate.errors?.required">
                        GST Rate is required!
                    </mat-error>
                    <mat-error *ngIf="f.gstRate.errors?.pattern">
                        GST Rate Only number allowed!
                    </mat-error>
                </mat-error>
            </div>

        </div>

        <div class="row mb-3">
            <!-- As on Date -->

            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Opening Stock</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="number" matNativeControl matInput placeholder="Openeing Stock"
                        formControlName="openingStock" autocomplete="off">
                </mat-form-field>
            </div>

            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">As on Date<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput required ngModel bsDatepicker [(ngModel)]="todaysDate" [minDate]="todaysDate"
                        style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                        containerClass: 'theme-dark-blue',
                        dateInputFormat: 'DD MMM YYYY',
                        showWeekNumbers:false
                    }" formControlName="asOfDate" (click)="openDatepicker()">
                    <span>
                        <mat-icon class="iconcalender">date_range</mat-icon>
                    </span>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-12 col-xl-12">
                <p class="d-md-inline-block fs--1 ml-5" style="font-weight:500;font-size:20px!important;margin-left: 5px;">
                    Add Service Image
                </p>
            </div>

        </div>

        <div class="row mt-2">
            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 flex d-flex flex-row  mb-sm-2" style="justify-content: center;">
                <button  *ngIf="imageSrcSeven" class="delete-button" type="button" style = "position: relative;top: -16px;left: 205px;" (click) = "cancelCoverImage()">
                  <i class="bi bi-x bi-sm" style="color:red"></i>
                </button>
                <label class="uploader2" style="width: 200px !important;height:200px !important;"
                    ondragover="return false;" [class.loaded]="loaded"
                    [style.outlineColor]="dragging ? activeColor : baseColor" (dragenter)="handleCoverDragEnter()"
                    (dragleave)="handleCoverDragLeave()" (drop)="handleCoverDrop($event)">

                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        <i class="bi bi-plus-lg  fs-3" *ngIf="!imageSrcSeven" [style.color]="dragging
                        ? ((imageSrcSeven.length > 0) ? overlayColor : activeColor)
                        : ((imageSrcSeven.length > 0) ? overlayColor : baseColor)">
                        </i>

                        <span  *ngIf="!imageSrcSeven" class="mt-2" style="font-size: 12px; font-weight:500;line-height: 21;">Add Service Cover
                            Image</span>
                        <span  *ngIf="!imageSrcSeven" class="mt-2 text-muted" style="text-align: center; font-size: 12px;line-height: 21;">Drop
                            an Image or <span style="color: #1BB2F2;">Browsing images</span> it from your
                            computer</span>

                    </div>


                    <img [src]="imageSrcSeven" width="200" height="200" (load)="handleCoverImageLoad()"
                        [class.loaded]="imageLoaded" />

                    <input type="file" name="file" accept="image/*" (change)="coverImgChange($event)">
                </label>
            </div>
            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6flex d-flex flex-row" style="justify-content: center;">
                <label class="uploader2" style="width: 200px !important;height:200px !important;">

                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        <i class="bi bi-plus-lg  fs-3"></i>

                        <span class="mt-2" style="font-size: 12px; font-weight:500;line-height: 21;">Add Service
                            Image</span>
                        <span class="mt-2 text-muted" style="text-align: center; font-size: 12px;line-height: 21;">Drop
                            an Image or <span style="color: #1BB2F2;">Browsing images</span> it from your
                            computer</span>


                    </div>

                    <input type="file" name="file" accept="image/*" style="display:none;" [(ngModel)]="currentInput"
                        [ngModelOptions]="{ standalone: true }" multiple (change)="onFileSelected($event)" />
                </label>

            </div>

        </div>

        <div class="row mt-3">
            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 mb-4 mt-2 flex d-flex flex-row" *ngFor="let preview of previews;let i=index;"
                style="justify-content: center;">
                <!-- <i class="bi bi-x-circle text-danger fs-1 fw-bold" (click)="cancelmultiImage(i)"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="delete image"></i> -->
                <button class="delete-button" type="button" (click)="cancelmultiImage(i)">
                    <i class="bi bi-x bi-sm" style="color:red"></i>
                </button>
                <img [src]="preview" class="preview" height="200" width="200px" />
            </div>
        </div>

    </form>

    <form [formGroup]="ProductForm" *ngIf="isArabic">
        <div class="row mb-3">
            <!-- Service Name -->
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Service Name<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput formControlName="serviceName" placeholder="Service Name"
                        [ngClass]="{
                    'is-invalid': (f.serviceName.invalid && f.serviceName.touched) && submitted }" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.serviceName.touched)">
                    <mat-error *ngIf="f.serviceName.errors?.required">
                        Service Name is required!
                    </mat-error>
                </mat-error>
            </div>

            <!-- Service Code -->
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Service Code<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput formControlName="serviceCode" placeholder="Service Code"
                        [ngClass]="{
                            'is-invalid': (f.serviceCode.invalid && f.serviceCode.touched) && submitted }"
                        minlength="4" maxlength="8" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.serviceCode.touched) && f.serviceCode.errors?.pattern">
                    Service Code must be alphanumeric value, Special character not Allowed !
                </mat-error>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.serviceCode.touched) && f.serviceCode.errors?.required">
                    Service Code is required!
                </mat-error>
                <mat-error *ngIf="f.serviceCode.errors?.minlength">
                    Service Code must be at least 4 character.
                </mat-error>
                <mat-error *ngIf="f.serviceCode.errors?.maxlength">
                    Service Code must be at least 8 characters.
                </mat-error>
            </div>
        </div>

        <div class="row mb-3">

            <!-- Generate Barcode -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Barcode/SKU<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="Barcode/SKU" type="text"
                        formControlName="generateBarcode" [ngClass]="{
                        'is-invalid': (f.generateBarcode.invalid && f.generateBarcode.touched) && submitted }"
                        minlength="13" maxlength="13" (keypress)="numberOnly($event)" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.generateBarcode.touched) && f.generateBarcode.errors?.pattern">
                    Barcode/SKU is required!
                </mat-error>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.generateBarcode.touched) && f.generateBarcode.errors?.required">
                    Barcode/SKU is required!
                </mat-error>
                <mat-error *ngIf="f.generateBarcode.errors?.maxlength">
                    Barcode/SKU must be 13 digit.
                </mat-error>
                <mat-error *ngIf="f.generateBarcode.errors?.minlength">
                    Barcode/SKU must be 13 digit.
                </mat-error>
            </div>

            <!-- Category -->
            <div style="font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Category<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl disableOptionCentering required formControlName="category"
                        placeholder="Category" [ngClass]="{
                            'is-invalid': (f.category.invalid && f.category.touched) && submitted }">
                        <mat-option *ngFor="let item of productCategory"
                            [value]="item.id">{{item.categoryName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.category.touched)">
                    <mat-error *ngIf="f.category.errors?.required">
                        Category is required!
                    </mat-error>
                </mat-error>
            </div>
        </div>

        <div class="row mb-3">


            <!-- Price -->
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">{{ lang.SalePriceInc }}<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" matNativeControl required matInput placeholder="{{ lang.SalePriceInc }}"
                        formControlName="price" [ngClass]="{
                            'is-invalid': (f.price.invalid && f.price.touched) && submitted }"
                        (keypress)="numberOnly($event)" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.price.touched)">
                    <mat-error *ngIf="f.price.errors?.required">
                        {{ lang.SalePriceInc }} is required!
                    </mat-error>
                    <mat-error *ngIf="f.price.errors?.pattern">
                        {{ lang.SalePriceInc }} Only number allowed!
                    </mat-error>
                </mat-error>
            </div>

            <!-- GST Rate(%) -->
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">{{lang.GSTRate}}<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl min="0" required matInput placeholder="{{lang.GSTRate}}" type="text"
                        formControlName="gstRate" [ngClass]="{
                            'is-invalid': (f.gstRate.invalid && f.gstRate.touched) && submitted }"
                        (keypress)="numberDecimalOnly($event)" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.gstRate.touched)">
                    <mat-error *ngIf="f.gstRate.errors?.required">
                        {{lang.GSTRate}} is required!
                    </mat-error>
                    <mat-error *ngIf="f.gstRate.errors?.pattern">
                        {{lang.GSTRate}} Only number allowed!
                    </mat-error>
                </mat-error>
            </div>
        </div>

        <div class="row mb-3">
            <!-- Service Description -->
            <div class="col-sm-12 col-md-12 col-lg-12">
                <label class="form-label">Service Description<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput rows="3" required placeholder="Service Description" formControlName="description"
                        [ngClass]="{
                            'is-invalid': (f.description.invalid && f.description.touched) && submitted }"
                        autocomplete="off"></textarea>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.description.touched)">
                    <mat-error *ngIf="f.description.errors?.required">
                        Service Description is required!
                    </mat-error>
                </mat-error>


            </div>
        </div>


        <div class="row mb-3">
            <!-- As on Date -->

            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Opening Stock</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="number" matNativeControl matInput placeholder="Openeing Stock"
                        formControlName="openingStock" autocomplete="off">
                </mat-form-field>
            </div>

            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">As on Date<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput required ngModel bsDatepicker [(ngModel)]="todaysDate" [minDate]="todaysDate"
                        style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                        containerClass: 'theme-dark-blue',
                        dateInputFormat: 'DD MMM YYYY',
                        showWeekNumbers:false
                    }" formControlName="asOfDate" (click)="openDatepicker()">
                    <span>
                        <mat-icon class="iconcalender">date_range</mat-icon>
                    </span>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-12 col-xl-12">
                <p class="d-md-inline-block fs--1 ml-5" style="font-weight:500;font-size:20px!important;margin-left: 5px;">
                    Add Service Image
                </p>
            </div>

        </div>

        <div class="row mt-2">
            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 flex d-flex flex-row  mb-sm-2" style="justify-content: center;">
                <button  *ngIf="imageSrcSeven" class="delete-button"  type="button" style = "position: relative;top: -16px;left: 205px;" (click) = "cancelCoverImage()">
                  <i class="bi bi-x bi-sm" style="color:red"></i>
                </button>
                <label class="uploader2" style="width: 200px !important;height:200px !important;"
                    ondragover="return false;" [class.loaded]="loaded"
                    [style.outlineColor]="dragging ? activeColor : baseColor" (dragenter)="handleCoverDragEnter()"
                    (dragleave)="handleCoverDragLeave()" (drop)="handleCoverDrop($event)">

                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        <i class="bi bi-plus-lg  fs-3" *ngIf="!imageSrcSeven" [style.color]="dragging
                        ? ((imageSrcSeven.length > 0) ? overlayColor : activeColor)
                        : ((imageSrcSeven.length > 0) ? overlayColor : baseColor)">
                        </i>

                        <span  *ngIf="!imageSrcSeven" class="mt-2" style="font-size: 12px; font-weight:500;line-height: 21;">Add Service Cover
                            Image</span>
                        <span  *ngIf="!imageSrcSeven" class="mt-2 text-muted" style="text-align: center; font-size: 12px;line-height: 21;">Drop
                            an Image or <span style="color: #1BB2F2;">Browsing images</span> it from your
                            computer</span>

                    </div>


                    <img [src]="imageSrcSeven" width="200" height="200" (load)="handleCoverImageLoad()"
                        [class.loaded]="imageLoaded" />

                    <input type="file" name="file" accept="image/*" (change)="coverImgChange($event)">
                </label>
            </div>
            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6flex d-flex flex-row" style="justify-content: center;">
                <label class="uploader2" style="width: 200px !important;height:200px !important;">

                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        <i class="bi bi-plus-lg  fs-3"></i>

                        <span class="mt-2" style="font-size: 12px; font-weight:500;line-height: 21;">Add Service
                            Image</span>
                        <span class="mt-2 text-muted" style="text-align: center; font-size: 12px;line-height: 21;">Drop
                            an Image or <span style="color: #1BB2F2;">Browsing images</span> it from your
                            computer</span>


                    </div>

                    <input type="file" name="file" accept="image/*" style="display:none;" [(ngModel)]="currentInput"
                        [ngModelOptions]="{ standalone: true }" multiple (change)="onFileSelected($event)" />
                </label>

            </div>

        </div>

        <div class="row mt-3">
            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 mb-4 mt-2 flex d-flex flex-row" *ngFor="let preview of previews;let i=index;"
                style="justify-content: center;">
                <!-- <i class="bi bi-x-circle text-danger fs-1 fw-bold" (click)="cancelmultiImage(i)"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="delete image"></i> -->
                <button class="delete-button" type="button" (click)="cancelmultiImage(i)">
                    <i class="bi bi-x bi-sm" style="color:red"></i>
                </button>
                <img [src]="preview" class="preview" height="200" width="200px" />
            </div>
        </div>

    </form>



</div>

