import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private productAddedSource = new Subject<void>();
  productAdded$ = this.productAddedSource.asObservable();

  notifyProductAdded() {
    this.productAddedSource.next();
  }

  constructor(public httpClient: HttpService, private httpclient: HttpClient) { }
  postGoods(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.addGoods, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  postService(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.addService, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  autoGenerateBarcode(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.autoGenerateBarcode + userId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  postBulkProduct(data: {}, id) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postBulkProduct + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getGoodsProductoffer(data: {}, userId: string, Type: String): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllProductsoffer + userId + "?productType=" + Type, 'GET').subscribe(
        //this.httpClient.inventory(data, UrlConstants.getAllProductsByUserTYpe + userId ,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }  
  getGoodsProductByUser_Type(data: {}, userId: string, Type: String, filter, size, page, warehouseId?): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllProductsByUserTYpe + userId + "?productType=" + Type + "&filter=" + filter + "&size=" + size + "&page=" + page + '&warehouseId=' + (warehouseId || ''), 'GET').subscribe(
        //this.httpClient.inventory(data, UrlConstants.getAllProductsByUserTYpe + userId ,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getServiceByUser_Type(data: {}, userId: string, Type: String, filter, size, page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllServiceByUserTYpe + userId + "?productType=" + Type + "&filter=" + filter + "&size=" + size + "&page=" + page , 'GET').subscribe(
        //this.httpClient.inventory(data, UrlConstants.getAllProductsByUserTYpe + userId ,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getGoodsService_TypeSearch(data: {}, userId: string, Type: String, filter, search: string): any {

    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllServiceByUserTYpe + userId + "?productType=" + Type + "&filter=" + filter + "&search=" + search, 'GET').subscribe(
        // this.httpClient.inventory(data, UrlConstants.getAllProductsByUserTYpe + userId ,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  
  // getGoodsService_TypeSearch(data: {}, userId: string, Type: String, filter, search: string): any {

  //   return new Promise((resolve, reject) => {
  //     this.httpClient.inventory(data, UrlConstants.getAllServiceByUserTYpe + userId + "?productType=" + Type + "&filter=" + filter + "&search=" + search, 'GET').subscribe(
  //       // this.httpClient.inventory(data, UrlConstants.getAllProductsByUserTYpe + userId ,  'GET').subscribe(
  //       (res) => resolve(res),
  //       (err) => reject(err)
  //     );
  //   });
  // }
  getGoodsProductByUser_TypeSearch(data: {}, userId: string, Type: String, filter, search: string): any {

    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllProductsByUserTYpe + userId + "?productType=" + Type + "&filter=" + filter + "&search=" + search, 'GET').subscribe(
        // this.httpClient.inventory(data, UrlConstants.getAllProductsByUserTYpe + userId ,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getServiceByUser(data: {}, userId: string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllServicesByUser + userId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  updateProduct(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.updateProduct + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  serviceUpdate(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.serviceUpdate + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  deleteProductById(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.deleteProductById + id, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err),
      );
    });
  }
  deletemultiProductById(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.deletemultiProductById + id, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err),
      );
    });
  }

  deletemultiProductByIdunarchive(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.deletemultiProductByIdunarchive + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err),
      );
    });
  }

  deleteServiceById(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.deleteServiceById + id, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getGoodsById(data: {}, id: string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getGoodsById + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getServiceById(data: {}, id: string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getServiceById + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  postProductImage(data: {}, prodId: string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.productImage + prodId, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  //for new update images upload Product-update
  postProductImage1(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.productImage1, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  postCoverImage(data: {}, prodId: string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.coverImage + prodId, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  //update new api image in Product
  postCoverImage1(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.coverImage1, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  //update new api image in Product
  PostImagePdf(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.uploadDoc, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getProductImage(data: {}, prodId: string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getProductImg + prodId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getLowStockWarning(data: {}, userId: string, size, page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getLowStock + userId + "?size=" + size + "&page=" + page, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getProductRepolist(data: {}, userId: string, size, page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getProductReport + userId + "?size=" + size + "&page=" + page, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getItemBYProductname(data: {}, userId: string, size, page, productName): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getProductReport + userId + "?size=" + size + "&page=" + page + "&itemName=" + productName, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }



  getproductCategory(data: {}) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.productCategoryGet, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getReportStockSummry(data: {}, userId: string, size, page, startDate, endDate) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.stockSummaryGet + userId + "?size=" + size + "&page=" + page + "&startDate=" + startDate + "&endDate=" + endDate, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getproductItem(data: {}, userId: string, size, page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.productItem + userId + "?size=" + size + "&page=" + page, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getproductDetails(data: {}, userId: string, productId: string, startDate, endDate): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.productDetails + userId + "?productId=" + productId + "&startDate=" + startDate + "&endDate=" + endDate, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getproductItemReport(data: {}, userId: string, startDate, endDate): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.productItemReport + userId + "?startDate=" + startDate + "&endDate=" + endDate, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getproductItemReportByPartyId(data: {}, userId: string, startDate, endDate, partyId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.productItemReport + userId + "?startDate=" + startDate + "&endDate=" + endDate + "&partyId=" + partyId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getproductItemProfitLossByDate(data: {}, userId: string, startDate, endDate) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.productprofitLossDate + userId + "?startDate=" + startDate + "&endDate=" + endDate, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getproductSrockDetailsByDate(data: {}, userId: string, startDate, endDate) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.StockDetailsDate + userId + "?startDate=" + startDate + "&endDate=" + endDate, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getCategoriesList(data: {}, userId: string, size, page, search: string) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllCategoriesByUserSearch + userId + "?size=" + size + "&page=" + page + "&category=" + search, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      )
    })
  }
  UnarchivedProduct(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.productUnarchived + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteproductImgId(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.deleteproductImage + id, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  deleteproductCoverImgId(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.deleteCoverPiProduct + userId, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  downloadProduct(data: {}) {
    return this.httpclient.request("GET", "https://web.iceipts.com/api/inventory/" + 'products/downloadPorducts', {
      body: data,
      responseType: "blob",
    })
  }

  //product setting api services
  postBrand(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.brands, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  updateBrand(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.brands, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteBrand(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.brands + '/' +  userId, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  chnageBrandStatus(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.brand_chnageStatus + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getBrandList({data, userId, size, page, search,isActive}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.brands  + "?userId=" + userId + "&size=" + (size || 1000) + "&page=" + (page || 1) + "&search=" + (search || '') + "&isActive=" + (isActive || ''), 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    }); 
  }

  postCategory(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.category, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteCategory(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.category + '/' + userId, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  categoryChangeStatus(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.category_changeStatus + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getCategory({data, userId, size, page, search, isActive}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.category + "?userId=" + userId + "&size=" + (size || 1000) + "&page=" + (page || 1) + "&search=" + (search || '') + "&isActive=" + (isActive || ''), 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getBrandListById(data: {}, userId, brandId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.brand_getById + userId + "/" + brandId , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  postSubCategory(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.subCategory, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteSubCategory(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.subCategory + '/' + userId, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  subCategoryChangeStatus(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.subCategory_changeStatus + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getSubCategory({data, userId, size, page, search, categoryId, isActive}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.subCategory + "?userId=" + userId + "&size=" + (size || 1000) + "&page=" + (page || 1) + "&categoryId=" + (categoryId ||'') + "&search=" + (search || '') + "&isActive=" + (isActive ||''), 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getSubCategoryById(data: {}, userId, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.subCategory_getById + userId + '/' + id  , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getCategoryById(data: {}, userId, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.category_getById + userId + '/' + id  , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getCustomFields({data, userId, size, page, search, isActive}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.customeField  + "?userId=" + userId + "&size=" + (size || 1000) + "&page=" + (page || 1) + "&search=" + (search || '') + "&isActive=" + (isActive ||''), 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }



  postCustomFields(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.customeField, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  putCustomFields(data: {}, fieldId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, `${UrlConstants.customeField}/changeStatus/${fieldId}`, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteCustomFields(data: {}, userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, `${UrlConstants.customeField}/${userId}`, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  // getCustomFieldDetails(data: {}, userId): any {
  //   return new Promise((resolve, reject) => {
  //     this.httpClient.inventory(data, UrlConstants.customeField_Details + `/${userId}`, 'GET').subscribe(
  //       (res) => resolve(res),
  //       (err) => reject(err)
  //     );
  //   });
  // }

  // postCustomFieldDetails(data: {}): any {
  //   return new Promise((resolve, reject) => {
  //     this.httpClient.inventory(data, UrlConstants.customeField_Details, 'POST').subscribe(
  //       (res) => resolve(res),
  //       (err) => reject(err)
  //     );
  //   });
  // }

  // putCustomFieldDetails(data: {}, userId): any {
  //   return new Promise((resolve, reject) => {
  //     this.httpClient.inventory(data, UrlConstants.customeField_Details + `/${userId}`, 'PUT').subscribe(
  //       (res) => resolve(res),
  //       (err) => reject(err)
  //     );
  //   });
  // }

}

