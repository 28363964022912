import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { UrlConstants } from '../../_helpers/url-constants';

@Injectable({
  providedIn: 'root'
})
export class SundryCreditorService {

  constructor(public httpClient:HttpService) { }
  sundryCredit(data:{},userId:string,receiptsType:string,size,page):any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getcredit +userId+ "?receiptsType=" +receiptsType +"&size=" + size +"&page=" +page ,'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });

  }
  
  sundryDebitFilter(data:{},userId:string,amountdebit1,amountdebit2,gstNo,size,page ,receiptsType,noOfDay):any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getcredit +userId+ "?amountDebit1=" +amountdebit1+ "&amountDebit2=" +amountdebit2+ "&gstNo=" +gstNo+ "&size="  +size+ "&page=" +page+ "&receiptsType=" +receiptsType+"&noDays=" +noOfDay,'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  sundryCreditFilter(data:{},userId:string,gstNo,size,page,receiptsType,amountCredit1,amountcredit2,noOfDay):any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getcredit +userId+"?gstNo="+gstNo+"&size="+size+"&page="+page+ "&receiptsType="+receiptsType+"&amountcredit1=" +amountCredit1+ "&amountcredit2=" +amountcredit2+"&noDays=" +noOfDay,'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

}
