import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/_helpers/constant';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  public defaultGalleryImg = Constants.defaultGalleryImages;
  constructor() { }

  ngOnInit(): void {
    // this.defaultGalleryImg = 
    console.log(this.defaultGalleryImg);
  }

}
