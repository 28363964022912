import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { debounceTime } from 'rxjs/operators';
import { AllUsersService } from 'src/app/core/services/all-users.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss']
})
export class EmployeeDetailsComponent implements OnInit {
  public roleDetails: any = [];
  public userData: any = [];
  public empId: any;
  public data: any;
  public fullName: any;
  public roleName: any;
  public ViewValue;
  public selectedClient: any
  public roleList;
  //public RolesUpdateForm: any;
  public submitted: boolean;
  public usersForm: FormGroup;
  public finalObject;
  RoleNo: any;
  Roleid: any;
  num: any = 3;
  today: Date;
  maxDate: Date;

  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public dragging: boolean = false;
  public loaded: boolean = false;
  public imageLoaded: boolean = false;
  public activeColor: string = 'green';
  public baseColor: string = '#ccc';
  public profilePic: any = '';
  public profilePicUrl: any = '';
  pinDetails: any;
  isProfilePicSelects: boolean = false;
  employeeId: any;
  assets: any[] = [
    { id: 1, name: 'Laptop', selected: false },
    { id: 2, name: 'Mobile', selected: false },
    { id: 3, name: 'headphones', selected: false },
  ];

  constructor(public allUsersService: AllUsersService,
    public fb: FormBuilder, public activeModal: NgbActiveModal, @Inject(MAT_DIALOG_DATA) public modalData: any,
    public dialogRef: MatDialogRef<EmployeeDetailsComponent>,
    public rolesPermissionsService: RolesPermissionsService,
    public router: Router, public profileService: ProfileService,
    public route: ActivatedRoute, private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,) {

    this.usersForm = this.fb.group({
      firstName: ["", [Validators.required, this.alphabeticOnlyValidator()]],
      lastName: [""],
      email: ["", [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"),Validators.email]],
      address: [null],
      roleAssign: ["", [Validators.required, this.alphabeticOnlyValidator()]],
      designation: ["", [Validators.required, this.alphabeticOnlyValidator()]],
      joiningDate: ["", Validators.required],
      resignationDate: [null],
      salary: ["", Validators.required],
      department: [null, [this.alphabeticOnlyValidator()]],
      asset: ['',],
      gender: ['',],
      mobile: [null, [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      homeno: [null],
      pincode: [null],
      city: [null],
      district: [null],
      landmark: [null],
      state: [null],
      country: ["India"],
    });
    this.usersForm.get('pincode').valueChanges
      .pipe(debounceTime(500)) // Add a debounce time to avoid making API calls on every keystroke
      .subscribe((pincode) => {
        this.getPincodeDetails(pincode);
      });
  }

  ngOnInit(): void {
    this.today = new Date();
    this.empId = this.modalData.itemId
    this.getRoleList();
    this.getRole();
  }

  getPincodeDetails(pin) {
    this.rolesPermissionsService.getEmployeePincode({}, pin).then((res) => {
      this.pinDetails = res;
      this.usersForm.patchValue({
        state: res.data.stateName,
        city: res.data.districtName
      });
    });
  }

  alphabeticOnlyValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const alphabeticOnlyRegex = /^[a-zA-Z\s]+$/;
      const value = control.value;
      if (value && !alphabeticOnlyRegex.test(value)) {
        return { alphabeticOnly: true };
      }
      return null;
    };
  }

  getRoleList() {
    // this.rolesPermissionsService.getAllUsersRole({}, this.empId).then((res) =>     // old API call
    this.rolesPermissionsService.getEmployeeById({}, this.empId).then((res) => {
      console.log(res.data)
      this.fullName = res.data.fullName
      this.roleName = res.data.employee.roleAssign;
      this.profilePic = res.data.profileImage

      this.employeeId = res.data.employee.userId

      this.usersForm.patchValue({
        firstName: res.data.firstName,
        email: res.data.email,
        mobile: res.data.mobile ? parseInt(res.data.mobile, 10):null,
        profilePic: this.profilePic ? this.profilePic : null,
        designation: res.data.employee.designation,
        joiningDate: res.data.employee.joiningDate ? new Date(res.data.employee.joiningDate) : null,
        resignationDate: res.data.employee.resignationDate ? new Date(res.data.employee.resignationDate) : null,
        department: res.data.employee.department,
        asset: res.data.employee.assets,
        gender: res.data.employee.gender,
        roleAssign: res.data.employee.roleAssign,
        salary: parseInt(res.data.employee.salary, 10),
        address: res.data.useraddresses[0]?.address ? res.data.useraddresses[0]?.address : null,
        city: res.data.useraddresses[0]?.city ? res.data.useraddresses[0]?.city : null,
        country: res.data.useraddresses[0]?.country ? res.data.useraddresses[0]?.country : null,
        district: res.data.useraddresses[0]?.district ? res.data.useraddresses[0]?.district : null,
        homeno: res.data.useraddresses[0]?.homeno ? res.data.useraddresses[0]?.homeno : null,
        landmark: res.data.useraddresses[0]?.landmark ? res.data.useraddresses[0]?.landmark : null,
        pincode: res.data.useraddresses[0]?.pincode ? res.data.useraddresses[0]?.pincode : null,
        state: res.data.useraddresses[0]?.state ? res.data.useraddresses[0]?.state : null,
      })
      console.log(this.usersForm)
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    })
  }
  setValue(value) {
    this.ViewValue = value
  }
  assignRole() {
    let data = {
      roles: []
    }

    this.selectedClient.map((e) => {
      data.roles.push({
        roleId: e
      })
    })
    this.rolesPermissionsService.postAssignRole(this.ViewValue, data).then((res) => {
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Role Updated Successfully!!!",
        });
        // this.toastService.openSnackBar("Role updated Successfully")
        this.getRoleList();
      }
    },

      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }

      })

  }
  public getRole() {
    this.rolesPermissionsService.getRoles({}, this.currentUser.id).then((response => {
      this.roleList = response.data;
    }))
  }
  get f() {
    return this.usersForm.controls;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  UpdateUsersWithRoles() {
    this.submitted = true;
    console.log(this.usersForm.value.gender, "UpdateUsersWithRoles", this.usersForm)
    if (this.usersForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
    } else {

      // Conditionally include profileImage if profilePicUrl is not null or empty
      if (this.isProfilePicSelects) {

        let userBody = {
          basicdetails: {
            employeeId: this.empId,
            firstName: this.usersForm.value.firstName,
            email: this.usersForm.value.email.toLowerCase(),
            mobile: this.usersForm.value.mobile ? this.usersForm.value.mobile:null,
            profileImage: this.profilePicUrl,
          },
          additional: {
            roleAssign: this.usersForm.value.roleAssign,
            designation: this.usersForm.value.designation,
            joiningDate: this.usersForm.value.joiningDate ? new Date(moment(this.usersForm.value.joiningDate).format("DD-MMM-YYYY")) : null,
            resignationDate: this.usersForm.value.resignationDate ? new Date(moment(this.usersForm.value.resignationDate).format("DD-MMM-YYYY")) : null,
            salary: parseInt(this.usersForm.value.salary, 10),
            assets: this.usersForm.value.asset,
            department: this.usersForm.value.department,
            gender: this.usersForm.value.gender,
          },
          address: {
            isPrimary: true,
            homeno: this.usersForm.value?.homeno ? this.usersForm.value?.homeno : null,
            pincode: this.usersForm.value?.pincode ? this.usersForm.value?.pincode : null,
            address: this.usersForm.value?.address ? this.usersForm.value?.address : null,
            city: this.usersForm.value?.city ? this.usersForm.value?.city : null,
            district: this.usersForm.value?.district ? this.usersForm.value?.district : null,
            landmark: this.usersForm.value?.landmark ? this.usersForm.value?.landmark : null,
            state: this.usersForm.value?.state ? this.usersForm.value?.state : null,
            country: this.usersForm.value?.country ? this.usersForm.value?.country : null
          }
        }
        this.makeApiCall(userBody)
      } else {
        let userBody = {

          basicdetails: {
            employeeId: this.empId,
            firstName: this.usersForm.value.firstName,
            email: this.usersForm.value.email.toLowerCase(),
            mobile: this.usersForm.value.mobile ? this.usersForm.value.mobile:null,

          },
          additional: {
            roleAssign: this.usersForm.value.roleAssign,
            designation: this.usersForm.value.designation,
            joiningDate: this.usersForm.value.joiningDate ? new Date(moment(this.usersForm.value.joiningDate).format("DD-MMM-YYYY")) : null,
            resignationDate: this.usersForm.value.resignationDate ? new Date(moment(this.usersForm.value.resignationDate).format("DD-MMM-YYYY")) : null,
            salary: parseInt(this.usersForm.value.salary, 10),
            assets: this.usersForm.value.asset,
            department: this.usersForm.value.department,
            gender: this.usersForm.value.gender,
          },
          address: {
            isPrimary: true,
            homeno: this.usersForm.value?.homeno ? this.usersForm.value?.homeno : null,
            pincode: this.usersForm.value?.pincode ? this.usersForm.value?.pincode : null,
            address: this.usersForm.value?.address ? this.usersForm.value?.address : null,
            city: this.usersForm.value?.city ? this.usersForm.value?.city : null,
            district: this.usersForm.value?.district ? this.usersForm.value?.district : null,
            landmark: this.usersForm.value?.landmark ? this.usersForm.value?.landmark : null,
            state: this.usersForm.value?.state ? this.usersForm.value?.state : null,
            country: this.usersForm.value?.country ? this.usersForm.value?.country : null
          }
        }
        this.makeApiCall(userBody)
      }
    }
  }

  makeApiCall(userBody) {
    this.rolesPermissionsService.UpdateUsers(userBody, this.currentUser.id).then((res) => {
      if (res.success) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Employee Details Updated Successfully!!!",
        });
        // this.toastService.openSnackBar("Employee Details Update Successfully!!!");
        this.rolesPermissionsService.notifyUserAdded();   // this service will be called for update the user list
        this.router.navigate(['/pages/employee-page/allEmployees'])
        this.dialogRef.close();
      }
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }

      })
  }


  removeRoles(_RoleNo: any) {
    this.RoleNo = _RoleNo;

  }

  deleteRole() {
    let body = {
      "removeRoleId": this.RoleNo,
    }
    //console.log(body)
    this.rolesPermissionsService.deleteRole(body, this.empId).then((res) => {
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Employee Role Deleted Successfully!!!",
        });
        // this.toastService.openSnackBar("Employee Role Deleted Successfully!!!");
        this.getRoleList();
        this.getRole();
      }
    })
  }

  cancel() {
    // Reset the form and submitted flag
    this.usersForm.reset();
    this.submitted = false;
    this.dialogRef.close();

  }

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  async handleInputChange(e) {
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file.type.match(pattern)) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Invalid Format. Please Select An Image File.",
      });
      // this.toastService.openErrorSnackBar("Invalid format. Please select an image file.");
      return;
    }
    const fileSizeLimit = 1 * 1024 * 1024; // 6 MB
    if (file.size > fileSizeLimit) {
      alert("File size exceeds the limit. Please select a smaller image (up to 1 MB).");
      return;
    }
    this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.profilePic = reader.result;
      };
      if (file == null) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Select Image To Upload.",
        });
        // this.toastService.openErrorSnackBar("Please Select Image To Upload.");
      } else {
        formdata.set("upload", file);
        e.srcElement.value = "";
        this.rolesPermissionsService
          .uploadImage(formdata)
          .then((res) => {
            this.profilePicUrl = res.data.location
            this.isProfilePicSelects = true
            this.toastService.toastMsg({
              title: "Success",
              content: "Image Uploaded Successfully!!!",
            });
            // this.toastService.openSnackBar("Image Uploaded Successfully");
          });
        this.loaded = true;
      }
    }
  }

  deleteProfilePic() {

    this.profileService.deleteProfileImg({}, this.employeeId)
      .then((res) => {
        console.log(res)
        this.toastService.toastMsg({
          title: "Success",
          content: "Image deleted successfully!!!",
        });
        // this.toastService.openSnackBar("Image deleted successfully");

        this.profilePic = '';
        this.profilePicUrl = null; // Clear the profilePic value
        this.isProfilePicSelects = false
        this.loaded = true;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  openDatepickerJoinDate() {
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.usersForm.get('joiningDate').value;
    if (isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.usersForm.get('joiningDate').setValue(new Date());
    }

  }
  openDatepickeresignDate() {
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.usersForm.get('resignationDate').value;
    if (isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.usersForm.get('resignationDate').setValue(new Date());
    }

  }

}
