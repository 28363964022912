<div class="container-fluid p-0">

  <!-- <div class="row d-flex flex-center mb-3">
    <div class="col-md-1 col-lg-5 col-xl-6"></div>
    <div class="col-sm-4 col-md-3 col-lg-3 col-xl-3 mt-2">
      <input type="search" id="searchInput" class="form-control pe-5" placeholder="Service Name" [(ngModel)]="searchKey"
        (change)="getGoodsByBarcodeservice(searchKey)">
    </div>
    <div class="navOption-btns col-sm-8 col-md-8 col-lg-4 col-xl-3 mt-2"
      style="display:flex;justify-content: space-around !important;align-items: center;">
      <button class="btn text-nowrap" type="button" (click)="openDialogservice()"><i class="fas fa-plus me-2"> </i>Add
        Service
      </button>

      <button class="btn text-nowrap" (click)="startUpload()">
        <i class="fas fa-upload"></i> Import
      </button>

      <button class="btn text-nowrap"
        onclick="location.href = 'https://web.iceipts.com/api/inventory/products/downloadPorducts';"><i
          class="fas fa-download"></i></button>
      <input class="inputhide" type="file" name="file" id="uploadFile" accept=".xls,.xlsx"
        (change)="handleInputChange($event)" />
    </div>
  </div> -->
  <div class="row headerButtons mb-3" id="showoptionHide">
    <div class="flex-item searchBar">
      <input type="search" id="searchInput" class="form-control pe-5 my-2" placeholder="Service Name/Item Barcode/SKU"
        [(ngModel)]="searchKey" (change)="getGoodsByBarcodeservice(searchKey)">
    </div>
    <div class="navOption-btns flex-item formButtons mx-0">
      <button id="saleButtonPrint1" class="btn text-nowrap my-2" type="button" (click)="openDialogservice()">
        <i class="fas fa-plus me-2"> </i>Add Service
      </button>
      <button id="saleButtonPrint1" class="btn text-nowrap my-2" type="button" (click)="startUpload()">
        <i class="fas fa-upload"></i> Import
      </button>
      <button id="saleButtonPrint1" class="btn text-nowrap my-2" type="button" (click)="downloadServices()"><i
          class="fas fa-download"></i></button>
      <input class="inputhide" type="file" name="file" id="uploadFile" accept=".xls,.xlsx"
        (change)="handleInputChange($event)" />
    </div>
  </div>

  <div class="tableoverflow" style="width: 100%;overflow-x: auto;" *ngIf="!isArabic">
    <table class="table table-responsive table-bordered fs--1 mb-0">
      <thead class="text-600">
        <tr>
          <th class="text-nowrap" style="vertical-align: middle;width:100px;height:50px;display:flex;align-items: center;">
            <input type="checkbox" class="form-check-input" (change)="onChekMultiple($event)" [value]="-1"
              [checked]="selectedPartyIds.length === serviceData.length" [(ngModel)]="allSelect" />
            <div class="d-flex align-items-center">
              <span *ngIf="showDeleteButton">
                <button style="padding-top: 15px;" *ngIf="checkSingleArray.length > 0 || isChecked"
                  class="btn" data-bs-toggle="modal" data-bs-target="#errorArchiveSelect-modal">
                  <span *ngIf="checkSingleArray.length > 0 || isChecked"
                    class="material-icons icon-color iconFontSize" style="color: orange;" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="Archive"
                    (click)=" upiCheckedId.length > 0? getUPIcheckId(upiCheckedId):getSingleCheckedId(checkSingleArray)">archive</span>
                </button>
              </span>
              <span *ngIf="showUnarchiveButton">
                <button style="padding-top: 15px;"
                  *ngIf="checkSingleArray.length > 0 ||  isChecked" class="btn"
                  data-bs-toggle="modal" data-bs-target="#errorUnarchiveSelect-modal">
                  <span *ngIf="checkSingleArray.length > 0 || isChecked"
                    class="material-icons icon-color iconFontSize" style="color: orange;" data-bs-toggle="tooltip"
                    (click)=" upiCheckedId.length > 0? getUPIcheckId(upiCheckedId):getSingleCheckedId(checkSingleArray)"
                    data-bs-placement="top" title="Unarchive">unarchive</span>
                </button>
              </span>
            </div>
          </th>
          <th class="text-nowrap" style="vertical-align:middle;">Service Name</th>
          <th class="text-nowrap" style="vertical-align:middle;">Item Barcode/SKU</th>
          <!-- <th class="text-nowrap" *ngIf="selectType=='Service'">
                                Item Code</th> -->
          <th class="text-nowrap" style="vertical-align:middle;">Category</th>
          <!-- <th class="text-nowrap">
                            Description</th> -->
          <th class="text-nowrap" style="vertical-align:middle;">In Stock</th>
          <th class="text-nowrap" style="vertical-align:middle;">GST Rate</th>
          <th class="text-nowrap" style="vertical-align:middle;text-align: right;">Sale Price</th>
          <!-- <th class="text-nowrap" *ngIf="selectType=='Product'||selected =='Product'">Unit
                        </th> -->
          <th class="text-nowrap" style = "width: 20%;"></th>
        </tr>
      </thead>

      <tbody>
        <tr class="btn-reveal-trigger text-left" *ngFor="let item of serviceData; let i = index"
          style="color: #676398 !important"
          [ngStyle]="{'background-color': item.isDeleted ? '#c7c2de' : (i % 2 === 0 ? '#f2f2f2' : '#ffffff')}">
          <!-- <td scope="row" style="vertical-align:middle;" *ngIf="item.isDeleted == false ">
            <input class="form-check-input" type="checkbox" [checked]="checkSelectsinglefalse" [ngClass]="{'custom-checkbox': !item.isDeleted, 'custom-checkbox deleted': item.isDeleted}"
              (change)="onChekedsingleCheckfalse($event,item.id,item.isDeleted)" [value]="item.id" />
          </td>
          <td scope="row" style="vertical-align:middle;" *ngIf="item.isDeleted == true ">
            <input class="form-check-input" type="checkbox" [checked]="checkSelectsingletrue" [ngClass]="{'custom-checkbox': !item.isDeleted, 'custom-checkbox deleted': item.isDeleted}"
              (change)="onChekedsingleChecktrue($event,item.id,item.isDeleted)" [value]="item.id" />
          </td> -->
          <td class="text-nowrap" style="vertical-align: middle;width:100px;">
            <input class="form-check-input" type="checkbox"
                [ngClass]="{'custom-checkbox': !item.isDeleted, 'custom-checkbox deleted': item.isDeleted}"
                [checked]="selectedPartyIds.includes(item.id)" (change)="onChekedsingleCheck($event, item.id)"
                [value]="item.id" />
          </td>
          <td class="ItemName text-nowrap" style="vertical-align: middle">
            {{ item.itemName }}
          </td>
          <td class="barcode text-nowrap" style="vertical-align: middle">
            {{ item.barcode }}
          </td>
          <td class="category text-nowrap" style="vertical-align: middle">
            {{
            item.category == null
            ? "-"
            : (item.category.categoryName | titlecase)
            }}
          </td>
          <!-- <td class="description" style="vertical-align: middle">
            {{ item.description }}
          </td> -->
          <td class="openingStock text-nowrap" style="vertical-align: middle">
            {{ item.stock }}
          </td>
          <td class="gstRate text-nowrap" style="vertical-align: middle;width: 50px !important;">
            {{ item.gstRate }}
          </td>
          <td class="price text-nowrap" style="vertical-align: middle;text-align: right;width:130px !important;">
            ₹&nbsp;{{ item.salePrice | number:'1.2-2'}}
          </td>
          <td style="vertical-align: middle; width: 20%; white-space: nowrap;text-align: end;">
            <div class="">
              <button [disabled]="item.isDeleted == true" class="btn btnp me-4 mt-1" type="button"
                (click)="viewService(item.id)">
                <span class="bi bi-eye icon-color iconFontSize" data-bs-toggle="tooltip" data-bs-placement="top"
                  title="View"></span>
              </button>

              <button [disabled]="item.isDeleted == true" class="btn btnp me-4"
                (click)="updateProductServiceId(item.id)">
                <span class="bi-pencil-square icon-color iconFontSize" data-bs-toggle="tooltip" data-bs-placement="top"
                  title="Edit"></span>
              </button>

              <button [disabled]="item.isDeleted == true" class="btn btnp mt-2 me-4" (click)="CloneService(item.id)">
                <span class="material-icons icon-color iconFontSize" data-bs-toggle="tooltip" data-bs-placement="top"
                  title="Clone">content_copy</span>
              </button>

              <button class="btn btnp me-4 mt-2" data-bs-toggle="modal" data-bs-target="#errorDelete-modal"
                style="color: #fc7643" (click)="GetServiceById(item.id, item.isDeleted)" [disabled]="this.selectedPartyIds.length != 0">
                <span class="material-icons icon-color iconFontSize" data-bs-toggle="tooltip" data-bs-placement="top"
                  [title]="item.isDeleted == true ? 'Unarchive' : 'Archive'">
                  {{ item.isDeleted ? "unarchive" : "archive" }}</span>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="goodsData == 0 && serviceData == 0">
        <tr class="text-center">
          <td style="width:100px;">&nbsp;</td>
          <td colspan="7">
            <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
              alt="notfound1" width="200" height="150" />
          </td>
        </tr>
      </tbody>
    </table>

    <mat-paginator class="float-right mt-2" *ngIf="serviceData.length > 0" [pageSize]="servicePageSize"
      [pageSizeOptions]="[5, 10, 20, 30]" [length]="totalrow" [pageIndex]="currentServicePageNo"
      showFirstLastButtons (page)="handleServicePage($event)" aria-label="Select page">
    </mat-paginator>
  </div>

  <div class="tableoverflow" style="width: 100%;overflow-x: auto;" *ngIf="isArabic">
    <table class="table table-responsive table-bordered fs--1 mb-0">
      <thead class="text-600">
        <tr>
          <th class="text-nowrap col-1" style="vertical-align:middle;">
            <input type="checkbox" class="form-check-input mt-2" (change)="onChekMultiple($event)" [value]="-1"
              [checked]="checkSelect" [(ngModel)]="allSelect" />

            <span *ngIf="archiveIconfalse">
              <button style="padding: 0px!important;" *ngIf="checkSingleArray.length > 0 || upiCheckedId.length > 0"
                class="btn ms-3" data-bs-toggle="modal" data-bs-target="#errorDeleteSelect-modal">
                <span *ngIf="checkSingleArray.length > 0 || upiCheckedId.length > 0"
                  class="material-icons icon-color fs-1" style="color: orange;" data-bs-toggle="tooltip"
                  data-bs-placement="top" title="Archive"
                  (click)=" upiCheckedId.length > 0? getUPIcheckId(upiCheckedId):getSingleCheckedId(checkSingleArray)">archive</span>
              </button>
            </span>
            <span *ngIf="archiveIcontrue">
              <button style="padding: 0px!important;"
                *ngIf="checkSingleArraytrue.length > 0 ||  upiCheckedId2.length > 0" class="btn ms-3"
                data-bs-toggle="modal" data-bs-target="#errorunarchieveSelect-modal">
                <span *ngIf="checkSingleArraytrue.length > 0 ||  upiCheckedId2.length > 0"
                  class="material-icons icon-color fs-1" style="color: orange;" data-bs-toggle="tooltip"
                  (click)=" upiCheckedId2.length > 0? getUPIcheckId(upiCheckedId):getSingleCheckedId(checkSingleArray)"
                  data-bs-placement="top" title="Unarchive">unarchive</span>
              </button>
            </span>
          </th>
          <th class="text-nowrap" style="vertical-align:middle;">Service Name</th>
          <th class="text-nowrap" style="vertical-align:middle;">Item Barcode/SKU</th>
          <!-- <th class="text-nowrap" *ngIf="selectType=='Service'">
                                Item Code</th> -->
          <th class="text-nowrap" style="vertical-align:middle;">Category</th>
          <!-- <th class="text-nowrap">
                            Description</th> -->
          <th class="text-nowrap" style="vertical-align:middle;">In Stock</th>
          <th class="text-nowrap" style="vertical-align:middle;">{{lang.GSTRate}}</th>
          <th class="text-nowrap" style="vertical-align:middle;text-align: right;">Sale Price</th>
          <!-- <th class="text-nowrap" *ngIf="selectType=='Product'||selected =='Product'">Unit
                        </th> -->
          <th class="text-nowrap"></th>
        </tr>
      </thead>

      <tbody>
        <tr class="btn-reveal-trigger text-left" *ngFor="let item of serviceData; let i = index"
          style="color: #676398 !important"
          [ngStyle]="{'background-color': item.isDeleted ? '#c7c2de' : (i % 2 === 0 ? '#f2f2f2' : '#ffffff')}">
          <td scope="row" style="vertical-align:middle;" *ngIf="item.isDeleted == false ">
            <input class="form-check-input" type="checkbox" [checked]="checkSelectsinglefalse" [ngClass]="{'custom-checkbox': !item.isDeleted, 'custom-checkbox deleted': item.isDeleted}"
              (change)="onChekedsingleCheckfalse($event,item.id,item.isDeleted)" [value]="item.id" />
          </td>
          <td scope="row" style="vertical-align:middle;" *ngIf="item.isDeleted == true ">
            <input class="form-check-input" type="checkbox" [checked]="checkSelectsingletrue" [ngClass]="{'custom-checkbox': !item.isDeleted, 'custom-checkbox deleted': item.isDeleted}"
              (change)="onChekedsingleChecktrue($event,item.id,item.isDeleted)" [value]="item.id" />
          </td>
          <td class="ItemName text-nowrap" style="vertical-align: middle">
            {{ item.itemName }}
          </td>
          <td class="barcode text-nowrap" style="vertical-align: middle">
            {{ item.barcode }}
          </td>
          <td class="category text-nowrap" style="vertical-align: middle">
            {{
            item.category == null
            ? "-"
            : (item.category.categoryName | titlecase)
            }}
          </td>
          <!-- <td class="description" style="vertical-align: middle">
            {{ item.description }}
          </td> -->
          <td class="openingStock text-nowrap" style="vertical-align: middle">
            {{ item.stock }}
          </td>
          <td class="gstRate text-nowrap" style="vertical-align: middle;width: 50px !important;">
            {{ item.gstRate }}
          </td>
          <td class="price text-nowrap" style="vertical-align: middle;text-align: right;width:130px !important;">
            AED&nbsp;{{ item.salePrice | number:'1.2-2'}}
          </td>
          <td style="vertical-align: middle; width: 20% !important; white-space: nowrap;text-align: center;">
            <div class="">
              <button [disabled]="item.isDeleted == true" class="btn btnp me-4 mt-1" type="button"
                (click)="viewService(item.id)">
                <span class="bi bi-eye icon-color iconFontSize" data-bs-toggle="tooltip" data-bs-placement="top"
                  title="View"></span>
              </button>

              <button [disabled]="item.isDeleted == true" class="btn btnp me-4"
                (click)="updateProductServiceId(item.id)">
                <span class="bi-pencil-square icon-color iconFontSize" data-bs-toggle="tooltip" data-bs-placement="top"
                  title="Edit"></span>
              </button>

              <button [disabled]="item.isDeleted == true" class="btn btnp mt-2 me-4" (click)="CloneService(item.id)">
                <span class="material-icons icon-color iconFontSize" data-bs-toggle="tooltip" data-bs-placement="top"
                  title="Clone">content_copy</span>
              </button>

              <button class="btn btnp mt-2 me-4" data-bs-toggle="modal" data-bs-target="#errorDelete-modal"
                style="color: #fc7643" (click)="GetServiceById(item.id, item.isDeleted)">
                <span class="material-icons icon-color iconFontSize" data-bs-toggle="tooltip" data-bs-placement="top"
                  [title]="item.isDeleted == true ? 'Unarchive' : 'Archive'">
                  {{ item.isDeleted ? "unarchive" : "archive" }}</span>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="goodsData == 0 && serviceData == 0">
        <tr class="text-center">
          <td colspan="10">
            <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
              alt="notfound1" width="200" height="150" />

            <!-- <h5 class="text-nowrap">
              No Service Details Added. Please Add Service Details To Be
              Displayed Here.
              <span>
                <a class="nav-link" (click)="openDialogservice()">Add New Service</a>
              </span>
            </h5> -->
          </td>
        </tr>
      </tbody>
    </table>

    <mat-paginator class="float-right mt-2" *ngIf="serviceData.length > 0" [pageSize]="servicePageSize"
      [pageSizeOptions]="[5, 10, 20, 30]" [length]="totalServiceRow" [pageIndex]="currentServicePageNo"
      showFirstLastButtons (page)="handleServicePage($event)" aria-label="Select page">
    </mat-paginator>
  </div>
  <!-- </div> -->
</div>

<div class="modal fade" id="errorDelete-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 350px">
    <div class="modal-content position-relative">
      <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
        <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
          aria-label="Close"></a>
      </div>
      <div class="modal-body p-0">
        <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
          <h2 *ngIf="!isDeletedservice" class="mb-1 text-center" style="font-weight: 500;font-family: 'Poppins';"
            id="modalExampleDemoLabel">
            Archive Service?
          </h2>
          <h2 *ngIf="isDeletedservice" class="mb-1 text-center" style="font-weight: 500;font-family: 'Poppins';"
            id="modalExampleDemoLabel">
            Unarchive Service?
          </h2>
        </div>
        <h4 class="text-center" style = "font-family: 'Poppins';" *ngIf="!isDeletedservice">
          Are You Sure To Archive Service?
        </h4>
        <h4 class="text-center" style = "font-family: 'Poppins';" *ngIf="isDeletedservice">
          Are You Sure To Unarchive Service?
        </h4>
      </div>
      <div class="modal-footer justify-content-center">
        <a class="btn btn-outline-success" data-bs-dismiss="modal">Close</a>
        <a *ngIf="!isDeletedservice" class="btn btn-outline-danger" (click)="deleteServiceId()"
          data-bs-dismiss="modal">Archive
        </a>
        <a class="btn btn-outline-warning" (click)="deleteServiceId()" *ngIf="isDeletedservice"
          data-bs-dismiss="modal">Unarchive
        </a>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="errorArchiveSelect-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 350px">
    <div class="modal-content position-relative">
      <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
        <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
          aria-label="Close"></a>
      </div>
      <div class="modal-body p-0">
        <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
          <h2 class="mb-1 text-center font-weight-bold" style="font-weight: 500;font-family: 'Poppins'; " id="modalExampleDemoLabel">
            Archive Service? </h2>
        </div>
        <h4 *ngIf="isChecked " class="text-center " style = "font-family: 'Poppins';">Are you sure to Archive All Service?</h4>
        <h4 *ngIf="!isChecked " class="text-center " style = "font-family: 'Poppins';">Are you sure to Archive selected Service?</h4>

      </div>
      <div class="modal-footer justify-content-center">
        <a class="btn btn-outline-success" data-bs-dismiss="modal">Close</a>
        <a *ngIf="isChecked " class="btn btn-outline-danger" data-bs-dismiss="modal"
          (click)="deleteProductById()">Archive
        </a>
        <a *ngIf="!isChecked" class="btn btn-outline-danger" data-bs-dismiss="modal"
          (click)="deleteProductById()">Archive
        </a>

      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="errorUnarchiveSelect-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 350px">
    <div class="modal-content position-relative">
      <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
        <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
          aria-label="Close"></a>
      </div>
      <div class="modal-body p-0">
        <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
          <h2 class="mb-1 text-center font-weight-bold" style="font-weight: 500;font-family: 'Poppins'; " id="modalExampleDemoLabel">
            Unarchive Service? </h2>
        </div>
        <h4 *ngIf="isChecked " class="text-center " style = "font-family: 'Poppins';">Are you sure to Unarchive All Service?</h4>
        <h4 *ngIf="!isChecked " class="text-center " style = "font-family: 'Poppins';">Are you sure to Unarchive selected Service?</h4>

      </div>
      <div class="modal-footer justify-content-center">
        <a class="btn btn-outline-success" data-bs-dismiss="modal">Close</a>
        <a *ngIf="isChecked" class="btn btn-outline-warning" data-bs-dismiss="modal"
          (click)="deleteProductByIdUnarchive()">Unarchive
        </a>
        <a *ngIf="!isChecked" class="btn btn-outline-warning" data-bs-dismiss="modal"
          (click)="deleteProductByIdUnarchive()">Unarchive
        </a>
      </div>
    </div>
  </div>
</div>

<!-- <div class="modal fade" id="errorDeleteSelect-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 350px">
    <div class="modal-content position-relative">
      <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
        <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
          aria-label="Close"></a>
      </div>
      <div class="modal-body p-0">
        <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
          <h2 class="mb-1 text-center font-weight-bold" style="font-weight: 500;font-family: 'Poppins'; " id="modalExampleDemoLabel">
            Archive Service? </h2>
        </div>
        <h4 *ngIf="isChecked " class="text-center " style = "font-family: 'Poppins';">Are you sure to Archive All Service?</h4>
        <h4 *ngIf="!isChecked " class="text-center " style = "font-family: 'Poppins';">Are you sure to Archive selected Service?</h4>

      </div>
      <div class="modal-footer justify-content-center">
        <a class="btn btn-outline-success" data-bs-dismiss="modal">Close</a>
        <a *ngIf="isChecked " class="btn btn-outline-danger" data-bs-dismiss="modal"
          (click)="deleteProductById()">Archive All
        </a>
        <a *ngIf="!isChecked" class="btn btn-outline-danger" data-bs-dismiss="modal"
          (click)="deleteProductById()">Archive Selected
        </a>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="errorunarchieveSelect-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 350px">
    <div class="modal-content position-relative">
      <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
        <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
          aria-label="Close"></a>
      </div>
      <div class="modal-body p-0">
        <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
          <h2 class="mb-1 text-center font-weight-bold" style="font-weight: 500;font-family: 'Poppins'; " id="modalExampleDemoLabel">
            Unarchive Service? </h2>
        </div>
        <h4 *ngIf="isChecked " class="text-center " style = "font-family: 'Poppins';">Are you sure to Unarchive All Service?</h4>
        <h4 *ngIf="!isChecked " class="text-center " style = "font-family: 'Poppins';">Are you sure to Unarchive selected Service?</h4>

      </div>
      <div class="modal-footer justify-content-center">
        <a class="btn btn-outline-success" data-bs-dismiss="modal">Close</a>
        <a *ngIf="isChecked" class="btn btn-outline-warning" data-bs-dismiss="modal"
          (click)="deleteProductByIdUnarchive()">Unarchive All
        </a>
        <a *ngIf="!isChecked" class="btn btn-outline-warning" data-bs-dismiss="modal"
          (click)="deleteProductByIdUnarchive()">Unarchive Selected
        </a>

      </div>
    </div>
  </div>
</div> -->
