import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, map, tap } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { InterceptorSkipHeader } from '../interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public CurrentUser = JSON.parse(this.localStorage.getItem("currentUser"));
  private readonly ACCESS_TOKEN = "ACCESS_TOKEN";
  private readonly REFRESH_TOKEN = "REFRESH_TOKEN";
  public AccessToken = this.localStorage.getItem(this.ACCESS_TOKEN);
  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject("LOCALSTORAGE") private localStorage: Storage
  ) { }

  login(data: {}) {
    const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
    return this.http
      .post<any>(environment.apiUrl + `users/clientLogin`, data, { headers })
      .pipe(
        map((res) => {
          console.log("auth service ---", res);
          const token = {
            accessToken: res.data.accessToken,
            refreshToken: res.data.refreshToken,
          };
          this.storeTokens(token);
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.localStorage.setItem(
            "currentUser",
            JSON.stringify({
              accessToken: res.data.accessToken,
              refreshToken: res.data.refreshToken,
              email: res.data.user.email,
              id: res.data.user.id,
              lastName: res.data.user.lastName,
              firstName: res.data.user.firstName,
              isSubscribed: res.data.user.isSubscribed,
              userType: res.data.user.userType,
              isActive: res.data.user.isActive,
              isTrial: res.data.user.isTrial,
              planExpiresOn: res.data.user.planExpiresOn,
              countryId: res.data.user.countryId,
              parentId: res.data.user.parentId,
            })
          );
          return res;
        })
      );
  }
  custLogin(data: {}) {
    return this.http
      .post<any>(environment.apiUrl + `users/customerLogin`, data)
      .pipe(
        map((res) => {
          const token = {
            accessToken: res.data.accessToken,
            refreshToken: res.data.refreshToken,
          };
          this.storeTokens(token);
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.localStorage.setItem(
            "currentUser",
            JSON.stringify({
              accessToken: res.data.accessToken,
              refreshToken: res.data.refreshToken,
              email: res.data.user.email,
              id: res.data.user.id,
              lastName: res.data.user.lastName,
              firstName: res.data.user.firstName,
              countryId: res.data.user.countryId,
            })
          );
          return true;
        })
      );
  }
  logout(): void {
    // clear token remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("clientId");
    localStorage.removeItem("ACCESS_TOKEN");
    localStorage.removeItem("Permissionn");
    localStorage.removeItem("Permission")
  }

  getCurrentUser(): any {
    // TODO: Enable after implementation
    return JSON.parse(this.localStorage.getItem("currentUser"));
  }
  isLoggedIn() {
    // Check if the user is authenticated
    // Return true if accessToken is available, false otherwise
    return this.getAccessToken() !== null;
  }

  getAccessToken() {
    return this.localStorage.getItem(this.ACCESS_TOKEN);
  }
  getRefreshToken() {
    return this.localStorage.getItem(this.REFRESH_TOKEN);
  }

  storeTokens(tokens: any) {
    localStorage.setItem(this.ACCESS_TOKEN, tokens.accessToken);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }
  refreshToken() {
    return this.http
      .post<any>(environment.apiUrl + `users/refreshToken`, {
        refreshToken: localStorage.getItem(this.REFRESH_TOKEN),
        userId: this.getCurrentUser().id,
      })
      .pipe(
        tap((tokens: any) => {
          localStorage.setItem(this.ACCESS_TOKEN, tokens.data.jwtToken);
          localStorage.setItem(this.REFRESH_TOKEN, tokens.data.refreshToken);
          console.log("inside refresh token");
        }),
        catchError((err) => {
          this.router.navigate(["/auth"]);
          return throwError(err);
        })
      );
  }
}
