
<div class="modal-header pt-0" id="Add-Party-modal">
  <h1 style="color: #EB8B3F; font-weight: bolder;margin-left: 5px;" class="modal-title mb-0">Purchase Order</h1>

  <div class="addProductButtons">

    <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel" (click)="cancel()">Cancel</button>

    <button type="button" class="btn btn-save" (click)="postPurchase()">Save</button>

  </div>

</div>

<div class="modal-body">
  <form [formGroup]="purchaseOrderForm" *ngIf="!isArabic">
    <div class="row">
      <div style="font-size: 14px" class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <label class="form-label" style="font-size: 14px !important">Vendor Name<span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 14px;" class="example-full-width" appearance="outline" style = "height: 48px !important;margin-bottom: 16px;">
          <input type="text" placeholder="Vendor Name" aria-label="Select Vendor Name" matInput
            (keyup)="searchPartyByName($event.target.value)" [formControl]="myControl" [matAutocomplete]="auto"
            [ngClass]="{
                  'is-invalid': (myControl.invalid && myControl.touched) && submitted }" required>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option>
              <a class="mobVerify" style="display: inline-block; width: 100%;" aria-hidden="true" title="Party"
                (click)="openAddPartyModal(addParty)">
                Add new Vendor
              </a>
            </mat-option>
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
              (onSelectionChange)="setSelectedPartyDeatails(option)">
              {{option.partyName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="
                  (submitted || myControl.touched) && myControl.errors?.required
                ">
          Vendor Is Required!
        </mat-error>


        <label class="form-label" style="font-size: 14px !important" for="Order Date">Order Date</label>
        <mat-form-field style="font-size: 14px !important" class="example-full-width" appearance="outline">
          <input matInput required placeholder="Order Date" id="Order Date" [outsideClick]="true" ngModel bsDatepicker
            [maxDate]="today" [(ngModel)]="todaysDate" style="text-transform:uppercase!important;"
            formControlName="orderDate"
            [bsConfig]="{ dateInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true, showWeekNumbers:false }"
            [ngClass]="{
            'is-invalid': (f.orderDate.invalid && f.orderDate.touched) && submitted }" (click)="openDatepickerOrderDate()">
          <span>
            <mat-icon class="iconcalender">date_range</mat-icon>
          </span>
        </mat-form-field>
        <!-- <mat-form-field  style="font-size: 14px !important" class="example-full-width" appearance="outline">
          <input matInput required ngModel bsDatepicker placeholder="DD MMM YYYY"
            style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                          containerClass: 'theme-dark-blue',
                          dateInputFormat: 'DD MMM YYYY',
                           showWeekNumbers:false
                          }" formControlName="orderDate" autocomplete="off" (click)="openDatepickerOrderDate()">
          <span>
            <mat-icon class="iconcalender p-1">date_range</mat-icon>
          </span>
        </mat-form-field> -->
        <mat-error class="text-danger" *ngIf="(submitted || f.orderDate.touched) && f.orderDate.errors?.required">
          Order Date Is Required!
        </mat-error>
      </div>

      <div style="font-size: 14px" class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <label class="form-label" style="font-size: 14px !important">Order #<span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 14px !important" class="example-full-width" appearance="outline" style = "height: 48px !important;margin-bottom: 16px;">
          <input matInput required type="text" placeholder="Order #" (keypress)="numberOnly($event)"
            formControlName="orderNo" autocomplete="off" [ngClass]="{
            'is-invalid': (f.orderNo.invalid && f.orderNo.touched) && submitted }">
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.orderNo.touched) && f.orderNo.errors?.required">
          Order # Is Required!
        </mat-error>

        <label class="form-label" style="font-size: 14px !important" for="Due Date">Due Date</label>
        <mat-form-field style="font-size: 14px !important" class="example-full-width" appearance="outline">
          <input matInput required placeholder="Due Date" id="Due Date" [outsideClick]="true" ngModel bsDatepicker
            formControlName="dueDate" [minDate]="today" [(ngModel)]="todaysDate2"
            style="text-transform:uppercase!important;"
            [bsConfig]="{ dateInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true, showWeekNumbers:false }"
            [ngClass]="{
              'is-invalid': (f.dueDate.invalid && f.dueDate.touched) && submitted }" (click)="openDatepickerDueDate()">
          <span>
            <mat-icon class="iconcalender">date_range</mat-icon>
          </span>
        </mat-form-field>
        <!-- <mat-form-field style="font-size: 14px !important" class="example-full-width" appearance="outline">
          <input matInput required ngModel bsDatepicker placeholder="DD MMM YYYY"
            style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                          containerClass: 'theme-dark-blue',
                          dateInputFormat: 'DD MMM YYYY',
                           showWeekNumbers:false
                          }" formControlName="dueDate" autocomplete="off" (click)="openDatepickerDueDate()">
          <span>
            <mat-icon class="iconcalender p-1">date_range</mat-icon>
          </span>
        </mat-form-field> -->
        <mat-error class="text-danger" *ngIf="(submitted || f.dueDate.touched) && f.dueDate.errors?.required">
          Due Date Is Required!
        </mat-error>
      </div>

      <div style="font-size: 14px" class="col-sm-12 col-md-12 col-lg-4">
        <label class="form-label" style="font-size: 14px !important">Billed From<span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline">
          <textarea matInput rows="7" placeholder="Billing Address" formControlName="billingAddress" required [ngClass]="{
                  'is-invalid': (f.billingAddress.invalid && f.billingAddress.touched) && submitted }"></textarea>
        </mat-form-field>
        <mat-error class="text-danger"
          *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
          Billing Address Is Required!
        </mat-error>

      </div>
    </div>

    <div class="row my-3">
      <div style="font-size: 14px" class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <label class="form-label" style="font-size: 14px !important">Mobile #</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matInput type="text" (keypress)="numberOnly($event)" placeholder="Mobile #" autocomplete="off" minlength="10"
            maxlength="10" formControlName="MobileNo">
        </mat-form-field>
      </div>
      <div [ngSwitch]="currentUser.userType"  class=" col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <div *ngSwitchCase="'CLIENT'" style="font-size: 14px">
          <label class="form-label">Warehouse<span class="error-asterisk">*</span></label>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse" (selectionChange)="onWarehouseSelectionChange($event)">
              <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                {{warehouse.firstName}}
              </mat-option>
              <mat-option value="clear">Clear Selection</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="(submitted || f.warehouse.touched)">
            <mat-error *ngIf="f.warehouse.errors?.required">
              Warehouse is required!
            </mat-error>
          </mat-error>
        </div>
        <div *ngSwitchCase="'SHOP'" style="font-size: 14px">
          <label class="form-label">Warehouse</label>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse" (selectionChange)="onWarehouseSelectionChange($event)">
              <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                {{warehouse.firstName}}
              </mat-option>
              <mat-option value="clear">Clear Selection</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>  
    </div>

    <div class="table-responsive my-custom-scrollbar">
      <table class="table table-bordered">
        <thead class="inner-model-table-heading">
          <tr class="font_size font-weight-bold">
            <th style = "vertical-align: middle;" scope="col">#</th>
            <th scope="col" style="width: 10%;vertical-align: middle;">
              SKU<span class="text-danger"> *</span>
            </th>
            <th style = "vertical-align: middle;" class="text-nowrap">SKU Description</th>
            <th style = "vertical-align: middle;">Unit</th>

            <th style = "vertical-align: middle;">Quantity</th>
            <!-- <th scope="col" class="text-nowrap" colspan="2">
                      Base Price (In Rs.)<span class="text-danger"> *</span>
                  </th>
                 -->
            <th style = "vertical-align: middle;" scope="col" colspan="1" class="text-nowrap">
              Purchase Price(With GST)<span class="text-danger"> *</span>
            </th>
            <th style = "vertical-align: middle;" scope="col" colspan="1">
              GST<span class="text-danger"> *</span>
            </th>
            <th style = "vertical-align: middle;" scope="col" colspan="2" class="text-nowrap" *ngIf="isShown">
              Additional CESS (In Rs.)<span class="text-danger"> *</span>
            </th>
            <th style = "vertical-align: middle;" class="text-nowrap" scope="col ">
              Total (In Rs.)<span class="text-danger text-nowrap"> *</span>
            </th>
            <th scope="col" class="text-nowrap" style="width:8%;vertical-align: middle;">
              Action<span class="text-danger"> *</span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
          <td style="vertical-align: middle;"></td>
          <td>
            <mat-form-field class="example-full-width" appearance="outline">
              <input type="text" id="inp12" placeholder="SKU" (input)="onSearchInputChange($event.target.value)"
                  matInput [formControl]="myControl1" [matAutocomplete]="productAuto">
              <mat-icon class="m-2" matSuffix>search</mat-icon>        
              <mat-autocomplete #productAuto="matAutocomplete">
                  <mat-option *ngFor="let option of productOptions | async" [value]="option.itemName"
                  (onSelectionChange)="getGoodsByBarcodeSelected(option.barcode,$event)">
                  <span style="font-size: 12px;">
                  {{option.itemName | titlecase}}/<b>{{ option.barcode}}</b>
                  </span>
              </mat-option>
              </mat-autocomplete>
          </mat-form-field>

        </td>
            <td>
              <input type="text" matInput placeholder="SKU Description" required />
            </td>
            <td>
              <input type="text" matInput placeholder="Unit"  required />
            </td>
            <td>
              <input type="text" matInput placeholder="Quantity" style="width: 5rem;" required />
            </td>
            <td>
              <input type="text" matInput placeholder="Unit Price" />
            </td>
            <!-- <td>
                      <input type="text" matInput placeholder="Total" />
                  </td> -->
            <td>
              <input type="text" matInput required placeholder=" %" />
            </td>
            <!-- <td colspan="1">
                      <input type="text" matInput required placeholder=" %" style="width: 3rem;" />
                  </td> -->
            <!--
                  <td colspan="1">
                      <input type="text" matInput required placeholder="IN Rs." style="width: 3rem;" />
                  </td> -->
            <td colspan="1" *ngIf="isShown">
              <input type="text" matInput placeholder="%" style="width: 2rem;" required />
            </td>
            <td colspan="1" *ngIf="isShown">
              <input type="text" matInput placeholder="IN Rs." style="width: 2rem;" required />
            </td>
            <td>
              <input type="text" matInput placeholder="Amount" required />
            </td>
            <td></td>
          </tr>
          <tr *ngFor="let item of productData;let i=index">
            <td style="vertical-align: middle;">{{ i + 1 }}</td>

            <td>
              {{ item.barcode }}
            </td>
            <td>{{ item.itemName | titlecase}}</td>
            <td class="text-center">
              <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit"
                readonly />
            </td>
            <!-- <td class="">
                      <p style="margin-top: 0px!important;">
                          <a class="border-white btn"
                              style="margin-top: 0px!important;padding: 0px!important;"
                              (click)="changeQuantity('-',i)">-</a>
                          <span class="">{{ item.quantity }}</span>
                          <a class="border-white btn"
                              style="margin-top: 0px!important;padding: 0px!important;"
                              (click)="changeQuantity('+',i)">+</a>
                      </p>
                  </td> -->
            <td style="display:flex; flex-direction: row; justify-content: center; align-items: center;">
              <!-- <input type="text" matinput [value]="item.quantity" style="width : 5 rem" /> -->
              <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                (click)="changeQuantity('-',i)">-</a>
              <input type="text" matInput [value]="item.quantity" placeholder="Quantity"
                (change)="changeQuantity('qty',i,$event.target.value)"  style="width: 5rem;text-align: center;" required />
              <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                (click)="changeQuantity('+',i)">+</a>
            </td>
            <td colspan="1">
              <input type="text" matInput (change)="setPurchasePrice($event.target.value,i)">
              <!-- <p class=""> -->
              <!-- {{ item.salePrice |
                          currency:'INR':'symbol':'1.2-2'}} -->
              <!-- {{ ((item.salePrice * item.quantity) - (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                        currency:'INR':'symbol':'1.2-2'}} -->
              <!-- {{ item.salePrice | currency:'INR':'symbol':'1.2-2'}} -->
              <!-- </p> -->
            </td>
            <!-- <td colspan="1">
                      <p class="">
                          {{item.subTotal |
                          currency:'INR':'symbol':'1.2-2'}} -->
            <!-- {{ ((item.salePrice * item.quantity) - (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                        currency:'INR':'symbol':'1.2-2'}} -->
            <!-- {{ item.salePrice * item.quantity |
                      currency:'INR':'symbol':'1.2-2'}} -->
            <!-- </p>
                  </td> -->
            <td colspan="1">
              <input type="text" matInput n (change)="setGST($event.target.value,i)" [value]="item.gstRate">
              <!-- <p class="">
                          {{ item.gstRate }}%
                      </p> -->
            </td>
            <!-- <td colspan="1">
                      <p class="" style="width: 3rem;">
                          {{ item.gstRate }}%
                      </p>
                  </td> -->
            <!--
                  <td colspan="1">
                      <p class="" style="width: 5rem;">
                          {{ item.gstAmount | currency:'INR':'symbol':'1.2-2' }}
                      </p>
                  </td> -->
            <td colspan="1" *ngIf="isShown">
              <p class="" style="width: 3rem;">{{ item.additionalCess }}% </p>
            </td>
            <td colspan="1" *ngIf="isShown">
              <p class="" style="width: 5rem;">
                <!-- {{ (item.salePrice * (item.additionalCess / 100)) | currency:'INR':'symbol':'1.2-2'}} -->
                ₹&nbsp;{{ (item.salePrice * (item.additionalCess / 100)) | number:'1.2-2'}}
              </p>
            </td>
            <td>

              <p class="" style="width:100% !important;">
                <!-- {{ item.total | currency:'INR':'symbol':'1.2-2'}} -->
                ₹&nbsp;{{ item.total | number:'1.2-2'}}
                <!-- {{ ((item.salePrice * item.quantity) + (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                      currency:'INR':'symbol':'1.2-2'}} -->
              </p>
            </td>
            <td>
              <button type="button" class="text-center btnb" style="width: 3rem!important;"
                (click)="changeQuantity('rm',i)">
                <span class="bi-trash fs-1" style="color:red" data-bs-toggle="tooltip" data-bs-placement="top"
                  title="Delete"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-responsive mt-2  my-custom-scrollbar d-flex justify-content-end">
      <div class="col-sm-6"></div>
      <div class="col-sm-4 table-bordered my-custom-scrollbar">
        <table class="table table-bordered  my-custom-scrollbar">
          <tr>
            <td class="thWidth p-2" style="text-align:left!important;">Subtotal</td>
            <td class="p-2" style="width: 50px;" style="text-align:right!important;">
              <!-- {{ subTotal | currency:'INR':'symbol':'1.2-2'}} -->
              ₹&nbsp;{{ subTotal | number:'1.2-2'}}
            </td>
          </tr>
          <tr>
            <td class="thWidth  p-2" *ngIf="gstStateMatch" style="text-align:left!important;">CGST</td>
            <td class="p-2" *ngIf="gstStateMatch" style="width: 50px;" style="text-align:right!important;">
              <!-- {{ gstRate/2| currency:'INR':'symbol':'1.2-2'}} -->
              ₹&nbsp;{{ gstRate/2 | number:'1.2-2'}}
            </td>
          </tr>
          <tr>
            <td class="thWidth  p-2" *ngIf="gstStateMatch" style="text-align:left!important;">SGST</td>
            <td class="p-2" style="width:50px;" *ngIf="gstStateMatch" style="text-align:right!important;">
              <!-- {{ gstRate/2 | currency:'INR':'symbol':'1.2-2'}} -->
              ₹&nbsp;{{ gstRate/2 | number:'1.2-2'}}
            </td>
          </tr>
          <tr>
            <td class="thWidth  p-2" *ngIf="!gstStateMatch" style="text-align:left!important;">IGST</td>
            <td class="p-2" *ngIf="!gstStateMatch" style="width: 50px;" style="text-align:right!important;">
              <!-- {{ (gstRate/2 + gstRate/2) | currency:'INR':'symbol':'1.2-2'}} -->
              ₹&nbsp;{{ (gstRate/2 + gstRate/2) | number:'1.2-2'}}
            </td>
          </tr>

          <tr>
            <td class="thWidth  p-2" *ngIf="isShown" style="text-align:left!important;">Additional Cess</td>
            <td class="p-2" style="width:50px;" *ngIf="isShown" style="text-align:right!important;">
              <!-- {{ additionalCess | currency:'INR':'symbol':'1.2-2'}} -->
              ₹&nbsp;{{ additionalCess | number:'1.2-2'}}
            </td>
          </tr>
          <tr>
            <td class="thWidth  p-2" style="text-align:left!important;">Total</td>
            <td class="p-2" style="width: 50px;" style="text-align:right!important;">
              <!-- {{ total | currency:'INR':'symbol':'1.2-2'}} -->
              ₹&nbsp;{{ total | number:'1.2-2'}}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="row">
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <label class="form-label">Purchase Type<span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 11px" class="example-full-width" appearance="outline">
          <mat-select required placeholder="Purchase Type" formControlName="PurchaseType"
            (selectionChange)="getCredit($event)" [ngClass]="{
            'is-invalid': (f.PurchaseType.invalid && f.PurchaseType.touched) && submitted }">
            <mat-option value="false">Cash</mat-option>
            <mat-option value="true">Credit</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.PurchaseType.touched) && f.PurchaseType.errors?.required">
          Purchase Type Is Required!
        </mat-error>
      </div>

    </div>
    <div class="row">
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <label class="form-label">Description</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <textarea matInput rows="5" formControlName="Description" placeholder="Description"></textarea>
        </mat-form-field>
      </div>
      <div style="font-size: 12px mt-2" class="col-xs-12 col-sm-12 col-md-4 col-lg-4 pt-4">
        <div class="{{imageSrc ? '':'dash'}} " style="height: 79% !important;margin-top: 7px;">
          <!-- <i *ngIf="imageSrc" class="bi bi-x-circle text-danger fs-1 fw-bold" (click)="cancelImage()"
            data-bs-toggle="tooltip" data-bs-placement="top" title="delete image"></i> -->
            <div (click)="cancelImage()" *ngIf="imageSrc" class="bi bi-x-circle text-danger fs-1 fw-bold"
               data-bs-toggle="tooltip" data-bs-placement="top" title="delete image"></div>

          <div *ngIf="imageSrc1">
            <img class="m-2" [src]="imageSrc ? imageSrc : ''" (load)="handleImageLoad()" [class.loaded]="imageLoaded"
              class="img-thumbnail text-center" />
          </div>
          <p *ngIf="imageSrc" class="p-2">{{AddReceiptName}}</p>
          <div class="input--file">
            <span *ngIf="!imageSrc" class="fas fa-camera fs-1 mt-3" style="padding-left: 45%" (click)="startUpload()">
            </span>
            <input name="Select File" type="file" id="uploadFile" (change)="handleInputChange($event)" />
            <div *ngIf="!imageSrc" class="mt-1 pl-0">
              <h2 class="text-bold text-center">Add Receipt</h2>
            </div>
          </div>
        </div>
      </div>
      <!-- <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <div class="dash" style="height:85% !important;">
          <div class="input--file">
            <span class="fas fa-camera fs-1" style="padding-left: 45%;">
            </span>
            <input name="Select File" type="file">
            <div class="mt-1 pl-0">
              <h2 class="text-bold text-center">
                Add Receipt
              </h2>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- <div class="col-12 d-flex p-3 justify-content-end">
          <div class="col-12 d-flex p-3 justify-content-end">
            <a type="submit" class="btn btn-outline-primary" (click)="postPurchase()"
              style="margin-left: 10px;">Save</a>
          </div>
        </div> -->

    <ng-template #addParty let-modal>
      <div class="modal-content position-relative">
        <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
          <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" (click)="modalDismiss()"
            aria-label="Close"></a>
        </div>
        <div class="modal-body p-0">
          <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
            <h2 class="mb-1" style="color: #EB8B3F; font-weight: bolder;" id="modalExampleDemoLabel">Add New Vendor</h2>
          </div>
          <form [formGroup]="PartyForm">
            <div class="p-4 pb-0">
              <div class="form-check form-switch mb-0 lh-1 mb-3">
                <input class="form-check-input" type="checkbox" [checked]="isShowGstFields"
                  style="border:1px solid rgb(201, 199, 199) !important;" id="flexSwitchCheckDefault"
                  (click)="customertoggleShow($event.target.checked)" />
                <label class="lab text-nowrap p-1"> As Customer </label>
              </div>

              <div class="row d-flex justify-content-start" *ngIf="!isShowGstFields">
                <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label class="form-label">GSTIN<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput required formControlName="gstIn" (change)="getGstNumber($event.target.value)"
                      maxlength="15" minlength="15"
                      pattern="^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$"
                      placeholder="GSTIN" value="" style="text-transform:uppercase">
                  </mat-form-field>
                  <mat-error *ngIf="(submittedParty ||fp.gstIn.touched) && fp.gstIn.errors?.required">
                    Enter a valid GSTIN.
                  </mat-error>

                  <mat-error
                    *ngIf="(submittedParty ||fp.gstIn.touched) && fp.gstIn.errors?.pattern || fp.gstIn.errors?.minlength">
                    Enter a valid GSTIN.
                  </mat-error>
                  <mat-error *ngIf="(submittedParty ||fp.gstIn.touched) && fp.gstIn.errors?.maxlength">
                    Enter a valid GSTIN.
                  </mat-error>
                  <!-- <mat-error *ngIf="(submittedParty ||fp.gstIn.touched) && fp.gstIn.errors?.minlength">
                    Enter a valid GSTIN.
                  </mat-error> -->
                </div>

                <div style=" font-size: 12px" class=" col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label class="form-label">GST Type<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl required formControlName="gstType" disableOptionCentering
                      placeholder="GST Type" [disabled]="!isDisabledGST">
                      <mat-option value="REGULAR">Regular</mat-option>
                      <mat-option value="COMPOSITE">Composite</mat-option>
                      <mat-option value="EXEMPTED">Exempted</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-error *ngIf="(submittedParty ||fp.gstType.touched) && fp.gstType.errors?.required">
                    GST Type Is Required!
                  </mat-error>
                </div>
              </div>

              <div class="row d-flex justify-content-start">
                <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label class="form-label">Vendor Name<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="Vendor Name" formControlName="partyName"
                      [ngClass]="{
                        'is-invalid': (fp.partyName.invalid && fp.partyName.touched) && submittedParty }">
                  </mat-form-field>
                  <mat-error class="text-danger" *ngIf="(submittedParty || fp.partyName.touched)">
                    <mat-error *ngIf="(submittedParty || fp.partyName.touched) && fp.partyName.errors?.required">
                      Vendor Name Is Required!
                    </mat-error>
                  </mat-error>

                </div>

                <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label class="form-label">Mobile #<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" placeholder="Mobile #" matInput formControlName="mobile" minlength="10"
                      maxLength="10" ngxOnlyNumbers [ngClass]="{
                          'is-invalid': (fp.mobile.invalid && fp.mobile.touched) && submittedParty }">
                  </mat-form-field>

                  <mat-error class="text-danger"
                    *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.pattern">
                    Enter a valid mobile number.
                  </mat-error>
                  <mat-error class="text-danger"
                    *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.required">
                    Mobile # is required.
                  </mat-error>
                  <mat-error *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.maxlength">
                    Mobile # must be of 10-digits
                  </mat-error>
                  <mat-error *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.minlength">
                    Mobile # must be of 10-digits
                  </mat-error>
                </div>

                <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label class="form-label">E-Mail<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <input type="email" placeholder="e-mail" matInput formControlName="email" [ngClass]="{
                          'is-invalid': (fp.email.invalid && fp.email.touched) && submittedParty }">
                  </mat-form-field>
                  <mat-error class="text-danger"
                    *ngIf="(submittedParty || fp.email.touched) && fp.email.errors?.pattern && fp.email.errors?.email">
                    Enter a valid email id.
                  </mat-error>
                  <mat-error class="text-danger"
                    *ngIf="(submittedParty || fp.email.touched) && fp.email.errors?.required">
                    Email id is required.
                  </mat-error>
                </div>
              </div>

              <div class="row d-flex justify-content-start">
                <div class="col-lg-4 col-sm-12 col-md-6">
                  <label class="form-label">Billing Address<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput required formControlName="billingAddress" placeholder="Enter your address"
                      [ngClass]="{
                          'is-invalid': (fp.billingAddress.invalid && fp.billingAddress.touched) && submittedParty }"></textarea>
                  </mat-form-field>
                  <mat-error class="text-danger" *ngIf="(submittedParty || fp.billingAddress.touched)">
                    <mat-error
                      *ngIf="(submittedParty || fp.billingAddress.touched) && fp.billingAddress.errors?.required">
                      Billing Address Is Required!
                    </mat-error>
                  </mat-error>
                </div>

                <div class="col-lg-4 col-sm-12 col-md-6" *ngIf="isShippingAddressinModal">
                  <label class="form-label">Shipping Address</label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput formControlName="shippingAddress" placeholder="Enter your address"></textarea>
                  </mat-form-field>
                </div>
              </div>

              <div class="form-check form-check-inline mb-2">
                <label class="form-check-label fs--1" for="inlineCheckbox1">Add Shipping Address</label>
                <input class="form-check-input fs--1" id="inlineCheckbox1" type="checkbox"
                  style="border:1px solid rgb(201, 199, 199) !important;"
                  (change)="showShippingAddressinModal($event.target.checked)" />
              </div>
            </div>
          </form>
        </div>
         <div class="modal-footer">
          <button class="btn btn-cancel" (click)="modalDismiss()">Close</button>
          <button class="btn btn-save" (click)="addNewParty()" data-bs-dismiss="modal">Add</button>
        </div>
      </div>
    </ng-template>

  </form>

  <!-- UAE -->
  <form [formGroup]="purchaseOrderForm" *ngIf="isArabic">
    <div class="row">
      <div style="font-size: 14px" class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <label class="form-label" style="font-size: 14px !important">Vendor Name<span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 14px;" class="example-full-width" appearance="outline" style = "height: 48px !important;margin-bottom: 16px;">
          <input type="text" placeholder="Vendor Name" aria-label="Select Vendor Name" matInput
            (keyup)="searchPartyByName($event.target.value)" [formControl]="myControl" [matAutocomplete]="auto"
            [ngClass]="{
                  'is-invalid': (myControl.invalid && myControl.touched) && submitted }" required>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option>
              <a class="mobVerify" style="display: inline-block; width: 100%;" aria-hidden="true" title="Party"
                (click)="openAddPartyModal(addParty)">
                Add new Vendor
              </a>
            </mat-option>
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
              (onSelectionChange)="setSelectedPartyDeatails(option)">
              {{option.partyName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="
                  (submitted || myControl.touched) && myControl.errors?.required
                ">
          Vendor Is Required!
        </mat-error>


        <label class="form-label" style="font-size: 14px !important" for="Order Date">Order Date</label>
        <mat-form-field style="font-size: 14px !important" class="example-full-width" appearance="outline">
          <input matInput required placeholder="Order Date" id="Order Date" [outsideClick]="true" ngModel bsDatepicker
            [maxDate]="today" [(ngModel)]="todaysDate" style="text-transform:uppercase!important;"
            formControlName="orderDate"
            [bsConfig]="{ dateInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true, showWeekNumbers:false }"
            [ngClass]="{
            'is-invalid': (f.orderDate.invalid && f.orderDate.touched) && submitted }">
          <span>
            <mat-icon class="iconcalender">date_range</mat-icon>
          </span>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.orderDate.touched) && f.orderDate.errors?.required" (click)="openDatepickerOrderDate()">
          Order Date Is Required!
        </mat-error>
        <!-- <mat-form-field  style="font-size: 14px !important" class="example-full-width" appearance="outline">
          <input matInput required ngModel bsDatepicker placeholder="DD MMM YYYY"
            style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                          containerClass: 'theme-dark-blue',
                          dateInputFormat: 'DD MMM YYYY',
                           showWeekNumbers:false
                          }" formControlName="orderDate" autocomplete="off" (click)="openDatepickerOrderDate()">
          <span>
            <mat-icon class="iconcalender p-1">date_range</mat-icon>
          </span>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.orderDate.touched) && f.orderDate.errors?.required">
          Order Date Is Required!
        </mat-error> -->
      </div>

      <div style="font-size: 14px" class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <label class="form-label" style="font-size: 14px !important">Order #<span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 14px !important" class="example-full-width" appearance="outline" style = "height: 48px !important;margin-bottom: 16px;">
          <input matInput required type="text" placeholder="Order #" (keypress)="numberOnly($event)"
            formControlName="orderNo" autocomplete="off" [ngClass]="{
            'is-invalid': (f.orderNo.invalid && f.orderNo.touched) && submitted }">
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.orderNo.touched) && f.orderNo.errors?.required">
          Order # Is Required!
        </mat-error>

        <label class="form-label" style="font-size: 14px !important" for="Due Date">Due Date</label>
        <mat-form-field style="font-size: 14px !important" class="example-full-width" appearance="outline">
          <input matInput required placeholder="Due Date" id="Due Date" [outsideClick]="true" ngModel bsDatepicker
            formControlName="dueDate" [minDate]="today" [(ngModel)]="todaysDate2"
            style="text-transform:uppercase!important;"
            [bsConfig]="{ dateInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true, showWeekNumbers:false }"
            [ngClass]="{
              'is-invalid': (f.dueDate.invalid && f.dueDate.touched) && submitted }" (click)="openDatepickerDueDate()">
          <span>
            <mat-icon class="iconcalender">date_range</mat-icon>
          </span>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.dueDate.touched) && f.dueDate.errors?.required" >
          Due Date Is Required!
        </mat-error>
        <!-- <mat-form-field style="font-size: 14px !important" class="example-full-width" appearance="outline">
          <input matInput required ngModel bsDatepicker placeholder="DD MMM YYYY"
            style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                          containerClass: 'theme-dark-blue',
                          dateInputFormat: 'DD MMM YYYY',
                           showWeekNumbers:false
                          }" formControlName="dueDate" autocomplete="off" (click)="openDatepickerDueDate()">
          <span>
            <mat-icon class="iconcalender p-1">date_range</mat-icon>
          </span>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.dueDate.touched) && f.dueDate.errors?.required">
          Due Date Is Required!
        </mat-error> -->
      </div>

      <div style="font-size: 14px" class="col-sm-12 col-md-12 col-lg-4">
        <label class="form-label" style="font-size: 14px !important">Billed From<span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 14px" class="example-full-width" appearance="outline">
          <textarea matInput rows="7" placeholder="Billing Address" formControlName="billingAddress" required [ngClass]="{
                  'is-invalid': (f.billingAddress.invalid && f.billingAddress.touched) && submitted }"></textarea>
        </mat-form-field>
        <mat-error class="text-danger"
          *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
          Billing Address Is Required!
        </mat-error>

      </div>
    </div>

    <div class="row my-3">
      <div style="font-size: 14px" class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <label class="form-label" style="font-size: 14px !important">Mobile #</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matInput type="text" (keypress)="numberOnly($event)" placeholder="Mobile #" autocomplete="off" minlength="9"
            maxlength="9" formControlName="MobileNo">
        </mat-form-field>
      </div>
    </div>

    <div class="table-responsive my-custom-scrollbar">
      <table class="table table-bordered">
        <thead class="inner-model-table-heading">
          <tr class="font_size font-weight-bold">
            <th style = "vertical-align: middle;" scope="col">#</th>
            <th scope="col" style="width: 10%;vertical-align: middle;">
              SKU<span class="text-danger"> *</span>
            </th>
            <th style = "vertical-align: middle;" class="text-nowrap">SKU Description</th>
            <th style = "vertical-align: middle;">Unit</th>

            <th style = "vertical-align: middle;">Quantity</th>
            <!-- <th scope="col" class="text-nowrap" colspan="2">
                      Base Price (In Rs.)<span class="text-danger"> *</span>
                  </th>
                 -->
            <th style = "vertical-align: middle;" scope="col" colspan="1" class="text-nowrap">
              {{ lang.Base_Price_In_Rs }}<span class="text-danger"> *</span>
            </th>
            <th style = "vertical-align: middle;" scope="col" colspan="1">
              {{ lang.GST }}<span class="text-danger"> *</span>
            </th>
            <th style = "vertical-align: middle;" scope="col" colspan="2" class="text-nowrap" *ngIf="isShown">
              Additional CESS (IN AED)<span class="text-danger"> *</span>
            </th>
            <th style = "vertical-align: middle;" class="text-nowrap" scope="col ">
              Total (In AED)<span class="text-danger text-nowrap"> *</span>
            </th>
            <th scope="col" class="text-nowrap" style="width:8%;vertical-align: middle;">
              Action<span class="text-danger"> *</span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td></td>
            <td>
              <input type="text" matInput  placeholder="SKU" (change)="getGoodsByBarcode()"
                [(ngModel)]="barcode" [ngModelOptions]="{standalone: true}" required />


            </td>
            <td>
              <input type="text" matInput placeholder="SKU Description" required />
            </td>
            <td>
              <input type="text" matInput placeholder="Unit"  required />
            </td>
            <td>
              <input type="text" matInput placeholder="Quantity" style="width: 5rem;" required />
            </td>
            <td>
              <input type="text" matInput placeholder="Unit Price" />
            </td>
            <!-- <td>
                      <input type="text" matInput placeholder="Total" />
                  </td> -->
            <td>
              <input type="text" matInput required placeholder=" %" />
            </td>
            <!-- <td colspan="1">
                      <input type="text" matInput required placeholder=" %" style="width: 3rem;" />
                  </td> -->
            <!--
                  <td colspan="1">
                      <input type="text" matInput required placeholder="IN Rs." style="width: 3rem;" />
                  </td> -->
            <!-- <td colspan="1" *ngIf="isShown">
              <input type="text" matInput placeholder="%" style="width: 2rem;" required />
            </td>
            <td colspan="1" *ngIf="isShown">
              <input type="text" matInput placeholder="IN AED" style="width: 2rem;" required />
            </td> -->
            <td>
              <input type="text" matInput placeholder="Amount" style = "max-width:150px;" required />
            </td>
            <td></td>
          </tr>
          <tr *ngFor="let item of productData;let i=index">
            <td style="vertical-align: middle;">{{ i + 1 }}</td>

            <td>
              {{ item.barcode }}
            </td>
            <td>{{ item.itemName | titlecase}}</td>
            <td class="text-center" >
              <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit"
                readonly />
            </td>
            <!-- <td class="">
                      <p style="margin-top: 0px!important;">
                          <a class="border-white btn"
                              style="margin-top: 0px!important;padding: 0px!important;"
                              (click)="changeQuantity('-',i)">-</a>
                          <span class="">{{ item.quantity }}</span>
                          <a class="border-white btn"
                              style="margin-top: 0px!important;padding: 0px!important;"
                              (click)="changeQuantity('+',i)">+</a>
                      </p>
                  </td> -->
            <td style="display:flex; flex-direction: row; justify-content: center; align-items: center;">
              <!-- <input type="text" matinput [value]="item.quantity" style="width : 5 rem" /> -->
              <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                (click)="changeQuantity('-',i)">-</a>
              <input type="text" matInput [value]="item.quantity" placeholder="Quantity"
                (change)="changeQuantity('qty',i,$event.target.value)"  style="width: 5rem;text-align: center;" required />
              <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                (click)="changeQuantity('+',i)">+</a>
            </td>
            <td colspan="1">
              <input type="text" matInput (change)="setPurchasePrice($event.target.value,i)">
              <!-- <p class=""> -->
              <!-- {{ item.salePrice |
                          currency:'INR':'symbol':'1.2-2'}} -->
              <!-- {{ ((item.salePrice * item.quantity) - (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                        currency:'INR':'symbol':'1.2-2'}} -->
              <!-- {{ item.salePrice | currency:'INR':'symbol':'1.2-2'}} -->
              <!-- </p> -->
            </td>
            <!-- <td colspan="1">
                      <p class="">
                          {{item.subTotal |
                          currency:'INR':'symbol':'1.2-2'}} -->
            <!-- {{ ((item.salePrice * item.quantity) - (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                        currency:'INR':'symbol':'1.2-2'}} -->
            <!-- {{ item.salePrice * item.quantity |
                      currency:'INR':'symbol':'1.2-2'}} -->
            <!-- </p>
                  </td> -->
            <td colspan="1">
              <input type="text" matInput n (change)="setGST($event.target.value,i)" [value]="item.gstRate">
              <!-- <p class="">
                          {{ item.gstRate }}%
                      </p> -->
            </td>
            <!-- <td colspan="1">
                      <p class="" style="width: 3rem;">
                          {{ item.gstRate }}%
                      </p>
                  </td> -->
            <!--
                  <td colspan="1">
                      <p class="" style="width: 5rem;">
                          {{ item.gstAmount | currency:'INR':'symbol':'1.2-2' }}
                      </p>
                  </td> -->
            <!-- <td colspan="1" *ngIf="isShown">
              <p class="" style="width: 3rem;">{{ item.additionalCess }}% </p>
            </td>
            <td colspan="1" *ngIf="isShown">
              <p class="" style="width: 5rem;">
                ₹&nbsp;{{ (item.salePrice * (item.additionalCess / 100)) | number:'1.2-2'}}
              </p>
            </td> -->
            <td style = "max-width: 150px;">

              <p class="">
                <!-- {{ item.total | currency:'INR':'symbol':'1.2-2'}} -->
                ₹&nbsp;{{ item.total | number:'1.2-2'}}
                <!-- {{ ((item.salePrice * item.quantity) + (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                      currency:'INR':'symbol':'1.2-2'}} -->
              </p>
            </td>
            <td>
              <button type="button" class="text-center btnb" style="width: 3rem!important;"
                (click)="changeQuantity('rm',i)">
                <span class="bi-trash fs-1" style="color:red" data-bs-toggle="tooltip" data-bs-placement="top"
                  title="Delete"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-responsive mt-2  my-custom-scrollbar d-flex justify-content-end">
      <div class="col-sm-6"></div>
      <div class="col-sm-4 table-bordered my-custom-scrollbar">
        <table class="table table-bordered  my-custom-scrollbar">
          <tr>
            <td class="thWidth p-2" style="text-align:left!important;">Subtotal</td>
            <td class="p-2" style="width: 50px;" style="text-align:right!important;">
              AED&nbsp;{{ subTotal | number:'1.2-2'}}
            </td>
          </tr>
          <tr>
            <td class="thWidth  p-2"  style="text-align:left!important;">{{lang.CGST}}</td>
            <td class="p-2"  style="width: 50px;" style="text-align:right!important;">
              AED&nbsp;{{ gstRate | number:'1.2-2'}}
            </td>
          </tr>
          <tr>
            <!-- <td class="thWidth  p-2" *ngIf="gstStateMatch" style="text-align:left!important;">SGST</td>
            <td class="p-2" style="width:50px;" *ngIf="gstStateMatch" style="text-align:right!important;">
              AED&nbsp;{{ gstRate/2 | number:'1.2-2'}}
            </td> -->
          </tr>
          <!-- <tr>
            <td class="thWidth  p-2" *ngIf="!gstStateMatch" style="text-align:left!important;">IGST</td>
            <td class="p-2" *ngIf="!gstStateMatch" style="width: 50px;" style="text-align:right!important;">
              AED122&nbsp;{{ (gstRate/2 + gstRate/2) | number:'1.2-2'}}
            </td>
          </tr>

          <tr>
            <td class="thWidth  p-2" *ngIf="isShown" style="text-align:left!important;">Additional Cess</td>
            <td class="p-2" style="width:50px;" *ngIf="isShown" style="text-align:right!important;">
              AED123&nbsp;{{ additionalCess | number:'1.2-2'}}
            </td>
          </tr> -->
          <tr>
            <td class="thWidth  p-2" style="text-align:left!important;">Total</td>
            <td class="p-2" style="width: 50px;" style="text-align:right!important;">
              <!-- {{ total | currency:'INR':'symbol':'1.2-2'}} -->
              AED&nbsp;{{ total | number:'1.2-2'}}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="row">
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <label class="form-label">Purchase Type<span class="error-asterisk">*</span></label>
        <mat-form-field style="font-size: 11px" class="example-full-width" appearance="outline">
          <mat-select required placeholder="Purchase Type" formControlName="PurchaseType"
            (selectionChange)="getCredit($event)" [ngClass]="{
            'is-invalid': (f.PurchaseType.invalid && f.PurchaseType.touched) && submitted }">
            <mat-option value="false">Cash</mat-option>
            <mat-option value="true">Credit</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.PurchaseType.touched) && f.PurchaseType.errors?.required">
          Purchase Type Is Required!
        </mat-error>
      </div>

    </div>
    <div class="row">
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <label class="form-label">Description</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <textarea matInput rows="5" formControlName="Description" placeholder="Description"></textarea>
        </mat-form-field>
      </div>
      <div style="font-size: 12px mt-2" class="col-xs-12 col-sm-12 col-md-4 col-lg-4 pt-4">
        <div class="{{imageSrc ? '':'dash'}} " style="height: 79% !important;margin-top: 7px;">
          <!-- <i *ngIf="imageSrc" class="bi bi-x-circle text-danger fs-1 fw-bold" (click)="cancelImage()"
            data-bs-toggle="tooltip" data-bs-placement="top" title="delete image"></i> -->
            <div (click)="cancelImage()" *ngIf="imageSrc" class="bi bi-x-circle text-danger fs-1 fw-bold"
               data-bs-toggle="tooltip" data-bs-placement="top" title="delete image"></div>

          <div *ngIf="imageSrc1">
            <img class="m-2" [src]="imageSrc ? imageSrc : ''" (load)="handleImageLoad()" [class.loaded]="imageLoaded"
              class="img-thumbnail text-center" />
          </div>
          <p *ngIf="imageSrc" class="p-2">{{AddReceiptName}}</p>
          <div class="input--file">
            <span *ngIf="!imageSrc" class="fas fa-camera fs-1 mt-3" style="padding-left: 45%" (click)="startUpload()">
            </span>
            <input name="Select File" type="file" id="uploadFile" (change)="handleInputChange($event)" />
            <div *ngIf="!imageSrc" class="mt-1 pl-0">
              <h2 class="text-bold text-center">Add Receipt</h2>
            </div>
          </div>
        </div>
      </div>
      <!-- <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <div class="dash" style="height:85% !important;">
          <div class="input--file">
            <span class="fas fa-camera fs-1" style="padding-left: 45%;">
            </span>
            <input name="Select File" type="file">
            <div class="mt-1 pl-0">
              <h2 class="text-bold text-center">
                Add Receipt
              </h2>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- <div class="col-12 d-flex p-3 justify-content-end">
          <div class="col-12 d-flex p-3 justify-content-end">
            <a type="submit" class="btn btn-outline-primary" (click)="postPurchase()"
              style="margin-left: 10px;">Save</a>
          </div>
        </div> -->

    <ng-template #addParty let-modal>
      <div class="modal-content position-relative">
        <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
          <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" (click)="modalDismiss()"
            aria-label="Close"></a>
        </div>
        <div class="modal-body p-0">
          <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
            <h2 class="mb-1" style="color: #EB8B3F; font-weight: bolder;" id="modalExampleDemoLabel">Add New Vendor</h2>
          </div>
          <form [formGroup]="PartyForm">
            <div class="p-4 pb-0">
              <div class="form-check form-switch mb-0 lh-1 mb-3">
                <input class="form-check-input" type="checkbox" [checked]="isShowGstFields"
                  style="border:1px solid rgb(201, 199, 199) !important;" id="flexSwitchCheckDefault"
                  (click)="customertoggleShow($event.target.checked)" />
                <label class="lab text-nowrap p-1"> As Customer </label>
              </div>

              <div class="row d-flex justify-content-start" *ngIf="!isShowGstFields">

                <div class="col-sm-12 col-md-6" *ngIf="!isShowGstFields">
                  <div><mat-label>{{ lang.GSTIN }}<span class="error-asterisk">*</span></mat-label></div>
                  <mat-form-field class="example-full-width" appearance="outline" style="width: 85%;">
                      <input type="text" matInput formControlName="gstIn" placeholder="{{lang.GSTIN }}" #input1
                      (keypress)="numberOnly($event)" maxlength="15" (input)="validateTRN($event.target.value)"
                          [ngClass]="{'is-invalid': (fp.gstIn.invalid && fp.gstIn.touched) && submittedParty }"
                          required>
                  </mat-form-field>

                  <mat-error class="text-danger" *ngIf="(submittedParty && fp.gstIn.errors?.required)">
                      {{ lang.GSTIN }} is Required!
                  </mat-error>
                  <mat-error class="text-danger"
                      *ngIf="fp.gstIn.invalid && (fp.gstIn.dirty || fp.gstIn.touched)">
                      Please enter the valid {{ lang.GSTIN }} number
                  </mat-error>
                  <mat-error class="text-danger" *ngIf="fp.gstIn.errors?.maxlength">
                      {{ lang.GSTIN }} must be of 15-digits
                  </mat-error>
                  <mat-error class="text-danger" *ngIf="fp.gstIn.errors?.minlength">
                      {{ lang.GSTIN }} must be of 15-digits
                  </mat-error>
                  <!-- <mat-error class="text-danger" *ngIf="f.gstinNo.errors?.required">GSTIN Is Required!</mat-error> -->
                  <mat-error class="text-danger" *ngIf="fp.gstIn.errors?.pattern">
                      {{ lang.GSTIN }} must be valid number
                  </mat-error>
              </div>
                <!-- <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label class="form-label">{{ lang.GSTIN }}</label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput required formControlName="gstIn"
                      maxlength="15" minlength="15" (keypress)="numberOnly($event)"
                      placeholder="{{lang.GSTIN}}" value="" style="text-transform:uppercase">
                  </mat-form-field>
                  <mat-error *ngIf="(submittedParty ||fp.gstIn.touched) && fp.gstIn.errors?.required">
                    Enter a valid {{lang.GSTIN}}.
                  </mat-error>

                  <mat-error
                    *ngIf="(submittedParty ||fp.gstIn.touched) && fp.gstIn.errors?.pattern || fp.gstIn.errors?.minlength">
                    Enter a valid {{lang.GSTIN}}.
                  </mat-error>
                  <mat-error *ngIf="(submittedParty ||fp.gstIn.touched) && fp.gstIn.errors?.maxlength">
                    Enter a valid {{lang.GSTIN}}.
                  </mat-error>
                  <mat-error *ngIf="(submittedParty ||fp.gstIn.touched) && fp.gstIn.errors?.minlength">
                    Enter a valid GSTIN.
                  </mat-error> -->
                <!-- </div> -->



<!--
                <div style=" font-size: 12px" class=" col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label class="form-label">{{lang.CGST}} Type</label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl required formControlName="gstType" disableOptionCentering
                      placeholder="{{ lang.CGST }} Type" [disabled]="!isDisabledGST">
                      <mat-option value="REGULAR">Regular</mat-option>
                      <mat-option value="COMPOSITE">Composite</mat-option>
                      <mat-option value="EXEMPTED">Exempted</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-error *ngIf="(submittedParty ||fp.gstType.touched) && fp.gstType.errors?.required">
                    {{lang.CGST}} Type Is Required!
                  </mat-error>
                </div> -->
              </div>

              <div class="row d-flex justify-content-start">
                <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label class="form-label">Vendor Name<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="Vendor Name" formControlName="partyName"
                      [ngClass]="{
                        'is-invalid': (fp.partyName.invalid && fp.partyName.touched) && submittedParty }">
                  </mat-form-field>
                  <mat-error class="text-danger" *ngIf="(submittedParty || fp.partyName.touched)">
                    <mat-error *ngIf="(submittedParty || fp.partyName.touched) && fp.partyName.errors?.required">
                      Vendor Name Is Required!
                    </mat-error>
                  </mat-error>

                </div>

                <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label class="form-label">Mobile #<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" placeholder="Mobile #" matInput formControlName="mobile" minlength="9"
                      maxLength="9" ngxOnlyNumbers [ngClass]="{
                          'is-invalid': (fp.mobile.invalid && fp.mobile.touched) && submittedParty }">
                  </mat-form-field>

                  <mat-error class="text-danger"
                    *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.pattern">
                    Enter a valid mobile number.
                  </mat-error>
                  <mat-error class="text-danger"
                    *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.required">
                    Mobile # is required.
                  </mat-error>
                  <mat-error *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.maxlength">
                    Mobile # must be of 9-digits
                  </mat-error>
                  <mat-error *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.minlength">
                    Mobile # must be of 9-digits
                  </mat-error>
                </div>

                <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <label class="form-label">E-Mail<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <input type="email" placeholder="e-mail" matInput formControlName="email" [ngClass]="{
                          'is-invalid': (fp.email.invalid && fp.email.touched) && submittedParty }">
                  </mat-form-field>
                  <mat-error class="text-danger"
                    *ngIf="(submittedParty || fp.email.touched) && fp.email.errors?.pattern && fp.email.errors?.email">
                    Enter a valid email id.
                  </mat-error>
                  <mat-error class="text-danger"
                    *ngIf="(submittedParty || fp.email.touched) && fp.email.errors?.required">
                    Email id is required.
                  </mat-error>
                </div>
              </div>

              <div class="row d-flex justify-content-start">
                <div class="col-lg-4 col-sm-12 col-md-6">
                  <label class="form-label">Billing Address<span class="error-asterisk">*</span></label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput required formControlName="billingAddress" placeholder="Enter your address"
                      [ngClass]="{
                          'is-invalid': (fp.billingAddress.invalid && fp.billingAddress.touched) && submittedParty }"></textarea>
                  </mat-form-field>
                  <mat-error class="text-danger" *ngIf="(submittedParty || fp.billingAddress.touched)">
                    <mat-error
                      *ngIf="(submittedParty || fp.billingAddress.touched) && fp.billingAddress.errors?.required">
                      Billing Address Is Required!
                    </mat-error>
                  </mat-error>
                </div>

                <div class="col-lg-4 col-sm-12 col-md-6" *ngIf="isShippingAddressinModal">
                  <label class="form-label">Shipping Address</label>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput formControlName="shippingAddress" placeholder="Enter your address"></textarea>
                  </mat-form-field>
                </div>
              </div>

              <div class="form-check form-check-inline mb-2">
                <label class="form-check-label fs--1" for="inlineCheckbox1">Add Shipping Address</label>
                <input class="form-check-input fs--1" id="inlineCheckbox1" type="checkbox"
                  style="border:1px solid rgb(201, 199, 199) !important;"
                  (change)="showShippingAddressinModal($event.target.checked)" />
              </div>
            </div>
          </form>
        </div>
         <div class="modal-footer">
          <button class="btn btn-cancel" (click)="modalDismiss()">Close</button>
          <button class="btn btn-save" (click)="addNewParty()" data-bs-dismiss="modal">Add</button>
        </div>
      </div>
    </ng-template>

  </form>
</div>











<!-- <form [formGroup]="purchaseOrderForm">
<div class="card mb-3">
  <div class="card-header bg-light pt-3 pb-3">
    <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
      Purchase Order
    </p>
  </div>
  <div class="progress" style="height: 2px">
    <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <mat-form-field style="font-size: 11px" class="example-full-width" appearance="outline">
          <mat-label>Party Name</mat-label>
          <input type="text" placeholder="Party Name" aria-label="Select Party Name" matInput
          (keyup)="searchPartyByName($event.target.value)" [formControl]="myControl" [matAutocomplete]="auto" [ngClass]="{
                  'is-invalid': (myControl.invalid && myControl.touched) && submitted }" required>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option>
              <a class="mobVerify" style="display: inline-block; width: 100%;" aria-hidden="true" title="Party" (click)="openAddPartyModal(addParty)">
                Add new party
              </a>
            </mat-option>
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
              (onSelectionChange)="setSelectedPartyDeatails(option)" >
                  {{option.partyName}}
              </mat-option>
          </mat-autocomplete>
      </mat-form-field>
      <mat-error class="text-danger" *ngIf="
                  (submitted || myControl.touched) && myControl.errors?.required
                ">
            Party Is Required!
      </mat-error>


      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label for="Order Date">Order Date</mat-label>
        <input matInput required placeholder="Order Date" id="Order Date" [outsideClick]="true" ngModel bsDatepicker
          [maxDate]="today" [(ngModel)]="todaysDate" style="text-transform:uppercase!important;" formControlName="orderDate"
          [bsConfig]="{ dateInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true }"
          [ngClass]="{
            'is-invalid': (f.orderDate.invalid && f.orderDate.touched) && submitted }">
        <span>
          <mat-icon class="iconcalender">date_range</mat-icon>
        </span>
      </mat-form-field>
      <mat-error class="text-danger"
                        *ngIf="(submitted || f.orderDate.touched) && f.orderDate.errors?.required">
                        Order Date Is Required!
                    </mat-error>
      </div>

      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Order No.</mat-label>
          <input matInput required type="text" placeholder="Order No." (keypress)="numberOnly($event)"  formControlName="orderNo"
          [ngClass]="{
            'is-invalid': (f.orderNo.invalid && f.orderNo.touched) && submitted }">
        </mat-form-field>
        <mat-error class="text-danger"
        *ngIf="(submitted || f.orderNo.touched) && f.orderNo.errors?.required">
        Order No Is Required!
      </mat-error>

        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label for="Due Date">Due Date</mat-label>
          <input matInput required placeholder="Due Date" id="Due Date" [outsideClick]="true" ngModel bsDatepicker formControlName="dueDate"
            [minDate]="today" [(ngModel)]="todaysDate2" style="text-transform:uppercase!important;"
            [bsConfig]="{ dateInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true }"
            [ngClass]="{
              'is-invalid': (f.dueDate.invalid && f.dueDate.touched) && submitted }">
          <span>
            <mat-icon class="iconcalender">date_range</mat-icon>
          </span>
        </mat-form-field>
        <mat-error class="text-danger"
                        *ngIf="(submitted || f.dueDate.touched) && f.dueDate.errors?.required">
                        Due Date Is Required!
                    </mat-error>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <mat-form-field style="font-size: 11px" class="example-full-width" appearance="outline">
            <mat-label>Billing Address</mat-label>
            <textarea matInput rows="5" placeholder="Billing Address" formControlName="billingAddress"
                required [ngClass]="{
                  'is-invalid': (f.billingAddress.invalid && f.billingAddress.touched) && submitted }"
               ></textarea>
        </mat-form-field>
        <mat-error class="text-danger"
        *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
        Billing Address Is Required!
      </mat-error>

    </div>
    </div>
    <div class="row">
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Mobile No.</mat-label>
          <input matInput type="text"(keypress)="numberOnly($event)" placeholder="Mobile No." minlength="10"
            maxlength="10" formControlName="MobileNo">
        </mat-form-field>
      </div>
    </div>
    <div class="table-responsive my-custom-scrollbar">
      <table class="table table-bordered">
          <thead class="thead-light">
              <tr class="font_size font-weight-bold text-center">
                  <th scope="col">#</th>
                  <th scope="col" style="width: 10%;">
                      SKU<span class="text-danger"> *</span>
                  </th>
                  <th class="text-nowrap">SKU Description</th>
                  <th>Unit</th>

                  <th>Quantity</th>

                  <th scope="col" colspan="1" class="text-nowrap">
                      Purchase Price(With GST)<span class="text-danger"> *</span>
                  </th>
                  <th scope="col" colspan="1">
                      GST<span class="text-danger"> *</span>
                  </th>
                  <th scope="col" colspan="2" class="text-nowrap" *ngIf="isShown">
                      Additional CESS (IN INR)<span class="text-danger"> *</span>
                  </th>
                  <th class="text-nowrap" scope="col ">
                      Total (In Rs.)<span class="text-danger text-nowrap"> *</span>
                  </th>
                  <th scope="col" class="text-nowrap" style="width:8%;">
                      Action<span class="text-danger"> *</span>
                  </th>
              </tr>
          </thead>

          <tbody>
              <tr>
                  <td>#</td>
                  <td>
                      <input type="text" matInput style="width: 70px;" placeholder="SKU"
                          (change)="getGoodsByBarcode()" [(ngModel)]="barcode"
                          [ngModelOptions]="{standalone: true}" required />


                  </td>
                  <td>
                      <input type="text" matInput placeholder="SKU Description" required />
                  </td>
                  <td>
                      <input type="text" matInput placeholder="Unit" style="width: 3rem;" required />
                  </td>
                  <td>
                      <input type="text" matInput placeholder="Quantity" style="width: 5rem;" required />
                  </td>
                  <td>
                      <input type="text" matInput placeholder="Unit Price" />
                  </td>

                  <td>
                      <input type="text" matInput required placeholder=" %" />
                  </td>

                  <td colspan="1" *ngIf="isShown">
                      <input type="text" matInput placeholder="%" style="width: 2rem;" required />
                  </td>
                  <td colspan="1" *ngIf="isShown">
                      <input type="text" matInput placeholder="IN Rs." style="width: 2rem;" required />
                  </td>
                  <td>
                      <input type="text" matInput placeholder="Amount" required />
                  </td>
                  <td></td>
              </tr>
              <tr *ngFor="let item of productData;let i=index">
                  <td>

                  </td>
                  <td>
                      {{ item.barcode }}
                  </td>
                  <td>{{ item.itemName | titlecase}}</td>
                  <td class="text-center">
                      <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit"
                          style="width: 5rem;" readonly />
                  </td>

                  <td
                      style="display:flex; flex-direction: row; justify-content: center; align-items: center;">
                      <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                          (click)="changeQuantity('-',i)">-</a>
                      <input type="text" matInput [value]="item.quantity" placeholder="Quantity"
                          (change)="changeQuantity('qty',i,$event.target.value)" style="width: 5rem;"
                          required />
                      <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                          (click)="changeQuantity('+',i)">+</a>
                  </td>
                  <td colspan="1">
                      <input type="text" matInput (change)="setPurchasePrice($event.target.value,i)">

                  </td>

                  <td colspan="1">
                      <input type="text" matInput n (change)="setGST($event.target.value,i)"
                          [value]="item.gstRate">

                  </td>

                  <td colspan="1" *ngIf="isShown">
                      <p class="" style="width: 3rem;">{{ item.additionalCess }}% </p>
                  </td>
                  <td colspan="1" *ngIf="isShown">
                      <p class="" style="width: 5rem;">
                          {{ (item.salePrice * item.additionalCess / 100) | currency:'INR':'symbol':'1.2-2'}}
                      </p>
                  </td>
                  <td>

                      <p class="" style="width:100% !important;">
                          {{ item.total | currency:'INR':'symbol':'1.2-2'}}

                      </p>
                  </td>
                  <td>
                      <button type="button" class="text-center btnb" style="width: 3rem!important;"
                          (click)="changeQuantity('rm',i)">
                          <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip"
                              data-bs-placement="top" title="Delete"></span>
                      </button>
                  </td>
              </tr>
          </tbody>
      </table>
  </div>

    <div class="table-responsive mt-2  my-custom-scrollbar d-flex justify-content-end">
      <div class="col-sm-6"></div>
      <div class="col-sm-4 table-bordered my-custom-scrollbar">
        <table class="table table-bordered  my-custom-scrollbar">
          <tr>
            <td class="thWidth p-2" style="text-align:left!important;">Subtotal</td>
            <td class="p-2" style="width: 50px;" style="text-align:right!important;">{{ subTotal |
              currency:'INR':'symbol':'1.2-2'}}</td>
          </tr>
          <tr>
            <td class="thWidth  p-2" *ngIf="gstStateMatch" style="text-align:left!important;">CGST</td>
            <td class="p-2" *ngIf="gstStateMatch" style="width: 50px;" style="text-align:right!important;">{{
              gstRate/2|
              currency:'INR':'symbol':'1.2-2'}}</td>
          </tr>
          <tr>
            <td class="thWidth  p-2" *ngIf="gstStateMatch" style="text-align:left!important;">SGST</td>
            <td class="p-2" style="width:50px;" *ngIf="gstStateMatch" style="text-align:right!important;">{{
              gstRate/2 |
              currency:'INR':'symbol':'1.2-2'}}</td>
          </tr>
          <tr>
            <td class="thWidth  p-2" *ngIf="!gstStateMatch" style="text-align:left!important;">IGST</td>
            <td class="p-2" *ngIf="!gstStateMatch" style="width: 50px;" style="text-align:right!important;">{{
              gstRate/2 + gstRate/2|
              currency:'INR':'symbol':'1.2-2'}}</td>
          </tr>

          <tr>
            <td class="thWidth  p-2" *ngIf="isShown" style="text-align:left!important;">Additional Cess</td>
            <td class="p-2" style="width:50px;" *ngIf="isShown" style="text-align:right!important;">{{
              additionalCess | currency:'INR':'symbol':'1.2-2'}}</td>
          </tr>
          <tr>
            <td class="thWidth  p-2" style="text-align:left!important;">Total</td>
            <td class="p-2" style="width: 50px;" style="text-align:right!important;">{{ total |
              currency:'INR':'symbol':'1.2-2'}}</td>
          </tr>
        </table>
      </div>
      </div>




    <div class="row">
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <mat-form-field style="font-size: 11px" class="example-full-width" appearance="outline">
          <mat-label>Purchase Type</mat-label>
          <mat-select  required placeholder="Purchase Type" formControlName="PurchaseType"  (selectionChange)="getCredit($event)"
          [ngClass]="{
            'is-invalid': (f.PurchaseType.invalid && f.PurchaseType.touched) && submitted }">
            <mat-option value="false">Cash</mat-option>
            <mat-option value="true">Credit</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.PurchaseType.touched) && f.PurchaseType.errors?.required">
          Purchase Type Is Required!
        </mat-error>
      </div>

    </div>
    <div class="row">
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Description</mat-label>
          <textarea matInput rows="5" formControlName="Description" placeholder="Description"></textarea>
        </mat-form-field>
      </div>
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <div class="{{imageSrc ? '':'dash'}} " style="height: 85% !important">
          <i  *ngIf="imageSrc" class="bi bi-x-circle text-danger fs-1 fw-bold"  (click)="cancelImage()"
          data-bs-toggle="tooltip" data-bs-placement="top" title="delete image"
          ></i>

          <div *ngIf="imageSrc1">
            <img  class="m-2" [src]="imageSrc ? imageSrc : ''" (load)="handleImageLoad()"
            [class.loaded]="imageLoaded" class="img-thumbnail text-center" />
           </div>
           <p *ngIf="imageSrc" class="p-2">{{AddReceiptName}}</p>
          <div class="input--file">
            <span *ngIf="!imageSrc"  class="fas fa-camera fs-1 mt-3"  style="padding-left: 45%" (click)="startUpload()">
            </span>
            <input name="Select File" type="file" id="uploadFile"  (change)="handleInputChange($event)" />
            <div  *ngIf="!imageSrc"class="mt-1 pl-0">
              <h2 class="text-bold text-center">Add Receipt</h2>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-12 d-flex p-3 justify-content-end">
      <div class="col-12 d-flex p-3 justify-content-end">
        <a type="submit" class="btn btn-outline-primary" (click)="postPurchase()" style="margin-left: 10px;"
        >Save</a>
      </div>
    </div>
    <ng-template #addParty let-modal>
      <div class="modal-content position-relative">
        <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
          <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" (click)="modalDismiss()"
             aria-label="Close"></a>
        </div>
        <div class="modal-body p-0">
          <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
            <h4 class="mb-1" id="modalExampleDemoLabel">Add New Party</h4>
          </div>
          <form [formGroup]="PartyForm">
            <div class="p-4 pb-0">
              <div class="form-check form-switch mb-0 lh-1 mb-3">
                <input class="form-check-input" type="checkbox" [checked]="isShowGstFields" style="border:1px solid rgb(201, 199, 199) !important;"
                  id="flexSwitchCheckDefault" (click)="customertoggleShow($event.target.checked)" />
                <label class="lab text-nowrap p-1"> As Customer </label>
              </div>

              <div class="row d-flex justify-content-start" *ngIf="!isShowGstFields">
                <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>GST Number</mat-label>
                    <input matInput required formControlName="gstIn" (change)="getGstNumber($event.target.value)"
                      maxlength="15" minlength="15"
                      pattern="^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$"
                      placeholder="GST Number" value="" style="text-transform:uppercase">
                  </mat-form-field>
                  <mat-error *ngIf="(submittedParty ||fp.gstIn.touched) && fp.gstIn.errors?.required">
                    Enter a valid GSTIN.
                  </mat-error>

                  <mat-error *ngIf="(submittedParty ||fp.gstIn.touched) && fp.gstIn.errors?.pattern || fp.gstIn.errors?.minlength">
                    Enter a valid GSTIN.
                  </mat-error>
                  <mat-error *ngIf="(submittedParty ||fp.gstIn.touched) && fp.gstIn.errors?.maxlength">
                    Enter a valid GSTIN.
                  </mat-error>

                </div>
                <div style=" font-size: 12px" class=" col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>GST Type</mat-label>
                    <mat-select matNativeControl required formControlName="gstType" disableOptionCentering
                      placeholder="GST Type" [disabled]="!isDisabledGST">
                      <mat-option value="REGULAR">Regular</mat-option>
                      <mat-option value="COMPOSITE">Composite</mat-option>
                      <mat-option value="EXEMPTED">Exempted</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-error *ngIf="(submittedParty ||fp.gstType.touched) && fp.gstType.errors?.required">
                    GST Type Is Required!
                  </mat-error>
                </div>
              </div>

              <div class="row d-flex justify-content-start">
                <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Party Name</mat-label>
                    <input matNativeControl required matInput placeholder="Party Name" formControlName="partyName"  [ngClass]="{
                        'is-invalid': (fp.partyName.invalid && fp.partyName.touched) && submittedParty }">
                  </mat-form-field>
                  <mat-error class="text-danger" *ngIf="(submittedParty || fp.partyName.touched)">
                    <mat-error *ngIf="(submittedParty || fp.partyName.touched) && fp.partyName.errors?.required">
                      Party Name Is Required!
                    </mat-error>
                  </mat-error>

                </div>
                <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Mobile Number</mat-label>
                    <input type="text" placeholder="Mobile Number" matInput formControlName="mobile" minlength="10" maxLength="10" ngxOnlyNumbers
                      [ngClass]="{
                          'is-invalid': (fp.mobile.invalid && fp.mobile.touched) && submittedParty }">
                  </mat-form-field>

                  <mat-error class="text-danger" *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.pattern">
                    Enter a valid mobile number.
                  </mat-error>
                  <mat-error class="text-danger" *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.required">
                    Mobile Number is required.
                  </mat-error>
                  <mat-error *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.maxlength">
                    Mobile Number must be of 10-digits
                  </mat-error>
                  <mat-error *ngIf="(submittedParty || fp.mobile.touched) && fp.mobile.errors?.minlength">
                    Mobile Number must be of 10-digits
                  </mat-error>
                </div>
                <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>E-Mail</mat-label>
                    <input type="email" placeholder="e-mail" matInput formControlName="email" [ngClass]="{
                          'is-invalid': (fp.email.invalid && fp.email.touched) && submittedParty }">
                  </mat-form-field>
                  <mat-error class="text-danger"
                    *ngIf="(submittedParty || fp.email.touched) && fp.email.errors?.pattern && fp.email.errors?.email">
                    Enter a valid email id.
                  </mat-error>
                  <mat-error class="text-danger" *ngIf="(submittedParty || fp.email.touched) && fp.email.errors?.required">
                    Email id is required.
                  </mat-error>
                </div>
              </div>

              <div class="row d-flex justify-content-start">
                <div class="col-lg-4 col-sm-12 col-md-6">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Billing Address</mat-label>
                    <textarea matInput required formControlName="billingAddress" placeholder="Enter your address"
                      [ngClass]="{
                          'is-invalid': (fp.billingAddress.invalid && fp.billingAddress.touched) && submittedParty }"></textarea>
                  </mat-form-field>
                  <mat-error class="text-danger" *ngIf="(submittedParty || fp.billingAddress.touched)">
                    <mat-error *ngIf="(submittedParty || fp.billingAddress.touched) && fp.billingAddress.errors?.required">
                      Billing Address Is Required!
                    </mat-error>
                  </mat-error>
                </div>

                <div class="col-lg-4 col-sm-12 col-md-6" *ngIf="isShippingAddressinModal">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Shipping Address</mat-label>
                    <textarea matInput formControlName="shippingAddress" placeholder="Enter your address"></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-check form-check-inline mb-2">
                <label class="form-check-label fs--1" for="inlineCheckbox1">Add Shipping Address</label>
                <input class="form-check-input fs--1" id="inlineCheckbox1" type="checkbox" style="border:1px solid rgb(201, 199, 199) !important;"
                  (change)="showShippingAddressinModal($event.target.checked)" />
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-outline-primary" (click)="modalDismiss()">Close</button>
          <button class="btn btn-outline-primary" (click)="addNewParty()" data-bs-dismiss="modal">Add
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
</form> -->
