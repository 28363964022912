import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-calender',
  templateUrl: './manage-calender.component.html',
  styleUrls: ['./manage-calender.component.scss']
})
export class ManageCalenderComponent implements OnInit {
  manageCalenderData=[
    {
      "id":1,
     "Name":"Rajeev Chotai",
     "schedule":"Can Schedule For",
     "Users":"Themself/owner/tester"
    },
    {
      "id":2,
     "Name":"Dr.root",
     "schedule":"Can Schedule For",
     "Users":"Themself"
    },
    {
      "id":3,
     "Name":"Workflow User",
     "schedule":"Can Schedule For",
     "Users":"Themself"
    },
    {
      "id":4,
     "Name":"Test Physisciian",
     "schedule":"Can Schedule For",
     "Users":"Themself"
    },
    {
      "id":5,
     "Name":"Test Owner",
     "schedule":"Can Schedule For",
     "Users":"Themself"
    },
  
  ]
  constructor() { }

  ngOnInit(): void {
  }
 

}
