import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/_helpers/constant';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public hide: boolean = false;
  public logoImage = Constants.images.defaultLogoImage;
  public isSearchBar: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }
  showSearchBar(){
    this.isSearchBar = !this.isSearchBar
  }
}
