<div class="container-fluid p-0 m-0" >

    <!-- <div class="row d-flex flex-center">
      <div class="col-xs-10 col-sm-10 col-md-8 col-lg-5 col-xl-3"></div>
      <div class="col-md-4 col-lg-1 col-xl-4"></div>
      <div id = "saleSearchPrint" class="col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3" style = "padding-left: 3rem;">
        <input type="search" id="searchInput" class="form-control pe-5 mb-2" placeholder="Invoice No"
        [(ngModel)]="searchKey" (change)="getAllsaleinvoicebySearch(searchKey)">
      </div>

      <div class="navOption-btns col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-2 mx-0 px-0 mb-2" style = "display:flex;flex-direction:row;justify-content:space-around !important">
        <button id = "saleButtonPrint1" class="btn" type="button" (click)="openDialog()">
          <i class="fas fa-plus me-2"> </i>Sale-Invoice
        </button>

        <button id = "saleButtonPrint2" class="btn" (click)="downlaodAllData()">
            <i class="fas fa-download"></i>
        </button>
      </div>
    </div> -->

    <div class="row headerButtons mb-3" id="showoptionHide">
      <div class = "flex-item searchBar">
        <input type="search" id="searchInput" class="form-control pe-5 my-2" placeholder="Invoice #"
        [(ngModel)]="searchKey" (change)="getAllsaleinvoicebySearch(searchKey)">
      </div>
      <div class="navOption-btns flex-item formButtons mx-0">
        <button id = "saleButtonPrint1" class="btn text-nowrap my-2" type="button" (click)="openDialog()">
          <i class="fas fa-plus me-2"> </i>Sale-Invoice
        </button>

        <button id = "saleButtonPrint2" class="btn my-2" (click)="downlaodAllData()">
            <i class="fas fa-download"></i>
        </button>
      </div>
    </div>

    <!-- <hr class="my-2 py-0" /> -->

    <div style="width:100% ; overflow-x: auto;" id = "saleTablePrint">
        <table class="table table-responsive table-bordered fs--1 mb-0" *ngIf="!isArabic">
            <thead class="text-600">
                <tr>
                    <!-- <th scope="col">
                        <input type="checkbox" />
                    </th> -->
                    <th class="text-nowrap" style="vertical-align:middle;width:200px !important;">Invoice #</th>
                    <!-- <th scope="col">Customer No.<th> -->
                    <th class="text-nowrap" style="vertical-align:middle;text-align: right;width:245px !important;">GST Amount</th>
                    <th class="text-nowrap" style="vertical-align:middle;text-align: right;width:245px !important;">Total Bill</th>
                    <th class="text-nowrap" style="width:auto !important;"></th>
                </tr>
            </thead>
            <tbody>

                <tr class="btn-reveal-trigger text-left" *ngFor="let item of invoiceData; let i = index"
                    style="color: #676398!important;"
                    [ngStyle]="{'background-color': item.isDeleted ? '#c7c2de' : (i % 2 === 0 ? '#f2f2f2' : '#fff')}">
                    <!-- <td scope="row" style="vertical-align:middle;">
                        <input type="checkbox" />
                    </td> -->
                    <td class="text-nowrap" style="vertical-align:middle;">{{ item.invoiceNo ? item.invoiceNo : '-'}}</td>
                    <!-- <td class="text-capitalize">{{ item.customerName }}</td> -->
                    <td class="text-nowrap" style="vertical-align:middle;text-align:right;">
                      ₹&nbsp;{{ item.billGstAmount | number:'1.2-2'}}
                      <!-- {{ item.billGstAmount | currency:'INR':'symbol':'1.2-2' }}               -->
                    </td>
                    <td class="text-nowrap" style="vertical-align:middle;text-align:right;">
                      ₹&nbsp;{{ item.totalBillAmount | number:'1.2-2'}}
                      <!-- {{ item.totalBillAmount| currency:'INR' }} -->
                    </td>
                    <td class="text-nowrap" style="vertical-align:middle;white-space:nowrap;text-align:center;">
                        <div class="">

                            <button class="btn btnp me-2" type="button" data-bs-toggle="modal"
                                data-bs-target="#invoice-modal2" (click)="getReceiptById(item.id)">
                                <span class="bi-printer icon-color iconFontSize" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="print"></span>
                            </button>
                            <!-- <span class="m-1" text-center></span>
                            <button class="btn btnp me-2" type="button">
                                <span class="bi bi-eye icon-color fs-2" data-bs-toggle="tooltip"
                                   data-bs-placement="top" title="View"></span>
                           </button> -->
                           <!-- <span class="m-1" text-center></span>
                            <button class="btn btnp me-2">
                                <span class="bi-pencil-square icon-color fs-1" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Edit"></span>

                            </button>
                            <span class="m-1" text-center></span>
                            <button class="btn btnp me-2" data-bs-toggle="modal" data-bs-target="#errorDelete-modal">
                                <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip" data-bs-placement="top"
                                    title="Delete" style="color: red;"></span>

                            </button> -->

                        </div>


                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="invoiceData == 0">
              <tr class="text-center">
                <td colspan="12">
                    <img class="image-responsive" src="assets/img/icons/spot-illustrations/GST No Transaction.png" width="124" height="142" alt="">
                    <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Data Found.</h5>

                    <!-- <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png" alt="notfound1" width="200" height="150" /> -->
                    <!-- <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Sales Invoice Details Added. Please Add Sales Invoice Details To Be Displayed Here.
                    <span>
                      <a class="nav-link" (click)="openDialog()">Add New Sales Invoice
                          Details</a>
                    </span>
                  </h5> -->
                </td>
              </tr>
                <!-- <tr class="text-center">
                    <td colspan="5">
                        <h5 class="text-nowrap">No Sales Invoice Details Added. Please Add Sales Invoice Details To Be Displayed Here. <span>
                                <a class="nav-link" (click)="openDialog()">Add New Sales Invoice
                                    Details</a>
                            </span></h5>
                    </td>
                </tr> -->
            </tbody>
        </table>

        <!-- UAE -->
        <table class="table table-responsive table-bordered fs--1 mb-0" *ngIf="isArabic">
            <thead class="text-600">
                <tr>
                    <th class="text-nowrap" style="vertical-align:middle;width:200px !important;">Invoice #</th>
                    <th class="text-nowrap" style="vertical-align:middle;text-align: right;width:245px !important;">{{lang.GST_Amount}}</th>
                    <th class="text-nowrap" style="vertical-align:middle;text-align: right;width:245px !important;">Total Bill</th>
                    <th class="text-nowrap" style="width:auto !important;"></th>
                </tr>
            </thead>
            <tbody>

                <tr class="btn-reveal-trigger text-left" *ngFor="let item of invoiceData; let i = index"
                    style="color: #676398!important;"
                    [ngStyle]="{'background-color': item.isDeleted ? '#c7c2de' : (i % 2 === 0 ? '#f2f2f2' : '#fff')}">

                    <td class="text-nowrap" style="vertical-align:middle;">{{ item.invoiceNo ? item.invoiceNo : '-'}}</td>
                    <td class="text-nowrap" style="vertical-align:middle;text-align:right;">
                      AED&nbsp;{{ item.billGstAmount | number:'1.2-2'}}
                    </td>
                    <td class="text-nowrap" style="vertical-align:middle;text-align:right;">
                      AED&nbsp;{{ item.totalBillAmount | number:'1.2-2'}}
                    </td>
                    <td class="text-nowrap" style="vertical-align:middle;white-space:nowrap;text-align:center;">
                        <div class="">

                            <button class="btn btnp me-2" type="button" data-bs-toggle="modal"
                                data-bs-target="#invoice-modal2" (click)="getReceiptById(item.id)">
                                <span class="bi-printer icon-color iconFontSize" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="print"></span>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="invoiceData == 0">
              <tr class="text-center">
                <td colspan="12">
                    <img class="image-responsive" src="assets/img/icons/spot-illustrations/GST No Transaction.png" width="124" height="142" alt="">
                    <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Data Found.</h5>
                </td>
              </tr>
            </tbody>
        </table>


        <mat-paginator class="float-right mt-2" *ngIf="invoiceData.length > 0" [pageSize]="partyPageSize"
            [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo" showFirstLastButtons
            (page)="handlePage($event)" aria-label="Select page">
        </mat-paginator>

    </div>
</div>
<!-- <div class="card mt-5 mb-3">
    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
            Sales Invoice List
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>
    <div class="card-body p-0 m-2 overflow-hidden">

        <div class="row">
            <div class="col-lg-3 col-sm-12 col-md-3 col-xl-3" style="margin-left: 12px !important; font-size: 12px">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Search</mat-label>
                    <input type="text" matInput placeholder="Search" [(ngModel)]="searchKey"
                        (change)="getAllsaleinvoicebySearch(searchKey)">
                    <span>
                        <mat-icon class="iconcalender p-1">search</mat-icon>
                    </span>
                </mat-form-field>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-striped table-bordered bg-white">
                <thead>
                    <tr class="btn-reveal-trigger">
                        <th scope="col">#</th>
                        <th scope="col">Invoice No.</th>

                        <th scope="col">GST Amount</th>
                        <th scope="col">Total Bill</th>
                        <th scope="col">Print</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let item of invoiceData; let i = index">
                        <td>{{ i+1 }}</td>
                        <td>{{ item.invoiceNo ? item.invoiceNo : '-'}}</td>

                        <td>{{ item.billGstAmount |currency:'INR':'symbol':'1.2-2' }}</td>
                        <td>{{ item.totalBillAmount| currency:'INR' }}</td>
                        <td>
                            <button class="btnb btnp" type="button" data-bs-toggle="modal"
                                data-bs-target="#invoice-modal2" (click)="getReceiptById(item.id)">
                                <span class="bi-printer icon-color fs-1" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="print"></span>

                            </button>

                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="invoiceData == 0">
                    <tr class="text-center">
                        <td colspan="5">
                            <h5 class="text-nowrap">No Sales Invoice Details Added. Please Add Sales Invoice Details To
                                Be Displayed Here. <span>
                                    <a class="nav-link" routerLink="/pages/sales-invoice">Add New Sales Invoice
                                        Details</a>
                                </span></h5>
                        </td>
                    </tr>
                </tbody>
            </table>
            <mat-paginator class="float-right mt-2" *ngIf="invoiceData.length > 0" [pageSize]="partyPageSize"
                [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo"
                (page)="handlePage($event)" aria-label="Select page">
            </mat-paginator>

        </div>
    </div>
    <div *ngIf="!invoiceData" class="card-footer border-top d-flex justify-content-center">
        <button class="btn btn-falcon-default btn-sm me-2" type="button" disabled="disabled" data-bs-toggle="tooltip"
            data-bs-placement="top" title="Prev"><span class="fas fa-chevron-left"></span></button><a
            class="btn btn-sm btn-falcon-default text-primary me-2" href="#!">1</a><a
            class="btn btn-sm btn-falcon-default me-2" href="#!">2</a><a class="btn btn-sm btn-falcon-default me-2"
            href="#!"><span class="fas fa-ellipsis-h"></span></a><a class="btn btn-sm btn-falcon-default me-2"
            href="#!">35</a>
        <button class="btn btn-falcon-default btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="top"
            title="Next"><span class="fas fa-chevron-right"></span></button>
    </div>
</div> -->
<!-- Print Modal -->
<!-- <ng-template #invoicePopup let-modal> -->

<div class="modal fade" #closebutton id="invoice-modal2" tabindex="-1" role="dialog" aria-hidden="true" *ngIf="!isArabic">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal" (click)="closeModal()" id = "closeModalPrint">
                  </button>
            </div>
            <!-- <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-md">
                        </div>
                        <div class="col-auto">
                            <button id="printPageButton" class="btn btn-falcon-default btn-sm me-1 mb-2 mb-sm-0"
                                type="button" (click)="onPrint()"><span class="fas fa-print me-1">
                                </span>Print</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4"></div>
                        <div class="col-4">
                            <h3  class="color-black" style = "font-weight: bold;">Sales Invoice</h3>
                        </div>
                        <div class="col-4">
                            <div style="font-size: 12px" class="text-end">
                                Original for Recipient
                                <mat-checkbox class="">
                                </mat-checkbox>
                            </div>
                            <div style="font-size: 12px" class="text-end">
                                Duplicate for Transporator
                                <mat-checkbox class="">
                                </mat-checkbox>
                            </div>
                            <div style="font-size: 12px" class="text-end">
                                Triplicator for Supplier
                                <mat-checkbox class="">
                                </mat-checkbox>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="card">
                        <div class="card-body">
                            <div class="row bordered" id="panel">
                                <div class="col-sm-6  "></div>
                                <div class="col-sm-6  text-sm-end mt-1 mt-sm-0 justify-content-end ">
                                    <h5 class="fw-bold" style="margin-right: 10px !important;">{{tradeName}}</h5>
                                    <p class="fs--1 mb-0 " style="margin-right: 10px !important;">
                                        {{addressDetails.homeno}} {{addressDetails.address}}
                                        {{addressDetails.landmark}}<br />{{addressDetails.state}}
                                        {{addressDetails.country}} <br /> {{addressDetails.pincode}}
                                    </p>
                                  </div>
                                <div class="col-12">
                                </div>
                            </div>
                            <div class="row bordered table-responsive scrollbar fs--1 borderbottom2 ">
                                <table class="table table-striped tablePrint" style="font-size: 11px!important;">
                                    <thead class="inner-model-table-heading">
                                        <tr class="text-white dark__bg-1000">
                                            <th class="borderbottom2" style="width: 33% !important;">Bill To</th>
                                            <th class="borderLeft borderbottom2 " style="width: 33% !important;">Ship To
                                            </th>
                                            <th class="borderLeft borderbottom2" style="width: 33% !important;"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="align-middle" style="width: 33% !important;">
                                                <p>{{salesData.customerName}}</p>
                                                <p>{{salesData.billingAddress}}</p>
                                            </td>
                                            <td class="align-middle text-center borderLeft"
                                                style="width: 33% !important;">
                                                {{salesData.shippingAddress}}

                                            </td>
                                            <td class="align-middle  borderLeft "
                                                style="width: 33% !important;text-align: left !important;">
                                                <p> Place of Supply: {{salesData.placeOfSupply}}</p>
                                                <p>Invoice No.:{{salesData.invoiceNo}}</p>
                                                <p> Date:{{salesData.invoiceDate| date:"dd MMM YYYY"| uppercase}}</p>
                                                <p *ngIf="salesData.ewayBillNo">E-way Bill
                                                    number:{{salesData.ewayBillNo}}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class=" row  borderbottom2 table-responsive scrollbar fs--1">
                                <table class="table table-striped  tablePrint" style="font-size: 11px!important;">
                                    <thead class="inner-model-table-heading">
                                        <tr class="text-white dark__bg-1000">
                                            <th class="borderedTopNone">#</th>
                                            <th class="borderedTopNone">Item Name</th>
                                            <th class="borderedTopNone">HSN/ SAC</th>
                                            <th class="borderedTopNone">Quantity</th>
                                            <th class="borderedTopNone">Unit</th>
                                            <th class="borderedTopNone">Price/ Unit</th>
                                            <th class="borderedTopNone">Taxable amount</th>
                                            <th *ngIf="gstStateMatch" class="borderedTopNone">CGST</th>
                                            <th *ngIf="gstStateMatch" class="borderedTopNone">SGST</th>
                                            <th *ngIf="!gstStateMatch" class="borderedTopNone">IGST</th>
                                            <th class="borderedTopNone">Support </th>
                                            <th class="borderedTopNone">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let i of saleProductData; let index = index">
                                            <td class="bordered">{{ index+1 }}</td>
                                            <td class="bordered text-capitalize">{{i.itemName}}</td>
                                            <td class="bordered">{{i.barcode}}</td>
                                            <td class="bordered">{{i.quantity}}</td>
                                            <td class="bordered">{{i.unit}}</td>
                                            <td class="bordered">{{i.pricePerUnit| currency:'INR':'symbol':'1.2-2'}}
                                            </td>
                                            <td class="bordered"> {{i.totalPrice - billGstAmount |
                                                currency:'INR':'symbol':'1.2-2'}}</td>
                                            <td *ngIf="gstStateMatch" class="bordered"> {{ (billGstAmount / 2) |
                                                currency:'INR':'symbol':'1.2-2'}}</td>
                                            <td *ngIf="gstStateMatch" class="bordered"> {{ (billGstAmount / 2) |
                                                currency:'INR':'symbol':'1.2-2'}}</td>
                                            <td *ngIf="!gstStateMatch" class="bordered">{{billGstAmount/2 +
                                                billGstAmount/2| currency:'INR':'symbol':'1.2-2'}}</td>
                                            <td class="bordered">{{i.supportType}}-{{i.supportDuration}} Months</td>
                                            <td class="bordered">{{i.finalAmount}}</td>

                                        </tr>
                                        <tr>
                                            <td class="bordered"></td>
                                            <td class="bordered">Total</td>
                                            <td class="bordered"></td>
                                            <td class="bordered"></td>
                                            <td class="bordered"></td>
                                            <td class="bordered"></td>
                                            <td class="bordered"></td>
                                            <td *ngIf="gstStateMatch" class="bordered"></td>
                                            <td *ngIf="gstStateMatch" class="bordered"></td>
                                            <td *ngIf="!gstStateMatch" class="bordered"></td>
                                            <td class="bordered"></td>
                                            <td class="bordered">{{salesData.totalBillAmount}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row ">
                                <div class="col-6  borderbottom2 borderLeft">
                                    <div class="table-responsive scrollbar fs--">
                                        <table class="table table-striped tablePrint"
                                            style="font-size: 11px!important;">
                                            <thead class="inner-model-table-heading">
                                                <tr class="text-white dark__bg-1000">
                                                    <th class=""> Tax Type</th>
                                                    <th>Taxable Amount</th>
                                                    <th>Rate</th>
                                                    <th>Tax Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let i of saleProductData; let index = index">
                                                    <td class="align-middle text-center">
                                                        {{salesData.supplyType}}
                                                    </td>
                                                    <td class="align-middle text-center">
                                                        {{i.totalPrice - billGstAmount|
                                                        currency:'INR':'symbol':'1.2-2'}}
                                                    </td>
                                                    <td class="align-middle text-center">
                                                        {{i.gst}}
                                                    </td>
                                                    <td class="align-middle text-center">
                                                        {{totalGST |currency:'INR':'symbol':'1.2-2' }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div class="col-6">
                                    <table class="table tablePrint" style="font-size: 11px!important;">
                                        <tr class = "inner-model-table-heading">
                                            <th class="thWidth borderLeft p-3" style="text-align:left!important">
                                                Amounts:</th>
                                            <th class="borderLeft borderbottom2 borderRIGHT p-3" style="width: 50px;"
                                                style="text-align:right!important">In Rs:</th>
                                        </tr>
                                        <tr>
                                            <td class="thWidth bordered p-2" style="text-align:left!important">Total
                                                Base Price</td>
                                            <td class="borderedLeftNone p-2" style="width: 50px;"
                                                style="text-align:right!important">{{totalBillAmount -
                                                billGstAmount|currency:'INR':'symbol':'1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="thWidth bordered p-2 " style="text-align:left!important">Total
                                            </td>
                                            <td class=" borderedLeftNone p-2" style="width:50px;"
                                                style="text-align:right!important">
                                                {{ totalBillAmount | currency:'INR':'symbol':'1.2-2'}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="thWidth bordered p-2" style="text-align:left!important">Received
                                            </td>
                                            <td class="borderedLeftNone p-2" style="width: 50px;"
                                                style="text-align:right!important">
                                                {{ salesData.received| currency:'INR':'symbol':'1.2-2'}}
                                            </td>
                                        </tr>

                                        <tr class="borderbottom2">
                                            <td class="thWidth bordered p-2" style="text-align:left!important">Balance
                                            </td>
                                            <td class="bordered p-2" style="width: 50px;"
                                                style="text-align:right!important">
                                                {{ salesData.balance| currency:'INR':'symbol':'1.2-2'}}
                                            </td>
                                        </tr>
                                    </table>

                                </div>
                            </div>
                            <div class="row borderLeft" style="font-size: 11px!important;">
                                <div class="col-6 ">
                                    <p style="width: 100%!important;" class="inner-model-table-heading p-1">
                                        Invoice Amount In Words</p>
                                    <p style="padding-left: 5px !important;">{{ finalAmount }}
                                    </p>
                                </div>
                                <div class="col-6 borderLeft borderRIGHT">
                                    <p class="p-1"></p>
                                    <p>
                                    </p>
                                </div>
                            </div>
                            <div class="row borderedBottom" style="font-size: 11px!important;">
                                <div class="col-6 ">
                                    <p class="inner-model-table-heading p-1">Terms and Conditions:
                                    </p>
                                    <p class="pb-2" style="padding-left: 5px !important;">Thanks for doing business with
                                        us.
                                    </p>
                                </div>
                                <div class="col-6 borderLeft">
                                    <p class="text-center">{{BusinessName}}</p>
                                    <p class="text-center pt-3">Authorized Signatory</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="modal-body p-0">
              <div class="py-3 ps-4 pe-4">
                  <!-- <div class="row justify-content-between align-items-center">
                      <div class="col-lg-6">

                      </div>
                      <div class="col-lg-6">

                      </div>
                      <div class="col-auto">
                          <button id="printPageButton" class="btn btn-falcon-default btn-sm me-1 mb-2 mb-sm-0"
                              type="button" (click)="onPrint()"><span class="fas fa-print me-1">
                              </span>Print</button>
                      </div>
                  </div> -->
                  <!-- <div class="row">
                      <div class="col-4"></div>
                      <div class="col-4">
                          <h3  class="color-black" style = "font-weight: bold;">Sales Invoice</h3>
                      </div>
                      <div class="col-4">
                          <div style="font-size: 12px" class="text-end">
                              Original for Recipient
                              <mat-checkbox class="">
                              </mat-checkbox>
                          </div>
                          <div style="font-size: 12px" class="text-end">
                              Duplicate for Transporator
                              <mat-checkbox class="">
                              </mat-checkbox>
                          </div>
                          <div style="font-size: 12px" class="text-end">
                              Triplicator for Supplier
                              <mat-checkbox class="">
                              </mat-checkbox>
                          </div>

                      </div>
                  </div> -->
              </div>
              <div class="container"  id="panel">
                  <div class="card">
                      <div class="card-body">
                          <div class="row my-2" >
                              <div class="col-6 pt-2">
                                <p style = "color: red;font-weight: 600;">{{tradeName ? tradeName : "NA"}}</p>
                                <p>Mobile:&nbsp;{{ mobile ? mobile : 'NA'}}</p>
                              </div>
                              <div class="col-6">
                                <div class="row headerAlignment">
                                  <div class="col-6" style = "font-weight: 600;">
                                    TAX INVOICE
                                  </div>
                                  <div class="col-6">
                                    <button style = "float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;" id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0"
                                      type="button" (click)="onPrint()"><span class="fas fa-print me-1">
                                      </span>Print
                                    </button>
                                    <div id = "originalForReceiptPrint">
                                      ORIGINAL FOR RECIPIENT
                                    </div>
                                  </div>
                                </div>
                                <div class="row headerAlignment">
                                  <div class="col-6">
                                    Invoice #<span style = "float: right;">:</span>
                                  </div>
                                  <div class="col-6" style = "text-align: end;">
                                    {{invoiceNo}}
                                  </div>
                                </div>
                                <div class="row headerAlignment">
                                  <div class="col-6">
                                    Invoice Date<span style = "float: right;">:</span>
                                  </div>
                                  <div class="col-6" style = "text-align: end;">
                                    {{invoiceDate | date:"dd MMM YYYY" | uppercase }}
                                  </div>
                                </div>
                                <div class="row headerAlignment">
                                  <div class="col-6">
                                    Due Date<span style = "float: right;">:</span>
                                  </div>
                                  <div class="col-6" style = "text-align: end;">
                                    {{ dueDate ? dueDate : 'NA' }}
                                  </div>
                                </div>
                              </div>

                              <!-- <div class="col-sm-6  text-sm-end mt-1 mt-sm-0 justify-content-end ">
                                  <h5 class="fw-bold" style="margin-right: 10px !important;">{{tradeName}}</h5>
                                  <p class="fs--1 mb-0 " style="margin-right: 10px !important;">
                                      {{addressDetails.homeno}} {{addressDetails.address}}
                                      {{addressDetails.landmark}}<br />{{addressDetails.state}}
                                      {{addressDetails.country}} <br /> {{addressDetails.pincode}}
                                  </p>
                                </div>
                              <div class="col-12">
                              </div> -->
                          </div>
                          <!-- <div class="row bordered table-responsive scrollbar fs--1 borderbottom2 ">
                              <table class="table table-striped tablePrint" style="font-size: 11px!important;">
                                  <thead class="inner-model-table-heading">
                                      <tr class="text-white dark__bg-1000">
                                          <th class="borderbottom2" style="width: 33% !important;">Bill To</th>
                                          <th class="borderLeft borderbottom2 " style="width: 33% !important;">Ship To
                                          </th>
                                          <th class="borderLeft borderbottom2" style="width: 33% !important;"></th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr>
                                          <td class="align-middle" style="width: 33% !important;">
                                              <p>{{salesData.customerName}}</p>
                                              <p>{{salesData.billingAddress}}</p>
                                          </td>
                                          <td class="align-middle text-center borderLeft"
                                              style="width: 33% !important;">
                                              {{salesData.shippingAddress}}

                                          </td>
                                          <td class="align-middle  borderLeft "
                                              style="width: 33% !important;text-align: left !important;">
                                              <p> Place of Supply: {{salesData.placeOfSupply}}</p>
                                              <p>Invoice No.:{{salesData.invoiceNo}}</p>
                                              <p> Date:{{salesData.invoiceDate| date:"dd MMM YYYY"| uppercase}}</p>
                                              <p *ngIf="salesData.ewayBillNo">E-way Bill
                                                  number:{{salesData.ewayBillNo}}</p>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div> -->
                          <div class = "row mt-2 mb-4">
                            <div class="col-6">
                              <div style="display: flex;flex-direction: column;justify-content: flex-start;">
                                <span class = "inner-model-table-heading p-2 my-2" style = "width: 100px;font-weight: 600;">BILL TO</span>
                                <span style = "font-weight: 600;">{{salesData.customerName ? salesData.customerName : "NA"}}</span>
                                <span>{{ billingAddress ? billingAddress : "NA"}}</span>
                                <span>Mobile: &nbsp;{{ salesData.mobile ? salesData.mobile : "NA" }}</span>
                                <span>GSTIN: &nbsp;{{gstinNo ? gstinNo : "NA"}}</span>
                                <span>State: &nbsp;{{ placeOfSupply ? placeOfSupply : "NA" }}</span>
                              </div>
                            </div>
                            <div class="col-6">
                              <div style="display: flex;flex-direction: column;justify-content: flex-start;">
                                <span class = "inner-model-table-heading p-2 my-2" style = "width: 100px;font-weight: 600;">SHIP TO</span>
                                <span style = "font-weight: 600;">{{salesData.customerName ? salesData.customerName : "NA"}}</span>
                                <span>{{ shippingAddress ? shippingAddress : "NA"}}</span>
                              </div>
                            </div>
                          </div>
                          <!-- <div class=" row  borderbottom2 table-responsive scrollbar fs--1">
                              <table class="table table-striped  tablePrint" style="font-size: 11px!important;">
                                  <thead class="inner-model-table-heading">
                                      <tr class="text-white dark__bg-1000">
                                          <th class="borderedTopNone">#</th>
                                          <th class="borderedTopNone">Item Name</th>
                                          <th class="borderedTopNone">HSN/ SAC</th>
                                          <th class="borderedTopNone">Quantity</th>
                                          <th class="borderedTopNone">Unit</th>
                                          <th class="borderedTopNone">Price/ Unit</th>
                                          <th class="borderedTopNone">Taxable amount</th>
                                          <th *ngIf="gstStateMatch" class="borderedTopNone">CGST</th>
                                          <th *ngIf="gstStateMatch" class="borderedTopNone">SGST</th>
                                          <th *ngIf="!gstStateMatch" class="borderedTopNone">IGST</th>
                                          <th class="borderedTopNone">Support </th>
                                          <th class="borderedTopNone">Amount</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr *ngFor="let i of saleProductData; let index = index">
                                          <td class="bordered">{{ index+1 }}</td>
                                          <td class="bordered text-capitalize">{{i.itemName}}</td>
                                          <td class="bordered">{{i.barcode}}</td>
                                          <td class="bordered">{{i.quantity}}</td>
                                          <td class="bordered">{{i.unit}}</td>
                                          <td class="bordered">{{i.pricePerUnit| currency:'INR':'symbol':'1.2-2'}}
                                          </td>
                                          <td class="bordered"> {{i.totalPrice - billGstAmount |
                                              currency:'INR':'symbol':'1.2-2'}}</td>
                                          <td *ngIf="gstStateMatch" class="bordered"> {{ (billGstAmount / 2) |
                                              currency:'INR':'symbol':'1.2-2'}}</td>
                                          <td *ngIf="gstStateMatch" class="bordered"> {{ (billGstAmount / 2) |
                                              currency:'INR':'symbol':'1.2-2'}}</td>
                                          <td *ngIf="!gstStateMatch" class="bordered">{{billGstAmount/2 +
                                              billGstAmount/2| currency:'INR':'symbol':'1.2-2'}}</td>
                                          <td class="bordered">{{i.supportType}}-{{i.supportDuration}} Months</td>
                                          <td class="bordered">{{i.finalAmount}}</td>

                                      </tr>
                                      <tr>
                                          <td class="bordered"></td>
                                          <td class="bordered">Total</td>
                                          <td class="bordered"></td>
                                          <td class="bordered"></td>
                                          <td class="bordered"></td>
                                          <td class="bordered"></td>
                                          <td class="bordered"></td>
                                          <td *ngIf="gstStateMatch" class="bordered"></td>
                                          <td *ngIf="gstStateMatch" class="bordered"></td>
                                          <td *ngIf="!gstStateMatch" class="bordered"></td>
                                          <td class="bordered"></td>
                                          <td class="bordered">{{salesData.totalBillAmount}}</td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div> -->
                          <div class=" row table-responsive scrollbar fs--1" style = "padding: 0px 10px;">
                            <table class="table  tablePrint" style="font-size: 14px!important;">
                                <thead class="inner-model-table-heading printHead" style="font-size: 14px!important;">
                                    <tr class="text-white dark__bg-1000">
                                        <th class="borderedTopNone" style="text-align: left;font-size: 14px!important;">#</th>
                                        <th class="borderedTopNone" style="text-align: left;width:20rem;font-size: 14px!important;">ITEMS</th>
                                        <th class="borderedTopNone" style="text-align: right;font-size: 14px!important;">HSN</th>
                                        <th class="borderedTopNone" style="text-align: right;font-size: 14px!important;">QTY.</th>
                                        <th class="borderedTopNone" style = "text-align: right;font-size: 14px!important;">RATE</th>
                                        <th class="borderedTopNone" style = "text-align: right;font-size: 14px!important;">TAX</th>
                                        <th class="borderedTopNone" style = "text-align: right;font-size: 14px!important;">AMOUNT</th>
                                    </tr>
                                </thead>
                                <tbody class = "printBody" style = "max-height: 200px;font-size: 14px!important;">
                                    <tr *ngFor="let i of saleProductData; let index = index">
                                        <td class="bordered" style = "text-align: left;font-size: 14px!important;">{{ index+1 }}</td>
                                        <td class="bordered" >
                                          <span style = "text-transform: uppercase;font-size: 14px!important;">{{i.itemName}}</span>
                                        </td>
                                        <td class="bordered" style = "text-align: right;font-size: 14px!important;">{{ hsn }}</td>
                                        <td class="bordered" style = "text-align: right;white-space: nowrap;font-size: 14px!important;">{{i.quantity}} {{i.unit.substr(i.unit.length - 5)}}</td>
                                        <td class="bordered" style = "text-align: right;font-size: 14px!important;">
                                          <!-- {{ (i.quantity * (i.pricePerUnit / (1 + (i.gst/100)))) | currency:'INR':'symbol':'1.2-2'}} -->
                                            ₹&nbsp;{{ (i.quantity * (i.pricePerUnit / (1 + (i.gst/100)))) | number:'1.2-2'}}
                                          </td>
                                        <td class="bordered" style = "display: flex;flex-direction:column;align-items:flex-end;justify-content:flex-end;font-size: 14px!important;">
                                          <span>
                                            <!-- {{ (i.gstAmount/2 + i.gstAmount/2)| currency:'INR':'symbol':'1.2-2'}} -->
                                            ₹&nbsp;{{ (i.gstAmount/2 + i.gstAmount/2) | number:'1.2-2'}}
                                          </span>
                                          <span style = "font-size: 12px;">({{ i.gst}}%)</span>
                                        </td>
                                        <td class="bordered" style = "text-align: right;font-size: 14px!important;">
                                           <!-- {{i.finalAmount | currency:'INR':'symbol':'1.2-2'}} -->
                                           ₹&nbsp;{{ i.finalAmount | number:'1.2-2'}}
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot class="inner-model-table-heading printFoot" style="font-size: 14px!important;">
                                    <tr>
                                        <td class="bordered"></td>
                                        <th class="bordered" style = "text-align: left;font-size: 14px!important;">SUBTOTAL</th>
                                        <td class="bordered"></td>
                                        <td class="bordered"></td>
                                        <td class="bordered"></td>
                                        <th class="bordered" style = "text-align: right;font-weight: 600;font-size: 14px!important;">
                                          <!-- {{salesData.billGstAmount | currency:'INR':'symbol':'1.2-2'}} -->
                                          ₹&nbsp;{{salesData.billGstAmount | number:'1.2-2'}}
                                        </th>
                                        <th class="bordered" style = "text-align: right;font-weight: 600;font-size: 14px!important;">
                                          <!-- {{salesData.totalBillAmount | currency:'INR':'symbol':'1.2-2'}} -->
                                          ₹&nbsp;{{salesData.totalBillAmount | number:'1.2-2'}}
                                        </th>
                                    </tr>
                                </tfoot>
                            </table>
                          </div>
                          <!-- <div class="row ">
                              <div class="col-6  borderbottom2 borderLeft">
                                  <div class="table-responsive scrollbar fs--">
                                      <table class="table table-striped tablePrint"
                                          style="font-size: 11px!important;">
                                          <thead class="inner-model-table-heading">
                                              <tr class="text-white dark__bg-1000">
                                                  <th class=""> Tax Type</th>
                                                  <th>Taxable Amount</th>
                                                  <th>Rate</th>
                                                  <th>Tax Amount</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              <tr *ngFor="let i of saleProductData; let index = index">
                                                  <td class="align-middle text-center">
                                                      {{salesData.supplyType}}
                                                  </td>
                                                  <td class="align-middle text-center">
                                                      {{i.totalPrice - billGstAmount|
                                                      currency:'INR':'symbol':'1.2-2'}}
                                                  </td>
                                                  <td class="align-middle text-center">
                                                      {{i.gst}}
                                                  </td>
                                                  <td class="align-middle text-center">
                                                      {{totalGST |currency:'INR':'symbol':'1.2-2' }}
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </div>

                              </div>
                              <div class="col-6">
                                  <table class="table tablePrint" style="font-size: 11px!important;">
                                      <tr class = "inner-model-table-heading">
                                          <th class="thWidth borderLeft p-3" style="text-align:left!important">
                                              Amounts:</th>
                                          <th class="borderLeft borderbottom2 borderRIGHT p-3" style="width: 50px;"
                                              style="text-align:right!important">In Rs:</th>
                                      </tr>
                                      <tr>
                                          <td class="thWidth bordered p-2" style="text-align:left!important">Total
                                              Base Price</td>
                                          <td class="borderedLeftNone p-2" style="width: 50px;"
                                              style="text-align:right!important">{{totalBillAmount -
                                              billGstAmount|currency:'INR':'symbol':'1.2-2'}}</td>
                                      </tr>
                                      <tr>
                                          <td class="thWidth bordered p-2 " style="text-align:left!important">Total
                                          </td>
                                          <td class=" borderedLeftNone p-2" style="width:50px;"
                                              style="text-align:right!important">
                                              {{ totalBillAmount | currency:'INR':'symbol':'1.2-2'}}
                                          </td>
                                      </tr>
                                      <tr>
                                          <td class="thWidth bordered p-2" style="text-align:left!important">Received
                                          </td>
                                          <td class="borderedLeftNone p-2" style="width: 50px;"
                                              style="text-align:right!important">
                                              {{ salesData.received| currency:'INR':'symbol':'1.2-2'}}
                                          </td>
                                      </tr>

                                      <tr class="borderbottom2">
                                          <td class="thWidth bordered p-2" style="text-align:left!important">Balance
                                          </td>
                                          <td class="bordered p-2" style="width: 50px;"
                                              style="text-align:right!important">
                                              {{ salesData.balance| currency:'INR':'symbol':'1.2-2'}}
                                          </td>
                                      </tr>
                                  </table>

                              </div>
                          </div>
                          <div class="row borderLeft" style="font-size: 11px!important;">
                              <div class="col-6 ">
                                  <p style="width: 100%!important;" class="inner-model-table-heading p-1">
                                      Invoice Amount In Words</p>
                                  <p style="padding-left: 5px !important;">{{ finalAmount }}
                                  </p>
                              </div>
                              <div class="col-6 borderLeft borderRIGHT">
                                  <p class="p-1"></p>
                                  <p>
                                  </p>
                              </div>
                          </div>
                          <div class="row borderedBottom" style="font-size: 11px!important;">
                              <div class="col-6 ">
                                  <p class="inner-model-table-heading p-1">Terms and Conditions:
                                  </p>
                                  <p class="pb-2" style="padding-left: 5px !important;">Thanks for doing business with
                                      us.
                                  </p>
                              </div>
                              <div class="col-6 borderLeft">
                                  <p class="text-center">{{BusinessName}}</p>
                                  <p class="text-center pt-3">Authorized Signatory</p>
                              </div>
                          </div> -->
                          <div class = "row my-2">
                            <div class="col-6" style = "display: flex;flex-direction: column;justify-content: center;">
                           
                            </div>
                            <div class="col-6">
                              <div class="row headerAlignment mt-2">
                                <div class="col-6">
                                  TAXABLE AMOUNT
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                    <!-- {{totalBillAmount - billGstAmount | currency:'INR':'symbol':'1.2-2'}} -->
                                    ₹&nbsp;{{totalBillAmount - billGstAmount | number:'1.2-2'}}
                                </div>
                              </div>
                              <div class="row headerAlignment mt-1" *ngIf="gstStateMatch == true">
                                <div class="col-6">
                                  CGST
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                  <!-- {{salesData.billGstAmount/2 | currency:'INR':'symbol':'1.2-2'}} -->
                                  ₹&nbsp;{{salesData.billGstAmount/2 | number:'1.2-2'}}
                                </div>
                              </div>
                              <div class="row headerAlignment mt-1" *ngIf="gstStateMatch == true">
                                <div class="col-6">
                                  SGST
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                  <!-- {{salesData.billGstAmount/2 | currency:'INR':'symbol':'1.2-2'}} -->
                                  ₹&nbsp;{{salesData.billGstAmount/2 | number:'1.2-2'}}
                                </div>
                              </div>
                              <div class="row headerAlignment mt-1" *ngIf="gstStateMatch == false">
                                <div class="col-6">
                                  IGST
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                  <!-- {{ (salesData.billGstAmount/2 + salesData.billGstAmount/2) | currency:'INR':'symbol':'1.2-2'}} -->
                                  ₹&nbsp;{{(salesData.billGstAmount/2 + salesData.billGstAmount/2) | number:'1.2-2'}}
                                </div>
                              </div>
                              <hr style = "border: 1px solid grey;" />
                                <div class="row headerAlignment">
                                  <div class="col-6"  style = "font-weight: 600;">
                                    TOTAL AMOUNT
                                  </div>
                                  <div class="col-6" style = "text-align: end;font-weight: 600;">
                                    <!-- {{salesData.totalBillAmount | currency:'INR':'symbol':'1.2-2'}} -->
                                    ₹&nbsp;{{salesData.totalBillAmount | number:'1.2-2'}}
                                  </div>
                                </div>
                              <hr style = "border: 1px solid grey;" />
                              <div class="row headerAlignment mt-1">
                                <div class="col-6">
                                  Received Amount
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                    <!-- {{ salesData.received | currency:'INR':'symbol':'1.2-2'}} -->
                                    ₹&nbsp;{{salesData.received | number:'1.2-2'}}
                                </div>
                              </div>
                              <div class="row headerAlignment mt-1">
                                <div class="col-6" style = "font-weight: 600;">
                                  Balance
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                    <!-- {{ salesData.balance | currency:'INR':'symbol':'1.2-2'}} -->
                                    ₹&nbsp;{{salesData.balance | number:'1.2-2'}}
                                </div>
                              </div>
                              <div class="row headerAlignment mt-3">
                                <div style = "text-align: end;">
                                  <p class = "mb-0">Total Amounts (in words)</p>
                                  <span>{{ finalAmount }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        <div class="row">
                          <div class="col-6" style="display: flex;flex-direction: column;justify-content: flex-start;">
                            <span class="mb-1 mt-5" style="font-weight: 600;">TERMS AND CONDITIONS</span>
                            <span #dynamicText [style.fontSize.px]="getTextSizeprint(TextSizeprint)" style = "margin-bottom: 4px;">{{invoiceTermsCon ? invoiceTermsCon :'NA'}}</span>
                          </div>
                          <div class="col-md-6 col-lg-6 col-sm-12" style="display: flex;flex-direction: column;justify-content: flex-end;">
                            <div class="image-wrapper">
                                <label class="signature-img" ondragover="return false;">
                                    <img *ngIf="signImg" [src]="signImg" />
                                </label>
                            </div>
                        </div>
                      </div>


                      </div>
                  </div>
              </div>
          </div>
        </div>
    </div>
</div>

<!-- UAE -->

<div class="modal fade" #closebutton id="invoice-modal2" tabindex="-1" role="dialog" aria-hidden="true" *ngIf="isArabic">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content position-relative">
          <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
              <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal" (click)="closeModal()" id = "closeModalPrint">
                </button>
          </div>
          <div class="modal-body p-0">
            <div class="rounded-top-lg py-3 ps-4 pe-6">
            </div>
            <div class="container" id="panel">
                <div class="card">
                    <div class="card-body">
                        <div class="row my-2" id="panel">
                            <div class="col-6 pt-2">
                              <p style = "color: red;font-weight: 600;">{{tradeName ? tradeName : "NA"}}</p>
                              <p>Mobile:&nbsp;{{ mobile ? mobile : 'NA'}}</p>
                            </div>
                            <div class="col-6">
                              <div class="row headerAlignment">
                                <div class="col-6" style = "font-weight: 600;">
                                  TAX INVOICE
                                </div>
                                <div class="col-6">
                                  <button style = "float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;" id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0"
                                    type="button" (click)="onPrint()"><span class="fas fa-print me-1">
                                    </span>Print
                                  </button>
                                  <div id = "originalForReceiptPrint">
                                    ORIGINAL FOR RECIPIENT
                                  </div>
                                </div>
                              </div>
                              <div class="row headerAlignment">
                                <div class="col-6">
                                  Invoice #<span style = "float: right;">:</span>
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                  {{invoiceNo}}
                                </div>
                              </div>
                              <div class="row headerAlignment">
                                <div class="col-6">
                                  Invoice Date<span style = "float: right;">:</span>
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                  {{invoiceDate | date:"dd MMM YYYY" | uppercase }}
                                </div>
                              </div>
                              <div class="row headerAlignment">
                                <div class="col-6">
                                  Due Date<span style = "float: right;">:</span>
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                  {{ dueDate ? dueDate : 'NA' }}
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class = "row mt-2 mb-4">
                          <div class="col-6">
                            <div style="display: flex;flex-direction: column;justify-content: flex-start;">
                              <span class = "inner-model-table-heading p-2 my-2" style = "width: 100px;font-weight: 600;">BILL TO</span>
                              <span style = "font-weight: 600;">{{salesData.customerName ? salesData.customerName : "NA"}}</span>
                              <span>{{ billingAddress ? billingAddress : "NA"}}</span>
                              <span>Mobile: &nbsp;{{ salesData.mobile ? salesData.mobile : "NA" }}</span>
                              <span>{{lang.GSTIN}}: &nbsp;{{gstinNo ? gstinNo : "NA"}}</span>
                              <!-- <span>State: &nbsp;{{ placeOfSupply ? placeOfSupply : "NA" }}</span> -->
                            </div>
                          </div>
                          <div class="col-6">
                            <div style="display: flex;flex-direction: column;justify-content: flex-start;">
                              <span class = "inner-model-table-heading p-2 my-2" style = "width: 100px;font-weight: 600;">SHIP TO</span>
                              <span style = "font-weight: 600;">{{salesData.customerName ? salesData.customerName : "NA"}}</span>
                              <span>{{ shippingAddress ? shippingAddress : "NA"}}</span>
                            </div>
                          </div>
                        </div>
                        <div class=" row table-responsive scrollbar fs--1" style = "padding: 0px 10px;">
                          <table class="table  tablePrint" style="font-size: 14px!important;">
                              <thead class="inner-model-table-heading printHead" style="font-size: 14px!important;">
                                  <tr class="text-white dark__bg-1000">
                                      <th class="borderedTopNone" style="text-align: left;font-size: 14px!important;">#</th>
                                      <th class="borderedTopNone" style="text-align: left;width:20rem;font-size: 14px!important;">ITEMS</th>
                                      <!-- <th class="borderedTopNone" style="text-align: right;font-size: 14px!important;">HSN</th> -->
                                      <th class="borderedTopNone" style="text-align: right;font-size: 14px!important;">QTY.</th>
                                      <th class="borderedTopNone" style = "text-align: right;font-size: 14px!important;">RATE</th>
                                      <th class="borderedTopNone" style = "text-align: right;font-size: 14px!important;">TAX</th>
                                      <th class="borderedTopNone" style = "text-align: right;font-size: 14px!important;">AMOUNT</th>
                                  </tr>
                              </thead>
                              <tbody class = "printBody" style = "max-height: 200px;font-size: 14px!important;">
                                  <tr *ngFor="let i of saleProductData; let index = index">
                                      <td class="bordered" style = "text-align: left;font-size: 14px!important;">{{ index+1 }}</td>
                                      <td class="bordered">
                                        <span style = "text-transform: uppercase;font-size: 14px!important;">{{i.itemName}}</span>
                                      </td>
                                      <!-- <td class="bordered" style = "text-align: right;font-size: 14px!important;">{{ hsn }}</td> -->
                                      <td class="bordered" style = "text-align: right;white-space: nowrap;font-size: 14px!important;">{{i.quantity}} {{i.unit.substr(i.unit.length - 5)}}</td>
                                      <td class="bordered" style = "text-align: right;font-size: 14px!important;">
                                        <!-- {{ (i.quantity * (i.pricePerUnit / (1 + (i.gst/100)))) | currency:'INR':'symbol':'1.2-2'}} -->
                                          AED&nbsp;{{ (i.quantity * (i.pricePerUnit / (1 + (i.gst/100)))) | number:'1.2-2'}}
                                        </td>
                                      <td class="bordered" style = "display: flex;flex-direction:column;align-items:flex-end;font-size: 14px!important;">
                                        <span>
                                          <!-- {{ (i.gstAmount/2 + i.gstAmount/2)| currency:'INR':'symbol':'1.2-2'}} -->
                                          AED&nbsp;{{ (i.gstAmount/2 + i.gstAmount/2) | number:'1.2-2'}}
                                        </span>
                                        <span style = "font-size: 12px;">({{ i.gst}}%)</span>
                                      </td>
                                      <td class="bordered" style = "text-align: right;font-size: 14px!important;">
                                         <!-- {{i.finalAmount | currency:'INR':'symbol':'1.2-2'}} -->
                                         AED&nbsp;{{ i.finalAmount | number:'1.2-2'}}
                                      </td>
                                  </tr>
                              </tbody>
                              <tfoot class="inner-model-table-heading printFoot" style="font-size: 14px!important;">
                                  <tr>
                                      <td class="bordered"></td>
                                      <th class="bordered" style = "text-align: left;font-size: 14px!important;">SUBTOTAL</th>
                                      <td class="bordered"></td>
                                      <td class="bordered"></td>
                                      <!-- <td class="bordered"></td> -->
                                      <th class="bordered" style = "text-align: right;font-weight: 600;font-size: 14px!important;">
                                        <!-- {{salesData.billGstAmount | currency:'INR':'symbol':'1.2-2'}} -->
                                        AED&nbsp;{{salesData.billGstAmount | number:'1.2-2'}}
                                      </th>
                                      <th class="bordered" style = "text-align: right;font-weight: 600;font-size: 14px!important;">
                                        <!-- {{salesData.totalBillAmount | currency:'INR':'symbol':'1.2-2'}} -->
                                        AED&nbsp;{{salesData.totalBillAmount | number:'1.2-2'}}
                                      </th>
                                  </tr>
                              </tfoot>
                          </table>
                        </div>

                        <div class = "row my-2">
                          <div class="col-6" style = "display: flex;flex-direction: column;justify-content: center;">
                         
                          </div>
                          <div class="col-6">
                            <div class="row headerAlignment mt-2">
                              <div class="col-6">
                                TAXABLE AMOUNT
                              </div>
                              <div class="col-6" style = "text-align: end;">
                                  <!-- {{totalBillAmount - billGstAmount | currency:'INR':'symbol':'1.2-2'}} -->
                                  AED&nbsp;{{totalBillAmount - billGstAmount | number:'1.2-2'}}
                              </div>
                            </div>
                            <div class="row headerAlignment mt-1">
                              <div class="col-6">
                                {{lang.CGST}}
                              </div>
                              <div class="col-6" style = "text-align: end;">
                                AED&nbsp;{{salesData.billGstAmount | number:'1.2-2'}}
                              </div>
                            </div>
                            <!-- <div class="row headerAlignment mt-1" *ngIf="gstStateMatch == true">
                              <div class="col-6">
                                SGST
                              </div>
                              <div class="col-6" style = "text-align: end;">
                                ₹&nbsp;{{salesData.billGstAmount/2 | number:'1.2-2'}}
                              </div>
                            </div> -->
                            <!-- <div class="row headerAlignment mt-1" *ngIf="gstStateMatch == false">
                              <div class="col-6">
                                IGST
                              </div>
                              <div class="col-6" style = "text-align: end;">
                                ₹&nbsp;{{(salesData.billGstAmount/2 + salesData.billGstAmount/2) | number:'1.2-2'}}
                              </div>
                            </div> -->
                            <hr style = "border: 1px solid grey;" />
                              <div class="row headerAlignment">
                                <div class="col-6"  style = "font-weight: 600;">
                                  TOTAL AMOUNT
                                </div>
                                <div class="col-6" style = "text-align: end;font-weight: 600;">
                                  AED&nbsp;{{salesData.totalBillAmount | number:'1.2-2'}}
                                </div>
                              </div>
                            <hr style = "border: 1px solid grey;" />
                            <div class="row headerAlignment mt-1">
                              <div class="col-6">
                                Received Amount
                              </div>
                              <div class="col-6" style = "text-align: end;">
                                  AED&nbsp;{{salesData.received | number:'1.2-2'}}
                              </div>
                            </div>
                            <div class="row headerAlignment mt-1">
                              <div class="col-6" style = "font-weight: 600;">
                                Balance
                              </div>
                              <div class="col-6" style = "text-align: end;">
                                  AED&nbsp;{{salesData.balance | number:'1.2-2'}}
                              </div>
                            </div>
                            <div class="row headerAlignment mt-3">
                              <div style = "text-align: end;">
                                <p class = "mb-0">Total Amounts (in words)</p>
                                <span>{{ finalAmount | titlecase}} {{ currencySymbol }}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-6" style="display: flex;flex-direction: column;justify-content: flex-start;">
                            <span class="mb-1 mt-5" style="font-weight: 600;">TERMS AND CONDITIONS</span>
                            <span #dynamicText [style.fontSize.px]="getTextSizeprint(TextSizeprint)" style = "margin-bottom: 4px;">{{invoiceTermsCon ? invoiceTermsCon :'NA'}}</span>
                          </div>
                          <div class="col-md-6 col-lg-6 col-sm-12" style="display: flex;flex-direction: column;justify-content: flex-end;">
                            <div class="image-wrapper">
                                <label class="signature-img" ondragover="return false;">
                                    <img *ngIf="signImg" [src]="signImg" />
                                </label>
                            </div>
                        </div>
                      </div>


                    </div>
                </div>
            </div>
        </div>
      </div>
  </div>
</div>
