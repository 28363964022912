import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { UrlConstants } from 'src/app/_helpers/url-constants';

@Injectable({
  providedIn: 'root'
})
export class Gstr1Service {

  constructor(public httpClient: HttpService) { }
  // getgstr1Report(data: {}, id, startDate, endDate) {
  //   return new Promise((resolve, reject) => {
  //     this.httpClient.inventory(data, UrlConstants.gstr1 + id +"&?startDate=" + startDate + "&endDate=" + endDate, 'GET').subscribe(
  //       (res) => resolve(res),
  //       (err) => reject(err)
  //     );
  //   });
  // }
  getgstr1Report(data: {}, id,startDate,endDate) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.gstr1 + id + "?startDate=" + startDate + "&endDate=" + endDate , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  gstr9Report(data: {}, id) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.gstr9 + id , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  
  getgstr2AReport(data: {}, id) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.gstr2A + id , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getgstrTaxReport(data: {}, id,startDate,endDate) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.gstrTax + id + "?startDate=" +startDate + "&endDate=" +endDate, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getgstrTaxRateReport(data: {}, id,startDate,endDate) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.gstrTaxRate + id + "?startDate=" +startDate + "&endDate=" +endDate, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getgstr3BReport(data: {}, id) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.gstr3B + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getgstr4BReport(data: {}, id) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.gstr4B + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  
  getSaleSummary(data: {}, id,startDate,endDate) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.salesummary + id + "?startDate=" +startDate + "&endDate=" +endDate, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getSaleSummarysearch(data: {}, id, startDate, endDate,search) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.salesummarysearch + id +"?startDate=" + startDate + "&endDate=" + endDate + "&search=" +search, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}
