import { Component, ElementRef, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ToastData, ToastNotificationService } from '../toast-notification.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-custom-toast',
  templateUrl: './custom-toast.component.html',
  styleUrls: ['./custom-toast.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'closed',
        style({
          transform: 'translateX(100%)', // Move to the right when closed
          visibility: 'hidden',
        })
      ),
      state(
        'open',
        style({
          transform: 'translateX(0)', // Translate back to the default position
        })
      ),
      transition('open <=> closed', [animate('1.5s ease-in-out')]),
    ])
  ]
  
})
export class CustomToastComponent implements OnInit {
  
  @ViewChild('element', { static: false }) progressBar: ElementRef
  progressInterval;
  toasts: ToastData[] = [];

  constructor(public toastService: ToastNotificationService) { }

  ngOnInit(): void {
    this.toastService.open.subscribe((data) => {
      if (data.show){
        this.toasts.push(data);
          this.countDown(data);
      }
    })
  }

  // countDown(toast) {
  //   this.toasts.forEach((toast, index) => {
  //     console.log(toast.progressWidth)
  //     this.progressBar.nativeElement.style.width = toast.progressWidth;

  //     toast.timer = setInterval(() => {
  //       const width = parseInt(this.progressBar.nativeElement.style.width, 10);
  //       if (width <= 0) {
  //         this.closeToast(index);
  //         clearInterval(this.progressInterval);
  //       } else {
  //         toast.progressWidth = String(width - 2);
  //         this.progressBar.nativeElement.style.width = toast.progressWidth + '%';
  //       }
  //     }, 150);
  //   });
  //   toast.timer = this.progressInterval; // Store the interval reference in the toast object

    // this.progressBar.nativeElement.style.width = this.toastService.data.progressWidth;
    // console.log(this.toastService.data)
    // this.progressInterval = setInterval(() => {
    //   const width = parseInt(this.progressBar.nativeElement.style.width, 10)

    //   if(width <= 0){
    //     this.toastService.hide();
    //     clearInterval(this.progressInterval)
    //     return
    //   }

    //   this.toastService.data.progressWidth = String( width - 2 );
    //   this.progressBar.nativeElement.style.width = this.toastService.data.progressWidth + '%';
    // }, 150);
  // }

  countDown(toast: ToastData) {
    if (toast.title !== 'Warning') {      // Start the countdown timer for non-warning messages
      toast.progressWidth = '100%';
      toast.timer = setInterval(() => {
        const width = parseInt(toast.progressWidth, 10);
        if (width <= 0) {
          this.stopCountDown(toast);
        } else {
          toast.progressWidth = String(width - 2);
        }
      }, 50);
    }
  }

  stopCountDown(toast: ToastData) {
    // clearInterval(this.progressInterval);
    clearInterval(toast.timer);
    const index = this.toasts.indexOf(toast);
    if (index !== -1) {
      this.toasts.splice(index, 1);
    }
  }

  // closeToast() {
    // this.toastService.hide();
  // closeToast(index: number) {
  //   this.toasts.splice(index, 1);
  // } 

  close(index){
    if (index !== -1) {
      this.toasts.splice(index, 1);
    }
  }

}
