<script src="vendors/choices/choices.min.js"></script>

<div class="container-fluid mb-3 mx-0 bg-white">
    <div class="row pt-3 pb-3 mt-" id="headingPrintHide">
        <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:600;font-size:20px!important;">
          Vendor Statement
        </div>
        <div class="col-6 navOption-btns" style ="display: flex; justify-content: flex-end;">
          <button class="btn me-3" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()" type="button"><i class="fas fa-download"></i></button> 
    
          <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
          </button>
        </div>
    </div>
    <hr class="mt-1" />
    


    <div class="fs--1 ">
        <div class="row">
            <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;" class="col-xs-12 col-sm-12 col-md-4 col-lg-3" id = "dateRangePrintHide">
                <label for="Date Range" class="form-label">Date</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input class="uppercase-date-range" matInput placeholder="Date Range" id="Date Range" type="text" [outsideClick]="true" ngModel
                        bsDaterangepicker [maxDate]="today"
                        [(ngModel)]="dateRange"
                        [bsConfig]="{ranges: ranges,showWeekNumbers:false, showNextMonth: false, rangeInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true }">
                    <span><mat-icon class="iconcalender p-1">date_range</mat-icon></span>
                </mat-form-field>
            </div>
            
            <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;" class="col-xs-12 col-sm-12 col-md-4 col-lg-3" id = "searchBarHide">
                <label class="form-label">Select Vendor Name</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" placeholder="Vendor Name" aria-label="Select Party Name" matInput
                        (keyup)="searchPartyByName($event.target.value)" [matAutocomplete]="auto" [ngClass]="{
                    'is-invalid': (partyName.invalid && partyName.touched) && submitted }" required>
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
                            (onSelectionChange)="searchPartyByName(option)">
                            {{option.partyName}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <!-- <div class="col-sm-4"></div>
            <div class="col-sm-1  mt-2 text-center" id = "excelPrintHide" (click)="exportExcel()">
                <a type="button" style="margin-left: 14px;" class="p-0 text-900 bi-file-earmark-excel fa-2x "></a>
                <div class="text-nowrap text-900  fs--2  mt-1">Export to Excel</div>

            </div>
            <div class="col-sm-1 mt-2 text-center" id = "PrintoptionHide" (click)="onPrint()">
                <a type="button" class=" p-0 text-900 bi-printer float-right fa-2x"
                    (click)="printComponent('component1')"></a>
                <div class="fs--2 text-900 text-nowrap mt-1">Print</div>
            </div> -->

        </div>

        <div class="table-responsive scrollbar mt-4 fs--1 mb-2">
            <table class="table table-bordered table-stripped" id="excel-table">
                <thead class="" style="background-color: #F0F0F0;">
                    <tr class="text-900">
                        <th scope="col" class="text-center text-nowrap p-1" style="width: 60%;">DATE
                            <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                    style="margin-left: 10px;color:#FF9A62" class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar sm p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Equal to</option>
                                        <option value="1">Greater than</option>
                                        <option value="2">Less than</option>
                                        <option value="3">Range</option>
                                    </select>

                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="date" />
                                    </div>
                                </li>

                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm" type="button">CLEAR</button>

                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class=" btn-primary btn-sm" type="button"
                                            style="margin-left: 8px;">APPLY</button>

                                    </div>
                                </div>
                            </div>
                        </th>
                        <th scope="col" class="text-center text-nowrap p-1">TXN TYPE

                            <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                    style="margin-left: 10px;color:#FF9A62" class="text-1400  fs-1 bi-filter-right"></i></button>

                            <div class="dropdown-menu sm p-3 " aria-labelledby="dropdownMenuButton" style="width: 28%;">
                                <ul class="scrollbar-overlay list-unstyled" style="height: 8rem;">
                                    <li>
                                        <input type="checkbox" name="chk1" value="casual"><label
                                            style="margin-left: 10px;">SALE</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="chk1" value="casual"><label
                                            style="margin-left: 10px;">PURCHASE</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="chk1" value="casual"><label
                                            style="margin-left: 10px;">PAYMENT-IN</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="chk1" value="casual"><label
                                            style="margin-left: 10px;">PAYMENT-OUT</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="chk1" value="casual"><label
                                            style="margin-left: 10px;">CREDIT NOTE</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="chk1" value="casual"><label
                                            style="margin-left: 10px;">DEBIT NOTE</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="chk1" value="casual"><label
                                            style="margin-left: 10px;">SALE ORDER</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="chk1" value="casual"><label
                                            style="margin-left: 10px;">PURCHASE ORDER</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="chk1" value="casual"><label
                                            style="margin-left: 10px;">ESTIMATE</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="chk1" value="casual"><label
                                            style="margin-left: 10px;">DELIVERY CHALLAN</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="chk1" value="casual"><label
                                            style="margin-left: 10px;">EXPENSE</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="chk1" value="casual"><label
                                            style="margin-left: 10px;">PARTY TO PARTY[RECEIVED]</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="chk1" value="casual"><label
                                            style="margin-left: 10px;">PARTY TO PARTY [PAID]</label>
                                    </li>
                                </ul>

                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group sm-2 mb-md-0 ">
                                        <button class="btn btn-primary btn-sm" type="button">CLEAR</button>

                                    </div>
                                    <div class="btn-group sm-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm" type="button"
                                            style="margin-left: 8px;">APPLY</button>

                                    </div>
                                </div>
                            </div>

                        </th>
                        <th scope="col" class="text-center text-nowrap p-1">REF #
                            <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                    style="margin-left: 10px;color:#FF9A62" class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Contains</option>
                                        <option value="1">Exact match</option>

                                    </select>

                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" />
                                    </div>
                                </li>

                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>

                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>

                                    </div>
                                </div>
                            </div>
                        </th>

                        <th class="text-center text-nowrap p-1">PAYMENT TYPE
                            <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                    style="margin-left: 10px;color:#FF9A62" class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <ul class="list-unstyled">
                                    <li>
                                        <input type="checkbox" name="chk1" value="casual"><label
                                            style="margin-left: 10px;">CASH</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="chk1" value="casual"><label
                                            style="margin-left: 10px;">CHEQUE</label>
                                    </li>
                                </ul>
                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>

                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>

                                    </div>
                                </div>
                            </div>
                        </th>
                        <th scope="col text-center text-nowrap " class="text-nowrap p-1">TOTAL
                            <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                    style="margin-left: 10px;color:#FF9A62" class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Equal to</option>
                                        <option value="1">Greater than</option>
                                        <option value="2">Less than</option>

                                    </select>

                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" />
                                    </div>
                                </li>

                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>

                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>

                                    </div>
                                </div>
                            </div>
                        </th>
                        <th scope="col" class="text-center text-nowrap p-1">RECEIVED/PAID
                            <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                    style="margin-left: 10px;color:#FF9A62" class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Equal to</option>
                                        <option value="1">Greater than</option>
                                        <option value="2">Less than</option>

                                    </select>

                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" />
                                    </div>
                                </li>

                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>

                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>

                                    </div>
                                </div>
                            </div>
                        </th>
                        <th scope="col" class="text-center text-nowrap p-1">TXN BALANCE
                            <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                    style="margin-left: 10px;color:#FF9A62" class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Equal to</option>
                                        <option value="1">Greater than</option>
                                        <option value="2">Less than</option>

                                    </select>

                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" />
                                    </div>
                                </li>

                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>

                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>

                                    </div>
                                </div>
                            </div>
                        </th>
                        <th scope="col" class=" text-center text-nowrap p-1">RECEIVABLE BALANCE
                            <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                    style="margin-left: 10px;color:#FF9A62" class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Equal to</option>
                                        <option value="1">Greater than</option>
                                        <option value="2">Less than</option>

                                    </select>

                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" />
                                    </div>
                                </li>

                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>

                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>

                                    </div>
                                </div>
                            </div>
                        </th>
                        <th scope="col" class="text-center text-nowrap p-1">PAYABLE BALANCE
                            <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                    style="margin-left: 10px;color:#FF9A62" class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Equal to</option>
                                        <option value="1">Greater than</option>
                                        <option value="2">Less than</option>

                                    </select>

                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" />
                                    </div>
                                </li>

                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>

                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;">APPLY</button>

                                    </div>
                                </div>
                            </div>
                        </th>
                        <th scope="col" class="text-center p-1">
                            <div class="text-center">
                                <button class="btn btnb btnp" type="button" data-bs-toggle="modal" (click)="getReceiptById()"
                                    >
                                    <span class="bi-printer icon-color fs-1" data-bs-toggle="tooltip" data-bs-placement="top" title="print"
                                        style="margin-right: 5px !important;"></span>
                                </button>
                                <!-- <button class="btnb btnp" type="button" data-bs-toggle="modal" data-bs-target="#invoice-modal2"
                                >
                                <span class="bi-printer icon-color fs-1" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="print"></span>
                                 //edit -->
                            <!-- </button>  -->
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="btn-reveal-trigger text-left" *ngFor="let item of searchpartyList;let i = index">
                        <td>{{item.invoiceDate}}</td>
                        <td>{{item.invoiceType}}</td>
                        <td>{{item.invoiceNo}}</td>
                        <td>{{item.paymentsType}}</td>
                        <td>{{item.totalBillAmount}}</td>
                        <td>{{item.billGstAmount}}</td>
                        <!-- <td>{{item.orderDate}}</td> -->
                        <td>{{item.receiptType}}</td>

                        <td>{{item.totalCess}}</td>
                        <!-- <td>{{item.invoiceType}}</td> -->
                        <!-- <td>{{item.rcm}}</td> -->
                        <!-- <td>{{item.supplyType}}</td> -->
                        <!-- <td>{{item.reverseCharge}}</td> -->
                        <td>{{item.billGstAmount}}</td>
                        <td>{{item.reverseCharge}}</td>
                        <!-- <td>{{item.saleReturnNumber}}</td> -->
                        <!-- <td>{{item.uploadEbayBill}}</td>
                      <td>{{item.uploadBill}}</td> -->
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="card-footer p-0">
            <div class="accordion" id="accordionPanelsStayOpenExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                        <button class="accordion-button" style="height: 10px" type="button" data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseOne">
                            Vendor Statement Summary

                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne">
                        <div class="accordion-body">
                            <div>
                                <div class="row">
                                    <div class="col-sm-3 ">
                                        <strong class=" text-dark">Total Sales: ₹ 0.00</strong>
                                        <div class="link-400">
                                            (Sales - Sales Return)

                                        </div>
                                    </div>
                                    <div class="col-sm-3 ">
                                        <strong class=" text-dark">Total Purchase: ₹ 0.00</strong>
                                        <div class="link-400">
                                            (Purchase - Purchase Return)
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <strong class=" text-dark">Total Expense: ₹ 0.00</strong>
                                    </div>

                                </div>

                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-sm-3">
                                        <strong class=" text-dark">Total Money-In: ₹ 0.00</strong>
                                    </div>
                                    <div class="col-sm-3">
                                        <strong class=" text-dark">Total Money-out: ₹ 0.00</strong>
                                    </div>
                                    <div class="col-sm-3">
                                    </div>
                                    <div class="col-sm-3 ">
                                        <strong class="fw-bold text-dark "
                                            style="margin-left: 30px; margin-bottom: 30px;">Total Receivable</strong>
                                        <div class="text-success">
                                            <strong class="fw-bold text-success" style="margin-left: 30px;">₹
                                                0.00</strong>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>

    </div>
</div>