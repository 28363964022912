<div class="container-fluid bg-white p-4">
  <div class="row mt-2">
    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="row mt-3 page-nav-link">
           <div class="links col-xxl-1 col-xl-1 col-lg-2 col-md-3 col-sm-4 col-xs-4" id="productlistLink">
                <a class="mt-3 pb-1 text-nowrap productLink" routerLink="./payroll-list" routerLinkActive='active'>Payroll</a>
            </div>
            <!-- <div class="links col-xxl-1 col-xl-1 col-lg-2 col-md-3 col-sm-4 col-xs-4">
                <a class="mt-3 pb-1 text-nowrap productLink" routerLink="./setup-payroll" routerLinkActive='active'>Setup-Payroll</a>
            </div> -->
           <div class="links col-xxl-1 col-xl-1 col-lg-2 col-md-3 col-sm-4 col-xs-4">
                <a class="mt-3 pb-1 text-nowrap productLink" routerLink="./create-structure" routerLinkActive='active'>Create Structure</a>
            </div>
            <div class="links col-xxl-1 col-xl-1 col-lg-2 col-md-3 col-sm-4 col-xs-4">
              <a class="mt-3 pb-1 text-nowrap productLink" routerLink="./release-salary" routerLinkActive='active'>Release Salary</a>
          </div>
            <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-3 col-sm-0 col-xs-0"></div>
        </div>
    </div>
  </div>
  <hr  class = "mt-1" />
  <div>
    <router-outlet></router-outlet>
  </div>
</div>
