import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EventListener } from 'ngx-bootstrap/utils/facade/browser';
import { CommonService } from 'src/app/core/services/common.service';
import { ProductService } from 'src/app/core/services/product.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';
import { TitleCasePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import lang from "src/assets/langTranslation/language_translation"
import { WarehouseService } from 'src/app/core/services/warehouse.service';
@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html',
  styleUrls: ['./product-add.component.scss']
})
export class ProductAddComponent implements OnInit {
  public lang = lang.UAE;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public unitValue: any;
  public unitSecondaryValue: any;
  public isDisabled: boolean = false;
  public showProductType: any;
  public serviceToggle: boolean;
  public isStockToggle: boolean = false;
  public ProductForm: any;
  public todaysDate: any;
  public todaysDate2: any;
  public today: Date;
  public unit: any;
  public primaryUnitShort: any;
  public secondaryUnitShort: any;
  public secondaryunit: any;
  public maxDate: Date;
  // Units = Constants.Units
  public Units = Constants.UnitFS
  public SecondaryUnit = Constants.Units
  public activeColor: string = 'green';
  public baseColor: string = '#ccc';
  public overlayColor: string = 'rgba(255,255,255,0.5)';
  public dragging: boolean = false;
  public loaded: boolean = false;
  public imageLoaded: boolean = false;
  public imageSrc: any = '';
  public imageSrcOne: any = '';
  public imageSrcTwo: any = '';
  public imageSrcThree: any = '';
  public imageSrcFour: any = '';
  public imageSrcFive: any = '';
  public imageSrcSix: any = '';
  public imageSrcSeven: any = '';
  public imgNumber: any;
  public selectedFiles: any;
  public selectedFileName: any;
  public submitted: boolean = false;
  public productCategory: any;
  public productPicUrl: string = '';
  public productPicUrl1: any = '';
  public productPicUrl2: any = '';
  public productPicUrl3: any = '';
  public productPicUrl4: any = '';
  public productPicUrl5: any = '';
  public productPicUrl6: any = '';
  public currentInput: any;
  myFiles: File[] = [];
  public multipleImage = [];
  public multipleImage1 = [];
  previews: string[] = [];
  public catId: number;
  public primarySelectUnit: any;
  public secondarySelectUnit: any;
  public isArabic: boolean;
  public warrantyType: string;
  public maxFiles: number = 0;
  public Filesmax: number = 5;
  subCategoryList: any;
  activeSubCategoryList: any;
  BrandId: any;
  ProductBrand: any;
  brandId: any;
  subCategoryId: any;
  customFieldsList: any[];
  public userType:any='';
  warehouseList: any;

  constructor(private productService: ProductService,
    private formBuilder: FormBuilder,
    private titleCasePipe: TitleCasePipe,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProductAddComponent>,
    private toastService: ToastNotificationService,
    private warehouseService: WarehouseService,
    private commonService: CommonService) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
  }

  async ngOnInit(): Promise<void> {
    this.userType=this.currentUser.userType
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });

    this.todaysDate = new Date(new Date().setDate(new Date().getDate()));
    this.todaysDate2 = new Date(new Date().setDate(new Date().getDate() + 1));
    this.getProductCategory();
    if (this.isArabic) {
      this.ProductForm = this.formBuilder.group({
        itemName: ["",],
        category: ["", Validators.required],
        itemCode: ["", [Validators.required, Validators.minLength(4), Validators.maxLength(8), Validators.pattern('^[a-zA-Z0-9]*$')]],
        generateBarcode: ["", [Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern("^[0-9]*$")]],
        description: [null],
        //hsn: ["", [Validators.pattern("^[0-9]*$")]],
        hsn: [null],
        unit: [null],
        //price: ["", [Validators.pattern("^[0-9]*$")]],
        price: ["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
        //gstRate: ["", [Validators.pattern("^[0-9]*$")]],
        gstRate: ["5"],
        secondaryUnitFlag: [false],
        secondaryUnit: [""],
        conversionRate: [null],
        openingStock: [],
        asOfDate: [""],
        lowStockWarning: [false],
        lowStockUnit: [null],
        //additionalCess: ["", [Validators.pattern("^[0-9]*$")]],
        additionalCess: ["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
        serviceCode: [""],
        serviceName: [""],
        supportType: ["GUARANTEE"],
        supportDuration: [""],
        expiry: [""],
        warehouse: [null],
        used: [false]

      });
    } else {
      this.ProductForm = this.formBuilder.group({
        itemName: ["",],
        category: ["", Validators.required],
        itemCode: ["", [Validators.required, Validators.minLength(4), Validators.maxLength(8), Validators.pattern('^[a-zA-Z0-9]*$')]],
        generateBarcode: ["", [Validators.required, Validators.minLength(12), Validators.maxLength(12), Validators.pattern("^[0-9]*$")]],
        description: [null],
        //hsn: ["", [Validators.pattern("^[0-9]*$")]],
        hsn: ["", [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
        unit: [""],
        //price: ["", [Validators.pattern("^[0-9]*$")]],
        price: ["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
        //gstRate: ["", [Validators.pattern("^[0-9]*$")]],
        gstRate: ["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
        secondaryUnitFlag: [false],
        secondaryUnit: [""],
        conversionRate: [null],
        openingStock: [],
        asOfDate: [""],
        lowStockWarning: [false],
        lowStockUnit: [null],
        //additionalCess: ["", [Validators.pattern("^[0-9]*$")]],
        additionalCess: ["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
        serviceCode: [""],
        serviceName: [""],
        supportType: ["GUARANTEE"],
        supportDuration: [""],
        expiry: [""],
        used: [false],
       brand: [null],
      section: [null],
      subCategoryId: [null],
      gender: [null],
      color: [null],
      warehouse: [null],
      ageGroup: [null]
      });
    }
    try {
      // Fetch necessary data using async/await
      await Promise.all([
        this.getProductCategory(),
        this.getProductBrand(),
        this.getWarehouseList(),
      ]);
      // Patch the form values after fetching data
      this.ProductForm.patchValue({
        additionalCess: 0,
        openingStock: 0
      })
      await this.getCustomFieldDetails()
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error if needed
    }

  }
  getDropdownOptions(field: any): any[] {
    const formArray = this.ProductForm.get(field.fieldName) as FormArray;
    return formArray ? formArray.value : [];
  }
    onOptionClick(field: any, option: any): void {
    const formArray = this.ProductForm.get(field.fieldName) as FormArray;
    
    // Clear existing values in the FormArray
    while (formArray.length) {
      formArray.removeAt(0);
    }
    formArray.push(this.formBuilder.control(option));
  }  
  

  
  generateCustomFields(): void {
    if (!this.customFieldsList || !Array.isArray(this.customFieldsList)) {
      console.error('Custom fields list is not properly initialized or is not an array.');
      return;
    }

    // Loop through customFieldsList and create form controls dynamically
    this.customFieldsList.forEach(field => {
      if (field.type === 'text') {
        // For text fields
        this.ProductForm.addControl(field.fieldName, this.formBuilder.control(''));
      } else if (field.type === 'dropdown' && field.productCustomFieldOptions) {
        // For dropdown fields
        const dropdownField = this.generateDropdownField(field);
        // Add the FormArray control for dropdown options
        this.ProductForm.addControl(field.fieldName, dropdownField);
      }
    });
  }
  
  // function to handle dropdown control creation
  generateDropdownField(field: any): FormArray {
    return this.formBuilder.array(
      field.productCustomFieldOptions.map(option =>
        this.formBuilder.control({
          optionName: option.optionName,
          optionId: option.id
        })
      )
    );
  }
  
  getCustomFieldDetails(){
    this.productService.getCustomFields({
      data:{}, 
      userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,  
      size: 1000, 
      page: 1, 
      search:'', 
      isActive: '' 
    }).then(async (res) => {
      // Filter and assign active custom fields to customFieldsList
      if (res.data) {
        this.customFieldsList = res.data.pageData.filter(field => field.isActive);
        // Call the function once data is assigned
        await this.generateCustomFields();
      } 
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      }
    })
  }
  
  getProductCategory() {
    this.productService.getCategory({
      data:{}, 
      userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, 
      size: 1000, 
      page: 1, 
      search:'', 
      isActive: ''
    }).then((res: any) => {
      this.productCategory = res.data.pageData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      }
    })
  }

  getProductBrand() {
    this.productService.getBrandList({
      data:{}, 
      userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, 
      size: 1000, 
      page: 1, 
      search:'', 
      isActive: ''
    }).then((res: any) => {
      this.ProductBrand = res.data.pageData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      };
    })
  }

  getWarehouseList() {
    this.warehouseService.getAllWarehouse({
      data:{}, 
      userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, 
      size: 1000, 
      page: 1, 
      search:'', 
    }).then((res: any) => {
      this.warehouseList = res.data.pageData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      };
    })
  }

  getProductSubCategory() {
    this.productService.getSubCategory({
      data:{}, 
      userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, 
      size: 1000, 
      page: 1, 
      search:'', 
      categoryId: this.ProductForm.controls.category.value, 
      isActive: ''
    }).then((res: any) => {
      if (res.success) {
        this.subCategoryList = res.data.pageData;
        this.activeSubCategoryList = this.subCategoryList ?.filter(subcategory => subcategory.isActive === true);

      }
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      })
  }

  selectWarrantyType(event) {
    console.log("event.target.valuee", event)
    this.warrantyType = event.value
  }

  get f() {
    return this.ProductForm.controls;
  }

  getPrimaryUnit(event) {
    this.primaryUnitShort = event.source.value.shortName;
    this.unit = event.source.value.fullName;
    this.unitValue = "1 " + event.source.value.fullName;

    const inputString = this.unit;
    const startIndex = 0;
    const endIndex = inputString.indexOf("(");

    if (endIndex !== -1) {
      const result = inputString.substring(startIndex, endIndex);
      this.primarySelectUnit = result;
    } else {
      console.log("Substring not found.");
    }



    this.filterSecondary();
  }

  private filterSecondary() {
    const selectedPrimary = this.SecondaryUnit.filter(us => this.unit === us);
    const index = this.SecondaryUnit.indexOf(this.unit);
    if (index > -1) {
      this.SecondaryUnit.splice(index, 1);
    }
    //this.unitSecondaryValue = selectedPrimary;
  }
  getEnableStockValue(event) {
    this.isStockToggle = event;
    console.log("this.isStockToggle", this.isStockToggle);
    if (!this.isStockToggle) {
      // Reset the form control and clear errors when the toggle is off
      this.ProductForm.get('lowStockUnit').reset();
      this.ProductForm.get('lowStockUnit').clearValidators();
      // console.log("enter patch sec if")
    } else {
      // Add the required validator when the toggle is on
      this.ProductForm.get('lowStockUnit').setValidators([Validators.required]);

    }
    // Update the validity of the form control
    this.ProductForm.get('lowStockUnit').updateValueAndValidity();
  }

  showSecondaryUnitField(value) {
    this.isDisabled = value;
    console.log("this.isDisabled", this.isDisabled);
    if (!this.isDisabled) {
      // Reset the form control and clear errors when the toggle is off
      this.ProductForm.get('secondaryUnit').reset();
      this.ProductForm.get('secondaryUnit').clearValidators();
      // console.log("enter patch sec if")
    } else {
      // Add the required validator when the toggle is on
      this.ProductForm.get('secondaryUnit').setValidators([Validators.required]);
      // console.log("enter patch sec else")
    }
    // Update the validity of the form control
    this.ProductForm.get('secondaryUnit').updateValueAndValidity();
  }

  getSecondaryUnit(event) {
    this.secondaryUnitShort = event.source.value.shortName;
    this.unitSecondaryValue = event.source.value;


    const regex = /\(([^)]+)\)/;
    const match = this.unitSecondaryValue.match(regex);

    if (match) {
      const result1 = match[1]; // The matched text inside the parentheses
      console.log(result1);
      this.secondarySelectUnit = result1;
    } else {
      console.log("Substring not found.");
    }

    // const inputString = this.unitSecondaryValue;
    // const startIndex = 0;
    // const endIndex = inputString.indexOf("(");

    // if (endIndex !== -1) {
    //   const result1 = inputString.substring(startIndex, endIndex);
    //   console.log(result1);
    //   this.secondarySelectUnit = result1;

    // } else {
    //   console.log("Substring not found.");
    // }

  }

  show(id) {
    if (id === 5) {
      this.showProductType = Constants.USER.GOODS;
      this.serviceToggle = false;
      //this.disabled = false;
    }
    else if (id === 6) {
      this.showProductType = Constants.USER.SERVICE;
      this.serviceToggle = true;
    }
  }

  getCategoryType(event) {
    console.log("*****", event.source.value)
    this.catId = event.source.value;
    this.getProductSubCategory();
  }

  addService() {
    let data = {
      "userId": this.currentUser.id,
      "itemName": this.ProductForm.controls.serviceName.value,
      "categoryId": this.ProductForm.controls.category.value,
      "itemCode": this.ProductForm.controls.serviceCode.value,
      "barcode": this.ProductForm.controls.serviceCode.value,
      "description": this.ProductForm.controls.description.value,
      "hsn": this.ProductForm.controls.hsn.value,
      "salePrice": this.ProductForm.controls.price.value,
      "gstRate": this.ProductForm.controls.gstRate.value,
      "stock": this.ProductForm.controls.openingStock.value,
      // "asOfDate": this.ProductForm.controls.asOfDate.value,
      "expiry": this.ProductForm.controls.expiry.value,
      "additionalCess": this.ProductForm.controls.additionalCess.value,
    }
    this.productService.postService(data).then((res) => {
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Service Added Successfully!!!",
        });
        // this.toastService.openSnackBar("Service Added Successfully")
      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went To Wrong");
      }
    })
    // }, (err) => {
    //   this.toastService.openErrorSnackBar("Please Try Again Later!!!")
    // })
  }

  saveAndNewService() {
    let data = {
      "userId": this.currentUser.id,
      "itemName": this.ProductForm.controls.serviceName.value,
      "categoryId": this.ProductForm.controls.category.value,
      "itemCode": this.ProductForm.controls.serviceCode.value,
      "barcode": this.ProductForm.controls.serviceCode.value,
      "description": this.ProductForm.controls.description.value,
      "hsn": this.ProductForm.controls.hsn.value,
      "salePrice": this.ProductForm.controls.price.value,
      "gstRate": this.ProductForm.controls.gstRate.value,
      "stock": this.ProductForm.controls.openingStock.value,
      "asOfDate": this.ProductForm.controls.asOfDate.value,
      "additionalCess": this.ProductForm.controls.additionalCess.value,
    }
    this.productService.postService(data).then((res) => {
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Service Added Successfully!!!",
        });
        // this.toastService.openSnackBar("Service Added Successfully")
        this.submitted = false;
        this.ProductForm.reset();
        this.todaysDate = new Date();
        this.ProductForm.patchValue({
          additionalCess: 0,
          openingStock: 0
        })
        this.imageSrcOne = '';
        this.imageSrcTwo = '';
        this.imageSrcThree = '';
        this.imageSrcFour = '';
        this.imageSrcFive = '';
        this.imageSrcSix = '';
        this.imageSrcSeven = '';
      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went To Wrong");
      }
    })

    // }, (err) => {
    //   console.log(this.ProductForm);
    //   this.toastService.openErrorSnackBar("Please Try Again Later!!!")
    // })
  }
  createCustomFieldData(field: any, fieldValue: any): any {
    return {
      fieldName: field.fieldName,
      value: fieldValue,
      productCustomFieldId: field.id,
      userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
    };
  }
  addGoods() {
    this.submitted = true;
    console.log("this.ProductForm", this.ProductForm)
    console.log(this.ProductForm.controls.warehouse.value === null)
    if (this.ProductForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      // this.toastService.openErrorSnackBar("Fill all required fields.")
      return false;
    }
    else {
      if (this.catId == 2 || this.catId == 4 || this.catId == 8 || this.catId == 9 || this.catId == 10 || this.catId == 11 || this.catId == 14 || this.catId == 15 || this.catId == 18) {
        let data = {
          "addProducts": {
            "userId": (this.ProductForm.controls.warehouse.value === null) ? this.currentUser.id : `${this.ProductForm.controls.warehouse.value}`,
            "itemName": this.ProductForm.controls.itemName.value,
            "itemCode": this.ProductForm.controls.itemCode.value,
            "barcode": this.ProductForm.controls.generateBarcode.value,
            "description": this.ProductForm.controls.description.value,
            "hsn": this.ProductForm.controls.hsn.value,
            "salePrice": this.ProductForm.controls.price.value,
            "gstRate": this.ProductForm.controls.gstRate.value,
            "unit": this.unit,
            "secondaryUnitFlag": this.ProductForm.controls.secondaryUnitFlag.value,
            "secondaryUnit": this.unitSecondaryValue,
            "conversionRate": this.ProductForm.controls.conversionRate.value,
            "stock": this.ProductForm.controls.openingStock.value,
            "asOfDate": this.ProductForm.controls.asOfDate.value,
            "lowStockWarning": this.ProductForm.controls.lowStockWarning.value,
            "lowStockUnits": this.ProductForm.controls.lowStockUnit.value,
            "expiryDate": this.ProductForm.controls.expiry.value,
            "additionalCess": this.ProductForm.controls.additionalCess.value,
            "supportType": this.ProductForm.controls.supportType.value,
            "supportDuration": String(String(this.ProductForm.controls.supportDuration.value) + this.warrantyType),
            "categoryId": this.ProductForm.controls.category.value,
            "coverImage": this.productPicUrl,
            "used": this.ProductForm.controls.used.value == "false" ? false : true,
            "brandId": this.ProductForm.controls.brand.value ? this.ProductForm.controls.brand.value.toString() : null,
            "section": this.ProductForm.controls.section.value ? this.ProductForm.controls.section.value : null,
            "subCategoryId": this.ProductForm.controls.subCategoryId.value ? this.ProductForm.controls.subCategoryId.value.toString() : null,
            "gender": this.ProductForm.controls.gender.value ? this.ProductForm.controls.gender.value : null,
            "color": this.ProductForm.controls.color.value ? this.ProductForm.controls.color.value : null,
            "ageGroup": this.ProductForm.controls.ageGroup.value ? this.ProductForm.controls.ageGroup.value : null,
          },
          "warehouseId": (this.ProductForm.controls.warehouse.value === null) ? this.currentUser.id : `${this.ProductForm.controls.warehouse.value}`,
          "productImages":
            this.multipleImage ? this.multipleImage : null,
            "fields": []

        }
           // Iterate over dynamically generated custom fields
           if (this.customFieldsList && this.customFieldsList.length > 0) {
            this.customFieldsList.forEach(field => {
              // const fieldValue = this.getProductFormFieldValue(field);
              const fieldValue = this.ProductForm.controls[field.fieldName].value;
              const customFieldData = this.createCustomFieldData(field, fieldValue);
              data.fields.push(customFieldData);
            });
          }
        this.productService.postGoods(data).then((res) => {
          if (res) {
            this.toastService.toastMsg({
              title: "Success",
              content: "Product Added Successfully!!!",
            });
            this.productService.notifyProductAdded();
            this.dialogRef.close();
          }

        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
            // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
            // this.toastService.openErrorSnackBar("Something Went To Wrong");
          }
        })
      }
      else {
        let data = {
          "addProducts": {
            "userId": (this.ProductForm.controls.warehouse.value === null) ? this.currentUser.id : `${this.ProductForm.controls.warehouse.value}`,
            "itemName": this.ProductForm.controls.itemName.value,
            "itemCode": this.ProductForm.controls.itemCode.value,
            "barcode": this.ProductForm.controls.generateBarcode.value,
            "description": this.ProductForm.controls.description.value,
            "hsn": this.ProductForm.controls.hsn.value,
            "salePrice": this.ProductForm.controls.price.value,
            "gstRate": this.ProductForm.controls.gstRate.value,
            "unit": this.unit,
            "secondaryUnitFlag": this.ProductForm.controls.secondaryUnitFlag.value,
            "secondaryUnit": this.unitSecondaryValue,
            "conversionRate": this.ProductForm.controls.conversionRate.value,
            "stock": this.ProductForm.controls.openingStock.value,
            "asOfDate": this.ProductForm.controls.asOfDate.value,
            "lowStockWarning": this.ProductForm.controls.lowStockWarning.value,
            "lowStockUnits": this.ProductForm.controls.lowStockUnit.value,
            //"expiryDate": this.ProductForm.controls.expiry.value,
            "additionalCess": this.ProductForm.controls.additionalCess.value,
            "supportType": this.ProductForm.controls.supportType.value,
            "supportDuration": String(String(this.ProductForm.controls.supportDuration.value) + this.warrantyType),
            "categoryId": this.ProductForm.controls.category.value,
            "coverImage": this.productPicUrl,
            "used": this.ProductForm.controls.used.value == "false" ? false : true,
            "brandId": this.ProductForm.controls.brand.value ? this.ProductForm.controls.brand.value.toString() : null,
            "section": this.ProductForm.controls.section.value ? this.ProductForm.controls.section.value : null,
            "subCategoryId": this.ProductForm.controls.subCategoryId.value ? this.ProductForm.controls.subCategoryId.value.toString() : null,
            "gender": this.ProductForm.controls.gender.value ? this.ProductForm.controls.gender.value : null,
            "color": this.ProductForm.controls.color.value ? this.ProductForm.controls.color.value : null,
            "ageGroup": this.ProductForm.controls.ageGroup.value ? this.ProductForm.controls.ageGroup.value : null,
          },
          "warehouseId": (this.ProductForm.controls.warehouse.value === null) ? this.currentUser.id : `${this.ProductForm.controls.warehouse.value}`,
          "productImages":
            this.multipleImage ? this.multipleImage : null,
            "fields": []

        }
           // Iterate over dynamically generated custom fields
           if (this.customFieldsList && this.customFieldsList.length > 0) {
            this.customFieldsList.forEach(field => {
              // const fieldValue = this.getProductFormFieldValue(field);
              const fieldValue = this.ProductForm.controls[field.fieldName].value;
              const customFieldData = this.createCustomFieldData(field, fieldValue);
              data.fields.push(customFieldData);
            });
          }
        this.productService.postGoods(data).then((res) => {
          if (res) {
            this.toastService.toastMsg({
              title: "Success",
              content: "Product Added Successfully!!!",
            });
            // this.toastService.openSnackBar("Product added Successfully");
            this.productService.notifyProductAdded();
            this.dialogRef.close();
          }

        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
            // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
            // this.toastService.openErrorSnackBar("Something Went To Wrong");
          }
        })
      }
    }


    // }, (err) => {
    //   this.toastService.openErrorSnackBar("Try Again")
    // })
  }

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  handleInputChange(e, imgNumber?: any) {
    this.imgNumber = imgNumber;
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];


    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }

    this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.ProductForm.patchValue({
          file: reader.result,
        });

        if (file == null) {
          // this.toastService.openErrorSnackBar(
          //   "Please Select Image To Upload.",
          // );
          this.toastService.toastMsg({
            title: "Error",
            content: "Please Select Image To Upload",
          });
        } else {
          if (this.imgNumber == 1) {
            this.imageSrcOne = reader.result;
            console.log("this.imagesrcone",this.imageSrcOne);
            formdata.set("upload", file);
            console.log("this.imagesrcone",this.imageSrcOne);
            e.srcElement.value = "";
            this.productService.postCoverImage1(formdata).then((res) => {
              console.log("**1***", res);
              this.productPicUrl1 = res.data.location;
              console.log("this.productPicUrl1",this.productPicUrl1);

            });
          } else if (this.imgNumber == 2) {
            this.imageSrcTwo = reader.result;
            formdata.set("upload", file);
            e.srcElement.value = "";
            this.productService.postCoverImage1(formdata).then((res) => {
              console.log("**2***", res);
              this.productPicUrl2 = res.data.location;


            });
          } else if (this.imgNumber == 3) {
            this.imageSrcThree = reader.result;
            formdata.set("upload", file);
            e.srcElement.value = "";
            this.productService.postCoverImage1(formdata).then((res) => {
              console.log("***3**", res);
              this.productPicUrl3 = res.data.location;


            });
          } else if (this.imgNumber == 4) {
            this.imageSrcFour = reader.result;
            formdata.set("upload", file);
            e.srcElement.value = "";
            this.productService.postCoverImage1(formdata).then((res) => {
              console.log("***4**", res);
              this.productPicUrl4 = res.data.location;


            });
          } else if (this.imgNumber == 5) {
            this.imageSrcFive = reader.result;
            formdata.set("upload", file);
            e.srcElement.value = "";
            this.productService.postCoverImage1(formdata).then((res) => {
              console.log("**5***", res);
              this.productPicUrl5 = res.data.location;


            });
          } else if (this.imgNumber == 6) {
            this.imageSrcSix = reader.result;
            formdata.set("upload", file);
            e.srcElement.value = "";
            this.productService.postCoverImage1(formdata).then((res) => {
              console.log("**6***", res);
              this.productPicUrl6 = res.data.location;


            });
          } else if (this.imgNumber == 7) {
            this.imageSrcSeven = reader.result;
            formdata.set("uploadProductImage", file)
            e.srcElement.value = "";
          }
          // this.productService.postProductImage(formdata, this.currentUser.id).then((res) => {


          // });
        }
      }
    };
  }
  handleCoverDragEnter() {
    this.dragging = true;
  }
  handleCoverDragLeave() {
    this.dragging = false;
  }
  handleCoverImageLoad() {
    this.imageLoaded = true;
  }
  handleCoverDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.coverImgChange(e);
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  numberDecimalOnly(event): boolean {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
  coverImgChange(e, imgNumber?: any) {
    this.imgNumber = imgNumber;
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];


    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    const fileSizeLimit = 1 * 1024 * 1024; // 6 MB
    if (file.size > fileSizeLimit) {
      alert("File size exceeds the limit. Please select a smaller image (up to 1 MB).");
      return;
    }
    else {
      this.loaded = false;
      if (e.target.files && e.target.files[0]) {
        reader.readAsDataURL(file);

        reader.onload = () => {
          this.ProductForm.patchValue({
            file: reader.result,
          });

          if (file == null) {
            // this.toastService.openErrorSnackBar(
            //   "Please Select Image To Upload.",
            // );
            this.toastService.toastMsg({
              title: "Error",
              content: "Please Select Image To Upload",
            });
          } else {

            this.imageSrcSeven = reader.result;

            formdata.set("upload", file)
            e.srcElement.value = "";
            this.productService.postCoverImage1(formdata).then((res) => {
              this.productPicUrl = res.data.locations[0];
              console.log("this.productPicUrl", this.productPicUrl);
              this.toastService.toastMsg({
                title: "Success",
                content: "Cover Image uploaded successfully!!!",
              });

            });
          }
        }
      }
    }

  }


  // Clear the inouts in the form.
  clearInput() {
    this.dialogRef.close();
    this.ProductForm.reset();
    this.isDisabled = false;
    this.isStockToggle = false;
    this.todaysDate = new Date();
    this.todaysDate2 = new Date();
    this.imageSrcOne = '';
    this.imageSrcTwo = '';
    this.imageSrcThree = '';
    this.imageSrcFour = '';
    this.imageSrcFive = '';
    this.imageSrcSix = '';
    this.imageSrcSeven = '';
  }
  autoGenerate() {
    this.productService.autoGenerateBarcode({}, this.currentUser.id).then((codeData) => {
      if (codeData) {
        this.ProductForm.patchValue({
          generateBarcode: codeData.data
        })
      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went To Wrong");
      }
    })
  }

  // onFileSelected(event) {
  //   const frmData = new FormData();
  //   // this.previews = [];
  //   for (var i = 0; i < event.target.files.length; i++) {
  //     //this.myFiles.push(event.target.files[i]);
  //     const reader = new FileReader();
  //     reader.onload = (e: any) => {
  //       this.previews.push(e.target.result);
  //     };

  //     reader.readAsDataURL(event.target.files[i]);
  //     frmData.append("upload", event.target.files[i]);
  //   }
  //   console.log(this.previews)

  //   this.uploadMultiplefile(frmData)



  // }
  onFileSelected(event) {
    const maxFileSize = 1024 * 1024; // 1 MB
    const selectedFiles = event.target.files;
    if (this.maxFiles == 5) {
      this.toastService.toastMsg({
        title: "Error",
        content: "You Have AllReady Select 5 Images.",
      });
      // this.toastService.openErrorSnackBar(`You have all ready select 5 images.`);
    }
    else {
      if (selectedFiles.length > (this.Filesmax - this.maxFiles)) {
        // Handle too many files error
        console.error("You can only select up to 5 files.");
        this.toastService.toastMsg({
          title: "Error",
          content: "You Can Only Select Up To ${this.Filesmax - this.maxFiles} Images.",
        });
        // this.toastService.openErrorSnackBar(`You can only select up to ${this.Filesmax - this.maxFiles} images.`);
        return;
      }

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        console.log("file", file.size)
        if (file.size > maxFileSize) {
          // Handle file size too large error
          //console.error(`File ${file.name} exceeds the maximum size of 1 MB.`);
          this.toastService.toastMsg({
            title: "Error",
            content: "File ${file.name} Exceeds The Maximum Size Of 1 MB.",
          });
          // this.toastService.openErrorSnackBar(`File ${file.name} exceeds the maximum size of 1 MB.`);
          return;
        }
      }

      // Continue with file processing
      const frmData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };
        reader.readAsDataURL(selectedFiles[i]);
        frmData.append("upload", selectedFiles[i]);
      }
      this.maxFiles = this.maxFiles + selectedFiles.length;
      console.log("this.maxFiles", this.maxFiles)
      console.log(this.previews);
      this.uploadMultiplefile(frmData);
    }


  }

  uploadMultiplefile(frmData: FormData) {
    this.productService.postCoverImage1(frmData).then((res) => {
      if (res) {
        if (this.multipleImage.length == 0) {
          this.multipleImage = res.data.locations;;
          console.log("1 st Multiple", this.multipleImage)
        }
        else {
          this.multipleImage1 = res.data.locations;

          for (var i = 0; i < this.multipleImage1.length; i++) {
            this.multipleImage.push(this.multipleImage1[i]);
          }
          console.log("length Multiple", this.multipleImage.length)
          console.log("again Multiple", this.multipleImage)

        }
        this.toastService.toastMsg({
          title: "Success",
          content: "Product Images Added Successfully!!!",
        });
        // this.toastService.openSnackBar("Product Images added Successfully!!!");
        //console.log("before",this.multipleImage);

      }

    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went To Wrong",
        });
        // this.toastService.openErrorSnackBar("Something Went To Wrong");
      }
    });

  }

  cancelmultiImage(i: number) {
    //var index = this.previews.indexOf(i);
    console.log("cancel images", i)
    this.previews.splice(i, 1); // Removes one element, starting from index
    this.maxFiles = this.maxFiles - 1; // Decrement maxFiles by 1
    console.log("cancelmultiImage, this.maxFiles", this.maxFiles)
    //console.log("before1",this.multipleImage);
    //var index1 = this.multipleImage.indexOf(removeItem);

    this.multipleImage.splice(i, 1);
    // console.log("after",this.multipleImage);
    this.toastService.toastMsg({
      title: "Success",
      content: 'Product Images Deleted Successfully!!!',
    }); 
  }

  cancelCoverImage() {
    this.imageSrcSeven = '';
    this.productPicUrl = '';
    this.imageLoaded = false;
    this.toastService.toastMsg({
      title: "Success",
      content: "Cover Image removed successfully!!!",
    });
  }
  openDatepicker(){
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.ProductForm.get('asOfDate').value;
    if (isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.ProductForm.get('asOfDate').setValue(new Date());
    }

 }
  openDatepickerexpiry(){
      // Check if the current value of taskdate is an "Invalid Date"
      const selectedDate2 = this.ProductForm.get('expiry').value;
      if (isNaN(selectedDate2.getTime())) {
        // Set a default date or any valid date here
        this.ProductForm.get('expiry').setValue(new Date());
      }
  }

  // Function to handle selection change in warehouse selection dropdown
  onWarehouseSelectionChange(event: any) {
    if (event.value === 'clear') {
      this.ProductForm.get('warehouse').setValue(null); // Clear the selection
    }
  }

}
