<!-- <div class="modal-header row" id="Add-Party-modal">
  <div class="col-lg-6 col-sm-12 col-md-4">
    <h1 style="color: #EB8B3F; font-weight: bolder;" class="modal-title">Payment Out</h1>
  </div>
  <div class="col-lg-3 col-sm-12 col-md-1"></div>
  <div class="col-lg-2 col-sm-12 col-md-3 mb-2 ps-5">
    <button type="button" class="btn btn-cancel" (click)="cancel()">Cancel</button>
  </div>

  <div class="col-lg-1 col-sm-12 col-md-3 mb-2">
    <button type="button" class="btn btn-save" (click)="postPaymentOutDetails()">Save</button>
  </div>

</div> -->

<div class="modal-header pt-0">
  <h1 style="color: #EB8B3F; font-weight: bolder;margin-left: 5px;" class="modal-title mb-0">Payment Out</h1>

  <div class="addProductButtons">

    <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel" (click)="cancel()">Cancel</button>
    <button type="submit" class="btn btn-save" (click)="postPaymentOutDetails()">Save</button>

  </div>

</div>

<div class="modal-body">
  <form [formGroup]="PaymentOutForm" *ngIf="!isArabic">
    <div class="row">
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Vendor Name<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input type="text" placeholder="Vendor Name" aria-label="Select Vendor Name" matInput [formControl]="myControl"
            (keyup)="searchPartyByName($event.target.value)" [matAutocomplete]="auto" required [ngClass]="{
                'is-invalid': (myControl.invalid && myControl.touched) && submitted }">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option>
              <a class="mobVerify" style="display: inline-block; width: 100%;" aria-hidden="true" title="Verify Mobile"
                (click)="openAddPartyModal(addParty)">
                Add new Vendor
              </a>
            </mat-option>
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
              (onSelectionChange)="setSelectedPartyDeatails(option)">
              {{ option.partyName | titlecase }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || myControl.touched) && myControl.errors?.required">
          Vendor Name Is Required!
        </mat-error>
      </div>

      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Receipt #<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matInput type="text" placeholder="Receipt #"  autocomplete="off" required formControlName="ReceiptNo" required
            (keypress)="numberOnly($event)" [ngClass]="{
              'is-invalid': (f.ReceiptNo.invalid && f.ReceiptNo.touched) && submitted }">
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.ReceiptNo.touched) && f.ReceiptNo.errors?.required">
          Receipt # Is Required!
        </mat-error>
      </div>
    </div>

    <div class="row">
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Payment Type<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-select placeholder="Payment Type" formControlName="paymentType" [ngClass]="{
              'is-invalid': (f.paymentType.invalid && f.paymentType.touched) && submitted }">
            <mat-option [value]="'CASH'">CASH</mat-option>
            <mat-option [value]="'CHEQUE'">CHEQUE</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.paymentType.touched) && f.paymentType.errors?.required">
          Payment Type Is Required!
        </mat-error>
      </div>

      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label" for="Date">Date</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matInput required ngModel bsDatepicker placeholder="Receipt Date." [maxDate]="today"
            [(ngModel)]="todaysDate" id="datePicker" bsDatepicker style="text-transform:uppercase!important;"
            [bsConfig]="{
                      containerClass: 'theme-dark-blue',
                      dateInputFormat: 'DD MMM YYYY',
                      showWeekNumbers:false
                      }" [ngModelOptions]="{ standalone: true }" (click)="openDatepicker()">
          <span>
            <mat-icon class="iconcalender">date_range</mat-icon>
          </span>
        </mat-form-field>
        <!-- <mat-form-field class="example-full-width" appearance="outline">
          <input matInput required ngModel bsDatepicker  placeholder="Receipt Date." [maxDate]="today"
            style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                          containerClass: 'theme-dark-blue',
                          dateInputFormat: 'DD MMM YYYY',
                           showWeekNumbers:false
                          }" formControlName="date" autocomplete="off" (click)="openDatepicker()">
          <span>
            <mat-icon class="iconcalender p-1">date_range</mat-icon>
          </span>
        </mat-form-field> -->
      </div>
    </div>

    <div class="row">
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
        <label class="form-label">Description<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <textarea matInput rows="5" placeholder="Description" formControlName="Description" required [ngClass]="{
              'is-invalid': (f.Description.invalid && f.Description.touched) && submitted }"></textarea>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.Description.touched) && f.Description.errors?.required">
          Description Is Required!
        </mat-error>
      </div>

      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-4 pt-4">
        <button  *ngIf="imageSrc" class="delete-button" type="button" style = "position: relative;top: -11px;left: 220px;" (click) = "cancelImage()">
          <i class="bi bi-x bi-sm" style="color:red"></i>
        </button>
        <div class="{{imageSrc ? '':'dash'}} " style="height: 100px;margin-top: 8px;"
        [ngStyle]="{'margin-top': imageSrc ? '-14px' : '0px'}">
          <!-- <i *ngIf="imageSrc" class="bi bi-x-circle text-danger fs-1 fw-bold" (click)="cancelImage()"
            data-bs-toggle="tooltip" data-bs-placement="top" title="delete image"></i> -->

          <img class="m-2" [src]="imageSrc ? imageSrc : ''" (load)="handleImageLoad()" [class.loaded]="imageLoaded"
            class="img-thumbnail text-center" />
          <div class="input--file">
            <span *ngIf="!imageSrc" class="fas fa-camera fs-1" style="padding-left: 45%;cursor: pointer;" (click)="startUpload()">
            </span>
            <input name="Select File" type="file" id="uploadFile" (change)="handleInputChange($event)" />
            <div *ngIf="!imageSrc" class="mt-1 pl-0">
              <h2 class="text-bold text-center">
                Add Receipt
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Paid<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matInput type="text" placeholder="Paid" formControlName="recieved" autocomplete="off" (keypress)="numberOnly($event)"
            required [ngClass]="{
                  'is-invalid': (f.recieved.invalid && f.recieved.touched) && submitted }">
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.recieved.touched) && f.recieved.errors?.required">
          Paid Is Required!
        </mat-error>
      </div>
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Total<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matInput type="text" placeholder="Total" formControlName="total" autocomplete="off" required
            (keypress)="numberOnly($event)" [ngClass]="{
                  'is-invalid': (f.total.invalid && f.total.touched) && submitted }">
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.total.touched) && f.total.errors?.required">
          Total Is Required!
        </mat-error>
      </div>
    </div>

    <div class="col-12 d-flex p-3 justify-content-end">
      <div class="">
        <!-- <button type="button" class="btn btn-outline-primary" style="margin-left: 10px;">Print</button> -->

        <!-- <button class="btn btn-outline-primary dropdown-toggle" id="dropdownMenu2" type="button"
          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Share
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
          <li><a class="dropdown-item" href="#">Share</a></li>
          <li><a class="dropdown-item" href="#">Print</a></li>
        </ul> -->
      </div>
      <!-- <button type="button" class="btn btn-outline-primary" (click)="postPaymentOutDetails()"
          style="margin-left: 10px;">Save</button> -->
    </div>
  </form>
  <form [formGroup]="PaymentOutForm" *ngIf="isArabic">
    <div class="row">
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Vendor Name<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input type="text" placeholder="Vendor Name" aria-label="Select Vendor Name" matInput [formControl]="myControl"
            (keyup)="searchPartyByName($event.target.value)" [matAutocomplete]="auto" required [ngClass]="{
                'is-invalid': (myControl.invalid && myControl.touched) && submitted }">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option>
              <a class="mobVerify" style="display: inline-block; width: 100%;" aria-hidden="true" title="Verify Mobile"
                (click)="openAddPartyModal(addParty)">
                Add new Vendor
              </a>
            </mat-option>
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
              (onSelectionChange)="setSelectedPartyDeatails(option)">
              {{ option.partyName | titlecase }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || myControl.touched) && myControl.errors?.required">
          Vendor Name Is Required!
        </mat-error>
      </div>

      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Receipt #<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matInput type="text" placeholder="Receipt #"  autocomplete="off" required formControlName="ReceiptNo" required
            (keypress)="numberOnly($event)" [ngClass]="{
              'is-invalid': (f.ReceiptNo.invalid && f.ReceiptNo.touched) && submitted }">
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.ReceiptNo.touched) && f.ReceiptNo.errors?.required">
          Receipt # Is Required!
        </mat-error>
      </div>
    </div>

    <div class="row">
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Payment Type<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-select placeholder="Payment Type" formControlName="paymentType" [ngClass]="{
              'is-invalid': (f.paymentType.invalid && f.paymentType.touched) && submitted }">
            <mat-option [value]="'CASH'">CASH</mat-option>
            <mat-option [value]="'CHEQUE'">CHEQUE</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.paymentType.touched) && f.paymentType.errors?.required">
          Payment Type Is Required!
        </mat-error>
      </div>

      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label" for="Date">Date</label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matInput required ngModel bsDatepicker placeholder="Receipt Date." [maxDate]="today"
            [(ngModel)]="todaysDate" id="datePicker" bsDatepicker style="text-transform:uppercase!important;"
            [bsConfig]="{
                      containerClass: 'theme-dark-blue',
                      dateInputFormat: 'DD MMM YYYY',
                      showWeekNumbers:false
                      }" [ngModelOptions]="{ standalone: true }" (click)="openDatepicker()">
          <span>
            <mat-icon class="iconcalender">date_range</mat-icon>
          </span>
        </mat-form-field>
        <!-- <mat-form-field class="example-full-width" appearance="outline">
          <input matInput required ngModel bsDatepicker placeholder="Receipt Date." [maxDate]="today"
            style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                          containerClass: 'theme-dark-blue',
                          dateInputFormat: 'DD MMM YYYY',
                           showWeekNumbers:false
                          }" formControlName="date" autocomplete="off" (click)="openDatepicker()">
          <span>
            <mat-icon class="iconcalender p-1">date_range</mat-icon>
          </span>
        </mat-form-field> -->
      </div>
    </div>

    <div class="row">
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-8">
        <label class="form-label">Description<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <textarea matInput rows="5" placeholder="Description" formControlName="Description" required [ngClass]="{
              'is-invalid': (f.Description.invalid && f.Description.touched) && submitted }"></textarea>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.Description.touched) && f.Description.errors?.required">
          Description Is Required!
        </mat-error>
      </div>

      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-4 pt-4">
        <button  *ngIf="imageSrc" class="delete-button" type="button" style = "position: relative;top: -11px;left: 220px;" (click) = "cancelImage()">
          <i class="bi bi-x bi-sm" style="color:red"></i>
        </button>
        <div class="{{imageSrc ? '':'dash'}} " style="height: 100px;margin-top: 8px;"
        [ngStyle]="{'margin-top': imageSrc ? '-14px' : '0px'}">
          <!-- <i *ngIf="imageSrc" class="bi bi-x-circle text-danger fs-1 fw-bold" (click)="cancelImage()"
            data-bs-toggle="tooltip" data-bs-placement="top" title="delete image"></i> -->

          <img class="m-2" [src]="imageSrc ? imageSrc : ''" (load)="handleImageLoad()" [class.loaded]="imageLoaded"
            class="img-thumbnail text-center" />
          <div class="input--file">
            <span *ngIf="!imageSrc" class="fas fa-camera fs-1" style="padding-left: 45%;cursor: pointer;" (click)="startUpload()">
            </span>
            <input name="Select File" type="file" id="uploadFile" (change)="handleInputChange($event)" />
            <div *ngIf="!imageSrc" class="mt-1 pl-0">
              <h2 class="text-bold text-center">
                Add Receipt
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Paid<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matInput type="text" placeholder="Paid" formControlName="recieved" autocomplete="off" (keypress)="numberOnly($event)"
            required [ngClass]="{
                  'is-invalid': (f.recieved.invalid && f.recieved.touched) && submitted }">
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.recieved.touched) && f.recieved.errors?.required">
          Paid Is Required!
        </mat-error>
      </div>
      <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="form-label">Total<span class="error-asterisk">*</span></label>
        <mat-form-field class="example-full-width" appearance="outline">
          <input matInput type="text" placeholder="Total" formControlName="total" autocomplete="off" required
            (keypress)="numberOnly($event)" [ngClass]="{
                  'is-invalid': (f.total.invalid && f.total.touched) && submitted }">
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="(submitted || f.total.touched) && f.total.errors?.required">
          Total Is Required!
        </mat-error>
      </div>
    </div>

    <div class="col-12 d-flex p-3 justify-content-end">
      <div class="">
        <!-- <button type="button" class="btn btn-outline-primary" style="margin-left: 10px;">Print</button> -->

        <!-- <button class="btn btn-outline-primary dropdown-toggle" id="dropdownMenu2" type="button"
          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Share
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
          <li><a class="dropdown-item" href="#">Share</a></li>
          <li><a class="dropdown-item" href="#">Print</a></li>
        </ul> -->
      </div>
      <!-- <button type="button" class="btn btn-outline-primary" (click)="postPaymentOutDetails()"
          style="margin-left: 10px;">Save</button> -->
    </div>
  </form>
</div>
<!-- Add New Party Modal -->
<ng-template #addParty let-modal>
  <div class="modal-content position-relative">
    <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
      <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" (click)="modalDismiss()"
        aria-label="Close"></a>
    </div>
    <div class="modal-body p-0" *ngIf="!isArabic">
      <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
        <h2 class="mb-1" style="color: #EB8B3F; font-weight: bolder;" id="modalExampleDemoLabel">Add New Vendor</h2>
      </div>
      <form [formGroup]="PartyForm">
        <div class="p-4 pb-0">
          <div class="form-check form-switch mb-0 lh-1 mb-3">
            <input class="form-check-input" type="checkbox" [checked]="isShowGstFields" id="flexSwitchCheckDefault"
              (click)="customertoggleShow($event.target.checked)" />
            <label class="lab text-nowrap p-1"> As Customer </label>
          </div>

          <div class="row d-flex justify-content-start" *ngIf="!isShowGstFields">
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label class="form-label">GSTIN<span class="error-asterisk">*</span></label>
              <mat-form-field class="example-full-width" appearance="outline">
                <input matInput required formControlName="gstIn" (change)="getGstNumber($event.target.value)"
                  maxlength="15" minlength="15"
                  pattern="^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$"
                  placeholder="GSTIN" value="" style="text-transform:uppercase">
              </mat-form-field>
              <mat-error *ngIf="(submittedParty ||fp.gstIn.touched) && fp.gstIn.errors?.required">
                Enter a valid GSTIN.
              </mat-error>

              <mat-error
                *ngIf="(submittedParty ||pf.gstIn.touched) && pf.gstIn.errors?.pattern || pf.gstIn.errors?.minlength">
                Enter a valid GSTIN.
              </mat-error>
              <mat-error *ngIf="(submittedParty ||pf.gstIn.touched) && pf.gstIn.errors?.maxlength">
                Enter a valid GSTIN.
              </mat-error>
              <!-- <mat-error *ngIf="(submittedParty ||pf.gstIn.touched) && pf.gstIn.errors?.minlength">
                Enter a valid GSTIN.
              </mat-error> -->
            </div>

            <div style=" font-size: 12px" class=" col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label class="form-label">GST Type<span class="error-asterisk">*</span></label>
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-select matNativeControl required formControlName="gstType" disableOptionCentering
                  placeholder="GST Type" [disabled]="!isDisabledGST">
                  <mat-option value="REGULAR">Regular</mat-option>
                  <mat-option value="COMPOSITE">Composite</mat-option>
                  <mat-option value="EXEMPTED">Exempted</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error *ngIf="(submittedParty ||fp.gstType.touched) && fp.gstType.errors?.required">
                GST Type Is Required!
              </mat-error>
            </div>
          </div>

          <div class="row d-flex justify-content-start">
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label class="form-label">Vendor Name<span class="error-asterisk">*</span></label>
              <mat-form-field class="example-full-width" appearance="outline">
                <input matNativeControl required matInput placeholder="Vendor Name" formControlName="partyName"
                  [ngClass]="{
                    'is-invalid': (pf.partyName.invalid && pf.partyName.touched) && submittedParty }">
              </mat-form-field>
              <mat-error class="text-danger" *ngIf="(submittedParty || pf.partyName.touched)">
                <mat-error *ngIf="(submittedParty || pf.partyName.touched) && pf.partyName.errors?.required">
                  Vendor Name Is Required!
                </mat-error>
              </mat-error>

            </div>

            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label class="form-label">Mobile Number<span class="error-asterisk">*</span></label>
              <mat-form-field class="example-full-width" appearance="outline">
                <input type="text" placeholder="Mobile Number" matInput formControlName="mobile" minlength="10"
                  maxLength="10" ngxOnlyNumbers [ngClass]="{
                      'is-invalid': (pf.mobile.invalid && pf.mobile.touched) && submittedParty }">
              </mat-form-field>

              <mat-error class="text-danger" *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.pattern">
                Enter a valid mobile number.
              </mat-error>
              <mat-error class="text-danger"
                *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.required">
                Mobile Number is required.
              </mat-error>
              <mat-error *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.maxlength">
                Mobile Number must be of 10-digits
              </mat-error>
              <mat-error *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.minlength">
                Mobile Number must be of 10-digits
              </mat-error>
            </div>

            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label class="form-label">E-Mail<span class="error-asterisk">*</span></label>
              <mat-form-field class="example-full-width" appearance="outline">
                <input type="email" placeholder="e-mail" matInput formControlName="email" [ngClass]="{
                      'is-invalid': (pf.email.invalid && pf.email.touched) && submittedParty }">
              </mat-form-field>
              <mat-error class="text-danger"
                *ngIf="(submittedParty || pf.email.touched) && pf.email.errors?.pattern && pf.email.errors?.email">
                Enter a valid email id.
              </mat-error>
              <mat-error class="text-danger" *ngIf="(submittedParty || pf.email.touched) && pf.email.errors?.required">
                Email id is required.
              </mat-error>
            </div>
          </div>

          <div class="row d-flex justify-content-start">
            <div class="col-lg-4 col-sm-12 col-md-6">
              <label class="form-label">Billing Address<span class="error-asterisk">*</span></label>
              <mat-form-field class="example-full-width" appearance="outline">
                <textarea matInput required formControlName="billingAddress" placeholder="Enter your address"
                  [ngClass]="{
                      'is-invalid': (pf.billingAddress.invalid && pf.billingAddress.touched) && submittedParty }"></textarea>
              </mat-form-field>
              <mat-error class="text-danger" *ngIf="(submittedParty || pf.billingAddress.touched)">
                <mat-error *ngIf="(submittedParty || pf.billingAddress.touched) && pf.billingAddress.errors?.required">
                  Billing Address Is Required!
                </mat-error>
              </mat-error>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6" *ngIf="isShippingAddressinModal">
              <label class="form-label">Shipping Address</label>
              <mat-form-field class="example-full-width" appearance="outline">
                <textarea matInput formControlName="shippingAddress" placeholder="Enter your address"></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="form-check form-check-inline mb-2">
            <label class="form-label" class="form-check-label fs--1" for="inlineCheckbox1">Add Shipping Address</label>
            <input class="form-check-input fs--1" id="inlineCheckbox1" type="checkbox"
              (change)="showShippingAddressinModal($event.target.checked)" />
          </div>
        </div>
      </form>
    </div>
    <div class="modal-body p-0" *ngIf="isArabic">
      <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
        <h2 class="mb-1" style="color: #EB8B3F; font-weight: bolder;" id="modalExampleDemoLabel" style = "font-size: 16px;font-weight:500;font-family: 'Poppins';">Add New Vendor</h2>
      </div>
      <form [formGroup]="PartyForm">
        <div class="p-4 pb-0">
          <div class="form-check form-switch mb-0 lh-1 mb-3">
            <input class="form-check-input" type="checkbox" [checked]="isShowGstFields" id="flexSwitchCheckDefault"
              (click)="customertoggleShow($event.target.checked)" />
            <label class="lab text-nowrap p-1"  style = "font-size: 14px;"> As Customer </label>
          </div>

          <div class="row d-flex justify-content-start" *ngIf="!isShowGstFields">
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <div><mat-label>{{ lang.GSTIN }}<span class="error-asterisk">*</span></mat-label></div>
              <mat-form-field class="example-full-width" appearance="outline" >
                  <input type="text" matInput formControlName="gstIn" placeholder="{{lang.GSTIN }}" #input1
                  (keypress)="numberOnly($event)" maxlength="15" (input)="validateTRN($event.target.value)"
                      [ngClass]="{'is-invalid': (fp.gstIn.invalid && fp.gstIn.touched) && submittedParty }"
                      required>
              </mat-form-field>

              <mat-error class="text-danger" *ngIf="(submittedParty && fp.gstIn.errors?.required)">
                  {{ lang.GSTIN }} is Required!
              </mat-error>
              <mat-error class="text-danger"
                  *ngIf="fp.gstIn.invalid && (fp.gstIn.dirty || fp.gstIn.touched)">
                  Please enter the valid {{ lang.GSTIN }} number
              </mat-error>
              <mat-error class="text-danger" *ngIf="fp.gstIn.errors?.maxlength">
                  {{ lang.GSTIN }} must be of 15-digits
              </mat-error>
              <mat-error class="text-danger" *ngIf="fp.gstIn.errors?.minlength">
                  {{ lang.GSTIN }} must be of 15-digits
              </mat-error>
              <!-- <mat-error class="text-danger" *ngIf="f.gstinNo.errors?.required">GSTIN Is Required!</mat-error> -->
              <mat-error class="text-danger" *ngIf="fp.gstIn.errors?.pattern">
                  {{ lang.GSTIN }} must be valid number
              </mat-error>
          </div>
          </div>


            <!-- <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label class="form-label">{{lang.GSTIN}}</label>
              <mat-form-field class="example-full-width" appearance="outline">
                <input matInput required formControlName="gstIn" (change)="getGstNumber($event.target.value)"
                  maxlength="15" minlength="15"
                  pattern="^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$"
                  placeholder="{{lang.GSTIN}}" value="" style="text-transform:uppercase">
              </mat-form-field>
              <mat-error *ngIf="(submittedParty ||fp.gstIn.touched) && fp.gstIn.errors?.required">
                Enter a valid {{lang.GSTIN}}.
              </mat-error>

              <mat-error
                *ngIf="(submittedParty ||pf.gstIn.touched) && pf.gstIn.errors?.pattern || pf.gstIn.errors?.minlength">
                Enter a valid {{lang.GSTIN}}.
              </mat-error>
              <mat-error *ngIf="(submittedParty ||pf.gstIn.touched) && pf.gstIn.errors?.maxlength">
                Enter a valid {{lang.GSTIN}}.
              </mat-error>
              <mat-error *ngIf="(submittedParty ||pf.gstIn.touched) && pf.gstIn.errors?.minlength">
                Enter a valid GSTIN.
              </mat-error> -->





          <div class="row d-flex justify-content-start">
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label class="form-label">Vendor Name<span class="error-asterisk">*</span></label>
              <mat-form-field class="example-full-width" appearance="outline">
                <input matNativeControl required matInput placeholder="Vendor Name" formControlName="partyName"
                  [ngClass]="{
                    'is-invalid': (pf.partyName.invalid && pf.partyName.touched) && submittedParty }">
              </mat-form-field>
              <mat-error class="text-danger" *ngIf="(submittedParty || pf.partyName.touched)">
                <mat-error *ngIf="(submittedParty || pf.partyName.touched) && pf.partyName.errors?.required">
                  Vendor Name Is Required!
                </mat-error>
              </mat-error>

            </div>

            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label class="form-label">Mobile Number<span class="error-asterisk">*</span></label>
              <mat-form-field class="example-full-width" appearance="outline">
                <input type="text" placeholder="Mobile Number" matInput formControlName="mobile" minlength="9"
                  maxLength="9" ngxOnlyNumbers [ngClass]="{
                      'is-invalid': (pf.mobile.invalid && pf.mobile.touched) && submittedParty }">
              </mat-form-field>

              <mat-error class="text-danger" *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.pattern">
                Enter a valid mobile number.
              </mat-error>
              <mat-error class="text-danger"
                *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.required">
                Mobile Number is required.
              </mat-error>
              <mat-error *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.maxlength">
                Mobile Number must be of 9-digits
              </mat-error>
              <mat-error *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.minlength">
                Mobile Number must be of 9 digits
              </mat-error>
            </div>

            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <label class="form-label">E-Mail<span class="error-asterisk">*</span></label>
              <mat-form-field class="example-full-width" appearance="outline">
                <input type="email" placeholder="e-mail" matInput formControlName="email" [ngClass]="{
                      'is-invalid': (pf.email.invalid && pf.email.touched) && submittedParty }">
              </mat-form-field>
              <mat-error class="text-danger"
                *ngIf="(submittedParty || pf.email.touched) && pf.email.errors?.pattern && pf.email.errors?.email">
                Enter a valid email id.
              </mat-error>
              <mat-error class="text-danger" *ngIf="(submittedParty || pf.email.touched) && pf.email.errors?.required">
                Email id is required.
              </mat-error>
            </div>
          </div>

          <div class="row d-flex justify-content-start">
            <div class="col-lg-4 col-sm-12 col-md-6">
              <label class="form-label">Billing Address<span class="error-asterisk">*</span></label>
              <mat-form-field class="example-full-width" appearance="outline">
                <textarea matInput required formControlName="billingAddress" placeholder="Enter your address"
                  [ngClass]="{
                      'is-invalid': (pf.billingAddress.invalid && pf.billingAddress.touched) && submittedParty }"></textarea>
              </mat-form-field>
              <mat-error class="text-danger" *ngIf="(submittedParty || pf.billingAddress.touched)">
                <mat-error *ngIf="(submittedParty || pf.billingAddress.touched) && pf.billingAddress.errors?.required">
                  Billing Address Is Required!
                </mat-error>
              </mat-error>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6" *ngIf="isShippingAddressinModal">
              <label class="form-label">Shipping Address</label>
              <mat-form-field class="example-full-width" appearance="outline">
                <textarea matInput formControlName="shippingAddress" placeholder="Enter your address"></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="form-check form-check-inline mb-2">
            <label class="form-label" class="form-check-label fs--1" for="inlineCheckbox1"><span  style = "font-size: 14px;">Add Shipping Address</span></label>
            <input class="form-check-input fs--1" id="inlineCheckbox1" type="checkbox"
              (change)="showShippingAddressinModal($event.target.checked)" />
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-cancel" (click)="modalDismiss()">Close</button>
      <button class="btn btn-save" (click)="addNewParty()" data-bs-dismiss="modal">Add
      </button>
    </div>
  </div>
</ng-template>


<!-- UAE -->












<!-- <form [formGroup]="PaymentOutForm">
  <div class="card">
    <div class="card-header bg-light pt-3 pb-3">
      <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
        Payment Out
      </p>
    </div>
    <div class="progress" style="height: 2px">
      <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-3 col-lg-4">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Party Name</mat-label>
            <input type="text" placeholder="Party Name" aria-label="Select Party Name" matInput [formControl]="myControl"
            (keyup)="searchPartyByName($event.target.value)" [matAutocomplete]="auto" required [ngClass]="{
                'is-invalid': (myControl.invalid && myControl.touched) && submitted }">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option>
                <a class="mobVerify" style="display: inline-block; width: 100%;" aria-hidden="true" title="Verify Mobile" (click)="openAddPartyModal(addParty)">
                  Add new party
              </a>
              </mat-option>
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
              (onSelectionChange)="setSelectedPartyDeatails(option)" >
                {{ option.partyName | titlecase }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="(submitted || myControl.touched) && myControl.errors?.required">
            Party Name Is Required!
          </mat-error>
        </div>
        <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-3 col-lg-4">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Recipt No.</mat-label>
            <input matInput type="text" placeholder="Recipt No." required formControlName="ReceiptNo" required
            (keypress)="numberOnly($event)"   [ngClass]="{
              'is-invalid': (f.ReceiptNo.invalid && f.ReceiptNo.touched) && submitted }">
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="(submitted || f.ReceiptNo.touched) && f.ReceiptNo.errors?.required">
            Receipt No Is Required!
          </mat-error>
        </div>
        <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-3 col-lg-4">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Payment Type</mat-label>
            <mat-select placeholder="paymentType" formControlName="paymentType" [ngClass]="{
              'is-invalid': (f.paymentType.invalid && f.paymentType.touched) && submitted }">
              <mat-option [value]="'CASH'">CASH</mat-option>
              <mat-option [value]="'CHEQUE'">CHEQUE</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="(submitted || f.paymentType.touched) && f.paymentType.errors?.required">
            Payment Type Is Required!
          </mat-error>
        </div>
      </div>
      <div class="row">
        <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-3 col-lg-4">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label for="Date">Date</mat-label>
            <input matInput required ngModel bsDatepicker placeholder="Receipt Date." [maxDate]="today"
              [(ngModel)]="todaysDate" id="datePicker" bsDatepicker style="text-transform:uppercase!important;" [bsConfig]="{
                      containerClass: 'theme-dark-blue',
                      dateInputFormat: 'DD MMM YYYY',
                      showWeekNumbers:false
                      }" [ngModelOptions]="{ standalone: true }">
            <span>
              <mat-icon class="iconcalender">date_range</mat-icon>
            </span>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Description</mat-label>
            <textarea matInput rows="5" placeholder="Description" formControlName="Description" required [ngClass]="{
              'is-invalid': (f.Description.invalid && f.Description.touched) && submitted }"></textarea>
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="(submitted || f.Description.touched) && f.Description.errors?.required">
            Description Is Required!
          </mat-error>
        </div>

        <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <div class="{{imageSrc ? '':'dash'}} " style="height:85% !important;">
            <i  *ngIf="imageSrc" class="bi bi-x-circle text-danger fs-1 fw-bold"  (click)="cancelImage()"
            data-bs-toggle="tooltip" data-bs-placement="top" title="delete image"
            ></i>

            <img  class="m-2"[src]="imageSrc ? imageSrc : ''" (load)="handleImageLoad()"
            [class.loaded]="imageLoaded" class="img-thumbnail text-center" />
            <div class="input--file">
              <span  *ngIf="!imageSrc"  class="fas fa-camera fs-1" style="padding-left: 45%;" (click)="startUpload()">
              </span>
              <input name="Select File" type="file" id="uploadFile"  (change)="handleInputChange($event)" />
            <div *ngIf="!imageSrc" class="mt-1 pl-0">
                <h2 class="text-bold text-center">
                  Add Receipt
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-3 col-lg-4">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Paid</mat-label>
            <input matInput type="text" placeholder="Paid" formControlName="recieved" (keypress)="numberOnly($event)"  required [ngClass]="{
                  'is-invalid': (f.recieved.invalid && f.recieved.touched) && submitted }">
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="(submitted || f.recieved.touched) && f.recieved.errors?.required">
            Paid Is Required!
          </mat-error>
        </div>
        <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-3 col-lg-4">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Total</mat-label>
            <input matInput type="text" placeholder="Total." formControlName="total" required (keypress)="numberOnly($event)"
             [ngClass]="{
                  'is-invalid': (f.total.invalid && f.total.touched) && submitted }">
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="(submitted || f.total.touched) && f.total.errors?.required">
            Total Is Required!
          </mat-error>
        </div>
      </div>

    </div>
    <div class="col-12 d-flex p-3 justify-content-end">
      <div class="">

      </div>
      <button type="button" class="btn btn-outline-primary" (click)="postPaymentOutDetails()" style="margin-left: 10px;">Save</button>
    </div>
  </div>
</form> -->
<!-- Add New Party Modal -->
<!-- <ng-template #addParty let-modal>
  <div class="modal-content position-relative">
    <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
      <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" (click)="modalDismiss()"
         aria-label="Close"></a>
    </div>
    <div class="modal-body p-0">
      <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
        <h4 class="mb-1" id="modalExampleDemoLabel">Add New Party</h4>
      </div>
      <form [formGroup]="PartyForm">
        <div class="p-4 pb-0">
          <div class="form-check form-switch mb-0 lh-1 mb-3">
            <input class="form-check-input" type="checkbox" [checked]="isShowGstFields"
              id="flexSwitchCheckDefault" (click)="customertoggleShow($event.target.checked)" />
            <label class="lab text-nowrap p-1"> As Customer </label>
          </div>

          <div class="row d-flex justify-content-start" *ngIf="!isShowGstFields">
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>GST Number</mat-label>
                <input matInput required formControlName="gstIn" (change)="getGstNumber($event.target.value)"
                  maxlength="15" minlength="15"
                  pattern="^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$"
                  placeholder="GST Number" value="" style="text-transform:uppercase">
              </mat-form-field>
              <mat-error *ngIf="(submittedParty ||fp.gstIn.touched) && fp.gstIn.errors?.required">
                Enter a valid GSTIN.
              </mat-error>

              <mat-error *ngIf="(submittedParty ||pf.gstIn.touched) && pf.gstIn.errors?.pattern || pf.gstIn.errors?.minlength">
                Enter a valid GSTIN.
              </mat-error>
              <mat-error *ngIf="(submittedParty ||pf.gstIn.touched) && pf.gstIn.errors?.maxlength">
                Enter a valid GSTIN.
              </mat-error>

            </div>
            <div style=" font-size: 12px" class=" col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>GST Type</mat-label>
                <mat-select matNativeControl required  formControlName="gstType" disableOptionCentering
                  placeholder="GST Type" [disabled]="!isDisabledGST">
                  <mat-option value="REGULAR">Regular</mat-option>
                  <mat-option value="COMPOSITE">Composite</mat-option>
                  <mat-option value="EXEMPTED">Exempted</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error *ngIf="(submittedParty ||fp.gstType.touched) && fp.gstType.errors?.required">
                GST Type Is Required!
              </mat-error>
            </div>
          </div>

          <div class="row d-flex justify-content-start">
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Party Name</mat-label>
                <input matNativeControl required matInput placeholder="Party Name" formControlName="partyName"  [ngClass]="{
                    'is-invalid': (pf.partyName.invalid && pf.partyName.touched) && submittedParty }">
              </mat-form-field>
              <mat-error class="text-danger" *ngIf="(submittedParty || pf.partyName.touched)">
                <mat-error *ngIf="(submittedParty || pf.partyName.touched) && pf.partyName.errors?.required">
                  Party Name Is Required!
                </mat-error>
              </mat-error>

            </div>
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Mobile Number</mat-label>
                <input type="text" placeholder="Mobile Number" matInput formControlName="mobile" minlength="10" maxLength="10" ngxOnlyNumbers
                  [ngClass]="{
                      'is-invalid': (pf.mobile.invalid && pf.mobile.touched) && submittedParty }">
              </mat-form-field>

              <mat-error class="text-danger" *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.pattern">
                Enter a valid mobile number.
              </mat-error>
              <mat-error class="text-danger" *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.required">
                Mobile Number is required.
              </mat-error>
              <mat-error *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.maxlength">
                Mobile Number must be of 10-digits
              </mat-error>
              <mat-error *ngIf="(submittedParty || pf.mobile.touched) && pf.mobile.errors?.minlength">
                Mobile Number must be of 10-digits
              </mat-error>
            </div>
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>E-Mail</mat-label>
                <input type="email" placeholder="e-mail" matInput formControlName="email" [ngClass]="{
                      'is-invalid': (pf.email.invalid && pf.email.touched) && submittedParty }">
              </mat-form-field>
              <mat-error class="text-danger"
                *ngIf="(submittedParty || pf.email.touched) && pf.email.errors?.pattern && pf.email.errors?.email">
                Enter a valid email id.
              </mat-error>
              <mat-error class="text-danger" *ngIf="(submittedParty || pf.email.touched) && pf.email.errors?.required">
                Email id is required.
              </mat-error>
            </div>
          </div>

          <div class="row d-flex justify-content-start">
            <div class="col-lg-4 col-sm-12 col-md-6">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Billing Address</mat-label>
                <textarea matInput required formControlName="billingAddress" placeholder="Enter your address"
                  [ngClass]="{
                      'is-invalid': (pf.billingAddress.invalid && pf.billingAddress.touched) && submittedParty }"></textarea>
              </mat-form-field>
              <mat-error class="text-danger" *ngIf="(submittedParty || pf.billingAddress.touched)">
                <mat-error *ngIf="(submittedParty || pf.billingAddress.touched) && pf.billingAddress.errors?.required">
                  Billing Address Is Required!
                </mat-error>
              </mat-error>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6" *ngIf="isShippingAddressinModal">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Shipping Address</mat-label>
                <textarea matInput formControlName="shippingAddress" placeholder="Enter your address"></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="form-check form-check-inline mb-2">
            <label class="form-check-label fs--1" for="inlineCheckbox1">Add Shipping Address</label>
            <input class="form-check-input fs--1" id="inlineCheckbox1" type="checkbox"
              (change)="showShippingAddressinModal($event.target.checked)" />
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-primary" (click)="modalDismiss()">Close</button>
      <button class="btn btn-outline-primary" (click)="addNewParty()" data-bs-dismiss="modal">Add
      </button>
    </div>
  </div>
</ng-template> -->
