import { Component, OnInit } from '@angular/core';

// import '../theme/public/assets/js/theme.js';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DynamicScriptLoaderService } from './_helpers/DynamicScriptLoaderService';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { DeviceDetectorService } from "ngx-device-detector";
import { NotificationService } from './core/services/notification.service';
import { CommonService } from './core/services/common.service';
import { ToastNotificationService } from './core/services/toast-notification.service';
import { TitleCasePipe } from '@angular/common';



@Component({
  selector: 'app-root',
  template: `nnnnnnnnnnnnn`,
  templateUrl: './app.component.html',

  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {


  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  public title = 'Iceipts Application';
  public message: any = null;
  public topPosToStartShowing = 100;
  public deviceInfo;
  public currentPageNo: number = 0;
  public pageSize: number = 15;
  constructor(
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
   ) {
    this.epicFunction()
  }

  ngBeViewInit() {

    // require('../theme/public/assets/js/theme.js');
  }
  private loadScripts() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.dynamicScriptLoader.load('themejs', 'bootstapminjs', 'a', 'b', 'c', 'd', 'e', 'g', 'h', 'i', 'j', 'k').then(data => {
      // Script Loaded Successfull
    }).catch(error => { });
  }

  loadScripts2() {
    const dynamicScripts = [
      '../theme/public/assets/js/theme.js',
      //'../theme/public/vendors/bootstrap/bootstrap.min.js' ,
      //  "../theme/public/vendors/popper/popper.min.js",
      //  "../theme/public/vendors/anchorjs/anchor.min.js",
      //  "../theme/public/vendors/is/is.min.js",
      //  "../theme/public/vendors/echarts/echarts.min.js",
      //  "../theme/public/vendors/fontawesome/all.min.js",
      //  "../theme/public/vendors/lodash/lodash.min.js",
      //  "https://polyfill.io/v3/polyfill.min.js?features=window.scroll",
      //  "../theme/public/vendors/list.js/list.min.js",
      //  "../theme/public/vendors/choices/choices.min.js",
      //  "../theme/public/assets/js/flatpickr.js",
      //  "../theme/public/vendors/list.js/list.min.js",

    ];

    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }


  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // new code
        // this.loadScripts2()
        this.loadScripts2()
      }
    });
    this.requestPermission();
    this.listen();
    if (this.currentUser) {
      this.getAllNotificationByUserId();
    }

    //scroll view to tp on route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      document.body.scrollTop = 0;
    });
  }


  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging,
      { vapidKey: environment.firebase.vapidKey }).then(
        (currentToken) => {
          if (currentToken) {
            this.postFcm(currentToken)
          } else {

          }
        }).catch((err) => {
        });
  }
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      this.message = payload;
    });
  }
  postFcm(token) {
    let data = {
      "fcm_key": token,
      "device_id": this.currentUser.id,
      "device_type": this.deviceInfo.deviceType,
      "userId": this.currentUser.id ? this.currentUser.id : "",
    }
    this.notificationService.postFcm(data).subscribe((res) => {
    })
  }
  /**
   * get notification by userId
   */
  getAllNotificationByUserId() {
    this.notificationService.getAllNotification({}, this.currentUser.id, this.pageSize, (this.currentPageNo + 1)).then((res) => {
      if (res) {
        this.commonService.sendData(res.data);
      }
    }
      , (err) => {
        if (err.error.expose) {
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }

      })
  }

  // init(editor: any): void {
  //   editor.ui
  //     .getEditableElement()
  //     .parentElement.insertBefore(
  //       editor.ui.view.toolbar.element,
  //       editor.ui.getEditableElement()
  //     );
  // }

}
