<div class="stikey_h">
    <div class="mb-2 d-flex justify-content-end">
        <div>
            <button class="btn btn-outline-secondary mx-2 rounded-pill btn-sm" (click)="ViewFullScreen()">
                <mat-icon>{{ ! isFullScreeenMode ? 'fullscreen': 'fullscreen_exit'}}</mat-icon>
                {{isFullScreeenMode ? 'Edit Screen' : 'Full Screen'}}
            </button>
        </div>
        <div>
            <button class="btn btn-outline-success rounded-pill btn-sm" (click)="addWidgetByRetailer()">
                <mat-icon>save</mat-icon>
                Save
            </button>
        </div>
    </div>
    <div class="row">
        <div class="{{isFullScreeenMode? 'd-none': 'col-xs-4 col-sm-4 col-md-4 col-lg-4'}} p-0">
            <div class="card">
                <div class="card-body">
                    <mat-accordion cdkDropList [cdkDropListData]="accordions" (cdkDropListDropped)="drop($event)">
                        <!-- <mat-expansion-panel formArrayName="Form" *ngFor="let creds of formArr.controls; let i = index " cdkDrag [cdkDropListSortingDisabled]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false"> -->
                        <mat-expansion-panel *ngFor="let creds of accordions;" cdkDrag
                            [cdkDropListSortingDisabled]="true" (opened)="panelOpenState = true"
                            (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-description>
                                    <!-- <h6 class="font">{{ creds.controls.type.value }}</h6> -->
                                    <h6 class="font">{{ creds.type }}</h6>
                                </mat-panel-description>
                            </mat-expansion-panel-header>


                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="addWidgets" (ngSubmit)="addNewWidgets()">
                        <mat-select formControlName="widegtType" class="size mt-2" title="Select widgets to add in list"
                            placeholder="Widgets" status="primary">
                            <mat-option class="text-no-wrap" *ngFor="let panel of accordions" [value]="panel.type">{{
                                panel.type }}
                            </mat-option>
                        </mat-select>
                        <span class="ml-2">
                            <button class="btn btn-primary" title="Click to add final widget" type="submit">+
                                Add</button>
                        </span>
                    </form>
                </div>
            </div>
        </div>

        <div
            class="{{isFullScreeenMode ? 'col-xs-12 col-sm-12 col-md-12 col-lg-12': 'col-xs-8 col-sm-8 col-md-8 col-lg-8'}} p-0">
            <div class="card">
                <div class="card-body p-0">
                    <div cdkDropList [cdkDropListData]="accordions">
                        <div *ngFor="let panel of accordions" class="p-0" cdkDrag>

                            <span *ngIf="panel.type == 'Header'">
                                <app-header></app-header>
                            </span>

                            <span *ngIf="panel.type == 'Slider'">
                                <app-slider></app-slider>
                            </span>

                            <span *ngIf="panel.type == 'Gallery'">
                                <app-gallery></app-gallery>
                            </span>

                            <span *ngIf="panel.type == 'Testimonial'">
                                <app-testimonial></app-testimonial>
                            </span>

                            <span *ngIf="panel.type == 'Section'">
                                <app-section></app-section>
                            </span>

                            <span *ngIf="panel.type == 'Feature Product'">
                                <app-feature-product></app-feature-product>
                            </span>

                            <span *ngIf="panel.type == 'Image'">
                                <app-image></app-image>
                            </span>

                            <span *ngIf="panel.type == 'Footer'">
                                <app-footer></app-footer>
                            </span>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>