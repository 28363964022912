import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-customer-appointment',
  templateUrl: './customer-appointment.component.html',
  styleUrls: ['./customer-appointment.component.scss']
})
export class CustomerAppointmentComponent implements OnInit {
  appointMentData=[
    {
      "id":1,
      "Name":"Antony Hopkins",
      "Date":"16/08/2022",
      "Time":"08:00 am",
    },
    {
      "id":2,
      "Name":"Anna Karinina",
      "Date":"09/08/2022",
      "Time":"11:00 am",
    },
    {
     "id":3,
     "Name":"John Lee",
     "Date":"11/08/2022",
     "Time":"02:00 ",
    },
    {
     "id":4,
     "Name":"Joe Bempsie",
     "Date":"17/08/2022",
     "Time":"03:30",
    },

  ]
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))

  customerApintlistres:any;
  constructor(private appointmentService : AppointmentService,
    private toastNotificationService:ToastNotificationService,
    private toastService: ToastNotificationService,

    private titleCasePipe: TitleCasePipe) { }

  ngOnInit(): void {
    this.getCustomerListByUser();
  }
 
  getCustomerListByUser(){
    this.appointmentService.getCustomerListByUser({},this.currentUser.id).then((res)=>{
      this.customerApintlistres=res.data.rows;
    },(err)=>{
      if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message)
          });
        // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }
}
