import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';

@Component({
  selector: 'app-view-employee',
  templateUrl: './view-employee.component.html',
  styleUrls: ['./view-employee.component.scss']
})
export class ViewEmployeeComponent implements OnInit {
  empId: any;
  fullName: any;
  email: any;
  mobile: any;
  profileImage: any;
  userType: any;
  designation: any;
  roleAssign: any;
  joiningDate: any;
  salary: any;
  assets: any;
  address: any;
  pincode: any;
  homeno: any;
  city: any;
  district: any;
  landmark: any;
  state: any;
  country: any;
  resignationDate: any;

  constructor(public activeModal: NgbActiveModal, @Inject(MAT_DIALOG_DATA) public modalData: any,
    public dialogRef: MatDialogRef<ViewEmployeeComponent>,
    public rolesPermissionsService : RolesPermissionsService) { }

  ngOnInit(): void {
    this.empId = this.modalData.itemId
    this.getEmployee()
  }

  getEmployee(){
    this.rolesPermissionsService.getEmployeeById({}, this.empId).then((response) => {
      console.log(response)
      this.fullName = response.data.firstName
      this.email = response.data.email
      this.mobile = response.data.mobile
      this.profileImage = response.data.profileImage
      this.userType = response.data.userType
      this.roleAssign = response.data.employee.roleAssign
      this.designation = response.data.employee.designation
      this.joiningDate = response.data.employee.joiningDate
      this.resignationDate = response.data.employee.resignationDate
      this.salary = response.data.employee.salary
      this.assets = response.data.employee.assets
      this.address = response.data.useraddresses[0].address
      this.pincode = response.data.useraddresses[0].pincode
      this.homeno = response.data.useraddresses[0].homeno
      this.city = response.data.useraddresses[0].city
      this.district = response.data.useraddresses[0].district
      this.landmark = response.data.useraddresses[0].landmark
      this.state = response.data.useraddresses[0].state
      this.country = response.data.useraddresses[0].country
    })
  }

  cancel() {
    this.dialogRef.close();

  }
}
