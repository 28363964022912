import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { AddPartyComponent } from '../add-party/add-party.component';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { PartyService } from 'src/app/core/services/party.service';
import { TitleCasePipe } from '@angular/common';
import { PartyGroupAddComponent } from '../party-group-add/party-group-add.component';
import { PartGroupServiceService } from 'src/app/core/services/part-group-service.service';

@Component({
  selector: 'app-party',
  templateUrl: './party.component.html',
  styleUrls: ['./party.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PartyComponent implements OnInit  {
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  partyListPage: boolean = true;
  grpListPage: boolean = false;
  partyGrpListPage: boolean = false;
  public excelFileUpload:any;
  partyList: any = [];
  partyId: string = "";
  public p = 1;
  public currentpage: any;
  public totalrow: any;
  public toatlPages: any;
  public currentPageNo: number = 1;
  public partyPageSize: number = 5;
  public pagesize: number = 1;
  public searchKey: any;
  searchGroupName: string = '';
  public partyGroupData:any=[];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, 
    public toastService: ToastNotificationService, private dialog: MatDialog, public partyService: PartyService,
    private titleCasePipe: TitleCasePipe, private partyGroupservice:PartGroupServiceService) { }
  
  ngOnInit(): void {
    // this.getGroupDetails()
  }

  // openDialog(): void {
  //   const dialogRef: MatDialogRef<AddPartyComponent> = this.dialog.open(AddPartyComponent, {
  //     width: '650px',
  //     height : '730px',
  //     data: { },
  //     panelClass: 'custom-dialog-Party-class'
  //   });

  //   dialogRef.afterClosed().subscribe((result) => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }

  // openDialogAddGrp(): void {
  //   const dialogRef: MatDialogRef<PartyGroupAddComponent> = this.dialog.open(PartyGroupAddComponent, {
  //     width: '650px',
  //     // height : '430px',
  //     data: { },
  //     panelClass: 'custom-dialog-PartyGrp-class'
  //   });

  //   dialogRef.afterClosed().subscribe((result) => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }

  isLinkActive(route: string): boolean {
    return this.router.isActive(route, true);
  }
  
  // selectedPage(str : string) {
  //   if(str === 'party-list'){
  //     this.partyListPage = true; 
  //     this.partyGrpListPage = false; 
  //   }
  //   if(str === 'group-list'){
  //     this.partyListPage = false; 
  //     this.partyGrpListPage = false; 
  //   }
  //   if(str === 'party-group-list'){
  //     this.partyGrpListPage = true; 
  //     this.partyListPage = false; 
  //   }
  // }

  // getAllParties() {
  //   this.partyService.getAllPartiesDetails({}, this.currentUser.id, this.partyPageSize, (this.currentPageNo )).then((res) => {
  //     this.p = res.data.currentPage;
  //     console.log("in the API p >>>>>>>>>>>>>>>>", this.p);
  //     this.partyList = res.data.pageData
  //     this.totalrow = res.data.totalrows
  //     this.toatlPages = res.data.totalPages
  //     console.log("party List: =>" ,res.data)
  //   }, (err) => {
  //     if (err.error.status == 404) {
  //       this.partyList = [];
  //     }
  //   })
  //   // const startIndex = this.p;
  //   // const endIndex = startIndex + this.pagesize;
  //   // const partiesToDisplay = this.partyList.slice(startIndex, endIndex);
  // }

  // handleInputChange(e){
  //   console.log("in here ")
  //   this.excelFileUpload= e.target.files[0];
  //   if(this.excelFileUpload){
  //     let formdata = new FormData();
  //     formdata.set("file", this.excelFileUpload)
  //     this.partyService.postBulkParty(formdata, this.currentUser.id).then((res) => {
  //       this.toastService.openSnackBar("Excel Uploaded Successfully")
  //       this.getAllParties();
  //       window.location.reload() 
  //     },(err) => {
  //       if (err.error.expose) {
  //         this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
  //       }
  //       else if(err.error.status == "409" && err.error.expose == false){
  //         this.toastService.openErrorSnackBar("List Of Parties Already Exists");
  //       }
  //       else{
  //         this.toastService.openErrorSnackBar("Something Went Wrong.");
  //       }
  //     });
  //   }
  // }

  // startUpload(){
  //   document.getElementById('uploadFile').click();
  // }

  // getPartyBySearch(searchKey) {
  //   this.partyService.getAllPartiesDetailsSearch({}, this.currentUser.id, this.partyPageSize, (this.currentPageNo),searchKey).then((res) => {
  //     this.p = res.data.currentpage;
  //     this.partyList = res.data.pageData.filter(party => party.isDeleted === false);
  //     this.totalrow = res.data.totalrows;
  //     this.toatlPages = res.data.totalPages;
  //     // console.log('partyList:', this.partyList);

  //     if(this.totalrow == 0){
  //       this.toastService.openErrorSnackBar("Party Not Found!!!");
  //     }else{
  //       this.toastService.openSnackBar("Party Found Successfully!!!");
  //     }
      
  //   }, (err) => {
  //     if (err.error.expose) {
  //       this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
  //     }
  //     else {
  //       this.toastService.openErrorSnackBar("Something Went Wrong.");
  //     }

  //   })
  // }

  // getGroupDetails(){
  //   this.partyGroupservice.getPartiGroupsDetails({}, this.currentUser.id).then((res) => {
  //     this.partyGroupData = res.data;
  //   },(err) => {
  //     if(err.error.expose){
  //       this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
  //     }
  //     else{
  //       this.toastService.openErrorSnackBar("Something Went Wrong.");
  //     }
  //   })
  // }

  // searchByGroupName() {
  //   if (this.searchGroupName.trim() === '') {
  //     this.getGroupDetails();
  //   } else {
  //     this.partyGroupData = this.partyGroupData.filter(group => group.group.groupName.toLowerCase().includes(this.searchGroupName.toLowerCase()));
  //     console.log(this.partyGroupData)
  //   }
  // }
  
}
