<div class="container-fluid mb-3 px-0 mx-0">
    <div class="row pt-2 pb-3" id="headingPrintHide">
        <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:500;font-size:20px!important;padding-left: 15px;">
            GSTR-3B
        </div>
        <div class="col-6 navOption-btns" style="display: flex; justify-content: flex-end;">
            <button class="btn me-3" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()"
                type="button"><i class="fas fa-download"></i></button>

            <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
            </button>
        </div>
    </div>
    <hr class="mt-1" />

    <div class="fs--1 bg-white">
        <div class="row mb-3">
            <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start;flex-basis:300px;" class="col-lg-3 col-sm-12 col-md-3">
                <label for="Date Range" class="form-label" id="datePrintHide">Date</label>
                <mat-form-field class="example-full-width" appearance="outline" id="datePrintHide">
                    <input matInput placeholder="Date Range" id="Date Range" type="text" [outsideClick]="true" ngModel
                        bsDaterangepicker [maxDate]="today" [(ngModel)]="dateRange" (ngModelChange)="changeDate($event)"
                        style="text-transform:uppercase!important;" [bsConfig]="{ ranges: ranges,
                                            showWeekNumbers:false,
                                            showNextMonth: false,
                                            rangeInputFormat : 'DD MMM YYYY',
                                            containerClass: 'theme-dark-blue',
                                              displayOneMonthRange: true }">
                    <span>
                        <mat-icon class="iconcalender p-1">date_range</mat-icon>
                    </span>
                </mat-form-field>
                <!-- <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label for="Date">Date</mat-label>
                      <input matInput placeholder="DD MMM YYYY" id="Date" [outsideClick]="true" ngModel bsDatepicker
                        [maxDate]="today" style="text-transform:uppercase!important;"[(ngModel)]="dateRange"  (ngModelChange)="changeDate($event)"
                        [bsConfig]="{ dateInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true }">
                      <span>
                        <mat-icon class="iconcalender">date_range</mat-icon>
                      </span>
                    </mat-form-field> -->
            </div>
            <!-- <div class="col-sm-5"></div>
            <div class="col-sm-2"></div>
            <div class="col-sm-1  mt-2 text-center" id="excelPrintHide" (click)="exportExcel()">
                <a type="button" style="margin-left: 14px;" class="p-0 text-900 bi-file-earmark-excel fa-2x "></a>
                <div class="text-nowrap text-900  fs--2  mt-1">Export to Excel</div>
            </div>
            <div class="col-sm-1 mt-2 text-center" id="PrintoptionHide" (click)="onPrint()">
                <a type="button" class=" p-0 text-900 bi-printer float-right fa-2x"></a>
                <div class="fs--2 text-900 text-nowrap mt-1">Print</div>
            </div> -->
        </div>
        <div class="form-check ms-1" id="showoptionHide">
            <input class="form-check-input" id="flexCheckIndeterminate" type="checkbox" value="" />
            <label class="form-check-label" for="flexCheckIndeterminate"><strong style="font-weight: 500; font-size: 14px;">Consider non-tax as
                    exempted</strong></label>
        </div>

        <h4 style="font-weight: 400; font-size: 14px;font-family: 'Poppins';padding-left: 4px;">1. Details of outward supplies and inward supplies liable to reverse charge</h4>

        <div class="table-responsive scrollbar">
            <!-- <table class="table table-striped overflow-hidden"> -->
            <table class="table table-striped table-bordered" id="excel-table">
                <thead style="background-color: #F0F0F0;">
                    <tr class="btn-reveal-trigger">
                        <th scope="col">Nature Of Supplies</th>
                        <th class="text-nowrap" scope="col" style="text-align: right;">Total Taxable Value</th>
                        <th class="text-nowrap" scope="col" style="text-align: right;">Integrated Tax</th>
                        <th class="text-nowrap" scope="col" style="text-align: right;">Central Tax</th>
                        <th class="text-nowrap" scope="col" style="text-align: right;">State/UT Tax</th>
                        <th scope="col" style="text-align: right;">Cess</th>

                    </tr>
                </thead>
                <tbody>
                    <tr class="btn-reveal-trigger">
                        <td>Outward taxable supplies (other than zero rated, nil rated and exempted)</td>
                        <td style="text-align: right;">₹&nbsp;{{TTaxValue | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{IntTax | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{centralTax | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{stateTax | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{tchess | number:'1.2-2'}}</td>



                    </tr>
                    <tr class="btn-reveal-trigger">
                        <td>Outward taxable supplies (zero rated)</td>
                        <td style="text-align: right;">₹&nbsp;{{TTaxValueB | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{IntTaxB | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{centralTaxB | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{stateTaxB | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{tchessB | number:'1.2-2'}}</td>

                    </tr>
                    <tr class="btn-reveal-trigger">
                        <td>Other outward supplies (nil rated, exempted)</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>



                    </tr>
                    <tr class="btn-reveal-trigger">
                        <td>Inward supplies (liable to reverse charge)</td>
                        <td style="text-align: right;">₹&nbsp;{{TTaxValueD | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{IntTaxD | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{centralTaxD | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{stateTaxD | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{tchessD | number:'1.2-2'}}</td>

                    </tr>
                    <tr class="btn-reveal-trigger">
                        <td>Non-GST outward supplies</td>
                        <td style="text-align: right;">₹&nbsp;{{TTaxValueE | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;{{IntTaxE | number:'1.2-2'}}</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;0.00</td>
                        <td style="text-align: right;">₹&nbsp;{{tchessE | number:'1.2-2'}}</td>

                    </tr>
                </tbody>
            </table>
        </div>



        <h4 style="font-weight: 400; font-size: 14px;font-family: 'Poppins';padding-left: 4px;">2. Details of inter-State supplies made to unregistered persons, composition dealer and UIN holders</h4>

        <div class="table-responsive scrollbar">
            <div>
                <table class="table table-striped table-bordered overflow-hidden">
                    <!-- <table class="table table-bordered"> -->
                    <thead >
                        <tr class="btn-reveal-trigger" style="background-color: #F0F0F0;">
                            <th scope="col">Place Of Supply(State/UT)</th>
                            <th colspan="2">Supplies Made To Unregistered persons</th>

                            <th colspan="2">Supplies Made To Composition Taxable Persons</th>
                            <th colspan="2">Supplies Made To UIN Holders</th>
                        </tr>
                        <tr class="btn-reveal-trigger">
                            <td></td>
                            <td style="text-align: right;">Total Taxable Value</td>
                            <td style="text-align: right;">Amount Of Integrated Tax</td>
                            <td style="text-align: right;">Total Taxable Value</td>
                            <td style="text-align: right;">Amount Of Integrated Tax</td>
                            <td style="text-align: right;">Total Taxable Value</td>
                            <td style="text-align: right;">Amount Of Integrated Tax</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style="text-align: right;">₹&nbsp;{{(URTTaxvalue ? URTTaxvalue : 0.00) | number:'1.2-2'}}</td>
                            <td style="text-align: right;">₹&nbsp;{{(URIntTax ? URIntTax : 0.00) | number:'1.2-2'}}</td>
                            <td style="text-align: right;">₹&nbsp;{{(RTTaxvalue ? RTTaxvalue : 0.00) | number:'1.2-2'}}</td>
                            <td style="text-align: right;">₹&nbsp;{{(RIntTax ? RIntTax : 0.00) | number:'1.2-2'}}</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                        </tr>

                    </thead>

                </table>
            </div>

            <h4 style="font-weight: 400; font-size: 14px;font-family: 'Poppins';padding-left: 4px;">3. Details of eligible Input Tax Credit</h4>

            <div class="table-responsive scrollbar">
                <table class="table table-striped table-bordered overflow-hidden">
                    <thead style="background-color: #F0F0F0;">
                        <tr class="btn-reveal-trigger">
                            <th scope="col">Details</th>
                            <th class="text-nowrap" scope="col"style="text-align: right;">Integrated Tax</th>
                            <th class="text-nowrap" scope="col"style="text-align: right;">Central Tax</th>
                            <th class="text-nowrap" scope="col"style="text-align: right;">State/UT Tax</th>
                            <th scope="col" style="text-align: right;">Cess</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr class="btn-reveal-trigger">
                            <td>(A) ITC Available (whether in full or part)</td>



                        </tr>
                        <tr class="btn-reveal-trigger">
                            <td>(1) Import of goods</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>

                        </tr>
                        <tr class="btn-reveal-trigger">
                            <td>(2) Import of services</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>(3) Inward supplies liable to reverse charge (other than 1 & 2 above)</td>
                            <td style="text-align: right;">₹&nbsp;{{ inwordSuppliesByRcmintegrated_Tax ? inwordSuppliesByRcmintegrated_Tax : 0.00 | number:'1.2-2'}}</td>
                            <td style="text-align: right;">₹&nbsp;{{ inwordSuppliesByRcmCentral_Tax ? inwordSuppliesByRcmCentral_Tax : 0.00 | number:'1.2-2'}}</td>
                            <td style="text-align: right;">₹&nbsp;{{ inwordSuppliesByRcmstate_UT_tax ? inwordSuppliesByRcmstate_UT_tax : 0.00 | number:'1.2-2'}}</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>(4) Inward supplies from ISD</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>(5) All other ITC</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>(B) ITC Reversed</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                        </tr>
                        <tr class="btn-reveal-trigger">
                            <td>(1) As per rules 42 & 43 of CGST Rules</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                        </tr>
                        <tr class="btn-reveal-trigger">
                            <td>(2) Others</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>(C) Net ITC Available (A)-(B)</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>(D) Ineligible ITC</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                        </tr>


                        <tr class="btn-reveal-trigger">
                            <td>(1) As per section 17(5)</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                        </tr>

                        <tr class="btn-reveal-trigger">
                            <td>(2) Others</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                            <td style="text-align: right;">₹&nbsp;0.00</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <h4 style="font-weight: 400; font-size: 14px;font-family: 'Poppins';padding-left: 4px;"> 4. Details of exempt, nil-rated and non-GST inward supplies</h4>

            <div class="table-responsive scrollbar">
                <table class="table table-striped table-bordered overflow-hidden">
                    <thead style="background-color: #F0F0F0;">
                        <tr class="btn-reveal-trigger">
                            <th scope="col">Nature Of Supplies</th>
                            <th scope="col">Inter-State Supplies</th>
                            <th scope="col">Intra-State Supplies</th>


                        </tr>
                    </thead>
                    <tbody>
                        <tr class="btn-reveal-trigger" *ngFor="let inter of interStateSupply;">
                            <td>From a supplier under composition scheme, Exempt and Nil rated supply</td>
                            <td style="text-align: right;">₹&nbsp;{{inter.interstate | number:'1.2-2'}}</td>
                            <td style="text-align: right;">₹&nbsp;{{inter.intrastate | number:'1.2-2'}}</td>
                            <!-- <td>0</td>
                            <td>0</td> -->

                        </tr>

                        <tr class="btn-reveal-trigger" *ngFor="let intra of intraStateSupplyNonGst;">
                            <td>Non GST supply</td>
                            <td style="text-align: right;">₹&nbsp;{{intra.interstate | number:'1.2-2'}}</td>
                            <td style="text-align: right;">₹&nbsp;{{intra.intrastate | number:'1.2-2'}}</td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
