import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { timeout } from 'rxjs/operators';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-edit-permissions',
  templateUrl: './edit-permissions.component.html',
  styleUrls: ['./edit-permissions.component.scss']
})
export class EditPermissionsComponent implements OnInit {
  currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public permissionData: any;
  public rolesForm: FormGroup;
  public permissionName: any;
  public isShowMessage: boolean = false;
  public isShowUpdateBtn: boolean = false;
  public roleId: any;
  public RoleName: any;
  public submitted: boolean;

  public sendArray: any = [];

  isAllSelected: boolean;
  constructor(private rolePermissionservice: RolesPermissionsService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private titleCasePipe: TitleCasePipe,
    private toast: ToastNotificationService,
    private toastService: ToastNotificationService,


    private router: Router) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((id) => {
      this.roleId = id.get("id");
      if (this.roleId != null) {
        this.editRolePermissions();
      }
    });
    this.rolesForm = this.fb.group({
      RoleName: ["", [Validators.required]],
      Description: ["", [Validators.required]],
      permissionsFormArray: this.fb.array([]),
    });
  }
  get formArr() {
    return this.rolesForm.get('permissionsFormArray') as FormArray;
  }
  get f() {
    return this.rolesForm.controls;
  }
  selectAllOption(index, checked: boolean) {
    const checkboxes = this.rolesForm.get("permissionsFormArray") as FormArray;
    if (checked) {
      checkboxes.controls[index].patchValue({
        read: true,
        delete: true,
        modify: true,
        create: true,
      })
    } else {
      checkboxes.controls[index].patchValue({
        read: false,
        delete: false,
        modify: false,
        create: false,
      })
    }
  }

  addDetailsButtonClick(index, id): void {
    const content = this.rolesForm.get("permissionsFormArray") as FormArray;
    content.push(this.addRolesPermission(id));
  }

  addRolesPermission(id): FormGroup {
    return new FormGroup({
      permissionName: new FormControl(),
      permissionId: new FormControl(id),
      read: new FormControl(false),
      create: new FormControl(false),
      modify: new FormControl(false),
      delete: new FormControl(false),
      all: new FormControl(),
    });
  }

  updateCustomRoles() {
    let data = {
      "roleName": this.rolesForm.controls.RoleName.value,
      "userId": this.currentUser.id,
      "description": this.rolesForm.controls.Description.value,
      // "permissionsArray": this.rolesForm.controls.permissionsFormArray.value
      "permissionsArray": this.formArr.value.map((item) => {
        return {
          permissionId: item.permissionId,
          read: item.read,
          delete: item.delete,
          create: item.create,
          modify: item.modify,
          //permissionName: item.permissionName

        }
      })
    }


    this.rolePermissionservice.updateCustomRoles(data, this.roleId).then(res => {
      if (res) {
        this.isShowMessage = true;
        setTimeout(() => {
          this.isShowMessage = false
        }, 3000);
        this.toastService.toastMsg({
          title: "Success",
          content: "Role List Updated Successfully!!!",
        });
        // this.toast.openSnackBar("Role List Updated Successfully!!!");
        this.rolesForm.reset();
        this.router.navigate(["/pages/roles-list"])
      }
    },
      (err) => {
        // this.sendArray = [];
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");        
        }
        
      })


  }

  /**
   * Row wise selecting and deselecting each crud
   * @param index 
   * @param checked 
   */
  selectCrud(index, checked: boolean) {
    if (!checked) {
      this.formArr.controls[index].patchValue({
        all: false
      })
      // this[type]=false
    } else if (checked) {
      let values = this.formArr.controls[index].value
      if (values.create && values.modify && values.delete && values.read) {
        this.formArr.controls[index].patchValue({
          all: true
        })
      }
    }
  }

  editRolePermissions() {
    this.rolePermissionservice.getRolesPermissionById({}, this.roleId)
      .then(res => {
        this.isShowUpdateBtn = true;
        this.rolesForm.get("RoleName").patchValue(res.data.role.roleName)
        this.rolesForm.get("Description").patchValue(res.data.role.description)
        for (let i = 0; i < res.data.rolepermissions.length; i++) {

          if (res.data.rolepermissions[i].role_permissions.read && res.data.rolepermissions[i].role_permissions.create
            && res.data.rolepermissions[i].role_permissions.modify && res.data.rolepermissions[i].role_permissions.delete) {
            this.formArr.push(
              new FormGroup({
                permissionName: new FormControl(res.data.rolepermissions[i].permissionName),
                permissionId: new FormControl(res.data.rolepermissions[i].id),
                read: new FormControl(res.data.rolepermissions[i].role_permissions.read),
                create: new FormControl(res.data.rolepermissions[i].role_permissions.create),
                modify: new FormControl(res.data.rolepermissions[i].role_permissions.modify),
                delete: new FormControl(res.data.rolepermissions[i].role_permissions.delete),
                all: new FormControl(true),
              })
            )
          }
          else if (res.data.rolepermissions[i].role_permissions.read || res.data.rolepermissions[i].role_permissions.create
            || res.data.rolepermissions[i].role_permissions.modify || res.data.rolepermissions[i].role_permissions.delete) {
            this.formArr.push(
              new FormGroup({
                permissionName: new FormControl(res.data.rolepermissions[i].permissionName),
                permissionId: new FormControl(res.data.rolepermissions[i].id),
                read: new FormControl(res.data.rolepermissions[i].role_permissions.read),
                create: new FormControl(res.data.rolepermissions[i].role_permissions.create),
                modify: new FormControl(res.data.rolepermissions[i].role_permissions.modify),
                delete: new FormControl(res.data.rolepermissions[i].role_permissions.delete),
                all: new FormControl(false),
              })
            )
          }
          else if (!res.data.rolepermissions[i].role_permissions.read && (!res.data.rolepermissions[i].role_permissions.create)
            && (!res.data.rolepermissions[i].role_permissions.modify) && (!res.data.rolepermissions[i].role_permissions.delete)) {
            this.formArr.push(
              new FormGroup({
                permissionName: new FormControl(res.data.rolepermissions[i].permissionName),
                permissionId: new FormControl(res.data.rolepermissions[i].id),
                read: new FormControl(res.data.rolepermissions[i].role_permissions.read),
                create: new FormControl(res.data.rolepermissions[i].role_permissions.create),
                modify: new FormControl(res.data.rolepermissions[i].role_permissions.modify),
                delete: new FormControl(res.data.rolepermissions[i].role_permissions.delete),
                all: new FormControl(false),
              })
            )
          }
        }
      })
  }

}
