import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AddOfferService } from 'src/app/core/services/add-offer.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import * as moment from 'moment';
import { Observable } from 'rxjs/internal/Observable';
import { BiddingService } from 'src/app/core/services/bidding.service';
import { map, startWith } from 'rxjs/operators';
import { ProductService } from 'src/app/core/services/product.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-offer',
  templateUrl: './edit-offer.component.html',
  styleUrls: ['./edit-offer.component.scss']
})
export class EditOfferComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public submitted: boolean;
  // public offerId: any;
  public OfferInfoForm: FormGroup;
  public showInvoiceGenerateData: boolean = false;

  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  public goodsData = [];
  public filteredOptions: Observable<string[]>
  public myControl = new FormControl();
  public searchedProduct = [];

  public uploadImage = false;
  public showdivwisedata: boolean = true;

  public searchedProduct1 = [];
  public myControl1 = new FormControl();
  public filteredOptions1: Observable<string[]>
  public goodsData1 = [];

  // public todaysDate:any;
  // public maxDate: Date;
  //public today: Date;
  // public todaysDate2: any;
  public discountType;
  public offerType;
  public options;
  public selectType;
  public activeColor: string = 'green';
  public baseColor: string = '#ccc';
  public overlayColor: string = 'rgba(255,255,255,0.5)';
  public dragging: boolean = false;
  public loaded: boolean = false;
  public imageLoaded: boolean = false;
  public imageSrcSeven: any;
  public OfferId: any;
  public updateofferPicUrl: any = '';
  public coverImageChange: boolean = false;
  public today1: Date;

  toatlPages: any;
  totalrow: any;
  p: any;
  public byPid: any = '';
  public byp: any;
  activeinactiveValue: any;

  constructor(private _formBuilder: FormBuilder, private commonService: CommonService,
    public dialogRef: MatDialogRef<EditOfferComponent>,
    @Inject(MAT_DIALOG_DATA) public offerData: any,
    private addofferService: AddOfferService,
    private productService: ProductService,
    private biddingService: BiddingService,
    private titleCasePipe: TitleCasePipe,
    private toastService: ToastNotificationService, private router: Router, private route: ActivatedRoute,) {
    this.today1 = new Date(new Date().setDate(new Date().getDate() + 1));
    //this.today = new Date();
    //this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
  }

  async ngOnInit() {

    try {
      this.OfferInfoForm = this._formBuilder.group({
        offerName: ['', Validators.required],
        description: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(150)]],
        offerStatus: ['', Validators.required],
        validFrom: ['', Validators.required],
        validTo: ['', Validators.required],
        ruleName: ['', Validators.required],
        offerType: ['', Validators.required],
        buyProduct: [''],
        barcode: [''],
        quantity: [''],
        barcode1: [''],
        quantity1: [''],
        discountType: [null],
        minAmount: [null],
        freeProduct: [''],
        selectDiscount: [null],
        discount: [null],
        options: [''],
      });
     await this.getGoodsbyUser();
      this.getGoodsbyUser1();

      this.OfferId = this.offerData.OfferId;

      // this.route.paramMap.subscribe((p) => {
      //   this.OfferId = p.get("id");
      // });
      this.getOfferById();

    }
    catch (e) {
      console.log("Enter catch")
    }


  }

  async getGoodsbyUser() {
    console.log("getGoodsbyUser")
    await this.productService.getGoodsProductoffer({}, this.currentUser.id, "GOODS").then((res) => {

      this.goodsData = res.data;
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(name => name ? this.filterNames(name) : this.goodsData.slice())
        );
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })
  }

  getGoodsbyUser1() {
    this.productService.getGoodsProductoffer({}, this.currentUser.id, "GOODS").then((res) => {

      this.goodsData1 = res.data;
      this.filteredOptions1 = this.myControl1.valueChanges
        .pipe(
          startWith(''),
          map(name => name ? this.filterNames(name) : this.goodsData1.slice())
        );
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })
  }

  private filterNames(name: string): string[] {
    return this.goodsData.filter(goods =>
      goods.itemName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }


  searchProduct(value) {
    this.myControl.patchValue(value);
    console.log(" this.myControl.value2", this.myControl.value, value)
    if (value && (String(value).trim() != '' || value != null)) {
      this.biddingService.getAllProductsByUserSearch({}, this.currentUser.id, this.myControl.value, this.partyPageSize, (this.currentPageNo + 1))
      .then((res) => {
        this.toatlPages = res.data.totalPages;
        this.totalrow = res.data.totalRows;
        this.p = res.data.currentPage;
        this.searchedProduct = res.data.pageData;
        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filterNames(name) : this.searchedProduct.slice())
          );
      },
        (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
            // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
            // this.toastService.openErrorSnackBar("Something Went Wrong.");
          }
        })
    }
    else{
      this.byPid = null;
      //this.barcodeProduct=res.data.pageData[0].barcode;
      this.OfferInfoForm.patchValue({
        barcode: null,
      })

    }

  }
  selectedProduct(itemName,event) {
    if (event.isUserInput) {
      this.biddingService.getAllProductsByUserSearch({}, this.currentUser.id, itemName, this.partyPageSize, (this.currentPageNo + 1))
      .then((res) => {
        this.toatlPages = res.data.totalPages;
        this.totalrow = res.data.totalRows;
        this.p = res.data.currentPage;
        this.byPid = res.data.pageData[0].id;
        this.searchedProduct = res.data.pageData;
        //this.barcodeProduct=res.data.pageData[0].barcode;
        this.OfferInfoForm.patchValue({
          barcode: res.data.pageData[0].barcode,

        })
      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })
    }

  }


  searchProduct1(value) {
    if (value != '' || value != null) {
       this.biddingService.getAllProductsByUserSearch({}, this.currentUser.id, this.myControl1.value, this.partyPageSize, (this.currentPageNo + 1))
      .then((res) => {
        this.toatlPages = res.data.totalPages;
        this.totalrow = res.data.totalRows;
        this.p = res.data.currentPage;
        this.searchedProduct1 = res.data.pageData;
        this.filteredOptions1 = this.myControl1.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filterNames(name) : this.searchedProduct1.slice())
          );
      },
        (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
            // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
            // this.toastService.openErrorSnackBar("Something Went Wrong.");
          }
        })
    }
    else {
      this.OfferInfoForm.patchValue({
        barcode1: null,

      })
    }

  }
  selectedProduct1(itemName,event) {
    if (event.isUserInput) {
      this.biddingService.getAllProductsByUserSearch({}, this.currentUser.id, itemName, this.partyPageSize, (this.currentPageNo + 1))
      .then((res) => {
        this.toatlPages = res.data.totalPages;
        this.totalrow = res.data.totalRows;
        this.p = res.data.currentPage;
        this.searchedProduct1 = res.data.pageData;
        //this.barcodeProduct=res.data.pageData[0].barcode;
        this.OfferInfoForm.patchValue({
          barcode1: res.data.pageData[0].barcode,

        })
      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })
    }

  }

  get f() {
    return this.OfferInfoForm.controls;
  }

  getOfferById() {
    console.log("getOfferById")
    this.addofferService.getAllOfferListById({}, this.OfferId).then((res) => {
      //console.log("res",res.data)
      this.imageSrcSeven = res.data.offerImage;
      this.byp = res.data.productId ? res.data.productId : null,
        this.OfferInfoForm.patchValue({
          offerName: res.data.offerName,
          description: res.data.description,
          offerStatus: res.data.status === true ? 'true' : 'false',
          validFrom: this.today1,
          validTo: moment(res.data.toDate).format("DD-MMM-YYYY"),
          ruleName: res.data.ruleName ? res.data.ruleName : null,
          offerType: res.data.offerType ? res.data.offerType : null,
          barcode: res.data.barcode ? res.data.barcode : null,
          quantity: res.data.quantity ? res.data.quantity : null,
          discountType: res.data.offerDiscountType ? res.data.offerDiscountType : null,
          minAmount: res.data.minimunAmount ? res.data.minimunAmount : null,
          freeProduct: res.data.freeProduct ? res.data.freeProduct : null,
          selectDiscount: res.data.selectDiscount ? res.data.selectDiscount : null,
          discount: res.data.discount ? res.data.discount : null,
          quantity1: res.data.freeproductQuantity ? res.data.freeproductQuantity : null
          //buyProduct: res.data.productId ? res.data.productId : null,



        });
        let p= res.data.freeProduct
        console.log("freePorduct Name",p)


      console.log("this.byp", this.byp)
      console.log("this.goodsData", this.goodsData)

      for (var i = 0; i < this.goodsData.length; i++) {
        // Do something with this.goodsData[i]
        if (this.byp === this.goodsData[i].id) {
          console.log(i)
          this.OfferInfoForm.patchValue({
            "buyProduct": this.goodsData[i].itemName
          }
          )
          this.byPid = this.byp;
        }
        if(p == this.goodsData[i].itemName){
          this.OfferInfoForm.patchValue({
            "barcode1": this.goodsData[i].barcode
          })
        }
      }

    })


  }
  getAllOffersType() {
    this.submitted = true;
    console.log("this.OfferInfoForm", this.OfferInfoForm)
    if (this.OfferInfoForm.invalid) {
      return false;
    }
    else {
      let data = {
        "updateOffer": {
          "userId": this.currentUser.id,
          "offerName": this.OfferInfoForm.controls.offerName.value,
          "status": this.OfferInfoForm.controls.offerStatus.value,
          "fromDate": moment(this.OfferInfoForm.controls.validFrom.value, 'DD-MMM-YYYY').format('YYYY-MM-DD'),
          "toDate": moment(this.OfferInfoForm.controls.validTo.value, 'DD-MMM-YYYY').format('YYYY-MM-DD'),
          "description": this.OfferInfoForm.controls.description.value,
          "ruleName": this.OfferInfoForm.controls.ruleName.value,
          "offerType": this.OfferInfoForm.controls.offerType.value,
          "offerDiscountType": this.OfferInfoForm.controls.discountType.value,
          "selectDiscount": this.OfferInfoForm.controls.selectDiscount.value ? this.OfferInfoForm.controls.selectDiscount.value : null,
          "minimunAmount": this.OfferInfoForm.controls.minAmount.value ? this.OfferInfoForm.controls.minAmount.value : null,
          "freeProduct": this.OfferInfoForm.controls.freeProduct.value ? this.OfferInfoForm.controls.freeProduct.value : null,
          "discount": this.OfferInfoForm.controls.discount.value ? this.OfferInfoForm.controls.discount.value : null,
          "barcode": this.OfferInfoForm.controls.barcode.value ? this.OfferInfoForm.controls.barcode.value : null,
          "quantity": this.OfferInfoForm.controls.quantity.value ? this.OfferInfoForm.controls.quantity.value : null,
          "buyProductId": this.byPid ? this.byPid : null,
          "freeproductQuantity":this.OfferInfoForm.controls.quantity1.value ? this.OfferInfoForm.controls.quantity1.value : null,
          //"buyProductId": this.OfferInfoForm.controls.buyProduct.value ? this.OfferInfoForm.controls.buyProduct.value : null,
          "offerImage": this.updateofferPicUrl
        }
      }

      let noDataImage2 = {
        "updateOffer": {
          "userId": this.currentUser.id,
          "offerName": this.OfferInfoForm.controls.offerName.value,
          "status": this.OfferInfoForm.controls.offerStatus.value,
          "fromDate": moment(this.OfferInfoForm.controls.validFrom.value, 'DD-MMM-YYYY').format('YYYY-MM-DD'),
          "toDate": moment(this.OfferInfoForm.controls.validTo.value, 'DD-MMM-YYYY').format('YYYY-MM-DD'),
          "description": this.OfferInfoForm.controls.description.value,
          "ruleName": this.OfferInfoForm.controls.ruleName.value,
          "offerType": this.OfferInfoForm.controls.offerType.value,
          "offerDiscountType": this.OfferInfoForm.controls.discountType.value,
          "selectDiscount": this.OfferInfoForm.controls.selectDiscount.value ? this.OfferInfoForm.controls.selectDiscount.value : null,
          "minimunAmount": this.OfferInfoForm.controls.minAmount.value ? this.OfferInfoForm.controls.minAmount.value : null,
          "freeProduct": this.OfferInfoForm.controls.freeProduct.value ? this.OfferInfoForm.controls.freeProduct.value : null,
          "discount": this.OfferInfoForm.controls.discount.value ? this.OfferInfoForm.controls.discount.value : null,
          "barcode": this.OfferInfoForm.controls.barcode.value ? this.OfferInfoForm.controls.barcode.value : null,
          "quantity": this.OfferInfoForm.controls.quantity.value ? this.OfferInfoForm.controls.quantity.value : null,
          "buyProductId": this.byPid ? this.byPid : null,
          "freeproductQuantity":this.OfferInfoForm.controls.quantity1.value ? this.OfferInfoForm.controls.quantity1.value : null,
          //"buyProductId": this.OfferInfoForm.controls.buyProduct.value ? this.OfferInfoForm.controls.buyProduct.value : null,
          //"offerImage":this.updateofferPicUrl
        }
      }

      if (this.coverImageChange == true) {
        this.addofferService.putAddOfferDetails(data, this.OfferId).then((res) => {
          if (res) {
            this.toastService.toastMsg({
              title: "Success",
              content: "Offer Updated Successfully!!!",
            });
            // this.toastService.openSnackBar("Offer Updated Successfully!!!")
            this.submitted = false;
            this.router.navigate(["/pages/new-product/offer-list"]);
            this.addofferService.notifyofferAdded();
            this.dialogRef.close();

          }
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
            // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
            // this.toastService.openErrorSnackBar("Something Went Wrong.");
          }
        });


      }
      else {
        this.addofferService.putAddOfferDetails(noDataImage2, this.OfferId).then((res) => {
          if (res) {
            this.toastService.toastMsg({
              title: "Success",
              content: "Offer Updated Successfully!!!",
            });
            // this.toastService.openSnackBar("Offer Updated Successfully!!!")
            this.submitted = false;
            this.router.navigate(["/pages/new-product/offer-list"]);
            this.addofferService.notifyofferAdded();
            this.dialogRef.close();

          }
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
            // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
            // this.toastService.openErrorSnackBar("Something Went Wrong.");
          }
        });


      }
    }



  }

  oncancel() {
    this.dialogRef.close();
  }
  getCredit(e) {
    console.log("e", e.source.value);
    this.activeinactiveValue = e.source.value;
    //console.log("activeinactiveValue", this.activeinactiveValue);

  }
  postOfferImage() {
    let data = {
      "location": this.OfferInfoForm.controls.offerName.value,
    }
  }

  cancelCoverImageCover() {
    //delete for cover pic
    this.addofferService.deleteproductofferCoverImgId({}, this.OfferId).then((res) => {
      console.log(res);
      this.toastService.toastMsg({
        title: "Success",
        content: "Cover Image Deleted Successfully!!!",
      });
      // this.toastService.openSnackBar("Cover Image Deleted  Successfully!!!");
      this.imageSrcSeven = "";
      this.imageLoaded = false;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        //this.getGoodsById()
        this.getGoodsbyUser();
        this.getGoodsbyUser1();
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went To Wrong",
        });
        // this.toastService.openErrorSnackBar("Something Went To Wrong");
      }
    });
  }

  startUpload() {
    document.getElementById('uploadCoverPic').click();
  }
  getOfferType(event) {
    this.offerType = event.source.value;
  }
  getDiscountType(event) {
    this.discountType = event.source.value;
  }
  selectDiscount(event) {
    this.selectType = event.source.value;
  }
  handleCoverDragEnter() {
    this.dragging = true;
  }
  handleCoverDragLeave() {
    this.dragging = false;
  }
  handleCoverImageLoad() {
    this.imageLoaded = true;
  }
  handleCoverDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.coverImgChange(e);
  }
  coverImgChange(e) {
    this.coverImageChange = true;
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];


    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);

      reader.onload = () => {


        if (file == null) {
          // this.toastService.openErrorSnackBar(
          //   "Please Select Image To Upload.",
          // );
          this.toastService.toastMsg({
            title: "Error",
            content: "Please Select Image To Upload.",
          });
        } else {

          this.imageSrcSeven = reader.result;

          formdata.set("upload", file);
          e.srcElement.value = "";
          this.addofferService.postAddOfferImage(formdata).then((res: any) => {
            console.log("image offer", res);
            this.updateofferPicUrl = res.data.location


          }, (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
              // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
              // this.toastService.openErrorSnackBar("Something Went Wrong.");
            }
          });

        }
      }
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  openDatepicker() {
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.OfferInfoForm.get('validFrom').value;
    if (isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.OfferInfoForm.get('validFrom').setValue(new Date());
    }
  }
  openDatepickerValidto(){
     // Check if the current value of taskdate is an "Invalid Date"
     const selectedDate2 = this.OfferInfoForm.get('validTo').value;
     if (isNaN(selectedDate2.getTime())) {
       // Set a default date or any valid date here
       this.OfferInfoForm.get('validTo').setValue(new Date());
     }

  }

}
