import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-warehouse-profile',
  templateUrl: './warehouse-profile.component.html',
  styleUrls: ['./warehouse-profile.component.scss']
})
export class WarehouseProfileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
