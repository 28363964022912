import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-item-serial-report',
  templateUrl: './item-serial-report.component.html',
  styleUrls: ['./item-serial-report.component.scss']
})
export class ItemSerialReportComponent implements OnInit {
  printComponent(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
    
}

  constructor() { }

  ngOnInit(): void {
  }

}
