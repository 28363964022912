<div class="container-fluid mb-3 mx-0 bg-white">
    <div class="row pt-3 pb-3 mt-" id="headingPrintHide">
        <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:600;font-size:20px!important;">
            Item Report By Vendor
        </div>
        <div class="col-6 navOption-btns" style ="display: flex; justify-content: flex-end;">
          <button class="btn me-3" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()" type="button"><i class="fas fa-download"></i></button>
    
          <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
          </button>
        </div>
    </div>
    <hr class="mt-1" />

    <div class="fs--1 ">
        <!-- <div class="row mx-auto"> -->
        <div class="row mx-auto">
            <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;" class="col-xs-12 col-sm-12 col-md-4 col-lg-3" id = "dateRangePrintHide">
                <label class="form-label">Date</label>
                <mat-form-field class="example-full-width" appearance="outline">     
                    <input class="uppercase-date-range" matInput placeholder="Date Range" id="Date Range" type="text" [outsideClick]="true" ngModel
                        bsDaterangepicker [maxDate]="today" [(ngModel)]="dateRange" (ngModelChange)="changeDate($event)"
                        [bsConfig]="{ranges: ranges,showWeekNumbers:false, showNextMonth: false, rangeInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true }">
                    <span><mat-icon class="iconcalender p-1">date_range</mat-icon></span>
                </mat-form-field>
            </div>

            <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 mr-0" id = "searchBarHide">
                <label class="form-label">Select Vendor Name</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" placeholder="Vendor Name" aria-label="FILTERS" matInput
                    (keyup)="searchPartyByName($event.target.value)"[matAutocomplete]="auto" [ngClass]="{
                            'is-invalid': (partyName.invalid && partyName.touched) && submitted }" required>
                    <mat-icon class="m-2" matSuffix>search</mat-icon>
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
                        (onSelectionChange)="setSelectedPartyDeatails(option)">
                            {{option.partyName}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <!-- <div class="col-sm-4 mt-1"></div>
            <div class="col-sm-1  mt-2 text-center" id = "excelPrintHide" (click)="exportExcel()">
                <a type="button" style="margin-left: 14px;" class="p-0 text-900 bi-file-earmark-excel fa-2x "></a>
                <div class="text-nowrap text-900  fs--2  mt-1">Export to Excel</div>

            </div>
            <div class="col-sm-1 mt-2 text-center" id = "PrintoptionHide" (click)="onPrint()">
                <a type="button" class=" p-0 text-900 bi-printer float-right fa-2x"
                    (click)="printComponent('component1')"></a>
                <div class="fs--2 text-900 text-nowrap mt-1">Print</div>
            </div> -->

            <div class="table-responsive scrollbar mb-3">
                <table class="table table-bordered table-striped fs--1 mb-0" id="excel-table">
                    <thead class="" style="background-color: #F0F0F0;">
                        <tr class="text-900">
                            <th class="nowrap text-center" scope="col">Item Name</th>
                            <th class="nowrap text-center" scope="col">Sales Quantity</th>
                            <th class="nowrap text-center" scope="col">Sales Amount</th>
                            <th class="nowrap text-center" scope="col">Purchase Quantity</th>
                            <th class="nowrap text-center" scope="col">Purchase Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="btn-reveal-trigger text-left" *ngFor="let item of itemReportList;let i = index">
                            <!-- <td>{{ i + 1}}</td> -->
                            <td class="text-center">{{item.itemName}}</td>
                            <td class="text-center">{{item.saleQuantity}}</td>
                            <td class="text-center">{{item.saleAmount}}</td>
                            <td class="text-center">{{item.purchaseQuantity}}</td>
                            <td class="text-center">{{item.purchaseAmount}}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="itemReportList == 0">
                        <tr class="text-center">
                            <td colspan="10">
                                <img class="image-responsive" src="assets/img/icons/spot-illustrations/GST No Transaction.png" width="124" height="142" alt="">
                                    <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Data Found.</h5>
                                <!-- <h5 class="text-nowrap">No Data Found.</h5> -->
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="nowrap" scope="col"><strong class= "text-dark">Total</strong></td>
                            <td class="nowrap" scope="col"><strong><a class="text-success">{{itempartyTotal.saleQuantityTotal}} </a></strong></td>
                            <td class="nowrap" scope="col"><strong>₹ <a class="text-success">{{itempartyTotal.saleAmountTotal}} </a></strong></td>
                            <td class="nowrap" scope="col"><strong><a class="text-success">{{itempartyTotal.purchaseQuantityTotal}} </a></strong></td>
                            <td class="nowrap" scope="col"><strong>₹ <a class="text-success">{{itempartyTotal.purchaseAmountTotal}} </a></strong></td>
                        </tr>
                    </tfoot>
                </table>
                <!-- <mat-paginator class="float-right mt-2" *ngIf="itemReportList.length> 0" [pageSize]="partyPageSize"
                    [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo"
                    (page)="handlePage($event)" aria-label="Select page">
                </mat-paginator> -->
            </div>
        </div>
    </div>
    <!-- <div class="card-footer mt-3">
        <div class="" id="accordionPanelsStayOpenExample" style="height: 10px;">
            <div class="accordion-item position-absolute bottom-0 start-0" style=" width: 100%;">
                <div class="accordion-body">
                    <div class="row sm p-0" style="height: px;">
                        <div class="col-sm-2 p-2">
                            <strong class=" text-dark p-2" style="margin-top: 20px; margin-left: 15px;">
                                Total
                            </strong>
                        </div>
                        <div class="col-sm-2 p-2">
                            <strong class=" text-dark p-2" style="margin-top: 10px; margin-left: 80px;">
                                <a class="text-success">{{itempartyTotal.saleQuantityTotal}} </a></strong>
                        </div>
                        <div class="col-sm-2 p-2">
                            <strong class=" text-dark p-2" style="margin-top: 20px; margin-left: 70px;">
                                ₹ <a class="text-success">{{itempartyTotal.saleAmountTotal}} </a></strong>
                        </div>
                        <div class="col-sm-3 p-2">
                            <strong class=" text-dark p-2" style="margin-top: 20px; margin-left: 120px;">
                                 <a class="text-success">{{itempartyTotal.purchaseQuantityTotal}} </a></strong>
                        </div>
                        <div class="col-sm-3 p-2">
                            <strong class=" text-dark p-2" style="margin-top: 20px; margin-left: 80px;">
                                ₹  <a class="text-success">{{itempartyTotal.purchaseAmountTotal}} </a></strong>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>  -->
</div>