import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';
import { Chart, registerables } from "chart.js";
import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import noData from 'highcharts/modules/no-data-to-display';

import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD MMM YYYY',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    // monthYearLabel: 'MMM YYYY',
    // dateA11yLabel: 'YYYY-MM-DD HH:mm:ss',
    // monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-total-purchase',
  templateUrl: './total-purchase.component.html',
  styleUrls: ['./total-purchase.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },

  ]
})
export class TotalPurchaseComponent implements OnInit {

  // @ViewChild("purchaseChart") purchaseChart;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public userId: string = "";
  public showProfile: boolean;
  public todaysDate = this.commonService.getTodaysDate();
  public totalPurchase: any;
  public permissionData: any;
  public purchaseChart: any = [];
  public puchaseMonthChart: any = []
  public puchaseYearChart: any = []
  public weekNames: any;
  public weekDates: any;
  public weekTotalPurchaseAmt: any[] = [];
  public pastWeekTotalPurchaseAmt: any[] = [];
  public weekNamesSales: any;
  public weekPurchaseDateValue: Date;
  public weekSalesDateValue: Date;
  public monthSalesDateValue: Date;
  public monthPurchaseDateValue: Date;
  public yearSalesDateValue: Date;
  public yearPurchaseDateValue: Date;
  public totalBillAmount: any;
  public monthDay: any;
  public purchaseMonthDay: any[];
  public pastpurchaseMonthDay: any[];
  public purchaseMonthTotalBillAmount: any[];
  public pastpurchaseMonthTotalBillAmount: any[];
  public yearlySalesDate: any;
  public yearlySalestotalBillAmount: any;
  public pastyearlyPurchaseDate: any;
  public yearlyPurchaseDate: any;
  public pastyearlyPurchasetotalBillAmount: any;
  public yearlyPurchasetotalBillAmount: any;
  public isWeek: boolean = true;
  public isMonth: boolean = false;
  public isYear: boolean = false;
  public purchaseType: string = 'Weekly';
  public maxDate = new Date();
  public newSaleDate;
  public newPurchaseDate;
  public defaultPurchaseSelect = "Weekly";
  public defaultSaleSelect = "Weekly";

  constructor(public rolePermission: RolesPermissionsService,
    public authorizationService: AuthorizationService,
    public dashboardService: DashboardService,
    public commonService: CommonService,
    public router: Router) {
    Chart.register(...registerables)
  }

  ngOnInit(): void {
    this.weekPurchaseDateValue = new Date();
    this.monthPurchaseDateValue = new Date();
    this.yearPurchaseDateValue = new Date();

    this.getDayWisePurchaseInvoiceReport();
    this.getTotalPurchaseWeekReport()
  }

  getPurchaseReportType(type) {
    this.purchaseType = type
    if (type == 'Weekly') {
      this.isWeek = true;
      this.isMonth = false;
      this.isYear = false;
      this.getTotalPurchaseWeekReport()
    } else if (type == 'Monthly') {
      this.isWeek = false;
      this.isMonth = true;
      this.isYear = false;
      this.getTotalPurchaseMonthReport();
    } else if (type == 'Yearly') {
      this.isWeek = false;
      this.isMonth = false;
      this.isYear = true;
      this.getTotalPurchaseYearReport();
    }
  }

  /**
   * DayWise Purchase Invoice Report
   */
  getDayWisePurchaseInvoiceReport() {
    this.dashboardService.getDayWiseInvoiceReport({}, this.currentUser.id, this.todaysDate, "PURCHASE").then((res) => {
      if(res.data){
        this.totalPurchase = res.data.dataset[0];
      }
    })
  }

  /**
   * Total Purchase Week Report
   */
  getTotalPurchaseWeekReport() {
    if (this.currentUser.id) {
      if (this.newPurchaseDate) {
        this.todaysDate = this.newPurchaseDate;
      }
      const today = new Date(this.todaysDate);
      const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
      const lastWeekStartDate = `${lastWeek.getFullYear()}-${lastWeek.getMonth() + 1}-${lastWeek.getDate()}`;
      this.dashboardService.getTotalInvoiceWeekReport({}, this.currentUser.id, this.todaysDate, "PURCHASE").then(async (res) => {
        this.weekNames = res.data.dataset.map((names: any) => names.dayName);
        this.weekDates = res.data.dataset.map((dates: any) => dates.date);
        this.weekTotalPurchaseAmt = (res.data.dataset).map((total: any) => total.totalBillAmount);
        // this.plotWeeklyChart()

        this.dashboardService.getTotalInvoiceWeekReport({}, this.currentUser.id, lastWeekStartDate, "PURCHASE").then(async (res) => {
          this.weekNames = res.data.dataset.map((names: any) => names.dayName);
          this.weekNames = res.data.dataset.map((names: any) => {
            const dayName = names.dayName;
            const abbreviatedDayName = dayName.substring(0, 3);
            return abbreviatedDayName;
          });
          this.weekDates = res.data.dataset.map((dates: any) => dates.date);
          this.pastWeekTotalPurchaseAmt = res.data.dataset.map((total: any) => total.totalBillAmount);
          this.plotWeeklyChart()
        })
      })
    } else {
      this.router.navigate(["/auth"]);
    }
  }

  plotWeeklyChart() {
    this.purchaseChart = Highcharts;
    // noData(Highcharts);
    const isPastWeekDataEmpty = this.pastWeekTotalPurchaseAmt.every(value => value === 0);
    const isCurrentWeekDataEmpty = this.weekTotalPurchaseAmt.every(value => value === 0);
    if (isPastWeekDataEmpty && isCurrentWeekDataEmpty) {
      // Display a "No data found" message
      const chartOptions = {
        chart: {
          type: 'spline',
          events: {
            load: function() {
              const chart = this;
              const centerX = chart.plotWidth / 2;
              const centerY = chart.plotHeight / 2;

              // Add the image element
              chart.noDataImage = chart.renderer
                .image('../../../assets/img/icons/spot-illustrations/notfound1.png', centerX - 90, centerY - 140, 200, 150)
                .add();
            }
          }
        },
        title: {
          // text: this.purchaseType + ' Purchase Report',
          text: ' ',
            style: {
              fontFamily: "'Poppins', sans-serif",
              fontWeight: '500',
              fontSize: '16px',
              color: '#737791'
            }
        },
        xAxis: {
          categories: this.weekNames
        },
        credits: {
          enabled: false
        },
      };
      this.purchaseChart.chart('purchaseCanvas', chartOptions)
      document.getElementById('purchaseCanvas').style.height = '300px';

    } else {
        const chartOptions = {
          chart: {
            type: 'spline',
          },
          credits:{
            enabled: false
         },
          title: {
            // text: this.purchaseType + ' Purchase Report',
            text: ' ',
            style: {
              fontFamily: "'Poppins', sans-serif",
              fontWeight: '500',
              fontSize: '16px',
              color: '#737791'
            }
          },
          xAxis: [{
            categories: this.weekNames
          }
        ],
          yAxis: {
            title: {
              text: 'Purchase Amount'
            },
            gridLineWidth: 0
          },
          plotOptions: {
            series: {
                animation: {
                    duration: 3000
                },
                cursor: 'pointer',

            }
          },
          series: [
            {
              name: 'Past Week',
              data: this.pastWeekTotalPurchaseAmt,
              color: '#FC7643',
            },
            {
              name: 'Current Week',
              data: this.weekTotalPurchaseAmt,
              color: '#110152',
            }
          ]
        };
        this.purchaseChart.chart('purchaseCanvas', chartOptions)

        // Add CSS to adjust the size visually
        // document.getElementById('salesCanvas').style.width = '300px'; // Set your desired width
        document.getElementById('purchaseCanvas').style.height = '300px';
      }
  }

  // getUpdatedPurchaseWeekReport(e) {
  //   this.purchaseChart.destroy();
  //   this.newPurchaseDate = moment(e).format('YYYY-MM-DD')
  //   this.dashboardService.getTotalInvoiceWeekReport({}, this.currentUser.id, moment(e).format('YYYY-MM-DD'), "PURCHASE").then((res) => {
  //     this.weekNames = res.data.dataset.map((names: any) => names.dayName);
  //     this.weekDates = res.data.dataset.map((dates: any) => dates.date);
  //     this.weekTotalPurchaseAmt = res.data.dataset.map((total: any) => total.totalBillAmount);

  //     this.purchaseChart = Highcharts;
  //     const chartOptions =  {
  //       chart: {
  //         type: 'spline'
  //       },
  //       credits:{
  //         enabled: false
  //      },
  //       title: {
  //         text: 'Total Purchase'
  //       },
  //       xAxis: {
  //         categories: this.weekNames,
  //         title: {
  //           text: 'Days of Week'
  //         }
  //       },
  //       yAxis: {
  //         title: {
  //           text: 'Purchase Amount'
  //         },
  //         min: 0,
  //         gridLineWidth: 0
  //       },
  //       plotOptions: {
  //         series: {
  //             animation: {
  //                 duration: 3000
  //             },
  //             cursor: 'pointer',
  //             dragDrop: {
  //               draggableY: true,
  //               dragCursor: 'ns-resize', // cursor style during drag
  //               dragStart: function (e) {
  //                 console.log('Drag started on series:', e.target.name);
  //               },
  //               dragEnd: function (e) {
  //                 console.log('Drag ended on series:', e.target.name);
  //               }
  //             }
  //         }
  //       },
  //       series: [{
  //         name: this.purchaseType + ' Purchase Report',
  //         data: this.weekTotalPurchaseAmt,
  //         fillColor: 'rgba(93,175,89,0.1)',
  //         color: '#FC7643',
  //       }]
  //     };
  //     this.purchaseChart.chart('purchaseCanvas', chartOptions);
  //   });
  // }

  /**
   * Total Purchase Month Report
   */
  getTotalPurchaseMonthReport() {
    // this.puchaseChart.destroy();
    if (this.newPurchaseDate) {
      this.todaysDate = this.newPurchaseDate;
    }
    const today = new Date(this.todaysDate);
    const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    const oneMonthAgoDateString = `${oneMonthAgo.getFullYear()}-${oneMonthAgo.getMonth() + 1}-${oneMonthAgo.getDate()}`;
    // this API call returns current month data
    this.dashboardService.getTotalInvoiceMonthReport({}, this.currentUser.id, this.todaysDate, "PURCHASE").then((res) => {
      this.purchaseMonthDay = res.data.dataset.map((names: any) => names.day.split('-')[2]);
      this.purchaseMonthTotalBillAmount = res.data.dataset.map((total: any) => total.totalBillAmount);
      // this.plotMonthChart()
      // this API call returns past month data
      this.dashboardService.getTotalInvoiceMonthReport({}, this.currentUser.id, oneMonthAgoDateString, "PURCHASE").then((res) => {
        this.pastpurchaseMonthDay = res.data.dataset.map((names: any) => names.day);
        this.pastpurchaseMonthTotalBillAmount = res.data.dataset.map((total: any) => total.totalBillAmount);
        this.plotMonthChart()
      });
    });
  }

  plotMonthChart(){
    this.purchaseChart = Highcharts;
    const isPastWeekDataEmpty = this.pastpurchaseMonthTotalBillAmount.every(value => value === 0);
    const isCurrentWeekDataEmpty = this.purchaseMonthTotalBillAmount.every(value => value === 0);
    if (isPastWeekDataEmpty && isCurrentWeekDataEmpty) {
      // Display a "No data found" message
      const chartOptions = {
        chart: {
          type: 'spline',
          events: {
            load: function() {
              const chart = this;
              const centerX = chart.plotWidth / 2;
              const centerY = chart.plotHeight / 2;

              // Add the image element
              chart.noDataImage = chart.renderer
                .image('../../../assets/img/icons/spot-illustrations/notfound1.png', centerX - 90, centerY - 140, 200, 150)
                .add();
            }
          }
        },
        title: {
          // text: this.purchaseType + ' Purchase Report',
          text: ' ',
            style: {
              fontFamily: "'Poppins', sans-serif",
              fontWeight: '500',
              fontSize: '16px',
              color: '#737791'
            }
        },
        xAxis: {
          categories: this.weekNames
        },
        credits: {
          enabled: false
        },
      };
      this.purchaseChart.chart('purchaseCanvas', chartOptions);
      document.getElementById('purchaseCanvas').style.height = '300px';

      } else {
        const chartOptions = {
          chart: {
            type: 'spline',
          },
          credits:{
            enabled: false
         },
          title: {
            // text: this.purchaseType + ' Purchase Report',
            text: ' ',
            style: {
              fontFamily: "'Poppins', sans-serif",
              fontWeight: '500',
              fontSize: '16px',
              color: '#737791'
            }
          },
          xAxis: [{
            categories: this.purchaseMonthDay
          }
        ],
          yAxis: {
            title: {
              text: 'Purchase Amount'
            },
            gridLineWidth: 0
          },
          plotOptions: {
            series: {
              animation: {
                  duration: 3000
              },
              cursor: 'pointer',
              draggableX: true, // Enable draggable points on the x-axis
              draggableY: true // Enable draggable points on the y-axis
            }
          },
          series: [
            {
              name: 'Past Month',
              // data: [900,290,350,261,754],
              data: this.pastpurchaseMonthTotalBillAmount,
              color: '#FC7643',
            },
            {
              name: 'Current Month',
              // data: [300,200,550,161,554],
              data: this.purchaseMonthTotalBillAmount,
              color: '#110152',
            }
          ]
        };
        this.purchaseChart.chart('purchaseCanvas', chartOptions)
        document.getElementById('purchaseCanvas').style.height = '300px';

      }
  }

  // getUpdatedPurchaseMonthReport(e) {
  //   // this.puchaseChart.destroy();
  //   this.newPurchaseDate = moment(e).format('YYYY-MM-DD')
  //   this.dashboardService.getTotalInvoiceMonthReport({}, this.currentUser.id, moment(e).format('YYYY-MM-DD'), "PURCHASE").then((res) => {
  //     this.purchaseMonthDay = res.data.dataset.map((names: any) => names.day);
  //     this.purchaseMonthTotalBillAmount = res.data.dataset.map((total: any) => total.totalBillAmount);
  //     this.purchaseChart = Highcharts;
  //     const chartOptions =  {
  //       chart: {
  //         type: 'spline'
  //       },
  //       credits:{
  //         enabled: false
  //      },
  //       title: {
  //         text: 'Total Monthly Purchase'
  //       },
  //       xAxis: {
  //         categories: this.purchaseMonthDay,
  //         title: {
  //           text: 'Days of Month'
  //         }
  //       },
  //       yAxis: {
  //         title: {
  //           text: 'Purchase Amount'
  //         },
  //         min: 0,
  //         gridLineWidth: 0
  //       },
  //       plotOptions: {
  //         series: {
  //             animation: {
  //                 duration: 3000
  //             },
  //             cursor: 'pointer',
  //             dragDrop: {
  //               draggableY: true,
  //               dragCursor: 'ns-resize', // cursor style during drag
  //               dragStart: function (e) {
  //                 console.log('Drag started on series:', e.target.name);
  //               },
  //               dragEnd: function (e) {
  //                 console.log('Drag ended on series:', e.target.name);
  //               }
  //             }
  //         }
  //       },
  //       series: [{
  //         name: this.purchaseType + ' Purchase Report',
  //         data: this.purchaseMonthTotalBillAmount,
  //         fillColor: 'rgba(93,175,89,0.1)',
  //         color: '#FC7643',
  //       }]
  //     };
  //     this.purchaseChart.chart('purchaseCanvas', chartOptions);
  //   });
  // }

  /**
   * Total Purchase Year Report
   */
  getTotalPurchaseYearReport() {
    // this.puchaseChart.destroy();
    if (this.newPurchaseDate) {
      this.todaysDate = this.newPurchaseDate;
    }
    const oneYearAgo = moment().subtract(1, 'years').format('YYYY-MM-DD')
    this.dashboardService.getTotalInvoiceYearReport({}, this.currentUser.id, this.todaysDate, "PURCHASE").then((res) => {
      this.yearlyPurchaseDate = res.data.dataset.map((names: any) => names.date + "/" + names.year);
      this.yearlyPurchasetotalBillAmount = res.data.dataset.map((total: any) => total.totalBillAmount);
      // this.plotYearChart()
      this.dashboardService.getTotalInvoiceYearReport({}, this.currentUser.id, oneYearAgo, "PURCHASE").then((res) => {
      this.pastyearlyPurchaseDate = res.data.dataset.map((names: any) => names.date + "/" + names.year);
      this.pastyearlyPurchasetotalBillAmount = res.data.dataset.map((total: any) => total.totalBillAmount);
      this.plotYearChart()
    })
  })
  }

  plotYearChart(){
    this.purchaseChart = Highcharts;
    const isPastWeekDataEmpty = this.pastyearlyPurchasetotalBillAmount.every(value => value === 0);
    const isCurrentWeekDataEmpty = this.yearlyPurchasetotalBillAmount.every(value => value === 0);
    if (isPastWeekDataEmpty && isCurrentWeekDataEmpty) {
      // Display a "No data found" message
      const chartOptions = {
        chart: {
          type: 'spline',
          events: {
            load: function() {
              const chart = this;
              const centerX = chart.plotWidth / 2;
              const centerY = chart.plotHeight / 2;

              // Add the image element
              chart.noDataImage = chart.renderer
                .image('../../../assets/img/icons/spot-illustrations/notfound1.png', centerX - 90, centerY - 140, 200, 150)
                .add();
            }
          }
        },
        title: {
          // text: this.purchaseType + ' Purchase Report',
          text: ' ',
            style: {
              fontFamily: "'Poppins', sans-serif",
              fontWeight: '500',
              fontSize: '16px',
              color: '#737791'
            }
        },
        xAxis: {
          categories: this.weekNames
        },
        credits: {
          enabled: false
        },
      };
      this.purchaseChart.chart('purchaseCanvas', chartOptions);
      document.getElementById('purchaseCanvas').style.height = '300px';

      } else {
        const chartOptions = {
          chart: {
            type: 'spline',
          },
          credits:{
            enabled: false
        },
          title: {
            // text: this.purchaseType + ' Purchase Report',
            text: ' ',
            style: {
              fontFamily: "'Poppins', sans-serif",
              fontWeight: '500',
              fontSize: '16px',
              color: '#737791'
            }
          },
          xAxis: [{
            categories: this.yearlyPurchaseDate
          }
        ],
          yAxis: {
            title: {
              text: 'Purchase Amount'
            },
            gridLineWidth: 0
          },
          plotOptions: {
            series: {
                animation: {
                    duration: 3000
                },
                cursor: 'pointer',
            }
          },
          series: [
            {
              name: 'Past year',
              data: this.pastyearlyPurchasetotalBillAmount,
              color: '#FC7643',
            },
            {
              name: 'Current Year',
              data: this.yearlyPurchasetotalBillAmount,
              color: '#110152',
            }
          ]
        };
        this.purchaseChart.chart('purchaseCanvas', chartOptions)
        document.getElementById('purchaseCanvas').style.height = '300px';

      }
  }

  // getUpdatedPurchaseYearReport(e) {
  //   this.purchaseChart.destroy();
  //   this.newPurchaseDate = moment(e).format('YYYY-MM-DD')
  //   this.dashboardService.getTotalInvoiceYearReport({}, this.currentUser.id, moment(e).format('YYYY-MM-DD'), "PURCHASE").then((res) => {
  //     this.yearlyPurchaseDate = res.data.dataset.map((names: any) => names.date + "/" + names.year);
  //     this.yearlyPurchasetotalBillAmount = res.data.dataset.map((total: any) => total.totalBillAmount);
  //     this.purchaseChart = Highcharts;
  //     const chartOptions =  {
  //       chart: {
  //         type: 'spline'
  //       },
  //       credits:{
  //         enabled: false
  //      },
  //       title: {
  //         text: 'Total Yearly Purchase'
  //       },
  //       xAxis: {
  //         categories: this.yearlyPurchaseDate,
  //         title: {
  //           text: 'months'
  //         }
  //       },
  //       yAxis: {
  //         title: {
  //           text: 'Purchase Amount'
  //         },
  //         min: 0,
  //         gridLineWidth: 0
  //       },
  //       plotOptions: {
  //         series: {
  //             animation: {
  //                 duration: 3000
  //             },
  //             cursor: 'pointer',
  //             dragDrop: {
  //               draggableY: true,
  //               dragCursor: 'ns-resize', // cursor style during drag
  //               dragStart: function (e) {
  //                 console.log('Drag started on series:', e.target.name);
  //               },
  //               dragEnd: function (e) {
  //                 console.log('Drag ended on series:', e.target.name);
  //               }
  //             }
  //         }
  //       },
  //       series: [{
  //         name: this.purchaseType + ' Purchase Report',
  //         data: this.yearlyPurchasetotalBillAmount,
  //         fillColor: 'rgba(93,175,89,0.1)',
  //         color: '#FC7643',
  //       }]
  //     };
  //     this.purchaseChart.chart('purchaseCanvas', chartOptions);
  //   });
  // }

}
