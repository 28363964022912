<div class="container-fluid mb-3 px-0 mx-0">
    <div class="row pt-2 pb-3" id="headingPrintHide">
        <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:500;font-size:20px!important;padding-left: 15px;">
            GSTR 4- Quarterly Return
        </div>
        <div class="col-6 navOption-btns" style="display: flex; justify-content: flex-end;">
            <button class="btn me-3" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()"
                type="button"><i class="fas fa-download"></i></button>

            <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
            </button>
        </div>
    </div>
    <hr class="mt-1" />
    <div class="fs--1 bg-white">
        <div class="row mx-auto mb-3">
            <div id="headingPrintHide">
                <u class="text-nowrap"><strong style="padding-left: 13px !important;font-weight: 500; font-size: 14px;">Uploaded by Supplier</strong></u>
            </div>

            <!-- <div class="col-sm-4"></div>
                <div class="col-sm-1  mt-2 text-center" id="excelPrintHide" (click)="exportExcel()">
                    <a type="button" style="margin-left: 14px;" class="p-0 text-900 bi-file-earmark-excel fa-2x "></a>
                    <div class="text-nowrap text-900  fs--2  mt-1">Export to Excel</div>
                </div>
                <div class="col-sm-1 mt-2 text-center" id="PrintoptionHide" (click)="onPrint()">
                    <a type="button" class=" p-0 text-900 bi-printer float-right fa-2x"></a>
                    <div class="fs--2 text-900 text-nowrap mt-1">Print</div>
                </div> -->
        </div>

        <h4 style="font-weight: 400; font-size: 14px;font-family: 'Poppins';">4A. Inward supplies received from a registered supplier (supplies excluding reverse charge)</h4>

        <div class="table-responsive scrollbar">
            <div class="container">
                <table class="table bg-white table-bordered table-stripped" id="excel-table">
                    <!-- <table class="table table-bordered"> -->
                    <thead class="table-secondary" style="background-color: #F0F0F0;">
                        <tr class="btn-reveal-trigger">
                            <th class="text-nowrap" scope="col">No of Records</th>
                            <th class="text-nowrap" scope="col" style="text-align: right;">Total Invoice value</th>
                            <th class="text-nowrap" scope="col" style="text-align: right;">Total Taxable value</th>
                            <th class="text-nowrap" scope="col" style="text-align: right;">Total Integrated Tax</th>
                            <th class="text-nowrap" scope="col" style="text-align: right;">Total Central Tax</th>
                            <th class="text-nowrap" scope="col" style="text-align: right;">Total State/UT Tax</th>
                            <th class="text-nowrap" scope="col" style="text-align: right;">Total Cess</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr class="btn-reveal-trigger" *ngFor="let gst4 of gst4ListA">
                            <td>{{gst4.no_of_record}}</td>
                            <td style="text-align: right;">₹&nbsp;{{(gst4.total_invoice_value ? gst4.total_invoice_value : 0.00) | number:'1.2-2'}}</td>
                            <td style="text-align: right;">₹&nbsp;{{(gst4.total_taxable_value ? gst4.total_taxable_value : 0.00) | number:'1.2-2'}}</td>
                            <td style="text-align: right;">₹&nbsp;{{(gst4.integrated_tax ? gst4.integrated_tax : 0.00) | number:'1.2-2'}}</td>
                            <td style="text-align: right;">₹&nbsp;{{(gst4.central_tax ? gst4.central_tax : 0.00) | number:'1.2-2'}}</td>
                            <td style="text-align: right;">₹&nbsp;{{(gst4.state_UT_tax ? gst4.state_UT_tax : 0.00) | number:'1.2-2'}}</td>
                            <td style="text-align: right;">₹&nbsp;{{(gst4.total_cess ? gst4.total_cess : 0.00) | number:'1.2-2'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <h4 style="font-weight: 400; font-size: 14px;font-family: 'Poppins';">4B. Inward supplies received from a registered supplier (supplies attracting reverse charge)</h4>

        <div class="table-responsive scrollbar">
            <div class="container">
                <table class="table bg-white table-bordered table-stripped">
                    <!-- <table class="table table-bordered"> -->
                    <thead class="table-secondary" style="background-color: #F0F0F0;">
                        <tr class="btn-reveal-trigger">
                            <th class="text-nowrap" scope="col">No of Records</th>
                            <th class="text-nowrap" scope="col" style="text-align: right;">Total Invoice value</th>
                            <th class="text-nowrap" scope="col" style="text-align: right;">Total Taxable value</th>
                            <th class="text-nowrap" scope="col" style="text-align: right;">Total Integrated Tax</th>
                            <th class="text-nowrap" scope="col" style="text-align: right;">Total Central Tax</th>
                            <th class="text-nowrap" scope="col" style="text-align: right;">Total State/UT Tax</th>
                            <th class="text-nowrap" scope="col" style="text-align: right;">Total Cess</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr class="btn-reveal-trigger" *ngFor="let gst4 of gst4ListB">
                            <td>{{gst4.no_of_record}}</td>
                            <td style="text-align: right;">₹&nbsp;{{gst4.total_invoice_value ? gst4.total_invoice_value : 0.00 | number:'1.2-2'}}</td>
                            <td style="text-align: right;">₹&nbsp;{{gst4.total_taxable_value ? gst4.total_taxable_value : 0.00 | number:'1.2-2'}}</td>
                            <td style="text-align: right;">₹&nbsp;{{gst4.integrated_Tax ? gst4.integrated_Tax : 0.00 | number:'1.2-2'}}</td>
                            <td style="text-align: right;">₹&nbsp;{{gst4.central_Tax ? gst4.central_Tax : 0.00 | number:'1.2-2'}}</td>
                            <td style="text-align: right;">₹&nbsp;{{gst4.state_UT_tax ? gst4.state_UT_tax : 0.00 | number:'1.2-2'}}</td>
                            <td style="text-align: right;">₹&nbsp;{{gst4.totalCess ? gst4.totalCess : 0.00 | number:'1.2-2'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
