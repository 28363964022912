import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as moment from 'moment';

import { PartyReportsService } from 'src/app/core/services/party-reports.service';
import { PartyService } from 'src/app/core/services/party.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import * as XLSX from 'xlsx';

interface IRange {
  value: Date[];
  label: string;

}

@Component({
  selector: 'app-all-parties',
  templateUrl: './all-parties.component.html',
  styleUrls: ['./all-parties.component.scss']
})
export class AllPartiesComponent implements OnInit {
  fileName = 'AllParty.xlsx';
  today: Date;
  maxDate: Date;
  public partyList: any = [];

  partyallReportList: any = [];
  public dateRange: Date[]
 public partyPagesize: number =5;
  public currentPageNo: number = 0;
  public allPartyList: any = [];
  public itemPartyList: any = [];
  public itemPartyList2: any = [];

  public searchPartyName: any;
  public ranges: IRange[];
   public quarterStartDate: any;
  public quarterEndDate: any;



 // public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  public p = 1;
  public totalrow: any;
  public toatlPages: any;
  public pagesize: number = 1;



  public filteredOptions: Observable<string[]>;
  public partyName = new FormControl();
  public partyData: any;
  public myControl = new FormControl();
  public submitted: boolean = false;
  public selectedPartyName: any;
  public minReceivable = 0;
  public maxReceivable = 0;
  public totalReceivable = 0;
  public minPayable = 0;
  public maxPayable = 0;
  public totalPayable = 0;
  public equalGst = 0;
  public totalgst = 0;
  public valueItem: string= "Equal";
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  constructor(private partyreportsService: PartyReportsService,
    public toastService: ToastNotificationService,
    private partyService: PartyService,
    private saleInvoiceService: SaleInvoiceService,


    private titleCasePipe: TitleCasePipe) {
    this.today = new Date();
    this.getQuarterRange();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
  }
  isShown: boolean = false;
  ngOnInit(): void {
    this.getAllParties();
    this.getAllpartListReport();
    this.ranges = [

      {
        value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 7 Days'
      },
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 30 Days'
      },
      {
        value: [new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()), new Date(new Date(new Date().setDate(0)).toDateString())],
        label: 'Last Month'

      },
      {
        value: [this.quarterStartDate._d, this.quarterEndDate._d],
        label: ' Last Quarter'
      },
      {
        value: [new Date(new Date(new Date().getFullYear() - 1, 3, 1).toDateString()), new Date(new Date(new Date().getFullYear(), 2, 31).toDateString())],
        label: 'Last Financial Year'

      },
      {
        value: [new Date(new Date(new Date().getFullYear(), 3, 1).toDateString()), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'This Financial Year'

      },
      {
        value: [],
        label: 'Custom Range'

      }];

    this.dateRange = [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate()))]

  }

  getQuarterRange() {
    this.quarterStartDate = moment(this.today).subtract(1, 'quarter').startOf('quarter');
    this.quarterEndDate = moment(this.today).subtract(1, 'quarter').endOf('quarter');
  }
  getAllpartListReport() {
    this.partyreportsService.getPartyReportAllPartyReport({}, this.currentUser.id, this.partyPageSize, (this.currentPageNo+1)).then((res: any) => {
      this.partyList = res.data.pageData;
      this.itemPartyList = res.data.total;
      this.itemPartyList2 = res.data.total;
      this.p = res.data.currentPage;
      this.totalrow = res.data.totalDataCount;
      this.toatlPages = res.data.pageDataCount

    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong."
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    })
  }
  getAllPartyReport() {
    this.partyreportsService.getPartyRepoALLFilter({}, this.currentUser.id, moment(this.dateRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD') ,moment(this.dateRange[1], 'DD-MMM-YYYY').format('YYYY-MM-DD')).then((res) => {
      this.partyList = res.data.pageData;
      this.itemPartyList = res.data.total;
      this.itemPartyList2 = res.data.total;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong."
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    })
  }

  searchPartyByName1(item) {
    console.log(item.partyName)
    this.searchPartyName = item.partyName;
    console.log(this.searchPartyName);

    this.partyreportsService.getPartyRepoALLFiltersearchPartyName({}, this.currentUser.id,this.searchPartyName,this.partyPageSize, (this.currentPageNo+1)).then((res) => {
      this.partyList = res.data.pageData;
      this.itemPartyList = res.data.total;
      this.itemPartyList2 = res.data.total;
      this.p = res.data.currentPage;
      this.totalrow = res.data.totalDataCount;
      this.toatlPages = res.data.pageDataCount
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong."
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    })


  }
  private filterNames(name: string): string[] {
    return this.allPartyList.filter(partyList =>
      partyList.partyName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
  getAllParties() {
    this.partyService
      .getAllPartiesDetails({}, this.currentUser.id, this.partyPagesize, (this.currentPageNo + 1))
      .then((res) => {
        this.allPartyList = res.data.pageData;
        this.filteredOptions = this.partyName.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filterNames(name) : this.allPartyList.slice())
          );
      });
  }
  searchPartyByName(item) {
    console.log("here in searchPartyByName in all party components");
    this.selectedPartyName = item.partyName
    this.saleInvoiceService.getPartyByName({}, this.currentUser.id, item)
      .then((response: any) => {
        this.partyData = response.data.rows;
        this.allPartyList = response.data.rows;
        if (this.allPartyList) {
          this.filteredOptions = this.myControl.valueChanges
            .pipe(
              startWith(''),
              map(name => name ? this.filterNames(name) : this.allPartyList.slice())
            );
        }
      });
    this.getAllPartyReport();

  }
  toggleShow() {
    this.isShown = !this.isShown;
  }
  printComponent(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;

  }
  getDate() {
    console.log("DATE ", this.dateRange)
  }
  exportExcel() {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }
  onPrint() {
    const elementsToHide = ['headingPrintHide', 'dateRangePrintHide', 'searchBarHide','headingPrintHide1'];

    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = 'none';
      }
    });

    // Hide headers and footers for printing
    const style = document.createElement('style');
    style.innerHTML = `
        @media print {
            @page {
                size: auto;   /* auto is the initial value */
                margin: 0;  /* this affects the margin in the printer settings */
            }
            @page {
                margin-top: 0;
                margin-bottom: 0;
            }
            body {
                margin: 0;  /* this affects the margin on the content before sending to the printer */
            }
            @page :first {
                margin-top: 0;
            }
            @page :last {
                margin-bottom: 0;
            }
            /* Define CSS rules to hide headers and footers */
            header, footer {
                display: none !important;
            }
        }
    `;
    document.head.appendChild(style);

    // Print
    const printContents = document.getElementById('excel-table').innerHTML;
    const originalContents = document.body.innerHTML;
    this.removeScrollbar();
    window.print();
    this.restoreScrollbar();
    // Restore hidden elements after printing
    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = '';
      }
    });
    // Remove the added style for hiding headers and footers
    document.head.removeChild(style);
  }
  private removeScrollbar() {
    document.body.style.overflow = 'hidden';
  }

  private restoreScrollbar() {
    document.body.style.overflow = 'auto';
  }
  Applygst(){
    console.log("APPLY CLICK", this.totalgst)
    this.partyreportsService.getPartyRepoALLFilteGst({}, this.currentUser.id,this.totalgst).then((res:any) => {
      this.partyList = res.data.parties;
      this.itemPartyList2 = res.data.total[0];

    })
  }
  // Applygst(){
  //   console.log("APPLY CLICK", this.totalgst)
  //   this.partyreportsService.getPartyRepoALLFilteGst({}, this.currentUser.id, moment(this.dateRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD') ,moment(this.dateRange[1], 'DD-MMM-YYYY').format('YYYY-MM-DD'),
  //   this.selectedPartyName, {
  //     type: "Equal",
  //     value: this.totalgst
  //   }).then((res) => {
  //     // this.partyList = res.data.parties;

  //   })
  // }
  onSelectgst(value){
    this.valueItem = value;
    console.log("valueItem", this.valueItem)
    console.log("value", value)
  }

    // if (item == 'Equal') {
    //   this.minPayable = this.totalPayable;
    //   this.maxPayable = this.totalPayable;
    //   console.log("ffgggh", this.minPayable, this.maxPayable)

    // }
    // if (item == 'Greater') {
    //   this.minPayable = 0;
    //   this.maxPayable= this.totalPayable;
    //   console.log("ffgggh", this.minPayable, this.maxPayable)

    // }
    // if (item == 'Less') {
    //   this.minPayable = this.totalPayable;
    //   this.maxPayable = 0;
    //   console.log("ffgggh", this.minPayable, this.maxPayable)


    // }


  // }
  onSelectPayable(value) {
    this.valueItem = value;
    console.log("valueItem--->", this.valueItem)
    console.log("value--->", value)
  }

  // ApplyPayable() {
  //   console.log("APPLY CLICK", this.totalPayable, this.totalPayable, this.totalPayable)

  //   if(this.valueItem == "Greater"){
  //     this.partyreportsService.getPartyRepoALLFilterPayableGreater({}, this.currentUser.id,this.totalPayable).then((res) => {
  //       this.partyList = res.data.parties;
  //       this.itemPartyList2 = res.data.total[0];

  //   })
  // }
  // else if (this.valueItem == "Equal"){
  //   this.partyreportsService.getPartyRepoALLFilterPayableEqual({}, this.currentUser.id,this.totalPayable).then((res) => {
  //     this.partyList = res.data.parties;
  //     this.itemPartyList2 = res.data.total[0];

  //   })

  // }
  // else{
  //   this.partyreportsService.getPartyRepoALLFilterPayableless({}, this.currentUser.id,this.totalPayable).then((res) => {
  //     this.partyList = res.data.parties;
  //     this.itemPartyList2 = res.data.total[0];

  //   })

  // }

  // }
  ApplyPayable() {
    console.log("APPLY CLICK", this.totalPayable, this.totalPayable, this.totalPayable)

    if(this.valueItem == "Greater"){
      this.partyreportsService.getPartyRepoALLFiltePayableGreater({}, this.currentUser.id,this.totalPayable).then((res) => {
        this.partyList = res.data.parties;
        this.itemPartyList2 = res.data.total[0];

      })
    }
    else if (this.valueItem == "Equal"){
      this.partyreportsService.getPartyRepoALLFiltePayableEqual({}, this.currentUser.id,this.totalPayable).then((res) => {
        this.partyList = res.data.parties;
        this.itemPartyList2 = res.data.total[0];

      })

    }
    else{
      this.partyreportsService.getPartyRepoALLFiltePayableless({}, this.currentUser.id,this.totalPayable).then((res) => {
        this.partyList = res.data.parties;
        this.itemPartyList2 = res.data.total[0];

      })

    }

  }

  onSelectReceivable(value) {
    this.valueItem = value;
    console.log("valueItem--->", this.valueItem)
    console.log("value---->", value)

  }

  ApplyReceivable() {
    console.log("APPLY CLICK", this.totalReceivable, this.totalReceivable, this.totalReceivable)
    // this.partyreportsService.getPartyRepoALLFilteReceivable({}, this.currentUser.id,this.totalReceivable).then((res) => {
    //   this.partyList = res.data.parties;

    // })

    if(this.valueItem == "Greater"){
      this.partyreportsService.getPartyRepoALLFilteReceivableGreater({}, this.currentUser.id,this.totalReceivable).then((res) => {
        this.partyList = res.data.parties;
        this.itemPartyList2 = res.data.total[0];

    })
  }
  else if (this.valueItem == "Equal"){
    this.partyreportsService.getPartyRepoALLFilteReceivableEqual({}, this.currentUser.id,this.totalReceivable).then((res) => {
      this.partyList = res.data.parties;
      this.itemPartyList2 = res.data.total[0];

    })

  }
  else{
    this.partyreportsService.getPartyRepoALLFilteReceivableless({}, this.currentUser.id,this.totalReceivable).then((res) => {
      this.partyList = res.data.parties;
      this.itemPartyList2 = res.data.total[0];

    })

  }

}

  // ApplyReceivable() {
  //   console.log("APPLY CLICK", this.totalReceivable, this.totalReceivable, this.totalReceivable)
  //   this.partyreportsService.getPartyRepoALLFilteReceivable({}, this.currentUser.id, moment(this.dateRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD') ,moment(this.dateRange[1], 'DD-MMM-YYYY').format('YYYY-MM-DD'),
  //     this.selectedPartyName, {
  //     type: this.valueItem,
  //     value: this.totalReceivable
  //   }).then((res) => {
  //     this.partyList = res.data.parties;

  //   })
  // }


  changeDate(e){
  this.getAllPartyReport();
  }
  handlePage(e){
    console.log(e)
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.partyList.length > 0) {
      this.partyPageSize = e.pageSize;
      this.getAllpartListReport();
    }

  }
}
