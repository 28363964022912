<div class="container-fluid p-0">
    <!-- <div class="row">
        <div class="col-lg-10 col-sm-12 col-md-10 col-xl-10">
        </div>
        <div class="col-lg-2 col-sm-12 col-md-2 col-xl-2" style="float: right !important;">
            <button data-bs-target="#staticBackdrop" style="height:40px; margin-left: 5px;"
                class="btn btn-outline-primary  text-center float-right" data-bs-toggle="modal"
                data-bs-target="#openFilterModel">
                <span class="material-icons fs-1 icon-color text-center mt-1" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="Filter">tune</span>
            </button>
            <button class="btn btn-outline-warning m-2">
                <i class="fas fa-download"></i>
            </button>
        </div>
    </div> -->

    <div class="row mb-3">
        <div style="display:flex;flex-direction:row;justify-content: flex-end;align-items: center;">
            <button id = "printPageButton" data-bs-target="#staticBackdrop" style="height:38px;margin-right: 20px;"
                class="btn btn-outline-warning" data-bs-toggle="modal" data-bs-target="#openFilterModel">
                <span class="material-icons fs-1 icon-color" data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Filter">tune</span>
            </button>
            <button id = "download" class="btn btn-outline-warning" (click)="downlaodAllData()">
                <i class="fas fa-download"></i>
            </button>
        </div>
    </div>

    <!-- <hr class="mt-3 mb-2 py-0" /> -->
    <div id="tableExample"
        data-list='{"valueNames":["partyName","GSTNumber","DueAmount","LastPaidOn","UnpaidMoreThan"]}'>
        <div style="width:100% ; overflow-x: auto;"  id = "saleTablePrint">

            <table class="table table-responsive table-bordered fs--1 mb-0" *ngIf="!isArabic">
                <thead class="text-600">
                    <tr>
                        <!-- <th scope="col">
                            <input type="checkbox" />
                        </th> -->
                        <th class="text-nowrap sort" style="vertical-align:middle;width:200px;">Vendor Name</th>
                        <th class="text-nowrap sort" style="vertical-align:middle;width:200px;">Email</th>
                        <th class="text-nowrap sort" style="vertical-align:middle;width:200px;">GSTIN</th>
                        <th class="text-nowrap sort" style="vertical-align:middle;width:130px;">No of Days</th>
                        <th class="text-nowrap sort" style="vertical-align:middle;width:180px;text-align: right;">Due
                            Amount</th>
                        <th class="text-nowrap sort" style="vertical-align:middle;width:auto;"></th>

                    </tr>

                </thead>
                <tbody>
                    <tr class="btn-reveal-trigger text-left" *ngFor="let item of debitdata; let i = index"
                        [ngStyle]="{'background-color': (i % 2 === 0 ? '#f2f2f2' : '#fff')}">
                        <!-- <td scope="row" style="vertical-align:middle;">
                            <input type="checkbox" />
                        </td> -->
                        <td style="vertical-align:middle;" class="partyName">{{ item.partyName ||'' }}</td>
                        <td style="vertical-align:middle;" class="GSTNumber">{{ item.email || ''}}</td>
                        <td style="vertical-align:middle;" class="GSTNumber">{{ item.gstIn || ''}}</td>
                        <td style="vertical-align:middle;" class="UnpaidMoreThan">{{item.creditPeriodDay}}</td>
                        <td style="vertical-align:middle;text-align: right;" class="DueAmount">₹&nbsp;{{ item.totalDebt
                            | number:'1.2-2'}}</td>
                        <td style="vertical-align:middle;white-space:nowrap;text-align: center;">
                            <div>
                                <button class="btn btnp me-3" type="button">
                                    <span class="bi bi-eye iconFontSize" data-bs-toggle="tooltip" data-bs-toggle="modal"
                                        data-bs-target="#sundry1-view" (click)="getReceiptById(item.id)"
                                        data-bs-placement="top" title="View"></span>
                                </button>

                                <button class="btn btnp" type="button" data-bs-toggle="modal"
                                    data-bs-target="#invoice-modal3" (click)="getReceiptById(item.id)">
                                    <span class="bi-printer icon-color iconFontSize" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="print"></span>
                                </button>
                            </div>


                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="debitdata == 0">
                    <tr class="text-center">
                        <td colspan="12">
                            <img class="image-responsive"
                                src="../../../assets/img/icons/spot-illustrations/notfound1.png" alt="notfound1"
                                width="200" height="150" />

                            <!-- <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Sundry Debtor
                                Details Added.</h5> -->
                        </td>
                    </tr>
                    <!-- <tr class="text-center">
                        <td colspan="10">
                            <h5 class="text-nowrap">No Sundry Debtor Details Added.<span>
                                </span></h5>
                        </td>
                    </tr> -->
                </tbody>
            </table>

            <!-- UAE -->

            <table class="table table-responsive table-bordered fs--1 mb-0" *ngIf="isArabic">
                <thead class="text-600">
                    <tr>
                        <!-- <th scope="col">
                          <input type="checkbox" />
                      </th> -->
                        <th class="text-nowrap sort" style="vertical-align:middle;width:200px;">Vendor Name</th>
                        <th class="text-nowrap sort" style="vertical-align:middle;width:200px;">Email</th>
                        <th class="text-nowrap sort" style="vertical-align:middle;width:200px;">{{lang.GSTIN}}</th>
                        <th class="text-nowrap sort" style="vertical-align:middle;width:130px;">No of Days</th>
                        <th class="text-nowrap sort" style="vertical-align:middle;width:180px;text-align: right;">Due
                            Amount</th>
                        <th class="text-nowrap sort" style="vertical-align:middle;width:auto;"></th>

                    </tr>

                </thead>
                <tbody>
                    <tr class="btn-reveal-trigger text-left" *ngFor="let item of debitdata; let i = index"
                        [ngStyle]="{'background-color': (i % 2 === 0 ? '#f2f2f2' : '#fff')}">
                        <!-- <td scope="row" style="vertical-align:middle;">
                          <input type="checkbox" />
                      </td> -->
                        <td style="vertical-align:middle;" class="partyName">{{ item.partyName ||'' }}</td>
                        <td style="vertical-align:middle;" class="GSTNumber">{{ item.email || ''}}</td>
                        <td style="vertical-align:middle;" class="GSTNumber">{{ item.gstIn || ''}}</td>
                        <td style="vertical-align:middle;" class="UnpaidMoreThan">{{item.creditPeriodDay}}</td>
                        <td style="vertical-align:middle;text-align: right;" class="DueAmount">AED&nbsp;{{
                            item.totalDebt
                            | number:'1.2-2'}}</td>
                        <td style="vertical-align:middle;white-space:nowrap;text-align: center;">
                            <div>
                                <button class="btn btnp me-3" type="button">
                                    <span class="bi bi-eye iconFontSize" data-bs-toggle="tooltip" data-bs-toggle="modal"
                                        data-bs-target="#sundry1-view" (click)="getReceiptById(item.id)"
                                        data-bs-placement="top" title="View"></span>
                                </button>

                                <button class="btn btnp" type="button" data-bs-toggle="modal"
                                    data-bs-target="#invoice-modal2" (click)="getReceiptById(item.id)">
                                    <span class="bi-printer icon-color iconFontSize" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="print"></span>
                                </button>
                            </div>


                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="debitdata == 0">
                    <tr class="text-center">
                        <td colspan="12">
                            <img class="image-responsive"
                                src="../../../assets/img/icons/spot-illustrations/notfound1.png" alt="notfound1"
                                width="200" height="150" />

                            <!-- <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Sundry Debtor
                              Details Added.</h5> -->
                        </td>
                    </tr>
                    <!-- <tr class="text-center">
                      <td colspan="10">
                          <h5 class="text-nowrap">No Sundry Debtor Details Added.<span>
                              </span></h5>
                      </td>
                  </tr> -->
                </tbody>
            </table>

            <mat-paginator class="float-right mt-2" *ngIf="debitdata.length> 0" [pageSize]="partyPageSize"
                [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo" showFirstLastButtons
                (page)="handlePage($event)" aria-label="Select page">
            </mat-paginator>

        </div>
    </div>


    <div class="modal fade" #closebutton id="sundry1-view" tabindex="-1" role="dialog" aria-hidden="true" style="margin-left: 22%;
    margin-top: -9%;" *ngIf="!isArabic">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content position-relative">
                <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                    <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                        data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body p-0">
                    <div class="rounded-top-lg py-3 ps-4 pe-6">

                        <!-- <div class="row">
                        <div class="col-4"></div>
                        <div class="col-4">
                            <h2 class=""><b>View Sundry Creditor</b></h2>
                        </div>
                    </div> -->
                    </div>
                    <div class="container" id = "panel">
                        <div class="card">
                            <div class="card-body">
                                <div class="row my-2" id="panel">
                                    <div class="col-6">
                                    </div>
                                    <!-- <div class="col-6">
                                <div class="row headerAlignment">
                                  <div class="col-6" style = "font-weight: 600;">
                                    TAX INVOICE
                                  </div>
                                  <div class="col-6" id = "originalForReceiptPrint" style="font-size: 14px!important;">
                                    ORIGINAL FOR RECIPIENT
                                  </div>
                                </div>
                              </div> -->
                                </div>
                                <div class="row bordered table-responsive scrollbar fs--1">
                                    <table class="table table-striped tablePrint" style="font-size: 14px!important;">
                                        <thead class="inner-model-table-heading">
                                            <tr class="dark__bg-1000">
                                                <th class="borderbottom2"
                                                    style="font-size: 16px!important;font-weight: 600;">Sundry Debtor
                                                </th>
                                                <th class="borderLeft borderbottom2"></th>
                                            </tr>
                                        </thead>
                                        <tbody class="table-body">
                                            <tr>
                                                <td class="align-middle">
                                                    <p style="font-size: 14px!important;"><span
                                                            style="font-weight: 600;">Vendor
                                                            Name:</span>&nbsp;{{partyName ? partyName : "NA"}}</p>
                                                    <p style="font-size: 14px!important;"><span
                                                            style="font-weight: 600;">Email:</span>&nbsp;{{email ? email
                                                        : "NA"}}</p>
                                                    <P style="font-size: 14px!important;"><span
                                                            style="font-weight: 600;">GSTIN:</span>&nbsp;{{gstIn ? gstIn
                                                        : "NA"}}</P>
                                                </td>
                                                <td class="align-middle">
                                                    <p style="font-size: 14px!important;"><span
                                                            style="font-weight: 600;">Credit Period
                                                            Day:</span>&nbsp;{{creditPeriodDay ? creditPeriodDay :
                                                        "NA"}}</p>
                                                    <!-- <p style="font-size: 14px!important;"><span
                                                            style="font-weight: 600;">Total Credit:</span> -->
                                                    <!-- {{totalCredit | currency:'INR':'symbol':'1.2-2'}} -->
                                                    <!-- ₹&nbsp;{{ totalCredit | number:'1.2-2'}}
                                                    </p> -->
                                                    <p style="font-size: 14px!important;"><span
                                                            style="font-weight: 600;">Total Debt:</span>
                                                        <!-- {{totalDebt | currency:'INR':'symbol':'1.2-2'}} -->
                                                        ₹&nbsp;{{ totalDebt | number:'1.2-2'}}
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- <hr style = "border: 1px solid grey;" />
                            <div class = "row my-2">
                              <div class="col-12" style = "display: flex;flex-direction: column;justify-content: flex-start;">
                                <span class = "mb-1" style = "font-weight: 600;">TERMS AND CONDITIONS</span>
                                <span>1. Goods once sold will not be taken back or exchanged.</span>
                                <span>2. All disputes are subject to your respective city jurisdiction only.</span>
                              </div>
                            </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- UAE -->

    <div class="modal fade" #closebutton id="sundry1-view" tabindex="-1" role="dialog" aria-hidden="true" style="margin-left: 22%;
    margin-top: -9%;" *ngIf="isArabic">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content position-relative">
                <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                    <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                        data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body p-0">
                    <div class="rounded-top-lg py-3 ps-4 pe-6">
                    </div>
                    <div class="container" id = "panel">
                        <div class="card">
                            <div class="card-body">
                                <div class="row my-2" id="panel">
                                    <div class="col-6">
                                    </div>

                                </div>
                                <div class="row bordered table-responsive scrollbar fs--1">
                                    <table class="table table-striped tablePrint" style="font-size: 14px!important;">
                                        <thead class="inner-model-table-heading">
                                            <tr class="dark__bg-1000">
                                                <th class="borderbottom2"
                                                    style="font-size: 16px!important;font-weight: 600;">Sundry Debtor
                                                </th>
                                                <th class="borderLeft borderbottom2"></th>
                                            </tr>
                                        </thead>
                                        <tbody class="table-body">
                                            <tr>
                                                <td class="align-middle">
                                                    <p style="font-size: 14px!important;"><span
                                                            style="font-weight: 600;">Vendor
                                                            Name:</span>&nbsp;{{partyName ? partyName : "NA"}}</p>
                                                    <p style="font-size: 14px!important;"><span
                                                            style="font-weight: 600;">Email:</span>&nbsp;{{email ? email
                                                        : "NA"}}</p>
                                                    <P style="font-size: 14px!important;"><span
                                                            style="font-weight: 600;">{{lang.GSTIN}}:</span>&nbsp;{{gstIn
                                                        ? gstIn
                                                        : "NA"}}</P>
                                                </td>
                                                <td class="align-start">
                                                    <p style="font-size: 14px!important;"><span
                                                            style="font-weight: 600;">Credit Period
                                                            Day:</span>&nbsp;{{creditPeriodDay ? creditPeriodDay :
                                                        "NA"}}</p>
                                                    <!-- <p style="font-size: 14px!important;"><span
                                                            style="font-weight: 600;">Total Credit:</span> -->
                                                    <!-- {{totalCredit | currency:'INR':'symbol':'1.2-2'}} -->
                                                    <!-- ₹&nbsp;{{ totalCredit | number:'1.2-2'}}
                                                    </p> -->
                                                    <p style="font-size: 14px!important;"><span
                                                            style="font-weight: 600;">Total Debt:</span>
                                                        <!-- {{totalDebt | currency:'INR':'symbol':'1.2-2'}} -->
                                                        AED&nbsp;{{ totalDebt | number:'1.2-2'}}
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- <hr style = "border: 1px solid grey;" />
                            <div class = "row my-2">
                              <div class="col-12" style = "display: flex;flex-direction: column;justify-content: flex-start;">
                                <span class = "mb-1" style = "font-weight: 600;">TERMS AND CONDITIONS</span>
                                <span>1. Goods once sold will not be taken back or exchanged.</span>
                                <span>2. All disputes are subject to your respective city jurisdiction only.</span>
                              </div>
                            </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="openFilterModel" data-bs-keyboard="false"
    data-bs-backdrop="static" aria-labelledby="staticBackdropLabel">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 500px">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    (click)="modalDismiss()" data-bs-dismiss="modal" aria-label="Close"></a>
            </div>
            <div class="modal-body container">
                <div class="row my-2">
                    <h5 class="mb-0 "
                        style="font-weight: 500;margin-left: 5px!important;font-size: 14px !important;font-family: 'Poppins', sans-serif !important;"
                        id="modalExampleDemoLabel">Filter By:
                    </h5>

                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <label class="form-label"
                            style="font-weight: 500;margin-left: 5px!important;font-size: 14px !important;">Select</label>

                        <mat-form-field style=" font-size: 14px;font-family: 'Poppins', sans-serif !important;"
                            class="example-full-width" appearance="outline">
                            <mat-select placeholder="Select" disableOptionCentering matNativeControl [(ngModel)]="slectDays">
                                <mat-option value="Days">Days</mat-option>
                                <mat-option value="Months">Months</mat-option>
                                <mat-option value="Years">Years</mat-option>
                            </mat-select>

                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <label class="form-label"
                            style="font-weight: 500;margin-left: 5px!important;font-size: 14px !important;font-family: 'Poppins', sans-serif !important;">Enter
                            Number of Days</label>
                        <mat-form-field style="font-size: 14px;font-family: 'Poppins', sans-serif !important;"
                            class="example-full-width" appearance="outline">
                            <!-- <mat-label>Enter Number of Days</mat-label> -->
                            <input type="text" matInput placeholder="Enter Number of Days"  [(ngModel)]="NoOfDays">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row my-2">
                    <h5 style="font-weight: 500;margin-left: 5px!important;font-size: 14px !important;font-family: 'Poppins', sans-serif !important;"
                        class="mb-0">Amount Range</h5>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <label class="form-label"
                            style="font-weight: 500;margin-left: 5px!important;font-size: 14px !important;font-family: 'Poppins', sans-serif !important;">
                            From</label>
                        <mat-form-field style="font-size: 14px;font-family: 'Poppins', sans-serif !important;"
                            class="example-full-width" appearance="outline">
                            <!-- <mat-label>From</mat-label> -->
                            <input type="text" matInput placeholder="From" [(ngModel)]="amountFrom" >
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <label class="form-label"
                            style="font-weight: 500;margin-left: 5px!important;font-size: 14px !important;font-family: 'Poppins', sans-serif !important;">
                            To</label>
                        <mat-form-field style=" font-size: 14px;font-family: 'Poppins', sans-serif !important;"
                            class="example-full-width" appearance="outline">
                            <!-- <mat-label>To</mat-label> -->
                            <input type="text" matInput placeholder="To" [(ngModel)]="amountTo">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button class="btn btn-cancel" data-bs-dismiss="modal" (click)="cancelModal()">Cancel
                </button>
                <button class="btn btn-save" data-bs-dismiss="modal" (click)="applyFilter()">Continue</button>
            </div>
        </div>
    </div>
</div>

    <div class="modal fade" #closebutton id="invoice-modal2" tabindex="-1" role="dialog" aria-hidden="true"
        *ngIf="isArabic">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content position-relative" id="print-section">
                <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1"  id="headingPrintHide">
                    <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                        data-bs-dismiss="modal"(click)="closeModal()" id="closeModalPrint">
                    </button>
                </div>
                <div class="modal-body p-0">
                    <div class="rounded-top-lg py-3 ps-4 pe-6" id="headingPrintHide1">
                    </div>
                    <div class="container" id = "panel">
                        <div class="card">
                            <div class="card-body">
                                <div class="row" id="panel">
                                    <div class="col-6 pt-2">
                                        <th class="borderbottom2" style="font-size: 16px!important;font-weight: 600;padding-left:0 !important;">
                                            Sundry Debtor
                                        </th>
                                    </div>
                                    <div class="col-6 pt-2" id="headingPrintHide2">
                                        <button
                                            style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                                            id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0"
                                            type="button" (click)="print()"><span class="fas fa-print me-1">
                                            </span>Print
                                        </button>
                                    </div>

                                </div>
                                <div class="row my-2">
                                    <div class="col-6">
                                        <p style="font-size: 14px!important;"><span style="font-weight: 600;">Vendor
                                                Name:</span>&nbsp;{{partyName ? partyName : "NA"}}</p>
                                        <p style="font-size: 14px!important;"><span
                                                style="font-weight: 600;">Email:</span>&nbsp;{{email ? email
                                            : "NA"}}</p>
                                        <P style="font-size: 14px!important;"><span
                                                style="font-weight: 600;">{{lang.GSTIN}}:</span>&nbsp;{{gstIn ? gstIn
                                            : "NA"}}</P>
                                    </div>
                                    <div class="col-6">
                                        <p style="font-size: 14px!important;"><span style="font-weight: 600;">Credit
                                                Period
                                                Day:</span>&nbsp;{{creditPeriodDay ? creditPeriodDay :
                                            "NA"}}</p>
                                        <p style="font-size: 14px!important;"><span style="font-weight: 600;">Total
                                                Debt:</span>
                                            AED&nbsp;{{ totalDebt | number:'1.2-2'}}
                                        </p>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" #closebutton id="invoice-modal3" tabindex="-1" role="dialog" aria-hidden="true"
        *ngIf="!isArabic">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content position-relative" id="print-section">
                <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1"  id="headingPrintHide">
                    <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                        data-bs-dismiss="modal" (click)="closeModal()" id="closeModalPrint">
                    </button>
                </div>
                <div class="modal-body p-0">
                    <div class="rounded-top-lg py-3 ps-4 pe-6" id="headingPrintHide1">
                    </div>
                    <div class="container" id = "panel">
                        <div class="card">
                            <div class="card-body">
                                <div class="row" id = "panel">
                                    <div class="col-6 pt-2">
                                        <th class="borderbottom2" style="font-size: 16px!important;font-weight: 600;padding-left: 0 !important;">
                                            Sundry Debtor
                                        </th>
                                    </div>
                                    <div class="col-6 pt-2" id="headingPrintHide2">
                                        <button
                                            style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                                            id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0"
                                            type="button" (click)="print()"><span class="fas fa-print me-1">
                                            </span>Print
                                        </button>
                                    </div>

                                </div>
                                <div class="row my-2">
                                    <div class="col-6">
                                        <p style="font-size: 14px!important;"><span style="font-weight: 600;">Vendor
                                                Name:</span>&nbsp;{{partyName ? partyName : "NA"}}</p>
                                        <p style="font-size: 14px!important;"><span
                                                style="font-weight: 600;">Email:</span>&nbsp;{{email ? email
                                            : "NA"}}</p>
                                        <P style="font-size: 14px!important;"><span
                                                style="font-weight: 600;">GSTIN:</span>&nbsp;{{gstIn ? gstIn
                                            : "NA"}}</P>
                                    </div>
                                    <div class="col-6">
                                        <p style="font-size: 14px!important;"><span style="font-weight: 600;">Credit
                                                Period
                                                Day:</span>&nbsp;{{creditPeriodDay ? creditPeriodDay :
                                            "NA"}}</p>
                                        <p style="font-size: 14px!important;"><span style="font-weight: 600;">Total
                                                Debt:</span>
                                                ₹&nbsp;{{ totalDebt | number:'1.2-2'}}
                                        </p>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>
<!-- <div class="card mb-3">
    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
            Sundry Debtor
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>
    <div class="card-body p-0 m-2">
        <div class="row d-flex justify-content-end">
            <div class="col-sm-10 col-md-11 col-lg-11"></div>
            <div class="col-sm-2 col-md-1 col-lg-1 mt-2 mb-3">
                <button data-bs-target="#staticBackdrop"
                    style="height:30px; margin-left: 5px;"
                    class="btn btn-outline-primary  text-center float-right" data-bs-toggle="modal"
                    data-bs-target="#openFilterModel">
                    <span class="material-icons fs-1 icon-color text-center" data-bs-toggle="tooltip"
                        data-bs-placement="top" title="Filter">tune</span></button>
            </div>
        </div>
        <div id="tableExample"
            data-list='{"valueNames":["partyName","GSTNumber","DueAmount","LastPaidOn","UnpaidMoreThan"]}'>
            <div class="table-responsive">
                <table class="table table-bordered table-striped fs--1 mb-0">
                    <thead class="bg-200 text-900">
                        <tr>
                            <th>#</th>
                            <th class="text-nowrap " class="sort" data-sort="partyName">Party Name</th>
                            <th class="text-nowrap " class="sort" data-sort="email">Email</th>
                            <th class="text-nowrap " class="sort" data-sort="GSTNumber">GST Number</th>
                            <th class="text-nowrap " class="sort" data-sort="DueAmount">Due Amount</th>

                            <th class="text-nowrap" class="sort" data-sort="UnpaidMoreThan">No of Days</th>
                        </tr>

                    </thead>
                    <tbody class="list" id="table-customers-body">
                        <tr class="btn-reveal-trigger text-left"  *ngFor="let item of debitdata; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td class="partyName">{{ item.partyName ||'' }}</td>
                            <td class="GSTNumber">{{  item.email || ''}}</td>
                            <td class="GSTNumber">{{  item.gstIn || ''}}</td>
                            <td class="DueAmount">{{ item.totalDebt}}</td>

                            <td class="UnpaidMoreThan">{{item.creditPeriodDay}}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="debitdata == 0">
                        <tr class="text-center">
                            <td colspan="10">
                                <h5 class="text-nowrap">No Sundry Debtor Details Added.<span>
                                </span></h5>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <mat-paginator class="float-right mt-2" *ngIf="debitdata.length> 0" [pageSize]="partyPageSize"
                    [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo"
                    (page)="handlePage($event)" aria-label="Select page">
                </mat-paginator>

            </div>
        </div>


    </div>

    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" id="openFilterModel" data-bs-keyboard="false"
        data-bs-backdrop="static" aria-labelledby="staticBackdropLabel">
        <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 500px">
            <div class="modal-content position-relative">
                <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                    <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                        (click)="modalDismiss()" data-bs-dismiss="modal" aria-label="Close"></a>
                </div>
                <div class="modal-body container">
                    <div class="row mt-2">
                        <h5 class="mb-0 " style="font-weight: 500;margin-left: 5px!important; "
                            id="modalExampleDemoLabel">Filter By:
                        </h5>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field style=" font-size: 11px" class="example-full-width" appearance="outline">
                                <mat-label>Select</mat-label>
                                <mat-select placeholder="Select">
                                    <mat-option value="Days">Days</mat-option>
                                    <mat-option value="Months">Months</mat-option>
                                    <mat-option value="Years">Years</mat-option>
                                </mat-select>

                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field style="font-size: 11px" class="example-full-width" appearance="outline">
                                <mat-label>Enter Number of Days</mat-label>
                                <input type="text" matInput placeholder="Enter Number of Days">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <h5 style="font-weight: 500;margin-left: 5px!important;" class="mb-0">Amount Range</h5>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field style="font-size: 11px" class="example-full-width" appearance="outline">
                                <mat-label>From</mat-label>
                                <input type="text" matInput placeholder="From">
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field style=" font-size: 11px" class="example-full-width" appearance="outline">
                                <mat-label>To</mat-label>
                                <input type="text" matInput placeholder="To">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-center">
                    <button class="btn btn-outline-primary" data-bs-dismiss="modal">Cancel
                    </button>
                    <button class="btn btn-outline-primary" data-bs-dismiss="modal">Continue</button>
                </div>
            </div>
        </div>
    </div>
</div> -->
