import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AddOfferService } from 'src/app/core/services/add-offer.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { EditOfferComponent } from '../edit-offer/edit-offer.component';
import { AddOffersComponent } from '../add-offers/add-offers.component';
import { OfferlistViewComponent } from '../offerlist-view/offerlist-view.component';

@Component({
  selector: 'app-offer-list',
  templateUrl: './offer-list.component.html',
  styleUrls: ['./offer-list.component.scss']
})
export class OfferListComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public offerData: any = []
  public searchKey: any;
  public offerId: any;
  public offerListRes: any;
  public currentpage: any;
  public totalrow: any;
  public currentPageNo: number = 0;
  public currentServicePageNo: number = 0
  public offerPageSize: number = 5;
  public pagesize: number = 1;
  public partyPageSize: number = 5;
  public toatlPages: any;
  public p = 1;

  public archiveIconfalse: boolean = false;
  public checkSingleArray: any = [];
  public isChecked: boolean = false;
  public upiCheckedId = [];
  public upiCheckedId1 = [];
  public allSelect: boolean = false;
  public checkSelect: boolean = false;

  public checkSelectsinglefalse: boolean = false;
  public checkSelectsingletrue: boolean = false;
  public productId: any;
  constructor(private addofferService: AddOfferService,
    public dialog: MatDialog,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe, private router: Router,
  ) { }

  ngOnInit(): void {
    this.getOfferListById();

    this.addofferService.offerAdded$.subscribe(()=>{
      this.getOfferListById();
    })
  }
  public handlePage(e: any) {
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.offerData.length > 0) {
      this.partyPageSize = e.pageSize;
      this.getOfferListById();
    }
  }

  getoffersearch(searchKey){
    console.log(this.searchKey);
    if (this.searchKey == "") {
      this.toastService.toastMsg({
        title: "Error",
        content: "Enter Valid Offer Name.",
      });
      // this.toastService.openErrorSnackBar("Enter Valid Offer Name.");
      this.addofferService.getAllOffersearch({}, this.currentUser.id,this.searchKey ,this.partyPageSize, (this.currentPageNo+1)).then((res) => {
        console.log(res);
        this.p = res.data.currentPage;
        this.offerData = res.data.pageData;
        this.totalrow = res.data.totalrows;
        this.toatlPages = res.data.totalPages
       });
    } else {
      this.addofferService.getAllOffersearch({}, this.currentUser.id,this.searchKey ,this.partyPageSize, (this.currentPageNo+1)).then((res) => {
        console.log(res);
        this.p = res.data.currentPage;
        this.offerData = res.data.pageData;
        this.totalrow = res.data.totalrows;
        this.toatlPages = res.data.totalPages;
        // this.toastService.openSnackBar("Offer Name Found Successfully!!!");
       },
         (err) => {
           if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
            //  this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
           }
           else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
            //  this.toastService.openErrorSnackBar("Something Went Wrong.");
           }

         })
    }
  }

  getOfferListById() {
    this.addofferService.getAllOfferList({}, this.currentUser.id, this.partyPageSize, (this.currentPageNo+1)).then((res) => {
      this.p = res.data.currentPage;
      this.offerData = res.data.pageData;
      this.totalrow = res.data.totalrows;
      this.toatlPages = res.data.totalPages
    },
      (err) => {
        if (err.error.expose) {
         // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }

      })
  }
  deleteOfferById() {
    this.addofferService.deleteOfferProductyById({}, this.offerId).then((res) => {
      // this.toastService.openSnackBar("Offer Deleted Successfully!!!");
      this.toastService.toastMsg({
        title: "Success",
        content: "Offer Deleted Successfully!!!",
      });
      this.getOfferListById();
    })
  }
  GetOfferById(offerId: any) {
    this.offerId = offerId;
  }
  updateOfferId(OfferId:string) {
    //this.router.navigate(["/pages/edit-offer/" + id]);
    const dialogRef = this.dialog.open(EditOfferComponent, {
      width: '700px',
      height : '730px',
      data: { OfferId: OfferId },
      panelClass: 'custom-dialog-updateoffer-class',
      disableClose:true,
      position: {
        right: '0',
      },

    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  viewoffer(OfferId:string) {
    //this.router.navigate(["/pages/edit-offer/" + id]);
    const dialogRef = this.dialog.open(OfferlistViewComponent, {
      width: '700px',
      height : '730px',
      data: { OfferId: OfferId },
      panelClass: 'custom-dialog-viewoffer-class',
      disableClose:true,
      position: {
        right: '0',
      },

    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  handlePageChange(pageNumber: number): void {
    console.log("handlePageChange", pageNumber);
    this.partyPageSize = this.partyPageSize
    this.currentPageNo = pageNumber;
    this.p = pageNumber;
    //this.getGoodsbyUser();
    this.getOfferListById();

  }

  getTotalPages(): number {
    return Math.ceil(this.totalrow / this.partyPageSize);
  }

  openDialogaddoffer(): void {
    const dialogRef: MatDialogRef<AddOffersComponent> = this.dialog.open(AddOffersComponent, {
      width: '700px',
      height : '730px',
      data: { },
      panelClass: 'custom-dialog-offer-class',
      disableClose:true,
      position: {
        right: '0',
      },

    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  getUPIcheckId(e, productId) {
    this.productId = productId;
    console.log("this.productId",this.productId)
    


  }

  getSingleCheckedId(e, productId) {
    this.productId = productId;

  }

  onChekedsingleCheckfalse(e, id) {
    console.log("checked", e.target.checked)

    if (e.target.checked == true) {
      this.archiveIconfalse = true;
      this.checkSingleArray.push(e.target.value);

    }
    else {
      if (this.upiCheckedId.length > this.checkSingleArray.length) {

        var index1 = this.upiCheckedId.indexOf(id);
        console.log(index1)
        this.upiCheckedId.splice(index1, 1);
        console.log("after single click upiCheckedId", this.upiCheckedId)

        if (this.upiCheckedId.length > 0) {
          this.archiveIconfalse = true;
          this.checkSelect = false;
          this.isChecked=false;
        }
        else {
          this.archiveIconfalse = false;
          this.upiCheckedId = [];
        }

      }
      else {
        var index = this.checkSingleArray.indexOf(id);
        console.log(index)
        this.checkSingleArray.splice(index, 1);
        console.log("this.checkSingleArray", this.checkSingleArray)

        if (this.checkSingleArray.length > 0) {
          this.archiveIconfalse = true;
        }
        else {
          this.archiveIconfalse = false;
          this.checkSingleArray = [];
        }

      }
    }
  }

  onChekMultiple($event) {
    let id = $event.target.value;
    this.isChecked = $event.target.checked;
    console.log("this.isChecked", this.isChecked)
    this.upiCheckedId1 = this.offerData.map((item) => {
      if (id == -1) {
        if (this.isChecked == true) {
          this.archiveIconfalse = true;
          this.checkSelectsinglefalse = true;
          return item;
        }
        else {
          this.archiveIconfalse = false;
          this.checkSelectsinglefalse = false;
          this.upiCheckedId = [];
          this.upiCheckedId1=[];
        }
      }
    })
    console.log("multiple click", this.upiCheckedId1)
    for (var i = 0; i < this.upiCheckedId1.length; i++) {
        this.upiCheckedId.push(this.upiCheckedId1[i].id);
    }
    console.log("multiple click", this.upiCheckedId)

  }

  deleteProductById() {
    console.log("this.productId-->",this.productId)
    let request = {
      "offersId": this.productId

    }

    this.addofferService.deletemultiofferById(request, this.currentUser.id).then((res) => {
      if (res) {
        // this.goodsData = res.data.pageData;
        // this.toastService.openSnackBar("Offer Deleted Successfully!!!");
        this.toastService.toastMsg({
          title: "Success",
          content: "Offer Deleted Successfully!!!",
        });
        this.archiveIconfalse = false;
        this.allSelect = false;
        this.checkSelectsinglefalse = false;
        this.isChecked=false;
        this.upiCheckedId = [];
        this.checkSingleArray = [];
       
        this.getOfferListById();  
      }

    }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }

      }
    );
  }

}
