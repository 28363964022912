<div class="card-container">
  <div class="card">
    <form [formGroup]="conactForm">
      <div class="row">
        <div class="mb-3 col-lg-12">
          <label class="form-label">Email <span class="error-asterisk">*</span></label>
          <mat-form-field class="example-full-width" appearance="outline">

            <input type="text" matInput required formControlName="email" placeholder="Email" [ngClass]="{
                                    'is-invalid': (f.email.invalid && f.email.touched) && submitted }">
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
            Email is required!
          </mat-error>
          <mat-error class="text-danger" *ngIf="(submitted || f.email.touched) && f.email.errors?.pattern">
            Email must be a valid email address.
          </mat-error>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 col-lg-12">
          <label class="form-label" for="Address">Reason <span class="error-asterisk">*</span></label>
          <mat-form-field class="example-full-width" appearance="outline">
            <textarea matNativeControl matInput id="address" placeholder="Reason" formControlName="reason" rows="3"
              required></textarea>
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="(submitted || f.reason.touched) && f.reason.errors?.required">
            Reason is required!
          </mat-error>
        </div>
      </div>

      <div class="row addProductButtons" style="float:right;">
        <button type="submit" class="btn btn-save" (click)="submitForm()">Submit</button>
      </div>



    </form>
  </div>
</div>