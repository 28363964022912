import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ProductService } from 'src/app/core/services/product.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';
import { Location, TitleCasePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import lang from "src/assets/langTranslation/language_translation"
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-clone-service',
  templateUrl: './clone-service.component.html',
  styleUrls: ['./clone-service.component.scss']
})
export class CloneServiceComponent implements OnInit {
  public lang = lang.UAE;
  public isArabic: boolean;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public unitValue: any;
  public unitSecondaryValue: any;
  public isDisabled: boolean = false;
  public showProductType: any;
  public serviceToggle: boolean = true;
  public isStockToggle: boolean = true;
  public ProductForm: any;
  public productId: string = "";
  public todaysDate;
  public unit: any;
  public units: any;
  public secondaryunit: any;

  // image upload
  public activeColor: string = 'green';
  public baseColor: string = '#ccc';
  public overlayColor: string = 'rgba(255,255,255,0.5)';
  public dragging: boolean = false;
  public loaded: boolean = false;
  public imageLoaded: boolean = false;
  public imageSrc: string = '';
  public file: any;
  public selectedFiles: any;
  public selectedFileName: any;
  public imageSrcOne: any = "";
  public imageSrcOneId: any;
  public imageSrcTwo: any = "";
  public imageSrcTwoId: any;
  public imageSrcThree: any = "";
  public imageSrcThreeId: any = ""
  public imageSrcFour: any = "";
  public imageSrcFourId: any = "";
  public imageSrcFive: any = "";
  public imageSrcFiveId: any = "";

  public imageSrcSix: any = "";
  public imageSrcSixId: any = "";
  public imageSrcSeven: any = "";
  public imgNumber: any;
  public submitted: boolean = false;
  public productCategory: any;
  public categoryIddd: any = 0;

  public currentInput: any;
  public multipleImage = [];
  public multipleImage1 = [];
  previews: string[] = [];
  public fetchImages: any;
  imageArray: any;
  public coverImageChange: boolean = false;
  blankImage = [];
  productPicUrl: any;
  public maxFiles: number = 0;
  public Filesmax: number = 5;

  constructor(private productService: ProductService,private commonService: CommonService,
    private formBuilder: FormBuilder,
    private titleCasePipe: TitleCasePipe,
    public dialogRef: MatDialogRef<CloneServiceComponent>,
    @Inject(MAT_DIALOG_DATA) public serviceData: any,

    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private toastService: ToastNotificationService) { }

  ngOnInit(): void {
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });
    this.todaysDate = new Date();
    this.productId = this.serviceData.productId
    this.getProductCategory();
    if(this.isArabic){
      this.ProductForm = this.formBuilder.group({
        serviceName: [""],
        serviceCode: ["",[Validators.required, Validators.minLength(4), Validators.maxLength(8),Validators.pattern('^[a-zA-Z0-9]*$')]],
        category: [""],
        description: [""],
        unit: [""],
        generateBarcode: ["",[Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern("^[0-9]*$")]],
        hsn:[null],
        price: [""],
        gstRate: ["5",],
        secondaryUnit: [""],
        conversionRate: [""],
        openingStock: [],
        asOfDate: [""],
        additionalCess: [""]

      });
    }
    else{
      this.ProductForm = this.formBuilder.group({
        serviceName: [""],
        serviceCode: ["",[Validators.required, Validators.minLength(4), Validators.maxLength(8),Validators.pattern('^[a-zA-Z0-9]*$')]],
        category: [""],
        description: [""],
        unit: [""],
        generateBarcode: ["",[Validators.required, Validators.minLength(12), Validators.maxLength(12), Validators.pattern("^[0-9]*$")]],
        hsn:["", [Validators.required, Validators.minLength(6), Validators.maxLength(6),Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
        price: [""],
        gstRate: [""],
        secondaryUnit: [""],
        conversionRate: [""],
        openingStock: [],
        asOfDate: [""],
        additionalCess: [""]

      });
    }

    this.getServiceById();
  }

  get f() {
    return this.ProductForm.controls;
  }

  SecondaryUnit = Constants.Units
  Units = Constants.Units
  getUnit(value) {
    this.unitValue = "1 " + value;
  }

  getEnableStockValue(event) {
    this.isStockToggle = event;
  }

  showSecondaryUnitField(value) {
    this.isDisabled = value;
  }

  getSecondaryUnit(value) {
    this.unitSecondaryValue = value;
  }

  show(id) {
    if (id === 5) {
      this.showProductType = Constants.USER.GOODS;
      this.serviceToggle = true;
      //this.disabled = false;
    }
    else if (id === 6) {
      this.showProductType = Constants.USER.SERVICE;
      this.serviceToggle = true;

    }
  }
  getProductCategory() {
    this.productService.getproductCategory({}).then((res: any) => {
      this.productCategory = res.data;
    })
  }
  getServiceById() {
    this.isDisabled = true
    this.productService.getServiceById({}, this.productId).then((res) => {
      this.ProductForm.patchValue({
        serviceName: res.data.itemName,
        category: res.data.category.id,
        //serviceCode: res.data.itemCode,
        //generateBarcode:res.data.barcode,
        description: res.data.description,
        hsn: res.data.hsn,
        //price: res.data.salePrice,
        gstRate: res.data.gstRate,
        openingStock: res.data.stock,
        //asOfDate: (moment(res.data.asOfDate).format("DD MMM YYYY")),
        additionalCess: res.data.additionalCess
      })
      //this.imageSrcSeven = res.data.coverImage ? res.data.coverImage : null;
      //this.fetchImages = res.data.productsImages;



    })
  }
  serviceClone() {
    this.submitted = true;
    if(this.ProductForm.invalid){
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      console.log("this.ProductForm",this.ProductForm)
      return false;
    }
    else{
      let data = {
        "addService": {
          "userId": this.currentUser.id,
          "itemName": this.ProductForm.controls.serviceName.value,
          "barcode": this.ProductForm.controls.generateBarcode.value,
          "categoryId":this.ProductForm.controls.category.value,
          "itemCode": this.ProductForm.controls.serviceCode.value,
          "description": this.ProductForm.controls.description.value,
          "hsn": this.ProductForm.controls.hsn.value,
          "salePrice": this.ProductForm.controls.price.value,
          "gstRate":this.ProductForm.controls.gstRate.value,
          "stock": this.ProductForm.controls.openingStock.value,
          "asOfDate": this.ProductForm.controls.asOfDate.value,
          "additionalCess": this.ProductForm.controls.additionalCess.value,
          "coverImage": this.productPicUrl
      },
        "productImages":
           this.multipleImage ? this.multipleImage : null

      }
      this.productService.postService(data).then((res) => {
        if (res) {
          this.toastService.toastMsg({
            title: "Success",
            content: "Service Clone Successfully.",
          });
          this.productService.notifyProductAdded();
          this.dialogRef.close();
        }

      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      })

    }

  }

  cancel() {
    //this._location.back();
    this.dialogRef.close();
  }


  handleCoverDragEnter() {
    this.dragging = true;
  }
  handleCoverDragLeave() {
    this.dragging = false;
  }
  handleCoverImageLoad() {
    this.imageLoaded = true;
  }
  handleCoverDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.coverImgChange(e);
  }
  coverImgChange(e, imgNumber?: any) {
    this.imgNumber = imgNumber;
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];


    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    const fileSizeLimit = 1 * 1024 * 1024; // 6 MB
    if (file.size > fileSizeLimit) {
      alert("File size exceeds the limit. Please select a smaller image (up to 1 MB).");
      return;
    }
    else{
      this.loaded = false;
      if (e.target.files && e.target.files[0]) {
        reader.readAsDataURL(file);

        reader.onload = () => {
          this.ProductForm.patchValue({
            file: reader.result,
          });

          if (file == null) {
            // this.toastService.openErrorSnackBar(
            //   "Please Select Image To Upload.",
            // );
            this.toastService.toastMsg({
              title: "Error",
              content: "Please Select Image To Upload.",
            });
          } else {

            this.imageSrcSeven = reader.result;

            formdata.set("upload", file)
            e.srcElement.value = "";

            this.productService.postCoverImage1(formdata).then((res) => {
              console.log("*****", res);
              this.productPicUrl = res.data.locations[0];

            });
          }
        }
      }
    }

  }
  cancelCoverImageCover() {
    this.productService.deleteproductCoverImgId({}, this.productId).then((res) => {
      // this.toastService.openSnackBar("Cover Image Deleted  Successfully!!!");
      this.toastService.toastMsg({
        title: "Success",
        content: "Cover Image Deleted Successfully!!!",
      });
      this.imageSrcSeven = '';
      this.getServiceById();
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went To Wrong");
      }
    });
  }

  startUpload() {
    document.getElementById('uploadCoverPic').click();
  }
  cancelCoverImage() {

    this.imageSrcSeven = '';
    this.productPicUrl = '';
    this.imageLoaded = false;

  }

  // onFileSelected(event){
  //   const frmData = new FormData();
  //  // this.previews = [];
  //   for (var i = 0; i < event.target.files.length; i++) {
  //     //this.myFiles.push(event.target.files[i]);
  //     const reader = new FileReader();
  //     reader.onload = (e: any) => {
  //       this.previews.push(e.target.result);
  //     };

  //     reader.readAsDataURL(event.target.files[i]);
  //     frmData.append("upload" , event.target.files[i]);
  //   }
  //   console.log(this.previews)

  //   this.uploadMultiplefile(frmData)



  // }
  onFileSelected(event) {
    const maxFileSize = 1024 * 1024; // 1 MB
    const selectedFiles = event.target.files;
    if (this.maxFiles == 5) {
      // this.toastService.openErrorSnackBar(`You have all ready select 5 images.`);
      this.toastService.toastMsg({
        title: "Error",
        content: "You Have All Ready Select 5 Images.",
      });
    }
    else{
      if (selectedFiles.length > (this.Filesmax - this.maxFiles)) {
        // Handle too many files error
        console.error("You can only select up to 5 files.");
        // this.toastService.openErrorSnackBar(`You can only select up to ${this.Filesmax - this.maxFiles} images.`);
        this.toastService.toastMsg({
          title: "Error",
          content: "You Can Only Select Up To ${this.Filesmax - this.maxFiles} Images.",
        });
        return;
      }

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        console.log("file", file.size)
        if (file.size > maxFileSize) {
          // Handle file size too large error
          //console.error(`File ${file.name} exceeds the maximum size of 1 MB.`);
          this.toastService.toastMsg({
            title: "Error",
            content: "File ${file.name} Exceeds The Maximum Size Of 1 MB.",
          });
          // this.toastService.openErrorSnackBar(`File ${file.name} exceeds the maximum size of 1 MB.`);
          return;
        }
      }

      // Continue with file processing
      const frmData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };
        reader.readAsDataURL(selectedFiles[i]);
        frmData.append("upload", selectedFiles[i]);
      }
      this.maxFiles = this.maxFiles + selectedFiles.length;
     // console.log("this.maxFiles",this.maxFiles)
      console.log(this.previews);
      this.uploadMultiplefile(frmData);
    }


  }
  uploadMultiplefile(frmData:FormData){
    this.productService.postCoverImage1(frmData).then((res) => {
      if(res){
        if(this.multipleImage.length == 0){
          this.multipleImage = res.data.locations;;
          console.log("1 st Multiple",this.multipleImage)
        }
        else{
          this.multipleImage1 = res.data.locations;

          for(var i=0 ; i < this.multipleImage1.length;i++){
            this.multipleImage.push(this.multipleImage1[i]);
          }
          console.log("length Multiple",this.multipleImage.length)
          console.log("again Multiple",this.multipleImage)

        }

      // this.toastService.openSnackBar("Product Images added Successfully!!!");
      this.toastService.toastMsg({
        title: "Success",
        content: "Product Images Added Successfully!!!",
      });
      //console.log("before",this.multipleImage);

      }

    },(err) => {
      if (err.error.expose) {
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went To Wrong");
      }
    });

 }

  cancelmultiImage(i: number) {
   //var index = this.previews.indexOf(i);

  this.previews.splice(i, 1); // Removes one element, starting from index
  this.maxFiles = this.maxFiles - 1; // Decrement maxFiles by 1
  console.log("cancelmultiImage, this.maxFiles", this.maxFiles)
  //console.log("before1",this.multipleImage);
  //var index1 = this.multipleImage.indexOf(removeItem);

  this.multipleImage.splice(i,1);
 // console.log("after",this.multipleImage);
  }



  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  numberDecimalOnly(event):boolean{
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
       return false;

    return true;
  }
  openDatepicker(){
       // Check if the current value of taskdate is an "Invalid Date"
       const selectedDate = this.ProductForm.get('asOfDate').value;
       if (isNaN(selectedDate.getTime())) {
         // Set a default date or any valid date here
         this.ProductForm.get('asOfDate').setValue(new Date());
       }
  }
  autoGenerate() {
    this.productService.autoGenerateBarcode({}, this.currentUser.id).then((codeData) => {
      if (codeData) {
        this.ProductForm.patchValue({
          generateBarcode: codeData.data
        })
      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
      }
    })
  }

}
