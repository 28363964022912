import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PartGroupServiceService } from 'src/app/core/services/part-group-service.service';
import { PartyService } from 'src/app/core/services/party.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {
  partyId: any;
  GroupId: any;

  constructor(private dialog: MatDialog, public dialogRef: MatDialogRef<DeleteModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, 
  private partyService: PartyService, private groupService: PartGroupServiceService,private toastService: ToastNotificationService,
  private titleCasePipe: TitleCasePipe) { }

  ngOnInit(): void {
    this.partyId = this.data.partyId
    this.GroupId = this.data.GroupId

  }

  DeletePartyFromGroup(){
    console.log(this.GroupId  )
    const body = { "partyId": this.partyId }
    this.groupService.DeletePartyFromGroup(body, this.GroupId).then((res:any)=>{
      // this.toastService.openSnackBar("Party Deleted Successfully!!!");
      this.toastService.toastMsg({
        title: "Success",
        content: "Vendor Deleted Successfully!!!",
      });
      // this.partyDataDetails.splice(this.partyId, 1);
      this.partyService.notifyPartyAdded();
    },(err) => {
      if(err.error.expose){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else{
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    })
    // this.submitted = false;
    this.dialogRef.close();
    // this.getGroupDataById()

  }

  cancel() {
    // this.submitted = false;
    this.dialogRef.close();
  }
}
