import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/core/services/product.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { BiddingService } from 'src/app/core/services/bidding.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { PartyReportsService } from 'src/app/core/services/party-reports.service';
import { map, startWith } from 'rxjs/operators';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-item-batch-report',
  templateUrl: './item-batch-report.component.html',
  styleUrls: ['./item-batch-report.component.scss']
})
export class ItemBatchReportComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  //public ProductPageSize: number = 5;
  public p = 1;
  public currentpage: any;
  public totalrow: any;
  public totalPages: any;
  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  // public partyPagesize: number = 5;
  public pagesize: number = 1;
  public goodsData = [];
  public searchedProduct = [];
  public myControl = new FormControl();
  public prodName: string;
  public prodId: string;
  public filteredOptions: Observable<string[]>
  public fileName = 'ItemBatchReport.xlsx';
  public partyList: any = []
  public productId: any;



  itemList: any = [];

  printComponent(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;

  }

  constructor(private productService: ProductService,
    private partyservice: PartyReportsService,
    private toastService: ToastNotificationService,
    private biddingService: BiddingService,
    private titleCasePipe: TitleCasePipe) { }

  ngOnInit(): void {
    this.getProductReport();
    this.getGoodsbyUser();

  }

  getProductReport() {
    this.productService.getProductRepolist({}, this.currentUser.id, this.partyPageSize, (this.currentPageNo + 1)).then((res) => {
      this.itemList = res.data.pageData;
      this.p = res.data.currentpage;
      this.totalrow = res.data.totalrows
      this.totalPages = res.data.totalPages
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
            });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })
  }


  getGoodsbyUser() {
    this.productService.getGoodsProductByUser_Type({}, this.currentUser.id, "goods", "DESC", this.partyPageSize, (this.currentPageNo + 1)).then((res) => {

      this.goodsData = res.data.pageData;
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(name => name ? this.filterNames(name) : this.goodsData.slice())
        );
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
            });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })
  }
  private filterNames(name: string): string[] {
    return this.goodsData.filter(goods =>
      goods.itemName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }



  searchProduct() {
    this.biddingService.getAllProductsByUserSearch({}, this.currentUser.id, this.myControl.value, this.partyPageSize, (this.currentPageNo + 1))
      .then((res) => {
        //  this.itemList =  res.data.pageData;
        this.prodName = res.data.pageData.itemName
        this.totalrow = res.data.totalrows;
        this.totalPages = res.data.totalPages;
        this.totalrow = res.data.totalRows;
        this.p = res.data.currentPage;

        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filterNames(name) : this.itemList.slice())
          );
      }
        ,
        (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
              });
            // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
              });
            // this.toastService.openErrorSnackBar("Something Went Wrong.");
          }
        })
  }


  selectedProduct(itemName) {
    console.log("itemname", itemName)
    this.biddingService.getAllProductsByUserSearch({}, this.currentUser.id, itemName, this.partyPageSize, (this.currentPageNo + 1))
      .then((res) => {
        //  this.prodName=res.data.pageData.itemName

        //   this.itemList =  res.data.pageData;
        this.totalPages = res.data.totalPages;
        this.totalrow = res.data.totalRows;
        this.p = res.data.currentPage;

      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
            });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })
    this.prodName = itemName
    this.getItemBYProductname()


  }
  getItemBYProductname() {

    this.productService.getItemBYProductname({}, this.currentUser.id, this.partyPageSize, (this.currentPageNo + 1), this.prodName).then((res: any) => {
      console.log("this.partyPageSize", this.partyPageSize)
      this.itemList = res.data.pageData;
      this.p = res.data.currentPage;
      this.totalrow = res.data.totalrows;
      this.totalPages = res.data.totalPages
      console.log("this.totalPages,", this.totalPages, this.totalrow, this.p)
      //   this.itemList =  res.data.pageData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went To Wrong",
          });
        // this.toastService.openErrorSnackBar("Something Went To Wrong");
      }
    })
  }
  exportExcel() {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }
  onPrint() {
    const elementsToHide = ['headingPrintHide', 'searchBarHide','headingPrintHide1'];

    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = 'none';
      }
    });

    // Hide headers and footers for printing
    const style = document.createElement('style');
    style.innerHTML = `
        @media print {
            @page {
                size: auto;   /* auto is the initial value */
                margin: 0;  /* this affects the margin in the printer settings */
            }
            @page {
                margin-top: 0;
                margin-bottom: 0;
            }
            body {
                margin: 0;  /* this affects the margin on the content before sending to the printer */
            }
            @page :first {
                margin-top: 0;
            }
            @page :last {
                margin-bottom: 0;
            }
            /* Define CSS rules to hide headers and footers */
            header, footer {
                display: none !important;
            }
        }
    `;
    document.head.appendChild(style);

    // Print
    const printContents = document.getElementById('excel-table').innerHTML;
    const originalContents = document.body.innerHTML;
    this.removeScrollbar();
    window.print();
    this.restoreScrollbar();

    // Restore hidden elements after printing
    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = '';
      }
    });
    // Remove the added style for hiding headers and footers
    document.head.removeChild(style);
  }
  private removeScrollbar() {
    document.body.style.overflow = 'hidden';
  }

  private restoreScrollbar() {
    document.body.style.overflow = 'auto';
  }
  public handlePage(e: any) {
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.itemList.length > 0) {
      this.partyPageSize = e.pageSize;
      console.log("---------", this.partyPageSize)
      this.getProductReport();
    }
  }
}
