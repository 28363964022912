import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ScheduleShopService } from 'src/app/core/services/schedule-shop.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Location, TitleCasePipe } from '@angular/common'
@Component({
  selector: 'app-schedule-shop-form-page',
  templateUrl: './schedule-shop-form-page.component.html',
  styleUrls: ['./schedule-shop-form-page.component.scss']
})
export class ScheduleShopFormPageComponent implements OnInit {
  public isFillBreakSlots: boolean = false;
  public isConfirmBtn:boolean = false;
  public submitted : boolean=false;
  public slots = {
    "0": {
      "holiday": false,
      "breakStart": "13:00",
      "breakEnd": "14:00",
      "shopStartTime": "09:00",
      "shopEndTime": "17:00",
      "bufferTime": 10,
      "slotDuration": 120,
      "capacity": 1
    },
    "1": {
      "holiday": false,
      "breakStart": "13:00",
      "breakEnd": "14:00",
      "shopStartTime": "09:00",
      "shopEndTime": "17:00",
      "bufferTime": 10,
      "slotDuration": 60,
      "capacity": 1
    },
    "2": {
      "holiday": false
    },
    "3": {
      "holiday": false
    },
    "4": {
      "holiday": false
    },
    "5": {
      "holiday": false
    },
    "6": {
      "holiday": false
    }
  }
  appointMentType = [
    {
      "id": 1,
      "appointMent": "Routine Visit"
    },
    {
      "id": 2,
      "appointMent": "Virtual appt	"
    },
    {
      "id": 3,
      "appointMent": "One To One"
    },
  ]

  public scheduleForm: FormGroup;
  public shopId;
  public appointmentData;
  public slotTiming;
  public dayWiseSlots ;
//   {
//         "MON": {
//             "dayDetails": {
//                 "holiday": false,
//                 "breakStart": "13:00",
//                 "breakEnd": "14:00",
//                 "shopStartTime": "09:00",
//                 "shopEndTime": "18:00",
//                 "bufferTime": 0,
//                 "slotDuration": 60,
//                 "capacity": 1,
//                 "slotNo": 1,
//                 "day": "MON"
//             },
//             "slotDetails": [
//                 {
//                     "startSlot": "09:00",
//                     "endSlot": "10:00",
//                     "slotNo": 1,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "10:00",
//                     "endSlot": "11:00",
//                     "slotNo": 2,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "11:00",
//                     "endSlot": "12:00",
//                     "slotNo": 3,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "12:00",
//                     "endSlot": "13:00",
//                     "slotNo": 4,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "13:00",
//                     "endSlot": "14:00",
//                     "slotNo": 5,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "14:00",
//                     "endSlot": "15:00",
//                     "slotNo": 6,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "15:00",
//                     "endSlot": "16:00",
//                     "slotNo": 7,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "16:00",
//                     "endSlot": "17:00",
//                     "slotNo": 8,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "17:00",
//                     "endSlot": "18:00",
//                     "slotNo": 9,
//                     "capacity": 1
//                 }
//             ]
//         },
//         "TUE": {
//             "dayDetails": {
//                 "holiday": false,
//                 "breakStart": "13:00",
//                 "breakEnd": "14:00",
//                 "shopStartTime": "09:00",
//                 "shopEndTime": "17:00",
//                 "bufferTime": 0,
//                 "slotDuration": 60,
//                 "capacity": 1,
//                 "slotNo": 1,
//                 "day": "TUE"
//             },
//             "slotDetails": [
//                 {
//                     "startSlot": "09:00",
//                     "endSlot": "10:00",
//                     "slotNo": 1,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "10:00",
//                     "endSlot": "11:00",
//                     "slotNo": 2,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "11:00",
//                     "endSlot": "12:00",
//                     "slotNo": 3,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "12:00",
//                     "endSlot": "13:00",
//                     "slotNo": 4,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "13:00",
//                     "endSlot": "14:00",
//                     "slotNo": 5,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "14:00",
//                     "endSlot": "15:00",
//                     "slotNo": 6,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "15:00",
//                     "endSlot": "16:00",
//                     "slotNo": 7,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "16:00",
//                     "endSlot": "17:00",
//                     "slotNo": 8,
//                     "capacity": 1
//                 }
//             ]
//         },
//         "WED": {
//             "dayDetails": {
//                 "holiday": false,
//                 "breakStart": "13:00",
//                 "breakEnd": "14:00",
//                 "shopStartTime": "09:00",
//                 "shopEndTime": "18:00",
//                 "bufferTime": 0,
//                 "slotDuration": 60,
//                 "capacity": 1,
//                 "slotNo": 1,
//                 "day": "WED"
//             },
//             "slotDetails": [
//                 {
//                     "startSlot": "09:00",
//                     "endSlot": "10:00",
//                     "slotNo": 1,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "10:00",
//                     "endSlot": "11:00",
//                     "slotNo": 2,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "11:00",
//                     "endSlot": "12:00",
//                     "slotNo": 3,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "12:00",
//                     "endSlot": "13:00",
//                     "slotNo": 4,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "13:00",
//                     "endSlot": "14:00",
//                     "slotNo": 5,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "14:00",
//                     "endSlot": "15:00",
//                     "slotNo": 6,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "15:00",
//                     "endSlot": "16:00",
//                     "slotNo": 7,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "16:00",
//                     "endSlot": "17:00",
//                     "slotNo": 8,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "17:00",
//                     "endSlot": "18:00",
//                     "slotNo": 9,
//                     "capacity": 1
//                 }
//             ]
//         },
//         "THU": {
//             "dayDetails": {
//                 "holiday": false,
//                 "breakStart": "13:00",
//                 "breakEnd": "14:00",
//                 "shopStartTime": "09:00",
//                 "shopEndTime": "18:00",
//                 "bufferTime": 0,
//                 "slotDuration": 60,
//                 "capacity": 1,
//                 "slotNo": 1,
//                 "day": "THU"
//             },
//             "slotDetails": [
//                 {
//                     "startSlot": "09:00",
//                     "endSlot": "10:00",
//                     "slotNo": 1,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "10:00",
//                     "endSlot": "11:00",
//                     "slotNo": 2,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "11:00",
//                     "endSlot": "12:00",
//                     "slotNo": 3,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "12:00",
//                     "endSlot": "13:00",
//                     "slotNo": 4,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "13:00",
//                     "endSlot": "14:00",
//                     "slotNo": 5,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "14:00",
//                     "endSlot": "15:00",
//                     "slotNo": 6,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "15:00",
//                     "endSlot": "16:00",
//                     "slotNo": 7,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "16:00",
//                     "endSlot": "17:00",
//                     "slotNo": 8,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "17:00",
//                     "endSlot": "18:00",
//                     "slotNo": 9,
//                     "capacity": 1
//                 }
//             ]
//         },
//         "FRI": {
//             "dayDetails": {
//                 "holiday": false,
//                 "breakStart": "13:00",
//                 "breakEnd": "14:00",
//                 "shopStartTime": "09:00",
//                 "shopEndTime": "18:00",
//                 "bufferTime": 0,
//                 "slotDuration": 60,
//                 "capacity": 1,
//                 "slotNo": 1,
//                 "day": "FRI"
//             },
//             "slotDetails": [
//                 {
//                     "startSlot": "09:00",
//                     "endSlot": "10:00",
//                     "slotNo": 1,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "10:00",
//                     "endSlot": "11:00",
//                     "slotNo": 2,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "11:00",
//                     "endSlot": "12:00",
//                     "slotNo": 3,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "12:00",
//                     "endSlot": "13:00",
//                     "slotNo": 4,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "13:00",
//                     "endSlot": "14:00",
//                     "slotNo": 5,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "14:00",
//                     "endSlot": "15:00",
//                     "slotNo": 6,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "15:00",
//                     "endSlot": "16:00",
//                     "slotNo": 7,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "16:00",
//                     "endSlot": "17:00",
//                     "slotNo": 8,
//                     "capacity": 1
//                 },
//                 {
//                     "startSlot": "17:00",
//                     "endSlot": "18:00",
//                     "slotNo": 9,
//                     "capacity": 1
//                 }
//             ]
//         },
//         "SAT": {
//             "dayDetails": {
//                 "holiday": true,
//                 "day": "SAT"
//             }
//         },
//         "SUN": {
//             "dayDetails": {
//                 "holiday": true,
//                 "day": "SUN"
//             }
//         }
// }
  public days = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"]
  dayDetailsData =
    {
      "MON":
      {
        "dayDetails": {
          "holiday": false,
          "breakStart": "13:00",
          "breakEnd": "14:00",
          "shopStartTime": "09:00",
          "shopEndTime": "17:00",
          "bufferTime": 0,
          "slotDuration": 120,
          "capacity": 1,
          "slotNo": 1,
          "day": "MON"
        },
        "slotDetails": [
          {
            "startSlot": "09:00",
            "endSlot": "11:00",
            "slotNo": 1,
            "capacity": 1
          },
          {
            "startSlot": "11:00",
            "endSlot": "13:00",
            "slotNo": 2,
            "capacity": 1
          },
          {
            "startSlot": "14:00",
            "endSlot": "16:00",
            "slotNo": 3,
            "capacity": 1
          }
        ]
      },
    }

  constructor(private formBuilder: FormBuilder,
    private scheduleShopService: ScheduleShopService,
    private route: ActivatedRoute,
    private toastService: ToastNotificationService,
    private router: Router,
    private location: Location,private titleCasePipe: TitleCasePipe) { }
  ngOnInit(): void {
    this.scheduleForm = this.formBuilder.group({
      appointmentType: ["", [Validators.required]],
      start_time: ['',[Validators.required]],
      break_start_time: ['',[Validators.required]],
      break_end_time: ['',[Validators.required]],
      end_time: ['',[Validators.required]],
      slotDuration: ['',[Validators.required]],
      capacity: [,[Validators.required]],
      bufferTime: [, [Validators.required]],
    });
    this.route.paramMap.subscribe((p) => {
      this.shopId = p.get("id");
      console.log(this.shopId);
      if (this.shopId != null) {
        this.getAppointmentType()
      }
    });
    // this.getTimeSlots();
  }
  getAppointmentType() {
    // this.scheduleShopService.getAppointmentType({}, this.shopId).then((res: any) => {
    this.scheduleShopService.getAppointmentType({}, 'acc6fc65-a47e-44d3-8523-72c2a9a1c9ee').then((res: any) => {
      console.log(res);
      this.appointmentData = res.data.pageData;
    })
  }
  postTimeSlots() {
    this.submitted =true;
    if (this.scheduleForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
        });
      // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!")
      return false;
    }
    else{
    this.slotTiming = {
      "breakStart": this.scheduleForm.controls.break_start_time.value,
      "breakEnd": this.scheduleForm.controls.break_end_time.value,
      "shopStartTime": this.scheduleForm.controls.start_time.value,
      "shopEndTime": this.scheduleForm.controls.end_time.value,
      "bufferTime": this.scheduleForm.controls.bufferTime.value,
      "slotDuration": this.scheduleForm.controls.slotDuration.value,
      "capacity": this.scheduleForm.controls.capacity.value,
      "match": false,
      "sameAs":0
    };
    let b = ["0", "1", "2", "3", "4", "5", "6"];
    b.forEach(i => {
      this.slots[i] = {
        ...this.slots[i],
        ...this.slotTiming
      }
    });
    console.log(this.slots);
    console.log(this.slotTiming)
    let data = {
      "shopId": this.shopId,
      "fillBreakSlots": this.isFillBreakSlots,
      "slots": this.slots
    }
    this.scheduleShopService.getTimeSlots(data).then((res: any) => {
      console.log(res);
      this.dayWiseSlots = res.data;
      this.isConfirmBtn = true;
      // this.scheduleForm.reset();
      // this.location.back()
    })
  }
  }
  postCreateSchedule() {
 
      let data = {
        "shopId": this.shopId,
        "appointmentTypeId" : this.scheduleForm.controls.appointmentType.value,
        "scheduleName": "S1",
        "scheduleDesc" : "Basic",
        "data": this.dayWiseSlots
      }
      this.scheduleShopService.postCreateSchedule(data).then((res) => {
        // this.toastService.openSnackBar("Slot Created Successfully!!!")
        this.toastService.toastMsg({
          title: "Success",
          content: "Slot Created Successfully!!!",
          })
        this.router.navigate(["/pages//shop-setting/"+this.shopId +"/shop-schedule/" + this.shopId])
      },(err)=>{
        if(err.error.expose){
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else{
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
            });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      }
      )
    
  
  }
  toggleBreakSlots(event: boolean) {
    this.isFillBreakSlots = event;
  }

  getHoliday(day) {
    console.log(day);
    this.slots[day].holiday = !this.slots[day].holiday
    console.log(this.slots);
  }
  get f() {
    return this.scheduleForm.controls;
  }
}
