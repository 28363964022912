import { Component, Inject, OnInit } from "@angular/core";
import lang from "src/assets/langTranslation/language_translation"
import { FormControl, FormBuilder, Validators, FormArray, FormGroup } from "@angular/forms";
import { PartyService } from "src/app/core/services/party.service";
import { PurchaseInvoiceService } from "src/app/core/services/purchase-invoice.service";
import { Observable } from "rxjs";

import { map, startWith } from "rxjs/operators";
import { StateConstants } from "src/app/_helpers/state-constans";
import { Router } from "@angular/router";
import { CommonService } from "src/app/core/services/common.service";
import { ToastNotificationService } from "src/app/core/services/toast-notification.service";
import { SaleInvoiceService } from "src/app/core/services/sale-invoice.service";
import { Constants } from "src/app/_helpers/constant";
import { TitleCasePipe } from "@angular/common";
import { ProfileService } from "src/app/core/services/profile.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BiddingService } from "src/app/core/services/bidding.service";
import { WarehouseService } from "src/app/core/services/warehouse.service";


@Component({
  selector: "app-purchase-invoice",
  templateUrl: "./purchase-invoice.component.html",
  styleUrls: ["./purchase-invoice.component.scss"],
})
export class PurchaseInvoiceComponent implements OnInit {
  // serialNumber: number = 1;

  public lang = lang.UAE;
  public gstAmount = 0;
  public gstRate = 0;
  public formControl = new FormControl(new Date());
  public ngModelDate = new Date();
  public isShippingAddress: boolean = false;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public partyData: any;
  public allPartyList: any = [];
  public partyId: any;
  public showInvoiceGenerateData: boolean = false;
  public gstType: any;
  public gstInRs: any;
  public gstInPer: any;
  public compositeType: any;
  public PurchaseInvoiceForm: any;
  public selectedClient: any;
  public productData: any = [];
  public sendArray: any = [];
  public taxRate: any;
  public allStates = StateConstants.states;
  public subTotal = 0;
  public totalGST = 0;
  public additionalCess = 0;
  public total = 0;
  public todaysDate;
  public today: any;
  public partyName = new FormControl();
  public invoiceNo;
  public lastInvoiceNumber;
  public mask = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  public filteredOptions: Observable<string[]>;
  public barcode = "";
  public submitted: boolean = false;
  public isShown: boolean = false;
  public currentPageNo: number = 0;
  public partyPagesize: number = 10;
  public purchaseGst: any;
  public userProfileGSTNo: any;
  public gstStateMatch: boolean = true;
  public supplytype: any;
  public myControl = new FormControl();
  public totalPrice: any;
  isOn = false;
  public isArabic: boolean;
  filteredOptions2: any;
  searchedProduct: any;
  myControl1 = new FormControl();
  filteredOptions3: Observable<any>;
  public warehouseList: any[];


  constructor(
    private purchaseInvoiceService: PurchaseInvoiceService, public activeModal: NgbActiveModal, @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PurchaseInvoiceComponent>,
    private formBuilder: FormBuilder,
    private partyService: PartyService,
    private router: Router,
    private toastService: ToastNotificationService,
    private commonService: CommonService,
    private toast: ToastNotificationService,
    private saleInvoiceService: SaleInvoiceService,
    private titleCasePipe: TitleCasePipe,
    private profileService: ProfileService,
    private biddingService: BiddingService, private warehouseService: WarehouseService,
  ) { }

  ngOnInit(): void {
    // Subscribe to the observable to receive updates
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });
    this.getAllParties();
    // this.getSaleInvoiceNumber();
    this.getProfileData();
    this.getWarehouseList();

    this.todaysDate = new Date();
    this.today = new Date();
    // Purchase invoice form
    this.PurchaseInvoiceForm = this.formBuilder.group({
      credit: ["", [Validators.required]],
      partyName: ["", [Validators.required]],
      reverseCharge: [false],
      billNo: ["", [Validators.required]],
      billDate: [""],
      barcode: [""],
      shippedFrom: [null,],
      eWayBillNo: [null],
      unit: [""],
      price: [""],
      gstRate: [""],
      billingAddress: ["", [Validators.required]],
      shippingAddress: [null],
      quantity: [""],
      additionalCess: [""],
      invoiceDate: ["", [Validators.required]],
      // invoiceNo: ["", [Validators.required]],

      invoiceNo: ["", [Validators.required]],
      warehouse: [null],
    });
    let d = Constants.bankNames;
  }

  getProfileData() {
    this.profileService.getUserProfile({}, this.currentUser.id).then((res) => {
      this.userProfileGSTNo = res.data.additional.gstinNo
        ? res.data.additional.gstinNo.slice(0, 2)
        : "";
    });
  }
  
  onSearchInputChange(searchKey: string) {
    if (searchKey.length >= 3) {
      this.getGoodsList(searchKey);
    }
  }

  /**
   * Filter customer name search
   * @param name
   * @returns
   */
  private filterNames(name: string): string[] {
    return this.allPartyList.filter(
      (partyList) =>
        partyList.partyName.toLowerCase().indexOf(name.toLowerCase()) === 0
    );
  }

  /**
   * toggle to show shipping address
   * @param checked
   */
  showShippingAddress(checked: boolean) {
    this.isShippingAddress = checked;
  }
  /**
   * toggle to hide shipping address
   * @param checked
   */
  hideShippingAddress(checked: boolean) {
    if (checked) {
      this.isShippingAddress = false;
    }
  }

  /**
   * get all party
   */
  getAllParties() {
    this.partyService
      .getAllPartiesDetails(
        {},
        this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
        this.partyPagesize,
        this.currentPageNo + 1
      )
      .then((res) => {
        this.allPartyList = res.data.pageData;
        this.filteredOptions = this.partyName.valueChanges.pipe(
          startWith(""),
          map((name) =>
            name ? this.filterNames(name) : this.allPartyList.slice()
          )
        );
      });
  }

  /**
   * get party by name
   * @param item
   */
  searchPartyByName(item) {
    this.saleInvoiceService
      .getPartyByName({},this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, item)
      .then((response: any) => {
        this.partyData = response.data.rows;
        this.allPartyList = response.data.rows;
        if (this.allPartyList) {
          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(""),
            map((name) =>
              name ? this.filterNames(name) : this.allPartyList.slice()
            )
          );
        }
      });
  }
  setSelectedPartyDeatails(item) {
    this.partyId = item.id;
    if (this.partyData) {
      this.partyData.forEach((ele) => {
        this.purchaseGst = ele.gstIn;
        this.PurchaseInvoiceForm.patchValue({
          billingAddress: ele.billingAddress,
        });
        if (this.purchaseGst.slice(0, 2) == this.userProfileGSTNo) {
          this.gstStateMatch = true;
          this.supplytype = "INTRASTATE";
        } else {
          this.gstStateMatch = false;
          this.supplytype = "INTERSTATE";
        }
      });
    } else {
      this.saleInvoiceService
        .getPartyByName({}, this.currentUser.id, item.partyName)
        .then((response: any) => {
          this.partyData = response.data.rows;
          this.allPartyList = response.data.rows;
          this.allPartyList.forEach((ele) => {
            this.purchaseGst = ele.gstIn;
            this.PurchaseInvoiceForm.patchValue({
              billingAddress: ele.billingAddress,
            });
            if (this.purchaseGst.slice(0, 2) == this.userProfileGSTNo) {
              this.gstStateMatch = true;
              this.supplytype = "INTRASTATE";
            } else {
              this.gstStateMatch = false;
              this.supplytype = "INTERSTATE";
            }
          });
        });
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(""),
        map((name) =>
          name ? this.filterNames(name) : this.allPartyList.slice()
        )
      );
    }
  }

  /**
   * calculation total for product
   */
  async calculationTotal() {
    this.barcode = "";
    let price = 0;

    let additionalCess = 0;
    let gstInPer = 0;
    this.totalPrice = 0;
    this.subTotal = 0;
    this.gstRate = 0;
    this.productData.forEach((element) => {
      let gstInPer = element.gstRate;
      // price = price + element.salePrice * element.quantity;
      // this.gstRate =
      //   (element.salePrice - element.salePrice / (1 + gstInPer / 100)) *
      //     element.quantity +
      //   this.gstRate;
      // this.gstAmount =
      //   (element.salePrice - element.salePrice / (1 + gstInPer / 100)) *
      //   element.quantity;
      additionalCess = (element.salePrice * element.additionalCess) / 100;

      // this.subTotal = element.basePrice * element.quantity + this.subTotal;

      // new calculation code for purchase invoice
      element.basePrice = element.purchasePrice / (1 + element.gstRate / 100);
      element.subTotal = element.basePrice * element.quantity;
      element.gstAmount =
        (element.purchasePrice - element.basePrice) * element.quantity;
      this.gstRate = element.gstAmount + this.gstRate;
      // element.total = element.salePrice * element.quantity;
      // total for each row in the array
      element.total = element.purchasePrice * element.quantity;
      // total for all products in the array
      price = element.total + price;
      // total without gst for all products in the array
      this.subTotal = element.subTotal + this.subTotal;

      gstInPer = element.gstRate;
      if (this.isShown) {
        this.totalPrice = price;
      } else {
        this.totalPrice = price;
      }
    });

    if (this.gstType === "Exempted") {
      this.totalGST = 0;
      this.gstInRs = 0;
      this.gstInPer = 0;
    } else if (this.gstType === "Composite") {
      if (
        this.compositeType == "Manufacturer" ||
        this.compositeType == "Trader"
      ) {
        this.totalGST = (this.subTotal * 1) / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      } else if (this.compositeType == "Restaurant") {
        this.totalGST = (this.subTotal * 5) / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      } else {
        this.totalGST = (this.subTotal * 6) / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      }
    } else {
      this.totalGST = this.gstRate;
      this.gstInRs = this.gstRate;
      this.gstInPer = gstInPer;
    }

    this.additionalCess = additionalCess;
    if (this.isShown) {
      this.total = price;
    } else {
      this.total = price;
    }
  }

  // getSaleInvoiceNumber() {
  //   this.saleInvoiceService
  //     .getSaleInvoiceNumber({}, this.currentUser.id)
  //     .then((res) => {
  //       this.PurchaseInvoiceForm.patchValue({
  //         invoiceNo: res.data.newInvoiceNumber,
  //       });
  //       this.invoiceNo = res.data.newInvoiceNumber;
  //       this.lastInvoiceNumber = res.data.lastInvoiceNumber;
  //     });
  // }
  /**
   * get goods by barcode
   */

  /**
   * calculation for quantity change
   * @param i
   */
  changeQtyCalculation(i) {
    this.totalGST =
      this.productData[i].salePrice *
      this.productData[i].quantity *
      (this.productData[i].gstRate / 100);
    this.subTotal =
      this.productData[i].salePrice * this.productData[i].quantity;
    this.total = this.totalGST + this.subTotal;
    this.calculationTotal();
  }
  /**
   * quantity change method
   * @param sign
   * @param i
   */

  changeQuantity(sign, i, quantity = 0) {
    switch (sign) {
      case "-":
        if (this.productData[i].quantity > 1) {
          this.productData[i].quantity = this.productData[i].quantity - 1;
        }
        // this.changeQtyCalculation(i);
        this.calculationTotal();
        break;
      case "+":
        this.productData[i].quantity = this.productData[i].quantity + 1;
        // this.changeQtyCalculation(i);
        this.calculationTotal();
        break;
      case "rm":
        this.productData.splice(i, 1);
        if (this.productData.length <= 0) {
          this.totalGST = 0;
          this.subTotal = 0;
          this.total = 0;
        }
        this.calculationTotal();
        break;
      case "qty":
        this.productData[i].quantity = quantity;
        this.calculationTotal();
        break;
      default:
        this.productData[i];
        break;
    }
  }
  getGoodsByBarcode() {
    this.saleInvoiceService
      .getGoodsByBarcode({}, this.barcode, this.currentUser.id)
      .then((response) => {
        // Check if the barcode already exists in productData
        const existingProductIndex = this.productData.findIndex(
          (product) => product.barcode === response.data.barcode
        );

        if (existingProductIndex !== -1) {
          // Barcode exists, update quantity
          this.productData[existingProductIndex].quantity += 1;
        } else {
          // Barcode doesn't exist, add new product
          response.data.quantity = 1;
          this.productData.push(response.data);
        }
        this.calculationTotal();
      });
  }


  getGoodsList(value) {
    if (value && (String(value).trim() !== '' || value !== null)) {
      this.biddingService.getAllProductsByUserSearch({}, this.PurchaseInvoiceForm.value.warehouse ? this.PurchaseInvoiceForm.value.warehouse : this.currentUser.id, value, 10, 1, this.PurchaseInvoiceForm.value.warehouse ? this.PurchaseInvoiceForm.value.warehouse : this.currentUser.id)
        .then((res) => {
          this.searchedProduct = res.data.pageData;
          this.filteredOptions3 = this.myControl1.valueChanges
            .pipe(
              startWith(''),
              map(name => name ? this.filterNames(name) : this.searchedProduct.slice())
            );
        },
          (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
            }

          })
    } else {

    }
  }

 
  getGoodsByBarcodeSelected(barcode, event) {
    if (event.isUserInput) {
      this.saleInvoiceService
        .getGoodsByBarcode({}, barcode, this.PurchaseInvoiceForm.value.warehouse ? this.PurchaseInvoiceForm.value.warehouse : this.currentUser.id)
        .then(async (response) => {
          response.data.quantity = 1;
          // Check if the barcode already exists in productData
          const existingProductIndex = this.productData.findIndex(
            (product) => product.barcode === response.data.barcode
            );

          if (existingProductIndex !== -1) {
            // Barcode exists, update quantity
            this.productData[existingProductIndex].quantity += 1;
            // await this.checkFreeProducts(this.productData)
            // await this.checkOffer(this.productData[existingProductIndex].quantity, this.productData[existingProductIndex].id)
            // this.removeCoupon()
          } else {
            // Barcode doesn't exist, add new product
            response.data.quantity = 1;
            this.productData.push(response.data);
            this.addDetailsButtonClick(this.productData.length - 1);
            // await this.checkFreeProducts(this.productData)
            // await this.checkOffer(response.data.quantity, response.data.id)
            // this.removeCoupon()
          }

          this.calculationTotal();
          this.myControl1.patchValue('')
          document.getElementById('inp12').nodeValue = ''
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        });
    }
  }
  addDetailsButtonClick(i): void {
    const content = this.PurchaseInvoiceForm.get("subcheckbox") as FormArray;
    content.push(this.addRolesPermission(i));
  }

  addRolesPermission(id): FormGroup {
    return new FormGroup({
      read: new FormControl(false),
    });
  }
 /**
   * craete purchase invoice
   */
  postPurchaseInvoice() {
    this.submitted = true;
    console.log("this.PurchaseInvoiceForm", this.PurchaseInvoiceForm)
    if (this.PurchaseInvoiceForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    } else {
      if (this.productData.length < 1) {
        // this.toastService.openErrorSnackBar(
        //   "Please Add At Least One Product To Proceed!!!"
        // );
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Add At Least One Product To Proceed!!!",
        });
      }
      this.productData.forEach((e) => {
        let data = {
          quantity: e.quantity,
          barcode: e.barcode,
          amount: e.purchasePrice ? e.purchasePrice : null,
          gstRate: e.gstRate,
          gstAmount: e.gstAmount,
          purchaseBasePrice: e.basePrice,
        };
        this.sendArray.push(data);
      });
      const userType = this.currentUser.userType.toLowerCase();
      const warehouseIdToSend =
        userType === 'warehouse' ? this.currentUser.id :
        userType === 'shop' ? (this.PurchaseInvoiceForm.value.warehouse || this.currentUser.id) :
        userType === 'client' && this.PurchaseInvoiceForm.value.warehouse ? this.PurchaseInvoiceForm.value.warehouse : this.currentUser.id;
      let data = {
        invoiceDetails: {
          invoiceDate: this.todaysDate,
          invoiceNo:
            this.PurchaseInvoiceForm.controls.invoiceNo.value.toString(),
          shippedFrom: this.PurchaseInvoiceForm.controls.shippedFrom.value,
          ewayBillNo: this.PurchaseInvoiceForm.controls.eWayBillNo.value
            ? this.PurchaseInvoiceForm.controls.eWayBillNo.value
            : null,
          billingAddress:
            this.PurchaseInvoiceForm.controls.billingAddress.value,
          shippingAddress: this.isShippingAddress
            ? this.PurchaseInvoiceForm.controls.shippingAddress.value
            : this.PurchaseInvoiceForm.controls.billingAddress.value,
          credit: this.PurchaseInvoiceForm.controls.credit.value,
          userId: this.PurchaseInvoiceForm.value.warehouse ? this.PurchaseInvoiceForm.value.warehouse : this.currentUser.id,
          partyId: this.partyId,
          reverseCharge: this.PurchaseInvoiceForm.controls.reverseCharge.value,
          supplyType: this.supplytype,
          warehouseId: `${warehouseIdToSend}`
        },
        products: this.sendArray,
      };
      this.purchaseInvoiceService.postPurchaseInvoice(data).then(
        (res) => {
          if (res.success === true) {
            if (res) {
              this.sendArray = [];
              this.toastService.toastMsg({
                title: "Success",
                content: "Purchase Invoice Created Successfully!!!",
              });
              // this.toast.openSnackBar("Purchase Invoice Created Successfully!!!");
            }
            // this.router.navigate(["/pages/purchase-page/purchase-invoice-list"]);
            this.partyService.notifyPartyAdded();
            this.dialogRef.close();
          }
        },
        (err) => {
          this.sendArray = [];
          if (err.error.expose) {
            let errorMessage = err.error.error_message;
            if (errorMessage.includes('invoiceDetails.partyId')) {
                errorMessage = "Vendor Name  is required. Please select a valid Vendor Name option.";
            } else {
                errorMessage = this.titleCasePipe.transform(errorMessage);
            }
            this.toastService.toastMsg({
                title: "Error",
                content: errorMessage,
            });
          } else {
             this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        }
      );
    }
  }
  toggleShow() {
    this.isOn = !this.isOn;
    this.isShown = !this.isShown;
    if (this.isShown) {
      this.total = this.subTotal + this.totalGST + this.additionalCess;
    } else {
      this.total = this.subTotal + this.totalGST;
    }
  }
  getCredit(e) {
    if (e.value == "true") {
      e.value = true;
    } else {
      e.value = false;
    }
    this.PurchaseInvoiceForm.controls.credit.value = e.value;
  }
  get f() {
    return this.PurchaseInvoiceForm.controls;
  }
  CancelPurchase() {
    this.PurchaseInvoiceForm.reset();
    this.productData = [];
    this.subTotal = 0;
    this.gstRate = 0;
    this.additionalCess = 0;
    this.total = 0;
    this.PurchaseInvoiceForm.reset();
    this.submitted = false;
    this.dialogRef.close();

  }

  setPurchasePrice(value, index) {
    console.log("Purchaseeeeee", value);
    this.productData[index].purchasePrice = value;
    console.log(
      " this.productData[index].purchasePrice ",
      this.productData[index].purchasePrice
    );
    this.calculationTotal();
  }

  setGST(value, index) {
    console.log("GST VALUE", value);
    this.productData[index].gstRate = value;
    console.log(
      " this.productData[index].purchasePrice ",
      this.productData[index].gstRate
    );
    this.calculationTotal();
  }

  openDatepicker(){
    // Check if the current value of invoiceDate is an "Invalid Date"
    const selectedDate = this.PurchaseInvoiceForm.get('invoiceDate').value;
    if (isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.PurchaseInvoiceForm.get('invoiceDate').setValue(new Date());
    }
  }
  getWarehouseList() {
    this.warehouseService.getAllWarehouse({
      data:{},
      userId: this.currentUser.userType !== 'CLIENT' ? this.currentUser.parentId : this.currentUser.id,
      size: 1000,
      page: 1,
      search:'',
    }).then((res: any) => {
      this.warehouseList = res.data.pageData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      };
    })
  }
   // Function to handle selection change in warehouse selection dropdown
   onWarehouseSelectionChange(event: any) {
    if (event.value === 'clear') {
      this.PurchaseInvoiceForm.get('warehouse').setValue(null); // Clear the selection
    }
  }

}
