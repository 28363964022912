<div class="container-fluid p-0">

  <!-- <div class="row d-flex flex-center">
      <div class="col-xs-10 col-sm-10 col-md-8 col-lg-5 col-xl-3"></div>
      <div class="col-md-4 col-lg-1 col-xl-4"></div>
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3" style = "padding-left: 3rem;">
        <input type="search" id="searchInput" class="form-control pe-5 mb-2" placeholder="Sales Invoice"
        [(ngModel)]="searchKey" (change)="getAllsalereturnbySearch(searchKey)">
      </div>

      <div class="navOption-btns col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-2 mx-0 px-0 mb-2" style = "display:flex;flex-direction:row;justify-content:space-around !important">
        <button class="btn" type="button" (click)="openDialog()"><i class="fas fa-plus me-2"> </i>
          Sale-Return
        </button>

        <button class="btn" (click)="downlaodAllData()">
            <i class="fas fa-download"></i>
        </button>
      </div>
    </div> -->

  <div class="row headerButtons mb-3">
    <div class="flex-item searchBar">
      <input type="search" id="searchInput" class="form-control pe-5 my-2" placeholder="Sales Invoice"
        [(ngModel)]="searchKey" (change)="getAllsalereturnbySearch(searchKey)">
    </div>
    <div class="navOption-btns flex-item formButtons mx-0">
      <button class="btn text-nowrap my-2" type="button" (click)="openDialog()"><i class="fas fa-plus me-2"> </i>
        Sale-Return
      </button>

      <button *ngIf="this.excelbutton == false " class="btn my-2" (click)="downlaodAllData()">
        <i class="fas fa-download"></i>
      </button>
      <button *ngIf="this.excelbutton == true " class="btn my-2" (click)="downlaodDatasearch()">
        <i class="fas fa-download"></i>
      </button>
    </div>
  </div>

  <!-- <hr class="my-0 py-0" /> -->
  <div style="width:100% ; overflow-x: auto;">
    <table class="table table-responsive table-bordered fs--1 mb-3" *ngIf="!isArabic">
      <thead class="text-600">
        <tr>
          <!-- <th scope="col">
                        <input type="checkbox" />
                    </th> -->
          <th class="text-nowrap" style="vertical-align:middle;">Date</th>
          <th class="text-nowrap" style="vertical-align:middle;">Vendor Name</th>
          <th class="text-nowrap" style="vertical-align:middle;">Sales Return #</th>
          <th class="text-nowrap" style="vertical-align:middle;">Against Sales Invoice</th>
          <th class="text-nowrap" style="vertical-align:middle;">Full/Partial</th>
          <th class="text-nowrap" style="vertical-align:middle;text-align: right;">Amount-Excluding GST (In Rs.)</th>
          <th class="text-nowrap" style="vertical-align:middle;text-align: right;">Amount-Including GST (In Rs.)</th>
          <th class="text-nowrap" style="vertical-align:middle;width:auto;"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let sale of saleListRes; let i = index" style="color: #676398!important;"
          [ngStyle]="{'background-color': (i % 2 === 0 ? '#f2f2f2' : '#fff')}">
          <!-- <td scope="row" style="vertical-align:middle;">
                    <input type="checkbox" />
                </td> -->
          <td class="text-nowrap" style="vertical-align:middle;" class="text-nowrap">{{sale.returnDate | date:"dd MMM
            YYYY"| uppercase}}</td>
          <td class="text-nowrap" style="vertical-align:middle;">{{sale['party.partyName']}}</td>
          <td class="text-nowrap" style="vertical-align:middle;">{{sale.saleReturnNumber}}</td>
          <td class="text-nowrap" style="vertical-align:middle;">{{sale.invoiceNo }}</td>
          <td class="text-nowrap" style="vertical-align:middle;">{{sale.isOrderReturn}}</td>
          <td class="text-nowrap" style="vertical-align:middle;text-align: right;">
            ₹&nbsp;{{sale['sales_products.amountExcludingGst'] | number:'1.2-2'}}
            <!-- ₹&nbsp;{{ sale['sales_products.amountExcludingGst'] | number:'1.2-2'}} -->
          </td>
          <td class="text-nowrap" style="vertical-align:middle;text-align: right;">
            ₹&nbsp;{{sale['sales_products.amountIncludingGst'] | number:'1.2-2'}}
            <!-- ₹&nbsp;{{ sale['sales_products.amountIncludingGst'] | number:'1.2-2'}} -->
          </td>
          <td class="text-nowrap"
            style="vertical-align:middle; width:20%!important; white-space:nowrap;text-align: center;">
            <div class="">

              <button class="btn btnp">
                <span class="bi bi-eye iconFontSize" data-bs-toggle="tooltip" data-bs-placement="top"
                  data-bs-toggle="modal" data-bs-target="#sale-return" (click)="getReceiptById(sale.id)"
                  title="View"></span>
              </button>
              <!-- <span class="m-1" text-center></span>
                            <button class="btn btnp me-2" type="button">
                                <span class="bi bi-eye icon-color fs-2" data-bs-toggle="tooltip"
                                   data-bs-placement="top" title="View"></span>
                           </button> -->
              <!-- <span class="m-1" text-center></span>
                            <button class="btn btnp me-2">
                                <span class="bi-pencil-square icon-color fs-1" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Edit"></span>

                            </button>
                            <span class="m-1" text-center></span>
                            <button class="btn btnp me-2" data-bs-toggle="modal" data-bs-target="#errorDelete-modal">
                                <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip" data-bs-placement="top"
                                    title="Delete" style="color: red;"></span>

                            </button> -->

            </div>
        </tr>
      </tbody>

      <tbody *ngIf="saleListRes==0">
        <tr class="text-center">
          <td colspan="12">
            <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
              alt="notfound1" width="200" height="150" />

            <!-- <img class="image-responsive" src="assets/img/icons/spot-illustrations/GST No Transaction.png" width="124" height="142" alt=""> -->
            <!-- <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Sales Return List Details Added. Please Sales Return List Details To Be Displayed Here.
                    <span>
                      <a class="nav-link" (click)="openDialog()">Add New Sales Return </a>
                    </span>
                  </h5> -->
          </td>
        </tr>
        <!-- <tr class="text-center">
                    <td colspan="10">
                        <h5 class="text-nowrap">No Sales Return List Details Added. Please Sales Return List Details To Be Displayed Here. <span>
                                <a class="nav-link" (click)="openDialog()">Add New Sales Return </a>
                            </span></h5>
                    </td>
                </tr> -->
      </tbody>
    </table>

    <!-- UAE -->
    <table class="table table-responsive table-bordered fs--1 mb-3" *ngIf="isArabic">
      <thead class="text-600">
        <tr>
          <!-- <th scope="col">
                        <input type="checkbox" />
                    </th> -->
          <th class="text-nowrap" style="vertical-align:middle;">Date</th>
          <th class="text-nowrap" style="vertical-align:middle;">Vendor Name</th>
          <th class="text-nowrap" style="vertical-align:middle;">Sales Return #</th>
          <th class="text-nowrap" style="vertical-align:middle;">Against Sales Invoice</th>
          <th class="text-nowrap" style="vertical-align:middle;">Full/Partial</th>
          <th class="text-nowrap" style="vertical-align:middle;text-align: right;">Amount-Excluding {{lang.GST_In_Rs}}</th>
          <th class="text-nowrap" style="vertical-align:middle;text-align: right;">Amount-Including {{lang.GST_In_Rs}}</th>
          <th class="text-nowrap" style="vertical-align:middle;width:auto;"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let sale of saleListRes; let i = index" style="color: #676398!important;"
          [ngStyle]="{'background-color': (i % 2 === 0 ? '#f2f2f2' : '#fff')}">
          <!-- <td scope="row" style="vertical-align:middle;">
                    <input type="checkbox" />
                </td> -->
          <td class="text-nowrap" style="vertical-align:middle;" class="text-nowrap">{{sale.returnDate | date:"dd MMM
            YYYY"| uppercase}}</td>
          <td class="text-nowrap" style="vertical-align:middle;">{{sale['party.partyName']}}</td>
          <td class="text-nowrap" style="vertical-align:middle;">{{sale.saleReturnNumber}}</td>
          <td class="text-nowrap" style="vertical-align:middle;">{{sale.invoiceNo }}</td>
          <td class="text-nowrap" style="vertical-align:middle;">{{sale.isOrderReturn}}</td>
          <td class="text-nowrap" style="vertical-align:middle;text-align: right;">
            AED&nbsp;{{sale['sales_products.amountExcludingGst'] | number:'1.2-2'}}
            <!-- ₹&nbsp;{{ sale['sales_products.amountExcludingGst'] | number:'1.2-2'}} -->
          </td>
          <td class="text-nowrap" style="vertical-align:middle;text-align: right;">
            AED&nbsp;{{sale['sales_products.amountIncludingGst'] | number:'1.2-2'}}
            <!-- ₹&nbsp;{{ sale['sales_products.amountIncludingGst'] | number:'1.2-2'}} -->
          </td>
          <td class="text-nowrap"
            style="vertical-align:middle; width:20%!important; white-space:nowrap;text-align: center;">
            <div class="">

              <button class="btn btnp">
                <span class="bi bi-eye iconFontSize" data-bs-toggle="tooltip" data-bs-placement="top"
                  data-bs-toggle="modal" data-bs-target="#sale-return" (click)="getReceiptById(sale.id)"
                  title="View"></span>
              </button>
              <!-- <span class="m-1" text-center></span>
                            <button class="btn btnp me-2" type="button">
                                <span class="bi bi-eye icon-color fs-2" data-bs-toggle="tooltip"
                                   data-bs-placement="top" title="View"></span>
                           </button> -->
              <!-- <span class="m-1" text-center></span>
                            <button class="btn btnp me-2">
                                <span class="bi-pencil-square icon-color fs-1" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Edit"></span>

                            </button>
                            <span class="m-1" text-center></span>
                            <button class="btn btnp me-2" data-bs-toggle="modal" data-bs-target="#errorDelete-modal">
                                <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip" data-bs-placement="top"
                                    title="Delete" style="color: red;"></span>

                            </button> -->

            </div>
        </tr>
      </tbody>

      <tbody *ngIf="saleListRes==0">
        <tr class="text-center">
          <td colspan="12">
            <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
              alt="notfound1" width="200" height="150" />

            <!-- <img class="image-responsive" src="assets/img/icons/spot-illustrations/GST No Transaction.png" width="124" height="142" alt=""> -->
            <!-- <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Sales Return List Details Added. Please Sales Return List Details To Be Displayed Here.
                    <span>
                      <a class="nav-link" (click)="openDialog()">Add New Sales Return </a>
                    </span>
                  </h5> -->
          </td>
        </tr>
        <!-- <tr class="text-center">
                    <td colspan="10">
                        <h5 class="text-nowrap">No Sales Return List Details Added. Please Sales Return List Details To Be Displayed Here. <span>
                                <a class="nav-link" (click)="openDialog()">Add New Sales Return </a>
                            </span></h5>
                    </td>
                </tr> -->
      </tbody>
    </table>
  </div>

</div>


<div class="modal fade" #closebutton id="sale-return" tabindex="-1" role="dialog" aria-hidden="true" *ngIf="!isArabic">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content position-relative">
      <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
        <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
          data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body p-0">
        <div class="rounded-top-lg py-3 ps-4 pe-6">
          <!-- <div class="row">
                      <div class="col-4"></div>
                      <div class="col-4">
                          <h2 class=""><b>View Purchase Return</b></h2>
                      </div>
                  </div> -->
        </div>
        <div class="container">
          <div class="card">
            <div class="card-body">
              <div class="row my-2" id="panel">
                <div class="col-6">
                </div>
                <div class="col-6">
                  <div class="row headerAlignment">
                    <div class="col-6" style="font-weight: 600;">
                      TAX INVOICE
                    </div>
                    <div class="col-6" id="originalForReceiptPrint">
                      ORIGINAL FOR RECIPIENT
                    </div>
                  </div>
                  <div class="row headerAlignment">
                    <div class="col-6">
                      <span style="font-weight: 600;">Return Date:</span><span style="float: right;">:</span>
                    </div>
                    <div class="col-6" style="text-align: end;">
                      {{returnDate| date:"dd MMM YYYY"| uppercase}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row bordered table-responsive scrollbar fs--1">
                <table class="table table-striped tablePrint" style="font-size: 11px!important;">
                  <thead class="inner-model-table-heading">
                    <tr class="dark__bg-1000">
                      <th class="borderbottom2" style="font-size: 14px!important;font-weight: 600;">Sale Return</th>
                      <th class="borderLeft borderbottom2"></th>
                      <th class="borderLeft borderbottom2"></th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr>
                      <td class="align-middle">
                        <p style="font-size: 14px!important;"><span style="font-weight: 600;">Vendor
                            Name:</span>&nbsp;{{partyName ? partyName : "NA"}}</p>
                        <p style="font-size: 14px!important;"><span
                            style="font-weight: 600;">FULL/PARTIAL:</span>&nbsp;{{isOrderReturn ? isOrderReturn : "NA"}}
                        </p>
                        <p style="font-size: 14px!important;"><span
                            style="font-weight: 600;">Document:</span>&nbsp;{{invoiceNo ? invoiceNo : "NA"}}</p>
                        <p style="font-size: 14px!important;"><span style="font-weight: 600;">Against Purchase
                            Invoice:</span>&nbsp;{{saleReturnNumber ? saleReturnNumber : "NA"}}</p>
                      </td>

                      <td class="align-middle  borderLeft " style="text-align: left !important;">
                        <p style="font-size: 14px!important;"><span style="font-weight: 600;">Amount Excluding
                            GST:</span> ₹&nbsp;{{amountExcludingGst | number:'1.2-2'}}</p>
                        <p style="font-size: 14px!important;"><span style="font-weight: 600;">Amount Including
                            GST:</span> ₹&nbsp;{{amountIncludingGst | number:'1.2-2'}}</p>
                        <p style="font-size: 14px!important;"><span style="font-weight: 600;">Bill Gst Amount:</span>
                          <!-- {{billGstAmount | currency:'INR':'symbol':'1.2-2'}} -->
                          ₹&nbsp;{{ billGstAmount | number:'1.2-2'}}
                        </p>
                        <p style="font-size: 14px!important;"><span style="font-weight: 600;">Total Bill Amount:</span>
                          <!-- {{totalBillAmount | currency:'INR':'symbol':'1.2-2'}} -->
                          ₹&nbsp;{{ totalBillAmount | number:'1.2-2'}}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr style="border: 1px solid grey;" />
              <div class="row">
                <div class="col-6" style="display: flex;flex-direction: column;justify-content: flex-start;">
                  <span class="mb-1 mt-5" style="font-weight: 600;">TERMS AND CONDITIONS</span>
                  <span #dynamicText [style.fontSize.px]="getTextSizeprint(TextSizeprint)"
                    style="margin-bottom: 4px;">{{invoiceTermsCon ? invoiceTermsCon :'NA'}}</span>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12" style="display: flex;flex-direction: column;justify-content: flex-end;">
                  <div class="image-wrapper">
                    <label class="signature-img" ondragover="return false;">
                      <img *ngIf="signImg" [src]="signImg" />
                    </label>
                  </div>
                </div>
              </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- UAE -->

<div class="modal fade" #closebutton id="sale-return" tabindex="-1" role="dialog" aria-hidden="true" *ngIf="isArabic">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content position-relative">
      <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
        <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
          data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body p-0">
        <div class="rounded-top-lg py-3 ps-4 pe-6">
        </div>
        <div class="container">
          <div class="card">
            <div class="card-body">
              <div class="row my-2" id="panel">
                <div class="col-6">
                </div>
                <div class="col-6">
                  <div class="row headerAlignment">
                    <div class="col-6" style="font-weight: 600;">
                      TAX INVOICE
                    </div>
                    <div class="col-6" id="originalForReceiptPrint">
                      ORIGINAL FOR RECIPIENT
                    </div>
                  </div>
                  <div class="row headerAlignment">
                    <div class="col-6">
                      <span style="font-weight: 600;">Return Date:</span><span style="float: right;">:</span>
                    </div>
                    <div class="col-6" style="text-align: end;">
                      {{returnDate| date:"dd MMM YYYY"| uppercase}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row bordered table-responsive scrollbar fs--1">
                <table class="table table-striped tablePrint" style="font-size: 11px!important;">
                  <thead class="inner-model-table-heading">
                    <tr class="dark__bg-1000">
                      <th class="borderbottom2" style="font-size: 14px!important;font-weight: 600;">Sale Return</th>
                      <th class="borderLeft borderbottom2"></th>
                      <th class="borderLeft borderbottom2"></th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr>
                      <td class="align-middle">
                        <p style="font-size: 14px!important;"><span style="font-weight: 600;">Vendor
                            Name:</span>&nbsp;{{partyName ? partyName : "NA"}}</p>
                        <p style="font-size: 14px!important;"><span
                            style="font-weight: 600;">FULL/PARTIAL:</span>&nbsp;{{isOrderReturn ? isOrderReturn : "NA"}}
                        </p>
                        <p style="font-size: 14px!important;"><span
                            style="font-weight: 600;">Document:</span>&nbsp;{{invoiceNo ? invoiceNo : "NA"}}</p>
                        <p style="font-size: 14px!important;"><span style="font-weight: 600;">Against Purchase
                            Invoice:</span>&nbsp;{{saleReturnNumber ? saleReturnNumber : "NA"}}</p>
                      </td>

                      <td class="align-middle  borderLeft " style="text-align: left !important;">
                        <p style="font-size: 14px!important;"><span style="font-weight: 600;">Amount Excluding
                            {{lang.GST_In_Rs}}:</span>&nbsp;AED&nbsp;{{amountExcludingGst | number:'1.2-2'}}</p>
                        <p style="font-size: 14px!important;"><span style="font-weight: 600;">Amount Including
                          {{lang.GST_In_Rs}}:</span>&nbsp;AED&nbsp;{{amountIncludingGst | number:'1.2-2'}}</p>
                        <p style="font-size: 14px!important;"><span style="font-weight: 600;">Bill Gst Amount:</span>
                          <!-- {{billGstAmount | currency:'INR':'symbol':'1.2-2'}} -->
                          AED&nbsp;{{ billGstAmount | number:'1.2-2'}}
                        </p>
                        <p style="font-size: 14px!important;"><span style="font-weight: 600;">Total Bill Amount:</span>
                          <!-- {{totalBillAmount | currency:'INR':'symbol':'1.2-2'}} -->
                          AED&nbsp;{{ totalBillAmount | number:'1.2-2'}}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr style="border: 1px solid grey;" />
              <div class="row my-2">
                <div class="col-12" style="display: flex;flex-direction: column;justify-content: flex-start;">
                  <span class="mb-1" style="font-weight: 600;">TERMS AND CONDITIONS</span>
                  <span>1. Goods once sold will not be taken back or exchanged.</span>
                  <span>2. All disputes are subject to your respective city jurisdiction only.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="card mt-5">
    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
            Sales Return List
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>
    <div class="card-body p-0 marginLeftRight m-2">
        <div class="table-responsive">
            <table class="table table-bordered table-striped fs--1 mb-0 bg-white">
                <thead class="bg-200 text-900">
                    <tr class="btn-reveal-trigger">
                        <th>#</th>
                        <th class="text-nowrap">Date</th>
                        <th class="text-nowrap">Vendor Name</th>
                        <th class="text-nowrap">Sales Return No</th>
                        <th class="text-nowrap">Against Sales Invoice</th>
                        <th class="text-nowrap">Full/Partial</th>
                        <th class="text-nowrap">Amount-Excluding GST (In Rs.)</th>
                        <th class="text-nowrap">Amount-Including GST (In Rs.)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let sale of saleListRes; let i = index">


                        <td scope="row">{{ i+1 }}</td>
                        <td>{{sale.returnDate | date:"dd MMM YYYY"| uppercase}}</td>
                        <td>{{sale['party.partyName']}}</td>
                        <td>{{sale.saleReturnNumber}}</td>
                        <td>{{sale.invoiceNo }}</td>
                        <td>{{sale.isOrderReturn}}</td>
                        <td>{{sale['sales_products.amountExcludingGst']}}</td>
                        <td>{{sale['sales_products.amountIncludingGst']}}</td>
                    </tr>
                </tbody>

                <tbody *ngIf="saleListRes==0">
                    <tr class="text-center">
                        <td colspan="10">
                            <h5 class="text-nowrap">No Sales Return List Details Added. Please Sales Return List Details
                                To Be
                                Displayed
                                Here. <span>
                                    <a class="nav-link" routerLink="/pages/sales-return">Add New Sales Return </a>
                                </span></h5>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>


</div> -->
