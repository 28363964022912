<div class="card widget" style = "box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);">
    <div class="card-header">
      <div class="row headerRow">
        <div class="col-sm-6" style="vertical-align: middle;">
          <span class="pt-3" style = "font-family: 'Poppins', sans-serif;font-weight: 500;font-size: 18px;">AI Review</span>
        </div>
      </div>
    </div>
    <div class="card-body" style = "height: 310px;">
        <div class="max-w-100" style = "margin: auto;" id="ai-details-chart">{{reviewAi}}</div>
    </div>
</div>

