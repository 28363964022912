<!-- <div class="row d-flex flex-center">
  <div class="col-md-4 col-lg-6 col-xl-7"></div>
  <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3" style = "padding-left: 5rem;">
    <input type="search" id="searchInput" class="form-control pe-5 mb-2" placeholder="Name/Document/Invoice"
    [(ngModel)]="searchTerm" (ngModelChange)="searchFunc()">
  </div>
  <div class="navOption-btns col-sm-6 col-md-4 col-lg-3 col-xl-2 mx-0 px-0 mb-2" style = "display:flex;flex-direction:row;justify-content:space-evenly;">
    <button class="btn" ngbTooltip="Add New Return" (click)="openDialog()"><i class="fas fa-plus"></i> Purchase Return</button>
    <button class="btn" ngbTooltip="Download" accept=".xls,.xlsx" id="download"
        (click)="downlaodAllData()"><i
            class="fas fa-download"></i></button>
  </div>
</div> -->

<div class="row headerButtons">
    <div class="flex-item searchBar">
        <input type="search" id="searchInput" class="form-control pe-5 my-2 mx-0" placeholder="Name/Document/Invoice"
            [(ngModel)]="searchTerm" (ngModelChange)="searchFunc()">
    </div>
    <div class="navOption-btns flex-item formButtons mx-0">
        <button class="btn text-nowrap my-2" ngbTooltip="Add New Return" (click)="openDialog()"><i
                class="fas fa-plus"></i> Purchase Return</button>
        <!-- <button class="btn my-2" ngbTooltip="Download" accept=".xls,.xlsx" id="download"
        (click)="downlaodAllData()"><i class="fas fa-download"></i></button> -->
        <button *ngIf="this.excelbutton == false " class="btn my-2" (click)="downlaodAllData()">
            <i class="fas fa-download"></i>
        </button>
        <button *ngIf="this.excelbutton == true " class="btn my-2" (click)="downlaodDatasearch()">
            <i class="fas fa-download"></i>
        </button>
    </div>
</div>

<!-- <hr class=" mb-2"> -->

<div style="width:100% ; overflow-x: auto;margin-top: 6px;">
    <div class="table-container overflow-visible">
        <div class="m-2">
            <table class="table table-responsive datalist"*ngIf="!isArabic">
                <thead>
                    <tr class="btn-reveal-trigger">
                        <!-- <th scope="col">
                        <input type="checkbox" />
                    </th> -->
                        <th class="text-nowrap" style="vertical-align:middle;">Date</th>
                        <th class="text-nowrap" style="vertical-align:middle;">Document</th>
                        <th class="text-nowrap" style="vertical-align:middle;">Against Purchase Invoice</th>
                        <th class="text-nowrap" style="vertical-align:middle;">Vendor Name</th>
                        <!-- <th class="text-nowrap">GSTIN</th>
                        <th class="text-nowrap">Contact</th> -->
                        <th class="text-nowrap" style="vertical-align:middle;">Full/Partial</th>
                        <!-- <th class="text-nowrap">Base Amount (In Rs.)</th> -->
                        <th class="text-nowrap" style="vertical-align:middle;text-align: right;min-width:200px;">Total
                            Amount (In Rs.)</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let sale of saleListRes; let i = index">
                        <!-- <td scope="row">
                        <input type="checkbox" />
                    </td> -->
                        <td class="text-nowrap" style="vertical-align:middle;">{{sale.returnDate | date:"dd MMM YYYY"|
                            uppercase}}</td>
                        <td class="text-nowrap" style="vertical-align:middle;">{{sale.invoiceNo}}</td>
                        <td class="text-nowrap" style="vertical-align:middle;">{{sale.saleReturnNumber}}</td>
                        <td class="text-nowrap" style="vertical-align:middle;">{{sale['party.partyName']}}</td>
                        <td class="text-nowrap" style="vertical-align:middle;">{{sale.isOrderReturn}}</td>
                        <td class="text-nowrap" style="vertical-align:middle;text-align: right;width:180px;">
                            ₹&nbsp;{{sale.totalBillAmount | number:'1.2-2' }}</td>

                        <td class="text-nowrap"
                            style="vertical-align:middle; width:20%!important; white-space:nowrap;text-align: center;">
                            <button class="btn btnp">
                                <span class="bi bi-eye iconFontSize" data-bs-toggle="tooltip" data-bs-placement="top"
                                    data-bs-toggle="modal" data-bs-target="#purchase-return"
                                    (click)="getReceiptById(sale.id)" title="View"></span>
                            </button>
                        </td>
                        <!-- <tr class="text-capitalize" *ngFor="let sale of saleListRes"> -->
                        <!-- <td scope="row">{{ i+1 }}</td>
                        <td>{{sale.returnDate}}</td>
                        <td>{{sale.partyName}}</td>
                        <td>{{sale.saleReturnNumber}}</td>
                        <td>{{sale.invoiceNo }}</td>
                        <td>{{sale.isOrderReturn}}</td>
                        <td>{{sale.amountExcludingGst}}</td>
                        <td>{{sale.totalBillAmount }}</td> -->
                    </tr>
                </tbody>

                <tbody *ngIf="saleListRes==0">
                    <tr class="text-center">
                        <td colspan="12">
                            <img class="image-responsive"
                                src="../../../assets/img/icons/spot-illustrations/notfound1.png" alt="notfound1"
                                width="200" height="150" />
                            <!-- <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Purchase Return List Details Added. Please Purchase  Return List Details To Be Displayed Here. <span>
                    <a class="nav-link" (click)="openDialog()">Add New Purchase Return </a>
                    </span>
                  </h5> -->
                        </td>
                    </tr>
                    <!-- <tr class="text-center">
                    <td colspan="10">
                        <h5 class="text-nowrap">No Purchase Return List Details Added. Please Purchase Return List Details To Be Displayed Here. <span>
                                <a class="nav-link" (click)="openDialog()">Add New Purchase Return </a>
                            </span></h5>
                    </td>
                </tr> -->
                </tbody>
            </table>

            <!-- UAE -->
            <table class="table table-responsive datalist"*ngIf="isArabic">
                <thead>
                    <tr class="btn-reveal-trigger">
                        <!-- <th scope="col">
                        <input type="checkbox" />
                    </th> -->
                        <th class="text-nowrap" style="vertical-align:middle;">Date</th>
                        <th class="text-nowrap" style="vertical-align:middle;">Document</th>
                        <th class="text-nowrap" style="vertical-align:middle;">Against Purchase Invoice</th>
                        <th class="text-nowrap" style="vertical-align:middle;">Vendor Name</th>
                        <!-- <th class="text-nowrap">GSTIN</th>
                        <th class="text-nowrap">Contact</th> -->
                        <th class="text-nowrap" style="vertical-align:middle;">Full/Partial</th>
                        <!-- <th class="text-nowrap">Base Amount (In Rs.)</th> -->
                        <th class="text-nowrap" style="vertical-align:middle;text-align: right;min-width:200px;">Total
                            Amount (In AED)</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let sale of saleListRes; let i = index">
                        <!-- <td scope="row">
                        <input type="checkbox" />
                    </td> -->
                        <td class="text-nowrap" style="vertical-align:middle;">{{sale.returnDate | date:"dd MMM YYYY"|
                            uppercase}}</td>
                        <td class="text-nowrap" style="vertical-align:middle;">{{sale.invoiceNo}}</td>
                        <td class="text-nowrap" style="vertical-align:middle;">{{sale.saleReturnNumber}}</td>
                        <td class="text-nowrap" style="vertical-align:middle;">{{sale['party.partyName']}}</td>
                        <td class="text-nowrap" style="vertical-align:middle;">{{sale.isOrderReturn}}</td>
                        <td class="text-nowrap" style="vertical-align:middle;text-align: right;width:180px;">
                            AED&nbsp;{{sale.totalBillAmount | number:'1.2-2' }}</td>

                        <td class="text-nowrap"
                            style="vertical-align:middle; width:20%!important; white-space:nowrap;text-align: center;">
                            <button class="btn btnp">
                                <span class="bi bi-eye iconFontSize" data-bs-toggle="tooltip" data-bs-placement="top"
                                    data-bs-toggle="modal" data-bs-target="#purchase-return"
                                    (click)="getReceiptById(sale.id)" title="View"></span>
                            </button>
                        </td>
                        <!-- <tr class="text-capitalize" *ngFor="let sale of saleListRes"> -->
                        <!-- <td scope="row">{{ i+1 }}</td>
                        <td>{{sale.returnDate}}</td>
                        <td>{{sale.partyName}}</td>
                        <td>{{sale.saleReturnNumber}}</td>
                        <td>{{sale.invoiceNo }}</td>
                        <td>{{sale.isOrderReturn}}</td>
                        <td>{{sale.amountExcludingGst}}</td>
                        <td>{{sale.totalBillAmount }}</td> -->
                    </tr>
                </tbody>

                <tbody *ngIf="saleListRes==0">
                    <tr class="text-center">
                        <td colspan="12">
                            <img class="image-responsive"
                                src="../../../assets/img/icons/spot-illustrations/notfound1.png" alt="notfound1"
                                width="200" height="150" />
                            <!-- <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Purchase Return List Details Added. Please Purchase  Return List Details To Be Displayed Here. <span>
                    <a class="nav-link" (click)="openDialog()">Add New Purchase Return </a>
                    </span>
                  </h5> -->
                        </td>
                    </tr>
                    <!-- <tr class="text-center">
                    <td colspan="10">
                        <h5 class="text-nowrap">No Purchase Return List Details Added. Please Purchase Return List Details To Be Displayed Here. <span>
                                <a class="nav-link" (click)="openDialog()">Add New Purchase Return </a>
                            </span></h5>
                    </td>
                </tr> -->
                </tbody>
            </table>

        </div>
    </div>
</div>

<div class="modal fade" #closebutton id="purchase-return" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body p-0" *ngIf="!isArabic">
                <div class="rounded-top-lg py-3 ps-4 pe-6">
                    <!-- <div class="row">
                        <div class="col-4"></div>
                        <div class="col-4">
                            <h2 class=""><b>View Purchase Return</b></h2>
                        </div>
                    </div> -->
                </div>
                <div class="container">
                    <div class="card">
                        <div class="card-body">
                            <div class="row my-2" id="panel">
                                <div class="col-6">
                                </div>
                                <div class="col-6">
                                    <div class="row headerAlignment">
                                        <div class="col-6" style="font-weight: 600;">
                                            TAX INVOICE
                                        </div>
                                        <div class="col-6" id="originalForReceiptPrint"
                                            style="font-size: 14px!important;">
                                            ORIGINAL FOR RECIPIENT
                                        </div>
                                    </div>
                                    <div class="row headerAlignment" style="font-size: 14px!important;">
                                        <div class="col-6">
                                            <span style="font-weight: 600;">Return Date:</span><span
                                                style="float: right;">:</span>
                                        </div>
                                        <div class="col-6" style="text-align: end;font-weight: 600;">
                                            {{returnDate| date:"dd MMM YYYY"| uppercase}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row bordered table-responsive scrollbar fs--1">
                                <table class="table table-striped tablePrint" style="font-size: 14px!important;">
                                    <thead class="inner-model-table-heading">
                                        <tr class="dark__bg-1000">
                                            <th class="borderbottom2"
                                                style="font-size: 14px!important;font-weight: 600;">Purchase Return</th>
                                            <th class="borderLeft borderbottom2"></th>
                                            <th class="borderLeft borderbottom2"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-body">
                                        <tr>
                                            <td class="align-middle">
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Vendor Name:</span>&nbsp;{{partyName ?
                                                    partyName : "NA"}}</p>
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">FULL/PARTIAL:</span>&nbsp;{{isOrderReturn
                                                    ? isOrderReturn : "NA"}}</p>
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Document:</span>&nbsp;{{invoiceNo ?
                                                    invoiceNo : "NA"}}</p>
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Against Purchase
                                                        Invoice:</span>&nbsp;{{saleReturnNumber ? saleReturnNumber :
                                                    "NA"}}</p>
                                            </td>

                                            <td class="align-middle  borderLeft " style="text-align: left !important;">
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Amount Excluding Gst:</span>
                                                        &nbsp;₹&nbsp;{{amountExcludingGst | number:'1.2-2'}}</p>
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Amount Including Gst:</span>
                                                        &nbsp;₹&nbsp;{{amountIncludingGst | number:'1.2-2'}}</p>
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Bill Gst Amount:</span>
                                                    <!-- {{billGstAmount | currency:'INR':'symbol':'1.2-2'}} -->
                                                    ₹&nbsp;{{ billGstAmount | number:'1.2-2'}}
                                                </p>
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Total Bill Amount:</span>
                                                    <!-- {{totalBillAmount | currency:'INR':'symbol':'1.2-2'}} -->
                                                    ₹&nbsp;{{ totalBillAmount | number:'1.2-2'}}
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr style="border: 1px solid grey;" />
                            <div class="row">
                                <div class="col-6" style="display: flex;flex-direction: column;justify-content: flex-start;">
                                  <span class="mb-1 mt-5" style="font-weight: 600;">TERMS AND CONDITIONS</span>
                                  <span #dynamicText [style.fontSize.px]="getTextSizeprint(TextSizeprint)" style = "margin-bottom: 4px;">{{invoiceTermsCon ? invoiceTermsCon :'NA'}}</span>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12" style="display: flex;flex-direction: column;justify-content: flex-end;">
                                  <div class="image-wrapper">
                                      <label class="signature-img" ondragover="return false;">
                                          <img *ngIf="signImg" [src]="signImg" />
                                      </label>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- UAE -->
            <div class="modal-body p-0" *ngIf="isArabic">
                <div class="rounded-top-lg py-3 ps-4 pe-6">
                    <!-- <div class="row">
                        <div class="col-4"></div>
                        <div class="col-4">
                            <h2 class=""><b>View Purchase Return</b></h2>
                        </div>
                    </div> -->
                </div>
                <div class="container">
                    <div class="card">
                        <div class="card-body">
                            <div class="row my-2" id="panel">
                                <div class="col-6">
                                </div>
                                <div class="col-6">
                                    <div class="row headerAlignment">
                                        <div class="col-6" style="font-weight: 600;">
                                            TAX INVOICE
                                        </div>
                                        <div class="col-6" id="originalForReceiptPrint"
                                            style="font-size: 14px!important;">
                                            ORIGINAL FOR RECIPIENT
                                        </div>
                                    </div>
                                    <div class="row headerAlignment" style="font-size: 14px!important;">
                                        <div class="col-6">
                                            <span style="font-weight: 600;">Return Date:</span><span
                                                style="float: right;">:</span>
                                        </div>
                                        <div class="col-6" style="text-align: end;font-weight: 600;">
                                            {{returnDate| date:"dd MMM YYYY"| uppercase}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row bordered table-responsive scrollbar fs--1">
                                <table class="table table-striped tablePrint" style="font-size: 14px!important;">
                                    <thead class="inner-model-table-heading">
                                        <tr class="dark__bg-1000">
                                            <th class="borderbottom2"
                                                style="font-size: 14px!important;font-weight: 600;">Purchase Return</th>
                                            <th class="borderLeft borderbottom2"></th>
                                            <th class="borderLeft borderbottom2"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-body">
                                        <tr>
                                            <td class="align-middle">
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Vendor Name:</span>&nbsp;{{partyName ?
                                                    partyName : "NA"}}</p>
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">FULL/PARTIAL:</span>&nbsp;{{isOrderReturn
                                                    ? isOrderReturn : "NA"}}</p>
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Document:</span>&nbsp;{{invoiceNo ?
                                                    invoiceNo : "NA"}}</p>
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Against Purchase
                                                        Invoice:</span>&nbsp;{{saleReturnNumber ? saleReturnNumber :
                                                    "NA"}}</p>
                                            </td>

                                            <td class="align-middle  borderLeft " style="text-align: left !important;">
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Amount Excluding {{ lang.CGST }}:</span>
                                                        &nbsp;AED&nbsp;{{amountExcludingGst | number:'1.2-2'}}</p>
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Amount Including {{ lang.CGST }}:</span>
                                                        &nbsp;AED&nbsp;{{amountIncludingGst | number:'1.2-2'}}</p>
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Bill {{ lang.CGST }} Amount:</span>
                                                    <!-- {{billGstAmount | currency:'INR':'symbol':'1.2-2'}} -->
                                                    AED&nbsp;{{ billGstAmount | number:'1.2-2'}}
                                                </p>
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Total Bill Amount:</span>
                                                    <!-- {{totalBillAmount | currency:'INR':'symbol':'1.2-2'}} -->
                                                    AED&nbsp;{{ totalBillAmount | number:'1.2-2'}}
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr style="border: 1px solid grey;" />
                            <div class="row">
                                <div class="col-6" style="display: flex;flex-direction: column;justify-content: flex-start;">
                                  <span class="mb-1 mt-5" style="font-weight: 600;">TERMS AND CONDITIONS</span>
                                  <span #dynamicText [style.fontSize.px]="getTextSizeprint(TextSizeprint)" style = "margin-bottom: 4px;">{{invoiceTermsCon ? invoiceTermsCon :'NA'}}</span>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12" style="display: flex;flex-direction: column;justify-content: flex-end;">
                                  <div class="image-wrapper">
                                      <label class="signature-img" ondragover="return false;">
                                          <img *ngIf="signImg" [src]="signImg" />
                                      </label>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>





<!--
<div class="modal fade" #closebutton id="purchase-return" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content position-relative">
          <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
              <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body p-0">
              <div class="rounded-top-lg py-3 ps-4 pe-6">

                  <div class="row">
                      <div class="col-4"></div>
                      <div class="col-4">
                          <h2 class=""><b>View Purchase Return</b></h2>
                      </div>
                  </div>
              </div>
              <div class="container">
                  <div class="card" >
                      <div class="card-body">
                          <div class="row bordered" id="panel">
                              <div class="col-12">
                              </div>
                          </div>
                          <div class="row bordered table-responsive scrollbar fs--1">
                              <table class="table table-striped tablePrint" style="font-size: 11px!important;">
                                  <thead class="inner-model-table-heading">
                                      <tr class="dark__bg-1000" >
                                          <th class="borderbottom2">Party Name</th>
                                          <th class="borderLeft borderbottom2">FULL/PARTIAL</th>
                                          <th class="borderLeft borderbottom2">Document</th>
                                          <th class="borderLeft borderbottom2">Against Purchase Invoice</th>
                                          <th class="borderLeft borderbottom2"></th>
                                      </tr>
                                  </thead>
                                  <tbody class="table-body">
                                      <tr>
                                          <td class="align-middle">
                                              <p>{{partyName}}</p>
                                          </td>
                                          <td class="align-middle text-center borderLeft">
                                              {{isOrderReturn}}
                                          </td>
                                          <td class="align-middle text-center borderLeft">
                                              {{invoiceNo}}
                                          </td>

                                          <td class="align-middle text-center borderLeft">
                                              {{saleReturnNumber}}
                                          </td>

                                          <td class="align-middle  borderLeft " style="text-align: left !important;">
                                              <p><b>Return Date:</b> {{returnDate| date:"dd MMM YYYY"| uppercase}}</p>
                                              <p><b>Amount Excluding Gst:</b> {{amountExcludingGst}}</p>
                                              <p><b>Amount Including Gst:</b> {{amountIncludingGst}}</p>
                                              <p><b>Bill Gst Amount:</b> {{billGstAmount}}</p>
                                              <p><b>Total Bill Amount:</b> {{totalBillAmount}}</p>

                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>

                          <div class="row borderedBottom" style="font-size: 11px!important;">
                              <div class="col-6 ">
                                  <p style="background: #e4e3e6;" class="inner-model-table-heading p-0 text-black"><b>Terms and conditions:</b>
                                  </p>
                                  <p class="pb-2"  style="padding-left: 5px !important;">Thanks for doing business with us.
                                  </p>
                              </div>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div> -->












<!-- <div class="card mb-2">
    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
            Purchase Return List
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>
    <div class="card-body p-0 marginLeftRight m-2">
        <div class="table-responsive">
            <table class="table table-bordered table-striped fs--1 mb-0 bg-white">
                <thead class="bg-200 text-900">
                    <tr class="btn-reveal-trigger">
                        <th>#</th>
                        <th class="text-nowrap">Date</th>
                        <th class="text-nowrap">Document</th>
                        <th class="text-nowrap">Against Purchase Invoice</th>
                        <th class="text-nowrap">Party Name</th>

                        <th class="text-nowrap">Full/Partial</th>
                        <th class="text-nowrap">Total Amount (In Rs.)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let sale of saleListRes; let i = index">
                        <td scope="row">{{ i+1 }}</td>
                        <td>{{sale.returnDate  | date:"dd MMM YYYY"| uppercase}}</td>
                        <td>{{sale.invoiceNo}}</td>
                        <td>{{sale.saleReturnNumber}}</td>
                        <td>{{sale['party.partyName']}}</td>
                        <td>{{sale.isOrderReturn}}</td>
                        <td>{{sale.totalBillAmount }}</td>

                    </tr>
                </tbody>

                <tbody *ngIf="saleListRes==0">
                    <tr class="text-center">
                        <td colspan="10">
                            <h5 class="text-nowrap">No Purchase Return List Details Added. Please Purchase Return List Details
                                To Be
                                Displayed
                                Here. <span>
                                    <a class="nav-link" routerLink="/pages/purchase-return">Add New Purchase Return </a>
                                </span></h5>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>


</div> -->
