import { Component, OnInit } from '@angular/core';
// import '../theme/public/assets/js/theme.js';
import { Router, NavigationEnd } from '@angular/router';
import { DynamicScriptLoaderService } from '../_helpers/DynamicScriptLoaderService';

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    //   styleUrls: ['./pages.component.scss']
})

export class PagesComponent implements OnInit {
    title = 'Iceipts Application';
    constructor(
        private dynamicScriptLoader: DynamicScriptLoaderService,
        private router: Router
    ) { }

    ngOnInit() {
    }

}
