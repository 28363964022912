import { TitleCasePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsServiceService } from 'src/app/core/services/contact-us-service.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  public activeColor: string = 'green';
  public baseColor: string = '#ccc';
  public overlayColor: string = 'rgba(255,255,255,0.5)';
  public dragging: boolean = false;
  public loaded: boolean = false;
  public imageLoaded: boolean = false;
  public imageSrc:any;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public conactForm: any;
  public TicketId:any;
  public currentInput:any;
  public FilesData:any;
  public multiplePic:any;
  public frmData:any;
  public imagedata:any
  public mulitImage:any;
  //myFiles =[];
  myFiles: File[] = [];
  @ViewChild('closebutton') closebutton;
 
  constructor(private formBuilder: FormBuilder,private toastService:ToastNotificationService,
    private ContactUsServiceService:ContactUsServiceService, private titleCasePipe: TitleCasePipe, private modal: NgbModal,
    private router: Router,
    ) { }


  ngOnInit(): void {
    this.conactForm = this.formBuilder.group({
      ticketType:[""],
      Message:[""],
    });
  }
  PostContactUsDetails(){

    let body=
{
  
  "enquiry":{
    "ticketType":this.conactForm.controls.ticketType.value,
    "description":this.conactForm.controls.Message.value,
    "image":""
  },

  "enquiryImages":this.mulitImage,
}
    //  let body= {
    //     "ticketType":this.conactForm.controls.ticketType.value,
    //     "description":this.conactForm.controls.Message.value
    //   }
    if (this.conactForm.valid) {
      this.ContactUsServiceService.contactUspost(body,this.currentUser.id).then((res)=>{
        if (res) {
          this.TicketId = res.data.id
          this.toastService.toastMsg({
            title: "Success",
            content: "Ticket Raised Successfully!!!"
          });
          // this.toastService.openSnackBar("Ticket Raised Successfully !!!");
          this.router.navigate(["/pages/ticket-list"]);
      
         
        }
       
      },(err) => {
        if(err.error.expose){
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else{
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }      
      })  
    }
  }
  formRest(){
    this.conactForm.reset();
    //this.myFiles.clear()
  }
  onFileSelected(event){
    this.frmData = new FormData();
    for (var i = 0; i < event.target.files.length; i++) {
      this.myFiles.push(event.target.files[i]);
      //console.log("Hi "+typeof(event.target.files[i]));
      //this.uploadMultiplefile(event.target.files[i])
      this.frmData.append("upload" , event.target.files[i]);
    }


  //  this.imagedata=this.frmData;
    console.log("Iamgedata", this.imagedata)

    this.uploadMultiplefile(this.frmData)
    
      

  }
  uploadMultiplefile(frmData:FormData){
    
    this.ContactUsServiceService.contactUsImagepost(frmData).then((res) => {
      console.log(res)
      if(res){
        this.mulitImage=res.data.locations;
        this.toastService.toastMsg({
          title: "Success",
          content: "File Uploaded Successfully!!!"
        });
        // this.toastService.openSnackBar("File Uploaded Successfully")
      }
      
     
    },(err) => {
      if(err.error.expose){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      
      else{
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }  
    });
  }
}



