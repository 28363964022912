<div class="container-fluid mb-3 mx-0 bg-white">
    <div class="row pt-3 pb-3 mt-" id="headingPrintHide">
        <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:600;font-size:20px!important;">
            Stock Summary
        </div>
        <div class="col-6 navOption-btns" style ="display: flex; justify-content: flex-end;">
          <button class="btn me-3" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()" type="button"><i class="fas fa-download"></i></button>
    
          <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
          </button>
        </div>
    </div>
    <hr class="mt-1" />
    <div class="fs--1">
        <div class="row mb-3" id = "dateRangePrintHide">
            <div  style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;" class="col-lg-3 col-sm-12 col-md-3">
                <!-- <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label for="Date Range">Date Range</mat-label>
                    <input matInput placeholder="DD MMM YYYY" id="Date" style="text-transform:uppercase!important;"
                        [outsideClick]="true" ngModel bsDatepicker [maxDate]="today" [(ngModel)]="dateRange"
                        [bsConfig]="{ dateInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true }">
                    <span>
                        <mat-icon class="iconcalender">date_range</mat-icon>
                    </span>
                </mat-form-field> -->
                <label class="form-label">Date</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input class="uppercase-date-range" matInput placeholder="Date Range" id="Date Range" type="text" [outsideClick]="true" ngModel
                        bsDaterangepicker [maxDate]="today" [(ngModel)]="dateRange" (ngModelChange)="changeDate($event)"
                        [bsConfig]="{ranges: ranges,showWeekNumbers:false, showNextMonth: false, rangeInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true }">
                    <span><mat-icon class="iconcalender p-1">date_range</mat-icon></span>
                </mat-form-field>
            </div>
            <!-- <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                
            </div>
            <label class="col-sm-1 mt-3  text-nowrap" for="inputPassword3"></label>
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-1 col-lg-1 ">
                
            </div>
            <div class="col-sm-1"></div>
            <div class="col-sm-1  mt-2 text-center" (click)="exportExcel()">
                <a type="button" style="margin-left: 14px;" class="p-0 text-900 bi-file-earmark-excel fa-2x "></a>
                <div class="text-nowrap text-900  fs--2  mt-1">Export to Excel</div>
            </div>
            <div class="col-sm-1 mt-2 text-center" (click)="onPrint()">
                <a type="button" class=" p-0 text-900 bi-printer float-right fa-2x"
                    (click)="printComponent('component1')"></a>
                <div class="fs--2 text-900 text-nowrap mt-1">Print</div>
            </div> -->
        </div>

        
    
        <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped fs--1 mb-0"  id="excel-table">
                <thead class="" style="background-color: #F0F0F0;">
                    <tr class="text-900">
                        <th class="text-nowrap text-center">#</th>
                        <th class="text-nowrap text-center" scope="col">Item Name</th>
                        <th class="text-nowrap text-center" scope="col">Sales Price</th>
                        <th class="text-nowrap text-center" scope="col">Purchase Price</th>
                        <th class="text-nowrap text-center" scope="col">Stock Qty</th>
                        <th class="text-nowrap text-center" scope="col">Stock Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="btn-reveal-trigger text-left" *ngFor="let item of stockSummaryList;let i = index">
                        <td class="text-center">{{ i + 1}}</td>
                        <td class="text-center">{{item.itemName}}</td>
                        <td class="text-center">{{item.salePrice}}</td>
                        <td class="text-center">{{item.recent_purchase_price}}</td>
                        <td class="text-center">{{item.stock}}</td>
                        <td class="text-center">{{item.stockValue}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="stockSummaryList == 0">
                    <tr class="text-center">
                      <td colspan="10">
                        <img class="image-responsive" src="assets/img/icons/spot-illustrations/GST No Transaction.png" width="124" height="142" alt="">
                        <!-- <h5 class="text-nowrap">No Product Details Added. Please Add Product Details To Be
                          Displayed
                          Here. <span id = "linkPrintHide">
                            <a class="nav-link" routerLink="/pages/product-add">Add New Product</a>
                          </span></h5> -->
                          <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Data Found.</h5>

                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                        <td colspan="4"></td>
                        <td colspan="1"><strong class="fw-bold text-dark">Total</strong></td>
                        <!-- <td class="nowrap" scope="col"><strong>₹ <a class="text-success">{{itempartyTotal.saleAmountTotal}} </a></strong></td> -->

                        <td colspan="1"><strong><a class="text-success">₹ {{total}}</a></strong></td>
                    </tr>
                  </tfoot>
            </table>
            <div id="headingPrintHide1">
                <mat-paginator class="float-right mt-2" *ngIf="stockSummaryList.length > 0" [pageSize]="partyPageSize"
                    [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow" [pageIndex]="currentPageNo" showFirstLastButtons
                    (page)="handlePage($event)" aria-label="Select page">
                  </mat-paginator>
            </div>
            
        </div>
    </div>
</div>
