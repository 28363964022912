import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { BiddingService } from 'src/app/core/services/bidding.service';
import { PartyReportsService } from 'src/app/core/services/party-reports.service';
import { ProductService } from 'src/app/core/services/product.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { map, startWith } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { ProfileService } from 'src/app/core/services/profile.service';

interface IRange {
  value: Date[];
  label: string;

}
@Component({
  selector: 'app-item-detail',
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.scss']
})
export class ItemDetailComponent implements OnInit {
  public quarterStartDate: any;
  public quarterEndDate: any;
  public today: Date;
  public quarter;
  public maxDate: Date;
  public start = moment().subtract(29, 'days');
  public end = moment();
  public ranges: IRange[];
  public goodsData = [];
  partyList: any = []
  public currentPageNo: number = 0;
  public pagesize: number = 1;
  public partyPageSize: number = 5;
  public myControl = new FormControl();
  public searchedProduct = [];
  public prodName: string = "No Data";
  public barcodeProduct:any = "No Data";
  public itemcodeProduct:any = "No Data";

  public prodId: string;
  public itemDetail: any = [];
  public totalrow: any;
  public productId: any;
  public itemDataDetailsApidata: any = [];
  fileName = 'ItemDetails.xlsx';
  public tradeName:any
  public toatlPages: any;
  public saleQuantity: any;
  public closingQunatity: any;
  public purchaseQuantity: any;
  public adjustmentQuantity: any;
  public returnQuantity : any;
  public filteredOptions: Observable<string[]>
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public dateRange: Date[];
  p: any;
  // printComponent(cmpName) {
  //   let printContents = document.getElementById(cmpName).innerHTML;
  //   let originalContents = document.body.innerHTML;
  //   document.body.innerHTML = printContents;
  //   window.print();
  //   document.body.innerHTML = originalContents;

  // }
  constructor(private productService: ProductService,
    public profileService: ProfileService,
    private partyservice: PartyReportsService,
    private toastService: ToastNotificationService,
    private biddingService: BiddingService,
    private titleCasePipe: TitleCasePipe) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
    let quarter = moment(this.today).utc().quarter() //outputs 4
    this.getQuarterRange();
  }
  isShown: boolean = false;
  ngOnInit(): void {
    this.getGoodsbyUser();
    //call profile get api
    this.getProfileData();
    this.ranges = [
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 7 Days'
      },
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 30 Days'
      },
      {
        value: [new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()), new Date(new Date(new Date().setDate(0)).toDateString())],
        label: 'Last Month'

      },
      {
        value: [this.quarterStartDate._d, this.quarterEndDate._d],
        label: ' Last Quarter'
      },
      {
        value: [new Date(new Date(new Date().getFullYear() - 1, 3, 1).toDateString()), new Date(new Date(new Date().getFullYear(), 2, 31).toDateString())],
        label: 'Last Financial Year'

      },
      {
        value: [new Date(new Date(new Date().getFullYear(), 3, 1).toDateString()), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'This Financial Year'

      },
      {
        value: [],
        label: 'Custom Range'

      }];
    this.dateRange = [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate()))]

  }
  getQuarterRange() {
    this.quarterStartDate = moment(this.today).subtract(1, 'quarter').startOf('quarter');
    this.quarterEndDate = moment(this.today).subtract(1, 'quarter').endOf('quarter');
  }
  getGoodsbyUser() {
    this.productService.getGoodsProductByUser_Type({}, this.currentUser.id, "goods", "DESC", this.partyPageSize, (this.currentPageNo + 1)).then((res) => {

      this.goodsData = res.data.pageData;
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(name => name ? this.filterNames(name) : this.goodsData.slice())
        );
    }  ,
    (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went To Wrong",
          });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    })
  }
  getItemBatch() {
    this.productService.getproductDetails({}, this.currentUser.id, this.prodId,moment(this.dateRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD') ,moment(this.dateRange[1], 'DD-MMM-YYYY').format('YYYY-MM-DD')).then((res) => {
      this.itemDataDetailsApidata = res.data;
      let a;
      a = res.data.sales[0]
      this.saleQuantity = a['sales_products.totalquantity']
      let b;
      b = res.data.purchase[0]
      this.purchaseQuantity = b['sales_products.totalquantity']
      let c;
      c = res.data.returnQuantity[0]
      this.returnQuantity = c['sales_products.adjustmentQuantity']

      this.closingQunatity = res.data.closingQuantity.stock
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
            });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })
  }
  public handlePage(e: any) {
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.itemDetail.length > 0) {
      this.partyPageSize = e.pageSize;
      this.getItemBatch()
    }
  }
  private filterNames(name: string): string[] {
    return this.goodsData.filter(goods =>
      goods.itemName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
  searchProduct() {
    this.biddingService.getAllProductsByUserSearch({}, this.currentUser.id, this.myControl.value, this.partyPageSize, (this.currentPageNo + 1))
      .then((res) => {
        this.toatlPages = res.data.totalPages;
        this.totalrow = res.data.totalRows;
        this.p = res.data.currentPage;
        this.searchedProduct = res.data.pageData;
        this.prodName = this.searchedProduct[0].itemName;
        this.prodId = this.searchedProduct[0].id;
        this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(name => name ? this.filterNames(name) : this.searchedProduct.slice())
        );
      },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
            });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
        })
  }
  selectedProduct(itemName) {
    this.biddingService.getAllProductsByUserSearch({}, this.currentUser.id, itemName,this.partyPageSize, (this.currentPageNo + 1))
      .then((res) => {
        this.toatlPages = res.data.totalPages;
        this.totalrow = res.data.totalRows;
        this.p = res.data.currentPage;
        this.searchedProduct = res.data.pageData;
        this.prodName = res.data.pageData[0].itemName;
        this.barcodeProduct=res.data.pageData[0].barcode;
        this.itemcodeProduct=res.data.pageData[0].itemCode;
        this.prodId = res.data.pageData[0].id
       // this.getPartyByReport();
        this.getItemBatch();
        console.log("this.prodId", this.prodId)
      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
            });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
            });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })
  }
  // getPartyByReport() {
  //   this.partyservice.getPartyByItem({}, this.currentUser.id, this.prodId).then((res: any) => {
  //     this.partyList = res.data.dataset;
  //   }, (err) => {
  //     if (err.error.expose) {
  //       this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
  //     }
  //     else {
  //       this.toastService.openErrorSnackBar("Something Went To Wrong");
  //     }
  //   })
  // }
  exportExcel() {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }

  getProfileData() {
    this.profileService.getUserProfile({}, this.currentUser.id).then((res => {
      this.tradeName=res.data.additional.tradeName;

    }),
    )}
    changeDate(e){
      this.getItemBatch();  
      }
    onPrint() {
      let printContents, popupWin;
      printContents = document.getElementById("print").innerHTML.toString();
      printContents = (<string>printContents + "").replace("col-sm", "col-xs");
      // console.log(printContents);
      popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <title>Reporte</title>
            <meta name="viewport" content="width=10000, initial-scale=1.0, maximum-scale=1.0, user-scalable=no">
            <link rel="stylesheet"
            href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
            integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
            <style>
            .namebold{
              font-weight: bold;
              text-decoration-line: underline;  
              font-size: 18px;
            }
            </style>
          </head>
          <body onload="window.print();">
          <div>
          <p>Organization’s Name : <span class="namebold">${this.tradeName}</span></P>
          <p>Product Name : ${this.prodName}</P>
          <p>Barcode : ${this.barcodeProduct}</P>
          <p>Item Code : ${this.itemcodeProduct}</P>
          <p>Report Generated from {Date in DD MMM YYYY format} to {Date in DD MMM YYYY format} :
           ${moment(this.dateRange[0], 'DD-MMM-YYYY').format('DD MMM YYYY')} 
          To ${moment(this.dateRange[1], 'DD-MMM-YYYY').format('DD MMM YYYY')}</P>
          </div>
            ${printContents}
          </body>
        </html>`);
      /* window.close(); */
      popupWin.document.close();
    }
  }

