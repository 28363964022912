<div class="modal-header pt-0" id="Add-Party-modal">
    <h1 style="color: #EB8B3F; font-weight: bolder;margin-left: 18px;" class="modal-title mb-0">Purchase Invoice</h1>

    <div class="addProductButtons">

        <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel"
            (click)="CancelPurchase()">Cancel</button>
        <button type="button" class="btn btn-save" (click)="postPurchaseInvoice()">Save</button>

    </div>

</div>
<div class="modal-body">
    <div class="px-3">
        <form [formGroup]="PurchaseInvoiceForm" *ngIf="!isArabic">
            <div class="row d-flex justify-content-end">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
                <!-- Select Purchase Invoice For RCM -->
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="d-flex" style="justify-content: flex-end;">
                        <div class="" style="margin-right: 20px;">
                            <label style="font-size: 14px !important;">Select Purchase Invoice For RCM </label>
                        </div>
                        <div class="form-check form-switch">
                            <input formControlName="reverseCharge" class="form-check-input" id="inlineCheckbox1"
                                type="checkbox" [disabled]="showInvoiceGenerateData" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-2">
                <!-- Transaction Type -->
                <div class="col-sm-12 col-md-6 col-lg-4 ">
                    <label class="form-label">Transaction Type<span class="error-asterisk">*</span></label>
                    <mat-form-field style=" font-size: 11px" class="example-full-width" appearance="outline">
                        <mat-select disableOptionCentering aria-label="Default select example" formControlName="credit"
                            (selectionChange)="getCredit($event)" placeholder="Transaction Type" [ngClass]="{
                              'is-invalid': (f.credit.invalid && f.credit.touched) && submitted }">
                            <mat-option value=false>Cash</mat-option>
                            <mat-option value=true>Credit</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submitted || f.credit.touched) && f.credit.errors?.required">
                        Transaction Type Is Required!
                    </mat-error>
                </div>
            </div>

            <div class="row mb-2">
                <!-- Party Name -->
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <label class="form-label">Vendor Name<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" style=" font-size: 11px" appearance="outline">
                        <input type="text" placeholder="Party Name" aria-label="Select Party Name" matInput
                            formControlName="partyName" (keyup)="searchPartyByName($event.target.value)"
                            [matAutocomplete]="auto" [ngClass]="{
                              'is-invalid': (f.partyName.invalid && f.partyName.touched) && submitted }">
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
                                (onSelectionChange)="setSelectedPartyDeatails(option)">
                                {{option.partyName}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.partyName.touched) && f.partyName.errors?.required">
                        Vendor Name Is Required!
                    </mat-error>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-4">
                    <label class="form-label">Purchase Document #<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" style=" font-size: 11px" appearance="outline">
                        <input type="text" matInput placeholder="Purchase Document #" formControlName="invoiceNo"
                            [disabled]="showInvoiceGenerateData" autocomplete="off" [ngClass]="{
                          'is-invalid': (f.invoiceNo.invalid && f.invoiceNo.touched) && submitted }">

                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submitted || f.invoiceNo.touched)">
                        <mat-error *ngIf="f.invoiceNo.errors?.required">
                            Purchase Document # is required!
                        </mat-error>
                    </mat-error>

                </div>
                <!-- Purchase Invoice Date -->
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <label class="form-label" for="Purchase Invoice Date">Purchase Invoice Date</label>
                    <mat-form-field class="example-full-width" style="font-size: 14px" appearance="outline">
                        <input matInput required placeholder="Purchase Invoice Date." formControlName="invoiceDate"
                            [(ngModel)]="todaysDate" style="text-transform:uppercase!important;" [maxDate]="today"
                            id="datePicker" bsDatepicker [bsConfig]="{
                          containerClass: 'theme-dark-blue',
                          dateInputFormat: 'DD MMM YYYY',
                          showWeekNumbers:false}" [ngClass]="{
                          'is-invalid': (f.invoiceDate.invalid && f.invoiceDate.touched) && submitted }" (click)="openDatepicker()">
                        <span>
                            <mat-icon class="iconcalender">date_range</mat-icon>
                        </span>
                    </mat-form-field>
                    <!-- <mat-form-field class="example-full-width" appearance="outline">
                      <input matInput required ngModel bsDatepicker placeholder="DD MMM YYYY"
                        style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                                      containerClass: 'theme-dark-blue',
                                      dateInputFormat: 'DD MMM YYYY',
                                       showWeekNumbers:false
                                      }" formControlName="invoiceDate" autocomplete="off" (click)="openDatepicker()">
                      <span>
                        <mat-icon class="iconcalender p-1">date_range</mat-icon>
                      </span>
                    </mat-form-field> -->
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.invoiceDate.touched) && f.invoiceDate.errors?.required">
                        Purchase Invoice Date Is Required!
                    </mat-error>
                </div>
            </div>

            <div class="row mb-2">
                <!-- Bill No. -->
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <label class="form-label">Invoice #<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" style=" font-size: 11px" appearance="outline">
                        <input type="text" placeholder="Invoice #" matInput formControlName="billNo"
                            autocomplete="off" [disabled]="showInvoiceGenerateData" [ngClass]="{
                          'is-invalid': (f.billNo.invalid && f.billNo.touched) && submitted }">

                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submitted || f.billNo.touched)">
                        <mat-error *ngIf="f.billNo.errors?.required">
                            Invoice # is required!
                        </mat-error>
                    </mat-error>
                </div>

                <div [ngSwitch]="currentUser.userType"  class=" col-xs-12 col-sm-12 col-md-6 col-lg-4">
                    <div *ngSwitchCase="'CLIENT'" style="font-size: 14px">
                      <label class="form-label">Warehouse<span class="error-asterisk">*</span></label>
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse" (selectionChange)="onWarehouseSelectionChange($event)">
                          <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                            {{warehouse.firstName}}
                          </mat-option>
                          <mat-option value="clear">Clear Selection</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-error class="text-danger" *ngIf="(submitted || f.warehouse.touched)">
                        <mat-error *ngIf="f.warehouse.errors?.required">
                          Warehouse is required!
                        </mat-error>
                      </mat-error>
                    </div>
                    <div *ngSwitchCase="'SHOP'" style="font-size: 14px">
                      <label class="form-label">Warehouse</label>
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse" (selectionChange)="onWarehouseSelectionChange($event)">
                          <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                            {{warehouse.firstName}}
                          </mat-option>
                          <mat-option value="clear">Clear Selection</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                <!-- Shipped From(State) -->
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <label class="form-label">Shipped From(State)</label>
                    <mat-form-field class="example-full-width" style=" font-size: 11px" appearance="outline">
                        <mat-select formControlName="shippedFrom" aria-label="Default select example"
                            placeholder="Select State" [disableOptionCentering]="true" [ngClass]="{
                              'is-invalid': (f.shippedFrom.invalid && f.shippedFrom.touched) && submitted }">

                            <mat-option [value]="state" *ngFor="let state of allStates">{{ state }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.shippedFrom.touched) && f.shippedFrom.errors?.required">
                        Shipped From(State) Is Required!
                    </mat-error>
                </div>

                <!-- e-Way Bill No. -->
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <label class="form-label">e-Way Bill #</label>
                    <mat-form-field class="example-full-width" style=" font-size: 11px" appearance="outline">
                        <input type="text" matInput [textMask]="{mask: mask}" formControlName="eWayBillNo"
                            autocomplete="off" placeholder="Enter e-way Bill #">
                    </mat-form-field>
                </div>
            </div>

            <div class="row mb-2">
                <!-- Billing Address -->
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <label class="form-label">Billed From<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <textarea matInput rows="5" formControlName="billingAddress" required
                            placeholder="Enter Billing Address" autocomplete="off"
                            [ngClass]="{
                          'is-invalid': (f.billingAddress.invalid || f.billingAddress.touched) && submitted }"></textarea>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
                        Billing Address is required
                    </mat-error>
                </div>

                <!-- Shipping Address -->
                <div *ngIf="isShippingAddress" class="col-sm-12 col-md-6 col-lg-4">
                    <label class="form-label">Shipped From</label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <textarea matInput rows="5" formControlName="shippingAddress"
                            placeholder="Enter Shipping Address"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="row ">
                <div class="col-md-4 d-flex" style="font-size: 12px;">
                    <div class="form-check">
                        <input class="form-check-input" id="inlineCheckbox1" type="checkbox"
                            [disabled]="showInvoiceGenerateData" (change)="showShippingAddress($event.target.checked)"
                            [checked]="isShippingAddress" />
                    </div>
                    <label style="margin-left:1px!important;font-size: 14px;" class="text-nowrap ">Shipped from
                        Address</label>
                </div>
            </div>



            <div class="row d-flex justify-content-end">
                <div class=""></div>
                <div class="col-sm-5 mt-3">
                    <div class="toggle-container container-fluid d-flex justify-content-center">
                        <div class="lable-container container d-flex justify-content-center" [class.bold]="isOn">
                          <span class = "form-label">Add Compensation Cess</span></div>
                        <input class="input-container container" type="checkbox" [checked]="isShown" id="switch"
                            (click)="toggleShow()" />
                        <label for="switch" class="small-toggle container"></label>
                        <!-- <div class="lable-container container d-flex justify-content-center" [class.bold]="isOn">Customer</div> -->
                    </div>
                    <!-- <div class="d-flex justify-content-between mt-1" style="font-size: 12px;">
                      <label>Add Compensation Cess</label>
                      <div class="form-check form-switch">
                          <input class="form-check-input toggleB mt-1" style="margin-left: 10px!important;"
                              id="inlineCheckbox1" type="checkbox" (click)="toggleShow()" />
                      </div>
                  </div> -->
                </div>

            </div>
            <div class="table-responsive my-custom-scrollbar">
                <table class="table table-bordered">
                    <thead class="inner-model-table-heading">
                        <tr class="font_size font-weight-bold">
                            <th style = "vertical-align: middle;" scope="col">#</th>
                            <th scope="col" style="width: 10%;vertical-align: middle;">
                                SKU<span class="text-danger">*</span>
                            </th>
                            <th style = "vertical-align: middle;" class="text-nowrap">SKU Description</th>
                            <th style = "vertical-align: middle;">Unit</th>

                            <th style = "vertical-align: middle;">Quantity</th>
                            <!-- <th scope="col" class="text-nowrap" colspan="2">
                              Base Price (In Rs.)<span class="text-danger"> *</span>
                          </th>
                          -->
                            <th style = "vertical-align: middle;" scope="col" colspan="1" class="text-nowrap">
                                Purchase Price(With GST)<span class="text-danger">*</span>
                            </th>
                            <th style = "vertical-align: middle;" scope="col" colspan="1">
                                GST<span class="text-danger">*</span>
                            </th>
                            <th style = "vertical-align: middle;" scope="col" colspan="2" class="text-nowrap" *ngIf="isShown">
                                Additional CESS (In Rs.)<span class="text-danger">*</span>
                            </th>
                            <th style = "vertical-align: middle;" class="text-nowrap" scope="col ">
                                Total (In Rs.)<span class="text-danger text-nowrap">*</span>
                            </th>
                            <th scope="col" class="text-nowrap" style="width:8%;vertical-align: middle;">
                                Action<span class="text-danger">*</span>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td></td>
                            <td class="s23323">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input type="text" id="inp12" placeholder="SKU" (input)="onSearchInputChange($event.target.value)"
                                        matInput [formControl]="myControl1" [matAutocomplete]="auto1">
                                    <mat-icon class="m-2" matSuffix>search</mat-icon>
                                    <mat-autocomplete #auto1="matAutocomplete">
                                        <mat-option *ngFor="let option of filteredOptions3 | async"
                                            [value]="option.itemName"
                                            (onSelectionChange)="getGoodsByBarcodeSelected(option.barcode,$event)">
                                            <span style="font-size: 12px;"> {{ option.itemName | titlecase }}/<b>{{
                                                    option.barcode}}</b></span>

                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </td>
                            <td>
                                <input type="text" matInput placeholder="SKU Description" required />
                            </td>
                            <td>
                                <input type="text" matInput placeholder="Unit"  required />
                            </td>
                            <td>
                                <input type="text" matInput placeholder="Quantity" style="width: 5rem;" required />
                            </td>
                            <td>
                                <input type="text" matInput placeholder="Unit Price" />
                            </td>
                            <!-- <td>
                              <input type="text" matInput placeholder="Total" />
                          </td> -->
                            <td>
                                <input type="text" matInput required placeholder=" %" />
                            </td>
                            <!-- <td colspan="1">
                              <input type="text" matInput required placeholder=" %" style="width: 3rem;" />
                          </td> -->
                            <!--
                          <td colspan="1">
                              <input type="text" matInput required placeholder="IN Rs." style="width: 3rem;" />
                          </td> -->
                            <td colspan="1" *ngIf="isShown">
                                <input type="text" matInput placeholder="%" style="width: 2rem;" required />
                            </td>
                            <td colspan="1" *ngIf="isShown">
                                <input type="text" matInput placeholder="In Rs." style="width: 3rem;" required />
                            </td>
                            <td>
                                <input type="text" matInput placeholder="Amount" required />
                            </td>
                            <td></td>
                        </tr>
                        <tr *ngFor="let item of productData;let i=index">
                            <td style="vertical-align: middle;">{{ i + 1 }}</td>

                            <td>
                                {{ item.barcode }}
                            </td>
                            <td>{{ item.itemName | titlecase}}</td>
                            <td class="text-center">
                                <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit"
                                     readonly />
                            </td>
                            <!-- <td class="">
                              <p style="margin-top: 0px!important;">
                                  <a class="border-white btn"
                                      style="margin-top: 0px!important;padding: 0px!important;"
                                      (click)="changeQuantity('-',i)">-</a>
                                  <span class="">{{ item.quantity }}</span>
                                  <a class="border-white btn"
                                      style="margin-top: 0px!important;padding: 0px!important;"
                                      (click)="changeQuantity('+',i)">+</a>
                              </p>
                          </td> -->
                            <td
                                style="display:flex; flex-direction: row; justify-content: center; align-items: center;">
                                <!-- <input type="text" matinput [value]="item.quantity" style="width : 5 rem" /> -->
                                <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                                    (click)="changeQuantity('-',i)">-</a>
                                <input type="text" matInput [value]="item.quantity" placeholder="Quantity"
                                    (change)="changeQuantity('qty',i,$event.target.value)"
                                    style="width: 5rem;text-align: center;" required />
                                <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                                    (click)="changeQuantity('+',i)">+</a>
                            </td>
                            <td colspan="1">
                                <input type="text" matInput (change)="setPurchasePrice($event.target.value,i)">
                                <!-- <p class=""> -->
                                <!-- {{ item.salePrice |
                                  currency:'INR':'symbol':'1.2-2'}} -->
                                <!-- {{ ((item.salePrice * item.quantity) - (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                                currency:'INR':'symbol':'1.2-2'}} -->
                                <!-- {{ item.salePrice | currency:'INR':'symbol':'1.2-2'}} -->
                                <!-- </p> -->
                            </td>
                            <!-- <td colspan="1">
                              <p class="">
                                  {{item.subTotal |
                                  currency:'INR':'symbol':'1.2-2'}} -->
                            <!-- {{ ((item.salePrice * item.quantity) - (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                                currency:'INR':'symbol':'1.2-2'}} -->
                            <!-- {{ item.salePrice * item.quantity |
                              currency:'INR':'symbol':'1.2-2'}} -->
                            <!-- </p>
                          </td> -->
                            <td colspan="1">
                                <input type="text" matInput n (change)="setGST($event.target.value,i)"
                                    [value]="item.gstRate">
                                <!-- <p class="">
                                  {{ item.gstRate }}%
                              </p> -->
                            </td>
                            <!-- <td colspan="1">
                              <p class="" style="width: 3rem;">
                                  {{ item.gstRate }}%
                              </p>
                          </td> -->
                            <!--
                          <td colspan="1">
                              <p class="" style="width: 5rem;">
                                  {{ item.gstAmount | currency:'INR':'symbol':'1.2-2' }}
                              </p>
                          </td> -->
                            <td colspan="1" *ngIf="isShown">
                                <p class="" style="width: 3rem;">{{ item.additionalCess }}% </p>
                            </td>
                            <td colspan="1" *ngIf="isShown">
                                <p class="" style="width: 5rem;">
                                    <!-- {{ (item.salePrice * (item.additionalCess / 100)) | currency:'INR':'symbol':'1.2-2'}} -->
                                    ₹&nbsp;{{ (item.salePrice * (item.additionalCess / 100)) | number:'1.2-2'}}
                                </p>
                            </td>
                            <td>

                                <p class="" style="width:100% !important;">
                                    <!-- {{ item.total | currency:'INR':'symbol':'1.2-2'}} -->
                                    ₹&nbsp;{{ item.total | number:'1.2-2'}}
                                    <!-- {{ ((item.salePrice * item.quantity) + (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                              currency:'INR':'symbol':'1.2-2'}} -->
                                </p>
                            </td>
                            <td>
                                <button type="button" class="text-center btnb" style="width: 3rem!important;"
                                    (click)="changeQuantity('rm',i)">
                                    <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="Delete"></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="row d-flex justify-content-between">
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 ">

                    <label class="form-label" for="customFile">Upload Bill</label>
                    <input class="form-control mb-3" placeolder="sd" id="customFile" type="file"
                        accept="image/*,.pdf" />


                    <label class="form-label" for="customFile">Upload E-way Bill</label>
                    <input class="form-control" id="customFile" type="file" accept="image/*,.pdf" />

                </div>

                <div class="col-sm-12 col-md-4 col-lg-4 table-bordered my-custom-scrollbar">

                    <table class="table table-bordered p-5 my-custom-scrollbar ">
                        <tr>
                            <td class="thWidth p-2" style="text-align:left!important">Subtotal</td>
                            <td class="p-2" style="width: 50px;" style="text-align:right!important">
                                <!-- {{ subTotal | currency:'INR':'symbol':'1.2-2'}} -->
                                ₹&nbsp;{{ subTotal | number:'1.2-2'}}
                            </td>
                        </tr>
                        <tr>
                            <td class="thWidth p-2" *ngIf="gstStateMatch" style="text-align:left!important">CGST
                            </td>
                            <td class="p-2" *ngIf="gstStateMatch" style="width: 50px;"
                                style="text-align:right!important">
                                <!-- {{ gstRate/2 | currency:'INR':'symbol':'1.2-2'}} -->
                                ₹&nbsp;{{ gstRate/2 | number:'1.2-2'}}
                            </td>
                        </tr>
                        <tr>
                            <td class="thWidth p-2" *ngIf="gstStateMatch" style="text-align:left!important">SGST
                            </td>
                            <td class="p-2" *ngIf="gstStateMatch" style="width:50px;"
                                style="text-align:right!important">
                                <!-- {{ gstRate/2 | currency:'INR':'symbol':'1.2-2'}} -->
                                ₹&nbsp;{{ gstRate/2 | number:'1.2-2'}}
                            </td>
                        </tr>
                        <tr>
                            <td class="thWidth  p-2" *ngIf="!gstStateMatch" style="text-align:left!important;">
                                IGST</td>
                            <td class="p-2" *ngIf="!gstStateMatch" style="width: 50px;"
                                style="text-align:right!important;">
                                <!-- {{ (gstRate/2 + gstRate/2) | currency:'INR':'symbol':'1.2-2'}} -->
                                ₹&nbsp;{{ (gstRate/2 + gstRate/2) | number:'1.2-2'}}
                            </td>
                        </tr>

                        <tr>
                            <td class="thWidth p-2" *ngIf="isShown" style="text-align:left!important">Additional
                                Cess
                            </td>
                            <td class="p-2" style="width:50px;" *ngIf="isShown" style="text-align:right!important">
                                <!-- {{ additionalCess | currency:'INR':'symbol':'1.2-2'}} -->
                                ₹&nbsp;{{ additionalCess | number:'1.2-2'}}
                            </td>
                        </tr>
                        <tr>
                            <td class="thWidth p-2" style="text-align:left!important">Total</td>
                            <td class="p-2" style="width: 50px;" style="text-align:right!important">
                                <!-- {{ total | currency:'INR':'symbol':'1.2-2'}} -->
                                ₹&nbsp;{{ total | number:'1.2-2'}}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <!-- <div class="col-12 d-flex  justify-content-end">
              <div class="">
                  <a type="button" class="btn btn-outline-primary" (click)="CancelPurchase()">Cancel</a>
              </div>
              <div class="">
                  <a type="submit" class="btn btn-outline-primary" style="margin-left: 10px;"
                      (click)="postPurchaseInvoice()">Save</a>
              </div>
          </div> -->
        </form>

        <!-- UAE -->

        <form [formGroup]="PurchaseInvoiceForm" *ngIf="isArabic">


            <div class="row mb-2">
                <div class="col-sm-12 col-md-6 col-lg-4 ">
                    <label class="form-label">Transaction Type<span class="error-asterisk">*</span></label>
                    <mat-form-field style=" font-size: 11px" class="example-full-width" appearance="outline">
                        <mat-select disableOptionCentering aria-label="Default select example" formControlName="credit"
                            (selectionChange)="getCredit($event)" placeholder="Transaction Type" [ngClass]="{
                              'is-invalid': (f.credit.invalid && f.credit.touched) && submitted }">
                            <mat-option value=false>Cash</mat-option>
                            <mat-option value=true>Credit</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submitted || f.credit.touched) && f.credit.errors?.required">
                        Transaction Type Is Required!
                    </mat-error>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <label class="form-label">Vendor Name<span class="error-asterisk">*</span></label>

                    <mat-form-field class="example-full-width" style=" font-size: 11px" appearance="outline">
                        <input type="text" placeholder="Vendor Name" aria-label="Select Vendor Name" matInput
                            formControlName="partyName" (keyup)="searchPartyByName($event.target.value)"
                            [matAutocomplete]="auto" [ngClass]="{
                              'is-invalid': (f.partyName.invalid && f.partyName.touched) && submitted }">
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
                                (onSelectionChange)="setSelectedPartyDeatails(option)">
                                {{option.partyName}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.partyName.touched) && f.partyName.errors?.required">
                        Vendor Name Is Required!
                    </mat-error>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-4">
                    <label class="form-label">Purchase Document #<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" style=" font-size: 11px" appearance="outline">
                        <input type="text" matInput placeholder="Purchase Document #" formControlName="invoiceNo"
                            [disabled]="showInvoiceGenerateData" autocomplete="off" [ngClass]="{
                          'is-invalid': (f.invoiceNo.invalid && f.invoiceNo.touched) && submitted }">

                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submitted || f.invoiceNo.touched)">
                        <mat-error *ngIf="f.invoiceNo.errors?.required">
                            Purchase Document # is required!
                        </mat-error>
                    </mat-error>

                </div>
                <!-- Purchase Invoice Date -->
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <label class="form-label" for="Purchase Invoice Date">Purchase Invoice Date</label>
                    <mat-form-field class="example-full-width" style="font-size: 14px" appearance="outline">
                        <input matInput required placeholder="Purchase Invoice Date." formControlName="invoiceDate"
                            [(ngModel)]="todaysDate" style="text-transform:uppercase!important;" [maxDate]="today"
                            id="datePicker" bsDatepicker [bsConfig]="{
                          containerClass: 'theme-dark-blue',
                          dateInputFormat: 'DD MMM YYYY',
                          showWeekNumbers:false}" [ngClass]="{
                          'is-invalid': (f.invoiceDate.invalid && f.invoiceDate.touched) && submitted }" (click)="openDatepicker()">
                        <span>
                            <mat-icon class="iconcalender">date_range</mat-icon>
                        </span>
                    </mat-form-field>
                    <!-- <mat-form-field class="example-full-width" appearance="outline">
                      <input matInput required ngModel bsDatepicker placeholder="DD MMM YYYY"
                        style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                                      containerClass: 'theme-dark-blue',
                                      dateInputFormat: 'DD MMM YYYY',
                                       showWeekNumbers:false
                                      }" formControlName="invoiceDate" autocomplete="off" (click)="openDatepicker()">
                      <span>
                        <mat-icon class="iconcalender p-1">date_range</mat-icon>
                      </span>
                    </mat-form-field> -->
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.invoiceDate.touched) && f.invoiceDate.errors?.required">
                        Purchase Invoice Date Is Required!
                    </mat-error>
                </div>
            </div>

            <div class="row mb-2">
                <!-- Bill No. -->
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <label class="form-label">Invoice #<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" style=" font-size: 11px" appearance="outline">
                        <input type="text" placeholder="Invoice #" matInput formControlName="billNo"
                            autocomplete="off" [disabled]="showInvoiceGenerateData" [ngClass]="{
                          'is-invalid': (f.billNo.invalid && f.billNo.touched) && submitted }">

                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submitted || f.billNo.touched)">
                        <mat-error *ngIf="f.billNo.errors?.required">
                            Invoice # is required!
                        </mat-error>
                    </mat-error>
                </div>

                <!-- Shipped From(State) -->
                <!-- <div class="col-sm-12 col-md-6 col-lg-4">
                  <label class="form-label">Shipped From(State)</label>
                  <mat-form-field class="example-full-width" style=" font-size: 11px" appearance="outline">
                      <mat-select formControlName="shippedFrom" aria-label="Default select example"
                          placeholder="Select State" [disableOptionCentering]="true" [ngClass]="{
                              'is-invalid': (f.shippedFrom.invalid && f.shippedFrom.touched) && submitted }">

                          <mat-option [value]="state" *ngFor="let state of allStates">{{ state }}</mat-option>
                      </mat-select>
                  </mat-form-field>
                  <mat-error class="text-danger"
                      *ngIf="(submitted || f.shippedFrom.touched) && f.shippedFrom.errors?.required">
                      Shipped From(State) Is Required!
                  </mat-error>
              </div> -->

                <!-- e-Way Bill No. -->
                <!-- <div class="col-sm-12 col-md-6 col-lg-4">
                  <label class="form-label">e-Way Bill No.</label>
                  <mat-form-field class="example-full-width" style=" font-size: 11px" appearance="outline">
                      <input type="text" matInput [textMask]="{mask: mask}" formControlName="eWayBillNo" autocomplete="off"
                          placeholder="Enter e-way Bill No.">
                  </mat-form-field>
              </div> -->
            </div>

            <div class="row mb-2">
                <!-- Billing Address -->
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <label class="form-label">Billed From<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <textarea matInput rows="5" formControlName="billingAddress" required
                            placeholder="Enter Billing Address" autocomplete="off"
                            [ngClass]="{
                          'is-invalid': (f.billingAddress.invalid || f.billingAddress.touched) && submitted }"></textarea>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
                        Billing Address is required
                    </mat-error>
                </div>

                <!-- Shipping Address -->
                <div *ngIf="isShippingAddress" class="col-sm-12 col-md-6 col-lg-4">
                    <label class="form-label">Shipped From</label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <textarea matInput rows="5" formControlName="shippingAddress"
                            placeholder="Enter Shipping Address"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="row ">
                <div class="col-md-4 d-flex" style="font-size: 12px;">
                    <div class="form-check">
                        <input class="form-check-input" id="inlineCheckbox1" type="checkbox"
                            [disabled]="showInvoiceGenerateData" (change)="showShippingAddress($event.target.checked)"
                            [checked]="isShippingAddress" />
                    </div>
                    <label style="margin-left:1px!important;font-size: 14px;" class="text-nowrap ">Shipped from
                        Address</label>
                </div>
            </div>



            <!-- <div class="row d-flex justify-content-end">
              <div class=""></div>
              <div class="col-sm-5 mt-3">
                  <div class="toggle-container container-fluid d-flex justify-content-center">
                      <div class="lable-container container d-flex justify-content-center" [class.bold]="isOn">Add Compensation Cess</div>
                      <input class="input-container container" type="checkbox" [checked]="isShown" id="switch" (click)="toggleShow()"/>
                      <label for="switch" class="small-toggle container"></label>
                  </div>
                  <div class="d-flex justify-content-between mt-1" style="font-size: 12px;">
                      <label>Add Compensation Cess</label>
                      <div class="form-check form-switch">
                          <input class="form-check-input toggleB mt-1" style="margin-left: 10px!important;"
                              id="inlineCheckbox1" type="checkbox" (click)="toggleShow()" />
                      </div>
                  </div>
              </div>

          </div>-->
            <div class="table-responsive my-custom-scrollbar">
                <table class="table table-bordered">
                    <thead class="inner-model-table-heading">
                        <tr class="font_size font-weight-bold">
                            <th style = "vertical-align: middle;" scope="col">#</th>
                            <th scope="col" style="width: 10%;vertical-align: middle;">
                                SKU<span class="text-danger">*</span>
                            </th>
                            <th style = "vertical-align: middle;" class="text-nowrap">SKU Description</th>
                            <th style = "vertical-align: middle;">Unit</th>

                            <th style = "vertical-align: middle;">Quantity</th>

                            <th style = "vertical-align: middle;" scope="col" colspan="1" class="text-nowrap">
                                {{ lang.Purchase_Price_With_GST}}<span class="text-danger">*</span>
                            </th>
                            <th style = "vertical-align: middle;" scope="col" colspan="1">
                                {{lang.GST}}<span class="text-danger">*</span>
                            </th>
                            <!-- <th scope="col" colspan="2" class="text-nowrap" *ngIf="isShown">
                              Additional CESS (IN INR)<span class="text-danger">*</span>
                          </th> -->
                            <th style = "vertical-align: middle;" class="text-nowrap" scope="col ">
                                {{lang.Total_In_Rs}}<span class="text-danger text-nowrap">*</span>
                            </th>
                            <th scope="col" class="text-nowrap" style="width:8%;vertical-align: middle;">
                                Action<span class="text-danger">*</span>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td></td>
                            <td class="s23323">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <input type="text" id="inp12" placeholder="Free Product"
                                        (keyup.enter)="getGoodsList($event.target.value)" aria-label="Select Party Name"
                                        matInput [formControl]="myControl1" [matAutocomplete]="auto1">
                                    <mat-icon class="m-2" matSuffix>search</mat-icon>
                                    <mat-autocomplete #auto1="matAutocomplete">
                                        <!-- <mat-option>Jeeas</mat-option> -->
                                        <mat-option *ngFor="let option of filteredOptions3 | async"
                                            [value]="option.itemName"
                                            (onSelectionChange)="getGoodsByBarcodeSelected(option.barcode,$event)">
                                            <span style="font-size: 12px;"> {{ option.itemName | titlecase }}/<b>{{
                                                    option.barcode}}</b></span>

                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </td>
                            <!-- <td>
                                <input type="text" matInput style="width: 70px;" placeholder="SKU"
                                    (change)="getGoodsByBarcode()" [(ngModel)]="barcode"
                                    [ngModelOptions]="{standalone: true}" required />


                            </td> -->
                            <td>
                                <input type="text" matInput placeholder="SKU Description" required />
                            </td>
                            <td>
                                <input type="text" matInput placeholder="Unit"  required />
                            </td>
                            <td>
                                <input type="text" matInput placeholder="Quantity" style="width: 5rem;" required />
                            </td>
                            <td>
                                <input type="text" matInput placeholder="Unit Price" />
                            </td>
                            <!-- <td>
                              <input type="text" matInput placeholder="Total" />
                          </td> -->
                            <td>
                                <input type="text" matInput required placeholder=" %" />
                            </td>
                            <!-- <td colspan="1">
                              <input type="text" matInput required placeholder=" %" style="width: 3rem;" />
                          </td> -->
                            <!--
                          <td colspan="1">
                              <input type="text" matInput required placeholder="IN Rs." style="width: 3rem;" />
                          </td> -->
                            <!-- <td colspan="1" *ngIf="isShown">
                              <input type="text" matInput placeholder="%" style="width: 2rem;" required />
                          </td>
                          <td colspan="1" *ngIf="isShown">
                              <input type="text" matInput placeholder="In Rs." style="width: 3rem;" required />
                          </td> -->
                            <td>
                                <input type="text" matInput placeholder="Amount" style="max-width: 150px;" required />
                            </td>
                            <td></td>
                        </tr>
                        <tr *ngFor="let item of productData;let i=index">
                            <td style="vertical-align: middle;">{{ i + 1 }}</td>

                            <td>
                                {{ item.barcode }}
                            </td>
                            <td>{{ item.itemName | titlecase}}</td>
                            <td class="text-center" >
                                <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit"
                                    readonly />
                            </td>
                            <!-- <td class="">
                              <p style="margin-top: 0px!important;">
                                  <a class="border-white btn"
                                      style="margin-top: 0px!important;padding: 0px!important;"
                                      (click)="changeQuantity('-',i)">-</a>
                                  <span class="">{{ item.quantity }}</span>
                                  <a class="border-white btn"
                                      style="margin-top: 0px!important;padding: 0px!important;"
                                      (click)="changeQuantity('+',i)">+</a>
                              </p>
                          </td> -->
                            <td
                                style="display:flex; flex-direction: row; justify-content: center; align-items: center;">
                                <!-- <input type="text" matinput [value]="item.quantity" style="width : 5 rem" /> -->
                                <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                                    (click)="changeQuantity('-',i)">-</a>
                                <input type="text" matInput [value]="item.quantity" placeholder="Quantity"
                                    (change)="changeQuantity('qty',i,$event.target.value)"
                                    style="width: 5rem;text-align: center;" required />
                                <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                                    (click)="changeQuantity('+',i)">+</a>
                            </td>
                            <td colspan="1">
                                <input type="text" matInput (change)="setPurchasePrice($event.target.value,i)">
                                <!-- <p class=""> -->
                                <!-- {{ item.salePrice |
                                  currency:'INR':'symbol':'1.2-2'}} -->
                                <!-- {{ ((item.salePrice * item.quantity) - (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                                currency:'INR':'symbol':'1.2-2'}} -->
                                <!-- {{ item.salePrice | currency:'INR':'symbol':'1.2-2'}} -->
                                <!-- </p> -->
                            </td>
                            <!-- <td colspan="1">
                              <p class="">
                                  {{item.subTotal |
                                  currency:'INR':'symbol':'1.2-2'}} -->
                            <!-- {{ ((item.salePrice * item.quantity) - (item.salePrice * item.quantity) * (item.gstRate / 100)) |
                                currency:'INR':'symbol':'1.2-2'}} -->
                            <!-- {{ item.salePrice * item.quantity |
                              currency:'INR':'symbol':'1.2-2'}} -->
                            <!-- </p>
                          </td> -->
                            <td colspan="1">
                                <input type="text" matInput n (change)="setGST($event.target.value,i)"
                                    [value]="item.gstRate">
                                <!-- <p class="">
                                  {{ item.gstRate }}%
                              </p> -->
                            </td>
                            <!-- <td colspan="1">
                              <p class="" style="width: 3rem;">
                                  {{ item.gstRate }}%
                              </p>
                          </td> -->
                            <!--
                          <td colspan="1">
                              <p class="" style="width: 5rem;">
                                  {{ item.gstAmount | currency:'INR':'symbol':'1.2-2' }}
                              </p>
                          </td> -->
                            <!-- <td colspan="1" *ngIf="isShown">
                              <p class="" style="width: 3rem;">{{ item.additionalCess }}% </p>
                          </td>
                          <td colspan="1" *ngIf="isShown">
                              <p class="" style="width: 5rem;">
                                  ₹&nbsp;{{ (item.salePrice * (item.additionalCess / 100)) | number:'1.2-2'}}
                              </p>
                          </td> -->
                            <td style="max-width: 150px;">

                                <p class="">
                                    AED&nbsp;{{ item.total | number:'1.2-2'}}
                                </p>
                            </td>
                            <td>
                                <button type="button" class="text-center btnb" style="width: 3rem!important;"
                                    (click)="changeQuantity('rm',i)">
                                    <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="Delete"></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="row d-flex justify-content-between">
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 ">

                    <label class="form-label" for="customFile">Upload Bill</label>
                    <input class="form-control mb-3" placeolder="sd" id="customFile" type="file"
                        accept="image/*,.pdf" />


                    <!-- <label class="form-label" for="customFile">Upload E-way Bill</label>
                  <input class="form-control" id="customFile" type="file" accept="image/*,.pdf" /> -->

                </div>

                <div class="col-sm-12 col-md-4 col-lg-4 table-bordered my-custom-scrollbar">

                    <table class="table table-bordered p-5 my-custom-scrollbar ">
                        <tr>
                            <td class="thWidth p-2" style="text-align:left!important">Subtotal</td>
                            <td class="p-2" style="width: 50px;" style="text-align:right!important">
                                AED&nbsp;{{ subTotal | number:'1.2-2'}}
                            </td>
                        </tr>
                        <tr>
                            <td class="thWidth p-2" *ngIf="gstStateMatch" style="text-align:left!important">
                                {{lang.CGST}}
                            </td>
                            <td class="p-2" *ngIf="gstStateMatch" style="width: 50px;"
                                style="text-align:right!important">
                                AED&nbsp;{{ gstRate | number:'1.2-2'}}
                            </td>
                        </tr>
                        <!-- <tr>
                          <td class="thWidth p-2" *ngIf="gstStateMatch" style="text-align:left!important">SGST
                          </td>
                          <td class="p-2" *ngIf="gstStateMatch" style="width:50px;"
                              style="text-align:right!important">
                              ₹&nbsp;{{ gstRate/2 | number:'1.2-2'}}
                          </td>
                      </tr> -->
                        <!-- <tr>
                          <td class="thWidth  p-2" *ngIf="!gstStateMatch" style="text-align:left!important;">
                              IGST</td>
                          <td class="p-2" *ngIf="!gstStateMatch" style="width: 50px;"
                              style="text-align:right!important;">
                              ₹&nbsp;{{ (gstRate/2 + gstRate/2) | number:'1.2-2'}}
                            </td>
                      </tr> -->

                        <!-- <tr>
                          <td class="thWidth p-2" *ngIf="isShown" style="text-align:left!important">Additional
                              Cess
                          </td>
                          <td class="p-2" style="width:50px;" *ngIf="isShown" style="text-align:right!important">
                              ₹&nbsp;{{ additionalCess | number:'1.2-2'}}
                            </td>
                      </tr> -->
                        <tr>
                            <td class="thWidth p-2" style="text-align:left!important">Total</td>
                            <td class="p-2" style="width: 50px;" style="text-align:right!important">
                                <!-- {{ total | currency:'INR':'symbol':'1.2-2'}} -->
                                AED&nbsp;{{ total | number:'1.2-2'}}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <!-- <div class="col-12 d-flex  justify-content-end">
              <div class="">
                  <a type="button" class="btn btn-outline-primary" (click)="CancelPurchase()">Cancel</a>
              </div>
              <div class="">
                  <a type="submit" class="btn btn-outline-primary" style="margin-left: 10px;"
                      (click)="postPurchaseInvoice()">Save</a>
              </div>
          </div> -->
        </form>
    </div>
</div>









<!-- <form [formGroup]="PurchaseInvoiceForm">
    <div class="card">
        <div class="card-header bg-light pt-3 pb-3">
            <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
                Purchase Invoice
            </p>
        </div>
        <div class="progress" style="height: 2px">
            <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
            </div>
        </div>
        <div class="card-body">
            <div class="row d-flex justify-content-end">
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4"></div>
                <div class="col-xs-4 col-sm-4 col-md-5 col-lg-4">
                    <div class="d-flex justify-content-between">
                        <div class="col-xs-4 col-sm-4 col-lg-2 col-md-3">
                            <label class="text-nowrap">Select Purchase Invoice For RCM </label>
                        </div>
                        <div class="col-xs-4 col-sm-4 col-lg-2 col-md-2 form-check form-switch">
                            <input formControlName="reverseCharge" class="form-check-input" id="inlineCheckbox1"
                                type="checkbox" [disabled]="showInvoiceGenerateData" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-sm-4 col-lg-4 col-md-4">
                    <mat-form-field style=" font-size: 11px" class="example-full-width" appearance="outline">
                        <label class="form-label">Transaction Type</label>
                        <mat-select disableOptionCentering aria-label="Default select example" formControlName="credit"
                            (selectionChange)="getCredit($event)" placeholder="Transaction Type" [ngClass]="{
                                'is-invalid': (f.credit.invalid && f.credit.touched) && submitted }">
                            <mat-option value=false>Cash</mat-option>
                            <mat-option value=true>Credit</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submitted || f.credit.touched) && f.credit.errors?.required">
                        Transaction Type Is Required!
                    </mat-error>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-sm-4">
                    <mat-form-field class="example-full-width" style=" font-size: 11px" appearance="outline">
                        <label class="form-label">Party Name</label>
                        <input type="text" placeholder="Party Name" aria-label="Select Party Name" matInput
                            formControlName="partyName" (keyup)="searchPartyByName($event.target.value)"
                            [matAutocomplete]="auto" [ngClass]="{
                                'is-invalid': (f.partyName.invalid && f.partyName.touched) && submitted }">
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
                                (onSelectionChange)="setSelectedPartyDeatails(option)">
                                {{option.partyName}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.partyName.touched) && f.partyName.errors?.required">
                        Party Name Is Required!
                    </mat-error>
                </div>



                <div class="col-sm-4">
                    <mat-form-field class="example-full-width" style=" font-size: 11px" appearance="outline">
                        <label class="form-label">Purchase Document No.</label>
                        <input type="text" matInput placeholder="Purchase Document No." formControlName="invoiceNo" [disabled]="showInvoiceGenerateData"
                            [ngClass]="{
                            'is-invalid': (f.invoiceNo.invalid && f.invoiceNo.touched) && submitted }">

                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submitted || f.invoiceNo.touched)">
                        <mat-error *ngIf="f.invoiceNo.errors?.required">
                            Purchase Document No is required!
                        </mat-error>
                    </mat-error>

                </div>
                <div class="col-sm-4">
                    <mat-form-field class="example-full-width" style="font-size: 11px" appearance="outline">
                        <label class="form-label" for="Purchase Invoice Date">Purchase Invoice Date</label>
                        <input matInput required placeholder="Purchase Invoice Date." formControlName="invoiceDate" [(ngModel)]="todaysDate"
                            style="text-transform:uppercase!important;" [maxDate]="today" id="datePicker" bsDatepicker
                            [bsConfig]="{
                            containerClass: 'theme-dark-blue',
                            dateInputFormat: 'DD MMM YYYY',
                            showWeekNumbers:false}" [ngClass]="{
                            'is-invalid': (f.invoiceDate.invalid && f.invoiceDate.touched) && submitted }">
                        <span>
                            <mat-icon class="iconcalender">date_range</mat-icon>
                        </span>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.invoiceDate.touched) && f.invoiceDate.errors?.required">
                        Purchase Invoice Date Is Required!
                    </mat-error>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-sm-4">
                    <mat-form-field class="example-full-width" style=" font-size: 11px" appearance="outline">
                        <label class="form-label">Invoice No.</label>
                        <input type="text" placeholder="Invoice No." matInput formControlName="billNo" [disabled]="showInvoiceGenerateData"
                            [ngClass]="{
                            'is-invalid': (f.billNo.invalid && f.billNo.touched) && submitted }">

                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="(submitted || f.billNo.touched)">
                        <mat-error *ngIf="f.billNo.errors?.required">
                            Invoice No. is required!
                        </mat-error>
                    </mat-error>
                </div>

                <div class="col-sm-4">
                    <mat-form-field class="example-full-width" style=" font-size: 11px" appearance="outline">
                        <label class="form-label">Shipped From(State)</label>
                        <mat-select formControlName="shippedFrom" aria-label="Default select example"
                            placeholder="Select State" [disableOptionCentering]="true" [ngClass]="{
                                'is-invalid': (f.shippedFrom.invalid && f.shippedFrom.touched) && submitted }">

                            <mat-option [value]="state" *ngFor="let state of allStates">{{ state }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.shippedFrom.touched) && f.shippedFrom.errors?.required">
                        Shipped From(State) Is Required!
                    </mat-error>
                </div>

                <div class="col-sm-4">
                    <mat-form-field class="example-full-width" style=" font-size: 11px" appearance="outline">
                        <label class="form-label">e-Way Bill No.</label>
                        <input type="text" matInput [textMask]="{mask: mask}" formControlName="eWayBillNo"
                            placeholder="Enter e-way Bill No.">
                    </mat-form-field>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-sm-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <label class="form-label">Billing Address</label>
                        <textarea matInput rows="5" formControlName="billingAddress" required
                            placeholder="Enter Billing Address"
                            [ngClass]="{
                            'is-invalid': (f.billingAddress.invalid || f.billingAddress.touched) && submitted }"></textarea>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.billingAddress.touched) && f.billingAddress.errors?.required">
                        Billing Address is required
                    </mat-error>
                </div>

                <div *ngIf="isShippingAddress" class="col-sm-4">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <label class="form-label">Shipping Address</label>
                        <textarea matInput rows="5" formControlName="shippingAddress"
                            placeholder="Enter Shipping Address"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="row ">
                <div class="col-md-4 d-flex" style="font-size: 12px;">
                    <div class="form-check">
                        <input class="form-check-input" id="inlineCheckbox1" type="checkbox"
                            [disabled]="showInvoiceGenerateData" (change)="showShippingAddress($event.target.checked)"
                            [checked]="isShippingAddress" />
                    </div>
                    <label style="margin-left:1px!important" class="text-nowrap ">Shipped from Address</label>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <div class="row d-flex justify-content-end">
                <div class=""></div>
                <div class="col-sm-3 mt-3">
                    <div class="d-flex justify-content-between mt-1" style="font-size: 12px;">
                        <label>Add Compensation Cess</label>
                        <div class="form-check form-switch">
                            <input class="form-check-input toggleB mt-1" style="margin-left: 10px!important;"
                                id="inlineCheckbox1" type="checkbox" (click)="toggleShow()" />
                        </div>
                    </div>
                </div>

            </div>
            <div class="table-responsive my-custom-scrollbar">
                <table class="table table-bordered">
                    <thead class="thead-light">
                        <tr class="font_size font-weight-bold text-center">
                            <th scope="col">#</th>
                            <th scope="col" style="width: 10%;">
                                SKU<span class="text-danger"> *</span>
                            </th>
                            <th class="text-nowrap">SKU Description</th>
                            <th>Unit</th>

                            <th>Quantity</th>


                            <th scope="col" colspan="1" class="text-nowrap">
                                Purchase Price(With GST)<span class="text-danger"> *</span>
                            </th>
                            <th scope="col" colspan="1">
                                GST<span class="text-danger"> *</span>
                            </th>
                            <th scope="col" colspan="2" class="text-nowrap" *ngIf="isShown">
                                Additional CESS (IN INR)<span class="text-danger"> *</span>
                            </th>
                            <th class="text-nowrap" scope="col ">
                                Total (In Rs.)<span class="text-danger text-nowrap"> *</span>
                            </th>
                            <th scope="col" class="text-nowrap" style="width:8%;">
                                Action<span class="text-danger"> *</span>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>#</td>
                            <td>
                                <input type="text" matInput style="width: 70px;" placeholder="SKU"
                                    (change)="getGoodsByBarcode()" [(ngModel)]="barcode"
                                    [ngModelOptions]="{standalone: true}" required />


                            </td>
                            <td>
                                <input type="text" matInput placeholder="SKU Description" required />
                            </td>
                            <td>
                                <input type="text" matInput placeholder="Unit" style="width: 3rem;" required />
                            </td>
                            <td>
                                <input type="text" matInput placeholder="Quantity" style="width: 5rem;" required />
                            </td>
                            <td>
                                <input type="text" matInput placeholder="Unit Price" />
                            </td>

                            <td>
                                <input type="text" matInput required placeholder=" %" />
                            </td>


                            <td colspan="1" *ngIf="isShown">
                                <input type="text" matInput placeholder="%" style="width: 2rem;" required />
                            </td>
                            <td colspan="1" *ngIf="isShown">
                                <input type="text" matInput placeholder="IN Rs." style="width: 2rem;" required />
                            </td>
                            <td>
                                <input type="text" matInput placeholder="Amount" required />
                            </td>
                            <td></td>
                        </tr>
                        <tr *ngFor="let item of productData;let i=index">
                            <td>

                            </td>
                            <td>
                                {{ item.barcode }}
                            </td>
                            <td>{{ item.itemName | titlecase}}</td>
                            <td class="text-center">
                                <input type="text" class="unitInput" [value]="item.unit" value="1" placeholder="Unit"
                                    style="width: 5rem;" readonly />
                            </td>

                            <td
                                style="display:flex; flex-direction: row; justify-content: center; align-items: center;">
                                <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                                    (click)="changeQuantity('-',i)">-</a>
                                <input type="text" matInput [value]="item.quantity" placeholder="Quantity"
                                    (change)="changeQuantity('qty',i,$event.target.value)" style="width: 5rem;"
                                    required />
                                <a class="border-white btn" style="margin-top: 0px!important;padding: 0px!important;"
                                    (click)="changeQuantity('+',i)">+</a>
                            </td>
                            <td colspan="1">
                                <input type="text" matInput (change)="setPurchasePrice($event.target.value,i)">

                            </td>

                            <td colspan="1">
                                <input type="text" matInput n (change)="setGST($event.target.value,i)"
                                    [value]="item.gstRate">

                            </td>


                            <td colspan="1" *ngIf="isShown">
                                <p class="" style="width: 3rem;">{{ item.additionalCess }}% </p>
                            </td>
                            <td colspan="1" *ngIf="isShown">
                                <p class="" style="width: 5rem;">
                                    {{ (item.salePrice * item.additionalCess / 100) | currency:'INR':'symbol':'1.2-2'}}
                                </p>
                            </td>
                            <td>

                                <p class="" style="width:100% !important;">
                                    {{ item.total | currency:'INR':'symbol':'1.2-2'}}

                                </p>
                            </td>
                            <td>
                                <button type="button" class="text-center btnb" style="width: 3rem!important;"
                                    (click)="changeQuantity('rm',i)">
                                    <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="Delete"></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="row d-flex justify-content-between">
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 ">

                    <label class="form-label" for="customFile">Upload Bill</label>
                    <input class="form-control mb-3" placeolder="sd" id="customFile" type="file"
                        accept="image/*,.pdf" />


                    <label class="form-label" for="customFile">Upload E-way Bill</label>
                    <input class="form-control" id="customFile" type="file" accept="image/*,.pdf" />

                </div>

                <div class="col-sm-12 col-md-4 col-lg-4 table-bordered my-custom-scrollbar">

                    <table class="table table-bordered p-5 my-custom-scrollbar ">
                        <tr>
                            <td class="thWidth p-2" style="text-align:left!important">Subtotal</td>
                            <td class="p-2" style="width: 50px;" style="text-align:right!important">{{ subTotal |
                                currency:'INR':'symbol':'1.2-2'}}</td>
                        </tr>
                        <tr>
                            <td class="thWidth p-2" *ngIf="gstStateMatch" style="text-align:left!important">CGST</td>
                            <td class="p-2" *ngIf="gstStateMatch" style="width: 50px;"
                                style="text-align:right!important">{{ gstRate/2|
                                currency:'INR':'symbol':'1.2-2'}}</td>
                        </tr>
                        <tr>
                            <td class="thWidth p-2" *ngIf="gstStateMatch" style="text-align:left!important">SGST</td>
                            <td class="p-2" *ngIf="gstStateMatch" style="width:50px;"
                                style="text-align:right!important">{{ gstRate/2 |
                                currency:'INR':'symbol':'1.2-2'}}</td>
                        </tr>
                        <tr>
                            <td class="thWidth  p-2" *ngIf="!gstStateMatch" style="text-align:left!important;">IGST</td>
                            <td class="p-2" *ngIf="!gstStateMatch" style="width: 50px;"
                                style="text-align:right!important;">{{
                                gstRate/2 + gstRate/2|
                                currency:'INR':'symbol':'1.2-2'}}</td>
                        </tr>

                        <tr>
                            <td class="thWidth p-2" *ngIf="isShown" style="text-align:left!important">Additional
                                Cess
                            </td>
                            <td class="p-2" style="width:50px;" *ngIf="isShown" style="text-align:right!important">
                                {{
                                additionalCess | currency:'INR':'symbol':'1.2-2'}}</td>
                        </tr>
                        <tr>
                            <td class="thWidth p-2" style="text-align:left!important">Total</td>
                            <td class="p-2" style="width: 50px;" style="text-align:right!important">{{ total |
                                currency:'INR':'symbol':'1.2-2'}}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="col-12 d-flex  justify-content-end">
                <div class="">
                    <a type="button" class="btn btn-outline-primary" (click)="CancelPurchase()">Cancel</a>
                </div>
                <div class="">
                    <a type="submit" class="btn btn-outline-primary" style="margin-left: 10px;"
                        (click)="postPurchaseInvoice()">Save</a>
                </div>
            </div>
        </div>
    </div>
</form> -->
