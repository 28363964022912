import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/common.service';
import lang from "src/assets/langTranslation/language_translation"

@Component({
  selector: 'app-continue-popup-modal',
  templateUrl: './continue-popup-modal.component.html',
  styleUrls: ['./continue-popup-modal.component.scss']
})
export class ContinuePopupModalComponent implements OnInit {
  public lang = lang.UAE;
  isArabic: boolean;

  constructor(public dialogRef: MatDialogRef<ContinuePopupModalComponent>,  public commonService: CommonService,) { }

  ngOnInit(): void {
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = true;
    });
  }

  continueAnyway() {
    this.dialogRef.close(false); // Close the dialog and pass 'true'
  }

  cancelContinue() {
    this.dialogRef.close(true); // Close the dialog and pass 'false'
  }

}
