import { DatePipe, TitleCasePipe } from '@angular/common';
import lang from "src/assets/langTranslation/language_translation"
import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { resolve } from 'dns';
import { ProfileService } from 'src/app/core/services/profile.service';
import { PurchaseInvoiceService } from 'src/app/core/services/purchase-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { ToWords } from 'to-words';
import { PurchaseInvoiceComponent } from '../purchase-invoice/purchase-invoice.component';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { PartyService } from 'src/app/core/services/party.service';
import { CommonService } from "src/app/core/services/common.service";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import * as numberToWords from 'number-to-words';

@Component({
  selector: 'app-purchase-invoice-list',
  templateUrl: './purchase-invoice-list.component.html',
  styleUrls: ['./purchase-invoice-list.component.scss']
})
export class PurchaseInvoiceListComponent implements OnInit {

  public lang = lang.UAE;
  public addressDetails:any=[];
  public salesData:any=[];
  public basicDetails:any=[];
  totalGST = 0;
  public gstStateMatch: boolean = true;
  public finalAmount:any;
  public wordAmount='';
  public searchKey: any;

  toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: true,
    }
  });
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  invoiceData: any = [];
  public imageSrc: any = '';
  imageLoaded: boolean = false;
  public BusinessName:any;

  purchasedProductData: any;
  billNo: any;
  shippedFrom: string;
  shippingAddress: string;
  billingAddress: string;
  billGstAmount: number;
  subTotal: number;
  total: number;
  saleProductData: any;
  invoiceNo: any;
  totalBillAmount: any;
  saleProductDataName: any;
  saleProductDataQuantity: any;
  saleProductDataRate: any;
  saleProductDataGsTAmount: any;
  placeOfSupply: any;
  public gstinNo: any;
  public invoiceDate: any;
  public dueDate: any;
  public mobile: any;
  public state: any;
  public hsn: any;

  public p=1;
  public currentpage:any;
  public totalrow:any;
  public toatlPages:any;

  public currentPageNo: number = 0;
  public purchaseListPageSize: number = 5;
  public pagesize: number = 1;
  public count: number = 0;
  public tradeName:any;
  downloadData: any = [];
  public isArabic: boolean;
  currencySymbol: string;
  public signImg: any = '';
  TextSizeprint: string = 'Medium';
  public invoiceTermsCon:any;


  constructor(private purchaseInvoiceService: PurchaseInvoiceService, public dialog: MatDialog,
    public profileService: ProfileService, public partyService : PartyService,
    private modal: NgbModal,
    private toastService: ToastNotificationService,
    private commonService: CommonService,
    private titleCasePipe : TitleCasePipe,  private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.invoiceTermsCon=localStorage.getItem('termsAndConditions');
    // Subscribe to the observable to receive updates
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });
    this.getAllPurchaseInvoice();
    this.partyService.partyAdded$.subscribe(() => {
      // Fetch the updated list
      this.getAllPurchaseInvoice();
    });
    //call profile get api
    this.getProfileData();
  }

  /**
   * get all invoice
   */
  getAllPurchaseInvoice() {
    this.purchaseInvoiceService.getAllPurchaseInvoice({}, this.currentUser.id,"PURCHASE",this.purchaseListPageSize, (this.currentPageNo + 1)).then((res) => {
      this.invoiceData = res.data.pageData;
      this.totalrow=res.data.totalrows
    },(err)=>{
      if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }

  getAllPurchaseinvoicebySearch(searchKey) {
    if (this.searchKey == "") {
      this.currentPageNo = 0;
      this.purchaseInvoiceService.getAllPurchaseInvoiceSearch({}, this.currentUser.id,"PURCHASE",this.purchaseListPageSize, (this.currentPageNo + 1),searchKey.trim()).then((res) => {
      this.invoiceData = res.data.pageData;
      this.totalrow=res.data.totalrows;
      if (this.currentPageNo > 0) {
        if (this.invoiceData.length === 0) {
          this.currentPageNo = 0;
          this.getAllPurchaseinvoicebySearch(this.searchKey);
        }
      }
    });
  }
  else {
    this.currentPageNo = 0;
    this.purchaseInvoiceService.getAllPurchaseInvoiceSearch({}, this.currentUser.id,"PURCHASE",this.purchaseListPageSize, (this.currentPageNo + 1),searchKey.trim()).then((res) => {
      this.invoiceData = res.data.pageData;
      this.totalrow=res.data.totalrows;
      if (this.currentPageNo > 0) {
        if (this.invoiceData.length === 0) {
          this.currentPageNo = 0;
          this.getAllPurchaseinvoicebySearch(this.searchKey);
        }
      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
      }
    })
  }

}

  getProfileData() {
    this.profileService.getUserProfile({}, this.currentUser.id).then((res => {
      this.imageSrc = res.data.basicdetails.profileImage,
      this.addressDetails = res.data.primaryAddress;
      this.basicDetails=res.data.basicdetails;
      this.BusinessName=res.data.additional.legalName;
      this.tradeName=res.data.additional.tradeName;
      this.mobile = res.data.basicdetails.mobile;
      this.gstinNo = res.data.additional.gstinNo;
      this.state = res.data.primaryAddress.state;
      this.signImg = res.data.basicdetails.addSignature;
    }),(err)=>{
      if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }

  /**
   * print receipt
   */
  onPrint() {
    const printContents = document.getElementById('panel').innerHTML;
    const originalContents = document.body.innerHTML;
    console.log("originalContents", originalContents)

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  modalDismiss() {
    this.modal.dismissAll();
  }

  /**
   * get receipt for invoive by id
   * @param receiptId
   */
  getReceiptById(receiptId) {
    this.purchaseInvoiceService.getPurchaseInvoiceById({}, receiptId).then((res) => {
      this.salesData= res.data;
      this.saleProductData = res.data.sales_products;
      this.invoiceNo = res.data.invoiceNo;
      this.invoiceDate = res.data.invoiceDate;
      this.dueDate = res.data.dueDate;
      this.billingAddress = res.data.billingAddress;
      this.shippingAddress = res.data.shippingAddress;
      this.billGstAmount = res.data.billGstAmount;
      this.totalGST = res.data.billGstAmount;
      this.totalBillAmount = res.data.totalBillAmount;
      this.placeOfSupply = res.data.placeOfSupply;
      this.finalAmount=this.totalBillAmount;
      this.hsn = res.data.sales_products[0].product.hsn;
      this.convertToWord(this.finalAmount)
      if(this.salesData.supplyType == 'INTERSTATE'){
        this.gstStateMatch = false;
      }else{
        this.gstStateMatch = true;
      }
    },(err)=>{
      if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }
  closeModal(){
  }
    // for pagination
    handlePage(e) {
      this.currentPageNo = e.pageIndex;
      this.pagesize = e.pageSize;
      if (this.invoiceData.length > 0) {
        this.purchaseListPageSize = e.pageSize;

      const searchInput = document.getElementById('searchInput') as HTMLInputElement;
      if (searchInput.value !== '') {
        this.getAllPurchaseinvoicebySearch(searchInput.value);
      } else {
        this.getAllPurchaseInvoice();
      }
      }
    }
    convertToWord(num) {
      if (!this.isArabic) {
        this.finalAmount = this.toWords.convert(num);
      }
      else {
        const toWords = new ToWords({
          converterOptions: {
            currency: false,
            ignoreDecimal: false,
            ignoreZeroCurrency: true,
          },
        });
        this.finalAmount = numberToWords.toWords(num);
        this.currencySymbol = 'AED';
        // this.finalAmount = toWords.convert(num);
        console.log(`${this.finalAmount} ${this.currencySymbol}`);

      }
    }

    openDialog(): void {
      const dialogRef: MatDialogRef<PurchaseInvoiceComponent> = this.dialog.open(PurchaseInvoiceComponent, {
        // width: '1050px',
        height : '730px',
        data: { },
        panelClass: 'custom-Modal-purchase-invoice',
        disableClose:true,
        position: {
          right: '0',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      });
    }

    downlaodAllData(){
      if(this.totalrow === undefined || this.totalrow === null){
        // this.toastService.openErrorSnackBar("No Data Available For Download");
        this.toastService.toastMsg({
          title: "Error",
          content: "No Data Available For Download",
        });
      } else {
            this.purchaseInvoiceService.getAllPurchaseInvoice({}, this.currentUser.id,"PURCHASE",this.totalrow, (this.currentPageNo + 1)).then((res) => {
              this.downloadData = res.data.pageData;
              if(this.downloadData.length > 0){
                this.exportToExcel()
              } else {
                // this.toastService.openErrorSnackBar("No Data Available For Download");
                this.toastService.toastMsg({
                  title: "Error",
                  content: "No Data Available For Download",
                });
              }
            },(err)=>{
              if(err.error.status === 500){
                // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
                this.toastService.toastMsg({
                  title: "Error",
                  content: this.titleCasePipe.transform(err.error.error_message),
                });
              }
            })
      }
    }

    exportToExcel() {
      if (this.isArabic) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');

        // Define a header style
        const headerStyle = {
          font: { color: { argb: 'FF0000FF' }, bold: true },
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFF00FF' } },
        };

        // Define your headers
        const headers = [
          'Invoice Number',
          'Date',
          'Due Date',
          'Customer Name',
          'Contact #',
          'Registered',
          'TRN  #',
          'Payment Status',
          'Invoice  Amount (In AED)',
          'VAT (in AED.)',
          'Billing Address',
          'Shipping Address',
        ];

        // Apply the header style to the header row
        const headerRow = worksheet.addRow(headers);
        headerRow.eachCell((cell) => {
          cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
          };
          cell.fill = headerStyle.fill;
          cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          //  cell.width = 120

        });
        // Set column widths for header cells
        worksheet.columns.forEach((column, index) => {
          // Assuming you want a fixed width of 120 for all header columns
          column.width = 15;
        });

        // Set background colors for multiple cells using a loop
        const cellBackgroundColors = [
          { cell: 'A1', color: 'FF8DB4E2', width: 5 }, // light blue background for cell A1
          { cell: 'B1', color: 'FF8DB4E2' },
          { cell: 'C1', color: 'FF8DB4E2' },
          { cell: 'D1', color: 'FFFFC4D5' }, //for orange
          { cell: 'E1', color: 'FFFFC4D5' },
          { cell: 'F1', color: 'FFFFC4D5' },
          { cell: 'G1', color: 'FFFFC4D5' },
          { cell: 'H1', color: 'FF8DB4E2' },
          { cell: 'I1', color: 'FF8DB4E2' },
          { cell: 'J1', color: 'FF8DB4E2' },
          { cell: 'K1', color: 'FFFFC4D5' },
          { cell: 'L1', color: 'FFFFC4D5' },

           // FF8DB4E2- blue color code  FFFFC4D5 Orange
        ];
        cellBackgroundColors.forEach((cellColor) => {
          worksheet.getCell(cellColor.cell).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: cellColor.color },
          };
        });

        this.downloadData.forEach((item) => {
          const row = [];
          // Add your data to the row
          row.push(item.invoiceNo);
          const formattedInvoiceDate = this.datePipe.transform(item.invoiceDate, 'dd MMM yyyy');
          row.push(formattedInvoiceDate ? formattedInvoiceDate : "NA");
          const dueDate = this.datePipe.transform(item.dueDate, 'dd MMM yyyy');
          row.push(dueDate ? dueDate : "NA");
          row.push(item.party ? item.party.partyName : "NA",)
          row.push(item.mobile)
          row.push((item && item.party?.gstIn) ? "Registered" : "Unregistered"),
            row.push((item && item.party?.gstIn) ? item.party.gstIn : "NA",)
          row.push(item.paymentStatus ? "Credit" : "Paid",),
            row.push(item.received,)
          const vatAmount = parseFloat(item.billGstAmount);
          row.push(vatAmount.toFixed(2));
          row.push(item.billingAddress,)
          row.push(item.shippingAddress);
          worksheet.addRow(row);
          const vatCell = worksheet.getCell('J' + worksheet.rowCount);

          // Set the alignment of the VAT cell to right
          vatCell.alignment = { horizontal: 'right' };

          // Get the cell in the VAT column for the current row

        });


        // Generate the Excel file
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, 'purchase_invoice_data.xlsx');
        });

      }
      else{
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');

        // Define a header style
        const headerStyle = {
          font: { color: { argb: 'FF0000FF' }, bold: true },
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFF00FF' } },
        };

        // Define your headers
        const headers = [
          'Invoice #',
          'Date',
          'Due Date',
          'Customer Name',
          'Contact #',
          'Registered',
          'GSTIN',
          'Payment Status',
          'Invoice  Amount (In Rs)',
          'GST (in Rs)',
          'Cess (in Rs.)',
          'Billing Address',
          'Shipping Address',
          'E-Way Bill #',
          'Reverse Charge',
          'Supply',
          'Supply to (State)'
        ];



        // Apply the header style to the header row
        const headerRow = worksheet.addRow(headers);
        headerRow.eachCell((cell) => {
          cell.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
          };
          cell.fill = headerStyle.fill;
          cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          //  cell.width = 120

        });
        // Set column widths for header cells
        worksheet.columns.forEach((column, index) => {
          // Assuming you want a fixed width of 120 for all header columns
          column.width = 15;
        });

        // Set background colors for multiple cells using a loop
        const cellBackgroundColors = [
          { cell: 'A1', color: 'FF8DB4E2', width: 5 }, // light blue background for cell A1
          { cell: 'B1', color: 'FF8DB4E2' },
          { cell: 'C1', color: 'FF8DB4E2' },
          { cell: 'D1', color: 'FFFFC4D5' }, //for orange
          { cell: 'E1', color: 'FFFFC4D5' },
          { cell: 'F1', color: 'FFFFC4D5' },
          { cell: 'G1', color: 'FFFFC4D5' },
          { cell: 'H1', color: 'FF8DB4E2' },
          { cell: 'I1', color: 'FF8DB4E2' },
          { cell: 'J1', color: 'FF8DB4E2' },
          { cell: 'K1', color: 'FF8DB4E2' },
          { cell: 'L1', color: 'FFFFC4D5' },
          { cell: 'M1', color: 'FFFFC4D5' },
          { cell: 'N1', color: 'FF8DB4E2' },
          { cell: 'O1', color: 'FF8DB4E2' },
          { cell: 'P1', color: 'FF8DB4E2' },
          { cell: 'Q1', color: 'FF8DB4E2' },

          // FF8DB4E2- blue color code  FFFFC4D5 Orange

        ];
        cellBackgroundColors.forEach((cellColor) => {
          worksheet.getCell(cellColor.cell).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: cellColor.color },
          };
        });





        this.downloadData.forEach((item) => {
          const row = [];
          // Add your data to the row
          row.push(item.invoiceNo);
          const formattedInvoiceDate = this.datePipe.transform(item.invoiceDate, 'dd MMM yyyy');
          row.push(formattedInvoiceDate ? formattedInvoiceDate : "NA");
          const dueDate = this.datePipe.transform(item.dueDate, 'dd MMM yyyy');
          row.push(dueDate ? dueDate : "NA");
          row.push(item.party ? item.party.partyName : "NA",)
          row.push(item.mobile)
          row.push((item && item.party?.gstIn) ? "Registered" : "Unregistered"),
            row.push((item && item.party?.gstIn) ? item.party.gstIn : "NA",)
          row.push(item.paymentStatus ? "Credit" : "Paid",),
            row.push(item.received,)
          const vatAmount = parseFloat(item.billGstAmount);
          row.push(vatAmount.toFixed(2));
          row.push(item.totalCess,)
          row.push(item.billingAddress,)
          row.push(item.shippingAddress);
          row.push(item.ewayBillNo);
          row.push(item.reverseCharge ? "Applicable" : "Not Applicable",)
          row.push(item.supplyType)
          row.push(item.placeOfSupply ? item.placeOfSupply : 'NA')


          worksheet.addRow(row);
          const vatCell = worksheet.getCell('J' + worksheet.rowCount);

          // Set the alignment of the VAT cell to right
          vatCell.alignment = { horizontal: 'right' };


        });


        // Generate the Excel file
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, 'purchase_invoice_data.xlsx');
        });


      }
    }
    getTextSize(selectedTextSize: string): number {
      switch (selectedTextSize) {
        case 'Large':
        return 22;
        case 'Medium':
        return 20;
        case 'Small':
        return 18;
        default:
        return 20; // Default to 'Medium' size if an invalid option is selected
      }
      }
  
      getTextSizeprint(TextSizeprint: string): number {
      switch (TextSizeprint) {
        case 'Large':
        return 16;
        case 'Medium':
        return 14;
        case 'Small':
        return 12;
        default:
        return 14; // Default to 'Medium' size if an invalid option is selected
      }
      }

}
