<div class="card card-chat overflow-hidden">
    <div class="card-body d-flex p-0 h-100">
        <div class="chat-sidebar">
            <div class="contacts-list scrollbar-overlay">
                <div class="nav nav-tabs border-0 flex-column" role="tablist" aria-orientation="vertical">

                    <div class="hover-actions-trigger chat-contact nav-item active" role="tab" id="chat-link-0"
                        data-bs-toggle="tab" data-bs-target="#chat-0" aria-controls="chat-0" aria-selected="true">
                        <div class="d-md-none d-lg-block">
                            <div class="dropdown dropdown-active-trigger dropdown-chat">
                                <button
                                    class="hover-actions btn btn-link btn-sm text-400 dropdown-caret-none dropdown-toggle end-0 fs-0 mt-4 me-1 z-index-1 pb-2 mb-n2"
                                    type="button" data-boundary="viewport" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false"><span class="fas fa-cog"
                                        data-fa-transform="shrink-3 down-4"></span></button>
                                <div class="dropdown-menu dropdown-menu-end border py-2 rounded-2"><a
                                        class="dropdown-item" href="#!">Mute</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Archive</a><a
                                        class="dropdown-item text-danger" href="#!">Delete</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Mark as
                                        Unread</a><a class="dropdown-item" href="#!">Something's Wrong</a><a
                                        class="dropdown-item" href="#!">Ignore Messsages</a><a class="dropdown-item"
                                        href="#!">Block Messages</a>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-xl status-online">
                                <img class="rounded-circle" src="../assets/img/team/2.jpg" alt="" />

                            </div>
                            <div class="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
                                <div class="d-flex justify-content-between">
                                    <h6 class="mb-0 chat-contact-title">Antony Hopkins</h6><span
                                        class="message-time fs--2">Tue</span>
                                </div>
                                <div class="min-w-0">
                                    <div class="chat-contact-content pe-3">Antony
                                        sent
                                        6 photos
                                    </div>
                                    <div class="position-absolute bottom-0 end-0 hover-hide">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="hover-actions-trigger chat-contact nav-item" role="tab" id="chat-link-1"
                        data-bs-toggle="tab" data-bs-target="#chat-1" aria-controls="chat-1" aria-selected="false">
                        <div class="d-md-none d-lg-block">
                            <div class="dropdown dropdown-active-trigger dropdown-chat">
                                <button
                                    class="hover-actions btn btn-link btn-sm text-400 dropdown-caret-none dropdown-toggle end-0 fs-0 mt-4 me-1 z-index-1 pb-2 mb-n2"
                                    type="button" data-boundary="viewport" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false"><span class="fas fa-cog"
                                        data-fa-transform="shrink-3 down-4"></span></button>
                                <div class="dropdown-menu dropdown-menu-end border py-2 rounded-2"><a
                                        class="dropdown-item" href="#!">Mute</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Archive</a><a
                                        class="dropdown-item text-danger" href="#!">Delete</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Mark as
                                        Unread</a><a class="dropdown-item" href="#!">Something's Wrong</a><a
                                        class="dropdown-item" href="#!">Ignore Messsages</a><a class="dropdown-item"
                                        href="#!">Block Messages</a>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-xl">
                                <div class="rounded-circle overflow-hidden h-100 d-flex">
                                    <div class="w-50 border-end"><img src="../assets/img/team/1.jpg" alt="" /></div>
                                    <div class="w-50 d-flex flex-column"><img class="h-50 border-bottom"
                                            src="../assets/img/team/2.jpg" alt="" /><img class="h-50"
                                            src="../assets/img/team/3.jpg" alt="" /></div>
                                </div>
                            </div>
                            <div class="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
                                <div class="d-flex justify-content-between">
                                    <h6 class="mb-0 chat-contact-title">Avengers</h6><span
                                        class="message-time fs--2">Sun</span>
                                </div>
                                <div class="min-w-0">
                                    <div class="chat-contact-content pe-3">Bucky: <a href="#!"
                                            class="text-primary">@Emma</a> What do you think about the plan?
                                    </div>
                                    <div class="position-absolute bottom-0 end-0 hover-hide"><span
                                            class="fas fa-check text-success"
                                            data-fa-transform="shrink-5 down-4"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="hover-actions-trigger chat-contact nav-item" role="tab" id="chat-link-2"
                        data-bs-toggle="tab" data-bs-target="#chat-2" aria-controls="chat-2" aria-selected="false">
                        <div class="d-md-none d-lg-block">
                            <div class="dropdown dropdown-active-trigger dropdown-chat">
                                <button
                                    class="hover-actions btn btn-link btn-sm text-400 dropdown-caret-none dropdown-toggle end-0 fs-0 mt-4 me-1 z-index-1 pb-2 mb-n2"
                                    type="button" data-boundary="viewport" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false"><span class="fas fa-cog"
                                        data-fa-transform="shrink-3 down-4"></span></button>
                                <div class="dropdown-menu dropdown-menu-end border py-2 rounded-2"><a
                                        class="dropdown-item" href="#!">Mute</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Archive</a><a
                                        class="dropdown-item text-danger" href="#!">Delete</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Mark as
                                        Unread</a><a class="dropdown-item" href="#!">Something's Wrong</a><a
                                        class="dropdown-item" href="#!">Ignore Messsages</a><a class="dropdown-item"
                                        href="#!">Block Messages</a>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-xl">
                                <img class="rounded-circle" src="../assets/img/team/1.jpg" alt="" />

                            </div>
                            <div class="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
                                <div class="d-flex justify-content-between">
                                    <h6 class="mb-0 chat-contact-title">Emma Watson</h6><span
                                        class="message-time fs--2">Sun</span>
                                </div>
                                <div class="min-w-0">
                                    <div class="chat-contact-content pe-3">You: 🙂
                                    </div>
                                    <div class="position-absolute bottom-0 end-0 hover-hide"><span
                                            class="fas fa-check text-success"
                                            data-fa-transform="shrink-5 down-4"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="hover-actions-trigger chat-contact nav-item unread-message" role="tab" id="chat-link-3"
                        data-bs-toggle="tab" data-bs-target="#chat-3" aria-controls="chat-3" aria-selected="false">
                        <div class="d-md-none d-lg-block">
                            <div class="dropdown dropdown-active-trigger dropdown-chat">
                                <button
                                    class="hover-actions btn btn-link btn-sm text-400 dropdown-caret-none dropdown-toggle end-0 fs-0 mt-4 me-1 z-index-1 pb-2 mb-n2"
                                    type="button" data-boundary="viewport" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false"><span class="fas fa-cog"
                                        data-fa-transform="shrink-3 down-4"></span></button>
                                <div class="dropdown-menu dropdown-menu-end border py-2 rounded-2"><a
                                        class="dropdown-item" href="#!">Mute</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Archive</a><a
                                        class="dropdown-item text-danger" href="#!">Delete</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Mark as
                                        Unread</a><a class="dropdown-item" href="#!">Something's Wrong</a><a
                                        class="dropdown-item" href="#!">Ignore Messsages</a><a class="dropdown-item"
                                        href="#!">Block Messages</a>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-xl status-online">
                                <img class="rounded-circle" src="../assets/img/team/13.jpg" alt="" />

                            </div>
                            <div class="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
                                <div class="d-flex justify-content-between">
                                    <h6 class="mb-0 chat-contact-title">Anna Karinina</h6><span
                                        class="message-time fs--2">Sat</span>
                                </div>
                                <div class="min-w-0">
                                    <div class="chat-contact-content pe-3">What are you doing?
                                    </div>
                                    <div class="position-absolute bottom-0 end-0 hover-hide">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="hover-actions-trigger chat-contact nav-item unread-message" role="tab" id="chat-link-4"
                        data-bs-toggle="tab" data-bs-target="#chat-4" aria-controls="chat-4" aria-selected="false">
                        <div class="d-md-none d-lg-block">
                            <div class="dropdown dropdown-active-trigger dropdown-chat">
                                <button
                                    class="hover-actions btn btn-link btn-sm text-400 dropdown-caret-none dropdown-toggle end-0 fs-0 mt-4 me-1 z-index-1 pb-2 mb-n2"
                                    type="button" data-boundary="viewport" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false"><span class="fas fa-cog"
                                        data-fa-transform="shrink-3 down-4"></span></button>
                                <div class="dropdown-menu dropdown-menu-end border py-2 rounded-2"><a
                                        class="dropdown-item" href="#!">Mute</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Archive</a><a
                                        class="dropdown-item text-danger" href="#!">Delete</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Mark as
                                        Unread</a><a class="dropdown-item" href="#!">Something's Wrong</a><a
                                        class="dropdown-item" href="#!">Ignore Messsages</a><a class="dropdown-item"
                                        href="#!">Block Messages</a>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-xl status-online">
                                <img class="rounded-circle" src="../assets/img/team/4.jpg" alt="" />

                            </div>
                            <div class="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
                                <div class="d-flex justify-content-between">
                                    <h6 class="mb-0 chat-contact-title">John Lee</h6><span
                                        class="message-time fs--2">Mon</span>
                                </div>
                                <div class="min-w-0">
                                    <div class="chat-contact-content pe-3">How are you?
                                    </div>
                                    <div class="position-absolute bottom-0 end-0 hover-hide">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="hover-actions-trigger chat-contact nav-item" role="tab" id="chat-link-5"
                        data-bs-toggle="tab" data-bs-target="#chat-5" aria-controls="chat-5" aria-selected="false">
                        <div class="d-md-none d-lg-block">
                            <div class="dropdown dropdown-active-trigger dropdown-chat">
                                <button
                                    class="hover-actions btn btn-link btn-sm text-400 dropdown-caret-none dropdown-toggle end-0 fs-0 mt-4 me-1 z-index-1 pb-2 mb-n2"
                                    type="button" data-boundary="viewport" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false"><span class="fas fa-cog"
                                        data-fa-transform="shrink-3 down-4"></span></button>
                                <div class="dropdown-menu dropdown-menu-end border py-2 rounded-2"><a
                                        class="dropdown-item" href="#!">Mute</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Archive</a><a
                                        class="dropdown-item text-danger" href="#!">Delete</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Mark as
                                        Unread</a><a class="dropdown-item" href="#!">Something's Wrong</a><a
                                        class="dropdown-item" href="#!">Ignore Messsages</a><a class="dropdown-item"
                                        href="#!">Block Messages</a>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-xl status-online">
                                <div class="avatar-name rounded-circle"><span>BR</span></div>
                            </div>
                            <div class="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
                                <div class="d-flex justify-content-between">
                                    <h6 class="mb-0 chat-contact-title">Bucky Robert</h6><span
                                        class="message-time fs--2">Wed</span>
                                </div>
                                <div class="min-w-0">
                                    <div class="chat-contact-content pe-3">You: 👋
                                    </div>
                                    <div class="position-absolute bottom-0 end-0 hover-hide"><span
                                            class="fas fa-check text-400" data-fa-transform="shrink-5 down-4"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="hover-actions-trigger chat-contact nav-item" role="tab" id="chat-link-6"
                        data-bs-toggle="tab" data-bs-target="#chat-6" aria-controls="chat-6" aria-selected="false">
                        <div class="d-md-none d-lg-block">
                            <div class="dropdown dropdown-active-trigger dropdown-chat">
                                <button
                                    class="hover-actions btn btn-link btn-sm text-400 dropdown-caret-none dropdown-toggle end-0 fs-0 mt-4 me-1 z-index-1 pb-2 mb-n2"
                                    type="button" data-boundary="viewport" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false"><span class="fas fa-cog"
                                        data-fa-transform="shrink-3 down-4"></span></button>
                                <div class="dropdown-menu dropdown-menu-end border py-2 rounded-2"><a
                                        class="dropdown-item" href="#!">Mute</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Archive</a><a
                                        class="dropdown-item text-danger" href="#!">Delete</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Mark as
                                        Unread</a><a class="dropdown-item" href="#!">Something's Wrong</a><a
                                        class="dropdown-item" href="#!">Ignore Messsages</a><a class="dropdown-item"
                                        href="#!">Block Messages</a>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-xl status-online">
                                <img class="rounded-circle" src="../assets/img/team/10.jpg" alt="" />

                            </div>
                            <div class="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
                                <div class="d-flex justify-content-between">
                                    <h6 class="mb-0 chat-contact-title">John Bradley</h6><span
                                        class="message-time fs--2">Fri</span>
                                </div>
                                <div class="min-w-0">
                                    <div class="chat-contact-content pe-3">Hello!
                                    </div>
                                    <div class="position-absolute bottom-0 end-0 hover-hide">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="hover-actions-trigger chat-contact nav-item" role="tab" id="chat-link-7"
                        data-bs-toggle="tab" data-bs-target="#chat-7" aria-controls="chat-7" aria-selected="false">
                        <div class="d-md-none d-lg-block">
                            <div class="dropdown dropdown-active-trigger dropdown-chat">
                                <button
                                    class="hover-actions btn btn-link btn-sm text-400 dropdown-caret-none dropdown-toggle end-0 fs-0 mt-4 me-1 z-index-1 pb-2 mb-n2"
                                    type="button" data-boundary="viewport" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false"><span class="fas fa-cog"
                                        data-fa-transform="shrink-3 down-4"></span></button>
                                <div class="dropdown-menu dropdown-menu-end border py-2 rounded-2"><a
                                        class="dropdown-item" href="#!">Mute</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Archive</a><a
                                        class="dropdown-item text-danger" href="#!">Delete</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Mark as
                                        Unread</a><a class="dropdown-item" href="#!">Something's Wrong</a><a
                                        class="dropdown-item" href="#!">Ignore Messsages</a><a class="dropdown-item"
                                        href="#!">Block Messages</a>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-xl status-online">
                                <img class="rounded-circle" src="../assets/img/team/11.jpg" alt="" />

                            </div>
                            <div class="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
                                <div class="d-flex justify-content-between">
                                    <h6 class="mb-0 chat-contact-title">Rory McCann</h6><span
                                        class="message-time fs--2">Sun</span>
                                </div>
                                <div class="min-w-0">
                                    <div class="chat-contact-content pe-3">You: 😮
                                    </div>
                                    <div class="position-absolute bottom-0 end-0 hover-hide"><span
                                            class="fas fa-check text-400" data-fa-transform="shrink-5 down-4"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="hover-actions-trigger chat-contact nav-item" role="tab" id="chat-link-8"
                        data-bs-toggle="tab" data-bs-target="#chat-8" aria-controls="chat-8" aria-selected="false">
                        <div class="d-md-none d-lg-block">
                            <div class="dropdown dropdown-active-trigger dropdown-chat">
                                <button
                                    class="hover-actions btn btn-link btn-sm text-400 dropdown-caret-none dropdown-toggle end-0 fs-0 mt-4 me-1 z-index-1 pb-2 mb-n2"
                                    type="button" data-boundary="viewport" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false"><span class="fas fa-cog"
                                        data-fa-transform="shrink-3 down-4"></span></button>
                                <div class="dropdown-menu dropdown-menu-end border py-2 rounded-2"><a
                                        class="dropdown-item" href="#!">Mute</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Archive</a><a
                                        class="dropdown-item text-danger" href="#!">Delete</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Mark as
                                        Unread</a><a class="dropdown-item" href="#!">Something's Wrong</a><a
                                        class="dropdown-item" href="#!">Ignore Messsages</a><a class="dropdown-item"
                                        href="#!">Block Messages</a>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-xl">
                                <img class="rounded-circle" src="../assets/img/team/12.jpg" alt="" />

                            </div>
                            <div class="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
                                <div class="d-flex justify-content-between">
                                    <h6 class="mb-0 chat-contact-title">Joe Bempsie</h6><span
                                        class="message-time fs--2">Wed</span>
                                </div>
                                <div class="min-w-0">
                                    <div class="chat-contact-content pe-3">Hello!
                                    </div>
                                    <div class="position-absolute bottom-0 end-0 hover-hide">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="hover-actions-trigger chat-contact nav-item" role="tab" id="chat-link-9"
                        data-bs-toggle="tab" data-bs-target="#chat-9" aria-controls="chat-9" aria-selected="false">
                        <div class="d-md-none d-lg-block">
                            <div class="dropdown dropdown-active-trigger dropdown-chat">
                                <button
                                    class="hover-actions btn btn-link btn-sm text-400 dropdown-caret-none dropdown-toggle end-0 fs-0 mt-4 me-1 z-index-1 pb-2 mb-n2"
                                    type="button" data-boundary="viewport" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false"><span class="fas fa-cog"
                                        data-fa-transform="shrink-3 down-4"></span></button>
                                <div class="dropdown-menu dropdown-menu-end border py-2 rounded-2"><a
                                        class="dropdown-item" href="#!">Mute</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Archive</a><a
                                        class="dropdown-item text-danger" href="#!">Delete</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Mark as
                                        Unread</a><a class="dropdown-item" href="#!">Something's Wrong</a><a
                                        class="dropdown-item" href="#!">Ignore Messsages</a><a class="dropdown-item"
                                        href="#!">Block Messages</a>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-xl">
                                <img class="rounded-circle" src="../assets/img/team/22.jpg" alt="" />

                            </div>
                            <div class="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
                                <div class="d-flex justify-content-between">
                                    <h6 class="mb-0 chat-contact-title">Diana Rigg</h6><span
                                        class="message-time fs--2">Mon</span>
                                </div>
                                <div class="min-w-0">
                                    <div class="chat-contact-content pe-3">You: Yes, in an organization stature, this is
                                        a must. Besides, we need to quickly establish all other professional
                                        appearances, e.g., having a website where members’ profile will be displayed
                                        along with additional organizational information. Providing services to existing
                                        members is more important than attracting new members at this moment, in my
                                        opinion..
                                    </div>
                                    <div class="position-absolute bottom-0 end-0 hover-hide"><span
                                            class="fas fa-check text-400" data-fa-transform="shrink-5 down-4"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="hover-actions-trigger chat-contact nav-item" role="tab" id="chat-link-10"
                        data-bs-toggle="tab" data-bs-target="#chat-10" aria-controls="chat-10" aria-selected="false">
                        <div class="d-md-none d-lg-block">
                            <div class="dropdown dropdown-active-trigger dropdown-chat">
                                <button
                                    class="hover-actions btn btn-link btn-sm text-400 dropdown-caret-none dropdown-toggle end-0 fs-0 mt-4 me-1 z-index-1 pb-2 mb-n2"
                                    type="button" data-boundary="viewport" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false"><span class="fas fa-cog"
                                        data-fa-transform="shrink-3 down-4"></span></button>
                                <div class="dropdown-menu dropdown-menu-end border py-2 rounded-2"><a
                                        class="dropdown-item" href="#!">Mute</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Archive</a><a
                                        class="dropdown-item text-danger" href="#!">Delete</a>
                                    <div class="dropdown-divider"></div><a class="dropdown-item" href="#!">Mark as
                                        Unread</a><a class="dropdown-item" href="#!">Something's Wrong</a><a
                                        class="dropdown-item" href="#!">Ignore Messsages</a><a class="dropdown-item"
                                        href="#!">Block Messages</a>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-xl">
                                <img class="rounded-circle" src="../assets/img/team/23.jpg" alt="" />

                            </div>
                            <div class="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
                                <div class="d-flex justify-content-between">
                                    <h6 class="mb-0 chat-contact-title">Gemma Whelan</h6>
                                </div>
                                <div class="min-w-0">
                                    <div class="chat-contact-content pe-3">Say hi to your new friend
                                    </div>
                                    <div class="position-absolute bottom-0 end-0 hover-hide">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <form class="contacts-search-wrapper">
                <div class="form-group mb-0 position-relative d-md-none d-lg-block w-100 h-100">
                    <input class="form-control form-control-sm chat-contacts-search border-0 h-100" type="text"
                        placeholder="Search contacts ..." /><span class="fas fa-search contacts-search-icon"></span>
                </div>
                <button class="btn btn-sm btn-transparent d-none d-md-inline-block d-lg-none"><span
                        class="fas fa-search fs--1"></span></button>
            </form>
        </div>
        <div class="tab-content card-chat-content">
            <div class="tab-pane card-chat-pane active" id="chat-0" role="tabpanel" aria-labelledby="chat-link-0">
                <div class="chat-content-header">
                    <div class="row flex-between-center">
                        <div class="col-6 col-sm-8 d-flex align-items-center"><a
                                class="pe-3 text-700 d-md-none contacts-list-show" href="#!">
                                <div class="fas fa-chevron-left"></div>
                            </a>
                            <div class="min-w-0">
                                <h5 class="mb-0 text-truncate fs-0">Antony Hopkins</h5>
                                <div class="fs--2 text-400">Active On Chat
                                </div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="0"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Call"><span
                                    class="fas fa-phone"></span></button>
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="0"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Video Call"><span
                                    class="fas fa-video"></span></button>
                            <button class="btn btn-sm btn-falcon-primary btn-info" type="button" data-index="0"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Conversation Information"><span
                                    class="fas fa-info"></span></button>
                        </div>
                    </div>
                </div>
                <div class="chat-content-body" style="display: inherit;">
                    <div class="conversation-info" data-index="0">
                        <div class="h-100 overflow-auto scrollbar">
                            <div class="d-flex position-relative align-items-center p-3 border-bottom">
                                <div class="avatar avatar-xl status-online">
                                    <img class="rounded-circle" src="../assets/img/team/2.jpg" alt="" />

                                </div>
                                <div class="flex-1 ms-2 d-flex flex-between-center">
                                    <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                            href="../pages/user/profile.html">Antony Hopkins</a></h6>
                                    <div class="dropdown z-index-1">
                                        <button
                                            class="btn btn-link btn-sm text-400 dropdown-toggle dropdown-caret-none me-n3"
                                            type="button" id="profile-dropdown-0" data-bs-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false"><span
                                                class="fas fa-cog"></span></button>
                                        <div class="dropdown-menu dropdown-menu-end border py-2"
                                            aria-labelledby="profile-dropdown-0"><a class="dropdown-item"
                                                href="#!">Mute</a>
                                            <div class="dropdown-divider"></div><a class="dropdown-item"
                                                href="#!">Archive</a><a class="dropdown-item text-danger"
                                                href="#!">Delete</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="px-3 pt-2">
                                <div class="nav flex-column font-sans-serif fw-medium"><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-search me-1"
                                                data-fa-transform="shrink-1 down-3"></span></span>Search in
                                        Conversation</a><a class="nav-link d-flex align-items-center py-1 px-0 text-600"
                                        href="#!"><span class="conversation-info-icon"><span
                                                class="fas fa-pencil-alt me-1"
                                                data-fa-transform="shrink-1"></span></span>Edit Nicknames</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-palette me-1"
                                                data-fa-transform="shrink-1"></span></span><span>Change
                                            Color</span></a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-thumbs-up me-1"
                                                data-fa-transform="shrink-1"></span></span>Change Emoji</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-bell me-1"
                                                data-fa-transform="shrink-1"></span></span>Notifications</a></div>
                            </div>
                            <hr class="my-2" />
                            <div class="px-3" id="others-info-0">
                                <div class="title" id="shared-media-title-0"><a
                                        class="btn btn-link btn-accordion hover-text-decoration-none dropdown-indicator"
                                        href="#shared-media-0" data-bs-toggle="collapse" aria-expanded="false"
                                        aria-controls="shared-media-0">Shared media</a></div>
                                <div class="collapse" id="shared-media-0" aria-labelledby="shared-media-title-0"
                                    data-parent="#others-info-0">
                                    <div class="row mx-n1">
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/1.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/1.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/2.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/2.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/3.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/3.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/4.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/4.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/5.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/5.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/6.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/6.jpg" alt="" /></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chat-content-scroll-area scrollbar">
                        <div class="d-flex position-relative p-3 border-bottom mb-3 align-items-center">
                            <div class="avatar avatar-2xl status-online me-3">
                                <img class="rounded-circle" src="../assets/img/team/2.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                        href="../pages/user/profile.html">Antony Hopkins</a></h6>
                                <p class="mb-0">You friends with Antony Hopkins. Say hi to start the conversation
                                </p>
                            </div>
                        </div>
                        <div class="text-center fs--2 text-500"><span>May 5, 2019, 11:54 am</span></div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/2.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message bg-200 p-2 rounded-2">Yes, in an organization stature,
                                            this is a must. Besides, we need to quickly establish all other professional
                                            appearances, e.g., having a website where members’ profile will be displayed
                                            along with additional organizational information. Providing services to
                                            existing members is more important than attracting new members at this
                                            moment, in my opinion..</div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span>11:54 am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/2.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message bg-200 p-2 rounded-2">What are you doing?</div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span>11:54 am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="flex-1 d-flex justify-content-end">
                                <div class="w-100 w-xxl-75">
                                    <div class="hover-actions-trigger d-flex flex-end-center">
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 me-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                        <div class="bg-primary text-white p-2 rounded-2 chat-message light">
                                            <p class="mb-0">I took this pic </p>
                                            <a href="../assets/img/chat/1.jpg" data-gallery="gallery-3">
                                                <img class="rounded" src="../assets/img/chat/1.jpg" alt="" width="150">
                                            </a>

                                        </div>
                                    </div>
                                    <div class="text-400 fs--2 text-end">11:54 am<span
                                            class="fas fa-check ms-2 text-success"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="flex-1 d-flex justify-content-end">
                                <div class="w-100 w-xxl-75">
                                    <div class="hover-actions-trigger d-flex flex-end-center">
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 me-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                        <div class="bg-primary text-white p-2 rounded-2 chat-message light">Nothing!
                                        </div>
                                    </div>
                                    <div class="text-400 fs--2 text-end">11:54 am<span
                                            class="fas fa-check ms-2 text-success"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center fs--2 text-500"><span>May 6, 2019, 11:54 am</span></div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/2.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message bg-200 p-2 rounded-2">How are you?</div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span>11:54 am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="flex-1 d-flex justify-content-end">
                                <div class="w-100 w-xxl-75">
                                    <div class="hover-actions-trigger d-flex flex-end-center">
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 me-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                        <div class="bg-primary text-white p-2 rounded-2 chat-message light">Fine
                                        </div>
                                    </div>
                                    <div class="text-400 fs--2 text-end">11:54 am<span
                                            class="fas fa-check-double ms-2"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center fs--2 text-500"><span>May 7, 2019, 11:54 am</span></div>
                        <div class="d-flex p-3">
                            <div class="flex-1 d-flex justify-content-end">
                                <div class="w-100 w-xxl-75">
                                    <div class="hover-actions-trigger d-flex flex-end-center">
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 me-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                        <div class="chat-message chat-gallery">
                                            <div class="row mx-n1">
                                                <div class="col-6 col-md-4 px-1" style="min-width: 50px;">
                                                    <a href="../assets/img/chat/7.jpg" data-gallery="gallery-1"><img
                                                            src="../assets/img/chat/7.jpg" alt=""
                                                            class="img-fluid rounded mb-2"></a>
                                                </div>
                                                <div class="col-6 col-md-4 px-1" style="min-width: 50px;">
                                                    <a href="../assets/img/chat/8.jpg" data-gallery="gallery-1"><img
                                                            src="../assets/img/chat/8.jpg" alt=""
                                                            class="img-fluid rounded mb-2"></a>
                                                </div>
                                                <div class="col-6 col-md-4 px-1" style="min-width: 50px;">
                                                    <a href="../assets/img/chat/9.jpg" data-gallery="gallery-1"><img
                                                            src="../assets/img/chat/9.jpg" alt=""
                                                            class="img-fluid rounded mb-2"></a>
                                                </div>
                                                <div class="col-6 col-md-4 px-1" style="min-width: 50px;">
                                                    <a href="../assets/img/chat/10.jpg" data-gallery="gallery-1"><img
                                                            src="../assets/img/chat/10.jpg" alt=""
                                                            class="img-fluid rounded mb-2 mb-lg-0"></a>
                                                </div>
                                                <div class="col-6 col-md-4 px-1" style="min-width: 50px;">
                                                    <a href="../assets/img/chat/11.jpg" data-gallery="gallery-1"><img
                                                            src="../assets/img/chat/11.jpg" alt=""
                                                            class="img-fluid rounded mb-2 mb-lg-0"></a>
                                                </div>
                                                <div class="col-6 col-md-4 px-1" style="min-width: 50px;">
                                                    <a href="../assets/img/chat/12.jpg" data-gallery="gallery-1"><img
                                                            src="../assets/img/chat/12.jpg" alt=""
                                                            class="img-fluid rounded"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-400 fs--2 text-end">11:54 am<span class="fas fa-check ms-2"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/2.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message bg-200 p-2 rounded-2">I took some excellent images
                                            yesterday.</div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span>11:54 am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center fs--2 text-500"><span>May 8, 2019, 11:54 am</span></div>
                        <div class="d-flex p-3">
                            <div class="flex-1 d-flex justify-content-end">
                                <div class="w-100 w-xxl-75">
                                    <div class="hover-actions-trigger d-flex flex-end-center">
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 me-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                        <div class="bg-primary text-white p-2 rounded-2 chat-message light">Give me the
                                            images.
                                        </div>
                                    </div>
                                    <div class="text-400 fs--2 text-end">11:54 am
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/2.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message chat-gallery">
                                            <div class="row mx-n1">
                                                <div class="col-6 col-md-4 px-1" style="min-width: 50px;">
                                                    <a href="../assets/img/chat/1.jpg" data-gallery="gallery-2"><img
                                                            src="../assets/img/chat/1.jpg" alt=""
                                                            class="img-fluid rounded mb-2"></a>
                                                </div>
                                                <div class="col-6 col-md-4 px-1" style="min-width: 50px;">
                                                    <a href="../assets/img/chat/2.jpg" data-gallery="gallery-2"><img
                                                            src="../assets/img/chat/2.jpg" alt=""
                                                            class="img-fluid rounded mb-2"></a>
                                                </div>
                                                <div class="col-6 col-md-4 px-1" style="min-width: 50px;">
                                                    <a href="../assets/img/chat/3.jpg" data-gallery="gallery-2"><img
                                                            src="../assets/img/chat/3.jpg" alt=""
                                                            class="img-fluid rounded mb-2"></a>
                                                </div>
                                                <div class="col-6 col-md-4 px-1" style="min-width: 50px;">
                                                    <a href="../assets/img/chat/4.jpg" data-gallery="gallery-2"><img
                                                            src="../assets/img/chat/4.jpg" alt=""
                                                            class="img-fluid rounded mb-2 mb-lg-0"></a>
                                                </div>
                                                <div class="col-6 col-md-4 px-1" style="min-width: 50px;">
                                                    <a href="../assets/img/chat/5.jpg" data-gallery="gallery-2"><img
                                                            src="../assets/img/chat/5.jpg" alt=""
                                                            class="img-fluid rounded mb-2 mb-lg-0"></a>
                                                </div>
                                                <div class="col-6 col-md-4 px-1" style="min-width: 50px;">
                                                    <a href="../assets/img/chat/6.jpg" data-gallery="gallery-2"><img
                                                            src="../assets/img/chat/6.jpg" alt=""
                                                            class="img-fluid rounded"></a>
                                                </div>
                                            </div>
                                        </div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span>11:54 am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane card-chat-pane" id="chat-1" role="tabpanel" aria-labelledby="chat-link-1">
                <div class="chat-content-header">
                    <div class="row flex-between-center">
                        <div class="col-6 col-sm-8 d-flex align-items-center"><a
                                class="pe-3 text-700 d-md-none contacts-list-show" href="#!">
                                <div class="fas fa-chevron-left"></div>
                            </a>
                            <div class="min-w-0">
                                <h5 class="mb-0 text-truncate fs-0">Avengers</h5>
                                <div class="fs--2 text-400">Active 7h ago
                                </div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="1"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Call"><span
                                    class="fas fa-phone"></span></button>
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="1"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Video Call"><span
                                    class="fas fa-video"></span></button>
                            <button class="btn btn-sm btn-falcon-primary btn-info" type="button" data-index="1"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Conversation Information"><span
                                    class="fas fa-info"></span></button>
                        </div>
                    </div>
                </div>
                <div class="chat-content-body" style="display: inherit;">
                    <div class="conversation-info" data-index="1">
                        <div class="h-100 overflow-auto scrollbar">
                            <div class="d-flex position-relative align-items-center p-3 border-bottom">
                                <div class="avatar avatar-xl">
                                    <div class="rounded-circle overflow-hidden h-100 d-flex">
                                        <div class="w-50 border-end"><img src="../assets/img/team/1.jpg" alt="" /></div>
                                        <div class="w-50 d-flex flex-column"><img class="h-50 border-bottom"
                                                src="../assets/img/team/2.jpg" alt="" /><img class="h-50"
                                                src="../assets/img/team/3.jpg" alt="" /></div>
                                    </div>
                                </div>
                                <div class="flex-1 ms-2 d-flex flex-between-center">
                                    <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                            href="../pages/user/profile.html">Avengers</a></h6>
                                    <div class="dropdown z-index-1">
                                        <button
                                            class="btn btn-link btn-sm text-400 dropdown-toggle dropdown-caret-none me-n3"
                                            type="button" id="profile-dropdown-1" data-bs-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false"><span
                                                class="fas fa-cog"></span></button>
                                        <div class="dropdown-menu dropdown-menu-end border py-2"
                                            aria-labelledby="profile-dropdown-1"><a class="dropdown-item"
                                                href="#!">Mute</a>
                                            <div class="dropdown-divider"></div><a class="dropdown-item"
                                                href="#!">Archive</a><a class="dropdown-item text-danger"
                                                href="#!">Delete</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="px-3 pt-2">
                                <div class="nav flex-column font-sans-serif fw-medium"><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-search me-1"
                                                data-fa-transform="shrink-1 down-3"></span></span>Search in
                                        Conversation</a><a class="nav-link d-flex align-items-center py-1 px-0 text-600"
                                        href="#!"><span class="conversation-info-icon"><span
                                                class="fas fa-pencil-alt me-1"
                                                data-fa-transform="shrink-1"></span></span>Edit Nicknames</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-palette me-1"
                                                data-fa-transform="shrink-1"></span></span><span>Change
                                            Color</span></a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-thumbs-up me-1"
                                                data-fa-transform="shrink-1"></span></span>Change Emoji</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-bell me-1"
                                                data-fa-transform="shrink-1"></span></span>Notifications</a></div>
                            </div>
                            <hr class="my-2" />
                            <div class="px-3" id="others-info-1">
                                <div class="title" id="member-title-1"><a
                                        class="btn btn-link btn-accordion hover-text-decoration-none dropdown-indicator"
                                        href="#members-1" data-bs-toggle="collapse" aria-expanded="false"
                                        aria-controls="members-1">Members</a></div>
                                <div class="collapse" id="members-1" aria-labelledby="member-title-1"
                                    data-parent="#others-info-1">
                                    <div class="d-flex align-items-center py-2 hover-actions-trigger">
                                        <div class="avatar avatar-xl status-online">
                                            <img class="rounded-circle" src="../assets/img/team/2.jpg" alt="" />

                                        </div>
                                        <div class="flex-1 ms-2 d-flex justify-content-between">
                                            <div>
                                                <h6 class="mb-0"><a class="text-700"
                                                        href="../pages/user/profile.html">Antony Hopkins</a></h6>
                                                <div class="fs--2 text-400">Admin</div>
                                            </div>
                                            <div
                                                class="dropdown hover-actions position-relative dropdown-active-trigger z-index-1">
                                                <button
                                                    class="btn btn-link btn-sm text-400 dropdown-toggle dropdown-caret-none"
                                                    type="button" id="user-settings-dropdown-0"
                                                    data-bs-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false"><span
                                                        class="fas fa-ellipsis-h"></span></button>
                                                <div class="dropdown-menu dropdown-menu-end py-2 border"
                                                    aria-labelledby="user-settings-dropdown-0"><a class="dropdown-item"
                                                        href="#!">Message</a><a class="dropdown-item" href="#!">View
                                                        Profile</a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center py-2 hover-actions-trigger">
                                        <div class="avatar avatar-xl">
                                            <img class="rounded-circle" src="../assets/img/team/1.jpg" alt="" />

                                        </div>
                                        <div class="flex-1 ms-2 d-flex justify-content-between">
                                            <div>
                                                <h6 class="mb-0"><a class="text-700"
                                                        href="../pages/user/profile.html">Emma Watson</a></h6>
                                                <div class="fs--2 text-400">Member</div>
                                            </div>
                                            <div
                                                class="dropdown hover-actions position-relative dropdown-active-trigger z-index-1">
                                                <button
                                                    class="btn btn-link btn-sm text-400 dropdown-toggle dropdown-caret-none"
                                                    type="button" id="user-settings-dropdown-1"
                                                    data-bs-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false"><span
                                                        class="fas fa-ellipsis-h"></span></button>
                                                <div class="dropdown-menu dropdown-menu-end py-2 border"
                                                    aria-labelledby="user-settings-dropdown-1"><a class="dropdown-item"
                                                        href="#!">Message</a><a class="dropdown-item" href="#!">View
                                                        Profile</a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center py-2 hover-actions-trigger">
                                        <div class="avatar avatar-xl status-online">
                                            <img class="rounded-circle" src="../assets/img/team/3.jpg" alt="" />

                                        </div>
                                        <div class="flex-1 ms-2 d-flex justify-content-between">
                                            <div>
                                                <h6 class="mb-0"><a class="text-700"
                                                        href="../pages/user/profile.html">Anna Karinina</a></h6>
                                                <div class="fs--2 text-400">Member</div>
                                            </div>
                                            <div
                                                class="dropdown hover-actions position-relative dropdown-active-trigger z-index-1">
                                                <button
                                                    class="btn btn-link btn-sm text-400 dropdown-toggle dropdown-caret-none"
                                                    type="button" id="user-settings-dropdown-2"
                                                    data-bs-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false"><span
                                                        class="fas fa-ellipsis-h"></span></button>
                                                <div class="dropdown-menu dropdown-menu-end py-2 border"
                                                    aria-labelledby="user-settings-dropdown-2"><a class="dropdown-item"
                                                        href="#!">Message</a><a class="dropdown-item" href="#!">View
                                                        Profile</a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center py-2 hover-actions-trigger">
                                        <div class="avatar avatar-xl status-online">
                                            <img class="rounded-circle" src="../assets/img/team/4.jpg" alt="" />

                                        </div>
                                        <div class="flex-1 ms-2 d-flex justify-content-between">
                                            <div>
                                                <h6 class="mb-0"><a class="text-700"
                                                        href="../pages/user/profile.html">John Lee</a></h6>
                                                <div class="fs--2 text-400">Member</div>
                                            </div>
                                            <div
                                                class="dropdown hover-actions position-relative dropdown-active-trigger z-index-1">
                                                <button
                                                    class="btn btn-link btn-sm text-400 dropdown-toggle dropdown-caret-none"
                                                    type="button" id="user-settings-dropdown-3"
                                                    data-bs-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false"><span
                                                        class="fas fa-ellipsis-h"></span></button>
                                                <div class="dropdown-menu dropdown-menu-end py-2 border"
                                                    aria-labelledby="user-settings-dropdown-3"><a class="dropdown-item"
                                                        href="#!">Message</a><a class="dropdown-item" href="#!">View
                                                        Profile</a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center py-2 hover-actions-trigger">
                                        <div class="avatar avatar-xl">
                                            <img class="rounded-circle" src="../assets/img/team/5.jpg" alt="" />

                                        </div>
                                        <div class="flex-1 ms-2 d-flex justify-content-between">
                                            <div>
                                                <h6 class="mb-0"><a class="text-700"
                                                        href="../pages/user/profile.html">Bucky Robert</a></h6>
                                                <div class="fs--2 text-400">Member</div>
                                            </div>
                                            <div
                                                class="dropdown hover-actions position-relative dropdown-active-trigger z-index-1">
                                                <button
                                                    class="btn btn-link btn-sm text-400 dropdown-toggle dropdown-caret-none"
                                                    type="button" id="user-settings-dropdown-4"
                                                    data-bs-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false"><span
                                                        class="fas fa-ellipsis-h"></span></button>
                                                <div class="dropdown-menu dropdown-menu-end py-2 border"
                                                    aria-labelledby="user-settings-dropdown-4"><a class="dropdown-item"
                                                        href="#!">Message</a><a class="dropdown-item" href="#!">View
                                                        Profile</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="title" id="shared-media-title-1"><a
                                        class="btn btn-link btn-accordion hover-text-decoration-none dropdown-indicator"
                                        href="#shared-media-1" data-bs-toggle="collapse" aria-expanded="false"
                                        aria-controls="shared-media-1">Shared media</a></div>
                                <div class="collapse" id="shared-media-1" aria-labelledby="shared-media-title-1"
                                    data-parent="#others-info-1">
                                    <div class="row mx-n1">
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/1.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/1.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/2.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/2.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/3.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/3.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/4.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/4.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/5.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/5.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/6.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/6.jpg" alt="" /></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chat-content-scroll-area scrollbar">
                        <div class="d-flex position-relative p-3 border-bottom mb-3 align-items-center">
                            <div class="avatar avatar-2xl me-3">
                                <div class="rounded-circle overflow-hidden h-100 d-flex">
                                    <div class="w-50 border-end"><img src="../assets/img/team/1.jpg" alt="" /></div>
                                    <div class="w-50 d-flex flex-column"><img class="h-50 border-bottom"
                                            src="../assets/img/team/2.jpg" alt="" /><img class="h-50"
                                            src="../assets/img/team/3.jpg" alt="" /></div>
                                </div>
                            </div>
                            <div class="flex-1">
                                <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                        href="../pages/user/profile.html">Avengers</a></h6>
                                <p class="mb-0">You are a member of Avengers. Say hi to start conversation to the group.
                                </p>
                            </div>
                        </div>
                        <div class="text-center fs--2 text-500"><span>May 5, 2019, 11:54 am</span></div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/13.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message bg-200 p-2 rounded-2">In an organisation stature, this
                                            is a must. Besides, we need to quickly establish all other professional
                                            appearances, e.g. having a website where members’ profile will be displayed
                                            along with other organisational information. Providing services to existing
                                            members is more important than attracting new members at this moment, in my
                                            opinion.</div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span class="fw-semi-bold me-2">Anna</span><span>11:54
                                            am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="flex-1 d-flex justify-content-end">
                                <div class="w-100 w-xxl-75">
                                    <div class="hover-actions-trigger d-flex flex-end-center">
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 me-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                        <div class="bg-primary text-white p-2 rounded-2 chat-message light">Your are
                                            right 👍
                                        </div>
                                    </div>
                                    <div class="text-400 fs--2 text-end">11:54 am<span
                                            class="fas fa-check ms-2 text-success"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/2.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message bg-200 p-2 rounded-2">We should divide the tasks among
                                            all other members.</div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span class="fw-semi-bold me-2">Antony</span><span>11:54
                                            am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/4.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message bg-200 p-2 rounded-2">I will make a list of all the
                                            tasks.</div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span class="fw-semi-bold me-2">John</span><span>11:54
                                            am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center fs--2 text-500"><span>May 7, 2019, 11:54 am</span></div>
                        <div class="d-flex p-3">
                            <div class="flex-1 d-flex justify-content-end">
                                <div class="w-100 w-xxl-75">
                                    <div class="hover-actions-trigger d-flex flex-end-center">
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 me-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                        <div class="bg-primary text-white p-2 rounded-2 chat-message light">I can help
                                            you to do this.
                                        </div>
                                    </div>
                                    <div class="text-400 fs--2 text-end">11:54 am<span
                                            class="fas fa-check ms-2 text-success"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/1.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message bg-200 p-2 rounded-2">It will be a great opportunity if
                                            I can contribute to this task 😊</div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span class="fw-semi-bold me-2">Emma</span><span>11:54
                                            am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/5.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message bg-200 p-2 rounded-2">Wow, it will be great!</div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span class="fw-semi-bold me-2">Bucky</span><span>11:54
                                            am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/5.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message bg-200 p-2 rounded-2"><a href="#!"
                                                class="text-primary">@Emma</a> What do you think about the plan?</div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span class="fw-semi-bold me-2">Bucky</span><span>11:54
                                            am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane card-chat-pane" id="chat-2" role="tabpanel" aria-labelledby="chat-link-2">
                <div class="chat-content-header">
                    <div class="row flex-between-center">
                        <div class="col-6 col-sm-8 d-flex align-items-center"><a
                                class="pe-3 text-700 d-md-none contacts-list-show" href="#!">
                                <div class="fas fa-chevron-left"></div>
                            </a>
                            <div class="min-w-0">
                                <h5 class="mb-0 text-truncate fs-0">Emma Watson</h5>
                                <div class="fs--2 text-400">Active 7h ago
                                </div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="2"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Call"><span
                                    class="fas fa-phone"></span></button>
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="2"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Video Call"><span
                                    class="fas fa-video"></span></button>
                            <button class="btn btn-sm btn-falcon-primary btn-info" type="button" data-index="2"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Conversation Information"><span
                                    class="fas fa-info"></span></button>
                        </div>
                    </div>
                </div>
                <div class="chat-content-body" style="display: inherit;">
                    <div class="conversation-info" data-index="2">
                        <div class="h-100 overflow-auto scrollbar">
                            <div class="d-flex position-relative align-items-center p-3 border-bottom">
                                <div class="avatar avatar-xl">
                                    <img class="rounded-circle" src="../assets/img/team/1.jpg" alt="" />

                                </div>
                                <div class="flex-1 ms-2 d-flex flex-between-center">
                                    <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                            href="../pages/user/profile.html">Emma Watson</a></h6>
                                    <div class="dropdown z-index-1">
                                        <button
                                            class="btn btn-link btn-sm text-400 dropdown-toggle dropdown-caret-none me-n3"
                                            type="button" id="profile-dropdown-2" data-bs-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false"><span
                                                class="fas fa-cog"></span></button>
                                        <div class="dropdown-menu dropdown-menu-end border py-2"
                                            aria-labelledby="profile-dropdown-2"><a class="dropdown-item"
                                                href="#!">Mute</a>
                                            <div class="dropdown-divider"></div><a class="dropdown-item"
                                                href="#!">Archive</a><a class="dropdown-item text-danger"
                                                href="#!">Delete</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="px-3 pt-2">
                                <div class="nav flex-column font-sans-serif fw-medium"><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-search me-1"
                                                data-fa-transform="shrink-1 down-3"></span></span>Search in
                                        Conversation</a><a class="nav-link d-flex align-items-center py-1 px-0 text-600"
                                        href="#!"><span class="conversation-info-icon"><span
                                                class="fas fa-pencil-alt me-1"
                                                data-fa-transform="shrink-1"></span></span>Edit Nicknames</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-palette me-1"
                                                data-fa-transform="shrink-1"></span></span><span>Change
                                            Color</span></a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-thumbs-up me-1"
                                                data-fa-transform="shrink-1"></span></span>Change Emoji</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-bell me-1"
                                                data-fa-transform="shrink-1"></span></span>Notifications</a></div>
                            </div>
                            <hr class="my-2" />
                            <div class="px-3" id="others-info-2">
                                <div class="title" id="shared-media-title-2"><a
                                        class="btn btn-link btn-accordion hover-text-decoration-none dropdown-indicator"
                                        href="#shared-media-2" data-bs-toggle="collapse" aria-expanded="false"
                                        aria-controls="shared-media-2">Shared media</a></div>
                                <div class="collapse" id="shared-media-2" aria-labelledby="shared-media-title-2"
                                    data-parent="#others-info-2">
                                    <div class="row mx-n1">
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/1.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/1.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/2.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/2.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/3.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/3.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/4.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/4.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/5.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/5.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/6.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/6.jpg" alt="" /></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chat-content-scroll-area scrollbar">
                        <div class="d-flex position-relative p-3 border-bottom mb-3 align-items-center">
                            <div class="avatar avatar-2xl me-3">
                                <img class="rounded-circle" src="../assets/img/team/1.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                        href="../pages/user/profile.html">Emma Watson</a></h6>
                                <p class="mb-0">You friends with Emma Watson. Say hi to start the conversation
                                </p>
                            </div>
                        </div>
                        <div class="text-center fs--2 text-500"><span>May 5, 2019, 11:54 am</span></div>
                        <div class="d-flex p-3">
                            <div class="flex-1 d-flex justify-content-end">
                                <div class="w-100 w-xxl-75">
                                    <div class="hover-actions-trigger d-flex flex-end-center">
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 me-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                        <div class="bg-primary text-white p-2 rounded-2 chat-message light">Hello
                                        </div>
                                    </div>
                                    <div class="text-400 fs--2 text-end">11:54 am<span
                                            class="fas fa-check ms-2 text-success"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="flex-1 d-flex justify-content-end">
                                <div class="w-100 w-xxl-75">
                                    <div class="hover-actions-trigger d-flex flex-end-center">
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 me-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                        <div class="bg-primary text-white p-2 rounded-2 chat-message light">🙂
                                        </div>
                                    </div>
                                    <div class="text-400 fs--2 text-end">11:54 am<span
                                            class="fas fa-check ms-2 text-success"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane card-chat-pane" id="chat-3" role="tabpanel" aria-labelledby="chat-link-3">
                <div class="chat-content-header">
                    <div class="row flex-between-center">
                        <div class="col-6 col-sm-8 d-flex align-items-center"><a
                                class="pe-3 text-700 d-md-none contacts-list-show" href="#!">
                                <div class="fas fa-chevron-left"></div>
                            </a>
                            <div class="min-w-0">
                                <h5 class="mb-0 text-truncate fs-0">Anna Karinina</h5>
                                <div class="fs--2 text-400">Active On Chat
                                </div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="3"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Call"><span
                                    class="fas fa-phone"></span></button>
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="3"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Video Call"><span
                                    class="fas fa-video"></span></button>
                            <button class="btn btn-sm btn-falcon-primary btn-info" type="button" data-index="3"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Conversation Information"><span
                                    class="fas fa-info"></span></button>
                        </div>
                    </div>
                </div>
                <div class="chat-content-body" style="display: inherit;">
                    <div class="conversation-info" data-index="3">
                        <div class="h-100 overflow-auto scrollbar">
                            <div class="d-flex position-relative align-items-center p-3 border-bottom">
                                <div class="avatar avatar-xl status-online">
                                    <img class="rounded-circle" src="../assets/img/team/13.jpg" alt="" />

                                </div>
                                <div class="flex-1 ms-2 d-flex flex-between-center">
                                    <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                            href="../pages/user/profile.html">Anna Karinina</a></h6>
                                    <div class="dropdown z-index-1">
                                        <button
                                            class="btn btn-link btn-sm text-400 dropdown-toggle dropdown-caret-none me-n3"
                                            type="button" id="profile-dropdown-3" data-bs-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false"><span
                                                class="fas fa-cog"></span></button>
                                        <div class="dropdown-menu dropdown-menu-end border py-2"
                                            aria-labelledby="profile-dropdown-3"><a class="dropdown-item"
                                                href="#!">Mute</a>
                                            <div class="dropdown-divider"></div><a class="dropdown-item"
                                                href="#!">Archive</a><a class="dropdown-item text-danger"
                                                href="#!">Delete</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="px-3 pt-2">
                                <div class="nav flex-column font-sans-serif fw-medium"><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-search me-1"
                                                data-fa-transform="shrink-1 down-3"></span></span>Search in
                                        Conversation</a><a class="nav-link d-flex align-items-center py-1 px-0 text-600"
                                        href="#!"><span class="conversation-info-icon"><span
                                                class="fas fa-pencil-alt me-1"
                                                data-fa-transform="shrink-1"></span></span>Edit Nicknames</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-palette me-1"
                                                data-fa-transform="shrink-1"></span></span><span>Change
                                            Color</span></a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-thumbs-up me-1"
                                                data-fa-transform="shrink-1"></span></span>Change Emoji</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-bell me-1"
                                                data-fa-transform="shrink-1"></span></span>Notifications</a></div>
                            </div>
                            <hr class="my-2" />
                            <div class="px-3" id="others-info-3">
                                <div class="title" id="shared-media-title-3"><a
                                        class="btn btn-link btn-accordion hover-text-decoration-none dropdown-indicator"
                                        href="#shared-media-3" data-bs-toggle="collapse" aria-expanded="false"
                                        aria-controls="shared-media-3">Shared media</a></div>
                                <div class="collapse" id="shared-media-3" aria-labelledby="shared-media-title-3"
                                    data-parent="#others-info-3">
                                    <div class="row mx-n1">
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/1.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/1.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/2.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/2.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/3.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/3.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/4.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/4.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/5.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/5.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/6.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/6.jpg" alt="" /></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chat-content-scroll-area scrollbar">
                        <div class="d-flex position-relative p-3 border-bottom mb-3 align-items-center">
                            <div class="avatar avatar-2xl status-online me-3">
                                <img class="rounded-circle" src="../assets/img/team/13.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                        href="../pages/user/profile.html">Anna Karinina</a></h6>
                                <p class="mb-0">You friends with Anna Karinina. Say hi to start the conversation
                                </p>
                            </div>
                        </div>
                        <div class="text-center fs--2 text-500"><span>May 5, 2019, 11:54 am</span></div>
                        <div class="d-flex p-3">
                            <div class="flex-1 d-flex justify-content-end">
                                <div class="w-100 w-xxl-75">
                                    <div class="hover-actions-trigger d-flex flex-end-center">
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 me-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                        <div class="bg-primary text-white p-2 rounded-2 chat-message light">Hello
                                        </div>
                                    </div>
                                    <div class="text-400 fs--2 text-end">11:54 am<span class="fas fa-check ms-2"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/13.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message bg-200 p-2 rounded-2">What are you doing?</div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span>11:54 am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane card-chat-pane" id="chat-4" role="tabpanel" aria-labelledby="chat-link-4">
                <div class="chat-content-header">
                    <div class="row flex-between-center">
                        <div class="col-6 col-sm-8 d-flex align-items-center"><a
                                class="pe-3 text-700 d-md-none contacts-list-show" href="#!">
                                <div class="fas fa-chevron-left"></div>
                            </a>
                            <div class="min-w-0">
                                <h5 class="mb-0 text-truncate fs-0">John Lee</h5>
                                <div class="fs--2 text-400">Active On Chat
                                </div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="4"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Call"><span
                                    class="fas fa-phone"></span></button>
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="4"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Video Call"><span
                                    class="fas fa-video"></span></button>
                            <button class="btn btn-sm btn-falcon-primary btn-info" type="button" data-index="4"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Conversation Information"><span
                                    class="fas fa-info"></span></button>
                        </div>
                    </div>
                </div>
                <div class="chat-content-body" style="display: inherit;">
                    <div class="conversation-info" data-index="4">
                        <div class="h-100 overflow-auto scrollbar">
                            <div class="d-flex position-relative align-items-center p-3 border-bottom">
                                <div class="avatar avatar-xl status-online">
                                    <img class="rounded-circle" src="../assets/img/team/4.jpg" alt="" />

                                </div>
                                <div class="flex-1 ms-2 d-flex flex-between-center">
                                    <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                            href="../pages/user/profile.html">John Lee</a></h6>
                                    <div class="dropdown z-index-1">
                                        <button
                                            class="btn btn-link btn-sm text-400 dropdown-toggle dropdown-caret-none me-n3"
                                            type="button" id="profile-dropdown-4" data-bs-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false"><span
                                                class="fas fa-cog"></span></button>
                                        <div class="dropdown-menu dropdown-menu-end border py-2"
                                            aria-labelledby="profile-dropdown-4"><a class="dropdown-item"
                                                href="#!">Mute</a>
                                            <div class="dropdown-divider"></div><a class="dropdown-item"
                                                href="#!">Archive</a><a class="dropdown-item text-danger"
                                                href="#!">Delete</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="px-3 pt-2">
                                <div class="nav flex-column font-sans-serif fw-medium"><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-search me-1"
                                                data-fa-transform="shrink-1 down-3"></span></span>Search in
                                        Conversation</a><a class="nav-link d-flex align-items-center py-1 px-0 text-600"
                                        href="#!"><span class="conversation-info-icon"><span
                                                class="fas fa-pencil-alt me-1"
                                                data-fa-transform="shrink-1"></span></span>Edit Nicknames</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-palette me-1"
                                                data-fa-transform="shrink-1"></span></span><span>Change
                                            Color</span></a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-thumbs-up me-1"
                                                data-fa-transform="shrink-1"></span></span>Change Emoji</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-bell me-1"
                                                data-fa-transform="shrink-1"></span></span>Notifications</a></div>
                            </div>
                            <hr class="my-2" />
                            <div class="px-3" id="others-info-4">
                                <div class="title" id="shared-media-title-4"><a
                                        class="btn btn-link btn-accordion hover-text-decoration-none dropdown-indicator"
                                        href="#shared-media-4" data-bs-toggle="collapse" aria-expanded="false"
                                        aria-controls="shared-media-4">Shared media</a></div>
                                <div class="collapse" id="shared-media-4" aria-labelledby="shared-media-title-4"
                                    data-parent="#others-info-4">
                                    <div class="row mx-n1">
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/1.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/1.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/2.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/2.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/3.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/3.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/4.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/4.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/5.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/5.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/6.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/6.jpg" alt="" /></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chat-content-scroll-area scrollbar">
                        <div class="d-flex position-relative p-3 border-bottom mb-3 align-items-center">
                            <div class="avatar avatar-2xl status-online me-3">
                                <img class="rounded-circle" src="../assets/img/team/4.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                        href="../pages/user/profile.html">John Lee</a></h6>
                                <p class="mb-0">You friends with John Lee. Say hi to start the conversation
                                </p>
                            </div>
                        </div>
                        <div class="text-center fs--2 text-500"><span>May 5, 2019, 11:54 am</span></div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/4.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message bg-200 p-2 rounded-2">What's Up!</div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span>11:54 am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="flex-1 d-flex justify-content-end">
                                <div class="w-100 w-xxl-75">
                                    <div class="hover-actions-trigger d-flex flex-end-center">
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 me-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                        <div class="bg-primary text-white p-2 rounded-2 chat-message light">Hey!
                                        </div>
                                    </div>
                                    <div class="text-400 fs--2 text-end">11:54 am<span class="fas fa-check ms-2"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/4.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message bg-200 p-2 rounded-2">How are you?</div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span>11:54 am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane card-chat-pane" id="chat-5" role="tabpanel" aria-labelledby="chat-link-5">
                <div class="chat-content-header">
                    <div class="row flex-between-center">
                        <div class="col-6 col-sm-8 d-flex align-items-center"><a
                                class="pe-3 text-700 d-md-none contacts-list-show" href="#!">
                                <div class="fas fa-chevron-left"></div>
                            </a>
                            <div class="min-w-0">
                                <h5 class="mb-0 text-truncate fs-0">Bucky Robert</h5>
                                <div class="fs--2 text-400">Active On Chat
                                </div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="5"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Call"><span
                                    class="fas fa-phone"></span></button>
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="5"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Video Call"><span
                                    class="fas fa-video"></span></button>
                            <button class="btn btn-sm btn-falcon-primary btn-info" type="button" data-index="5"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Conversation Information"><span
                                    class="fas fa-info"></span></button>
                        </div>
                    </div>
                </div>
                <div class="chat-content-body" style="display: inherit;">
                    <div class="conversation-info" data-index="5">
                        <div class="h-100 overflow-auto scrollbar">
                            <div class="d-flex position-relative align-items-center p-3 border-bottom">
                                <div class="avatar avatar-xl status-online">
                                    <div class="avatar-name rounded-circle"><span>BR</span></div>
                                </div>
                                <div class="flex-1 ms-2 d-flex flex-between-center">
                                    <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                            href="../pages/user/profile.html">Bucky Robert</a></h6>
                                    <div class="dropdown z-index-1">
                                        <button
                                            class="btn btn-link btn-sm text-400 dropdown-toggle dropdown-caret-none me-n3"
                                            type="button" id="profile-dropdown-5" data-bs-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false"><span
                                                class="fas fa-cog"></span></button>
                                        <div class="dropdown-menu dropdown-menu-end border py-2"
                                            aria-labelledby="profile-dropdown-5"><a class="dropdown-item"
                                                href="#!">Mute</a>
                                            <div class="dropdown-divider"></div><a class="dropdown-item"
                                                href="#!">Archive</a><a class="dropdown-item text-danger"
                                                href="#!">Delete</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="px-3 pt-2">
                                <div class="nav flex-column font-sans-serif fw-medium"><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-search me-1"
                                                data-fa-transform="shrink-1 down-3"></span></span>Search in
                                        Conversation</a><a class="nav-link d-flex align-items-center py-1 px-0 text-600"
                                        href="#!"><span class="conversation-info-icon"><span
                                                class="fas fa-pencil-alt me-1"
                                                data-fa-transform="shrink-1"></span></span>Edit Nicknames</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-palette me-1"
                                                data-fa-transform="shrink-1"></span></span><span>Change
                                            Color</span></a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-thumbs-up me-1"
                                                data-fa-transform="shrink-1"></span></span>Change Emoji</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-bell me-1"
                                                data-fa-transform="shrink-1"></span></span>Notifications</a></div>
                            </div>
                            <hr class="my-2" />
                            <div class="px-3" id="others-info-5">
                                <div class="title" id="shared-media-title-5"><a
                                        class="btn btn-link btn-accordion hover-text-decoration-none dropdown-indicator"
                                        href="#shared-media-5" data-bs-toggle="collapse" aria-expanded="false"
                                        aria-controls="shared-media-5">Shared media</a></div>
                                <div class="collapse" id="shared-media-5" aria-labelledby="shared-media-title-5"
                                    data-parent="#others-info-5">
                                    <div class="row mx-n1">
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/1.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/1.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/2.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/2.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/3.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/3.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/4.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/4.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/5.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/5.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/6.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/6.jpg" alt="" /></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chat-content-scroll-area scrollbar">
                        <div class="d-flex position-relative p-3 border-bottom mb-3 align-items-center">
                            <div class="avatar avatar-2xl status-online me-3">
                                <div class="avatar-name rounded-circle"><span>BR</span></div>
                            </div>
                            <div class="flex-1">
                                <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                        href="../pages/user/profile.html">Bucky Robert</a></h6>
                                <p class="mb-0">You friends with Bucky Robert. Say hi to start the conversation
                                </p>
                            </div>
                        </div>
                        <div class="text-center fs--2 text-500"><span>May 5, 2019, 11:54 am</span></div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/5.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message bg-200 p-2 rounded-2">What's Up!</div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span>11:54 am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="flex-1 d-flex justify-content-end">
                                <div class="w-100 w-xxl-75">
                                    <div class="hover-actions-trigger d-flex flex-end-center">
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 me-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                        <div class="bg-primary text-white p-2 rounded-2 chat-message light">Hey!
                                        </div>
                                    </div>
                                    <div class="text-400 fs--2 text-end">11:54 am<span class="fas fa-check ms-2"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="flex-1 d-flex justify-content-end">
                                <div class="w-100 w-xxl-75">
                                    <div class="hover-actions-trigger d-flex flex-end-center">
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 me-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                        <div class="bg-primary text-white p-2 rounded-2 chat-message light">👋
                                        </div>
                                    </div>
                                    <div class="text-400 fs--2 text-end">11:54 am<span class="fas fa-check ms-2"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane card-chat-pane" id="chat-6" role="tabpanel" aria-labelledby="chat-link-6">
                <div class="chat-content-header">
                    <div class="row flex-between-center">
                        <div class="col-6 col-sm-8 d-flex align-items-center"><a
                                class="pe-3 text-700 d-md-none contacts-list-show" href="#!">
                                <div class="fas fa-chevron-left"></div>
                            </a>
                            <div class="min-w-0">
                                <h5 class="mb-0 text-truncate fs-0">John Bradley</h5>
                                <div class="fs--2 text-400">Active On Chat
                                </div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="6"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Call"><span
                                    class="fas fa-phone"></span></button>
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="6"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Video Call"><span
                                    class="fas fa-video"></span></button>
                            <button class="btn btn-sm btn-falcon-primary btn-info" type="button" data-index="6"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Conversation Information"><span
                                    class="fas fa-info"></span></button>
                        </div>
                    </div>
                </div>
                <div class="chat-content-body" style="display: inherit;">
                    <div class="conversation-info" data-index="6">
                        <div class="h-100 overflow-auto scrollbar">
                            <div class="d-flex position-relative align-items-center p-3 border-bottom">
                                <div class="avatar avatar-xl status-online">
                                    <img class="rounded-circle" src="../assets/img/team/10.jpg" alt="" />

                                </div>
                                <div class="flex-1 ms-2 d-flex flex-between-center">
                                    <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                            href="../pages/user/profile.html">John Bradley</a></h6>
                                    <div class="dropdown z-index-1">
                                        <button
                                            class="btn btn-link btn-sm text-400 dropdown-toggle dropdown-caret-none me-n3"
                                            type="button" id="profile-dropdown-6" data-bs-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false"><span
                                                class="fas fa-cog"></span></button>
                                        <div class="dropdown-menu dropdown-menu-end border py-2"
                                            aria-labelledby="profile-dropdown-6"><a class="dropdown-item"
                                                href="#!">Mute</a>
                                            <div class="dropdown-divider"></div><a class="dropdown-item"
                                                href="#!">Archive</a><a class="dropdown-item text-danger"
                                                href="#!">Delete</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="px-3 pt-2">
                                <div class="nav flex-column font-sans-serif fw-medium"><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-search me-1"
                                                data-fa-transform="shrink-1 down-3"></span></span>Search in
                                        Conversation</a><a class="nav-link d-flex align-items-center py-1 px-0 text-600"
                                        href="#!"><span class="conversation-info-icon"><span
                                                class="fas fa-pencil-alt me-1"
                                                data-fa-transform="shrink-1"></span></span>Edit Nicknames</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-palette me-1"
                                                data-fa-transform="shrink-1"></span></span><span>Change
                                            Color</span></a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-thumbs-up me-1"
                                                data-fa-transform="shrink-1"></span></span>Change Emoji</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-bell me-1"
                                                data-fa-transform="shrink-1"></span></span>Notifications</a></div>
                            </div>
                            <hr class="my-2" />
                            <div class="px-3" id="others-info-6">
                                <div class="title" id="shared-media-title-6"><a
                                        class="btn btn-link btn-accordion hover-text-decoration-none dropdown-indicator"
                                        href="#shared-media-6" data-bs-toggle="collapse" aria-expanded="false"
                                        aria-controls="shared-media-6">Shared media</a></div>
                                <div class="collapse" id="shared-media-6" aria-labelledby="shared-media-title-6"
                                    data-parent="#others-info-6">
                                    <div class="row mx-n1">
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/1.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/1.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/2.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/2.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/3.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/3.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/4.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/4.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/5.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/5.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/6.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/6.jpg" alt="" /></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chat-content-scroll-area scrollbar">
                        <div class="d-flex position-relative p-3 border-bottom mb-3 align-items-center">
                            <div class="avatar avatar-2xl status-online me-3">
                                <img class="rounded-circle" src="../assets/img/team/10.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                        href="../pages/user/profile.html">John Bradley</a></h6>
                                <p class="mb-0">You friends with John Bradley. Say hi to start the conversation
                                </p>
                            </div>
                        </div>
                        <div class="text-center fs--2 text-500"><span>May 5, 2019, 11:54 am</span></div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/10.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message bg-200 p-2 rounded-2">What's Up!</div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span>11:54 am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="flex-1 d-flex justify-content-end">
                                <div class="w-100 w-xxl-75">
                                    <div class="hover-actions-trigger d-flex flex-end-center">
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 me-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                        <div class="bg-primary text-white p-2 rounded-2 chat-message light">Hey!
                                        </div>
                                    </div>
                                    <div class="text-400 fs--2 text-end">11:54 am<span class="fas fa-check ms-2"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/10.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message bg-200 p-2 rounded-2">Hello!</div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span>11:54 am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane card-chat-pane" id="chat-7" role="tabpanel" aria-labelledby="chat-link-7">
                <div class="chat-content-header">
                    <div class="row flex-between-center">
                        <div class="col-6 col-sm-8 d-flex align-items-center"><a
                                class="pe-3 text-700 d-md-none contacts-list-show" href="#!">
                                <div class="fas fa-chevron-left"></div>
                            </a>
                            <div class="min-w-0">
                                <h5 class="mb-0 text-truncate fs-0">Rory McCann</h5>
                                <div class="fs--2 text-400">Active On Chat
                                </div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="7"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Call"><span
                                    class="fas fa-phone"></span></button>
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="7"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Video Call"><span
                                    class="fas fa-video"></span></button>
                            <button class="btn btn-sm btn-falcon-primary btn-info" type="button" data-index="7"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Conversation Information"><span
                                    class="fas fa-info"></span></button>
                        </div>
                    </div>
                </div>
                <div class="chat-content-body" style="display: inherit;">
                    <div class="conversation-info" data-index="7">
                        <div class="h-100 overflow-auto scrollbar">
                            <div class="d-flex position-relative align-items-center p-3 border-bottom">
                                <div class="avatar avatar-xl status-online">
                                    <img class="rounded-circle" src="../assets/img/team/11.jpg" alt="" />

                                </div>
                                <div class="flex-1 ms-2 d-flex flex-between-center">
                                    <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                            href="../pages/user/profile.html">Rory McCann</a></h6>
                                    <div class="dropdown z-index-1">
                                        <button
                                            class="btn btn-link btn-sm text-400 dropdown-toggle dropdown-caret-none me-n3"
                                            type="button" id="profile-dropdown-7" data-bs-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false"><span
                                                class="fas fa-cog"></span></button>
                                        <div class="dropdown-menu dropdown-menu-end border py-2"
                                            aria-labelledby="profile-dropdown-7"><a class="dropdown-item"
                                                href="#!">Mute</a>
                                            <div class="dropdown-divider"></div><a class="dropdown-item"
                                                href="#!">Archive</a><a class="dropdown-item text-danger"
                                                href="#!">Delete</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="px-3 pt-2">
                                <div class="nav flex-column font-sans-serif fw-medium"><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-search me-1"
                                                data-fa-transform="shrink-1 down-3"></span></span>Search in
                                        Conversation</a><a class="nav-link d-flex align-items-center py-1 px-0 text-600"
                                        href="#!"><span class="conversation-info-icon"><span
                                                class="fas fa-pencil-alt me-1"
                                                data-fa-transform="shrink-1"></span></span>Edit Nicknames</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-palette me-1"
                                                data-fa-transform="shrink-1"></span></span><span>Change
                                            Color</span></a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-thumbs-up me-1"
                                                data-fa-transform="shrink-1"></span></span>Change Emoji</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-bell me-1"
                                                data-fa-transform="shrink-1"></span></span>Notifications</a></div>
                            </div>
                            <hr class="my-2" />
                            <div class="px-3" id="others-info-7">
                                <div class="title" id="shared-media-title-7"><a
                                        class="btn btn-link btn-accordion hover-text-decoration-none dropdown-indicator"
                                        href="#shared-media-7" data-bs-toggle="collapse" aria-expanded="false"
                                        aria-controls="shared-media-7">Shared media</a></div>
                                <div class="collapse" id="shared-media-7" aria-labelledby="shared-media-title-7"
                                    data-parent="#others-info-7">
                                    <div class="row mx-n1">
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/1.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/1.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/2.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/2.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/3.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/3.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/4.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/4.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/5.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/5.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/6.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/6.jpg" alt="" /></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chat-content-scroll-area scrollbar">
                        <div class="d-flex position-relative p-3 border-bottom mb-3 align-items-center">
                            <div class="avatar avatar-2xl status-online me-3">
                                <img class="rounded-circle" src="../assets/img/team/11.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                        href="../pages/user/profile.html">Rory McCann</a></h6>
                                <p class="mb-0">You friends with Rory McCann. Say hi to start the conversation
                                </p>
                            </div>
                        </div>
                        <div class="text-center fs--2 text-500"><span>May 5, 2019, 11:54 am</span></div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/11.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message bg-200 p-2 rounded-2">I got my visa</div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span>11:54 am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/11.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message bg-200 p-2 rounded-2">Let's have fun</div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span>11:54 am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="flex-1 d-flex justify-content-end">
                                <div class="w-100 w-xxl-75">
                                    <div class="hover-actions-trigger d-flex flex-end-center">
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 me-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                        <div class="bg-primary text-white p-2 rounded-2 chat-message light">😮
                                        </div>
                                    </div>
                                    <div class="text-400 fs--2 text-end">11:54 am<span class="fas fa-check ms-2"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane card-chat-pane" id="chat-8" role="tabpanel" aria-labelledby="chat-link-8">
                <div class="chat-content-header">
                    <div class="row flex-between-center">
                        <div class="col-6 col-sm-8 d-flex align-items-center"><a
                                class="pe-3 text-700 d-md-none contacts-list-show" href="#!">
                                <div class="fas fa-chevron-left"></div>
                            </a>
                            <div class="min-w-0">
                                <h5 class="mb-0 text-truncate fs-0">Joe Bempsie</h5>
                                <div class="fs--2 text-400">Active 7h ago
                                </div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="8"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Call"><span
                                    class="fas fa-phone"></span></button>
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="8"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Video Call"><span
                                    class="fas fa-video"></span></button>
                            <button class="btn btn-sm btn-falcon-primary btn-info" type="button" data-index="8"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Conversation Information"><span
                                    class="fas fa-info"></span></button>
                        </div>
                    </div>
                </div>
                <div class="chat-content-body" style="display: inherit;">
                    <div class="conversation-info" data-index="8">
                        <div class="h-100 overflow-auto scrollbar">
                            <div class="d-flex position-relative align-items-center p-3 border-bottom">
                                <div class="avatar avatar-xl">
                                    <img class="rounded-circle" src="../assets/img/team/12.jpg" alt="" />

                                </div>
                                <div class="flex-1 ms-2 d-flex flex-between-center">
                                    <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                            href="../pages/user/profile.html">Joe Bempsie</a></h6>
                                    <div class="dropdown z-index-1">
                                        <button
                                            class="btn btn-link btn-sm text-400 dropdown-toggle dropdown-caret-none me-n3"
                                            type="button" id="profile-dropdown-8" data-bs-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false"><span
                                                class="fas fa-cog"></span></button>
                                        <div class="dropdown-menu dropdown-menu-end border py-2"
                                            aria-labelledby="profile-dropdown-8"><a class="dropdown-item"
                                                href="#!">Mute</a>
                                            <div class="dropdown-divider"></div><a class="dropdown-item"
                                                href="#!">Archive</a><a class="dropdown-item text-danger"
                                                href="#!">Delete</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="px-3 pt-2">
                                <div class="nav flex-column font-sans-serif fw-medium"><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-search me-1"
                                                data-fa-transform="shrink-1 down-3"></span></span>Search in
                                        Conversation</a><a class="nav-link d-flex align-items-center py-1 px-0 text-600"
                                        href="#!"><span class="conversation-info-icon"><span
                                                class="fas fa-pencil-alt me-1"
                                                data-fa-transform="shrink-1"></span></span>Edit Nicknames</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-palette me-1"
                                                data-fa-transform="shrink-1"></span></span><span>Change
                                            Color</span></a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-thumbs-up me-1"
                                                data-fa-transform="shrink-1"></span></span>Change Emoji</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-bell me-1"
                                                data-fa-transform="shrink-1"></span></span>Notifications</a></div>
                            </div>
                            <hr class="my-2" />
                            <div class="px-3" id="others-info-8">
                                <div class="title" id="shared-media-title-8"><a
                                        class="btn btn-link btn-accordion hover-text-decoration-none dropdown-indicator"
                                        href="#shared-media-8" data-bs-toggle="collapse" aria-expanded="false"
                                        aria-controls="shared-media-8">Shared media</a></div>
                                <div class="collapse" id="shared-media-8" aria-labelledby="shared-media-title-8"
                                    data-parent="#others-info-8">
                                    <div class="row mx-n1">
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/1.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/1.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/2.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/2.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/3.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/3.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/4.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/4.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/5.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/5.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/6.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/6.jpg" alt="" /></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chat-content-scroll-area scrollbar">
                        <div class="d-flex position-relative p-3 border-bottom mb-3 align-items-center">
                            <div class="avatar avatar-2xl me-3">
                                <img class="rounded-circle" src="../assets/img/team/12.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                        href="../pages/user/profile.html">Joe Bempsie</a></h6>
                                <p class="mb-0">You friends with Joe Bempsie. Say hi to start the conversation
                                </p>
                            </div>
                        </div>
                        <div class="text-center fs--2 text-500"><span>May 5, 2019, 11:54 am</span></div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/12.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message bg-200 p-2 rounded-2">What's Up!</div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span>11:54 am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="flex-1 d-flex justify-content-end">
                                <div class="w-100 w-xxl-75">
                                    <div class="hover-actions-trigger d-flex flex-end-center">
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 me-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                        <div class="bg-primary text-white p-2 rounded-2 chat-message light">Hey!
                                        </div>
                                    </div>
                                    <div class="text-400 fs--2 text-end">11:54 am<span class="fas fa-check ms-2"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/12.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message bg-200 p-2 rounded-2">Hello!</div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span>11:54 am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane card-chat-pane" id="chat-9" role="tabpanel" aria-labelledby="chat-link-9">
                <div class="chat-content-header">
                    <div class="row flex-between-center">
                        <div class="col-6 col-sm-8 d-flex align-items-center"><a
                                class="pe-3 text-700 d-md-none contacts-list-show" href="#!">
                                <div class="fas fa-chevron-left"></div>
                            </a>
                            <div class="min-w-0">
                                <h5 class="mb-0 text-truncate fs-0">Diana Rigg</h5>
                                <div class="fs--2 text-400">Active 7h ago
                                </div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="9"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Call"><span
                                    class="fas fa-phone"></span></button>
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="9"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Video Call"><span
                                    class="fas fa-video"></span></button>
                            <button class="btn btn-sm btn-falcon-primary btn-info" type="button" data-index="9"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Conversation Information"><span
                                    class="fas fa-info"></span></button>
                        </div>
                    </div>
                </div>
                <div class="chat-content-body" style="display: inherit;">
                    <div class="conversation-info" data-index="9">
                        <div class="h-100 overflow-auto scrollbar">
                            <div class="d-flex position-relative align-items-center p-3 border-bottom">
                                <div class="avatar avatar-xl">
                                    <img class="rounded-circle" src="../assets/img/team/22.jpg" alt="" />

                                </div>
                                <div class="flex-1 ms-2 d-flex flex-between-center">
                                    <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                            href="../pages/user/profile.html">Diana Rigg</a></h6>
                                    <div class="dropdown z-index-1">
                                        <button
                                            class="btn btn-link btn-sm text-400 dropdown-toggle dropdown-caret-none me-n3"
                                            type="button" id="profile-dropdown-9" data-bs-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false"><span
                                                class="fas fa-cog"></span></button>
                                        <div class="dropdown-menu dropdown-menu-end border py-2"
                                            aria-labelledby="profile-dropdown-9"><a class="dropdown-item"
                                                href="#!">Mute</a>
                                            <div class="dropdown-divider"></div><a class="dropdown-item"
                                                href="#!">Archive</a><a class="dropdown-item text-danger"
                                                href="#!">Delete</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="px-3 pt-2">
                                <div class="nav flex-column font-sans-serif fw-medium"><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-search me-1"
                                                data-fa-transform="shrink-1 down-3"></span></span>Search in
                                        Conversation</a><a class="nav-link d-flex align-items-center py-1 px-0 text-600"
                                        href="#!"><span class="conversation-info-icon"><span
                                                class="fas fa-pencil-alt me-1"
                                                data-fa-transform="shrink-1"></span></span>Edit Nicknames</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-palette me-1"
                                                data-fa-transform="shrink-1"></span></span><span>Change
                                            Color</span></a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-thumbs-up me-1"
                                                data-fa-transform="shrink-1"></span></span>Change Emoji</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-bell me-1"
                                                data-fa-transform="shrink-1"></span></span>Notifications</a></div>
                            </div>
                            <hr class="my-2" />
                            <div class="px-3" id="others-info-9">
                                <div class="title" id="shared-media-title-9"><a
                                        class="btn btn-link btn-accordion hover-text-decoration-none dropdown-indicator"
                                        href="#shared-media-9" data-bs-toggle="collapse" aria-expanded="false"
                                        aria-controls="shared-media-9">Shared media</a></div>
                                <div class="collapse" id="shared-media-9" aria-labelledby="shared-media-title-9"
                                    data-parent="#others-info-9">
                                    <div class="row mx-n1">
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/1.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/1.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/2.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/2.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/3.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/3.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/4.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/4.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/5.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/5.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/6.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/6.jpg" alt="" /></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chat-content-scroll-area scrollbar">
                        <div class="d-flex position-relative p-3 border-bottom mb-3 align-items-center">
                            <div class="avatar avatar-2xl me-3">
                                <img class="rounded-circle" src="../assets/img/team/22.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                        href="../pages/user/profile.html">Diana Rigg</a></h6>
                                <p class="mb-0">You friends with Diana Rigg. Say hi to start the conversation
                                </p>
                            </div>
                        </div>
                        <div class="text-center fs--2 text-500"><span>May 5, 2019, 11:54 am</span></div>
                        <div class="d-flex p-3">
                            <div class="avatar avatar-l me-2">
                                <img class="rounded-circle" src="../assets/img/team/22.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <div class="w-xxl-75">
                                    <div class="hover-actions-trigger d-flex align-items-center">
                                        <div class="chat-message bg-200 p-2 rounded-2">What's Up!</div>
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 ms-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                    </div>
                                    <div class="text-400 fs--2"><span>11:54 am</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="flex-1 d-flex justify-content-end">
                                <div class="w-100 w-xxl-75">
                                    <div class="hover-actions-trigger d-flex flex-end-center">
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 me-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                        <div class="bg-primary text-white p-2 rounded-2 chat-message light">Hey!
                                        </div>
                                    </div>
                                    <div class="text-400 fs--2 text-end">11:54 am<span class="fas fa-check ms-2"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex p-3">
                            <div class="flex-1 d-flex justify-content-end">
                                <div class="w-100 w-xxl-75">
                                    <div class="hover-actions-trigger d-flex flex-end-center">
                                        <ul class="hover-actions position-relative list-inline mb-0 text-400 me-2">
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Forward"><span class="fas fa-share"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Archive"><span class="fas fa-archive"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><span
                                                        class="fas fa-edit"></span></a></li>
                                            <li class="list-inline-item"><a class="chat-option" href="#!"
                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="Remove"><span class="fas fa-trash-alt"></span></a></li>
                                        </ul>
                                        <div class="bg-primary text-white p-2 rounded-2 chat-message light">Yes, in an
                                            organization stature, this is a must. Besides, we need to quickly establish
                                            all other professional appearances, e.g., having a website where members’
                                            profile will be displayed along with additional organizational information.
                                            Providing services to existing members is more important than attracting new
                                            members at this moment, in my opinion..
                                        </div>
                                    </div>
                                    <div class="text-400 fs--2 text-end">11:54 am<span class="fas fa-check ms-2"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane card-chat-pane" id="chat-10" role="tabpanel" aria-labelledby="chat-link-10">
                <div class="chat-content-header">
                    <div class="row flex-between-center">
                        <div class="col-6 col-sm-8 d-flex align-items-center"><a
                                class="pe-3 text-700 d-md-none contacts-list-show" href="#!">
                                <div class="fas fa-chevron-left"></div>
                            </a>
                            <div class="min-w-0">
                                <h5 class="mb-0 text-truncate fs-0">Gemma Whelan</h5>
                                <div class="fs--2 text-400">Active 7h ago
                                </div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="10"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Call"><span
                                    class="fas fa-phone"></span></button>
                            <button class="btn btn-sm btn-falcon-primary me-2" type="button" data-index="10"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Start a Video Call"><span
                                    class="fas fa-video"></span></button>
                            <button class="btn btn-sm btn-falcon-primary btn-info" type="button" data-index="10"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Conversation Information"><span
                                    class="fas fa-info"></span></button>
                        </div>
                    </div>
                </div>
                <div class="chat-content-body" style="display: inherit;">
                    <div class="conversation-info" data-index="10">
                        <div class="h-100 overflow-auto scrollbar">
                            <div class="d-flex position-relative align-items-center p-3 border-bottom">
                                <div class="avatar avatar-xl">
                                    <img class="rounded-circle" src="../assets/img/team/23.jpg" alt="" />

                                </div>
                                <div class="flex-1 ms-2 d-flex flex-between-center">
                                    <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                            href="../pages/user/profile.html">Gemma Whelan</a></h6>
                                    <div class="dropdown z-index-1">
                                        <button
                                            class="btn btn-link btn-sm text-400 dropdown-toggle dropdown-caret-none me-n3"
                                            type="button" id="profile-dropdown-10" data-bs-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false"><span
                                                class="fas fa-cog"></span></button>
                                        <div class="dropdown-menu dropdown-menu-end border py-2"
                                            aria-labelledby="profile-dropdown-10"><a class="dropdown-item"
                                                href="#!">Mute</a>
                                            <div class="dropdown-divider"></div><a class="dropdown-item"
                                                href="#!">Archive</a><a class="dropdown-item text-danger"
                                                href="#!">Delete</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="px-3 pt-2">
                                <div class="nav flex-column font-sans-serif fw-medium"><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-search me-1"
                                                data-fa-transform="shrink-1 down-3"></span></span>Search in
                                        Conversation</a><a class="nav-link d-flex align-items-center py-1 px-0 text-600"
                                        href="#!"><span class="conversation-info-icon"><span
                                                class="fas fa-pencil-alt me-1"
                                                data-fa-transform="shrink-1"></span></span>Edit Nicknames</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-palette me-1"
                                                data-fa-transform="shrink-1"></span></span><span>Change
                                            Color</span></a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-thumbs-up me-1"
                                                data-fa-transform="shrink-1"></span></span>Change Emoji</a><a
                                        class="nav-link d-flex align-items-center py-1 px-0 text-600" href="#!"><span
                                            class="conversation-info-icon"><span class="fas fa-bell me-1"
                                                data-fa-transform="shrink-1"></span></span>Notifications</a></div>
                            </div>
                            <hr class="my-2" />
                            <div class="px-3" id="others-info-10">
                                <div class="title" id="shared-media-title-10"><a
                                        class="btn btn-link btn-accordion hover-text-decoration-none dropdown-indicator"
                                        href="#shared-media-10" data-bs-toggle="collapse" aria-expanded="false"
                                        aria-controls="shared-media-10">Shared media</a></div>
                                <div class="collapse" id="shared-media-10" aria-labelledby="shared-media-title-10"
                                    data-parent="#others-info-10">
                                    <div class="row mx-n1">
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/1.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/1.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"><a href="../assets/img/chat/2.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/2.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/3.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/3.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/4.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/4.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/5.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/5.jpg" alt="" /></a></div>
                                        <div class="col-6 col-md-4 px-1"> <a href="../assets/img/chat/6.jpg"
                                                data-gallery="images-1"><img class="img-fluid rounded-1 mb-2"
                                                    src="../assets/img/chat/6.jpg" alt="" /></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chat-content-scroll-area scrollbar">
                        <div class="d-flex position-relative p-3 border-bottom mb-3 align-items-center">
                            <div class="avatar avatar-2xl me-3">
                                <img class="rounded-circle" src="../assets/img/team/23.jpg" alt="" />

                            </div>
                            <div class="flex-1">
                                <h6 class="mb-0"><a class="text-decoration-none stretched-link text-700"
                                        href="../pages/user/profile.html">Gemma Whelan</a></h6>
                                <p class="mb-0">You friends with Gemma Whelan. Say hi to start the conversation
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <form class="chat-editor-area">
                <div class="emojiarea-editor outline-none scrollbar" contenteditable="true"></div>
                <input class="d-none" type="file" id="chat-file-upload" />
                <label class="chat-file-upload cursor-pointer" for="chat-file-upload"><span
                        class="fas fa-paperclip"></span></label>
                <div class="btn btn-link emoji-icon" data-emoji-button="data-emoji-button"><span
                        class="far fa-laugh-beam"></span></div>
                <button class="btn btn-sm btn-send" type="submit">Send</button>
            </form>
        </div>
    </div>
</div>