import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AddOfferService } from 'src/app/core/services/add-offer.service';
import { BiddingService } from 'src/app/core/services/bidding.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ProductService } from 'src/app/core/services/product.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-add-offers',
  templateUrl: './add-offers.component.html',
  styleUrls: ['./add-offers.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class AddOffersComponent implements OnInit {
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public offerId: any;
  public OfferInfoForm: FormGroup;
  public ConditionForm: FormGroup;
  public showInvoiceGenerateData: boolean = false;
  public showdivwisedata: boolean = true;

  public currentPageNo: number = 0;
  public partyPageSize: number = 5;
  public goodsData = [];
  public filteredOptions: Observable<string[]>
  public myControl = new FormControl();
  public searchedProduct = [];

  public searchedProduct1 = [];
  public myControl1 = new FormControl();
  public filteredOptions1: Observable<string[]>
  public goodsData1 = [];



  public todaysDate: any;
  public maxDate: Date;
  public today: Date;
  public today1: Date;
  public todaysDate2: any;
  public discountType;
  public offerType;
  public options;
  public selectType;
  public activeColor: string = 'green';
  public baseColor: string = '#ccc';
  public overlayColor: string = 'rgba(255,255,255,0.5)';
  public dragging: boolean = false;
  public loaded: boolean = false;
  public imageLoaded: boolean = false;
  public imageSrcSeven: any;
  public offerPostId: any;
  isNextDisabled = true;
  isEditable = false;
  uploadImage = false;
  public offerPicUrl: any = '';
  public byPid: any;
  toatlPages: any;
  totalrow: any;
  p: any;
  public fdate: any;
  public tdate: any;
  public submitted: boolean = false;
  activeinactiveValue: any;
  // public OfferInfoForm = this._formBuilder.group({
  //   offerName: ['', Validators.required],
  //   description: ['', Validators.required],
  //   offerStatus: ['', Validators.required],
  //   validFrom: ['', Validators.required],
  //   validTo: ['', Validators.required]
  // });


  constructor(private _formBuilder: FormBuilder, private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddOffersComponent>,
    private addofferService: AddOfferService,
    private productService: ProductService,
    private biddingService: BiddingService,
    private titleCasePipe: TitleCasePipe,
    private toastService: ToastNotificationService, private router: Router,) {
    this.today = new Date(new Date().setDate(new Date().getDate() + 2));
    this.today1 = new Date(new Date().setDate(new Date().getDate() + 1));
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
  }

  ngOnInit(): void {
    this.todaysDate = new Date(new Date().setDate(new Date().getDate() + 1));
    this.todaysDate2 = new Date(new Date().setDate(new Date().getDate() + 2));
    this.getGoodsbyUser();
    this.getGoodsbyUser1();

    this.OfferInfoForm = this._formBuilder.group({
      offerName: ['', Validators.required],
      description: ['', Validators.required, Validators.minLength(4), Validators.maxLength(150)],
      offerStatus: ['', Validators.required],
      validTo: ['', Validators.required],
      validFrom: ['', Validators.required],
      ruleName: ['', Validators.required],
      offerType: ['', Validators.required],
      buyProduct: [null],
      barcode: [''],
      barcode1: [''],
      quantity: [''],
      quantity1: [''],
      discountType: [null],
      minAmount: [null],
      freeProduct: [''],
      selectDiscount: [null],
      discount: [null],
      options: [''],
    });

  }
  get f() {
    return this.OfferInfoForm.controls;
  }
  getGoodsbyUser() {
    this.productService.getGoodsProductByUser_Type({}, this.currentUser.id, "goods", "DESC", this.partyPageSize, (this.currentPageNo + 1)).then((res) => {

      this.goodsData = res.data.pageData;
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(name => name ? this.filterNames(name) : this.goodsData.slice())
        );
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      })
  }

  getGoodsbyUser1() {
    this.productService.getGoodsProductByUser_Type({}, this.currentUser.id, "goods", "DESC", this.partyPageSize, (this.currentPageNo + 1)).then((res) => {

      this.goodsData1 = res.data.pageData;
      this.filteredOptions1 = this.myControl1.valueChanges
        .pipe(
          startWith(''),
          map(name => name ? this.filterNames(name) : this.goodsData1.slice())
        );
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });        }
      })
  }

  private filterNames(name: string): string[] {
    return this.goodsData.filter(goods =>
      goods.itemName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }



  searchProduct(value: any) {
    console.log(" this.myControl.value", this.myControl.value, value)
    this.myControl.patchValue(value);
    console.log(" this.myControl.value2", this.myControl.value, value)
    if (value && (String(value).trim() != '' || value != null)) {
      this.biddingService.getAllProductsByUserSearch({}, this.currentUser.id, this.myControl.value, this.partyPageSize, (this.currentPageNo + 1))
        .then((res) => {
          this.searchedProduct = res.data.pageData;
          this.toatlPages = res.data.totalPages;
          this.totalrow = res.data.totalRows;
          this.p = res.data.currentPage;
          this.filteredOptions = this.myControl.valueChanges
            .pipe(
              startWith(''),
              map(name => name ? this.filterNames(name) : this.searchedProduct.slice())
            );
          this.byPid = res.data.pageData[0].id;
          //this.barcodeProduct=res.data.pageData[0].barcode;
          this.OfferInfoForm.patchValue({
            barcode: res.data.pageData[0].barcode,

          })
        },
          (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
              // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
              // this.toastService.openErrorSnackBar("Something Went Wrong.");
            }
            this.byPid = null;
            //this.barcodeProduct=res.data.pageData[0].barcode;
            this.OfferInfoForm.patchValue({
              barcode: null,

            })
          })
    } else {
      this.byPid = null;
      //this.barcodeProduct=res.data.pageData[0].barcode;
      this.OfferInfoForm.patchValue({
        barcode: null,

      })
    }
  }
  selectedProduct(itemName, event) {
    if (event.isUserInput) {
      console.log("function called inside if", itemName)
      this.biddingService.getAllProductsByUserSearch({}, this.currentUser.id, itemName, this.partyPageSize, (this.currentPageNo + 1))
        .then((res) => {
          this.searchedProduct = res.data.pageData;
          this.toatlPages = res.data.totalPages;
          this.totalrow = res.data.totalRows;
          this.p = res.data.currentPage;
          console.log("this.searchedProduct", this.searchedProduct);
          this.byPid = res.data.pageData[0].id;
          //this.barcodeProduct=res.data.pageData[0].barcode;
          this.OfferInfoForm.patchValue({
            barcode: res.data.pageData[0].barcode,

          })
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
            // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
            // this.toastService.openErrorSnackBar("Something Went Wrong.");
          }
        })
    }

  }

  searchProduct1(value) {
    if (value != '' || value != null) {
      this.biddingService.getAllProductsByUserSearch({}, this.currentUser.id, this.myControl1.value, this.partyPageSize, (this.currentPageNo + 1))
        .then((res) => {
          this.toatlPages = res.data.totalPages;
          this.totalrow = res.data.totalRows;
          this.p = res.data.currentPage;
          this.searchedProduct1 = res.data.pageData;
          this.filteredOptions1 = this.myControl1.valueChanges
            .pipe(
              startWith(''),
              map(name => name ? this.filterNames(name) : this.searchedProduct1.slice())
            );
          this.OfferInfoForm.patchValue({
            barcode1: res.data.pageData[0].barcode

          })

        },
          (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
              // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
              // this.toastService.openErrorSnackBar("Something Went Wrong.");
            }
          })
    } else {
      this.OfferInfoForm.patchValue({
        barcode1: null,

      })

    }
  }
  selectedProduct1(itemName, event) {
    if (event.isUserInput) {
      this.biddingService.getAllProductsByUserSearch({}, this.currentUser.id, itemName, this.partyPageSize, (this.currentPageNo + 1))
        .then((res) => {
          this.searchedProduct1 = res.data.pageData;
          this.toatlPages = res.data.totalPages;
          this.totalrow = res.data.totalRows;
          this.p = res.data.currentPage;
          //this.barcodeProduct=res.data.pageData[0].barcode;
          this.OfferInfoForm.patchValue({
            barcode1: res.data.pageData[0].barcode,

          })
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
            // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
            // this.toastService.openErrorSnackBar("Something Went Wrong.");
          }
        })
    }
  }
  getAllOffersType() {
    this.submitted = true;
    //console.log("hjgdghjghj", this.OfferInfoForm)

    if (this.OfferInfoForm.invalid) {
      // console.log(this.OfferInfoForm.controls);
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      // this.toastService.openErrorSnackBar("Please fill mandatory fields!!!");
      return false;
    }
    else {
      let data = {
        "addOffers": {
          "userId": this.currentUser.id,
          "offerName": this.OfferInfoForm.controls.offerName.value,
          "status": this.OfferInfoForm.controls.offerStatus.value,
          "fromDate": this.OfferInfoForm.controls.validFrom.value.toISOString(),
          "toDate": this.OfferInfoForm.controls.validTo.value.toISOString(),
          "description": this.OfferInfoForm.controls.description.value,
          "ruleName": this.OfferInfoForm.controls.ruleName.value,
          "offerType": this.OfferInfoForm.controls.offerType.value,
          "offerDiscountType": this.OfferInfoForm.controls.discountType.value,
          "selectDiscount": this.OfferInfoForm.controls.selectDiscount.value ? this.OfferInfoForm.controls.selectDiscount.value : null,
          "minimunAmount": this.OfferInfoForm.controls.minAmount.value ? this.OfferInfoForm.controls.minAmount.value : null,
          "freeProduct": this.OfferInfoForm.controls.freeProduct.value ? this.OfferInfoForm.controls.freeProduct.value : null,
          "discount": this.OfferInfoForm.controls.discount.value ? this.OfferInfoForm.controls.discount.value : null,
          "barcode": this.OfferInfoForm.controls.barcode.value ? this.OfferInfoForm.controls.barcode.value : null,
          "quantity": this.OfferInfoForm.controls.quantity.value ? this.OfferInfoForm.controls.quantity.value : null,
          "buyProductId": this.byPid ? this.byPid : null,
          "freeproductQuantity":this.OfferInfoForm.controls.quantity1.value ? this.OfferInfoForm.controls.quantity1.value : null,
          "offerImage": this.offerPicUrl
        }
      }

      this.addofferService.postAddOfferDetails(data).then((res: any) => {
        if (res) {
          this.offerPostId = res.data.id;
          console.log("Offer Id", this.offerPostId)
          this.toastService.toastMsg({
            title: "Success",
            content: "Offer Added Successfully!!!",
          });
          // this.toastService.openSnackBar("Offer Added Successfully!!!");
          this.submitted = false;
          this.showdivwisedata = false;
          this.uploadImage = true;
          this.router.navigate(["/pages/new-product/offer-list"]);
          this.addofferService.notifyofferAdded();
          this.dialogRef.close();
          // /pages/offer-list
          // this.submitted = false;
          // this.ConditionForm.reset();
          // this.OfferInfoForm.reset();
          // this.router.navigate(["/pages/offer-list"])
        }
      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      });
    }

  }

  onclear() {
    this.dialogRef.close();
    this.OfferInfoForm.reset();
    this.ConditionForm.reset();
    //this.todaysDate = new Date();
    //this.todaysDate2 = new Date();
  }
  getCredit(e) {
    console.log("e", e.source.value);
    this.activeinactiveValue = e.source.value;
    //console.log("activeinactiveValue", this.activeinactiveValue);


  }
  postOfferImage() {
    let data = {
      "location": this.OfferInfoForm.controls.offerName.value,
    }
  }



  getOfferType(event) {
    this.offerType = event.source.value;
    console.log(this.offerType)
  }
  getDiscountType(event) {
    this.discountType = event.source.value;
    console.log(this.discountType);
  }
  selectDiscount(event) {
    this.selectType = event.source.value;
    console.log(this.selectType)
  }
  handleCoverDragEnter() {
    this.dragging = true;
  }
  handleCoverDragLeave() {
    this.dragging = false;
  }
  handleCoverImageLoad() {
    this.imageLoaded = true;
  }
  handleCoverDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.coverImgChange(e);
  }
  coverImgChange(e) {
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];


    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);

      reader.onload = () => {
        // this.ProductForm.patchValue({
        //   file: reader.result,
        // });

        if (file == null) {
          // this.toastService.openErrorSnackBar(
          //   "Please Select Image To Upload.",
          // );
          this.toastService.toastMsg({
            title: "Error",
            content: "Please Select Image To Upload.",
          });
        } else {

          this.imageSrcSeven = reader.result;

          formdata.set("upload", file);
          e.srcElement.value = "";
          this.addofferService.postAddOfferImage(formdata).then((res: any) => {
            //console.log("image offer",res);
            this.offerPicUrl = res.data.location
            // this.toastService.openSnackBar("Offer Image Added Successfully!!!")

          }, (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
              // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
              // this.toastService.openErrorSnackBar("Something Went Wrong.");
            }
          });
        }


      }
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  cancelCoverImage() {

    this.imageSrcSeven = '';
    // this.productPicUrl = '';
    this.imageLoaded = false;

  }
  openDatepicker() {
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.OfferInfoForm.get('validFrom').value;
    if (isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.OfferInfoForm.get('validFrom').setValue(new Date());
    }
  }
  openDatepickerValidto(){
     // Check if the current value of taskdate is an "Invalid Date"
     const selectedDate2 = this.OfferInfoForm.get('validTo').value;
     if (isNaN(selectedDate2.getTime())) {
       // Set a default date or any valid date here
       this.OfferInfoForm.get('validTo').setValue(new Date());
     }

  }
}
