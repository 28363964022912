import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';
import { UrlConstants } from 'src/app/_helpers/url-constants';

@Component({
  selector: 'app-book-appointment-shop',
  templateUrl: './book-appointment-shop.component.html',
  styleUrls: ['./book-appointment-shop.component.scss']
})
export class BookAppointmentShopComponent implements OnInit {
  public allAppointmentShops :any = [];
  public searchKey: any;
  public defaultScheduleImage = Constants.images.defaultScheduleImage
  constructor( private appointmentService : AppointmentService,
    private router: Router,private toastService:ToastNotificationService,
    private toastNotificationService:ToastNotificationService,
    private titleCasePipe: TitleCasePipe) { 
      this.getAppointmentUsers();
    }

  ngOnInit(): void {
    
  }
  getAppointmentUsers() {
    this.appointmentService.getAppointmentUsers({}).then((res) => {
      this.allAppointmentShops = res.data
    },(err)=> {
      if(err.error.status === 404){
        this.toastService.toastMsg({
          title: "Error",
          content: "No data Found.",
        });
        // this.toastNotificationService.openErrorSnackBar("No data Found.");
      } else if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }

  getAppointmentUserssearch(searchKey) {
    this.appointmentService.getAppointmentUsersSearch({},searchKey).then((res) => {
      this.allAppointmentShops = res.data
      console.log(this.allAppointmentShops.length)
      if(this.allAppointmentShops.length > 0){
        this.toastService.toastMsg({
          title: "Success",
          content: "Shop Found Successfully!!!"
        });
        // this.toastNotificationService.openSnackBar("Shop Found Successfully!!!")
      }else{
        this.toastService.toastMsg({
          title: "Error",
          content: "Shop Not Found Successfully!!!"
        });
        // this.toastNotificationService.openErrorSnackBar("Shop Not Found Successfully!!!");
      }
      
      
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: "Shop Not Found Successfully!!!"
          });
          // this.toastNotificationService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong."
          });
          // this.toastNotificationService.openErrorSnackBar("Something Went Wrong.");
        }
    })
  }
}
