import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[ngxOnlyString]'
})
export class OnlyCharactersDirective {
  pattern = '^[a-zA-Z ]+';
  constructor() { }

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return new RegExp(this.pattern).test(event.key);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    event.preventDefault();
  }

}