import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardService } from 'src/app/core/services/dashboard.service'

@Component({
  selector: 'app-net-profit-chart',
  templateUrl: './net-profit-chart.component.html',
  styleUrls: ['./net-profit-chart.component.scss']
})
export class NetProfitChartComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public todaysDate = this.commonService.getTodaysDate();
  public netProfitChart: any = [];
  netProfitCurrentYearList: any[];
  netProfitLastYearList : any[];
  currentProfitDataList : any[]
  lastProfitDataList :any[]

  constructor(public commonService: CommonService, public dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.getBarChart()

  }
  getProfitLossCurrentYear(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dashboardService.getProfitLossCurrentYear({}, this.currentUser.id).then((response: any) => {
        this.netProfitCurrentYearList = response.data;
        this.currentProfitDataList = this.netProfitCurrentYearList.map((item: any) => item.Profit);
        resolve(); // Resolve the promise once the data is retrieved
      }).catch((error: any) => {
        reject(error); // Reject the promise if an error occurs
      });
    });
  }

  getProfitLossLastYear(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dashboardService.getProfitLossLastYear({}, this.currentUser.id).then((response: any) => {
        this.netProfitLastYearList = response.data;
        this.lastProfitDataList = this.netProfitLastYearList.map((item: any) => item.Profit);
        resolve(); // Resolve the promise once the data is retrieved
      }).catch((error: any) => {
        reject(error); // Reject the promise if an error occurs
      });
    });
  }

  getBarChart() {
    const currentYearPromise = this.getProfitLossCurrentYear();
    const lastYearPromise = this.getProfitLossLastYear();

    Promise.all([currentYearPromise, lastYearPromise]).then(() => {
      this.netProfitChart = Highcharts;
      var islastProfitDataEmpty = this.lastProfitDataList.every(value => value === 0);
    var iscurrentProfitDataEmpty = this.currentProfitDataList.every(value => value === 0);
    if (islastProfitDataEmpty && iscurrentProfitDataEmpty) {
      // Display a "No data found" message
      const chartOptions = {
        chart: {
          type: 'column',
          events: {
            load: function() {
              const chart = this;
              const centerX = chart.plotWidth / 2;
              const centerY = chart.plotHeight / 2;

              // Add the image element
              chart.noDataImage = chart.renderer
                .image('../../../assets/img/icons/spot-illustrations/notfound1.png', centerX - 90, centerY - 70, 200, 150)
                .add();
            }
          }
        },
        title: {
          text: 'Net Profit Last Year vs Current Year',
          style: {
            fontFamily: "'Poppins', sans-serif",
            fontWeight: '500',
            fontSize: '18px',
            color: '#737791'
          }
        },
        xAxis: {
          categories: ['Quarter 1', 'Quarter 2', 'Quarter 3', 'Quarter 4']
        },
        credits: {
          enabled: false
        },
      };
      this.netProfitChart.chart('column-chart', chartOptions);
      document.getElementById('column-chart').style.height = '300px';

    } else {
      const chartOptions = {
        chart: {
                type: 'column'
              },
              credits:{
                enabled: false
             },
              title: {
                text: 'Net Profit Last Year vs Current Year',
                style: {
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: '500',
                  fontSize: '18px',
                  color: '#737791'
                }
              },
              xAxis: {
                categories: ['Quarter 1', 'Quarter 2', 'Quarter 3', 'Quarter 4']
              },
              yAxis: {
                title: {
                  text: 'Net Profit'
                },
                gridLineWidth: 0
              },
              plotOptions : {
                column: {
                  dataLabels: {
                    // enabled: true,
                  },
                  tooltip: {
                    enabled: true,
                    formatter: function () {
                      return this.y; // Display the amount as the tooltip content
                    },
                  },
                },
              },
        series: [
          {
            name: 'Last Year',
            data: this.lastProfitDataList,
            color: '#110152',
            dataLabels: {
              enabled: true
            }
          },
          {
            name: 'Current Year',
            data: this.currentProfitDataList,
            color: '#FC7643',
            dataLabels: {
              enabled: true
            }
          }
        ]
      };

      this.netProfitChart.chart('column-chart', chartOptions);

      // Add CSS to adjust the size visually
      // document.getElementById('salesCanvas').style.width = '300px'; // Set your desired width
      document.getElementById('column-chart').style.height = '300px';
    }
    });
  }

}
