import { E, I } from '@angular/cdk/keycodes';
import { TitleCasePipe } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { PartyService } from 'src/app/core/services/party.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { HasPermissionDirective } from 'src/app/directive/has-permission.directive';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddPartyComponent } from '../Party/add-party/add-party.component';
import { PartyGroupAddComponent } from '../Party/party-group-add/party-group-add.component';
import { ProductAddComponent } from '../Product/product-add/product-add.component';
import { ServiceaddComponent } from '../Product/serviceadd/serviceadd.component';
import { AddOffersComponent } from '../Product/add-offers/add-offers.component';
import { SalesInvoiceComponent } from '../Invoice/sales-invoice/sales-invoice.component';
import { PaymentInComponent } from '../payment-in/payment-in.component';
import { EstimateComponent } from '../Invoice/estimate/estimate.component';
import { SaleOrderComponent } from '../sale-order/sale-order.component';
import { SalesReturnComponent } from '../Invoice/sales-return/sales-return.component';
import { PurchaseInvoiceComponent } from '../Invoice/purchase-invoice/purchase-invoice.component';
import { PaymentOutComponent } from '../payment-out/payment-out.component';
import { PurchaseOrderComponent } from '../purchase-order/purchase-order.component';
import { PuchaseReturnComponent } from '../Invoice/puchase-return/puchase-return.component';
import { AddBankComponent } from '../bank-module/add-bank/add-bank.component';
import { AddAppointmentComponent } from '../new-shop-schedule/appointment-page/add-appointment/add-appointment.component';
import { AddTimeScheduleComponent } from '../new-shop-schedule/time-schedule-page/add-time-schedule/add-time-schedule.component';
import { ExpenseAddComponent } from '../expense/expense-add.component';
import { AddCampaignComponent } from '../campaign/add-campaign/add-campaign.component';
import { AdminAddRoleFormComponent } from '../admin-new-module/admin-add-role-form/admin-add-role-form.component';
import { AddRoleBasedUsersComponent } from '../add-role-based-users/add-role-based-users.component';
import { AddLeadComponent } from '../crmmodule/add-lead/add-lead.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { FormControl } from '@angular/forms';
import { NewStructureComponent } from '../payroll/new-structure/new-structure.component';
import { AddTimeComponent } from '../timesheet/add-time/add-time.component';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  isFluid = JSON.parse(localStorage.getItem("isFluid"))
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public permissionArray = JSON.parse(localStorage.getItem("Permission"));
  public showTimeInterval: any;
  public interval: any;
  public difference: any;
  public notificationReadArray:any = [];
  public notificationUnreadArray:any = [];
  public isData: boolean = false;
  public type="Bidding"
  public imageLoaded: boolean = false;
  public imageSrc: any = '';
  public permissionData: any;
  public isShow : boolean = false;
  public currentPageNo: number = 0;
  public pageSize: number = 100;
  @ViewChild(HasPermissionDirective) directive = null
  searchResults: any[];
  searchTerm: string = '';
  categories: string[] = [
    "Contacts", "Product", "Sale", "Purchase", "Bank", "Bid", "Shop Schedule",
    "Expense", "Campaign", "Admin", "Employee", "Payroll", "Time Sheet", "CRM", "GST Report",
    "Remote Shopping", "Ledger","Contact Us", "Tickets", "Invoice Setting", "Help and FAQs", "Assets", "Books of Account", "Organization Structure"
  ];
  uaeCategories: string[] = [
    "Contacts", "Product", "Sale", "Purchase", "Contact Us","Help and FAQs" ];

  filteredCategories: string[] = [];
  categoryRouterLinks: { [key: string]: string } = {
    "Contacts": "/pages/party-page",
    "Product": "/pages/new-product",
    "Sale": "/pages/sale_new",
    "Purchase" : "/pages/purchase-page",
    "Bank" : "/pages/Bank-page",
    "Bid" : "/pages/Bid-page",
    "Shop Schedule" : "/pages/shop-schedule-page",
    "Expense" : "/pages/expense_page",
    "Campaign" : "/pages/campaign-page",
    "Admin" : "/pages/Admin-page",
    "Employee" : "/pages/employee-page",
    "Payroll" : "/pages/payroll-page",
    "Time Sheet" : "/pages/time-sheet",
    "CRM" : "/pages/crm",
    "GST Report" : "/pages/reportgst",
    "Remote Shopping" :"/pages/remote-shop-page",
    "Ledger"  : "pages/ledger-page/create-ledger",
    "Books of Account"  : "pages/books-of-account",
    "Assets"  : "pages/new-assets",
    "Organization Structure"  : "pages/organization-structure",
    "Contact Us" : "/pages/Contact-page-new",
    "Tickets" : "/pages/contactlist-page",
    "Invoice Setting" : "/pages/Setting-page/invoice_generate",
    "Help and FAQs"  : "/pages/help-faq-page",
  };
  isArabic: boolean;
  // @ViewChild("insideElement") insideElement;

  constructor(private authService: AuthService, private renderer: Renderer2, private el: ElementRef,
    public profileService: ProfileService, public dialog: MatDialog,
    public router: Router, public partyService : PartyService,
    public subscriptionService: SubscriptionService,
    public notificationService: NotificationService,
    private rolePermission:RolesPermissionsService,
    private toastService:ToastNotificationService,
    private titleCasePipe: TitleCasePipe, private commonService : CommonService
    ) {
      // this.commonService.getData().subscribe((res)=>{
      // })
     }

  ngOnInit(): void {
    // Subscribe to the observable to receive updates
    this.commonService.isArabic$.subscribe((isArabic) => {
      this.isArabic = isArabic;
    });
        //call profile get api
    this.partyService.partyAdded$.subscribe(() => {
      // Fetch the updated list of users
      this.getProfileData();
    });
    this.getPermissionById();
    this.getProfileData();

    this.getSubscribedUser();

    if(this.currentUser == null){
      this.router.navigate(["/auth"]);
    } else {
      this.interval = setInterval(() => {
        this.getExpirationTime();
      }, 1000);
    }
    this.getAllNotificationByUserId();
  }

  @ViewChild('searchInput') searchInput!: ElementRef;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    // console.log("inb my keyborad listner Functions")
    if (event.ctrlKey && event.key === '/') {
      // console.log("inside if keyborad listner")
      event.preventDefault(); // Prevent the default browser behavior
      this.searchInput.nativeElement.focus();
    }
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: any) {
    if (!this.searchInput.nativeElement.contains(event.target)) {   // If clicked outside then the dropdown is get closed
      this.closeDropdown();
    }
  }

  getPermissionById() {
    if(this.currentUser != null){
      this.rolePermission.getPermissions({}, this.currentUser.id).then((res) => {
        this.permissionData=res.data;
        localStorage.setItem("Permission", JSON.stringify({
          data: res.data,
          id: res.id,
        }))
      })
    }
  }

  getSubscribedUser() {
    if (this.currentUser != null && this.currentUser.userType == 'CLIENT') {
      this.subscriptionService.getSubscribedUsersDetails({}, this.currentUser.id).then((res) => {
      })
    }
  }
  getExpirationTime() {
    let planExpiration = new Date(this.currentUser.planExpiresOn).getTime();
    let currentDate = new Date().getTime()
    this.difference = planExpiration - currentDate;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(this.difference / (1000 * 60 * 60 * 24));
    var hours = Math.floor((this.difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((this.difference % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((this.difference % (1000 * 60)) / 1000);

    // Output the result in an element with id="demo"
    this.showTimeInterval = days + "d " + hours + "h "
      + minutes + "m " + seconds + "s ";
    // If the count down is over, write some text
    if (this.difference < 0) {
      clearInterval(this.interval);
      this.showTimeInterval = "EXPIRED";
    }
  }
  logout(): void {
    this.authService.logout();
    this.router.navigateByUrl("/auth/login")
  }
  /**
   * get notification by userId
   */
   getAllNotificationByUserId(){
    this.notificationService.getAllNotification({},this.currentUser.id,this.pageSize, (this.currentPageNo + 1)).then((res)=>{
      this.isData = true;
      let notification = res.data.pageData
      notification.forEach(element => {
        if(element.read == true){
          // element.read = null;
          this.notificationReadArray.push(element);
          this.isShow = false;
          this.notificationUnreadArray = [];
        }

        else if(element.read === null) {
           this.notificationUnreadArray.push(element);
          // console.log(element);
          this.isShow = true;
          this.notificationReadArray = [];
          // this.notificationReadArray.push(element);
          console.log(this.notificationReadArray);
        }
        // console.log(this.notificationReadArray);
      });

    }
    ,(err) => {
      if(err.error.expose){
        this.isData = false;
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else{
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went To Wrong",
          });
        // this.toastService.openErrorSnackBar("Something Went To Wrong");
      }

    }
    )
  }
  getProfileData() {
    this.profileService.getUserProfile({}, this.currentUser.id).then((res => {
      this.imageSrc = res.data.basicdetails.profileImage

    }),
    )}
  handleImageLoad() {
    this.imageLoaded = true;
  }
  GoTOPages(type){
    if(type){
      this.router.navigate(["/pages/client-bid"]);
    }
  }

  onSearch() {
    if(this.isArabic){
    this.filteredCategories = this.uaeCategories.filter(category =>
      category.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }else {
    this.filteredCategories = this.categories.filter(category =>
      category.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  }

  onEnter() {
    // If user entered a category then this function will get called
    if (this.filteredCategories.length > 0) {
      const firstCategory = this.filteredCategories[0];
      const routerLink = this.categoryRouterLinks[firstCategory];
      if (routerLink) {
        this.router.navigateByUrl(routerLink);
      }
    }
    this.closeDropdown()  //after the navigation is done dropdown get closed
  }

  closeDropdown() {
    this.filteredCategories = [];
    this.searchTerm = ''
  }

  openDialog(item : string) {
    let componentName;
    if(item === "Vendor_Group"){
      componentName = PartyGroupAddComponent;
    } else if(item === 'Contact') {
      componentName = AddPartyComponent;
    } else if(item === 'Product') {
      componentName = ProductAddComponent;
    } else if(item === 'Service') {
      componentName = ServiceaddComponent;
    } else if(item === 'Offer') {
      componentName = AddOffersComponent;
    } else if(item === 'sale-invoice') {
      componentName = SalesInvoiceComponent;
    } else if(item === 'payment-in') {
      componentName = PaymentInComponent;
    } else if(item === 'Estimation-Invoice') {
      componentName = EstimateComponent;
    } else if(item === 'sale-order') {
      componentName = SaleOrderComponent;
    } else if(item === 'sale-return') {
      componentName = SalesReturnComponent;
    } else if(item === 'purchase-invoice') {
      componentName = PurchaseInvoiceComponent;
    } else if(item === 'payment-out') {
      componentName = PaymentOutComponent;
    } else if(item === 'purchase-order') {
      componentName = PurchaseOrderComponent;
    } else if(item === 'purchase-return') {
      componentName = PuchaseReturnComponent;
    } else if(item === 'bank') {
      componentName = AddBankComponent;
    } else if(item === 'Appointment') {
      componentName = AddAppointmentComponent;
    } else if(item === 'Time-Schedule') {
      componentName = AddTimeScheduleComponent;
    } else if(item === 'Expense') {
      componentName = ExpenseAddComponent;
    } else if(item === 'Campaign') {
      componentName = AddCampaignComponent;
    } else if(item === 'Admin') {
      componentName = AdminAddRoleFormComponent;
    } else if(item === 'Employee') {
      componentName = AddRoleBasedUsersComponent;
    } else if(item === 'Lead') {
      componentName = AddLeadComponent;
    } else if(item === 'New-Structure') {
      componentName = NewStructureComponent;
    } else if(item === 'Time') {
      componentName = AddTimeComponent;
    }
    const dialogRef: MatDialogRef<Component> = this.dialog.open(componentName, {
      height : '100vh',
      width : 'auto',
      data: { },
      disableClose:true,
      position: {
        right: '0',
      },
    });
  }

}
