<!-- <div class="modal-header flex d-flex flex-row pt-0" style="justify-content:center; align-items: center;">
    <h1 style="color: #EB8B3F; font-weight: bolder;" class="modal-title mb-1">Add Appointment</h1>

    <div class="flex d-flex flex-row" style="margin-left: auto;">


        <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel" (click)="cancel()">Cancel</button>

        <button type="button" class="btn btn-save" (click)="getAppointmentType()">Save</button>

    </div>

</div> -->

<div class="modal-header pt-0">

  <h1 style="color: #EB8B3F; font-weight: 500;" class="modal-title mb-0">Add Appointment</h1>

  <div class="addProductButtons">

    <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel" (click)="cancel()">Cancel</button>

    <button type="button" class="btn btn-save" (click)="getAppointmentType()">Save</button>

  </div>

</div>

<div class="modal-body">
    <form [formGroup]="addAppointmentform">
        <div class="row">
            <div class="col-sm-12 col-lg-12 col-md-12">
                <div class="mb-1">
                    <label class="form-label">Name<span class="error-asterisk">*</span> </label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input matInput placeholder="Name" formControlName="name" required autocomplete="off"
  [ngClass]="{'is-invalid': f?.name?.invalid && f?.name?.touched && submitted}" />

                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="submitted || f?.name?.touched">
                            <mat-error *ngIf="f?.name?.errors?.required">
                              Name Is Required!
                            </mat-error>
                          </mat-error>
                          
                        
                </div>
            </div>

            <div class="col-sm-12 col-lg-12 col-md-12">
                <div class="mb-1">
                    <label class="form-label">Duration<span class="error-asterisk">*</span> </label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input matInput placeholder="Duration" formControlName="duration" required autocomplete="off"
  [ngClass]="{'is-invalid': f?.duration?.invalid && f?.duration?.touched && submitted}" />

                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="submitted || f?.duration?.touched">
                            <mat-error *ngIf="f?.duration?.errors?.required">
                              Duration Is Required!
                            </mat-error>
                          </mat-error>
                        <!-- <input matInput placeholder="Duration" formControlName="duration" required autocomplete="off"
                        [ngClass]="{
                            'is-invalid':
                              f.duration.invalid && f.duration.touched && submitted
                          }">
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="submitted || f.duration.touched">
                        <mat-error *ngIf="f.duration.errors?.required">
                            Duration Is Required!
                        </mat-error>
                    </mat-error> -->
                </div>
            </div>

            <div class="col-sm-12 col-lg-12 col-md-12">
                <div class="mb-1">
                    <label class="form-label">Shop Location<span class="error-asterisk">*</span> </label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-select formControlName="LocationType" placeholder="Shop Location" [ngClass]="{
                        'is-invalid':
                        f.LocationType.invalid && f.LocationType.touched && submitted
                      }">
                            <mat-option *ngFor="let location of locations" [value]="location.shopId">
                                {{ location.tradeName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="submitted || f.LocationType.touched">
                        <mat-error *ngIf="f.LocationType.errors?.required">
                            Shop Location Is Required!
                        </mat-error>
                    </mat-error>
                </div>
            </div>
            <div class="col-sm-12 col-lg-12 col-md-12">
                <div class="mb-1">
                    <label class="form-label">User Role<span class="error-asterisk">*</span> </label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-select formControlName="UserRole" placeholder="User Role" [ngClass]="{
                    'is-invalid':
                    f.UserRole.invalid && f.UserRole.touched && submitted
                  }">
                            <mat-option *ngFor="let userR of userRoles" [value]="userR.userRole">
                                {{userR.userRole}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="submitted || f.UserRole.touched">
                        <mat-error *ngIf="f.UserRole.errors?.required">
                            User Role Is Required!
                        </mat-error>
                    </mat-error>
                </div>
            </div>


            <div class="col-sm-12 col-lg-12 col-md-12">
                <div class="mb-1">
                    <label class="form-label">Description<span class="error-asterisk">*</span> </label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <textarea matInput cols="35" rows="4" placeholder="Description" formControlName="Description"
                            [ngClass]="{
                    'is-invalid':
                    f.Description.invalid && f.Description.touched && submitted
                  }">
                </textarea>
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="submitted || f.Description.touched">
                        <mat-error *ngIf="f.Description.errors?.required">
                            Description Is Required!
                        </mat-error>
                    </mat-error>
                </div>
            </div>

            <div class = "my-2">
                <mat-checkbox formControlName="Cancellation" style = "margin-left: 5px;">
                    <span style = "font-size: 14px;">Cancellation</span>
                </mat-checkbox>

            </div>
            <!-- <div>
                <mat-checkbox formControlName="isDisable">
                    Is Disable
                </mat-checkbox>

            </div> -->

            <div class="col-sm-12 col-lg-12 col-md-12">
                <div class="mb-1">
                    <label class="form-label">Within Days<span class="error-asterisk">*</span> </label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input matNativeControl type="number" required matInput formControlName="withinDays"
                            placeholder="Enter Days" min="0" oninput="this.value = Math.abs(this.value)" [ngClass]="{
                        'is-invalid':
                        f.withinDays.invalid && f.withinDays.touched && submitted
                      }">
                    </mat-form-field>
                    <mat-error class="text-danger" *ngIf="submitted || f.withinDays.touched">
                        <mat-error *ngIf="f.withinDays.errors?.required">
                            Within Days Is Required!
                        </mat-error>
                    </mat-error>
                </div>
            </div>
        </div>
    </form>
</div>
