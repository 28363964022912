<div class="card">
    <div class="card-header">
        <div class="bg-light pt-3 pb-3">
            <div class="col-md-auto col-lg-auto col-sm-auto">
                <p class="d-none d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
                    <!-- Shopping Cart ({{ quantityCount }} Items) -->
                    Shopping Cart ({{ count }} Items)
                </p><span class="text-end" style="margin-left: 47%!important;"> <a class="btn btn-outline-primary border-300 me-2"
                    routerLink="/pages/customer/remote-customer">
                    <span class="fas fa-chevron-left me-1 text-end" data-fa-transform="shrink-4">
                    </span>Continue Shopping
                </a>
                <a class="btn btn-outline-primary" routerLink="/pages/customer/checkout">Checkout</a></span>
                <!-- <h5 class="mb-3 mb-md-0">Shopping Cart ({{ quantityCount }} Items)</h5> -->
            </div>
            <!-- <div class="col-md-auto">
                <a class="btn btn-outline-primary border-300 me-2"
                    routerLink="/pages/customer/remote-customer">
                    <span class="fas fa-chevron-left me-1" data-fa-transform="shrink-4">
                    </span>Continue Shopping
                </a>
                <a class="btn btn-outline-primary" routerLink="/pages/customer/checkout">Checkout</a>
            </div> -->
          
        </div>
        <div class="progress" style="height: 2px">
            <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
            </div>
        </div>
    </div>

    <div class="card-body p-0 m-2">
        <!-- <div class="row min-vh-50 flex-center g-0" *ngIf="!isData">
            <h4 class="text-center">No Retailer to show....</h4>
         </div> -->
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 col-xl-3">
                <label class = "form-label">Coupon Code<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input [textMask]="{mask: mask}" placeholder="Enter Coupon Value" matNativeControl type="text"
                        required matInput>
                </mat-form-field>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-bordered fs--1 mb-0  m-1">
                <thead class="bg-200 text-900">
                    <tr>
                        <th scope="col">#</th>
                        <th class="text-nowrap">Name</th>
                        <th class="text-nowrap">Quantity</th>
                        <th class="text-nowrap">Price</th>
                        <th class="text-nowrap">GST(in %)</th>
                        <th class="text-nowrap">GST</th>
                        <th class="text-nowrap">Total</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="btn-reveal-trigger text-left" *ngFor="let item of productArray; let i = index">
                        <td >{{ i+1 }}</td>
                        <td>{{ item.itemName | titlecase }}</td>
                        <td class="paddingtd">
                            <div class="input-group input-group-sm divheight " data-quantity="data-quantity">
                            <button class="btn btn-sm btn-outline-secondary border-300 px-2" data-type="minus"
                                (click)="putProductQuantity(item.cartId,item.quantity,'-')">-</button>
                            <input class="form-control text-center  input-spin-none" type="text"
                                [(ngModel)]="item.quantity" (keyup.enter)="putProductQuantity1(item.cartId,item.quantity)" aria-label="Amount (to the nearest dollar)"
                                style="width: 50px" />
                            <button class="btn btn-sm btn-outline-secondary border-300 px-2" data-type="plus"
                                (click)="putProductQuantity(item.cartId,item.quantity,'+')">+</button>
                        </div>
                    </td>
                        <td>{{ item.totalPrice | currency:'INR':'symbol':'1.2-2'}}</td>
                        <td>{{ item.gst }}</td>
                        <td>{{ item.gstAmount | currency:'INR':'symbol':'1.2-2'}}</td>
                        <td>{{ item.finalAmount | currency:'INR':'symbol':'1.2-2'}}</td>
                        <td><span class="bi-trash icon-color fs-1" data-bs-toggle="modal" data-bs-placement="top"
                            title="Delete Product"  data-bs-target="#errorDelete-modal" (click)="deleteProduct(item.cartId)"></span></td>
                    </tr>
                </tbody>
             
            </table>
        </div>









        <!-- <div class="row gx-card mx-0 bg-200 text-900 fs--1 fw-semi-bold">
            <div class="col-4 col-md-4 py-2">Name</div>
            <div class="col-8 col-md-8">
                <div class="row">
                    <div class="col-md-2 py-2 d-none d-md-block text-left">Quantity</div>
                    <div class="col-12 col-md-2 text-center py-2">Price</div>
                    <div class="col-12 col-md-2 text-center py-2">GST(in %)</div>
                    <div class="col-12 col-md-2 text-center py-2">GST(in Rs.)</div>
                    <div class="col-12 col-md-2 text-center py-2">Total</div>
                    <div class="col-12 col-md-2 text-center py-2">Action</div>
                </div>
            </div>
        </div>
        <div class="row gx-card mx-0 align-items-center border-bottom border-200" *ngFor="let item of productArray">
            <div class="col-4 py-3">
                <div class="d-flex align-items-center">
                    <div class="flex-1">
                        <h5 class="fs-0">
                            <a class="text-900">{{ item.itemName | titlecase }}</a>
                        </h5>
                     <div class="fs--2 fs-md--1"><a class="text-danger pointer-event-cursor"
                                (click)="deleteProduct(item.cartId)">Remove</a></div> -->
                    <!-- </div>
                </div>
            </div>
            <div class="col-8 py-3">
                <div class="row align-items-center">
                    <div class="col-md-2 d-flex text-center order-1 order-md-0">
                        <div>
                            <div class="input-group input-group-sm flex-nowrap" data-quantity="data-quantity">
                                <button class="btn btn-sm btn-outline-secondary border-300 px-2" data-type="minus"
                                    (click)="putProductQuantity(item.cartId,item.quantity,'-')">-</button>
                                <input class="form-control text-center px-2 input-spin-none" type="text"
                                    [(ngModel)]="item.quantity" (keyup.enter)="putProductQuantity1(item.cartId,item.quantity)" aria-label="Amount (to the nearest dollar)"
                                    style="width: 50px" />
                                <button class="btn btn-sm btn-outline-secondary border-300 px-2" data-type="plus"
                                    (click)="putProductQuantity(item.cartId,item.quantity,'+')">+</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 text-center ps-0 order-0 order-md-1 mb-2 mb-md-0 text-600">{{ item.totalPrice |
                        currency:'INR':'symbol':'1.2-2' }}</div>
                    <div class="col-md-2 text-center ps-0 order-0 order-md-1 mb-2 mb-md-0 text-600">{{ item.gst }}%
                    </div>
                    <div class="col-md-2 text-center ps-0 order-0 order-md-1 mb-2 mb-md-0 text-600">{{ item.gstAmount |
                        currency:'INR':'symbol':'1.2-2' }}</div>
                    <div class="col-md-2 text-center ps-0 order-0 order-md-1 mb-2 mb-md-0 text-600">{{ (item.gstAmount +
                        item.totalPrice) |
                        currency:'INR':'symbol':'1.2-2' }}</div>
                    
                    <div
                        class="col-md-2 text-center ps-0 order-0 order-md-1 mb-2 mb-md-0 text-600 pointer-event-cursor">
                        <span class="bi-trash icon-color fs-1" data-bs-toggle="modal" data-bs-placement="top"
                            title="Delete Product"  data-bs-target="#errorDelete-modal" (click)="deleteProduct(item.cartId)"></span>
                         Delete -->
<!-- 
                    </div>

                </div>
            </div>
        </div> -->

        <div class="row fw-bold gx-card mx-0 mt-3">
            <div class="col-8 col-md-8  text-end text-900  text-end">Total</div>
            
                    
                    <!-- <div class="col-md-4 py-2 d-none d-md-block text-center">{{ quantityCount }} Items</div> -->
                    <div class="col-md-2  d-none d-md-block text-end">{{ count }} Items</div>
                    <div class="col-12 col-md-2 text-left text-end">{{ totalBillAmount |
                        currency:'INR':'symbol':'1.2-2'}}</div>
                <!-- </div>
            </div> -->
        </div>


    </div>
    <!-- <div class="card-body p-0" *ngIf="productArray.length <= 0">
        <ngx-spinner bdOpacity="1" bdColor="rgba(97,197,212,0.07)" size="medium" color="#0047AB"
            type="ball-spin-clockwise" fullScreen="true">
            <p style="color: #61c5d4;">Loading..</p>
        </ngx-spinner>
    </div> -->
    <div class="card-footer bg-light d-flex justify-content-end">
        <form class="me-3">
            <!-- <div class="input-group input-group-sm">
          <input class="form-control" type="text" placeholder="Promocode" />
          <button class="btn btn-outline-secondary border-300 btn-sm" type="submit">Apply</button>
        </div> -->
        </form><a class="btn btn-outline-primary" routerLink="/pages/customer/checkout">Checkout</a>
    </div>
</div>

<div class="modal fade" id="errorDelete-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 350px">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
                    aria-label="Close"></a>
            </div>
            <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                    <h2 class="mb-1 text-center font-weight-bold" style="font-weight: 800; " id="modalExampleDemoLabel">
                        Delete Shopping Cart? </h2>
                </div>
                <h4 class="text-center ">Are You Sure To Delete this Cart?</h4>

            </div>
            <div class="modal-footer justify-content-center">
                <a class="btn btn-outline-primary" data-bs-dismiss="modal">Close</a>
                <a class="btn btn-outline-primary" data-bs-dismiss="modal" (click)="removeCart()">Delete
                </a>

            </div>
        </div>
    </div>
</div>