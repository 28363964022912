<div class="modal-header pt-0">
    <h1 style="color: #EB8B3F; font-weight: bolder;margin-left: 5px;" class="modal-title mb-0">Add Product</h1>
    <div class="addProductButtons" *ngIf="!isArabic">
        <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel"
            (click)="clearInput()">Cancel</button>
        <button type="submit" class="btn btn-save" (click)="addGoods()"
            *appHasPermission="['delete', 'modify','create', 'read']; name 'products'">Save</button>
    </div>
</div>
<div class="modal-body">
    <form [formGroup]="ProductForm" *ngIf="!isArabic">
        <div class="row mb-3">
            <!-- Item Name -->
            <div style="margin-bottom: -13px;  font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Item Name<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="Item Name" formControlName="itemName"
                        [ngClass]="{
                    'is-invalid': (f.itemName.invalid && f.itemName.touched) && submitted }" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.itemName.touched)">
                    <mat-error *ngIf="f.itemName.errors?.required">
                        Item Name is required!
                    </mat-error>
                </mat-error>
            </div>

            <!-- item Code -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Item Code<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="Item Code" formControlName="itemCode"
                        [ngClass]="{
                      'is-invalid': (f.itemCode.invalid && f.itemCode.touched) && submitted }" minlength="4"
                        maxlength="8" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.itemCode.touched) && f.itemCode.errors?.pattern">
                    Item Code must be alphanumeric value, Special character not Allowed !
                </mat-error>
                <mat-error class="text-danger" *ngIf="(submitted || f.itemCode.touched) && f.itemCode.errors?.required">
                    Item Code is required!
                </mat-error>
                <mat-error *ngIf="f.itemCode.errors?.minlength">
                    Item Code must be at least 4 character.
                </mat-error>
                <mat-error *ngIf="f.itemCode.errors?.maxlength">
                    Item Code must be at least 8 characters.
                </mat-error>
            </div>
        </div>

        <div class="row mb-3">
            <!-- Generate Barcode -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Generate Barcode<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="Barcode" type="text"
                        formControlName="generateBarcode" [ngClass]="{
                        'is-invalid': (f.generateBarcode.invalid && f.generateBarcode.touched) && submitted }"
                        minlength="12" maxlength="12" (keypress)="numberOnly($event)" autocomplete="off">

                </mat-form-field>
                <a style="cursor: pointer;color:blue;margin-left: 5px;" (click)="autoGenerate()">Auto Generate</a>


                <mat-error class="text-danger"
                    *ngIf="(submitted || f.generateBarcode.touched) && f.generateBarcode.errors?.pattern">
                    Barcode is required!
                </mat-error>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.generateBarcode.touched) && f.generateBarcode.errors?.required">
                    Barcode is required!
                </mat-error>
                <mat-error *ngIf="f.generateBarcode.errors?.maxlength">
                    Barcode must be 12 digit.
                </mat-error>
                <mat-error *ngIf="f.generateBarcode.errors?.minlength">
                    Barcode must be 12 digit.
                </mat-error>
            </div>

            <div style="margin-bottom: -13px; font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Brand</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl disableOptionCentering formControlName="brand"
                        placeholder="Brand">
                        <mat-option *ngFor="let item of ProductBrand" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row mb-3">
            <!-- Category -->
            <div style="margin-bottom: -13px; font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Category<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl required disableOptionCentering formControlName="category"
                        placeholder="Category" (selectionChange)="getCategoryType($event)">
                        <mat-option *ngFor="let item of productCategory"
                            [value]="item.id">{{item.categoryName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.category.touched)">
                    <mat-error *ngIf="f.category.errors?.required">
                        Category is required!
                    </mat-error>
                </mat-error>
            </div>
            <div style="margin-bottom: -13px; font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Sub Category</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl disableOptionCentering formControlName="subCategoryId"
                        placeholder="Sub Category">
                        <mat-option *ngFor="let item of activeSubCategoryList" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row mb-3">
            <div style="margin-bottom: -13px; font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Gender</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl disableOptionCentering formControlName="gender" placeholder="Gender">
                        <mat-option value="Boys"> Boys </mat-option>
                        <mat-option value="Girls"> Girls </mat-option>
                        <mat-option value="Man"> Man </mat-option>
                        <mat-option value="Women"> Women </mat-option>
                        <mat-option value="Unisex"> Unisex </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="margin-bottom: -13px; font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Section</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl matInput placeholder="Section" formControlName="section" autocomplete="off">
                </mat-form-field>
            </div>
        </div>

        <div class="row mb-3">
            <div style="margin-bottom: -13px; font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Color</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl matInput placeholder="Color" formControlName="color" autocomplete="off">
                </mat-form-field>
            </div>
            <div style="margin-bottom: -13px; font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Age Group</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl matInput placeholder="Age Group" formControlName="ageGroup"
                        autocomplete="off">
                </mat-form-field>
            </div>
        </div>

        <div class="row mb-3">
            <!-- HSN -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">HSN/SAC Code<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="HSN Code" type="text" formControlName="hsn"
                        [ngClass]="{'is-invalid': (f.hsn.invalid && f.hsn.touched) && submitted }" minlength="6"
                        maxlength="6" (keypress)="numberOnly($event)" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.hsn.touched) && f.hsn.errors?.pattern">
                    HSN is required!
                </mat-error>
                <mat-error class="text-danger" *ngIf="(submitted || f.hsn.touched) && f.hsn.errors?.required">
                    HSN is required!
                </mat-error>
                <mat-error *ngIf="f.hsn.errors?.maxlength">
                    HSN must be at least 6 digit.
                </mat-error>
                <mat-error *ngIf="f.hsn.errors?.minlength">
                    HSN must be at least 6 digit.
                </mat-error>

            </div>

            <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Sale Price Inc. GST<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="Sale Price Inc. GST" formControlName="price"
                        [ngClass]="{
                        'is-invalid': (f.price.invalid && f.price.touched) && submitted }"
                        (keypress)="numberOnly($event)" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.price.touched)">
                    <mat-error *ngIf="f.price.errors?.required">
                        Price is required!
                    </mat-error>
                </mat-error>
            </div>

        </div>

        <div class="row mb-3">
            <!-- User New Product -->
            <!-- <div style="margin-bottom: -13px; font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Product Type<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl required disableOptionCentering formControlName="used"
                        placeholder="Product Type">
                        <mat-option value=false>New</mat-option>
                        <mat-option value=true>Used</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.used.touched)">
                    <mat-error *ngIf="f.used.errors?.required">
                        Product Type is required!
                    </mat-error>
                </mat-error>
            </div> -->
            <div *ngIf="currentUser.userType !== 'WAREHOUSE'" style="margin-bottom: -13px; font-size: 12px" class=" col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Warehouse</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select matNativeControl disableOptionCentering formControlName="warehouse" placeholder="Warehouse" (selectionChange)="onWarehouseSelectionChange($event)">
                        <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.id">
                            {{warehouse.firstName}}
                        </mat-option>
                        <mat-option value="clear">Clear Selection</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row mb-2">
            <!-- Item Description -->
            <div class="col-sm-12 col-md-12 col-lg-12">
                <label class="form-label">Item Description</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput rows="3" placeholder="Enter Item Description" formControlName="description"
                        autocomplete="off"></textarea>
                </mat-form-field>
                <!-- <mat-error class="text-danger" *ngIf="(submitted || f.description.touched)" >
                    <mat-error *ngIf="f.description.errors?.required">
                        Item Description is required!
                    </mat-error>
                    [ngClass]="{
                        'is-invalid': (f.itemCode.invalid && f.itemCode.touched) && submitted }"
                </mat-error> -->
            </div>

        </div>


        <div class="row mb-3">
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Additional Cess</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl matInput placeholder="Additional Cess" type="text"
                        formControlName="additionalCess" (keypress)="numberOnly($event)" autocomplete="off">
                </mat-form-field>
                <!-- <mat-error class="text-danger"
                    *ngIf="(submitted || f.additionalCess.touched)">
                    <mat-error *ngIf="f.additionalCess.errors?.required">
                        Additional Cess is required!
                    </mat-error>
                </mat-error> -->
            </div>

            <!-- Gst Rate -->
            <div style=" margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">GST Rate(%)<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl min="0" required matInput placeholder="GST Rate" type="number"
                        formControlName="gstRate" [ngClass]="{
                    'is-invalid': (f.gstRate.invalid && f.gstRate.touched) && submitted }"
                        (keypress)="numberDecimalOnly($event)" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.gstRate.touched)">
                    <mat-error *ngIf="f.gstRate.errors?.required">
                        Gst Rate is required!
                    </mat-error>
                    <mat-error *ngIf="f.gstRate.errors?.pattern">
                        GST Rate Only number allowed!
                    </mat-error>
                </mat-error>
            </div>

        </div>

        <!-- Additional Cess -->
        <div class="row mb-3">

            <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Support Type</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select formControlName="supportType" matNativeControl disableOptionCentering
                        placeholder="Select Support Type">
                        <mat-option value="GUARANTEE">Guarantee</mat-option>
                        <mat-option value="WARRANTY">Warranty</mat-option>
                        <mat-option value="NONE">None
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="margin-bottom: -13px; font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                *ngIf="this.ProductForm.get('supportType').value =='GUARANTEE' ||this.ProductForm.get('supportType').value =='WARRANTY'">
                <label class="form-label">Limit</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input formControlName="supportDuration" matNativeControl matInput type="number"
                        placeholder="Limits in Month" min="0" autocomplete="off">
                </mat-form-field>
            </div>
        </div>






        <div class="row enableToggles mt-4 mb-3" style="padding-left: 50px;">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-check form-switch flex d-flex flex-row"
                style="justify-content: flex-start;padding-left: 0px;">
                <input class="form-check-input" formControlName="secondaryUnitFlag" type="checkbox"
                    id="flexSwitchCheckDefault" (click)="showSecondaryUnitField($event.target.checked)"
                    style="float: inherit;margin-right: 10px" />
                <label class="form-label" style="font-size: 14px;">Enable Secondary Unit</label>

            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-check form-switch flex d-flex flex-row"
                style="justify-content: flex-start;padding-left: 27px;">
                <input class="form-check-input mr-1 lowStckWarning" type="checkbox" formControlName="lowStockWarning"
                    id="flexSwitchCheckDefault" (click)="getEnableStockValue($event.target.checked)"
                    style="float: inherit;margin-right: 10px" />
                <label class="form-label" style="font-size: 14px;">Enable Low Stock Warning </label>
            </div>
        </div>

        <div class="row mb-3">
            <!-- Primary Unit -->
            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Primary Unit<span class="error-asterisk">*</span></label>
                <mat-form-field style=" font-size: 12px" class="example-full-width" appearance="outline">
                    <mat-select placeholder="Primary Unit" formControlName="unit" matNativeControl
                        disableOptionCentering (selectionChange)="getPrimaryUnit($event)" [ngClass]="{
                            'is-invalid': (f.unit.invalid && f.unit.touched) && submitted }" required>
                        <mat-option *ngFor="let unit of Units" [value]="unit">
                            {{ unit.fullName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.unit.touched)">
                    <mat-error *ngIf="f.unit.errors?.required">
                        Primary Unit is required!
                    </mat-error>
                </mat-error>
            </div>

            <!-- Secondary Unit -->
            <div *ngIf="isDisabled" style=" margin-bottom: -13px; font-size: 12px"
                class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Secondary Unit<span class="error-asterisk">*</span></label>
                <mat-form-field style=" font-size: 12px" class="example-full-width" appearance="outline">
                    <mat-select placeholder="Secondary Unit" formControlName="secondaryUnit" matNativeControl
                        disableOptionCentering (selectionChange)="getSecondaryUnit($event)" required [ngClass]="{
                            'is-invalid': (f.secondaryUnit.invalid && f.secondaryUnit.touched) && submitted }">
                        <mat-option *ngFor="let secondaryunit of SecondaryUnit" [value]="secondaryunit">
                            {{ secondaryunit}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.secondaryUnit.touched)">
                    <mat-error *ngIf="f.secondaryUnit.errors?.required">
                        Secondary Unit is required!
                    </mat-error>
                </mat-error>
            </div>

            <!-- Low Stock Units -->
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1"
                *ngIf="this.isStockToggle">
                <label class="form-label">Low Stock Units<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matNativeControl required matInput placeholder="Low Stock Units"
                        formControlName="lowStockUnit" (keypress)="numberOnly($event)" [ngClass]="{
                            'is-invalid': (f.lowStockUnit.invalid && f.lowStockUnit.touched) && submitted }"
                        autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.lowStockUnit.touched)">
                    <mat-error *ngIf="f.lowStockUnit.errors?.required">
                        Low Stock Units is required!
                    </mat-error>
                </mat-error>
            </div>

            <!-- Conversion Rate -->
            <div *ngIf="isDisabled" style=" font-size: 12px"
                class="col-xs-12 conversionWidth col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Conversion Rate<span class="error-asterisk">*</span></label>
                <!-- <mat-form-field class="example-full-width" appearance="outline">
                    <span matPrefix style="font-size: 12px !important;">1 {{primarySelectUnit}} =
                        &nbsp;</span>
                    <input matNativeControl matInput min="0" formControlName="conversionRate"
                        (keypress)="numberOnly($event)" autocomplete="off">
                    <span matSuffix style="font-size: 12px !important;">{{secondarySelectUnit}}</span>
                </mat-form-field> -->

                <div class="input-group mt-2" style = "height: 38px !important;">
                  <span class="input-group-text ms-1" style="font-size: 14px !important;padding-bottom: 3.75px !important;">1 {{primaryUnitShort}} =</span>
                  <input class="form-control" min="0" formControlName="conversionRate"
                  (keypress)="numberOnly($event)" autocomplete="off" style = "font-size: 14px !important;" />
                  <span class="input-group-text me-2" style="font-size: 14px !important;padding-bottom: 3.75px !important;">{{secondarySelectUnit}}</span>
                </div>

                <!-- <mat-form-field class="example-full-width" style="justify-content: center; align-items: center;"
                    appearance="outline">
                    <span matPrefix style="font-size: 12px !important">1 {{primarySelectUnit}} =
                        &nbsp;</span>
                    <input matNativeControl matInput min="0" formControlName="conversionRate"
                        (keypress)="numberOnly($event)" autocomplete="off">
                    <span matSuffix style="font-size: 12px !important">{{secondarySelectUnit}}</span>
                </mat-form-field> -->

            </div>
        </div>

        <div class="row mb-3">
            <!-- Opening Stock -->
            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">Opening Stock</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="number" min="0" matNativeControl required matInput placeholder="Openeing Stock"
                        formControlName="openingStock" (keypress)="numberOnly($event)" autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.openingStock.touched)">
                    <mat-error *ngIf="f.openingStock.errors?.required">
                        Opening Stock is required!
                    </mat-error>
                </mat-error>
            </div>
            <!-- As on Date -->
            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">As on Date<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput ngModel bsDatepicker [(ngModel)]="todaysDate" [maxDate]="today"
                        style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                        containerClass: 'theme-dark-blue',
                        dateInputFormat: 'DD MMM YYYY',
                         showWeekNumbers:false
                        }" formControlName="asOfDate" (click)="openDatepicker()" >
                        <span>
                            <mat-icon class="iconcalender">date_range</mat-icon>
                        </span>
                </mat-form-field>
                <!-- <mat-error class="text-danger" *ngIf="(submitted || f.asOfDate.touched)">
                    <mat-error *ngIf="f.asOfDate.errors?.required">
                        As Of Date is required!
                    </mat-error>
                    [ngClass]="{
                            'is-invalid': (f.asOfDate.invalid && f.asOfDate.touched) && submitted }"
                </mat-error> -->
            </div>
        </div>

        <div class="row mb-3"
            *ngIf="this.catId == 2 || this.catId == 4 || this.catId == 8 || this.catId == 9 || this.catId == 10 || this.catId == 11 || this.catId == 14 || this.catId == 15 || this.catId == 18">
            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Expiry Date</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput ngModel bsDatepicker [(ngModel)]="todaysDate2" [minDate]="todaysDate2"
                        style="text-transform:uppercase!important;" id="datePicker" bsDatepicker [bsConfig]="{
                        containerClass: 'theme-dark-blue',
                        dateInputFormat: 'DD MMM YYYY',
                         showWeekNumbers:false
                        }" formControlName="expiry" (click)="openDatepickerexpiry()">
                        <span>
                            <mat-icon class="iconcalender">date_range</mat-icon>
                        </span>
                </mat-form-field>
                <!-- <mat-error class="text-danger" *ngIf="(submitted || f.expiry.touched)">
                    <mat-error *ngIf="f.expiry.errors?.required">
                        Expiry Date is required!
                    </mat-error>
                    [ngClass]="{
                        'is-invalid': (f.expiry.invalid && f.expiry.touched) && submitted }"
                </mat-error> -->
            </div>
        </div>
        <div class="row mt-3" *ngIf="customFieldsList && customFieldsList.length > 0">
            <div class="col-sm-12 col-md-6 col-lg-12 col-xl-12">
                <p class="d-md-inline-block fs--1 ml-5"
                    style="font-weight:500;font-size:20px!important;margin-left: 5px;">
                    Custom Fields
                </p>
            </div>
        </div>
        <div class="row mt-3" *ngIf="customFieldsList && customFieldsList.length > 0">
            <ng-container *ngFor="let field of customFieldsList">
              <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-sm-1">
                <label class="form-label">{{ field.fieldName }}</label>
                <mat-form-field appearance="outline" class="example-full-width">
                  <ng-container *ngIf="field.type === 'text'; else dropdownField">
                    <input matInput [formControlName]="field.fieldName" type="text" placeholder="{{ field.fieldName }}">
                  </ng-container>
                  <ng-template #dropdownField>
                    <mat-select [formGroup]="ProductForm.get(field.fieldName)" placeholder="{{ field.fieldName }}">
                        <mat-option *ngFor="let option of getDropdownOptions(field)" [value]="option.optionId" (click)="onOptionClick(field, option)">
                          {{ option.optionName }}
                        </mat-option>
                      </mat-select>
                  </ng-template>                  
                </mat-form-field>
              </div>
            </ng-container>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-12 col-xl-12">
                <p class="d-md-inline-block fs--1 ml-5" style="font-weight:500;font-size:20px!important;margin-left: 5px;">
                    Add Product Image
                </p>
            </div>

        </div>

        <div class="row mt-3">
            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 flex d-flex flex-row  mb-sm-1"
                style="justify-content: center;">
                <button *ngIf="imageSrcSeven" class="delete-button" type="button" style="position: relative;top: -16px;left: 205px;"
                    (click)="cancelCoverImage()">
                    <i class="bi bi-x bi-sm" style="color:red"></i>
                </button>
                <label class="uploader2" style="width: 200px !important;height:200px !important;"
                    ondragover="return false;" [class.loaded]="loaded"
                    [style.outlineColor]="dragging ? activeColor : baseColor" (dragenter)="handleCoverDragEnter()"
                    (dragleave)="handleCoverDragLeave()" (drop)="handleCoverDrop($event)">

                    <div
                        style="display: flex; flex-direction: column; justify-content:flex-start; align-items: center;">
                        <i class="bi bi-plus-lg  fs-3" *ngIf="!imageSrcSeven" [style.color]="dragging
                        ? ((imageSrcSeven.length > 0) ? overlayColor : activeColor)
                        : ((imageSrcSeven.length > 0) ? overlayColor : baseColor)">
                        </i>

                        <span *ngIf="!imageSrcSeven" class="mt-2"
                            style="font-size: 12px; font-weight:500;line-height: 21;">Add Product Cover
                            Image</span>
                        <span *ngIf="!imageSrcSeven" class="mt-2 text-muted"
                            style="text-align: center; font-size: 12px;line-height: 21;">Drop
                            an Image or <span style="color: #1BB2F2;">Browsing images</span> it from your
                            computer</span>


                    </div>


                    <img [src]="imageSrcSeven" width="200" height="200" (load)="handleCoverImageLoad()"
                        [class.loaded]="imageLoaded" />

                    <input type="file" name="file" accept="image/*" (change)="coverImgChange($event)">
                </label>

            </div>

            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 flex d-flex flex-row" style="justify-content: center;">

                <!-- <label class="btn btn-default mt-3">
                    <i class="fas fa-paperclip fs--1 textColor"></i>
                    <input type="file" name="file" accept="image/*" style="display:none;"
                    [(ngModel)]="currentInput" [ngModelOptions]="{ standalone: true }"  multiple   (change)="onFileSelected($event)"/>Attach File
                </label> -->
                <label class="uploader2" style="width: 200px !important;height:200px !important;">

                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        <!-- <i class="bi bi-plus-lg fs--1" *ngIf="!imageSrcSeven" [style.color]="dragging
                        ? ((imageSrcSeven.length > 0) ? overlayColor : activeColor)
                        : ((imageSrcSeven.length > 0) ? overlayColor : baseColor)">
                        </i> -->
                        <i class="bi bi-plus-lg  fs-3"></i>

                        <span class="mt-2" style="font-size: 12px; font-weight:500;line-height: 21;">Add Product
                            Image</span>
                        <span class="mt-2 text-muted" style="text-align: center; font-size: 12px;line-height: 21;">Drop
                            an Image or <span style="color: #1BB2F2;">Browsing images</span> it from your
                            computer</span>
                    </div>

                    <input type="file" name="file" accept="image/*" style="display:none;" [(ngModel)]="currentInput"
                        [ngModelOptions]="{ standalone: true }" multiple (change)="onFileSelected($event)" />
                </label>

            </div>
        </div>


        <div class="row mt-3">
            <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6 mb-4 mt-2 flex d-flex flex-row"
                *ngFor="let preview of previews;let i=index;" style="justify-content: center;">
                <!-- <i class="bi bi-x-circle text-danger fs-1 fw-bold" (click)="cancelmultiImage(i)"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="delete image"></i> -->
                <button class="delete-button" type="button" (click)="cancelmultiImage(i)">
                    <i class="bi bi-x bi-sm" style="color:red"></i>
                </button>
                <img [src]="preview" class="preview" height="200" width="200px" />
            </div>
        </div>

    </form>
</div>
