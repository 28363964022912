import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';
import { TimesheetService } from 'src/app/core/services/timesheet.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-add-time',
  templateUrl: './add-time.component.html',
  styleUrls: ['./add-time.component.scss']
})
export class AddTimeComponent implements OnInit {

  // employeeList = [
  //   {id: "1", name: "employee1"}, {id: "2", name: "employee2"}
  // ];
  // clientList = [
  //   {id: "1", name: "client1"}, {id: "2", name: "client2"}
  // ];
  // projectList = [
  //   {id: "1", name: "Iceipts"}, {id: "2", name: "FIFM"},{id : "3", name: "FIGO"},
  // ];

  public currentUser = JSON.parse(localStorage.getItem("currentUser"))

  public AddTimeForm: FormGroup;
  public isSwitchOn: boolean = false;
  public todaysDate;
  public employeeList: [];
  startTime: string = ''; // Format: 'hh:mm:ss'
  endTime: string = ''; // Format: 'hh:mm:ss'
  period: string = '';
  timeDifferenceFormatted: string = '';
  public submitted: boolean = false;
  public today: Date;
  constructor(
    public dialogRef: MatDialogRef<AddTimeComponent>,
    private formBuilder: FormBuilder,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
    private timesheetService: TimesheetService,
    public rolesPermissionsService: RolesPermissionsService,
  ) {
    this.today = new Date(new Date().setDate(new Date().getDate() - 15));
    this.todaysDate = new Date();
  }

  ngOnInit(): void {

    this.AddTimeForm = this.formBuilder.group({
      employeeName: [''],
      startDate: [''],
      start_time: ['', [Validators.required]],
      end_time: ['', [Validators.required]],
      period: ['00:00:00', Validators.required], // Default duration
      isBillable: [false],
      clientName: ['', [Validators.required]],
      projectName: ['', [Validators.required]],
      task: [''],
      description: [''],
      reason: [''],
    })

    // // Subscribe to changes in start and end times
    // this.AddTimeForm.controls.startTime.valueChanges.subscribe(() => {
    //   this.updateDuration();
    // });
    // this.AddTimeForm.controls.endTime.valueChanges.subscribe(() => {
    //   this.updateDuration();
    // });

    this.getUsersList();

  }

  // calculateTimeDifference() {
  //   const startTime = this.AddTimeForm.get('startTime').value;
  //   const endTime = this.AddTimeForm.get('endTime').value;

  //   const startTimeParts = startTime.split(':');
  //   const endTimeParts = endTime.split(':');

  //   const startDate = new Date(0, 0, 0, +startTimeParts[0], +startTimeParts[1]);
  //   const endDate = new Date(0, 0, 0, +endTimeParts[0], +endTimeParts[1]);

  //   const differenceInMillis = endDate.getTime() - startDate.getTime();
  //   const hours = Math.floor(differenceInMillis / (1000 * 60 * 60));
  //   const minutes = Math.floor((differenceInMillis % (1000 * 60 * 60)) / (1000 * 60));

  //   this.period = `${hours}:${minutes}`;
  // }

  // updateDuration() {
  //   const start = this.AddTimeForm.controls.startTime.value;
  //   const end = this.AddTimeForm.controls.endTime.value;
  //   const period = this.calculateDuration(start, end);

  //   this.AddTimeForm.controls.duration.setValue(period, { emitEvent: false });
  // }

  // calculateDuration(start: string, end: string): string {
  //   const startTime = new Date(`1970-01-01T${start}`);
  //   const endTime = new Date(`1970-01-01T${end}`);
  //   const durationInSeconds = (endTime.getTime() - startTime.getTime()) / 1000;

  //   const hours = Math.floor(durationInSeconds / 3600);
  //   const minutes = Math.floor((durationInSeconds % 3600) / 60);
  //   const seconds = durationInSeconds % 60;

  //   return `${this.padNumber(hours)}:${this.padNumber(minutes)}:${this.padNumber(seconds)}`;
  // }

  // padNumber(num: number): string {
  //   return num.toString().padStart(2, '0');
  // }


  calculateTimeDifference() {
    const startTimeValue = this.AddTimeForm.controls['start_time'].value;
    const endTimeValue = this.AddTimeForm.controls['end_time'].value;
    // console.log("startTimeValue",startTimeValue);
    // console.log("endTimeValue",endTimeValue);


    if (startTimeValue && endTimeValue) {
      const [startHour, startMinute] = startTimeValue.split(':');
      const [endHour, endMinute] = endTimeValue.split(':');

      const startDate = new Date();
      startDate.setHours(parseInt(startHour, 10));
      startDate.setMinutes(parseInt(startMinute, 10));

      const endDate = new Date();
      endDate.setHours(parseInt(endHour, 10));
      endDate.setMinutes(parseInt(endMinute, 10));

      if (startDate < endDate) {
        const timeDifferenceInMilliseconds = endDate.getTime() - startDate.getTime();
        const hoursDifference = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60));
        const minutesDifference = Math.floor((timeDifferenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const secondsDifference = Math.floor((timeDifferenceInMilliseconds % (1000 * 60)) / 1000);

        this.timeDifferenceFormatted = `${this.formatTimeUnit(hoursDifference)}:${this.formatTimeUnit(minutesDifference)}:${this.formatTimeUnit(secondsDifference)}`;
        console.log("  this.timeDifferenceFormatted", this.timeDifferenceFormatted);

        this.AddTimeForm.patchValue({
          period: this.timeDifferenceFormatted
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Start Time Must Be Less Than End Time!!!",
        });
        // this.toastService.openErrorSnackBar("Start time must be less than end time!!!");
        //this.timeDifferenceFormatted = '';
        this.AddTimeForm.patchValue({
          //period: this.timeDifferenceFormatted,
          start_time: [null, [Validators.required]],
          end_time: [null, [Validators.required]],
        });
      }
    }
    else {
      this.timeDifferenceFormatted = '';
      this.AddTimeForm.patchValue({
        period: this.timeDifferenceFormatted,
      });
    }




  }



  private formatTimeUnit(unit: number): string {
    return unit < 10 ? `0${unit}` : `${unit}`;
  }

  getUsersList() {
    this.rolesPermissionsService.getUsers({}, this.currentUser.id,1000,1).then((res) => {
      this.employeeList = res.data.pageData.map((item) => { return item.firstName });
      console.log("employee", this.employeeList)
    })
  }

  // Short-cut used in mat errors.
  get f() {
    return this.AddTimeForm.controls;
  }

  // To save the add time form
  postAddTimeForm() {
    this.submitted = true;
    if (this.AddTimeForm.invalid) {
      console.log("this.AddTimeForm", this.AddTimeForm)
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      // this.toastService.openErrorSnackBar("Please Fill Mandatory Fields!!!");
      return false;
    }
    else {
      if (this.AddTimeForm.controls.start_time.value < this.AddTimeForm.controls.end_time.value) {

        let body = {
          "timeDetails": {
            "date": this.AddTimeForm.controls.startDate.value,
            "startTime": this.AddTimeForm.controls.start_time.value,
            "endTime": this.AddTimeForm.controls.end_time.value,
            "period": this.AddTimeForm.controls.period.value,
            "isBillable": this.AddTimeForm.controls.isBillable.value,
            "selectProject": this.AddTimeForm.controls.projectName.value,
            "enterTask": this.AddTimeForm.controls.task.value,
            "description": this.AddTimeForm.controls.description.value,
            "reason": this.AddTimeForm.controls.reason.value,
            "selectClient": this.AddTimeForm.controls.clientName.value,
            "selectEmployee": this.AddTimeForm.controls.employeeName.value,
          }
        }

        this.timesheetService.addTime(body, this.currentUser.id).then((res) => {

          console.log("time", res);
          this.toastService.toastMsg({
            title: "Success",
            content: "Time Added Successfully!!!",
            })
          // this.toastService.openSnackBar("Time Added Successfully!!!");
          this.timesheetService.notifytimeAdded();
          this.AddTimeForm.reset();
          this.todaysDate = "";
          this.submitted = false;
          this.dialogRef.close();
        },
          (err) => {

            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
              // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
              // this.toastService.openErrorSnackBar("Something Went Wrong.");
            }

          })
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Start Time Must Be Less Than End Time!!!",
        });
        // this.toastService.openErrorSnackBar("Start time must be less than end time!!!");
      }

    }

  }

  // For isBillable input.
  toggleSwitch(): void {
    this.isSwitchOn = !this.isSwitchOn;
  }

  // To close the dialog.
  clearAddTimeForm() {
    this.dialogRef.close();
  }

  openDatepicker() {
   // Check if the current value of taskdate is an "Invalid Date"
   const selectedDate = this.AddTimeForm.get('startDate').value;
   if (!(selectedDate instanceof Date) || isNaN(selectedDate.getTime())) {
       // Set a default date or any valid date here
       this.AddTimeForm.get('startDate').setValue(new Date());
   }
  }
}
