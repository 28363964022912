import { TitleCasePipe } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { CustomerProfileService } from 'src/app/core/services/customer-profile.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { RemoteShopService } from 'src/app/core/services/remote-shop.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { Constants } from 'src/app/_helpers/constant';

@Component({
  selector: 'app-layout-customer',
  templateUrl: './layout-customer.component.html',
  styleUrls: ['./layout-customer.component.scss']
})
export class LayoutCustomerComponent implements OnInit {

  public isFluid = JSON.parse(localStorage.getItem("isFluid"))
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public showTimeInterval: any;
  public interval: any;
  public difference: any;
  public messages: number = 0;
  public quantityCount: number;
  public subscription: Subscription;
  public productArray = [];
  public notificationReadArray: any = [];
  public notificationUnreadArray = [];
  public isShow: boolean = false;
  public isData: boolean = false;
  public imageSrc: any = '';
  public currentPageNo: number = 0;
  public pageSize: number = 100;

  constructor(private elementRef: ElementRef,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private remoteShopService: RemoteShopService,
    private notificationService: NotificationService,
    private commonService: CommonService, public profileService: CustomerProfileService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe) {
    this.subscription = this.commonService.getData().subscribe(message => {
      if (message) {
        this.messages = message.count ? message.count : 0;
      } else {
        // clear messages when empty message received
        this.messages = 0;
      }
    });
  }

  ngOnInit(): void {
    this.getProfileData();
    this.getSubscribedUser();
    this.getAllNotificationByUserId();
    this.getCartCount();
    if (this.currentUser == null) {
      this.router.navigate(["/auth/login"]);
    } else {
      this.interval = setInterval(() => {
        // this.getExpirationTime();
      }, 1000);
    }
  }
  getSubscribedUser() {
    if (this.currentUser != null && this.currentUser.userType == 'CLIENT') {
      this.subscriptionService.getSubscribedUsersDetails({}, this.currentUser.id).then((res) => {
      })
    }
  }

  logout(): void {
    // this.authService.logout();
    this.router.navigateByUrl("/auth/login")
  }
  /**
   * get user cart count
  */
  getCartCount() {
    this.remoteShopService.getCartCount({}, this.currentUser.id).then((res) => {
      this.messages = res.data.count
      this.commonService.sendData(res.data.count)
    }, (err) => {
      if (err.error.status === 500) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }

  /**
   * get notification by userId
   */
  getAllNotificationByUserId() {
    this.notificationService.getAllNotification({}, this.currentUser.id, this.pageSize, (this.currentPageNo + 1)).then((res) => {
      console.log(res);
      this.isData = false;
      let notification = res.data.pageData
      notification.forEach(element => {
        if (element.read == true) {
          this.notificationReadArray.push(element);
          this.isShow = false;
          this.notificationUnreadArray = [];
        }

        else if (element.read === null) {
          this.notificationUnreadArray.push(element);
          this.isShow = true;
          this.notificationReadArray = [];
          console.log(this.notificationReadArray);
        }
      });

    }, (err) => {
      if (err.error.status == 404) {
        this.isData = true;
      } else if (err.error.status === 500) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    })
  }

  openmenu() {
    var input = this.elementRef.nativeElement.getElementsByClassName('collapse');
    //var btnDropdownClose = searchArea.querySelector(Selectors.SEARCH_DISMISS);
    //var dropdownMenu = searchArea.querySelector(Selectors.DROPDOWN_MENU);

    if (input) {
      // input.addEventListener(Events.FOCUS, function () {
      //   hideAllSearchAreas();
      //   var el = searchArea.querySelector(Selectors.SEARCH_TOGGLE);
      //   el.setAttribute(Attribute.ARIA_EXPANDED, 'true');
      //   el.classList.add(ClassName.SHOW);
      input.classList.add('show');
      // });
    }
  }
  
  getProfileData() {
    this.profileService.getCustomerProfileByUserId({}, this.currentUser.id).then((res => {
      this.imageSrc = res.data.basicDetails.profileImage;
      if (!this.imageSrc) {
        this.imageSrc = "assets/img/team/3-thumb.png";
      }
    }),(err)=>{
      if(err.error.status === 500){
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
          });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
    }
    )
  }
}
