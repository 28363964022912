import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  public static appointmentType="appointment/"
  constructor(public httpClient: HttpService) { }
  getAppointmentUsers(data: {} ): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getAppointmentUsers , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAppointmentUsersSearch(data: {},search ): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getAppointmentUsers +"?search="+search , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getAppointmentType(data: {}, shopId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.appointment(data, UrlConstants.appointmentType + shopId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getDaySlot(data: {},shopId,appointId,day): any {
    return new Promise((resolve, reject) => {
      this.httpClient.appointment(data, UrlConstants.getDaySlot + "?shopId="+shopId+"&search="+day+"&appointmentId="+appointId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  postCustomerAppointBookDetails(data:{}){
    return new Promise((resolve,reject)=>{
      this.httpClient.appointment(data,UrlConstants.customerBookAppointPOST,'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    })
   }
 
   getCustomerListByUser(data: {},userId): any {
    return new Promise((resolve, reject) => {
      this.httpClient.appointment(data, UrlConstants.CustomerListByuser + userId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  UpdateAppointmentDetails(data:{},appointmentId){
    return new Promise((resolve,reject)=>{
      this.httpClient.appointment(data,UrlConstants.updateAppointment +appointmentId,'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    })
   }

  getAppointmentById(data:{},appointmentId):any{
    return new Promise((resolve,reject)=>{
      this.httpClient.appointment(data,UrlConstants.singleAppointmentId + appointmentId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    })
   }
  //  getPartyDetails(data:{},id):any{
  //   return new Promise((resolve, reject) => {
  //     this.httpClient.inventory(data, UrlConstants.getParty + id, 'GET').subscribe(
  //       (res) => resolve(res),
  //       (err) => reject(err)
  //     );
  //   });
  // }
}
