
 <div class="card mt-3">
    <div class="theme-wizard">
        <div class="card-header bg-light pt-3 pb-3">
            <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;" >
                Shop Setting
             </p>
        </div>
        <div class="progress" style="height: 2px">
            <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
            </div>
        </div>
    
        <div>
            <div>
                <ul class="nav nav-pills mb-3 mt-2 container-fluid p-3" role="tablist" id="pill-tab2">
                    <li class="nav-item" role="presentation">
                      <button routerLinkActive="active"
                      routerLink="/pages/shop-setting/{{shopID}}/edit-shop-details/{{shopID}}"
                    
                     
                       class="nav-link active" data-wizard-step="data-wizard-step" data-bs-toggle="pill" data-bs-target="#form-wizard-progress-tab1" type="button" role="tab" aria-controls="form-wizard-progress-tab1" aria-selected="true">
                        Edit Shop Details
                      </button>
                   </li>
                   <li class="nav-item" role="presentation">
                     <button class="nav-link" routerLinkActive="active"  
                     routerLink="/pages/shop-setting/{{shopID}}/shop-appointment/{{shopID}}" 
                     data-wizard-step="data-wizard-step" data-bs-toggle="pill" data-bs-target="#form-wizard-progress-tab2" type="button" role="tab" aria-controls="form-wizard-progress-tab2" aria-selected="false">
                         Appointment Type
                     </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" routerLinkActive="active"  
                        routerLink="/pages/shop-setting//{{shopID}}/shop-schedule/{{shopID}}" data-wizard-step="data-wizard-step" data-bs-toggle="pill" data-bs-target="#form-wizard-progress-tab3" type="button" role="tab" aria-controls="form-wizard-progress-tab3" aria-selected="false" >
                          Shop Timings
                        </button>
                    </li>
                     <li class="nav-item" role="presentation">
                         <button class="nav-link" routerLinkActive="active" 
                          routerLink="/pages/shop-setting//{{shopID}}/shop-employee/{{shopID}}" data-wizard-step="data-wizard-step" data-bs-toggle="pill" data-bs-target="#form-wizard-progress-tab4" type="button" role="tab" aria-controls="form-wizard-progress-tab4" aria-selected="false">
                            Shop  Employee 
                           </button>
                    </li>
                </ul>
               
            </div>
            <div class="container-fluid">
              <router-outlet></router-outlet> 
            </div>
        </div>
      </div>
</div>
