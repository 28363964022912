<!-- <div class="row navOptions" style="margin-left: 50%;">
    <div class="col-lg-3"></div>
    <div class="col-lg-5 col-md-12 col-sm-12 containerSearchBar mt-2">
        <input type="search" id="searchInput" class="form-control pe-5" placeholder="Search by Receipt no"
        [(ngModel)]="searchKey" (ngModelChange)="getAllPaymentOutSearch(searchKey)">
    </div>

    <div class="navOption-btns mt-2 col-lg-4 col-md-12 col-sm-12">
        <button class="btn" ngbTooltip="Add New Payment" (click)="openDialog()"><i class="fas fa-plus"></i> payment Out</button>
        <button class="btn" ngbTooltip="Download" accept=".xls,.xlsx"  id="download"
        (click)="downlaodAllData()"><i class="fas fa-download"></i></button>
    </div>

</div> -->


<!-- <div class="row d-flex flex-center">
  <div class="col-md-4 col-lg-6 col-xl-7"></div>
  <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3" style = "padding-left: 5rem;">
    <input type="search" id="searchInput" class="form-control pe-5 mb-2" placeholder="Receipt Number"
    [(ngModel)]="searchKey" (ngModelChange)="getAllPaymentOutSearch(searchKey)">
  </div>
  <div class="navOption-btns col-sm-6 col-md-4 col-lg-3 col-xl-2 mx-0 px-0 mb-2">
    <button class="btn" ngbTooltip="Add New Payment" (click)="openDialog()"><i class="fas fa-plus"></i> Payment Out</button>
    <button class="btn" ngbTooltip="Download" accept=".xls,.xlsx"  id="download"
    (click)="downlaodAllData()"><i class="fas fa-download"></i></button>
  </div>
</div> -->

<div class="row headerButtons mb-3">
    <div class="flex-item searchBar">
        <input type="search" id="searchInput" class="form-control pe-5 my-2" placeholder="Receipt #"
            [(ngModel)]="searchKey" (ngModelChange)="getAllPaymentOutSearch(searchKey)">
    </div>
    <div class="navOption-btns flex-item formButtons mx-0">
        <button class="btn text-nowrap my-2" ngbTooltip="Add New Payment" (click)="openDialog()"><i
                class="fas fa-plus"></i> Payment Out</button>
        <button class="btn my-2" ngbTooltip="Download" accept=".xls,.xlsx" id="download" (click)="downlaodAllData()"><i
                class="fas fa-download"></i></button>
    </div>
</div>

<!-- <hr class=" mb-2"> -->

<div class="card-body p-0 m-2 overflow-auto">
    <div class="table-responsive datalist">
        <table class="table table-striped table-bordered bg-white" *ngIf="!isArabic">
            <thead>
                <tr class="btn-reveal-trigger">
                    <!-- <th scope="col">
                            <input type="checkbox" />
                        </th> -->
                    <th class="text-nowrap" style="vertical-align:middle;width:200px;">Receipt #</th>
                    <th class="text-nowrap" style="vertical-align:middle;width:200px;">Date</th>
                    <th class="text-nowrap" style="vertical-align:middle;text-align: right;width:150px;">Received</th>
                    <th class="text-nowrap" style="vertical-align:middle;text-align: right;width:200px;">Total</th>
                    <th class="text-nowrap" style="vertical-align:middle;width:auto;"></th>
                </tr>
            </thead>

            <tbody>

                <tr *ngFor="let item of invoiceData; let i = index">
                    <!-- <td scope="row">
                            <input type="checkbox" />
                        </td> -->
                    <td class="text-nowrap" style="vertical-align:middle;">{{ item.invoiceNo ? item.invoiceNo : '-'}}
                    </td>
                    <td class="text-nowrap" style="vertical-align:middle;">{{ item.invoiceDate | date:"dd MMM YYYY"|
                        uppercase }}</td>
                    <td class="text-nowrap" style="vertical-align:middle;text-align: right;">₹&nbsp;{{
                        item.totalBillAmount | number:'1.2-2'}}</td>
                    <td class="text-nowrap" style="vertical-align:middle;text-align: right;">₹&nbsp;{{
                        item.vendor?.totalCredit | number:'1.2-2'}}</td>
                    <td class="text-nowrap" style="vertical-align:middle;white-space:nowrap;text-align: center;">
                        <button class="btn btnp">
                            <span class="bi bi-eye iconFontSize" data-bs-toggle="tooltip" data-bs-toggle="modal"
                                data-bs-target="#payment-out" (click)="getReceiptById(item.id)" data-bs-placement="top"
                                title="View"></span>
                        </button>
                    </td>
                </tr>
            </tbody>

            <tbody *ngIf="invoiceData == 0">
                <tr class="text-center">
                    <td colspan="12">
                        <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
                            alt="notfound1" width="200" height="150" />
                        <!-- <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Payment Out Details Added. Please Add Payment Out Details To Be Displayed Here.
                        <span>
                          <a class="nav-link" (click)="openDialog()">Add New Payment Out
                              Details</a>
                        </span>
                      </h5> -->
                    </td>
                </tr>
                <!-- <tr class="text-center">
                        <td colspan="5">
                            <h5 class="text-nowrap">No Payment Out Details Added. Please Add Payment Out Details To
                                Be Displayed Here. <span>
                                    <a class="nav-link" (click)="openDialog()">Add New Payment Out
                                        Details</a>
                                </span></h5>
                        </td>
                    </tr> -->
            </tbody>
        </table>

        <!-- UAE -->

        <table class="table table-striped table-bordered bg-white" *ngIf="isArabic">
            <thead>
                <tr class="btn-reveal-trigger">
                    <!-- <th scope="col">
                          <input type="checkbox" />
                      </th> -->
                    <th class="text-nowrap" style="vertical-align:middle;width:200px;">Receipt #</th>
                    <th class="text-nowrap" style="vertical-align:middle;width:200px;">Date</th>
                    <th class="text-nowrap" style="vertical-align:middle;text-align: right;width:150px;">Received</th>
                    <th class="text-nowrap" style="vertical-align:middle;text-align: right;width:200px;">Total</th>
                    <th class="text-nowrap" style="vertical-align:middle;width:auto;"></th>
                </tr>
            </thead>

            <tbody>

                <tr *ngFor="let item of invoiceData; let i = index">
                    <!-- <td scope="row">
                          <input type="checkbox" />
                      </td> -->
                    <td class="text-nowrap" style="vertical-align:middle;">{{ item.invoiceNo ? item.invoiceNo : '-'}}
                    </td>
                    <td class="text-nowrap" style="vertical-align:middle;">{{ item.invoiceDate | date:"dd MMM YYYY"|
                        uppercase }}</td>
                    <td class="text-nowrap" style="vertical-align:middle;text-align: right;">AED&nbsp;{{
                        item.totalBillAmount | number:'1.2-2'}}</td>
                    <td class="text-nowrap" style="vertical-align:middle;text-align: right;">AED&nbsp;{{
                        item.party.totalCredit | number:'1.2-2'}}</td>
                    <td class="text-nowrap" style="vertical-align:middle;white-space:nowrap;text-align: center;">
                        <button class="btn btnp">
                            <span class="bi bi-eye iconFontSize" data-bs-toggle="tooltip" data-bs-toggle="modal"
                                data-bs-target="#payment-out" (click)="getReceiptById(item.id)" data-bs-placement="top"
                                title="View"></span>
                        </button>
                    </td>
                </tr>
            </tbody>

            <tbody *ngIf="invoiceData == 0">
                <tr class="text-center">
                    <td colspan="12">
                        <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
                            alt="notfound1" width="200" height="150" />
                        <!-- <h5 class="text-nowrap pt-2" style="font-weight: 600;color: #000000;">No Payment Out Details Added. Please Add Payment Out Details To Be Displayed Here.
                      <span>
                        <a class="nav-link" (click)="openDialog()">Add New Payment Out
                            Details</a>
                      </span>
                    </h5> -->
                    </td>
                </tr>
                <!-- <tr class="text-center">
                      <td colspan="5">
                          <h5 class="text-nowrap">No Payment Out Details Added. Please Add Payment Out Details To
                              Be Displayed Here. <span>
                                  <a class="nav-link" (click)="openDialog()">Add New Payment Out
                                      Details</a>
                              </span></h5>
                      </td>
                  </tr> -->
            </tbody>
        </table>
        <mat-paginator class="float-right mt-2" *ngIf="invoiceData.length > 0" showFirstLastButtons
            [pageSize]="partyPageSize" [pageSizeOptions]="[5, 10, 20,30]" [length]="totalrow"
            [pageIndex]="currentPageNo" (page)="handlePage($event)" aria-label="Select page">
        </mat-paginator>

    </div>
</div>
<!--
<div class="modal fade" #closebutton id="payment-out" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6">

                    <div class="row">
                        <div class="col-4"></div>
                        <div class="col-4">
                            <h2 class=""><b>View Payment Out</b></h2>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="card" >
                        <div class="card-body">
                            <div class="row bordered" id="panel">
                                <div class="col-12">
                                </div>
                            </div>
                            <div class="row bordered table-responsive scrollbar fs--1">
                                <table class="table table-striped tablePrint" style="font-size: 11px!important;">
                                    <thead class="inner-model-table-heading">
                                        <tr class="dark__bg-1000" >
                                            <th class="borderbottom2">Bill To</th>
                                            <th class="borderLeft borderbottom2">Ship To</th>
                                            <th class="borderLeft borderbottom2">Description</th>
                                            <th class="borderLeft borderbottom2"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-body">
                                        <tr>
                                            <td class="align-middle">
                                                <p>{{customerName}}</p>
                                                <p>{{billingAddress}}</p>
                                            </td>
                                            <td class="align-middle text-center borderLeft">
                                                {{shippingAddress}}
                                            </td>

                                            <td class="align-middle text-center borderLeft">
                                                {{description}}
                                            </td>

                                            <td class="align-middle  borderLeft " style="text-align: left !important;">
                                               <p><b>Place of Supply:</b> {{shippingAddress}}</p>
                                               <p><b>Invoice No.:</b> {{invoiceNo}}</p>
                                               <p><b>Total Bill:</b> {{totalBillAmount}}</p>
                                               <p><b>Date:</b> {{invoiceDate| date:"dd MMM YYYY"| uppercase}}</p>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="row borderedBottom" style="font-size: 11px!important;">
                                <div class="col-6 ">
                                    <p style="background: #e4e3e6;" class="inner-model-table-heading p-0 text-black"><b>Terms and conditions:</b>
                                    </p>
                                    <p class="pb-2"  style="padding-left: 5px !important;">Thanks for doing business with us.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="modal fade" #closebutton id="payment-out" tabindex="-1" role="dialog" aria-hidden="true" *ngIf="!isArabic">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6">

                    <!-- <div class="row">
                      <div class="col-4"></div>
                      <div class="col-4">
                          <h2 class=""><b>View Payment Out</b></h2>
                      </div>
                  </div> -->
                </div>
                <div class="container">
                    <div class="card">
                        <div class="card-body">
                            <div class="row my-2" id="panel">
                                <div class="col-6 pt-2">
                                    <!-- <p style = "color: red;font-weight: 600;">{{tradeName ? tradeName : "NA"}}</p> -->
                                    <!-- <p>Mobile:&nbsp;{{ mobile ? mobile : 'NA'}}</p> -->
                                </div>
                                <div class="col-6">
                                    <div class="row headerAlignment">
                                        <div class="col-6" style="font-weight: 600;">
                                            TAX INVOICE
                                        </div>
                                        <div class="col-6">
                                            <!-- <button style = "float: right;" id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0"
                                    type="button" (click)="onPrint()"><span class="fas fa-print me-1">
                                    </span>Print
                                  </button> -->
                                            <div id="originalForReceiptPrint" style="font-size:14px !important">
                                                ORIGINAL FOR RECIPIENT
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="row headerAlignment">
                                <div class="col-6">
                                  Invoice No.<span style = "float: right;">:</span>
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                  {{invoiceNo ? invoiceNo : "NA"}}
                                </div>
                              </div> -->
                                    <!-- <div class="row headerAlignment">
                                <div class="col-6">
                                  Invoice Date<span style = "float: right;">:</span>
                                </div>
                                <div class="col-6" style = "text-align: end;">
                                  {{invoiceDate | date:"dd/MM/YYYY" }}
                                </div>
                              </div> -->
                                </div>
                            </div>
                            <!--
                          <div class="row bordered table-responsive scrollbar fs--1">
                              <table class="table table-striped tablePrint" style="font-size: 11px!important;">
                                  <thead class="inner-model-table-heading">
                                      <tr class="dark__bg-1000" >
                                          <th class="borderbottom2">Bill To</th>
                                          <th class="borderLeft borderbottom2">Ship To</th>
                                          <th class="borderLeft borderbottom2">Description</th>
                                          <th class="borderLeft borderbottom2"></th>
                                      </tr>
                                  </thead>
                                  <tbody class="table-body">
                                      <tr>
                                          <td class="align-middle">
                                              <p>{{customerName}}</p>
                                              <p>{{billingAddress}}</p>
                                          </td>
                                          <td class="align-middle text-center borderLeft">
                                              {{shippingAddress}}
                                          </td>

                                          <td class="align-middle text-center borderLeft">
                                              {{description}}
                                          </td>

                                          <td class="align-middle  borderLeft " style="text-align: left !important;">
                                             <p><b>Place of Supply:</b> {{shippingAddress}}</p>
                                             <p><b>Invoice No.:</b> {{invoiceNo}}</p>
                                             <p><b>Total Bill:</b> {{totalBillAmount}}</p>
                                             <p><b>Date:</b> {{invoiceDate| date:"dd MMM YYYY"| uppercase}}</p>

                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div> -->

                            <!-- <div class = "row mt-2">
                            <div class="col-6">
                              <div style="display: flex;flex-direction: column;justify-content: flex-start;">
                                <span class = "inner-model-table-heading p-2 my-2" style = "width: 100px;font-weight: 600;">BILL TO</span>
                                <span style = "font-weight: 600;">{{customerName ? customerName : "NA"}}</span>
                                <span>{{ billingAddress ? billingAddress : "NA"}}</span>
                                <span>Description: &nbsp;{{ salesData.description }}</span>
                              </div>
                            </div>
                            <div class="col-6">
                              <div style="display: flex;flex-direction: column;justify-content: flex-start;">
                                <span class = "inner-model-table-heading p-2 my-2" style = "width: 100px;font-weight: 600;">SHIP TO</span>
                                <span style = "font-weight: 600;">{{customerName ? customerName : "NA"}}</span>
                                <span>{{ shippingAddress ? shippingAddress : billingAddress}}</span>
                                <span>Total Bill: &nbsp;{{totalBillAmount ? totalBillAmount : "NA"}}</span>
                              </div>
                            </div>
                          </div> -->

                            <div class="row bordered table-responsive scrollbar fs--1">
                                <table class="table table-striped tablePrint" style="font-size: 14px!important;">
                                    <thead class="inner-model-table-heading">
                                        <tr class="dark__bg-1000">
                                            <th class="borderbottom2"
                                                style="font-size: 14px!important;font-weight: 600;">Payment Out</th>
                                            <th class="borderLeft borderbottom2"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-body">
                                        <tr>
                                            <td class="align-middle">
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Vendor
                                                        Name:</span>&nbsp;{{customerName ? customerName : "NA"}}</p>
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Invoice #.:</span>&nbsp;{{invoiceNo}}
                                                </p>
                                                <P style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Invoice
                                                        Date:</span>&nbsp;{{invoiceDate | date:"dd MMM YYYY" | uppercase
                                                    }}</P>
                                            </td>
                                            <td class="align-middle">
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Receipt
                                                        Type:</span>&nbsp;{{receiptType ? receiptType : "NA"}}</p>
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Supply Type:</span>&nbsp;{{supplyType
                                                    ? supplyType : "NA"}}</p>
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Total Bill:</span>
                                                    ₹&nbsp;{{totalBillAmount | number:'1.2-2'}}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <hr style="border: 1px solid grey;" />
                            <div class="row">
                                <div class="col-6"
                                    style="display: flex;flex-direction: column;justify-content: flex-start;">
                                    <span class="mb-1 mt-5" style="font-weight: 600;">TERMS AND CONDITIONS</span>
                                    <span #dynamicText [style.fontSize.px]="getTextSizeprint(TextSizeprint)"
                                        style="margin-bottom: 4px;">{{invoiceTermsCon ? invoiceTermsCon :'NA'}}</span>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12"
                                    style="display: flex;flex-direction: column;justify-content: flex-end;">
                                    <div class="image-wrapper">
                                        <label class="signature-img" ondragover="return false;">
                                            <img *ngIf="signImg" [src]="signImg" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- UAE -->

<div class="modal fade" #closebutton id="payment-out" tabindex="-1" role="dialog" aria-hidden="true" *ngIf="isArabic">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6">

                </div>
                <div class="container">
                    <div class="card">
                        <div class="card-body">
                            <div class="row my-2" id="panel">
                                <div class="col-6 pt-2">

                                </div>
                                <div class="col-6">
                                    <div class="row headerAlignment">
                                        <div class="col-6" style="font-weight: 600;">
                                            TAX INVOICE
                                        </div>
                                        <div class="col-6">

                                            <div id="originalForReceiptPrint" style="font-size:14px !important">
                                                ORIGINAL FOR RECIPIENT
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="row bordered table-responsive scrollbar fs--1">
                                <table class="table table-striped tablePrint" style="font-size: 14px!important;">
                                    <thead class="inner-model-table-heading">
                                        <tr class="dark__bg-1000">
                                            <th class="borderbottom2"
                                                style="font-size: 14px!important;font-weight: 600;">Payment Out</th>
                                            <th class="borderLeft borderbottom2"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-body">
                                        <tr>
                                            <td class="align-middle">
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Vendor
                                                        Name:</span>&nbsp;{{customerName ? customerName : "NA"}}</p>
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Invoice No.:</span>&nbsp;{{invoiceNo}}
                                                </p>
                                                <P style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Invoice
                                                        Date:</span>&nbsp;{{invoiceDate | date:"dd MMM YYYY" | uppercase
                                                    }}</P>
                                            </td>
                                            <td class="align-middle">
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Receipt
                                                        Type:</span>&nbsp;{{receiptType ? receiptType : "NA"}}</p>
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Supply Type:</span>&nbsp;{{supplyType
                                                    ? supplyType : "NA"}}</p>
                                                <p style="font-size: 14px!important;"><span
                                                        style="font-weight: 600;">Total
                                                        Bill:</span>&nbsp;AED&nbsp;{{totalBillAmount | number:'1.2-2'}}
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <hr style="border: 1px solid grey;" />
                            <div class="row">
                                <div class="col-6"
                                    style="display: flex;flex-direction: column;justify-content: flex-start;">
                                    <span class="mb-1 mt-5" style="font-weight: 600;">TERMS AND CONDITIONS</span>
                                    <span #dynamicText [style.fontSize.px]="getTextSizeprint(TextSizeprint)"
                                        style="margin-bottom: 4px;">{{invoiceTermsCon ? invoiceTermsCon :'NA'}}</span>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12"
                                    style="display: flex;flex-direction: column;justify-content: flex-end;">
                                    <div class="image-wrapper">
                                        <label class="signature-img" ondragover="return false;">
                                            <img *ngIf="signImg" [src]="signImg" />
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="row borderedBottom" style="font-size: 11px!important;">
                              <div class="col-6 ">
                                  <p style="background: #e4e3e6;" class="inner-model-table-heading p-0 text-black"><b>Terms and conditions:</b>
                                  </p>
                                  <p class="pb-2"  style="padding-left: 5px !important;">Thanks for doing business with us.
                                  </p>
                              </div>

                          </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>












<!-- <div class="card mb-3">
    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
            Payment Out List
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>
    <div class="card-body p-0 m-2 overflow-hidden">

        <div class="row">
            <div class="col-lg-3 col-sm-12 col-md-3 col-xl-3" style="margin-left: 2px; font-size: 12px">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Search</mat-label>
                    <input type="text" matInput placeholder="Search" [(ngModel)]="searchKey"
                        (change)="getAllPaymentOutSearch(searchKey)">
                    <span>
                        <mat-icon class="iconcalender p-1">search</mat-icon>
                    </span>
                </mat-form-field>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-striped table-bordered bg-white">
                <thead>
                    <tr class="btn-reveal-trigger">
                        <th>#</th>
                        <th class="text-nowrap">Receipt Number</th>
                        <th class="text-nowrap">Date</th>
                        <th class="text-nowrap">Received</th>
                        <th class="text-nowrap">Total</th>
                    </tr>
                </thead>

                <tbody>

                    <tr *ngFor="let item of invoiceData; let i = index">
                        <td>{{ i+1 }}</td>
                        <td>{{ item.invoiceNo ? item.invoiceNo : '-'}}</td>
                        <td>{{ item.invoiceDate | date:"dd MMM YYYY"| uppercase }}</td>
                        <td>{{ item.received}}</td>
                        <td>{{ item.balance}}</td>

                    </tr>
                </tbody>

                <tbody *ngIf="invoiceData == 0">
                    <tr class="text-center">
                        <td colspan="5">
                            <h5 class="text-nowrap">No Payment Out Details Added. Please Add Payment Out Details To
                                Be Displayed Here. <span>
                                    <a class="nav-link" routerLink="/pages/payment-out">Add New Payment Out
                                        Details</a>
                                </span></h5>
                        </td>
                    </tr>
                </tbody>
            </table>
            <mat-paginator class="float-right mt-2" *ngIf="invoiceData.length > 0"
                [pageSize]="partyPageSize" [pageSizeOptions]="[5, 10, 20,30]"
                [length]="totalrow" [pageIndex]="currentPageNo" (page)="handlePage($event)"

             aria-label="Select page">
            </mat-paginator>

        </div>
    </div>

</div> -->