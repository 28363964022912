import { Injectable } from '@angular/core';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class BiddingService {

  constructor(public httpClient: HttpService) { }

  postwishlistBid(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postwishlistBid, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      )
    })
  }
  getWishlistsByUserId(data: {}, userId,size,page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getWishlistsByUserId + userId+ "?size="+ size + "&page=" + page,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      )
    })
  }

  removeWishlist(data: {}, wishId:string): any{
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.removeWishlist + wishId, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      )
    })
  }

  /**
   * Method to get client bidding list
   * @param data 
   * @param userId 
   * @returns 
   */
    getAllBiddings(data: {}, userId:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllBiddings + userId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      )
    })
  }

  getBiddings(data: {}, userId:string,size,page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllBiddings + userId + "?size="+ size + "&page=" + page, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      )
    })
  }

  /**
   * Method to get wishlist details by Id
   * @param data 
   * @param wishId 
   * @returns 
   */
  getWishlistById(data: {}, wishId:string): any{
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getWishlistById + wishId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      )
    })
  }
  
  postClientQuotePrice(data: {}): any{
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.clientQuotePrice, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      )
    })
  }

  getAllProductsByUserSearch(data, userId, name, size, page, warehouseId?): any{
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getAllProductsByUserSearch + userId + "?productType=goods&search=" + name + "&size="+ size + "&page=" + page + "&warehouseId=" + warehouseId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      )
    })
  }


  

  getProductByBarcode(data: {},userId:string,barcode:string): any{
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getProductByBarcode + barcode +"&userId" + userId , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      )
    })
  }

  postCustomerAcceptBid(data:{}):any{
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.PostCustomerAcceptBid, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      )
    })

  }


RejectCustomerBid(data:{}):any{
  return new Promise((resolve, reject) => {
    this.httpClient.inventory(data, UrlConstants.rejectBidCustomer, 'POST').subscribe(
      (res) => resolve(res),
      (err) => reject(err)
    )
  })

}

}
