import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { UrlConstants } from 'src/app/_helpers/url-constants';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(public httpClient:HttpService) { }

  postFcm(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.postFcm ,'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  getAllNotification(data: {}, userId: string,size,page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getAllNotification + userId + "?size="+ size + "&page=" + page,'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}
