import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Subject } from 'rxjs';
import { UrlConstants } from 'src/app/_helpers/url-constants';

@Injectable({
  providedIn: 'root'
})
export class CrmService {

  private leadAddedSource = new Subject<void>();
  leadAdded$ = this.leadAddedSource.asObservable();

  notifyLeadAdded() {
    this.leadAddedSource.next();
  }

  constructor(public httpClient: HttpService) {

  }

  //add Lead
  postLead(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.addLead + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  //Lead list
  getAllLeadList(data: {}, id, size, page): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.leadList + id + "?size=" + size + "&page=" + page, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  //Lead list search
  getsearchAllLeadList(data: {}, id, size, page, search): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.searchleadList + id + "?size=" + size + "&page=" + page + "&search=" + search, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  //meeting list
  getMeetingList(data: {}, id, search): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.meetingList + id + "?search=" + search, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  //activity list
  getActivityList(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.activityList + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  // //activity list search
  // getActivityListsearch(data: {}, id, search): any {
  //   return new Promise((resolve, reject) => {
  //     this.httpClient.inventory(data, UrlConstants.searchactivityList + id + "?search=" + search, 'GET').subscribe(
  //       (res) => resolve(res),
  //       (err) => reject(err)
  //     );
  //   });
  // }

  //activity list search
  getActivityListsearch(data: {},id,search): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.searchactivityList + id + "?search=" +search, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  //nots list
  getNoteList(data: {}, id, search): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.noteList + id + "?search=" + search, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getNotesListsearch(data: {},id,search,size,page,search2): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.noteactivityList + id +"?search="+search+"&size="+size +"&page="+page +"&search2="+search2, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getTaskListsearch(data: {},id,search,size,page,search2): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.taskactivityList + id +"?search="+search+"&size="+size +"&page="+page +"&search2="+search2, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  //Task List
  getTaskList(data: {}, id, search): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.taskList + id + "?search=" + search, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  //mail List
  getMailList(data: {}, id, search): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.mailList + id + "?search=" + search, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getleadById(data: {}, id: string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.editleadList + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  updateLead(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.updateleadList + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteleadProfileImg(data: {}, userId: string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.imageleadedit + userId, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteleadById(data: {}, Id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.deleteleadList + Id, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deletemultileadById(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.deletemultileadList + id, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err),
      );
    });
  }
  postNote(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.AddNote + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });

  }
  updateNote(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.updateNote + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });

  }
  postTask(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.AddTask + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });

  }
  updateTask(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.updateTask + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });

  }

  updateEmail(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.updateemail + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });

  }
  postEmail(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.AddEmail + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });

  }
  PostMeetingLogAdd(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.logMeeeingAdd + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });

  }



  getCrmDATABYLEADID(data: {}, id, leadId) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.crmDataContactByLeadId + id + "?contactId=" + leadId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  sendEmail(data: {}) {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.sendUserMail, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });


  }

  postBulklead(data: {}, id) {
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.postBulkLead + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}
