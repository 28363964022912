import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { RolesPermissionsService } from 'src/app/core/services/roles-permissions.service';
import { Chart, registerables } from "chart.js";
import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import noData from 'highcharts/modules/no-data-to-display';

import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD MMM YYYY',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    // monthYearLabel: 'MMM YYYY',
    // dateA11yLabel: 'YYYY-MM-DD HH:mm:ss',
    // monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-total-sales',
  templateUrl: './total-sales.component.html',
  styleUrls: ['./total-sales.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },

  ]
})
export class TotalSalesComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public userId: string = "";
  public showProfile: boolean;
  public todaysDate = this.commonService.getTodaysDate();
  public totalSales: any;
  public permissionData: any;
  public canvas: any;
  public puchaseChart: any = [];
  public salesChart: any = [];
  public salesMonthlyChart: any = []
  public puchaseMonthChart: any = []
  public puchaseYearChart: any = []
  public salesYearChart: any = []
  public weekNames: any;
  public weekDates: any;
  public weekTotalSaleAmt: any;
  public pastWeekTotalSaleAmt: any;
  public weekNamesSales: any;
  public weekSalesDateValue: Date;
  public monthSalesDateValue: Date;
  public yearSalesDateValue: Date;
  public lastweekSalesDateValue: Date;
  public lastmonthSalesDateValue: Date;
  public lastyearSalesDateValue: Date;
  public currentMonthtotalBillAmount: any[];
  public pastMonthTotalBillAmount: any[];
  public monthDay: any;
  public pastmonthDay: any;
  public yearlySalesDate: any;
  public lastyearlySalesDate: any;
  public yearlySalestotalBillAmount: any;
  public lastYearlySalestotalBillAmount: any;
  public isWeek: boolean = true;
  public isMonth: boolean = false;
  public isYear: boolean = false;
  public salesType: string = 'Weekly';
  public maxDate = new Date();
  public newSaleDate;
  public defaultSaleSelect = "Weekly";

  constructor(public rolePermission: RolesPermissionsService,
    public authorizationService: AuthorizationService,
    public dashboardService: DashboardService,
    public commonService: CommonService,
    public router: Router) {
    Chart.register(...registerables)
  }

  ngOnInit(): void {
    this.weekSalesDateValue = new Date();
    this.lastweekSalesDateValue = new Date();
    this.monthSalesDateValue = new Date();
    this.lastmonthSalesDateValue = new Date();
    this.yearSalesDateValue = new Date();
    this.lastyearSalesDateValue = new Date();

    this.getTotalInvoiceWeekReport();
    // this.getTotalPurchaseWeekReport()
  }

  getSaleInvoiceReportType(type) {
    this.salesType = type
    if (type == 'Weekly') {
      this.isWeek = true;
      this.isMonth = false;
      this.isYear = false;
      this.getTotalInvoiceWeekReport();
    } else if (type == 'Monthly') {
      this.isWeek = false;
      this.isMonth = true;
      this.isYear = false;
      this.getTotalInvoiceMonthReport();
    } else if (type == 'Yearly') {
      this.isWeek = false;
      this.isMonth = false;
      this.isYear = true;
      this.getTotalInvoiceYearReport();
    }
  }
  /**
   * get Total Sales
   */
  // getTotalSales() {
  //   this.dashboardService.getTotalSales({}, this.currentUser.id).then((res) => {
  //     if (res.data) {
  //       // this.totalSales = res.data[0].totalSales;
  //     }
  //   })
  // }
  /**
   * Total Sales Invoice Week Report
   */

  getTotalInvoiceWeekReport() {
    if (this.newSaleDate) {
      this.todaysDate = this.newSaleDate
    }
    const today = new Date(this.todaysDate);
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    const lastWeekStartDate = `${lastWeek.getFullYear()}-${lastWeek.getMonth() + 1}-${lastWeek.getDate()}`;
    // this api call for the latest sales of the current week report
    this.dashboardService.getTotalInvoiceWeekReport({}, this.currentUser.id, this.todaysDate, "SALES").then((res) => {
      this.weekNamesSales = res.data.dataset.map((names: any) => {
        const dayName = names.dayName;
        const abbreviatedDayName = dayName.substring(0, 3);
        return abbreviatedDayName;
      });
      this.weekTotalSaleAmt = res.data.dataset.map((total: any) => total.totalBillAmount);
      let date = res.data.dataset.map((date: any) => date.date);
      this.dashboardService.getTotalInvoiceWeekReport({}, this.currentUser.id, lastWeekStartDate, "SALES").then((res) => {
        this.pastWeekTotalSaleAmt = res.data.dataset.map((total: any) => total.totalBillAmount);
        let date = res.data.dataset.map((date: any) => date.date);
        this.plotWeeklyChart()
      })
    })
  }

  plotWeeklyChart(){
    this.salesChart = Highcharts;
    // noData(Highcharts);

    const isPastWeekDataEmpty = this.pastWeekTotalSaleAmt.every(value => value === 0);
      const isCurrentWeekDataEmpty = this.weekTotalSaleAmt.every(value => value === 0);
      if (isPastWeekDataEmpty && isCurrentWeekDataEmpty) {
        // Display a "No data found" message
        const chartOptions = {
          chart: {
            type: 'spline',
            events: {
              load: function() {
                const chart = this;
                const centerX = chart.plotWidth / 2;
                const centerY = chart.plotHeight / 2;

                // Add the image element
                chart.noDataImage = chart.renderer
                  .image('../../../assets/img/icons/spot-illustrations/notfound1.png', centerX - 90, centerY - 140, 200, 150)
                  .add();
              }
            }
          },
          title: {
            // text: this.salesType + ' Sales Report',
            text: ' ',
            style: {
              fontFamily: "'Poppins', sans-serif",
              fontWeight: '500',
              fontSize: '16px',
              color: '#737791'
            }
          },
          xAxis: {
            categories: this.weekNames
          },
          credits: {
            enabled: false
          },
        };

        // Render the chart
        this.salesChart.chart('salesCanvas', chartOptions);
        document.getElementById('salesCanvas').style.height = '300px';

      } else {
        const chartOptions = {
          chart: {
            type: 'spline',
          },
          credits:{
            enabled: false
          },
          title: {
            // text: this.salesType + ' Sales Report',
            text:' ',
            style: {
              fontFamily: "'Poppins', sans-serif",
              fontWeight: '500',
              fontSize: '16px',
              color: '#737791'
            }
          },
          xAxis: {
            title: {
              text: 'Days'
            },
            categories: this.weekNamesSales
          },
          yAxis: {
            title: {
              text: 'Sale Amount'
            },
            gridLineWidth: 0
          },
          plotOptions: {
            series: {
              animation: {
                duration: 3000
              },
              cursor: 'pointer',
            }
          },
          series: [
            {
              name: 'Past Week',
              data: this.pastWeekTotalSaleAmt,
              color: '#FC7643',
            },
            {
              name: 'Current Week',
              data: this.weekTotalSaleAmt,
              color: '#110152',
            }
          ],
        };

        this.salesChart.chart('salesCanvas', chartOptions);

        // Add CSS to adjust the size visually
        // document.getElementById('salesCanvas').style.width = '300px'; // Set your desired width
        document.getElementById('salesCanvas').style.height = '300px';
     }
  }

  // getUpdatedInvoiceWeekReport(e) {
  //   this.destroySalesChart()
  //   this.newSaleDate = moment(e).format('YYYY-MM-DD')
  //   this.dashboardService.getTotalInvoiceWeekReport({}, this.currentUser.id, moment(e).format('YYYY-MM-DD'), "SALES").then(async (res) => {
  //     this.weekNamesSales = res.data.dataset.map((names: any) => names.dayName);
  //     this.weekTotalSaleAmt = res.data.dataset.map((total: any) => total.totalBillAmount);
  //     let date = res.data.dataset.map((date: any) => date.date);
  //     this.salesChart = Highcharts;
  //     const chartOptions = {
  //       chart: {
  //         type: 'spline'
  //       },
  //       title: {
  //         text: 'Total Sale'
  //       },
  //       credits:{
  //         enabled: false
  //      },
  //       xAxis: {
  //         categories: this.weekNamesSales,
  //         title: {
  //           text: 'Days of Week'
  //         }
  //       },
  //       yAxis: {
  //         title: {
  //           text: 'Sales Amount'
  //         },
  //         min: 0,
  //         gridLineWidth: 0
  //       },
  //       plotOptions: {
  //         series: {
  //             animation: {
  //                 duration: 3000
  //             },
  //             cursor: 'pointer',
  //             dragDrop: {
  //               draggableY: true, // enable Y-axis dragging
  //               // dragMinY: 0, // minimum Y-axis value
  //               // dragMaxY: 100, // maximum Y-axis value
  //               // dragPrecisionY: 1, // Y-axis precision
  //               dragCursor: 'ns-resize', // cursor style during drag
  //               dragStart: function (e) {
  //                 console.log('Drag started on series:', e.target.name);
  //               },
  //               dragEnd: function (e) {
  //                 console.log('Drag ended on series:', e.target.name);
  //               }
  //             }
  //         }
  //       },
  //       series: [{
  //         name: this.salesType + ' Sales Report',
  //         data: this.weekTotalSaleAmt,
  //         fillColor: 'rgba(93,175,89,0.1)',
  //         color: '#FC7643',
  //       }]
  //     };
  //     this.salesChart.chart('salesCanvas', chartOptions);
  //   });

  // }

  /**
   * Total Sales Invoice Month Report
   */
  getTotalInvoiceMonthReport() {
    if (this.newSaleDate) {
      this.todaysDate = this.newSaleDate;
    }
    const today = new Date(this.todaysDate);
    const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    const oneMonthAgoDateString = `${oneMonthAgo.getFullYear()}-${oneMonthAgo.getMonth() + 1}-${oneMonthAgo.getDate()}`;
    // this API call returns current month data
    this.dashboardService.getTotalInvoiceMonthReport({}, this.currentUser.id, this.todaysDate, "SALES").then(async (res) => {
      this.monthDay = Object.values(res.data.dataset).map((names: any) => names.day.split('-')[2]);
      this.currentMonthtotalBillAmount = Object.values(res.data.dataset).map((total: any) => total.totalBillAmount);
      // this.plotMonthChart()
      this.dashboardService.getTotalInvoiceMonthReport({}, this.currentUser.id, oneMonthAgoDateString, "SALES").then(async (res) => {
        this.pastmonthDay = Object.values(res.data.dataset).map((names: any) => names.day.split('-')[2]);
        this.pastMonthTotalBillAmount = Object.values(res.data.dataset).map((total: any) => total.totalBillAmount);
            this.plotMonthChart();
          });
    });
  }

  plotMonthChart(){
    this.salesChart = Highcharts;
    const isPastMonthDataEmpty = this.pastMonthTotalBillAmount.every(value => value === 0);
    const isCurrentMonthDataEmpty = this.currentMonthtotalBillAmount.every(value => value === 0);
    if (isPastMonthDataEmpty && isCurrentMonthDataEmpty) {
      // Display a "No data found" message
      const chartOptions = {
        chart: {
          type: 'spline',
          events: {
            load: function() {
              const chart = this;
              const centerX = chart.plotWidth / 2;
              const centerY = chart.plotHeight / 2;

              // Add the image element
              chart.noDataImage = chart.renderer
                .image('../../../assets/img/icons/spot-illustrations/notfound1.png', centerX - 90, centerY - 140, 200, 150)
                .add();
            }
          }
        },
        title: {
          // text: this.salesType + ' Sales Report',
          text: ' ',
            style: {
              fontFamily: "'Poppins', sans-serif",
              fontWeight: '500',
              fontSize: '16px',
              color: '#737791'
            }
        },
        xAxis: {
          categories: this.weekNames
        },
        credits: {
          enabled: false
        },
      };
          this.salesChart.chart('salesCanvas', chartOptions);
          document.getElementById('salesCanvas').style.height = '300px';

        } else {
        // Data available, render the chart
      const chartOptions = {
        chart: {
      type: 'spline'
        },
        title: {
          // text: this.salesType + ' Sales Report',
          text:' ',
          style: {
            fontFamily: "'Poppins', sans-serif",
            fontWeight: '500',
            fontSize: '16px',
            color: '#737791'
          }
        },
        credits:{
          enabled: false
       },
        xAxis: {
          categories: this.pastmonthDay
        },
        yAxis: {
          title: {
            text: 'Sales Amount'
          },
          gridLineWidth: 0
        },
        plotOptions: {
          series: {
              animation: {
                  duration: 3000
              },
              cursor: 'pointer',
          }
        },
        series: [
          {
            name: 'Past Month',
            data: this.pastMonthTotalBillAmount,
            color: '#FC7643',
          },
          {
            name: 'Current Month',
            data: this.currentMonthtotalBillAmount,
            color: '#110152',
          }
        ]
      };
      this.salesChart.chart('salesCanvas', chartOptions);
      document.getElementById('salesCanvas').style.height = '300px';

    }
  }

  // getUpdatedInvoiceMonthReport(e) {
  //   this.newSaleDate = moment(e).format('YYYY-MM-DD');
  //   this.dashboardService.getTotalInvoiceMonthReport({}, this.currentUser.id, moment(e).format('YYYY-MM-DD'), "SALES").then((res) => {
  //     this.monthDay = res.data.dataset.map((names: any) => names.day);
  //     this.currentMonthtotalBillAmount = res.data.dataset.map((total: any) => total.totalBillAmount);
  //     this.salesChart = Highcharts;
  //     const chartOptions = {
  //       chart: {
  //         type: 'spline'
  //       },
  //       title: {
  //         text: 'Total Sale'
  //         // text: this.salesType + ' Sales Report'
  //       },
  //       credits:{
  //         enabled: false
  //      },
  //       xAxis: {
  //         categories: this.monthDay
  //       },
  //       yAxis: {
  //         title: {
  //           text: 'Sales Amount'
  //         },
  //         min: 0,
  //         gridLineWidth: 0
  //       },
  //       plotOptions: {
  //         series: {
  //             animation: {
  //                 duration: 3000
  //             },
  //             cursor: 'pointer',
  //             dragDrop: {
  //               draggableY: true, // enable Y-axis dragging
  //               // dragMinY: 0, // minimum Y-axis value
  //               // dragMaxY: 100, // maximum Y-axis value
  //               // dragPrecisionY: 1, // Y-axis precision
  //               dragCursor: 'ns-resize', // cursor style during drag
  //               dragStart: function (e) {
  //                 console.log('Drag started on series:', e.target.name);
  //               },
  //               dragEnd: function (e) {
  //                 console.log('Drag ended on series:', e.target.name);
  //               }
  //             }
  //         }
  //       },
  //       series: [{
  //         name: this.salesType,
  //         data: this.currentMonthtotalBillAmount,
  //         fillColor: 'rgba(93,175,89,0.1)',
  //         color: '#FC7643'
  //       }],
  //     }
  //     this.salesChart.chart('salesCanvas', chartOptions)
  //   })

  // }

  /**
   * Total Sales Invoice Year Report
   */
  getTotalInvoiceYearReport() {
    if (this.newSaleDate) {
      this.todaysDate = this.newSaleDate;
    }
    const oneYearAgo = moment().subtract(1, 'years').format('YYYY-MM-DD')
    this.dashboardService.getTotalInvoiceYearReport({}, this.currentUser.id, this.todaysDate, "SALES").then((res) => {
      this.yearlySalesDate = res.data.dataset.map((names: any) => names.date + "/" + names.year);
      this.yearlySalestotalBillAmount = res.data.dataset.map((total: any) => total.totalBillAmount);
      // this.plotYearlyChart()
      this.dashboardService.getTotalInvoiceYearReport({}, this.currentUser.id, oneYearAgo, "SALES").then((res) => {
        this.lastyearlySalesDate = res.data.dataset.map((names: any) => names.date + "/" + names.year);
        this.lastYearlySalestotalBillAmount = res.data.dataset.map((total: any) => total.totalBillAmount);
        this.plotYearlyChart()
      })
    })
  }

  plotYearlyChart(){
    this.salesChart = Highcharts;
    const isPastYearDataEmpty = this.lastYearlySalestotalBillAmount.every(value => value === 0);
    const isCurrentYearDataEmpty = this.yearlySalestotalBillAmount.every(value => value === 0);
    if (isPastYearDataEmpty && isCurrentYearDataEmpty) {
      // Display a "No data found" message
      const chartOptions = {
        chart: {
          type: 'spline',
          events: {
            load: function() {
              const chart = this;
              const centerX = chart.plotWidth / 2;
              const centerY = chart.plotHeight / 2;

              // Add the image element
              chart.noDataImage = chart.renderer
                .image('../../../assets/img/icons/spot-illustrations/notfound1.png', centerX - 90, centerY - 140, 200, 150)
                .add();
            }
          }
        },
        title: {
          // text: this.salesType + ' Sales Report',
          text: ' ',
            style: {
              fontFamily: "'Poppins', sans-serif",
              fontWeight: '500',
              fontSize: '16px',
              color: '#737791'
            }
        },
        xAxis: {
          categories: this.weekNames
        },
        credits: {
          enabled: false
        },
      };
          this.salesChart.chart('salesCanvas', chartOptions);
          document.getElementById('salesCanvas').style.height = '300px';

        } else {
          const chartOptions = {
            chart: {
              type: 'spline'
            },
            credits:{
              enabled: false
           },
            title: {
              // text: this.salesType + ' Sales Report',
              text: ' ',
              style: {
                fontFamily: "'Poppins', sans-serif",
                fontWeight: '500',
                fontSize: '16px',
                color: '#737791'
              }
            },
            xAxis: {
              categories: this.yearlySalesDate
              // categories: this.lastyearlySalesDate + '-' + this.yearlySalesDate
            },
            yAxis: {
              title: {
                text: 'Sales Amount'
              },
              min: 0,
              gridLineWidth: 0
            },
            plotOptions: {
              series: {
                  animation: {
                      duration: 3000
                  },
                  cursor: 'pointer',
                  dragDrop: {
                    draggableY: true, // enable Y-axis dragging
                    // dragMinY: 0, // minimum Y-axis value
                    // dragMaxY: 100, // maximum Y-axis value
                    // dragPrecisionY: 1, // Y-axis precision
                    dragCursor: 'ns-resize', // cursor style during drag
                    dragStart: function (e) {
                      console.log('Drag started on series:', e.target.name);
                    },
                    dragEnd: function (e) {
                      console.log('Drag ended on series:', e.target.name);
                    }
                  }
              }
            },
            series: [{
              name: 'Past Year',
              data: this.lastYearlySalestotalBillAmount,
              color: '#FC7643',
            },
            {
              name: 'Current Year',
              data: this.yearlySalestotalBillAmount,
              color: '#110152',
            }]
          }
          this.salesChart.chart('salesCanvas', chartOptions)
          document.getElementById('salesCanvas').style.height = '300px';

        }
  }

  // getUpdatedInvoiceYearReport(e) {
  //   this.newSaleDate = moment(e).format('YYYY-MM-DD')
  //   this.dashboardService.getTotalInvoiceYearReport({}, this.currentUser.id, moment(e).format('YYYY-MM-DD'), "SALES").then((res) => {
  //     this.yearlySalesDate = res.data.dataset.map((names: any) => names.date + "/" + names.year);
  //     this.yearlySalestotalBillAmount = res.data.dataset.map((total: any) => total.totalBillAmount);
  //     this.salesChart = Highcharts;
  //     const chartOptions = {
  //       chart: {
  //         type: 'spline'
  //       },
  //       credits:{
  //         enabled: false
  //      },
  //       title: {
  //         text: 'Total Sale'
  //       },
  //       xAxis: {
  //         categories: this.monthDay
  //       },
  //       yAxis: {
  //         title: {
  //           text: 'Sales Amount'
  //         },
  //         gridLineWidth: 0
  //       },
  //       plotOptions: {
  //         series: {
  //             animation: {
  //                 duration: 3000
  //             },
  //             cursor: 'pointer',
  //             dragDrop: {
  //               draggableY: true, // enable Y-axis dragging
  //               // dragMinY: 0, // minimum Y-axis value
  //               // dragMaxY: 100, // maximum Y-axis value
  //               // dragPrecisionY: 1, // Y-axis precision
  //               dragCursor: 'ns-resize', // cursor style during drag
  //               dragStart: function (e) {
  //                 console.log('Drag started on series:', e.target.name);
  //               },
  //               dragEnd: function (e) {
  //                 console.log('Drag ended on series:', e.target.name);
  //               }
  //             }
  //         }
  //       },
  //       series: [{
  //         name: this.salesType + ' Sales Report',
  //         data: this.yearlySalestotalBillAmount,
  //         fillColor: 'rgba(93,175,89,0.1)',
  //         borderColor: '#FC7643'
  //       }],
  //     }
  //     this.salesChart.chart('salesCanvas', chartOptions)
  //   })
  // }

}
