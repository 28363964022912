import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { BiddingService } from 'src/app/core/services/bidding.service';
import { PartyReportsService } from 'src/app/core/services/party-reports.service';
import { ProductService } from 'src/app/core/services/product.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { map, startWith } from 'rxjs/operators';
import * as XLSX from 'xlsx';


interface IRange {
  value: Date[];
  label: string;

}

@Component({
  selector: 'app-party-report-by-item',
  templateUrl: './party-report-by-item.component.html',
  styleUrls: ['./party-report-by-item.component.scss']
})
export class PartyReportByItemComponent implements OnInit {
  public currentPageNo: number = 0;
  public goodsData = [];
  public partyPageSize: number = 5;
  public prodName: string;
  public prodId: string;
  public searchedProduct = [];
  public myControl = new FormControl();
  public quarterStartDate: any;
  public quarterEndDate: any;
  public today: Date;
  public quarter;
  public maxDate: Date;
  public start = moment().subtract(29, 'days');
  public end = moment();
  public ranges: IRange[];
  partyList: any = [];
  itemPartyList: any = [];
  itemPartyListTotal: any = [];
  public productId: any;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public filteredOptions: Observable<string[]>
  public dateRange: Date[]
  fileName = 'partyReportByItem.xlsx';

  public saleQuantityFilterMin = 0;
  public saleQuantityFilterMax = 0;
  public totalSaleInput = 0;
  public saleAmountFilterMin = 0;
  public saleAmountFilterMax = 0;
  public totalQuantityInput = 0;
  public purchaseAmountFilterMin = 0;
  public purchaseAmountFilterMax = 0;
  public totalPurchaseInput= 0;
  public purchaseQuantityFilterMin = 0;
  public purchaseQuantityFilterMax =0;
  public totalPurchaseAmt = 0;
  public valueItem : string="Equal";
  public noData: any;
  public pagesize: number = 1;
  public totalrow: any;
  public toatlPages: any;
  p: any;

  printComponent(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;

  }

  constructor(private partyreportsService: PartyReportsService,
    private toastService: ToastNotificationService,
    private titleCasePipe: TitleCasePipe,
    private biddingService: BiddingService,
    private productService: ProductService,) {
    this.today = new Date();
    this.maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), 25);
    let quarter = moment(this.today).utc().quarter() //outputs 4
    this.getQuarterRange();
  }

  ngOnInit(): void {
    this.getGoodsbyUser();
    this.ranges = [

      {
        value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 7 Days'
      },
      {
        value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'Last 30 Days'
      },
      {
        value: [new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()), new Date(new Date(new Date().setDate(0)).toDateString())],
        label: 'Last Month'

      },
      {
        value: [this.quarterStartDate._d, this.quarterEndDate._d],
        label: ' Last Quarter'
      },
      {
        value: [new Date(new Date(new Date().getFullYear() - 1, 3, 1).toDateString()), new Date(new Date(new Date().getFullYear(), 2, 31).toDateString())],
        label: 'Last Financial Year'

      },
      {
        value: [new Date(new Date(new Date().getFullYear(), 3, 1).toDateString()), new Date(new Date().setDate(new Date().getDate() - 1))],
        label: 'This Financial Year'

      },
      {
        value: [],
        label: 'Custom Range'

      }];
    this.dateRange = [new Date(new Date().setDate(new Date().getDate() - 30)), new Date(new Date().setDate(new Date().getDate()))]


  }
  getQuarterRange() {
    this.quarterStartDate = moment(this.today).subtract(1, 'quarter').startOf('quarter');
    this.quarterEndDate = moment(this.today).subtract(1, 'quarter').endOf('quarter');
  }
  getGoodsbyUser() {
    this.productService.getGoodsProductByUser_Type({}, this.currentUser.id, "goods", "DESC", this.partyPageSize, (this.currentPageNo + 1)).then((res) => {

      this.goodsData = res.data.pageData;
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(name => name ? this.filterNames(name) : this.goodsData.slice())
        );
    }, (err) => {
      if (err.error.status == 404) {
      }
    })
  }

  private filterNames(name: string): string[] {
    return this.goodsData.filter(goods =>
      goods.itemName.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  searchProduct() {
    if(this.myControl.value== ""){
      this.toastService.toastMsg({
        title: "Error",
        content: "Please Enter Product Name.!!!",
      });
      // this.toastService.openErrorSnackBar("Please enter Product Name.!!!");
      this.partyList=[];
    }
    this.biddingService.getAllProductsByUserSearch({}, this.currentUser.id, this.myControl.value,this.partyPageSize, (this.currentPageNo + 1))
      .then((res) => {
        this.searchedProduct = res.data.pageData;
        this.toatlPages = res.data.totalPages;
        this.totalrow = res.data.totalRows;
        this.p = res.data.currentPage;
        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this.filterNames(name) : this.searchedProduct.slice())
          );
        this.prodName = this.searchedProduct[0].itemName;
        this.prodId = this.searchedProduct[0].id
        this.productId = this.searchedProduct[0].id
      })
  }


  selectedProduct(itemName) {
    if(itemName== ""){
      this.toastService.toastMsg({
        title: "Error",
        content: "Please Enter Product Name.!!!",
      });
      // this.toastService.openErrorSnackBar("Please enter Product Name.!!!");
      this.partyList=[];
    }
    this.biddingService.getAllProductsByUserSearch({}, this.currentUser.id, itemName,this.partyPageSize, (this.currentPageNo + 1))
      .then((res) => {
        this.searchedProduct = res.data.pageData;
        this.prodName = res.data.pageData[0].itemName;
        this.prodId = res.data.pageData[0].id
        this.productId = res.data.pageData[0].id
        this.toatlPages = res.data.totalPages;
        this.totalrow = res.data.totalRows;
        this.p = res.data.currentPage;
        this.getPartyByReport();
        this.getAllPartyReport();
      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
          // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
          // this.toastService.openErrorSnackBar("Something Went Wrong.");
        }
      }
      )
  }

  getPartyByReport() {
    this.partyreportsService.getPartyByItem({}, this.currentUser.id, this.prodId, this.partyPageSize, (this.currentPageNo + 1)).then((res: any) => {
      this.partyList = res.data.dataset;
      this.itemPartyListTotal = res.data;
      // this.partyList = res.data.pageData;
       this.toatlPages = res.data.totalPages;
      this.totalrow = res.data.totalRows;
      // If item searched don't have any data.
      this.noData = res.data.message;
      // console.log("=====", this.noData)
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    })
  }

  getAllPartyReport() {
    this.partyreportsService.getItemPartyReport({}, this.currentUser.id, this.productId,this.partyPageSize, (this.currentPageNo + 1),moment(this.dateRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD') ,moment(this.dateRange[1], 'DD-MMM-YYYY').format('YYYY-MM-DD')).then((res:any) => {
      this.partyList = res.data.dataset;
      this.itemPartyListTotal = res.data;
      this.toatlPages = res.data.totalPages;
      this.totalrow = res.data.totalRows;
      this.p = res.data.currentPage;


    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        // this.toastService.openErrorSnackBar(this.titleCasePipe.transform(err.error.error_message));
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
        // this.toastService.openErrorSnackBar("Something Went Wrong.");
      }
    })
  } exportExcel() {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }
  onPrint() {
    const elementsToHide = ['headingPrintHide', 'dateRangePrintHide', 'searchBarHide','headingPrintHide1'];

    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = 'none';
      }
    });

    // Hide headers and footers for printing
    const style = document.createElement('style');
    style.innerHTML = `
        @media print {
            @page {
                size: auto;   /* auto is the initial value */
                margin: 0;  /* this affects the margin in the printer settings */
            }
            @page {
                margin-top: 0;
                margin-bottom: 0;
            }
            body {
                margin: 0;  /* this affects the margin on the content before sending to the printer */
            }
            @page :first {
                margin-top: 0;
            }
            @page :last {
                margin-bottom: 0;
            }
            /* Define CSS rules to hide headers and footers */
            header, footer {
                display: none !important;
            }
        }
    `;
    document.head.appendChild(style);

    // Print
    const printContents = document.getElementById('excel-table').innerHTML;
    const originalContents = document.body.innerHTML;
    this.removeScrollbar();
    window.print();
    this.restoreScrollbar();
    // Restore hidden elements after printing
    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = '';
      }
    });
    // Remove the added style for hiding headers and footers
    document.head.removeChild(style);
  }
  private removeScrollbar() {
    document.body.style.overflow = 'hidden';
  }

  private restoreScrollbar() {
    document.body.style.overflow = 'auto';
  }
  onSelectSaleQuantity(value) {
    this.valueItem = value;
    console.log("valueItem---->", this.valueItem)
    console.log("value---->", value)
}
  ApplyValue(){
    console.log("APPLY CLICK", this.totalSaleInput,this.totalSaleInput,this.totalSaleInput)


    if(this.valueItem == "Greater"){
      this.valueItem ="Greater"
      this.partyreportsService.getPartyRepoSaleQuantGreater({}, this.currentUser.id, this.productId,this.totalSaleInput).then((res) => {
        this.partyList = res.data.dataset;
        this.itemPartyListTotal = res.data;

      })

    }
    else if (this.valueItem == "Equal"){
      this.valueItem ="Equal"
      this.partyreportsService.getPartyRepoSaleQuantEqual({}, this.currentUser.id, this.productId,this.totalSaleInput).then((res) => {
        this.partyList = res.data.dataset;
        this.itemPartyListTotal = res.data;

      })

    }
    else{
      this.partyreportsService.getPartyRepoSaleQuantless({}, this.currentUser.id, this.productId,this.totalSaleInput).then((res) => {
        this.partyList = res.data.dataset;
        this.itemPartyListTotal = res.data;

      })

    }
  }
  onSelectSaleAmount(value){
    this.valueItem = value;
    console.log("valueItem--->", this.valueItem)
    console.log("value--->", value)
}
ApplyQuantity(){
  console.log("APPLY CLICK sale Amount", this.totalQuantityInput, this.totalQuantityInput,this.totalQuantityInput)


  if(this.valueItem == "Greater"){
    this.valueItem = "Greater"
    this.partyreportsService.getPartyRepoSaleAmountGreater({}, this.currentUser.id, this.productId,this.totalQuantityInput).then((res) => {
      this.partyList = res.data.dataset;
      this.itemPartyListTotal = res.data;
    })

  }
  else if (this.valueItem == "Equal"){
    this.valueItem ="Equal"
    this.partyreportsService.getPartyRepoSaleAmountEqual({}, this.currentUser.id, this.productId,this.totalQuantityInput).then((res) => {
      this.partyList = res.data.dataset;
      this.itemPartyListTotal = res.data;
    })

  }
  else{
    this.partyreportsService.getPartyRepoSaleAmountless({}, this.currentUser.id, this.productId,this.totalQuantityInput).then((res) => {
      this.partyList = res.data.dataset;
      this.itemPartyListTotal = res.data;
    })

  }



  }
  onSelectPurchase(value) {
    this.valueItem = value;
    console.log("valueItem---->", this.valueItem)
    console.log("value--->", value)
}
ApplyPurchaseValue(){
  console.log("APPLY CLICK", this.totalPurchaseInput, this.totalPurchaseInput,this.totalPurchaseInput)


    if(this.valueItem == "Greater"){
      this.valueItem = "Greater"
      this.partyreportsService.getPartyRepoPurchaseQuantityGreater({}, this.currentUser.id, this.productId,this.totalPurchaseInput).then((res) => {
        this.partyList = res.data.dataset;
        this.itemPartyListTotal = res.data;
        })

    }
    else if (this.valueItem == "Equal"){
      this.valueItem ="Equal"
      this.partyreportsService.getPartyRepoPurchaseQuantityEqual({}, this.currentUser.id, this.productId,this.totalPurchaseInput).then((res) => {
        this.partyList = res.data.dataset;
        this.itemPartyListTotal = res.data;

        })

    }

    else{
      this.partyreportsService.getPartyRepoPurchaseQuantityless({}, this.currentUser.id, this.productId,this.totalPurchaseInput).then((res) => {
        this.partyList = res.data.dataset;
        this.itemPartyListTotal = res.data;

        })

    }

  }
onSelectPurchaseQuantity(value) {
  this.valueItem = value;
  console.log("valueItem--->", this.valueItem)
  console.log("value--->", value)
}

ApplyPurchase(){
console.log("APPLY CLICK", this.totalPurchaseAmt, this.totalPurchaseAmt,this.totalPurchaseAmt)

    if(this.valueItem == "Greater"){
      this.valueItem = "Greater"
      this.partyreportsService.getPartyRepoPurchaseAmountGreater({}, this.currentUser.id, this.productId,this.totalPurchaseAmt).then((res) => {
        this.partyList = res.data.dataset;
        this.itemPartyListTotal = res.data;
    })

    }
    else if (this.valueItem == "Equal"){
      this.valueItem ="Equal"
      this.partyreportsService.getPartyRepoPurchaseAmountEqual({}, this.currentUser.id, this.productId,this.totalPurchaseAmt).then((res) => {
        this.partyList = res.data.dataset;
        this.itemPartyListTotal = res.data;
    })
    }
    else{
      this.partyreportsService.getPartyRepoPurchaseAmountless({}, this.currentUser.id, this.productId,this.totalPurchaseAmt).then((res) => {
        this.partyList = res.data.dataset;
        this.itemPartyListTotal = res.data;
    })
}


}
changeDate(e){
  this.getAllPartyReport();
  }
  clearsaleQuantity(){
    this.valueItem ="Equal"
    this.getPartyByReport();
    this.getAllPartyReport();
  }

  public handlePage(e: any) {
    console.log(e);
    // this.tableData = [];
    this.currentPageNo = e.pageIndex;
    this.pagesize = e.pageSize;
    if (this.partyList.length > 0) {
      this.partyPageSize = e.pageSize;
      this.getAllPartyReport();
    }
  }
}

