
<!-- <div class="toast_stack">
  <div *ngFor="let toast of toasts; let i = index" class="toast_wrapper" [@openClose]="'open'">
    <div class="inner_wrapper">
      <div class="toast_container">
        <div class = "flex_image">
          <img class = "img-fluid" *ngIf="toast.title === 'Success'" src = "../../../../assets/images/Success.png" alt = "Success" width = "50" height = "50">
          <img class = "img-fluid" *ngIf="toast.title === 'Error'" src = "../../../../assets/images/Error.png" alt = "Error" width = "50" height = "50">
          <img class = "img-fluid" *ngIf="toast.title === 'Warning'" src = "../../../../assets/images/Warning.png" alt = "Warning" width = "50" height = "50">
        </div>
        <div class="toast_content">
          <div class="d-flex justify-content-between">
            <h5 class="toast_title" [ngClass]="{'error_title': toast.title === 'Error', 'success_title': toast.title === 'Success', 'warning_title': toast.title === 'Warning'}">
              {{ toast.title }}
            </h5>
            <button type="button" class="btn-close" (click)="closeToast(i)" aria-label="Close"></button>
          </div>
          <div class="toast_text" [ngClass]="{'success_text': toast.title === 'Success', 'error_text': toast.title === 'Error', 'warning_text': toast.title === 'Warning'}">
            {{ toast.content }}
          </div>
        </div>
      </div> -->
      <!-- <div class="progress_bar" *ngIf="toast.title === 'Success'" [ngClass]="{'success_progress_bar': toast.title === 'Success'}" #element></div>
      <div class="progress_bar" *ngIf="toast.title === 'Error'" [ngClass]="{'error_progress_bar': toast.title === 'error'}" #element></div>
      <div class="progress_bar" *ngIf="toast.title === 'Warning'" [ngClass]="{'warning_progress_bar': toast.title === 'Warning'}" #element></div> -->
      <!-- <div class="progress_bar" [ngClass]="{'success_progress_bar': toast.title === 'Success','error_progress_bar': toast.title === 'Error','warning_progress_bar': toast.title === 'Warning'}" #element></div>
    </div>
  </div>
</div> -->

<!-- (@openClose.done)="onToastHidden()" -->
<!-- <div
  (mouseenter)="stopCountDown()"
  (mouseleave)="countDown()"
  class="toast_wrapper"
  [@openClose]="toastService.data && toastService.data.show ? 'open' : 'closed'"
>
  <div class="inner_wrapper">
    
    <div class="flex" *ngIf="toastService.data">
      <div class="toast_content">
        <div class="d-flex justify-content-between">
          <h5 class="toast_title">
            {{ toastService.data.title }}
          </h5>
          <button type="button" class="btn-close" (click)="closeToast()" aria-label="Close"></button>
        </div>
      
        <div class="toast_text">
          {{ toastService.data.content }}
        </div>
      </div>
    </div>
    
  </div>

  <div
    class="progress_bar"
    [ngClass]="{ success: toastService.data && toastService.data.type === 1 }"
    #element
  ></div>
</div> -->

<div class="toast_stack">
  <div *ngFor="let toast of toasts.slice().reverse(); let i = index" class="toast_wrapper"
  [@openClose]="toastService.data && toastService.data.show ? 'open' : 'closed'"
  [ngClass]="{'success_background': toast.title === 'Success', 'error_background': toast.title === 'Error', 'warning_background': toast.title === 'Warning', 'info_background': toast.title === 'Info'}">
    <div class="inner_wrapper">
      <div class="toast_container">
        <div class = "flex_image">
          <img class = "img-fluid" *ngIf="toast.title === 'Success'" src = "../../../../assets/images/success-new.png" alt = "Success" width = "55" height = "55" style = "background-color: #fff;border-radius: 50%;">
          <img class = "img-fluid" *ngIf="toast.title === 'Error'" src = "../../../../assets/images/error-new.png" alt = "Error" width = "55" height = "55" style = "background-color: #fff;border-radius: 50%;">
          <img class = "img-fluid" *ngIf="toast.title === 'Warning'" src = "../../../../assets/images/warning-new.png" alt = "Warning" width = "55" height = "55" style = "background-color: #fff;border-radius: 50%;padding:4px;">
          <img class = "img-fluid" *ngIf="toast.title === 'Info'" src = "../../../../assets/images/info-new.png" alt = "Info" width = "55" height = "55" style = "background-color: #fff;border-radius: 50%;">
        </div>
        <div class="toast_content">
          <div class="d-flex justify-content-between">
            <h5 class="toast_title" [ngClass]="{'error_title': toast.title === 'Error', 'success_title': toast.title === 'Success', 'warning_title': toast.title === 'Warning', 'info_title': toast.title === 'Info'}">
              {{ toast.title }}
            </h5>
            <button type="button" class="btn-close" style = "background-color: #fff;" (click)="close(i)" aria-label="Close"></button>
          </div>
          <div class="toast_text" [ngClass]="{'success_text': toast.title === 'Success', 'error_text': toast.title === 'Error', 'warning_text': toast.title === 'Warning', 'info_text': toast.title === 'Info'}">
            {{ toast.content }}
          </div>
        </div>
      </div>
      <div class="progress_bar" [ngClass]="{'success_progress_bar': toast.title === 'Success','error_progress_bar': toast.title === 'Error','warning_progress_bar': toast.title === 'Warning', 'info_progress_bar': toast.title === 'Info'}" #element></div>
    </div>
  </div>
</div>
s
