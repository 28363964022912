<div class="container-fluid pt-3 bg-white">
    <div class="fixed-nav-panel row">
        <div class="nav-panel-header col-lg-4">
            <label class="form-label" style="font-size: 20px; font-weight: 500;">
                Add New Trade Name                        
            </label>
        </div>

        <div class="nav-panel-actions headerButtons col-lg-8">
            <div class="nav-panel-btns formButtons">                
                <button mat-raised-button class="text-nowrap" style="border: 1px solid #000;"
                routerLink="/pages/profile" *appHasPermission="['modify']; name 'profile'">Cancel</button>
                <button mat-raised-button class="text-nowrap text-light" style="background: #a0a0a0;" type="button"
                *appHasPermission="['modify']; name 'profile'" (click)="UpdateNewShop()">Save Changes</button>
            </div>
        </div>
    </div>
<hr style="background-color: black;font-weight: bold;">
    <!-- <form #ProfileForm="ngForm"> -->
    <form [formGroup]="ProfileForm" autocomplete="off">
        <!-- Profile options -->
        <div class="profile-options row">
            <div class="profile-details col-sm-12 col-md-6 col-lg-6 pe-4">
                <div class="row mb-5">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <label class="form-label ms-2"> Admin Name<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="text" matInput required formControlName="firstName" maxLength="50"
                            placeholder="First Name">
                        </mat-form-field>
                    </div>
                    <div class="form-group col-lg-6 col-md-12 col-sm-12">
                        <label class="form-label ms-2"> Email Address<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="email" matInput required formControlName="email"
                                placeholder="Email">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="form-group col-lg-6 col-md-12 col-sm-12">
                        <label class="form-label ms-2"> Mobile #<span class="error-asterisk">*</span></label>
                        <mat-form-field appearance="outline" class="example-full-width">
                            <input type="text" matInput formControlName="mobile" minlength="10" maxlength="10"
                                placeholder="Mobile #">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <label class="form-label ms-2"> GSTIN </label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="text" matInput formControlName="gstinNo"
                                (keyup)="validateGSTIN($event.target.value)" maxlength="15" #input1
                                placeholder="GSTIN" (input)="input1.value=$event.target.value.toUpperCase()">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-lg-6 col-md-12 col-sm-12" *ngIf="(ProfileForm.get('businessType').value =='Private Limited Company' || ProfileForm.get('businessType').value =='Public Limited Company') && isShown">
                        <label class="form-label ms-2"> CIN</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" formControlName="cinNumber"
                                (keyup)="validatecinNumber($event.target.value)" #input1
                                (input)="input1.value=$event.target.value.toUpperCase()" placeholder="CIN">
                        </mat-form-field>
                        <mat-error
                            *ngIf="f.cinNumber.invalid && (f.cinNumber.dirty || f.cinNumber.touched)"
                            class="invalid-feedback">
                            <mat-error *ngIf="f.cinNumber.errors?.required"> CINI is Required!</mat-error>
                            <mat-error class="text-danger"
                                *ngIf="(submitted || f.cinNumber.touched) && f.cinNumber.errors?.pattern">
                                CIN pattern is Required!
                            </mat-error>
                        </mat-error>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12" *ngIf="ProfileForm.get('businessType').value =='Proprietorship' || ProfileForm.get('businessType').value =='Hindu Undivided Family' && isShown">
                        <label class="form-label ms-2"> Legal Name</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" formControlName="legalName"
                                placeholder="Legal Name" [ngClass]="{
                                    'is-invalid': (f.legalName.invalid && f.legalName.touched) && submitted }" requied>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.legalName.touched) && f.legalName.errors">
                            Legal name must be in Alphabets
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.legalName.touched) && f.legalName.errors?.required">
                            Legal Name is Required!
                        </mat-error>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-lg-6 col-md-12 col-sm-12" *ngIf="isShown">
                        <label class="form-label ms-2"> PAN </label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput formControlName="panNumber" placeholder="PAN"
                                (keyup)="validatepanNumber($event.target.value)" #input1
                                (input)="input1.value=$event.target.value.toUpperCase()">
                        </mat-form-field>
                        <mat-error style="font-weight: 400; font-size: 11px; "
                            *ngIf="f.panNumber.invalid && (f.panNumber.dirty || f.panNumber.touched)"
                            class="invalid-feedback">
                            <mat-error *ngIf="f.panNumber.errors?.required"> PAN  Is Required!</mat-error>
                            <mat-error class="text-danger"
                                *ngIf="(submitted || f.panNumber.touched) && f.panNumber.errors?.pattern">
                                PAN pattern is Required!
                            </mat-error>
                        </mat-error>
                    </div>
                </div>

                <!-- <div class="row mb-3">

                </div> -->

                <div class="row mb-3">
                    <div class="col-lg-6 col-md-12 col-sm-12" *ngIf="isShown">
                        <label class="form-label ms-2"> Financial Year Start From</label>
                        <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
                            <input matInput ngModel bsDatepicker placeholder="Financial Year From" [maxDate]="today"
                                formControlName="financialYearFrom" style="text-transform:uppercase!important;"
                                bsDatepicker [bsConfig]="{
                          containerClass: 'theme-dark-blue',
                          dateInputFormat: 'DD MMM YYYY',
                          showWeekNumbers:false }"
                                [ngClass]="{
                            'is-invalid': (f.financialYearFrom.invalid && f.financialYearFrom.touched) && submitted }">
                            <span>
                                <mat-icon class="iconcalender">date_range</mat-icon>
                            </span>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.financialYearFrom.touched) && f.financialYearFrom.errors?.required">
                            financialYearFrom is Required!
                        </mat-error>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12" *ngIf="isShown">
                        <label class="form-label ms-2"> Book Beginning From</label>
                        <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
                            <input matInput ngModel bsDatepicker placeholder="Book Beginning From"
                                formControlName="bookBeginningFrom" style="text-transform:uppercase!important;"
                                bsDatepicker [bsConfig]="{
                          containerClass: 'theme-dark-blue',
                          dateInputFormat: 'DD MMM',
                          showWeekNumbers:false }">
                            <span>
                                <mat-icon class="iconcalender">date_range</mat-icon>
                            </span>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-sm-12">
                        <label class="form-label ms-2">Billing Address<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <textarea matNativeControl matInput required type="text" formControlName="address" row="2"
                                placeholder="Billing Address" [ngClass]="{
                                'is-invalid': (f.address.invalid && f.address.touched) && submitted }" required></textarea>
                        </mat-form-field>

                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.address.touched) && f.address.errors?.required">
                            Billing Address is Required!
                        </mat-error>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <label class="form-label ms-2"> Pincode<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="text" matInput required maxlength="6" minlength="6" name="pincodetxt"
                                id="pincodetxt" formControlName="pincode" placeholder="Pincode" [ngClass]="{
                                    'is-invalid': (f.pincode.invalid && f.pincode.touched) && submitted }"
                                (change)="getPincodeDetails($event.target.value)">

                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.pincode.touched) && f.pincode.errors?.required">
                            Pincode is Required!
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.pincode.touched) && f.pincode.errors?.pattern">
                            Pincode should be only digits
                        </mat-error>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <label class="form-label ms-2"> State<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput required type="text" ngxOnlyString required matInput
                                formControlName="state" name="pintxt" id="pintxt" placeholder="State" [ngClass]="{
                                    'is-invalid': (f.state.invalid && f.state.touched) && submitted }" value="">
                        </mat-form-field>

                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.state.touched) && f.state.errors?.required">
                            State is Required!
                        </mat-error>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <!-- <label class="form-label ms-2"> City<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput required type="text" formControlName="city"
                                placeholder="Town/City" [ngClass]="{
                                'is-invalid': (f.city.invalid && f.city.touched) && submitted }" required>
                        </mat-form-field>

                        <mat-error class="text-danger" *ngIf="(submitted || f.city.touched) && f.city.errors?.required">
                            Town/City is Required!
                        </mat-error> -->
                        <label class="form-label ms-2"> City<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput required type="text" formControlName="district"
                            placeholder="City" [ngClass]="{
                                'is-invalid': (f.district.invalid && f.district.touched) && submitted }" required>
                        </mat-form-field>

                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.district.touched) && f.district.errors?.required">
                            District is Required!
                        </mat-error>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <label class="form-label ms-2"> Country<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput required type="text" formControlName="country"
                                placeholder="Country" [ngClass]="{
                                'is-invalid': (f.country.invalid && f.country.touched) && submitted }" required>
                        </mat-form-field>

                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.country.touched) && f.country.errors?.required">
                            Country is Required!
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="profile-details col-sm-12 col-md-6 col-lg-6 ps-4">
                <div class="row mb-3">
                    <div class="col-lg-6 col-md-12 col-sm-12" *ngIf="isShown">
                        <label class="form-label ms-2"> Tax Payer Type</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select matNativeControl matInput disableOptionCentering formControlName="gstType"
                                placeholder="GST Type" [ngClass]="{
                                    'is-invalid': (f.gstType.invalid && f.gstType.touched) && submitted }"
                                [disabled]="!isDisabledGST">
                                <mat-option value="Regular">Regular</mat-option>
                                <mat-option value="Composite">Composite
                                </mat-option>
                                <mat-option value="Exempted">Exempted</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.gstType.touched) && f.gstType.errors?.required">
                            Tax Payer Type is Required!
                        </mat-error>
                    </div>
                </div>

                <div class="row">
                    <!-- <div class="col-lg-6 col-md-12 col-sm-12" *ngIf="isShown && ProfileForm.get('gstType').value =='Composite'"> -->
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <label class="form-label ms-2"> Trade Name<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput formControlName="tradeName" placeholder="Trade Name"
                                [ngClass]="{
                                    'is-invalid': (f.tradeName.invalid && f.tradeName.touched) && submitted }">
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.tradeName.touched) && f.tradeName.errors?.required">
                            Trade Name is Required!
                        </mat-error>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <label class="form-label ms-2">Password<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput formControlName="password" placeholder="Password"
                                [ngClass]="{
                                    'is-invalid': (f.password.invalid && f.password.touched) && submitted }" [type]="hide ? 'password' : 'text'">
                                      <button type="button" mat-icon-button matSuffix (click)="hide = !hide" aria-label="Toggle password visibility">
                                        <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                                    </button>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.password.touched) && f.password.errors?.required">
                            Password is Required!
                        </mat-error>
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-12" *ngIf="isShown && ProfileForm.get('gstType').value =='Composite'">
                        <label class="form-label ms-2"> Composition Dealer *</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select matNativeControl matInput disableOptionCentering
                                placeholder="Composition Dealer">
                                <mat-option value="Manufacturer & Trader (Goods)">Manufacturer & Trader (Goods)</mat-option>
                                <mat-option value="Restaurant (Not Serving Alcohol)">Restaurant (Not Serving Alcohol)
                                </mat-option>
                                <mat-option value="Service">Service</mat-option>
                                <mat-option value="Manufacturers of Bricks">Manufacturers of Bricks</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </div>


                <div class="row mb-3">
                    <div class="col-lg-6 col-md-12 col-sm-12" *ngIf="isShown">
                        <label class="form-label ms-2"> Constitution of Business</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select formControlName="businessType" matNativeControl matInput disableOptionCentering
                                placeholder="Constitution of Business" [disabled]="!isDisabledGST"
                                [ngClass]="{
                                    'is-invalid': (f.businessType.invalid && f.businessType.touched) && submitted }">
                                <mat-option *ngFor="let item of cosntBusiness" [value]="item">{{item}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.businessType.touched) && f.businessType.errors?.required">
                            Constitution of Business is Required!
                        </mat-error>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <label class="form-label ms-2"> Account Method</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select matNativeControl matInput disableOptionCentering required formControlName="accountMethod"
                                placeholder="Constitution of Business" [ngClass]="{
                                    'is-invalid': (f.accountMethod.invalid && f.accountMethod.touched) && submitted }"
                                required>
                                <mat-option value="Accounts">Only Accounts</mat-option>
                                <mat-option value="Accounts With Stock Management">Accounts With Stock Management</mat-option>
                                <!-- <option value="Both">Both</option> -->
                            </mat-select>
                        </mat-form-field>

                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.accountMethod.touched) && f.accountMethod.errors?.required">
                            Account Method is Required!
                        </mat-error>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-lg-6 col-md-12 col-sm-12" *ngIf="isShown">
                        <label class="form-label ms-2"> Business Type(Select multiple, if applicable)<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select matNativeControl matInput disableOptionCentering formControlName="businessNature"
                            placeholder="Business Type" multiple
                            [ngClass]="{'is-invalid': (f.businessNature.invalid && f.businessNature.touched) && submitted }" required>
                                <mat-option *ngFor="let type of typeList" [value]="type.id">{{type.businessType}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.businessNature.touched) && f.businessNature.errors?.required">
                            Business Type is Required!
                        </mat-error>
                    </div>

                    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12" *ngIf="isShown">
                        <label class="form-label ms-2"> Business Category<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-chip-list #chipList aria-label="category selection" [multiple]="true">
                                <mat-chip *ngFor="let category of categoryName" [selectable]="category=='Education'"
                                    [removable]="removable" (removed)="remove(category)">
                                    {{category}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input placeholder="Business Category" #categoryInput
                                    #autocompleteTrigger="matAutocompleteTrigger" [formControl]="categoryCtrl"
                                    [matAutocomplete]="auto" [matChipInputFor]="chipList" (click)="openAuto(autocompleteTrigger)"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="add($event)" [ngClass]="{
                                        'is-invalid': (categoryCtrl.invalid && categoryCtrl.touched) && submitted }"
                                    required>
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                <mat-option *ngFor="let item of filteredCategory | async" [value]="item.id"
                                    (onSelectionChange)="getCategoryId(item)">
                                    <mat-checkbox color="primary" style="padding: 0 12px;"
                                        [checked]="categoryName.indexOf(item.category) >=0"></mat-checkbox>
                                    {{item.category}}
                                </mat-option>
                            </mat-autocomplete>
                            <a mat-icon-button matSuffix style="width: 34px; height: 34px;"
                                (click)="openAuto(autocompleteTrigger)">
                                <mat-icon style="width: 34px;">expand_more</mat-icon>
                            </a>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || categoryCtrl.touched) && categoryCtrl.errors?.required">
                            Category is Required!
                        </mat-error>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-sm-12 col-md-12">
                        <div class="notes-section">
                            <p class="notes-textarea text-black" style="background: #e4e3e6;">
                                <b>Note:</b> Terms & condition and Signature added below will be shown on your invoices.
                            </p>
                          </div>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <div class="terms-section">

                          </div>
                          <label class="form-label">Terms & Conditions</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput required rows="3" placeholder="Terms & Conditions"
                       >
                       1. Goods once sold will not be taken back or exchanged.
                       1. Goods once sold will not be taken back or exchanged.</textarea>
                </mat-form-field>
                    </div>
                </div>


            </div>

        </div>
    </form>
    <div class="mb-3" *appHasPermission="['read']; name: 'profile'">
        <form [formGroup]="addressForm">
            <label class="pb-3 form-label" style="font-size: 16px; font-weight: 500;">
                Add multiple Business Addresses                        
            </label>
            <div>
                <div formArrayName="addresses" *ngFor="let item of addressControls.controls; let i = index">
                    <div [formGroupName]="i" class="row">
                        <div class="col-sm-12 col-md-4 col-lg-4 " >
                            <label class="form-label ms-2" >Pincode<span class="error-asterisk">*</span></label  >
                            <mat-form-field class="example-full-width" appearance="outline">
                                <input type="text" matInput required
                                    (input)="getPincodeDetailsForAdditionalAddress($event.target.value,i)"
                                    maxlength="6" minlength="6" formControlName="pincode"
                                    placeholder="Pincode"
                                    [ngClass]="{
                                        'is-invalid': (f.pincode.invalid && f.pincode.touched) && submitted }" value="">
                            </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(submitted || f.pincode.touched) && f.pincode.errors?.required">
                                Pincode is Required !
                            </mat-error>
                            <mat-error class="text-danger"
                                *ngIf="(submitted || f.pincode.touched) && f.pincode.errors?.pattern">
                                Pincode should be only digits.
                            </mat-error>
                        </div>
    
                        <div class="col-sm-12 col-md-4 col-lg-4 ">
                            <label class="form-label ms-2" >State<span class="error-asterisk">*</span></label  >
                            <mat-form-field class="example-full-width" appearance="outline">
                                <input matNativeControl matInput disableOptionCentering required type="text" ngxOnlyString
                                    formControlName="state" name="pintxt" id="pintxt"
                                    placeholder="State" [ngClass]="{
                                    'is-invalid': (f.state.invalid && f.state.touched) && submitted }"
                                    value="">
                            </mat-form-field>
    
                            <mat-error class="text-danger"
                                *ngIf="(submitted || f.state.touched) && f.state.errors?.required">
                                State is Required!
                            </mat-error>
                        </div>
    
                        <div class="col-sm-12 col-md-4 col-lg-4 ">
                            <label class="form-label ms-2" >District<span class="error-asterisk">*</span></label  >
                            <mat-form-field class="example-full-width" appearance="outline">
                                <input matNativeControl matInput required type="text"
                                    formControlName="district" placeholder="District"
                                    [ngClass]="{
                                    'is-invalid': (f.district.invalid && f.district.touched) && submitted }" required>
                            </mat-form-field>
    
                            <mat-error class="text-danger"
                                *ngIf="(submitted || f.district.touched) && f.district.errors?.required">
                                District is Required!
                            </mat-error>
                        </div>
    
                        <div class="col-sm-12 col-md-4 col-lg-4 ">
                            <label class="form-label ms-2" >Country<span class="error-asterisk">*</span></label  >
                            <mat-form-field class="example-full-width" appearance="outline">
                                <input matNativeControl matInput required type="text"
                                    formControlName="country" placeholder="Country" [ngClass]="{
                                'is-invalid': (f.country.invalid && f.country.touched) && submitted }">
                            </mat-form-field>
    
                            <mat-error class="text-danger"
                                *ngIf="(submitted || f.country.touched) && f.country.errors?.required">
                                Country is Required!
                            </mat-error>
    
                        </div>
    
                        <div class="col-sm-12 col-md-4 col-lg-4 ">
                            <label class="form-label ms-2" >Town/City<span class="error-asterisk">*</span></label  >
                            <mat-form-field class="example-full-width" appearance="outline">
                                <input matNativeControl matInput required type="text" formControlName="city"
                                    placeholder="Town/City" [ngClass]="{
                                'is-invalid': (f.city.invalid && f.city.touched) && submitted }" required>
                            </mat-form-field>
    
                            <mat-error class="text-danger"
                                *ngIf="(submitted || f.city.touched) && f.city.errors?.required">
                                Town/City is Required!
                            </mat-error>
                        </div>
    
                        <div class="col-sm-12 col-md-4 col-lg-4 ">
                            <label class="form-label ms-2" >House #<span class="error-asterisk">*</span></label  >
                            <mat-form-field class="example-full-width" appearance="outline">
                                <input matNativeControl matInput required type="text"
                                    formControlName="homeno"
                                    placeholder="House #,Apartment,Building,Company" [ngClass]="{
                                'is-invalid': (f.homeno.invalid && f.homeno.touched) && submitted }"
                                    required>
                            </mat-form-field>
    
                            <mat-error class="text-danger"
                                *ngIf="(submitted || f.homeno.touched) && f.homeno.errors?.required">
                                House # is Required!
                            </mat-error>
                        </div>
    
                        <div class="col-sm-12 col-md-4 col-lg-4 ">
                            <label class="form-label ms-2" >Area, Street, Sector, Village<span class="error-asterisk">*</span></label  >
                            <mat-form-field class="example-full-width" appearance="outline">
                                <input matNativeControl matInput required type="text"
                                    formControlName="address" placeholder="Area" [ngClass]="{
                                'is-invalid': (f.address.invalid && f.address.touched) && submitted }"
                                    required>
                            </mat-form-field>
    
                            <mat-error class="text-danger"
                                *ngIf="(submitted || f.address.touched) && f.address.errors?.required">
                                Area, Street, Sector, Village is Required!
                            </mat-error>
                        </div>
    
                        <div class="col-sm-12 col-md-4 col-lg-4 ">
                            <label class="form-label ms-2" >Landmark<span class="error-asterisk">*</span></label  >
                            <mat-form-field class="example-full-width" appearance="outline">
                                <input matNativeControl matInput required type="text"
                                    formControlName="landmark" placeholder="Landmark" [ngClass]="{
                                    'is-invalid': (f.landmark.invalid && f.country.touched) && submitted }"
                                    required>
                            </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(submitted || f.landmark.touched) && f.landmark.errors?.required">
                                Landmark is Required!
                            </mat-error>
                        </div>
                    </div>
                    <div class="row">
                        <div class="">
                            <div class="d-flex justify-content-center">
                                <div *ngIf="item.value.id">
                                    <button class="btn btn-save mb-1" type="button" style="margin-right: 10px;"
                                        *appHasPermission="['modify']; name: 'profile'"
                                        (click)="updateMultipleAddress(item)">
                                        Update
                                    </button>
                                </div>
                                <div>
                                    <button type="button" class="btn btn-cancel" *ngIf="item.value.id"
                                        (click)="deleteAddress(item)">
                                        -
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
    
                <div class="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-end pb-4">
                    <button type="button" class="btn btn-cancel" style="margin-right: 10px" (click)="addAddress()">
                        +
                    </button>
    
                    <button class="btn btn-save" type="button"
                        *appHasPermission="['modify']; name: 'profile'" (click)="addMultipleAddress()">
                        Add
                    </button>
                </div>
            </div>
        </form>
    </div>

</div>
