<div class="card mb-3">
    <div class="card-header bg-light pt-3 pb-3">
        <p class="d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:800;font-size:20px!important;">
            UPI Payment
        </p>
    </div>
    <div class="progress" style="height: 2px">
        <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">
        </div>
    </div>
    <div class="container-fluid bg-white">
        <div class=" d-flex justify-content-end">
            <a class="btn btn-outline-primary me-1 mb-1 m-2" (click)="openAddUpiPopup(Upi)"><i class="fas fa-plus-square me-2"> </i>Add New UPI
            </a>
        </div>
    </div>
    <div class="card-body p-0 m-2">
        <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th>

                                <button style="text-align: left !important; float: left!important;" *ngIf="checkSingleArray.length > 0 || isChecked" class="btn btnp" data-bs-toggle="modal" data-bs-target="#errorDelete-modal">
                                    <!-- <a class="btn btnp" (click)="getUPIID(upiData.upiNo)" data-bs-toggle="modal"
                                        data-bs-target="#errorDelete-modal"> -->
                                        <span  *ngIf="checkSingleArray.length > 0 || isChecked" class="bi-trash icon-color fs-1 text-white" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="Delete"
                                                        (click)=" upiCheckedId.length > 0? getUPIcheckId(upiCheckedId):getSingleCheckedId(checkSingleArray)"></span>
                                        <!-- <span class="bi-trash icon-color fs-1"  data-bs-toggle="tooltip" data-bs-placement="top"
                                            title="Delete"></span> -->
                                    </button>

                                <input class="form-check-input" type="checkbox"
                                (change)="onChekMultiple($event)" [value]="-1" [checked]="checkSelect" [(ngModel)]="allSelect"/>
                                <span style="margin-left:5px !important;">All</span>

                        </th>
                        <th class="text-nowrap">UPI ID</th>
                        <th class="text-nowrap">Primary</th>
                        <th class="text-nowrap">Remove</th>
                    </tr>
                </thead>
                <tbody class="list" *ngIf="UpiListRes" >
                        <tr  [formGroup]="form" *ngFor="let upiData of UpiListRes;let i = index">
                            <td>
                                <input class="form-check-input" type="checkbox" [checked]="checkSelectsingle"
                                   (change)="onChekedsingleCheck($event)" [value]="upiData.id" />
                            </td>
                            <td>{{upiData.upiNo}}</td>
                            <td>
                                <div class="col-sm-4 d-flex mt-1 mt-sm-0 text-center">
                                    <label class="form-check-label me-2" style="margin-left: 90%; "
                                        for="customSwitch1">No</label>
                                    <div class="form-check form-switch mb-0">
                                        <input class="form-check-input" id="customSwitch1" [checked]="upiData.isPrimary" type="checkbox"
                                        (click)="toggleShow(upiData.id,$event.target.checked,i)"  />
                                        <label class="form-check-label align-top" for="customSwitch1">Yes</label>
                                    </div>
                                </div>
                            </td>


                            <td>
                                <span class="m-1" text-center></span>
                                <button class="btn btnp" data-bs-toggle="modal" data-bs-target="#xyz">
                                <!-- <a class="btn btnp" (click)="getUPIID(upiData.upiNo)" data-bs-toggle="modal"
                                    data-bs-target="#errorDelete-modal"> -->
                                    <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip"
                                                    data-bs-placement="top" title="Delete"
                                                    (click)="getUPIID(upiData.upiNo)"></span>
                                    <!-- <span class="bi-trash icon-color fs-1"  data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="Delete"></span> -->
                                </button>
                                <!-- </a> -->
                            </td>

                        </tr>

                </tbody>
                <tbody *ngIf="UpiListRes == 0">
                    <tr class="text-center">
                        <td colspan="10">
                            <h5 class="text-nowrap">No UPI Account Details Added. Please Add at least one UPI
                                Account Details to be displayed here
                            </h5>
                            <!-- <a class="btn btn-outline-primary me-1 mb-1 m-2" (click)="openAddUpiPopup(Upi)">
                                <i class="fas fa-plus-square me-2"> </i>Add New UPI
                            </a> -->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>


    <div class="modal fade" id="error-modal" mat-dialog-content aria-hidden="true" cdkFocusInitial>
        <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 500px">
            <div class="modal-content position-relative">
                <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                    <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                        data-bs-dismiss="modal" aria-label="Close" (click)="modalDismiss()"></a>
                </div>
                <div class="modal-body p-0">
                    <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                        <h4 class="mb-1" id="modalExampleDemoLabel"><b>Enter UPI Details</b></h4>
                    </div>
                    <div class="p-12 pb-2">
                        <div class="">
                            <form [formGroup]="upiForm">
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <mat-label>UPI ID</mat-label>
                                        <input matNativeControl type="text" required matInput formControlName="UPIID"
                                            placeholder="UPI ID" [ngClass]="{
                                                'is-invalid': (f.UPIID.invalid && f.UPIID.touched) && submitted }"
                                            value="">
                                    </mat-form-field>
                                    <mat-error class="text-danger" *ngIf="(submitted || f.UPIID.touched)">
                                        <mat-error *ngIf="f.UPIID.errors?.required">
                                            UPI ID Is Required!
                                        </mat-error>
                                        <mat-error *ngIf="f.UPIID.errors?.pattern">
                                            UPI ID Format 'aaaaaa@bbb'
                                            Requires!
                                        </mat-error>
                                    </mat-error>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <!-- <a type="a" (click)="upiSave()" data-bs-dismiss="modal"
                        class="btn btn-outline-primary">Save</a>
                    <a class="btn btn-outline-primary" (click)="modalDismiss()" data-bs-dismiss="modal">Close</a> -->
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="errorDelete-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 350px">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
                    aria-label="Close"></a>
            </div>
            <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                    <h2 class="mb-1 text-center font-weight-bold" style="font-weight: 800; " id="modalExampleDemoLabel">
                        Delete UPI? </h2>
                </div>
                <h4   *ngIf="!isChecked  && !checkSingleArray "class="text-center ">Are you sure to delete this UPI?</h4>
                <h4 *ngIf="isChecked " class="text-center ">Are you sure to delete All UPI?</h4>
                <h4 *ngIf="!isChecked " class="text-center ">Are you sure to delete selected UPI?</h4>

            </div>
            <div class="modal-footer justify-content-center">
                <a class="btn btn-outline-primary" data-bs-dismiss="modal">Close</a>
                <a   *ngIf="!isChecked  && !checkSingleArray " class="btn btn-outline-primary" data-bs-dismiss="modal" (click)="deleteUPI()">Delete
                </a>
                <a   *ngIf="isChecked " class="btn btn-outline-primary" data-bs-dismiss="modal" (click)="deletemultipleUPI()">Delete All
                </a>
                <a   *ngIf="!isChecked" class="btn btn-outline-primary" data-bs-dismiss="modal" (click)="deletemultipleUPI()">Delete Selected
                </a>

            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="xyz" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 350px">
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
                    aria-label="Close"></a>
            </div>
            <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                    <h2 class="mb-1 text-center font-weight-bold" style="font-weight: 800; " id="modalExampleDemoLabel">
                        Delete UPI? </h2>
                </div>
                <h4 class="text-center ">Are you sure to delete this UPI?</h4>


            </div>
            <div class="modal-footer justify-content-center">
                <a class="btn btn-outline-primary" data-bs-dismiss="modal">Close</a>
                <a  class="btn btn-outline-primary" data-bs-dismiss="modal" (click)="deleteUPI()">Delete
                </a>
                <!-- <a   *ngIf="isChecked " class="btn btn-outline-primary" data-bs-dismiss="modal" (click)="deletemultipleUPI()">Delete All
                </a>
                <a   *ngIf="!isChecked" class="btn btn-outline-primary" data-bs-dismiss="modal" (click)="deletemultipleUPI()">Delete Selected
                </a> -->

            </div>
        </div>
    </div>
</div>


<ng-template #Upi let-modal>
    <div>
        <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <a class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                    data-bs-dismiss="modal" aria-label="Close" (click)="modalDismiss()"></a>
            </div>
            <div class="modal-body p-0">
                <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                    <h4 class="mb-1" id="modalExampleDemoLabel"><b>Enter UPI Details</b></h4>
                </div>
                <div class="p-12 pb-2">
                    <div class="">
                        <form [formGroup]="upiForm">
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
                                <mat-form-field class="example-full-width" appearance="outline">
                                    <mat-label>UPI ID</mat-label>
                                    <input matNativeControl type="text" required matInput formControlName="UPIID"
                                        placeholder="UPIID" [ngClass]="{
                                            'is-invalid': (f.UPIID.invalid && f.UPIID.touched) && submitted }"
                                        value="">
                                </mat-form-field>
                                <mat-error class="text-danger" *ngIf="(submitted || f.UPIID.touched)">
                                    <mat-error *ngIf="f.UPIID.errors?.required">
                                        UPI ID Is Required!
                                    </mat-error>
                                    <mat-error *ngIf="f.UPIID.errors?.pattern">
                                        UPI ID Format 'aaaaaa@bbb'
                                        Requires!
                                    </mat-error>
                                </mat-error>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-outline-primary" (click)="modalDismiss()">Close</button>
                <button type="submit" (click)="upiSave()"
                    class="btn btn-outline-primary">Save</button>
            </div>
        </div>
    </div>

</ng-template>