<div class="card widget" style = "box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);">
    <div class="card-header">
      <div class="row headerRow">
        <div class="col-sm-6">
          <span class="pt-3" style = "font-family: 'Poppins', sans-serif;font-weight: 500;font-size: 18px;">Stock Details</span>
        </div>
        <div class="col-sm-6">
          <div class="row justify-content-end">
            <div class="col-auto">
              <!-- <mat-label style="margin-left: 15px; font-size:12px;">Select Type</mat-label> -->
              <mat-form-field appearance="outline" style="width: 120px;">
                <mat-select matNativeControl matInput disableOptionCentering [(value)]="defaultSaleSelect" (selectionChange)="getSaleInvoiceReportType($event.value)">
                  <mat-option value=Weekly selected>Weekly</mat-option>
                  <mat-option value=Monthly>Monthly</mat-option>
                  <mat-option value=Yearly>Yearly</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" style = "height: 325px;">
        <div class="max-w-100" style = "margin: auto;" id="stock-details-chart">{{stockDetailsChart}}</div>
    </div>
</div>
